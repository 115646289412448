import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	topHeader: {
		fontWeight: 800,
		color: '#464749',
		fontSize: 20,
		marginBottom: 10,
		float: 'left',
		marginTop: 15
	},
	update: {
		padding: 10,
		borderRadius: 4,
		color: '#7a7a7a',
		border: '1px solid #b3bcc1'
	},
	updateSectionContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	updatesContainer: {
		border: '1px solid rgb(220,235,243)',
		maxHeight: 600,
		overflowY: 'scroll',
		borderRadius: 3,
		'& > div > div > div': {
			borderBottom: '1px solid rgb(220,235,243)'
		},
		'& > div > div > div:last-child': {
			borderBottom: 'none'
		}
	},
	loadMoreStyles: {
		color: 'grey',
		padding: 10
	}
}));

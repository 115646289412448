import React, { useEffect, useState } from 'react';
import HeaderBanner from '../HeaderBanner';
import DataTable from '../DataTable';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { claimList } from './queries';
import ClaimedThoroughbredDataRow from '../ClaimedThoroughbredDataRow';
import { mobileHeadings, tableHeadings } from './constants';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import { Link } from 'gatsby';
import LoaderSpinner from '../LoaderSpinner';

const ClaimedThoroughbredListings = ({ isMobile }) => {
	const [numClaimedHorses, setNumClaimedHorses] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [claimedHorses, setClaimedHorses] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser) {
			const input = { memberExternalId: currentUser?.UserId };
			AppSyncService.execute(claimList, input).then((data) => {
				if (data?.data?.getHorseClaims) {
					setClaimedHorses(data.data.getHorseClaims);
					setNumClaimedHorses(data.data.getHorseClaims.length);
					setIsLoading(false);
				}
			});
		}
	}, [currentUser]);

	useEffect(() => {
		buildRows();
	}, [claimedHorses]);

	const sortRowsDown = () => {
		const thoroughbredRows = claimedHorses;
		setClaimedHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horse?.name
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2.horse?.name
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = () => {
		const thoroughbredRows = claimedHorses;
		setClaimedHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horse?.name
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horse?.name
				)
			)
		);
		buildRows();
	};

	const buildRows = () => {
		const numRows = claimedHorses.length;
		const constructedRows = claimedHorses.map((claimedHorse, index) => {
			return (
				<ClaimedThoroughbredDataRow
					thoroughbred={claimedHorse}
					key={claimedHorse.horse.horseCode}
					index={index}
					isLastRow={numRows === index + 1}
					isMobile={isMobile}
				/>
			);
		});
		setRows(constructedRows);
	};

	return (
		<div>
			<HeaderBanner
				title={'Claimed Thoroughbreds (' + numClaimedHorses + ')'}
				styles={{ marginBottom: '0px', marginTop: 0, borderRadius: 0 }}
			/>

			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			{!isLoading && numClaimedHorses === 0 && (
				<InfoBar
					type={MessageBarTypeEnum.info}
					noButton
					id={`No-Claimed-Thoroughbreds-Infobar`}
					style={{ borderRadius: 5, marginTop: 20 }}
				>
					You have not yet claimed any thoroughbred horses, or your claim form
					is being reviewed by the RVEW team, which occurs during business
					hours.
					<br />
					<br />
					To start a claim form and let us know about an OTT in your possession
					please{' '}
					<Link style={{ color: '#0087ce' }} to="/search">
						Search for your Thoroughbred
					</Link>
					.
					<br />
					<br />
					Need more information about the Claim process or details about the
					information required to complete a Claim form? Visit the{' '}
					<Link style={{ color: '#0087ce' }} to="/programs/claim-thoroughbred">
						Claim page
					</Link>
					.
				</InfoBar>
			)}
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
		</div>
	);
};

export default ClaimedThoroughbredListings;

import { createUseStyles } from 'react-jss';
import BannerImage from '../../../assets/images/header_banner.png';

export default createUseStyles({
	header: {
		background: `url(${BannerImage})`,
		borderRadius: 5,
		padding: 20,
		fontWeight: 800,
		color: '#464749',
		marginBottom: 30
	},
	contentContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		fontSize: 15,
		'@media (min-width: 992px)': {
			justifyContent: 'space-between',
			flexDirection: 'row',
			alignItems: 'center',
			fontSize: 20
		}
	},
	statusLabel: {
		fontWeight: 'normal'
	}
});

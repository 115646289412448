import React from 'react';
import useStyles from './styles';

const FormTextField = ({
	onChange,
	styles,
	value,
	canEdit = true,
	preSymbol,
	fieldType = 'text',
	placeholder,
	name,
	id,
	isDisabled = false,
	maxLength = 2000
}) => {
	const classes = useStyles();

	const renderField = () => {
		if (canEdit == false) {
			return <span className={classes.readonly}>{value}</span>;
		} else if (preSymbol) {
			return (
				<div className={classes.fieldEntrySymbolWrapper}>
					<div className={classes.symbol}>
						<div className={classes.symbolContainer}>{preSymbol}</div>
					</div>
					<input
						type={fieldType}
						style={styles}
						name={name}
						className={classes.fieldEntrySymbol}
						onChange={onChange}
						value={value || ''}
						placeholder={placeholder || ''}
						id={id}
						disabled={isDisabled}
						maxLength={maxLength}
					/>
				</div>
			);
		} else {
			return (
				<input
					type={fieldType}
					style={styles}
					name={name}
					className={classes.fieldEntry}
					onChange={onChange}
					value={value ? value : ''}
					placeholder={placeholder ? placeholder : ''}
					id={id}
					disabled={isDisabled}
					maxLength={maxLength}
				></input>
			);
		}
	};

	return renderField();
};

export default FormTextField;

import React, { useEffect, useState, useMemo } from 'react';
import useStyles from './styles';
import {
	Col,
	Container,
	Row,
	ScreenClassRender,
	useScreenClass
} from 'react-grid-system';
import ScrollMenu from '../ScrollMenu';
import AppSyncService from '../../graphql/AppSyncService';
import { useParams } from '@reach/router';
import {
	getBusinessProfileByIdTemplateQuery,
	getForSaleHorseListRetrainer
} from '../../graphql/custom.queries';
import ProfileHeader from '../ProfileHeader';
import SEO from '../seo';
import ImageSlider from '../ImageSlider';
import {
	FOSTER,
	RETIREMENT,
	RETRAINER,
	PROFILE,
	HORSE_FOR_SALE
} from './constants';
import { PAGE_NOT_FOUND_ROUTE } from '../../utils/constants/routes';
import { OFFSITE_COLOR, ONSITE_COLOR } from '../FosterProviderSearch';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { referenceRangeOfFosterCare } from '../FosterProviderInformation';
import { navigate } from '@reach/router';
import { RetrainerProfileListKeys } from '../../utils/constants';
import LoaderSpinner from '../LoaderSpinner';
2;
import HorseForSaleCard from '../HorseForSaleCard';
import Accordion from '../Accordion';
import MultiDropDown from '../MultiDropDown';

const TagsList = ({ sectionTitle, tags, tagColors, ...rest }) => {
	const classes = useStyles();
	const screenClass = useScreenClass();
	const isSmallScreen = ['xs', 'sm', 'md'].includes(screenClass);
	return (
		<Col {...rest}>
			<div
				className={
					isSmallScreen ? classes.tagsHeading : classes.tagsHeadingDesktop
				}
			>
				{sectionTitle}
			</div>
			<div
				className={
					isSmallScreen ? classes.resultTags : classes.resultTagsDesktop
				}
			>
				{tags.length > 0 &&
					tags.map((tag, index) => {
						const id = `${rest.id}-${tag}-${index}`;
						return (
							<div
								className={classes.resultTag}
								style={{
									background: tagColors
								}}
								id={id}
								key={id}
							>
								{tag}
							</div>
						);
					})}
			</div>
		</Col>
	);
};

const RetrainerProfileTemplate = ({ profileType }) => {
	const classes = useStyles();
	const screenClass = useScreenClass();
	const isSmallScreen = ['xs', 'sm', 'md'].includes(screenClass);
	let { id } = useParams();
	const [businessProfileData, setBusinessProfileData] = useState(null);
	const [fullAddress, setFullAddress] = useState('');
	const [businessPhone, setBusinesPhone] = useState(null);
	const [email, setEmail] = useState(null);
	const [website, setWebsite] = useState(null);
	const [twitter, setTwitter] = useState(null);
	const [facebook, setFacebook] = useState(null);
	const [instagram, setInstagram] = useState(null);
	const [onSiteFacilities, setOnSiteFacilities] = useState([]);
	const [offSiteFacilities, setOffSiteFacilities] = useState([]);
	const [specialtyDisciplines, setSpecialtyDisciplines] = useState([]);
	const [servicesOffered, setServicesOffered] = useState([]);
	const [bannerDataArray, setBannerDataArray] = useState([]);
	const [startIndexLeftSlider, setStartIndexLeftSlider] = useState(0);
	const [startIndexRightSlider, setStartIndexRightSlider] = useState(0);
	const [isNotRetrainer, setIsNotRetrainer] = useState(false);
	const [selectedTab, setSelectedTab] = useState(
		RetrainerProfileListKeys.PROFILE
	);
	const [horseForSale, setHorseForSale] = useState([]);
	const [toggleFilter, setToggleFilter] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [listOfDiscipline, setListOfDiscipline] = useState([]);
	const [listOfGender, setListOfGender] = useState([]);
	const [horseForSaleFilter, setHorseForSaleFilter] = useState([]);
	const [selectedFilterIds, setSelectedFilterIds] = useState([]);
	const [selectedFilterGender, setSelectedFilterGender] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const SERVICES_OFFERED_COLORS = {
		dot: '#dab9fb',
		containers: 'rgb(244 240 255)'
	};
	const SPECIALTY_DISCIPLINES_COLORS = {
		dot: '#facce0',
		containers: 'rgb(255 239 247)'
	};
	const FACILITIES_COLORS = { dot: '#ededed', containers: 'rgb(237,237,237)' };

	useEffect(() => {
		setIsLoading(true);
		const input = {
			id: parseInt(id),
			byBusinessProfile: false
		};
		AppSyncService.execute(getBusinessProfileByIdTemplateQuery, input).then(
			(data) => {
				if (data.data != null) {
					if (data.data.getBusinessProfileById != null) {
						let rotatingBannerArray = [];
						let address = null;
						setBusinessProfileData({
							...data.data.getBusinessProfileById
						});
						let getForSaleHorseListByBusinessProfileId =
							data?.data?.getBusinessProfileById?.id;
						fetchHorseForSale(getForSaleHorseListByBusinessProfileId);

						if (
							data.data.getBusinessProfileById.streetAddressDisplayOption?.id ==
							1
						) {
							address = data.data.getBusinessProfileById.streetAddress;
						}
						if (data.data.getBusinessProfileById.suburbDisplayOption?.id == 1) {
							address = address
								? address + ', ' + data.data.getBusinessProfileById.suburb
								: data.data.getBusinessProfileById.suburb;
						}
						if (data.data.getBusinessProfileById.stateDisplayOption?.id == 1) {
							address = address
								? address + ' ' + data.data.getBusinessProfileById.state
								: data.data.getBusinessProfileById.state;
						}
						if (
							data.data.getBusinessProfileById.postcodeDisplayOption?.id == 1
						) {
							address = address
								? address + ' ' + data.data.getBusinessProfileById.postcode
								: data.data.getBusinessProfileById.postcode;
						}
						setFullAddress(address);
						if (data.data.getBusinessProfileById.emailDisplayOption?.id == 1) {
							setEmail(data.data.getBusinessProfileById.email);
						}
						if (
							data.data.getBusinessProfileById.businessPhoneDisplayOption?.id ==
							1
						) {
							setBusinesPhone(data.data.getBusinessProfileById.businessPhone);
						}
						if (data.data.getBusinessProfileById.website?.trim() != '') {
							setWebsite(data.data.getBusinessProfileById.website);
						}
						if (data.data.getBusinessProfileById.twitter?.trim() != '') {
							setTwitter(data.data.getBusinessProfileById.twitter);
						}
						if (data.data.getBusinessProfileById.facebook?.trim() != '') {
							setFacebook(data.data.getBusinessProfileById.facebook);
						}
						if (data.data.getBusinessProfileById.instagram?.trim() != '') {
							setInstagram(data.data.getBusinessProfileById.instagram);
						}
						data.data.getBusinessProfileById?.riddenPhotos
							?.filter((f) => f.approved)
							.map((img) => {
								if (
									img.businessProfileId === data.data.getBusinessProfileById?.id
								) {
									rotatingBannerArray.push({ image: img.key, type: img.type });
								}
							});
						data.data.getBusinessProfileById?.propertyPhotos
							?.filter((f) => f.approved)
							.map((img) => {
								if (
									img.businessProfileId === data.data.getBusinessProfileById?.id
								) {
									rotatingBannerArray.push({ image: img.key, type: img.type });
								}
							});
						data.data.getBusinessProfileById?.generalPhotos
							?.filter((f) => f.approved)
							.map((img) => {
								if (
									img.businessProfileId === data.data.getBusinessProfileById?.id
								) {
									rotatingBannerArray.push({ image: img.key, type: img.type });
								}
							});
						setBannerDataArray(rotatingBannerArray);

						if (
							data.data.getBusinessProfileById?.businessProfileType?.id === 2
						) {
							setIsNotRetrainer(true);
							if (
								data.data.getBusinessProfileById?.riddenPhotos.length !== 0 &&
								data.data.getBusinessProfileById?.propertyPhotos.length !== 0
							) {
								rotatingBannerArray.forEach((banner, index) => {
									if (banner.type === 'ridden') {
										setStartIndexLeftSlider(index);
									}
									if (banner.type === 'property') {
										setStartIndexRightSlider(index);
									}
								});
							} else {
								rotatingBannerArray.forEach((banner, index) => {
									if (banner.type === 'property') {
										setStartIndexLeftSlider(index);
									}
									if (banner.type === 'general') {
										setStartIndexRightSlider(index);
									}
								});
							}
						} else {
							setIsNotRetrainer(false);
						}

						let onSiteFacilitiesArray = data.data.getBusinessProfileById.facilitiesOnProperty?.map(
							(item) => item.value
						);
						onSiteFacilitiesArray = onSiteFacilitiesArray.filter(
							(i) => i !== 'Other'
						);
						setOnSiteFacilities(onSiteFacilitiesArray);
						let offSiteFacilitiesArray = data.data.getBusinessProfileById.facilitiesOffSite?.map(
							(item) => item.value
						);
						offSiteFacilitiesArray = offSiteFacilitiesArray.filter(
							(i) => i !== 'Other'
						);
						setOffSiteFacilities(offSiteFacilitiesArray);
						let specialtyDisciplinesArray = data.data.getBusinessProfileById.specialtyDisciplines?.map(
							(item) => item.value
						);
						specialtyDisciplinesArray = specialtyDisciplinesArray.filter(
							(i) => i !== 'Other'
						);
						setSpecialtyDisciplines(specialtyDisciplinesArray);
						let servicesOfferedArray = data.data.getBusinessProfileById.servicesOffered?.map(
							(item) => item.value
						);
						servicesOfferedArray = servicesOfferedArray.filter(
							(i) => i !== 'Other'
						);
						setServicesOffered(servicesOfferedArray);
					}
					if (data.data.listSpecialtyDisciplines?.length > 0) {
						let listDisciplineArray = [];
						let count = 0;
						data.data.listSpecialtyDisciplines.map((rec) => {
							listDisciplineArray.push({
								id: count,
								key: rec.id,
								title: rec.value,
								selected: false
							});
							count++;
						});
						setListOfDiscipline(listDisciplineArray);
					}
					if (data.data.listHorseGenderTypes?.length > 0) {
						let listGenderArray = [];
						let count = 0;
						data.data.listHorseGenderTypes.map((rec) => {
							listGenderArray.push({
								id: count,
								key: rec.id,
								title: rec.value,
								selected: false
							});
							count++;
						});
						setListOfGender(listGenderArray);
					}
				} else {
					setIsLoading(false);
					goToPageNotFound();
				}
			}
		);
	}, []);

	const goToPageNotFound = () => {
		navigate(PAGE_NOT_FOUND_ROUTE);
	};

	const scrollMenuList = useMemo(() => {
		if (horseForSale) {
			const RETRAINER_PROFILE_HORSE_SALE_TAB_LIST = [
				{
					id: RetrainerProfileListKeys.PROFILE,
					key: 1,
					menuItem: PROFILE,
					title: `${profileType ?? RETRAINER} Profile`,
					show: true
				},
				{
					id: RetrainerProfileListKeys.HORSE_FOR_SALE,
					key: 2,
					menuItem: HORSE_FOR_SALE,
					title: `OTT's available (${horseForSale.length})`,
					show: profileType === RETRAINER
				}
			];
			return RETRAINER_PROFILE_HORSE_SALE_TAB_LIST;
		}
	}, [horseForSale]);

	const otherOnSiteFacilitiesSplit = businessProfileData?.facilitiesOnPropertyOther?.split(
		','
	);
	const otherOnSiteFacilitiesMapped = otherOnSiteFacilitiesSplit?.map(
		(i) => ' ' + i
	);
	const otherOnSiteFacilities = otherOnSiteFacilitiesMapped
		? ['Other - ' + otherOnSiteFacilitiesMapped]
		: [];

	const otherOffSiteFacilitiesSplit = businessProfileData?.facilitiesOffSiteOther?.split(
		','
	);
	const otherOffSiteFacilitiesMapped = otherOffSiteFacilitiesSplit?.map(
		(i) => ' ' + i
	);
	const otherOffSiteFacilities = otherOffSiteFacilitiesMapped
		? ['Other - ' + otherOffSiteFacilitiesMapped]
		: [];

	const otherSpecialtyDisciplinesSplit = businessProfileData?.specialtyDisciplinesOther?.split(
		','
	);
	const otherSpecialtyDisciplinesMapped = otherSpecialtyDisciplinesSplit?.map(
		(i) => ' ' + i
	);
	const otherSpecialtyDisciplines = otherSpecialtyDisciplinesMapped
		? ['Other - ' + otherSpecialtyDisciplinesMapped]
		: [];

	const otherServicesOfferedSplit = businessProfileData?.servicesOfferedOther?.split(
		','
	);
	const otherServicesOfferedMapped = otherServicesOfferedSplit?.map(
		(i) => ' ' + i
	);
	const otherServicesOffered = otherServicesOfferedMapped
		? ['Other - ' + otherServicesOfferedMapped]
		: [];

	const renderFosterCapacity = (capacity) => {
		const capacityItem = referenceRangeOfFosterCare.find(
			(item) => item.id === capacity
		);
		return capacityItem && capacityItem.value !== ''
			? `${capacityItem.value}`
			: '';
	};

	const fetchHorseForSale = async (businessProfileId) => {
		const result = await AppSyncService.execute(getForSaleHorseListRetrainer, {
			businessProfileId
		});

		if (result?.data?.getForSaleHorseListByBusinessProfileId) {
			let horseItemTemp = [];
			const horseForSaleList =
				result.data.getForSaleHorseListByBusinessProfileId;
			horseForSaleList.forEach((horseItem) => {
				horseItemTemp = [
					...horseItemTemp,
					{
						id: horseItem?.horseId,
						imageLink: horseItem?.files[0]?.key,
						horseName: horseItem?.horseName,
						sex: horseItem?.horse?.sex,
						age: horseItem?.horse?.age,
						disciplines: [...horseItem?.horse?.ottInformation?.disciplines],
						disciplinesOther:
							horseItem?.horse?.ottInformation?.disciplinesOther,
						horseCode: horseItem?.horseCode
					}
				];
			});
			setHorseForSale(horseItemTemp);
		}
		setIsLoading(false);
	};

	const resetGender = (id) => {
		const temp = listOfGender;

		temp[id].selected = !temp[id].selected;

		let filter = Object.assign([], selectedFilterGender);
		let filterIds = Object.assign([], selectedFilterIds);
		let horseForSaleFilterTemp = Object.assign([], horseForSaleFilter);

		if (temp[id].selected) {
			filter.push(temp[id].title);
			const newHorseFilter = horseForSale.filter(
				(item) => item.sex.toLowerCase() === temp[id].title.toLowerCase()
			);
			if (horseForSaleFilterTemp.length > 0) {
				horseForSaleFilterTemp = [...horseForSaleFilterTemp, ...newHorseFilter];
			} else {
				horseForSaleFilterTemp = [...newHorseFilter];
			}
		}

		if (!temp[id].selected) {
			const index = filter.indexOf(temp[id].title);
			if (index > -1) {
				filter.splice(index, 1);
				horseForSaleFilterTemp = horseForSale.filter((item) =>
					filter.includes(item.sex)
				);
				if (selectedFilter.length > 0) {
					const disciplineFilter = horseForSale.filter((item) =>
						item.disciplines
							.map((item2) => item2.id)
							.some((item3) => filterIds.includes(item3))
					);
					horseForSaleFilterTemp = [
						...horseForSaleFilterTemp,
						...disciplineFilter
					];
				}
			}
		}

		const filterDuplicates = [
			...new Map(
				horseForSaleFilterTemp.map((item) => [item['id'], item])
			).values()
		];
		setHorseForSaleFilter(filterDuplicates);
		setSelectedFilterGender(filter);
	};

	const resetDiscipline = (id) => {
		const temp = listOfDiscipline;

		temp[id].selected = !temp[id].selected;

		let filter = Object.assign([], selectedFilter);
		let filterIds = Object.assign([], selectedFilterIds);
		let horseForSaleFilterTemp = Object.assign([], horseForSaleFilter);

		if (temp[id].selected) {
			filter.push(temp[id].title);
			filterIds.push(temp[id].key);
			const newHorseFilter = horseForSale.filter((item) =>
				item.disciplines.map((item2) => item2.value).includes(temp[id].title)
			);
			if (horseForSaleFilterTemp.length > 0) {
				horseForSaleFilterTemp = [...horseForSaleFilterTemp, ...newHorseFilter];
			} else {
				horseForSaleFilterTemp = [...newHorseFilter];
			}
		}

		if (!temp[id].selected) {
			const index = filter.indexOf(temp[id].title);
			if (index > -1) {
				filter.splice(index, 1);
				filterIds = filterIds.filter((item) => item !== temp[id].key);
				horseForSaleFilterTemp = horseForSale.filter((item) =>
					item.disciplines
						.map((item2) => item2.id)
						.some((item3) => filterIds.includes(item3))
				);
				if (selectedFilterGender.length > 0) {
					horseForSaleFilterTemp = horseForSale.filter((item) =>
						selectedFilterGender.includes(item.sex)
					);
				}
			}
		}

		const filterDuplicates = [
			...new Map(
				horseForSaleFilterTemp.map((item) => [item['id'], item])
			).values()
		];
		const filterDuplicateIds = [...new Set(filterIds)];
		setHorseForSaleFilter(filterDuplicates);
		setSelectedFilter(filter);
		setSelectedFilterIds(filterDuplicateIds);
	};

	const removeAllFilters = () => {
		selectedFilter.forEach((value) => {
			removeFilter(value);
		});
		selectedFilterGender.forEach((value) => {
			removeGenderFilter(value);
		});
		setHorseForSaleFilter([]);
		setSelectedFilter([]);
		setSelectedFilterIds([]);
		setSelectedFilterGender([]);
	};

	const removeFilter = (val) => {
		let filters = Object.assign([], selectedFilter);
		let filterIds = Object.assign([], selectedFilterIds);
		const index = filters.indexOf(val);
		if (index > -1) {
			filters.splice(index, 1);
			filterIds.splice(index, 1);
		}

		const tempA = horseForSaleFilter;
		let removedFilteredHorse = tempA.filter((item) =>
			item.disciplines
				.map((item2) => item2.id)
				.some((item3) => filterIds.includes(item3))
		);
		if (selectedFilterGender.length > 0) {
			removedFilteredHorse = horseForSale.filter((item) =>
				selectedFilterGender.includes(item.sex)
			);
		}
		const tempB = listOfDiscipline;
		const removedListOfDiscipline = tempB.map((d) => {
			if (d.title === val) {
				d.selected = false;
			}
			return d;
		});
		setListOfDiscipline(removedListOfDiscipline);
		setHorseForSaleFilter(removedFilteredHorse);
		setSelectedFilter(filters);
		const filterDuplicateIds = [...new Set(filterIds)];
		setSelectedFilterIds(filterDuplicateIds);
	};

	const removeGenderFilter = (val) => {
		let filters = Object.assign([], selectedFilterGender);
		let filterIds = Object.assign([], selectedFilterIds);
		const index = filters.indexOf(val);
		if (index > -1) {
			filters.splice(index, 1);
		}

		const tempA = horseForSaleFilter;
		let removedFilteredHorse = tempA.filter((item) =>
			filters.includes(item.sex)
		);
		if (selectedFilter.length > 0) {
			const disciplineFilter = horseForSale.filter((item) =>
				item.disciplines
					.map((item2) => item2.id)
					.some((item3) => filterIds.includes(item3))
			);
			removedFilteredHorse = [...removedFilteredHorse, ...disciplineFilter];
		}
		const filterDuplicates = [
			...new Map(
				removedFilteredHorse.map((item) => [item['id'], item])
			).values()
		];
		const tempB = listOfGender;
		const removedListOfGender = tempB.map((d) => {
			if (d.title === val) {
				d.selected = false;
			}
			return d;
		});
		setListOfGender(removedListOfGender);
		setHorseForSaleFilter(filterDuplicates);
		setSelectedFilterGender(filters);
	};

	const triggerGAHorseForSale = (horseDetails) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'GAEvent',
			eventCategory: `CTA ${horseDetails.horseName} - ${horseDetails.horseCode}`,
			eventAction: `Click on Horse For Sale ${horseDetails.horseName} - ${horseDetails.horseCode}`,
			eventLabel: `${horseDetails.horseName} - ${horseDetails.horseCode}`
		});
	};

	const SharedComponent = (mobile) => {
		return (
			<>
				<SEO title={`OTT ${profileType ? profileType : RETRAINER} Services`} />
				{businessProfileData != null && (
					<ProfileHeader
						title={businessProfileData?.businessName}
						logoKey={
							businessProfileData?.logo
								.filter((l) => l.approved)
								.find((log) => log.businessProfileId === businessProfileData.id)
								?.key
						}
						address={fullAddress}
						website={website}
						email={email}
						phone={businessPhone}
						twitter={twitter}
						facebook={facebook}
						instagram={instagram}
						mobile={mobile}
						firstName={businessProfileData?.firstName}
						showFirstName={businessProfileData?.firstNameDisplayOption?.value.includes(
							'Everyone (Public)'
						)}
						lastName={businessProfileData?.lastName}
						showLastName={businessProfileData?.lastNameDisplayOption?.value.includes(
							'Everyone (Public)'
						)}
						profileType={profileType}
						alternativePhone={businessProfileData?.alternativePhone}
						alternativeContact={businessProfileData?.alternativeContact}
						top
					/>
				)}
				<div className={classes.scrollableTabsContainer}>
					<ScrollMenu
						menuList={scrollMenuList}
						selectedSearch={selectedTab}
						onClick={(newTabSelection) => setSelectedTab(newTabSelection)}
					/>
				</div>
				{selectedTab === RetrainerProfileListKeys.PROFILE ? (
					<>
						<Row>
							<Col>
								{mobile ? (
									<>
										<ImageSlider sliderImages={bannerDataArray} />
										{profileType === FOSTER && (
											<>
												{businessProfileData?.experiences && (
													<div style={{ marginBottom: 0 }}>
														<div
															className={
																mobile
																	? classes.sectionHeading
																	: classes.sectionHeadingDesktop
															}
														>
															Experience / Specialty Services
														</div>
														<div
															className={
																mobile
																	? classes.sectionText
																	: classes.sectionTextDesktop
															}
														>
															{businessProfileData?.experiences}
														</div>
													</div>
												)}
											</>
										)}
									</>
								) : (
									<>
										<div className={classes.desktopImageSliderContainer}>
											<ImageSlider
												sliderImages={bannerDataArray}
												startIndex={isNotRetrainer ? startIndexLeftSlider : 0}
											/>
											<ImageSlider
												sliderImages={bannerDataArray}
												startIndex={
													isNotRetrainer
														? startIndexRightSlider
														: bannerDataArray.length > 1
														? 1
														: 0
												}
											/>
										</div>
										{profileType === FOSTER && (
											<>
												{businessProfileData?.experiences && (
													<div style={{ marginBottom: 0 }}>
														<div
															className={
																mobile
																	? classes.sectionHeading
																	: classes.sectionHeadingDesktop
															}
														>
															Experience / Specialty Services
														</div>
														<div
															className={
																mobile
																	? classes.sectionText
																	: classes.sectionTextDesktop
															}
														>
															{businessProfileData?.experiences}
														</div>
													</div>
												)}
											</>
										)}
									</>
								)}
							</Col>
						</Row>
						<div
							className={
								mobile ? classes.sectionText : classes.sectionTextDesktop
							}
						>
							{businessProfileData?.businessSummary}
						</div>
						<div className={mobile ? classes.lineMobile : classes.line}></div>
						{mobile && (
							<>
								<Row>
									<TagsList
										id={'retrainerprofile-facilities-onsite'}
										sectionTitle={`Facilities - On ${
											profileType === RETIREMENT || profileType === FOSTER
												? 'Property'
												: 'Site'
										}`}
										tags={[
											...onSiteFacilities,
											...(otherOnSiteFacilities || [])
										]}
										tagColors={
											profileType !== FOSTER
												? FACILITIES_COLORS.containers
												: ONSITE_COLOR
										}
									/>
									<TagsList
										id={'retrainerprofile-facilities-offsite'}
										sectionTitle={`Facilities - Off ${
											profileType === RETIREMENT || profileType === FOSTER
												? 'Property'
												: 'Site'
										}`}
										tags={[
											...offSiteFacilities,
											...(otherOffSiteFacilities || [])
										]}
										tagColors={
											profileType !== FOSTER
												? FACILITIES_COLORS.containers
												: OFFSITE_COLOR
										}
									/>
								</Row>
								<Row>
									{profileType !== FOSTER && (
										<>
											<TagsList
												id={'retrainerprofile-special-disciplines'}
												sectionTitle="Specialty Disciplines"
												tags={[
													...specialtyDisciplines,
													...(otherSpecialtyDisciplines || [])
												]}
												tagColors={SPECIALTY_DISCIPLINES_COLORS.containers}
											/>
											<TagsList
												id={'retrainerprofile-services-offered'}
												sectionTitle="Services Offered"
												tags={[
													...servicesOffered,
													...(otherServicesOffered || [])
												]}
												tagColors={SERVICES_OFFERED_COLORS.containers}
											/>
										</>
									)}
								</Row>

								{profileType === FOSTER &&
									businessProfileData?.adviseDatesUnAvailable && (
										<Row>
											<Col xs={12} lg={6}>
												<div
													className={
														isSmallScreen
															? classes.tagsHeading
															: classes.tagsHeadingDesktop
													}
													style={{
														marginTop: 13,
														marginBottom: 0,
														marginLeft: 'auto',
														marginRight: 'auto',
														width: 'fit-content'
													}}
												>
													Dates Unavailable
												</div>
												<DateRange
													onChange={() => null}
													editableDateInputs={false}
													showDateDisplay={false}
													ranges={[
														{
															startDate: businessProfileData?.dateUnavailableStart
																? new Date(
																		businessProfileData?.dateUnavailableStart
																  )
																: new Date(),
															endDate: businessProfileData?.dateUnavailableEnd
																? new Date(
																		businessProfileData?.dateUnavailableEnd
																  )
																: new Date(),
															key: 'selection',
															color: '#464749'
														}
													]}
												/>
											</Col>
										</Row>
									)}
							</>
						)}
						{!mobile && (
							<>
								<Row style={{ marginRight: 20, marginLeft: 20 }}>
									<Col xs={6} lg={3}>
										<TagsList
											id={'retrainerprofile-facilities-onsite'}
											sectionTitle={`Facilities - On ${
												profileType === RETIREMENT || profileType === FOSTER
													? 'Property'
													: 'Site'
											}`}
											tags={[
												...onSiteFacilities,
												...(otherOnSiteFacilities || [])
											]}
											tagColors={
												profileType !== FOSTER
													? FACILITIES_COLORS.containers
													: ONSITE_COLOR
											}
										/>
									</Col>
									<Col xs={6} lg={3}>
										<TagsList
											id={'retrainerprofile-facilities-offsite'}
											sectionTitle={`Facilities - Off ${
												profileType === RETIREMENT || profileType === FOSTER
													? 'Property'
													: 'Site'
											}`}
											tags={[
												...offSiteFacilities,
												...(otherOffSiteFacilities || [])
											]}
											tagColors={
												profileType !== FOSTER
													? FACILITIES_COLORS.containers
													: OFFSITE_COLOR
											}
										/>
									</Col>
									{profileType !== FOSTER && (
										<>
											<Col xs={6} lg={3}>
												<TagsList
													id={'retrainerprofile-special-disciplines'}
													sectionTitle="Specialty Disciplines"
													tags={[
														...specialtyDisciplines,
														...(otherSpecialtyDisciplines || [])
													]}
													tagColors={SPECIALTY_DISCIPLINES_COLORS.containers}
												/>
											</Col>
											<Col xs={6} lg={3}>
												<TagsList
													id={'retrainerprofile-services-offered'}
													sectionTitle="Services Offered"
													tags={[
														...servicesOffered,
														...(otherServicesOffered || [])
													]}
													tagColors={SERVICES_OFFERED_COLORS.containers}
												/>
											</Col>
										</>
									)}
									{profileType === FOSTER &&
										businessProfileData?.adviseDatesUnAvailable && (
											<Col xs={6} lg={6}>
												<div
													className={
														isSmallScreen
															? classes.tagsHeading
															: classes.tagsHeadingDesktop
													}
													style={{
														marginLeft: 'auto',
														marginRight: 'auto',
														width: 'inherit'
													}}
												>
													Dates Unavailable
												</div>
												<DateRange
													onChange={() => null}
													editableDateInputs={false}
													showDateDisplay={false}
													ranges={[
														{
															startDate: businessProfileData?.dateUnavailableStart
																? new Date(
																		businessProfileData?.dateUnavailableStart
																  )
																: new Date(),
															endDate: businessProfileData?.dateUnavailableEnd
																? new Date(
																		businessProfileData?.dateUnavailableEnd
																  )
																: new Date(),
															key: 'selection',
															color: '#464749'
														}
													]}
												/>
											</Col>
										)}
								</Row>
							</>
						)}
						{profileType !== FOSTER && (
							<>
								<div
									style={{ marginBottom: 0 }}
									className={mobile ? classes.lineMobile : classes.line}
								/>
								{businessProfileData?.experiences && (
									<>
										<div
											className={
												mobile
													? classes.sectionHeading
													: classes.sectionHeadingDesktop
											}
										>
											Experience / Qualifications
										</div>
										<div
											className={
												mobile
													? classes.sectionText
													: classes.sectionTextDesktop
											}
										>
											{businessProfileData?.experiences}
										</div>
									</>
								)}
								{businessProfileData?.priceRange && (
									<div
										className={
											mobile
												? classes.priceRangeContainer
												: classes.priceRangeContainerDesktop
										}
									>
										<div className={classes.subSectionHeading}>Price Range</div>
										<div className={classes.subSectionText}>
											{businessProfileData?.priceRange}
										</div>
									</div>
								)}
							</>
						)}
						{profileType === FOSTER && (
							<>
								<div
									style={{ marginBottom: 0 }}
									className={mobile ? classes.lineMobile : classes.line}
								/>
								<div
									className={
										mobile
											? classes.priceRangeContainer
											: classes.priceRangeContainerDesktop
									}
								>
									<div className={classes.subSectionHeading}>
										Capacity for STAR Care / Emergency Aid horses
									</div>
									<div className={classes.subSectionTextWithoutMarginBtm}>
										<p>
											Maximum Capacity?{' '}
											{businessProfileData?.isMaximumCapacity
												? businessProfileData?.maximumCapacity
												: 'No'}
										</p>
										{businessProfileData?.rangeOfFosterCare && (
											<p>
												Comfortable with:{' '}
												{renderFosterCapacity(
													businessProfileData?.rangeOfFosterCare
												)}
											</p>
										)}
									</div>
								</div>
								{businessProfileData?.finalNotes && (
									<div style={{ paddingLeft: mobile ? 0 : 9 }}>
										<div
											className={
												mobile
													? classes.subSectionHeadingNotesMobile
													: classes.subSectionHeadingNotes
											}
										>
											Notes
										</div>
										<div
											className={
												mobile
													? classes.subSectionTextWithoutMarginBtmMobile
													: classes.subSectionTextWithoutMarginBtm
											}
										>
											{businessProfileData?.finalNotes}
										</div>
									</div>
								)}
							</>
						)}
					</>
				) : (
					<>
						{selectedFilter.length > 0 || selectedFilterGender.length > 0 ? (
							<div className={classes.filters}>
								<div className={classes.filterContent}>
									{selectedFilter.map((f) => {
										return (
											<span key={f} className={classes.appliedFilter}>
												{f}{' '}
												<span
													className={classes.appliedFilterCross}
													onClick={() => removeFilter(f)}
												>
													&#10006;
												</span>
											</span>
										);
									})}
									{selectedFilterGender.map((f) => {
										return (
											<span key={f} className={classes.appliedFilter}>
												{f}{' '}
												<span
													className={classes.appliedFilterCross}
													// onClick={() => removeFilter(f)}
													onClick={() => removeGenderFilter(f)}
												>
													&#10006;
												</span>
											</span>
										);
									})}
									<span
										className={classes.appliedFilter}
										style={{ cursor: 'pointer' }}
										onClick={() => removeAllFilters()}
									>
										Clear all
									</span>
								</div>
							</div>
						) : null}
						<div className={classes.searchFieldContainer}>
							<div
								className={classes.addFilter}
								onClick={() => setToggleFilter(!toggleFilter)}
							></div>
							<Accordion title="Add Filters" isOpen={toggleFilter} mobile>
								<div className={classes.filterContainer}>
									<div className={classes.filterMobileSer}>
										<MultiDropDown
											list={listOfDiscipline}
											resetThenSet={resetDiscipline}
											headTitle="Discipline"
										/>
									</div>
									<div className={classes.filterMobileSer}>
										<MultiDropDown
											list={listOfGender}
											resetThenSet={resetGender}
											headTitle="Sex"
										/>
									</div>
								</div>
							</Accordion>
						</div>
						<Row>
							<Col>
								{selectedFilter.length > 0 ||
								selectedFilterGender.length > 0 ? (
									horseForSaleFilter.length > 0 ? (
										<div className={classes.forSaleHorseCards}>
											{horseForSaleFilter.map((item) => {
												return (
													<HorseForSaleCard
														isMobile={mobile}
														{...item}
														key={item.id}
														onClick={() => {
															triggerGAHorseForSale(item);
															navigate(`/horse-profile/${item?.horseCode}`);
														}}
													/>
												);
											})}
										</div>
									) : (
										<>
											<div className={classes.sectionNoListing}>
												<p className={classes.sectionNoListingText}>
													This Retrainer currently has no OTT&apos;s listed as
													available, you can contact them directly for OTT
													enquiries or you can browse for available OTT&apos;s
													listed by Acknowledged Retrainers using the
													Suggestions to Follow section (within your Following
													tab).
												</p>
												<p className={classes.sectionNoListingText}>
													Did you know you can click Follow on any horse profile
													to stay updated about their life after racing?
												</p>
											</div>
										</>
									)
								) : horseForSale.length > 0 ? (
									<div className={classes.forSaleHorseCards}>
										{horseForSale.map((item) => {
											return (
												<HorseForSaleCard
													isMobile={mobile}
													{...item}
													key={item.id}
													onClick={() => {
														triggerGAHorseForSale(item);
														navigate(`/horse-profile/${item?.horseCode}`);
													}}
												/>
											);
										})}
									</div>
								) : (
									<>
										<div className={classes.sectionNoListing}>
											<p className={classes.sectionNoListingText}>
												This Retrainer currently has no OTT&apos;s listed as
												available, you can contact them directly for OTT
												enquiries or you can browse for available OTT&apos;s
												listed by Acknowledged Retrainers using the Suggestions
												to Follow section (within your Following tab).
											</p>
											<p className={classes.sectionNoListingText}>
												Did you know you can click Follow on any horse profile
												to stay updated about their life after racing?
											</p>
										</div>
									</>
								)}
							</Col>
						</Row>
					</>
				)}
			</>
		);
	};

	const desktopComponent = (
		<Container className={classes.pageContainer}>
			{SharedComponent(false)}
		</Container>
	);

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.pageContainerMobile}>{SharedComponent(true)}</div>
		</div>
	);

	const page = (screenClass) => {
		return isLoading ? (
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
		) : ['xs', 'sm', 'md'].includes(screenClass) ? (
			mobileComponent
		) : (
			desktopComponent
		);
	};

	return <ScreenClassRender render={page} />;
};

export default RetrainerProfileTemplate;

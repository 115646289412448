import React, { useState, useEffect, Fragment } from 'react';
import useStyles from './style';
import {
	modalStylesConfirmation,
	StyledCancelRequest,
	StyledContainer,
	StyledErrorText,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledFormActionsRow,
	StyledFormEndDivider,
	StyledFormFieldsContainer,
	StyledSaveDraft
} from './style';
import { BLANK_OPTION } from '../../utils/constants/index.js';
import { validation, TOGGLE_DEPENDENT } from '../ConcludeOHEP/validation';
import FormTextField from '../FormTextField';
import ResizingTextArea from '../ResizingTextArea';
import FormDropDown from '../FormDropDown';
import { navigate } from 'gatsby';
import { Col, Row } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService.js';
import FormDatePicker from '../FormDatePicker/index.js';
import {
	getMemberBenefitsAndMerchandiseOptionsData,
	saveMemberBenefitsAndMerchandiseForm
} from './queries';
import ConfirmationModal from '../ConfirmationModal';
import { useToast } from '../../hooks/useToast';
import { formatDate } from '../../utils/helpers';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import PrimaryButton from '../PrimaryButton/index.js';

const PAGE_ID = 'Member-Benefits-And-Merchandise';
const validationFields = [
	'memberBenefits',
	'memberBenefitsOther',
	'memberMerchandise',
	'memberMerchandiseOther',
	'memberMerchandiseDetails',
	'memberMerchandiseRugSize',
	'eventTicketsDetails',
	'eventDate',
	'clinicDetails',
	'clinicDetailsOther',
	'competitionDrawDate',
	'competitionPrizeDetails',
	'winnersEligibilityConfirmedBy',
	'comment'
];

const CommunityMemberBenefitsAndMerchandiseForm = ({
	memberData,
	setIsLoading,
	loadMemberData,
	adminExternalId,
	setIsAccordionOpen,
	setInitialLoad
}) => {
	const classes = useStyles();
	const [benefitsTypeOptions, setBenefitsTypeOptions] = useState([]);
	const [validationOn, setValidationOn] = useState(false);
	const [merchandiseTypeOptions, setMerchandiseTypeOptions] = useState([]);
	const [
		merchandiseRugSizeTypeOptions,
		setMerchandiseRugSizeTypeOptions
	] = useState([]);
	const [clinicDetailsTypeOptions, setClinicDetailsTypeOptions] = useState([]);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [submission, setSubmission] = useState(false);
	const [memberBenefitType, setMemberBenefitType] = useState(0);
	const [merchandiseType, setMerchandiseType] = useState(0);
	const [clinicDetails, setClinicDetails] = useState(0);
	const [errors, setErrors] = useState({});
	const [values, setValues] = useState({
		memberId: {
			value: memberData.id,
			isRequired: false
		},
		memberBenefits: {
			value: 0,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select a type of member benefits.'
		},
		memberBenefitsOther: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 6,
					errorMessage: 'Other description is required for member benefits.'
				}
			],
			isRequired: false
		},
		memberMerchandise: {
			value: 0,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 5,
					errorMessage: 'You must select a type of member merchandise.'
				}
			],
			isRequired: false
		},
		memberMerchandiseOther: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberMerchandise',
					value: 8,
					errorMessage: 'Other description is required for merchandise.'
				}
			],
			isRequired: false
		},
		memberMerchandiseDetails: {
			value: null,
			conditions: [],
			isRequired: false
		},
		memberMerchandiseRugSize: {
			value: 0,
			conditions: [],
			isRequired: false
		},
		eventTicketsDetails: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 4,
					errorMessage: 'You must enter event ticket details.'
				}
			],
			isRequired: false
		},
		eventDate: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 3,
					errorMessage: 'You must select an event date.'
				},
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 4,
					errorMessage: 'You must select an event date.'
				}
			],
			isRequired: false
		},
		clinicDetails: {
			value: 0,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 1,
					errorMessage: 'You must select a clinic detail.'
				}
			],
			isRequired: false
		},
		clinicDetailsOther: {
			value: 0,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'clinicDetails',
					value: 5,
					errorMessage: 'Other description is required for clinic.'
				}
			],
			isRequired: false
		},
		competitionDrawDate: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 2,
					errorMessage: 'You must select a clinic detail.'
				}
			],
			isRequired: false
		},
		competitionPrizeDetails: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 2,
					errorMessage: 'You must select a clinic detail.'
				}
			],
			isRequired: false
		},
		winnersEligibilityConfirmedBy: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'memberBenefits',
					value: 2,
					errorMessage: 'You must select a clinic detail.'
				}
			],
			isRequired: false
		},
		comment: {
			value: null,
			conditions: [],
			isRequired: false
		}
	});

	const { addToast } = useToast();

	const generatePayload = () => {
		return {
			memberId: memberData.id,
			memberBenefits: values.memberBenefits.value || null,
			memberBenefitsOther: values.memberBenefitsOther.value || null,
			memberMerchandise: values.memberMerchandise.value || null,
			memberMerchandiseOther: values.memberMerchandiseOther.value || null,
			memberMerchandiseDetails: values.memberMerchandiseDetails.value || null,
			memberMerchandiseRugSize: values.memberMerchandiseRugSize.value || null,
			eventTicketsDetails: values.eventTicketsDetails.value || null,
			eventDate: values.eventDate.value
				? formatDate(values.eventDate.value)
				: null,
			clinicDetails: values.clinicDetails.value || null,
			clinicDetailsOther: values.clinicDetailsOther.value || null,
			competitionDrawDate: values.competitionDrawDate.value
				? formatDate(values.competitionDrawDate.value)
				: null,
			competitionPrizeDetails: values.competitionPrizeDetails.value || null,
			winnersEligibilityConfirmedBy:
				values.winnersEligibilityConfirmedBy.value || null,
			comment: values.comment.value || null,
			adminExternalId: adminExternalId || null
		};
	};

	const clearForm = () => {
		setValues({
			memberId: {
				value: memberData.id,
				isRequired: false
			},
			memberBenefits: {
				value: 0,
				isRequired: true,
				conditions: [],
				errorMessage: 'You must select a type of member benefits.'
			},
			memberBenefitsOther: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 6,
						errorMessage: 'Other description is required for member benefits.'
					}
				],
				isRequired: false
			},
			memberMerchandise: {
				value: 0,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 5,
						errorMessage: 'You must select a type of member merchandise.'
					}
				],
				isRequired: false
			},
			memberMerchandiseOther: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberMerchandise',
						value: 8,
						errorMessage: 'Other description is required for merchandise.'
					}
				],
				isRequired: false
			},
			memberMerchandiseDetails: {
				value: null,
				conditions: [],
				isRequired: false
			},
			memberMerchandiseRugSize: {
				value: 0,
				conditions: [],
				isRequired: false
			},
			eventTicketsDetails: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 4,
						errorMessage: 'You must enter event ticket details.'
					}
				],
				isRequired: false
			},
			eventDate: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 3,
						errorMessage: 'You must select an event date.'
					},
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 4,
						errorMessage: 'You must select an event date.'
					}
				],
				isRequired: false
			},
			clinicDetails: {
				value: 0,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 1,
						errorMessage: 'You must select a clinic detail.'
					}
				],
				isRequired: false
			},
			clinicDetailsOther: {
				value: 0,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'clinicDetails',
						value: 5,
						errorMessage: 'Other description is required for clinic.'
					}
				],
				isRequired: false
			},
			competitionDrawDate: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 2,
						errorMessage: 'You must select a clinic detail.'
					}
				],
				isRequired: false
			},
			competitionPrizeDetails: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 2,
						errorMessage: 'You must select a clinic detail.'
					}
				],
				isRequired: false
			},
			winnersEligibilityConfirmedBy: {
				value: null,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'memberBenefits',
						value: 2,
						errorMessage: 'You must select a clinic detail.'
					}
				],
				isRequired: false
			},
			comment: {
				value: null,
				conditions: [],
				isRequired: false
			}
		});
	};

	useEffect(() => {
		AppSyncService.execute(getMemberBenefitsAndMerchandiseOptionsData).then(
			(data) => {
				if (data?.data?.listMemberBenefitsTypes) {
					setBenefitsTypeOptions(
						[BLANK_OPTION].concat(data.data.listMemberBenefitsTypes)
					);
				}
				if (data?.data?.listMerchandiseTypes) {
					setMerchandiseTypeOptions(
						[BLANK_OPTION].concat(data.data.listMerchandiseTypes)
					);
				}
				if (data?.data?.listMerchandiseRugSizeTypes) {
					setMerchandiseRugSizeTypeOptions(
						[BLANK_OPTION].concat(data.data.listMerchandiseRugSizeTypes)
					);
				}
				if (data?.data?.listClinicDetailsTypes) {
					setClinicDetailsTypeOptions(
						[BLANK_OPTION].concat(data.data.listClinicDetailsTypes)
					);
				}
			}
		);
	}, []);

	useEffect(() => {
		setErrors(validation(values, validationOn, validationFields));
	}, [values]);

	const handleSave = async () => {
		setIsLoading(true);
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		setSubmission(false);
		const payload = generatePayload();
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			await AppSyncService.execute(saveMemberBenefitsAndMerchandiseForm, {
				input: payload
			}).then((data) => {
				if (data?.data?.saveMemberBenefitsAndMerchandiseForm?.id) {
					let items = { ...values };
					let item = {
						...items['id'],
						value: data.data.saveMemberBenefitsAndMerchandiseForm.id
					};
					items['id'] = item;
					setValues(items);
					setValidationOn(false);
					clearForm();
					setInitialLoad(true);
					setIsAccordionOpen(true);
					loadMemberData().finally(() => {
						setIsLoading(false);
					});
					addToast({
						Message: 'Member benefits and merchandise form saved.',
						IsSuccess: true
					});
				} else {
					setIsLoading(false);
					addToast({
						Message: 'Save form failed to submit.',
						IsSuccess: false
					});
					setSubmission(false);
				}
			});
		} else {
			setIsLoading(false);
			addToast({
				Message: 'Save form failed to submit.',
				IsSuccess: false
			});
		}
	};

	const handleCancel = async (e) => {
		e.preventDefault();
		addToast({ Message: 'Save cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigate(DASHBOARD_ROUTE);
	};

	const handleChange = (e, field) => {
		setValidationOn(false);
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		setValidationOn(false);
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;

		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setMemberBenefitType(items['memberBenefits'].value);
		setMerchandiseType(items['memberMerchandise'].value);
		setClinicDetails(items['clinicDetails'].value);

		setErrors(validation(values, validationOn, validationFields));
	};

	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};

	return (
		<Fragment>
			<StyledContainer>
				<StyledFormFieldsContainer>
					<Row>
						<Col xs={12} lg={4}>
							<StyledFieldContainer>
								<StyledFieldLabel>Type of member benefit*</StyledFieldLabel>
								<FormDropDown
									name="memberBenefits"
									items={benefitsTypeOptions.map((item) => {
										return {
											id: item.id,
											value: item.value
										};
									})}
									selectedId={values.memberBenefits.value}
									onChange={(e) =>
										handleChangeDropDown(e, [
											{ item: 'memberBenefitsOther', value: '' },
											{ item: 'memberMerchandise', value: null },
											{ item: 'memberMerchandiseOther', value: '' },
											{ item: 'memberMerchandiseDetails', value: '' },
											{ item: 'memberMerchandiseRugSize', value: null },
											{ item: 'eventTicketsDetails', value: '' },
											{ item: 'eventDate', value: null },
											{ item: 'clinicDetails', value: null },
											{ item: 'clinicDetailsOther', value: '' },
											{ item: 'competitionDrawDate', value: null },
											{ item: 'competitionPrizeDetails', value: '' },
											{ item: 'winnersEligibilityConfirmedBy', value: '' },
											{ item: 'comment', value: '' }
										])
									}
									showBlank
									isError={errors.memberBenefits}
								/>
								<StyledErrorText>{errors.memberBenefits}</StyledErrorText>
							</StyledFieldContainer>
						</Col>
						{memberBenefitType === 6 && (
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>
										Type of member benefit (Other)*
									</StyledFieldLabel>
									<StyledFieldLabel></StyledFieldLabel>
									<FormTextField
										name="memberBenefitsOther"
										onChange={(e) => handleChange(e, 'memberBenefitsOther')}
										value={values.memberBenefitsOther.value}
									/>
									<StyledErrorText>
										{errors.memberBenefitsOther}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
						)}
					</Row>
					{memberBenefitType === 5 && (
						<Row>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Merchandise type*</StyledFieldLabel>
									<FormDropDown
										name="memberMerchandise"
										items={merchandiseTypeOptions.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										selectedId={values.memberMerchandise.value}
									/>
									<StyledErrorText>{errors.memberMerchandise}</StyledErrorText>
								</StyledFieldContainer>
							</Col>
							{merchandiseType === 8 && (
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											Merchandise type (Other)*
										</StyledFieldLabel>
										<StyledFieldLabel></StyledFieldLabel>
										<FormTextField
											name="memberMerchandiseOther"
											onChange={(e) =>
												handleChange(e, 'memberMerchandiseOther')
											}
											value={values.memberMerchandiseOther.value}
										/>
										<StyledErrorText>
											{errors.memberMerchandiseOther}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							)}

							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Merchandise details</StyledFieldLabel>
									<StyledFieldLabel></StyledFieldLabel>
									<ResizingTextArea
										name="memberMerchandiseDetails"
										onChange={(e) =>
											handleChange(e, 'memberMerchandiseDetails')
										}
										value={values.memberMerchandiseDetails.value}
										maxChars={300}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										charLengthStyle={{ display: 'none' }}
									/>
									<StyledErrorText>
										{errors.memberMerchandiseDetails}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Merchandise rug size</StyledFieldLabel>
									<FormDropDown
										name="memberMerchandiseRugSize"
										items={merchandiseRugSizeTypeOptions.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										selectedId={values.memberMerchandiseRugSize.value}
									/>
									<StyledErrorText>
										{errors.memberMerchandiseRugSize}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
						</Row>
					)}
					{memberBenefitType === 4 && (
						<Row>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Event ticket details*</StyledFieldLabel>
									<StyledFieldLabel></StyledFieldLabel>
									<ResizingTextArea
										name="eventTicketsDetails"
										onChange={(e) => handleChange(e, 'eventTicketsDetails')}
										value={values.eventTicketsDetails.value}
										maxChars={300}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										charLengthStyle={{ display: 'none' }}
									/>
									<StyledErrorText>
										{errors.eventTicketsDetails}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>

							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Event Date*</StyledFieldLabel>
									<FormDatePicker
										name="eventDate"
										DateValue={values.eventDate.value}
										noMaxDate
										updateSelection={(value) =>
											handleDateUpdate(value, 'eventDate')
										}
									/>
									<StyledErrorText>{errors.eventDate}</StyledErrorText>
								</StyledFieldContainer>
							</Col>
						</Row>
					)}
					{memberBenefitType === 3 && (
						<Row>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Event Date*</StyledFieldLabel>
									<FormDatePicker
										name="eventDate"
										DateValue={values.eventDate.value}
										noMaxDate
										updateSelection={(value) =>
											handleDateUpdate(value, 'eventDate')
										}
									/>
									<StyledErrorText>{errors.eventDate}</StyledErrorText>
								</StyledFieldContainer>
							</Col>
						</Row>
					)}
					{memberBenefitType === 1 && (
						<Row>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Clinic details*</StyledFieldLabel>
									<FormDropDown
										name="clinicDetails"
										items={clinicDetailsTypeOptions.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.clinicDetails}
										selectedId={values.clinicDetails.value}
									/>
									<StyledErrorText>{errors.clinicDetails}</StyledErrorText>
								</StyledFieldContainer>
							</Col>
							{clinicDetails === 5 && (
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Clinic details other*</StyledFieldLabel>
										<StyledFieldLabel></StyledFieldLabel>
										<FormTextField
											name="clinicDetailsOther"
											onChange={(e) => handleChange(e, 'clinicDetailsOther')}
											value={values.clinicDetailsOther.value}
										/>
										<StyledErrorText>
											{errors.clinicDetailsOther}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							)}
						</Row>
					)}
					{memberBenefitType === 2 && (
						<Row>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>Competition draw date*</StyledFieldLabel>
									<FormDatePicker
										name="competitionDrawDate"
										DateValue={values.competitionDrawDate.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'competitionDrawDate')
										}
									/>
									<StyledErrorText>
										{errors.competitionDrawDate}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>
										Competition prize details*
									</StyledFieldLabel>
									<StyledFieldLabel></StyledFieldLabel>
									<ResizingTextArea
										name="competitionPrizeDetails"
										onChange={(e) => handleChange(e, 'competitionPrizeDetails')}
										value={values.competitionPrizeDetails.value}
										maxChars={300}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										charLengthStyle={{ display: 'none' }}
									/>
									<StyledErrorText>
										{errors.competitionPrizeDetails}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
							<Col xs={12} lg={4}>
								<StyledFieldContainer>
									<StyledFieldLabel>
										Winners eligibility confirmed by*
									</StyledFieldLabel>
									<ResizingTextArea
										name="winnersEligibilityConfirmedBy"
										onChange={(e) =>
											handleChange(e, 'winnersEligibilityConfirmedBy')
										}
										value={values.winnersEligibilityConfirmedBy.value}
										maxChars={300}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										charLengthStyle={{ display: 'none' }}
									/>
									<StyledErrorText>
										{errors.winnersEligibilityConfirmedBy}
									</StyledErrorText>
								</StyledFieldContainer>
							</Col>
						</Row>
					)}
					<Row>
						<Col xs={12} lg={4}>
							<StyledFieldContainer>
								<StyledFieldLabel>Comment</StyledFieldLabel>
								<FormTextField
									name="comment"
									onChange={(e) => handleChange(e, 'comment')}
									value={values.comment.value}
									canEdit={true}
								/>
								<StyledErrorText>{errors.comment}</StyledErrorText>
							</StyledFieldContainer>
						</Col>
					</Row>
				</StyledFormFieldsContainer>
				<StyledFormEndDivider />
				<StyledFormActionsRow>
					<Col xs={3} lg={7}>
						<StyledCancelRequest
							onClick={() => setConfirmationModal(true)}
							isVisible={true}
						>
							Cancel
						</StyledCancelRequest>
					</Col>
					<StyledSaveDraft xs={2} lg={2.5}>
						<PrimaryButton
							onClick={handleSave}
							className={
								submission
									? classes.disabledCompleteFormButton
									: classes.completeFormButton
							}
							id={`${PAGE_ID}-submit`}
							disabled={submission ? true : false}
						>
							{submission ? 'Saving' : 'Save'}
						</PrimaryButton>
					</StyledSaveDraft>
				</StyledFormActionsRow>
				<ConfirmationModal
					showModal={confirmationModal}
					closeModal={() => setConfirmationModal(false)}
					styles={modalStylesConfirmation}
					heading="Cancel Member Benefits and Merchandise Form"
					positiveLabel="Confirm"
					negativeLabel="Cancel"
					onClickPositive={handleCancel}
				>
					Are you sure you wish to delete this form?
				</ConfirmationModal>
			</StyledContainer>
		</Fragment>
	);
};

export default CommunityMemberBenefitsAndMerchandiseForm;

import React, { useState, useEffect } from 'react';
import { Container, ScreenClassRender } from 'react-grid-system';
import classnames from 'classnames';
import BusinessProfile from '../BusinessProfile';
import PersonalProfile from '../PersonalProfile';
import SEO from '../seo';
import useStyles from './styles';
import { useAuth } from '../../hooks/useAuth';
import {
	ProfileKeys,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_FOSTER_CARER
} from '../../utils/constants';

const Profile = () => {
	const classes = useStyles();
	const [profileName, setProfileName] = useState('Personal Profile');
	const [isBusinessUser, setIsBusinessUser] = useState(false);
	const { currentUser } = useAuth();

	useEffect(() => {
		setIsBusinessUser(
			currentUser?.Roles.some(
				(role) =>
					role === ROLE_ACKNOWLEDGED_RETIREMENT_FARM ||
					role === ROLE_RV_ACKNOWLEDGED_RETRAINER ||
					role === ROLE_ACKNOWLEDGED_FOSTER_CARER
			)
		);
	}, []);

	const personalProfileClicked = () => {
		setProfileName('Personal Profile');
	};
	const businessProfileClicked = () => {
		setProfileName('Business Profile');
	};

	const desktopComponent = (
		<Container className={classes.profilePageContainer}>
			<SEO title="My Account" />
			<div className={classes.navigationLinks}>
				<span
					id={ProfileKeys.PERSONAL_PROFILE}
					onClick={personalProfileClicked}
					className={classnames({
						[classes.siteHeaderMenuItem]: true,
						[classes.siteHeaderMenuItemSelected]:
							profileName === 'Personal Profile' ? true : false
					})}
				>
					Personal Profile
				</span>
				{isBusinessUser && (
					<span
						id={ProfileKeys.BUSINESS_PROFILE}
						onClick={businessProfileClicked}
						className={classnames({
							[classes.siteHeaderMenuItem]: true,
							[classes.siteHeaderMenuItemSelected]:
								profileName === 'Business Profile' ? true : false
						})}
					>
						Business Profile
					</span>
				)}
			</div>
			<>
				{profileName == 'Personal Profile' && <PersonalProfile />}
				{profileName == 'Business Profile' && <BusinessProfile />}
			</>
		</Container>
	);

	//const mobileComponent = <div>Coming soon</div>;

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default Profile;

import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import { Col, Row } from 'react-grid-system';
import HeaderBanner from '../HeaderBanner';
import { logToServer } from '../../utils/logger';
import {
	getUserImage,
	uploadImageToS3,
	saveMemberNoteAttachment,
	deleteImageFromS3,
	deleteMemberNoteAttachment,
	saveMemberNote
} from '../../graphql/custom.queries';
import ResizingTextArea from '../ResizingTextArea';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import PrimaryButton from '../PrimaryButton';
import { WORD_DOC_MIME_TYPE } from '../../utils/constants';

const MemberAdminNotes = ({
	memberData,
	adminExternalId,
	onUploadError,
	setImgSrcUrl,
	setImageSrcPdf,
	mobile
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [note, setNote] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [notes, setNotes] = useState([]);

	useEffect(() => {
		setAttachments(memberData.unattendedAttachments);
		setNotes(memberData.notes);
	}, [memberData]);

	const handleAttachmentUpload = (attachment) => {
		{
			const headers = {
				'Content-Type': attachment.type
			};
			const attachmentKey = { imageKey: attachment.name };
			AppSyncService.execute(uploadImageToS3, attachmentKey).then((data) => {
				if (data.data != null) {
					let attachmentKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, attachment, {
							headers: headers
						})
						.then(() => {
							AppSyncService.execute(saveMemberNoteAttachment, {
								filename: attachment.name,
								key: attachmentKey,
								adminExternalId: adminExternalId,
								memberId: memberData.id
							}).then((uploadResponse) => {
								if (uploadResponse.errors && uploadResponse.errors.length > 0) {
									logToServer(
										adminExternalId,
										uploadResponse,
										`Failed to upload attachment ${attachment.name} due to backend error.`
									);
									addToast({
										Message: 'Failed to upload attachment',
										IsSuccess: false
									});
								} else {
									setAttachments((attachments) => [
										...attachments,
										{ filename: attachment.name, key: attachmentKey }
									]);
									addToast({ Message: 'Attachment uploaded', IsSuccess: true });
								}
							});
						});
				}
			});
		}
	};

	const removeAttachment = (attachment) => {
		AppSyncService.execute(deleteImageFromS3, {
			imageKey: attachment.key
		}).then((data) => {
			if (data?.data?.deleteUserImage?.success) {
				AppSyncService.execute(deleteMemberNoteAttachment, {
					key: attachment.key
				}).then((data) => {
					if (data.data.deleteMemberNoteAttachment) {
						setAttachments(
							attachments.filter((item) => item.key !== attachment.key)
						);
						addToast({ Message: 'Attachment removed', IsSuccess: true });
					}
				});
			}
		});
	};

	const getAttachment = (attachment) => {
		const imageKey = { imageKey: attachment.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			if (attachment.key.endsWith('.pdf') || attachment.key.endsWith('.doc')) {
				setImageSrcPdf(data.data.getUserImage.signedUrl);
			} else {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			}
		});
	};

	const saveNote = () => {
		let newNote = note;
		if (newNote.length === 0) {
			newNote = '--None--';
		}
		AppSyncService.execute(saveMemberNote, {
			note: newNote,
			adminExternalId: adminExternalId,
			memberId: memberData.id
		}).then((response) => {
			if (response.errors && response.errors.length > 0) {
				logToServer(
					adminExternalId,
					response,
					`Failed save note due to backend error.`
				);
				addToast({ Message: 'Failed to save attachment', IsSuccess: false });
			} else {
				setAttachments([]);
				setNote('');
				setNotes((notes) => [response.data.saveMemberNote, ...notes]);
				addToast({ Message: 'Note added', IsSuccess: true });
			}
		});
	};

	const renderAttachments = (data) => {
		if (data && data.length > 0) {
			return data.map((img, index) => {
				return (
					<span
						key={`attachment-${img.filename}-${index}`}
						className={classes.linkedAttachments}
						onClick={() => getAttachment(img)}
					>
						{img.filename}
					</span>
				);
			});
		}
	};

	const renderNotes = () => {
		if (notes && notes.length > 0) {
			return notes.map((data) => {
				return (
					<div key={`note-${data.id}`} className={classes.noteRow}>
						{data.note} {renderAttachments(data.attachments)} - {data.created} -{' '}
						{data.by?.firstName} {data.by?.lastName}
					</div>
				);
			});
		}
	};

	return (
		<>
			<HeaderBanner
				title="Admin Notes"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderBottom: '1px solid #b3bcc1'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.uploadContainer}>
							<div className={classes.fieldTitle}>
								Attachments (jpeg, png, pdf, docx)
							</div>
							<UploadPhoto
								id={`member-memberAdminNotes-attachments`}
								formats={['png', 'jpeg', 'pdf', WORD_DOC_MIME_TYPE]}
								labelName="Attach"
								error={onUploadError}
								data={attachments}
								sizeLimitMB={5}
								numberOfImages={10}
								success={(attachment) => handleAttachmentUpload(attachment)}
								getImage={getAttachment}
								removeImage={removeAttachment}
								canEdit={true}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.noteContainer}>
							<ResizingTextArea
								title="Note"
								id={`member-memberAdminNotes-note`}
								onChange={(e) => setNote(e.target.value)}
								maxChars={500}
								value={note}
								canEdit={true}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.saveNote}>
							<PrimaryButton
								id={`member-memberAdminNotes-save-button`}
								isNonSubmit={false}
								style={{ minWidth: '170px' }}
								onClick={saveNote}
								disabled={attachments.length === 0 && note.length === 0}
							>
								Save
							</PrimaryButton>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>{renderNotes()}</div>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default MemberAdminNotes;

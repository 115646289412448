import React, { useState } from 'react';
import useStyles from './styles';
import DropdownDown from '../../assets/icons/Dropdown_Down_icon.svg';
import DropdownUp from '../../assets/icons/Dropdown_Up_icon.svg';

const Accordion = ({
	children,
	id,
	title,
	styles,
	isOpen,
	mobile,
	titleStyle,
	dropDownStyle
}) => {
	const [listOpen, setListOpen] = useState(isOpen ? true : false);
	const classes = useStyles();

	const renderAccordion = () => {
		if (mobile) {
			return (
				<>
					<div
						className={classes.listToggleContainerMobile}
						onClick={() => setListOpen(!listOpen)}
						style={styles}
					>
						<div className={classes.listTitleMobile} style={titleStyle}>
							{title}
						</div>
						<div className={classes.dropDownMobile} style={dropDownStyle}>
							{!listOpen && <img src={DropdownDown} />}{' '}
							{listOpen && <img src={DropdownUp} />}
						</div>
					</div>
					{listOpen && <div>{children}</div>}
				</>
			);
		} else {
			return (
				<>
					<div
						id={id}
						className={classes.listToggleContainer}
						onClick={() => setListOpen(!listOpen)}
						style={styles}
					>
						<div className={classes.listTitle} style={titleStyle}>
							{title}
						</div>
						<div className={classes.dropDown} style={dropDownStyle}>
							{!listOpen && <img src={DropdownDown} />}{' '}
							{listOpen && <img src={DropdownUp} />}
						</div>
					</div>
					{listOpen && <div>{children}</div>}
				</>
			);
		}
	};

	return renderAccordion();
};

export default Accordion;

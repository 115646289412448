import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import SEO from '../seo';
import { Container, Row, Col } from 'react-grid-system';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import useStyles from './styles';

const ImNew = () => {
	const classes = useStyles();
	const [richText, setRichText] = useState(null);
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const contentfulImNewContent = useStaticQuery(
		graphql`
			query ImNewQuery {
				allContentfulImNewContent(sort: { fields: updatedAt, order: DESC }) {
					edges {
						node {
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		setRichText(
			contentfulImNewContent.allContentfulImNewContent.edges[0].node.body
		);
	});

	return (
		<>
			<div>
				<SEO title="I'm New" />
				<Container className={classes.container}>
					<Row>
						<Col md={12} lg={8} className={classes.col}>
							{richText && renderRichText(richText, options)}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default ImNew;

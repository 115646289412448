import { createUseStyles } from 'react-jss';

export default createUseStyles({
	cropperContainer: {
		height: '70%',
		position: 'relative',
		width: '100%',
		background: '#333',
		borderRadius: 2,
		overflow: 'hidden'
	},
	imageControlsContainer: {
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		'@media (min-width: 992px)': {
			flexDirection: 'row',
			alignItems: 'center',
			width: 300
		}
	},
	zoomControlLabel: {
		marginRight: 15,
		fontFamily: 'arial',
		color: '#464749',
		fontSize: 13,
		fontWeight: 600
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: 20
	},
	cancelButton: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer',
		background: 'none',
		border: 'none'
	}
});

import React, { useRef, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';

const FilePreviewer = ({ file, onClose: clearPreviewState }) => {
	const fileFormat = file.format?.toLowerCase();
	const filePreviewLink = useRef(null);
	useEffect(() => {
		if (fileFormat === 'pdf' || fileFormat === 'docx') {
			filePreviewLink.current.click();
			clearPreviewState();
		}
	}, [file]);

	let PreviewComponent = '';
	switch (fileFormat) {
		case 'pdf':
			PreviewComponent = (
				<a
					style={{ visibility: 'hidden', cursor: 'pointer' }}
					href={file.signedUrl}
					ref={filePreviewLink}
					target="_blank"
					rel="noreferrer"
				>
					Pdf Link
				</a>
			);
			break;
		case 'docx':
			PreviewComponent = (
				<a
					style={{ visibility: 'hidden', cursor: 'pointer' }}
					href={file.signedUrl}
					ref={filePreviewLink}
					download
				>
					Word Link
				</a>
			);
			break;
		default:
			PreviewComponent = (
				<Lightbox mainSrc={file.signedUrl} onCloseRequest={clearPreviewState} />
			);
			break;
	}
	return PreviewComponent;
};

export default FilePreviewer;

import React, { useEffect, useState } from 'react';
import { useParams, navigate } from '@reach/router';
import PageContainer from '../PageContainer';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import { Row, Col } from 'react-grid-system';
import SimpleRadioGroup from '../SimpleRadioGroup';
import FormSectionContentDivider from '../FormComponents/FormSectionContentDivider';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getRESETConcludedOptions,
	saveConcludeResetForm,
	getHorseClaimers,
	unclaimHorse,
	saveDeceasedThoroughbredForm
} from '../../graphql/custom.queries';
import {
	checkDropDownValue,
	extractLookUpOptionsList,
	isOptionOther,
	formatDate,
	validatePostCode
} from '../../utils/helpers';
import { BLANK_OPTION } from '../../utils/constants';
import Toggle from '../Toggle';
import OptionBar from '../OptionBar';
import FormFooter from '../FormComponents/FormFooter';
import FormCancelConfirmModal from '../FormComponents/FormCancelConfirmModal';
import { useToast } from '../../hooks/useToast';
import { INITIAL_FORM_STATE, RESET_CONCLUDED_OPTIONS } from './constants';
import DateInputField from '../FormComponents/DateInputField';
import TextInputField from '../FormComponents/TextInputField';
import SelectInputField from '../FormComponents/SelectInputField';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import ToggleWithMultiSelect from '../FormComponents/ToggleWithMultiSelect';
import { ResetConcludeFormKeys } from './../../utils/constants/index';

const RESETConcludedForm = () => {
	const { horseCode } = useParams();
	const { addToast } = useToast();
	const [formState, setFormState] = useState(INITIAL_FORM_STATE);
	const [euthanisedReasonOptions, setEuthanisedReasonOptions] = useState([]);
	const [injuredReasonsOptions, setInjuredReasonsOptions] = useState([]);
	const [rehomingPurposeOptions, setRehomingPurposeOptions] = useState([]);
	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [returnedReasonOptions, setReturnedReasonOptions] = useState([]);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [currentRetrainer, setCurrentRetrainer] = useState(null);

	useEffect(() => {
		AppSyncService.execute(getRESETConcludedOptions).then((result) => {
			extractLookUpOptionsList(
				result,
				'listInjuryDetails',
				setInjuredReasonsOptions
			);
			extractLookUpOptionsList(
				result,
				'listSpecialtyDisciplines',
				setDisciplineOptions
			);
			extractLookUpOptionsList(
				result,
				'listHorseRehomePurposes',
				setRehomingPurposeOptions
			);
			extractLookUpOptionsList(
				result,
				'listShareInfoEuthanisedIllness',
				setIllnessOptions
			);
			extractLookUpOptionsList(
				result,
				'listResetConcludedEuthanisedReasons',
				setEuthanisedReasonOptions
			);
			extractLookUpOptionsList(
				result,
				'listResetConcludedReturnedReasons',
				setReturnedReasonOptions
			);
		});
	}, []);

	useEffect(() => {
		AppSyncService.execute(getHorseClaimers, { horseCode: +horseCode }).then(
			(result) => {
				if (result?.data?.getHorseProfile?.claims?.length) {
					setCurrentRetrainer(
						result.data.getHorseProfile.claims.filter(
							({ ownershipType }) => ownershipType === 'Retrainer'
						)[0]
					);
				}
			}
		);
	}, []);

	const isEuthanisedReasonInjury = checkDropDownValue(
		euthanisedReasonOptions,
		formState.euthanisedReason.value,
		'Injury'
	);

	const isReturnedReasonInjury = checkDropDownValue(
		returnedReasonOptions,
		formState.returnedReason.value,
		'Injury'
	);

	const isEuthanisedReasonOther = isOptionOther(
		euthanisedReasonOptions,
		formState.euthanisedReason.value
	);

	const isRehomingPurposeOther = isOptionOther(
		rehomingPurposeOptions,
		formState.rehomedPurpose.value
	);

	const isReturnedReasonOther = isOptionOther(
		returnedReasonOptions,
		formState.returnedReason.value
	);

	const isValidationErrorsPresent = Object.values(formState).find(
		({ validationError }) => validationError
	);
	const EuthanisedInputComponents = (
		<>
			<Row style={{ marginTop: 15 }}>
				<DateInputField
					id={ResetConcludeFormKeys.DATE_EUTHANISED}
					label="Date Euthanised"
					isRequired
					value={formState.dateEuthanised.value}
					onChange={(newDateValue) => {
						const newFormState = {
							...formState,
							dateEuthanised: {
								value: newDateValue ? formatDate(newDateValue) : newDateValue,
								validationError: ''
							}
						};
						setFormState(newFormState);
					}}
					validationError={formState.dateEuthanised.validationError}
				/>
				<SelectInputField
					id={ResetConcludeFormKeys.EUTHANISED_REASON}
					label={'Reason'}
					options={[BLANK_OPTION, ...euthanisedReasonOptions]}
					value={formState.euthanisedReason.value}
					onChange={(event) => {
						const newFormState = {
							...formState,
							euthanisedReason: {
								value: event.target.value,
								validationError: ''
							},
							...(!isEuthanisedReasonOther && {
								euthanisedOtherReason: { value: '', validationError: '' }
							}),
							...(!isEuthanisedReasonInjury && {
								euthanisedInjuries: { value: [], validationError: '' },
								euthanisedOtherInjuries: { value: [], validationError: '' }
							})
						};
						setFormState(newFormState);
					}}
					validationError={formState.euthanisedReason.validationError}
					isRequired
				/>
			</Row>
			{isEuthanisedReasonOther && (
				<Row style={{ marginTop: 15 }}>
					<TextInputField
						id={ResetConcludeFormKeys.OTHER_EUTHANISED_REASON}
						label={'Other reason'}
						value={formState.euthanisedOtherReason.value}
						onChange={(event) => {
							const newFormState = {
								...formState,
								euthanisedOtherReason: {
									value: event.target.value,
									validationError: ''
								}
							};
							setFormState(newFormState);
						}}
						fullWidth
					/>
				</Row>
			)}
			{isEuthanisedReasonInjury && (
				<MultiSelectInputField
					idPrefix={ResetConcludeFormKeys.EUTHANISED_INJURY}
					isRequired
					fieldLabel={'Injury types'}
					selectedIds={formState.euthanisedInjuries.value}
					options={injuredReasonsOptions}
					onSelectChange={(newInjuriesValue) => {
						const newFormState = {
							...formState,
							euthanisedInjuries: {
								value: newInjuriesValue,
								validationError: ''
							},
							...(!newInjuriesValue ||
								(newInjuriesValue.find(({ label }) => label === 'Other') && {
									euthanisedOtherInjuries: { value: '' }
								}))
						};
						setFormState(newFormState);
					}}
					otherValue={formState.euthanisedOtherInjuries.value}
					onOtherChange={(newOtherValue) => {
						const newFormState = {
							...formState,
							euthanisedOtherInjuries: { value: newOtherValue }
						};
						setFormState(newFormState);
					}}
					validationError={formState.euthanisedInjuries.validationError}
				/>
			)}
		</>
	);

	const RehomedInputComponents = (
		<>
			<Row style={{ marginTop: 15 }}>
				<DateInputField
					id={ResetConcludeFormKeys.DATE_SOLD_REHOMED}
					label="Date sold/rehomed"
					isRequired
					value={formState.dateRehomed.value}
					onChange={(newDateValue) => {
						const newFormState = {
							...formState,
							dateRehomed: {
								value: newDateValue ? formatDate(newDateValue) : newDateValue,
								validationError: ''
							}
						};
						setFormState(newFormState);
					}}
					validationError={formState.dateRehomed.validationError}
				/>
				<TextInputField
					id={ResetConcludeFormKeys.PRICE}
					label="Price"
					isRequired
					value={formState.soldPrice.value}
					onChange={(e) => {
						const newFormState = {
							...formState,
							soldPrice: { value: e.target.value, validationError: '' }
						};
						setFormState(newFormState);
					}}
					validationError={formState.soldPrice.validationError}
				/>
				<SelectInputField
					id={ResetConcludeFormKeys.REHOMING_PURPOSE}
					label="Purpose"
					isRequired
					value={formState.rehomedPurpose.value}
					options={[BLANK_OPTION, ...rehomingPurposeOptions]}
					onChange={(event) => {
						const newFormState = {
							...formState,
							rehomedPurpose: {
								value: event.target.value,
								validationError: ''
							},
							...(!isRehomingPurposeOther && {
								otherRehomingPurpose: { value: '' }
							})
						};
						setFormState(newFormState);
					}}
					validationError={formState.rehomedPurpose.validationError}
				/>
			</Row>
			{isRehomingPurposeOther && (
				<Row style={{ marginTop: 15 }}>
					<TextInputField
						id={ResetConcludeFormKeys.OTHER_REHOMING_PURPOSE}
						label={'Other purpose'}
						value={formState.otherRehomingPurpose.value}
						onChange={(event) => {
							const newFormState = {
								...formState,
								otherRehomingPurpose: {
									value: event.target.value
								}
							};
							setFormState(newFormState);
						}}
						fullWidth
					/>
				</Row>
			)}
			<MultiSelectInputField
				idPrefix={ResetConcludeFormKeys.DISCIPLINES}
				isRequired
				fieldLabel="Disciplines"
				selectedIds={formState.selectedDisciplines.value}
				options={disciplineOptions}
				onSelectChange={(newDisciplinesValue) => {
					const newFormState = {
						...formState,
						selectedDisciplines: {
							value: newDisciplinesValue,
							validationError: ''
						},
						...((!newDisciplinesValue ||
							!newDisciplinesValue.find(({ label }) => label === 'Other')) && {
							otherDisciplines: {
								value: ''
							}
						})
					};
					setFormState(newFormState);
				}}
				otherValue={formState.otherDisciplines.value}
				onOtherChange={(newOtherValue) => {
					const newFormState = {
						...formState,
						otherDisciplines: { value: newOtherValue }
					};
					setFormState(newFormState);
				}}
				validationError={formState.selectedDisciplines.validationError}
			/>
			<Row style={{ marginTop: 15 }}>
				<TextInputField
					id={ResetConcludeFormKeys.POSTCODE}
					label="Postcode"
					isRequired
					value={formState.rehomedPostcode.value}
					onChange={(e) => {
						const isValidPostcode = validatePostCode(e.target.value);
						if (isValidPostcode) {
							const newFormState = {
								...formState,
								rehomedPostcode: { value: e.target.value, validationError: '' }
							};
							setFormState(newFormState);
						}
					}}
					validationError={formState.rehomedPostcode.validationError}
				/>
			</Row>
		</>
	);

	const ReturnedInputComponents = (
		<>
			<Row style={{ marginTop: 15 }}>
				<DateInputField
					id={ResetConcludeFormKeys.DATE_RETURNED}
					label="Date Returned"
					isRequired
					value={formState.dateReturned.value}
					onChange={(newDateValue) => {
						const newFormState = {
							...formState,
							dateReturned: {
								value: newDateValue ? formatDate(newDateValue) : newDateValue,
								validationError: ''
							}
						};
						setFormState(newFormState);
					}}
					validationError={formState.dateReturned.validationError}
				/>
				<SelectInputField
					id={ResetConcludeFormKeys.RETURNED_REASON}
					label="Reason"
					isRequired
					options={[BLANK_OPTION, ...returnedReasonOptions]}
					value={formState.returnedReason.value}
					onChange={(event) => {
						const newFormState = {
							...formState,
							returnedReason: {
								value: event.target.value,
								validationError: ''
							},
							...(!isReturnedReasonOther && {
								otherReturnedReason: { value: '' }
							}),
							...(!isReturnedReasonInjury && {
								returnedInjuries: { value: [], validationError: '' },
								otherReturnedInjuries: {
									value: '',
									validationError: ''
								}
							})
						};
						setFormState(newFormState);
					}}
					validationError={formState.returnedReason.validationError}
				/>
			</Row>
			{isReturnedReasonOther && (
				<Row style={{ marginTop: 15 }}>
					<TextInputField
						id={ResetConcludeFormKeys.OTHER_RETURNED_REASON}
						label={'Other reason'}
						value={formState.otherReturnedReason.value}
						onChange={(event) => {
							const newFormState = {
								...formState,
								otherReturnedReason: { value: event.target.value }
							};
							setFormState(newFormState);
						}}
						fullWidth
					/>
				</Row>
			)}
			{isReturnedReasonInjury && (
				<MultiSelectInputField
					idPrefix={ResetConcludeFormKeys.RETURNED_INJURY}
					isRequired
					fieldLabel="Injury types"
					selectedIds={formState.returnedInjuries.value}
					options={injuredReasonsOptions}
					onSelectChange={(newValue) => {
						const newFormState = {
							...formState,
							returnedInjuries: {
								value: newValue,
								validationError: ''
							},
							...(newValue &&
								!newValue.find(({ label }) => label === 'Other') && {
									otherReturnedInjuries: {
										value: ''
									}
								})
						};
						setFormState(newFormState);
					}}
					otherValue={formState.otherReturnedInjuries.value}
					onOtherChange={(newValue) => {
						const newFormState = {
							...formState,
							otherReturnedInjuries: { value: newValue }
						};
						setFormState(newFormState);
					}}
					validationError={formState.returnedInjuries.validationError}
				/>
			)}
		</>
	);

	const onCancel = (event) => {
		event.preventDefault();
		setShowCancelModal(true);
	};

	const generateSaveResetFormPayload = () => {
		return {
			horseCode: +horseCode,
			concludedReason: +formState.resetOption.value,
			...(formState.resetOption.value == 1 && {
				euthanisedDate: formState.dateEuthanised.value,
				euthanisedReason: +formState.euthanisedReason.value,
				...(formState.euthanisedOtherReason.value && {
					euthanisedOtherReason: formState.euthanisedOtherReason.value
				}),
				...(formState.euthanisedInjuries.value.length > 0 && {
					euthanisedInjuries: formState.euthanisedInjuries.value.map(
						({ value }) => value
					)
				}),
				...(formState.euthanisedOtherInjuries.value.length > 0 && {
					euthanisedOtherInjury: formState.euthanisedOtherInjuries.value
						.map(({ value }) => value)
						.toString()
				})
			}),
			...(formState.resetOption.value == 2 && {
				rehomedDate: formState.dateRehomed.value,
				soldPrice: formState.soldPrice.value, //free-text value stored in reset_concluded_form table
				rehomedPurpose: +formState.rehomedPurpose.value,
				...(formState.otherRehomingPurpose.value && {
					rehomedOtherPurpose: formState.otherRehomingPurpose.value
				}),
				rehomedDisciplines: formState.selectedDisciplines.value.map(
					({ value }) => value
				),
				...(formState.otherDisciplines.value && {
					rehomedDisciplineOther: formState.otherDisciplines.value
						.map(({ value }) => value)
						.toString()
				}),
				newOwnerPostcode: formState.rehomedPostcode.value
			}),
			...(formState.resetOption.value == 3 && {
				returnedDate: formState.dateReturned.value,
				returnedReason: +formState.returnedReason.value,
				...(formState.otherReturnedReason.value && {
					returnedOtherReason: formState.otherReturnedReason.value
				}),
				...(formState.returnedInjuries.value.length > 0 && {
					returnedInjuries: formState.returnedInjuries.value.map(
						({ value }) => value
					)
				}),
				...(formState.otherReturnedInjuries.value &&
					formState.otherReturnedInjuries.value.length > 0 && {
						returnedOtherInjury: formState.otherReturnedInjuries.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			injuriesPresent: formState.injuriesPresent.value,
			preNotedInjuriesPresent: formState.notedInjuriesPresent.value,
			...(formState.notedInjuriesPresent.value && {
				preNotedInjuries: formState.notedInjuries.value.map(
					({ value }) => value
				),
				...(formState.otherNotedInjuries.value &&
					formState.otherNotedInjuries.value.length > 0 && {
						preNotedOtherInjuries: formState.otherNotedInjuries.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			preNotNotedInjuriesPresent: formState.notNotedInjuriesPresent.value,
			...(formState.notNotedInjuriesPresent.value && {
				preNotNotedInjuries: formState.notNotedInjuries.value.map(
					({ value }) => value
				),
				...(formState.otherNotNotedInjuries.value &&
					formState.otherNotNotedInjuries.value.length > 0 && {
						preNotNotedOtherInjuries: formState.otherNotNotedInjuries.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			newInjuriesPresent: formState.newInjuriesPresent.value,
			...(formState.newInjuriesPresent.value && {
				newInjuries: formState.newInjuries.value.map(({ value }) => value),
				...(formState.otherNewInjuries.value &&
					formState.otherNewInjuries.value.length > 0 && {
						newOtherInjuries: formState.otherNewInjuries.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			illnessesPresent: formState.illnessesPresent.value,
			preNotedIllnessesPresent: formState.notedIllnessesPresent.value,
			...(formState.notedIllnessesPresent.value && {
				preNotedIllnesses: formState.notedIllnesses.value.map(
					({ value }) => value
				),
				...(formState.otherNotedIllnesses.value &&
					formState.otherNotedIllnesses.value.length > 0 && {
						preNotedOtherIllnesses: formState.otherNotedIllnesses.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			preNotNotedIllnessesPresent: formState.notNotedIllnessesPresent.value,
			...(formState.notNotedIllnessesPresent.value && {
				preNotNotedIllnesses: formState.notNotedIllnesses.value.map(
					({ value }) => value
				),
				...(formState.otherNotNotedIllnesses.value &&
					formState.otherNotNotedIllnesses.value.length > 0 && {
						preNotNotedOtherIllnesses: formState.otherNotNotedIllnesses.value
							.map(({ value }) => value)
							.toString()
					})
			}),
			newIllnessesPresent: formState.newIllnessesPresent.value,
			...(formState.newIllnessesPresent.value && {
				newIllnesses: formState.newIllnesses.value.map(({ value }) => value),
				...(formState.otherNewIllnesses.value &&
					formState.otherNewIllnesses.value.length > 0 && {
						newOtherIllnesses: formState.otherNewIllnesses.value
							.map(({ value }) => value)
							.toString()
					})
			})
		};
	};

	const validateFields = () => {
		let isErrorsPresent = false;
		let newFormState = { ...formState };
		if (formState.resetOption.value == 1) {
			if (!formState.dateEuthanised.value) {
				newFormState = {
					...newFormState,
					dateEuthanised: {
						...newFormState.dateEuthanised,
						validationError: 'Euthanised date is required'
					}
				};
				isErrorsPresent = true;
			}
			if (formState.euthanisedReason.value == BLANK_OPTION.id) {
				newFormState = {
					...newFormState,
					euthanisedReason: {
						...newFormState.euthanisedReason,
						validationError: 'Euthanised reason is required'
					}
				};
				isErrorsPresent = true;
			}
			if (
				isEuthanisedReasonInjury &&
				formState.euthanisedInjuries.value.length === 0
			) {
				newFormState = {
					...newFormState,
					euthanisedInjuries: {
						...newFormState.euthanisedInjuries,
						validationError: 'Euthanised injury types are required'
					}
				};
				isErrorsPresent = true;
			}
		}
		if (formState.resetOption.value == 2) {
			if (!formState.dateRehomed.value) {
				newFormState = {
					...newFormState,
					dateRehomed: {
						...newFormState.dateRehomed,
						validationError: 'Rehomed date is required'
					}
				};
				isErrorsPresent = true;
			}
			if (
				!formState.soldPrice.value ||
				!Number.isInteger(+formState.soldPrice.value)
			) {
				newFormState = {
					...newFormState,
					soldPrice: {
						...newFormState.soldPrice,
						validationError: 'Sold price is required'
					}
				};
				isErrorsPresent = true;
			}
			if (formState.rehomedPurpose.value == BLANK_OPTION.id) {
				newFormState = {
					...newFormState,
					rehomedPurpose: {
						...newFormState.rehomedPurpose,
						validationError: 'Rehomed purpose is required'
					}
				};
				isErrorsPresent = true;
			}
			if (formState.selectedDisciplines.value.length === 0) {
				newFormState = {
					...newFormState,
					selectedDisciplines: {
						...newFormState.selectedDisciplines,
						validationError: 'Discipline types are required'
					}
				};
				isErrorsPresent = true;
			}
			if (!formState.rehomedPostcode.value) {
				newFormState = {
					...newFormState,
					rehomedPostcode: {
						...newFormState.rehomedPostcode,
						validationError: 'New owner postcode is required'
					}
				};
				isErrorsPresent = true;
			}
		}
		if (formState.resetOption.value == 3) {
			if (!formState.dateReturned.value) {
				newFormState = {
					...newFormState,
					dateReturned: {
						...newFormState.dateReturned,
						validationError: 'Returned date is required'
					}
				};
				isErrorsPresent = true;
			}
			if (formState.returnedReason.value == BLANK_OPTION.id) {
				newFormState = {
					...newFormState,
					returnedReason: {
						...newFormState.returnedReason,
						validationError: 'Returned reason is required'
					}
				};
				isErrorsPresent = true;
			}
			if (
				isReturnedReasonInjury &&
				formState.returnedInjuries.value.length === 0
			) {
				newFormState = {
					...newFormState,
					returnedInjuries: {
						...newFormState.returnedInjuries,
						validationError: 'Returned injury types is required'
					}
				};
				isErrorsPresent = true;
			}
		}
		if (
			formState.injuriesPresent.value &&
			formState.notedInjuriesPresent.value &&
			formState.notedInjuries.value.length === 0
		) {
			newFormState = {
				...newFormState,
				notedInjuries: {
					...newFormState.notedInjuries,
					validationError: 'Injury types are required'
				}
			};
			isErrorsPresent = true;
		}
		if (
			formState.injuriesPresent.value &&
			formState.notNotedInjuriesPresent.value &&
			formState.notNotedInjuries.value.length === 0
		) {
			newFormState = {
				...newFormState,
				notNotedInjuries: {
					...newFormState.notNotedInjuries,
					validationError: 'Injury types are required'
				}
			};
			isErrorsPresent = true;
		}
		if (
			formState.injuriesPresent.value &&
			formState.newInjuriesPresent.value &&
			formState.newInjuries.value.length === 0
		) {
			newFormState = {
				...newFormState,
				newInjuries: {
					...newFormState.newInjuries,
					validationError: 'Injury types are required'
				}
			};
			isErrorsPresent = true;
		}
		if (
			formState.illnessesPresent.value &&
			formState.notedIllnessesPresent.value &&
			formState.notedIllnesses.value.length === 0
		) {
			newFormState = {
				...newFormState,
				notedIllnesses: {
					...newFormState.notedIllnesses,
					validationError: 'Illness types are required'
				}
			};
			isErrorsPresent = true;
		}
		if (
			formState.illnessesPresent.value &&
			formState.notNotedIllnessesPresent.value &&
			formState.notNotedIllnesses.value.length === 0
		) {
			newFormState = {
				...newFormState,
				notNotedIllnesses: {
					...newFormState.notNotedIllnesses,
					validationError: 'Illness types are required'
				}
			};
			isErrorsPresent = true;
		}
		if (
			formState.illnessesPresent.value &&
			formState.newIllnessesPresent.value &&
			formState.newIllnesses.value.length === 0
		) {
			newFormState = {
				...newFormState,
				newIllnesses: {
					...newFormState.newIllnesses,
					validationError: 'Illness types are required'
				}
			};
			isErrorsPresent = true;
		}
		setFormState(newFormState);
		return isErrorsPresent;
	};

	const generateUnclaimHorsePayload = () => {
		const {
			member: { externalId: retrainerExternalId }
		} = currentRetrainer;
		let unclaimHorseInputPayload = {};
		const UNCLAIM_HORSE_BASE_INPUT = {
			member_id: retrainerExternalId,
			horse_code: +horseCode,
			unclaim_reason: null,
			effective_date: null,
			other_details: null,
			full_circle_interested: null,
			date_of_death: null,
			euthanised_unsuitable_id: null,
			euthanised_unsuitable_other: null,
			euthanised_illness_id: null,
			euthanised_illness_other: null,
			cause_of_death_id: null,
			euthanised_injury_id: null,
			euthanised_injury_other: null,
			deceased_other_info: null,
			knackery_location: null,
			date_rehomed: null,
			price: null,
			purpose: null,
			purpose_other: null,
			rehomed_disciplines: [],
			disciplines_other: null
		};
		if (formState.resetOption.value == 1) {
			unclaimHorseInputPayload = {
				...UNCLAIM_HORSE_BASE_INPUT,
				full_circle_interested: false,
				cause_of_death_id: 2,
				date_of_death: formState.dateEuthanised.value,
				unclaim_reason: 1
			};
		} else if (formState.resetOption.value == 2) {
			unclaimHorseInputPayload = {
				...UNCLAIM_HORSE_BASE_INPUT,
				date_rehomed: formState.dateRehomed.value,
				rehomed_disciplines: formState.selectedDisciplines.value.map(
					({ value }) => value
				),
				disciplines_other: formState.otherDisciplines.value
					? formState.otherDisciplines.value
							.map(({ value }) => value)
							.toString()
					: '',
				purpose: +formState.rehomedPurpose.value,
				full_circle_interested: false,
				price: null
			};
		} else if (formState.resetOption.value == 3) {
			unclaimHorseInputPayload = {
				...UNCLAIM_HORSE_BASE_INPUT,
				date_rehomed: formState.dateReturned.value,
				price: null,
				purpose: 5,
				purpose_other: `Returned to Owner - Reason: ${
					returnedReasonOptions.find(
						({ id }) => id == formState.returnedReason.value
					).value
				} - Other Reason: ${formState.otherReturnedReason.value}`,
				full_circle_interested: false
			};
		}
		return unclaimHorseInputPayload;
	};

	const onSubmit = (event) => {
		event.preventDefault();
		const errorsPresent = validateFields();
		if (!errorsPresent) {
			const resetFormInputData = generateSaveResetFormPayload();
			AppSyncService.execute(saveConcludeResetForm, {
				resetConcludedFormData: resetFormInputData
			}).then((result) => {
				if (
					result?.data?.concludeReset?.id &&
					result?.data?.concludeReset?.id > 0
				) {
					if (formState.resetOption.value == 1) {
						const deceasedCauseOfDeathId = 2;
						const euthanisedReasonId =
							deceasedCauseOfDeathId == 2 &&
							formState.euthanisedReason.value != 0
								? Number(formState.euthanisedReason.value)
								: null;
						const deceasedInput = {
							horseCode: Number(horseCode),
							deceasedThoroughbredSourceId: 3, //@NOTE - 2 is equal to Conclude RESET
							deceasedDateOfDeath: formState.dateEuthanised.value,
							deceasedCauseOfDeathId,
							memberId: null,
							euthanisedReasonId: euthanisedReasonId,
							euthanisedInjuryOther:
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 3 &&
								formState.euthanisedOtherInjuries?.value?.length > 0
									? formState.euthanisedOtherInjuries?.value
											?.map(({ value }) => value)
											?.toString()
									: null,
							euthanisedIllnessOther: null,
							unsuitableReasons: [],
							euthanisedUnsuitableOther: null,
							deceasedReasonForDeathOther: [],
							deceasedReasonForDeathOtherText:
								deceasedCauseOfDeathId == 2 && euthanisedReasonId == 4
									? formState.euthanisedOtherReason?.value
									: null,
							deceasedKnackeryPostcode: null,
							deceasedKnackerySuburb: null,
							deceasedOtherPostcode: null,
							deceasedOtherSuburb: null,
							informationAboutDeath: null,
							hasOwnerBeenNotified: false,
							status: 'Review',
							injuries:
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 3 &&
								formState.euthanisedInjuries?.value?.length > 0
									? formState.euthanisedInjuries.value?.map(
											({ value }) => value
									  )
									: [],
							illnesses: []
						};
						AppSyncService.execute(saveDeceasedThoroughbredForm, {
							input: deceasedInput
						});
					}
					if (currentRetrainer) {
						const unclaimHorsePayload = generateUnclaimHorsePayload();
						AppSyncService.execute(unclaimHorse, {
							input: unclaimHorsePayload
						}).then((result) => {
							if (result?.data?.unclaimHorse?.success) {
								addToast({ Message: 'Concluded RESET', IsSuccess: true });
								navigate(`/horse-profile/${horseCode}`);
							} else {
								addToast({
									Message: 'Failed to conclude RESET',
									IsSuccess: false
								});
							}
						});
					} else {
						addToast({ Message: 'Concluded RESET', IsSuccess: true });
						navigate(`/horse-profile/${horseCode}`);
					}
				} else {
					addToast({ Message: 'Failed to conclude RESET', IsSuccess: false });
				}
			});
		}
	};

	return (
		<PageContainer>
			<form onSubmit={onSubmit}>
				<FormBody>
					<FormSection sectionTitle="Conclude RESET">
						<Col>
							<Row>
								<Col lg={12}>
									<SimpleRadioGroup
										groupName="sampleType"
										onChange={(e) => {
											const newFormState = {
												...formState,
												resetOption: { value: e.target.value },
												...(e.target.value != 1 && {
													dateEuthanised: {
														value: null,
														validationError: ''
													},
													euthanisedReason: {
														value: BLANK_OPTION.id,
														validationError: ''
													},
													euthanisedOtherReason: {
														value: '',
														validationError: ''
													},
													euthanisedInjuries: {
														value: [],
														validationError: ''
													},
													euthanisedOtherInjuries: {
														value: '',
														validationError: ''
													}
												}),
												...(e.target.value != 2 && {
													dateRehomed: {
														value: null,
														validationError: ''
													},
													soldPrice: {
														value: null,
														validationError: ''
													},
													rehomedPurpose: {
														value: BLANK_OPTION.id,
														validationError: ''
													},
													otherRehomingPurpose: {
														value: '',
														validationError: ''
													},
													selectedDisciplines: {
														value: [],
														validationError: ''
													},
													otherDisciplines: {
														value: '',
														validationError: ''
													},
													rehomedPostcode: {
														value: '',
														validationError: ''
													}
												}),
												...(e.target.value != 3 && {
													dateReturned: {
														value: null,
														validationError: ''
													},
													returnedReason: {
														value: BLANK_OPTION.id,
														validationError: ''
													},
													otherReturnedReason: {
														value: ''
													},
													returnedInjuries: {
														value: [],
														validationError: ''
													},
													otherReturnedInjuries: {
														value: ''
													}
												})
											};
											setFormState(newFormState);
										}}
										verticalDisplay
										items={RESET_CONCLUDED_OPTIONS}
									/>
								</Col>
							</Row>
						</Col>
						<FormSectionContentDivider />
						<Col>
							{formState.resetOption.value == 1 && EuthanisedInputComponents}
							{formState.resetOption.value == 2 && RehomedInputComponents}
							{formState.resetOption.value == 3 && ReturnedInputComponents}
						</Col>
						<FormSectionContentDivider
							style={{ marginTop: 20, marginBottom: 20 }}
						/>
						<Col>
							<Row>
								<OptionBar description="Are there any injuries impacting the horse before, during or after the RESET program?">
									<Toggle
										id={ResetConcludeFormKeys.INJURIES_IMPACTING}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(newValue) => {
											const newFormState = {
												...formState,
												injuriesPresent: {
													value: newValue
												},
												...(!newValue && {
													notedInjuriesPresent: {
														value: false
													},
													notedInjuries: {
														value: [],
														valdiationError: ''
													},
													otherNotedInjuries: {
														value: '',
														validationError: ''
													},
													notNotedInjuriesPresent: {
														value: false
													},
													notNotedInjuries: {
														value: [],
														validationError: ''
													},
													otherNotNotedInjuries: {
														value: '',
														validationError: ''
													},
													newInjuriesPresent: {
														value: false
													},
													newInjuries: {
														value: [],
														validationError: ''
													},
													otherNewInjuries: {
														value: '',
														validationError: ''
													}
												})
											};
											setFormState(newFormState);
										}}
										value={formState.injuriesPresent.value}
									/>
								</OptionBar>
							</Row>
							{formState.injuriesPresent.value && (
								<>
									<ToggleWithMultiSelect
										toggleId={ResetConcludeFormKeys.PRE_EXISTING_INJURIES_NOTED}
										toggleLabel="Any pre-existing injuries noted on Nomination form or Vet Assessment?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notedInjuriesPresent: {
													value: newValue
												},
												...(!newValue && {
													notedInjuries: {
														value: [],
														validationError: ''
													},
													otherNotedInjuries: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.notedInjuriesPresent.value}
										multiSelectId={ResetConcludeFormKeys.INJURIES_NOTED}
										multiSelectLabel="Please select injury types"
										multiSelectOptions={injuredReasonsOptions}
										multiSelectSelectedOptions={formState.notedInjuries.value}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notedInjuries: { value: newValue, validationError: '' },
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNotedInjuries: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNotedInjuries.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNotedInjuries: { value: newValue }
											};
											setFormState(newFormState);
										}}
										validationError={formState.notedInjuries.validationError}
									/>
									<ToggleWithMultiSelect
										toggleId={
											ResetConcludeFormKeys.PRE_EXISTING_INJURIES_NOT_NOTED
										}
										toggleLabel="Any pre-existing injury NOT noted on Nomination form or Vet Assessment?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notNotedInjuriesPresent: {
													value: newValue,
													validationError: ''
												},
												...(!newValue && {
													notNotedInjuries: {
														value: [],
														validationError: ''
													},
													otherNotNotedInjuries: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.notNotedInjuriesPresent.value}
										multiSelectId={ResetConcludeFormKeys.INJURIES_NOT_NOTED}
										multiSelectLabel="Please select injury types"
										multiSelectOptions={injuredReasonsOptions}
										multiSelectSelectedOptions={
											formState.notNotedInjuries.value
										}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notNotedInjuries: {
													value: newValue,
													validationError: ''
												},
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNotNotedInjuries: {
														value: ''
													}
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNotNotedInjuries.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNotNotedInjuries: {
													value: newValue
												}
											};
											setFormState(newFormState);
										}}
										validationError={formState.notNotedInjuries.validationError}
									/>
									<ToggleWithMultiSelect
										toggleId={
											ResetConcludeFormKeys.ANY_INJURIES_THE_HORSE_LEAVING
										}
										toggleLabel="Any injuries the horse is leaving the program with?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												newInjuriesPresent: { value: newValue },
												...(!newValue && {
													newInjuries: {
														value: [],
														validationError: ''
													},
													otherNewInjuries: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.newInjuriesPresent.value}
										multiSelectId={ResetConcludeFormKeys.INJURIES_LEAVING}
										multiSelectLabel="Please select injury types"
										multiSelectOptions={injuredReasonsOptions}
										multiSelectSelectedOptions={formState.newInjuries.value}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												newInjuries: {
													value: newValue,
													validationError: ''
												},
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNewInjuries: {
														value: ''
													}
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNewInjuries.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNewInjuries: {
													value: newValue
												}
											};
											setFormState(newFormState);
										}}
										validationError={formState.newInjuries.validationError}
									/>
								</>
							)}
							<Row style={{ marginTop: 15 }}>
								<OptionBar description="Are there any illnesses impacting the horse before, during or after the RESET program?">
									<Toggle
										id={ResetConcludeFormKeys.ILLNESSES_IMPACTING}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(newValue) => {
											const newFormState = {
												...formState,
												illnessesPresent: { value: newValue },
												...(!newValue && {
													notedIllnessesPresent: {
														value: false
													},
													notedIllnesses: {
														value: [],
														validationError: ''
													},
													otherNotedIllnesses: {
														value: ''
													},
													notNotedIllnessesPresent: {
														value: false
													},
													notNotedIllnesses: {
														value: [],
														validationError: ''
													},
													otherNotNotedIllnesses: {
														value: ''
													},
													newIllnessesPresent: {
														value: false
													},
													newIllnesses: {
														value: [],
														validationError: ''
													},
													otherNewIllnesses: {
														value: ''
													}
												})
											};
											setFormState(newFormState);
										}}
										value={formState.illnessesPresent.value}
									/>
								</OptionBar>
							</Row>
							{formState.illnessesPresent.value && (
								<>
									<ToggleWithMultiSelect
										toggleId={ResetConcludeFormKeys.PRE_EXISTING_ILLNESS_NOTED}
										toggleLabel="Any pre-existing illness noted on Nomination form or Vet Assessment?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notedIllnessesPresent: {
													value: newValue
												},
												...(!newValue && {
													notedIllnesses: {
														value: [],
														validationError: ''
													},
													otherNotedIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.notedIllnessesPresent.value}
										multiSelectId={ResetConcludeFormKeys.ILLNESS_NOTED}
										multiSelectLabel="Please select illness types"
										multiSelectOptions={illnessOptions}
										multiSelectSelectedOptions={formState.notedIllnesses.value}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notedIllnesses: {
													value: newValue,
													validationError: ''
												},
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNotedIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNotedIllnesses.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNotedIllnesses: { value: newValue }
											};
											setFormState(newFormState);
										}}
										validationError={formState.notedIllnesses.validationError}
									/>
									<ToggleWithMultiSelect
										toggleId={
											ResetConcludeFormKeys.PRE_EXISTING_ILLNESS_NOT_NOTED
										}
										toggleLabel="Any pre-existing illness NOT noted on Nomination form or Vet Assessment?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notNotedIllnessesPresent: {
													value: newValue
												},
												...(!newValue && {
													notNotedIllnesses: {
														value: [],
														validationError: ''
													},
													otherNotNotedIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.notNotedIllnessesPresent.value}
										multiSelectId={ResetConcludeFormKeys.ILLNESS_NOT_NOTED}
										multiSelectLabel="Please select illness types"
										multiSelectOptions={illnessOptions}
										multiSelectSelectedOptions={
											formState.notNotedIllnesses.value
										}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												notNotedIllnesses: {
													value: newValue,
													validationError: ''
												},
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNotNotedIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNotNotedIllnesses.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNotNotedIllnesses: { value: newValue }
											};
											setFormState(newFormState);
										}}
										validationError={
											formState.notNotedIllnesses.validationError
										}
									/>
									<ToggleWithMultiSelect
										toggleId={
											ResetConcludeFormKeys.ANY_ILLNESS_THE_HORSE_LEAVING
										}
										toggleLabel="Any illness the horse is leaving the program with?"
										isRequired
										toggleChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												newIllnessesPresent: {
													value: newValue
												},
												...(!newValue && {
													newIllnesses: {
														value: [],
														validationError: ''
													},
													otherNewIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										showMultiSelect={formState.newIllnessesPresent.value}
										multiSelectId={ResetConcludeFormKeys.ILLNESS_LEAVING}
										multiSelectLabel="Please select illness types"
										multiSelectOptions={illnessOptions}
										multiSelectSelectedOptions={formState.newIllnesses.value}
										multiSelectChangeHandler={(newValue) => {
											const newFormState = {
												...formState,
												newIllnesses: {
													value: newValue,
													validationError: ''
												},
												...((!newValue ||
													!newValue.find(({ label }) => label === 'Other')) && {
													otherNewIllnesses: { value: '' }
												})
											};
											setFormState(newFormState);
										}}
										otherValue={formState.otherNewIllnesses.value}
										onOtherChange={(newValue) => {
											const newFormState = {
												...formState,
												otherNewIllnesses: { value: newValue }
											};
											setFormState(newFormState);
										}}
										validationError={formState.newIllnesses.validationError}
									/>
								</>
							)}
						</Col>
					</FormSection>
				</FormBody>
				<FormFooter
					onCancel={onCancel}
					showSaveButton={false}
					validationErrorsExist={isValidationErrorsPresent}
					submitEnabled={!isValidationErrorsPresent}
					submitButtonText="Complete form"
				/>
			</form>
			<FormCancelConfirmModal
				showCancelConfirmModal={showCancelModal}
				onCloseHandler={() => setShowCancelModal(false)}
				onConfirmHandler={() => navigate(`/horse-profile/${horseCode}`)}
				modalTitle="Cancel RESET Concluded form"
			/>
		</PageContainer>
	);
};

export default RESETConcludedForm;

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import SEO from '../seo';
import { Container, useScreenClass } from 'react-grid-system';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import HeaderBanner from '../HeaderBanner';
import { checkIsSmallScreen } from '../../utils/helpers';

const RetrainerPrivacyPolicy = () => {
	const classes = useStyles();
	const [richText, setRichText] = useState(null);
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const contentfulRetrainerApplicationPrivacyPolicyContent = useStaticQuery(
		graphql`
			query RetrainerApplicationPrivacyPolicyContentQuery {
				allContentfulRetrainerApplicationFormPrivacyPolicyContent(
					sort: { fields: updatedAt, order: DESC }
				) {
					edges {
						node {
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		setRichText(
			contentfulRetrainerApplicationPrivacyPolicyContent
				.allContentfulRetrainerApplicationFormPrivacyPolicyContent.edges[0].node
				.body
		);
	});

	return (
		<>
			<div>
				<SEO title="Acknowledged Retrainer Privacy Collection Statement" />
				<Container
					className={
						isSmallScreen ? classes.mobileContainer : classes.container
					}
				>
					<HeaderBanner
						title="Acknowledged Retrainer Privacy Collection Statement"
						className={classes.bannerStyles}
					/>
					{richText && renderRichText(richText, options)}
				</Container>
			</div>
		</>
	);
};

export default RetrainerPrivacyPolicy;

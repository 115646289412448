import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import useStyles from './styles';

const FullCircleStats = ({ stats, mobile }) => {
	const renderStats = () => {
		return (
			<Container>
				<Row>
					{stats.map((s, index) => {
						return (
							<Col key={index} xs={12} lg={6}>
								<div
									className={
										mobile ? classes.infoContainerMobile : classes.infoContainer
									}
								>
									<div
										className={mobile ? classes.mobileValue : classes.value}
										style={{ backgroundColor: s.color }}
									>
										{s.value}
									</div>
									<div
										className={
											mobile ? classes.mobileDescription : classes.description
										}
									>
										{s.description}
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>
		);
	};

	const renderPage = () => {
		return (
			<>
				<div className={mobile ? classes.headingMobile : classes.heading}>
					Full Circle Statistics
				</div>
				<div
					className={
						mobile ? classes.statsContainerMobile : classes.statsContainer
					}
				>
					{renderStats()}
				</div>
			</>
		);
	};

	const classes = useStyles();
	return renderPage();
};
export default FullCircleStats;

import styled from 'styled-components';
import BannerImage from '../../../../assets/images/header_banner.png';

export const StyledHeader = styled.div`
	background: url(${BannerImage});
	background-size: contain;
	min-width: 330px;
	width: 100%;
	height: 100px;
	border-radius: 5px 5px 0px 0px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
`;

export const StyledHorseName = styled.div`
	font-weight: 800;
	color: ${({ theme: { colors } }) => colors.text};
	margin-left: 20px;
	height: 100%;
	display: flex;
	align-items: center;
	min-width: 110px;
	font-size: 15px;
	margin-right: 5px;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		font-size: 20px;
		margin-right: 20px;
	}
`;

export const StyledLongText = styled.div`
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		max-width: 140px;
	}
`;

export const StyledHorseMeta = styled.div`
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const StyledFollowerCount = styled.p`
	margin: 0;
	font-weight: normal;
	.count {
		font-weight: bold;
	}
	color: ${({ theme: { colors } }) => colors.text};
	font-size: 13px;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		font-size: 15px;
	}
`;

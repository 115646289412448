import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	leftContainer: {
		width: '100%',
		marginBottom: 40
	},
	headTitle: {
		fontSize: 20,
		fontWeight: 600,
		color: '#464749',
		marginBottom: 15,
		textTransform: 'uppercase'
	},
	headTitleMobile: {
		fontSize: 20,
		fontWeight: 600,
		color: '#464749',
		marginBottom: 15,
		textTransform: 'uppercase',
		marginLeft: 15
	},
	leftContent: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	card: {
		width: 230,
		position: 'relative',
		height: 340
	},
	image: {
		objectFit: 'cover',
		height: 140,
		width: '100%',
		borderRadius: 5,
		marginBottom: 5
	},
	readMore: {
		background: '#dcebf3',
		textAlign: 'center',
		padding: '10px 10px 10px 10px',
		color: '#006da6',
		fontWeight: 600,
		borderRadius: 5,
		fontSize: 15,
		position: 'absolute',
		left: 0,
		right: 20,
		bottom: 0,
		cursor: 'pointer',
		width: 230,
		boxSizing: 'border-box',
		'&:hover': {
			backgroundColor: '#cde3ef'
		}
	},
	program: {
		fontSize: 15,
		fontWeight: 600,
		textTransform: 'uppercase',
		color: '#464749'
	},
	description: {
		fontSize: 12,
		marginTop: 7,
		marginBottom: 10,
		maxHeight: 105,
		textOverflow: 'hidden',
		overflow: 'hidden'
	},
	mobileCaroContainer: {
		position: 'relative',
		overflow: 'hidden',
		width: '100%',
		marginBottom: 10
	},
	mobileLeft: {
		width: 400,
		height: 205,
		position: 'absolute',
		zIndex: 1,
		left: -390,
		borderRadius: '5px 5px 0px 0px'
	},
	mobileRight: {
		width: 400,
		height: 205,
		position: 'absolute',
		right: -390,
		top: 0,
		borderRadius: '5px 5px 0px 0px'
	},
	mobileActive: {
		width: '90%',
		left: 0,
		margin: '0 auto',
		zIndex: 2,
		right: 0,
		borderRadius: '5px 5px 0px 0px',
		height: 200
	},
	mobileBack: {
		position: 'absolute',
		left: 15,
		top: 85,
		zIndex: 3
	},
	mobileForward: {
		position: 'absolute',
		right: 15,
		top: 85,
		zIndex: 3
	},
	mobileButton: {
		height: 40
	},
	mobileTextBannerContainer: {
		width: '100%',
		boxSizing: 'border-box',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 205,
		background: 'white',
		minHeight: 185,
		display: 'block',
		borderRight: '2px solid #edf5f9',
		borderLeft: '2px solid #edf5f9',
		borderBottom: '2px solid #edf5f9'
	},
	mobileTextBannerContainerActive: {
		width: '100%',
		boxSizing: 'border-box',
		marginLeft: 'auto',
		marginRight: 'auto',
		background: 'white'
	},
	mobileTextTitle: {
		paddingLeft: 12,
		paddingRight: 12,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		textTransform: 'uppercase'
	},
	mobileLinkTitle: {
		extend: 'mobileTextTitle',
		color: '#0085ca',
		cursor: 'pointer'
	},
	mobileText: {
		paddingLeft: 12,
		paddingRight: 12,
		fontSize: 12,
		marginTop: 5,
		fontWeight: 400,
		color: '#464749'
	},
	mobileButtonLink: {
		background: '#dcebf3',
		width: '90%',
		color: '#006da6',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		padding: 10,
		fontWeight: 600,
		fontSize: 15,
		borderRadius: 5,
		marginTop: 20,
		cursor: 'pointer',
		boxSizing: 'border-box'
	},
	mobileContentContainer: {
		paddingTop: 10,
		paddingBottom: 10,
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '89%',
		borderRight: '2px solid #edf5f9',
		borderLeft: '2px solid #edf5f9',
		borderBottom: '2px solid #edf5f9'
	}
}));

import React from 'react';
import SectionRow from '../../SectionRow';
import PrimaryButton from '../../PrimaryButton';
import useStyles from './styles';

const SAVE_ADMIN_DETAILS_BUTTON_STYLES = {
	width: 200,
	marginTop: 0,
	marginRight: 0
};

const SaveAdminDetailsSection = ({ onSave, disabled }) => {
	const classes = useStyles();
	return (
		<SectionRow>
			<div className={classes.saveAdminActionContainer}>
				<PrimaryButton
					style={SAVE_ADMIN_DETAILS_BUTTON_STYLES}
					onClick={onSave}
					disabled={disabled}
				>
					Save Admin Details
				</PrimaryButton>
			</div>
		</SectionRow>
	);
};

export default SaveAdminDetailsSection;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	addressSectionContent: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 230,
		alignItems: 'flex-end',
		'& p': {
			margin: '0px 0px 5px 0px',
			fontWeight: 600,
			fontSize: 13,
			textAlign: 'end',
			'@media (min-width:992px)': {
				fontSize: 15
			}
		}
	},
	viewClaimFormLink: {
		color: '#006da6',
		fontWeight: 600,
		cursor: 'pointer'
	}
}));

import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		paddingLeft: 0,
		paddingRight: 0
	},
	filterContainer: {
		background: '#dcebf3',
		minHeight: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingTop: 12,
		paddingBottom: 5
	},
	submitButton: {
		width: 180,
		float: 'right'
	},
	buttonText: {
		float: 'left'
	},
	filterContainerDatePicker: {
		background: '#dcebf3',
		minHeight: 65
	},
	filterComponentContainerDatePicker: {
		background: '#dcebf3',
		minHeight: 65,
		paddingTop: 12,
		paddingBottom: 'unset'
	},
	filterComponentContainerDatePickerMobile: {
		extend: 'filterComponentContainerDatePicker',
		padding: 5
	},
	filterComponentContainerDatePickerBottomMobile: {
		extend: 'filterComponentContainerDatePickerMobile',
		paddingBottom: 15
	}
}));

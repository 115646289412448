import React from 'react';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const MultiSelectAll = ({
	label,
	options,
	selectedOptions,
	setSelectedOptions
}) => {
	function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
		if (value && value.some((o) => o.value === '*')) {
			return `${placeholderButtonLabel}: All`;
		} else {
			return `${placeholderButtonLabel}: ${value.length} selected`;
		}
	}

	function onChange(value, event) {
		if (event.action === 'select-option' && event.option.value === '*') {
			this.setState(this.options);
		} else if (
			event.action === 'deselect-option' &&
			event.option.value === '*'
		) {
			this.setState([]);
		} else if (event.action === 'deselect-option') {
			this.setState(value.filter((o) => o.value !== '*'));
		} else if (value.length === this.options.length - 1) {
			this.setState(this.options);
		} else {
			this.setState(value);
		}
	}

	return (
		<ReactMultiSelectCheckboxes
			options={options}
			placeholderButtonLabel={label}
			getDropdownButtonLabel={getDropdownButtonLabel}
			value={selectedOptions}
			onChange={onChange}
			setState={setSelectedOptions}
			closeMenuOnSelect={false}
			styles={{
				// Fixes the overlapping problem of the component
				container: (provided) => ({
					...provided,
					marginTop: -15
				}),
				input: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				}),
				menuPortal: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				}),
				menu: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				}),
				option: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				}),
				dropdownIndicator: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				}),
				dropdownButton: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF',
					boxShadow: 'none',
					color: '#0085ca',
					fontWeight: 500,
					width: '100%'
				}),
				placeholder: (provided) => ({
					...provided,
					zIndex: 9999,
					backgroundColor: '#FFF'
				})
			}}
			menuPortalTarget={document.body}
		/>
	);
};

export default MultiSelectAll;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	modalContentContainer: {
		width: '100%'
	},
	otherReasonContainer: {
		marginTop: 10
	}
});

export const MODAL_STYLES = {
	content: {
		minWidth: 300,
		maxWidth: 400,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

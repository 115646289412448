import { createUseStyles } from 'react-jss';

export const HORSE_AVATAR_INLINE_STYLES = {
	width: 37.5,
	height: 37.5,
	cursor: 'pointer'
};

export default createUseStyles({
	horseUpdateContainer: {
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'space-between'
	},
	horseName: {
		color: '#006da6',
		fontWeight: 'bold',
		fontSize: 15,
		margin: '0px 0px 1px 0px',
		cursor: 'pointer'
	},
	updateContentContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 10
	},
	updateCreatedTime: {
		marginBottom: 0,
		marginTop: 3,
		color: 'grey',
		fontSize: 13
	},
	horseMessage: {
		fontSize: 15,
		color: '#464749',
		'& > strong': {
			'&.generic, &.claimed, &.fullcircle, &.programChange, &.statusChange': {
				color: '#c43294'
			},
			'&.sns': {
				color: '#8534D3'
			}
		}
	},
	likeText: {
		marginLeft: 10,
		color: '#017F1C',
		fontWeight: 'bold',
		fontFamily: 'arial'
	},
	like: {
		display: 'flex',
		alignItems: 'center',
		margin: 10,
		marginBottom: 0
	},
	likeButton: {
		width: 30,
		height: 30,
		background: 'none',
		borderStyle: 'initial',
		cursor: 'pointer',
		transition: 'all .5s ease',
		marginLeft: 15
	}
});

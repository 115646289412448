import React, { useState } from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Row, Col } from 'react-grid-system';
import HeaderBanner from '../HeaderBanner';
import Stepper from '../Stepper';
import {
	INITIAL_FORM_STATE,
	INITIAL_STEPPER_STATE,
	PAGE_ID
} from './constants';
import useStyles, {
	HEADER_BANNER_STYLES,
	INLINE_CONTAINER_COLUMN_STYLES
} from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import TransitionStep from './components/TransitionStep';
import HorseInformationMiniForm from './components/HorseInformationMiniForm';
import AppSyncService from '../../graphql/AppSyncService';
import { useToast } from '../../hooks/useToast';
import { saveTransitionGuide } from './mutation';
import { REVIEWED, REVIEW } from '../../utils/constants';
import { mapLinksToAppPages } from './helpers';

const TransitionGuide = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [formState, setFormState] = useState(INITIAL_FORM_STATE);
	const [stepperState, setStepperState] = useState(INITIAL_STEPPER_STATE);
	const { allContentfulTransitionStep } = useStaticQuery(graphql`
		query transitionSteps {
			allContentfulTransitionStep {
				nodes {
					stepNumber
					pageType
					title
					options {
						optionId
						link
						name
					}
					stepImage {
						fluid(quality: 100) {
							src
						}
					}
					moreInformation {
						raw
					}
				}
			}
		}
	`);

	const handleFormUpdate = (name, value) =>
		setFormState((prevState) => ({
			...prevState,
			[name]: value
		}));

	const saveHorseTransitionGuide = (onSaveSuccess) => {
		AppSyncService.execute(saveTransitionGuide, {
			input: {
				status: formState.horseName ? REVIEW : REVIEWED,
				...formState
			}
		}).then((result) => {
			if (result?.data?.saveTransitionGuide) {
				addToast({ Message: 'Saved horse transition guide', IsSuccess: true });
				onSaveSuccess();
			} else {
				addToast({
					Message: 'Failed to save horse transition guide',
					IsSuccess: false
				});
			}
		});
	};

	const updateStepperState = (newStep) => {
		setStepperState((prevState) => ({
			activeStep: newStep,
			previousActiveSteps: [
				...prevState.previousActiveSteps.filter((step) => step < newStep),
				newStep
			]
		}));
	};

	const handleOptionClick = (stepNumber, selectedOption, linkValue) => {
		// Links to either a step (linkValue is number) or application page (linkValue is page link)
		const isLinkToStep = !!+linkValue;
		if (isLinkToStep) {
			handleFormUpdate(`step${stepNumber}`, selectedOption);
			updateStepperState(+linkValue);
		} else {
			const onSuccess = mapLinksToAppPages(linkValue);
			saveHorseTransitionGuide(onSuccess);
		}
	};

	const onStepChange = (newStep) => {
		updateStepperState(newStep);
		setFormState((prevState) => {
			// Clearing selected options for steps with higher step number
			const clearedSteps = Object.keys(prevState).reduce(
				(stepsToClear, stateKey) => {
					const isStepToClear =
						stateKey.startsWith('step') && stateKey.split('step')[1] > newStep;
					return {
						...stepsToClear,
						...(isStepToClear && {
							[stateKey]: null
						})
					};
				},
				{}
			);
			return {
				...prevState,
				...clearedSteps,
				horseName: '',
				concerns: ''
			};
		});
	};

	const transitionSteps = allContentfulTransitionStep.nodes.map(
		(transitionStep) => {
			const isHorseMiniFormStep = transitionStep.stepNumber === 11;
			const question = isHorseMiniFormStep ? (
				<>
					{transitionStep.title}
					<HorseInformationMiniForm
						formState={formState}
						handleFormStateChange={handleFormUpdate}
					/>
				</>
			) : (
				transitionStep.title
			);
			return (
				<TransitionStep
					key={transitionStep.stepNumber}
					{...transitionStep}
					isActive={stepperState.activeStep === transitionStep.stepNumber}
					question={question}
					onOptionClick={handleOptionClick}
					isButtonsDisabled={isHorseMiniFormStep && !formState.horseName}
				/>
			);
		}
	);

	return (
		<PageContainer>
			<SEO title="Post-Racing Transition Guide" />
			<Row>
				<Col style={INLINE_CONTAINER_COLUMN_STYLES}>
					<HeaderBanner
						title={
							<div className={classes.headerContainer}>
								<h1 className={classes.headerTitle}>
									Post Racing Transition Guide
								</h1>
								<Stepper
									idPrefix={PAGE_ID}
									totalSteps={8}
									activeStep={stepperState.activeStep}
									previousActiveSteps={stepperState.previousActiveSteps}
									onStepChange={onStepChange}
								/>
							</div>
						}
						styles={HEADER_BANNER_STYLES}
					/>
					{transitionSteps}
				</Col>
			</Row>
		</PageContainer>
	);
};

export default TransitionGuide;

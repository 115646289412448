import React, { useState, useEffect } from "react";
import useStyles from "./styles";

const RotatingBanner = ({ data, stats, mobile }) => {
  const [change, setChange] = useState(true);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      changeText();
    }, 4500);
    return () => clearInterval(interval);
  }, [change]);

  const changeText = () => {
    setChange(!change);
    if (!change) {
      if (current === data.length - 1) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
    }
  };

  const renderStats = () => {
    return stats.map((s, index) => {
      return (
        <div key={index} className={mobile ? classes.infoContainerMobile : classes.infoContainer}>
          <img src={s.src}></img>
          <div className={classes.description}>{s.description}</div>
        </div>
      );
    });
  };

  const renderPage = () => {
    if (mobile) {
      return (
        <>
          <div className={classes.headingMobile}>Each Year We Support</div>
          <div className={classes.imageContainerMobile}>
            <div className={classes.statsContainerMobile}>{renderStats()}</div>
            <div className={classes.lineMobile}></div>
            <div className={classes.textMobile}>
              <div className={change ? classes.textContentMobile : classes.textPrevMobile}>
                {data[current]}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.heading}>Each Year We Support</div>
          <div className={classes.imageContainer}>
            <div className={classes.statsContainer}>{renderStats()}</div>
            <div className={classes.line}></div>
            <div className={classes.text}>
              <div className={change ? classes.textContent : classes.textPrev}>
                {data[current]}
              </div>
            </div>
          </div>
        </>
      );
    }
  };

  const classes = useStyles();
  return renderPage();
};
export default RotatingBanner;

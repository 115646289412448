import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	ottNewsDesktopTopContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		'& $resultContent:nth-child(3n+2)': {
			marginRight: 20,
			marginLeft: 20
		}
	},
	ottNewsDesktopTopContainerSearch: {
		display: 'flex',
		flexDirection: 'column'
	},
	desktopBannerStyles: {
		boxSizing: 'border-box',
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	pageDesktopContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important'
	},
	resultContent: {
		width: 320,
		marginTop: 25
	},
	resultContentSearch: {
		width: 700,
		marginTop: 25
	},
	newsImage: {
		height: 200,
		borderRadius: 4,
		objectFit: 'cover',
		width: '100%'
	},
	summaryContainer: {
		background: 'white',
		marginTop: '-25%',
		position: 'relative',
		zIndex: 3,
		padding: '8px 14px 20px 14px',
		marginLeft: 20,
		borderRadius: 4,
		marginRight: 20
	},
	summaryContainerSearch: {
		background: 'white',
		borderRadius: 4
	},
	summaryHeading: {
		fontWeight: 600,
		fontSize: 12,
		marginBottom: 10,
		color: '#464749',
		wordBreak: 'break-word'
	},
	summaryHeadingSearch: {
		fontWeight: 600,
		fontSize: 15,
		color: '#464749',
		wordBreak: 'break-word'
	},
	summaryBody: {
		fontSize: 12,
		height: 'auto',
		wordBreak: 'break-word',
		minHeight: 50
	},
	summaryDate: { marginTop: 10, fontSize: 12, color: '#9a9a9a' },
	summaryDateSearch: { fontSize: 12, color: '#9a9a9a', marginBottom: 10 },
	ottNewsMobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	}
}));

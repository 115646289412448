import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

const FormSection = ({
	sectionTitle,
	children,
	isAdminSection = false,
	applyBorderRadius = false,
	withBottomBorder = false,
	showHeader = true,
	withoutPadding = false
}) => {
	const classes = useStyles();
	return (
		<div
			className={classnames({
				[classes.formSectionContainer]: true,
				[classes.bottomBorder]: withBottomBorder
			})}
		>
			{showHeader && (
				<div
					className={classnames({
						[classes.formSectionHeader]: true,
						[classes.borderRadius]: applyBorderRadius
					})}
				>
					{sectionTitle}
				</div>
			)}
			<div
				className={classnames({
					[classes.formSectionBody]: !withoutPadding,
					[classes.formSectionBodyWithoutPadding]: withoutPadding,
					[classes.adminSection]: isAdminSection
				})}
			>
				{children}
			</div>
		</div>
	);
};

export default FormSection;

import React, { useEffect, useState } from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Col, Container, Hidden, Row, useScreenClass } from 'react-grid-system';
import { BLOCKS } from '@contentful/rich-text-types';
import { checkIsSmallScreen, formatDate } from '../../utils/helpers';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import useStyles from './styles';
import FormSection from '../FormComponents/FormSection';
import TextInputField from '../FormComponents/TextInputField';
import DateInputField from '../FormComponents/DateInputField';
import SimpleCheckBox from '../SimpleCheckBox';
import { validation } from './validation';
import PrimaryButton from '../PrimaryButton';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import {
	addCompetitionEntry,
	checkUserCanEnterCompetition,
	getCurrentCompetition,
	dismissCompetitionNotification
} from './queries';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import TwoColLayout from '../TwoColLayout';
import MessageBar from '../MessageBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import { useToast } from '../../hooks/useToast';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const PAGE_ID = 'competitions';

const validationFields = [
	'firstName',
	'lastName',
	'dateOfBirth',
	'phoneNumber',
	'competitionsCheckBox'
];

const CompetitionPage = () => {
	const classes = useStyles();
	const [errors, setErrors] = useState({});
	const [validationOn, setValidationOn] = useState(false);
	const [competitionName, setCompetitionName] = useState('');
	const [competitionId, setCompetitionId] = useState(null);
	const { addToast } = useToast();
	const { currentUser } = useAuth();

	const handleChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};
	const handleCheckbox = (id, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: !items[name].value
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};
	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			AppSyncService.execute(addCompetitionEntry, {
				input: {
					firstName: values.firstName.value,
					lastName: values.lastName.value,
					phoneNumber: values.phoneNumber.value,
					seeMoreOnOtt: values.seeMore.value,
					dateOfBirth: new Date(values.dateOfBirth.value).toLocaleDateString(),
					competitionId: competitionId
				}
			})
				.then(() => {
					addToast({
						Message: 'Competition Successfully Entered',
						IsSuccess: true
					});
					removeCompetitionNotification();
					navigate(DASHBOARD_ROUTE);
				})
				.catch((err) => {
					addToast({
						Message: `An Error occured during the competition entry process. Please try again. Information: ${err}`,
						IsSuccess: false
					});
				});
		}
	};

	const removeCompetitionNotification = () => {
		AppSyncService.execute(dismissCompetitionNotification);
	};

	const [values, setValues] = useState({
		firstName: {
			value: '',
			isRequired: true
		},
		lastName: {
			value: '',
			isRequired: true,
			errorMessage: 'Last Name is required'
		},
		phoneNumber: {
			value: '',
			isRequired: true,
			errorMessage: 'Phone number is required'
		},
		dateOfBirth: {
			value: null,
			isRequired: true,
			errorMessage: 'Date of birth is required'
		},
		competitionsCheckBox: {
			value: false,
			isRequired: true
		},
		seeMore: {
			value: '',
			isRequired: false
		}
	});

	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const [richText, setRichText] = useState(null);
	const [competitionBanner, setCompetitionBanner] = useState(null);
	const [entriesClose, setEntriesClose] = useState(null);
	const [prizes, setPrizes] = useState(null);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [disableReason, setDisableReason] = useState('');
	const [competitionIsActive, setCompetitionIsActive] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const contentfulData = useStaticQuery(graphql`
		query getCompetitionContent {
			allContentfulCompetition(filter: { isActive: { eq: true } }) {
				edges {
					node {
						title {
							title
						}
						competitionBanner {
							file {
								contentType
								fileName
								url
							}
						}
						competitionPrize
						entriesClose
						description {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
								}
								title
							}
						}
					}
				}
			}
		}
	`);

	useEffect(() => {
		setIsLoading(true);
		setRichText(
			contentfulData.allContentfulCompetition.edges[0]?.node.description
		);
		setCompetitionBanner(
			contentfulData.allContentfulCompetition.edges[0]?.node.competitionBanner
		);
		setEntriesClose({
			title: 'Competition dates',
			value: contentfulData.allContentfulCompetition.edges[0]?.node.entriesClose
		});
		setPrizes({
			title: 'Prizes',
			value:
				contentfulData.allContentfulCompetition.edges[0]?.node.competitionPrize
		});
		AppSyncService.execute(getCurrentCompetition).then((data) => {
			if (
				data?.data?.getCompetition?.length > 0 &&
				contentfulData.allContentfulCompetition.edges[0]?.node
			) {
				setCompetitionId(data.data.getCompetition[0].id);
				setCompetitionName(data.data.getCompetition[0].name);
				setCompetitionIsActive(true);
				AppSyncService.execute(checkUserCanEnterCompetition, {
					competitionId: data.data.getCompetition[0].id,
					memberExternalId: currentUser?.UserId
				}).then((data) => {
					if (data?.data?.getCompetitionEntries?.length !== 0) {
						setIsSubmitDisabled(true);
						setDisableReason(
							'You have already entered this contest. Contest winners will be contacted after the competition ends.'
						);
					}
				});
			} else {
				setCompetitionIsActive(false);
			}
		});
		setIsLoading(false);
	}, [contentfulData]);

	const competitionInfoBox = (title, value) => {
		return (
			<>
				<div className={classes.infoBoxContent}>
					<div className={classes.infoBoxTitle}>{title}</div>
					<div className={classes.infoBoxValue}>{value}</div>
				</div>
			</>
		);
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>,
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				videoCollection.push({
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				});
			}
		}
	};

	return (
		<PageContainer
			className={isSmallScreen ? classes.mobileTopContainer : classes.container}
		>
			{!isLoading && (
				<>
					{competitionIsActive && (
						<>
							<SEO title={competitionName} />
							{competitionBanner && (
								<div>
									<img
										src={competitionBanner?.file?.url}
										className={classes.banner}
									/>
								</div>
							)}
							<Row>
								<Col xs={12}>
									{richText && renderRichText(richText, options)}
								</Col>
							</Row>
							<div>
								<Row nogutter>
									<Col xs={12} lg={6}>
										<div style={isSmallScreen ? {} : { marginRight: 10 }}>
											{competitionInfoBox(
												entriesClose?.title,
												entriesClose?.value
											)}
										</div>
									</Col>
									<Col xs={12} lg={6}>
										<div style={isSmallScreen ? {} : { marginLeft: 10 }}>
											{competitionInfoBox(prizes?.title, prizes?.value)}
										</div>
									</Col>
								</Row>
							</div>
							<div className={classes.formSectionContent}>
								<FormSection
									sectionTitle="Enter Now"
									applyBorderRadius
									withBottomBorder
								>
									{!isSubmitDisabled && (
										<>
											<Row className={classes.formRowFirst}>
												<Col xs={12} lg={6}>
													<TextInputField
														id={`${PAGE_ID}-first-name-input`}
														label="First Name"
														value={values.firstName.value}
														onChange={(e) => {
															handleChange(e, 'firstName');
														}}
														validationError={errors.firstName}
														isRequired
														fullWidth
													/>
												</Col>
												<Col xs={12} lg={6}>
													<TextInputField
														id={`${PAGE_ID}-last-name-input`}
														label="Last Name"
														value={values.lastName.value}
														onChange={(e) => {
															handleChange(e, 'lastName');
														}}
														validationError={errors.lastName}
														isRequired
														fullWidth
													/>
												</Col>
											</Row>
											<Row className={classes.formRow}>
												<Col xs={12} lg={6}>
													<TextInputField
														id={`${PAGE_ID}-phone-number-input`}
														label="Phone Number"
														value={values.phoneNumber.value}
														onChange={(e) => {
															handleChange(e, 'phoneNumber');
														}}
														validationError={errors.phoneNumber}
														isRequired
														fullWidth
													/>
												</Col>
												<Col xs={12} lg={6}>
													<DateInputField
														label="Date of Birth"
														value={values.dateOfBirth.value}
														isRequired
														fullWidth
														validationError={errors.dateOfBirth ? true : false}
														onChange={(value) =>
															handleDateUpdate(value, 'dateOfBirth')
														}
													/>
													{errors.dateOfBirth && (
														<div className={classes.errorText}>
															<ValidationErrorText
																errorText={errors.dateOfBirth}
															/>
														</div>
													)}
												</Col>
											</Row>
											<Row
												className={classes.formRow}
												style={{ marginLeft: 0 }}
											>
												<Col xs={12} lg={6}>
													<div className={classes.checkboxContainer}>
														<SimpleCheckBox
															changeCheckboxState={(id) =>
																handleCheckbox(id, 'competitionsCheckBox')
															}
															custom
															customStyle={
																errors.competitionsCheckBox
																	? { float: 'left', border: '1px solid red' }
																	: { float: 'left' }
															}
															data={{
																id: `${PAGE_ID}-competitionsCheckBox`,
																isSelected: values.competitionsCheckBox.value
															}}
														/>
														<div style={{ marginLeft: 15 }}>
															By entering this competition, I agree to the{' '}
															<a
																href="/competition-terms-and-conditions"
																target="_blank"
															>
																Terms and Conditions
															</a>
															.*
														</div>
													</div>
												</Col>
											</Row>
											<Row className={classes.formRow}>
												<Col xs={12}>
													<TextInputField
														id={`${PAGE_ID}-see-more-input`}
														label={`We’d love to hear your feedback about the Off The Track Community. 
														Please tell us about the types of stories or videos you’d like to read and learn more about.
														Are there any other features that you’d like us to launch?`}
														value={values.seeMore.value}
														onChange={(event) => {
															handleChange(event, 'seeMore');
														}}
														fullWidth
													/>
												</Col>
											</Row>
											<Row>
												<Col>
													<PrimaryButton
														onClick={handleSubmit}
														id={`${PAGE_ID}-submit`}
														style={{
															float: 'right',
															maxWidth: '150px',
															marginRight: 45
														}}
													>
														Submit
													</PrimaryButton>
												</Col>
											</Row>
										</>
									)}
									<Row>
										<Col>
											{isSubmitDisabled && (
												<MessageBar
													className={classes.disableReasonBar}
													type={MessageBarTypeEnum.info}
												>
													{disableReason}
												</MessageBar>
											)}
										</Col>
									</Row>
								</FormSection>
							</div>
						</>
					)}
					{!competitionIsActive && (
						<>
							{!isSmallScreen && (
								<TwoColLayout>
									<TwoColLayout.Col1>
										<Container>
											<div className={classes.notActiveText}>
												There are no competitions currently active.
												<br />
												Please click{' '}
												<a href={DASHBOARD_ROUTE} className={classes.link}>
													here
												</a>{' '}
												to return to the dashboard.
											</div>
										</Container>
									</TwoColLayout.Col1>
									<Hidden xs sm>
										<TwoColLayout.Col2>
											<div className={classes.competitionImageDisplay}></div>
										</TwoColLayout.Col2>
									</Hidden>
								</TwoColLayout>
							)}
							{isSmallScreen && (
								<>
									<Row>
										<Col xs={12}>
											<div
												className={classes.competitionImageDisplayMobile}
											></div>
										</Col>
									</Row>
									<Row>
										<Col xs={12}>
											<div className={classes.textWrapper}>
												<div className={classes.notActiveText}>
													There are no competitions currently active.
													<br />
													Please click{' '}
													<a href={DASHBOARD_ROUTE} className={classes.link}>
														here
													</a>{' '}
													to return to the dashboard.
												</div>
											</div>
										</Col>
									</Row>
								</>
							)}
						</>
					)}
				</>
			)}
		</PageContainer>
	);
};

export default CompetitionPage;

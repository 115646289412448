import { DEFAULT_COUNTRY } from '../../utils/constants';

export const getDataModel = (stateVal, data, isAdminUser) => {
	let dataModel = { ...stateVal };

	dataModel = {
		// Event Details
		eventName: {
			...dataModel.eventName,
			value: data?.eventName
		},
		eventDisciplines: {
			...dataModel.eventDisciplines,
			value: data?.eventDisciplineId
		},
		address: {
			...dataModel.eventAddress,
			value: data?.eventAddress
		},
		manualAddress: {
			...dataModel.manualAddress,
			value: data?.manualAddress
		},
		suburb: {
			...dataModel.suburb,
			value: data?.eventSuburb
		},
		postcode: {
			...dataModel.postcode,
			value: data?.eventPostcode
		},
		state: {
			...dataModel.state,
			value: data?.eventState
		},
		country: {
			...dataModel.country,
			value: data?.eventCountry ? data?.eventCountry : DEFAULT_COUNTRY
		},
		eventEndDate: {
			...dataModel.eventEndDate,
			value: data.eventEndDate ? new Date(data.eventEndDate) : null
		},
		eventStartDate: {
			...dataModel.eventStartDate,
			value: data.eventStartDate ? new Date(data.eventStartDate) : null
		},
		startTime: {
			...dataModel.eventStartTime,
			value: data.eventStartTime
				? new Date().setUTCHours(
						parseInt(data.eventStartTime.substring(0, 2)),
						parseInt(data.eventStartTime.substring(3, 5)),
						parseInt(data.eventStartTime.substring(6, 8))
				  )
				: null
		},
		endTime: {
			...dataModel.eventEndTime,
			value: data.eventEndTime
				? new Date().setUTCHours(
						parseInt(data.eventEndTime.substring(0, 2)),
						parseInt(data.eventEndTime.substring(3, 5)),
						parseInt(data.eventEndTime.substring(6, 8))
				  )
				: null
		},
		ottSponsoredClasses: {
			...dataModel.ottSponsoredClasses,
			value: data.ottSponsoredClasses
				? getSponsoredClasses(data.ottSponsoredClasses)
				: []
		},
		howToEnter: {
			...dataModel.howToEnter,
			value: data?.howToEnter
		},
		entriesOpenDate: {
			...dataModel.entriesOpenDate,
			value: data.entriesOpenDate ? new Date(data.entriesOpenDate) : null
		},
		entriesCloseDate: {
			...dataModel.entriesCloseDate,
			value: data.entriesCloseDate ? new Date(data.entriesCloseDate) : null
		},
		organiserLogo: {
			...dataModel.organiserLogo,
			value: data?.eventOrganiserImage?.filename
				? [data?.eventOrganiserImage]
				: []
		},
		organiserContactDetails: {
			...dataModel.organiserContactDetails,
			value: data?.organiserContactDetails
		},
		ottSponsorshipDetails: {
			...dataModel.ottSponsorshipDetails,
			value: data?.ottSponsorshipDetails
		},
		judgingDetails: {
			...dataModel.judgingDetails,
			value: data?.judgingDetails
		},
		photographerDetails: {
			...dataModel.photographerDetails,
			value: data?.photographerDetails
		},
		socialMediaLinks: {
			...dataModel.socialMedia,
			value: data?.socialMedia
		},
		limitedEntries: {
			...dataModel.limitedEntries,
			value: data?.limitedEntries
		},
		otherInformation: {
			...dataModel.otherInformation,
			value: data?.otherInformation
		},
		otherDiscipline: {
			...dataModel.otherDiscipline,
			value: data?.disciplineOther
		},
		status: {
			...dataModel.complete,
			value: data?.status
		},
		organiserOther: {
			...dataModel.organiserOther,
			value: data?.organiserOther
		},
		isCancelled: {
			...dataModel.isCancelled,
			value: data?.isCancelled
		},
		isAddEventResults: {
			...dataModel.isAddEventResults,
			value: data?.isAddEventResults ?? false
		},
		eventCancellationReason: {
			...dataModel.eventCancellationReason,
			value: data?.eventCancellationReason
		},
		cancellationReason: {
			...dataModel.cancellationReason,
			value: data?.cancellationReason
		},
		cancellationReasonOther: {
			...dataModel.cancellationReasonOther,
			value: data?.cancellationReasonOther
		},
		dateNotified: {
			...dataModel.dateNotified,
			value: data?.dateNotified
		},
		otherDetails: {
			...data.otherDetails,
			value: data?.otherDetails
		},
		isPreviouslyCancelled: {
			...data.isPreviouslyCancelled,
			value: data?.status === 'Approved' && data?.isCancelled
		}
	};

	if (isAdminUser) {
		dataModel = {
			...dataModel,
			eventOrganiser: {
				...dataModel.eventOrganiser,
				value: data?.eventOrganiserId
			}
		};
	}

	return dataModel;
};

const getSponsoredClasses = (sponsoredClasses) => {
	let types = [];
	sponsoredClasses?.split(',').map((val) => {
		types.push({ label: val, value: val });
	});
	return types;
};

export const setEventResultInput = (input, formId, memberExternalId) => {
	return {
		eventId: formId,
		memberExternalId,
		class: input.class,
		horseCode: input.horse?.horseCode || null,
		racingHorseName: input.racingHorseName,
		riderName: input.riderName,
		result: input.result,
		comments: input.comments
	};
};

import React from 'react';
import { Row, Col } from 'react-grid-system';
import PrivateImage from '../../assets/images/private_horse_visibility.png';
import FollowersImage from '../../assets/images/followers_horse_visibility.png';
import PublicImage from '../../assets/images/public_horse_visibility.png';
import useStyles from './styles';
import OptionExplanation from './components/OptionExplanation';

const HorseProfileVisibilityHelperSection = ({ hintMessage }) => {
	const classes = useStyles();
	return (
		<Col>
			<Row>
				<Col>
					<div className={classes.visibilityHelperContentContainer}>
						<p className={classes.visibilityHelperText}>{hintMessage}</p>
						<Row style={{ flexWrap: 'wrap' }}>
							<OptionExplanation
								img={PrivateImage}
								imgAlt="private dropdown option  image"
								imgHeight={23}
								imageWidth={80}
								explanation="(visible to you)"
							/>
							<OptionExplanation
								img={FollowersImage}
								imgAlt="followers dropdown option  image"
								imgHeight={23}
								imageWidth={90}
								explanation="(visible to followers)"
							/>
							<OptionExplanation
								img={PublicImage}
								imgAlt="public dropdown option  image"
								imgHeight={23}
								imageWidth={80}
								explanation="(visible to all)"
							/>
						</Row>
					</div>
				</Col>
			</Row>
		</Col>
	);
};

export default HorseProfileVisibilityHelperSection;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	mobileContainer: {
		width: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	bannerStyles: {
		boxSizing: 'border-box',
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	articleText: {
		fontSize: 13,
		marginTop: 15
	},
	centerDiv: {
		maxWidth: 1000,
		marginLeft: 'auto',
		marginRight: 'auto'
	}
}));

import React, { useState, useEffect } from 'react';
import useStyles from './styles.js';
import { useTheme } from 'react-jss';
import classnames from 'classnames';

function SimpleCheckBox({
	data,
	changeCheckboxState,
	verticalDisplay,
	small,
	noCapitalize,
	isHidden,
	custom,
	customStyle,
	...rest
}) {
	const theme = useTheme();
	const classes = useStyles(theme);
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(data.isSelected);
	}, [data.isSelected]);

	const updateCheckbox = () => {
		changeCheckboxState(data.id);
	};
	const { horseProfile } = rest;
	const renderCheckbox = () => {
		if (verticalDisplay) {
			return (
				<div style={{ float: 'left', marginBottom: 10 }}>
					<div style={{ width: 65 }}>
						<label>
							<div
								onClick={updateCheckbox}
								onKeyPress={updateCheckbox}
								role="checkbox"
								aria-checked={isChecked}
								id={data.id}
								name={data.id}
								className={classnames({
									[classes.checkBox]: true,
									[classes.selected]: isChecked
								})}
								style={{ margin: 'auto' }}
								tabIndex={0}
							></div>
						</label>
					</div>
					<div
						className={classes.verticalLabel}
						style={{ width: 65, textAlign: 'center', wordBreak: 'break-word' }}
					>
						{data.label}
					</div>
				</div>
			);
		} else if (custom) {
			return (
				<label>
					<div
						onClick={updateCheckbox}
						onKeyPress={updateCheckbox}
						role="checkbox"
						aria-checked={isChecked}
						id={data.id}
						name={data.id}
						className={classnames({
							[classes.checkBox]: true,
							[classes.selected]: isChecked
						})}
						style={customStyle}
						tabIndex={0}
					></div>
				</label>
			);
		} else {
			return (
				<div className={classes.flexContainer} {...rest}>
					<div className={classes.flexFirst}>
						<label>
							<div
								onClick={updateCheckbox}
								onKeyPress={updateCheckbox}
								role="checkbox"
								aria-checked={isChecked}
								id={data.id}
								name={data.id}
								className={classnames({
									[classes.checkBox]: true,
									[classes.selected]: isChecked
								})}
								style={{
									...(isHidden && { visibility: 'hidden' }),
									...(!horseProfile && { paddingBottom: 13 })
								}}
								tabIndex={0}
							></div>
						</label>
					</div>
					<div className={classes.flexLast}>
						<div
							style={small ? { paddingTop: 17 } : {}}
							className={
								noCapitalize ? classes.noCapitalizeTitle : classes.headingtitle
							}
						>
							{data.label}
						</div>
					</div>
				</div>
			);
		}
	};

	return <>{renderCheckbox()}</>;
}

export default SimpleCheckBox;

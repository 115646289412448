import React from 'react';
import StyledSelectWrapper from '../StyledSelectWrapper';

function FormDropDown({
	items,
	name,
	onChange,
	selectedId,
	styles,
	showBlank,
	id,
	disabled,
	isError,
	placeholder = ''
}) {
	const renderOptions =
		items?.length > 0 &&
		items.map((item) => {
			if (item.id == '' && !showBlank) {
				return (
					<option
						key={item.id}
						value={item.id}
						disabled={item.disabled ? true : false}
					>
						Any
					</option>
				);
			} else {
				return (
					<option
						key={item.id}
						value={item.id}
						disabled={item.disabled ? true : false}
					>
						{item.value}
					</option>
				);
			}
		});

	return (
		<StyledSelectWrapper isError={isError}>
			<select
				id={id}
				style={styles}
				name={name}
				onChange={onChange}
				value={selectedId}
				disabled={disabled}
			>
				{placeholder != ''
					? [
							<option value="" key="placeholder" disabled selected hidden>
								{placeholder}
							</option>,
							...renderOptions
					  ]
					: renderOptions}
			</select>
		</StyledSelectWrapper>
	);
}

export default FormDropDown;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	table: {
		borderCollapse: 'collapse',
		width: '100%'
	},
	th: {
		paddingTop: 12,
		paddingBottom: 12,
		textAlign: 'left',
		fontSize: 12,
		fontWeight: 400
	},
	thead: {
		border: '1px solid #b3bcc1'
	},
	tr: {
		background: '#f5f5f5'
	},
	td: {
		paddingTop: 12,
		paddingBottom: 12
	},
	tdToggle: {
		paddingRight: 20
	},
	thname: {
		paddingLeft: 20,
		paddingTop: 12,
		paddingBottom: 12,
		textAlign: 'left',
		fontSize: 12,
		fontWeight: 400,
		display: 'flex',
		alignItems: 'center'
	},
	thButton: {
		paddingRight: 20
	},
	tbodyName: {
		paddingLeft: 20,
		fontWeight: 600
	},
	tbodyButton: {
		paddingRight: 20,
		marginTop: 5
	},
	tbody: {
		border: '1px solid #b3bcc1'
	},
	trbody: {
		fontSize: 14,
		borderBottom: '1px solid #dcebf3',
		'&:last-child': {
			borderBottom: 'none'
		}
	},
	trbodyLast: {
		fontSize: 14,
		borderBottom: '1px solid #b3bcc1'
	},
	sortIcon: {
		position: 'absolute'
	},
	sort: {
		height: 18
	},
	tdEmptyList: {
		padding: 12,
		fontSize: 14
	},
	cellDropDownWrapper: {
		'@media (min-width:992px)': {
			paddingRight: 15
		}
	},
	tbodyProfilePic: {
		paddingLeft: 20
	},
	profilePic: {
		cursor: 'pointer'
	},
	tbodyNameSpan: {
		color: '#006da6',
		cursor: 'pointer',
		fontWeight: 600
	},
	mobileContainer: {
		padding: 20
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	fieldTitleMobile: {
		extend: 'fieldTitle',
		marginTop: 15
	},
	fieldContainer: {
		paddingTop: 15,
		overflow: 'auto'
	},
	checkboxMobileContainer: {
		paddingTop: 10
	}
}));

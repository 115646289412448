export const getResetDraftForms = `
query getResetDraftForms($memberExternalId: String!) {
    getResetWeeklyProgressForms(memberExternalId: $memberExternalId, status: Draft) {
        id
    }
	getResetInitialAssessmentForms(memberExternalId: $memberExternalId, status: Draft) {
        id
    }
  }
`;

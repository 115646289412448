import React, { useEffect, useState } from 'react';
import { MessageBarTypeEnum } from '../../utils/enums';
import InfoBar from '../InfoBar';
import useStyles from './styles';
import { formatDate } from '../../utils/helpers';
import { useToast } from '../../hooks/useToast';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getUserImage,
	uploadImageToS3,
	getResetInitialAssessmentForm,
	saveResetInitialAssessmentForm,
	saveResetReportingImage,
	submitResetInitialReportsForReview
} from '../../graphql/custom.queries';
import axios from 'axios';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import ResizingTextArea from '../ResizingTextArea';
import PrimaryButton from '../PrimaryButton';
import Toggle from '../Toggle';
import { useAuth } from '../../hooks/useAuth';
import FormDatePicker from '../FormDatePicker';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import LoaderSpinner from '../LoaderSpinner';

const schema = yup.object().shape({
	FeedingPlan: yup
		.string()
		.required('You must enter the initial feeding plan assessment.'),
	Temperament: yup
		.string()
		.required('You must enter the initial temperament assessment.'),
	RiddenAssessment: yup
		.string()
		.required('You must enter the initial ridden assessment.'),
	OverallCondition: yup
		.string()
		.required('You must enter the overall condition assessment.'),
	Repurposing: yup
		.string()
		.required('You must enter initial thoughts on possible repurposing/use.'),
	Timeframe: yup
		.string()
		.required('You must enter initial thoughts on the retraining timeframe.')
});

const infoText =
	'As this is your first report, please complete the following Initial Assessment form within 14 days of the horse arriving.';

const RESETInitialAssessmentForm = ({ horseCode, reload }) => {
	const screenClassForRender = useScreenClass();
	let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, control, setValue } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});
	const { currentUser } = useAuth();
	const [leftSidePhotos, setLeftSidePhotos] = useState([]);
	const [rightSidePhotos, setRightSidePhotos] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [overallCondition, setOverallCondition] = useState('');
	const [worming, setWorming] = useState(false);
	const [farrier, setFarrier] = useState(false);
	const [dentist, setDentist] = useState(false);
	const [otherHusbandryInfo, setOtherHusbandryInfo] = useState('');
	const [feedingPlan, setFeedingPlan] = useState('');
	const [temperament, setTemperament] = useState('');
	const [riddenAssessment, setRiddenAssessment] = useState('');
	const [repurposing, setRepurposing] = useState('');
	const [timeframe, setTimeframe] = useState('');
	const [nonContinueReason, setNonContinueReason] = useState(false);
	const [nonContinueExplanation, setNonContinueExplanation] = useState('');
	const [wormingDate, setWormingDate] = useState(null);
	const [farrierDate, setFarrierDate] = useState(null);
	const [dentistDate, setDentistDate] = useState(null);
	const [dateOfAssessment, setDateOfAssessment] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [shouldValidate, setShouldValidate] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [overallConditionError, setOverallConditionError] = useState(false);
	const [feedingError, setFeedingError] = useState(false);
	const [temperamentError, setTemperamentError] = useState(false);
	const [riddenAssessmentError, setRiddenAssessmentError] = useState(false);
	const [repurposingError, setRepurposingError] = useState(false);
	const [timeframeError, setTimeframeError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	let isValidationErrors = false;
	const validateUncontrolledErrors = () => {
		validateLeftSidePhotos();
		validateRightSidePhotos();
		validateNonContinueExplanation();
		validateWormingDate();
		validateFarrierDate();
		validateDentistDate();
		validateAssessmentDate();
		if (overallCondition.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setOverallConditionError(
				'You must enter the overall condition assessment.'
			);
		} else {
			setOverallConditionError('');
		}
		if (feedingPlan.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setFeedingError('You must enter the initial feeding plan assessment.');
		} else {
			setFeedingError('');
		}
		if (temperament.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setTemperamentError('You must enter the initial temperament assessment.');
		} else {
			setTemperamentError('');
		}
		if (riddenAssessment.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setRiddenAssessmentError('You must enter the initial ridden assessment.');
		} else {
			setRiddenAssessmentError('');
		}
		if (repurposing.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setRepurposingError(
				'You must enter initial thoughts on possible repurposing/use.'
			);
		} else {
			setRepurposingError('');
		}
		if (timeframe.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setTimeframeError(
				'You must enter initial thoughts on the retraining timeframe.'
			);
		} else {
			setTimeframeError('');
		}
	};

	const validateLeftSidePhotos = () => {
		if (leftSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: {
					message: 'You must upload at least one left side photo'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: { message: null }
			}));
		}
	};

	const validateRightSidePhotos = () => {
		if (rightSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: {
					message: 'You must upload at least one right side photo'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: { message: null }
			}));
		}
	};

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			onSubmit(true);
		}
	};

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	useEffect(() => {
		if (uncontrolledErrors?.leftSidePhotos?.message) {
			validateLeftSidePhotos();
		}
	}, [leftSidePhotos]);

	useEffect(() => {
		if (uncontrolledErrors?.rightSidePhotos?.message) {
			validateRightSidePhotos();
		}
	}, [rightSidePhotos]);

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const validateNonContinueExplanation = () => {
		if (nonContinueReason && nonContinueExplanation === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				nonContinueExplanation: {
					message:
						'You must enter a reason for the horse to not continue in RESET.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				nonContinueExplanation: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.nonContinueExplanation?.message) {
			validateNonContinueExplanation();
		}
	}, [nonContinueReason, nonContinueExplanation]);

	const validateWormingDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WormingDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (worming && !wormingDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: {
						message: 'You must enter a Date of Completion for Worming.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.WormingDate?.message) {
			validateWormingDate();
		}
	}, [worming, wormingDate]);

	const validateAssessmentDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				AssessmentDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!dateOfAssessment) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: {
						message: 'You must enter Date of Assessment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.AssessmentDate?.message) {
			validateAssessmentDate();
		}
	}, [dateOfAssessment]);

	const validateFarrierDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FarrierDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (farrier && !farrierDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: {
						message: 'You must enter a Date of Completion for the Farrier.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.FarrierDate?.message) {
			validateFarrierDate();
		}
	}, [farrier, farrierDate]);

	const validateDentistDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				DentistDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (dentist && !dentistDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: {
						message: 'You must enter a Date of Completion for Dentistry.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.DentistDate?.message) {
			validateDentistDate();
		}
	}, [dentist, dentistDate]);

	useEffect(() => {
		const horseInput = {
			horseCode: horseCode,
			memberExternalId: currentUser?.UserId
		};
		AppSyncService.execute(getResetInitialAssessmentForm, horseInput).then(
			(data) => {
				if (data.data != null) {
					let initialData = data.data.getResetInitialAssessmentForm;
					if (!initialData?.id) setIsShowErrorMessages(false);
					else setIsShowErrorMessages(true);
					setLeftSidePhotos(initialData.leftSidePhotos);
					setRightSidePhotos(initialData.rightSidePhotos);
					setOverallCondition(initialData.overallCondition ?? '');
					setValue('OverallCondition', initialData.overallCondition ?? '');
					setWorming(initialData.worming ? true : false);
					setFarrier(initialData.farrier ? true : false);
					setDentist(initialData.dentist ? true : false);
					setWormingDate(initialData.worming);
					setFarrierDate(initialData.farrier);
					setDentistDate(initialData.dentist);
					setDateOfAssessment(initialData.dateOfAssessment);
					setOtherHusbandryInfo(initialData.otherHusbandryInfo ?? '');
					setFeedingPlan(initialData.feedingRequirementsPlan ?? '');
					setValue('FeedingPlan', initialData.feedingRequirementsPlan ?? '');
					setTemperament(initialData.temperament ?? '');
					setValue('Temperament', initialData.temperament ?? '');
					setRiddenAssessment(initialData.groundwork ?? '');
					setValue('RiddenAssessment', initialData.groundwork ?? '');
					setRepurposing(initialData.possibleRepurposing ?? '');
					setValue('Repurposing', initialData.possibleRepurposing ?? '');
					setNonContinueExplanation(initialData.notContinueDetails ?? '');
					setNonContinueReason(initialData.notContinueDetails ? true : false);
					setTimeframe(initialData.retrainingTimeframe ?? '');
					setValue('Timeframe', initialData.retrainingTimeframe ?? '');
				}
			}
		);
	}, []);

	const updateDate = (value, stateAssignmentFunction, validationFunction) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			validationFunction(false);
		} else {
			validationFunction(true);
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const onSubmit = (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const input = {
			horseCode: horseCode,
			weekId: 1,
			overallCondition: overallCondition,
			worming: wormingDate,
			farrier: farrierDate,
			dentist: dentistDate,
			otherHusbandryInfo: otherHusbandryInfo,
			feedingRequirementsPlan: feedingPlan,
			temperament: temperament,
			groundwork: riddenAssessment,
			possibleRepurposing: repurposing,
			retrainingTimeframe: timeframe,
			notContinueDetails: nonContinueExplanation,
			dateOfAssessment: dateOfAssessment
		};
		setIsLoading(true);
		AppSyncService.execute(saveResetInitialAssessmentForm, { input }).then(
			(data) => {
				if (data.data !== null) {
					let photoArrays = [
						{ name: 'leftSide', arr: leftSidePhotos },
						{ name: 'rightSide', arr: rightSidePhotos }
					];
					photoArrays.forEach((photoArray, index) => {
						photoArray.arr.map((photo) => {
							AppSyncService.execute(saveResetReportingImage, {
								filename: photo.filename,
								key: photo.key,
								reportType: 'ResetInitial',
								reportId: data.data.saveResetInitialAssessmentForm.id,
								type: photoArrays[index].name
							});
						});
					});
					setIsShowErrorMessages(true);
					if (!isSubmitReport) {
						reload();
						addToast({ Message: 'Draft Saved', IsSuccess: true });
					} else {
						AppSyncService.execute(submitResetInitialReportsForReview, {
							horseCode: horseCode
						}).then((data) => {
							if (data?.data?.submitResetInitialReportsForReview) {
								addToast({
									Message: 'Initial RESET Form Submitted',
									IsSuccess: true
								});
							} else {
								addToast({
									Message:
										'An error submitting. Racing Victoria are investigating.',
									IsSuccess: false
								});
							}
						});
					}
					if (!isSubmitReport) {
						if (!isValidationErrors) {
							setDisableSubmitbutton(false);
						}
					}
					setIsLoading(false);
				}
			}
		);
	};

	const clearForm = () => {
		deleteAllPhotos();
		setOverallCondition('');
		setWorming(false);
		setFarrier(false);
		setDentist(false);
		setOtherHusbandryInfo('');
		setFeedingPlan('');
		setTemperament('');
		setTemperament('');
		setRiddenAssessment('');
		setRepurposing('');
		setTimeframe('');
		setNonContinueReason(false);
		setNonContinueExplanation('');
		setWormingDate(null);
		setFarrierDate(null);
		setDentistDate(null);
		setDateOfAssessment(null);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
					setShouldValidate(true);
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[leftSidePhotos, rightSidePhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setLeftSidePhotos([]);
					setRightSidePhotos([]);
				}
			});
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={classes.formHeading}>Initial Assessment Form</div>
					<InfoBar
						type={MessageBarTypeEnum.info}
						infoText={infoText}
						noButton
					/>
					<Container>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Date of Assessment*</div>
									<FormDatePicker
										id={HorseManagementKeys.DATE_OF_ASSESSMENT}
										DateValue={dateOfAssessment}
										updateSelection={(value) => {
											updateDate(
												value,
												setDateOfAssessment,
												validateAssessmentDate
											);
											setShouldValidate(true);
										}}
									/>
								</div>
								{isShowErrorMessages && (
									<span style={{ color: 'red' }}>
										{uncontrolledErrors?.AssessmentDate?.message}
									</span>
								)}
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Upload Left Side*</div>
									<UploadPhoto
										id={HorseManagementKeys.INITIAL_LEFT_SIDE_PHOTO}
										formats={['png', 'jpeg']}
										labelName="Upload Left Side"
										error={uploadError}
										data={leftSidePhotos}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(e) => photosUpload(e, setLeftSidePhotos)}
										getImage={getImage}
										removeImage={(e) =>
											removePhoto(e, leftSidePhotos, setLeftSidePhotos)
										}
									/>
								</div>
								{isShowErrorMessages && (
									<span style={{ color: 'red' }}>
										{uncontrolledErrors?.leftSidePhotos?.message}
									</span>
								)}
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Upload Right Side*</div>
									<UploadPhoto
										id={HorseManagementKeys.INITIAL_RIGHT_SIDE_PHOTO}
										formats={['png', 'jpeg']}
										labelName="Upload Right Side"
										error={uploadError}
										data={rightSidePhotos}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(e) => photosUpload(e, setRightSidePhotos)}
										getImage={getImage}
										removeImage={(e) =>
											removePhoto(e, rightSidePhotos, setRightSidePhotos)
										}
									/>
								</div>
								{isShowErrorMessages && (
									<span style={{ color: 'red' }}>
										{uncontrolledErrors?.rightSidePhotos?.message}
									</span>
								)}
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="OverallCondition"
										render={({ onChange }) => (
											<ResizingTextArea
												title={
													<div>
														Describe overall condition{' '}
														<span className={classes.fontWeightNormal}>
															(body condition, feet condition, note any
															superficial wounds/abrasions)
														</span>
														*
													</div>
												}
												id={HorseManagementKeys.OVERALL_CONDITION}
												onChange={(e) => {
													onChange(e.target.value);
													setOverallCondition(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={overallCondition}
											/>
										)}
									/>
									{overallConditionError && isShowErrorMessages && (
										<ValidationErrorText errorText={overallConditionError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Worming</div>
									<Toggle
										id={HorseManagementKeys.WORMING}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => {
											setWorming(value);
											setShouldValidate(true);
										}}
										value={worming}
									/>
								</div>
							</Col>
							{worming && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Date Of Completion*
										</div>
										<FormDatePicker
											id={HorseManagementKeys.WORMING_DATE_OF_COMPLETION}
											DateValue={wormingDate}
											updateSelection={(value) => {
												updateDate(value, setWormingDate, validateWormingDate);
												setShouldValidate(true);
											}}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.WormingDate?.message}
										</span>
									)}
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Farrier</div>
									<Toggle
										id={HorseManagementKeys.FARRIER}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => {
											setFarrier(value);
											setShouldValidate(true);
										}}
										value={farrier}
									/>
								</div>
							</Col>
							{farrier && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Date Of Completion*
										</div>
										<FormDatePicker
											id={HorseManagementKeys.FARRIER_DATE_OF_COMPLETION}
											DateValue={farrierDate}
											updateSelection={(value) => {
												updateDate(value, setFarrierDate, validateFarrierDate);
												setShouldValidate(true);
											}}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.FarrierDate?.message}
										</span>
									)}
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Dentist</div>
									<Toggle
										id={HorseManagementKeys.DENTIST}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => {
											setDentist(value);
											setShouldValidate(true);
										}}
										value={dentist}
									/>
								</div>
							</Col>
							{dentist && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Date Of Completion*
										</div>
										<FormDatePicker
											id={HorseManagementKeys.DENTIST_DATE_OF_COMPLETION}
											DateValue={dentistDate}
											updateSelection={(value) => {
												updateDate(value, setDentistDate, validateDentistDate);
												setShouldValidate(true);
											}}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.DentistDate?.message}
										</span>
									)}
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<ResizingTextArea
										title="Other Husbandry Information"
										id={HorseManagementKeys.OTHER_HUSBANDRY_INFO}
										onChange={(e) => setOtherHusbandryInfo(e.target.value)}
										maxChars={500}
										value={otherHusbandryInfo}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="FeedingPlan"
										render={({ onChange }) => (
											<ResizingTextArea
												title={
													<div>
														Assessment re feeding requirements/plan{' '}
														<span className={classes.fontWeightNormal}>
															(feeding to maintain or increase condition,
															feeding to let down etc)
														</span>
														*
													</div>
												}
												id={HorseManagementKeys.FEEDING_REQUIREMENT_PLAN}
												onChange={(e) => {
													onChange(e.target.value);
													setFeedingPlan(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={feedingPlan}
											/>
										)}
									/>
									{feedingError && isShowErrorMessages && (
										<ValidationErrorText errorText={feedingError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="Temperament"
										render={({ onChange }) => (
											<ResizingTextArea
												title={
													<div>
														Assessment re temperament/behaviour{' '}
														<span className={classes.fontWeightNormal}>
															(nervous/relaxed/aggressive/sensitive)
														</span>
														*
													</div>
												}
												id={HorseManagementKeys.TEMPERAMENT}
												onChange={(e) => {
													onChange(e.target.value);
													setTemperament(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={temperament}
											/>
										)}
									/>
									{temperamentError && isShowErrorMessages && (
										<ValidationErrorText errorText={temperamentError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="RiddenAssessment"
										render={({ onChange }) => (
											<ResizingTextArea
												title={
													<div>
														Groundwork/Ridden Assessment{' '}
														<span className={classes.fontWeightNormal}>
															(Describe your sessions this week, what has been
															done, what went well and what requires work etc.)
														</span>
														*
													</div>
												}
												id={HorseManagementKeys.GROUNDWORK_RIDDEN_ASSESSMENT}
												onChange={(e) => {
													onChange(e.target.value);
													setRiddenAssessment(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={riddenAssessment}
											/>
										)}
									/>
									{riddenAssessmentError && isShowErrorMessages && (
										<ValidationErrorText errorText={riddenAssessmentError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="Repurposing"
										render={({ onChange }) => (
											<ResizingTextArea
												title="Your initial thoughts on possible repurposing / use?*"
												id={HorseManagementKeys.REPURPOSING}
												onChange={(e) => {
													onChange(e.target.value);
													setRepurposing(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={repurposing}
											/>
										)}
									/>
									{repurposingError && isShowErrorMessages && (
										<ValidationErrorText errorText={repurposingError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<Controller
										control={control}
										name="Timeframe"
										render={({ onChange }) => (
											<ResizingTextArea
												title="Your initial thoughts on retraining timeframe.*"
												id={HorseManagementKeys.TIMEFRAME}
												onChange={(e) => {
													onChange(e.target.value);
													setTimeframe(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={timeframe}
											/>
										)}
									/>
									{timeframeError && isShowErrorMessages && (
										<ValidationErrorText errorText={timeframeError} />
									)}
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Is there a physical or behavioural reason the horse should
										not continue in RESET?
									</div>
									<Toggle
										id={HorseManagementKeys.BEHAVIOURAL_REASON}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => {
											setNonContinueReason(value);
											setShouldValidate(true);
										}}
										value={nonContinueReason}
									/>
								</div>
							</Col>
						</Row>
						{nonContinueReason && (
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title="If yes, please explain*"
											id={HorseManagementKeys.BEHAVIOURAL_REASON_EXPLANATION}
											onChange={(e) => {
												setNonContinueExplanation(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={nonContinueExplanation}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.nonContinueExplanation?.message}
										</span>
									)}
								</Col>
							</Row>
						)}

						<hr className={classes.divider} />
						{isLoading ? (
							<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<div
									id={CommonKeys.CLEAR_FORM}
									onClick={() => clearForm()}
									className={classes.clearFormLink}
								>
									Clear Form
								</div>
								<div
									className={
										isMobile ? classes.saveRequestMobile : classes.saveRequest
									}
								>
									<span className={isMobile ? null : classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											onClick={() => onSubmit()}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											disabled={disableSubmitButton}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						)}
					</Container>
					{imgSrcUrl && (
						<Lightbox
							mainSrc={imgSrcUrl}
							onCloseRequest={() => setImgSrcUrl(false)}
						/>
					)}
					{showImageUploadErrorModal && (
						<UploadPhotoErrorModal
							showErrorModal={showImageUploadErrorModal}
							closeModal={() => setShowImageUploadErrorModal(false)}
							isMinimumUploadSize={isMinimumUploadSize}
						/>
					)}
				</form>
			</div>
		</div>
	);
};

export default RESETInitialAssessmentForm;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	componentContainer: {},
	searchHeader: {
		fontWeight: 800,
		color: '#464749',
		fontSize: 20,
		marginBottom: 10
	},
	searchDetails: {
		borderBottom: '2px solid #d9dde0',
		marginTop: 25,
		fontSize: 15
	},
	clear: {
		clear: 'both'
	},
	filterOrderBy: {
		float: 'left',
		marginLeft: 15,
		marginBottom: 3,
		cursor: 'pointer'
	},
	sortIcon: {
		float: 'right',
		marginTop: '-1px'
	},

	numberOfRecords: {
		float: 'right',
		marginRight: 15,
		color: '#757575'
	},
	searchResultContainer: {
		overflowY: 'auto',
		width: '100%',
		boxSizing: 'content-box',
		maxHeight: 500
	},
	searchResult: {
		borderBottom: '2px solid #d9dde0',
		paddingTop: 15,
		paddingBottom: 15,
		cursor: 'pointer'
	},
	horseName: {
		paddingLeft: 15,
		color: '#464749',
		fontSize: 22,
		fontWeight: 800
	},
	horseNameMobile: {
		paddingLeft: 15,
		color: '#464749',
		fontSize: 18,
		fontWeight: 800
	},
	additionalDetails: {
		paddingLeft: 15,
		display: 'block',
		fontStyle: 'italic',
		color: '#757575',
		fontSize: 14
	},
	additionalDetailsMobile: {
		paddingLeft: 13,
		display: 'block',
		color: '#757575',
		fontSize: 12,
		marginTop: 10
	},
	additionalInfo: {
		marginRight: 15
	},
	additionalInfoMobile: {
		marginRight: 15,
		background: '#ededed',
		padding: 3
	},
	loadMoreButton: {
		textAlign: 'center',
		background: '#dcebf3',
		color: '#026ea7',
		paddingTop: 10,
		paddingBottom: 10,
		fontWeight: 800,
		cursor: 'pointer'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%'
	},
	claimContainer: {
		background: '#fcf7da',
		fontSize: 12,
		marginTop: 10,
		padding: 10,
		marginBottom: 10
	},
	link: {
		color: '#0087ce'
	},
	dialogTextColor: {
		color: '#0f0f0f !important',
		fontSize: '18px !important'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px',
		width: '100%'
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		marginTop: 10,
		cursor: 'pointer'
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer'
	},
	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 400,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	}
}));

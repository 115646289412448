export const REGEX = 'REGEX';
export const CHARACTER_LIMIT = 'CHARACTER_LIMIT';
export const MULTI_DEPENDENT = 'MULTI_DEPENDENT';
export const TOGGLE_DEPENDENT = 'TOGGLE_DEPENDENT';
export const DEPENDENT_DROPDOWN = 'DEPENDENT_DROPDOWN';
export const CHECKBOX_DEPENDENCY = 'CHECKBOX_DEPENDENCY';
export const ARRAY_LENGTH_DEPENDENT = 'ARRAY_LENGTH_DEPENDENT';
export const ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE =
	'ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE';

export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (initial && values[f].conditions?.length > 0) {
			values[f].conditions.map((c) => {
				if (c.condition === CHARACTER_LIMIT) {
					if (values[f].value && values[f].value.length > c.limit) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === MULTI_DEPENDENT) {
					let dependentValueId = values[c.field].value.filter(
						(rec) => rec.label === c.value
					);
					if (
						dependentValueId.length > 0 &&
						(!values[f].value || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
			});
		}
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value?.length === 0 ||
				values[f].value === false) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'Field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

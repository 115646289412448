import React from 'react';
import { Col } from 'react-grid-system';
import useStyles from './styles';

const OptionBar = ({ description, children }) => {
	const classes = useStyles();
	return (
		<Col>
			<div className={classes.optionBar}>
				<Col
					lg={8}
					sm={12}
					style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}
				>
					{description}
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.optionBarInputContainer}>{children}</div>
				</Col>
			</div>
		</Col>
	);
};

export default OptionBar;

import React, { useState, useEffect } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import {
	updateMemberProfile,
	saveResidentialAddress,
	savePostalAddress,
	getProfileReferenceData,
	checkIfEmailExist,
	updateCurrentUserEmail
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import FormDatePicker from '../FormDatePicker';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import { formatDate, emailPattern } from '../../utils/helpers';
import SimpleCheckBox from '../SimpleCheckBox';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import { DEFAULT_COUNTRY } from '../../utils/constants';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';
import Dialog from '../Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import HeaderBanner from '../HeaderBanner';

// form validation rules
const schema = yup.object().shape({
	firstName: yup
		.string() //First name can have special characters
		.required('First Name is required')
		.matches(/^[a-z ,.'-]+$/i, 'Please enter valid First Name')
		.typeError('First Name is required'),
	lastName: yup
		.string() //Lastname can have special characters
		.required('Last Name is required')
		.matches(/^[a-z ,.'-]+$/i, 'Please enter valid Last Name')
		.typeError('Last Name is required'),
	preferredName: yup
		.string() //Preferred name can have special characters
		.nullable()
		.matches(/^[a-z ,.'-]+$/i, {
			message: 'Please enter valid preferred name',
			excludeEmptyString: true
		}),
	phoneNumber: yup
		.string()
		.required('Phone number is required')
		.matches(/^\+\d*$|^\d*$/, 'Please enter valid Phone number')
		.typeError('Phone number is required'),
	businessPhoneNumber: yup
		.string()
		.nullable()
		.matches(/^\+\d*$|^\d*$/, {
			message: 'Please enter valid Business Phone',
			excludeEmptyString: true
		}),
	dateOfBirth: yup.date().nullable(),
	gender: yup
		.string()
		.required('Gender is required')
		.matches(/[1-9][0-9]*/i, 'Please select a gender'),
	address: yup
		.string()
		.required('Address is required')
		.typeError('Address is required'),
	suburb: yup
		.string()
		.required('Suburb is required')
		.typeError('Suburb is required'),
	postcode: yup
		.string() //Alpahbet and numeric -
		.required('Postcode is required')
		.min(4, 'Postal code must be a minimum of 4 characters')
		.matches(
			/^\d+$/i,
			'Please enter valid postcode, postcode should only be numeric'
		)
		.typeError('Postal code is required'),
	state: yup
		.string()
		.required('State is required')
		.typeError('State is required'),
	country: yup
		.string()
		.required('Country is required')
		.typeError('Country is required')
});

const MemberProfile = ({ memberProfileData, isLoading, updateIsLoading }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { updateCurrentUserAttributes, verifyCurrentUserAttribute } = useAuth();
	const { handleSubmit, errors, control, setValue } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const screenClassForRender = useScreenClass();

	let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [preferredName, setPreferredName] = useState('');
	const [email, setEmail] = useState('');
	const [newEmail, setNewEmail] = useState('');
	const [verificationCode, setVerificationCode] = useState('');
	const [openChangeEmailDialog, setOpenChangeEmailDialog] = useState(false);
	const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
	const [changeEmailDialogTitle, setChangeEmailDialogTitle] = useState('');
	const [verifyEmailDialogTitle, setVerifyEmailDialogTitle] = useState('');

	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [emailExistLoading, setEmailExistLoading] = useState(false);
	const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);

	const [phoneNumber, setPhoneNumber] = useState('');
	const [businessPhoneNumber, setBusinessPhoneNumber] = useState('');
	const [dateOfBirth, setDateOfBirth] = useState(null);
	const [address, setAddress] = useState('');
	const [suburb, setSuburb] = useState('');
	const [postcode, setPostcode] = useState(0);
	const [state, setState] = useState('');
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [postalAddress, setPostalAddress] = useState('');
	const [postalSuburb, setPostalSuburb] = useState('');
	const [postalPostcode, setPostalPostcode] = useState('');
	const [postalState, setPostalState] = useState('');
	const [postalCountry, setPostalCountry] = useState(DEFAULT_COUNTRY);
	const [selectedGender, setSelectedGender] = useState(0);
	const [selectedYearsRiding, setSelectedYearsRiding] = useState(0);
	const [isFormDisabled, setIsFormDisabled] = useState(true);

	const [isPostalAddressSame, setIsPostalAddressSame] = useState(true);
	const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
	const [yearsRidingOptions, setYearsRidingOptions] = useState([]);
	const [dateOfBirthErrorMessage, setDateOfBirthErrorMessage] = useState(null);
	const [postalAddressErrorMessage, setPostalAddressErrorMessage] = useState(
		null
	);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const checkBoxData = {
		id: 'postalCheckBox',
		label: 'I have a different postal address',
		value: 'I have a different postal address',
		isSelected: !isPostalAddressSame
	};
	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add my address',
		value: 'Manually add my address',
		isSelected: isPostalAddressManual
	};

	useEffect(() => {
		if (memberProfileData) {
			setFirstName(memberProfileData.firstName);
			setValue('firstName', memberProfileData.firstName);
			setLastName(memberProfileData.lastName);
			setValue('lastName', memberProfileData.lastName);
			setPreferredName(memberProfileData.preferredName);
			setValue('preferredName', memberProfileData.preferredName);
			setEmail(memberProfileData.email);
			setValue('email', memberProfileData.email);
			setPhoneNumber(memberProfileData.phone);
			setValue('phoneNumber', memberProfileData.phone);
			setBusinessPhoneNumber(memberProfileData.businessPhone);
			setValue('businessPhoneNumber', memberProfileData.businessPhone);
			setDateOfBirth(memberProfileData.dateOfBirth);
			setValue('dateOfBirth', memberProfileData.dateOfBirth);
			let value;
			if (memberProfileData.gender != null) {
				value = genderOptions.find((x) => x.value === memberProfileData.gender)
					.id;
				setSelectedGender(value);
			}
			setValue('gender', value);
			setSelectedYearsRiding(memberProfileData.yearsRiding.id);
			setValue('yearsRiding', memberProfileData.yearsRiding.id);
			let memberProfileResidentialAddress =
				memberProfileData.residentialAddress;
			if (memberProfileResidentialAddress) {
				setAddress(memberProfileResidentialAddress.address);
				setValue('address', memberProfileResidentialAddress.address);
				setSuburb(memberProfileResidentialAddress.suburb);
				setValue('suburb', memberProfileResidentialAddress.suburb);
				setPostcode(memberProfileResidentialAddress.postcode);
				setValue('postcode', memberProfileResidentialAddress.postcode);
				const stateValue = memberProfileResidentialAddress.state || '';
				setState(stateValue);
				setValue('state', stateValue);
				let countryValue = DEFAULT_COUNTRY;
				if (memberProfileResidentialAddress.country) {
					countryValue = memberProfileResidentialAddress.country;
				}
				setCountry(countryValue);
				setValue('country', countryValue);
			}
			let memberProfilePostalAddress = memberProfileData.postalAddress;
			if (
				memberProfilePostalAddress != null &&
				memberProfilePostalAddress.address != null
			) {
				setPostalAddress(memberProfilePostalAddress.address);
				setValue('postalAddress', memberProfilePostalAddress.address);
				setPostalSuburb(memberProfilePostalAddress.suburb);
				setValue('postalSuburb', memberProfilePostalAddress.suburb);
				setPostalPostcode(memberProfilePostalAddress.postcode);
				setValue('postalPostcode', memberProfilePostalAddress.postcode);
				const postalStateValue = memberProfilePostalAddress.state || '';
				setPostalState(postalStateValue);
				setValue('postalState', postalStateValue);
				let countryValue = DEFAULT_COUNTRY;
				if (memberProfilePostalAddress.country) {
					countryValue = memberProfilePostalAddress.country;
				}
				setPostalCountry(countryValue);
				setValue('postalCountry', countryValue);
				if (
					!memberProfilePostalAddress.address ||
					memberProfilePostalAddress.address.length > 0
				) {
					changeIsPostAddressData(false);
				} else {
					changeIsPostAddressData(true);
				}
			} else {
				changeIsPostAddressData(true);
			}
			updateIsLoading(false);
			setIsFormDisabled(true);
		}

		AppSyncService.execute(getProfileReferenceData).then((result) => {
			setIsFormDisabled(true);
			if (result.data) {
				let yearsRidingOptions = result.data.listYearsRidingLookups;
				yearsRidingOptions.unshift({ id: 0, value: '' });
				setYearsRidingOptions(yearsRidingOptions);
			}
		});
		setIsFormDisabled(true);
	}, [memberProfileData]);

	useEffect(() => {
		setIsFormDisabled(false);
	}, [
		firstName,
		lastName,
		preferredName,
		email,
		phoneNumber,
		businessPhoneNumber,
		dateOfBirth,
		selectedGender,
		selectedYearsRiding,
		address,
		suburb,
		postcode,
		state,
		country,
		postalAddress,
		postalSuburb,
		postalPostcode,
		postalState,
		postalCountry,
		isPostalAddressSame
	]);

	useEffect(() => {
		if (
			!isPostalAddressSame &&
			(postalAddress == null ||
				postalAddress.trim() == '' ||
				postalSuburb.trim() == '' ||
				postalPostcode.trim() == '' ||
				postalState.trim() == '' ||
				postalCountry.trim() == '')
		) {
			setPostalAddressErrorMessage(null);
		}
	}, [postalAddress, postalSuburb, postalPostcode, postalState, postalCountry]);

	const validatePostalAddress = () => {
		if (
			!isPostalAddressSame &&
			(postalAddress.trim() == '' ||
				postalSuburb.trim() == '' ||
				postalPostcode.trim() == '' ||
				postalState.trim() == '' ||
				postalCountry.trim() == '')
		) {
			setPostalAddressErrorMessage('Please enter valid postal address');
			return false;
		} else {
			setPostalAddressErrorMessage(null);
			return true;
		}
	};

	const onSubmit = async () => {
		let valdiPostalAdd = validatePostalAddress();
		setBackendErrorMessage(null);
		if (valdiPostalAdd && dateOfBirthErrorMessage == null) {
			const updateMemberProfileData = {
				firstName: firstName,
				lastName: lastName,
				preferredName: preferredName,
				email: email,
				phone: phoneNumber,
				businessPhone: businessPhoneNumber,
				dateOfBirth: dateOfBirth,
				gender:
					genderOptions[selectedGender].value === ''
						? null
						: genderOptions[selectedGender].value,
				yearsRiding:
					selectedYearsRiding === '' || selectedYearsRiding === null
						? 0
						: selectedYearsRiding,
				postcode: postcode
			};
			try {
				await updateCurrentUserAttributes({
					given_name: firstName,
					family_name: lastName,
					'custom:postcode': postcode
				});
				AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData
				}).then((data) => {
					if (data.data != null) {
						addToast({ Message: 'Saved', IsSuccess: true });
					}
				});
				saveResidentialAddressProfile();
				if (postalAddress !== '' || postalAddress.length > 0) {
					savePostalAddressProfile();
				}
				setIsFormDisabled(true);
			} catch (error) {
				if (error && error.message) {
					setBackendErrorMessage(error.message);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving user profile data. Please contact support'
					);
				}
			}
		}
	};

	const saveResidentialAddressProfile = () => {
		const saveAddress = {
			memberExternalId: '', // TODO: remove it when AddressInput in GQL fully deprecates the member external id field
			address: address,
			country: country,
			postcode: postcode,
			state: state,
			suburb: suburb
		};
		AppSyncService.execute(saveResidentialAddress, {
			saveAddress
		});
	};

	const savePostalAddressProfile = () => {
		const saveAddress = {
			memberExternalId: '', // TODO: remove it when AddressInput in GQL fully deprecates the member external id field
			address: postalAddress,
			country: postalCountry,
			postcode: postalPostcode,
			state: postalState,
			suburb: postalSuburb
		};
		AppSyncService.execute(savePostalAddress, {
			saveAddress
		});
	};

	const updateGender = (value) => {
		setSelectedGender(value);
	};

	const updateYearsRiding = (value) => {
		setSelectedYearsRiding(value);
	};

	const genderOptions = [
		{ id: 0, value: '' },
		{ id: 1, value: 'Male' },
		{ id: 2, value: 'Female' },
		{ id: 3, value: 'Prefer not to disclose' }
	];

	const updateDateOfBirth = (value) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			setDateOfBirthErrorMessage(null);
		} else {
			setDateOfBirthErrorMessage('Please check the date of birth selected');
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			setDateOfBirth(formattedDate);
		} else {
			setDateOfBirth(null);
		}
	};

	const fillEmptyPostalAddress = () => {
		setPostalAddress('');
		setPostalSuburb('');
		setPostalPostcode('');
		setPostalState('');
		setPostalCountry(DEFAULT_COUNTRY);
	};

	const changeIsPostAddressData = (value) => {
		setIsPostalAddressSame(value);
		checkBoxData.isSelected = !value;
	};

	const havePostalAddress = () => {
		checkBoxData.isSelected = !isPostalAddressSame;

		if (!isPostalAddressSame) {
			setPostalAddressErrorMessage(null);
		} else {
			fillEmptyPostalAddress();
		}
		setIsPostalAddressSame(!isPostalAddressSame);
	};

	const toggleManualAddress = () => {
		setIsPostalAddressManual(!isPostalAddressManual);
	};

	const populateResidentialAddressFields = (addressDetails) => {
		// TODO: We can only update if the field value is different.
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setAddress(address);
		setValue('address', address);
		setSuburb(addressDetails.Suburb);
		setValue('suburb', addressDetails.Suburb);
		setPostcode(addressDetails.Postcode);
		setValue('postcode', addressDetails.Postcode);
		setState(addressDetails.State);
		setValue('state', addressDetails.State);
		setCountry(addressDetails.Country);
		setValue('country', addressDetails.Country);
	};
	const populatePostalAddressFields = (addressDetails) => {
		// TODO: We can only update if the field value is different.
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setPostalAddress(address);
		setValue('postalAddress', address);
		setPostalSuburb(addressDetails.Suburb);
		setValue('postalSuburb', addressDetails.Suburb);
		setPostalPostcode(addressDetails.Postcode);
		setValue('postalPostcode', addressDetails.Postcode);
		setPostalState(addressDetails.State);
		setValue('postalState', addressDetails.State);
		setPostalCountry(addressDetails.Country);
		setValue('postalCountry', addressDetails.Country);
	};

	const onChangeEmail = async () => {
		setOpenChangeEmailDialog(true);
	};

	const evalEmailExist = async () => {
		const result = await AppSyncService.execute(checkIfEmailExist, {
			email: newEmail
		});
		return result?.data?.isEmailExist;
	};

	const onSubmitEmail = async (e) => {
		e.preventDefault();
		setPasswordErrorMessage('');
		setEmailExistLoading(true);
		const result = await evalEmailExist();

		try {
			if (emailPattern.test(newEmail) && !result) {
				setPasswordErrorMessage('');
				await updateCurrentUserAttributes({
					email: newEmail
				});
				setOpenChangeEmailDialog(false);
				setOpenVerifyDialog(true);
			} else {
				if (newEmail === '' || newEmail.length === 0) {
					setEmailExistLoading(false);
					return setPasswordErrorMessage('Email is required');
				}
				if (result) {
					setPasswordErrorMessage('This email is already in use');
				} else {
					setPasswordErrorMessage(
						'Email must be a valid email address, please check for spaces or other characters'
					);
				}
			}
			setEmailExistLoading(false);
		} catch (error) {
			addToast({ Message: error.message, IsSuccess: false });
			setEmailExistLoading(false);
		}
	};

	const onResendVerificationCode = async () => {
		try {
			await updateCurrentUserAttributes({
				email: newEmail
			});
			addToast({
				Message: `Verification email sent to ${newEmail}`,
				IsSuccess: true
			});
		} catch (error) {
			addToast({ Message: error.message, IsSuccess: false });
		}
	};

	const onVerificationCode = async () => {
		setVerifyCodeLoading(true);
		try {
			await verifyCurrentUserAttribute('email', verificationCode);
			await AppSyncService.execute(updateCurrentUserEmail, {
				email: newEmail,
				memberId: memberProfileData.id
			});
			setEmail(newEmail);
			setOpenVerifyDialog(false);
			setNewEmail('');
			addToast({ Message: 'Email Verified', IsSuccess: true });
			setVerifyCodeLoading(false);
		} catch (error) {
			setVerifyCodeLoading(false);
			addToast({ Message: error.message, IsSuccess: false });
		}
	};

	const onCloseChangeEmailDialog = (event, reason) => {
		if (reason && reason == 'backdropClick') return;
		setOpenChangeEmailDialog(false);
		setNewEmail('');
		setPasswordErrorMessage('');
	};

	const onCloseVerifyDialog = (event, reason) => {
		if (reason && reason == 'backdropClick') return;
		setNewEmail('');
		setOpenVerifyDialog(false);
	};

	const onRenderVerifyDialogContent = () => {
		setVerifyEmailDialogTitle('Please verify your email address');
		return (
			<div>
				<div>
					<DialogContentText className={classes.dialogTextColor}>
						A verification email has been sent to <b>{newEmail}</b>, please open
						your email in a new window or tab and copy the verification code
						supplied into the text-box below (it may take up to 5 minutes for
						your email to arrive).
					</DialogContentText>

					<DialogContentText className={classes.dialogTextColor}>
						Please enter the verification code and click Submit to update your
						email address.
					</DialogContentText>

					<DialogContentText className={classes.dialogTextColor}>
						Please ensure to check your spam or junk folder if the email does
						not appear in your inbox.
					</DialogContentText>

					<DialogContentText className={classes.dialogTextColor}>
						The verification code will be active for 24 hours.
					</DialogContentText>

					<DialogContentText className={classes.dialogTextColor}>
						Did not receive the confirmation code?{' '}
						<Typography
							className={classes.resendText}
							onClick={() => onResendVerificationCode()}
							display="inline"
						>
							RESEND
						</Typography>
					</DialogContentText>
				</div>
				<div style={{ marginTop: '15px' }}>
					<div className={classes.fieldTitle}>Verification Code*</div>
					<Controller
						control={control}
						name="verificationCode"
						render={({ onChange }) => (
							<FormTextField
								value={verificationCode}
								onChange={(e) => {
									onChange(e.target.value);
									setVerificationCode(e.target.value);
								}}
								canEdit={!verifyCodeLoading}
							/>
						)}
					/>
				</div>

				{verifyCodeLoading ? (
					<LoaderSpinner status={true} />
				) : (
					<div style={{ marginTop: '15px' }}>
						<PrimaryButton onClick={() => onVerificationCode()} isNonSubmit>
							Submit
						</PrimaryButton>
						<PrimaryButton
							style={{ backgroundColor: '#c62828' }}
							onClick={() => onCloseVerifyDialog()}
							isNonSubmit
						>
							Cancel
						</PrimaryButton>
					</div>
				)}
			</div>
		);
	};

	const onRenderChangeEmailDialogContent = () => {
		setChangeEmailDialogTitle('Change Email Address');
		return (
			<div>
				<div className={classes.ProfilefieldContainer}>
					<div className={classes.fieldTitle}>Email*</div>
					<Controller
						control={control}
						name="email"
						render={({ onChange }) => (
							<FormTextField
								value={newEmail}
								onChange={(e) => {
									onChange(e.target.value);
									setNewEmail(e.target.value);
								}}
								canEdit={!emailExistLoading}
							/>
						)}
					/>
					{passwordErrorMessage && (
						<span className={classes.errorText}>{passwordErrorMessage}</span>
					)}
				</div>
				{emailExistLoading ? (
					<LoaderSpinner status={true} />
				) : (
					<div style={{ marginTop: '15px' }}>
						<PrimaryButton
							onClick={(event) => onSubmitEmail(event)}
							isNonSubmit
						>
							Submit
						</PrimaryButton>
						<PrimaryButton
							style={{ backgroundColor: '#c62828' }}
							onClick={() => onCloseChangeEmailDialog()}
							isNonSubmit
						>
							Cancel
						</PrimaryButton>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.personalProfile}>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>First Name*</div>
								<Controller
									control={control}
									name="firstName"
									render={({ onChange }) => (
										<FormTextField
											value={firstName}
											onChange={(e) => {
												onChange(e.target.value);
												setFirstName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.firstName?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Last Name*</div>
								<Controller
									control={control}
									name="lastName"
									render={({ onChange }) => (
										<FormTextField
											value={lastName}
											onChange={(e) => {
												onChange(e.target.value);
												setLastName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.lastName?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Preferred Name</div>
								<Controller
									control={control}
									name="preferredName"
									render={({ onChange }) => (
										<FormTextField
											value={preferredName}
											onChange={(e) => {
												onChange(e.target.value);
												setPreferredName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.preferredName?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={6}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Phone* (Personal)</div>
								<Controller
									control={control}
									name="phoneNumber"
									render={({ onChange }) => (
										<FormTextField
											value={phoneNumber}
											onChange={(e) => {
												onChange(e.target.value);
												setPhoneNumber(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.phoneNumber?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={6}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Phone (Business)</div>
								<Controller
									control={control}
									name="businessPhoneNumber"
									render={({ onChange }) => (
										<FormTextField
											value={businessPhoneNumber}
											onChange={(e) => {
												onChange(e.target.value);
												setBusinessPhoneNumber(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.businessPhoneNumber?.message}
								</span>
							</div>
						</Col>
					</Row>

					<Row>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Date of Birth</div>
								<Controller
									control={control}
									name="dateOfBirth"
									render={({ onChange }) => (
										<FormDatePicker
											DateValue={dateOfBirth}
											updateSelection={(value) => {
												onChange(value);
												updateDateOfBirth(value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.dateOfBirth?.message}
								</span>
							</div>
						</Col>

						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Gender*</div>
								<Controller
									control={control}
									name="gender"
									render={({ onChange }) => (
										<FormDropDown
											items={genderOptions}
											selectedId={selectedGender}
											onChange={(e) => {
												onChange(e.target.value);
												updateGender(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.gender?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>
									How many years have you been riding?
								</div>
								<Controller
									control={control}
									name="yearsRiding"
									render={({ onChange }) => (
										<FormDropDown
											items={yearsRidingOptions}
											selectedId={selectedYearsRiding}
											onChange={(e) => {
												onChange(e.target.value);
												updateYearsRiding(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.selectedYearsRiding?.message}
								</span>
							</div>
						</Col>
					</Row>
				</div>
				<hr className={classes.divider}></hr>
				<div className={classes.personalProfile}>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Residential Address*</div>
								<Controller
									control={control}
									name="address"
									render={({ onChange }) => (
										<AddressAutoSuggestion
											value={address}
											onChange={(value) => {
												onChange(value);
												setAddress(value);
											}}
											onAddressSelect={populateResidentialAddressFields}
											disableAutoSuggestion={isPostalAddressManual}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.address?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Residential Suburb*</div>
								<Controller
									control={control}
									name="suburb"
									render={({ onChange }) => (
										<FormTextField
											value={suburb}
											onChange={(e) => {
												onChange(e.target.value);
												setSuburb(e.target.value);
											}}
											isDisabled={!isPostalAddressManual}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.suburb?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Residential Postcode*</div>
								<Controller
									control={control}
									name="postcode"
									render={({ onChange }) => (
										<FormTextField
											value={postcode}
											onChange={(e) => {
												onChange(e.target.value);
												setPostcode(e.target.value);
											}}
											isDisabled={!isPostalAddressManual}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.postcode?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Residential State*</div>
								<Controller
									control={control}
									name="state"
									render={({ onChange }) => (
										<StateSelector
											id="personal-profile-residential-state-selector"
											country={country}
											value={state}
											changeHandler={(newStateValue) => {
												onChange(newStateValue);
												setState(newStateValue);
											}}
											isDisabled={!isPostalAddressManual}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.state?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Residential Country*</div>
								<Controller
									control={control}
									name="country"
									render={({ onChange }) => (
										<CountrySelector
											id="personal-profile-residential-country-selector"
											value={country}
											changeHandler={(newCountryValue) => {
												onChange(newCountryValue);
												setCountry(newCountryValue);
											}}
											isDisabled={!isPostalAddressManual}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.country?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.manualAddressCheckContainer}>
								<SimpleCheckBox
									small={true}
									noCapitalize={true}
									data={manualAddressCheckBoxData}
									changeCheckboxState={toggleManualAddress}
									key={
										manualAddressCheckBoxData && manualAddressCheckBoxData.id
									}
								/>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<SimpleCheckBox
								small={true}
								noCapitalize={true}
								data={checkBoxData}
								changeCheckboxState={havePostalAddress}
								key={checkBoxData && checkBoxData.id}
							/>
						</Col>
					</Row>
				</div>
				<hr className={classes.divider}></hr>
				{isPostalAddressSame === false && (
					<div className={classes.personalProfile}>
						<Row>
							<Col md={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postal Address</div>
									<Controller
										control={control}
										name="postalAddress"
										render={({ onChange }) => (
											<AddressAutoSuggestion
												value={postalAddress}
												onChange={(value) => {
													onChange(value);
													setPostalAddress(value);
												}}
												onAddressSelect={populatePostalAddressFields}
												disableAutoSuggestion={isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postalAddress?.message}
									</span>
								</div>
							</Col>
							<Col md={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postal Suburb</div>
									<Controller
										control={control}
										name="postalSuburb"
										render={({ onChange }) => (
											<FormTextField
												value={postalSuburb}
												onChange={(e) => {
													onChange(e.target.value);
													setPostalSuburb(e.target.value);
												}}
												isDisabled={!isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postalSuburb?.message}
									</span>
								</div>
							</Col>
							<Col md={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postal Postcode</div>
									<Controller
										control={control}
										name="postalPostcode"
										render={({ onChange }) => (
											<FormTextField
												value={postalPostcode}
												onChange={(e) => {
													onChange(e.target.value);
													setPostalPostcode(e.target.value);
												}}
												isDisabled={!isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postalPostcode?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postal State</div>
									<Controller
										control={control}
										name="postalState"
										render={({ onChange }) => (
											<StateSelector
												id="personal-profile-postal-state-selector"
												country={postalCountry}
												value={postalState}
												changeHandler={(newStateValue) => {
													onChange(newStateValue);
													setPostalState(newStateValue);
												}}
												isDisabled={!isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postalState?.message}
									</span>
								</div>
							</Col>
							<Col md={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postal Country</div>
									<Controller
										control={control}
										name="postalCountry"
										render={({ onChange }) => (
											<CountrySelector
												id="personal-profile-postal-country-selector"
												value={postalCountry}
												changeHandler={(newCountryValue) => {
													onChange(newCountryValue);
													setPostalCountry(newCountryValue);
												}}
												isDisabled={!isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postalCountry?.message}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				)}
				<div className={classes.buttonContainer}>
					<div className={classes.errorContainer}>
						{dateOfBirthErrorMessage && (
							<span className={classes.errorMessage}>
								{dateOfBirthErrorMessage}
							</span>
						)}
						{postalAddressErrorMessage && (
							<span className={classes.errorMessage}>
								{postalAddressErrorMessage}
							</span>
						)}
						{backendErrorMessage && (
							<span className={classes.errorMessage}>
								{backendErrorMessage}
							</span>
						)}
					</div>
					<div className={classes.saveRequest}>
						<span className={classes.lastSaved}></span>

						<span>
							<PrimaryButton
								style={{ width: '165px' }}
								disabled={isFormDisabled}
							>
								{isFormDisabled ? 'Saved' : 'Save Changes'}
							</PrimaryButton>
						</span>
					</div>
				</div>
				<div className={classes.clear}></div>
			</form>
			<HeaderBanner
				title="Email"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>
			<div className={classes.formContainer}>
				<div
					className={
						!isMobile ? classes.emailContainer : classes.emailContainerMobile
					}
				>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Email</div>
						<Controller
							control={control}
							name="email"
							render={({ onChange }) => (
								<FormTextField
									value={email}
									onChange={(e) => {
										onChange(e.target.value);
										setEmail(e.target.value);
									}}
									canEdit={false}
								/>
							)}
						/>
						<span className={classes.errorText}>{errors.email?.message}</span>
					</div>

					<div className={isMobile ? classes.emailButtonContainer : ''}>
						<div style={{ marginBottom: '18px' }}></div>
						<PrimaryButton
							style={{ width: '165px' }}
							onClick={() => onChangeEmail()}
							isNonSubmit
						>
							Change Email
						</PrimaryButton>
					</div>
				</div>
				<Dialog
					openDialog={openChangeEmailDialog}
					handleClose={onCloseChangeEmailDialog}
					title={changeEmailDialogTitle}
					contentChildren={onRenderChangeEmailDialogContent}
				/>
				<Dialog
					openDialog={openVerifyDialog}
					handleClose={onCloseVerifyDialog}
					title={verifyEmailDialogTitle}
					contentChildren={onRenderVerifyDialogContent}
				/>
			</div>
		</>
	);
};

export default MemberProfile;

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  container: {
    background: "#f5f5f5",
    paddingBottom: 20,
    borderBottom: "1px solid #e5f0f6"
  },
  formContainer: {
    paddingRight: 20,
  },
  formHeading: {
    fontSize: 18,
    fontWeight: 600,
    color: "#464749",
    padding: "20px 0 20px 0",
  },
  divider: {
    background: "#e2e2e2",
    height: 2,
    border: "none",
    marginTop: 35,
    marginBottom: 10
  },
  topMessage: {
    padding: "10px 0px 10px 5px",
  },
  dateFieldTitle: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 5,
    marginTop: 20
  },
  fieldTitle: {
    fontSize: 14,
    marginTop: 5,
    marginBottom: 5,
    fontWeight: 600
  },
  saveButtonContainer: {
    marginTop: 20
  },
  error: {
    fontSize: 12,
    color: "red"
  }
}));

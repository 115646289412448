import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

const MenuItem = ({
	id,
	key,
	isSelectedTab = false,
	title,
	onClick,
	customStyles
}) => {
	const classes = useStyles();
	return (
		<div
			id={id}
			key={key}
			className={classnames({
				[classes.menuItem]: true,
				[classes.selectedMenuItem]: isSelectedTab
			})}
			onClick={onClick}
			style={{
				...customStyles?.tabItem,
				...(isSelectedTab && customStyles?.selectedTabItem)
			}}
		>
			{title}
		</div>
	);
};

export default MenuItem;

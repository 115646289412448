import React, { useState } from 'react';
import useStyles from './styles';
import { Col, Row } from 'react-grid-system';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import FormTextField from '../FormTextField';
import ManualAddressCheckBox from '../ManualAddressEntryCheckbox';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';

const FormAddressEntry = ({
	savedAddressInfo,
	address,
	onAddressChange,
	suburb,
	onSuburbChange,
	postcode,
	onPostCodeChange,
	country,
	onCountryChange,
	state,
	onAddressStateChange
}) => {
	const classes = useStyles();
	const populateResidentialAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		onAddressChange(address);
		onSuburbChange(addressDetails.Suburb);
		onPostCodeChange(addressDetails.Postcode);
		onAddressStateChange(addressDetails.State);
		onCountryChange(addressDetails.Country);
	};
	const [isAddressManual, setIsAddressManual] = useState(false);
	return (
		<>
			<Row>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Residential Address*</div>
						<AddressAutoSuggestion
							value={address}
							onChange={onAddressChange}
							onAddressSelect={populateResidentialAddressFields}
							disableAutoSuggestion={isAddressManual}
							canEdit={savedAddressInfo?.residentialAddress?.address == null}
						/>
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Residential Suburb*</div>
						{!savedAddressInfo?.residentialAddress?.suburb && (
							<FormTextField
								value={suburb}
								onChange={(e) => onSuburbChange(e.target.value)}
								isDisabled={!isAddressManual}
							/>
						)}
						{savedAddressInfo?.residentialAddress?.suburb && (
							<span className={classes.profileInfo}>{suburb}</span>
						)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Residential Postcode*</div>
						{(!savedAddressInfo?.residentialAddress?.postcode ||
							savedAddressInfo?.residentialAddress?.postcode === 0) && (
							<FormTextField
								value={postcode}
								onChange={(e) => onPostCodeChange(e.target.value)}
								isDisabled={!isAddressManual}
							/>
						)}
						{savedAddressInfo?.residentialAddress?.postcode &&
							savedAddressInfo?.residentialAddress?.postcode > 0 && (
								<span className={classes.profileInfo}>{postcode}</span>
							)}
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Residential State*</div>
						{!savedAddressInfo?.residentialAddress?.state && (
							<StateSelector
								id="fullcircleform-address-state-selector"
								country={country}
								value={state}
								changeHandler={(newStateValue) =>
									onAddressStateChange(newStateValue)
								}
								isDisabled={!isAddressManual}
							/>
						)}
						{savedAddressInfo?.residentialAddress?.state && (
							<span className={classes.profileInfo}>{state}</span>
						)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Residential Country*</div>
						{!savedAddressInfo?.residentialAddress?.country && (
							<CountrySelector
								id="fullcircleform-address-country-selector"
								value={country}
								changeHandler={(newCountryValue) =>
									onCountryChange(newCountryValue)
								}
								isDisabled={!isAddressManual}
							/>
						)}
						{savedAddressInfo?.residentialAddress?.country && (
							<span className={classes.profileInfo}>{country}</span>
						)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}></Col>
			</Row>
			{savedAddressInfo?.residentialAddress?.address == null && (
				<Row style={{ marginTop: 5 }}>
					<Col>
						<ManualAddressCheckBox
							isSelected={isAddressManual}
							onChangeHandler={() => setIsAddressManual(!isAddressManual)}
						/>
					</Col>
				</Row>
			)}
		</>
	);
};

export default FormAddressEntry;

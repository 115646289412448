export const getMemberData = `
  query getMemberData($externalId: String!) {
    getMember(externalId: $externalId) {
      id
      externalId
      eventOrganiser {
        id
        value
      }
      claims {
        horse {
          horseCode
          name
        }
      }
      quickLinks {
				linkId
				linkOrder
			}
      notifications {
        type
        id
        message
        identifiers {
          id
          value
        }
        link
      }
      roles {
        id
        code
      }
    }
  }
`;

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  container: {
    border: "1px solid #b3bcc1",
    backgroundColor:"#f0f0f0",
  },
  fieldTitle: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 7,
    color: "#464749"
  },
  ProfilefieldContainer: {
    padding: "20px 18px 20px 18px",
  },
  loadingDiv: {
    marginTop: 20,
    position: "absolute",
    left: "45%",
    top: "50%",
    zIndex: 3,
  },
  profileInfo: {
    fontSize: 15,
    display: "block",
    marginTop: 10,
    wordBreak: "break-word",
  },

}));

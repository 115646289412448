import React from 'react';
import useStyles from './styles';

const EditFormHeader = ({ horseName, onCancel }) => {
	const classes = useStyles();
	return (
		<div className={classes.formHeaderContainer}>
			<div className={classes.horseNameContainer}>
				<span className={classes.longTextWrapper}>{horseName}</span>
			</div>
			<div className={classes.headerButtonsContainer}>
				<input
					id={'horseprofile-horse-cancel-button'}
					type="button"
					value="Cancel"
					onClick={onCancel}
					className={classes.horseDetailsHeaderButtonCancel}
				/>
				<input
					id={'horseprofile-horse-save-button'}
					type="submit"
					value="Save"
					className={classes.horseDetailsHeaderButtonPrimary}
				/>
			</div>
		</div>
	);
};

export default EditFormHeader;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledContainer, StyledLoadingContainer } from './styles';
import LoaderSpinner from '../LoaderSpinner';
import MemberProfileView from '../MemberProfileView';
import ThoroughbredIdentificationView from '../ThoroughbredIdentificationView';
import SNSAdmin from '../SNSAdmin';
import HeaderBanner from '../HeaderBanner';
import { parseUTCToLocal } from '../../utils/helpers';

const ClaimThoroughbredReview = ({
	allClaimData,
	children,
	data,
	title,
	isCurrent = true,
	isLoading
}) => {
	return (
		<StyledContainer>
			<HeaderBanner
				title={title}
				top
				onBehalfText={
					data?.behalfAdmin?.firstName && data?.behalfAdmin?.lastName ? (
						<span style={{ fontSize: 13, color: '#006da6' }}>
							(submitted on-behalf of user by{' '}
							{`${data?.behalfAdmin?.firstName} ${data?.behalfAdmin?.lastName}`}
							)
						</span>
					) : null
				}
				dateText={
					data?.completionDatetime ? (
						<span style={{ fontSize: 13 }}>
							Date Submitted:{' '}
							{parseUTCToLocal(data.completionDatetime).format('DD/MM/YYYY')}
						</span>
					) : null
				}
				type="primary"
				rightTitle={`Status: ${data.reviewStatus}`}
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			<div>
				<StyledLoadingContainer>
					<LoaderSpinner status={isLoading} />
				</StyledLoadingContainer>
				{data.member != null && (
					<MemberProfileView
						memberProfileData={data.member}
						ownershipType={data.ownershipType}
						organisation={data.organisation}
						claimPurpose={data.claimPurpose}
						horseProgram={data.horseProgram}
						stabledAddress={data.stabledAddress}
						stabledDate={data.stabledDate}
						claimsSource={data.claimsSource}
						claimsSourceOther={data.claimsSourceOther}
						adminReview
					/>
				)}
				{data.horse != null && (
					<Fragment>
						<ThoroughbredIdentificationView
							claimData={allClaimData}
							isPreviousClaim={!isCurrent}
						/>
						<SNSAdmin horseProfileData={data.horse} />
					</Fragment>
				)}
				{children}
			</div>
		</StyledContainer>
	);
};

ClaimThoroughbredReview.propTypes = {
	allClaimData: PropTypes.object,
	children: PropTypes.element,
	data: PropTypes.object,
	title: PropTypes.string,
	isCurrent: PropTypes.bool,
	isLoading: PropTypes.bool
};

export default ClaimThoroughbredReview;

import { createUseStyles } from "react-jss";
import BannerImage from '../../assets/images/header_banner.png'

export default createUseStyles(() => ({
  bannerImage: {
    height: 100,
    width: "100%",
  },
  imageContainer: {
    height: 200,
    width: "100%",
    backgroundImage: `url(${BannerImage})`,
    borderRadius: 5
  },
  imageContainerMobile: {
    height: 340,
    marginLeft: 15,
    marginRight: 15,
    backgroundImage: `url(${BannerImage})`,
    backgroundSize: "contain",
    borderRadius: 5
  },
  text:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    fontSize: 18,
    fontWeight: 600
  },
  textContent:{
    width: 590,
    wordBreak: "break-word",
    textAlign: "center",
    opacity: 1,
    transition: "opacity 5000ms ease",
  },
  textPrev:{
    width: 590,
    wordBreak: "break-word",
    textAlign: "center",
    opacity: 0,
    transition: "opacity 5000ms ease",
  },
  textMobile:{
    marginTop: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    fontSize: 15,
    fontWeight: 600,
    paddingRight: 15,
    paddingLeft: 15
  },
  textContentMobile:{
    width: "100%",
    wordBreak: "break-word",
    textAlign: "center",
    opacity: 1,
    transition: "opacity 5000ms ease",
  },
  textPrevMobile:{
    width: "100%",
    wordBreak: "break-word",
    textAlign: "center",
    opacity: 0,
    transition: "opacity 5000ms ease",
  },
  statsContainer: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 20,
    paddingLeft: 60,
    paddingTop: 30,
  },
  statsContainerMobile: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 30,
  },
  description: {
    display: "inline",
    
    fontSize: 15,
    marginLeft: 5,
    color: "#006da6",
    fontWeight: 600,
    textTransform: "uppercase"
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%"
  },
  infoContainerMobile: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingBottom: 20
  },
  line: {
    height: 1,
    background: "white",
    marginTop: 20,
    marginRight: 25,
    marginLeft: 25
  },
  lineMobile: {
    height: 1,
    background: "white",
    marginRight: 25,
    marginLeft: 25
  },
  heading: {
    textTransform:"uppercase",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
    color: "#464749",
  },
  headingMobile: {
    textTransform:"uppercase",
    fontSize: 20,
    fontWeight: 600,
    marginLeft: 15,
    marginBottom: 15,
    color: "#464749",
  }
}));

import * as Yup from 'yup';
import {
	MICROCHIP_LENGTH_ERROR_MESSAGE,
	AGE_LENGTH_ERROR_MESSAGE
} from './constants';

const schema = Yup.object()
	.shape({
		photos: Yup.array()
			.required('Brand Image is required')
			.min(1, 'Brand Image is required')
			.default([]),
		currentAddress: Yup.string().required('Thoroughbred Address is required'),
		currentSuburb: Yup.string().required('Thoroughbred Suburb is required'),
		currentPostcode: Yup.string().required('Thoroughbred Postcode is required'),
		currentState: Yup.string().required('Thoroughbred State is required'),
		currentCountry: Yup.string().required('Thoroughbred Country is required'),
		currentAddressValidated: Yup.boolean(),
		spAddress: Yup.string().required('Service Provider Address is required'),
		spSuburb: Yup.string().required('Service Provider Suburb is required'),
		spPostcode: Yup.string().required('Service Provider Postcode is required'),
		spState: Yup.string().required('Service Provider State is required'),
		spCountry: Yup.string().required('Service Provider Country is required'),
		spAddressValidated: Yup.boolean(),
		horseAge: Yup.string().required('Age is required'),
		horseColour: Yup.string().required('Horse Colour is required'),
		horseDam: Yup.string().required('Dam is required'),
		horseMicrochip: Yup.string().required('Microchip Number is required'),
		horseOwnerEmail: Yup.string().required('Email is required'),
		horseOwnerFirstName: Yup.string().required('First Name is required'),
		horseOwnerLastName: Yup.string().required('Surname is required'),
		horseOwnerMobile: Yup.string()
			.required('Contact number is required')
			.matches(/^\+\d*$|^\d*$/, 'Contact number must be valid'),
		horseOwnerName: Yup.string().required(`Horse Owner's Name is required`),
		horseOwnerTitle: Yup.string().required('Title is required'),
		horseSire: Yup.string().required('Sire is required'),
		horseGender: Yup.string().required('Horse Sex is required'),
		serviceProviderEmail: Yup.string().required(`Provider's Email is required`),
		serviceProviderFullname: Yup.string().required(
			`Provider's Full Name is required`
		),
		serviceProviderShortName: Yup.string().required(
			`Provider's Short Name is required`
		),
		typeOfService: Yup.number()
			.required('Type Of Service is required')
			.moreThan(0, 'Type Of Service is required')
	})
	.test('test-horseMicrochip-18digits', '', (values, context) => {
		if (values.horseMicrochip && values.horseMicrochip.length > 18) {
			return context.createError({
				path: 'horseMicrochip',
				message: MICROCHIP_LENGTH_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-horseAge-2digits', '', (values, context) => {
		if (values.horseAge && values.horseAge.length > 2) {
			return context.createError({
				path: 'horseAge',
				message: AGE_LENGTH_ERROR_MESSAGE
			});
		}
		return true;
	});

export default schema;

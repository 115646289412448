import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form-v7';
import { v4 as uuidv4 } from 'uuid';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteOhepEoiForm,
	saveOhepEoiForm,
	saveOhepEoiFormStatus,
	updateMemberProfile
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { REVIEW } from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import { logToServer } from '../../utils/logger';
import ConfirmationModal from '../ConfirmationModal';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import SEO from '../seo';
import StyledAccordion from './../OHEPForm/StyledAccordion';
import {
	StyledCancelRequest,
	StyledContainer,
	StyledErrorMessage,
	StyledErrorText,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledFieldText,
	StyledForm,
	StyledFormActionLink,
	StyledFormActionsRow,
	StyledFormEndDivider,
	StyledFormFieldsContainer,
	StyledFormSubmitButton,
	StyledLoadingContainer,
	StyledSaveDraft,
	modalStylesConfirmation
} from './../OHEPForm/style';
import {
	AT_LEAST_ONE_ERROR_MESSAGE,
	OHEP_FORM_DEFAULT_VALUES
} from './constants';
import { generatePayload, setFormValuesFromApiResult } from './helper';
import { getOhepEoiFormData, getOhepEoiFormReferenceData } from './queries';
import schema from './schema';

const OHEPEOIForm = () => {
	let { id } = useParams();
	const PAGE_ID = 'ohep-eoi-Form';
	const [guid, setGuid] = useState('');
	const [isDraftSaved, setIsDraftSaved] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [relationshipToHorseOptions, setRelationshipToHorseOptions] = useState(
		[]
	);
	const [eligibilityCriteriaOptions, setEligibilityCriteriaOptions] = useState(
		[]
	);
	const [horseGenderTypesOptions, setHorseGenderTypesOptions] = useState([]);
	const [
		preferredContactMethodOptions,
		setPreferredContactMethodOptions
	] = useState([]);
	const [memberData, setMemberData] = useState({});
	const [draftErrorMessage, setDraftErrorMessage] = useState(null);
	const [isFormError, setIsFormError] = useState(false);
	const [isFormInReview, setIsFormInReview] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		clearErrors,
		formState: { errors, isSubmitted }
	} = useForm({
		mode: 'onSubmit',
		defaultValues: OHEP_FORM_DEFAULT_VALUES,
		resolver: yupResolver(schema)
	});

	const values = watch();

	const fetchOhepEoiReferenceData = async () => {
		setIsLoading(true);
		try {
			const { data } = await AppSyncService.execute(
				getOhepEoiFormReferenceData,
				{
					memberExternalId: currentUser.UserId
				}
			);
			setRelationshipToHorseOptions([
				{ id: 0, value: '' },
				...data.listOhepEoiRelationships
			]);
			setEligibilityCriteriaOptions([
				{ id: 0, value: '' },
				...data.listOhepEoiEligibilityCriteria
			]);
			setHorseGenderTypesOptions([
				{ id: 0, value: '' },
				...data.listHorseGenderTypes
			]);
			setPreferredContactMethodOptions([
				{ id: 0, value: '' },
				...data.listOhepEoiPreferredContactMethod
			]);
			setMemberData(data.getMember);
			if (data.getMember.phone) {
				setValue('contactNumber', data.getMember.phone);
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			addToast({
				Message: 'Failed to fetch data',
				IsSuccess: false
			});
		}
	};

	const fetchOhepEoiFormData = async () => {
		setIsLoading(true);
		try {
			const { data } = await AppSyncService.execute(getOhepEoiFormData, {
				guid: id
			});

			if (data.getOhepEoiForm.memberId) {
				setFormValuesFromApiResult(data.getOhepEoiForm, setValue);
				setIsFormInReview(data.getOhepEoiForm.status === 'Review');
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			addToast({
				Message: 'Failed to fetch data',
				IsSuccess: false
			});
		}
	};

	useEffect(() => {
		fetchOhepEoiReferenceData();
	}, []);

	useEffect(() => {
		setIsLoading(true);
		if (id == null || id == undefined || id == '') {
			setGuid(uuidv4()); //new record
		} else {
			setGuid(id);
			fetchOhepEoiFormData();
		}
		setIsLoading(true);
	}, [id]);

	const updateProfile = async ({ phone }) => {
		if (!memberData.phone) {
			try {
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData: { phone }
				});
				addToast({ Message: 'Updated member profile', IsSuccess: true });
			} catch (error) {
				addToast({
					Message: 'Could not update member profile',
					IsSuccess: false
				});
			}
		}
	};

	const handleSubmitSuccess = async (data) => {
		setIsFormError(false);
		setIsLoading(true);
		await updateProfile({ phone: data.contactNumber });
		try {
			const ohepFormData = generatePayload(data, guid);
			const { data: saveOhepEoiFormData } = await AppSyncService.execute(
				saveOhepEoiForm,
				{
					input: ohepFormData
				}
			);
			if (!saveOhepEoiFormData?.saveOhepEoiForm?.success) {
				throw new Error('Failed to submit form, server error');
			}
			const { data: saveOhepEoiFormStatusData } = await AppSyncService.execute(
				saveOhepEoiFormStatus,
				{
					guid,
					status: REVIEW
				}
			);
			if (!saveOhepEoiFormStatusData?.saveOhepEoiFormStatus?.success) {
				throw new Error('Failed to submit form, server error');
			}
			setIsLoading(false);
			navigate(DASHBOARD_ROUTE);
			addToast({ Message: 'Form Submitted!', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Failed to submit form!', IsSuccess: false });
		}
	};

	const handleSubmitError = () => {
		setIsFormError(true);
	};

	const handleDraft = async (e, isRedirected) => {
		e.preventDefault();
		setIsLoading(true);
		const data = getValues();
		await updateProfile({ phone: data.contactNumber });
		setIsLoading(false);
		setDraftErrorMessage(null);
		if (data.horseName != null && data.horseName.trim() != '') {
			setIsLoading(true);
			try {
				const ohepFormData = generatePayload(data, guid);
				const { data: saveOhepEoiFormData } = await AppSyncService.execute(
					saveOhepEoiForm,
					{
						input: ohepFormData
					}
				);
				if (!saveOhepEoiFormData?.saveOhepEoiForm?.success) {
					addToast({
						Message: 'A form with this horse name already exists',
						IsSuccess: false
					});
					throw new Error('Failed to save draft, server error');
				}
				setIsLoading(false);
				if (isRedirected) {
					addToast({ Message: 'Form Saved!', IsSuccess: true });
					navigate(DASHBOARD_ROUTE);
				}
				setIsDraftSaved(true);
			} catch (error) {
				addToast({ Message: 'Failed to save draft!', IsSuccess: false });
				logToServer(
					currentUser?.UserId,
					data,
					`Failed to save OHEP EOI draft due to backend error.`
				);
				setIsLoading(false);
			}
		} else {
			setDraftErrorMessage('Please enter Horse name to save Draft');
		}
	};

	const handleCancel = async (e) => {
		e.preventDefault();
		if (!isDraftSaved && (id == null || id == undefined || id == '')) {
			return onCancelSuccess();
		}
		const { data } = await AppSyncService.execute(deleteOhepEoiForm, {
			guid
		});
		if (!data.deleteOhepEoiForm) {
			return addToast({ Message: 'Error Cancelling Form', IsSuccess: false });
		}
		onCancelSuccess();
	};

	const onCancelSuccess = () => {
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigate(DASHBOARD_ROUTE);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		handleSubmit(handleSubmitSuccess, handleSubmitError)(e);
	};

	const handleChange = (name, value) => {
		setValue(name, value);
		if (name === 'preferredContactType' && +value !== 0) {
			clearErrors('preferredContactType');
		} else if (name === 'horseGender' && +value !== 0) {
			clearErrors('horseGender');
		} else if (name === 'eligibilityCriteria' && +value !== 0) {
			clearErrors('eligibilityCriteria');
		} else if (name === 'relationshipToHorse' && +value !== 0) {
			clearErrors('relationshipToHorse');
			if (+value !== 5) {
				setValue('relationshipToHorseOther', '');
			}
		}
	};

	const handleMandatoryFieldsChange = (name, value) => {
		if (name === 'microchip') {
			if (/^[0-9]*$/.test(value)) {
				setValue(name, value);
				clearErrors('microchip');
			}
		} else {
			setValue(name, value);
			clearErrors(name);
		}
	};

	const handleHorseAgeChange = (e) => {
		clearErrors('age');
		const { name, value } = e.target;
		if (name === 'age' && /^[0-9]{0,2}$/.test(value)) {
			setValue(name, value);
		}
	};

	return (
		<Fragment>
			<SEO title="OHEP EOI Form" />
			<StyledLoadingContainer>
				<LoaderSpinner status={isLoading} />
			</StyledLoadingContainer>
			{!isLoading && (
				<StyledContainer>
					<StyledForm>
						<StyledAccordion title="Contact" isOpen={true}>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Contact Name</StyledFieldLabel>
											<FormTextField
												name="contactName"
												canEdit={false}
												value={`${memberData.firstName} ${memberData.lastName}`}
											/>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Contact Email</StyledFieldLabel>
											<FormTextField
												name="contactEmail"
												canEdit={false}
												value={memberData.email}
											/>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Contact Number{!memberData.phone && '*'}
											</StyledFieldLabel>
											{!memberData.phone ? (
												<Fragment>
													<StyledFieldText
														isError={errors.contactNumber?.message}
														{...register('contactNumber', { required: true })}
													/>
													<StyledErrorText>
														{errors.contactNumber?.message}
													</StyledErrorText>
												</Fragment>
											) : (
												<FormTextField
													value={memberData.phone}
													canEdit={false}
												/>
											)}
										</StyledFieldContainer>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Your relationship to this horse*
											</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="relationshipToHorse"
												selectedId={values.relationshipToHorse}
												onChange={(e) =>
													handleChange(e.target.name, e.target.value)
												}
												items={relationshipToHorseOptions}
												isError={errors.relationshipToHorse?.message}
											/>
											<StyledErrorText>
												{errors.relationshipToHorse?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									{+values.relationshipToHorse === 5 && (
										<Col xs={12} lg={4}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Other Relationship to this horse*
												</StyledFieldLabel>
												<StyledFieldText
													isError={errors.relationshipToHorseOther?.message}
													{...register('relationshipToHorseOther', {
														required: true
													})}
												/>
												<StyledErrorText>
													{errors.relationshipToHorseOther?.message}
												</StyledErrorText>
											</StyledFieldContainer>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Preferred contact method*
											</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="preferredContactType"
												selectedId={values.preferredContactType}
												onChange={(e) =>
													handleChange(e.target.name, e.target.value)
												}
												items={preferredContactMethodOptions}
												isError={errors.preferredContactType?.message}
											/>
											<StyledErrorText>
												{errors.preferredContactType?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={8}>
										<StyledFieldContainer>
											<StyledFieldLabel>Contact details*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.contactDetails?.message}
												{...register('contactDetails', { required: true })}
											/>
											<StyledErrorText>
												{errors.contactDetails?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Horse Details" isOpen={true}>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Horse Name*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseName?.message}
												{...register('horseName', { required: true })}
											/>
											<StyledErrorText>
												{errors.horseName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Microchip Number</StyledFieldLabel>
											<StyledFieldText
												type="text"
												isError={errors.microchip?.message}
												name="microchip"
												value={values.microchip}
												onChange={(e) =>
													handleMandatoryFieldsChange(
														e.target.name,
														e.target.value
													)
												}
											/>
											<StyledErrorText>
												{errors.microchip?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Sire Name</StyledFieldLabel>
											<StyledFieldText
												isError={errors.sire?.message}
												name="sire"
												value={values.sire}
												onChange={(e) =>
													handleMandatoryFieldsChange(
														e.target.name,
														e.target.value
													)
												}
											/>
											<StyledErrorText>{errors.sire?.message}</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Dam Name</StyledFieldLabel>
											<StyledFieldText
												isError={errors.dam?.message}
												name="dam"
												value={values.dam}
												onChange={(e) =>
													handleMandatoryFieldsChange(
														e.target.name,
														e.target.value
													)
												}
											/>
											<StyledErrorText>{errors.dam?.message}</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Sex*</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="horseGender"
												selectedId={values.horseGender}
												onChange={(e) =>
													handleChange(e.target.name, e.target.value)
												}
												items={horseGenderTypesOptions}
												isError={errors.horseGender?.message}
											/>
											<StyledErrorText>
												{errors.horseGender?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Age</StyledFieldLabel>
											<StyledFieldText
												type="text"
												isError={errors.age?.message}
												name="age"
												value={values.age}
												onChange={handleHorseAgeChange}
											/>
											<StyledErrorText>{errors.age?.message}</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={8}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Which eligibility criteria do you believe your horse
												meets?*
											</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="eligibilityCriteria"
												selectedId={values.eligibilityCriteria}
												onChange={(e) =>
													handleChange(e.target.name, e.target.value)
												}
												items={eligibilityCriteriaOptions}
												isError={errors.eligibilityCriteria?.message}
											/>
											<StyledErrorText>
												{errors.eligibilityCriteria?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Current address of Horse" isOpen={true}>
							<StyledFormFieldsContainer>
								<FormAddressEntryGeneric
									address={values.currentAddress}
									onAddressChange={(address) =>
										setValue('currentAddress', address)
									}
									isPostalAddressManual={false}
									suburb={values.currentSuburb}
									onSuburbChange={(suburb) => setValue('currentSuburb', suburb)}
									postcode={values.currentPostcode}
									onPostCodeChange={(postcode) =>
										setValue('currentPostcode', postcode)
									}
									country={values.currentCountry}
									onCountryChange={(country) =>
										setValue('currentCountry', country)
									}
									manualAddressCheckBoxData={{
										id: 'manualCheckBox',
										label: 'Manually add my address',
										value: 'Manually add my address',
										isSelected: values.currentAddressValidated
									}}
									toggleManualAddressCheckBox={() =>
										setValue(
											'currentAddressValidated',
											!values.currentAddressValidated
										)
									}
									state={values.currentState}
									onAddressStateChange={(state) =>
										setValue('currentState', state)
									}
									isSubmitClicked={isSubmitted}
								/>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledFormEndDivider />
						<StyledFormActionsRow>
							<Col xs={3} lg={7}>
								<StyledCancelRequest
									onClick={() => setConfirmationModal(true)}
									isVisible={!isFormInReview}
								>
									Cancel Request
								</StyledCancelRequest>
							</Col>
							<StyledSaveDraft xs={2} lg={2.5}>
								<StyledFormActionLink
									onClick={(e) => handleDraft(e, true)}
									isVisible={!isFormInReview}
								>
									Save Draft
								</StyledFormActionLink>
							</StyledSaveDraft>
							<Col xs={7} lg={2.5}>
								<StyledFormSubmitButton
									onClick={onSubmit}
									disabled={isFormInReview}
									id={`${PAGE_ID}-submit`}
								>
									Complete Request
								</StyledFormSubmitButton>
							</Col>
						</StyledFormActionsRow>
						{isFormError && (
							<StyledErrorMessage>
								{`Please complete all required fields. ${AT_LEAST_ONE_ERROR_MESSAGE}`}
							</StyledErrorMessage>
						)}
						{draftErrorMessage && (
							<StyledErrorMessage>{draftErrorMessage}</StyledErrorMessage>
						)}
					</StyledForm>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Cancel OHEP Form"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Are you sure you wish to delete this form?
					</ConfirmationModal>
				</StyledContainer>
			)}
		</Fragment>
	);
};

export default OHEPEOIForm;

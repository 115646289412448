import { BLANK_OPTION } from '../../utils/constants';

export const PAGE_ID = 'admin-review-acknowledged-retrainer-form';

export const INITIAL_APPLICATION_REVIEW_STATE = {
	actioning: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	adminStatus: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	applicationFormComplete: {
		value: false,
		validationError: ''
	},
	videoAssessmentComments: {
		value: '',
		validationError: ''
	},
	videoAssessmentDate: {
		value: null,
		validationError: ''
	},
	videoAssessmentUploads: {
		value: [],
		validationError: ''
	},
	phoneInterviewComments: {
		value: '',
		validationError: ''
	},
	phoneInterviewDate: {
		value: null,
		validationError: ''
	},
	propertyAssessmentComments: {
		value: '',
		validationError: ''
	},
	propertyAssessmentDate: {
		value: null,
		validationError: ''
	},
	propertyAssessmentUploads: {
		value: [],
		validationError: ''
	},
	ridingAssessmentSameDay: {
		value: true,
		validationError: ''
	},
	ridingAssessmentDate: {
		value: null,
		validationError: ''
	},
	ridingScore: {
		value: '',
		validationError: ''
	},
	propertyAndRidingPhotoUploads: {
		value: [],
		validationError: ''
	},
	ridingAssessmentComments: {
		value: '',
		validationError: ''
	},
	ridingAssessmentUploads: {
		value: [],
		validationError: ''
	},
	applicationRecommendation: {
		value: '',
		validationError: ''
	},
	ewgmComment: {
		value: '',
		validationError: ''
	},
	applicationReviewDate: {
		value: null,
		validationError: ''
	},
	rejectionReasons: {
		value: [],
		validationError: ''
	},
	rejectionNotification: {
		value: '',
		validationError: ''
	},
	personalDetailUploads: {
		value: [],
		validationError: ''
	}
};

export const INITIAL_LOOKUP_STATE = {
	actioningOptions: [],
	adminStatusOptions: [],
	rejectReasonOptions: []
};

export const COMPANY_BUSINESS_TYPE_ID = 2;
export const PARTNERSHIP_BUSINESS_TYPE_ID = 4;

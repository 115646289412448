import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		marginTop: 15
	},
	brandingHeader: {
		float: 'left',
		textTransform: 'uppercase',
		fontSize: 15,
		color: '#464749',
		fontWeight: 'bold',
		marginBottom: 10,
		marginTop: 10,
		display: 'contents'
	},
	brandingLogo: {
		maxHeight: '50px',
		paddingLeft: '5px',
		paddingRight: '5px'
	},
	logoSection: {
		marginTop: 20
	}
}));

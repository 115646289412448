import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
	DROPDOWN,
	HORSESUGGESTION,
	MULTILINETEXT
} from '../AddEvent/constants';
import SelectInputField from '../FormComponents/SelectInputField';
import HorseAutoSuggestion from '../HorseAutoSuggestion';
import TextInputField from '../FormComponents/TextInputField';
import ResizingTextArea from '../ResizingTextArea';
import { useScreenClass } from 'react-grid-system';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	},
	table: {
		minWidth: 650
	},
	selectTableCell: {
		width: 60
	},
	tableHeader: {
		width: 130,
		height: 40,
		paddingTop: 1,
		fontWeight: 600
	},
	tableCell: {
		width: 130,
		height: 40,
		paddingTop: 1,
		paddingBottom: 8
	},
	input: {
		width: 130,
		height: 40
	},
	columnHorseCode: {
		width: 230,
		paddingTop: 1,
		paddingLeft: 25,
		paddingBottom: 8
	},
	headerHorseCode: {
		width: 230,
		textAlign: 'center',
		paddingTop: 9,
		fontWeight: 600,
		paddingBottom: 0
	}
}));

const CustomTableCell = ({
	row,
	name,
	editableType,
	label,
	options,
	onChange
}) => {
	const screenClassForRender = useScreenClass();
	let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
	const [horseAutocompleteValue, setHorseAutocompleteValue] = useState(
		editableType === HORSESUGGESTION ? row[name].name : ''
	);
	const classes = useStyles();
	const { isEditMode } = row;
	return (
		<TableCell
			align="left"
			className={
				editableType === HORSESUGGESTION
					? classes.columnHorseCode
					: classes.tableCell
			}
		>
			{isEditMode ? (
				editableType === DROPDOWN ? (
					<SelectInputField
						label=""
						id={`${row.id}-class-select-input-field`}
						options={options}
						name={name}
						value={row[name]}
						fullWidth
						onChange={(e) => onChange(e, row)}
					/>
				) : editableType === HORSESUGGESTION ? (
					<HorseAutoSuggestion
						value={horseAutocompleteValue}
						onChange={(e) => setHorseAutocompleteValue(e)}
						onHorseSelect={(obj) => {
							setHorseAutocompleteValue(obj.name);
							onChange(
								{
									target: { id: obj.id, value: obj.id, name: obj.name },
									isHorse: true
								},
								row
							);
						}}
						formTextFieldStyle={
							isMobile ? { marginBottom: 15 } : { marginTop: 8 }
						}
					/>
				) : editableType === MULTILINETEXT ? (
					<ResizingTextArea
						id={`${name}-editable-text`}
						onChange={(e) => onChange(e, row)}
						maxChars={500}
						name={name}
						value={row[name]}
						canEdit={true}
						isShowRemainingChars={false}
						styles={
							isMobile
								? { marginBottom: 10, paddingTop: 0 }
								: { paddingTop: 10 }
						}
					/>
				) : (
					<TextInputField
						id={`${name}-editable-text`}
						name={name}
						value={row[name]}
						onChange={(e) => onChange(e, row)}
						fullWidth
						maxLength={500}
					/>
				)
			) : editableType === HORSESUGGESTION ? (
				row[label].name
			) : (
				row[label]
			)}
		</TableCell>
	);
};

function EditableTable({ columns, data, setData, id }) {
	const [previous, setPrevious] = useState({});
	const classes = useStyles();

	const onToggleEditMode = (_row) => {
		if (_row.isEditMode) return;
		setData(() => {
			return data.map((row) => {
				if (row.id === _row.id) {
					return { ...row, isEditMode: !row.isEditMode };
				}
				return row;
			});
		});
	};

	const onChange = (e, row) => {
		if (!previous[row.id]) {
			setPrevious((state) => ({ ...state, [row.id]: row }));
		}

		const value = e.target.value;
		const name = e.target.name;
		const { id } = row;
		const newRows = data.map((row) => {
			if (row.id === id) {
				if (e.isHorse)
					return { ...row, horse: { horseCode: value, name, value } };
				else return { ...row, [name]: value };
			}
			return row;
		});
		setData(newRows);
	};

	return (
		<Paper className={classes.root}>
			<Table className={classes.table} id={id} aria-label="caption table">
				<TableHead>
					<TableRow>
						{columns &&
							columns.map((column) => (
								<TableCell
									key={column.accessor}
									className={
										column?.editableType === HORSESUGGESTION
											? classes.headerHorseCode
											: classes.tableHeader
									}
									align="left"
								>
									{column.Header}
								</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data &&
						data.map((row) => (
							<TableRow onClick={() => onToggleEditMode(row)} key={row.id}>
								{columns &&
									columns.map((column) => (
										<CustomTableCell
											key={column.accessor}
											editableType={column.editableType}
											options={column.options}
											{...{
												row,
												name: column.accessor,
												label: column.labelAccessor,
												onChange
											}}
										/>
									))}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</Paper>
	);
}

EditableTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	setData: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired
};

export default EditableTable;

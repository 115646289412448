import { createUseStyles } from "react-jss";


export default createUseStyles(() => ({
  menuContainer: {
    overflowX: "scroll",
    whiteSpace: "noWrap",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  menuItems: {
    display: "inline-block",
    margin: 50,
  },
  toggleHeader: {
    display: "inline",
    padding: 10,
    cursor: "pointer",
    borderRadius: 50,
    color: "#006da6",
    fontSize: 15,
    width: 300,
  },
  toggleContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    textAlign: "center",
    background: "#dcebf3",
    marginBottom: 20,
    padding: "12px 4px",
    borderRadius: 50,
    overflowX: "scroll",
    whiteSpace: "nowrap",
    "&::-webkit-scrollbar": {
        display: "none",
      },
  },
  pageContainer: {

  },
  pageContainerMobile: {
      marginTop: 70,
      marginLeft: 15,
      marginRight: 15
  }
}));

import React, { useState, useEffect, Fragment } from 'react';
import FormDatePicker from '../FormDatePicker';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SaveCancelButton from '../SaveCancelButton';
import { formatDate } from '../../utils/helpers';
import FormTextField from '../FormTextField';
import FormDropDown from '../FormDropDown';
import FormMultiSelectList from '../FormMultiSelectList';
import { convertToMultiListData } from '../../utils/helpers';
import RetrainerConcludedFullCircleForm from '../RetrainerConcludedFullCircleForm';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';
import Toggle from '../Toggle';

const RetrainerConcludedOwnUseLeasedForm = ({
	retrainPurpose,
	cancel,
	submitForm,
	disciplineOptions = [],
	showFullCircleForm
}) => {
	const classes = useStyles();
	const [selectedPurposeList, setSelectedPurposeList] = useState(0);
	const [date, setDate] = useState(null);
	const [error, setError] = useState('');
	const [other, setOther] = useState('');
	const [otherDiscipline, setOtherDiscipline] = useState('');
	const [disciplineList, setDisciplineList] = useState([]);
	const [selectedDisciplineList, setSelectedDisciplineList] = useState([]);
	const [wantFullCircle, setWantFullCircle] = useState(true);
	const [isHorseStaying, setIsHorseStaying] = useState(false);
	const [assistanceType, setAssistanceType] = useState(0);

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDate(formattedDate);
			} else {
				setDate(null);
			}
		}
	};

	const saveData = () => {
		const disciplineIds = selectedDisciplineList.map((i) => i.value);
		if (
			date != null &&
			selectedPurposeList.length > 0 &&
			((retrainPurpose.filter(
				(i) => i.id === parseInt(selectedPurposeList) && i.value === 'Other'
			).length > 0 &&
				other != '') ||
				retrainPurpose.filter(
					(i) => i.id === parseInt(selectedPurposeList) && i.value === 'Other'
				).length === 0) &&
			disciplineIds.length > 0 &&
			((disciplineOptions.filter(
				(i) => disciplineIds.includes(i.id) && i.value === 'Other'
			).length > 0 &&
				otherDiscipline != '') ||
				disciplineOptions.filter(
					(i) => disciplineIds.includes(i.id) && i.value === 'Other'
				).length === 0) &&
			(!showFullCircleForm ||
				!wantFullCircle ||
				(wantFullCircle && assistanceType.length > 0))
		) {
			let data = {
				date: date,
				focusIds: selectedPurposeList,
				disciplineIds: selectedDisciplineList.map((i) => i.value),
				other: other,
				wantFullCircle: !showFullCircleForm ? false : wantFullCircle,
				assistanceType,
				otherDiscipline,
				isHorseStaying
			};
			submitForm(data);
		} else {
			setError('Fields cannot be empty');
		}
	};

	const updateDiscipline = (value) => {
		setSelectedDisciplineList(value);
	};

	useEffect(() => {
		if (disciplineOptions.length > 0) {
			setDisciplineList(convertToMultiListData(disciplineOptions, 'value'));
		}
	}, []);

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<Row>
					<Col lg={4} xs={12}>
						<div className={classes.dateFieldTitle}>Date Commenced*</div>
						<div>
							<FormDatePicker
								id={HorseManagementKeys.DATE_COMMENCED}
								styles={{ width: '100%', color: 'blue' }}
								DateValue={date}
								updateSelection={(value) => updateDate(value)}
							/>
						</div>
					</Col>
					<Col lg={4} xs={12}>
						<div className={classes.dateFieldTitle}>Purpose*</div>
						<FormDropDown
							id={HorseManagementKeys.CONCLUDED_PURPOSE}
							showBlank
							items={retrainPurpose}
							selectedId={selectedPurposeList}
							onChange={(e) => setSelectedPurposeList(e.target.value)}
						/>
					</Col>
					{retrainPurpose.filter(
						(i) => i.id === parseInt(selectedPurposeList) && i.value === 'Other'
					).length > 0 && (
						<Col lg={4} xs={12}>
							<div className={classes.dateFieldTitle}>Other*</div>
							<FormTextField
								id={HorseManagementKeys.CONCLUDED_PURPOSE_OTHER}
								value={other}
								onChange={(e) => setOther(e.target.value)}
								styles={{ height: 38 }}
							/>
						</Col>
					)}
					<Col lg={4} xs={12}>
						<div className={classes.dateFieldTitle}>Disciplines*</div>
						<FormMultiSelectList
							id={HorseManagementKeys.CONCLUDED_DISCIPLINE}
							name="Discipline"
							listOptions={disciplineList}
							selectedIds={selectedDisciplineList}
							onSelectChange={updateDiscipline}
						/>
					</Col>
					{selectedDisciplineList.filter((r) => r.label === 'Other').length >
						0 && (
						<Col xs={12} lg={4}>
							<div className={classes.dateFieldTitle}>Other Discipline*</div>
							<FormTextField
								id={HorseManagementKeys.CONCLUDED_DISCIPLINE_OTHER}
								value={otherDiscipline}
								onChange={(e) => setOtherDiscipline(e.target.value)}
								styles={{ height: 38 }}
							/>
						</Col>
					)}
				</Row>
				<hr className={classes.divider}></hr>
				<Col xs={12} lg={4} style={{ paddingLeft: 0 }}>
					<div className={classes.dateFieldTitle}>
						Is this horse staying on your property? (If yes, the horse will
						remain in your claimed list).
					</div>
					<Toggle
						id={HorseManagementKeys.HORSE_STAYING}
						labelNegative="No"
						labelPositive="Yes"
						onClick={(value) => setIsHorseStaying(value)}
						value={isHorseStaying}
					/>
				</Col>
				{showFullCircleForm && (
					<Fragment>
						<RetrainerConcludedFullCircleForm
							wantFullCircle={wantFullCircle}
							updateWantFullCircle={setWantFullCircle}
							assistanceType={assistanceType}
							updateAssistanceType={setAssistanceType}
						/>
						<hr className={classes.divider}></hr>
					</Fragment>
				)}
				<div className={classes.error}>{error}</div>
				<div className={classes.saveButtonContainer}>
					<SaveCancelButton
						idPositive={CommonKeys.SAVE}
						idNegative={CommonKeys.CANCEL}
						positiveLabel="Save"
						cancel={cancel}
						onClickPositive={saveData}
						negativeLabel="Cancel"
					/>
				</div>
			</div>
		</div>
	);
};
export default RetrainerConcludedOwnUseLeasedForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-grid-system';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { StyledContainer, StyledLogoCol } from './styles';
import FullArticleEmbeddedAsset from '../../../FullArticleEmbeddedAsset';

const PartnershipCard = ({ partnership }) => {
	const options = {
		renderMark: {
			[MARKS.BOLD]: (text) => <div>{text}</div>
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
		}
	};

	return (
		<StyledContainer>
			<StyledLogoCol xs={12} md={10} lg={2}>
				<a target="_blank" rel="noopener noreferrer" href={partnership.url}>
					<img src={partnership.logo.file.url} alt={partnership.logo.title} />
				</a>
			</StyledLogoCol>
			<Col xs={12} md={10} lg={6}>
				<a target="_blank" rel="noopener noreferrer" href={partnership.url}>
					<h2>{partnership.name}</h2>
				</a>
				<div>
					{partnership.description &&
						renderRichText(partnership.description, options)}
				</div>
			</Col>
			<StyledLogoCol xs={12} md={10} lg={4}>
				{partnership.media?.file?.url && (
					<FullArticleEmbeddedAsset
						videoStyle={{ borderRadius: 5 }}
						imageStyle={{ borderRadius: 5 }}
						contentType={partnership.media.file.contentType}
						contentSrc={partnership.media.file.url}
					/>
				)}
			</StyledLogoCol>
		</StyledContainer>
	);
};

PartnershipCard.propTypes = {
	partnership: PropTypes.shape({
		name: PropTypes.string,
		url: PropTypes.string,
		description: PropTypes.object,
		logo: PropTypes.object,
		media: PropTypes.object
	})
};

export default PartnershipCard;

import styled from 'styled-components';

export const StyledWidget = styled.div`
	.title {
		padding: 1rem 0;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		background-color: ${({ theme: { colors } }) => colors.pink};
		color: #fff;
		text-align: center;
		font-size: 3rem;
		font-weight: bold;
	}
	.description {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		background-color: ${({ theme: { colors } }) => colors.gray};
		color: ${({ theme: { colors } }) => colors.text};
		font-size: 12px;
		font-weight: 600;
		padding: 0.625rem 1rem;
		text-align: center;
		@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
			font-size: 15px;
		}
	}
`;

import { createUseStyles } from "react-jss";


export default createUseStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "flex-end"
  },
  buttonStyle: {
    color: "white",
    background: "#006da6",
    textAlign: "center",
    height: 30,
    paddingTop: 8,
    width: 100,
    borderRadius: 5,
    cursor: "pointer",
  },
  buttonStyleNegative: {
    marginRight: 10,
    color: "#006da6",
    background: "white",
    border: "1px solid #c7ced1",
    textAlign: "center",
    width: 100,
    height: 30,
    paddingTop: 8,
    borderRadius: 5,
    cursor: "pointer",
  }
}));

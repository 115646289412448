import React from 'react';
import Chip from '@material-ui/core/Chip';

const BasicChip = ({ icon, styles, containerStyle, label = '' }) => {
	return (
		<div style={containerStyle}>
			<Chip icon={icon} label={label} style={styles} />
		</div>
	);
};

export default BasicChip;

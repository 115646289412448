import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainerMobile: {
		marginTop: 70,
		marginLeft: 15,
		marginRight: 15
	},
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important',
		border: '1px solid #ddd6d6',
		borderRadius: 6
	},
	sectionContainer: {
		marginLeft: 15,
		marginRight: 15,
		paddingTop: 5,
		paddingBottom: 6,
		border: '1px solid #b3bcc1',
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px'
	},
	scrollableTabsContainer: {
		marginTop: 20,
		marginBottom: 20
	},
	sectionNoListing: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center'
	},
	sectionNoListingText: {
		margin: '0.5em',
		fontWeight: '500'
	},
	forSaleHorseCards: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		gap: '1rem'
	},
	lineMobile: {
		height: '1px',
		backgroundColor: '#ddd6d6',
		border: 'none',
		marginRight: 15,
		marginTop: 20,
		marginBottom: 20
	},
	line: {
		height: '1px',
		backgroundColor: '#ddd6d6',
		border: 'none',
		marginTop: 20,
		marginBottom: 20
	},
	sectionHeading: {
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		paddingTop: 15
	},
	sectionHeadingDesktop: {
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		paddingTop: 15,
		marginLeft: 20,
		marginRight: 20
	},
	tagsHeading: {
		marginBottom: 5,
		fontSize: 12,
		fontWeight: 600,
		color: '#464749'
	},
	tagsHeadingDesktop: {
		marginBottom: 5,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749'
	},
	subSectionHeading: {
		marginTop: 15,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		marginLeft: 15,
		paddingTop: 15
	},
	subSectionHeadingNotes: {
		marginTop: 15,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		marginLeft: 15
	},
	subSectionHeadingNotesMobile: {
		marginTop: 15,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749'
	},
	sectionText: {
		fontSize: 15,
		color: '#464749',
		marginTop: 15,
		marginRight: 15
	},
	sectionTextDesktop: {
		fontSize: 15,
		color: '#464749',
		marginTop: 15,
		marginRight: 20,
		marginLeft: 20
	},
	subSectionText: {
		fontSize: 15,
		color: '#464749',
		marginTop: 15,
		marginRight: 15,
		marginLeft: 15,
		marginBottom: 30,
		paddingBottom: 20
	},
	subSectionTextWithoutMarginBtm: {
		fontSize: 15,
		color: '#464749',
		marginTop: 5,
		marginRight: 15,
		marginLeft: 15,
		paddingBottom: 10
	},
	subSectionTextWithoutMarginBtmMobile: {
		fontSize: 15,
		color: '#464749',
		marginTop: 5,
		marginRight: 15,
		paddingBottom: 10
	},
	desktopImageSliderContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0px 20px'
	},
	priceRangeContainer: {
		backgroundColor: '#ededed',
		borderRadius: 3
	},
	priceRangeContainerDesktop: {
		backgroundColor: '#ededed',
		borderRadius: 3,
		marginLeft: 20,
		marginRight: 20
	},
	resultTags: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	resultTagsDesktop: {
		display: 'inline-flex',
		flexWrap: 'wrap',
		flexDirection: 'column'
	},
	resultTag: {
		background: '#ededed',
		fontSize: 12,
		padding: '5px 10px',
		color: '#464749',
		marginRight: 7,
		marginBottom: 10,
		borderRadius: 3
	},
	logo: {},
	searchFieldContainer: {
		margin: '15px 5px'
	},
	addFilter: {
		color: '#006da6',
		fontWeight: 600,
		fontSize: 15,
		cursor: 'pointer',
		marginBottom: 5
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#ededed',
		padding: '10px 20px 20px 20px',
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4
	},
	filterMobileSer: {
		width: '100%',
		marginRight: 4
	},
	filters: {
		color: '#016ea6',
		fontWeight: 600,
		fontSize: 13,
		width: '100%',
		minHeight: 40,
		background: '#dcebf3'
	},
	filterContent: {
		padding: '10px 10px 10px 20px',
		display: 'flex',
		flexWrap: 'wrap'
	},
	appliedFilter: {
		marginRight: 30,
		cursor: 'default',
		position: 'relative'
	},
	appliedFilterCross: {
		cursor: 'pointer',
		float: 'right',
		marginTop: 4,
		fontSize: 8,
		paddingLeft: 5
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	navigationLinks: {
		fontSize: 13,
		padding: '15px 5px',
		backgroundColor: '#dcebf3',
		width: '100%',
		boxSizing: 'border-box',
		marginBottom: 15,
		textAlign: 'center',
		overflowX: 'scroll',
		'@media (min-width:992px)': {
			fontSize: 15
		}
	},
	tabsContainer: {
		minWidth: 350
	},
	siteHeaderMenuItem: {
		padding: 10,
		color: '#006da6',
		cursor: 'pointer',
		borderRadius: 50
	},
	siteHeaderMenuItemSelected: {
		color: 'white',
		backgroundColor: '#006da6',
		fontWeight: 600
	}
});

import styled from 'styled-components';
import BannerImage from '../../assets/images/header_banner.png';
import PageContainer from '../PageContainer';

export const StyledPageContainer = styled(PageContainer)`
	color: ${({ theme: { colors } }) => colors.text};
`;

export const StyledTableHeading = styled.h2`
	font-size: 16px;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		font-size: 18px;
	}
`;

export const StyledTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	thead {
		background: url(${BannerImage});
	}
	font-size: 12px;
	thead th {
		padding: 0.75rem 0.5rem;
	}
	tbody td {
		padding: 0.5rem 0.625rem;
	}
	tbody td,
	thead th {
		border: 1px solid #d9dde0;
	}
	tbody tr:nth-child(even) {
		background-color: #f5f5f5;
	}
	tbody tr:hover {
		background-color: #ddd;
	}
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		font-size: 16px;
		thead th {
			padding: 1rem 0.5rem;
			text-align: left;
		}
		tbody td {
			padding: 0.75rem 0.5rem;
		}
	}
`;

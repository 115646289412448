import React, { useEffect, useRef, useState } from 'react';
import {
	DEFAULT_FORM_VALUES,
	competitiveOrNonOptions,
	numberOfEventsOptions,
	ottSponsorshipOptions,
	isRecommendationFutureSponsorshipOpts
} from './constants';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form-v7';
import { navigate, useParams } from '@reach/router';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getEventDropdowns,
	getSponsorshipOutcomeForm,
	getSponsorshipOutcomeFormByIdAdmin,
	getUserImage,
	saveSponsorshipOutcomeForm,
	getAdminActionSponsorshipOutcomeOptionsList,
	saveAdminAction,
	saveSponsorshipOutcomeFormStatus
} from '../../graphql/custom.queries';
import {
	APPROVED,
	BLANK_OPTION,
	REJECTED,
	REVIEW,
	SponsorshipOutcomeFormKeys
} from '../../utils/constants';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import FormTextField from '../FormTextField';
import { schema } from './schema';
import {
	formatDateReadableVariant,
	sortArray,
	convertToSortedMultiListData
} from '../../utils/helpers';
import numeral from 'numeral';
import ResizingTextArea from '../ResizingTextArea';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import { useToast } from '../../hooks/useToast';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
// import Divider from '../Divider';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import { mapArrayToMultiSelectValues, mapFileArray } from './helpers';
import FormLabelField from '../FormLabelField';
import FormDropDown from '../FormDropDown';
import AdminActions from '../AdminActions';
import { useAuth } from '../../hooks/useAuth';

// form validation rules

const SponsorshipOutcomeFormReview = () => {
	const [referenceEventOrganisers, setReferenceEventOrganisers] = useState([]);
	let { id } = useParams();
	const { addToast } = useToast();
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const hrefLink = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [imgSrcPdf, setImageSrcPdf] = useState(null);
	const {
		handleSubmit,
		watch,
		control,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onSubmit',
		defaultValues: DEFAULT_FORM_VALUES,
		resolver: yupResolver(schema)
	});

	const values = watch();
	const { currentUser } = useAuth();

	const [outcomeId, setOutcomeId] = useState(0);
	const [actionMemberId, setActionMemberId] = useState(1);
	const [adminStatusId, setAdminStatusId] = useState(0);
	const [outcomeList, setOutcomeList] = useState([]);
	const [actionMemberList, setActionMemberList] = useState([]);
	const [adminStatusList, setAdminStatusList] = useState([]);
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [adminActions, setAdminActions] = useState(null);
	const [adminActionList, setAdminActionList] = useState(null);
	const [error, setError] = useState('');
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [sponsorshipOutcomeForm, setSponsorshipOutcomeForm] = useState(null);
	const [isSponsorshipOutcomeReview, setIsSponsorshipOutcomeReview] = useState(
		false
	);
	const [
		sponsorshipOutcomeReportError,
		setSponsorshipOutcomeReportError
	] = useState({
		whatNeedsImproveAgreement: {
			value: false,
			message: `What needs to improve for the sponsorship agreement to meet the objective is required*`
		},
		howHasSponsorshipAgreementDelivered: {
			value: false,
			message:
				'How has the sponsorship agreement delivered the objective is required*'
		},
		improvementsRequired: {
			value: false,
			message: `Improvements required is required*`
		},
		commentsOnSponsorshipOverall: {
			value: false,
			message: `Comments on the Event Sponsorship overall is required*`
		},
		renewalRecommendation: {
			value: false,
			message: `Please provide further details about the renewal recommendation is required*`
		},
		sponsorshipReviewOutcome: {
			value: false,
			message: `Sponsorship Review Outcome is required*`
		}
	});
	const [isInitLoaded, setIsInitLoaded] = useState(true);

	useEffect(() => {
		if (imgSrcPdf != null) {
			hrefLink.current.click();
		}
	}, [imgSrcPdf]);

	useEffect(() => {
		const fetchSponsorshipOutcomeById = async () => {
			setIsLoading(true);
			AppSyncService.execute(getSponsorshipOutcomeFormByIdAdmin, {
				id: +id
			}).then((result) => {
				setIsLoading(false);
				if (result) {
					if (result.data?.getSponsorshipOutcomeFormByIdAdmin) {
						setIsReadOnly(
							result.data?.getSponsorshipOutcomeFormByIdAdmin?.status ===
								APPROVED ||
								result.data?.getSponsorshipOutcomeFormByIdAdmin?.status ===
									REJECTED
						);
						const formData = result.data?.getSponsorshipOutcomeFormByIdAdmin;
						setSponsorshipOutcomeForm(formData);
						setIsInitLoaded(true);
						updateFormData(formData);
					}
				}
			});
		};

		const fetchSponsorshipOutcome = async () => {
			setIsLoading(true);
			AppSyncService.execute(getSponsorshipOutcomeForm).then((result) => {
				setIsLoading(false);
				if (result) {
					if (result.data?.getSponsorshipOutcomeForm) {
						setIsReadOnly(
							result.data?.getSponsorshipOutcomeForm?.status === APPROVED ||
								result.data?.getSponsorshipOutcomeForm?.status === REVIEW
						);
						const formData = result.data?.getSponsorshipOutcomeForm;
						updateFormData(formData);
					}
				}
			});
		};

		if (id) fetchSponsorshipOutcomeById();
		else fetchSponsorshipOutcome();

		AppSyncService.execute(getEventDropdowns).then((data) => {
			// if (data?.data?.listEventDisciplines) {
			// 	setReferenceEventDisciplines(
			// 		[BLANK_OPTION].concat(data.data.listEventDisciplines)
			// 	);
			// }

			if (data?.data?.listEventOrganisers) {
				setReferenceEventOrganisers(
					[BLANK_OPTION].concat(data.data.listEventOrganisers)
				);
			}
		});
	}, []);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getAdminActionSponsorshipOutcomeOptionsList).then(
			(list) => {
				setAdminActionList(list.data);
				setIsLoading(false);
			}
		);
	}, []);

	useEffect(() => {
		if (adminActionList && sponsorshipOutcomeForm) {
			let modifiedOutcomeList = sortArray(
				adminActionList.listAdminActionOutcomesSponsorship,
				true
			);
			modifiedOutcomeList.unshift({ id: 0, value: '' });
			setOutcomeList(modifiedOutcomeList);
			let modifiedActionList = adminActionList.listAdminActionStauses;
			modifiedActionList.unshift({ id: 0, value: '' });
			setAdminStatusList(modifiedActionList);
			let memberList = [];
			adminActionList.getAdminMembers.map((v) => {
				memberList.push({
					id: v.externalId,
					value: `${v.firstName} ${v.lastName}`
				});
			});
			setActionMemberList(memberList);

			setAdminActions(sponsorshipOutcomeForm.adminAction);
			let memberId = sponsorshipOutcomeForm.adminAction?.admin?.externalId
				? sponsorshipOutcomeForm.adminAction?.admin?.externalId
				: currentUser?.UserId;
			let outComeId = sponsorshipOutcomeForm.adminAction?.adminOutcome?.id
				? sponsorshipOutcomeForm.adminAction?.adminOutcome?.id
				: 0;
			let adminStatus = sponsorshipOutcomeForm?.adminAction?.adminStatus?.id
				? sponsorshipOutcomeForm.adminAction?.adminStatus?.id
				: 0;
			setOutcomeId(outComeId);
			setActionMemberId(memberId);
			setAdminStatusId(adminStatus);
		}
	}, [sponsorshipOutcomeForm, adminActionList]);

	useEffect(() => {
		const isToggleAgreementTrue =
			(values.howHasSponsorshipAgreementDelivered.length > 0 &&
				values.hasTheSponsorshipAgreement) ||
			(!values.hasTheSponsorshipAgreement &&
				values.whatNeedsImproveAgreement.length > 0);
		if (
			isToggleAgreementTrue &&
			((values.areThereAdditionalImprovements &&
				values.improvementsRequired.length > 0) ||
				!values.areThereAdditionalImprovements) &&
			values.commentsOnSponsorshipOverall.length > 0 &&
			values.renewalRecommendation.length > 0 &&
			sponsorshipOutcomeForm
		) {
			setIsSponsorshipOutcomeReview(true);
		} else {
			setIsSponsorshipOutcomeReview(false);
		}
	}, [values, sponsorshipOutcomeForm]);

	useEffect(() => {
		if (!isInitLoaded) {
			if (outcomeId == 0) {
				setSponsorshipOutcomeReportError({
					...sponsorshipOutcomeReportError,
					sponsorshipReviewOutcome: {
						...sponsorshipOutcomeReportError.sponsorshipReviewOutcome,
						value: true
					}
				});
			} else {
				setSponsorshipOutcomeReportError({
					...sponsorshipOutcomeReportError,
					sponsorshipReviewOutcome: {
						...sponsorshipOutcomeReportError.sponsorshipReviewOutcome,
						value: false
					}
				});
			}
		}
	}, [outcomeId, isInitLoaded]);

	const updateReviewNotes = (val) => {
		setValue('reviewNotes', val);
		setIsAdminActionUpdated(true);
	};

	const updateReasonForDecline = (val) => {
		setValue('rejectReasonNotification', val);
		setIsAdminActionUpdated(true);
	};

	const updateFields = (name, value) => {
		if (name === 'outcome') {
			setOutcomeId(value);
		}
		if (name === 'adminStatus') {
			setAdminStatusId(value);
		}

		if (name === 'memberId') {
			setActionMemberId(value);
		}
		setIsInitLoaded(false);
		setIsAdminActionUpdated(true);
	};

	const completeForm = (status, complete = true) => {
		updateAdminActions(status, complete);
	};

	const updateAdminActions = (status = null, complete) => {
		setIsLoadingAdminAction(true);
		const hasError = evaluateAdminError();
		if (hasError && complete) {
			setIsLoadingAdminAction(false);
			return;
		}
		AppSyncService.execute(saveAdminAction, {
			formId: parseInt(sponsorshipOutcomeForm?.id),
			formType: 'SponsorshipOutcomeForm',
			memberExternalId: actionMemberId,
			adminOutcomeId: parseInt(outcomeId) === 0 ? null : parseInt(outcomeId),
			adminStatusId:
				parseInt(adminStatusId) === 0 ? null : parseInt(adminStatusId),
			id:
				adminActions?.admin?.id === actionMemberId
					? adminActions?.admin?.id
					: null
		}).then((data) => {
			if (
				data.data != null &&
				!data.data?.saveAdminAction?.error?.errorMessage
			) {
				const sponsorshipOutcomeFormAdminInput = {
					reviewNotes: values.reviewNotes,
					hasTheSponsorshipAgreement: values.hasTheSponsorshipAgreement,
					howHasSponsorshipAgreementDelivered:
						values.howHasSponsorshipAgreementDelivered,
					whatNeedsImproveAgreement: values.whatNeedsImproveAgreement,
					areThereAdditionalImprovements: values.areThereAdditionalImprovements,
					improvementsRequired: values.improvementsRequired,
					commentsOnSponsorshipOverall: values.commentsOnSponsorshipOverall,
					doYouRecommendRenewSponsorship: values.doYouRecommendRenewSponsorship,
					isRecommendationFutureSponsorship:
						values.isRecommendationFutureSponsorship,
					renewalRecommendation: values.renewalRecommendation,
					rejectReasonNotification: values.rejectReasonNotification,
					organisation: values.organisationVal
				};
				AppSyncService.execute(saveSponsorshipOutcomeFormStatus, {
					id: sponsorshipOutcomeForm.id,
					status: status,
					sponsorshipAdminInput: sponsorshipOutcomeFormAdminInput
				}).then((result) => {
					setIsLoadingAdminAction(false);
					if (
						result.data != null &&
						!result.data?.saveSponsorshipOutcomeFormStatus?.error?.errorMessage
					) {
						addToast({
							Message: 'Sponsorship Outcome Review changes have been saved',
							IsSuccess: true
						});
						navigate(`/event-reporting`);
					} else {
						addToast({ Message: 'Failed to Update', IsSuccess: false });
						setError(
							result.data?.saveSponsorshipOutcomeFormStatus?.error?.errorMessage
						);
					}
				});
			} else {
				setIsLoadingAdminAction(false);
				addToast({ Message: 'Failed to Update', IsSuccess: false });
				setError(data.data?.saveAdminAction?.error?.errorMessage);
			}
		});
	};

	const evaluateAdminError = () => {
		let errorItems = { ...sponsorshipOutcomeReportError };

		let whatNeedsImproveAgreement = {
			...errorItems['whatNeedsImproveAgreement'],
			value:
				!values.hasTheSponsorshipAgreement &&
				values.whatNeedsImproveAgreement.length === 0
		};
		errorItems['whatNeedsImproveAgreement'] = whatNeedsImproveAgreement;

		let howHasSponsorshipAgreementDelivered = {
			...errorItems['howHasSponsorshipAgreementDelivered'],
			value:
				values.hasTheSponsorshipAgreement &&
				values.howHasSponsorshipAgreementDelivered.length === 0
		};
		errorItems[
			'howHasSponsorshipAgreementDelivered'
		] = howHasSponsorshipAgreementDelivered;

		let improvementsRequired = {
			...errorItems['improvementsRequired'],
			value:
				values.areThereAdditionalImprovements &&
				values.improvementsRequired.length === 0
		};
		errorItems['improvementsRequired'] = improvementsRequired;

		let commentsOnSponsorshipOverall = {
			...errorItems['commentsOnSponsorshipOverall'],
			value: values.commentsOnSponsorshipOverall.length === 0
		};
		errorItems['commentsOnSponsorshipOverall'] = commentsOnSponsorshipOverall;

		let renewalRecommendation = {
			...errorItems['renewalRecommendation'],
			value: values.renewalRecommendation.length === 0
		};
		errorItems['renewalRecommendation'] = renewalRecommendation;

		let sponsorshipReviewOutcome = {
			...errorItems['sponsorshipReviewOutcome'],
			value: outcomeId == 0
		};
		errorItems['sponsorshipReviewOutcome'] = sponsorshipReviewOutcome;

		setSponsorshipOutcomeReportError(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const updateFormData = (data) => {
		setValue('id', data.id ?? null);
		setValue('organisation', data.organisation?.id ?? null);
		setValue('organisationVal', data.organisation?.value ?? '');
		setValue('representativeName', data.representativeName ?? null);
		setValue('role', data.role ?? null);
		setValue('contactDetails', data.contactDetails ?? null);
		setValue('reportDate', data.reportDate ?? DEFAULT_FORM_VALUES.reportDate);
		setValue('effectiveDateAgreement', data.effectiveDateAgreement ?? null);
		setValue('conclusionDateAgreement', data.conclusionDateAgreement ?? null);
		setValue(
			'totalValueAgreement',
			data.totalValueAgreement
				? numeral(data.totalValueAgreement).format('0,0')
				: null
		);
		setValue(
			'utilizationOfOttSponsorship',
			data.utilizationOfOttSponsorship?.length > 0
				? mapArrayToMultiSelectValues(
						data.utilizationOfOttSponsorship,
						ottSponsorshipOptions
				  )
				: DEFAULT_FORM_VALUES.utilizationOfOttSponsorship
		);
		setValue(
			'utilizationOfOttSponsorshipOther',
			data.utilizationOfOttSponsorshipOther ?? null
		);
		setValue('numberOfEventsIncluded', data.numberOfEventsIncluded ?? null);
		setValue('totalOttClassesRun', data.totalOttClassesRun ?? null);
		setValue('overallEntrantsNumber', data.overallEntrantsNumber ?? null);
		setValue('ottEntrantsNumber', data.ottEntrantsNumber ?? null);
		// setValue('discipline', data.discipline?.id ?? null);
		setValue(
			'discipline',
			data.discipline?.length > 0
				? convertToSortedMultiListData(data.discipline)
				: DEFAULT_FORM_VALUES.discipline
		);
		setValue('disciplineOther', data.otherDiscipline ?? null);
		setValue(
			'isPrizeMoneyDistributed',
			data.isPrizeMoneyDistributed ??
				DEFAULT_FORM_VALUES.isPrizeMoneyDistributed
		);
		// setValue(
		// 	'amountOfPrizeMoneyDistributed',
		// 	data.amountOfPrizeMoneyDistributed ?? null
		// );
		setValue(
			'amountOfPrizeMoneyDistributed',
			data.amountOfPrizeMoneyDistributed
				? numeral(data.amountOfPrizeMoneyDistributed).format('0,0')
				: null
		);
		setValue(
			'numberOfFacebookEventPosts',
			data.numberOfFacebookEventPosts ?? null
		);
		setValue(
			'numberOfInstagramEventPosts',
			data.numberOfInstagramEventPosts ?? null
		);
		setValue('otherSocialMediaUpdates', data.otherSocialMediaUpdates ?? null);
		setValue(
			'numberOfFacebookPostShare',
			data.numberOfFacebookPostShare ?? null
		);
		setValue(
			'numberOfInstagramPostShare',
			data.numberOfInstagramPostShare ?? null
		);
		setValue('otherComments', data.otherComments ?? null);
		setValue('signageDescription', data.signageDescription ?? null);
		setValue('whatWorkedWellQuestion', data.whatWorkedWellQuestion ?? null);
		setValue('whatDidNotWorkQuestion', data.whatDidNotWorkQuestion ?? null);
		setValue('howToImproveQuestion', data.howToImproveQuestion ?? null);
		setValue('keyAchievements', data.keyAchievements ?? null);
		setValue('feedbackFromMembers', data.feedbackFromMembers ?? null);
		setValue(
			'isAnyPathwaysInititiativesCreated',
			data.isAnyPathwaysInititiativesCreated ??
				DEFAULT_FORM_VALUES.isAnyPathwaysInititiativesCreated
		);
		setValue(
			'competitiveOrNonCompetitive',
			data.competitiveOrNonCompetitive?.length > 0
				? mapArrayToMultiSelectValues(
						data.competitiveOrNonCompetitive,
						competitiveOrNonOptions
				  )
				: DEFAULT_FORM_VALUES.competitiveOrNonCompetitive
		);
		setValue('numberOfNewPathways', data.numberOfNewPathways ?? null);
		setValue(
			'isIncreasedUpstakeOfThoroughbreds',
			data.isIncreasedUpstakeOfThoroughbreds ??
				DEFAULT_FORM_VALUES.isIncreasedUpstakeOfThoroughbreds
		);
		setValue('numberOfNewOtts', data.numberOfNewOtts ?? null);
		setValue('commentsOrSuggestions', data.commentsOrSuggestions ?? null);
		setValue(
			'eventPhotos',
			data.eventPhotos?.length > 0
				? mapFileArray(data.eventPhotos)
				: DEFAULT_FORM_VALUES.eventPhotos
		);
		setValue(
			'signageOrPromotionPhotos',
			data.signageOrPromotionPhotos?.length > 0
				? mapFileArray(data.signageOrPromotionPhotos)
				: DEFAULT_FORM_VALUES.signageOrPromotionPhotos
		);
		setValue(
			'reviewNotes',
			data.reviewNotes ?? DEFAULT_FORM_VALUES.reviewNotes
		);
		setValue(
			'hasTheSponsorshipAgreement',
			data.hasTheSponsorshipAgreement ??
				DEFAULT_FORM_VALUES.hasTheSponsorshipAgreement
		);
		setValue(
			'howHasSponsorshipAgreementDelivered',
			data.howHasSponsorshipAgreementDelivered ??
				DEFAULT_FORM_VALUES.howHasSponsorshipAgreementDelivered
		);
		setValue(
			'whatNeedsImproveAgreement',
			data.whatNeedsImproveAgreement ??
				DEFAULT_FORM_VALUES.whatNeedsImproveAgreement
		);
		setValue(
			'areThereAdditionalImprovements',
			data.areThereAdditionalImprovements ??
				DEFAULT_FORM_VALUES.areThereAdditionalImprovements
		);
		setValue(
			'improvementsRequired',
			data.improvementsRequired ?? DEFAULT_FORM_VALUES.improvementsRequired
		);
		setValue(
			'commentsOnSponsorshipOverall',
			data.commentsOnSponsorshipOverall ??
				DEFAULT_FORM_VALUES.commentsOnSponsorshipOverall
		);
		setValue(
			'doYouRecommendRenewSponsorship',
			data.doYouRecommendRenewSponsorship ??
				DEFAULT_FORM_VALUES.doYouRecommendRenewSponsorship
		);
		setValue(
			'isRecommendationFutureSponsorship',
			data.isRecommendationFutureSponsorship
				? data.isRecommendationFutureSponsorship.toString()
				: '0'
		);
		setValue(
			'renewalRecommendation',
			data.renewalRecommendation ?? DEFAULT_FORM_VALUES.renewalRecommendation
		);
		setValue(
			'rejectReasonNotification',
			data.rejectReasonNotification ??
				DEFAULT_FORM_VALUES.rejectReasonNotification
		);
	};

	const saveData = async (payload) => {
		const result = await AppSyncService.execute(saveSponsorshipOutcomeForm, {
			input: payload
		});
		return result;
	};

	const generatePayload = (data) => {
		const { organisation, discipline, ...otherProperties } = data;
		return {
			...otherProperties,
			lastUpdated: otherProperties.lastUpdated || null,
			lastSubmitted: otherProperties.lastSubmitted || null,
			organisationId: organisation || null,
			disciplineId: discipline || null,
			competitiveOrNonCompetitive:
				otherProperties.competitiveOrNonCompetitive?.map(
					(item) => item.value
				) || [],
			utilizationOfOttSponsorship:
				otherProperties.utilizationOfOttSponsorship?.map(
					(item) => item.value
				) || [],
			totalValueAgreement: numeral(otherProperties.totalValueAgreement).value()
		};
	};

	const onSubmit = async () => {
		const payload = generatePayload({ ...values, status: REVIEW });
		const result = await saveData(payload);
		if (
			result.data != null &&
			!result.data?.saveSponsorshipOutcomeForm?.error?.errorMessage
		) {
			addToast({ Message: 'Form Submitted', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		} else {
			addToast({ Message: 'Failed to submit form', IsSuccess: false });
		}
	};

	const handleChange = (name, value) => {
		setValue(name, value);
		setIsAdminActionUpdated(true);
		evaluateAdminError();
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			if (!img.key.includes('.pdf')) {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			} else {
				setImageSrcPdf(data.data.getUserImage.signedUrl);
			}
		});
	};
	return (
		<PageContainer>
			<SEO title="OTT sponsorship agreement - outcome report" />
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<Container
				className={
					['xs', 'sm', 'md'].includes(screenClassForRender)
						? classes.mobileContainer
						: classes.container
				}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormBody>
						<FormSection sectionTitle="OTT sponsorship agreement - outcome report">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Organisation*</div>
											<FormTextField
												value={
													referenceEventOrganisers.find(
														(org) => org.id === values?.organisation
													)?.value
												}
												canEdit={false}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Representative Name*
											</div>
											<Controller
												control={control}
												name="representativeName"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Role*</div>
											<Controller
												control={control}
												name="role"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Contact Details*</div>
											<Controller
												control={control}
												name="contactDetails"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Report Date*</div>
											{values.reportDate
												? formatDateReadableVariant(values.reportDate)
												: ''}
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Effective date of Sponsorship Agreement*
											</div>
											{values.effectiveDateAgreement
												? formatDateReadableVariant(
														values.effectiveDateAgreement
												  )
												: ''}
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Conclusion date of Sponsorship Agreement*
											</div>
											{values.conclusionDateAgreement
												? formatDateReadableVariant(
														values.conclusionDateAgreement
												  )
												: ''}
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total value of Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="totalValueAgreement"
												render={({ field }) => (
													<FormTextField
														canEdit={false}
														preSymbol={'$'}
														{...field}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												How was the sponsorship from OTT utilised?*
											</div>
											<Controller
												control={control}
												name="utilizationOfOttSponsorship"
												render={() => (
													<FormLabelField
														value={values.utilizationOfOttSponsorship
															.map((item) => item.label)
															.join(', ')}
														styles={{ padding: 0 }}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								{values?.utilizationOfOttSponsorship?.find(
									(item) => item.value == 4
								) && (
									<Row>
										<Col xs={12}>
											<div className={classes.listfieldContainer}>
												<Controller
													control={control}
													name="utilizationOfOttSponsorshipOther"
													render={({ field }) => (
														<ResizingTextArea
															{...field}
															title={'Other details of Sponsorship Agreement*'}
															value={values.utilizationOfOttSponsorshipOther}
															maxChars={500}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															canEdit={false}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.utilizationOfOttSponsorshipOther?.message}
												</span>
											</div>
										</Col>
									</Row>
								)}
							</div>
						</FormSection>
						<FormSection sectionTitle="Events overview">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of events which included individual OTT classes?*
											</div>
											<FormTextField
												value={
													numberOfEventsOptions.find(
														(eventOpt) =>
															eventOpt.id === values?.numberOfEventsIncluded
													)?.value
												}
												canEdit={false}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total OTT classes run during this Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="totalOttClassesRun"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total horses entered overall *
											</div>
											<Controller
												control={control}
												name="overallEntrantsNumber"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT entrants*
											</div>
											<Controller
												control={control}
												name="ottEntrantsNumber"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Discipline/s included*
											</div>
											{/* <FormTextField
												value={
													referenceEventDisciplines.find(
														(eventDisc) => eventDisc.id === values?.discipline
													)?.value
												}
												canEdit={false}
											/> */}
											<Controller
												control={control}
												name="discipline"
												render={() => (
													<FormLabelField
														value={values.discipline
															.map((item) => item.label)
															.join(', ')}
														styles={{ padding: 0 }}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								{values?.discipline.filter((r) => r.label === 'Other').length >
									0 && (
									<Row>
										<Col xs={12}>
											<div className={classes.listfieldContainer}>
												<Controller
													control={control}
													name="disciplineOther"
													render={({ field }) => (
														<ResizingTextArea
															{...field}
															title={'Other Discipline/s included*'}
															value={values.disciplineOther}
															maxChars={500}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															canEdit={false}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.disciplineOther?.message}
												</span>
											</div>
										</Col>
									</Row>
								)}
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Was any prizemoney distributed?
											</div>
											<Controller
												control={control}
												name="isPrizeMoneyDistributed"
												render={({ field }) => (
													<Toggle
														{...field}
														id={
															SponsorshipOutcomeFormKeys.PRIZE_MONEY_DISTRIBUTED
														}
														labelNegative="No"
														labelPositive="Yes"
														value={values.isPrizeMoneyDistributed}
														disabled={true}
													/>
												)}
											/>
										</div>
									</Col>
									{values.isPrizeMoneyDistributed && (
										<Col lg={4} xs={12}>
											<div className={classes.listfieldContainer}>
												<div className={classes.fieldTitle}>
													If Yes, what was the total amount of prizemoney
													distributed?*
												</div>
												<Controller
													control={control}
													name="amountOfPrizeMoneyDistributed"
													render={({ field }) => (
														<FormTextField
															canEdit={false}
															preSymbol={'$'}
															{...field}
														/>
													)}
												/>
											</div>
										</Col>
									)}
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="Marketing and promotion">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of event posts, Facebook*
											</div>
											<Controller
												control={control}
												name="numberOfFacebookEventPosts"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of event posts, Instagram*
											</div>
											<Controller
												control={control}
												name="numberOfInstagramEventPosts"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="otherSocialMediaUpdates"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Other social media updates?'}
														value={values.otherSocialMediaUpdates}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.otherSocialMediaUpdates?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT post shares, Facebook*
											</div>
											<Controller
												control={control}
												name="numberOfFacebookPostShare"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT post shares, Instagram*
											</div>
											<Controller
												control={control}
												name="numberOfInstagramPostShare"
												render={({ field }) => (
													<FormTextField canEdit={false} {...field} />
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="otherComments"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Other comments'}
														value={values.otherComments}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="signageDescription"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Please describe the signage presence at the event/s*'
														}
														value={values.signageDescription}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Photos of signage or promotions
											</div>
											<Controller
												control={control}
												name="signageOrPromotionPhotos"
												render={({ field }) => (
													<UploadPhoto
														{...field}
														data={values.signageOrPromotionPhotos}
														getImage={getImage}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="Feedback and highlights">
							<div className={classes.sectionContainer}>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="whatWorkedWellQuestion"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'What worked well?*'}
														value={values.whatWorkedWellQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="whatDidNotWorkQuestion"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'What didn’t work, why?*'}
														value={values.whatDidNotWorkQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="howToImproveQuestion"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'How can this be improved if the sponsorship is continued in the following year?*'
														}
														value={values.howToImproveQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="keyAchievements"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Key achievements and/ or highlights, specific to OTT if possible*'
														}
														value={values.keyAchievements}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="feedbackFromMembers"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Feedback from your members?*'}
														value={values.feedbackFromMembers}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Photos event/s</div>
											<Controller
												control={control}
												name="eventPhotos"
												render={({ field }) => (
													<UploadPhoto
														{...field}
														data={values.eventPhotos}
														getImage={getImage}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="OTT pathways">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Were any new pathways or initiatives activated?
											</div>
											<Controller
												control={control}
												name="isAnyPathwaysInititiativesCreated"
												render={({ field }) => (
													<Toggle
														{...field}
														id={SponsorshipOutcomeFormKeys.PATHWAYS_ACTIVATED}
														labelNegative="No"
														labelPositive="Yes"
														value={values.isAnyPathwaysInititiativesCreated}
														disabled={true}
													/>
												)}
											/>
										</div>
									</Col>
									{values.isAnyPathwaysInititiativesCreated && (
										<>
											<Col lg={4} xs={12}>
												<div className={classes.listfieldContainer}>
													<div className={classes.fieldTitle}>
														Competitive or Non-competitive?*
													</div>
													<Controller
														control={control}
														name="competitiveOrNonCompetitive"
														render={() => (
															<FormLabelField
																value={values.competitiveOrNonCompetitive
																	.map((item) => item.label)
																	.join(', ')}
																styles={{ padding: 0 }}
															/>
														)}
													/>
												</div>
											</Col>
											<Col lg={4} xs={12}>
												<div className={classes.listfieldContainer}>
													<div className={classes.fieldTitle}>
														Number of new pathways*
													</div>
													<Controller
														control={control}
														name="numberOfNewPathways"
														render={({ field }) => (
															<FormTextField canEdit={false} {...field} />
														)}
													/>
												</div>
											</Col>
										</>
									)}
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Are you experiencing an increased uptake of
												thoroughbreds?
											</div>
											<Controller
												control={control}
												name="isIncreasedUpstakeOfThoroughbreds"
												render={({ field }) => (
													<Toggle
														{...field}
														id={SponsorshipOutcomeFormKeys.INCREASED_UPSTAKE}
														labelNegative="No"
														labelPositive="Yes"
														value={values.isIncreasedUpstakeOfThoroughbreds}
														disabled={true}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.isIncreasedUpstakeOfThoroughbreds?.message}
											</span>
										</div>
									</Col>
									{values.isIncreasedUpstakeOfThoroughbreds && (
										<Col lg={4} xs={12}>
											<div className={classes.listfieldContainer}>
												<div className={classes.fieldTitle}>
													Number of new OTTs?*
												</div>
												<Controller
													control={control}
													name="numberOfNewOtts"
													render={({ field }) => (
														<FormTextField canEdit={false} {...field} />
													)}
												/>
											</div>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="commentsOrSuggestions"
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Comments or suggestions on how to progress new OTT pathways or opportunities'
														}
														value={values.commentsOrSuggestions}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={false}
													/>
												)}
											/>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="Sponsorship outcome review">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Has the sponsorship agreement met the objective?
											</div>
											<Controller
												control={control}
												name="hasTheSponsorshipAgreement"
												render={({ field }) => (
													<Toggle
														{...field}
														id={
															SponsorshipOutcomeFormKeys.HAS_SPONSORSHIP_MET_OBJECTIVE
														}
														labelNegative="No"
														labelPositive="Yes"
														onClick={(val) => {
															handleChange('hasTheSponsorshipAgreement', val);
															setIsAdminActionUpdated(true);
														}}
														value={values.hasTheSponsorshipAgreement}
														disabled={isReadOnly}
													/>
												)}
											/>
										</div>
									</Col>
									{values.hasTheSponsorshipAgreement && (
										<>
											<Col lg={12} xs={12}>
												<div className={classes.listfieldContainer}>
													<Controller
														control={control}
														name="howHasSponsorshipAgreementDelivered"
														onChange={(e) => {
															handleChange(e.target.name, e.target.value);
														}}
														render={({ field }) => (
															<ResizingTextArea
																{...field}
																title={
																	'How has the sponsorship agreement delivered the objective?*'
																}
																value={
																	values.howHasSponsorshipAgreementDelivered
																}
																maxChars={700}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																onChange={(e) => {
																	handleChange(e.target.name, e.target.value);
																	setIsAdminActionUpdated(true);
																}}
																canEdit={!isReadOnly}
															/>
														)}
													/>
													<span className={classes.errorText}>
														{sponsorshipOutcomeReportError
															.howHasSponsorshipAgreementDelivered.value
															? sponsorshipOutcomeReportError
																	.howHasSponsorshipAgreementDelivered.message
															: null}
													</span>
												</div>
											</Col>
										</>
									)}
									{!values.hasTheSponsorshipAgreement && (
										<>
											<Col lg={12} xs={12}>
												<div className={classes.listfieldContainer}>
													<Controller
														control={control}
														name="whatNeedsImproveAgreement"
														onChange={(e) => {
															handleChange(e.target.name, e.target.value);
														}}
														render={({ field }) => (
															<ResizingTextArea
																{...field}
																title={
																	'What needs to improve for the sponsorship agreement to meet the objective?*'
																}
																value={values.whatNeedsImproveAgreement}
																maxChars={700}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																onChange={(e) => {
																	handleChange(e.target.name, e.target.value);
																	setIsAdminActionUpdated(true);
																}}
																canEdit={!isReadOnly}
															/>
														)}
													/>
													<span className={classes.errorText}>
														{sponsorshipOutcomeReportError
															.whatNeedsImproveAgreement.value
															? sponsorshipOutcomeReportError
																	.whatNeedsImproveAgreement.message
															: null}
													</span>
												</div>
											</Col>
										</>
									)}
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Are there are additional improvements required,
												inclusive of updates to future sponsorship agreements?
											</div>
											<Controller
												control={control}
												name="areThereAdditionalImprovements"
												render={({ field }) => (
													<Toggle
														{...field}
														id={
															SponsorshipOutcomeFormKeys.ADDITIONAL_IMPROVEMENTS_REQUIRED
														}
														labelNegative="No"
														labelPositive="Yes"
														onClick={(val) => {
															handleChange(
																'areThereAdditionalImprovements',
																val
															);
														}}
														value={values.areThereAdditionalImprovements}
														disabled={isReadOnly}
													/>
												)}
											/>
										</div>
									</Col>
									{values.areThereAdditionalImprovements && (
										<Col lg={12} xs={12}>
											<div className={classes.listfieldContainer}>
												<Controller
													control={control}
													name="improvementsRequired"
													onChange={(e) => {
														handleChange(e.target.name, e.target.value);
													}}
													render={({ field }) => (
														<ResizingTextArea
															{...field}
															title={'Improvements required*'}
															value={values.improvementsRequired}
															maxChars={700}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															onChange={(e) => {
																handleChange(e.target.name, e.target.value);
																setIsAdminActionUpdated(true);
															}}
															canEdit={!isReadOnly}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{sponsorshipOutcomeReportError.improvementsRequired
														.value
														? sponsorshipOutcomeReportError.improvementsRequired
																.message
														: null}
												</span>
											</div>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="commentsOnSponsorshipOverall"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Comments on the Event Sponsorship overall*'}
														value={values.commentsOnSponsorshipOverall}
														maxChars={700}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														onChange={(e) => {
															handleChange(e.target.name, e.target.value);
															setIsAdminActionUpdated(true);
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{sponsorshipOutcomeReportError
													.commentsOnSponsorshipOverall.value
													? sponsorshipOutcomeReportError
															.commentsOnSponsorshipOverall.message
													: null}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Do you recommend to renew this sponsorship agreement?
											</div>
											<Controller
												control={control}
												name="doYouRecommendRenewSponsorship"
												render={({ field }) => (
													<Toggle
														{...field}
														id={
															SponsorshipOutcomeFormKeys.RECOMMEND_TO_RENEW_SPONSORSHIP
														}
														labelNegative="No"
														labelPositive="Yes"
														onClick={(val) => {
															handleChange(
																'doYouRecommendRenewSponsorship',
																val
															);
															setIsAdminActionUpdated(true);
														}}
														value={values.doYouRecommendRenewSponsorship}
														disabled={isReadOnly}
													/>
												)}
											/>
										</div>
									</Col>
									{values.doYouRecommendRenewSponsorship && (
										<Col lg={4} xs={12}>
											<div className={classes.listfieldContainer}>
												<div className={classes.fieldTitle}>
													Is the recommendation that the future sponsorship
													value includes an increase, decrease or remain the
													same?*
												</div>
												<Controller
													control={control}
													name="isRecommendationFutureSponsorship"
													onChange={(e) => {
														handleChange(e.target.name, e.target.value);
													}}
													render={({ field }) => (
														<FormDropDown
															showBlank
															disabled={isReadOnly}
															items={isRecommendationFutureSponsorshipOpts}
															selectedId={
																values.isRecommendationFutureSponsorship
															}
															{...field}
															onChange={(e) => {
																handleChange(e.target.name, e.target.value);
																setIsAdminActionUpdated(true);
															}}
															styles={{ width: '100%' }}
														/>
													)}
												/>
											</div>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="renewalRecommendation"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Please provide further details about the renewal recommendation*'
														}
														value={values.renewalRecommendation}
														maxChars={700}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														onChange={(e) => {
															handleChange(e.target.name, e.target.value);
															setIsAdminActionUpdated(true);
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{sponsorshipOutcomeReportError.renewalRecommendation
													.value
													? sponsorshipOutcomeReportError.renewalRecommendation
															.message
													: null}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection withoutPadding showHeader={false}>
							<AdminActions
								actionMemberId={actionMemberId}
								adminStatusId={adminStatusId}
								outComeId={outcomeId}
								memberList={actionMemberList}
								statusList={adminStatusList}
								outComeList={outcomeList}
								formName="SponsorshipOutcomeForm"
								reviewNotes={values.reviewNotes}
								updateReviewNotes={updateReviewNotes}
								isAdminActionUpdated={isAdminActionUpdated}
								updateFields={updateFields}
								reviewStatus={sponsorshipOutcomeForm?.status}
								isLoading={isLoadingAdminAction}
								updateAdminAction={() => updateAdminActions(REVIEW)}
								completeForm={completeForm}
								error={error}
								updateReasonForDecline={updateReasonForDecline}
								reasonForDecline={values.rejectReasonNotification}
								isSponsorshipOutcomeReview={isSponsorshipOutcomeReview}
								noBorderBottom
								formError={sponsorshipOutcomeReportError}
							/>
						</FormSection>
					</FormBody>
				</form>
				<UploadPhotoErrorModal
					showErrorModal={showImageUploadErrorModal}
					closeModal={() => setShowImageUploadErrorModal(false)}
				/>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
				<a
					style={{ visibility: 'hidden', cursor: 'pointer' }}
					href={imgSrcPdf}
					ref={hrefLink}
					target="_blank"
					rel="noreferrer"
				>
					Pdf Link
				</a>
			</Container>
		</PageContainer>
	);
};

export default SponsorshipOutcomeFormReview;

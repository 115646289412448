import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	footer: {
		left: 0,
		bottom: 0,
		width: '100%',
		backgroundColor: 'rgba(237,237,237,1)',
		color: 'white',
		textAlign: 'center',
		display: 'inline-table',
		paddingBottom: 10
	},

	column: {
		width: '25%',
		float: 'left',
		color: 'rgb(70 71 73)',
		marginTop: 25,
		textTransform: 'uppercase',
		display: 'grid',
		textAlign: 'left',
		fontSize: 12
	},

	mobilecolumn: {
		color: 'rgb(70 71 73)',
		marginTop: 25,
		textTransform: 'uppercase',
		display: 'grid',
		textAlign: 'center',
		fontSize: 12
	},

	linkItem: {
		textAlign: 'left',
		textTransform: 'initial',
		marginBottom: 15,
		color: '#006da6',
		'&:visited': {
			color: '#006da6'
		}
	},
	linkItemMobile: {
		textAlign: 'center',
		textTransform: 'initial',
		marginBottom: 15,
		color: '#006da6',
		'&:visited': {
			color: '#006da6'
		}
	},
	iconContainer: {
		display: 'inline-flex',
		marginBottom: 15
	},
	iconContainerMobile: {
		display: 'inline-flex',
		marginBottom: 15,
		justifyContent: 'center'
	},
	iconLinkItem: {
		textAlign: 'left',
		textTransform: 'initial',
		marginLeft: 5,
		color: '#006da6',
		'&:visited': {
			color: '#006da6'
		}
	},
	colHeader: {
		fontWeight: 'bolder',
		marginTop: 10,
		marginBottom: 15,
		fontSize: 15
	},
	linksContainer: {
		display: 'inherit'
	},
	linksContainerMobile: {
		margin: 'auto',
		display: 'inherit',
		minWidth: 220
	},
	squareIcon: {
		width: '24px',
		height: '24px'
	},
	basicLink: {
		color: '#006da6',
		'&:visited': {
			color: '#006da6'
		},
		cursor: 'pointer'
	},
	columnContainer: {
		width: '75%',
		margin: 'auto'
	},
	columnContainerMobile: {
		width: '80%',
		margin: 'auto'
	}
}));

import { Container } from 'react-grid-system';
import styled from 'styled-components';

export const StyledContainer = styled(Container)`
	max-width: 1000px !important;
	margin-bottom: 150px;
	padding-left: 0px !important;
	padding-right: 0px !important;
`;

export const StyledLoadingContainer = styled.div`
	margin-top: 20px;
	position: absolute;
	left: 45%;
	top: 50%;
	z-index: 3;
`;

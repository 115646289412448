import { createUseStyles } from 'react-jss';
import BannerImage from '../../../assets/images/header_banner.png';

export default createUseStyles({
	formHeader: {
		marginBottom: 20
	},
	formSection: {
		color: '#464749',
		background: `url(${BannerImage})`,
		display: 'flex',
		alignItems: 'center',
		height: 65,
		fontWeight: 800,
		paddingLeft: 15,
		width: '100%',
		boxSizing: 'border-box',
		fontSize: 15,
		'@media (min-width: 992px)': {
			fontSize: 20
		}
	},
	formHeaderTitleSection: {
		extend: 'formSection',
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5
	},
	formHeaderDescriptionContent: {
		height: 65,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'rgb(252,247,218)',
		padding: '0px 15px',
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		fontSize: 13,
		color: '#464749',
		width: '100%',
		boxSizing: 'border-box',
		'@media (min-width: 992px)': {
			fontSize: 15
		}
	}
});

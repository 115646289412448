import { createUseStyles } from 'react-jss';
import dropdown from '../../assets/icons/Dropdown_Down_icon.svg';

export default createUseStyles(() => ({
	customDropdownStyles: {
		position: 'relative',
		verticalAlign: 'middle',
		'& select': {
			height: 40,
			paddingLeft: 16,
			fontSize: 12,
			border: '1px solid #c7ced1',
			backgroundColor: '#fff',
			borderRadius: '4px',
			width: '100%',
			display: 'inline-block',
			font: 'inherit',
			lineHeight: '1.5em',
			padding: '7px 0px 9px 0px',
			margin: 0,
			'-webkitBoxSizing': 'border-box',
			'-mozBoxSizing': 'border-box',
			boxSizing: 'border-box',
			'-webkitAppearance': 'none',
			'-mozAppearance': 'none',
			'-webkitPaddingEnd': '5% !important',
			'&:focus': {
				border: '1px solid #c7ced1',
				outline: 'none',
				backgroundSize: '5px 5px,5px 5px,4.5em 45px',
				backgroundRepeat: 'no-repeat',
				'-webkitPaddingEnd': '5% !important'
			},
			'@media (min-width:992px)': {
				fontSize: 15
			}
		},
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${dropdown})`,
			right: 10,
			marginTop: 9
		}
	},
	disabledStyles: {
		'& select': {
			border: '1px solid rgb(199,206,209)',
			backgroundColor: 'rgba(239, 239, 239, 0.2)',
			opacity: 1,
			color: 'rgb(84,84,84)',
			cursor: 'not-allowed'
		},
		'&:after': {
			filter: 'grayscale(100%)',
			opacity: 0.3
		}
	},
	errorStyle: {
		border: '1px solid red',
		borderRadius: 5
	}
}));

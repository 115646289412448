import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

export const Step = ({ stepNumber, isActive, isDisabled, handleClick }) => {
	const classes = useStyles();
	return (
		<button
			className={classnames({
				[classes.step]: true,
				[classes.selected]: isActive,
				[classes.disabled]: isDisabled
			})}
			onClick={() => handleClick(stepNumber)}
			disabled={isDisabled}
		>
			{stepNumber}
		</button>
	);
};

const Stepper = ({
	idPrefix,
	activeStep,
	previousActiveSteps,
	onStepChange,
	totalSteps
}) => {
	const classes = useStyles();

	let steps = [];
	for (let stepNumber = 1; stepNumber <= totalSteps; stepNumber++) {
		steps.push(
			<Step
				key={`step-${stepNumber}`}
				stepNumber={stepNumber}
				isActive={activeStep === stepNumber}
				handleClick={onStepChange}
				isDisabled={
					activeStep < stepNumber || !previousActiveSteps.includes(stepNumber)
				}
			/>
		);
	}

	return (
		<div id={`${idPrefix}-stepper`} className={classes.stepperContainer}>
			{steps}
		</div>
	);
};

export default Stepper;

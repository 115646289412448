import React from 'react';
import GoogleMapReact from 'google-map-react';
const MELBOURNE_LAT_LONG = { lat: -37.840935, lng: 144.946457 };
const { GOOGLE_MAPS_API_KEY } = process.env;

const Map = ({ children, hoverDistance, centerLatLng, ...rest }) => {
	const defaultMapProps = {
		center: centerLatLng || MELBOURNE_LAT_LONG,
		zoom: 7
	};
	return (
		<div {...rest}>
			<GoogleMapReact
				apiKey={GOOGLE_MAPS_API_KEY}
				center={defaultMapProps.center}
				zoom={defaultMapProps.zoom}
				hoverDistance={hoverDistance}
			>
				{children}
			</GoogleMapReact>
		</div>
	);
};

export default Map;

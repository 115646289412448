import React, { useEffect, useRef } from 'react';
import useStyles from './styles';
import websiteIcon from '../../assets/icons/website-icon.png';
import emailIcon from '../../assets/icons/email icon.png';
import phoneIcon from '../../assets/icons/phone-icon.png';
import twitterIcon from '../../assets/icons/twitter-icon.png';
import facebookIcon from '../../assets/icons/facebook-icon.png';
import instagramIcon from '../../assets/icons/instagram-icon.png';

const SocialIcon = ({
	iconType,
	value,
	url,
	showPopup,
	updateShowPopup,
	triggerGA
}) => {
	const classes = useStyles();
	const node = useRef();

	useEffect(() => {
		document.addEventListener('mousedown', closeIconPopup);

		return () => {
			document.removeEventListener('mousedown', closeIconPopup);
		};
	}, []);

	const closeIconPopup = (e) => {
		if (e.target.tagName !== 'A') {
			if (!node.current.contains(e.target)) {
				showPopupClicked(null);
			}
		}
	};

	const getIcon = () => {
		switch (iconType) {
			case 'website':
				return websiteIcon;
			case 'email':
				return emailIcon;
			case 'phone':
				return phoneIcon;
			case 'twitter':
				return twitterIcon;
			case 'facebook':
				return facebookIcon;
			case 'instagram':
				return instagramIcon;
			default:
				return url;
		}
	};

	const showPopupClicked = (val) => {
		updateShowPopup(val);
	};

	const triggerGAEvent = (label, iconType) => {
		triggerGA(label, iconType);
	};

	return (
		<div ref={node} className={classes.container}>
			<div
				className={classes.iconsContainer}
				onClick={() => {
					showPopupClicked(iconType);
					triggerGAEvent(value, iconType);
				}}
			>
				<img src={getIcon()} />
			</div>
			{showPopup && (
				<div
					onClick={() => showPopupClicked(null)}
					className={classes.iconText}
				>
					<a
						href={url}
						style={{ color: '#006DA6' }}
						rel="noreferrer"
						target="_blank"
					>
						{value}
					</a>
				</div>
			)}
		</div>
	);
};
export default SocialIcon;

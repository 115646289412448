import styled from 'styled-components';

export const StyledTagsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`;

export const StyledTag = styled.span`
	background-color: ${({ theme: { colors } }) => colors.gray};
	color: ${({ theme: { colors } }) => colors.text};
	padding: 5px 10px;
	border-radius: 3px;
	margin: 5px 3px 0px 0px;
	font-size: 12px;
`;

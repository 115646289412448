import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  profilePageContainer: {
    maxWidth: "1000px !important",
    marginBottom: 150,
  },
  navigationLinks: {
    margin: "12px 10px 0px 0px",
    fontSize: 20,
    fontWeight: 800,
  },
  formContainer: {
    border: "1px solid #b3bcc1",
    maxHeight: "100%",
    borderTop: "none",
    borderRadius: "0px 0px 5px 5px",
  },
  fieldTitle: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 7,
    color: "#464749"
  },
  divider: {
    marginTop: 0,
    background: "#edf5f9",
    height: 1,
    border: "none",
  },
  completeFormButton: {
    backgroundColor: "#006da6",
    border: "none",
    borderRadius: 4,
    color: "white",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    height: 38,
    fontWeight: 600,
    width: 170,
    fontSize: 16,
    "&:focus": {
      outline: "none",
    },
  },
  businessProfile: {
    padding: "0 15px",
  },
  ProfilefieldContainer: {
    paddingLeft: 3,
    paddingTop: 15,
    paddingBottom: 10,
    overflow: "auto",
  },
  listfieldContainer: {
    paddingTop: 15,
    paddingBottom: 10,
  },
  sectionContainer: {
    paddingBottom: 20
  },
  postalAddressCheckContainer: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 25,
  },
  clear: {
    clear: "both"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 17px 17px 17px",
  },
  errorContainer: {
    fontSize: 14,
    marginTop: 20,
  },

  lastSaved: {
    paddingRight: "15px",
  },
  errorMessage: {
    fontSize: 12,
    color: "red",
    marginRight: 10
  },
  errorText: {
    color: "#db0000"
  },
  loadingDiv: {
    marginTop: 20,
    position: "absolute",
    left: "45%",
    top: "50%",
    zIndex: 3,
  },
  rightSide: {
    backgroundColor: "#dcebf3",
    paddingBottom: 15,
  }
}));

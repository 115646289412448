import React from 'react';
import useStyles from './styles';
import { Row } from 'react-grid-system';

const FormHeader = ({ formTitle, children }) => {
	const classes = useStyles();
	return (
		<Row className={classes.formHeader}>
			<div className={classes.formHeaderTitleSection}>{formTitle}</div>
			<div className={classes.formHeaderDescriptionContent}>{children}</div>
		</Row>
	);
};

export default FormHeader;

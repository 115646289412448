import React, { useEffect, useState } from 'react';
import { useToast } from '../../hooks/useToast';
import { Paper, CardMedia, Card } from '@material-ui/core';
import PrimaryButton from '../PrimaryButton';
import classnames from 'classnames';

import AppSyncService from '../../graphql/AppSyncService';
import {
	getImageSrcUrl,
	parseUTCToLocal,
	navigateToHorseProfile
} from '../../utils/helpers';
import { followHorse, unfollowHorse } from '../HorseProfile/graphql/queries';
import { FOLLOWING_STATUS } from '../../utils/constants/index';
import FollowConfirmModal from '../HorseProfile/components/FollowConfirmModal';
import LoaderSpinner from '../LoaderSpinner';
import ForSaleImage from '../../assets/images/OTT_Retrainers_Logo_RGB.png';
import { checkImageExist } from '../../graphql/custom.queries';

import useStyles from './styles';
const SuggestionsToFollow = ({
	message,
	horse,
	files,
	createdAt,
	horseCode,
	following,
	onSetFollowingUnfollowing
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [showFollowConfirmModal, setShowFollowConfirmModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [hasNoImage, setHasNoImage] = useState(false);

	useEffect(() => {
		if (files.length > 0) {
			AppSyncService.execute(checkImageExist, { imageKey: files[0].key }).then(
				(data) => {
					if (!data?.data?.checkImageExist) {
						setImgSrcUrl(ForSaleImage);
						setHasNoImage(true);
					} else {
						getImageSrcUrl(files[0].key, setImgSrcUrl);
						setHasNoImage(false);
					}
				}
			);
		} else {
			setImgSrcUrl(ForSaleImage);
			setHasNoImage(true);
		}
	}, [files]);

	const onFollowUnfollowHorse = async () => {
		if (!following) {
			setIsLoading(true);
			const followHorseInput = {
				horseCode: +horseCode
			};
			const result = await AppSyncService.execute(
				followHorse,
				followHorseInput
			);

			if (result?.data?.createPrpHorseFollows.horse_code) {
				onSetFollowingUnfollowing(horseCode, true);
				setShowFollowConfirmModal(true);
				addToast({ Message: 'Following horse', IsSuccess: true });
			} else {
				addToast({
					Message: 'Failed to follow horse',
					IsSuccess: false
				});
			}
			setIsLoading(false);
		} else {
			setIsLoading(true);
			const unfollowHorseInput = {
				horseCode: +horseCode
			};
			const result = await AppSyncService.execute(
				unfollowHorse,
				unfollowHorseInput
			);
			if (result?.data?.deletePrpHorseFollows?.horse_code) {
				onSetFollowingUnfollowing(horseCode, false);
				addToast({ Message: 'Unfollowed horse', IsSuccess: true });
			} else {
				addToast({
					Message: 'Failed to Unfollow horse',
					IsSuccess: false
				});
			}
			setIsLoading(false);
		}
	};

	const handleProfileLinkClick = () => navigateToHorseProfile(horseCode);

	return (
		<div>
			<Paper>
				<div className={classes.carouselItemContainer}>
					<div>
						<p className={classes.horseName} onClick={handleProfileLinkClick}>
							{horse.name}
						</p>
						<div className={classes.horseMessage}>{message}</div>
						<p className={classes.updateCreatedTime}>
							{parseUTCToLocal(createdAt).fromNow()}
						</p>
					</div>
					{isLoading ? (
						<LoaderSpinner status={true} />
					) : (
						<PrimaryButton
							id={`post-button`}
							className={classes.startFollowingBtn}
							onClick={() => onFollowUnfollowHorse()}
							disabled={false}
						>
							{following
								? FOLLOWING_STATUS.IS_FOLLOWING
								: FOLLOWING_STATUS.NOT_FOLLOWING}
						</PrimaryButton>
					)}
				</div>
				<Card className={classes.cardContainer}>
					<CardMedia
						component="img"
						style={{ visibility: !imgSrcUrl ? 'hidden' : 'visible' }}
						image={imgSrcUrl}
						className={classnames({
							[classes.suggestedHorseImage]: true,
							[classes.objectFitContainVal]: hasNoImage
						})}
					/>
				</Card>
			</Paper>
			<FollowConfirmModal
				horseName={horse?.name}
				showModal={showFollowConfirmModal}
				closeModal={() => setShowFollowConfirmModal(false)}
			/>
		</div>
	);
};

export default SuggestionsToFollow;

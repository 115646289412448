import React from 'react';
import { Col, Row } from 'react-grid-system';
import { formatDateReadableVariant } from '../../../utils/helpers';
import FormLabelField from '../../FormLabelField';
import useStyles from '../../ResetFormReviewInfo/styles';

const ThoroughbredRelationshipSection = ({
	relationship,
	relationshipOther,
	lastSeenDate
}) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.sectionContainer}>
				<Row>
					{relationship?.value && (
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Relationship to this horse"
								value={relationship?.value}
							/>
						</Col>
					)}
					{relationshipOther && (
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Other Relationship to this horse"
								value={relationshipOther}
							/>
						</Col>
					)}
					{lastSeenDate && (
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Last seen date"
								value={formatDateReadableVariant(lastSeenDate)}
							/>
						</Col>
					)}
					<Col></Col>
				</Row>
			</div>
		</>
	);
};

export default ThoroughbredRelationshipSection;

import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useStyles from './styles';

const TimeInputField = ({
	label,
	isRequired,
	value,
	updateSelection,
	validationError,
	fullWidth,
	timeIntervals = 15,
	timeCaption = 'Time',
	timeFormat = 'h:mm aa'
}) => {
	const classes = useStyles();
	return (
		<FormFieldWrapper
			fieldLabel={label}
			isRequired={isRequired}
			errorMessage={validationError}
			fullWidth={fullWidth}
		>
			<DatePicker
				selected={value}
				onChange={(date) => updateSelection(date)}
				showTimeSelect
				showTimeSelectOnly
				timeIntervals={timeIntervals}
				timeCaption={timeCaption}
				dateFormat={timeFormat}
				className={classes.dateField}
				wrapperClassName={classes.dateContainer}
				autoComplete="off"
			/>
		</FormFieldWrapper>
	);
};

export default TimeInputField;

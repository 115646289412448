export const getTotalUserHorseUpdatesCount = `
  query getTotalUserHorseUpdatesCount {
    countUserHorseUpdates
  }
`;

export const getUserHorseUpdates = `
 query getUserHorseUpdates($paginationIndex: Int!, $paginationSize: Int!){
  getUserHorseUpdates(paginationIndex: $paginationIndex, paginationSize: $paginationSize) {
    created_at
    horse_code
    post_id
    message
    member_id
    source
    horse {
      name
      ottInformation {
        profilePhotoVisibility {
          id
        }
        profilePhoto {
          key
        }
      }
    }
    files {
      filename
      key
    }
  }
 }
`;

export const getRemoveReasons = `
  query getRemoveReasons {
    listHorseUpdateRemoveReasons {
      id
      value
    }
  }
`;

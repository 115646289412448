import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	}
}));

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatToggleValue } from '../../../../utils/helpers';
import UploadPhoto from '../../../UploadPhoto';
import {
	RETIREMENT,
	RETRAINER
} from '../../../RetrainerProfileTemplate/constants';

const ExperienceSection = ({ formData, previewImage, formType }) => {
	return (
		<AdminReviewSection title="Experience">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label={`${
							formType === RETRAINER
								? 'Please describe your riding & competition experience'
								: 'Please describe your experience with retired or spelling thoroughbreds'
						}`}
						value={formData?.ridingExperience}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please list any memberships you currently have"
						value={formData?.memberships.map(({ value }) => value).join(', ')}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.membershipsOther && (
					<SectionRow>
						<AdminReviewDatum
							label="Other memberships"
							value={formData?.membershipsOther}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				{formType === RETRAINER && (
					<>
						<SectionRow>
							<AdminReviewDatum
								label="Do you currently receive coaching?"
								value={formatToggleValue(formData?.coach)}
								largeScreenWidth={12}
							/>
						</SectionRow>
						{formData?.coach && (
							<SectionRow>
								<AdminReviewDatum
									label="Who is your coach?"
									value={formData?.coach}
									largeScreenWidth={12}
								/>
							</SectionRow>
						)}
						<SectionRow>
							<AdminReviewDatum
								label="Are you a qualified coach or instructor?"
								value={formatToggleValue(formData?.qualifyToCoach)}
								largeScreenWidth={12}
							/>
						</SectionRow>
						{formData?.qualifications && (
							<SectionRow>
								<AdminReviewDatum
									label="Relevant Qualifications e.g. EA Level One Coach"
									value={formData?.qualifications}
									largeScreenWidth={12}
								/>
							</SectionRow>
						)}
						<SectionRow>
							<AdminReviewDatum
								label="Please include any educational achievements/certificates detailed in this application (jpg,png,pdf)"
								value={
									<UploadPhoto
										data={formData?.certificates}
										getImage={previewImage}
										canEdit={false}
									/>
								}
							/>
							<AdminReviewDatum
								label="Upload resume (jpg,png,pdf)"
								value={
									<UploadPhoto
										data={formData?.resume}
										getImage={previewImage}
										canEdit={false}
									/>
								}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Are you adding a video link?"
								value={formatToggleValue(formData?.videoDetails)}
								largeScreenWidth={12}
							/>
						</SectionRow>
						{formData?.videoDetails && (
							<SectionRow>
								<AdminReviewDatum
									label="Video link"
									value={formData?.videoDetails}
									largeScreenWidth={12}
								/>
							</SectionRow>
						)}
					</>
				)}
				{formType === RETIREMENT && (
					<>
						<SectionRow>
							<AdminReviewDatum
								label={`Why do you want to become an Acknowledged Retirement Farm Program supplier?`}
								value={formData?.ackFarmSupplierQuestion}
								largeScreenWidth={12}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label={`What are your expectations for support from Racing Victoria?`}
								value={formData?.expectationFromRVQuestion}
								largeScreenWidth={12}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Do you have a formal agreement in place for agistment and/or retirement services?"
								value={formatToggleValue(
									formData?.hadFormalAgreementForAgistmentQuestion
								)}
								largeScreenWidth={12}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label={`Please outline your approach to letting down thoroughbreds when first retiring from racing?`}
								value={formData?.outlineApproachQuestion}
								largeScreenWidth={12}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label={`Please outline who will take responsibility for the horses in your care, should you be away or unable to?`}
								value={formData?.outlineResponsibilityQuestion}
								largeScreenWidth={12}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Please submit at least 2 references from people you have agisted horses for, inclusive of dates (jpg, png, pdf)"
								value={
									<UploadPhoto
										data={formData?.clientRefUploads}
										getImage={previewImage}
										canEdit={false}
									/>
								}
							/>
							<AdminReviewDatum
								label="Please include a minimum of 4 photos of your agistment facilities and fencing (jpg, png, pdf)"
								value={
									<UploadPhoto
										data={formData?.agistmentFacilitiesUploads}
										getImage={previewImage}
										canEdit={false}
									/>
								}
							/>
						</SectionRow>
					</>
				)}
			</Col>
		</AdminReviewSection>
	);
};

export default ExperienceSection;

import React, { useEffect, useState } from 'react';
import HeaderBanner from '../HeaderBanner';
import DataTable from '../DataTable';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { fullCircleList } from './queries';
import FullCircleDataRow from '../FullCircleDataRow';
import {
	checkUserAdmin,
	formatFullCircleRecordVisibility
} from '../../utils/helpers';
import LoaderSpinner from '../LoaderSpinner';

const tableHeadings = ['', 'Name', 'Colour', 'Sex', 'Claimed'];

const mobileHeadings = ['', 'Name', 'Colour'];

const FullCircleListings = ({ isMobile }) => {
	const [fullCircleHorses, setFullCircleHorses] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { currentUser } = useAuth();
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	const [rows, setRows] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		const memberExternalId = { memberExternalId: currentUser?.UserId };
		AppSyncService.execute(fullCircleList, memberExternalId).then((data) => {
			if (data?.data?.getFullCircles) {
				setFullCircleHorses(
					formatFullCircleRecordVisibility(
						data.data.getFullCircles,
						isAdminUser
					)
				);
			}
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		buildRows();
	}, [fullCircleHorses]);

	const sortRowsDown = () => {
		let thoroughbredRows = fullCircleHorses;
		setFullCircleHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horse?.name
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2.horse?.name
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = () => {
		let thoroughbredRows = fullCircleHorses;
		setFullCircleHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horse?.name
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horse?.name
				)
			)
		);
		buildRows();
	};

	const buildRows = () => {
		const numRows = fullCircleHorses.length;
		const constructedRows = fullCircleHorses.map((claimedHorse, index) => {
			return (
				<FullCircleDataRow
					thoroughbred={claimedHorse}
					key={index}
					index={index}
					isLastRow={numRows === index + 1}
					isMobile={isMobile}
				/>
			);
		});
		setRows(constructedRows);
	};

	return (
		<div>
			<HeaderBanner
				title={`Full Circle (${fullCircleHorses.length})`}
				top
				styles={{ marginBottom: '0px', marginTop: 0, borderRadius: 0 }}
			/>
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
		</div>
	);
};

export default FullCircleListings;

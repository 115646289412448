import React from 'react';
import useStyles from './styles';
import { navigate } from 'gatsby';
import HorseAvatar from '../HorseAvatar';

const FullCircleDataRow = ({ thoroughbred, isLastRow, isMobile }) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<tr className={isLastRow ? classes.trbodyLast : classes.trbody}>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={
							thoroughbred?.horse?.ottInformation?.profilePhoto?.key
						}
						idPrefix={`my-stable-${thoroughbred?.horse?.horseCode}`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() =>
							navigate(`/horse-profile/${thoroughbred?.horse?.horseCode}`)
						}
						className={classes.profilePic}
					/>
				</td>
				<td className={classes.td}>
					<span
						className={classes.tbodyName}
						onClick={() =>
							navigate(`/horse-profile/${thoroughbred.horse.horseCode}`)
						}
					>
						{thoroughbred?.horse?.ottInformation?.paddockName ||
							thoroughbred?.horse?.name}
					</span>
				</td>
				<td className={classes.td}>{thoroughbred?.horse?.colour}</td>
				{!isMobile && (
					<>
						<td className={classes.td}>{thoroughbred?.horse?.sex}</td>
						<td className={classes.td}>
							{thoroughbred?.memberHasClaimOnHorse ? 'Yes' : 'No'}
						</td>
					</>
				)}
			</tr>
		</React.Fragment>
	);
};

export default FullCircleDataRow;

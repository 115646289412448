import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import SEO from '../seo';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';

const PhotoUploadGuide = () => {
	const screenClassForRender = useScreenClass();
	const classes = useStyles();
	const [richText, setRichText] = useState(null);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const photoGuide = useStaticQuery(
		graphql`
			query photoGuide {
				allContentfulPhotoUploadGuide(
					sort: { fields: updatedAt, order: DESC }
				) {
					edges {
						node {
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		setRichText(photoGuide.allContentfulPhotoUploadGuide.edges[0].node.body);
	}, []);

	return (
		<>
			<Container
				className={classes.dashboardDesktopPageContainer}
				style={
					['sm', 'xs', 'md'].includes(screenClassForRender)
						? { width: '100%', marginTop: 60 }
						: { width: 660 }
				}
			>
				<SEO title="Upload Image Guide" />
				<Row>
					<Col
						style={
							['sm', 'xs', 'md'].includes(screenClassForRender)
								? {}
								: { paddingLeft: 0, paddingRight: 0 }
						}
					>
						<div>{richText && renderRichText(richText, options)}</div>
						<hr className={classes.hr}></hr>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default PhotoUploadGuide;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		border: '1px solid #b3bcc1',
		borderTop: 'none'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	fieldContainer: {
		padding: '20px 18px 20px 18px'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	profileInfo: {
		fontSize: 15,
		display: 'block',
		marginTop: 10,
		wordBreak: 'break-word'
	},
	uploadFieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	}
}));

import React from 'react';
import useStyles from './styles';
import LazyImage from '../LazyImage';
import ForSaleImage from '../../assets/images/OTT_Retrainers_Logo_RGB.png';

const HorseForSaleCard = ({
	imageLink,
	isMobile,
	headingStyle,
	onClick,
	body,
	disciplines,
	disciplinesOther,
	horseName,
	sex,
	age
}) => {
	const classes = useStyles();

	const renderDisciplines = () => {
		let allDisciplines = [];
		if (disciplines?.length > 0) {
			disciplines?.forEach((discpline) => {
				if (discpline.id === 13) {
					if (!disciplinesOther) return;
					let otherDisciplinesSplit = disciplinesOther.split(',');
					otherDisciplinesSplit.forEach((otherDiscipline) => {
						allDisciplines.push(
							<div
								className={classes.tagsContent}
								key={discpline.id + otherDiscipline}
							>
								{otherDiscipline}
							</div>
						);
					});
				} else
					return allDisciplines.push(
						<div className={classes.tagsContent} key={discpline.id}>
							{discpline.value}
						</div>
					);
			});
		}

		if (allDisciplines?.length > 0) {
			const elemsToDelete = allDisciplines.length - 4;
			if (elemsToDelete > 0)
				allDisciplines.splice(
					allDisciplines.length - elemsToDelete,
					elemsToDelete
				);
			return allDisciplines.map((item) => item);
		} else return <></>;
	};

	return (
		<div style={{ width: '320px', cursor: 'pointer' }} onClick={onClick}>
			<LazyImage
				styles={{ objectFit: 'contain' }}
				imageKey={imageLink}
				defaultImage={ForSaleImage}
				isMobile={isMobile}
				horseForSale
			/>
			<div
				className={
					body ? classes.summaryContainer : classes.noBodysummaryContainer
				}
				id={`${horseName}-${age}`}
				style={
					isMobile
						? {
								marginBottom: 60
						  }
						: { marginBottom: 20 }
				}
			>
				<div className={classes.summaryHeading} style={headingStyle}>
					{horseName}
				</div>
				<div
					style={{ color: 'black', fontSize: 14 }}
				>{`${sex}, ${age} year(s) old`}</div>
				<div className={classes.tags}>{renderDisciplines()}</div>
			</div>
		</div>
	);
};

export default HorseForSaleCard;

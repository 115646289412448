import React, { useState, useEffect } from 'react';
import Toggle from '../Toggle';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import ResizingTextArea from '../ResizingTextArea';
import { Col, Container, Row } from 'react-grid-system';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { getEligibilityReferences } from '../../graphql/custom.queries';
import BasicCheckbox from '../BasicCheckbox';
import FormLabelField from '../FormLabelField';

import { extractLookUpOptionsOther } from '../../utils/helpers';

const EligibilityForBCTS = ({
	data,
	adminData,
	handleCheckbox,
	handleMultiFieldChange,
	adminDataErrors,
	isReadOnly
}) => {
	const classes = useStyles();
	const [viceOptions, setViceOptions] = useState([]);
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	useEffect(() => {
		setIsLoading(true);

		AppSyncService.execute(getEligibilityReferences).then((result) => {
			if (result.data) {
				setInjuryOptions(
					extractLookUpOptionsOther(result, 'listAckRetirementInjuryTypes')
				);
				setViceOptions(extractLookUpOptionsOther(result, 'listHabitsAndVices'));
			}
			setIsLoading(false);
		});
	}, []);

	useEffect(() => {
		if (
			!(
				adminData.hasValidAge ||
				adminData.hasValidConformationalDefects ||
				adminData.hasValidInjury ||
				adminData.hasValidWindSucker ||
				adminData.hasValidUnbroken ||
				adminData.hasValidOtherReason ||
				adminData.hasValidBehaviour
			) &&
			adminData.eligibilityForBCTS
		) {
			setShowErrorMessage(true);
		} else {
			setShowErrorMessage(false);
		}
	}, [
		adminData.hasValidAge,
		adminData.hasValidBehaviour,
		adminData.hasValidConformationalDefects,
		adminData.hasValidInjury,
		adminData.hasValidWindSucker,
		adminData.hasValidUnbroken,
		adminData.eligibilityForBCTS,
		adminData.hasValidOtherReason
	]);

	const renderEligibilityFields = () => {
		return isLoading ? (
			<div className={classes.loadingDiv}>
				<LoaderSpinner status={true} />
			</div>
		) : (
			<>
				<Row style={{ marginTop: 20 }}>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidAge}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidAge')}
							label={`Age >=10: ${data.horse?.age}`}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidBehaviour}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidBehaviour')}
							label={'Behaviour'}
						/>
					</Col>
					{adminData.hasValidBehaviour ? (
						<Col xs={12} lg={12}>
							<div style={{ marginLeft: 35 }}>
								{isReadOnly ? (
									<>
										<FormLabelField
											title={`List of vice/s*`}
											value={adminData.selectedVices
												.map((selectedHabit) => selectedHabit.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
										{adminData.selectedVices.some((vice) => vice.value == 12) &&
										adminData.otherVices.length > 0 ? (
											<FormLabelField
												title={`Other*`}
												value={adminData.otherVices
													.map((vices) => vices.label)
													.join(', ')}
												styles={{ padding: 0 }}
											/>
										) : null}
									</>
								) : (
									<>
										<MultiSelectInputField
											idPrefix={'behaviour-bcts'}
											fieldLabel={`List of vice/s*`}
											selectedIds={adminData.selectedVices}
											options={viceOptions}
											onSelectChange={(newVices) => {
												handleMultiFieldChange(newVices, 'selectedVices');
											}}
											otherValue={adminData.otherVices}
											onOtherChange={(newOtherValue) => {
												handleMultiFieldChange(newOtherValue, 'otherVices');
											}}
											isOtherFieldRequired
											otherDisabled
											otherErrorMessage={adminDataErrors.behaviourOtherBCTS}
										/>
										<div className={classes.fieldRequiredError}>
											{adminDataErrors.behaviourBCTS.value
												? adminDataErrors.behaviourBCTS.message
												: null}
										</div>
									</>
								)}
							</div>
						</Col>
					) : null}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidConformationalDefects}
							disabled={isReadOnly}
							handleChange={() =>
								handleCheckbox('hasValidConformationalDefects')
							}
							label={`Conformational Defect/s`}
						/>
					</Col>
					{adminData.hasValidConformationalDefects ? (
						<Col xs={12} lg={12}>
							<div style={{ marginLeft: 35 }}>
								<ResizingTextArea
									title="Conformational Defect/s-details*"
									titleStyles={{ fontSize: '14px' }}
									id={'conformation-details-bcts'}
									onChange={(e) => {
										handleMultiFieldChange(
											e.target.value,
											'conformationalDefects'
										);
									}}
									styles={{ paddingTop: 0 }}
									maxChars={700}
									value={adminData.conformationalDefects}
									canEdit={!isReadOnly}
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.conformationalBCTS.value
										? adminDataErrors.conformationalBCTS.message
										: null}
								</div>
							</div>
						</Col>
					) : null}
				</Row>
				<Row>
					<Col xs={12} lg={12}>
						<BasicCheckbox
							value={adminData.hasValidInjury}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidInjury')}
							label={'Injury'}
						/>
					</Col>
					{adminData.hasValidInjury ? (
						<>
							<Col xs={12} lg={5}>
								<div style={{ marginLeft: 35, paddingTop: 0, marginTop: 0 }}>
									{isReadOnly ? (
										<FormLabelField
											title={'Current'}
											value={adminData.selectedCurrentInjuries
												.map((selectedInjury) => selectedInjury.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									) : (
										<MultiSelectInputField
											idPrefix={'current-injuries-bcts'}
											fieldLabel={`Current`}
											selectedIds={adminData.selectedCurrentInjuries}
											options={injuryOptions}
											onSelectChange={(newInjuries) => {
												handleMultiFieldChange(
													newInjuries,
													'selectedCurrentInjuries'
												);
											}}
											otherDisabled
											noMargin
										/>
									)}
								</div>
							</Col>
							<Col xs={12} lg={5}>
								<div style={{ marginLeft: 35 }}>
									{isReadOnly ? (
										<FormLabelField
											title={'Previous'}
											value={adminData.selectedPreviousInjuries
												.map((selectedInjury) => selectedInjury.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									) : (
										<MultiSelectInputField
											idPrefix={'previous-injuries-bcts'}
											fieldLabel={`Previous`}
											selectedIds={adminData.selectedPreviousInjuries}
											options={injuryOptions}
											onSelectChange={(newInjuries) => {
												handleMultiFieldChange(
													newInjuries,
													'selectedPreviousInjuries'
												);
											}}
											otherDisabled
											noMargin
										/>
									)}
								</div>
							</Col>
							<Col xs={12} lg={12}>
								{adminData.selectedCurrentInjuries.some(
									(x) => x.value === 12
								) ? (
									<div style={{ marginLeft: 35, marginTop: 5 }}>
										<ResizingTextArea
											title="Current - other*"
											titleStyles={{ fontSize: '14px' }}
											id={'other-current-injury-bcts'}
											onChange={(e) => {
												handleMultiFieldChange(
													e.target.value,
													'otherCurrentInjuries'
												);
											}}
											styles={{ paddingTop: 0 }}
											maxChars={700}
											value={adminData.otherCurrentInjuries}
											canEdit={!isReadOnly}
										/>
										<div className={classes.fieldRequiredError}>
											{adminDataErrors.otherCurrentInjuriesBCTS.value
												? adminDataErrors.otherCurrentInjuriesBCTS.message
												: null}
										</div>
									</div>
								) : null}
							</Col>
							<Col xs={12} lg={12}>
								{adminData.selectedPreviousInjuries.some(
									(x) => x.value === 12
								) ? (
									<div style={{ marginLeft: 35, marginTop: 5 }}>
										<ResizingTextArea
											title="Previous - other*"
											titleStyles={{ fontSize: '14px' }}
											id={'other-previous-injury-bcts'}
											onChange={(e) => {
												handleMultiFieldChange(
													e.target.value,
													'otherPreviousInjuries'
												);
											}}
											styles={{ paddingTop: 0 }}
											maxChars={700}
											value={adminData.otherPreviousInjuries}
											canEdit={!isReadOnly}
										/>
										<div className={classes.fieldRequiredError}>
											{adminDataErrors.otherPreviousInjuriesBCTS.value
												? adminDataErrors.otherPreviousInjuriesBCTS.message
												: null}
										</div>
									</div>
								) : null}
							</Col>
						</>
					) : null}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidWindSucker}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidWindSucker')}
							label={'Windsucker'}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidUnbroken}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidUnbroken')}
							label={'Unbroken'}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<BasicCheckbox
							value={adminData.hasValidOtherReason}
							disabled={isReadOnly}
							handleChange={() => handleCheckbox('hasValidOtherReason')}
							label={'Other reason'}
						/>
					</Col>
					{adminData.hasValidOtherReason ? (
						<Col xs={12} lg={12}>
							<div style={{ marginLeft: 35 }}>
								<ResizingTextArea
									title="Other reason-details*"
									titleStyles={{ fontSize: '14px' }}
									id={'other-reason-details-bcts'}
									onChange={(e) => {
										handleMultiFieldChange(
											e.target.value,
											'otherReasonDetails'
										);
									}}
									maxChars={700}
									value={adminData.otherReasonDetails}
									canEdit={!isReadOnly}
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.otherReasonDetailsBCTS.value
										? adminDataErrors.otherReasonDetailsBCTS.message
										: null}
								</div>
							</div>
						</Col>
					) : null}
				</Row>
			</>
		);
	};

	const onRenderErrorMessage = () => {
		return (
			<Col className={classes.errorMessage} xs={12} lg={8}>
				<div className={classes.fieldContainer}>
					<div className={classes.fieldTitle} style={{ color: 'red' }}>
						You have indicated that this thoroughbred is eligible for BCTS.
						Please provide one or more reason/s in the fields below for this.
					</div>
				</div>
			</Col>
		);
	};

	return (
		<Container>
			<Row>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Eligibility for BCTS?</div>
						<Toggle
							id={'EligibilityForBCTS'}
							labelNegative="No"
							labelPositive="Yes"
							onClick={(value) => {
								handleMultiFieldChange(value, 'eligibilityForBCTS');
							}}
							value={adminData.eligibilityForBCTS}
							disabled={isReadOnly}
						/>
					</div>
				</Col>
				{showErrorMessage ? onRenderErrorMessage() : null}
			</Row>

			{adminData.eligibilityForBCTS ? renderEligibilityFields() : null}
		</Container>
	);
};

export default EligibilityForBCTS;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-grid-system';

const TwoColLayout = ({ children, ...rest }) => {
	return <Row {...rest}>{children}</Row>;
};

const Col1 = ({ children }) => <Col md={6}>{children}</Col>;
const Col2 = ({ children }) => <Col md={6}>{children}</Col>;
const Col3 = ({ children }) => <Col md={4}>{children}</Col>;
const Col4 = ({ children }) => <Col md={8}>{children}</Col>;

TwoColLayout.propTypes = {
	children: PropTypes.node.isRequired
};

TwoColLayout.Col1 = Col1;
TwoColLayout.Col2 = Col2;
TwoColLayout.Col3 = Col3;
TwoColLayout.Col4 = Col4;

export default TwoColLayout;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	claimPageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	formContainer: {
		border: '1px solid #b3bcc1',
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px'
	},
	fieldContainer: {
		padding: '18px 0px 25px 0px'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	profileInfo: {
		fontSize: 15,
		display: 'block',
		marginTop: 17,
		wordBreak: 'break-word'
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	IdentificationDivider: {
		background: '#edf5f9',
		height: 2,
		border: 'none',
		marginTop: 40
	},
	fieldEntry: {
		float: 'left',
		height: '39px',
		borderRadius: '4px',
		border: '1px solid #c7ced1',
		width: '100%',
		boxSizing: 'border-box',
		paddingLeft: 16,
		fontSize: 15,
		'&:focus': {
			outline: 'none'
		}
	},
	pointMessage: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '18px 17px',
		margin: '0px 20px 0px 20px',
		fontSize: 14,
		flexWrap: 'wrap'
	},
	claimMessage: {
		marginTop: 5,
		marginBottom: 10,
		maxWidth: 600,
		display: 'flex'
	},
	points: {
		fontSize: 20,
		fontWeight: 800,
		color: '#464749'
	},
	pointsError: {
		fontSize: 20,
		fontWeight: 800,
		color: 'red'
	},
	horseInfo: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '0px 20px 5px 20px',
		flexDirection: 'column'
	},
	horseName: {
		fontSize: 20,
		fontWeight: 800,
		color: '#464749'
	},
	horseAdditionalInfo: {
		fontStyle: 'italic',
		color: '#757575',
		marginTop: 5
	},
	uploadButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		width: '100%',
		fontSize: 15,
		fontWeight: 400,
		'&:focus': {
			outline: 'none'
		}
	},
	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	disabledCompleteFormButton: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	},
	personalProfile: {
		padding: '0px 18px 0px 18px'
	},
	ProfilefieldContainer: {
		paddingTop: 10,
		paddingBottom: 10
	},
	claimHorseContainer: {
		padding: '0px 18px 0px 18px'
	},
	claimFieldContainer: {
		paddingTop: 10,
		paddingBottom: 10
	},
	uploadFieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		marginTop: 10,
		cursor: 'pointer'
	},
	mailLink: {
		textDecoration: 'none',
		color: '#006da6',
		fontWeight: 600
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	},
	clear: {
		clear: 'both'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},
	uploadedFile: {
		fontStyle: 'italic',
		textDecoration: 'underline',
		fontSize: 13,
		color: '#0085ca',
		cursor: 'pointer'
	},
	clearUpload: {
		fontSize: 13,
		marginLeft: 4,
		color: '#afafaf',
		cursor: 'pointer'
	},
	errorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	},
	loadingDiv: {
		marginTop: '20vh',
		position: 'absolute',
		left: '45%',
		zIndex: 3
	},
	pointsImageContainer: {
		marginTop: '5px',
		display: 'block',
		float: 'left',
		marginRight: '12px'
	},
	pointsImage: {
		height: 30,
		'-webkitTransform': 'rotate(180deg)',
		'-mozTransform': 'rotate(180deg)',
		'-msTransform': 'rotate(180deg)',
		'-oTransform': 'rotate(180deg)',
		transform: 'rotate(180deg)'
	},
	errorText: {
		color: 'red',
		fontSize: 13
	},
	line: {}
}));

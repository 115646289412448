import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	textLabel: {
		color: '#0f0f0f !important',
		fontSize: 14,
		fontWeight: 'bolder'
	},
	iconButtonContainer: {
		width: 24,
		height: 24
	},
	iconColor: {
		color: '#006da6'
	}
}));

import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	imageContainer: {
		display: 'flex',
		justifyContent: 'left',
		height: 200,
		backgroundImage: `url(${BannerImage})`,
		backgroundSize: 'contain',
		borderRadius: '5px 5px 0px 0px',
		padding: 20
	},
	imageContainerMobile: {
		display: 'flex',
		boxSizing: 'border-box',
		justifyContent: 'left',
		minHeight: 150,
		backgroundImage: `url(${BannerImage})`,
		backgroundSize: 'contain',
		borderRadius: 5,
		padding: 10
	},
	textMobile: {
		paddingLeft: 20
	},
	titleMobile: {
		fontSize: 15,
		fontWeight: 600
	},
	addressLine: {
		fontSize: 12
	},
	iconContainer: {
		display: 'flex'
	},
	text: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 100,
		fontSize: 18,
		fontWeight: 600
	}
}));

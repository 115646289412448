import React from 'react';
import PropTypes from 'prop-types';
import { StyledWidget } from './styles';

const StatisticWidget = ({ title, description, ...props }) => (
	<StyledWidget {...props}>
		<div className="title">{title}</div>
		<div className="description">{description}</div>
	</StyledWidget>
);

StatisticWidget.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	description: PropTypes.string
};

export default StatisticWidget;

import React from 'react';
import useStyles from './styles';

const EmptyHorseUpdates = () => {
	const classes = useStyles();
	return (
		<div className={classes.emptyHorseUpdates}>No horse updates to review.</div>
	);
};

export default EmptyHorseUpdates;

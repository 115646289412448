import React, { createContext, useContext, useCallback } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Toast from '../../components/Toast';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../utils/helpers';

const toastOptions = {
	duration: 6000
};

interface ToastType {
	Message: string;
	IsSuccess: boolean;
}

const toastContext = createContext(null);
export const useToast = () => useContext(toastContext);

export function ToastProvider({ children }) {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	const addToast = useCallback((toastData: ToastType) => {
		if (toastData.IsSuccess) {
			toast.success(
				({ id }) => (
					<Toast type="Success" message={toastData.Message} toastId={id} />
				),
				toastOptions
			);
		} else {
			toast.error(
				({ id }) => (
					<Toast type="Failed" message={toastData.Message} toastId={id} />
				),
				toastOptions
			);
		}
	}, []);

	return (
		<toastContext.Provider value={{ addToast }}>
			{children}
			<Toaster position={isSmallScreen ? 'top-center' : 'top-right'} />
		</toastContext.Provider>
	);
}

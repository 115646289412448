import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useParams } from '@reach/router';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useStaticQuery, graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { OTTNewsLink, TopContent } from '../Mobile';
import SEO from '../seo';
import { formatDateReadable } from '../../utils/helpers';
import { navigate } from '@reach/router';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import { useAuth } from '../../hooks/useAuth';

const FullArticle = () => {
	const screenClassForRender = useScreenClass();
	const classes = useStyles();
	let { articleId } = useParams();
	const [otherArticles, setOtherArticles] = useState([]);
	const [richText, setRichText] = useState(null);
	const TAG_NAME_COMMUNITY_CONTRIBUTORS = 'Community Contributors';
	const TAG_NAME_NEWS_FEED = 'News Feed';
	const { currentUser } = useAuth();

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const contentfulArticle = useStaticQuery(
		graphql`
			query FullArticle {
				allContentfulArticle(
					filter: { tags: { in: ["News Feed", "Community Contributors"] } }
					sort: { fields: publishTime, order: DESC }
				) {
					edges {
						node {
							id
							title
							summary
							publishTime
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
								}
							}
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
							tags
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		const currentDate = new Date();
		let contentFulArticles = contentfulArticle.allContentfulArticle.edges.filter(
			(a) => {
				var publishTime = new Date(a.node.publishTime);
				return publishTime.getTime() <= currentDate.getTime();
			}
		);
		let otherArticlesList = [];
		let isCommunityArticle = false;
		contentFulArticles.map((article) => {
			if (article.node.id === articleId) {
				setRichText(article.node.body);
				if (
					currentUser &&
					article.node.tags.includes(TAG_NAME_COMMUNITY_CONTRIBUTORS)
				) {
					isCommunityArticle = true;
				}
			}
		});

		contentFulArticles.map((article) => {
			if (article.node.id != articleId) {
				if (isCommunityArticle) {
					if (article.node.tags.includes(TAG_NAME_COMMUNITY_CONTRIBUTORS)) {
						otherArticlesList.push(article.node);
					}
				} else {
					if (article.node.tags.includes(TAG_NAME_NEWS_FEED)) {
						otherArticlesList.push(article.node);
					}
				}
			}
		});
		setOtherArticles(otherArticlesList);
	}, [articleId]);

	const goToArticle = (id) => {
		navigate(`/ott-news/${id}`);
	};

	const additionalNewsArticlesRender = () => {
		let size = 3;
		return otherArticles.slice(0, size).map((news) => {
			return (
				<OTTNewsLink
					onClick={() => goToArticle(news.id)}
					key={news.id}
					heading={news.title}
					date={formatDateReadable(news.publishTime)}
					imageLink={news.previewImage?.fluid?.src}
					isMobile
				/>
			);
		});
	};

	return (
		<>
			<SEO title="Article" />
			<Container className={classes.dashboardDesktopPageContainer}>
				<Row>
					<Col
						lg={9}
						sm={12}
						xs={12}
						style={
							['sm', 'xs', 'md'].includes(screenClassForRender)
								? {}
								: { flex: '0 0 67%', maxWidth: '67%', width: '67%' }
						}
					>
						{richText && renderRichText(richText, options)}
					</Col>
					<Col
						lg={3}
						sm={12}
						xs={12}
						style={
							['sm', 'xs', 'md'].includes(screenClassForRender)
								? { padding: 15 }
								: {
										flex: '0 0 33%',
										maxWidth: '33%',
										width: '33%',
										padding: 10
								  }
						}
					>
						{otherArticles.length > 0 && (
							<div>
								<TopContent
									isMobile
									styles={{ marginTop: 10, marginBottom: 15 }}
									leftText="Recent News"
								/>
								{additionalNewsArticlesRender()}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default FullArticle;

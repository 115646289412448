import React, { useEffect, useState } from 'react';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import HeaderBanner from '../HeaderBanner';
import DataTable from '../DataTable';
import AppSyncService from '../../graphql/AppSyncService';
import FormMultiSelectList from '../FormMultiSelectList';
import { formatDate, formatDateReadableVariant } from '../../utils/helpers';
import useStyles from './styles';
import {
	//getAcknowledgedRetirementHorses,
	//listAckRetirementNewStatusTypes,
	submitRetirementReportsForReview,
	getRetirementForms,
	dismissRetirementNotificationSubmit,
	unclaimHorse,
	getAcknowledgedFosterHorses
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import HorseManagementDataRow from '../HorseManagementDataRow';
import { useToast } from '../../hooks/useToast';
import { getFosterAssessmentFormLatestUpdated } from './queries';
import { convertToMultiListData } from '../../utils/helpers';
import {
	OTT_INFO_MAIL,
	DRAFT,
	ACK_FOSTER_FILTER_OPT,
	FOSTER
} from '../../utils/constants';
import { logToServer } from '../../utils/logger';
import { navigate } from 'gatsby-link';
import LoaderSpinner from '../LoaderSpinner';

const tableHeadings = [
	'',
	'Name',
	'Status',
	'As of Date',
	'Form Select',
	'Last Report Submitted'
];

const mobileHeadings = null;

const AcknowledgedFosterManagement = ({ isMobile }) => {
	const classes = useStyles();

	const [
		acknowledgedRetirementHorses,
		setAcknowledgedRetirementHorses
	] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const { addToast } = useToast();
	const [originalHorseList, setOriginalHorseList] = useState([]);
	const [hasHorseInOriginalList, setHasHorseInOriginalList] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [currentSortDirection, setCurrentSortDirection] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [retirementDraftFormsCount, setRetirementDraftFormsCount] = useState(0);
	const [latestFormSubmittedDate, setLatestFormSubmittedDate] = useState(null);
	const [
		acknowledgedRetirementQuarterForms,
		setAcknowledgedRetirementQuarterForms
	] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		const memberExternalId = { memberExternalId: currentUser?.UserId };
		setStatusOptions(convertToMultiListData(ACK_FOSTER_FILTER_OPT, 'value'));
		AppSyncService.execute(getAcknowledgedFosterHorses, memberExternalId).then(
			(data) => {
				if (data?.data?.getAcknowledgedFosterHorses) {
					setAcknowledgedRetirementHorses(
						data.data.getAcknowledgedFosterHorses
					);
					const allHorses = JSON.parse(
						JSON.stringify(data.data.getAcknowledgedFosterHorses)
					);
					setOriginalHorseList(allHorses);
					setHasHorseInOriginalList(
						data.data.getAcknowledgedFosterHorses.length > 0 ? true : false
					);
				}
				setIsLoading(false);
			}
		);
		setIsLoading(false);
	}, []);

	const submitReports = async () => {
		if (
			acknowledgedRetirementQuarterForms &&
			acknowledgedRetirementQuarterForms.length > 0
		) {
			for (let i = 0; i < acknowledgedRetirementQuarterForms.length; i++) {
				const form = acknowledgedRetirementQuarterForms[i];
				//3 is equivalent to Deceased on newStatus
				if (form.newStatus?.id === 3) {
					const input = {
						horse_code: Number(form.horse.horseCode),
						member_id: currentUser?.UserId,
						unclaim_reason: 1, //1 is equivalent to Deceased on unclaim_reason
						full_circle_interested: false,
						cause_of_death_id:
							form.deceasedCauseOfDeath.id != 0
								? Number(form.deceasedCauseOfDeath.id)
								: null,
						date_of_death: form.deceasedDateOfDeath
							? formatDate(form.deceasedDateOfDeath)
							: form.deceasedDateOfDeath,
						other_details: null,
						deceased_other_info:
							form.deceasedReasonForDeathOther.id != 0
								? form.deceasedReasonForDeathOther.value
								: null,
						knackery_location:
							(form.deceasedKnackerySuburb || '') +
								(form.deceasedKnackeryPostcode || '') || null
					};

					AppSyncService.execute(unclaimHorse, { input }).then((data) => {
						if (data?.data?.unclaimHorse?.success) {
							addToast({ Message: 'Thoroughbred Unclaimed', IsSuccess: true });
						} else {
							addToast({
								Message: 'An error occured unclaiming.',
								IsSuccess: false
							});
						}
					});
					break;
				}
			}
		}

		try {
			const data = await AppSyncService.execute(
				submitRetirementReportsForReview,
				{}
			);

			await AppSyncService.execute(dismissRetirementNotificationSubmit);
			if (data?.data?.submitRetirementReportsForReview) {
				addToast({
					Message: 'Retirement Form(s) Submitted',
					IsSuccess: true
				});
				logToServer(
					currentUser?.UserId,
					data,
					'Retirement Report form Submission Submitted and Passed'
				);
				navigate('/');
			} else {
				addToast({
					Message: 'An error submitting. Racing Victoria are investigating.',
					IsSuccess: false
				});
				logToServer(
					currentUser?.UserId,
					data,
					'Retirement Report form Submission Submitted and Failed'
				);
			}
		} catch (error) {
			addToast({
				Message:
					'An Error occured when submitting the Retirement Report forms. Please try again later',
				IsSuccess: false
			});
			logToServer(
				currentUser?.UserId,
				error,
				'Retirement Report form Submission Failed'
			);
		}
	};

	const NoRetiredHorsesInfoBarText = () => {
		return (
			<>
				<div>
					You do not have any claimed thoroughbreds which are assigned to the
					STAR Program.
				</div>
				<div>
					If this is incorrect, please ensure you have claimed the thoroughbreds
					first, then email{' '}
					<a href={'mailto:' + OTT_INFO_MAIL}>{OTT_INFO_MAIL}</a> with the horse
					name/s to have the Program updated.
				</div>
			</>
		);
	};

	useEffect(() => {
		buildRows();
	}, [acknowledgedRetirementHorses]);

	const reloadReportNumbers = () => {
		AppSyncService.execute(getRetirementForms, {
			memberExternalId: currentUser?.UserId,
			status: [DRAFT]
		}).then((data) => {
			if (
				data?.data?.getRetirementInitialAssessmentForms?.length > 0 ||
				data?.data?.getRetirementQuarterlyAssessmentForms?.length > 0
			) {
				setAcknowledgedRetirementQuarterForms(
					data?.data?.getRetirementQuarterlyAssessmentForms
				);
				setRetirementDraftFormsCount(
					data?.data?.getRetirementInitialAssessmentForms?.length +
						data?.data?.getRetirementQuarterlyAssessmentForms?.length
				);
			}
		});
	};

	const reloadReportLastSubmittedDate = () => {
		AppSyncService.execute(getFosterAssessmentFormLatestUpdated, {
			memberExternalId: currentUser?.UserId
		}).then((data) => {
			if (
				data?.data?.getFosterWeeklyAssessmentForms[0]?.lastUpdated ||
				data?.data?.getFosterInitialAssessmentForms[0]?.lastUpdated
			) {
				setLatestFormSubmittedDate(
					formatDateReadableVariant(
						data?.data?.getFosterWeeklyAssessmentForms[0]?.lastUpdated
					) >
						formatDateReadableVariant(
							data?.data?.getFosterInitialAssessmentForms[0]?.lastUpdated
						)
						? formatDateReadableVariant(
								data?.data?.getFosterWeeklyAssessmentForms[0]?.lastUpdated
						  )
						: formatDateReadableVariant(
								data?.data?.getFosterInitialAssessmentForms[0]?.lastUpdated
						  )
				);
			} else {
				setLatestFormSubmittedDate('never');
			}
		});
	};

	useEffect(() => {
		// Todo Foster
		reloadReportLastSubmittedDate();
		reloadReportNumbers();
	}, [currentUser]);

	const buildRows = () => {
		// Todo Foster
		const numRows = acknowledgedRetirementHorses.length;
		const constructedRows = acknowledgedRetirementHorses.map(
			(acknowledgedRetirementHorse, index) => {
				return (
					<HorseManagementDataRow
						horseData={acknowledgedRetirementHorse}
						key={index}
						index={index}
						isLastRow={numRows === index + 1}
						isMobile={isMobile}
						reload={reloadReportNumbers}
						program={FOSTER}
					/>
				);
			}
		);
		setRows(constructedRows);
	};

	const sortRowsDown = (horseList) => {
		// Todo Foster
		setCurrentSortDirection(1);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horseName
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = (horseList) => {
		// Todo Foster
		setCurrentSortDirection(2);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horseName
				)
			)
		);
		buildRows();
	};

	const updateHorseList = (value) => {
		// Todo Foster
		setSelectedStatus(value);
		let tempList = Object.assign([], originalHorseList);
		let array = [];
		if (value.length > 0) {
			value.map((flist) => {
				let filter = flist.label;
				tempList.map((d) => {
					if (d?.currentStatus?.value === filter) {
						array.push(d);
					}
				});
			});
			if (currentSortDirection === 1) {
				sortRowsDown(array);
			} else if (currentSortDirection === 2) {
				sortRowsUp(array);
			} else {
				setAcknowledgedRetirementHorses(array);
			}
		} else {
			if (currentSortDirection === 1) {
				sortRowsDown(tempList);
			} else if (currentSortDirection === 2) {
				sortRowsUp(tempList);
			} else {
				setAcknowledgedRetirementHorses(tempList);
			}
		}
	};

	const SubmitInfoBar = () => {
		// Todo Foster
		const infoBarText = `Last submitted STAR Program report was ${latestFormSubmittedDate}.
Please complete your initial or weekly report/s for each STAR horse/s and Save each form. When you are ready to submit for review, click Submit Report.`;
		return (
			<InfoBar
				infoText={hasHorseInOriginalList && infoBarText}
				buttonText="Submit Report"
				buttonOnClick={() => submitReports()}
				noButton
				isDisabled={retirementDraftFormsCount === 0}
				type={
					!hasHorseInOriginalList
						? MessageBarTypeEnum.info
						: MessageBarTypeEnum.message
				}
			>
				{!hasHorseInOriginalList && <NoRetiredHorsesInfoBarText />}
			</InfoBar>
		);
	};

	return (
		<div>
			<SubmitInfoBar />
			<HeaderBanner
				title={`STAR Program Thoroughbreds (${acknowledgedRetirementHorses.length})`}
				top
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			{!isMobile && (
				<div className={classes.filterContainer}>
					<div className={classes.filterTitle}>Filter by</div>
					<div className={classes.filterComponentContainer}>
						<FormMultiSelectList
							name="filterStatus"
							listOptions={statusOptions}
							selectedIds={selectedStatus}
							onSelectChange={updateHorseList}
						/>
					</div>
				</div>
			)}
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
				hasHorseInOriginalList={hasHorseInOriginalList}
				program={FOSTER}
				emptyMessage={'No thoroughbreds in the STAR Program'}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
			{hasHorseInOriginalList && <SubmitInfoBar />}
		</div>
	);
};

export default AcknowledgedFosterManagement;

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import { getAssistanceTypeList } from '../../graphql/custom.queries';
import MultiDropDown from '../MultiDropDown';
import Toggle from '../Toggle';
import { StyledFieldTitle } from './style';
import { HorseManagementKeys } from './../../utils/constants/index';

const RetrainerConcludedFullCircleForm = ({
	wantFullCircle,
	updateWantFullCircle,
	assistanceType,
	updateAssistanceType
}) => {
	const [assistanceTypeList, setAssistanceTypeList] = useState([]);

	const fetchData = async () => {
		const assist = await AppSyncService.execute(getAssistanceTypeList);
		let listAssistArray = [];
		let count = 0;
		assist.data.listFullCircleTypesOfAssistance
			.filter((i) => i.value != 'Other')
			.map((rec) => {
				listAssistArray.push({
					id: count,
					key: rec.id,
					title: rec.value,
					selected: false
				});
				count++;
			});
		setAssistanceTypeList(listAssistArray);
	};

	const resetAssistType = (id) => {
		const temp = assistanceTypeList;
		temp[id].selected = !temp[id].selected;
		let selectedAssist = Object.assign([], assistanceType);
		if (temp[id].selected) {
			selectedAssist.push({ key: temp[id].key, value: temp[id].title });
		}
		if (!temp[id].selected) {
			const key = temp[id].key;
			const filterIndex = selectedAssist
				.map(function (e) {
					return e.key;
				})
				.indexOf(key);
			if (filterIndex > -1) {
				selectedAssist.splice(filterIndex, 1);
			}
		}
		setAssistanceTypeList(temp);
		updateAssistanceType(selectedAssist);
	};

	const handleWantFullCircleChange = (val) => {
		updateWantFullCircle(val);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<div>
			<Row>
				<Col xs={12} lg={4}>
					<StyledFieldTitle>
						Do you want to Full Circle this horse?
					</StyledFieldTitle>
					<Toggle
						id={HorseManagementKeys.FULL_CIRCLE_HORSE}
						labelNegative="No"
						labelPositive="Yes"
						onClick={handleWantFullCircleChange}
						value={wantFullCircle}
					/>
				</Col>
				{wantFullCircle && (
					<Col xs={12} lg={4}>
						<StyledFieldTitle>Type of Assistance *</StyledFieldTitle>
						<MultiDropDown
							id={HorseManagementKeys.TYPE_OF_ASSISTANCE}
							list={assistanceTypeList}
							resetThenSet={resetAssistType}
							headTitle=""
						/>
					</Col>
				)}
			</Row>
		</div>
	);
};

export default RetrainerConcludedFullCircleForm;

export const saveHorseProfileImage = `
  mutation saveHorseProfileImage($fileName: String!, $horseCode: Int!, $key:String!, $type:String!) {
    saveHorseProfileFile(filename: $fileName, horseCode: $horseCode, key: $key, type: $type) {
      id
      success
    }
  }
`;

export const deleteHorseProfileImage = `
  mutation deleteHorseProfileImage($key: String!) {
    deleteHorseProfileFile(key: $key)
  }
`;

export const saveHorseProfileRetirement = `
	mutation saveHorseProfileRetirement($input: HorseProfileRetirementInput) {
	  saveHorseProfileRetirement(input: $input) {
	  id
	  error {
		errorCode
		errorDetails
		errorMessage
	  }
	  message
	  success
	}
  }`;

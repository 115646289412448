export const getHorseStatus = `
query getHorse($horseCode: Int!) {
    getHorseProfile(horseCode: $horseCode) {
        status {
            status {
                id
                value
            }
        }
    }
  }
`;

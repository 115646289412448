export const generateTagLabels = (nonOtherInput, otherInput) => {
	return [
		...nonOtherInput.map(({ value }) => value),
		...(otherInput ? otherInput.split(',') : [])
	];
};

export const shouldShowDetail = (visibility, isFollower, isAdmin, isOwner) => {
	const publicVisibility = visibility?.id === 3;
	const followerVisibility = visibility?.id === 2;
	return (
		publicVisibility ||
		(followerVisibility && isFollower) ||
		isAdmin ||
		!!isOwner
	);
};

export const shouldShowOTTInfoTab = (
	ottInformation,
	isFollower,
	isAdmin,
	isOwner
) => {
	//Should show the tab if there is data and the user has visibility to at least one field
	const isProfilePhotoVisibile =
		shouldShowDetail(
			ottInformation?.profilePhotoVisibility,
			isFollower,
			isAdmin,
			isOwner
		) &&
		ottInformation?.uploadedFiles?.filter(
			(file) => file.type === 'ProfileImage' && !file.removed
		)?.length > 0;
	const isPaddockNameVisibile =
		shouldShowDetail(
			ottInformation?.paddockNameVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.paddockName;
	const isPurposeVisibile =
		shouldShowDetail(
			ottInformation?.purposeVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.purpose?.value;
	const isDisciplinesVisibile =
		shouldShowDetail(
			ottInformation?.disciplinesVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.disciplines?.length > 0;
	const isPersonalityDetailsVisible =
		shouldShowDetail(
			ottInformation?.personalityDetailsVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.personalityDetails;
	const isHappinessDetailsVisible =
		shouldShowDetail(
			ottInformation?.happyDetailsVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.happyDetails;
	const isVicesVisible =
		shouldShowDetail(
			ottInformation?.habitsVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.habits?.length > 0;
	const isInjuriesVisible =
		shouldShowDetail(
			ottInformation?.injuriesVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.hasInjuries !== null;
	const illnessesVisible =
		shouldShowDetail(
			ottInformation?.illnessesVisibility,
			isFollower,
			isAdmin,
			isOwner
		) && ottInformation?.hasIllnesses !== null;
	return (
		isProfilePhotoVisibile ||
		isPaddockNameVisibile ||
		isPurposeVisibile ||
		isDisciplinesVisibile ||
		isPersonalityDetailsVisible ||
		isHappinessDetailsVisible ||
		isVicesVisible ||
		isInjuriesVisible ||
		illnessesVisible
	);
};

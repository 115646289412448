import React from 'react';
import { TablePagination } from '@material-ui/core';

const BasicPagination = ({ count = 10, page = 0, handleChange = () => {} }) => {
	return (
		<div>
			<TablePagination
				rowsPerPageOptions={[]}
				rowsPerPage={10}
				count={count}
				page={page}
				onPageChange={handleChange}
			/>
		</div>
	);
};

export default BasicPagination;

import AppSyncService from '../../graphql/AppSyncService';
import { getUserImage } from '../../graphql/custom.queries';

export const getImage = (img, setImgSrcUrl) => {
	const imageKey = { imageKey: img.key };
	AppSyncService.execute(getUserImage, imageKey).then((data) => {
		setImgSrcUrl(data.data.getUserImage.signedUrl);
	});
};
export const mapAdminActionDataForLocal = (
	data,
	adminActions,
	currentUserExternalId
) => {
	const mappedAdminActionData = {
		...adminActions,
		adminExternalId: {
			...adminActions.adminExternalId,
			value:
				data.adminData.adminAction?.admin?.externalId || currentUserExternalId
		},
		resetNominationId: {
			...adminActions.resetNominationId,
			value: data.id
		},
		ewdAccept: {
			...adminActions.ewdAccept,
			value:
				data.adminData.ewdAccept === null
					? adminActions.ewdAccept.value
					: data.adminData.ewdAccept
		},
		ewdComments: {
			...adminActions.ewdComments,
			value: data.adminData.ewdComments
		},
		ewdRecommendationDate: {
			...adminActions.ewdRecommendationDate,
			value: data.adminData.ewdRecommendationDate
		},
		ewgmComments: {
			...adminActions.ewgmComments,
			value: data.adminData.ewgmComments
		},
		ewgmProceedToVetAssessment: {
			...adminActions.ewgmProceedToVetAssessment,
			value:
				data.adminData.ewgmProceedToVetAssessment === null
					? adminActions.ewgmProceedToVetAssessment.value
					: data.adminData.ewgmProceedToVetAssessment
		},
		ewgmRecommendationDate: {
			...adminActions.ewgmRecommendationDate,
			value: data.adminData.ewgmRecommendationDate
		},
		illnessId: {
			...adminActions.illnessId,
			value: data.adminData.illness?.id
		},
		ilnessesOther: {
			...adminActions.ilnessesOther,
			value: data.adminData.ilnessesOther
		},
		recommendation: {
			...adminActions.recommendation,
			value: data.adminData.recommendation
		},
		injuryIds: {
			...adminActions.injuryIds,
			value: data.adminData?.injuries.map((item) => ({
				...item,
				label: item.value
			}))
		},
		injuriesOther: {
			...adminActions.injuriesOther,
			value: data.adminData.injuriesOther
		},
		rejectReasonId: {
			...adminActions.rejectReasonId,
			value: data.adminData.rejectReason?.id
		},
		rejectReasonOther: {
			...adminActions.rejectReasonOther,
			value: data.adminData.rejectReasonOther
		},
		rejectingApplication: {
			...adminActions.rejectingApplication,
			value:
				data.adminData.rejectingApplication === null
					? adminActions.rejectingApplication.value
					: data.adminData.rejectingApplication
		},
		vetAssessmentDate: {
			...adminActions.vetAssessmentDate,
			value: data.adminData.vetAssessmentDate
		},
		vetAssessmentId: {
			...adminActions.vetAssessmentId,
			value: data.adminData.vetAssessment?.id
		},
		vetAssessmentSummary: {
			...adminActions.vetAssessmentSummary,
			value: data.adminData.vetAssessmentSummary
		},
		vetUploads: {
			...adminActions.vetUploads,
			value: data.adminData.vetUploads
		},
		adminStatusId: {
			...adminActions.adminStatusId,
			value: data.adminData.adminAction?.adminStatus?.id
		},
		formComplete: {
			...adminActions.formComplete,
			value:
				data.adminData.adminAction?.formComplete === null
					? adminActions.formComplete.value
					: data.adminData.adminAction?.formComplete
		}
	};
	return mappedAdminActionData;
};

export const mapAdminActionForUpdateAPI = (adminActions) => {
	const mappedAdminActionForUpdateAPI = {
		adminExternalId: adminActions.adminExternalId.value,
		resetNominationId: adminActions.resetNominationId.value,
		adminStatusId: adminActions.adminStatusId.value,
		formComplete: adminActions.formComplete.value
	};
	return mappedAdminActionForUpdateAPI;
};

export const mapAdminDataForUpdateAPI = (adminActions) => {
	const vetsData = adminActions.ewgmProceedToVetAssessment.value
		? {
				vetAssessmentDate: adminActions.vetAssessmentDate.value || null,
				vetAssessmentId:
					Number(adminActions.vetAssessmentId.value) !== 0
						? Number(adminActions.vetAssessmentId.value)
						: null,
				vetAssessmentSummary: adminActions.vetAssessmentSummary.value || null,
				injuryIds: adminActions.injuryIds.value.map((item) => Number(item.id)),
				injuriesOther:
					adminActions.injuryIds &&
					adminActions.injuryIds.value?.find(({ label }) => label === 'Other')
						? adminActions.injuriesOther.value
						: null,
				illnessId:
					Number(adminActions.illnessId.value) !== 0
						? Number(adminActions.illnessId.value)
						: null,
				ilnessesOther:
					adminActions.ilnessesOther.value &&
					Number(adminActions.illnessId.value) === 7
						? adminActions.ilnessesOther.value
						: null
		  }
		: {
				vetAssessmentDate: null,
				vetAssessmentId: null,
				vetAssessmentSummary: null,
				injuryIds: [],
				illnessId: null
		  };

	const mappedAdminDataForUpdateAPI = {
		adminExternalId: adminActions.adminExternalId.value,
		resetNominationId: adminActions.resetNominationId.value,
		ewdAccept: adminActions.ewdAccept.value,
		ewdComments: adminActions.ewdComments.value || null,
		ewdRecommendationDate: adminActions.ewdRecommendationDate.value || null,
		ewgmComments: adminActions.ewgmComments.value || null,
		ewgmProceedToVetAssessment: adminActions.ewgmProceedToVetAssessment.value,
		ewgmRecommendationDate: adminActions.ewgmRecommendationDate.value || null,
		recommendation: adminActions.recommendation.value,
		rejectReasonId:
			Number(adminActions.rejectReasonId.value) !== 0
				? Number(adminActions.rejectReasonId.value)
				: null,
		rejectReasonOther:
			adminActions.rejectReasonOther.value &&
			Number(adminActions.rejectReasonId.value) === 7
				? adminActions.rejectReasonOther.value
				: null,
		rejectingApplication: adminActions.rejectingApplication.value,
		...vetsData
	};
	return mappedAdminDataForUpdateAPI;
};

export const mapAdminOptionsForLocal = (data) => {
	const firstOption = { id: 0, value: null };
	const adminMembers = [
		firstOption,
		...data.getAdminMembers.map((item) => ({
			id: item.externalId,
			value: `${item.firstName} ${item.lastName}`
		}))
	];
	const resetAdminStatuses = [firstOption, ...data.listResetAdminStatuses];
	const resetInjuries = data.listInjuryDetails.map((item) => ({
		...item,
		label: item.value
	}));
	const resetIllnesses = [firstOption, ...data.listShareInfoEuthanisedIllness];
	const resetVetCategories = [firstOption, ...data.listResetVetCategories];
	const resetRejectReasons = [firstOption, ...data.listResetRejectReasons];

	return {
		adminMembers,
		resetAdminStatuses,
		resetInjuries,
		resetIllnesses,
		resetVetCategories,
		resetRejectReasons
	};
};

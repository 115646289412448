import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';

const RetrainingDetailsSection = ({ formData }) => {
	return (
		<AdminReviewSection title="Retraining Details">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="How do you source OTT horses?"
						value={formData?.sourceOfHorses
							.map(({ value }) => value)
							.join(', ')}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.sourceOfHorsesOther && (
					<SectionRow>
						<AdminReviewDatum
							label="Other source"
							value={formData?.sourceOfHorsesOther}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="What are your primary retraining disciplines?"
						value={formData?.disciplines.map(({ value }) => value).join(', ')}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.disciplinesOther && (
					<SectionRow>
						<AdminReviewDatum
							label="Other disciplines"
							value={formData?.disciplinesOther}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
			</Col>
		</AdminReviewSection>
	);
};

export default RetrainingDetailsSection;

import React from 'react';
import useStyles from './styles';

const NewTabLink = ({ url, children, ...rest }) => {
	const classes = useStyles();
	return (
		<a
			href={url}
			target="_blank"
			rel="noreferrer"
			className={classes.linkStyles}
			{...rest}
		>
			{children}
		</a>
	);
};

export default NewTabLink;

import React from 'react';
import useStyles from './styles';

const FormButton = ({ title, onClick, isDisabled, styles, id }) => {
	const classes = useStyles();
	const noAction = () => {};
	return (
		<div
			style={styles}
			id={id}
			className={isDisabled ? classes.disabledButton : classes.button}
			onClick={isDisabled ? () => noAction() : onClick}
		>
			{title}
		</div>
	);
};

export default FormButton;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formFieldWrapper: {
		width: '100%',
		marginBottom: 20,
		fontSize: 13,
		display: 'flex',
		flexDirection: 'column',
		'@media (min-width: 992px)': {
			marginBottom: 0,
			fontSize: 15
		}
	},
	formFieldLabel: {
		fontWeight: 600,
		marginBottom: 7,
		color: '#464749',
		fontSize: 14
	},
	visibilitySelectorContainer: {
		display: 'inline-block',
		float: 'right',
		borderRadius: 3,
		overflow: 'hidden'
	}
}));

import React from 'react';
import useStyles from './styles';

const TwoColumArticle = ({
	titleOne,
	buttonOneClick,
	textOne,
	textTwo,
	titleTwo,
	buttonTwoClick,
	mobile,
	buttonOneTitle,
	buttonTwoTitle
}) => {
	const classes = useStyles();

	const renderArticles = () => {
		if (mobile) {
			return (
				<div className={classes.mobileContainer}>
					<div style={{ marginBottom: 20 }}>
						<div className={classes.mobileTitle}>{titleOne}</div>
						<div className={classes.mobileText}>{textOne}</div>
						<div className={classes.buttonLink} onClick={buttonOneClick}>
							{buttonOneTitle}
						</div>
					</div>
					<div style={{ marginBottom: 20 }}>
						<div className={classes.mobileTitle}>{titleTwo}</div>
						<div className={classes.mobileText}>{textTwo}</div>
						<div className={classes.buttonLink} onClick={buttonTwoClick}>
							{buttonTwoTitle}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className={classes.container}>
					<div className={classes.flexCol}>
						<div className={classes.title}>{titleOne}</div>
						<div className={classes.text}>{textOne}</div>
						<div className={classes.buttonLink} onClick={buttonOneClick}>
							{buttonOneTitle}
						</div>
					</div>
					<div className={classes.flexCol}>
						<div className={classes.title}>{titleTwo}</div>
						<div className={classes.text}>{textTwo}</div>
						<div className={classes.buttonLink} onClick={buttonTwoClick}>
							{buttonTwoTitle}
						</div>
					</div>
				</div>
			);
		}
	};

	return renderArticles();
};

export default TwoColumArticle;

import React, { useState, useEffect, useRef } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import qs from 'query-string';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getMemberProfileForAdminView,
	listClaimedThoroughbreds,
	listPreviouslyClaimedThoroughbreds,
	listFullCircleFormsForMember,
	listThoroughbredFormsForMember,
	listResetNominationFormsForMember,
	listResetWeeklyProgressFormsForMember,
	listResetInitialAssessmentFormsForMember,
	listHorsePlacementFormsForMember,
	getContactUsForms,
	listShareInformationForms,
	listAccountDisableReasons,
	changeUserAccountStatus,
	getTransitionGuides,
	getOhepEoiForms,
	getOHEPNominationForms,
	getMemberBenefitsAndMerchandiseForms,
	filterAcknowledgedProgramApplications,
	getRetirementFormsAdmin,
	getBusinessProfilesByMemberId,
	getDeceasedThoroughbredForms,
	getThoroughbredUnregisteredForms,
	getSponsorshipOutcomeFormsAdmin,
	getResetExpressInitialForms
} from '../../graphql/custom.queries';
import {
	CLAIM_CURRENT_THOROUGHBRED_FORM,
	CLAIM_PREVIOUS_THOROUGHBRED_FORM,
	FULL_CIRCLE_FORM,
	FIND_THOROUGHBRED_FORM,
	AVAIL_RETRAINING_FORM,
	AVAIL_RETRAINING_INTEREST_FORM,
	RESET_NOMINATION_FORM,
	RESET_WEEKLY_PROG_FORM,
	RESET_INITIAL_FORM,
	SHARE_INFO_FORM,
	ARRAY_FORM_TYPE_LIST,
	USER_EDIT_STATUS_LIST,
	UNNAMED,
	CONTACTS_FORM,
	BUSINESS_PROFILE_FORM,
	OHEP_EOI_FORM,
	TRANSITION_SUPPORT_FORM,
	OHEP_NOMINATION_FORM,
	MEMBER_BENEFITS_AND_MERCHANDISE_FORM,
	RETIREMENT_FARM_INITIAL_ASSESSMENT,
	RETIREMENT_FARM_QUARTERLY_ASSESSMENT,
	FOSTER_INITIAL_ASSESSMENT,
	FOSTER_WEEKLY_ASSESSMENT,
	DECEASED_THOROUGHBRED_FORM,
	THOROUGHBRED_UNREGISTERED_FORM,
	SPONSORSHIP_OUTCOME_FORM,
	RESET_EXPRESS_INITIAL_ASSESSMENT,
	RESET_EXPRESS_REHOME_REPORT,
	RESET_EXPRESS_REHABILITATION_REPORT
} from './constants';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import MemberDetailsAdminView from '../MemberDetailsAdminView';
import MemberAdminNotes from '../MemberAdminNotes';
import MemberAccountStatus from '../MemberAccountStatus';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import {
	APPROVED,
	FOSTER_FORMS,
	REJECTED,
	RESET_FORMS,
	RETIREMENT_FORMS,
	REVIEW,
	ROLE_RV_EQUINE_WELFARE
} from '../../utils/constants';
import { logToServer } from '../../utils/logger';
import { navigate, Link } from '@reach/router';
import Accordion from '../Accordion';
import {
	convertToMultiListData,
	parseUTCToLocal,
	formatDateTimeReadable
} from '../../utils/helpers';
import FormMultiSelectList from '../FormMultiSelectList';
import ButtonBlue from '../../assets/icons/Button_Square_BlueContinue.svg';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import {
	CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE,
	PREVIOUS_CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE,
	SPONSORSHIP_OUTCOME_REVIEW_ROUTE
} from '../../utils/constants/routes';
import CommunityMemberBenefitsAndMerchandiseForm from '../CommunityMemberBenefitsAndMerchandiseForm';

const ColumnContent = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<div className={classes.resultColumn} {...rest}>
			{children}
		</div>
	);
};

const MemberAdminView = () => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	let { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [memberData, setMemberData] = useState(null);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const hrefLink = useRef(null);
	const [imgSrcPdf, setImageSrcPdf] = useState(null);
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const [accountDisableReasonsList, setAccountDisableReasonsList] = useState(
		[]
	);
	const [claimList, setClaimList] = useState([]);
	const [formList, setFormList] = useState([]);
	const [
		memberBenefitsAndMerchandiseList,
		setMemberBenefitsAndMerchandiseList
	] = useState([]);
	const [formTypeList, setFormTypeList] = useState([]);
	const [selectedFormTypes, setSelectedFormTypes] = useState([]);
	const [statusChangedByName, setStatusChangedByName] = useState(null);
	const [allFormData, setAllFormData] = useState([]);
	const PAGE_ID = 'memberAdminViewForm';
	const [initialLoad, setInitialLoad] = useState(true);
	const [isAccordionOpen, setIsAccordionOpen] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		loadMemberData();
		setFormList([]);
		if (memberData && selectedFormTypes.length > 0) {
			fetchForms(
				memberData.externalId,
				selectedFormTypes.map((d) => d.label)
			).finally(setIsLoading(false));
		} else if (selectedFormTypes.length === 0) {
			setFormList(allFormData);
		}
	}, [selectedFormTypes]);

	const loadMemberData = async () => {
		setFormTypeList(convertToMultiListData(ARRAY_FORM_TYPE_LIST, 'value'));
		AppSyncService.execute(getMemberProfileForAdminView, {
			id
		}).then((result) => {
			if (result.errors && result.errors.length > 0) {
				logToServer(
					currentUser?.UserId,
					result,
					`Failed to load member (id=${id}) due to backend error.`
				);
				addToast({ Message: "Failed to load member's data", IsSuccess: false });
			} else {
				setMemberData(result.data.getMember);
				// Get the list of Account Disable Reasons
				AppSyncService.execute(listAccountDisableReasons).then((data) => {
					try {
						setAccountDisableReasonsList(data.data.listAccountDisableReasons);
					} catch {
						addToast({
							Message: 'Error Fetching AccountDisableReasons..',
							IsSuccess: false
						});
					}
				});

				// Get details of Admin that changed account status for this member
				if (result?.data?.getMember?.accountStatusChangedBy) {
					let id = result?.data?.getMember?.accountStatusChangedBy;
					AppSyncService.execute(getMemberProfileForAdminView, {
						id
					}).then((accountStatusChangeData) => {
						if (
							accountStatusChangeData.errors &&
							accountStatusChangeData.errors.length > 0
						) {
							logToServer(
								currentUser?.UserId,
								accountStatusChangeData,
								`Failed to load accountStatusChangeData (id=${id}) due to backend error.`
							);
							addToast({
								Message: "Failed to load member's data",
								IsSuccess: false
							});
						} else {
							try {
								setStatusChangedByName(
									accountStatusChangeData?.data?.getMember?.firstName +
										' ' +
										accountStatusChangeData?.data?.getMember?.lastName
								);
							} catch {
								addToast({
									Message: 'Error Fetching accountStatusChangeData..',
									IsSuccess: false
								});
							}
						}
					});
				}

				AppSyncService.execute(listClaimedThoroughbreds, {
					memberExternalId: result.data.getMember.externalId
				}).then((data) => {
					try {
						setClaimList(data.data.getHorseClaims);
						fetchForms(
							result.data.getMember.externalId,
							selectedFormTypes.map((d) => d.label)
						);
					} catch {
						addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
					}
				});

				AppSyncService.execute(getMemberBenefitsAndMerchandiseForms, {
					memberExternalId: result.data.getMember.externalId
				}).then((data) => {
					try {
						setMemberBenefitsAndMerchandiseList(
							data.data.getMemberBenefitsAndMerchandiseForms
						);
					} catch {
						addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
					}
				});
			}
		});
	};

	useEffect(() => {
		if (imgSrcPdf != null) {
			hrefLink.current.click();
		}
	}, [imgSrcPdf]);

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const updateFormsList = (value) => {
		setSelectedFormTypes(value);
	};

	const fetchForms = async (memberExternalId, selectedForms) => {
		let formListData = [];
		const areFormsEmpty = selectedForms.length === 0;
		try {
			if (areFormsEmpty || selectedForms.includes(FULL_CIRCLE_FORM)) {
				const results = await AppSyncService.execute(
					listFullCircleFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getFullCircles.map((item) => ({
						formType: FULL_CIRCLE_FORM,
						link: `/full-circle-review/${item.id}`,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.reviewLastUpdated,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(CLAIM_CURRENT_THOROUGHBRED_FORM)
			) {
				const results = await AppSyncService.execute(listClaimedThoroughbreds, {
					memberExternalId: memberExternalId,
					status: []
				});
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getHorseClaims.map((item) => ({
						formType: CLAIM_CURRENT_THOROUGHBRED_FORM,
						link: `${CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE}/${item.id}`,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.reviewLastUpdated,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						isCurrent: true,
						...item
					}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(CLAIM_PREVIOUS_THOROUGHBRED_FORM)
			) {
				const results = await AppSyncService.execute(
					listPreviouslyClaimedThoroughbreds,
					{
						memberExternalId
					}
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getHorsePreviousClaims.map((item) => ({
						formType: CLAIM_PREVIOUS_THOROUGHBRED_FORM,
						link: `${PREVIOUS_CLAIM_THOROUGHBRED_REVIEW__ID_ROUTE}/${item.id}`,
						timeStamp: item.created,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						isCurrent: false,
						status: APPROVED,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(FIND_THOROUGHBRED_FORM)) {
				const results = await AppSyncService.execute(
					listThoroughbredFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getThoroughbredForms.map((item) => ({
						id: item.guid,
						formType: FIND_THOROUGHBRED_FORM,
						link: `/thoroughbred-registration-review/${item.guid}`,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.reviewLastUpdated,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(RESET_NOMINATION_FORM)) {
				const results = await AppSyncService.execute(
					listResetNominationFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getResetNominationForms.map((item) => ({
						formType: RESET_NOMINATION_FORM,
						link: `/reset-form-review/${item.id}`,
						subType: RESET_NOMINATION_FORM,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.lastReviewed,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(RESET_INITIAL_FORM)) {
				const results = await AppSyncService.execute(
					listResetInitialAssessmentFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getResetInitialAssessmentForms.map((item) => ({
						formType: RESET_INITIAL_FORM,
						link: `/reset-form-review/${item.id}`,
						subType: RESET_INITIAL_FORM,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.lastReviewed,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(RESET_WEEKLY_PROG_FORM)) {
				const results = await AppSyncService.execute(
					listResetWeeklyProgressFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getResetWeeklyProgressForms.map((item) => ({
						formType: `${RESET_WEEKLY_PROG_FORM} - Week: ${item.weekId}`,
						link: `/reset-form-review/${item.id}`,
						subType: RESET_WEEKLY_PROG_FORM,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.lastReviewed,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(AVAIL_RETRAINING_FORM)) {
				const results = await AppSyncService.execute(
					listHorsePlacementFormsForMember,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getHorsePlacementForms.map((item) => ({
						formType: AVAIL_RETRAINING_FORM,
						link: `/available-for-retraining/${item.id}`,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.lastReviewed,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(AVAIL_RETRAINING_INTEREST_FORM)
			) {
				const results = await AppSyncService.execute(
					filterAcknowledgedProgramApplications,
					{ filterStatuses: [], paginationIndex: 0, paginationSize: 50 }
				);

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				const {
					data: {
						getAcknowledgedReTrainerForms,
						getAcknowledgedRetirementForms
					}
				} = results;
				const idNumber = Number(id);
				formListData = formListData.concat(
					getAcknowledgedReTrainerForms
						.filter((retrainer) => retrainer?.member?.id === idNumber)
						.map((item) => ({
							formType: AVAIL_RETRAINING_INTEREST_FORM,
							link: `/acknowledged-retrainer-form-review/${item.id}`,
							status: item.status,
							horseName: `${item.member?.firstName} ${item.member?.lastName}`,
							...item
						}))
				);

				formListData = formListData.concat(
					getAcknowledgedRetirementForms
						.filter((retirement) => retirement?.member?.id === idNumber)
						.map((item) => ({
							formType: AVAIL_RETRAINING_INTEREST_FORM,
							link: `/acknowledged-retirement-form-review/${item.id}`,
							status: item.status,
							horseName: `${item.member?.firstName} ${item.member?.lastName}`,
							...item
						}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(SHARE_INFO_FORM)) {
				const results = await AppSyncService.execute(
					listShareInformationForms,
					{ memberExternalId }
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				formListData = formListData.concat(
					results.data.getShareInformationForms.map((item) => ({
						formType: SHARE_INFO_FORM,
						link: `/share-information-form-review/${item.id}`,
						timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
							? item.lastUpdated
							: item.lastReviewed,
						horseName:
							item.horse.ottInformation.paddockName &&
							item.horse.name === UNNAMED
								? item.horse.ottInformation.paddockName
								: item.horse.name,
						...item
					}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(CONTACTS_FORM)) {
				const results = await AppSyncService.execute(getContactUsForms, {
					status: []
				});

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				const { data } = results;
				const filteredNonMember = data?.getContactUsForms.filter(
					(contact) => contact.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((contact) => contact?.member?.id === idNumber)
						.map((item) => ({
							formType: CONTACTS_FORM,
							link: `/contact-us-form/${item.id}`,
							status: item.status,
							timeStamp: item.created,
							horseName: data.listContactReasons.find(
								(contact) => contact.id === item.contactReason
							)?.value,
							...item
						}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(BUSINESS_PROFILE_FORM)) {
				const results = await AppSyncService.execute(
					getBusinessProfilesByMemberId,
					{
						memberExternalId: memberExternalId
					}
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				if (results?.data?.getBusinessProfilesByMemberId?.length > 0) {
					formListData = formListData.concat(
						results.data.getBusinessProfilesByMemberId.map((item) => ({
							formType: BUSINESS_PROFILE_FORM,
							link: `/business-profile-review/${item.id}`,
							status: item.status,
							timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
								? item.lastUpdated
								: item.reviewLastUpdated,
							horseName: item.businessName,
							...item
						}))
					);
				}
			}
			if (areFormsEmpty || selectedForms.includes(TRANSITION_SUPPORT_FORM)) {
				const results = await AppSyncService.execute(getTransitionGuides, {
					status: []
				});

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getTransitionGuides.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: TRANSITION_SUPPORT_FORM,
							link: `/transition-guide-form/${item.id}`,
							status: item.status,
							timeStamp: item.created,
							horseName: item.horseName,
							...item
						}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(OHEP_EOI_FORM)) {
				const results = await AppSyncService.execute(getOhepEoiForms, {
					status: []
				});

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getOhepEoiForms.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: OHEP_EOI_FORM,
							link: `/ohep-eoi-form-review/${item.guid}`,
							status: item.status,
							horseName: item.horseName,
							id: item.guid,
							...item
						}))
				);
			}
			if (areFormsEmpty || selectedForms.includes(OHEP_NOMINATION_FORM)) {
				const results = await AppSyncService.execute(getOHEPNominationForms, {
					status: []
				});

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getOhepNominationForms.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: OHEP_NOMINATION_FORM,
							link: `/ohep-form-review/${item.id}`,
							status: item.status,
							horseName: item.horse?.name,
							...item
						}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(MEMBER_BENEFITS_AND_MERCHANDISE_FORM)
			) {
				const results = await AppSyncService.execute(
					getMemberBenefitsAndMerchandiseForms,
					{ memberExternalId }
				);

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getMemberBenefitsAndMerchandiseForms.filter(
					(x) => x.memberId !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: MEMBER_BENEFITS_AND_MERCHANDISE_FORM,
							...item
						}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(RETIREMENT_FARM_INITIAL_ASSESSMENT) ||
				selectedForms.includes(RETIREMENT_FARM_QUARTERLY_ASSESSMENT) ||
				selectedForms.includes(FOSTER_INITIAL_ASSESSMENT) ||
				selectedForms.includes(FOSTER_WEEKLY_ASSESSMENT) ||
				selectedForms.includes(RESET_EXPRESS_REHOME_REPORT) ||
				selectedForms.includes(RESET_EXPRESS_REHABILITATION_REPORT)
			) {
				const results = await AppSyncService.execute(getRetirementFormsAdmin, {
					status: [APPROVED, REVIEW, REJECTED],
					memberExternalId
				});
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}
				const {
					data: {
						getRetirementInitialAssessmentForms,
						getRetirementQuarterlyAssessmentForms,
						getFosterInitialAssessmentForms,
						getFosterWeeklyAssessmentForms,
						getResetExpressRehomeAssessmentForms,
						getResetExpressRehabilitationConclusionForms
					}
				} = results;
				const idNumber = Number(id);

				if (
					areFormsEmpty ||
					selectedForms.includes(RETIREMENT_FARM_INITIAL_ASSESSMENT)
				) {
					formListData = formListData.concat(
						getRetirementInitialAssessmentForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: RETIREMENT_FARM_INITIAL_ASSESSMENT,
								link: `/safety-net-program-reports/${item.id}?${qs.stringify({
									subType: RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT
								})}`,
								timeStamp: item.lastUpdated,
								status: item.status,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}

				if (
					areFormsEmpty ||
					selectedForms.includes(RETIREMENT_FARM_QUARTERLY_ASSESSMENT)
				) {
					formListData = formListData.concat(
						getRetirementQuarterlyAssessmentForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: RETIREMENT_FARM_QUARTERLY_ASSESSMENT,
								link: `/safety-net-program-reports/${item.id}?${qs.stringify({
									subType: RETIREMENT_FORMS.RETIREMENT_QUARTERLY_ASSESSMENT
								})}`,
								status: item.status,
								timeStamp: USER_EDIT_STATUS_LIST.includes(item.status)
									? item.lastUpdated
									: item.lastReviewed,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}

				if (
					areFormsEmpty ||
					selectedForms.includes(FOSTER_INITIAL_ASSESSMENT)
				) {
					formListData = formListData.concat(
						getFosterInitialAssessmentForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: FOSTER_INITIAL_ASSESSMENT,
								link: `/safety-net-program-reports/${item.id}?${qs.stringify({
									subType: FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT
								})}`,
								status: item.status,
								timeStamp: item.lastUpdated,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}
				if (areFormsEmpty || selectedForms.includes(FOSTER_WEEKLY_ASSESSMENT)) {
					formListData = formListData.concat(
						getFosterWeeklyAssessmentForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: FOSTER_WEEKLY_ASSESSMENT,
								link: `/safety-net-program-reports/${item.id}?${qs.stringify({
									subType: FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT
								})}`,
								status: item.status,
								timeStamp: item.lastUpdated,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}
				if (
					areFormsEmpty ||
					selectedForms.includes(RESET_EXPRESS_REHOME_REPORT)
				) {
					formListData = formListData.concat(
						getResetExpressRehomeAssessmentForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: RESET_EXPRESS_REHOME_REPORT,
								link: `/reset-form-review/${item.id}?${qs.stringify({
									subType: RESET_FORMS.RESET_EXPRESS_REHOME_REPORT
								})}`,
								status: item.status,
								timeStamp: item.lastUpdated,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}
				if (
					areFormsEmpty ||
					selectedForms.includes(RESET_EXPRESS_REHABILITATION_REPORT)
				) {
					formListData = formListData.concat(
						getResetExpressRehabilitationConclusionForms
							.filter((item) => item?.member?.id === idNumber)
							.map((item) => ({
								formType: RESET_EXPRESS_REHABILITATION_REPORT,
								link: `/reset-form-review/${item.id}?${qs.stringify({
									subType: RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT
								})}`,
								status: item.status,
								timeStamp: item.lastUpdated,
								horseName:
									item.horse.name === UNNAMED
										? item.horse.ottInformation.paddockName
										: item.horse.name,
								...item
							}))
					);
				}
			}

			if (areFormsEmpty || selectedForms.includes(DECEASED_THOROUGHBRED_FORM)) {
				const results = await AppSyncService.execute(
					getDeceasedThoroughbredForms,
					{
						status: []
					}
				);

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getDeceasedThoroughbredForms.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: DECEASED_THOROUGHBRED_FORM,
							link: `/deceased-thoroughbred-form/${item.id}`,
							status: item.status,
							horseName: item.horse?.name,
							...item
						}))
				);
			}

			if (areFormsEmpty || selectedForms.includes(SPONSORSHIP_OUTCOME_FORM)) {
				const results = await AppSyncService.execute(
					getSponsorshipOutcomeFormsAdmin,
					{
						status: []
					}
				);

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getSponsorshipOutcomeFormsAdmin.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: SPONSORSHIP_OUTCOME_FORM,
							link: `${SPONSORSHIP_OUTCOME_REVIEW_ROUTE}/${item.id}`,
							status: item.status,
							horseName: '',
							...item
						}))
				);
			}

			if (
				areFormsEmpty ||
				selectedForms.includes(THOROUGHBRED_UNREGISTERED_FORM)
			) {
				const results = await AppSyncService.execute(
					getThoroughbredUnregisteredForms,
					{
						status: []
					}
				);

				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const idNumber = Number(id);
				formListData = formListData.concat(
					data?.getThoroughbredUnregisteredForms
						?.filter((item) => item?.memberId === idNumber)
						.map((item) => ({
							formType: THOROUGHBRED_UNREGISTERED_FORM,
							link: `/thoroughbred-unregistered-form-review/${item.id}`,
							status: item.status,
							timeStamp: item.lastUpdated,
							horseName: null
						}))
				);
			}
			if (
				areFormsEmpty ||
				selectedForms.includes(RESET_EXPRESS_INITIAL_ASSESSMENT)
			) {
				const results = await AppSyncService.execute(
					getResetExpressInitialForms,
					{
						status: [APPROVED, REVIEW, REJECTED]
					}
				);
				if (results.errors && results.errors.length > 0) {
					throw JSON.stringify(results.errors);
				}

				const { data } = results;
				const filteredNonMember = data?.getResetExpressInitialAssessmentForms.filter(
					(x) => x.member !== null
				);
				const idNumber = Number(id);
				formListData = formListData.concat(
					filteredNonMember
						.filter((x) => x?.member?.id === idNumber)
						.map((item) => ({
							formType: `${RESET_EXPRESS_INITIAL_ASSESSMENT} - Week: ${item.weekId}`,
							link: `/reset-form-review/${item.id}`,
							subType: RESET_EXPRESS_INITIAL_ASSESSMENT,
							status: item.status,
							horseName: item.horse?.name,
							...item
						}))
				);
			}
		} catch (e) {
			console.error(
				`Failed to fetch forms for member (id=${id}) due to backend error. ${e}`
			);
			logToServer(
				currentUser?.UserId,
				e,
				`Failed to fetch forms for member (id=${id}) due to backend error.`
			);
			addToast({
				Message: 'Error fetching form data..',
				IsSuccess: false
			});
		}
		formListData.sort((a, b) =>
			a.formType === b.formType
				? a.horseName > b.horseName
					? 1
					: -1
				: a.formType > b.formType
				? 1
				: -1
		);
		setFormList(formListData);
		if (areFormsEmpty) {
			setAllFormData(formListData);
		}
		setInitialLoad(false);
	};

	const changeMemberAccountStatus = (input) => {
		AppSyncService.execute(changeUserAccountStatus, { input }).then((data) => {
			if (data?.data?.changeUserAccountStatus?.success) {
				addToast({ Message: 'Account status changed', IsSuccess: true });
				loadMemberData();
			} else {
				addToast({
					Message: 'An error occured changing status.',
					IsSuccess: false
				});
			}
		});
	};

	const renderResults = (data, type) => {
		if (data.length > 0) {
			if (type === 'claimedThoroughbreds') {
				return data.map((d) => {
					return (
						<div
							className={classes.singleResultContainer}
							key={d.id}
							id={`member-claimedThoroughbreds-${d.horse.horseCode}`}
							onClick={() => navigate(`/horse-profile/${d.horse.horseCode}`)}
						>
							<ColumnContent style={{ maxWidth: 300 }}>
								<strong className={classes.longText}>
									{d.horse.ottInformation.paddockName &&
									d.horse.name === UNNAMED
										? d.horse.ottInformation.paddockName
										: d.horse.name}
								</strong>
							</ColumnContent>
							<ColumnContent>{d.ownershipType}</ColumnContent>
							<ColumnContent>
								<span className={classes.longText}>
									{d.horse.location
										? `${d.horse.location.address}, ${d.horse.location.suburb}, ${d.horse.location.state} ${d.horse.location.postcode}`
										: ''}
								</span>
							</ColumnContent>
						</div>
					);
				});
			} else if (type === 'forms') {
				return data.map((d) => (
					<div
						className={classes.singleFormResultContainer}
						key={`${d.formType.replace(/\s/g, '')}-${d.id}`}
						id={`member-${d.formType.replace(/\s/g, '')}-${d.id}`}
					>
						<ColumnContent style={{ maxWidth: 300 }}>
							<strong className={classes.longText}>{d.formType}</strong>
						</ColumnContent>
						<ColumnContent>{d.horseName}</ColumnContent>
						<ColumnContent>{d.status}</ColumnContent>
						<ColumnContent>
							{parseUTCToLocal(d.timeStamp).format('DD/MM/YYYY')}
						</ColumnContent>
						<ColumnContent style={{ alignItems: 'right', maxWidth: 200 }}>
							<Link
								to={`${d.link}${
									d.subType ? '?' + qs.stringify({ subType: d.subType }) : ''
								}`}
							>
								<div className={classes.formLinkButton}>
									<img src={ButtonBlue} />
								</div>
							</Link>
						</ColumnContent>
					</div>
				));
			} else if (type === 'memberBenefitsAndMerchandise') {
				return data.map((d) => (
					<>
						<table key={d.id} className={classes.memberBenefitsTable}>
							<tr>
								<td
									className={classes.memberBenefitsTableData}
									style={{ maxWidth: 200 }}
								>
									<strong className={classes.longText}>
										Type of member benefit
									</strong>
								</td>
								<td className={classes.memberBenefitsTableData}>
									{d.memberBenefits.value}
								</td>
							</tr>
							<tr>
								<td
									className={classes.memberBenefitsTableData}
									style={{ maxWidth: 200 }}
								>
									{d.memberBenefits.id === 1 && (
										<strong className={classes.longText}>Clinic details</strong>
									)}
									{d.memberBenefits.id === 2 && (
										<strong className={classes.longText}>
											Competition draw date
										</strong>
									)}
									{d.memberBenefits.id === 3 && d.eventDate && (
										<strong className={classes.longText}>Event date</strong>
									)}
									{d.memberBenefits.id === 4 && (
										<strong className={classes.longText}>
											Event tickets details
										</strong>
									)}
									{d.memberBenefits.id === 5 && (
										<strong className={classes.longText}>
											Merchandise type
										</strong>
									)}
									{d.memberBenefits.id === 6 && (
										<strong className={classes.longText}>Other Details</strong>
									)}
								</td>
								<td
									className={classes.memberBenefitsTableData}
									style={{ maxWidth: 200 }}
								>
									{d.memberBenefits.id === 1 && d.clinicDetails.value}
									{d.memberBenefits.id === 2 && d.competitionDrawDate}
									{d.memberBenefits.id === 3 && d.eventDate}
									{d.memberBenefits.id === 4 && (
										<span>{d.eventTicketsDetails}</span>
									)}
									{d.memberBenefits.id === 5 && (
										<span>{d.memberMerchandise.value}</span>
									)}
									{d.memberBenefits.id === 6 && (
										<span>{d.memberBenefitsOther}</span>
									)}
								</td>
							</tr>
							{d.clinicDetailsOther && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Clinic other details
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.clinicDetailsOther}
									</td>
								</tr>
							)}
							{d.memberMerchandiseDetails && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Merchandise details
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.memberMerchandiseDetails}
									</td>
								</tr>
							)}
							{d.memberMerchandiseOther && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Merchandise details other
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.memberMerchandiseOther}
									</td>
								</tr>
							)}
							{d.memberMerchandiseRugSize.value && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Merchandise rug size
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.memberMerchandiseRugSize.value}
									</td>
								</tr>
							)}
							{d.competitionPrizeDetails && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Competition prize details
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.competitionPrizeDetails}
									</td>
								</tr>
							)}
							{d.winnersEligibilityConfirmedBy && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>
											Winners eligibility confirmed by
										</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.winnersEligibilityConfirmedBy}
									</td>
								</tr>
							)}
							{d.eventDate && d.memberBenefits.id === 4 && (
								<tr>
									<td
										className={classes.memberBenefitsTableData}
										style={{ maxWidth: 200 }}
									>
										<strong className={classes.longText}>Event Date</strong>
									</td>
									<td className={classes.memberBenefitsTableData}>
										{d.eventDate}
									</td>
								</tr>
							)}
							<tr style={{ maxWidth: 200 }}>
								<td
									className={classes.memberBenefitsTableData}
									style={{ maxWidth: 200 }}
								>
									<strong className={classes.longText}>Comment</strong>
								</td>
								<td className={classes.memberBenefitsTableData}>{d.comment}</td>
							</tr>
						</table>
						<div className={classes.updatedBy}>
							{formatDateTimeReadable(d.created)} -{' '}
							{d.byMemberId && (
								<span>
									{d.byMemberId.firstName} {d.byMemberId.lastName}
								</span>
							)}
						</div>
					</>
				));
			}
		}
	};

	return (
		<Container className={classes.pageContainer}>
			<SEO title="Member Profile Admin View" />
			<HeaderBanner
				title={
					memberData ? `${memberData?.firstName} ${memberData?.lastName}` : ''
				}
				top
				type="primary"
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			{isLoading && initialLoad ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<div>
					{memberData && (
						<MemberDetailsAdminView
							memberData={memberData}
							mobile={['xs', 'sm'].includes(screenClassForRender)}
						/>
					)}

					<Accordion
						title={`Claimed Thoroughbreds (${claimList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div
							className={classes.resultContainer}
							style={claimList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(claimList, 'claimedThoroughbreds')}
						</div>
					</Accordion>
					<Accordion
						title={`Forms (${formList.length})`}
						styles={{ marginTop: 20 }}
					>
						<div className={classes.filterContainer}>
							<div className={classes.filterTitle}>Filter by Form Type</div>
							<div className={classes.filterComponentContainer}>
								<FormMultiSelectList
									name="filterStatus"
									listOptions={formTypeList}
									selectedIds={selectedFormTypes}
									onSelectChange={updateFormsList}
								/>
							</div>
						</div>
						<div
							className={classes.resultContainer}
							style={formList.length > 0 ? {} : { display: 'none' }}
						>
							{renderResults(formList, 'forms')}
						</div>
					</Accordion>
					<Accordion
						title={`Community Member Benefits & Merchandise (${memberBenefitsAndMerchandiseList.length})`}
						styles={{ marginTop: 20 }}
						isOpen={isAccordionOpen}
					>
						{memberBenefitsAndMerchandiseList && (
							<>
								<CommunityMemberBenefitsAndMerchandiseForm
									memberData={memberData}
									adminExternalId={currentUser?.UserId}
									loadMemberData={loadMemberData}
									setIsLoading={setIsLoading}
									isLoading={isLoading}
									setInitialLoad={setInitialLoad}
									setIsAccordionOpen={setIsAccordionOpen}
								/>
							</>
						)}
						<div
							className={classes.resultContainer}
							style={
								memberBenefitsAndMerchandiseList.length > 0
									? {}
									: { display: 'none' }
							}
						>
							{renderResults(
								memberBenefitsAndMerchandiseList,
								'memberBenefitsAndMerchandise'
							)}
						</div>
					</Accordion>

					{currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE) &&
						memberData && (
							<MemberAdminNotes
								memberData={memberData}
								adminExternalId={currentUser?.UserId}
								mobile={['xs', 'sm'].includes(screenClassForRender)}
								onUploadError={handleUploadError}
								setImgSrcUrl={setImgSrcUrl}
								setImageSrcPdf={setImageSrcPdf}
							/>
						)}

					{currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE) &&
						memberData && (
							<MemberAccountStatus
								formName={PAGE_ID}
								memberData={memberData}
								accountDisableReasonsList={accountDisableReasonsList}
								adminExternalId={currentUser?.UserId}
								statusChangedByName={statusChangedByName}
								mobile={['xs', 'sm'].includes(screenClassForRender)}
								isLoading={isLoading}
								onMemberAccountStatusUpdate={changeMemberAccountStatus}
							/>
						)}
				</div>
			)}
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
			<a
				style={{ visibility: 'hidden', cursor: 'pointer' }}
				href={imgSrcPdf}
				ref={hrefLink}
				target="_blank"
				rel="noreferrer"
			>
				Pdf Link
			</a>
		</Container>
	);
};
export default MemberAdminView;

import React from 'react';
import useStyles from './styles';
import FormToolTip from '../../../FormToolTip';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../../../utils/helpers';

const HorseDatum = ({
	label,
	value,
	showPrivateDataTooltip,
	displayCondition = true
}) => {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const classes = useStyles();
	return displayCondition ? (
		<div className={classes.horseDatumContainer}>
			<div className={classes.datumLabel}>
				{label}
				{showPrivateDataTooltip && (
					<FormToolTip
						toolTipContainerStyle={{
							width: isSmallScreen ? 170 : 400,
							left: 40
						}}
					>
						Change who can see specific fields by clicking Edit, and changing
						the privacy buttons next to each field.
					</FormToolTip>
				)}
			</div>
			<div className={classes.datumValue}>{value}</div>
		</div>
	) : null;
};

export default HorseDatum;

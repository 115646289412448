import { BLANK_OPTION } from '../../utils/constants';

export const INITIAL_FORM_STATE = {
	resetOption: {
		value: 1
	},
	dateEuthanised: {
		value: null,
		validationError: ''
	},
	euthanisedReason: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	euthanisedOtherReason: {
		value: '',
		validationError: ''
	},
	euthanisedInjuries: {
		value: [],
		validationError: ''
	},
	euthanisedOtherInjuries: {
		value: '',
		validationError: ''
	},
	dateRehomed: {
		value: null,
		validationError: ''
	},
	soldPrice: {
		value: null,
		validationError: ''
	},
	rehomedPurpose: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	otherRehomingPurpose: {
		value: '',
		validationError: ''
	},
	selectedDisciplines: {
		value: [],
		validationError: ''
	},
	otherDisciplines: {
		value: '',
		validationError: ''
	},
	rehomedPostcode: {
		value: '',
		validationError: ''
	},
	dateReturned: {
		value: null,
		validationError: ''
	},
	returnedReason: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	otherReturnedReason: {
		value: ''
	},
	returnedInjuries: {
		value: [],
		validationError: ''
	},
	otherReturnedInjuries: {
		value: ''
	},
	injuriesPresent: {
		value: false
	},
	notedInjuriesPresent: {
		value: false
	},
	notedInjuries: {
		value: [],
		valdiationError: ''
	},
	otherNotedInjuries: {
		value: '',
		validationError: ''
	},
	notNotedInjuriesPresent: {
		value: false
	},
	notNotedInjuries: {
		value: [],
		validationError: ''
	},
	otherNotNotedInjuries: {
		value: '',
		validationError: ''
	},
	newInjuriesPresent: {
		value: false
	},
	newInjuries: {
		value: [],
		validationError: ''
	},
	otherNewInjuries: {
		value: '',
		validationError: ''
	},
	illnessesPresent: {
		value: false
	},
	notedIllnessesPresent: {
		value: false
	},
	notedIllnesses: {
		value: [],
		validationError: ''
	},
	otherNotedIllnesses: {
		value: ''
	},
	notNotedIllnessesPresent: {
		value: false
	},
	notNotedIllnesses: {
		value: [],
		validationError: ''
	},
	otherNotNotedIllnesses: {
		value: ''
	},
	newIllnessesPresent: {
		value: false
	},
	newIllnesses: {
		value: [],
		validationError: ''
	},
	otherNewIllnesses: {
		value: ''
	}
};

export const RESET_CONCLUDED_OPTIONS = [
	{
		id: 1,
		label: 'Euthanised',
		value: 1,
		isSelected: true
	},
	{
		id: 2,
		label: 'Sold/Rehomed',
		value: 2,
		isSelected: false
	},
	{
		id: 3,
		label: 'Returned to owner',
		value: 3,
		isSelected: false
	}
];

export const PAGE_ID = 'reset-concluded-form';

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	validationError: {
		color: '#db0000',
		marginTop: 3,
		marginBottom: 0,
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	}
}));

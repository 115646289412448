import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { Col, Row } from 'react-grid-system';
import { BLANK_OPTION, EventRequestReportKeys } from '../../utils/constants';
import { formatDate } from '../../utils/helpers';
import FormDatePicker from '../FormDatePicker';
import { useToast } from '../../hooks/useToast';
import PrimaryButton from '../PrimaryButton';
import SelectInputField from '../FormComponents/SelectInputField';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getEventDropdowns,
	generateAssignedEventReportNotice,
	saveEventReportRequestNotification
} from '../../graphql/custom.queries';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import PageContainer from '../PageContainer';
import SEO from '../seo';

const EventReportingRequest = () => {
	const { addToast } = useToast();
	const classes = useStyles();
	const [eventOrganisers, setEventOrganisers] = useState([]);
	const [onValidate, setOnValidate] = useState(false);
	const [isShowError, setIsShowError] = useState(false);
	const [formData, setFormData] = useState({
		eventOrganiser: {
			value: '',
			errorMessage: 'Event Organiser is required',
			isError: false
		},
		dateRequired: {
			value: null,
			errorMessage: 'Date is required',
			isError: false
		}
	});

	useEffect(() => {
		AppSyncService.execute(getEventDropdowns).then((data) => {
			if (data?.data?.listEventOrganisers) {
				setEventOrganisers(
					[BLANK_OPTION].concat(data.data.listEventOrganisers)
				);
			}
		});
	}, []);

	useEffect(() => {
		if (onValidate) {
			setOnValidate(false);
			const validatedFields = validateFields();
			setFormData(validatedFields);
		}
	}, [onValidate]);

	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...formData };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setFormData(items);
		} else {
			let items = { ...formData };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setFormData(items);
		}
		setOnValidate(true);
	};

	const validateFields = () => {
		let items = { ...formData };
		let eventOrganiserItem = {
			...items['eventOrganiser'],
			isError: !formData.eventOrganiser.value
		};
		items['eventOrganiser'] = eventOrganiserItem;
		let dateRequiredItem = {
			...items['dateRequired'],
			isError: !formData.dateRequired.value
		};
		items['dateRequired'] = dateRequiredItem;
		return items;
	};

	const handleClick = async () => {
		const validatedFields = validateFields();
		setFormData(validatedFields);
		if (Object.values(validatedFields)?.every((field) => !field.isError)) {
			const payload = {
				eventOrganiserId: formData.eventOrganiser.value,
				dateRequired: formatDate(formData.dateRequired.value)
			};
			const result = await AppSyncService.execute(
				saveEventReportRequestNotification,
				payload
			);
			const resultEmail = await AppSyncService.execute(
				generateAssignedEventReportNotice,
				{
					eventOrganiserId: payload.eventOrganiserId,
					dueDate: payload.dateRequired
				}
			);
			if (
				resultEmail.data?.generateAssignedEventReportNotice &&
				result.data?.saveEventReportRequestNotification?.success
			) {
				addToast({
					Message: 'The Sponsorship Outcome Report has been requested',
					IsSuccess: true
				});
			}
		} else {
			setIsShowError(true);
		}
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		let items = { ...formData };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;

		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setFormData(items);
		setOnValidate(true);
	};

	return (
		<PageContainer className={classes.container}>
			<SEO title="Off The Track Events" />
			<Row>
				<Col xs={12}>
					<div className={classes.sectionContainer}>
						<div className={classes.headerContainer}>
							Request Sponsorship Outcome Report
						</div>
					</div>
				</Col>
			</Row>
			<Row style={{ marginTop: 20, marginLeft: 15 }}>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						{/* <div className={classes.fieldTitle}>Event Organiser*</div> */}
						<SelectInputField
							label="Event organiser"
							id={EventRequestReportKeys.EVENT_ORGANISER}
							options={eventOrganisers}
							name={`eventOrganiser`}
							value={formData.eventOrganiser.value}
							isRequired
							fullWidth
							onChange={(e) => handleChangeDropDown(e, [])}
							validationError={
								isShowError && formData.eventOrganiser?.isError
									? formData.eventOrganiser.errorMessage
									: ''
							}
						/>
						{/* {isShowError && formData.eventOrganiser?.isError && (
							<span className={classes.errorText}>
								{formData.eventOrganiser.errorMessage}
							</span>
						)} */}
					</div>
				</Col>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Date Required*</div>
						<FormDatePicker
							id={EventRequestReportKeys.DATE_REQUIRED}
							name="dateRequired"
							minDate={new Date()}
							noMaxDate
							DateValue={formData.dateRequired.value}
							updateSelection={(value) =>
								handleDateUpdate(value, 'dateRequired')
							}
						/>
						{isShowError && formData.dateRequired?.isError && (
							<ValidationErrorText
								errorText={formData.dateRequired.errorMessage}
							/>
						)}
					</div>
				</Col>
				<Col xs={12} lg={4} style={{ paddingTop: 35 }}>
					<PrimaryButton
						className={classes.buttonStyle}
						isNonSubmit
						onClick={handleClick}
					>
						Request Report
					</PrimaryButton>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default EventReportingRequest;

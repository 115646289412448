export const defaultModalMobileStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 100
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		top: '40px',
		left: '40px',
		right: '40px',
		bottom: '40px',
		border: '1px solid #ccc',
		background: '#ddd',
		overflow: 'inherit',
		WebkitOverflowScrolling: 'touch',
		borderRadius: '4px',
		outline: 'none',
		padding: 0,
		minWidth: '100%',
		minHeight: 250,
		maxHeight: 500,
		position: 'relative',
		inset: 'unset'
	}
};

export const defaultModalStyles = {
	overlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 100
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		top: '40px',
		left: '40px',
		right: '40px',
		bottom: '40px',
		border: '1px solid #ccc',
		background: '#ddd',
		overflow: 'inherit',
		WebkitOverflowScrolling: 'touch',
		borderRadius: '4px',
		outline: 'none',
		padding: '20px',
		maxWidth: 500,
		maxHeight: 500,
		minWidth: 500,
		minHeight: 500,
		position: 'relative'
	}
};

import React from 'react';
import Modal from 'react-modal';
import { CommonKeys } from './../../utils/constants/index';

const buttonStyle = {
	color: 'white',
	background: '#006da6',
	marginTop: 20,
	width: '35%',
	textAlign: 'center',
	height: 30,
	paddingTop: 8,
	borderRadius: 5,
	marginLeft: 'auto',
	marginRight: 'auto',
	cursor: 'pointer'
};

const buttonStyleNegative = {
	color: '#006da6',
	background: 'white',
	border: '1px solid #c7ced1',
	marginTop: 20,
	width: '35%',
	textAlign: 'center',
	height: 30,
	paddingTop: 8,
	marginLeft: 'auto',
	borderRadius: 5,
	marginRight: 'auto',
	cursor: 'pointer'
};

const ConfirmationModal = ({
	heading,
	children,
	styles,
	showModal,
	closeModal,
	positiveLabel,
	negativeLabel,
	onClickPositive,
	isConfirmDisabled = false
}) => {
	return (
		<div>
			<Modal
				isOpen={showModal}
				onRequestClose={closeModal}
				style={styles}
				contentLabel="Example Modal"
				ariaHideApp={false}
			>
				<h2>{heading}</h2>
				{children}
				<div style={{ display: 'flex' }}>
					<div
						id={CommonKeys.CANCEL}
						style={buttonStyleNegative}
						onClick={closeModal}
					>
						{negativeLabel}
					</div>
					<div
						style={{
							...buttonStyle,
							...(isConfirmDisabled && {
								pointerEvents: 'none',
								background: '#d8d8d8',
								color: '#b2b2b2'
							})
						}}
						onClick={onClickPositive}
						id={CommonKeys.CONFIRM}
					>
						{positiveLabel}
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default ConfirmationModal;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	topContainer: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 560,
		width: '100%'
	},
	caroContainer: {
		position: 'absolute',
		left: 0,
		right: 0,
		margin: 'auto',
		width: '100%'
	},
	active: {
		left: 0,
		right: 0,
		margin: 'auto',
		position: 'absolute',
		height: 520,
		zIndex: 1,
		borderRadius: 10,
		opacity: 1,
		transition: 'opacity 1000ms ease',
		width: '100%'
	},
	next: {
		right: 75,
		position: 'absolute',
		height: 490,
		top: 10,
		borderRadius: 10,
		opacity: 0.5,
		width: '100%'
	},
	prev: {
		left: 75,
		position: 'absolute',
		height: 490,
		top: 10,
		borderRadius: 10,
		opacity: 0.5,
		width: '100%'
	},
	image: {
		width: '100%',
		height: '100%',
		borderRadius: 10
	},
	back: {
		position: 'absolute',
		left: 25,
		top: 215,
		zIndex: 2
	},
	forward: {
		position: 'absolute',
		right: 25,
		top: 215,
		zIndex: 2
	},
	sliderContainer: {
		position: 'relative',
		overflow: 'hidden',
		maxHeight: 470,
		minHeight: 250,
		marginTop: 20,
		marginRight: 10,
		width: '100%',
		marginBottom: 10,
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		maxWidth: 500
	},
	sliderContainerMobile: {
		extend: 'sliderContainer',
		marginRight: 10,
		marginLeft: 10
	},
	mobileActive: {
		display: 'block',
		maxWidth: 500,
		maxHeight: 500,
		width: 'auto',
		height: 'auto'
	},
	mobileBack: {
		position: 'absolute',
		left: 15,
		top: 70,
		zIndex: 3
	},
	mobileForward: {
		position: 'absolute',
		right: 15,
		top: 70,
		zIndex: 3
	},
	mobileBackBase: {
		position: 'absolute',
		left: 15
	},
	mobileForwardBase: {
		position: 'absolute',
		right: 15
	},
	mobileButton: {
		height: 40
	},
	modalStyle: {
		zIndex: '4!important'
	},
	xButton: {
		position: 'absolute',
		zIndex: 70,
		top: -13,
		right: 5
	}
}));

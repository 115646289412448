import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	resultColumn: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: 20,
		boxSizing: 'border-box'
	},
	resultColumnAckApplication: {
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: 20,
		boxSizing: 'border-box'
	},
	resultHeading: {
		background: '#f5f5f5',
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 15
	},
	resultContainer: {
		maxHeight: 304,
		border: '1px solid #d9dde0',
		borderTop: 'none',
		overflowY: 'auto',
		'& > div:last-child': {
			borderBottom: 'none'
		}
	},
	singleResultContainer: {
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 15
	},
	button: {
		textAlign: 'right',
		marginRight: 30,
		width: '100%',
		height: '100%'
	},
	actionButton: {
		display: 'block',
		textAlign: 'center',
		paddingLeft: 26,
		paddingRight: 26,
		borderRadius: 4,
		background: '#006da6',
		padding: 8,
		color: 'white',
		fontWeight: 600,
		float: 'right',
		marginTop: 13,
		cursor: 'pointer'
	},
	longText: {
		width: 250,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		'@media (max-width:992px)': {
			width: 80
		}
	},
	bolder: {
		fontWeight: 'bold'
	}
}));

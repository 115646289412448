import React, { useEffect, useState } from 'react';
import AppSyncService from '../../graphql/AppSyncService';
import { getResetExpressHorses } from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import {
	FOSTER,
	OTT_INFO_MAIL,
	RESET_EXPRESS,
	RESET_EXPRESS_FILTER_OPT
} from '../../utils/constants';
import { MessageBarTypeEnum } from '../../utils/enums';
import { convertToMultiListData } from '../../utils/helpers';
import DataTable from '../DataTable';
import FormMultiSelectList from '../FormMultiSelectList';
import HeaderBanner from '../HeaderBanner';
import HorseManagementDataRow from '../HorseManagementDataRow';
import InfoBar from '../InfoBar';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';

const tableHeadings = [
	'',
	'Horse Name',
	'Current Address',
	'Form/ Status Update',
	'Last Report Submitted'
];

const mobileHeadings = null;

const ResetExpressManagement = ({ isMobile }) => {
	const classes = useStyles();

	const [
		acknowledgedRetirementHorses,
		setAcknowledgedRetirementHorses
	] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const [originalHorseList, setOriginalHorseList] = useState([]);
	const [hasHorseInOriginalList, setHasHorseInOriginalList] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [currentSortDirection, setCurrentSortDirection] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		const memberExternalId = { memberExternalId: currentUser?.UserId };
		setStatusOptions(convertToMultiListData(RESET_EXPRESS_FILTER_OPT, 'value'));
		AppSyncService.execute(getResetExpressHorses, memberExternalId).then(
			(data) => {
				if (data?.data?.getResetExpressHorses) {
					setAcknowledgedRetirementHorses(data.data.getResetExpressHorses);
					const allHorses = JSON.parse(
						JSON.stringify(data.data.getResetExpressHorses)
					);
					setOriginalHorseList(allHorses);
					setHasHorseInOriginalList(
						data.data.getResetExpressHorses.length > 0 ? true : false
					);
				}
				setIsLoading(false);
			}
		);
		setIsLoading(false);
	}, []);

	const NoRetiredHorsesInfoBarText = () => {
		return (
			<>
				<div>
					You do not have any claimed thoroughbreds which are assigned to this
					Program.
				</div>
				<div>
					If this is incorrect, please ensure you have claimed the thoroughbreds
					first, then email{' '}
					<a href={'mailto:' + OTT_INFO_MAIL}>{OTT_INFO_MAIL}</a> with the horse
					name/s to have the Program updated by the RVEW team.
				</div>
			</>
		);
	};

	useEffect(() => {
		buildRows();
	}, [acknowledgedRetirementHorses]);

	const reloadReportLastSubmittedDate = () => {
		// @TODO: Fetch last submitted date for reset express forms
	};

	useEffect(() => {
		reloadReportLastSubmittedDate();
	}, [currentUser]);

	const buildRows = () => {
		const numRows = acknowledgedRetirementHorses.length;
		const constructedRows = acknowledgedRetirementHorses.map(
			(acknowledgedRetirementHorse, index) => {
				return (
					<HorseManagementDataRow
						horseData={acknowledgedRetirementHorse}
						key={index}
						index={index}
						isLastRow={numRows === index + 1}
						isMobile={isMobile}
						// reload={reloadReportNumbers}
						program={RESET_EXPRESS}
					/>
				);
			}
		);
		setRows(constructedRows);
	};

	const sortRowsDown = (horseList) => {
		setCurrentSortDirection(1);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horseName
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = (horseList) => {
		setCurrentSortDirection(2);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horseName
				)
			)
		);
		buildRows();
	};

	const updateHorseList = (value) => {
		setSelectedStatus(value);
		let tempList = Object.assign([], originalHorseList);
		let array = [];
		if (value.length > 0) {
			value.map((flist) => {
				let filter = flist.label;
				tempList.map((d) => {
					if (d?.currentStatus?.value === filter) {
						array.push(d);
					}
				});
			});
			if (currentSortDirection === 1) {
				sortRowsDown(array);
			} else if (currentSortDirection === 2) {
				sortRowsUp(array);
			} else {
				setAcknowledgedRetirementHorses(array);
			}
		} else {
			if (currentSortDirection === 1) {
				sortRowsDown(tempList);
			} else if (currentSortDirection === 2) {
				sortRowsUp(tempList);
			} else {
				setAcknowledgedRetirementHorses(tempList);
			}
		}
	};

	const SubmitInfoBar = () => {
		return !hasHorseInOriginalList ? (
			<InfoBar
				noButton
				type={
					!hasHorseInOriginalList
						? MessageBarTypeEnum.info
						: MessageBarTypeEnum.message
				}
			>
				<NoRetiredHorsesInfoBarText />
			</InfoBar>
		) : (
			<></>
		);
	};

	return (
		<div>
			<SubmitInfoBar />
			<HeaderBanner
				title={`RESET 2 Program Thoroughbreds (${acknowledgedRetirementHorses.length})`}
				top
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			{!isMobile && (
				<div className={classes.filterContainer}>
					<div className={classes.filterTitle}>Filter by</div>
					<div className={classes.filterComponentContainer}>
						<FormMultiSelectList
							name="filterStatus"
							listOptions={statusOptions}
							selectedIds={selectedStatus}
							onSelectChange={updateHorseList}
						/>
					</div>
				</div>
			)}
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
				hasHorseInOriginalList={hasHorseInOriginalList}
				program={FOSTER}
				emptyMessage={'No thoroughbreds in the RESET 2 Program'}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
			{hasHorseInOriginalList && <SubmitInfoBar />}
		</div>
	);
};

export default ResetExpressManagement;

import React, { Fragment, useState } from 'react';
import FormDatePicker from '../FormDatePicker';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SaveCancelButton from '../SaveCancelButton';
import { formatDate } from '../../utils/helpers';
import FormTextField from '../FormTextField';
import FormDropDown from '../FormDropDown';
import RetrainerConcludedFullCircleForm from '../RetrainerConcludedFullCircleForm';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';

const RetrainerConcludedReturnToUserForm = ({
	cancel,
	submitForm,
	reasons,
	showFullCircleForm
}) => {
	const classes = useStyles();
	const [selectedReason, setSelectedReason] = useState(0);
	const [date, setDate] = useState(null);
	const [error, setError] = useState('');
	const [other, setOther] = useState('');
	const [wantFullCircle, setWantFullCircle] = useState(true);
	const [assistanceType, setAssistanceType] = useState(0);

	const updateDate = (date) => {
		if (date === '') {
			setDate(null);
		} else {
			const formattedDate = formatDate(date);
			if (formattedDate !== '1970-01-01') {
				setDate(formattedDate);
			} else {
				setDate(null);
			}
		}
	};

	const saveData = () => {
		if (
			date &&
			selectedReason.length > 0 &&
			((reasons.filter(
				(i) => i.id === parseInt(selectedReason) && i.value === 'Other'
			).length > 0 &&
				other != '') ||
				reasons.filter(
					(i) => i.id === parseInt(selectedReason) && i.value === 'Other'
				).length === 0) &&
			(!showFullCircleForm ||
				!wantFullCircle ||
				(wantFullCircle && assistanceType.length > 0))
		) {
			const data = {
				date: date,
				reasonId: selectedReason,
				other: other,
				wantFullCircle: !showFullCircleForm ? false : wantFullCircle,
				assistanceType
			};
			submitForm(data);
		} else {
			setError('Fields cannot be empty');
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<Row>
					<Col lg={4}>
						<div className={classes.dateFieldTitle}>Date Returned*</div>
						<div>
							<FormDatePicker
								id={HorseManagementKeys.DATE_RETURNED}
								styles={{ width: '100%', color: 'blue' }}
								DateValue={date}
								updateSelection={(value) => updateDate(value)}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className={classes.dateFieldTitle}>Reason*</div>
						<FormDropDown
							id={HorseManagementKeys.RETURN_TO_OWNER_REASON}
							showBlank
							items={reasons}
							selectedId={selectedReason}
							onChange={(e) => setSelectedReason(e.target.value)}
						/>
					</Col>
					{reasons.filter(
						(i) => i.id === parseInt(selectedReason) && i.value === 'Other'
					).length > 0 && (
						<Col lg={4}>
							<div className={classes.dateFieldTitle}>Other*</div>
							<FormTextField
								id={HorseManagementKeys.RETURN_TO_OWNER_REASON_OTHER}
								value={other}
								onChange={(e) => setOther(e.target.value)}
								styles={{ height: 38 }}
							/>
						</Col>
					)}
				</Row>
				<hr className={classes.divider}></hr>
				{showFullCircleForm && (
					<Fragment>
						<RetrainerConcludedFullCircleForm
							wantFullCircle={wantFullCircle}
							updateWantFullCircle={setWantFullCircle}
							assistanceType={assistanceType}
							updateAssistanceType={setAssistanceType}
						/>
						<hr className={classes.divider}></hr>
					</Fragment>
				)}
				<div className={classes.error}>{error}</div>
				<div className={classes.saveButtonContainer}>
					<SaveCancelButton
						idPositive={CommonKeys.SAVE}
						idNegative={CommonKeys.CANCEL}
						positiveLabel="Save"
						cancel={cancel}
						onClickPositive={saveData}
						negativeLabel="Cancel"
					/>
				</div>
			</div>
		</div>
	);
};
export default RetrainerConcludedReturnToUserForm;

import React from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import StyledSelectWrapper from '../StyledSelectWrapper';

const StateSelector = ({
	id,
	country,
	value,
	changeHandler,
	isError,
	isDisabled = false
}) => {
	return (
		<StyledSelectWrapper isDisabled={isDisabled} isError={isError}>
			<RegionDropdown
				id={id}
				country={country}
				value={value}
				onChange={changeHandler}
				valueType="short"
				disabled={isDisabled}
			/>
		</StyledSelectWrapper>
	);
};

export default StateSelector;

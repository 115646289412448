import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	searchContainer: {
		border: '2px solid #d9dde0',
		maxHeight: '100%',
		overflow: 'hidden'
	},
	searchContainerHeader: {
		fontSize: 15,
		color: '#464749',
		fontWeight: 800,
		padding: '10px 15px 6px 0px'
	},
	searchFieldContainer: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	searchField: {
		float: 'left',
		height: '38px',
		borderRadius: '4px 0px 0px 4px',
		border: '1px solid #b3bcc1',
		minWidth: '100%',
		paddingLeft: 20,
		fontSize: 15,
		margin: 0,
		boxSizing: 'border-box',
		'&:focus': {
			outline: 'none'
		}
	},
	searchButton: {
		backgroundColor: '#006da6',
		border: 'none',
		'-webkitAppearance': 'none',
		borderRadius: '0px 4px 4px 0px',
		color: 'white',
		float: 'left',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		minWidth: '100%',
		height: 38,
		fontSize: 16,
		margin: 0,
		cursor: 'pointer',
		'&:focus': {
			outline: 'none'
		}
	},
	errorText: {
		color: '#db0000'
	},
	break: {
		flexBasis: '100%',
		height: 0
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	listfieldContainer: {
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	errorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	},
	errorText: {
		color: '#db0000'
	},
	formContainer: {
		marginTop: 20,
		border: '1px solid #b3bcc1',
		borderRadius: 5
	},
	sectionContainer: {
		padding: 10
	},
	container: {
		width: 1000,
		marginBottom: 50,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 50,
		marginTop: 80,
		paddingLeft: '15px !important',
		paddingRight: '15px !important'
	},
	saveRequest: {
		marginTop: 12,
		color: '#0085ca',
		fontSize: 15,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	}
}));

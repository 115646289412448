import React from "react";
import useStyles from "./styles";

const FilterBarGlobal = ({ filters, mobile }) => {
  const classes = useStyles();

  const clearAllFilters = () => {
    filters.forEach(filter => {
      filter[1]('');
    });
  }

  const displayFilter = (item, updateItem) => {
    return (
      item && (
        <span key={item} className={mobile ? classes.appliedFilterMobile : classes.appliedFilter}>
          {item}{" "}
          <span
            className={classes.appliedFilterCross}
            onClick={() => updateItem("")}
          >
            &#10006;
          </span>
        </span>
      )
    );
  };

  return (
    <>
      <div className={classes.filters}>
        <div className={classes.filterContent}>
          {filters.map(filter => displayFilter(filter[0], filter[1]))}<span
            className={mobile ? classes.appliedFilterMobile : classes.appliedFilter}
            onClick={clearAllFilters}
          >
            Clear all
          </span>
        </div>
      </div>
    </>
  );
};

export default FilterBarGlobal;

import React, { useState } from "react";
import { Col, Row } from "react-grid-system";
import useStyles from "./styles";
import HeaderBanner from "../HeaderBanner";
import { getUserImage } from "../../graphql/custom.queries";
import UploadPhoto from "../UploadPhoto";
import AppSyncService from "../../graphql/AppSyncService";
import Lightbox from "react-image-lightbox";
import FormLabelField from "../FormLabelField";

const BusinessProfileView = ({
  businessProfileData,
}) => {
  const classes = useStyles();
  const [imgSrcUrl, setImgSrcUrl] = useState(null);

  const getImage = img => {
    const imageKey = { imageKey: img.key };
    AppSyncService.execute(getUserImage, imageKey).then(data => {
      setImgSrcUrl(data.data.getUserImage.signedUrl);
    });
  };

  return (
    <>
      <HeaderBanner
        title="Business Details"
        top
        type="primary"
        styles={{ marginBottom: "0px", marginTop: "0px" }}
      />
      <div className={classes.container}>
        <Row>
          <Col>
            <FormLabelField
              title="I am a"
              value={businessProfileData?.businessProfileType?.value}
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <FormLabelField
              title="Business Name*"
              value={businessProfileData?.businessName}
            />
          </Col>
          <Col>
            <div className={classes.ProfilefieldContainer}>
              <div className={classes.fieldTitle}>
                Business Logo
                </div>
              <UploadPhoto
                formats={["png", "jpeg"]}
                data={businessProfileData?.logo}
                getImage={getImage}
                canEdit={false}
              />
            </div>
          </Col>
          <Col></Col>
        </Row>
        <hr className={classes.divider}></hr>
        <Row>
          <Col>
            <FormLabelField
              title="First Name*"
              value={businessProfileData?.firstName}
            />
          </Col>
          <Col>
            <FormLabelField
              title="Last Name*"
              value={businessProfileData?.lastName}
            />
          </Col>
          <Col>
            <FormLabelField
              title="Email*"
              value={businessProfileData?.email}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormLabelField
              title="Phone*"
              value={businessProfileData?.businessPhone}
            />
          </Col>
          <Col>
          </Col>
          <Col></Col>
        </Row>
        <hr className={classes.divider}></hr>
        <Row>
          <Col>
            <FormLabelField
              title="Address Line 1*"
              value={businessProfileData?.streetAddress}
            />
          </Col>
          <Col>
            <FormLabelField
              title="Address Line 2"
              value=""
            />
          </Col>
          <Col>
            <FormLabelField
              title="Suburb*"
              value={businessProfileData?.suburb}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormLabelField
              title="Postcode*"
              value={businessProfileData?.postcode}
            />
          </Col>
          <Col>
            <FormLabelField
              title="State*"
              value={businessProfileData?.state}
            />
          </Col>
          <Col></Col>
        </Row>
        {imgSrcUrl && (
          <Lightbox
            mainSrc={imgSrcUrl}
            onCloseRequest={() => setImgSrcUrl(false)}
          />
        )}
      </div>
    </>
  );
};
export default BusinessProfileView;
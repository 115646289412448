export const getFosterAssessmentFormLatestUpdated = `
query getFosterAssessmentFormLatestUpdated($memberExternalId: String!) {
    getFosterInitialAssessmentForms(memberExternalId: $memberExternalId status: Review orderBy: "last_updated" order: DESC) {
        id
        lastUpdated
    }
    getFosterWeeklyAssessmentForms(memberExternalId: $memberExternalId status: Review orderBy: "last_updated" order: DESC) {
        id
        lastUpdated
    }
}
`;

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import MessageBar from '../MessageBar';
import { Button } from '../Mobile';
import FormButton from '../FormButton';
import SimpleCheckBox from '../SimpleCheckBox';
import FormDatePicker from '../FormDatePicker';
import { formatDate } from '../../utils/helpers';
import FormDropDown from '../FormDropDown';
import FormTextArea from '../FormTextArea';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import { listNoLongerInterestedOptions } from '../../graphql/custom.queries';

const MAXREASONLENGTH = 100;

const HorseInterestForm = ({
	retrainingConfirmed,
	noLongerInterestedAction,
	confirmRetrainerAction,
	mobile
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [contactOwner, setContactOwner] = useState(false);
	const [dateArranged, setDateArranged] = useState(false);
	const [acknowledged, setAcknowledged] = useState(false);
	const [nolongerInterested, setNolongerInterested] = useState(0);
	const [noLongerInterestedList, setNoLongerInterestedList] = useState([]);
	const [moreInfo, setMoreInfo] = useState('');
	const [noLongerInterestedPressed, setNoLongerInterestedPressed] = useState(
		false
	);
	const [characters, setCharacters] = useState(MAXREASONLENGTH);
	const [date, setDate] = useState(null);

	useEffect(() => {
		AppSyncService.execute(listNoLongerInterestedOptions).then((data) => {
			try {
				let list = data.data.listNoLongerInterestedOptions;
				list.unshift({ id: 0, value: '' });
				setNoLongerInterestedList(list);
			} catch {
				addToast({ Message: 'Unable to fetch list', IsSuccess: false });
			}
		});
	}, []);

	const noLongerInterestedReason = (value) => {
		setNolongerInterested(value);
	};

	const noLongerInterestedSubmit = () => {
		noLongerInterestedAction(nolongerInterested, moreInfo);
	};

	const updateMoreInfo = (val) => {
		let valueLength = val.length;
		setCharacters(MAXREASONLENGTH - valueLength);
		setMoreInfo(val);
	};

	const confirmRetrainer = () => {
		confirmRetrainerAction(contactOwner, acknowledged, dateArranged, date);
	};

	const updateDate = (val) => {
		let formattedDate = formatDate(val);
		if (formattedDate != '1970-01-01') {
			setDate(formattedDate);
		} else {
			setDate(null);
		}
	};

	return (
		<>
			{!retrainingConfirmed && (
				<div className={mobile ? {} : classes.formContainer}>
					<MessageBar
						type="info"
						style={
							mobile
								? { padding: '15px 12px', fontSize: 13 }
								: { padding: '15px 12px' }
						}
					>
						Thank you for your interest in retraining this thoroughbred.
						{mobile ? '' : <br />}
						<b>
							{mobile ? ' ' : ''}This listing is now only visible to you for 12
							hours.
						</b>
						{mobile ? '' : <br />}
						After 12 hours, if you have not completed the below confirmation,{' '}
						{mobile ? '' : <br />}
						the horse will be <b>available to all Retrainers again.</b>
						<div className={classes.noLongerInterestedContainer}>
							<Button
								id="availableForRetraining-horseDetailsInfo-noLongerInterested"
								text="I'm no longer interested in this horse"
								styles={{ fontSize: 15 }}
								onClick={() => setNoLongerInterestedPressed(true)}
							/>
						</div>
					</MessageBar>
					<MessageBar type="message" style={{ padding: '5px 12px 20px 12px' }}>
						{noLongerInterestedPressed && (
							// Enable if no longer insterested
							<div>
								<div
									className={classes.notInterestedField}
									style={mobile ? { fontSize: 13 } : {}}
								>
									Why are you no longer interested in this horse?*
								</div>
								<FormDropDown
									showBlank
									id="availableForRetraining-horseDetailsInfo-noLongerInterestedReason"
									items={noLongerInterestedList}
									selectedId={nolongerInterested}
									onChange={(e) => {
										noLongerInterestedReason(e.target.value);
									}}
									styles={{ width: '100%' }}
								/>
								<div
									className={classes.notInterestedFieldMoreInfo}
									style={mobile ? { fontSize: 13 } : {}}
								>
									Please provide more information*{' '}
									<span
										className={classes.remainingCharacters}
										style={mobile ? { fontSize: 13 } : {}}
									>
										{characters} characters {mobile ? '' : 'remaining'}
									</span>
								</div>
								<FormTextArea
									maxLength={100}
									styles={{ height: 70, float: 'initial' }}
									value={moreInfo}
									id="availableForRetraining-horseDetailsInfo-moreInfo"
									onChange={(e) => {
										updateMoreInfo(e.target.value);
									}}
								/>
								<hr className={classes.divider} />
								<div className={classes.notInterestedContainer}>
									<div
										className={classes.notInterestedCancelButton}
										onClick={() => setNoLongerInterestedPressed(false)}
									>
										Cancel
									</div>
									<div className={classes.notInterestedSubmit}>
										<FormButton
											id="availableForRetraining-horseInterestForm-submitNotInterested"
											title="Submit"
											styles={{ fontSize: 15 }}
											isDisabled={moreInfo === '' || nolongerInterested == 0}
											onClick={() => noLongerInterestedSubmit()}
										/>
									</div>
								</div>
								<div className={classes.clear}></div>
							</div>
						)}
						{!noLongerInterestedPressed && (
							<div>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={() => setContactOwner(!contactOwner)}
										custom
										customStyle={{ float: 'left' }}
										data={{
											id:
												'availableForRetraining-horseInterestForm-contactOwner',
											isSelected: contactOwner
										}}
									/>

									<div
										className={classes.checkboxField}
										style={mobile ? { fontSize: 13 } : {}}
									>
										<b>Contact the owner to discuss details.</b>
									</div>
								</div>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={() => setDateArranged(!dateArranged)}
										custom
										customStyle={{ float: 'left' }}
										data={{
											id:
												'availableForRetraining-horseInterestForm-dateArranged',
											isSelected: dateArranged
										}}
									/>
									<div
										className={classes.checkboxField}
										style={mobile ? { fontSize: 13 } : {}}
									>
										<b>Arrange a date to collect this horse.</b>
									</div>

									{dateArranged && (
										<div
											className={classes.dateField}
											style={mobile ? { fontSize: 13 } : {}}
										>
											<b>Date of horse collection*</b>
										</div>
									)}
									{dateArranged && (
										<div className={classes.dateContainer}>
											<FormDatePicker
												isBasic
												id="availableForRetraining-horseInterestForm-date"
												DateValue={date}
												updateSelection={(value) => updateDate(value)}
											/>
										</div>
									)}
								</div>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={() => setAcknowledged(!acknowledged)}
										custom
										customStyle={{ float: 'left' }}
										data={{
											id:
												'availableForRetraining-horseInterestForm-acknowledged',
											isSelected: acknowledged
										}}
									/>

									<div
										className={classes.checkboxField}
										style={mobile ? { fontSize: 13 } : {}}
									>
										<b>Please acknowledge:</b> A Claim Thoroughbred form will be
										added to your notifications.{' '}
										<b>
											When you have collected the horse, please complete this
											form.
										</b>
									</div>
								</div>
								<hr className={classes.divider} />
								<FormButton
									id="availableForRetraining-horseInterestForm-confirmRetraining"
									title="I confirm I am retraining this horse"
									styles={{ fontSize: 15 }}
									onClick={() => confirmRetrainer()}
									isDisabled={
										!contactOwner ||
										!acknowledged ||
										!dateArranged ||
										date === null
									}
								/>
							</div>
						)}
					</MessageBar>
				</div>
			)}
			{retrainingConfirmed && (
				<div className={mobile ? {} : classes.formContainer}>
					<MessageBar
						style={
							mobile
								? { fontSize: 13, padding: '15px 12px' }
								: { padding: '15px 12px' }
						}
					>
						<div
							style={
								mobile
									? { fontSize: 15, fontWeight: 600 }
									: { fontSize: 20, fontWeight: 600 }
							}
						>
							Completed
						</div>
						<p>
							A Claim Thoroughbred form has been added to your notifications.
						</p>
						<p>
							{' '}
							When you have collected the horse, please gather the required
							photos and information to complete this form.
						</p>
						<p>
							{' '}
							No further action is required, this listing will now be removed.
						</p>
					</MessageBar>
				</div>
			)}
		</>
	);
};

export default HorseInterestForm;

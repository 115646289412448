export const defaultQuarterNumber = {
	value: null
};

export const defaultProgressPhotos = {
	value: []
};

export const defaultDataModel = {
	memberId: {
		value: null
	},
	horseCode: {
		value: null
	},
	dateOfAssessment: {
		value: null
	},
	hasHorseStatusChanged: {
		value: null
	},
	newStatus: {
		value: null
	},
	imgSrcUrl: {
		value: null
	},
	agistmentHasPhysicalHealthChanged: {
		value: null
	},
	agistmentChangesToPhysicalHealth: {
		value: null
	},
	agistmentChangesToPhysicalHealthOther: {
		value: null
	},
	agistmentHasBehaviourChanged: {
		value: null
	},
	agistmentChangesToBehaviourOther: {
		value: null
	},
	agistmentTreatmentSinceLastReport: {
		value: null
	},
	worming: {
		value: null
	},
	wormingDateCompleted: {
		value: null
	},
	farrier: {
		value: null
	},
	farrierDateCompleted: {
		value: null
	},
	dentistTreatment: {
		value: null
	},
	dentistTreatmentDateCompleted: {
		value: null
	},
	husbandryOther: {
		value: null
	},
	rehabReason: {
		value: null
	},
	injuryType: {
		value: []
	},
	illnessType: {
		value: []
	},
	rehabReasonOther: {
		value: null
	},
	rehabDateCommenced: {
		value: null
	},
	treatmentSinceLastReport: {
		value: null
	},
	deceasedCauseOfDeath: {
		value: null
	},
	deceasedDateOfDeath: {
		value: null
	},
	deceasedReasonOfDeath: {
		value: null
	},
	deceasedInjuryDetailsOther: {
		value: null
	},
	deceasedIllnessDetailsOther: {
		value: null
	},
	deceasedReasonForDeathOther: {
		value: null
	},
	deceasedKnackeryPostcode: {
		value: null
	},
	deceasedKnackerySuburb: {
		value: null
	},
	informationAboutDeath: {
		value: null
	},
	hasOwnerBeenNotified: {
		value: false
	},
	finalCommentsNotes: {
		value: null
	}
};

export const getDataModel = (stateVal, data) => {
	let dataModel = { ...stateVal };
	dataModel = {
		// Personal Details
		memberId: {
			...dataModel.memberId,
			value: data.memberId
		},
		horseCode: {
			...dataModel.horseCode,
			value: data.horseCode
		},
		dateOfAssessment: {
			...dataModel.dateOfAssessment,
			value: data.dateOfAssessment ? data.dateOfAssessment : null
		},
		hasHorseStatusChanged: {
			...dataModel.hasHorseStatusChanged,
			value: data.hasHorseStatusChanged ? true : false
		},
		newStatus: {
			...dataModel.newStatus,
			value: data.newStatus?.id
		},
		agistmentHasPhysicalHealthChanged: {
			...dataModel.agistmentHasPhysicalHealthChanged,
			value: data.agistmentHasPhysicalHealthChanged ? true : false
		},
		agistmentChangesToPhysicalHealth: {
			...dataModel.agistmentChangesToPhysicalHealth,
			value: data.agistmentChangesToPhysicalHealth?.id
		},
		agistmentChangesToPhysicalHealthOther: {
			...dataModel.agistmentChangesToPhysicalHealthOther,
			value: data.agistmentChangesToPhysicalHealthOther
				? data.agistmentChangesToPhysicalHealthOther
				: ''
		},
		agistmentHasBehaviourChanged: {
			...dataModel.agistmentHasBehaviourChanged,
			value: data.agistmentHasBehaviourChanged ? true : false
		},
		agistmentChangesToBehaviourOther: {
			...dataModel.agistmentChangesToBehaviourOther,
			value: data.agistmentChangesToBehaviourOther
				? data.agistmentChangesToBehaviourOther
				: ''
		},
		worming: {
			...dataModel.worming,
			value: data.worming ? true : false
		},
		wormingDateCompleted: {
			...dataModel.wormingDateCompleted,
			value: data.wormingDateCompleted ? data.wormingDateCompleted : null
		},
		farrier: {
			...dataModel.farrier,
			value: data.farrier ? true : false
		},
		farrierDateCompleted: {
			...dataModel.farrierDateCompleted,
			value: data.farrierDateCompleted ? data.farrierDateCompleted : null
		},
		dentistTreatment: {
			...dataModel.dentistTreatment,
			value: data.dentistTreatment ? true : false
		},
		dentistTreatmentDateCompleted: {
			...dataModel.dentistTreatmentDateCompleted,
			value: data.dentistTreatmentDateCompleted
				? data.dentistTreatmentDateCompleted
				: null
		},
		husbandryOther: {
			...dataModel.husbandryOther,
			value: data.husbandryOther ? data.husbandryOther : null
		},
		rehabReason: {
			...dataModel.rehabReason,
			value: data.rehabReason?.id
		},
		injuryType: {
			...dataModel.injuryType,
			value: getSources(data.injuryType) ?? []
		},
		illnessType: {
			...dataModel.illnessType,
			value: getSources(data.illnessType) ?? []
		},
		rehabReasonOther: {
			...dataModel.rehabReasonOther,
			value: data.rehabReasonOther ? data.rehabReasonOther : null
		},
		rehabDateCommenced: {
			...dataModel.rehabDateCommenced,
			value: data.rehabDateCommenced ? data.rehabDateCommenced : null
		},
		treatmentSinceLastReport: {
			...dataModel.treatmentSinceLastReport,
			value: data.treatmentSinceLastReport ? true : false
		},
		deceasedCauseOfDeath: {
			...dataModel.deceasedCauseOfDeath,
			value: data.deceasedCauseOfDeath?.id
		},
		deceasedDateOfDeath: {
			...dataModel.deceasedDateOfDeath,
			value: data.deceasedDateOfDeath ? data.deceasedDateOfDeath : null
		},
		deceasedReasonOfDeath: {
			...dataModel.deceasedReasonOfDeath,
			value: data.deceasedReasonOfDeath?.id
		},
		deceasedInjuryDetailsOther: {
			...dataModel.deceasedInjuryDetailsOther,
			value: data.deceasedInjuryDetailsOther
				? data.deceasedInjuryDetailsOther
				: null
		},
		deceasedIllnessDetailsOther: {
			...dataModel.deceasedIllnessDetailsOther,
			value: data.deceasedIllnessDetailsOther
				? data.deceasedIllnessDetailsOther
				: null
		},
		deceasedReasonForDeathOther: {
			...dataModel.deceasedReasonForDeathOther,
			value: data.deceasedReasonForDeathOther?.id
		},
		deceasedKnackeryPostcode: {
			...dataModel.deceasedKnackeryPostcode,
			value: data.deceasedKnackeryPostcode
				? data.deceasedKnackeryPostcode
				: null
		},
		deceasedKnackerySuburb: {
			...dataModel.deceasedKnackerySuburb,
			value: data.deceasedKnackerySuburb ? data.deceasedKnackerySuburb : null
		},
		informationAboutDeath: {
			...dataModel.informationAboutDeath,
			value: data.informationAboutDeath ? data.informationAboutDeath : null
		},
		hasOwnerBeenNotified: {
			...dataModel.hasOwnerBeenNotified,
			value: data.hasOwnerBeenNotified ? true : false
		},
		finalCommentsNotes: {
			...dataModel.finalCommentsNotes,
			value: data.finalCommentsNotes ? data.finalCommentsNotes : null
		}
	};

	// dataModel.staffList.conditions[0].dependantArray = staffRoleList;

	return dataModel;
};

export const setDataModel = (
	values,
	currentUserId,
	horseCode,
	quarterNumber
) => {
	let dataModel = {};
	dataModel.memberId = currentUserId;
	dataModel.horseCode = horseCode;
	dataModel.quarterNumber = quarterNumber.value;
	dataModel.dateOfAssessment = values.dateOfAssessment.value;
	dataModel.hasHorseStatusChanged = values.hasHorseStatusChanged.value;
	dataModel.newStatus = values.newStatus.value;
	dataModel.agistmentHasPhysicalHealthChanged =
		values.agistmentHasPhysicalHealthChanged.value;
	dataModel.agistmentChangesToPhysicalHealth =
		values.agistmentChangesToPhysicalHealth.value;
	dataModel.agistmentChangesToPhysicalHealthOther =
		values.agistmentChangesToPhysicalHealthOther.value;
	dataModel.agistmentHasBehaviourChanged =
		values.agistmentHasBehaviourChanged.value;
	dataModel.agistmentChangesToBehaviourOther =
		values.agistmentChangesToBehaviourOther.value;
	dataModel.worming = values.worming.value;
	dataModel.wormingDateCompleted = values.wormingDateCompleted.value;
	dataModel.farrier = values.farrier.value;
	dataModel.farrierDateCompleted = values.farrierDateCompleted.value;
	dataModel.dentistTreatment = values.dentistTreatment.value;
	dataModel.dentistTreatmentDateCompleted =
		values.dentistTreatmentDateCompleted.value;
	dataModel.husbandryOther = values.husbandryOther.value;
	dataModel.rehabReason = values.rehabReason.value;
	dataModel.injuryType = setInjurySource(values.injuryType.value);
	dataModel.illnessType = setIllnessSource(values.illnessType.value);
	dataModel.rehabReasonOther = values.rehabReasonOther.value;
	dataModel.rehabDateCommenced = values.rehabDateCommenced.value;
	dataModel.treatmentSinceLastReport = values.treatmentSinceLastReport.value;
	dataModel.deceasedCauseOfDeath = values.deceasedCauseOfDeath.value;
	dataModel.deceasedDateOfDeath = values.deceasedDateOfDeath.value;
	dataModel.deceasedReasonOfDeath = values.deceasedReasonOfDeath.value;
	dataModel.deceasedInjuryDetailsOther =
		values.deceasedInjuryDetailsOther.value;
	dataModel.deceasedIllnessDetailsOther =
		values.deceasedIllnessDetailsOther.value;
	dataModel.deceasedReasonForDeathOther =
		values.deceasedReasonForDeathOther.value;
	dataModel.deceasedKnackeryPostcode = values.deceasedKnackeryPostcode.value;
	dataModel.deceasedKnackerySuburb = values.deceasedKnackerySuburb.value;
	dataModel.informationAboutDeath = values.informationAboutDeath.value;
	dataModel.hasOwnerBeenNotified = values.hasOwnerBeenNotified.value;
	dataModel.finalCommentsNotes = values.finalCommentsNotes.value;

	return dataModel;
};

const setInjurySource = (sources) => {
	let source = [];
	if (sources !== null && sources !== undefined) {
		sources.map((val) => {
			source.push(val.value);
		});
	}
	return source;
};

const setIllnessSource = (sources) => {
	let source = [];
	if (sources !== null && sources !== undefined) {
		sources.map((val) => {
			source.push(val.value);
		});
	}
	return source;
};

const getSources = (sources) => {
	let source = [];
	sources.map((val) => {
		source.push({ value: val.id, label: val.value });
	});
	return source;
};

export const getQuarterlyYears = () => {
	const currYear = new Date().getFullYear();
	const startingYear = currYear - 5 < 2020 ? 2020 : currYear - 5;
	let years = [];
	for (let i = currYear + 1; i >= startingYear; i--) {
		years.push({
			id: i,
			value: i
		});
	}
	return years;
};

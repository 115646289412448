export const REGEX = 'REGEX';
export const TOGGLE_DEPENDENT = 'TOGGLE_DEPENDENT';

export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (initial && values[f].conditions?.length > 0) {
			values[f].conditions.map((c) => {
				if (c.condition === REGEX) {
					let regex = new RegExp(c.regex);
					if (!regex.test(values[f].value)) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === TOGGLE_DEPENDENT) {
					if (
						String(values[c.field].value) === String(c.value) &&
						(values[f].value === null ||
							values[f].value === 0 ||
							values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
			});
		}
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value?.length === 0 ||
				values[f].value === false ||
				values[f].value === undefined) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'Field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

import React, { useState, useEffect } from 'react';
import MultiDropDown from '../MultiDropDown';
import { navigate } from '@reach/router';
import useStyles from './styles';
import { ScreenClassRender, Row, Col } from 'react-grid-system';
import SearchField from '../SearchField';
import AppSyncService from '../../graphql/AppSyncService';
import {
	listFacilitiesOnAndOffProperty,
	searchFosterProvider
} from '../../graphql/custom.queries';
import Sorting_Default_icon from '../../assets/icons/Sorting_Default_icon.svg';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import { useToast } from '../../hooks/useToast';
import LoaderSpinner from '../LoaderSpinner';
import LazyImage from '../LazyImage';
import SEO from '../seo';
import Accordion from '../Accordion';
import PageContainer from '../PageContainer';
import RetirementFarmMap from '../RetirementFarmSearch/components/RetirementFarmMap';
import FormDropDown from '../FormDropDown';
import { referenceRangeOfFosterCare } from '../FosterProviderInformation';
import { OFF_SITE, ON_SITE } from '../../utils/constants';
export const ONSITE_COLOR = '#facce0';
export const OFFSITE_COLOR = '#dab9fb';
const FACILITIES_OFFERED_COLORS = [
	{
		dotColor: ONSITE_COLOR,
		title: 'On property'
	},
	{
		dotColor: OFFSITE_COLOR,
		title: 'Off property'
	}
];
const MAX_CONTAINERS_TO_DISPLAY = 8;

const FosterProviderSearch = ({ isSmallScreen }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [toggleFilter, setToggleFilter] = useState(!isSmallScreen);
	const [servicesOffered, setServiceOffered] = useState([]);
	const [filterHorseCapacity, setFilterHorseCapacity] = useState(null);
	const [offFacilities, setOffFacilities] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState([]);
	const [selectedServiceOffer, setSelectedServiceOffer] = useState([]);
	const [businessProfileIdFilter, setBusinessProfileIdFilter] = useState(null);
	const [orderByName, setOrderByName] = useState(0);
	const [nameFilterValue, setNameFilterValue] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [searchActive, setSearchActive] = useState(true);
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		AppSyncService.execute(listFacilitiesOnAndOffProperty).then((data) => {
			let listServiceArray = [];
			let count = 0;
			//@NOTE: Do not display Other as per Business Logic - IS-8727
			data.data.listFacilitiesOnProperty
				?.filter((item) => item.value !== 'Other')
				.map((rec) => {
					listServiceArray.push({
						id: count,
						key: rec.id,
						title: rec.value,
						selected: false
					});
					count++;
				});
			setOffFacilities(
				data.data.listFacilitiesOffSite?.filter(
					(item) => item.value !== 'Other'
				) ?? []
			);
			setServiceOffered(listServiceArray);
		});
	}, []);

	const searchByInput = () => {
		setSearchActive(true);
	};

	useEffect(() => {
		if (searchResults.length > 0) {
			searchByInput();
		}
	}, [businessProfileIdFilter]);

	useEffect(() => {
		if (searchActive === true) {
			updateSearch();
		}
		setSearchActive(false);
	}, [searchActive]);

	const resetServiceOffer = (id) => {
		const temp = servicesOffered;

		temp[id].selected = !temp[id].selected;

		let filter = Object.assign([], selectedFilter);
		let selectedOffer = Object.assign([], selectedServiceOffer);

		if (temp[id].selected) {
			filter.push(temp[id].title);
			selectedOffer.push({ key: temp[id].key, value: temp[id].title });
		}

		if (!temp[id].selected) {
			const index = filter.indexOf(temp[id].title);
			const key = temp[id].key;
			const filterIndex = selectedOffer.indexOf(key);
			if (index > -1) {
				filter.splice(index, 1);
				selectedOffer.splice(filterIndex, 1);
			}
		}

		setSelectedFilter(filter);
		setServiceOffered(temp);
		setSelectedServiceOffer(selectedOffer);

		setSearchActive(true);
	};

	const updateValue = (val) => {
		setNameFilterValue(val);
	};

	const removeAllFilters = () => {
		selectedFilter.forEach((value) => {
			removeFilter(value);
		});
		setSelectedServiceOffer([]);
		setSelectedFilter([]);
		setBusinessProfileIdFilter(null);
		setSearchActive(true);
	};

	const removeFilter = (val) => {
		let filters = Object.assign([], selectedFilter);
		const index = filters.indexOf(val);
		if (index > -1) {
			filters.splice(index, 1);
		}

		setSelectedFilter(filters);

		const tempA = servicesOffered;

		tempA
			.filter((i) => i.title === val)
			.map((d) => {
				d.selected = false;
			});
		let service = selectedServiceOffer.filter((i) => i.value != val);

		setServiceOffered(tempA);

		setSelectedServiceOffer(service);
		setSearchActive(true);
	};

	const updateOrdering = () => {
		let order = orderByName === 0 ? 1 : orderByName === 1 ? 2 : 0;
		setOrderByName(order);
		setSearchActive(true);
	};

	const selectedList = (data) => {
		let array = [];
		data.map((d) => {
			array.push(d.key);
		});
		return array;
	};

	const updateSearch = () => {
		let businessProfileType = 3;
		const filterCapacity = isNaN(parseInt(filterHorseCapacity))
			? null
			: parseInt(filterHorseCapacity);
		let filterOnFacilities = selectedList(selectedServiceOffer);
		let filterOffFacilities = [];
		offFacilities.forEach((facility) => {
			if (selectedServiceOffer.some((item) => item.value === facility.value)) {
				if (!filterOffFacilities.includes(facility.id)) {
					filterOffFacilities = [...filterOffFacilities, facility.id];
				}
			}
		});

		let orderBy = orderByName === 2 ? 'DESC' : 'ASC';
		setIsLoading(true);
		AppSyncService.execute(searchFosterProvider, {
			businessProfileType,
			name: nameFilterValue,
			filterOnFacilities,
			filterOffFacilities,
			rangeOfFosterCare: filterCapacity,
			orderBy,
			businessProfileIds: businessProfileIdFilter
				? [businessProfileIdFilter]
				: []
		}).then((data) => {
			//@TODO: Filter results
			try {
				setResultCount(data.data.countSearchResults);
			} catch {
				addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
			}
			try {
				setSearchResults(data.data.findRetrainers);
			} catch {
				addToast({ Message: 'Error Fetching Images', IsSuccess: false });
			}
			setIsLoading(false);
		});
	};

	const keyCheck = (key) => {
		if (key === 13) {
			setSearchActive(true);
		}
	};

	const renderFosterCapacity = (capacity) => {
		const capacityItem = referenceRangeOfFosterCare.find(
			(item) => item.id === capacity
		);
		return (
			<div className={classes.resultCapacity}>
				{capacityItem && capacityItem.value !== ''
					? `STAR Capacity: ${capacityItem.value}`
					: ''}
			</div>
		);
	};

	const getContainerLimitsForSearchResult = (onsite, offsite) => {
		const facilitiesLen = onsite?.length + offsite?.length;
		if (facilitiesLen < MAX_CONTAINERS_TO_DISPLAY / 2) {
			return {
				numFacilitiesToDisplay: facilitiesLen
			};
		} else {
			return {
				numFacilitiesToDisplay: MAX_CONTAINERS_TO_DISPLAY / 2
			};
		}
	};

	const filterOutOther = (arr = []) => {
		return arr.filter((item) => item.value !== 'Other');
	};

	const handleChangeCapacity = (e) => {
		setFilterHorseCapacity(e.target.value);
		setSearchActive(true);
	};

	const renderFacilities = (data) => {
		const onsiteFacilities = data?.facilitiesOnProperty ?? [];
		const offsiteFacilities = data?.facilitiesOffSite ?? [];
		let facilities = [
			...filterOutOther(
				onsiteFacilities.map((facility) => ({
					...facility,
					bgColor: ONSITE_COLOR,
					type: ON_SITE
				}))
			),
			...filterOutOther(
				offsiteFacilities.map((facility) => ({
					...facility,
					bgColor: OFFSITE_COLOR,
					type: OFF_SITE
				}))
			)
		];
		const { numFacilitiesToDisplay } = getContainerLimitsForSearchResult(
			onsiteFacilities,
			offsiteFacilities
		);
		if (data?.facilitiesOnPropertyOther) {
			facilities = [
				...facilities,
				{
					id: 'other-on-site-facilities',
					value: `Other - ${data.facilitiesOnPropertyOther}`,
					bgColor: ONSITE_COLOR,
					type: ON_SITE
				}
			];
		}
		if (data?.facilitiesOffSiteOther) {
			facilities = [
				...facilities,
				{
					id: 'other-off-site-facilities',
					value: `Other - ${data.facilitiesOffSiteOther}`,
					bgColor: OFFSITE_COLOR,
					type: OFF_SITE
				}
			];
		}
		let onSiteIndex = -1;
		let offSiteIndex = -1;

		return facilities.map((tag) => {
			if (tag.type === ON_SITE) onSiteIndex++;
			if (tag.type === OFF_SITE) offSiteIndex++;
			return (
				<div key={onSiteIndex}>
					{((tag.type === ON_SITE && onSiteIndex) ||
						(tag.type === OFF_SITE && offSiteIndex)) <
						numFacilitiesToDisplay && (
						<div
							className={classes.resultTag}
							style={{ background: tag.bgColor }}
							key={tag.id}
						>
							{tag.value}
						</div>
					)}
				</div>
			);
		});
	};

	const renderSearchResults = (forMobile) => {
		return searchResults.map((data) => {
			return (
				<div
					className={
						forMobile ? classes.resultContentMobile : classes.resultContent
					}
					key={data.id}
				>
					<div
						className={
							forMobile ? classes.resultImage : classes.resultImageMobile
						}
					>
						<LazyImage
							imageKey={
								data.propertyPhotos
									?.filter((item) => item.businessProfileId === data.id)
									?.filter((f) => f.approved)[0]?.key
							}
						/>
					</div>
					<div
						className={classes.resultTitle}
						onClick={() => navigate(`/star-provider-profile/${data.id}`)}
					>
						{data.businessName}
					</div>
					<div
						className={classes.resultAddress}
					>{`${data.suburb}, ${data.state} ${data.postcode}`}</div>
					{renderFosterCapacity(data?.rangeOfFosterCare)}
					{data && (
						<div className={classes.resultTags}>{renderFacilities(data)}</div>
					)}
				</div>
			);
		});
	};

	const SearchResults = isLoading ? (
		<Row>
			<div className={classes.loadingDiv}>
				<LoaderSpinner status={isLoading} />
			</div>
		</Row>
	) : (
		<Row style={{ flexDirection: isSmallScreen ? 'column-reverse' : 'row' }}>
			<Col sm={12} lg={4}>
				<div className={classes.resultContainer}>
					{renderSearchResults(false)}
				</div>
			</Col>
			<Col sm={12} lg={8} style={isSmallScreen && { marginBottom: 15 }}>
				<RetirementFarmMap
					searchResults={searchResults}
					businessProfileIdFilter={businessProfileIdFilter}
					setBusinessProfileIdFilter={setBusinessProfileIdFilter}
				/>
			</Col>
		</Row>
	);

	const desktopComponent = (
		<PageContainer>
			<SEO title="STAR Provider Search" />
			<SearchField
				onInputChange={updateValue}
				title="Search by Name"
				onSearchClick={searchByInput}
				keyCheck={keyCheck}
			/>
			{selectedFilter.length > 0 && (
				<div className={classes.filters}>
					<div className={classes.filterContent}>
						{selectedFilter.map((f) => {
							return (
								<span key={f} className={classes.appliedFilter}>
									{f}{' '}
									<span
										className={classes.appliedFilterCross}
										onClick={() => removeFilter(f)}
									>
										&#10006;
									</span>
								</span>
							);
						})}
						<span
							className={classes.appliedFilter}
							style={{ cursor: 'pointer' }}
							onClick={() => removeAllFilters()}
						>
							Clear all
						</span>
					</div>
				</div>
			)}
			<div className={classes.searchFieldContainer}>
				<div
					className={classes.addFilter}
					onClick={() => setToggleFilter(!toggleFilter)}
				></div>
				<Accordion title="Add Filters" isOpen={toggleFilter} mobile>
					<div className={classes.filterContainer}>
						<div className={classes.specFilter}>
							<MultiDropDown
								dotColorsWithTexts={FACILITIES_OFFERED_COLORS}
								list={servicesOffered}
								resetThenSet={resetServiceOffer}
								headTitle="Facilities"
							/>
						</div>
						<div className={classes.specFilter}>
							<div className={classes.fieldTitle}>STAR Horse Capacity</div>
							<FormDropDown
								showBlank
								name="horseCapacity"
								id="fosterProviderSearch-horseCapacity"
								items={referenceRangeOfFosterCare}
								selectedId={filterHorseCapacity}
								onChange={handleChangeCapacity}
							/>
						</div>
					</div>
				</Accordion>
				<Row>
					<Col>
						<div className={classes.searchDetails}>
							<span className={classes.results}>Results</span>
							{searchResults.length > 0 && (
								<span
									className={classes.filterOrderBy}
									onClick={() => updateOrdering()}
								>
									Order by Name
									<span className={classes.sortIcon}>
										{orderByName === 0 && <img src={Sorting_Default_icon} />}
										{orderByName === 1 && <img src={Sorting_Down_icon} />}
										{orderByName === 2 && <img src={Sorting_Up_icon} />}
									</span>
								</span>
							)}
							<span className={classes.numberOfRecords}>
								{resultCount} {resultCount > 1 ? 'results' : 'result'} found
							</span>
							<div className={classes.clear}></div>
						</div>
					</Col>
				</Row>
				{SearchResults}
			</div>
		</PageContainer>
	);

	const mobileComponent = (
		<PageContainer>
			<SEO title="STAR Provider Search" />
			<SearchField
				onInputChange={updateValue}
				title="Search by Name"
				onSearchClick={searchByInput}
				keyCheck={keyCheck}
			/>
			{selectedFilter.length > 0 && (
				<div className={classes.filters}>
					<div className={classes.filterContent}>
						{selectedFilter.map((f) => {
							return (
								<span key={f} className={classes.appliedFilterMobile}>
									{f}{' '}
									<span
										className={classes.appliedFilterCross}
										onClick={() => removeFilter(f)}
									>
										&#10006;
									</span>
								</span>
							);
						})}
						<span
							className={classes.appliedFilterMobile}
							onClick={() => removeAllFilters()}
						>
							Clear all
						</span>
					</div>
				</div>
			)}
			<div className={classes.searchFieldContainer}>
				<div
					className={classes.addFilter}
					onClick={() => setToggleFilter(!toggleFilter)}
				></div>

				<Accordion title="Add Filters" isOpen={toggleFilter} mobile>
					<div className={classes.filterContainer}>
						<div className={classes.filterMobileSer}>
							<MultiDropDown
								dotColorsWithTexts={FACILITIES_OFFERED_COLORS}
								list={servicesOffered}
								resetThenSet={resetServiceOffer}
								headTitle="Facilities"
							/>
						</div>
						<div className={classes.filterMobileSer}>
							<div className={classes.fieldTitle}>STAR Horse Capacity</div>
							<FormDropDown
								showBlank
								name="horseCapacity"
								id="fosterProviderSearch-horseCapacity"
								items={referenceRangeOfFosterCare}
								selectedId={filterHorseCapacity}
								onChange={handleChangeCapacity}
							/>
						</div>
					</div>
				</Accordion>
				<div className={classes.searchDetails}>
					<span className={classes.results}>Results</span>
					{searchResults.length > 0 && (
						<span
							className={classes.filterOrderBy}
							onClick={() => updateOrdering()}
						>
							Order by Name
							<span className={classes.sortIcon}>
								{orderByName === 0 && <img src={Sorting_Default_icon} />}
								{orderByName === 1 && <img src={Sorting_Down_icon} />}
								{orderByName === 2 && <img src={Sorting_Up_icon} />}
							</span>
						</span>
					)}
					<span className={classes.numberOfRecords}>
						{resultCount} {resultCount > 1 ? 'results' : 'result'} found
					</span>
					<div className={classes.clear}></div>
				</div>
				{SearchResults}
			</div>
		</PageContainer>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default FosterProviderSearch;

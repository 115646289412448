import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formContainer: {
		paddingTop: 5,
		paddingBottom: 6,
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px'
	},
	passwordChange: {
		padding: '20px 18px 20px 18px'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	ProfilefieldContainer: {
		paddingTop: 15,
		paddingBottom: 10
	},
	clear: {
		clear: 'both'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 0px 17px 0px'
	},
	rightContainer: {
		fontSize: 14,
		marginTop: 20
	},

	lastSaved: {
		paddingRight: '15px'
	},
	errorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	},
	errorText: {
		color: '#db0000'
	},
	passwordInfo: {
		fontSize: 13
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		marginRight: 20,
		cursor: 'pointer'
	}
}));

export const getHorsePlacementListing = `
  query getHorsePlacementListing($horseCode: Int!) {
        getHorsePlacementListing(horseCode: $horseCode) {
            lastReviewed
            lastApproved
            reasonRetirement {
              id
              value
            }
            interestHistory {
              acknowledged
              collectDate
              contactOwner
              created
              expires
              id
              lastUpdated
              noLongerInterestedDetails
              status
              member {
                firstName
                lastName
              }
              noLongerInterested {
                value
                id
              }
            }
            retirementOtherReason
            relationshipToHorseOther
            retirementReason {
              id
              value
            }
            isKnownToWindsuck{
              id
              value
            }
            relationshipToHorse{
              id
              value
            }
            relationshipToHorseOther
            lengthOfRelationship{
              id
              value
            }
            currentOwners
            currentRetrainers
            injuries{
              id
              value
            }
            injuriesDetails
            temperament
            disciplines{
              id
              value
            }
            disciplinesOther
            riddenHistory
            otherDetails
            faceOnPhotos {
              created
              filename
              isApproved
              key
              type
            }
            leftSidePhotos {
              created
              filename
              isApproved
              key
              type
            }
            rightSidePhotos {
              created
              filename
              isApproved
              key
              type
            }
            behindFullPhotos {
              created
              filename
              isApproved
              key
              type
            }
            additionalPhotos {
              created
              filename
              isApproved
              key
              type
            }
            vetReports {
              filename
              key
            }
            isEligibleForRESET
            termsDeclaration
            registerDeclaration
            contributeRetrainingCosts
            status
            created
            lastUpdated
            reviewNotes
            reviewMessages
            lastReviewed
            eligibilityForBCTS
            ageGreaterBCTS
            behaviourBCTS
            behaviourOthersBCTS
            conformationalBCTS
            conformationalDetailsBCTS
            injuryBCTS
            otherReasonBCTS
            otherReasonDetailsBCTS
            retirementCategory
            vulnerabilityType
            behaviourOthersRetirement
            conformationalDetailsRetirement
            otherEducationDetails
            otherInjuryDetailsRetirement
            otherIllnessDetailsRetirement
            rehomingHistoryDetails
            otherDetailsRetirement
            windsucker
            unbroken
            currentInjuryOther
            previousInjuryOther
            habitsBCTS {
              id
              value
            }
            currentInjuries {
              id
              value
            }
            previousInjuries {
              id
              value
            }
            habitsRetirement {
              id
              value
            }
            injuriesRetirement {
              id
              value
            }
            illnessRetirement {
              id
              value
            }
            education {
              id
              value
            }
            reasonRetirement {
              id
              value
            }
            behalfAdmin {
              firstName
              lastName
            }
            isInjuryImpactedHorse
            isIllnessImpactedHorse
            illnesses {
              id
              value
            }
            doesHorseHasCurrentVices
            doesHorseHasPastVices
            vicesCurrent {
              id
              value
            }
            vicesPast {
              id
              value
            }
            isWillingToArrange
            illnessOther
            injuryOther
            habitsCurrentOther
            habitsPastOther
            isPastInjuryImpactedHorse
            injuriesPast {
              id
              value
            }
            illnessAndInjuriesDetails
            injuryPastOther
            isImpactSoundness
          }
        }`;

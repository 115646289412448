import { APPROVED, DECLINED, DRAFT, REVIEW } from '../../utils/constants';

export const statusListOptions = [
	{
		id: 1,
		value: DRAFT
	},
	{
		id: 2,
		value: REVIEW
	},
	{
		id: 3,
		value: APPROVED
	},
	{
		id: 4,
		value: DECLINED
	}
];

export const formTypeListOptions = [
	{
		id: 2,
		value: 'Event Debrief Report'
	},
	{
		id: 3,
		value: 'Sponsorship Outcome Report'
	}
];

export const FORM_TYPES = {
	EVENT_DEBRIEF_REPORT: 'Event Debrief Report',
	SPONSORSHIP_OUTCOME_REPORT: 'Sponsorship Outcome Report'
};

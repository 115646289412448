import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  container: {
    marginRight: 20,
    marginLeft: 20,
  },
  fieldContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 15
  },
  fieldContainerMobile: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13
  },
  field: {
    color: "grey"
  },
  value:{
    fontWeight: 600
  },
  hrLine: {
    background: "#edf5f9",
    height: 2,
    border: "none"
  }
}));

import React, { useEffect, useState } from 'react';
import AppSyncService from '../../../../graphql/AppSyncService';
import { listHorseClaims } from '../../../../graphql/custom.queries';
import { convertToMultiListData } from '../../../../utils/helpers';
import { countTotalFilteredHorseClaims } from '../../graphql/queries';
import FormReviewList from '../FormReviewList';
import {
	ColumnContent,
	ResponsiveText,
	ResultRow,
	ReviewButton
} from '../ResultComponents';
import { navigate } from 'gatsby';
import { DEFAULT_STATUS_FILTER, FORM_STATUSES } from '../../constants';
import { ReviewListKeys } from '../../../../utils/constants';

const ClaimThoroughbredFormReview = () => {
	const [claimThoroughbredForms, setClaimThoroughbredForms] = useState([]);
	const [
		totalClaimThoroughbredForms,
		setTotalClaimThoroughbredForms
	] = useState(0);
	const [selectedStatusFilters, setSelectedStatusFilters] = useState(
		DEFAULT_STATUS_FILTER
	);
	const [paginationIndex, setPaginationIndex] = useState(0);

	const fetchMoreHorseClaims = () => {
		const filterStatuses = selectedStatusFilters.map(({ label }) => label);
		AppSyncService.execute(listHorseClaims, {
			status: filterStatuses,
			paginationIndex,
			paginationSize: 50
		}).then((result) => {
			const filteredHorseClaims = result?.data?.getHorseClaims;
			if (filteredHorseClaims) {
				setClaimThoroughbredForms((prevState) => [
					...prevState,
					...filteredHorseClaims
				]);
				setPaginationIndex((prevState) => ++prevState);
			}
		});
	};

	useEffect(() => {
		const filterStatuses = selectedStatusFilters.map(({ label }) => label);
		AppSyncService.execute(listHorseClaims, {
			status: filterStatuses,
			paginationIndex: 0,
			paginationSize: 50
		}).then((result) => {
			const filteredHorseClaims = result?.data?.getHorseClaims;
			if (filteredHorseClaims) {
				setClaimThoroughbredForms(filteredHorseClaims);
				setPaginationIndex(1);
			}
		});
		AppSyncService.execute(countTotalFilteredHorseClaims, {
			filterStatuses
		}).then((result) => {
			if (result?.data?.countHorseClaims != null) {
				setTotalClaimThoroughbredForms(result.data.countHorseClaims);
			}
		});
	}, [selectedStatusFilters]);

	const Row = ({ form }) => {
		return (
			<ResultRow id={form.id} key={`claim-thoroughbred-form-${form.id}`}>
				<ColumnContent style={{ maxWidth: 200 }}>
					<ResponsiveText
						bolder
					>{`${form.member.firstName} ${form.member.lastName}`}</ResponsiveText>
				</ColumnContent>
				<ColumnContent>
					<ResponsiveText>{form.horse.name}</ResponsiveText>
				</ColumnContent>
				<ColumnContent>{form.status}</ColumnContent>
				<ReviewButton
					onClickHandler={() =>
						navigate(`/claim-thoroughbred-review/${form.id}`)
					}
				/>
			</ResultRow>
		);
	};

	return (
		<FormReviewList
			sectionTitle={`Claim Thoroughbred (${totalClaimThoroughbredForms})`}
			selectableFilters={convertToMultiListData(FORM_STATUSES, 'value')}
			selectedFilters={selectedStatusFilters}
			onFiltersChange={(value) => setSelectedStatusFilters(value)}
			results={claimThoroughbredForms}
			totalResults={totalClaimThoroughbredForms}
			fetchData={fetchMoreHorseClaims}
			sectionId={ReviewListKeys.CLAIM_THOROUGHBRED_FORMS}
			Row={Row}
			noFilterByName
		/>
	);
};

export default ClaimThoroughbredFormReview;

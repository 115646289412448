import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { Row, Col, Container, Hidden, useScreenClass } from 'react-grid-system';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import useStyles from './styles';
import TwoColLayout from '../TwoColLayout';
import SEO from '../seo';
import PrimaryButton from '../PrimaryButton';
import { useAuth } from '../../hooks/useAuth';
import LoaderSpinner from '../LoaderSpinner';
import SimpleCheckBox from '../SimpleCheckBox';
import FormTextField from '../FormTextField';
import qs from 'query-string';
import {
	INVALID_EMAIL_MESSAGE,
	MOBILE_SCREEN_SIZES,
	RegisterKeys,
	LG_TO_XL_SCREEN_SIZES
} from '../../utils/constants';
import FormToolTip from '../FormToolTip';
import { listPrimaryMemeberships } from '../../graphql/queries';
import AppSyncService from '../../graphql/AppSyncService';

// form validation rules
const schema = yup.object().shape({
	firstName: yup
		.string() //First name and lastname can have special characters
		.required('First Name is required')
		.matches(/^[a-z ,.'-]+$/i, 'Please enter valid name'),
	lastName: yup
		.string()
		.required('Last name is required')
		.matches(/^[a-z ,.'-]+$/i, 'Please enter valid name'),
	email: yup
		.string()
		.required('Email is required')
		.email(INVALID_EMAIL_MESSAGE),
	postcode: yup
		.string() //Alpahbet and numeric -
		.nullable()
		.matches(/^[0-9]{4}$/, {
			message: 'Postcode should only be numeric and 4 digits',
			excludeEmptyString: true
		}),
	primary_membership_type: yup
		.string()
		.required('Primary User Type is required'),
	password: yup
		.string()
		.required('Password is required')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Must Contain at-least 8 Characters with One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*)'
		),
	confirmPassword: yup
		.string()
		.required('Confirm Password is required')
		.oneOf([yup.ref('password'), null], 'Passwords do not match'),
	registerConditionsCheckbox: yup
		.boolean()
		.oneOf(
			[true],
			'Please agree to the Terms & Conditions in order to Register'
		)
});

const Register = ({ location }) => {
	const registerPageContentful = useStaticQuery(
		graphql`
			query registerPageContentful {
				allContentfulInfoCards(filter: { tags: { eq: "Register Page" } }) {
					nodes {
						image {
							file {
								url
							}
							title
						}
					}
				}
			}
		`
	);

	const registerImage =
		registerPageContentful?.allContentfulInfoCards?.nodes &&
		registerPageContentful?.allContentfulInfoCards?.nodes?.length > 0
			? registerPageContentful.allContentfulInfoCards.nodes[0]?.image?.file?.url
			: null;
	const classes = useStyles({
		registrationRightColumnImage: registerImage
	});
	const { signUp } = useAuth();
	const { register, control, handleSubmit, errors, clearErrors } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [postcode, setPostcode] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [isRecapchaValid, setIsRecapchaValid] = useState(false);
	const [showRecaptchError, setShowRecaptchError] = useState(false);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [registerConditionsCheckbox, setRegisterConditionsCheckbox] = useState(
		false
	);
	const [marketingCheckbox, setMarketingCheckbox] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFieldsLoading, setIsFieldsLoading] = useState(false);
	const [isRegisterDisabled, setIsRegisterDisabled] = useState(false);
	const [membershipTypes, setMembershipTypes] = useState([]);

	const fetchPrimaryUserTypes = async () => {
		setIsFieldsLoading(true);
		const result = await AppSyncService.execute(listPrimaryMemeberships);
		if (result?.data?.listPrimaryMemeberships?.length) {
			const sortedMembershipsTypes = result.data.listPrimaryMemeberships
				.map(({ id, name }) => ({
					id,
					label: name
				}))
				.sort((a, b) =>
					a.label.toUpperCase().localeCompare(b.label.toUpperCase())
				);
			setMembershipTypes([{ id: null, label: '' }, ...sortedMembershipsTypes]);
			setIsFieldsLoading(false);
		}
	};

	useEffect(() => {
		fetchPrimaryUserTypes();
	}, []);

	const registerCheckBoxData = {
		id: RegisterKeys.TERMS_AND_CONDITIONS,
		label: '',
		value: '',
		isSelected: registerConditionsCheckbox
	};
	const marketingCheckBoxData = {
		id: RegisterKeys.SUBSCRIBE_EMAIL,
		label: '',
		value: '',
		isSelected: marketingCheckbox
	};

	async function onSubmit(data) {
		if (!isRecapchaValid) {
			setShowRecaptchError(true);
			return false;
		} else {
			setShowRecaptchError(false);
		}
		setIsRegisterDisabled(true);
		setIsLoading(true);
		// Post the user to the server
		const isSaved = await saveUser(data);
		setIsLoading(false);
		if (isSaved) {
			navigate(`/verification-landing-page/${data.email}`);
		} else {
			setIsRegisterDisabled(false);
		}
	}

	const onRecatptchaSuccess = () => {
		setIsRecapchaValid(true);
		setShowRecaptchError(false);
	};

	const clearRecaptcha = () => {
		setIsRecapchaValid(false);
		setShowRecaptchError(true);
	};

	const toggleRegisterConditionsCheckbox = () => {
		clearErrors('registerConditionsCheckbox');
		setRegisterConditionsCheckbox(!registerConditionsCheckbox);
	};

	const toggleMarketingCheckbox = () => {
		setMarketingCheckbox(!marketingCheckbox);
	};

	const retrieveReferrerDetails = () => {
		const referrerDetails = qs.parse(location.search);
		return Object.keys(referrerDetails).length > 0 ? referrerDetails : null;
	};

	const saveUser = async (data) => {
		try {
			setBackendErrorMessage(null);
			/**
			 * Negative Scenarios TBD:
			 * 1. The cognito user is created and it was not created on our side, in that case, we should ask them to login and register
			 * details just like 3rd party auth like FB, Google
			 **/

			// First register the user Cognito.

			const referrerDetails = retrieveReferrerDetails();
			const inputData = {
				email: data.email,
				firstName: data.firstName,
				lastName: data.lastName,
				postcode: data.postcode == '' ? null : data.postcode,
				primaryMembershipTypeId: data.primary_membership_type,
				optInMarketing: marketingCheckbox.toString(),
				...(referrerDetails && {
					referrerSource: referrerDetails.utm_source,
					referrerMedium: referrerDetails.utm_medium,
					referrerCampaign: referrerDetails.utm_campaign
				})
			};
			await signUp(
				data.email,
				data.password,
				{
					email: data.email,
					given_name: data.firstName,
					family_name: data.lastName,
					'custom:postcode': '0000',
					'custom:primary_user_type': membershipTypes.find(
						(mt) => mt.id == data.primary_membership_type
					).label
				},
				inputData
			);
			return true;
		} catch (error) {
			if (error && error.message && error.code) {
				switch (error.code) {
					case 'UserNotConfirmedException':
						navigate(`/verification-landing-page/${data.email}`);
						break;
					default:
						setBackendErrorMessage(error.message);
						break;
				}
			} else {
				setBackendErrorMessage(
					'Unknown error while registering the user. Please contact support'
				);
			}
			return false;
		}
	};

	const screenSize = useScreenClass();
	const isMobile = MOBILE_SCREEN_SIZES.includes(screenSize);
	const isLargeAndBelowScreen = LG_TO_XL_SCREEN_SIZES.includes(screenSize);
	return (
		<>
			{isFieldsLoading ? (
				<div className={classes.fieldsLoadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<>
					<SEO title="Register" />
					<div className={classes.pushFloor}>
						<TwoColLayout>
							<TwoColLayout.Col3>
								<Container>
									<Row>
										<Col sm={3}></Col>
										<Col sm={9}>
											<form onSubmit={handleSubmit(onSubmit)}>
												<h2 className={classes.Header}>Register</h2>
												<p className={classes.description}>
													Registering allows you to access everything in the
													platform including exclusive member only benefits.
													Once registered you can update your profile to tell us
													more about your interests and thoroughbreds.
												</p>
												<p className={classes.subHeading}>
													Already have an account? <a href="/login">LOGIN</a>
												</p>

												<hr className={classes.divider}></hr>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>
														First Name*
													</label>
													<Controller
														control={control}
														name="firstName"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.FIRST_NAME}
																value={firstName}
																onChange={(e) => {
																	onChange(e.target.value);
																	setFirstName(e.target.value);
																}}
																placeholder="Enter your First Name"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.firstName?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>
														Last Name*
													</label>
													<Controller
														control={control}
														name="lastName"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.LAST_NAME}
																value={lastName}
																onChange={(e) => {
																	onChange(e.target.value);
																	setLastName(e.target.value);
																}}
																placeholder="Enter your Last Name"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.lastName?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>Email*</label>
													<Controller
														control={control}
														name="email"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.EMAIL}
																value={email}
																onChange={(e) => {
																	onChange(e.target.value);
																	setEmail(e.target.value);
																}}
																placeholder="Enter your email"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.email?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>Postcode</label>
													<Controller
														control={control}
														name="postcode"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.POSTCODE}
																value={postcode}
																onChange={(e) => {
																	onChange(e.target.value);
																	setPostcode(e.target.value);
																}}
																placeholder="Enter your Postcode"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.postcode?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>Password*</label>
													<Controller
														control={control}
														name="password"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.PASSWORD}
																value={password}
																onChange={(e) => {
																	onChange(e.target.value);
																	setPassword(e.target.value);
																}}
																fieldType="password"
																placeholder="Enter your password"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.password?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<label className={classes.formLabel}>
														Confirm Password*
													</label>
													<Controller
														control={control}
														name="confirmPassword"
														render={({ onChange }) => (
															<FormTextField
																id={RegisterKeys.CONFIRM_PASSWORD}
																value={confirmPassword}
																onChange={(e) => {
																	onChange(e.target.value);
																	setConfirmPassword(e.target.value);
																}}
																fieldType="password"
																placeholder="Confirm your password"
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.confirmPassword?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<div className={classes.tooltipdiv}>
														<label className={classes.formLabel}>
															Primary User Type*
														</label>
														<FormToolTip
															toolTipContainerStyle={{
																left: 40,
																width: 290,
																padding: 15,
																...(isMobile && { top: -105, left: -145 })
															}}
															type="questionMark"
														>
															Please select the option which best represents
															you. More changes can be made in your account.
														</FormToolTip>
													</div>
													<select
														id={RegisterKeys.PRIMARY_MEMBERSHIP_TYPE}
														name="primary_membership_type"
														placeholder="Choose the best option"
														className={classes.formInput}
														ref={register}
													>
														{membershipTypes.map((mt) => (
															<option key={mt.id} value={mt.id}>
																{mt.label}
															</option>
														))}
													</select>

													<span className={classes.errorText}>
														{errors.primary_membership_type?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<div className={classes.checkboxContainer}>
														<SimpleCheckBox
															name="registerConditionsCheckboxDummy"
															data={registerCheckBoxData}
															onClick={() => {
																toggleRegisterConditionsCheckbox;
															}}
															changeCheckboxState={
																toggleRegisterConditionsCheckbox
															}
															value={registerConditionsCheckbox}
															style={{ marginRight: 20 }}
														/>
														<input
															name="registerConditionsCheckbox"
															type="checkbox"
															onChange={toggleRegisterConditionsCheckbox}
															checked={registerConditionsCheckbox}
															ref={register}
															style={{ display: 'none' }}
														/>
														<p className={classes.description}>
															I have read and accept the OTT Community Platform{' '}
															<a href="/terms" target="_blank">
																Terms and Conditions
															</a>
															,&nbsp;
															<a
																href="/privacy-collection-statement"
																target="_blank"
															>
																Privacy Collection Statement
															</a>{' '}
															and{' '}
															<a href="/privacy" target="_blank">
																OTT Privacy Policy
															</a>{' '}
															(which explains how my personal information is
															collected, used, disclosed and otherwise handled
															by Racing Victoria).
														</p>
													</div>
													<span className={classes.errorText}>
														{errors.registerConditionsCheckbox?.message}
													</span>
												</div>
												<div className={classes.formInputs}>
													<div className={classes.checkboxContainer}>
														<SimpleCheckBox
															name="marketingCheckbox"
															data={marketingCheckBoxData}
															changeCheckboxState={toggleMarketingCheckbox}
															value={marketingCheckbox}
															style={{ marginRight: 20 }}
														/>
														<p className={classes.description}>
															Yes! Please sign me up to receive communications
															about the OTT community (including news and
															events). I understand I can opt-out at any time by
															using the relevant unsubscribe feature which comes
															with the message or by opting out by following the
															process set out in the{' '}
															<a href="/privacy" target="_blank">
																OTT Privacy Policy
															</a>
															.
														</p>
													</div>
												</div>
												<div
													className={classes.formInputs}
													style={
														isLargeAndBelowScreen
															? {
																	transform: 'scale(0.77)',
																	transformOrigin: '0 0'
															  }
															: {}
													}
												>
													<ReCAPTCHA
														sitekey={process.env.CAPTCHA_SITEKEY}
														onChange={(e) => {
															onRecatptchaSuccess(e);
														}}
														onExpired={clearRecaptcha}
														size={'normal'}
														ref={register}
													/>
												</div>
												{showRecaptchError && (
													<div
														className={classes.errorText}
													>{`Captcha response required`}</div>
												)}
												{backendErrorMessage && (
													<div className={classes.errorText}>
														{backendErrorMessage}
													</div>
												)}
												{isLoading && (
													<div className={classes.loadingDiv}>
														<LoaderSpinner status={true} />
													</div>
												)}
												<PrimaryButton
													id={RegisterKeys.REGISTER}
													disabled={isRegisterDisabled}
												>
													Register
												</PrimaryButton>
											</form>
										</Col>
									</Row>
								</Container>
							</TwoColLayout.Col3>
							<Hidden xs sm>
								<TwoColLayout.Col4>
									<div className={classes.imageDisplay}></div>
								</TwoColLayout.Col4>
							</Hidden>
						</TwoColLayout>
					</div>
				</>
			)}
		</>
	);
};
export default Register;

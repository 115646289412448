import React from 'react';
import FormSection from '../../../FormComponents/FormSection';
import TextInputField from '../../../FormComponents/TextInputField';
import SelectInputField from '../../../FormComponents/SelectInputField';
import MultiSelectInputField from '../../../FormComponents/MultiSelectInputField';
import ToggleWithMultiSelect from '../../../FormComponents/ToggleWithMultiSelect';
import FormFieldWrapper from '../../../FormComponents/FormFieldWrapper';
import ResizingTextArea from '../../../ResizingTextArea';
import { Col, Row } from 'react-grid-system';
import { PAGE_ID } from '../../constants';
import { isOptionOther } from '../../../../utils/helpers';
import useStyles from './styles';
import ProfilePhoto from '../../../ProfilePhoto';
import HorseDetailVisibilitySelect from '../../../HorseDetailVisibilitySelect';

const OTTInfoForm = ({
	horseData,
	formState,
	updateFormState,
	lookUpData,
	cropImage,
	deleteImage,
	setActivePhoto,
	imageUploadError,
	getImage
}) => {
	const classes = useStyles();
	const {
		profileImage,
		knownAsName,
		purpose,
		otherPurpose,
		disciplines,
		otherDisciplines,
		personalityInfo,
		happinessInfo,
		vices,
		otherVices,
		injuriesPresent,
		injuryTypes,
		otherInjuryTypes,
		illnessesPresent,
		illnessTypes,
		otherIllnessTypes
	} = formState;
	const {
		purposeOptions,
		disciplineOptions,
		viceOptions,
		injuryOptions,
		illnessOptions,
		visibilityOptions
	} = lookUpData;
	const isPurposeOther = isOptionOther(purposeOptions, purpose.value);

	return (
		<FormSection sectionTitle="OTT Information">
			<Col className={classes.formSectionContent}>
				<Row>
					<FormFieldWrapper
						fieldLabel={`Please upload one or more photos of ${horseData?.name} (jpg, png)`}
						visibilitySelector={
							<HorseDetailVisibilitySelect
								value={profileImage.visibility}
								options={visibilityOptions}
								onChange={(e) => {
									const newVisibilityValue = e.target.value;
									updateFormState((prevState) => ({
										...prevState,
										profileImage: {
											...prevState.profileImage,
											visibility: newVisibilityValue
										}
									}));
								}}
							/>
						}
					>
						<ProfilePhoto
							id={`${PAGE_ID}-profile-photo-upload`}
							formats={['png', 'jpeg']}
							labelName="Upload Photo/s"
							sizeLimitMB={5}
							numberOfImages={10}
							success={cropImage}
							data={profileImage.value}
							removeImage={deleteImage}
							setActivePhoto={setActivePhoto}
							error={imageUploadError}
							getImage={getImage}
							canEdit
						/>
					</FormFieldWrapper>
					<TextInputField
						id={`${PAGE_ID}-known-name-textfield`}
						label={`Does ${horseData?.name} have a paddock/ known as name?`}
						value={knownAsName.value}
						onChange={(event) => {
							const newKnownAsName = event.target.value;
							updateFormState((prevState) => ({
								...prevState,
								knownAsName: {
									...prevState.knownAsName,
									value: newKnownAsName,
									validationError: ''
								}
							}));
						}}
						visibilitySelector={
							<HorseDetailVisibilitySelect
								value={knownAsName.visibility}
								options={visibilityOptions}
								onChange={(e) => {
									const newVisibilityValue = e.target.value;
									updateFormState((prevState) => ({
										...prevState,
										knownAsName: {
											...prevState.knownAsName,
											visibility: newVisibilityValue
										}
									}));
								}}
							/>
						}
					/>
					<SelectInputField
						id={`${PAGE_ID}-horse-purpose-select`}
						label={`
            Which best explains ${horseData?.name}'s current purpose?`}
						options={purposeOptions}
						value={purpose.value}
						onChange={(event) => {
							const newHorsePurpose = event.target.value;
							updateFormState((prevState) => ({
								...prevState,
								purpose: {
									...prevState.purpose,
									value: newHorsePurpose,
									validationError: ''
								},
								otherPurpose: { value: '', validationError: '' }
							}));
						}}
						visibilitySelector={
							<HorseDetailVisibilitySelect
								value={purpose.visibility}
								options={visibilityOptions}
								onChange={(e) => {
									const newVisibilityValue = e.target.value;
									updateFormState((prevState) => ({
										...prevState,
										purpose: {
											...prevState.purpose,
											visibility: newVisibilityValue
										}
									}));
								}}
							/>
						}
					/>
				</Row>
				{isPurposeOther && (
					<Row>
						<TextInputField
							id={`${PAGE_ID}-other-purpose-textfield`}
							label="Other purpose"
							value={otherPurpose.value}
							onChange={(event) => {
								const newValue = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									otherPurpose: { value: newValue, validationError: '' }
								}));
							}}
						/>
					</Row>
				)}
				<MultiSelectInputField
					idPrefix={`${PAGE_ID}-disciplines`}
					fieldLabel={`What are ${horseData?.name}'s specialty disciplines?`}
					selectedIds={disciplines.value}
					options={disciplineOptions}
					onSelectChange={(newDisciplines) =>
						updateFormState((prevState) => ({
							...prevState,
							disciplines: {
								...prevState.disciplines,
								value: newDisciplines,
								validationError: ''
							},
							...(!newDisciplines ||
								(!newDisciplines.find(({ label }) => label === 'Other') && {
									otherDisciplines: {
										value: ''
									}
								}))
						}))
					}
					otherValue={otherDisciplines.value}
					onOtherChange={(newOtherValue) => {
						updateFormState((prevState) => ({
							...prevState,
							otherDisciplines: {
								value: newOtherValue
							}
						}));
					}}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={disciplines.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									disciplines: {
										...prevState.disciplines,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				/>
				<Row>
					<Col>
						<ResizingTextArea
							title={`Tell us about ${horseData?.name}'s personality`}
							id={`${PAGE_ID}-personality-info-textarea`}
							onChange={(event) => {
								const newPersonalityInfo = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									personalityInfo: {
										...prevState.personalityInfo,
										value: newPersonalityInfo
									}
								}));
							}}
							maxChars={500}
							value={personalityInfo.value}
							styles={{ paddingTop: 0 }}
							visibilitySelector={
								<HorseDetailVisibilitySelect
									value={personalityInfo.visibility}
									options={visibilityOptions}
									onChange={(e) => {
										const newVisibilityValue = e.target.value;
										updateFormState((prevState) => ({
											...prevState,
											personalityInfo: {
												...prevState.personalityInfo,
												visibility: newVisibilityValue
											}
										}));
									}}
								/>
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<ResizingTextArea
							title={`What makes ${horseData?.name} happy?`}
							id={`${PAGE_ID}-happiness-info-textarea`}
							onChange={(event) => {
								const newHappinessInfo = event.target.value;
								updateFormState((prevState) => ({
									...prevState,
									happinessInfo: {
										...prevState.happinessInfo,
										value: newHappinessInfo
									}
								}));
							}}
							maxChars={500}
							value={happinessInfo.value}
							styles={{ paddingTop: 0 }}
							visibilitySelector={
								<HorseDetailVisibilitySelect
									value={happinessInfo.visibility}
									options={visibilityOptions}
									onChange={(e) => {
										const newVisibilityValue = e.target.value;
										updateFormState((prevState) => ({
											...prevState,
											happinessInfo: {
												...prevState.happinessInfo,
												visibility: newVisibilityValue
											}
										}));
									}}
								/>
							}
						/>
					</Col>
				</Row>
				<MultiSelectInputField
					idPrefix={`${PAGE_ID}-vices`}
					fieldLabel={`Select vices or bad habits that ${horseData?.name} has`}
					selectedIds={vices.value}
					options={viceOptions}
					onSelectChange={(newVices) =>
						updateFormState((prevState) => ({
							...prevState,
							vices: {
								...prevState.vices,
								value: newVices,
								validationError: ''
							},
							...(!newVices ||
								(!newVices.find(({ label }) => label === 'Other') && {
									otherVices: {
										value: ''
									}
								}))
						}))
					}
					otherValue={otherVices.value}
					onOtherChange={(newOtherValue) => {
						updateFormState((prevState) => ({
							...prevState,
							otherVices: {
								value: newOtherValue
							}
						}));
					}}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							disabled
							value={vices.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									vices: {
										...prevState.vices,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				/>
				<ToggleWithMultiSelect
					toggleLabel={`Has ${horseData?.name} had any injuries, previously or currently?`}
					toggleChangeHandler={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							injuriesPresent: {
								...prevState.injuriesPresent,
								value: newValue
							},
							...(!newValue && {
								injuryTypes: { value: [] },
								otherInjuryTypes: { value: '' }
							})
						}))
					}
					showMultiSelect={!!injuriesPresent.value}
					multiSelectId={`${PAGE_ID}-injury-types`}
					multiSelectLabel="Please select injury types"
					multiSelectOptions={injuryOptions}
					multiSelectSelectedOptions={injuryTypes.value}
					multiSelectChangeHandler={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							injuryTypes: {
								value: newValue
							},
							...((!newValue ||
								!newValue.find(({ label }) => label === 'Other')) && {
								otherInjuryTypes: { value: '' }
							})
						}))
					}
					otherValue={otherInjuryTypes.value}
					onOtherChange={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							otherInjuryTypes: { value: newValue }
						}))
					}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							disabled
							value={injuriesPresent.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									injuriesPresent: {
										...prevState.injuriesPresent,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				/>
				<ToggleWithMultiSelect
					toggleLabel={`Has ${horseData?.name} had any illnesses, previously or currently?`}
					toggleChangeHandler={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							illnessesPresent: {
								...prevState.illnessesPresent,
								value: newValue
							},
							...(!newValue && {
								illnessTypes: { value: [] },
								otherIllnessTypes: { value: '' }
							})
						}))
					}
					showMultiSelect={!!illnessesPresent.value}
					multiSelectId={`${PAGE_ID}-illness-types`}
					multiSelectLabel="Please select illness types"
					multiSelectOptions={illnessOptions}
					multiSelectSelectedOptions={illnessTypes.value}
					multiSelectChangeHandler={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							illnessTypes: {
								value: newValue
							},
							...((!newValue ||
								!newValue.find(({ label }) => label === 'Other')) && {
								otherIllnessTypes: { value: '' }
							})
						}))
					}
					otherValue={otherIllnessTypes.value}
					onOtherChange={(newValue) =>
						updateFormState((prevState) => ({
							...prevState,
							otherIllnessTypes: { value: newValue }
						}))
					}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							disabled
							value={illnessesPresent.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									illnessesPresent: {
										...prevState.illnessesPresent,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				/>
			</Col>
		</FormSection>
	);
};
export default OTTInfoForm;

import React, { useEffect, useState } from 'react';
import useStyles from './styles.js';
import { Row, Col } from 'react-grid-system';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import { useParams } from '@reach/router';
import {
	getTransitionAdminInfo,
	getTransitionGuide,
	saveAdminTransitionStatus
} from '../../graphql/custom.queries.js';
import AppSyncService from '../../graphql/AppSyncService.js';
import MemberProfileView from '../MemberProfileView/index.js';
import LoaderSpinner from '../LoaderSpinner/index.js';
import FormLabelField from '../FormLabelField/index.js';
import SelectInputField from '../FormComponents/SelectInputField/index.js';
import AdminReviewSection from '../AdminReviewComponents/AdminReviewSection/index.js';
import SectionRow from '../SectionRow/index.js';
import { BLANK_OPTION, REVIEW } from '../../utils/constants';
import FormTextArea from '../FormTextArea/index.js';
import { isOptionOther } from '../../utils/helpers/index.js';
import { graphql, useStaticQuery } from 'gatsby';
import { useAuth } from '../../hooks/useAuth';

const PAGE_ID = 'Transition-Guide-Admin-Form';
const REVIEWED = 'Reviewed';

const TransitionGuideAdminForm = () => {
	const classes = useStyles();
	const { id } = useParams();
	const { currentUser } = useAuth();
	const [memberProfileData, setMemberProfileData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [horseName, setHorseName] = useState('');
	const [concerns, setConcerns] = useState('');
	const [adminMemberLookupValues, setAdminMemberLookupValues] = useState([]);
	const [adminStatusLookupValues, setAdminStatusLookupValues] = useState([]);
	const [adminOutcomeLookupValues, setAdminOutcomeLookupValues] = useState([]);
	const [actioning, setActioning] = useState(null);
	const [adminStatus, setAdminStatus] = useState(null);
	const [adminOutcome, setAdminOutcome] = useState(null);
	const [adminNotes, setAdminNotes] = useState('');
	const [adminNotesOther, setAdminNotesOther] = useState('');
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [formError, setFormError] = useState('');
	const [reviewStatus, setReviewStatus] = useState('');
	const [transitionGuideSteps, setTransitionGuideSteps] = useState(null);

	const { allContentfulTransitionStep } = useStaticQuery(graphql`
		query transitionStepsAdmin {
			allContentfulTransitionStep {
				nodes {
					stepNumber
					title
					options {
						optionId
						link
						name
					}
				}
			}
		}
	`);

	useEffect(() => {
		if (id) {
			let formStatus = null;
			AppSyncService.execute(getTransitionGuide, { id: id }).then((result) => {
				if (result?.data?.getTransitionGuide) {
					setMemberProfileData(result?.data?.getTransitionGuide?.member);
					setHorseName(result?.data?.getTransitionGuide?.horseName);
					setConcerns(result?.data?.getTransitionGuide?.concerns);
					setReviewStatus(result?.data?.getTransitionGuide?.status);
					formStatus = result?.data?.getTransitionGuide?.status;
					setTransitionGuideSteps({
						1: result.data.getTransitionGuide.step1,
						2: result.data.getTransitionGuide.step2,
						3: result.data.getTransitionGuide.step3,
						4: result.data.getTransitionGuide.step4,
						5: result.data.getTransitionGuide.step5,
						6: result.data.getTransitionGuide.step6,
						7: result.data.getTransitionGuide.step7,
						8: result.data.getTransitionGuide.step8
					});
				}
				AppSyncService.execute(getTransitionAdminInfo, { id: id }).then(
					(result) => {
						if (result?.data) {
							const adminMemberLookupValues = {
								actioningOptions: [BLANK_OPTION].concat(
									result.data.getAdminMembers?.map(
										({ id, externalId, firstName, lastName }) => ({
											id,
											value: `${firstName} ${lastName}`,
											externalId
										})
									)
								)
							};
							setAdminMemberLookupValues(adminMemberLookupValues);
							setAdminStatusLookupValues({
								statusOptions: [BLANK_OPTION].concat(
									result.data.listAdminActionStauses
								)
							});
							setAdminOutcomeLookupValues({
								outcomeOptions: [BLANK_OPTION].concat(
									result.data.listTransitionGuideOutcomes
								)
							});
							if (result?.data?.getTransitionGuideAdminInfo) {
								setActioning(
									result.data.getTransitionGuideAdminInfo.actioningMember ??
										(formStatus === REVIEW
											? adminMemberLookupValues.actioningOptions.find(
													(user) => user.externalId == currentUser?.UserId
											  )?.id
											: 0)
								);
								setAdminNotes(
									result.data.getTransitionGuideAdminInfo.notes || ''
								);
								setAdminStatus(
									result.data.getTransitionGuideAdminInfo.status || 0
								);
								setAdminOutcome(
									result.data.getTransitionGuideAdminInfo.outcome || 0
								);
								setAdminNotesOther(
									result.data.getTransitionGuideAdminInfo.notesOther || ''
								);
							}
						}
					}
				);
				setIsLoading(false);
			});
		}
	}, [id]);

	const updateAdminAction = () => {
		const input = {
			formId: +id,
			formStatus: 'Review',
			actioningMember: +actioning || null,
			outcome: +adminOutcome || null,
			status: +adminStatus || null,
			notes: adminNotes || null,
			notesOther: adminNotesOther || null
		};
		AppSyncService.execute(saveAdminTransitionStatus, { input }).then(() => {
			setIsAdminActionUpdated(false);
		});
	};

	const completeForm = (status) => {
		if (+actioning === 0 || +adminStatus === 0 || +adminOutcome === 0) {
			setFormError('Please complete all required fields');
		} else {
			const input = {
				formId: +id,
				formStatus: status,
				actioningMember: +actioning || null,
				outcome: +adminOutcome || null,
				status: +adminStatus || null,
				notes: adminNotes || null,
				notesOther: adminNotesOther || null
			};
			AppSyncService.execute(saveAdminTransitionStatus, { input }).then(() => {
				setIsAdminActionUpdated(false);
				setReviewStatus(REVIEWED);
			});
		}
	};

	const TransitionAdminActions = () => {
		return (
			<>
				<AdminReviewSection
					title="Additional Notes (Admin Only)"
					darkContentBackground
					nogutter
				>
					<Col>
						<SectionRow>
							<SelectInputField
								id={`${PAGE_ID}-actioning-select-input`}
								label="Actioning"
								value={actioning}
								options={adminMemberLookupValues.actioningOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setActioning(event.target.value);
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
							<SelectInputField
								id={`${PAGE_ID}-status-select-input`}
								label="Admin Status"
								value={adminStatus}
								options={adminStatusLookupValues.statusOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setAdminStatus(event.target.value);
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
							<SelectInputField
								id={`${PAGE_ID}-outcome-select-input`}
								label="Outcome"
								value={adminOutcome}
								options={adminOutcomeLookupValues.outcomeOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setAdminOutcome(event.target.value);
									setAdminNotesOther('');
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
						</SectionRow>
						{adminOutcomeLookupValues &&
							isOptionOther(
								adminOutcomeLookupValues.outcomeOptions,
								adminOutcome
							) && (
								<SectionRow>
									<Col className={classes.notesContainer}>
										<div className={classes.fieldTitle}>Other</div>
										<FormTextArea
											id={`${PAGE_ID}-notes-input-other`}
											key={`${PAGE_ID}-notes-input-other`}
											rows="1"
											value={adminNotesOther}
											onChange={(e) => {
												setIsAdminActionUpdated(true);
												setAdminNotesOther(e.target.value);
											}}
											disabled={reviewStatus === REVIEWED}
										/>
									</Col>
								</SectionRow>
							)}
						<SectionRow>
							<Col className={classes.notesContainer}>
								<div className={classes.fieldTitle}>Notes</div>
								<FormTextArea
									id={`${PAGE_ID}-notes-input`}
									key={`${PAGE_ID}-notes-input`}
									value={adminNotes}
									onChange={(e) => {
										setIsAdminActionUpdated(true);
										setAdminNotes(e.target.value);
									}}
									disabled={reviewStatus === REVIEWED}
								/>
							</Col>
						</SectionRow>
					</Col>
					<Row>
						<Col>
							<div className={classes.saveRequest}>
								<span className={classes.saveContainer}>
									<input
										type="button"
										value={
											isAdminActionUpdated && reviewStatus !== REVIEWED
												? 'Save Admin Details'
												: 'Saved'
										}
										className={
											isAdminActionUpdated && reviewStatus !== REVIEWED
												? classes.primaryFormButton
												: classes.disabledFormButton
										}
										onClick={() => updateAdminAction()}
									/>
								</span>
								{isLoading && (
									<span style={{ float: 'right', marginTop: 10 }}>
										<LoaderSpinner
											status={true}
											height="22"
											color="cadetblue"
										/>
									</span>
								)}
							</div>
						</Col>
					</Row>
					<hr className={classes.rule} />
					<Row nogutter style={{ borderBottom: '1px solid #b2b2b2' }}>
						<Col>
							{formError && (
								<span className={classes.errorMessage}>{formError}</span>
							)}
						</Col>
						<Col>
							<div className={classes.saveRequest}>
								<span className={classes.saveContainer}>
									<div
										className={classes.saveRequest}
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<span>
											<input
												type="button"
												value={
													reviewStatus === REVIEWED ? 'Completed' : 'Complete'
												}
												className={
													reviewStatus === REVIEWED
														? classes.disabledFormButtonComplete
														: classes.primaryFormButtonComplete
												}
												onClick={() => completeForm(REVIEWED)}
											/>
										</span>
									</div>
								</span>
							</div>
						</Col>
					</Row>
				</AdminReviewSection>
			</>
		);
	};

	const TransitionHorseDetails = ({ horseName, concerns }) => {
		return (
			<>
				<HeaderBanner
					title="Transition Guide Responses"
					inline
					type="primary"
					styles={{ marginBottom: '0px', marginTop: '0px' }}
				/>
				<div className={classes.container}>
					{transitionGuideSteps &&
						allContentfulTransitionStep &&
						Object.keys(transitionGuideSteps).map((key) => {
							let step = allContentfulTransitionStep?.nodes?.find(
								(item) => +item.stepNumber === +key
							);
							let answer = step?.options.find(
								(option) => +option.optionId === +transitionGuideSteps[key]
							)?.name;
							return (
								<Row key={key}>
									<Col xs={12}>
										{transitionGuideSteps[key] && (
											<FormLabelField title={step?.title} value={answer} />
										)}
									</Col>
								</Row>
							);
						})}
				</div>{' '}
				{(horseName || concerns) && (
					<>
						<HeaderBanner
							title="Thoroughbred Data"
							inline
							type="primary"
							styles={{ marginBottom: '0px', marginTop: '0px' }}
						/>
						<div className={classes.container}>
							<Row>
								<Col xs={12} lg={6}>
									{horseName && (
										<FormLabelField
											title="Horse name/breeding"
											value={horseName}
										/>
									)}
								</Col>
								<Col xs={12} lg={6}>
									{concerns && (
										<FormLabelField
											title="Details of the horse’s history such as injury, illness or behaviour concerns"
											value={concerns}
										/>
									)}
								</Col>
							</Row>
						</div>
					</>
				)}
			</>
		);
	};

	return (
		<PageContainer className={classes.container}>
			<SEO title="Transistion Guide Admin Form" />
			<HeaderBanner
				title="Transistion Guide Admin Form"
				styles={{ marginBottom: '0px' }}
				rightTitle={`Status: ${reviewStatus}`}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{memberProfileData && (
					<MemberProfileView
						memberProfileData={memberProfileData}
						isThoroughbredRegistration={true}
					/>
				)}
				{horseName && (
					<TransitionHorseDetails horseName={horseName} concerns={concerns} />
				)}
				{TransitionAdminActions()}
			</div>
		</PageContainer>
	);
};

export default TransitionGuideAdminForm;

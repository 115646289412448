import { createUseStyles } from 'react-jss';

export default createUseStyles({
	explanationContentContainer: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 13,
		minWidth: 250
	},
	baseImageStyles: {
		marginRight: 5
	}
});

export const getEventDropdowns = `
query getEventDropdowns {
    listEventDisciplines {
      id
      value
    }
    listEventOrganisers {
      id
      value
    }
	listEventCancellationReasons {
	  id
	  value
	}
  }
  
`;

export const eventOrganiserUploadImage = `
mutation saveEventOrganiserImages(
  $input: EventOrganiserImageInput
) {
	saveEventOrganiserImages(
		input: $input
	) {
		id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
	}
}`;

export const deleteEventOrganiserImage = `
mutation deleteEventOrganiserImage($key: String!) {
	deleteEventOrganiserImage(key: $key) 
}
`;

export const saveEvent = `
mutation saveEvent($input: EventInput!) {
	saveEvent(input: $input){
    id
		success
		error {
			errorCode
			errorDetails
			errorMessage
		}
  }
}
`;

export const deleteEvent = `
mutation deleteEvent($eventId: Int!){
	deleteEvent(eventId: $eventId) 
}
`;

export const cancelExistingEvent = `
mutation cancelEvent($input: CancelEventInput){
	cancelEvent(input: $input){
		success
	}
}
`;

export const saveEventResults = /* GraphQL */ `
	mutation saveEventResults($input: [EventResultInput]) {
		saveEventResults(input: $input) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

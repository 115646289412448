import React from 'react';
import MessageBar from '../../../MessageBar';
import useStyles from './styles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import NewTabLink from '../../../NewTabLink';

const MoreInformationSection = ({ moreInformation }) => {
	const classes = useStyles();
	const richTextOptions = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<p className={classes.moreInfoParagraph}>{children}</p>
			),
			[INLINES.HYPERLINK]: (node, children) => (
				<NewTabLink url={node.data.uri}>{children}</NewTabLink>
			)
		}
	};
	return (
		<MessageBar className={classes.messageBar}>
			{renderRichText(moreInformation, richTextOptions)}
		</MessageBar>
	);
};

export default MoreInformationSection;

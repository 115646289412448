import React from 'react';
import classnames from 'classnames';
import useStyles from './styles';

const StepOption = ({
	id,
	children,
	onClick,
	isFilled,
	isOutcome,
	isDisabled
}) => {
	const classes = useStyles();
	return (
		<button
			id={id}
			className={classnames({
				[classes.stepOption]: true,
				[classes.filled]: isFilled,
				[classes.outcomeButton]: isOutcome,
				[classes.disabled]: isDisabled
			})}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default StepOption;

import React from 'react';
import { JPEG, MP4, PNG } from '../../utils/constants';
import useStyles from './styles';

const FullArticleEmbeddedAsset = ({
	contentType,
	contentSrc,
	mobile,
	videoStyle,
	imageStyle
}) => {
	const classes = useStyles();

	const renderAsset = () => {
		if (mobile) {
			switch (contentType) {
				case JPEG:
				case PNG:
					return (
						<img
							src={contentSrc}
							style={imageStyle}
							className={classes.imageMobile}
						></img>
					);
				case MP4:
					return (
						<video
							className={classes.videoMobile}
							style={videoStyle}
							controls
							muted
							autoPlay
							preload="metadata"
						>
							<source src={contentSrc} type={contentType} />
							Your browser does not support the video tag.
						</video>
					);
				default:
					return <></>;
			}
		} else {
			switch (contentType) {
				case JPEG:
				case PNG:
					return (
						<img
							src={contentSrc}
							style={imageStyle}
							className={classes.image}
						></img>
					);
				case MP4:
					return (
						<video
							className={classes.video}
							style={videoStyle}
							controls
							muted
							autoPlay
							preload="metadata"
						>
							<source src={contentSrc} type={contentType} />
							Your browser does not support the video tag.
						</video>
					);
				default:
					return <></>;
			}
		}
	};

	return renderAsset();
};

export default FullArticleEmbeddedAsset;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import { navigate } from '@reach/router';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import { useScreenClass } from 'react-grid-system';
import { HORSESUGGESTION, LOGO } from '../AddEvent/constants';
import { Paper, TableSortLabel } from '@material-ui/core';
import HorseAvatar from '../HorseAvatar';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto'
	},
	table: {
		minWidth: 650,
		border: '1px solid #b3bcc1'
	},
	selectTableCell: {
		width: 60
	},
	tableHeader: {
		width: 130,
		height: 40,
		paddingTop: 1,
		fontWeight: 600
	},
	tableCell: {
		width: 130,
		maxWidth: 130,
		height: 40,
		paddingTop: 1,
		paddingBottom: 8,
		overflowWrap: 'break-word'
	},
	input: {
		width: 130,
		height: 40
	},
	columnHorseCode: {
		width: 180,
		maxWidth: 180,
		paddingTop: 1,
		paddingBottom: 8,
		overflowWrap: 'break-word',
		cursor: 'pointer',
		color: '#006da6',
		fontWeight: 600
	},
	headerHorseCode: {
		width: 180,
		maxWidth: 180,
		paddingTop: 9,
		fontWeight: 600,
		paddingBottom: 0
	},
	headerLogo: {
		height: 40,
		paddingTop: 1,
		fontWeight: 600
	},
	columnLogo: {
		paddingTop: 5,
		paddingBottom: 0
	},
	profilePic: {
		cursor: 'pointer'
	}
}));

const EventResultsTable = ({ columns, data, id, sortOrder, requestSort }) => {
	const classes = useStyles();
	// const screenClassForRender = useScreenClass();
	// let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);

	const CustomTableCell = ({ row, column }) => {
		if (column?.type === HORSESUGGESTION) {
			return (
				<TableCell
					key={column.accessor}
					align="left"
					className={classes.columnHorseCode}
					onClick={() => {
						if (row[column.labelAccessor].horseCode)
							navigate(`/horse-profile/${row[column.labelAccessor].horseCode}`);
					}}
				>
					{row[column.labelAccessor].name}
				</TableCell>
			);
		} else if (column?.type === LOGO) {
			return (
				<TableCell
					key={column.accessor}
					align="left"
					className={classes.columnLogo}
				>
					<HorseAvatar
						avatarImageKey={row[column.labelAccessor]}
						idPrefix={`event-results-logo-${row['horse'].horseCode}`}
						style={{ width: 45, height: 45 }}
						onClick={() => {
							if (row['horse'].horseCode)
								navigate(`/horse-profile/${row['horse'].horseCode}`);
						}}
						className={classes.profilePic}
					/>
				</TableCell>
			);
		} else {
			return (
				<TableCell
					key={column.accessor}
					align="left"
					className={classes.tableCell}
				>
					{row[column.labelAccessor]}
				</TableCell>
			);
		}
	};

	return (
		<Paper className={classes.root}>
			<Table className={classes.table} id={id} aria-label="caption table">
				<TableHead>
					<TableRow style={{ backgroundColor: '#f5f5f5' }}>
						{columns &&
							columns.map((column) => (
								<TableCell
									key={column.accessor}
									className={
										column?.type === HORSESUGGESTION
											? classes.headerHorseCode
											: column?.type === LOGO
											? classes.headerLogo
											: classes.tableHeader
									}
									align="left"
								>
									{column.accessor === 'result' ? (
										<TableSortLabel
											active={true}
											direction={sortOrder}
											onClick={() => requestSort(column.accessor)}
										>
											{column.Header}
										</TableSortLabel>
									) : (
										column.Header
									)}
								</TableCell>
							))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data &&
						data.length > 0 &&
						data.map((row) => (
							<TableRow key={row.id}>
								{columns &&
									columns.map((column) => (
										<CustomTableCell
											key={column.accessor}
											row={row}
											column={column}
										/>
									))}
							</TableRow>
						))}
				</TableBody>
			</Table>
		</Paper>
	);
};

EventResultsTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	id: PropTypes.string.isRequired
};

export default EventResultsTable;

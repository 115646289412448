import React from 'react';
import Select from 'react-select';

const customStyles = {
	container: (provided, state) => {
		const position = 'relative';
		const boxSizing = 'border-box';
		if (state.selectProps.border) {
			const border = `1px solid ${state.selectProps.border}`;
			const borderRadius = `4px`;
			return { ...provided, border, borderRadius, position, boxSizing };
		} else {
			return { ...provided, position, boxSizing };
		}
	},
	multiValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		const color = '#fff';
		const backgroundColor = '#006da6';
		return { ...provided, opacity, transition, color, backgroundColor };
	},
	multiValueLabel: (provided) => {
		const color = '#fff';
		return { ...provided, color };
	}
};

const FormMultiSelectList = ({
	listOptions,
	selectedIds,
	onSelectChange,
	name,
	border,
	id = '',
	canEdit = true
}) => {
	return (
		<Select
			id={id}
			isMulti
			name={name}
			border={border}
			options={listOptions?.map((option) => ({
				...option,
				isDisabled: !canEdit
			}))}
			value={selectedIds}
			styles={customStyles}
			width="100%"
			onChange={(value) => onSelectChange(value)}
		/>
	);
};

export default FormMultiSelectList;

import { DashboardKeys } from '../../utils/constants';

export const HOME = 'home';
export const MYSTABLE = 'my-stable';
export const FOLLOWING = 'following';
export const DASHBOARD_TAB_OPTIONS = [
	{
		id: DashboardKeys.FOLLOWING,
		navigation: FOLLOWING,
		title: 'Following',
		show: true
	},
	{
		id: DashboardKeys.MY_STABLE,
		navigation: MYSTABLE,
		title: 'My Stable',
		show: true
	}
];

export const EVENT_REQUEST_REPORT_STRING = 'Event Report Request Id';

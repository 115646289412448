import React, { useEffect, useState } from 'react';
import FormTextField from '../FormTextField';
import List from '../List';
import { API } from 'aws-amplify';
import { ProfileKeys } from '../../utils/constants';

const AddressAutoSuggestion = ({
	value,
	onChange,
	onAddressSelect,
	formTextFieldStyle,
	disableAutoSuggestion = false,
	canEdit = true,
	id = null,
	...rest
}) => {
	const [activeSuggestion, setActiveSuggestion] = useState(0);
	const [userInput, setUserInput] = useState(null);
	const [showSuggestions, setShowSuggestions] = useState(false);

	const [timer, setTimer] = useState(null);
	const [addressSuggestions, setAddressSuggestions] = useState([]);

	useEffect(() => {
		setUserInput(value);
	}, [value]);

	useEffect(() => {
		if (disableAutoSuggestion == false) {
			clearTimeout(timer);
			if (userInput != '' && showSuggestions == true) {
				setTimer(
					setTimeout(function () {
						API.get(
							'AuAddressAutoAPI',
							`&key=${process.env.GATSBY_ADDRESSFINDER_KEY}&q=${userInput}`
						)
							.then((data) => {
								setAddressSuggestions(data.completions);
							})
							.catch(() => {
								setAddressSuggestions([]);
							});
					}, 1000)
				);
			}
		}
	}, [userInput]);

	const getAddressDetails = (id) => {
		if (id == null) {
			return;
		}
		return API.get(
			'AuAddressMetaAPI',
			`&key=${process.env.GATSBY_ADDRESSFINDER_KEY}&id=${id}`
		)
			.then((data) => {
				if (data) {
					onAddressSelect({
						Line1: data.address_line_1,
						Line2: data.address_line_2,
						Suburb: data.locality_name,
						Postcode: data.postcode,
						State: data.state_territory,
						Country: 'Australia'
					});
				}
				return data;
			})
			.catch(() => {
				return null;
			});
	};

	const onKeyDown = (e) => {
		// User pressed the enter key
		if (e.keyCode === 13) {
			setUserInput(addressSuggestions[activeSuggestion]);
			setActiveSuggestion(0);
			setShowSuggestions(0);
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestion === 0) {
				return;
			}

			setActiveSuggestion(activeSuggestion - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === addressSuggestions.length) {
				return;
			}

			setActiveSuggestion(activeSuggestion + 1);
		}
	};

	const onClick = (address) => {
		setActiveSuggestion(0);
		setAddressSuggestions([]);
		setShowSuggestions(false);
		setUserInput(address.full_address);
		getAddressDetails(address.id);
	};

	const onChangeInternal = (value) => {
		setActiveSuggestion(0);
		setShowSuggestions(true);
		setUserInput(value);
		if (onChange) {
			onChange(value);
		}
	};

	const renderSuggestions = () => {
		if (showSuggestions && userInput) {
			if (addressSuggestions.length) {
				return (
					<List
						listItems={addressSuggestions}
						itemRender={(item) => item.full_address}
						onItemClick={onClick}
					/>
				);
			} else {
				return (
					<List
						listItems={[{ value: 'No matches' }]}
						itemRender={(item) => item.value}
						onItemClick={onClick}
					/>
				);
			}
		}
	};

	return (
		<div {...rest}>
			<FormTextField
				id={ProfileKeys.STREET_ADDRESS}
				styles={formTextFieldStyle}
				value={value}
				onKeyDown={onKeyDown}
				onChange={(e) => {
					onChangeInternal(e.target.value);
				}}
				canEdit={canEdit}
				{...(id && { id })}
			/>
			{disableAutoSuggestion == false && canEdit == true && renderSuggestions()}
		</div>
	);
};

export default AddressAutoSuggestion;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	headerTitle: {
		border: '1px solid #c7ced1',
		background: 'white',
		width: '100%',
		padding: 10,
		boxSizing: 'border-box',
		fontSize: 12,
		borderRadius: 4
	},
	inputText: {
		height: '38px',
		borderRadius: '4px 0px 0px 4px',
		border: '1px solid #c7ced1',
		minWidth: '100%',
		paddingLeft: 10,
		fontSize: 15,
		margin: 0,
		boxSizing: 'border-box',
		'&:focus': {
			outline: 'none'
		}
	},
	dropDownTitle: {
		fontSize: 13,
		fontWeight: 600,
		marginBottom: 5,
		color: '#464749'
	},
	listContainer: {
		position: 'relative',
		zIndex: 100
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		cursor: 'pointer',
		background: 'white',
		top: 5,
		width: '100%',
		height: 150,
		overflow: 'hidden',
		overflowY: 'scroll',
		border: '1px solid #b3bcc1'
	},
	notSelected: {
		padding: 10,
		fontSize: 13
	},
	selected: {
		background: '#dcebf3',
		padding: 10,
		fontSize: 13,
		color: '#016ea6',
		fontWeight: 600
	},
	hrLine: {
		background: '#d4dbde',
		height: 1,
		border: 'none',
		margin: 0
	},
	dropDown: {
		float: 'right',
		marginTop: '-3px'
	},
	dot: {
		height: 11,
		width: 11,
		marginLeft: 5,
		borderRadius: '50%',
		display: 'inline-block'
	},
	dotTitle: {
		fontWeight: 400,
		fontSize: 10
	}
}));

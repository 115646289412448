import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	formContainer: {
		extend: 'container',
		width: '100%',
		paddingLeft: '15px !important',
		paddingRight: '15px !important',
		marginLeft: 0,
		marginRight: 0
	},
	filterContainer: {
		background: '#dcebf3',
		height: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingTop: 12
	},
	tabsContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%'
	}
}));

import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Col, Row } from 'react-grid-system';
import HeaderBanner from '../HeaderBanner';
import PrimaryButton from '../PrimaryButton';
import FormMultiSelectList from '../FormMultiSelectList';
import MultiSelectInput from '../MultiSelectInput';
import MessageBar from '../MessageBar';
import FormLabelField from '../FormLabelField';
import ResizingTextArea from '../ResizingTextArea';
import { formatDateReadableVariant } from '../../utils/helpers';

const MemberAccountStatus = ({
	formName,
	memberData,
	accountDisableReasonsList,
	adminExternalId,
	statusChangedByName,
	mobile,
	onMemberAccountStatusUpdate
}) => {
	const classes = useStyles();
	const [disableUserClicked, setDisableUserClicked] = useState(false);
	const [reasonValues, setReasonValues] = useState([]);
	const [reasonsOther, setReasonsOther] = useState([]);
	const [accountStatus, setAccountStatus] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [formHasBeenValidated, setFormHasBeenValidated] = useState(false);

	useEffect(() => {
		if (memberData?.accountStatus == null) {
			setAccountStatus(1);
		} else {
			setAccountStatus(memberData.accountStatus);
		}
		setReasonValues([]);
		setReasonsOther([]);
	}, [memberData]);

	const reactivateAccountStatus = () => {
		const input = {
			accountStatus: 1,
			disableReasonOther: '',
			disableReasons: [],
			externalId: memberData?.externalId,
			memberId: memberData?.id,
			statusChangedBy: adminExternalId
		};
		onMemberAccountStatusUpdate(input);
	};

	const disableUserAccount = () => {
		if (!validateDisableReasons()) {
			let reasonIDs = [];
			reasonValues.map((item) => {
				reasonIDs.push(item.id);
			});

			let reasonOtherValues = reasonsOther
				?.map(({ value }) => value)
				.join(', ');
			const input = {
				accountStatus: 2,
				disableReasonOther: reasonOtherValues,
				disableReasons: reasonIDs,
				externalId: memberData?.externalId,
				memberId: memberData?.id,
				statusChangedBy: adminExternalId
			};
			onMemberAccountStatusUpdate(input);
		}
	};

	const disableReasons = accountDisableReasonsList.map((item) => ({
		...item,
		label: item.value
	}));

	const reasonIdsChanged = (value) => {
		setReasonValues(value);
		clearOtherIfShould(value, reasonsOther, setReasonsOther);
	};

	useEffect(() => {
		if (formHasBeenValidated) {
			validateDisableReasons();
		}
	}, [reasonValues, reasonsOther]);

	const validateDisableReasons = () => {
		let validationError = false;

		if (reasonValues.length === 0) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disableReasons: {
					message: 'You must select at least one reason.'
				}
			}));
			validationError = true;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disableReasons: { message: null }
			}));
		}

		if (
			reasonValues.filter((r) => r.label === 'Other').length > 0 &&
			reasonsOther == ''
		) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disableReasonsOther: {
					message: 'You must enter value for other reason.'
				}
			}));
			validationError = true;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disableReasonsOther: { message: null }
			}));
		}
		setFormHasBeenValidated(true);
		return validationError;
	};

	const clearOtherIfShould = (
		currentValue,
		otherValue,
		updateOtherValueFunc
	) => {
		if (!currentValue.find(({ label }) => label === 'Other') && otherValue) {
			updateOtherValueFunc([]);
		}
	};

	const disableUserButtonClicked = (value) => {
		setDisableUserClicked(value);
	};

	return (
		<>
			<HeaderBanner
				title="Account Status"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderBottom: '1px solid #b3bcc1'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				{accountStatus == 2 && (
					<>
						<Row>
							<Col xs={12} lg={4}>
								<FormLabelField
									id={`${formName}-memberAccountStatus-accountStatusChangeDate`}
									title="Date Disabled"
									value={
										formatDateReadableVariant(
											memberData.accountStatusChangeDate
										) || ''
									}
								/>
							</Col>
							<Col xs={12} lg={4}>
								<FormLabelField
									id={`${formName}-memberAccountStatus-accountStatusChangedBy`}
									title="Disabled By"
									value={statusChangedByName}
								/>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldsContainerNoTopMargin}>
									<ResizingTextArea
										id={`${formName}-memberAccountStatus-accountStatusChangedBy`}
										value={memberData.accountDisableReasons
											?.map((item) => item.value)
											.join(', ')}
										title="Reason for Disabling Account"
										canEdit={false}
									/>
								</div>
							</Col>
						</Row>
						<Row className={classes.fieldsContainerNoTopMargin}>
							<Col xs={12}>
								<ResizingTextArea
									id={`${formName}-memberAccountStatus-accountDisableReasonOther`}
									value={memberData.accountDisableReasonOther}
									title="Other reasons for Disabling Account"
									canEdit={false}
								/>
							</Col>
						</Row>
						<Row className={classes.updateButtonContainer}>
							<Col>
								<PrimaryButton
									id={`${formName}-memberAccountStatus-reactivateAccount`}
									isNonSubmit={false}
									style={{ width: '190px' }}
									onClick={() => reactivateAccountStatus()}
								>
									Reactivate Account
								</PrimaryButton>
							</Col>
							<Col></Col>
							<Col></Col>
						</Row>
					</>
				)}

				{(accountStatus == null || accountStatus == 1) &&
					disableUserClicked == false && (
						<>
							<div className={classes.fieldsContainer}>
								<PrimaryButton
									id={`member-MemberAccountStatus-save-button`}
									isNonSubmit={false}
									style={{ width: '190px', backgroundColor: '#c00000' }}
									onClick={() => disableUserButtonClicked(true)}
								>
									Disable Account
								</PrimaryButton>
							</div>
							<div className={classes.warningInfo}>
								<MessageBar type="info">
									Disabling the user’s account will prevent this user from
									accessing any member pages, forms or benefits.
									<br />
									<br />
									The user will be immediately logged out if they are currently
									logged in.
									<br />
									<br />
									You must supply a reason if you Disable the user’s account.
								</MessageBar>
							</div>
						</>
					)}

				{(accountStatus == null || accountStatus == 1) &&
					disableUserClicked === true && (
						<>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldsContainer}>
										<div className={classes.fieldTitle}>
											Reason for Disabling Account*
										</div>
										<FormMultiSelectList
											name="disableReasons"
											listOptions={disableReasons}
											selectedIds={reasonValues}
											onSelectChange={reasonIdsChanged}
										/>
										<span className={classes.errorText}>
											{uncontrolledErrors?.disableReasons?.message}
										</span>
									</div>
								</Col>
							</Row>
							{reasonValues.filter((r) => r.label === 'Other').length > 0 && (
								<Row style={{ marginTop: 8 }}>
									<Col xs={12}>
										<div className={classes.fieldsContainer}>
											<div style={{ marginBottom: 7 }}>
												<span className={classes.fieldTitle}>
													Other Reasons (Multiple responses to be added with
													comma, press Enter to save)
												</span>
											</div>
											<MultiSelectInput
												value={reasonsOther}
												updateValue={(value) => {
													setReasonsOther(value);
												}}
											/>
											<span className={classes.errorText}>
												{uncontrolledErrors?.disableReasonsOther?.message}
											</span>
										</div>
									</Col>
								</Row>
							)}
							<div className={classes.buttonContainer}>
								<div></div>
								<div className={classes.rightContainer}>
									<span
										className={classes.cancelRequest}
										onClick={() => disableUserButtonClicked(false)}
									>
										Cancel
									</span>
									<span>
										<PrimaryButton
											id={`member-MemberAccountStatus-save-button`}
											isNonSubmit={false}
											style={{ width: '190px', backgroundColor: '#c00000' }}
											onClick={() => disableUserAccount()}
										>
											Disable Account
										</PrimaryButton>
									</span>
								</div>
							</div>
						</>
					)}
			</div>
		</>
	);
};
export default MemberAccountStatus;

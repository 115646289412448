import { INITIAL_APPLICATION_REVIEW_STATE } from './constants';
import { BLANK_OPTION } from '../../utils/constants';

export const initialiseFormState = (formData, currentUser, lookupValues) => {
	const actioningUserExternalId =
		formData?.adminAction?.admin?.externalId || currentUser?.UserId;
	return {
		...INITIAL_APPLICATION_REVIEW_STATE,
		actioning: {
			...INITIAL_APPLICATION_REVIEW_STATE.actioning,
			value: lookupValues.actioningOptions.some(
				(item) => item.externalId == actioningUserExternalId
			)
				? lookupValues.actioningOptions.find(
						({ externalId }) => externalId == actioningUserExternalId
				  ).id
				: ''
		},
		adminStatus: {
			...INITIAL_APPLICATION_REVIEW_STATE.adminStatus,
			value: formData?.adminAction?.adminStatus?.id || BLANK_OPTION.id
		},
		applicationFormComplete: {
			...INITIAL_APPLICATION_REVIEW_STATE.applicationFormComplete,
			value: !!formData?.adminAction?.formComplete
		},
		videoAssessmentComments: {
			...INITIAL_APPLICATION_REVIEW_STATE.videoAssessmentComments,
			value: formData?.videoAssessmentComments || ''
		},
		videoAssessmentDate: {
			...INITIAL_APPLICATION_REVIEW_STATE.videoAssessmentDate,
			value: formData?.videoAssessmentDate
		},
		videoAssessmentUploads: {
			...INITIAL_APPLICATION_REVIEW_STATE.videoAssessmentUploads,
			value: formData?.videoAssessmentUploads
		},
		phoneInterviewComments: {
			...INITIAL_APPLICATION_REVIEW_STATE.phoneInterviewComments,
			value: formData?.phoneInterviewComments || ''
		},
		phoneInterviewDate: {
			...INITIAL_APPLICATION_REVIEW_STATE.phoneInterviewDate,
			value: formData?.phoneInterviewDate
		},
		propertyAssessmentComments: {
			...INITIAL_APPLICATION_REVIEW_STATE.propertyAssessmentComments,
			value: formData?.propertyAssessmentComments || ''
		},
		propertyAssessmentDate: {
			...INITIAL_APPLICATION_REVIEW_STATE.propertyAssessmentDate,
			value: formData?.propertyAssessmentDate
		},
		propertyAssessmentUploads: {
			...INITIAL_APPLICATION_REVIEW_STATE.propertyAssessmentUploads,
			value: formData?.propertyAssessmentUploads
		},
		ridingAssessmentSameDay: {
			...INITIAL_APPLICATION_REVIEW_STATE.ridingAssessmentSameDay,
			value: !formData?.ridingAssessmentDate
		},
		ridingAssessmentDate: {
			...INITIAL_APPLICATION_REVIEW_STATE.ridingAssessmentDate,
			value: formData?.ridingAssessmentDate
		},
		ridingScore: {
			...INITIAL_APPLICATION_REVIEW_STATE.ridingScore,
			value: formData?.ridingScore
		},
		propertyAndRidingPhotoUploads: {
			...INITIAL_APPLICATION_REVIEW_STATE.propertyAndRidingPhotoUploads,
			value: formData?.propertyRidingPhotos
		},
		ridingAssessmentComments: {
			...INITIAL_APPLICATION_REVIEW_STATE.ridingAssessmentComments,
			value: formData?.ridingAssessmentComments || ''
		},
		ridingAssessmentUploads: {
			...INITIAL_APPLICATION_REVIEW_STATE.ridingAssessmentUploads,
			value: formData?.ridingAssessmentUpload
		},
		applicationRecommendation: {
			...INITIAL_APPLICATION_REVIEW_STATE.applicationRecommendation,
			value: formData?.applicationRecommendation || ''
		},
		ewgmComment: {
			...INITIAL_APPLICATION_REVIEW_STATE.ewgmComment,
			value: formData?.ewgmComment || ''
		},
		applicationReviewDate: {
			...INITIAL_APPLICATION_REVIEW_STATE.applicationReviewDate,
			value: formData?.ewgmReviewDate
		},
		rejectionReasons: {
			...INITIAL_APPLICATION_REVIEW_STATE.rejectionReasons,
			value:
				formData.rejectionReasons && formData.rejectionReasons.length > 0
					? formData.rejectionReasons.map(({ id, value }) => ({
							label: value,
							value: id
					  }))
					: []
		},
		personalDetailUploads: {
			...INITIAL_APPLICATION_REVIEW_STATE.personalDetailUploads,
			value: formData?.personalDetailUploads || []
		}
	};
};

export const validateFormData = (formState, newStatus) => {
	let newFormState = formState;
	newFormState['rejectionReasons']['validationError'] = '';
	newFormState['rejectionNotification']['validationError'] = '';
	if (newStatus === 'Rejected') {
		if (formState.rejectionReasons.value.length === 0) {
			newFormState = {
				...newFormState,
				rejectionReasons: {
					...newFormState.rejectionReasons,
					validationError: 'Rejection reasons are required'
				}
			};
		}
		if (!formState.rejectionNotification.value) {
			newFormState = {
				...newFormState,
				rejectionNotification: {
					...newFormState.rejectionNotification,
					validationError: 'Rejection notification is required'
				}
			};
		}
	} else {
		if (
			formState.personalDetailUploads.value &&
			formState.personalDetailUploads.value.length > 0
		) {
			let tempPersonalDetailUploads = formState.personalDetailUploads.value;
			let isErrorPersonalDetail = false;
			tempPersonalDetailUploads = tempPersonalDetailUploads.map((item) => {
				if (!item?.documentType) {
					isErrorPersonalDetail = true;
					return {
						...item,
						documentTypeError: 'Document type is required'
					};
				} else return item;
			});
			newFormState = {
				...newFormState,
				personalDetailUploads: {
					...tempPersonalDetailUploads,
					value: tempPersonalDetailUploads,
					validationError: isErrorPersonalDetail
						? 'Document type is required'
						: ''
				}
			};
		}
		if (formState.videoAssessmentUploads.value.length === 0) {
			newFormState = {
				...newFormState,
				videoAssessmentUploads: {
					...newFormState.videoAssessmentUploads,
					validationError: 'Video assessment uploads are required'
				}
			};
		}
		if (!formState.videoAssessmentDate.value) {
			newFormState = {
				...newFormState,
				videoAssessmentDate: {
					...newFormState.videoAssessmentDate,
					validationError: 'Video assessment date is required'
				}
			};
		}
		if (!formState.propertyAssessmentComments.value) {
			newFormState = {
				...newFormState,
				propertyAssessmentComments: {
					...newFormState.propertyAssessmentComments,
					validationError: 'Property assessment comment is required'
				}
			};
		}
		if (!formState.propertyAssessmentDate.value) {
			newFormState = {
				...newFormState,
				propertyAssessmentDate: {
					...newFormState.propertyAssessmentDate,
					validationError: 'Property assessment date is required'
				}
			};
		}
		if (formState.propertyAssessmentUploads.value.length === 0) {
			newFormState = {
				...newFormState,
				propertyAssessmentUploads: {
					...newFormState.propertyAssessmentUploads,
					validationError: 'Property assessment uploads are required'
				}
			};
		}
		if (
			!formState.ridingAssessmentSameDay.value &&
			!formState.ridingAssessmentDate.value
		) {
			newFormState = {
				...newFormState,
				ridingAssessmentDate: {
					...newFormState.ridingAssessmentDate,
					validationError: 'Riding assessment date is required'
				}
			};
		}
		if (
			!formState.ridingScore.value ||
			isNaN(formState.ridingScore.value) ||
			!(
				+formState.ridingScore.value >= 0 && +formState.ridingScore.value <= 100
			)
		) {
			newFormState = {
				...newFormState,
				ridingScore: {
					...newFormState.ridingScore,
					validationError: 'Riding score between 0 and 100 is required'
				}
			};
		}
		if (!formState.ridingAssessmentComments.value) {
			newFormState = {
				...newFormState,
				ridingAssessmentComments: {
					...newFormState.ridingAssessmentComments,
					validationError: 'Riding assessment comments is required'
				}
			};
		}
		if (formState.ridingAssessmentUploads.value.length === 0) {
			newFormState = {
				...newFormState,
				ridingAssessmentUploads: {
					...newFormState.ridingAssessmentUploads,
					validationError: 'Riding assessment uploads are required'
				}
			};
		}
		if (!formState.applicationRecommendation.value) {
			newFormState = {
				...newFormState,
				applicationRecommendation: {
					...newFormState.applicationRecommendation,
					validationError: 'Application recommendation is required'
				}
			};
		}
		if (!formState.ewgmComment.value) {
			newFormState = {
				...newFormState,
				ewgmComment: {
					...newFormState.ewgmComment,
					validationError: 'EWGM comment is required'
				}
			};
		}
		if (!formState.applicationReviewDate.value) {
			newFormState = {
				...newFormState,
				applicationReviewDate: {
					...newFormState.applicationReviewDate,
					validationError: 'EWGM review date is required'
				}
			};
		}
	}
	return newFormState;
};

export const validateFormRetirementData = (formState, newStatus) => {
	let newFormState = formState;
	newFormState['rejectionReasons']['validationError'] = '';
	newFormState['rejectionNotification']['validationError'] = '';
	if (newStatus === 'Rejected') {
		if (formState.rejectionReasons.value.length === 0) {
			newFormState = {
				...newFormState,
				rejectionReasons: {
					...newFormState.rejectionReasons,
					validationError: 'Rejection reasons are required'
				}
			};
		}
		if (!formState.rejectionNotification.value) {
			newFormState = {
				...newFormState,
				rejectionNotification: {
					...newFormState.rejectionNotification,
					validationError: 'Rejection notification is required'
				}
			};
		}
	} else {
		if (
			formState.personalDetailUploads.value &&
			formState.personalDetailUploads.value.length > 0
		) {
			let tempPersonalDetailUploads = formState.personalDetailUploads.value;
			let isErrorPersonalDetail = false;
			tempPersonalDetailUploads = tempPersonalDetailUploads.map((item) => {
				if (!item?.documentType) {
					isErrorPersonalDetail = true;
					return {
						...item,
						documentTypeError: 'Document type is required'
					};
				} else return item;
			});
			newFormState = {
				...newFormState,
				personalDetailUploads: {
					value: tempPersonalDetailUploads,
					validationError: isErrorPersonalDetail
						? 'Document type is required'
						: ''
				}
			};
		}
		if (!formState.propertyAssessmentDate.value) {
			newFormState = {
				...newFormState,
				propertyAssessmentDate: {
					...newFormState.propertyAssessmentDate,
					validationError: 'Property assessment date is required'
				}
			};
		}
		if (formState.propertyAssessmentUploads.value.length === 0) {
			newFormState = {
				...newFormState,
				propertyAssessmentUploads: {
					...newFormState.propertyAssessmentUploads,
					validationError: 'Property assessment uploads are required'
				}
			};
		}
		if (!formState.applicationRecommendation.value) {
			newFormState = {
				...newFormState,
				applicationRecommendation: {
					...newFormState.applicationRecommendation,
					validationError: 'Application recommendation is required'
				}
			};
		}
		if (!formState.ewgmComment.value) {
			newFormState = {
				...newFormState,
				ewgmComment: {
					...newFormState.ewgmComment,
					validationError: 'EWGM comment is required'
				}
			};
		}
		if (!formState.applicationReviewDate.value) {
			newFormState = {
				...newFormState,
				applicationReviewDate: {
					...newFormState.applicationReviewDate,
					validationError: 'EWGM review date is required'
				}
			};
		}
	}
	return newFormState;
};

export const generateSaveAdminDataPayload = (formState, formId) => {
	return {
		adminMemberId: formState.actioning.value,
		formId: formId,
		adminStatusId:
			formState.adminStatus.value != BLANK_OPTION.id
				? +formState.adminStatus.value
				: null,
		formComplete: formState.applicationFormComplete.value,
		videoAssessmentComments: formState.videoAssessmentComments.value || null,
		videoAssessmentDate: formState.videoAssessmentDate.value || null,
		phoneInterviewComments: formState.phoneInterviewComments.value || null,
		phoneInterviewDate: formState.phoneInterviewDate.value || null,
		personalDetailUploads:
			formState.personalDetailUploads.value &&
			formState.personalDetailUploads.value.length > 0
				? formState.personalDetailUploads.value.map((item) => ({
						filename: item.filename,
						key: item.key,
						type: item.type,
						documentType: item.documentType
				  }))
				: null,
		propertyAssessmentComments:
			formState.propertyAssessmentComments.value || null,
		propertyAssessmentDate: formState.propertyAssessmentDate.value || null,
		ridingAssessmentComments: formState.ridingAssessmentComments.value || null,
		ridingAssessmentdate: formState.ridingAssessmentDate.value || null,
		ridingScore: !isNaN(formState.ridingScore.value)
			? +formState.ridingScore.value
			: null,
		applicationRecommendation:
			formState.applicationRecommendation.value || null,
		ewgmComment: formState.ewgmComment.value || null,
		ewgmReviewDate: formState.applicationReviewDate.value || null,
		rejectReasonNotification: formState.rejectionNotification.value || null,
		rejectionReasonIds: formState.rejectionReasons.value.map(
			({ value }) => value
		)
	};
};

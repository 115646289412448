import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import useStyles from './styles';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from '../../hooks/useToast';
import { useParams } from '@reach/router';
import HeaderBanner from '../HeaderBanner';
import FormTextField from '../FormTextField';
import SEO from '../seo';
import MessageBar from '../MessageBar';
import Toggle from '../Toggle';
import { yupResolver } from '@hookform/resolvers/yup';
import FormDatePicker from '../FormDatePicker';
import {
	convertToSortedMultiListData,
	formatDate,
	checkUserAdmin
} from '../../utils/helpers';
import { navigate } from 'gatsby-link';
import PrimaryButton from '../PrimaryButton';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseProfile,
	getBusinessInfoReferenceData,
	getShareInformationOptions,
	unclaimHorse,
	getHorseRehomePurposes,
	getExistingNonRejectedForms,
	listSoldRehomePriceBrackets,
	saveDeceasedThoroughbredForm,
	getDeceasedThoroughbredForm
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import ResizingTextArea from '../ResizingTextArea';
import MultiSelectInput from '../MultiSelectInput';
import FormMultiSelectList from '../FormMultiSelectList';
import {
	checkDropDownValue,
	checkNotEqualDropDownValue,
	sortArray
} from '../../utils/helpers';
import { otherUnclaimedSchema, rehomedSchema } from './validations';
import { useAuth } from '../../hooks/useAuth';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import SubmitOnBehalf from '../SubmitOnBehalf';

const updateDate = (value, stateAssignmentFunction, validationFunction) => {
	let formattedDate = formatDate(value);
	if (formattedDate != '1970-01-01') {
		stateAssignmentFunction(formattedDate);
		validationFunction(false, formattedDate);
	} else {
		stateAssignmentFunction(null);
		validationFunction(true);
	}
};

const UnclaimThoroughbred = () => {
	const classes = useStyles();
	const { horseCode } = useParams();
	const { currentUser } = useAuth();
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	const [rehomed, setRehomed] = useState(true);
	const [horseName, setHorseName] = useState('');
	const [hasExistingFullCircleForm, setHasExistingFullCircleForm] = useState(
		false
	);
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');

	useEffect(() => {
		const input = {
			horseCode: parseInt(horseCode)
		};
		AppSyncService.execute(getHorseProfile, input).then((data) => {
			if (data?.data?.getHorseProfile) {
				setHorseName(data.data.getHorseProfile.horse.name);
			}
		});
		const existingFullCircleInput = {
			horseCode: parseInt(horseCode),
			memberExternalId: currentUser?.UserId
		};
		AppSyncService.execute(
			getExistingNonRejectedForms,
			existingFullCircleInput
		).then((existingFormData) => {
			setHasExistingFullCircleForm(
				existingFormData?.data?.getExistingNonRejectedForms?.find(
					(item) => item.formName === 'full_circle'
				).nonRejectedFormExists
			);
		});
	}, [currentUser]);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	const onSelectUserVerified = (selectedUserVerified) => {
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
	};
	return (
		<>
			<Container className={classes.pageContainer}>
				<SEO title="Unclaim Thoroughbred" />
				{isAdminUser ? (
					<SubmitOnBehalf
						horseCode={horseCode}
						onToggleSubmitBehalf={onToggleSubmitBehalf}
						onSelectUserVerified={onSelectUserVerified}
						isGetAllUsersVerified={false}
						selectUserRequiredError={selectUserRequiredError}
						isSelectUserRequired={isSelectUserRequired}
					/>
				) : null}
				<HeaderBanner
					title="Unclaim Thoroughbred"
					top
					type="primary"
					styles={{ marginBottom: '0px', borderRadius: '5px 5px 0px 0px' }}
				/>
				<MessageBar>
					Is this horse no longer in your care or on your property? Please fill
					in the required details for <b>{horseName}</b>.
				</MessageBar>
				<HeaderBanner
					title="Reason For Unclaiming"
					top
					type="primary"
					styles={{ marginBottom: '0px', marginTop: '30px' }}
				/>
				<div className={classes.formContainer}>
					<div className={classes.sectionContainer}>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Did you rehome/ sell this horse?*
									</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => setRehomed(value)}
										value={rehomed}
									/>
								</div>
							</Col>
						</Row>
						<hr className={classes.divider} />
						{rehomed && (
							<RehomedSubForm
								currentUser={currentUser}
								horseCode={horseCode}
								showFullCircle={!hasExistingFullCircleForm}
								submitBehalfItems={{
									isSubmitOnBehalf,
									selectedUserVerifiedInfo,
									setIsSelectUserRequired,
									selectUserRequiredError
								}}
							/>
						)}
						{!rehomed && (
							<OtherUnclaimedSubForm
								currentUser={currentUser}
								horseCode={horseCode}
								showFullCircle={!hasExistingFullCircleForm}
								submitBehalfItems={{
									isSubmitOnBehalf,
									selectedUserVerifiedInfo,
									setIsSelectUserRequired,
									selectUserRequiredError
								}}
							/>
						)}
					</div>
				</div>
			</Container>
		</>
	);
};

const FullCircleInterestedBar = ({
	fullCircleInterested,
	setFullCircleInterested
}) => {
	const classes = useStyles();

	return (
		<div className={classes.fullCircleBar}>
			<hr className={classes.fullWidthDivider} />
			<MessageBar type={'message'}>
				<Row style={{ alignItems: 'center' }}>
					<Col xs={12} md={8}>
						<div>
							Are you interested in becoming an emergency contact through Full
							Circle?
						</div>
					</Col>
					<Col xs={12} md={4}>
						<Toggle
							labelNegative="No"
							labelPositive="Yes"
							onClick={(value) => setFullCircleInterested(value)}
							value={fullCircleInterested}
						/>
					</Col>
				</Row>
			</MessageBar>

			{fullCircleInterested && (
				<>
					<MessageBar type={'info'} style={{ marginTop: 10 }}>
						Please note this will require additional information to be completed
						as part of the Full Circle process.{' '}
						<a
							className={classes.readMoreLink}
							href="/programs/full-circle"
							target="_blank"
						>
							Read More
						</a>
					</MessageBar>
				</>
			)}
		</div>
	);
};

const RehomedSubForm = ({
	horseCode,
	currentUser,
	showFullCircle,
	submitBehalfItems
}) => {
	const { handleSubmit, errors, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(rehomedSchema())
	});

	const classes = useStyles();
	const [rehomedDate, setRehomedDate] = useState(null);
	const [price, setPrice] = useState(0);
	const [purpose, setPurpose] = useState('');
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [otherPurpose, setOtherPurpose] = useState('');
	const [disciplinesValues, setDisciplinesValues] = useState([]);
	const [disciplinesList, setDisciplinesList] = useState([]);
	const [disciplinesOtherMulti, setDisciplinesOtherMulti] = useState([]);
	const [fullCircleInterested, setFullCircleInterested] = useState(false);
	const [formHasBeenValidated, setFormHasBeenValidated] = useState(false);
	const [purposeOptions, setPurposeOptions] = useState([]);
	const [priceBracketOptions, setPriceBracketOptions] = useState([]);
	const { addToast } = useToast();
	const {
		isSubmitOnBehalf,
		selectedUserVerifiedInfo,
		setIsSelectUserRequired,
		selectUserRequiredError
	} = submitBehalfItems;
	useEffect(() => {
		AppSyncService.execute(getBusinessInfoReferenceData).then((result) => {
			if (result.data) {
				setDisciplinesList(
					convertToSortedMultiListData(result.data.listSpecialtyDisciplines)
				);
			}
		});
		AppSyncService.execute(getHorseRehomePurposes).then((result) => {
			if (result.data) {
				setPurposeOptions(
					[{ id: 0, value: '' }]
						.concat(result.data.listHorseRehomePurposes)
						.sort(function (a, b) {
							return a.id - b.id;
						})
				);
			}
		});
		AppSyncService.execute(listSoldRehomePriceBrackets).then((result) => {
			if (result.data) {
				setPriceBracketOptions(
					[{ id: 0, value: '' }]
						.concat(result.data.listSoldRehomePriceBrackets)
						.sort(function (a, b) {
							return a.id - b.id;
						})
				);
			}
		});
	}, []);

	const disciplinesValuesChanged = (value) => {
		setDisciplinesValues(value);
		clearOtherIfShould(value, disciplinesOtherMulti, setDisciplinesOtherMulti);
	};

	useEffect(() => {
		if (formHasBeenValidated) {
			validateDisciplines();
		}
	}, [disciplinesValues]);

	const validateDisciplines = () => {
		if (disciplinesValues.length === 0) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disciplines: {
					message: 'You must select at least one discipline.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				disciplines: { message: null }
			}));
		}
	};

	const clearOtherIfShould = (
		currentValue,
		otherValue,
		updateOtherValueFunc
	) => {
		if (!currentValue.find(({ label }) => label === 'Other') && otherValue) {
			updateOtherValueFunc([]);
		}
	};

	const validateUncontrolledErrors = () => {
		validateRehomedDate();
		validateOtherPurpose();
		validateDisciplines();
		setFormHasBeenValidated(true);
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
			return;
		} else {
			setIsSelectUserRequired(false);
		}
	};

	const validateRehomedDate = (isInvalidDate, date) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				RehomedDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!date && !rehomedDate) {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					RehomedDate: {
						message: 'You must enter a Date sold or rehomed.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					RehomedDate: { message: null }
				}));
			}
		}
	};

	const validateOtherPurpose = () => {
		if (purpose === '5' && otherPurpose === '') {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OtherPurpose: {
					message: 'You must enter in details for Other purpose.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OtherPurpose: { message: null }
			}));
		}
	};

	const onSubmit = () => {
		if (
			//If this condition is met here, form is validated
			Object.values(uncontrolledErrors).every(
				(item) => item.message === null
			) &&
			selectUserRequiredError.length <= 0
		) {
			let disciplineIDs = [];
			disciplinesValues.map((item) => {
				disciplineIDs.push(item.value);
			});

			let dispciplines = disciplinesOtherMulti
				.map(({ value }) => value)
				.toString();

			const input = {
				horse_code: Number(horseCode),
				member_id: isSubmitOnBehalf
					? selectedUserVerifiedInfo.memberExternalId
					: currentUser?.UserId,
				date_rehomed: rehomedDate,
				price: price,
				purpose: purpose,
				purpose_other: otherPurpose,
				full_circle_interested: fullCircleInterested,
				rehomed_disciplines: disciplineIDs,
				disciplines_other: dispciplines,
				isSubmitOnBehalf: isSubmitOnBehalf
			};
			AppSyncService.execute(unclaimHorse, { input }).then((data) => {
				if (data?.data?.unclaimHorse?.success) {
					addToast({ Message: 'Thoroughbred Unclaimed', IsSuccess: true });
					navigate(DASHBOARD_ROUTE);
				} else {
					addToast({
						Message: 'An error occured unclaiming.',
						IsSuccess: false
					});
				}
			});
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Row>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Date sold / rehomed*</div>
						<FormDatePicker
							DateValue={rehomedDate}
							updateSelection={(value) => {
								updateDate(value, setRehomedDate, validateRehomedDate);
							}}
						/>
					</div>
					<span className={classes.errorText}>
						{uncontrolledErrors?.RehomedDate?.message}
					</span>
				</Col>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Price Bracket*</div>
						<Controller
							control={control}
							name="price"
							render={({ onChange }) => (
								<FormDropDown
									items={priceBracketOptions}
									selectedId={price}
									showBlank
									onChange={(e) => {
										onChange(e.target.value);
										setPrice(e.target.value);
									}}
								/>
							)}
						/>
						<span className={classes.errorText}>{errors.price?.message}</span>
					</div>
				</Col>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Purpose*</div>
						<Controller
							control={control}
							name="purpose"
							render={({ onChange }) => (
								<FormDropDown
									items={purposeOptions}
									selectedId={purpose}
									showBlank
									onChange={(e) => {
										onChange(e.target.value);
										setPurpose(e.target.value);
									}}
									styles={{ width: '100%' }}
								/>
							)}
						/>
						<span className={classes.errorText}>{errors.purpose?.message}</span>
					</div>
				</Col>
			</Row>
			{purpose === '5' && (
				<Row>
					<Col xs={12}>
						<ResizingTextArea
							title="Other Purpose Details*"
							id={`ResizingTextArea-otherPurpose`}
							onChange={(e) => {
								setOtherPurpose(e.target.value);
								validateOtherPurpose();
							}}
							value={otherPurpose}
						/>
						<span className={classes.errorText}>
							{uncontrolledErrors?.OtherPurpose?.message}
						</span>
					</Col>
				</Row>
			)}
			<Row>
				<Col xs={12}>
					<div className={classes.ProfilefieldContainer}>
						<div className={classes.fieldTitle}>Disciplines*</div>
						<FormMultiSelectList
							name="disciplines"
							listOptions={disciplinesList}
							selectedIds={disciplinesValues}
							onSelectChange={disciplinesValuesChanged}
						/>
						<span className={classes.errorText}>
							{uncontrolledErrors?.disciplines?.message}
						</span>
					</div>
				</Col>
			</Row>
			{disciplinesValues.filter((r) => r.label === 'Other').length > 0 && (
				<Row style={{ marginTop: 8 }}>
					<Col xs={12}>
						<div className={classes.ProfilefieldContainer}>
							<div style={{ marginBottom: 7 }}>
								<span className={classes.fieldTitle}>
									Other Disciplines (Please separate individual specialties by
									comma)
								</span>
							</div>
							<MultiSelectInput
								value={disciplinesOtherMulti}
								updateValue={(value) => {
									setDisciplinesOtherMulti(value);
								}}
							/>
						</div>
					</Col>
				</Row>
			)}
			{showFullCircle && (
				<FullCircleInterestedBar
					fullCircleInterested={fullCircleInterested}
					setFullCircleInterested={setFullCircleInterested}
				/>
			)}
			<div className={classes.submitContainer}>
				<div
					onClick={() => navigate(DASHBOARD_ROUTE)}
					className={classes.cancelRequestLink}
				>
					Cancel Request
				</div>
				<span>
					<PrimaryButton
						id={`Submit-Primary-Button-Rehomed`}
						isNonSubmit={false}
						style={{ minWidth: '170px' }}
						onClick={() => validateUncontrolledErrors()}
					>
						Complete Form
					</PrimaryButton>
				</span>
			</div>
		</form>
	);
};

const otherReasonOptions = [
	{ id: 0, value: '' },
	{ id: 1, value: 'Deceased' },
	{ id: 2, value: 'Other' }
];

const OtherUnclaimedSubForm = ({
	horseCode,
	currentUser,
	showFullCircle,
	submitBehalfItems
}) => {
	const classes = useStyles();
	const [unclaimReason, setUnclaimReason] = useState('0');
	const [horseDeathCause, setHorseDeathCause] = useState('0');
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [fullCircleInterested, setFullCircleInterested] = useState(false);
	const [otherReason, setOtherReason] = useState('');
	const [effectiveDate, setEffectiveDate] = useState(null);
	const [deathDate, setDeathDate] = useState(null);
	const [euthanasiaReason, setEuthanasiaReason] = useState('0');
	const [injuryType, setInjuryType] = useState('0');
	const [injuryOther, setInjuryOther] = useState('');
	const [illnessType, setIllnessType] = useState('0');
	const [illnessOther, setIllnessOther] = useState('');
	const [unsuitableReason, setUnsuitableReason] = useState('0');
	const [unsuitableOther, setUnsuitableOther] = useState('');
	const [euthanasiaOther, setEuthanasiaOther] = useState('');
	const [otherDeath, setOtherDeath] = useState('');
	const [postcode, setPostcode] = useState('');
	const [suburb, setSuburb] = useState('');

	const [horseDeathReasonOptions, setHorseDeathReasonOptions] = useState([]);
	const [euthanasiaReasons, setEuthanasiaReasons] = useState([]);
	const [euthanasiaInjuryTypes, setEuthanasiaInjuryTypes] = useState([]);
	const [euthanasiaIllnessTypes, setEuthanasiaIllnessTypes] = useState([]);
	const [euthanasiaOtherTypes, setEuthanasiaOtherTypes] = useState([]);
	const [
		euthanasiaUnsuitableForReasons,
		setEuthanasiaUnsuitableForReasons
	] = useState([]);
	const { addToast } = useToast();
	const {
		isSubmitOnBehalf,
		selectedUserVerifiedInfo,
		setIsSelectUserRequired,
		selectUserRequiredError
	} = submitBehalfItems;

	useEffect(() => {
		AppSyncService.execute(getShareInformationOptions).then((result) => {
			if (result.data) {
				setHorseDeathReasonOptions(
					[{ id: 0, value: '' }].concat(result.data.listShareInfoCauseOfDeaths)
				);
				setEuthanasiaInjuryTypes(
					[{ id: 0, value: '' }].concat(
						result.data.listShareInfoEuthanisedInjuries
					)
				);
				setEuthanasiaReasons(
					[{ id: 0, value: '' }].concat(
						result.data.listShareInfoEuthanisedReasons
					)
				);
				setEuthanasiaIllnessTypes(
					[{ id: 0, value: '' }].concat(
						sortArray(result.data.listShareInfoEuthanisedIllness, true)
					)
				);
				setEuthanasiaOtherTypes(
					[{ id: 0, value: '' }].concat(
						result.data.listShareInfoEuthanisedOtherReasons
					)
				);
				setEuthanasiaUnsuitableForReasons(
					[{ id: 0, value: '' }].concat(
						result.data.listShareInfoEuthanisedUnsuitableReasons
					)
				);
			}
		});
	}, []);

	const { handleSubmit, errors, control, setValue } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(
			otherUnclaimedSchema(
				horseDeathReasonOptions,
				euthanasiaReasons,
				euthanasiaInjuryTypes,
				euthanasiaIllnessTypes,
				euthanasiaUnsuitableForReasons,
				euthanasiaOtherTypes
			)
		)
	});

	useEffect(() => {
		setValue('unclaimReason', '');
		setValue('injuryType', '');
		setValue('euthanasiaReason', '');
		setValue('horseDeathCause', '');
	}, []);

	const validateDeathDate = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				DeathDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (unclaimReason === '1' && !deathDate) {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DeathDate: {
						message: 'You must enter a date of death.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DeathDate: { message: null }
				}));
			}
		}
	};

	const validateEffectiveDate = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				EffectiveDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (unclaimReason === '2' && !effectiveDate) {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					EffectiveDate: {
						message: 'You must enter an effective date for other.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					EffectiveDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		setInjuryType('0');
		setInjuryOther('');
		setIllnessType('0');
		setIllnessOther('');
		setUnsuitableReason('0');
		setUnsuitableOther('');
	}, [euthanasiaReason]);

	useEffect(() => {
		validateEffectiveDate();
	}, [effectiveDate]);

	useEffect(() => {
		validateDeathDate();
	}, [deathDate]);

	const validateUncontrolledErrors = () => {
		validateEffectiveDate();
		validateDeathDate();
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
		} else {
			setIsSelectUserRequired(false);
		}
	};

	const resetFormValidation = () => {
		setUncontrolledErrors({});
	};

	useEffect(() => {
		resetFormValidation();
	}, [unclaimReason]);

	const onSubmit = () => {
		if (
			//If this condition is met here, form is validated
			Object.values(uncontrolledErrors).every(
				(item) => item.message === null
			) &&
			selectUserRequiredError.length <= 0
		) {
			const input = {
				horse_code: Number(horseCode),
				member_id: isSubmitOnBehalf
					? selectedUserVerifiedInfo.memberExternalId
					: currentUser?.UserId,
				unclaim_reason: Number(unclaimReason),
				effective_date: effectiveDate,
				other_details: otherReason || null,
				full_circle_interested: fullCircleInterested,
				date_of_death: deathDate,
				euthanised_unsuitable_id:
					unsuitableReason != 0 ? Number(unsuitableReason) : null,
				euthanised_unsuitable_other: unsuitableOther || null,
				euthanised_illness_id: illnessType != 0 ? Number(illnessType) : null,
				euthanised_illness_other: illnessOther || null,
				cause_of_death_id:
					horseDeathCause != 0 ? Number(horseDeathCause) : null,
				euthanised_injury_id: injuryType != 0 ? Number(injuryType) : null,
				euthanised_injury_other: injuryOther || null,
				deceased_other_info: otherDeath || null,
				knackery_location: (suburb || '') + (postcode || '') || null,
				isSubmitOnBehalf: isSubmitOnBehalf
			};
			AppSyncService.execute(unclaimHorse, { input }).then((data) => {
				if (data?.data?.unclaimHorse?.success) {
					addToast({ Message: 'Thoroughbred Unclaimed', IsSuccess: true });
					if (unclaimReason === '1') {
						const deceasedCauseOfDeathId =
							horseDeathCause != 0 ? Number(horseDeathCause) : null;
						const euthanisedReasonId =
							deceasedCauseOfDeathId == 2 && euthanasiaReason != 0
								? Number(euthanasiaReason)
								: null;
						const deceasedInput = {
							horseCode: Number(horseCode),
							deceasedThoroughbredSourceId: 1, //@NOTE - 1 is equal to Unclaim Thoroughbred
							deceasedDateOfDeath: deathDate,
							deceasedCauseOfDeathId: deceasedCauseOfDeathId,
							memberId: null,
							euthanisedReasonId,
							unsuitableReasons:
								deceasedCauseOfDeathId == 2 &&
								euthanasiaReason == 3 &&
								Number(unsuitableReason) != 0 &&
								!isNaN(Number(unsuitableReason))
									? [Number(unsuitableReason)]
									: [],
							euthanisedInjuryOther:
								deceasedCauseOfDeathId == 2 && euthanasiaReason == 1
									? injuryOther
									: null,
							euthanisedIllnessOther:
								deceasedCauseOfDeathId == 2 && euthanasiaReason == 2
									? illnessOther
									: null,
							euthanisedUnsuitableId:
								deceasedCauseOfDeathId == 2 &&
								euthanasiaReason == 3 &&
								unsuitableReason != 0
									? Number(unsuitableReason)
									: null,
							euthanisedUnsuitableOther:
								(deceasedCauseOfDeathId == 2 &&
									euthanasiaReason == 3 &&
									unsuitableOther) ||
								null,
							deceasedReasonForDeathOther:
								euthanasiaOther &&
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 4 &&
								!isNaN(Number(euthanasiaOther))
									? [Number(euthanasiaOther)]
									: [],
							deceasedKnackeryPostcode:
								deceasedCauseOfDeathId == 4 ? postcode : null,
							deceasedKnackerySuburb:
								deceasedCauseOfDeathId == 4 ? suburb : null,
							deceasedOtherPostcode:
								deceasedCauseOfDeathId == 6 ? postcode : null,
							deceasedOtherSuburb: deceasedCauseOfDeathId == 6 ? suburb : null,
							informationAboutDeath:
								deceasedCauseOfDeathId == 6 ? otherDeath : null,
							hasOwnerBeenNotified: false,
							status: 'Review',
							injuries:
								deceasedCauseOfDeathId == 2 &&
								euthanasiaReason == 1 &&
								Number(injuryType) != 0 &&
								!isNaN(Number(injuryType))
									? [Number(injuryType)]
									: [],
							illnesses:
								deceasedCauseOfDeathId == 2 &&
								euthanasiaReason == 2 &&
								Number(illnessType) != 0 &&
								!isNaN(Number(illnessType))
									? [Number(illnessType)]
									: []
						};
						AppSyncService.execute(saveDeceasedThoroughbredForm, {
							input: deceasedInput
						}).then((data) => {
							console.log(data);
							AppSyncService.execute(getDeceasedThoroughbredForm, {
								horseCode: Number(horseCode),
								memberExternalId: currentUser?.UserId
							}).then((form) => {
								console.log(form);
							});
						});
						addToast({ Message: 'Thoroughbred Unclaimed', IsSuccess: true });
					}
					navigate(DASHBOARD_ROUTE);
				} else {
					addToast({
						Message: 'An error occured unclaiming.',
						IsSuccess: false
					});
				}
			});
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Row>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Reason for Unclaiming*</div>
						<Controller
							control={control}
							name="unclaimReason"
							render={({ onChange }) => (
								<FormDropDown
									items={otherReasonOptions}
									selectedId={unclaimReason}
									showBlank
									onChange={(e) => {
										onChange(e.target.value);
										setUnclaimReason(e.target.value);
									}}
									styles={{ width: '100%' }}
								/>
							)}
						/>
						<span className={classes.errorText}>
							{errors.unclaimReason?.message}
						</span>
					</div>
				</Col>
				{checkDropDownValue(otherReasonOptions, unclaimReason, 'Other') && (
					<>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Effective Date*</div>
								<FormDatePicker
									DateValue={effectiveDate}
									updateSelection={(value) => {
										updateDate(value, setEffectiveDate, validateEffectiveDate);
									}}
								/>
							</div>
							<span className={classes.errorText}>
								{uncontrolledErrors?.EffectiveDate?.message}
							</span>
						</Col>
						<Col xs={12}>
							<Controller
								control={control}
								name="otherReason"
								render={({ onChange }) => (
									<ResizingTextArea
										title="Other Details*"
										id={`ResizingTextArea-otherReason`}
										onChange={(e) => {
											onChange(e.target.value);
											setOtherReason(e.target.value);
										}}
										value={otherReason}
									/>
								)}
							/>
							<span className={classes.errorText}>
								{errors.otherReason?.message}
							</span>
						</Col>
					</>
				)}
			</Row>
			{checkDropDownValue(otherReasonOptions, unclaimReason, 'Deceased') && (
				<>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									What is the cause of death?*
								</div>
								<Controller
									control={control}
									name="horseDeathCause"
									render={({ onChange }) => (
										<FormDropDown
											items={horseDeathReasonOptions}
											selectedId={horseDeathCause}
											onChange={(e) => {
												onChange(e.target.value);
												setHorseDeathCause(e.target.value);
											}}
											showBlank
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.horseDeathCause?.message}
								</span>
							</div>
						</Col>
					</Row>
					{checkNotEqualDropDownValue(
						horseDeathReasonOptions,
						horseDeathCause,
						''
					) && (
						<>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Date of Death*</div>
										<FormDatePicker
											DateValue={deathDate}
											updateSelection={(value) => {
												updateDate(value, setDeathDate, validateDeathDate);
											}}
										/>
									</div>
									<span className={classes.errorText}>
										{uncontrolledErrors?.DeathDate?.message}
									</span>
								</Col>
								{checkDropDownValue(
									horseDeathReasonOptions,
									horseDeathCause,
									'Euthanised'
								) && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Reason*</div>
											<Controller
												control={control}
												name="euthanasiaReason"
												render={({ onChange }) => (
													<FormDropDown
														items={euthanasiaReasons}
														selectedId={euthanasiaReason}
														showBlank
														onChange={(e) => {
															onChange(e.target.value);
															setEuthanasiaReason(e.target.value);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.euthanasiaReason?.message}
											</span>
										</div>
									</Col>
								)}
							</Row>
							{checkDropDownValue(
								horseDeathReasonOptions,
								horseDeathCause,
								'Euthanised'
							) && (
								<>
									{checkDropDownValue(
										euthanasiaReasons,
										euthanasiaReason,
										'Injury'
									) && (
										<Row>
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>Injury Type*</div>
													<Controller
														control={control}
														name="injuryType"
														render={({ onChange }) => (
															<FormDropDown
																items={euthanasiaInjuryTypes}
																selectedId={injuryType}
																onChange={(e) => {
																	onChange(e.target.value);
																	setInjuryType(e.target.value);
																}}
																showBlank
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.injuryType?.message}
													</span>
												</div>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaInjuryTypes,
										injuryType,
										'Other'
									) && (
										<Row>
											<Col xs={12}>
												<Controller
													control={control}
													name="injuryOther"
													render={({ onChange }) => (
														<ResizingTextArea
															title="Other*"
															id={`ResizingTextArea-injuryOther`}
															onChange={(e) => {
																onChange(e.target.value);
																setInjuryOther(e.target.value);
															}}
															value={injuryOther}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.injuryOther?.message}
												</span>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaReasons,
										euthanasiaReason,
										'Illness'
									) && (
										<Row>
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>Illness*</div>
													<Controller
														control={control}
														name="illnessType"
														render={({ onChange }) => (
															<FormDropDown
																items={euthanasiaIllnessTypes}
																selectedId={illnessType}
																onChange={(e) => {
																	onChange(e.target.value);
																	setIllnessType(e.target.value);
																}}
																showBlank
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.illnessType?.message}
													</span>
												</div>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaIllnessTypes,
										illnessType,
										'Other'
									) && (
										<Row>
											<Col xs={12}>
												<Controller
													control={control}
													name="illnessOther"
													render={({ onChange }) => (
														<ResizingTextArea
															title="Other*"
															id={`ResizingTextArea-illnessOther`}
															onChange={(e) => {
																onChange(e.target.value);
																setIllnessOther(e.target.value);
															}}
															value={illnessOther}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.illnessOther?.message}
												</span>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaReasons,
										euthanasiaReason,
										'Unsuitable for rehoming'
									) && (
										<Row>
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>Reason*</div>
													<Controller
														control={control}
														name="unsuitableReason"
														render={({ onChange }) => (
															<FormDropDown
																items={euthanasiaUnsuitableForReasons}
																selectedId={unsuitableReason}
																onChange={(e) => {
																	onChange(e.target.value);
																	setUnsuitableReason(e.target.value);
																}}
																showBlank
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.unsuitableReason?.message}
													</span>
												</div>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaUnsuitableForReasons,
										unsuitableReason,
										'Other'
									) && (
										<Row>
											<Col xs={12}>
												<Controller
													control={control}
													name="unsuitableOther"
													render={({ onChange }) => (
														<ResizingTextArea
															title="Other*"
															id={`ResizingTextArea-unsuitableOther`}
															onChange={(e) => {
																onChange(e.target.value);
																setUnsuitableOther(e.target.value);
															}}
															value={unsuitableOther}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.unsuitableOther?.message}
												</span>
											</Col>
										</Row>
									)}
									{checkDropDownValue(
										euthanasiaReasons,
										euthanasiaReason,
										'Other'
									) && (
										<Row>
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>Reason*</div>
													<Controller
														control={control}
														name="euthanasiaOther"
														render={({ onChange }) => (
															<FormDropDown
																items={euthanasiaOtherTypes}
																selectedId={euthanasiaOther}
																onChange={(e) => {
																	onChange(e.target.value);
																	setEuthanasiaOther(e.target.value);
																}}
																showBlank
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.euthanasiaOther?.message}
													</span>
												</div>
											</Col>
										</Row>
									)}
								</>
							)}
							{checkDropDownValue(
								horseDeathReasonOptions,
								horseDeathCause,
								'Other'
							) && (
								<Row>
									<Col xs={12}>
										<Controller
											control={control}
											name="otherDeath"
											render={({ onChange }) => (
												<ResizingTextArea
													title="Information about the death*"
													id={`ResizingTextArea-otherDeath`}
													onChange={(e) => {
														onChange(e.target.value);
														setOtherDeath(e.target.value);
													}}
													value={otherDeath}
												/>
											)}
										/>
										<span className={classes.errorText}>
											{errors.otherDeath?.message}
										</span>
									</Col>
								</Row>
							)}
							{(checkDropDownValue(
								horseDeathReasonOptions,
								horseDeathCause,
								'Knackery/Abattoir'
							) ||
								checkDropDownValue(
									horseDeathReasonOptions,
									horseDeathCause,
									'Other'
								)) && (
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Suburb</div>
											<FormTextField
												value={suburb}
												onChange={(e) => {
													setSuburb(e.target.value);
												}}
											/>
										</div>
									</Col>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Postcode</div>
											<FormTextField
												value={postcode}
												onChange={(e) => {
													setPostcode(e.target.value);
												}}
											/>
										</div>
									</Col>
								</Row>
							)}
						</>
					)}
				</>
			)}
			{showFullCircle && (
				<FullCircleInterestedBar
					fullCircleInterested={fullCircleInterested}
					setFullCircleInterested={setFullCircleInterested}
				/>
			)}
			<div className={classes.submitContainer}>
				<div
					onClick={() => navigate(DASHBOARD_ROUTE)}
					className={classes.cancelRequestLink}
				>
					Cancel Request
				</div>
				<span>
					<PrimaryButton
						id={`Submit-Primary-Button-Rehomed`}
						isNonSubmit={false}
						style={{ minWidth: '170px' }}
						onClick={() => {
							validateUncontrolledErrors();
						}}
					>
						Complete Form
					</PrimaryButton>
				</span>
			</div>
		</form>
	);
};

export default UnclaimThoroughbred;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	horseUpdatesContainer: {
		width: '100%',
		boxSizing: 'border-box',
		border: '1px solid rgb(220,235,243)',
		borderRadius: 3,
		'& > div > div > div': {
			borderBottom: '1px solid rgb(220,235,243)'
		},
		'& > div > div > div:last-child': {
			borderBottom: 'none'
		},
		overflowY: 'scroll'
	}
});

import { futureDateAfterDays } from '../../utils/helpers';

export const OHEP_NTP_FORM_DEFAULT_VALUES = {
	currentAddress: '',
	currentAddressValidated: false,
	currentCountry: '',
	currentPostcode: '',
	currentState: '',
	currentSuburb: '',
	horseAge: null,
	horseColour: 0,
	horseDam: '',
	horseMicrochip: null,
	horseName: '',
	horseOwnerEmail: '',
	horseOwnerFirstName: '',
	horseOwnerLastName: '',
	horseOwnerMobile: '',
	horseOwnerName: '',
	horseOwnerSyndicateName: '',
	horseOwnerTitle: '',
	horseSire: '',
	horseGender: '',
	letterSentDate: new Date(),
	letterSentPostDate: futureDateAfterDays(new Date(), 30),
	photos: [],
	serviceProviderAddress: '',
	serviceProviderEmail: '',
	serviceProviderFullname: '',
	serviceProviderShortName: '',
	status: 'Draft',
	typeOfService: 1
};

export const OHEP_NTP_FORM_CLEAR_VALUES = {
	horseOwnerName: '',
	spAddress: '',
	spSuburb: '',
	spPostcode: '',
	spCountry: '',
	spAddressValidated: false,
	letterSentDate: new Date(),
	letterSentPostDate: futureDateAfterDays(new Date(), 30),
	serviceProviderEmail: '',
	serviceProviderFullname: '',
	serviceProviderShortName: '',
	status: 'Draft',
	typeOfService: 0
};

export const MICROCHIP_LENGTH_ERROR_MESSAGE =
	'Microchip number must be 18 digits';

export const AGE_LENGTH_ERROR_MESSAGE = 'Age must be less than 3 digits';

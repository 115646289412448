import { APPROVED } from '../../utils/constants';
export const generatePayload = (values, horseCode, formStatus) => {
	const payload = { horseCode };
	payload.typeOfService =
		!isNaN(+values.typeOfService) && +values.typeOfService !== 0
			? +values.typeOfService
			: null;
	payload.currentAddress = values.currentAddress;
	payload.currentSuburb = values.currentSuburb;
	payload.currentPostcode = values.currentPostcode;
	payload.currentState = values.currentState;
	payload.currentCountry = values.currentCountry;
	payload.currentAddressValidated = values.currentAddressValidated;
	payload.spAddress = values.spAddress;
	payload.spSuburb = values.spSuburb;
	payload.spPostcode = values.spPostcode;
	payload.spState = values.spState;
	payload.spCountry = values.spCountry;
	payload.spAddressValidated = values.spAddressValidated;
	payload.horseAge = values.horseAge;
	payload.horseColour = values.horseColour;
	payload.horseDam = values.horseDam;
	payload.horseMicrochip = values.horseMicrochip;
	payload.horseName = values.horseName;
	payload.horseOwnerEmail = values.horseOwnerEmail;
	payload.horseOwnerFirstName = values.horseOwnerFirstName;
	payload.horseOwnerLastName = values.horseOwnerLastName;
	payload.horseOwnerMobile = values.horseOwnerMobile;
	payload.horseOwnerName = values.horseOwnerName;
	payload.horseOwnerSyndicateName = values.horseOwnerSyndicateName;
	payload.horseOwnerTitle = values.horseOwnerTitle;
	payload.horseSire = values.horseSire;
	payload.horseGender = values.horseGender;
	payload.letterSentDate = values.letterSentDate;
	payload.letterSentPostDate = values.letterSentPostDate;
	payload.serviceProviderEmail = values.serviceProviderEmail;
	payload.serviceProviderFullname = values.serviceProviderFullname;
	payload.serviceProviderShortName = values.serviceProviderShortName;
	payload.status = formStatus;
	return payload;
};

export const setFormValuesFromApiResult = (resultValues, setValue) => {
	setValue('typeOfService', resultValues.typeOfService?.id || 0);
	setValue('currentAddress', resultValues.currentAddress || '');
	setValue('currentSuburb', resultValues.currentSuburb || '');
	setValue('currentPostcode', resultValues.currentPostcode || '');
	setValue('currentState', resultValues.currentState || '');
	setValue('currentCountry', resultValues.currentCountry || '');
	setValue(
		'currentAddressValidated',
		resultValues.currentAddressValidated || false
	);
	setValue('spAddress', resultValues.spAddress || '');
	setValue('spSuburb', resultValues.spSuburb || '');
	setValue('spPostcode', resultValues.spPostcode || '');
	setValue('spState', resultValues.spState || '');
	setValue('spCountry', resultValues.spCountry || '');
	setValue('spAddressValidated', resultValues.spAddressValidated || false);

	setValue('horseAge', resultValues.horseAge || null);
	setValue('horseColour', resultValues.horseColour || '');
	setValue('horseDam', resultValues.horseDam || '');

	setValue('horseMicrochip', resultValues.horseMicrochip || null);
	setValue('horseName', resultValues.horseName || '');
	setValue('horseOwnerEmail', resultValues.horseOwnerEmail || '');
	setValue('horseOwnerFirstName', resultValues.horseOwnerFirstName || '');
	setValue('horseOwnerLastName', resultValues.horseOwnerLastName || '');
	setValue('horseOwnerMobile', resultValues.horseOwnerMobile || '');
	setValue('horseOwnerName', resultValues.horseOwnerName || '');
	setValue(
		'horseOwnerSyndicateName',
		resultValues.horseOwnerSyndicateName || ''
	);
	setValue('horseOwnerTitle', resultValues.horseOwnerTitle || '');
	setValue('horseSire', resultValues.horseSire || '');
	setValue('horseGender', resultValues.horseGender || '');
	if (resultValues.status === APPROVED) {
		setValue('letterSentDate', new Date());
		setValue('letterSentPostDate', resultValues.letterSentPostDate || '');
	}
	setValue('serviceProviderEmail', resultValues.serviceProviderEmail || '');
	setValue(
		'serviceProviderFullname',
		resultValues.serviceProviderFullname || ''
	);
	setValue(
		'serviceProviderShortName',
		resultValues.serviceProviderShortName || ''
	);
	setValue('status', resultValues.status || '');
	setValue('photos', resultValues.photos || []);
};

export const setDefaultValuesFromNominationForm = (
	resultValues,
	horseData,
	setValue
) => {
	setValue('currentAddress', resultValues.currentAddress || '');
	setValue('currentSuburb', resultValues.currentSuburb || '');
	setValue('currentPostcode', resultValues.currentPostcode || '');
	setValue('currentState', resultValues.currentState || '');
	setValue('currentCountry', resultValues.currentCountry || '');
	setValue(
		'currentAddressValidated',
		resultValues.currentAddressValidated || false
	);

	setValue('horseAge', horseData.age || null);
	setValue('horseColour', horseData.colour || '');
	setValue('horseDam', horseData.damName || '');

	setValue('horseMicrochip', horseData.microChipNumber || null);
	setValue('horseName', horseData.name || '');
	setValue('horseOwnerEmail', resultValues.member.email || '');
	setValue('horseOwnerFirstName', resultValues.member.firstName || '');
	setValue('horseOwnerLastName', resultValues.member.lastName || '');
	setValue('horseOwnerMobile', resultValues.member.phone || '');
	setValue(
		'horseOwnerName',
		resultValues.member.firstName + ' ' + resultValues.member.lastName || ''
	);
	setValue('horseSire', horseData.sireName || '');
	setValue('horseGender', horseData.sex || '');
};

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	optionBar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		boxSizing: 'border-box',
		backgroundColor: '#f5f5f5',
		paddingTop: 15,
		paddingBottom: 15,
		borderRadius: 5,
		'& > div': {
			marginBottom: 10
		},
		'@media (min-width:992px)': {
			flexDirection: 'row',
			'& > div': {
				marginBottom: 0
			}
		}
	},
	optionBarInputContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	}
}));

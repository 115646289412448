import {
	DEFAULT_COUNTRY,
	DRAFT,
	REJECTED,
	REVIEW
} from '../../utils/constants';

const filterFilesByFormStatus = (status, fileArray) => {
	if (status === DRAFT || status === REVIEW) {
		return fileArray.filter((item) => item.isDeleted !== true);
	} else if (status === REJECTED) {
		return [];
	} else return fileArray;
};

export const getDataModel = (stateVal, data, staffRoleList) => {
	let dataModel = { ...stateVal };
	dataModel = {
		// Personal Details
		firstName: {
			value: data.member?.firstName
		},
		email: {
			value: data.member?.email
		},
		lastName: {
			value: data.member?.lastName
		},
		dateOfBirth: {
			...dataModel.dateOfBirth,
			value: data.member?.dateOfBirth
				? data.member?.dateOfBirth
				: data.dateOfBirth
		},
		contactNumber: {
			...dataModel.contactNumber,
			value: data.contactNumber ? data.contactNumber : data.member?.phone
		},
		resAddress: {
			...dataModel.resAddress,
			value: data.member?.residentialAddress?.address
				? data.member?.residentialAddress?.address
				: data.residentalAddress
		},
		resSuburb: {
			...dataModel.resSuburb,
			value: data.member?.residentialAddress?.suburb
				? data.member?.residentialAddress?.suburb
				: data.residentalSuburb
		},
		resState: {
			...dataModel.resState,
			value: data.member?.residentialAddress?.state
				? data.member?.residentialAddress?.state
				: data.residentalState
		},
		resPostcode: {
			...dataModel.resPostcode,
			value: data.member?.residentialAddress?.postcode
				? data.member?.residentialAddress?.postcode
				: data.residentalPostcode
		},
		resCountry: {
			...dataModel.resCountry,
			value: data.member?.residentialAddress?.country
				? data.member?.residentialAddress?.country
				: data.residentalCountry
				? data.residentalCountry
				: DEFAULT_COUNTRY
		},
		isResidentialAddressManual: {
			value: false
		},
		isPostalAddressDifferent: {
			value:
				data.postalAddress ||
				data.postalSuburb ||
				data.postalState ||
				data.postalPostcode ||
				data.postalCountry
					? true
					: false
		},
		posAddress: {
			...dataModel.posAddress,
			value: data.postalAddress
		},
		posSuburb: {
			...dataModel.posSuburb,
			value: data.postalSuburb
		},
		posState: {
			...dataModel.posState,
			value: data.postalState
		},
		posPostcode: {
			...dataModel.posPostcode,
			value: data.postalPostcode
		},
		posCountry: {
			...dataModel.posCountry,
			value: data.postalCountry ? data.postalCountry : DEFAULT_COUNTRY
		},
		isPostalAddressManual: {
			value: false
		},
		staAddress: {
			...dataModel.staAddress,
			value: data.stableAddress
		},
		staSuburb: {
			...dataModel.staSuburb,
			value: data.stableSuburb
		},
		staState: {
			...dataModel.staState,
			value: data.stableState
		},
		staPostcode: {
			...dataModel.staPostcode,
			value: data.stablePostcode
		},
		staCountry: {
			...dataModel.staCountry,
			value: data.stableCountry ? data.stableCountry : DEFAULT_COUNTRY
		},
		isStableAddressManual: {
			value: false
		},
		isStableSameAsResidential: {
			value: data.stableAddressSameAsResidental ? true : false
		},
		preFilledAddress: {
			value: data.member?.residentialAddress?.address ? true : false
		},
		propertyRented: {
			...dataModel.propertyRented,
			value: data.propertyRented
		},
		passportDriverLicense: {
			...dataModel.passportDriverLicense,
			value:
				filterFilesByFormStatus(data.status, data.personalDetailUploads) || []
		},
		//Business Details
		companyBusiness: {
			...dataModel.companyBusiness,
			value: !!(data.businessName || data.businessType?.id)
		},
		companyBusinessName: {
			...dataModel.companyBusinessName,
			value: data.businessName
		},
		businessStructure: {
			...dataModel.businessStructure,
			value: data.businessType?.id || 0
		},
		ownAllShares: {
			...dataModel.ownAllShares,
			value: data.ownsAllCompanyShares
		},
		additionalOwnAllShares: {
			...dataModel.additionalOwnAllShares,
			value: data.companySharesOwnershipDetails
		},
		trustOwnAllShares: {
			...dataModel.trustOwnAllShares,
			value: data.trusteeHasShares
		},
		additionalTrustOwnAllShares: {
			...dataModel.additionalTrustOwnAllShares,
			value: data.trusteeFurtherDetails
		},
		trusteeName: {
			...dataModel.trusteeName,
			value: data.trusteeName
		},
		trusteeAcn: {
			...dataModel.trusteeAcn,
			value: data.trusteeAcn
		},
		partnerList: {
			...dataModel.partnerList,
			value:
				data.partners?.length > 0
					? getPartnerList(data.partners)
					: [{ partnerName: '', partnerDetails: '' }]
		},
		abn: {
			...dataModel.abn,
			value: data.abn
		},
		additionalBusinessDetails: {
			...dataModel.additionalBusinessDetails,
			value: data.additionalDetails ? data.additionalDetails : ''
		},
		additionalEmployment: {
			...dataModel.additionalEmployment,
			value: data.additionalEmployment.id ? true : false
		},
		typeOfEmployment: {
			...dataModel.typeOfEmployment,
			value: data.additionalEmployment.id ? data.additionalEmployment.id : 0
		},
		additionalEmploymentTypeDetails: {
			...dataModel.additionalEmploymentTypeDetails,
			value: data.additionalEmploymentDetails
				? data.additionalEmploymentDetails
				: ''
		},
		otherBusinessActivity: {
			...dataModel.otherBusinessActivity,
			value: data.otherActivitiesDetails ? true : false
		},
		otherBusinessActivityDetails: {
			...dataModel.otherBusinessActivityDetails,
			value: data.otherActivitiesDetails ? data.otherActivitiesDetails : ''
		},
		//Business Supplies
		veterinaryName: {
			...dataModel.veterinaryName,
			value: data.veterinaryPracticeName
		},
		veterinaryRegular: {
			...dataModel.veterinaryRegular,
			value: data.regularVeterinarian
		},
		veterinaryAddress: {
			...dataModel.veterinaryAddress,
			value: data.veterinaryAddress
		},
		veterinaryPhone: {
			...dataModel.veterinaryPhone,
			value: data.veterinaryPhone
		},
		veterinaryEmail: {
			...dataModel.veterinaryEmail,
			value: data.veterinaryEmail
		},
		farrier: {
			...dataModel.farrier,
			value: data.farrier
		},
		farrierPhone: {
			...dataModel.farrierPhone,
			value: data.farrierPhone
		},
		farrierEmail: {
			...dataModel.farrierEmail,
			value: data.farrierEmail
		},
		// Credit References
		creditRef: {
			...dataModel.creditRef,
			value: data.creditReferencesUploads || []
		},
		// Staff
		anyStaff: {
			...dataModel.anyStaff,
			value: data.staffs?.length > 0 ? true : false
		},
		staffList: {
			...dataModel.staffList,
			value:
				data.staffs?.length > 0
					? getStaffList(data.staffs)
					: [{ fullName: '', roleId: 0, roleOther: null }]
		},
		//Insurance
		publicLiabilityExpiry: {
			...dataModel.publicLiabilityExpiry,
			value: data.liabilityExpiryDate
		},
		publicLiabilityCoverage: {
			...dataModel.publicLiabilityCoverage,
			value: data.liabilityCoverage ? data.liabilityCoverage : ''
		},
		publicLiabilityPolicyNumber: {
			...dataModel.publicLiabilityPolicyNumber,
			value: data.liabilityPolicyNumber
		},
		publicLiabilityInsurerName: {
			...dataModel.publicLiabilityInsurerName,
			value: data.publicLiabilityInsurerName || null
		},
		publicLiabilityInsuredPartyName: {
			...dataModel.publicLiabilityInsuredPartyName,
			value: data.publicLiabilityInsuredPartyName || null
		},
		professionalIndemnity: {
			...dataModel.professionalIndemnity,
			value: data.professionalIndemnity ? true : false
		},
		indemnityInsurerName: {
			...dataModel.indemnityInsurerName,
			value: data.indemnityInsurerName || null
		},
		indemnityInsuredPartyName: {
			...dataModel.indemnityInsuredPartyName,
			value: data.indemnityInsuredPartyName || null
		},
		professionalIndemnityPolicyNumber: {
			...dataModel.professionalIndemnityPolicyNumber,
			value: data.indemnityPolicyNumber
		},
		professionalIndemnityExpiry: {
			...dataModel.professionalIndemnityExpiry,
			value: data.indemnityExpiryDate
		},
		professionalIndemnityCoverage: {
			...dataModel.professionalIndemnityCoverage,
			value: data.indemnityCoverage ? data.indemnityCoverage : ''
		},
		workerCompensation: {
			...dataModel.workerCompensation,
			value:
				data.workersCompensationPolicyNumber ||
				data.workersCompensationInsuredPartyName ||
				data.workersCompensationExpiryDate ||
				data.workersCompensationCoverage
					? true
					: false
		},
		workerCompensationExpiry: {
			...dataModel.workerCompensationExpiry,
			value: data.workersCompensationExpiryDate
		},
		workersCompensationInsuredPartyName: {
			...dataModel.workersCompensationInsuredPartyName,
			value: data.workersCompensationInsuredPartyName || null
		},
		workerCompensationPolicyNumber: {
			...dataModel.workerCompensationPolicyNumber,
			value: data.workersCompensationPolicyNumber
		},
		workerCompensationCoverage: {
			...dataModel.workerCompensationCoverage,
			value: data.workersCompensationCoverage
				? data.workersCompensationCoverage
				: ''
		},
		insuranceCertificateOfCurrency: {
			...dataModel.insuranceCertificateOfCurrency,
			value:
				filterFilesByFormStatus(
					data.status,
					data.insuranceCertificateOfCurrencyUploads
				) || []
		},
		//Experience
		ridingComExperience: {
			...dataModel.ridingComExperience,
			value: data.ridingExperience ? data.ridingExperience : ''
		},
		listMembership: {
			...dataModel.listMembership,
			value: getMemberShips(data.memberships)
		},
		otherMembership: {
			...dataModel.otherMembership,
			value: data.membershipsOther ? data.membershipsOther : ''
		},
		ackFarmSupplierQuestion: {
			...dataModel.ackFarmSupplierQuestion,
			value: data.ackFarmSupplierQuestion ? data.ackFarmSupplierQuestion : ''
		},
		expectationFromRVQuestion: {
			...dataModel.expectationFromRVQuestion,
			value: data.expectationFromRVQuestion
				? data.expectationFromRVQuestion
				: ''
		},
		hadFormalAgreementForAgistmentQuestion: {
			...dataModel.hadFormalAgreementForAgistmentQuestion,
			value: data.hadFormalAgreementForAgistmentQuestion
				? data.hadFormalAgreementForAgistmentQuestion
				: ''
		},
		outlineApproachQuestion: {
			...dataModel.outlineApproachQuestion,
			value: data.outlineApproachQuestion ? data.outlineApproachQuestion : ''
		},
		outlineResponsibilityQuestion: {
			...dataModel.outlineResponsibilityQuestion,
			value: data.outlineResponsibilityQuestion
				? data.outlineResponsibilityQuestion
				: ''
		},
		clientRefUploads: {
			...dataModel.clientRefUploads,
			value: data.clientRefUploads || []
		},
		agistmentFacilitiesUploads: {
			...dataModel.agistmentFacilitiesUploads,
			value: data.agistmentFacilitiesUploads || []
		},
		//Current
		currentLicenseType: {
			...dataModel.currentLicenseType,
			value: data.licenceType?.id ? data.licenceType?.id : 0
		},
		currentLicenseNumber: {
			...dataModel.currentLicenseNumber,
			value: data.licence
		},
		currentLicenseStartDate: {
			...dataModel.currentLicenseStartDate,
			value: data.licenceStart
		},
		//Previous
		previousLicenseType: {
			...dataModel.previousLicenseType,
			value: data.previousLicenceType?.id ? data.previousLicenceType?.id : 0
		},
		previousLicenseNumber: {
			...dataModel.previousLicenseNumber,
			value: data.previousLicence
		},
		previousLicenseStartDate: {
			...dataModel.previousLicenseStartDate,
			value: data.previousLicenceStart
		},
		//Declaration
		subjectedToAnimalWelfare: {
			...dataModel.subjectedToAnimalWelfare,
			value: data.hadRSPCAInvestigation ? true : false
		},
		subjectedToAnimalWelfareDetails: {
			...dataModel.subjectedToAnimalWelfareDetails,
			value: data.rspcaInvestigationDetails
				? data.rspcaInvestigationDetails
				: ''
		},
		anyBan: {
			...dataModel.anyBan,
			value: data.isBannedByAnyRacingAuthority ? true : false
		},
		anyBanDetails: {
			...dataModel.anyBanDetails,
			value: data.bannedByAnyRacingAuthorityDetails
				? data.bannedByAnyRacingAuthorityDetails
				: ''
		},
		disqualified: {
			...dataModel.disqualified,
			value: data.disqualified ? true : false
		},
		disqualifiedDetails: {
			...dataModel.disqualifiedDetails,
			value: data.disqualificationDetails ? data.disqualificationDetails : ''
		},
		guiltyOfOffense: {
			...dataModel.guiltyOfOffense,
			value: data.wasCharged ? true : false
		},
		guiltyOfOffenseDetails: {
			...dataModel.guiltyOfOffenseDetails,
			value: data.chargeDetails ? data.chargeDetails : ''
		},
		anyCriminalCharges: {
			...dataModel.anyCriminalCharges,
			value: data.currentCriminalProceedings ? true : false
		},
		anyCriminalChargesDetails: {
			...dataModel.anyCriminalChargesDetails,
			value: data.criminalProceedingDetails
				? data.criminalProceedingDetails
				: ''
		},
		bankruptcy: {
			...dataModel.bankruptcy,
			value: data.bankruptcy ? true : false
		},
		bankruptcyDetails: {
			...dataModel.bankruptcyDetails,
			value: data.bankruptcyDetails ? data.bankruptcyDetails : ''
		},
		//Acknowledged
		supportPromoteEquineWelfare: {
			...dataModel.supportPromoteEquineWelfare,
			value: data.acknowledgesEquineWelfare ? true : false
		},
		policeRecordCert: {
			...dataModel.policeRecordCert,
			value: data.acknowledgesNationalPoliceRecordCertificate ? true : false
		},
		criminalCharges: {
			...dataModel.criminalCharges,
			value: data.acknowledgesCriminalCharges ? true : false
		},
		personalBankruptcy: {
			...dataModel.personalBankruptcy,
			value: data.acknowledgesBankruptcy ? true : false
		},
		readAndAgree: {
			...dataModel.readAndAgree,
			value: data.acknowledgesAgreement ? true : false
		},
		utiliseToSubmitReport: {
			...dataModel.utiliseToSubmitReport,
			value: data.acknowledgesToUtiliseOttCommunity ? true : false
		},
		declareApplicationTrueAndCorrect: {
			...dataModel.declareApplicationTrueAndCorrect,
			value: data.acknowledgesCorrectApplicationDetails ? true : false
		},
		status: {
			value: data.status
		}
	};

	dataModel.staffList.conditions[0].dependantArray = staffRoleList;

	return dataModel;
};

export const setDataModel = (values, memberId) => {
	let dataModel = {};
	dataModel.memberExternalId = memberId;
	dataModel.dateOfBirth = values.dateOfBirth.value;
	dataModel.contactNumber = values.contactNumber.value;
	dataModel.residentalAddress = values.resAddress.value;
	dataModel.residentalSuburb = values.resSuburb.value;
	dataModel.residentalPostcode = values.resPostcode.value;
	dataModel.residentalState = values.resState.value;
	dataModel.residentalCountry = values.resCountry.value;
	dataModel.postalAddress = values.isPostalAddressDifferent.value
		? values.posAddress.value
		: values.resAddress.value;
	dataModel.postalSuburb = values.isPostalAddressDifferent.value
		? values.posSuburb.value
		: values.resSuburb.value;
	dataModel.postalPostcode = values.isPostalAddressDifferent.value
		? values.posPostcode.value
		: values.resPostcode.value;
	dataModel.postalState = values.isPostalAddressDifferent.value
		? values.posState.value
		: values.resState.value;
	dataModel.postalCountry = values.isPostalAddressDifferent.value
		? values.posCountry.value
		: values.resCountry.value;
	dataModel.stableAddressSameAsResidental =
		values.isStableSameAsResidential.value;
	dataModel.stableAddress = values.isStableSameAsResidential.value
		? values.resAddress.value
		: values.staAddress.value;
	dataModel.stableSuburb = values.isStableSameAsResidential.value
		? values.resSuburb.value
		: values.staSuburb.value;
	dataModel.stablePostcode = values.isStableSameAsResidential.value
		? values.resPostcode.value
		: values.staPostcode.value;
	dataModel.stableState = values.isStableSameAsResidential.value
		? values.resState.value
		: values.staState.value;
	dataModel.stableCountry = values.isStableSameAsResidential.value
		? values.resCountry.value
		: values.staCountry.value;
	dataModel.agentName = null;
	dataModel.businessName = values.companyBusinessName.value;
	dataModel.abn = values.abn.value;
	dataModel.additionalDetails = values.additionalBusinessDetails.value;
	dataModel.additionalEmploymentId =
		values.typeOfEmployment.value === 0 ? null : values.typeOfEmployment.value;
	dataModel.additionalEmploymentDetails =
		values.additionalEmploymentTypeDetails.value;
	dataModel.otherActivitiesDetails = values.otherBusinessActivityDetails.value;
	dataModel.veterinaryPracticeName = values.veterinaryName.value;
	dataModel.regularVeterinarian = values.veterinaryRegular.value;
	dataModel.veterinaryAddress = values.veterinaryAddress.value;
	dataModel.veterinaryPhone = values.veterinaryPhone.value;
	dataModel.veterinaryEmail = values.veterinaryEmail.value;
	dataModel.farrier = values.farrier.value;
	dataModel.farrierPhone = values.farrierPhone.value;
	dataModel.farrierEmail = values.farrierEmail.value;
	dataModel.staffs = setStaffList(values.staffList.value);
	dataModel.professionalIndemnity = values.professionalIndemnity.value;
	dataModel.publicLiabilityInsurerName =
		values.publicLiabilityInsurerName.value;
	dataModel.publicLiabilityInsuredPartyName =
		values.publicLiabilityInsuredPartyName.value;
	dataModel.liabilityPolicyNumber = values.publicLiabilityPolicyNumber.value;
	dataModel.liabilityExpiryDate = values.publicLiabilityExpiry.value;
	dataModel.liabilityCoverage = values.publicLiabilityCoverage.value;
	dataModel.indemnityInsurerName = values.indemnityInsurerName.value;
	dataModel.indemnityInsuredPartyName = values.indemnityInsuredPartyName.value;
	dataModel.indemnityPolicyNumber =
		values.professionalIndemnityPolicyNumber.value;
	dataModel.indemnityExpiryDate = values.professionalIndemnityExpiry.value;
	dataModel.indemnityCoverage = values.professionalIndemnityCoverage.value;
	dataModel.workersCompensationInsuredPartyName =
		values.workersCompensationInsuredPartyName.value;
	dataModel.workersCompensationPolicyNumber =
		values.workerCompensationPolicyNumber.value;
	dataModel.workersCompensationExpiryDate =
		values.workerCompensationExpiry.value;
	dataModel.workersCompensationCoverage =
		values.workerCompensationCoverage.value;
	dataModel.ridingExperience = values.ridingComExperience.value;
	dataModel.ackFarmSupplierQuestion = values.ackFarmSupplierQuestion.value;
	dataModel.expectationFromRVQuestion = values.expectationFromRVQuestion.value;
	dataModel.hadFormalAgreementForAgistmentQuestion =
		values.hadFormalAgreementForAgistmentQuestion.value;
	dataModel.outlineApproachQuestion = values.outlineApproachQuestion.value;
	dataModel.outlineResponsibilityQuestion =
		values.outlineResponsibilityQuestion.value;
	dataModel.membershipIds = setMembershipList(values.listMembership.value);
	dataModel.membershipsOther = values.otherMembership.value;
	dataModel.licenceTypeId =
		values.currentLicenseType.value === 0
			? null
			: values.currentLicenseType.value;
	dataModel.licence = values.currentLicenseNumber.value;
	dataModel.licenceStart = values.currentLicenseStartDate.value;
	dataModel.previousLicenceTypeId =
		values.previousLicenseType.value === 0
			? null
			: values.previousLicenseType.value;
	dataModel.previousLicence = values.previousLicenseNumber.value;
	dataModel.previousLicenceStart = values.previousLicenseStartDate.value;
	dataModel.hadRSPCAInvestigation = values.subjectedToAnimalWelfare.value;
	dataModel.isBannedByAnyRacingAuthority = values.anyBan.value;
	dataModel.disqualified = values.disqualified.value;
	dataModel.wasCharged = values.guiltyOfOffense.value;
	dataModel.currentCriminalProceedings = values.anyCriminalCharges.value;
	dataModel.bankruptcy = values.bankruptcy.value;
	dataModel.rspcaInvestigationDetails =
		values.subjectedToAnimalWelfareDetails.value;
	dataModel.bannedByAnyRacingAuthorityDetails = values.anyBanDetails.value;
	dataModel.disqualificationDetails = values.disqualifiedDetails.value;
	dataModel.chargeDetails = values.guiltyOfOffenseDetails.value;
	dataModel.criminalProceedingDetails = values.anyCriminalChargesDetails.value;
	dataModel.bankruptcyDetails = values.bankruptcyDetails.value;
	dataModel.acknowledgesEquineWelfare =
		values.supportPromoteEquineWelfare.value;
	dataModel.acknowledgesNationalPoliceRecordCertificate =
		values.policeRecordCert.value;
	dataModel.acknowledgesCriminalCharges = values.criminalCharges.value;
	dataModel.acknowledgesBankruptcy = values.personalBankruptcy.value;
	dataModel.acknowledgesAgreement = values.readAndAgree.value;
	dataModel.acknowledgesToUtiliseOttCommunity =
		values.utiliseToSubmitReport.value;
	dataModel.acknowledgesCorrectApplicationDetails =
		values.declareApplicationTrueAndCorrect.value;
	dataModel.businessTypeId =
		values.businessStructure.value === 0
			? null
			: values.businessStructure.value;
	dataModel.ownsAllCompanyShares = values.ownAllShares.value;
	dataModel.trusteeHasShares = values.trustOwnAllShares.value;
	dataModel.trusteeFurtherDetails = values.additionalTrustOwnAllShares.value;
	dataModel.trusteeName = values.trusteeName.value;
	dataModel.trusteeAcn = values.trusteeAcn.value;
	dataModel.partners = setPartnerList(values.partnerList.value);
	dataModel.companySharesOwnershipDetails = values.additionalOwnAllShares.value;
	dataModel.propertyRented = values.propertyRented.value;

	return dataModel;
};

const setStaffList = (staff) => {
	let staffList = [];
	staff.map((s) => {
		if (
			(s.fullName != null || s.fullName != '') &&
			parseInt(s.roleId) != 0 &&
			(s.roleOther != null || s.roleOther != '')
		) {
			staffList.push({
				fullName: s.fullName,
				roleId: parseInt(s.roleId) === 0 ? null : parseInt(s.roleId),
				roleOther: s.roleOther
			});
		}
	});

	return staffList;
};

const setPartnerList = (partners) => {
	let partnerList = [];
	partners.map((s) => {
		if (s.partnerName || s.partnerDetails) {
			partnerList.push({
				name: s.partnerName,
				contactDetails: s.partnerDetails
			});
		}
	});

	return partnerList;
};

const setMembershipList = (memberships) => {
	let membership = [];
	memberships.map((val) => {
		membership.push(val.value);
	});
	return membership;
};

const getMemberShips = (memberships) => {
	let membership = [];
	memberships.map((val) => {
		membership.push({ value: val.id, label: val.value });
	});
	return membership;
};

const getStaffList = (staff) => {
	let staffList = [];
	staff.map((s) => {
		staffList.push({
			fullName: s.fullName,
			roleId: parseInt(s.role.id),
			roleOther: s.roleOther
		});
	});

	return staffList;
};

const getPartnerList = (partners) => {
	let partnerList = [];
	partners.map((s) => {
		partnerList.push({
			partnerName: s.name,
			partnerDetails: s.contactDetails
		});
	});

	return partnerList;
};

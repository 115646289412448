import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import LoaderSpinner from '../LoaderSpinner';
import FormTextArea from '../FormTextArea';
import FormDropDown from '../FormDropDown';
import HeaderBanner from '../HeaderBanner';

const AdminActions = ({
	reviewStatus,
	actionMemberId,
	adminStatusId,
	outComeId,
	memberList,
	statusList,
	outComeList,
	formName,
	reviewNotes,
	updateFields,
	isAdminActionUpdated,
	updateReviewNotes,
	isLoading,
	formError,
	error,
	completeForm,
	updateAdminAction,
	noBorderBottom,
	retirementCategory,
	isShowMiniLoader = true,
	updateReasonForDecline,
	reasonForDecline
}) => {
	const classes = useStyles();
	const REVIEWED = 'Reviewed';
	const REJECTED = 'Rejected';
	const APPROVED = 'Approved';
	return (
		<>
			<HeaderBanner
				title="Additional Notes (Admin Only)"
				inline
				type="primary"
				styles={
					formName === 'SponsorshipOutcomeForm'
						? {
								marginBottom: '0px',
								marginTop: '0px',
								backgroundSize: 'cover',
								maxWidth: 'none'
						  }
						: { marginBottom: '0px', marginTop: '0px' }
				}
			></HeaderBanner>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div>
					<div
						className={classes.adminActionContainer}
						style={noBorderBottom ? { borderBottom: 'none' } : {}}
					>
						<div className={classes.notesContainer}>
							<Row>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div>
										<div className={classes.fieldTitle}>{`Actioning${
											formName === 'SponsorshipOutcomeForm' ? '*' : ''
										}`}</div>
									</div>
									<FormDropDown
										showBlank
										id={`${formName}-memberActioningId`}
										items={memberList}
										selectedId={actionMemberId}
										onChange={(e) => updateFields('memberId', e.target.value)}
										disabled={
											reviewStatus === REVIEWED ||
											reviewStatus === APPROVED ||
											reviewStatus === REJECTED
										}
									/>
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>Admin status</div>
									<FormDropDown
										showBlank
										id={`${formName}-adminStatus`}
										items={statusList}
										selectedId={adminStatusId}
										onChange={(e) =>
											updateFields('adminStatus', e.target.value)
										}
										disabled={
											reviewStatus === REVIEWED ||
											reviewStatus === APPROVED ||
											reviewStatus === REJECTED
										}
									/>
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>
										{formName !== 'SponsorshipOutcomeForm'
											? 'Outcome'
											: 'Sponsorship Review Outcome*'}
									</div>
									<FormDropDown
										showBlank
										name="typeOfEmployment"
										id={`${formName}-outcome`}
										items={outComeList}
										selectedId={outComeId}
										onChange={(e) => updateFields('outcome', e.target.value)}
										disabled={
											reviewStatus === REVIEWED ||
											reviewStatus === APPROVED ||
											reviewStatus === REJECTED
										}
									/>
									{formName === 'SponsorshipOutcomeForm'
										? formError.sponsorshipReviewOutcome.value && (
												<span className={classes.errorMessage}>
													{formError.sponsorshipReviewOutcome.message}
												</span>
										  )
										: null}
								</Col>
							</Row>
						</div>
						<hr
							className={classes.divider}
							style={{ background: '#e7eff3' }}
						></hr>
						{retirementCategory ? retirementCategory : null}
						<Row>
							<Col
								className={classes.notesContainer}
								style={{ marginLeft: 20 }}
							>
								<div className={classes.fieldTitle}>Notes</div>
								<FormTextArea
									value={reviewNotes}
									onChange={(e) => {
										updateReviewNotes(e.target.value);
									}}
									disabled={
										reviewStatus === REVIEWED ||
										reviewStatus === APPROVED ||
										reviewStatus === REJECTED
									}
								/>
							</Col>
						</Row>
						{formName === 'SponsorshipOutcomeForm' ? (
							<Row>
								<Col
									className={classes.notesContainer}
									style={{ marginLeft: 20 }}
								>
									<div className={classes.fieldTitle}>
										Reason for Decline (Required if Declining form - This is a
										Notification to the User)
									</div>
									<FormTextArea
										value={reasonForDecline}
										onChange={(e) => {
											updateReasonForDecline(e.target.value);
										}}
										disabled={
											reviewStatus === REVIEWED ||
											reviewStatus === APPROVED ||
											reviewStatus === REJECTED
										}
									/>
								</Col>
							</Row>
						) : null}
						<Row>
							<Col>
								{error && <span className={classes.errorMessage}>{error}</span>}
							</Col>
							{formName === 'SponsorshipOutcomeForm' ? (
								<Col>
									<div
										className={classes.saveRequest}
										style={{ marginRight: 15 }}
									>
										<span style={{ float: 'right' }}>
											<input
												style={{ marginRight: 20 }}
												type="button"
												value={
													isAdminActionUpdated ? 'Save Admin Details' : 'Saved'
												}
												className={
													isAdminActionUpdated
														? classes.primaryFormButton
														: classes.disabledFormButton
												}
												onClick={
													isAdminActionUpdated
														? () => updateAdminAction()
														: () => {}
												}
											/>
										</span>
									</div>
								</Col>
							) : formName === 'ThoroughbredUnRegistered' ? (
								<Col>
									<div
										className={classes.saveRequest}
										style={{ marginRight: 15 }}
									>
										<span style={{ float: 'right' }}>
											<input
												style={
													formName === 'deceasedThoroughbred'
														? { marginRight: 20 }
														: {}
												}
												type="button"
												value={
													reviewStatus !== REVIEWED
														? 'Save Admin Details'
														: 'Saved'
												}
												className={
													reviewStatus !== REVIEWED
														? classes.primaryFormButton
														: classes.disabledFormButton
												}
												onClick={
													reviewStatus !== REVIEWED
														? () => updateAdminAction()
														: () => {}
												}
											/>
										</span>
									</div>
								</Col>
							) : (
								<Col>
									<div
										className={classes.saveRequest}
										style={
											formName === 'ThoroughbredUnRegistered'
												? { marginRight: 15 }
												: {}
										}
									>
										<span style={{ float: 'right' }}>
											<input
												style={
													formName === 'deceasedThoroughbred'
														? { marginRight: 20 }
														: {}
												}
												type="button"
												value={
													isAdminActionUpdated ? 'Save Admin Details' : 'Saved'
												}
												className={
													isAdminActionUpdated
														? classes.primaryFormButton
														: classes.disabledFormButton
												}
												onClick={() => updateAdminAction()}
											/>
										</span>
										{isLoading && isShowMiniLoader && (
											<span style={{ float: 'right', marginTop: 10 }}>
												<LoaderSpinner
													status={true}
													height="22"
													color="cadetblue"
												/>
											</span>
										)}
									</div>
								</Col>
							)}
						</Row>
					</div>
					{completeForm && formName !== 'SponsorshipOutcomeForm' && (
						<Row>
							<Col>
								{formError && (
									<span className={classes.errorMessage}>{formError}</span>
								)}
							</Col>
							{formName === 'ThoroughbredUnRegistered' ? (
								<Col
									style={
										formName === 'ThoroughbredUnRegistered'
											? { display: 'flex', justifyContent: 'flex-start' }
											: {}
									}
								>
									<div
										className={classes.saveRequest}
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<span>
											<input
												type="button"
												value="Create OTTC thoroughbred record"
												style={{ width: 270 }}
												className={
													reviewStatus === REVIEWED &&
													isAdminActionUpdated &&
													outComeId != 6
														? classes.primaryFormButtonComplete
														: classes.disabledFormButtonComplete
												}
												onClick={() => {
													console.log('COMPLETE');
												}}
												disabled={
													reviewStatus !== REVIEWED &&
													!isAdminActionUpdated &&
													outComeId == 6
												}
											/>
										</span>
									</div>
									<div
										className={classes.saveRequest}
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<span>
											<input
												type="button"
												value="Complete"
												className={
													outComeId == 0 ||
													adminStatusId == 0 ||
													reviewStatus === REVIEWED
														? classes.disabledFormButtonComplete
														: classes.primaryFormButtonComplete
												}
												onClick={() => completeForm(REVIEWED)}
												disabled={
													outComeId == 0 ||
													adminStatusId == 0 ||
													reviewStatus === REVIEWED
												}
											/>
										</span>
									</div>
								</Col>
							) : (
								<Col>
									<div
										className={classes.saveRequest}
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<span>
											<input
												type="button"
												value="Complete"
												style={
													formName === 'deceasedThoroughbred'
														? { marginRight: 20 }
														: {}
												}
												className={
													reviewStatus === REVIEWED && !isAdminActionUpdated
														? classes.disabledFormButtonComplete
														: classes.primaryFormButtonComplete
												}
												onClick={() => completeForm(REVIEWED)}
												disabled={
													reviewStatus === REVIEWED && !isAdminActionUpdated
												}
											/>
										</span>
									</div>
								</Col>
							)}
						</Row>
					)}
					{formName === 'SponsorshipOutcomeForm' ? (
						<Row>
							<Col>
								<div
									className={classes.saveRequest}
									style={{ marginTop: 20, marginBottom: 20 }}
								>
									<span>
										<input
											type="button"
											value="Decline"
											style={
												!reasonForDecline
													? {}
													: { backgroundColor: '#DCEBF3', color: '#006DA6' }
											}
											className={
												!reasonForDecline
													? classes.disabledFormButtonComplete
													: classes.primaryFormButtonComplete
											}
											onClick={() => completeForm(REJECTED)}
											disabled={!reasonForDecline}
										/>
									</span>

									<span>
										<input
											type="button"
											value="Approve"
											style={
												formName === 'SponsorshipOutcomeForm'
													? { marginRight: 20 }
													: {}
											}
											className={
												reasonForDecline ||
												reviewStatus === APPROVED ||
												reviewStatus === REJECTED
													? classes.disabledFormButtonComplete
													: classes.primaryFormButtonComplete
											}
											onClick={() => completeForm(APPROVED)}
											disabled={
												reasonForDecline ||
												reviewStatus === APPROVED ||
												reviewStatus === REJECTED
											}
										/>
									</span>
								</div>
							</Col>
						</Row>
					) : null}
				</div>
			</div>
		</>
	);
};
export default AdminActions;

import React, { useEffect, useState } from 'react';
import useStyles from './styles.js';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import AppSyncService from '../../graphql/AppSyncService.js';
import {
	dismissNotification,
	updateCompetitionAdminDismissableNotif,
	saveRetirementDismissableNotification
} from '../../graphql/custom.queries.js';
import { getMemberData } from '../Dashboard/graphql/queries.js';
import { useAuth } from '../../hooks/useAuth';
import Notifications from '../Dashboard/Components/Notifications/index.js';
import { Box, TablePagination } from '@material-ui/core';
import { logToServer } from '../../utils/logger/index.js';
import { useToast } from '../../hooks/useToast';
import {
	COMPETITION_ADMIN_LINK,
	COMPETITION_ADMIN,
	DISMISS,
	IS_REMOVE_IDENTIFIER,
	REMOVE_COMPETITION_ADMIN,
	ACKNOWLEDGED_RETIREMENT,
	ACK_FOSTER,
	REMOVE_ACK_FOSTER,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	FOSTER_FORMS,
	HORSE_CLAIM_STAR,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	RETRAINER_NOTIF,
	BUSINESS_PROFILE
} from '../../utils/constants';

const PAGE_ID = 'View-All-Notifications';

const ViewNotifications = () => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const [userNotifications, setUserNotifications] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const { addToast } = useToast();

	const fetchMemberData = async () => {
		if (currentUser?.UserId) {
			try {
				const result = await AppSyncService.execute(getMemberData, {
					externalId: currentUser.UserId
				});
				if (result?.data?.getMember) {
					let filteredMemberNotifications = result.data.getMember.notifications.filter(
						(memberNotif) =>
							!(
								(memberNotif.type === DISMISS &&
									memberNotif.link === '/contacts') ||
								memberNotif.link === ''
							) &&
							(memberNotif.link !== COMPETITION_ADMIN_LINK || !memberNotif.link)
					);
					let finalFilteredMemberNotifications = [];
					filteredMemberNotifications.forEach((filteredMemberNotif) => {
						if (
							!(
								(filteredMemberNotif.type === COMPETITION_ADMIN &&
									filteredMemberNotif.identifiers.find(
										(memberIdentifier) =>
											memberIdentifier.id === IS_REMOVE_IDENTIFIER
									)?.value === 'true') ||
								(filteredMemberNotif.type === ACK_FOSTER &&
									filteredMemberNotif.identifiers.find(
										(memberIdentifier) =>
											memberIdentifier.id === IS_REMOVE_IDENTIFIER
									)?.value === 'true')
							)
						) {
							finalFilteredMemberNotifications.push(filteredMemberNotif);
						}
					});

					const isFosterRole = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
					);

					const isRetrainerRole = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_RV_ACKNOWLEDGED_RETRAINER
					);

					if (!isRetrainerRole) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(notif) =>
								!(
									(notif.type === BUSINESS_PROFILE &&
										notif?.identifiers?.find(
											(identifier) => identifier.id === 'businessType'
										).value == 1) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.ACK_RETRAINER_APPLICATION_APPROVED
									) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.ACK_RETRAINER_APPLICATION_DECLINED
									) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.HORSE_AVAIABLE_FOR_RETRAINING
									) ||
									notif?.identifiers?.find((memberIdentifier) =>
										memberIdentifier.value.includes(
											RETRAINER_NOTIF.ACK_RETRAINER_SUBMITTED_REPORT
										)
									)
								)
						);
					}

					if (!isFosterRole) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(notif) =>
								!(
									notif?.message?.includes(
										FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.STAR_WEEKLY_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.STAR_INITIAL_ASSESSMENT
									) ||
									notif.type === HORSE_CLAIM_STAR
								)
						);
					}
					//check if there's a dismissable notif in localstorage to be filtered
					let storedNotif = JSON.parse(
						localStorage.getItem('notificationListIds')
					);
					if (storedNotif) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(item) => !storedNotif.includes(item.id)
						);
					}
					setUserNotifications(finalFilteredMemberNotifications);
				} else {
					addToast({
						Message: 'Failed to get notifications',
						IsSuccess: false
					});
				}
			} catch (error) {
				addToast({ Message: 'Failed to get notifications', IsSuccess: false });
				logToServer(
					currentUser?.UserId,
					error,
					`View Notifications => getMemberData`
				);
			}
		}
	};

	useEffect(() => {
		fetchMemberData();
	}, []);

	const removeNotification = (id) => {
		AppSyncService.execute(dismissNotification, { notificationId: +id }).then(
			(result) => {
				if (result?.data?.dismissNotification) {
					setUserNotifications((prevState) =>
						prevState.filter((notification) => notification.id != id)
					);
				}
			}
		);
	};

	const tempRemoveNotification = (id) => {
		const filteredNotif = userNotifications.filter((item) => item.id !== id);
		let storedNotif = JSON.parse(localStorage.getItem('notificationListIds'));
		if (storedNotif) {
			storedNotif = [...storedNotif, id];
		} else {
			storedNotif = [id];
		}
		localStorage.setItem('notificationListIds', JSON.stringify(storedNotif));
		setUserNotifications([...filteredNotif]);
	};

	const updateAckFosterNofitcation = async (id, type) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: parseInt(id),
					source: REMOVE_ACK_FOSTER,
					link: ''
				}
			);
			if (result?.data) {
				const filteredMemberNotifications = userNotifications.filter(
					(userNotif) => userNotif.type !== type
				);
				setUserNotifications(filteredMemberNotifications);
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateCompetitionAdminNofitcation`
			);
		}
	};

	const updateCompetitionAdminNofitcation = async (id, type) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: parseInt(id),
					source: REMOVE_COMPETITION_ADMIN,
					link: ''
				}
			);
			if (result?.data) {
				const filteredMemberNotifications = userNotifications.filter(
					(userNotif) => userNotif.type !== type
				);
				setUserNotifications(filteredMemberNotifications);
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateCompetitionAdminNofitcation`
			);
		}
	};

	const changePage = (event, page) => {
		setCurrentPage(page);
	};

	const updateRetirementAdminNotification = async (id, type, link) => {
		try {
			console.log(link + id);
			await AppSyncService.execute(saveRetirementDismissableNotification, {
				externalId: currentUser.UserId,
				source: type,
				link: ACKNOWLEDGED_RETIREMENT,
				reportId: id
			});
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => retirementdismissableNotifResult`
			);
		}
	};

	return (
		<PageContainer>
			<SEO title={`All Notifications (${userNotifications.length})`} />
			<HeaderBanner
				title={`All Notifications (${userNotifications.length})`}
				styles={{ marginBottom: '0px' }}
			/>
			<div className={classes.container} id={`${PAGE_ID}-container`}>
				{Notifications(
					true,
					userNotifications,
					removeNotification,
					updateCompetitionAdminNofitcation,
					updateRetirementAdminNotification,
					updateAckFosterNofitcation,
					tempRemoveNotification,
					true,
					currentPage
				)}
			</div>
			<div className={classes.paginationContainer}>
				<Box m="auto">
					<TablePagination
						rowsPerPageOptions={[10]}
						rowsPerPage={10}
						count={userNotifications.length}
						page={currentPage}
						onPageChange={changePage}
					/>
				</Box>
			</div>
		</PageContainer>
	);
};

export default ViewNotifications;

import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import { useToast } from '../../hooks/useToast';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';
import SaveCancelButton from '../SaveCancelButton';
import FormDatePicker from '../FormDatePicker';
import MessageBar from '../MessageBar';
import SimpleRadioGroup from '../SimpleRadioGroup';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import {
	expressHorseOptionList,
	saveRetrainerHorseStatus,
	saveRetrainerRehab
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import { sortArray, formatDate, isOptionOther } from '../../utils/helpers';
import LoaderSpinner from '../LoaderSpinner';

const AcknowledgedResetExpressChangeStatus = ({
	horseCode,
	selectedStatus,
	setSelectedForm
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [selectedOption, setSelectedOption] = useState(0);
	const [illness, setIllness] = useState(0);
	const [illnessOther, setIllnessOther] = useState('');
	const [injury, setInjury] = useState(0);
	const [injuryOther, setInjuryOther] = useState('');
	const [otherText, setOtherText] = useState('');
	const [error, setError] = useState('');
	const [date, setDate] = useState(null);
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	let isValidationErrors = false;

	useEffect(() => {
		AppSyncService.execute(expressHorseOptionList).then((data) => {
			try {
				let sortedInjuryList = sortArray(data.data.listInjuryDetails);
				setInjuryOptions(sortedInjuryList);
				let sortedIllnessList = sortArray(
					data.data.listAckRetirementIllnessTypes
				);
				setIllnessOptions(sortedIllnessList);
			} catch {
				addToast({ Message: 'Options list error', IsSuccess: false });
			}
		});
	}, []);

	useEffect(() => {
		clearForm();
	}, [selectedStatus]);

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDate(formattedDate);
				setError('');
			} else {
				setDate(null);
			}
		}
	};

	const updateSelectionChange = (e) => {
		let radioInt = parseInt(e.target.value);
		setSelectedOption(radioInt);
	};

	const clearForm = () => {
		setInjury(0);
		setIllness(0);
		setInjuryOther('');
		setIllnessOther('');
		setOtherText('');
		setError('');
		setDate(null);
		setUncontrolledErrors({});
	};

	const validateDate = () => {
		if (date === null) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Date: {
					message: 'Date Required*'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Date: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Date?.message) {
			validateDate();
		}
	}, [date]);

	const validateInjuries = () => {
		if (injury === 0) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Injury: {
					message: 'Injury Required*'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Injury: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Injury?.message) {
			validateInjuries();
		}
	}, [injury]);

	const validateOtherText = () => {
		if (otherText === '') {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OtherText: {
					message: 'Other Details Required*'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OtherText: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.OtherText?.message) {
			validateOtherText();
		}
	}, [otherText]);

	const validateIllness = () => {
		if (illness === 0) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Illness: {
					message: 'Illness Required*'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Illness: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Illness?.message) {
			validateIllness();
		}
	}, [illness]);

	const onSaveRehabilitation = () => {
		let data = null;
		if (selectedOption === 1) {
			validateInjuries();
			validateDate();
			if (isValidationErrors) return;
			if (isOptionOther(injuryOptions, injury)) {
				data = {
					date: date,
					injury: injury,
					otherInjury: injuryOther,
					rehabReason: 1
				};
			} else {
				data = {
					date: date,
					injury: injury,
					rehabReason: 1
				};
			}
		} else if (selectedOption === 2) {
			validateIllness();
			validateDate();
			if (isValidationErrors) return;
			if (isOptionOther(illnessOptions, illness)) {
				data = {
					date: date,
					injury: illness,
					otherIllness: illnessOther,
					rehabReason: 2
				};
			} else {
				data = {
					date: date,
					illness: illness,
					rehabReason: 2
				};
			}
		} else {
			validateOtherText();
			validateDate();
			if (isValidationErrors) return;
			data = {
				date: date,
				other: otherText,
				rehabReason: 3
			};
		}
		if (data === null) {
			return (rehabInput = {});
		}
		const injuryDetailsId = data.injury === 0 ? null : data.injury;
		const otherInjuryDetails = data.otherInjury;
		const rehabOtherReason = data.other;
		const rehabReasonId = data.rehabReason;
		const illnessDetailsId = data.illness === 0 ? null : data.illness;
		const otherIllnessDetails = data.otherIllness;
		let rehabInput = {
			injuryDetailsId,
			otherInjuryDetails,
			rehabOtherReason,
			rehabReasonId,
			illnessDetailsId,
			otherIllnessDetails
		};

		return rehabInput;
	};

	const evaluateHorseStatus = () => {
		switch (selectedStatus) {
			case 4:
				//In Retraining
				return 41;
			case 5:
				//Spelling
				return 42;
			case 6:
				//Rehabilitation
				return 43;
			default:
				break;
		}
	};

	const onSaveStatus = () => {
		setIsLoading(true);
		const input = {
			asOfDate: date,
			horseCode: horseCode,
			statusId: evaluateHorseStatus()
		};
		if (selectedStatus === 6) {
			onSaveRehabilitation();
			if (isValidationErrors) {
				setIsLoading(false);
				return;
			}
		}
		try {
			AppSyncService.execute(saveRetrainerHorseStatus, { input }).then(
				(data) => {
					if (selectedStatus == 6) {
						let rehabInput = onSaveRehabilitation();
						rehabInput = { ...rehabInput, id: data.data.saveStatus };
						AppSyncService.execute(saveRetrainerRehab, rehabInput).then(
							(data) => {
								if (data.data != null) {
									addToast({
										Message: 'Status Changed Successfully',
										IsSuccess: true
									});
								} else {
									addToast({
										Message: 'Failed to submit form',
										IsSuccess: false
									});
								}
							}
						);
						setIsLoading(false);
					} else {
						setIsLoading(false);
						addToast({
							Message: 'Status Changed Successfully',
							IsSuccess: true
						});
					}
				}
			);
		} catch (error) {
			console.log(error);
		}
	};

	const renderTitle = () => {
		switch (selectedStatus) {
			case 4:
				return 'In Retraining';
			case 5:
				return 'Spelling';
			case 6:
				return 'Rehabilitation';
			default:
				break;
		}
	};

	const renderDropDownSelection = () => {
		switch (selectedOption) {
			case 1:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Injury Details*</div>
							<FormDropDown
								id={HorseManagementKeys.INJURY_DETAILS}
								showBlank
								selectedId={injury}
								onChange={(e) => {
									setInjuryOther('');
									setInjury(e.target.value);
									validateInjuries();
								}}
								items={injuryOptions}
							/>
							<div className={classes.error}>
								{uncontrolledErrors?.Injury?.message}
							</div>
						</Col>
						{injuryOptions.filter(
							(i) => i.id === parseInt(injury) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.INJURY_OTHER_DETAILS}
									value={injuryOther}
									onChange={(e) => setInjuryOther(e.target.value)}
								/>
								<div className={classes.error}>
									{uncontrolledErrors?.Injury?.message}
								</div>
							</Col>
						)}
					</Row>
				);
			case 2:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Illness Details*</div>
							<FormDropDown
								id={HorseManagementKeys.ILLNESS_DETAILS}
								showBlank
								selectedId={illness}
								onChange={(e) => {
									setIllnessOther('');
									setIllness(e.target.value);
									validateIllness();
								}}
								items={illnessOptions}
							/>
							<div className={classes.error}>
								{uncontrolledErrors?.Illness?.message}
							</div>
						</Col>
						{illnessOptions.filter(
							(i) => i.id === parseInt(illness) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.ILLNESS_OTHER_DETAILS}
									value={illnessOther}
									onChange={(e) => setIllnessOther(e.target.value)}
								/>
							</Col>
						)}
					</Row>
				);
			case 3:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Other Details*</div>
							<FormTextField
								id={HorseManagementKeys.OTHER_DETAILS}
								value={otherText}
								onChange={(e) => {
									setOtherText(e.target.value);
									validateOtherText();
								}}
							/>
							<div className={classes.error}>
								{uncontrolledErrors?.OtherText?.message}
							</div>
						</Col>
					</Row>
				);
		}
	};

	const renderRehabilitation = () => {
		return (
			<>
				<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
					Reason for Rehabilitation
				</div>
				<Row style={{ marginTop: 20 }}>
					<Col lg={12}>
						<SimpleRadioGroup
							groupName="rehabilitationGroup"
							onChange={(e) => {
								clearForm();
								updateSelectionChange(e);
							}}
							verticalDisplay
							items={[
								{
									id: 1,
									label: 'Injury',
									value: 1,
									isSelected: false
								},
								{
									id: 2,
									label: 'Illness',
									value: 2,
									isSelected: false
								},
								{
									id: 3,
									label: 'Other',
									value: 3,
									isSelected: false
								}
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col>{renderDropDownSelection()}</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					{selectedOption != 0 && (
						<Col lg={4}>
							<div className={classes.dateFieldTitle}>Date Commenced*</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									styles={{ width: '100%', color: 'blue' }}
									DateValue={date}
									updateSelection={(value) => updateDate(value)}
								/>
							</div>
							<div className={classes.error}>
								{uncontrolledErrors?.Date?.message}
							</div>
						</Col>
					)}
				</Row>
			</>
		);
	};

	const renderSpellingAndInRetraining = () => {
		return (
			<Row style={{ marginTop: 20 }}>
				<Col lg={4}>
					<div className={classes.dateFieldTitle}>Date Commenced*</div>
					<div>
						<FormDatePicker
							id={HorseManagementKeys.DATE_COMMENCED}
							styles={{ width: '100%', color: 'blue' }}
							DateValue={date}
							updateSelection={(value) => updateDate(value)}
						/>
					</div>
					<div className={classes.error}>{error}</div>
				</Col>
			</Row>
		);
	};

	const renderResetExpressSelectedStatus = () => {
		return (
			<div className={classes.container}>
				<div className={classes.formContainer}>
					<div className={classes.formHeading}>
						Change Status: {renderTitle()}
					</div>
					<MessageBar>
						<div className={classes.topMessage}>
							We need more information for a Retraining Status change. Please
							fill in and save all the fields below.
						</div>
					</MessageBar>

					{selectedStatus === 6 ? renderRehabilitation() : null}

					{selectedStatus === 4 || selectedStatus === 5
						? renderSpellingAndInRetraining()
						: null}
					<>
						<hr className={classes.divider}></hr>
						<div className={classes.saveButtonContainer}>
							{isLoading ? (
								<LoaderSpinner status={isLoading} />
							) : (
								<SaveCancelButton
									idPositive={CommonKeys.SAVE}
									idNegative={CommonKeys.CANCEL}
									positiveLabel="Save"
									cancel={() => {
										setSelectedForm(0);
									}}
									onClickPositive={() => {
										if (selectedStatus == 6 && selectedOption == 0) {
											return;
										}
										onSaveStatus();
										setSelectedForm(0);
									}}
									negativeLabel="Cancel"
								/>
							)}
						</div>
					</>
				</div>
			</div>
		);
	};
	return <>{renderResetExpressSelectedStatus()}</>;
};

export default AcknowledgedResetExpressChangeStatus;

export const newStatus = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Retired Agistment'
	},
	{
		id: 2,
		value: 'Retirement Rehabilitation'
	}
];

export const physicalHealthItems = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Injury'
	},
	{
		id: 2,
		value: 'Illness'
	},
	{
		id: 3,
		value: 'Lameness'
	},
	{
		id: 4,
		value: 'Other'
	}
];

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useParams } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { OTTNewsLink, TopContent } from '../Mobile';
import SEO from '../seo';
import { formatDateReadable } from '../../utils/helpers';
import { navigate } from '@reach/router';
import { useAuth } from '../../hooks/useAuth';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import HeroBannerImage from '../HeroBannerImage';
import { OTT_EXPLAINED } from '../../utils/constants';

const membersOnlyText =
	'The OTT Tack Room is your place to get videos and articles for a broad range of topics. Sign up for your free membership today to unlock new content!';

const OTTArticle = () => {
	const screenClassForRender = useScreenClass();
	const classes = useStyles();
	let { articleId } = useParams();
	const auth = useAuth();
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [tackRoomOverview, setTackRoomOverview] = useState(null);
	const [otherArticles, setOtherArticles] = useState([]);
	const [richText, setRichText] = useState(null);
	const [articleTitle, setArticleTitle] = useState('OTT Tack Room');
	const [meta, setMeta] = useState([]);
	const [articleDescription, setArticleDescription] = useState(null);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const contentfulArticle = useStaticQuery(
		graphql`
			query OTTarticle {
				allContentfulArticle(filter: { tags: { in: "Tack Room" } }) {
					edges {
						node {
							id
							title
							summary
							tags
							publishTime
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
								}
							}
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
				allContentfulTackRoomOverview {
					nodes {
						link
						linkButtonTitle
						summary {
							summary
						}
						title
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		const currentDate = new Date();
		let overview = contentfulArticle.allContentfulTackRoomOverview.nodes[0];
		setTackRoomOverview(overview);
		let contentFulArticles = contentfulArticle.allContentfulArticle.edges.filter(
			(a) => {
				var publishTime = new Date(a.node.publishTime);
				return publishTime.getTime() <= currentDate.getTime();
			}
		);
		let otherArticlesList = [];
		contentFulArticles.map((article) => {
			if (article.node.id === articleId) {
				setArticleTitle(article.node.title);
				setArticleDescription(article.node?.summary);
				let newMeta = [];
				if (article.node?.previewImage?.fluid?.src) {
					newMeta.push({
						property: `og:image`,
						content: article.node?.previewImage?.fluid?.src
					});
				}
				newMeta.push(
					{
						property: 'og:url',
						content: `https://www.ottcommunity.com.au/ott-tack-room/${articleId}`
					},
					{
						property: 'og:site_name',
						content: 'Off The Track'
					}
				);
				setMeta(newMeta);
				setRichText(article.node.body);
			}
		});
		if (auth?.currentUser) {
			contentFulArticles.map((article) => {
				if (article.node.id != articleId) {
					otherArticlesList.push(article.node);
				}
			});
		} else {
			contentFulArticles
				.filter((t) => t.node.tags.includes(OTT_EXPLAINED))
				.map((article) => {
					if (article.node.id != articleId) {
						otherArticlesList.push(article.node);
					}
				});
		}
		setOtherArticles(otherArticlesList);
		if (auth?.currentUser) {
			setIsLoggedIn(true);
		}
	}, [articleId, auth?.currentUser]);

	const goToArticle = (id) => {
		navigate(`/ott-tack-room/${id}`);
	};

	const articleSpacer = () => {
		return otherArticles.slice(0, 3).map((news) => {
			return <div className={classes.smallContainer} key={news.id} />;
		});
	};

	const additionalNewsArticlesRender = () => {
		let size = 3;
		return otherArticles.slice(0, size).map((news) => {
			return (
				<OTTNewsLink
					onClick={() => goToArticle(news.id)}
					key={news.id}
					heading={news.title}
					date={formatDateReadable(news.publishTime)}
					imageLink={news.previewImage?.fluid?.src}
					isMobile
				/>
			);
		});
	};

	return (
		<>
			<Container className={classes.dashboardDesktopPageContainer}>
				<SEO
					title={articleTitle}
					meta={meta}
					description={articleDescription}
					type={'article'}
				/>
				<Row>
					<Col
						lg={9}
						sm={12}
						xs={12}
						style={
							['sm', 'xs', 'md'].includes(screenClassForRender)
								? {}
								: { flex: '0 0 67%', maxWidth: '67%', width: '67%' }
						}
					>
						<div
							className={
								auth?.currentUser ? classes.loggedIn : classes.loggedOut
							}
						>
							{richText && renderRichText(richText, options)}
						</div>
						{auth?.currentUser && (
							<>
								<hr className={classes.hr}></hr>
								{tackRoomOverview && (
									<HeroBannerImage
										title={tackRoomOverview?.title}
										text={tackRoomOverview?.summary?.summary}
										imgSrc={tackRoomOverview?.image?.file?.url}
										buttonLink={tackRoomOverview?.link}
										buttonText={tackRoomOverview?.linkButtonTitle}
										isLoggedIn={isLoggedIn}
										isTackArticle
									/>
								)}
							</>
						)}
						{!auth?.currentUser && (
							<>
								<div style={{ opacity: 0 }}>
									<HeroBannerImage
										title="MEMBERS ONLY"
										text={membersOnlyText}
										imgSrc={tackRoomOverview?.image?.file?.url}
										buttonLink="register"
										buttonText={tackRoomOverview?.linkButtonTitle}
										isTackArticle
									/>
									{['sm', 'xs', 'md'].includes(screenClassForRender) && (
										<Col xs={4} className={classes.moreArticles}>
											{otherArticles.length > 0 && (
												<div>
													<TopContent
														isMobile
														styles={{ marginTop: 10, marginBottom: 15 }}
														leftText="Recent OTT Tack Room"
													/>
													{articleSpacer()}
												</div>
											)}
										</Col>
									)}
								</div>
								<div className={classes.joinNow}>
									{tackRoomOverview && (
										<div
											className={
												['sm', 'xs'].includes(screenClassForRender)
													? classes.membersOnlyMobileBanner
													: classes.membersOnlyBanner
											}
										>
											<HeroBannerImage
												title="MEMBERS ONLY"
												text={membersOnlyText}
												imgSrc={tackRoomOverview?.image?.file?.url}
												buttonLink="register"
												buttonText={tackRoomOverview?.linkButtonTitle}
												isTackArticle
											/>
										</div>
									)}
								</div>
							</>
						)}
					</Col>

					<Col lg={3} md={12} className={classes.moreArticles}>
						{otherArticles.length > 0 && (
							<div>
								<TopContent
									isMobile
									styles={{ marginTop: 10, marginBottom: 15 }}
									leftText="Recent OTT Tack Room"
								/>
								{additionalNewsArticlesRender()}
							</div>
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default OTTArticle;

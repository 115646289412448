import React, { useEffect, useState } from 'react';
import MessageBar from '../MessageBar';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SimpleRadioGroup from '../SimpleRadioGroup';
import RetrainerConcludedEuthanisedForm from '../RetrainerConcludedEuthanisedForm';
import RetrainerConcludedSoldForm from '../RetrainerConcludedSoldForm';
import RetrainerConcludedOwnUseForm from '../RetrainerConcludedOwnUseForm';
import RetrainerConcludedReturnToUserForm from '../RetrainerConcludedReturnToUserForm';
import {
	EUTHANISED,
	SOLD,
	OWN_USE,
	OWN_USE_FUNDED,
	RETURN_TO_USER,
	OWN_USE_LEASED
} from '../../utils/constants';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { getFullCircle } from '../../graphql/custom.queries';
import RetrainerConcludedOwnUseLeasedForm from '../RetrainerConcludedOwnUseLeasedForm';

const RetrainerConcludedForm = ({
	title,
	submitForm,
	priceBracket,
	rehomingPurposeList,
	disciplineOptions,
	retrainPurpose,
	concludedReturnedReasons,
	conclusionReasons,
	cancel,
	horseCode
}) => {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState(0);
	const [showFullCircleForm, setShowFullCircleForm] = useState(false);
	const { currentUser } = useAuth();

	const fetchData = async () => {
		const { data: getFullCircleData } = await AppSyncService.execute(
			getFullCircle,
			{
				memberExternalId: currentUser?.UserId,
				horseCode
			}
		);
		if (!getFullCircleData.getFullCircle?.status) {
			setShowFullCircleForm(true);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const updateSelectionChange = (e) => {
		let radioInt = parseInt(e.target.value);
		setSelectedOption(radioInt);
	};

	const renderDropDownSelection = () => {
		switch (selectedOption) {
			case 1:
				return (
					<RetrainerConcludedEuthanisedForm
						cancel={cancel}
						submitForm={(data) => submitForm(data, EUTHANISED)}
					/>
				);
			case 2:
				return (
					priceBracket.length > 0 &&
					rehomingPurposeList.length > 0 &&
					disciplineOptions.length > 0 && (
						<RetrainerConcludedSoldForm
							cancel={cancel}
							priceBracket={priceBracket}
							rehomingPurposeList={rehomingPurposeList}
							disciplineOptions={disciplineOptions}
							submitForm={(data) => submitForm(data, SOLD)}
							showFullCircleForm={showFullCircleForm}
						/>
					)
				);
			case 3:
				return (
					<RetrainerConcludedOwnUseForm
						retrainPurpose={retrainPurpose}
						cancel={cancel}
						submitForm={(data) => submitForm(data, OWN_USE)}
						showFullCircleForm={showFullCircleForm}
					/>
				);
			case 4:
				return (
					<RetrainerConcludedOwnUseForm
						retrainPurpose={retrainPurpose}
						cancel={cancel}
						submitForm={(data) => submitForm(data, OWN_USE_FUNDED)}
						clientFunded={true}
						disciplineOptions={disciplineOptions}
						showFullCircleForm={showFullCircleForm}
					/>
				);
			case 5:
				return (
					<RetrainerConcludedReturnToUserForm
						cancel={cancel}
						submitForm={(data) => submitForm(data, RETURN_TO_USER)}
						reasons={concludedReturnedReasons}
						disciplineOptions={disciplineOptions}
						showFullCircleForm={showFullCircleForm}
					/>
				);
			case 6:
				return (
					<RetrainerConcludedOwnUseLeasedForm
						retrainPurpose={retrainPurpose}
						cancel={cancel}
						submitForm={(data) => submitForm(data, OWN_USE_LEASED)}
						disciplineOptions={disciplineOptions}
						showFullCircleForm={showFullCircleForm}
					/>
				);
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<div className={classes.formHeading}>{title}</div>
				<MessageBar>
					<div className={classes.topMessage}>
						We need more information for a Retraining Status change. Please fill
						in and save all the fields below.
					</div>
				</MessageBar>
				<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
					Reason for Concluding Retraining
				</div>
				<Row style={{ marginTop: 20 }}>
					<Col lg={12}>
						<SimpleRadioGroup
							groupName="sampleType"
							onChange={(e) => updateSelectionChange(e)}
							verticalDisplay
							col={4}
							items={conclusionReasons}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col>{renderDropDownSelection()}</Col>
				</Row>
			</div>
		</div>
	);
};
export default RetrainerConcludedForm;

import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
const FullCircleMemberProfileView = ({
	fullCircleMemberProfileData,
	relationshipType,
	typeOfAssistance,
	organisation
}) => {
	const classes = useStyles();
	return (
		<>
			<HeaderBanner
				title="Personal Profile"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col md={12} lg={4}>
						<FormLabelField
							title="First Name*"
							value={fullCircleMemberProfileData?.firstName}
						/>
					</Col>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Last Name*"
							value={fullCircleMemberProfileData?.lastName}
						/>
					</Col>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Phone (Personal)*"
							value={fullCircleMemberProfileData?.phone}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Phone (Business)"
							value={fullCircleMemberProfileData?.businessPhone}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Residential Address*"
							value={fullCircleMemberProfileData?.residentialAddress?.address}
						/>
					</Col>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Residential Suburb*"
							value={fullCircleMemberProfileData?.residentialAddress?.suburb}
						/>
					</Col>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Residential Postcode*"
							value={fullCircleMemberProfileData?.residentialAddress?.postcode}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Residential State*"
							value={fullCircleMemberProfileData?.residentialAddress?.state}
						/>
					</Col>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Residential Country*"
							value={fullCircleMemberProfileData?.residentialAddress?.country}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col md={12} lg={4}>
						<FormLabelField
							title="Relationship to Horse*"
							value={relationshipType}
						/>
					</Col>
					{relationshipType === 'Organisation' && (
						<Col md={12} lg={4}>
							<FormLabelField title="Organisation Name*" value={organisation} />
						</Col>
					)}
					<Col md={12} lg={4}>
						<FormLabelField
							title="Type of Assistance*"
							value={typeOfAssistance}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default FullCircleMemberProfileView;

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseOTTStatus,
	reviewFosterInitialAssessmentForm,
	setOttProgramAndStatus
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { formatDate, formatDateReadableVariant } from '../../utils/helpers';
import { physicalHealthItems } from './constants';

const FosterInitialFormReviewInfo = ({ data, mobile, getImage }) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const { addToast } = useToast();
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [horseCurrentStatus, setHorseCurrentStatus] = useState(null);

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
		AppSyncService.execute(getHorseOTTStatus, {
			horseCode: data?.horse?.horseCode
		}).then((response) => {
			if (response.data?.getHorseOTTStatus?.horseOTTStatusId)
				setHorseCurrentStatus(response.data.getHorseOTTStatus.horseOTTStatusId);
		});
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewFosterInitialAssessmentForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveFosterInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveFosterInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewFosterInitialAssessmentForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveFosterInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				const ottStatus = {
					programId: 3,
					horseCode: data?.horse?.horseCode,
					memberExternalId: data.member?.externalId,
					statusId: horseCurrentStatus,
					asOfDate: formatDate(data.dateOfAssessment)
				};
				if (ottStatus) {
					AppSyncService.execute(setOttProgramAndStatus, ottStatus).then(() => {
						navigate(`/review-list`);
					});
				}
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveFosterInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	return (
		<>
			<div
				style={{
					borderTop: '1px solid #b3bcc1',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: 'none'
				}}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="STAR Initial Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date of assessment*"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.dateOfAssessment)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastSubmitted
									? formatDateReadableVariant(data.lastSubmitted)
									: ''
							}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Left side photo/s*</div>
							<UploadPhoto
								data={data.leftSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Right side photo/s*</div>
							<UploadPhoto
								data={data.rightSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Additional Photos</div>
							<UploadPhoto
								data={data.additionalPhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title={
								<div>
									Describe the horse’s overall condition{' '}
									<span className={classes.fontWeightNormal}>
										(body condition, feet condition, note any superficial
										wounds/abrasions)
									</span>
									*
								</div>
							}
							value={data.overallCondition}
						/>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Worming</div>
							{data.worming ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Date of Completion*</div>
							{data.worming ? formatDateReadableVariant(data.worming) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Farrier</div>
							{data.farrier ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Date of Completion/Booking*
							</div>
							{data.farrier ? formatDateReadableVariant(data.farrier) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Dentist</div>
							{data.dentist ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Date of Completion/Booking*
							</div>
							{data.dentist ? formatDateReadableVariant(data.dentist) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title={
								<div>
									Other Husbandry Information{' '}
									<span className={classes.fontWeightNormal}>
										(include possible requirements in future)
									</span>
								</div>
							}
							value={data.otherHusbandryInfo}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={
								<div>
									Assessment of feeding requirements/plan{' '}
									<span className={classes.fontWeightNormal}>
										(including whether the horse is currently eating well)
									</span>
									*
								</div>
							}
							value={data.feedingRequirementsPlan}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={
								<div>
									Assessment of temperament/behaviour{' '}
									<span className={classes.fontWeightNormal}>
										(nervous/relaxed/aggressive/sensitive)
									</span>
									*
								</div>
							}
							value={data.temperament}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="How has the horse been to handle?"
							value={data.horseHandle}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Has the horse displayed any vices?"
							value={data.hasHorseDisplayedVices ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Select vices or bad habits ${data.horse?.name} has`}
							value={data.habits
								.map((selectedHabit) => selectedHabit.value)
								.join()}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={
								<div>
									Other{' '}
									<span className={classes.fontWeightNormal}>
										(Please separate individual values by using a comma)
									</span>
								</div>
							}
							value={data.habitsOther}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title={`Are there any concerns with the horse's physical health?`}
							value={data.horsePhysicalHealth ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title={
								<div>
									Please identify the concern{' '}
									<span className={classes.fontWeightNormal}>
										(select other if there are multiple)
									</span>
									*
								</div>
							}
							value={
								physicalHealthItems.find(
									(selectedPhysicalHealth) =>
										selectedPhysicalHealth.id === data.horsePhysicalHealth
								)?.value
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Other concern*"
							value={data.horsePhysicalHealthOtherConcern}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Are there any concerns with the horse's behaviour?`}
							value={data.horseBehaviour ? 'Yes' : 'No'}
						/>
					</Col>{' '}
					<Col xs={12}>
						<FormLabelField
							title="Please provide more information*"
							value={data.horseBehaviour}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Final comments" value={data.finalComments} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
				isReadOnly={data.status !== 'Review'}
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default FosterInitialFormReviewInfo;

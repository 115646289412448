import React from 'react';
import useStyles from './styles';
import OTTInformationDatum from '../OTTInformationDatum';
import TagsList from '../TagsList';
import { generateTagLabels, shouldShowDetail } from '../../helpers';
import ImageSlider from '../../../ImageSlider';
import { useScreenClass, Row } from 'react-grid-system';
import { checkIsSmallScreen } from '../../../../utils/helpers';
import HorseProfileVisibilityHelperSection from '../../../HorseVisibilityHelperSection';

const OTTInformationTab = ({ horseProfile, isFollower, isAdmin, isOwner }) => {
	const {
		horse: { ottInformation, name: horseName }
	} = horseProfile;
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	const listOfImages = () => {
		return ottInformation?.uploadedFiles
			?.filter((file) => file.type === 'ProfileImage' && !file.removed)
			?.map((file) => {
				return {
					image: file.key,
					...file
				};
			});
	};

	const imageSliderHolder = () => {
		return (
			<>
				{isSmallScreen && <ImageSlider sliderImages={listOfImages()} />}
				{!isSmallScreen && (
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<ImageSlider sliderImages={listOfImages()} />
						{listOfImages()?.length > 1 && (
							<ImageSlider sliderImages={listOfImages()} startIndex={1} />
						)}
					</div>
				)}
			</>
		);
	};
	return (
		<div className={classes.sectionContainer}>
			{(isOwner || isAdmin) && (
				<Row>
					<HorseProfileVisibilityHelperSection hintMessage="Change who can see which information fields by clicking Edit and changing the privacy buttons next to each field." />
				</Row>
			)}
			<OTTInformationDatum
				label={`${horseName}'s Profile Photos (jpg, png)`}
				value={imageSliderHolder()}
				showDatum={
					shouldShowDetail(
						ottInformation.profilePhotoVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && listOfImages()?.length > 0
				}
			/>
			<OTTInformationDatum
				label={`What is ${horseName}'s paddock/known as name?`}
				value={ottInformation.paddockName}
				showDatum={
					shouldShowDetail(
						ottInformation.paddockNameVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.paddockName
				}
			/>
			<OTTInformationDatum
				label={`What would you describe ${horseName}'s purpose as?`}
				value={ottInformation.purposeOther || ottInformation.purpose.value}
				showDatum={
					shouldShowDetail(
						ottInformation.purposeVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.purpose?.value
				}
			/>
			<OTTInformationDatum
				label={`What are ${horseName}'s specialty disciplines?`}
				value={
					<TagsList
						tagsList={generateTagLabels(
							ottInformation.disciplines,
							ottInformation.disciplinesOther
						)}
					/>
				}
				showDatum={
					shouldShowDetail(
						ottInformation.disciplinesVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.disciplines.length > 0
				}
			/>
			<OTTInformationDatum
				label={`Tell us about ${horseName}'s personality`}
				value={ottInformation.personalityDetails}
				showDatum={
					shouldShowDetail(
						ottInformation.personalityDetailsVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.personalityDetails
				}
			/>
			<OTTInformationDatum
				label={`What makes ${horseName} happy?`}
				value={ottInformation.happyDetails}
				showDatum={
					shouldShowDetail(
						ottInformation.happyDetailsVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.happyDetails
				}
			/>
			<OTTInformationDatum
				label={`Vices or bad habits that ${horseName} has`}
				value={
					<TagsList
						tagsList={generateTagLabels(
							ottInformation.habits,
							ottInformation.habitsOther
						)}
					/>
				}
				showDatum={
					shouldShowDetail(
						ottInformation.habitsVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.habits.length > 0
				}
			/>
			<OTTInformationDatum
				label={`Has ${horseName} had any injuries, previously or currently?`}
				value={ottInformation.hasInjuries ? 'Yes' : 'No'}
				showDatum={
					shouldShowDetail(
						ottInformation.injuriesVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.hasInjuries !== null
				}
			/>
			<OTTInformationDatum
				label={`Has ${horseName} had any illnesses, previously or currently?`}
				value={ottInformation.hasIllnesses ? 'Yes' : 'No'}
				showDatum={
					shouldShowDetail(
						ottInformation.illnessesVisibility,
						isFollower,
						isAdmin,
						isOwner
					) && ottInformation.hasIllnesses !== null
				}
			/>
		</div>
	);
};

export default OTTInformationTab;

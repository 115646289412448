import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	resultColumn: {
		width: 200,
		maxWidth: 200,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		padding: '15px 10px 15px 20px',
		boxSizing: 'border-box',
		'& > span': {
			width: '100%',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis'
		}
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	toastMessageContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: 5,
		lineHeight: 1.43,
		marginRight: 25,
		minWidth: 220
	},
	toast: {
		display: 'flex'
	},
	toastType: {
		fontWeight: 600,
		color: '#464749',
		fontSize: 15
	},
	toastMessage: {
		color: '#757575',
		fontSize: 14
	},
	closeButtonContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	closeButton: {
		background: 'none',
		border: 'none',
		color: 'darkgray',
		fontFamily: 'sans-serif',
		height: '100%',
		width: '100%',
		fontSize: 22
	}
});

import React, { useState, useEffect } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import useDebounce from '../../hooks/useDebounce';
import PointIcon from '../../assets/icons/Points_icon.svg';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useToast } from '../../hooks/useToast';
import axios from 'axios';
import {
	getHorses,
	getMemberProfile,
	getHorseClaim,
	saveHorseClaim,
	updateMemberProfile,
	verifyHorseClaimData,
	saveResidentialAddress,
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	uploadImage,
	deleteImage,
	deleteImagesFromS3,
	deleteHorseClaim,
	listHorseClaimSources,
	getHorseProgram,
	getPreviousHorseClaimByUserQuery,
	deleteUploadedImageByMemberIdAndHorseCode
} from '../../graphql/custom.queries';
import SEO from '../seo';
import FormDropDown from '../FormDropDown';
import FormDatePicker from '../FormDatePicker';
import FormTextField from '../FormTextField';
import { useParams, navigate } from '@reach/router';
import LoaderSpinner from '../LoaderSpinner';
import FormValidateTextField from '../FormValidateTextField';
import {
	formatDate,
	calculateHorseIdentificationScore,
	formatiOSTextField,
	isOptionOther
} from '../../utils/helpers';
import UploadPhoto from '../UploadPhoto';
import MessageBar from '../MessageBar';
import ConfirmationModal from '../ConfirmationModal';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import SimpleCheckBox from '../SimpleCheckBox';
import Toggle from '../Toggle';
import {
	HORSE_IDENTIFICATION_POINTS_ALLOCATION,
	DEFAULT_COUNTRY,
	RELATIONSHIP_ORGANISATION,
	RELATIONSHIP_RETRAINER,
	BLANK_OPTION,
	APPROVED,
	ClaimThoroughbredFormKeys,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ACK_RETIREMENT_FARM,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ACK_FOSTER_PROVIDER,
	RV_FOSTER_PROGRAM_ID,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	RELATIONSHIP_FOSTER,
	ROLE_REHOMER,
	ACK_REHOMER
} from '../../utils/constants';
import { addNameToUnnamedHorse, checkUserAdmin } from '../../utils/helpers';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';
import { logToServer } from '../../utils/logger';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import PageContainer from '../PageContainer';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import ResizingTextArea from '../ResizingTextArea';
import classNames from 'classnames';
import { CommonKeys } from './../../utils/constants/index';
import qs from 'query-string';
import SubmitOnBehalf from '../SubmitOnBehalf';

const ClaimThoroughbred = ({ location }) => {
	const isPreviousClaim = JSON.parse(
		JSON.stringify(qs.parse(location.search)['isPreviousClaim'] || '')
	);
	const classes = useStyles();
	let { horseCode } = useParams();
	const { currentUser } = useAuth();
	const isAckRetirementFarmRole = currentUser?.Roles.some(
		(role) => role === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
	);
	const isAckRetrainerRole = currentUser?.Roles.some(
		(role) => role === ROLE_RV_ACKNOWLEDGED_RETRAINER
	);
	const isAckFosterRole = currentUser?.Roles.some(
		(role) => role === ROLE_ACKNOWLEDGED_FOSTER_CARER
	);
	const isAckRehomer = currentUser?.Roles.some((role) => role === ROLE_REHOMER);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	const { addToast } = useToast();
	const screenClassForRender = useScreenClass();

	const [selectedRelation, setSelectedRelation] = useState('');
	const [selectedPurpose, setSelectedPurpose] = useState(0);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [selectedGender, setSelectedGender] = useState(0);
	const [gender, setGender] = useState('');
	const [horseProfile, setHorseProfile] = useState('');
	const [profileInfo, setProfileInfo] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [address, setAddress] = useState('');
	const [noClaimFound, setnoClaimFound] = useState(false);
	const [suburb, setSuburb] = useState('');
	const [postcode, setPostcode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [thoroughbredAddress, setThoroughbredAddress] = useState('');
	const [thoroughbredSuburb, setThoroughbredSuburb] = useState('');
	const [thoroughbredPostcode, setThoroughbredPostcode] = useState('');
	const [thoroughbredState, setThoroughbredState] = useState('');
	const [thoroughbredCountry, setThoroughbredCountry] = useState(
		DEFAULT_COUNTRY
	);
	const [microchip, setMicrochip] = useState('');
	const [dam, setDam] = useState('');
	const [sire, setSire] = useState('');
	const [organisationName, setOrganisationName] = useState('');
	const [foalDate, setFoalDate] = useState(null);
	const [stabledDate, setStabledDate] = useState(null);
	const [score, setScore] = useState(0);
	const [brandLeftImages, setBrandLeftImages] = useState([]);
	const [brandRightImages, setBrandRightImages] = useState([]);
	const [markingsFrontImages, setMarkingsFrontImages] = useState([]);
	const [markingsSideImages, setMarkingsSideImages] = useState([]);
	const [microchipImages, setMicrochipImages] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isAtAddress, setIsAtAddress] = useState(true);
	const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
	const [
		isThoroughbredAddressManual,
		setIsThoroughbredAddressManual
	] = useState(false);
	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add my address',
		value: 'Manually add my address',
		isSelected: isPostalAddressManual
	};
	const manualThoroughbredAddressCheckBoxData = {
		id: ClaimThoroughbredFormKeys.MANUALLY_ADD_THOROUGHBRED_ADDRESS,
		label: 'Manually add thoroughbred address',
		value: 'Manually add thoroughbred address',
		isSelected: isThoroughbredAddressManual
	};
	const [brandLeftImageExist, setBrandLeftImageExist] = useState(false);
	const [brandRightImageExist, setBrandRightImageExist] = useState(false);
	const [relationOptions, setRelationOptions] = useState([]);
	const [claimSources, setClaimSources] = useState([]);
	const [selectedClaimSource, setSelectedClaimSource] = useState(0);
	const [selectedClaimSourceOther, setSelectedClaimSourceOther] = useState('');
	const [formStatus, setFormStatus] = useState(null);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [isUploadingPreviousImages, setIsUploadingPreviousImages] = useState(
		false
	);
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');
	const [tempRelationOptions, setTempRelationOptions] = useState([]);
	const [relationOptionFiltered, setRelationOptionFiltered] = useState([]);
	const [
		isSelectedProfileChangedByAdmin,
		setIsSelectedProfileChangedByAdmin
	] = useState(false);
	const [
		isSelectedAddressChangedByAdmin,
		setIsSelectedAddressChangedByAdmin
	] = useState(false);

	const updateFoalDate = (value) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			setFoalDate(formattedDate);
		} else {
			setFoalDate(null);
		}
	};

	const updateStabledDate = (value) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			setStabledDate(formattedDate);
		} else {
			setStabledDate(null);
		}
	};

	const updateSireName = (value) => {
		setSire(formatiOSTextField(value));
	};

	const updateDamName = (value) => {
		setDam(formatiOSTextField(value));
	};

	const cancelClaim = () => {
		if (formStatus === APPROVED) return;

		let listOfKeys = [];
		if (brandLeftImages.length > 0) {
			brandLeftImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (brandRightImages.length > 0) {
			brandRightImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (markingsFrontImages.length > 0) {
			markingsFrontImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (markingsSideImages.length > 0) {
			markingsSideImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (microchipImages.length > 0) {
			microchipImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					deleteHorseClaimForm();
				}
			});
		} else {
			deleteHorseClaimForm();
		}
	};

	const deleteHorseClaimForm = () => {
		AppSyncService.execute(deleteHorseClaim, {
			horseCode: horseCode
		}).then(() => {
			addToast({ Message: 'Form Cancelled', IsSuccess: true });
			if (!isPreviousClaim) navigate(DASHBOARD_ROUTE);
			else navigate('/?activeTab=my-stable&activeRole=RETRAINER');
		});
	};

	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removeMicrochipImage = (img) => {
		let imageInput = img;
		imageInput = {
			...imageInput,
			...(isSubmitOnBehalf &&
				selectedUserVerifiedInfo && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
		};
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (
					data?.data?.deleteUserImage?.success ||
					data?.data?.deleteUserImage === null
				) {
					AppSyncService.execute(deleteImage, { imageInput }).then((data) => {
						if (data.data.deleteUploadedImage) {
							let dataList = Object.assign([], microchipImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setMicrochipImages(newDataList);
						}
					});
				}
			}
		);
	};

	const removeMarkingFrontImage = (img) => {
		let imageInput = img;
		imageInput = {
			...imageInput,
			...(isSubmitOnBehalf &&
				selectedUserVerifiedInfo && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
		};
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (
					data?.data?.deleteUserImage?.success ||
					data?.data?.deleteUserImage === null
				) {
					AppSyncService.execute(deleteImage, { imageInput }).then((data) => {
						if (data.data.deleteUploadedImage) {
							let dataList = Object.assign([], markingsFrontImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setMarkingsFrontImages(newDataList);
						}
					});
				}
			}
		);
	};

	const removeMarkingSideImage = (img) => {
		let imageInput = img;
		imageInput = {
			...imageInput,
			...(isSubmitOnBehalf &&
				selectedUserVerifiedInfo && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
		};
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (
					data?.data?.deleteUserImage?.success ||
					data?.data?.deleteUserImage === null
				) {
					AppSyncService.execute(deleteImage, { imageInput }).then((data) => {
						if (data.data.deleteUploadedImage) {
							let dataList = Object.assign([], markingsSideImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setMarkingsSideImages(newDataList);
						}
					});
				}
			}
		);
	};

	const removeBrandLeftImage = (img) => {
		let imageInput = img;
		imageInput = {
			...imageInput,
			...(isSubmitOnBehalf &&
				selectedUserVerifiedInfo && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
		};
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (
					data?.data?.deleteUserImage?.success ||
					data?.data?.deleteUserImage === null
				) {
					AppSyncService.execute(deleteImage, { imageInput }).then((data) => {
						if (data.data.deleteUploadedImage) {
							let dataList = Object.assign([], brandLeftImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							if (newDataList.length == 0) {
								setBrandLeftImageExist(false);
							}
							setBrandLeftImages(newDataList);
						}
					});
				}
			}
		);
	};

	const removeBrandRightImage = (img) => {
		let imageInput = img;
		imageInput = {
			...imageInput,
			...(isSubmitOnBehalf &&
				selectedUserVerifiedInfo && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
		};
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (
					data?.data?.deleteUserImage?.success ||
					data?.data?.deleteUserImage === null
				) {
					AppSyncService.execute(deleteImage, { imageInput }).then((data) => {
						if (data.data.deleteUploadedImage) {
							let dataList = Object.assign([], brandRightImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							if (newDataList.length == 0) {
								setBrandRightImageExist(false);
							}
							setBrandRightImages(newDataList);
						}
					});
				}
			}
		);
	};

	// Debounce
	const debouncedMicrochip = useDebounce(microchip, 3000);
	const debouncedDam = useDebounce(dam, 3000);
	const debouncedSire = useDebounce(sire, 3000);
	const debouncedFoalDate = useDebounce(foalDate, 3000);

	const [validMicrochip, setValidMicrochip] = useState('default');
	const [validDam, setValidDam] = useState('default');
	const [validSire, setValidSire] = useState('default');
	const [validFoalDate, setValidFoalDate] = useState('default');

	const [isAcquireReadOnly, setIsAcquireReadOnly] = useState(false);
	const [horseProgram, setHorseProgram] = useState(null);
	useEffect(() => {
		// Make sure we have a value (user has entered something in input)
		if (
			debouncedDam ||
			debouncedFoalDate ||
			debouncedMicrochip ||
			debouncedSire
		) {
			let verifyClaimData = {
				damName: dam != '' ? dam : null,
				foalYear: foalDate != null ? parseInt(foalDate.substr(0, 4)) : null,
				horseCode: horseCode,
				microChipNumber: microchip,
				sireName: sire
			};
			AppSyncService.execute(verifyHorseClaimData, { verifyClaimData }).then(
				(data) => {
					if (data.data != null) {
						if (data.data.isMatched.damName) {
							setValidDam(true);
						} else {
							setValidDam(false);
						}
						if (data.data.isMatched.foalYear) {
							setValidFoalDate(true);
						} else {
							setValidFoalDate(false);
						}
						if (data.data.isMatched.microChipNumber) {
							setValidMicrochip(true);
						} else {
							setValidMicrochip(false);
						}
						if (data.data.isMatched.sireName) {
							setValidSire(true);
						} else {
							setValidSire(false);
						}
					}
				}
			);
		}
	}, [debouncedDam, debouncedFoalDate, debouncedMicrochip, debouncedSire]);

	// Recalculate the score when any of these values change.
	useEffect(() => {
		const newTotalScore = calculateHorseIdentificationScore({
			validDam: validDam,
			validFoalDate,
			validMicrochip,
			validSire,
			brandLeftImagesCount: brandLeftImages.length,
			brandRightImagesCount: brandRightImages.length,
			markingsFrontImagesCount: markingsFrontImages.length,
			markingsSideImagesCount: markingsSideImages.length,
			microchipImagesCount: microchipImages.length
		});
		setScore(newTotalScore);
	}, [
		validDam,
		validFoalDate,
		validMicrochip,
		validSire,
		brandLeftImages,
		brandRightImages,
		markingsFrontImages,
		markingsSideImages,
		microchipImages
	]);

	useEffect(() => {
		if (currentUser !== null) {
			const input = {
				horseCode: parseInt(horseCode)
			};
			const externalId = { externalId: currentUser?.UserId };
			setIsLoading(true);

			AppSyncService.execute(getHorseProgram, input).then((data) => {
				const dataHorseProgram = data?.data?.getHorseProfile?.program?.id;
				setHorseProgram(dataHorseProgram);
				AppSyncService.execute(listHorseClaimSources).then((data) => {
					if (data?.data?.listHorseClaimSources) {
						let horseClaimLookup = data?.data?.listHorseClaimSources;
						if (dataHorseProgram !== 3) {
							//@NOTE: Remove RV Foster Program option if horse's program is not Foster
							horseClaimLookup = horseClaimLookup?.filter(
								(item) => item.id !== RV_FOSTER_PROGRAM_ID
							);
						}
						horseClaimLookup.sort((a, b) => {
							if (a.id == 11) {
								return 1;
							} else {
								return a.value.localeCompare(b.value);
							}
						});
						setClaimSources([BLANK_OPTION].concat(horseClaimLookup));
					}
				});
			});
			AppSyncService.execute(getHorses, input).then((data) => {
				if (data.data != null) {
					setHorseProfile(data.data.getHorse);
					let listClaimRelationships = data.data.listClaimRelationships;
					setTempRelationOptions(data.data.listClaimRelationships);
					const dataHorseProgram = data?.data?.getHorseProfile?.program?.id;
					setHorseProgram(dataHorseProgram);
					if (isAckRetrainerRole && isAckFosterRole && dataHorseProgram !== 3) {
						setSelectedRelation(RELATIONSHIP_RETRAINER);
					} else {
						if (isAckFosterRole && dataHorseProgram === 3) {
							setSelectedRelation(RELATIONSHIP_FOSTER);
							updateRelation(ACK_FOSTER_PROVIDER, dataHorseProgram);
						} else if (isAckRetrainerRole) {
							setSelectedRelation(RELATIONSHIP_RETRAINER);
						} else setSelectedRelation(listClaimRelationships[0]);
					}

					if (!isAckRetirementFarmRole) {
						listClaimRelationships = listClaimRelationships.filter(
							(rel) => rel !== ACK_RETIREMENT_FARM
						);
					}
					if (!isAckFosterRole) {
						listClaimRelationships = listClaimRelationships.filter(
							(rel) => rel !== ACK_FOSTER_PROVIDER
						);
					}
					if (!isAckRehomer) {
						listClaimRelationships = listClaimRelationships.filter(
							(rel) => rel !== ACK_REHOMER
						);
					}
					setRelationOptionFiltered(listClaimRelationships);
					setRelationOptions(listClaimRelationships);
				}
				AppSyncService.execute(getMemberProfile, externalId).then((data) => {
					if (data.data != null) {
						setProfileInfo(data.data.getMember);
						setGender(data.data.getMember.gender);
						setPhoneNumber(data.data.getMember.phone);
						let memberProfileResidentialAddress =
							data.data.getMember.residentialAddress;
						if (memberProfileResidentialAddress) {
							setAddress(memberProfileResidentialAddress.address);
							setSuburb(memberProfileResidentialAddress.suburb);
							setPostcode(memberProfileResidentialAddress.postcode);
							setState(memberProfileResidentialAddress.state || '');
							setCountry(
								memberProfileResidentialAddress.country || DEFAULT_COUNTRY
							);
						}
						let horseCodeForClaim = parseInt(horseCode);
						let memberExternalId = currentUser?.UserId;
						let getQuery = isPreviousClaim
							? getPreviousHorseClaimByUserQuery
							: getHorseClaim;
						let input = isPreviousClaim
							? {
									horseCode: horseCodeForClaim
							  }
							: {
									horseCodeForClaim,
									memberExternalId
							  };
						AppSyncService.execute(getQuery, input).then(async (data) => {
							const resultData = isPreviousClaim
								? data.data.getHorsePreviousClaimByUser
								: data.data.getHorseClaim;
							if (data.data != null && resultData != null) {
								setFormStatus(resultData.status);
								if (resultData.status === 'Review') {
									setIsSubmitted(true);
								}
								if (
									resultData.brandLeftImages != null &&
									resultData.brandLeftImages.length > 0
								) {
									setBrandLeftImageExist(true);
									setBrandLeftImages(resultData.brandLeftImages);
								}
								if (
									resultData.brandRightImages != null &&
									resultData.brandRightImages.length > 0
								) {
									setBrandRightImageExist(true);
									setBrandRightImages(resultData.brandRightImages);
								}
								setMarkingsFrontImages(resultData.markingsFrontImages);
								setMarkingsSideImages(resultData.markingsSideImages);

								setMicrochipImages(resultData.microchipImages);

								if (isPreviousClaim) {
									setIsUploadingPreviousImages(true);
									try {
										await saveImagesToClaim(resultData.brandLeftImages);
									} catch (e) {
										logToServer(
											currentUser?.UserId,
											e,
											`Claim Thoroughbred - save images to uploaded-images failed - data: ${resultData.brandLeftImages}`
										);
									}
									try {
										await saveImagesToClaim(resultData.brandRightImages);
									} catch (e) {
										logToServer(
											currentUser?.UserId,
											e,
											`Claim Thoroughbred - save images to uploaded-images failed - data: ${resultData.brandRightImages}`
										);
									}
									try {
										await saveImagesToClaim(resultData.markingsFrontImages);
									} catch (e) {
										logToServer(
											currentUser?.UserId,
											e,
											`Claim Thoroughbred - save images to uploaded-images failed - data: ${resultData.markingsFrontImages}`
										);
									}
									try {
										await saveImagesToClaim(resultData.markingsSideImages);
									} catch (e) {
										logToServer(
											currentUser?.UserId,
											e,
											`Claim Thoroughbred - save images to uploaded-images failed - data: ${resultData.markingsSideImages}`
										);
									}
									try {
										await saveImagesToClaim(resultData.microchipImages);
									} catch (e) {
										logToServer(
											currentUser?.UserId,
											e,
											`Claim Thoroughbred - save images to uploaded-images failed - data: ${resultData.microchipImages}`
										);
									}
									setIsUploadingPreviousImages(false);
								}
							}
							if (
								(data.data != null && resultData?.createDatetime != null) ||
								isPreviousClaim
							) {
								setMicrochip(resultData.microChipNumber);
								setSire(resultData.sireName);
								setDam(resultData.damName);
								if (resultData.foalYear != null) {
									try {
										setFoalDate(resultData.foalYear + '/01/01');
									} catch (error) {
										setFoalDate(null);
										logToServer(
											currentUser?.UserId,
											error,
											`Claim Thoroughbred - set foal year - data: ${resultData?.foalYear}`
										);
									}
								}
								try {
									if (resultData.stabledDate) {
										setStabledDate(new Date(resultData.stabledDate));
									} else {
										setStabledDate(null);
									}
								} catch (error) {
									setStabledDate(null);
									logToServer(
										currentUser?.UserId,
										error,
										`Claim Thoroughbred - set stabled date - data: ${resultData?.stabledDate}`
									);
								}
								let relationValue = resultData.ownershipType;
								setSelectedRelation(relationValue);
								if (relationValue === RELATIONSHIP_RETRAINER) {
									if (resultData.claimPurpose) {
										let purposeValue = purposeOptions.find(
											(x) => x.value === resultData.claimPurpose
										).id;
										setSelectedPurpose(purposeValue);
									} else {
										setSelectedPurpose(0);
									}
								}
								setOrganisationName(resultData.organisation);
								setIsAtAddress(resultData.isAtMyAddress ?? true);
								if (
									memberProfileResidentialAddress.address === '' ||
									memberProfileResidentialAddress.address === null
								) {
									if (resultData.gender != null) {
										let value = genderOptions.find(
											(x) => x.value === resultData.gender
										).id;
										setSelectedGender(value);
									}
									setPhoneNumber(resultData.phone);
									setAddress(resultData.residentialAddress);
									setSuburb(resultData.residentialSuburb);
									setPostcode(resultData.residentialPostcode);
									setState(resultData.residentialState || '');
									setCountry(resultData.residentialCountry || DEFAULT_COUNTRY);
								}
								if (resultData.isAtMyAddress != true) {
									setThoroughbredAddress(resultData.stabledAddressLine);
									setThoroughbredSuburb(resultData.stabledSuburb);
									setThoroughbredPostcode(resultData.stabledPostcode);
									setThoroughbredState(resultData.stabledState || '');
									setThoroughbredCountry(
										resultData.stabledCountry || DEFAULT_COUNTRY
									);
									if (resultData.stabledAddressValidated != null) {
										setIsThoroughbredAddressManual(
											!resultData.stabledAddressValidated
										);
									}
								}
								setSelectedClaimSource(resultData?.claimsSource || 0);
								if (resultData.claimsSourceOther) {
									setSelectedClaimSourceOther(resultData.claimsSourceOther);
								}
							} else {
								setnoClaimFound(true);
							}
						});
					}
					setIsLoading(false);
				});
			});
		}
	}, [horseCode, currentUser]);

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const saveImagesToClaim = async (imagesArr, retryCount = 3) => {
		try {
			if (imagesArr?.length > 0) {
				await Promise.allSettled(
					imagesArr.map(async (img) => {
						const saveImage = {
							filename: img.filename,
							horseCode: img.horseCode,
							imageSection: img.imageSection,
							key: img.key
						};
						await AppSyncService.execute(uploadImage, { saveImage });
					})
				);
			}
			return true;
		} catch (err) {
			if (retryCount == 0)
				throw 'Saving of images still failed after retrying 3 times';
			else return await saveImagesToClaim(imagesArr, --retryCount);
		}
	};

	const microchipPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {};
						try {
							saveImage = {
								filename: image.name,
								horseCode: horseCode,
								imageSection: 'microchip',
								key: imageKey,
								...(isSubmitOnBehalf &&
									selectedUserVerifiedInfo && {
										memberExternalId: selectedUserVerifiedInfo.memberExternalId
									})
							};
						} catch {
							addToast({ Message: 'Error Uploading...', IsSuccess: false });
						}
						AppSyncService.execute(uploadImage, { saveImage }).then((data) => {
							try {
								let dataList = Object.assign([], microchipImages);
								dataList.push(data.data.saveUploadedImage);
								setMicrochipImages(dataList);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const brandLeftPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {};
						try {
							saveImage = {
								filename: image.name,
								horseCode: horseCode,
								imageSection: 'brandLeft',
								key: imageKey,
								...(isSubmitOnBehalf &&
									selectedUserVerifiedInfo && {
										memberExternalId: selectedUserVerifiedInfo.memberExternalId
									})
							};
						} catch {
							addToast({ Message: 'Error Uploading...', IsSuccess: false });
						}
						AppSyncService.execute(uploadImage, { saveImage }).then((data) => {
							try {
								let dataList = Object.assign([], brandLeftImages);
								dataList.push(data.data.saveUploadedImage);
								setBrandLeftImages(dataList);
								setBrandLeftImageExist(true);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const brandRightPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {};
						try {
							saveImage = {
								filename: image.name,
								horseCode: horseCode,
								imageSection: 'brandRight',
								key: imageKey,
								...(isSubmitOnBehalf &&
									selectedUserVerifiedInfo && {
										memberExternalId: selectedUserVerifiedInfo.memberExternalId
									})
							};
						} catch {
							addToast({ Message: 'Error Uploading...', IsSuccess: false });
						}
						AppSyncService.execute(uploadImage, { saveImage }).then((data) => {
							try {
								let dataList = Object.assign([], brandRightImages);
								dataList.push(data.data.saveUploadedImage);
								setBrandRightImages(dataList);
								setBrandRightImageExist(true);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const markingFrontPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {};
						try {
							saveImage = {
								filename: image.name,
								horseCode: horseCode,
								imageSection: 'markingsFront',
								key: imageKey,
								...(isSubmitOnBehalf &&
									selectedUserVerifiedInfo && {
										memberExternalId: selectedUserVerifiedInfo.memberExternalId
									})
							};
						} catch {
							addToast({ Message: 'Error Uploading...', IsSuccess: false });
						}
						AppSyncService.execute(uploadImage, { saveImage }).then((data) => {
							try {
								let dataList = Object.assign([], markingsFrontImages);
								dataList.push(data.data.saveUploadedImage);
								setMarkingsFrontImages(dataList);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const markingSidePhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {};
						try {
							saveImage = {
								filename: image.name,
								horseCode: horseCode,
								imageSection: 'markingsSide',
								key: imageKey,
								...(isSubmitOnBehalf &&
									selectedUserVerifiedInfo && {
										memberExternalId: selectedUserVerifiedInfo.memberExternalId
									})
							};
						} catch {
							addToast({ Message: 'Error Uploading...', IsSuccess: false });
						}
						AppSyncService.execute(uploadImage, { saveImage }).then((data) => {
							try {
								let dataList = Object.assign([], markingsSideImages);
								dataList.push(data.data.saveUploadedImage);
								setMarkingsSideImages(dataList);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const updatePostCode = (postCode) => {
		const regex = RegExp(/^\d*$/);
		if (regex.test(postCode) && postCode.toString().length <= 4) {
			setPostcode(postCode);
		}
	};

	const updatePhoneNumber = (number) => {
		const regex = RegExp(/^\+\d*$|^\d*$/);
		if (regex.test(number)) {
			setPhoneNumber(number);
		}
	};

	const updateUserVerifiedPhoneNumber = (number) => {
		const regex = RegExp(/^\+\d*$|^\d*$/);
		if (regex.test(number)) {
			setSelectedUserVerifiedInfo({
				...selectedUserVerifiedInfo,
				phone: number
			});
		}
	};

	const updateClaim = () => {
		let horseClaimData = {
			horseCode: parseInt(horseCode),
			damName: dam === '' ? null : dam,
			foalYear: foalDate != null ? parseInt(foalDate.substr(0, 4)) : null,
			sireName: sire === '' ? null : sire,
			score: score,
			organisation: organisationName === '' ? null : organisationName,
			isAtMyAddress: isAtAddress,
			stabledAddressLine: thoroughbredAddress,
			stabledSuburb: thoroughbredSuburb,
			stabledState: thoroughbredState,
			stabledPostcode: thoroughbredPostcode,
			stabledCountry: !isAtAddress ? thoroughbredCountry : '',
			stabledAddressValidated: !isThoroughbredAddressManual,
			stabledDate: stabledDate === '' ? null : stabledDate,
			ownershipType: selectedRelation,
			claimPurpose:
				selectedRelation === RELATIONSHIP_RETRAINER
					? purposeOptions[selectedPurpose].value
					: null,
			claimsSource: +selectedClaimSource === 0 ? null : +selectedClaimSource,
			claimsSourceOther: isOptionOther(claimSources, selectedClaimSource)
				? selectedClaimSourceOther
				: null,
			microChipNumber: microchip === '' ? null : microchip,
			phone: phoneNumber === '' ? null : phoneNumber,
			gender:
				genderOptions[selectedGender].value === ''
					? null
					: genderOptions[selectedGender].value,
			residentialAddress: address === '' ? null : address,
			residentialSuburb: suburb === '' ? null : suburb,
			residentialState: state === '' ? null : state,
			residentialPostcode: parseInt(postcode),
			residentialCountry: country === '' ? null : country,
			isSubmitOnBehalf: false
		};
		if (noClaimFound) {
			AppSyncService.execute(saveHorseClaim, {
				horseClaimData
			}).then((data) => {
				if (data.data != null) {
					setnoClaimFound(false);
					addToast({ Message: 'Form Saved', IsSuccess: true });
					if (!isPreviousClaim) navigate(DASHBOARD_ROUTE);
					else navigate('/?activeTab=my-stable&activeRole=RETRAINER');
				}
			});
		} else {
			AppSyncService.execute(saveHorseClaim, {
				horseClaimData
			}).then((data) => {
				if (data.data != null) {
					addToast({ Message: 'Form Saved', IsSuccess: true });
					if (!isPreviousClaim) navigate(DASHBOARD_ROUTE);
					else navigate('/?activeTab=my-stable&activeRole=RETRAINER');
				}
			});
		}
	};

	const validThoroughbredAddress = () => {
		if (!isAtAddress) {
			if (
				thoroughbredAddress != '' &&
				thoroughbredSuburb != '' &&
				thoroughbredState != '' &&
				thoroughbredPostcode != '' &&
				thoroughbredCountry != ''
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const completeClaim = () => {
		if (!areRequiredFieldsMet()) {
			setIsShowErrorMessages(true);
			return;
		}

		if (
			address != null &&
			address != '' &&
			state != null &&
			state != '' &&
			suburb != null &&
			suburb != '' &&
			postcode != null &&
			postcode != '' &&
			postcode > 0 &&
			country != null &&
			country != '' &&
			phoneNumber != null &&
			phoneNumber != '' &&
			(selectedRelation !== RELATIONSHIP_ORGANISATION ||
				(selectedRelation === RELATIONSHIP_ORGANISATION &&
					organisationName != '' &&
					organisationName != null)) &&
			(selectedGender != 0 || (gender != '' && gender != null)) &&
			brandLeftImageExist &&
			brandRightImageExist &&
			stabledDate &&
			score >= 100 &&
			!isSubmitted &&
			validThoroughbredAddress()
		) {
			let horseClaimData = {
				horseCode: parseInt(horseCode),
				damName: dam === '' ? null : dam,
				organisation: organisationName === '' ? null : organisationName,
				isAtMyAddress: isAtAddress,
				stabledAddressLine: thoroughbredAddress,
				stabledSuburb: thoroughbredSuburb,
				stabledState: thoroughbredState,
				stabledPostcode: thoroughbredPostcode,
				stabledCountry: thoroughbredCountry,
				stabledAddressValidated: !isThoroughbredAddressManual,
				stabledDate: stabledDate === '' ? null : stabledDate,
				foalYear: foalDate != null ? parseInt(foalDate.substr(0, 4)) : null,
				sireName: sire === '' ? null : sire,
				score: score,
				ownershipType: selectedRelation,
				claimPurpose:
					selectedRelation === RELATIONSHIP_RETRAINER
						? purposeOptions[selectedPurpose].value
						: null,
				claimsSource: +selectedClaimSource === 0 ? null : +selectedClaimSource,
				claimsSourceOther: isOptionOther(claimSources, selectedClaimSource)
					? selectedClaimSourceOther
					: null,
				microChipNumber: microchip === '' ? null : microchip,
				phone: phoneNumber === '' ? null : phoneNumber,
				gender:
					genderOptions[selectedGender].value === ''
						? null
						: genderOptions[selectedGender].value,
				residentialAddress: address === '' ? null : address,
				residentialSuburb: suburb === '' ? null : suburb,
				residentialState: state === '' ? null : state,
				residentialPostcode: parseInt(postcode),
				residentialCountry: country === '' ? null : country,
				complete: true,
				isSubmitOnBehalf: false
			};
			if (noClaimFound) {
				AppSyncService.execute(saveHorseClaim, {
					horseClaimData
				}).then((data) => {
					if (data.data != null) {
						try {
							if (profileInfo.gender === null || profileInfo.gender === '') {
								updateProfile();
							}
							if (
								profileInfo.residentialAddress.country === null ||
								profileInfo.residentialAddress.country === ''
							) {
								updateAddress();
							}
							addToast({ Message: 'Form Submitted', IsSuccess: true });
							setIsSubmitted(true);
							setnoClaimFound(false);
							if (!isPreviousClaim) navigate(DASHBOARD_ROUTE);
							else navigate('/?activeTab=my-stable&activeRole=RETRAINER');
						} catch {
							addToast({ Message: 'Error Saving...', IsSuccess: false });
						}
					}
				});
			} else {
				AppSyncService.execute(saveHorseClaim, {
					horseClaimData
				}).then(() => {
					try {
						if (profileInfo.gender === null || profileInfo.gender === '') {
							updateProfile();
						}
						if (
							profileInfo.residentialAddress.country === null ||
							profileInfo.residentialAddress.country === ''
						) {
							updateAddress();
						}
						addToast({ Message: 'Form Completed', IsSuccess: true });
						setIsSubmitted(true);
						if (!isPreviousClaim) navigate(DASHBOARD_ROUTE);
						else navigate('/?activeTab=my-stable&activeRole=RETRAINER');
					} catch {
						addToast({ Message: 'Error Saving...', IsSuccess: false });
					}
				});
			}
		}
	};

	const updateProfile = () => {
		const { gender, phone } = selectedUserVerifiedInfo || {};
		let updateMemberProfileData = {
			gender: genderOptions[selectedGender].value,
			phone: phoneNumber
		};

		if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
			let evaluatedGender = isSelectedProfileChangedByAdmin
				? gender
				: onEvaluateGender(gender);
			updateMemberProfileData = {
				...updateMemberProfileData,
				...(gender && { gender: genderOptions[evaluatedGender].value }),
				...(phone && { phone: phone }),
				...(isSubmitOnBehalf &&
					selectedUserVerifiedInfo && {
						externalId: selectedUserVerifiedInfo.memberExternalId
					})
			};
		}

		AppSyncService.execute(updateMemberProfile, {
			updateMemberProfileData
		});
	};

	const updateAddress = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		let saveAddress = {
			memberExternalId:
				isSubmitOnBehalf && selectedUserVerifiedInfo
					? selectedUserVerifiedInfo.memberExternalId
					: '',
			address: address,
			country: country,
			postcode: postcode,
			state: state,
			suburb: suburb
		};
		if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
			saveAddress = {
				...saveAddress,
				address: residentialAddress?.address,
				country: residentialAddress?.country,
				postcode: residentialAddress?.postcode,
				state: residentialAddress?.state,
				suburb: residentialAddress?.suburb
			};
		}

		AppSyncService.execute(saveResidentialAddress, {
			saveAddress
		});
	};

	const updateRelation = (val, dataHorseProgram = null) => {
		if (horseProgram === 3 || dataHorseProgram === 3) {
			if (val === ACK_FOSTER_PROVIDER) {
				setIsAcquireReadOnly(true);
				setSelectedClaimSource(RV_FOSTER_PROGRAM_ID);
				setSelectedClaimSourceOther('');
			} else {
				if (isAcquireReadOnly) setSelectedClaimSource(0);
				setIsAcquireReadOnly(false);
			}
		}
		setSelectedRelation(val);
	};

	const updatePurpose = (e) => {
		setSelectedPurpose(e.target.value);
	};

	const updateGender = (e) => {
		setSelectedGender(e.target.value);
	};

	const updateGenderByAdmin = (e) => {
		setSelectedUserVerifiedInfo({
			...selectedUserVerifiedInfo,
			gender: e.target.value
		});
		setIsSelectedProfileChangedByAdmin(true);
	};

	const purposeOptions = [
		{ id: 0, value: 'Retrain for sale' },
		{ id: 1, value: 'Retrain on behalf of client' }
	];

	const genderOptions = [
		{ id: 0, value: '' },
		{ id: 1, value: 'Male' },
		{ id: 2, value: 'Female' },
		{ id: 3, value: 'Prefer not to disclose' }
	];

	const populateResidentialAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setAddress(address);
		setSuburb(addressDetails.Suburb);
		setPostcode(addressDetails.Postcode);
		setState(addressDetails.State);
		setCountry(addressDetails.Country);
	};
	const populateThoroughbredAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setThoroughbredAddress(address);
		setThoroughbredSuburb(addressDetails.Suburb);
		setThoroughbredPostcode(addressDetails.Postcode);
		setThoroughbredState(addressDetails.State);
		setThoroughbredCountry(addressDetails.Country);
	};
	const toggleManualAddress = () => {
		setIsPostalAddressManual(!isPostalAddressManual);
	};
	const toggleManualThoroughbredAddress = () => {
		setIsThoroughbredAddressManual(!isThoroughbredAddressManual);
	};
	const updateThoroughbredPostCode = (postCode) => {
		const regex = RegExp(/^\d*$/);
		if (regex.test(postCode) && postCode.toString().length <= 4) {
			setThoroughbredPostcode(postCode);
		}
	};
	const updateIsAtAddress = (value) => {
		if (value === false) {
			setIsThoroughbredAddressManual(false);
			setThoroughbredAddress('');
			setThoroughbredSuburb('');
			setThoroughbredState('');
			setThoroughbredPostcode('');
			setThoroughbredCountry(DEFAULT_COUNTRY);
		}
		setIsAtAddress(value);
	};

	const areRequiredFieldsMet = () => {
		return (
			profileInfo.firstName &&
			profileInfo.lastName &&
			profileInfo.email &&
			phoneNumber &&
			(selectedGender != 0 || (gender != '' && gender != null)) &&
			address &&
			suburb &&
			state &&
			postcode &&
			country &&
			stabledDate &&
			((!isAtAddress &&
				thoroughbredAddress &&
				thoroughbredSuburb &&
				thoroughbredCountry &&
				thoroughbredState &&
				thoroughbredPostcode) ||
				isAtAddress) &&
			brandRightImages.length > 0 &&
			brandLeftImages.length > 0
		);
	};

	const areRequiredFieldsMetBySubmitAdmin = () => {
		const { residentialAddress, gender, phone } =
			selectedUserVerifiedInfo || {};
		return (
			phone &&
			gender &&
			residentialAddress?.address &&
			residentialAddress?.suburb &&
			residentialAddress?.state &&
			residentialAddress?.postcode &&
			residentialAddress?.country &&
			stabledDate &&
			((!isAtAddress &&
				thoroughbredAddress &&
				thoroughbredSuburb &&
				thoroughbredCountry &&
				thoroughbredState &&
				thoroughbredPostcode) ||
				isAtAddress) &&
			brandRightImages.length > 0 &&
			brandLeftImages.length > 0 &&
			score >= 100
		);
	};

	useEffect(() => {
		if (areRequiredFieldsMet()) setIsShowErrorMessages(false);
	}, [
		profileInfo,
		phoneNumber,
		gender,
		address,
		suburb,
		state,
		postcode,
		country,
		stabledDate,
		thoroughbredAddress,
		thoroughbredSuburb,
		thoroughbredCountry,
		thoroughbredState,
		thoroughbredPostcode,
		brandRightImages,
		brandLeftImages,
		isAtAddress
	]);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	useEffect(() => {
		if (isSubmitOnBehalf) {
			setRelationOptions(tempRelationOptions);
		} else {
			setRelationOptions(relationOptionFiltered);
		}
	}, [isSubmitOnBehalf]);

	const onSubmitOnBehalfByAdmin = async () => {
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
			setIsShowErrorMessages(true);
			return;
		} else {
			setIsSelectUserRequired(false);
		}
		if (!areRequiredFieldsMetBySubmitAdmin()) {
			setIsShowErrorMessages(true);
			return;
		}

		const { residentialAddress, gender, phone, id } =
			selectedUserVerifiedInfo || {};

		let horseClaimData = {
			horseCode: parseInt(horseCode),
			damName: dam === '' ? null : dam,
			organisation: organisationName === '' ? null : organisationName,
			isAtMyAddress: isAtAddress,
			stabledAddressLine: thoroughbredAddress,
			stabledSuburb: thoroughbredSuburb,
			stabledState: thoroughbredState,
			stabledPostcode: thoroughbredPostcode,
			stabledCountry: thoroughbredCountry,
			stabledAddressValidated: !isThoroughbredAddressManual,
			stabledDate: stabledDate === '' ? null : stabledDate,
			foalYear: foalDate != null ? parseInt(foalDate.substr(0, 4)) : null,
			sireName: sire === '' ? null : sire,
			score: score,
			ownershipType: selectedRelation,
			claimPurpose:
				selectedRelation === RELATIONSHIP_RETRAINER
					? purposeOptions[selectedPurpose].value
					: null,
			claimsSource: +selectedClaimSource === 0 ? null : +selectedClaimSource,
			claimsSourceOther: isOptionOther(claimSources, selectedClaimSource)
				? selectedClaimSourceOther
				: null,
			microChipNumber: microchip === '' ? null : microchip,
			phone: !phone ? null : phone,
			gender: !gender ? null : gender,
			residentialAddress: residentialAddress
				? !residentialAddress?.address
					? null
					: residentialAddress?.address
				: null,
			residentialSuburb: residentialAddress
				? !residentialAddress?.suburb
					? null
					: residentialAddress?.suburb
				: null,
			residentialState: residentialAddress
				? !residentialAddress?.state
					? null
					: residentialAddress?.state
				: null,
			residentialPostcode: residentialAddress
				? !residentialAddress?.postcode
					? null
					: parseInt(residentialAddress?.postcode)
				: null,
			residentialCountry: residentialAddress
				? !residentialAddress?.country
					? null
					: residentialAddress?.country
				: null,
			complete: true,
			isSubmitOnBehalf: true,
			memberId: id
		};
		const result = await AppSyncService.execute(saveHorseClaim, {
			horseClaimData
		});

		if (result) {
			if (isSelectedProfileChangedByAdmin) {
				updateProfile();
			}
			if (isSelectedAddressChangedByAdmin) {
				updateAddress();
			}

			addToast({ Message: 'Form Submitted', IsSuccess: true });
			setIsSubmitted(true);
			setnoClaimFound(false);
			navigate(DASHBOARD_ROUTE);
		}
	};

	const getListOfKeys = () => {
		let listOfKeys = [];
		if (brandLeftImages.length > 0) {
			brandLeftImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (brandRightImages.length > 0) {
			brandRightImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (markingsFrontImages.length > 0) {
			markingsFrontImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (markingsSideImages.length > 0) {
			markingsSideImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (microchipImages.length > 0) {
			microchipImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		return listOfKeys;
	};

	const removeImagesFromSelectedUser = async (listOfKeys, byAdmin) => {
		const result = await AppSyncService.execute(deleteImagesFromS3, {
			imageKeys: listOfKeys
		});
		if (result?.data?.deleteUserImages?.success) {
			const deletedImageResult = await AppSyncService.execute(
				deleteUploadedImageByMemberIdAndHorseCode,
				{
					memberId: byAdmin ? profileInfo.id : selectedUserVerifiedInfo.id,
					horseCode: horseCode
				}
			);
			if (deletedImageResult?.data?.deleteUploadedImageByMemberIdAndHorseCode) {
				setBrandLeftImages([]);
				setBrandRightImages([]);
				setMarkingsFrontImages([]);
				setMarkingsSideImages([]);
				setMicrochipImages([]);
			}
		}
	};

	const onClearFields = async () => {
		setFoalDate(null);
		setMicrochip('');
		setSire('');
		setDam('');
		setStabledDate(null);
		setSelectedClaimSource(0);
		setSelectedClaimSourceOther('');

		let listOfKeys = getListOfKeys();

		if (listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys, false);
		}
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
		let onBehalfRelationshipOpt = tempRelationOptions;
		const isAckRehomerRole = selectedUserVerified?.roles.some(
			(role) => role.code === ROLE_REHOMER
		);
		const isAckRetirementFarm = selectedUserVerified?.roles.some(
			(role) => role.code === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
		);
		if (!isAckRetirementFarm) {
			onBehalfRelationshipOpt = onBehalfRelationshipOpt.filter(
				(rel) => rel !== ACK_RETIREMENT_FARM
			);
		}
		if (!isAckRehomerRole) {
			onBehalfRelationshipOpt = onBehalfRelationshipOpt.filter(
				(rel) => rel !== ACK_REHOMER
			);
		}
		setRelationOptions([...onBehalfRelationshipOpt]);
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		let listOfKeys = getListOfKeys();
		if (submitOnBehalf && listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys, true);
		}
		if (!submitOnBehalf) {
			onClearFields();
		}
	};

	const onEvaluateGender = (userSelectedGender) => {
		switch (userSelectedGender) {
			case '':
				return 0;
			case 'Male':
				return 1;
			case 'Female':
				return 2;
			case 'Prefer not to disclose':
				return 3;
		}
	};

	const onRenderPersonalProfileSubmitByAdmin = () => {
		return (
			<>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>First Name*</div>
							<FormTextField
								value={
									selectedUserVerifiedInfo
										? selectedUserVerifiedInfo.firstName
										: ''
								}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Last Name*</div>
							<FormTextField
								value={
									selectedUserVerifiedInfo
										? selectedUserVerifiedInfo.lastName
										: ''
								}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Email*</div>
							<FormTextField
								value={
									selectedUserVerifiedInfo ? selectedUserVerifiedInfo.email : ''
								}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Phone* (Personal)</div>
							<FormTextField
								value={
									selectedUserVerifiedInfo ? selectedUserVerifiedInfo.phone : ''
								}
								onChange={(e) => {
									updateUserVerifiedPhoneNumber(e.target.value);
									setIsSelectedProfileChangedByAdmin(true);
								}}
							/>
						</div>
						{isShowErrorMessages && !selectedUserVerifiedInfo?.phone && (
							<span className={classes.errorText}>Phone is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Gender*</div>
							<FormDropDown
								items={genderOptions}
								selectedId={onEvaluateGender(
									selectedUserVerifiedInfo
										? selectedUserVerifiedInfo.gender
										: ''
								)}
								onChange={updateGenderByAdmin}
								styles={{ width: '100%' }}
								showBlank
							/>
						</div>
						{isShowErrorMessages && !selectedUserVerifiedInfo?.gender && (
							<span className={classes.errorText}>Gender is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}></Col>
				</Row>
			</>
		);
	};

	const onRenderPersonalProfileByMember = () => {
		return (
			<>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>First Name*</div>
							<FormTextField
								value={profileInfo.firstName}
								canEdit={profileInfo.firstName == null}
							/>
						</div>
						{isShowErrorMessages && !profileInfo.firstName && (
							<span className={classes.errorText}>First name is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Last Name*</div>
							<FormTextField
								value={profileInfo.lastName}
								canEdit={profileInfo.lastName == null}
							/>
						</div>
						{isShowErrorMessages && !profileInfo.lastName && (
							<span className={classes.errorText}>Last name is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Email*</div>
							<FormTextField
								value={profileInfo.email}
								canEdit={profileInfo.email == null}
							/>
						</div>
						{isShowErrorMessages && !profileInfo.email && (
							<span className={classes.errorText}>Email is required</span>
						)}
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Phone* (Personal)</div>
							{!profileInfo.phone && (
								<FormTextField
									value={phoneNumber}
									onChange={(e) => updatePhoneNumber(e.target.value)}
								/>
							)}
							{profileInfo.phone && (
								<span className={classes.profileInfo}>{phoneNumber}</span>
							)}
						</div>
						{isShowErrorMessages && !phoneNumber && (
							<span className={classes.errorText}>Phone is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Gender*</div>
							{!profileInfo.gender && (
								<FormDropDown
									items={genderOptions}
									selectedId={selectedGender}
									onChange={updateGender}
									styles={{ width: '100%' }}
									showBlank
								/>
							)}
							{gender && <span className={classes.profileInfo}>{gender}</span>}
						</div>
						{isShowErrorMessages && !gender && (
							<span className={classes.errorText}>Gender is required</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}></Col>
				</Row>
			</>
		);
	};

	const populateResidentialAddressFieldsSubmitByAdmin = (addressDetails) => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setSelectedUserVerifiedInfo({
			...selectedUserVerifiedInfo,
			residentialAddress: {
				...residentialAddress,
				address: address,
				suburb: addressDetails.Suburb,
				postcode: addressDetails.Postcode,
				state: addressDetails.State,
				country: addressDetails.Country
			}
		});
	};

	const onRenderResidentialSubmitByAdmin = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		return (
			<>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Address*</div>
							<AddressAutoSuggestion
								value={residentialAddress ? residentialAddress.address : ''}
								onChange={(val) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: { ...residentialAddress, address: val }
									});
									setIsSelectedAddressChangedByAdmin(true);
								}}
								onAddressSelect={populateResidentialAddressFieldsSubmitByAdmin}
								disableAutoSuggestion={isPostalAddressManual}
							/>
						</div>
						{isShowErrorMessages && !residentialAddress?.address && (
							<span className={classes.errorText}>
								Residential Address is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Suburb*</div>
							<FormTextField
								value={residentialAddress ? residentialAddress?.suburb : ''}
								onChange={(e) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											suburb: e.target.value
										}
									});
									setIsSelectedAddressChangedByAdmin(true);
								}}
								isDisabled={!isPostalAddressManual}
							/>
						</div>
						{isShowErrorMessages && !residentialAddress?.suburb && (
							<span className={classes.errorText}>
								Residential Suburb is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential State*</div>
							<StateSelector
								id="claim-thoroughbred-form-residential-state-selector"
								country={country}
								value={residentialAddress ? residentialAddress?.state : ''}
								changeHandler={(newStateValue) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											state: newStateValue
										}
									});
									setIsSelectedAddressChangedByAdmin(true);
								}}
								isDisabled={!isPostalAddressManual}
							/>
						</div>
						{isShowErrorMessages && !residentialAddress?.state && (
							<span className={classes.errorText}>
								Residential State is required
							</span>
						)}
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Postcode*</div>
							<FormTextField
								value={residentialAddress ? residentialAddress?.postcode : ''}
								onChange={(e) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											postcode: e.target.value
										}
									});
									setIsSelectedAddressChangedByAdmin(true);
								}}
								isDisabled={!isPostalAddressManual}
							/>
						</div>
						{isShowErrorMessages && !residentialAddress?.postcode && (
							<span className={classes.errorText}>
								Residential Postcode is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Country*</div>
							<CountrySelector
								id="claim-thoroughbred-form-residential-country-selector"
								value={residentialAddress ? residentialAddress?.country : ''}
								changeHandler={(newCountryValue) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											country: newCountryValue
										}
									});
									setIsSelectedAddressChangedByAdmin(true);
								}}
								isDisabled={!isPostalAddressManual}
							/>
						</div>
						{isShowErrorMessages && !residentialAddress?.country && (
							<span className={classes.errorText}>
								Residential Country is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}></Col>
				</Row>
				<Row>
					<Col>
						<SimpleCheckBox
							small={true}
							noCapitalize={true}
							data={manualAddressCheckBoxData}
							changeCheckboxState={toggleManualAddress}
							key={manualAddressCheckBoxData && manualAddressCheckBoxData.id}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const onRenderResidentialByMember = () => {
		return (
			<>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Address*</div>
							<AddressAutoSuggestion
								value={address}
								onChange={setAddress}
								onAddressSelect={populateResidentialAddressFields}
								disableAutoSuggestion={isPostalAddressManual}
								canEdit={profileInfo?.residentialAddress?.address == null}
							/>
						</div>
						{isShowErrorMessages && !address && (
							<span className={classes.errorText}>
								Residential Address is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Suburb*</div>
							{!profileInfo?.residentialAddress?.suburb && (
								<FormTextField
									value={suburb}
									onChange={(e) => setSuburb(e.target.value)}
									isDisabled={!isPostalAddressManual}
								/>
							)}
							{profileInfo?.residentialAddress?.suburb && (
								<span className={classes.profileInfo}>{suburb}</span>
							)}
						</div>
						{isShowErrorMessages && !suburb && (
							<span className={classes.errorText}>
								Residential Suburb is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential State*</div>
							{!profileInfo?.residentialAddress?.state && (
								<StateSelector
									id="claim-thoroughbred-form-residential-state-selector"
									country={country}
									value={state}
									changeHandler={(newStateValue) => setState(newStateValue)}
									isDisabled={!isPostalAddressManual}
								/>
							)}
							{profileInfo?.residentialAddress?.state && (
								<span className={classes.profileInfo}>{state}</span>
							)}
						</div>
						{isShowErrorMessages && !state && (
							<span className={classes.errorText}>
								Residential State is required
							</span>
						)}
					</Col>
				</Row>
				<Row>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Postcode*</div>
							{(!profileInfo?.residentialAddress?.postcode ||
								profileInfo?.residentialAddress?.postcode === 0) && (
								<FormTextField
									value={postcode}
									onChange={(e) => updatePostCode(e.target.value)}
									isDisabled={!isPostalAddressManual}
								/>
							)}
							{profileInfo?.residentialAddress?.postcode &&
								profileInfo?.residentialAddress?.postcode > 0 && (
									<span className={classes.profileInfo}>{postcode}</span>
								)}
						</div>
						{isShowErrorMessages && !postcode && (
							<span className={classes.errorText}>
								Residential Postcode is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Country*</div>
							{!profileInfo?.residentialAddress?.country && (
								<CountrySelector
									id="claim-thoroughbred-form-residential-country-selector"
									value={country}
									changeHandler={(newCountryValue) =>
										setCountry(newCountryValue)
									}
									isDisabled={!isPostalAddressManual}
								/>
							)}
							{profileInfo?.residentialAddress?.country && (
								<span className={classes.profileInfo}>{country}</span>
							)}
						</div>
						{isShowErrorMessages && !country && (
							<span className={classes.errorText}>
								Residential Country is required
							</span>
						)}
					</Col>
					<Col lg={4} md={4} sm={12}></Col>
				</Row>
				{profileInfo?.residentialAddress?.address == null && (
					<Row>
						<Col>
							<SimpleCheckBox
								small={true}
								noCapitalize={true}
								data={manualAddressCheckBoxData}
								changeCheckboxState={toggleManualAddress}
								key={manualAddressCheckBoxData && manualAddressCheckBoxData.id}
							/>
						</Col>
					</Row>
				)}
			</>
		);
	};

	return (
		<PageContainer>
			<SEO title="Claim Thoroughbred" />
			{isAdminUser ? (
				<SubmitOnBehalf
					horseCode={horseCode}
					onToggleSubmitBehalf={onToggleSubmitBehalf}
					onSelectUserVerified={onSelectUserVerified}
					isGetUsersUnclaim
					isGetAllUsersVerified={false}
					selectUserRequiredError={selectUserRequiredError}
					isSelectUserRequired={isSelectUserRequired}
				/>
			) : null}
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<>
					<HeaderBanner
						title="Claim Thoroughbred Form"
						styles={{ marginBottom: '0px' }}
					/>
					<MessageBar>
						We need more info to complete your Claim request for{' '}
						<b>{addNameToUnnamedHorse(horseProfile.name)}.</b>
					</MessageBar>
					<HeaderBanner
						title="Thoroughbred Relationship"
						top
						styles={{ marginBottom: '0px', marginTop: '25px' }}
					/>
					<div className={classes.formContainer}>
						<div className={classes.personalProfile}>
							<Row>
								<Col md={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>I am an*</div>

										<FormDropDown
											id={ClaimThoroughbredFormKeys.RELATIONSHIP}
											selectedId={selectedRelation}
											items={relationOptions.map((item) => {
												return {
													id: item,
													value: item
												};
											})}
											onChange={(e) => updateRelation(e.target.value)}
											showBlank
										/>
									</div>
								</Col>
								{selectedRelation === RELATIONSHIP_ORGANISATION && (
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Organisation Name*
											</div>
											<FormTextField
												id={ClaimThoroughbredFormKeys.ORGANISATION_NAME}
												value={organisationName}
												onChange={(e) => setOrganisationName(e.target.value)}
												canEdit={profileInfo.organisationName == null}
											/>
										</div>
									</Col>
								)}
								{selectedRelation === RELATIONSHIP_RETRAINER && (
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Purpose of Claim*
											</div>

											<FormDropDown
												items={purposeOptions}
												selectedId={selectedPurpose}
												onChange={updatePurpose}
												showBlank
												id={ClaimThoroughbredFormKeys.PURPOSE_OF_CLAIM}
											/>
										</div>
									</Col>
								)}
							</Row>
						</div>
						<HeaderBanner
							title="Personal Profile - Details"
							inline
							styles={{ marginBottom: '0px' }}
						/>
						<div className={classes.personalProfile}>
							{isSubmitOnBehalf && selectedUserVerifiedInfo
								? onRenderPersonalProfileSubmitByAdmin()
								: onRenderPersonalProfileByMember()}
						</div>
						<hr className={classes.divider}></hr>
						<div className={classes.personalProfile}>
							{isSubmitOnBehalf && selectedUserVerifiedInfo
								? onRenderResidentialSubmitByAdmin()
								: onRenderResidentialByMember()}
						</div>
						<hr className={classes.divider}></hr>
						<div className={classes.personalProfile}>
							<Row>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Is your thoroughbred at this address?
										</div>
										<Toggle
											id={
												ClaimThoroughbredFormKeys.IS_YOUR_THOROUGHBRED_AT_THIS_ADDRESS
											}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => updateIsAtAddress(value)}
											value={isAtAddress}
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Date Arrived* (Approximate)
										</div>
										<FormDatePicker
											id={ClaimThoroughbredFormKeys.DATE_ARRIVED}
											DateValue={stabledDate}
											updateSelection={(value) => updateStabledDate(value)}
										/>
									</div>
									{isShowErrorMessages && !stabledDate && (
										<span className={classes.errorText}>
											Date Arrived is required
										</span>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											How did you acquire this horse?
										</div>
										<FormDropDown
											id={
												ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE
											}
											items={claimSources}
											selectedId={selectedClaimSource}
											value={selectedClaimSource}
											onChange={(e) => {
												setSelectedClaimSource(e.target.value);
												setSelectedClaimSourceOther('');
											}}
											disabled={isAcquireReadOnly}
											showBlank
											name={`claimSource`}
										/>
									</div>
								</Col>
							</Row>
							{isOptionOther(claimSources, selectedClaimSource) && (
								<Row>
									<Col xs={12}>
										<ResizingTextArea
											id={
												ClaimThoroughbredFormKeys.HOW_DID_YOU_ACQUIRE_THIS_HORSE_OTHER
											}
											title="Please explain further"
											value={selectedClaimSourceOther}
											onChange={(e) => {
												setSelectedClaimSourceOther(e.target.value);
											}}
											fullWidth
										/>
									</Col>
								</Row>
							)}
							{!isAtAddress && (
								<>
									<Row>
										<Col lg={4} md={4} sm={12}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Thoroughbred Address*
												</div>
												<AddressAutoSuggestion
													id={ClaimThoroughbredFormKeys.THOROUGHBRED_ADDRESS}
													value={thoroughbredAddress}
													onChange={setThoroughbredAddress}
													onAddressSelect={populateThoroughbredAddressFields}
													disableAutoSuggestion={isThoroughbredAddressManual}
												/>
											</div>
											{isShowErrorMessages && !thoroughbredAddress && (
												<span className={classes.errorText}>
													Thoroughbred Address is required
												</span>
											)}
										</Col>
										<Col lg={4} md={4} sm={12}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Thoroughbred Suburb*
												</div>
												<FormTextField
													id={ClaimThoroughbredFormKeys.THOROUGHBRED_SUBURB}
													value={thoroughbredSuburb}
													onChange={(e) =>
														setThoroughbredSuburb(e.target.value)
													}
													isDisabled={!isThoroughbredAddressManual}
												/>
											</div>
											{isShowErrorMessages && !thoroughbredSuburb && (
												<span className={classes.errorText}>
													Thoroughbred Suburb is required
												</span>
											)}
										</Col>
										<Col lg={4} md={4} sm={12}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Thoroughbred State*
												</div>
												<StateSelector
													id={ClaimThoroughbredFormKeys.THOROUGHBRED_STATE}
													country={thoroughbredCountry}
													value={thoroughbredState}
													changeHandler={(newStateValue) =>
														setThoroughbredState(newStateValue)
													}
													isDisabled={!isThoroughbredAddressManual}
												/>
											</div>
											{isShowErrorMessages && !thoroughbredState && (
												<span className={classes.errorText}>
													Thoroughbred State is required
												</span>
											)}
										</Col>
									</Row>
									<Row>
										<Col lg={4} md={4} sm={12}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Thoroughbred Postcode*
												</div>
												<FormTextField
													id={ClaimThoroughbredFormKeys.THOROUGHBRED_POSTCODE}
													value={thoroughbredPostcode}
													onChange={(e) =>
														updateThoroughbredPostCode(e.target.value)
													}
													isDisabled={!isThoroughbredAddressManual}
												/>
											</div>
											{isShowErrorMessages && !thoroughbredPostcode && (
												<span className={classes.errorText}>
													Thoroughbred Postcode is required
												</span>
											)}
										</Col>
										<Col lg={4} md={4} sm={12}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Thoroughbred Country*
												</div>
												<CountrySelector
													id={ClaimThoroughbredFormKeys.THOROUGHBRED_COUNTRY}
													value={thoroughbredCountry}
													changeHandler={(newCountryValue) =>
														setThoroughbredCountry(newCountryValue)
													}
													isDisabled={!isThoroughbredAddressManual}
												/>
											</div>
											{isShowErrorMessages && !thoroughbredCountry && (
												<span className={classes.errorText}>
													Thoroughbred Country is required
												</span>
											)}
										</Col>
									</Row>
									<Row>
										<Col>
											<SimpleCheckBox
												small={true}
												noCapitalize={true}
												data={manualThoroughbredAddressCheckBoxData}
												changeCheckboxState={toggleManualThoroughbredAddress}
												key={
													manualThoroughbredAddressCheckBoxData &&
													manualThoroughbredAddressCheckBoxData.id
												}
											/>
										</Col>
									</Row>
								</>
							)}
						</div>
						<HeaderBanner
							title="Thoroughbred Identification"
							inline
							styles={{ marginTop: 40 }}
						/>
						<MessageBar type="primary" className={classes.pointMessage}>
							<div className={classes.claimMessage}>
								{!['xs', 'sm', 'md'].includes(screenClassForRender) && (
									<span className={classes.pointsImageContainer}>
										<img src={PointIcon} className={classes.pointsImage} />
									</span>
								)}
								To claim this thoroughbred, please provide at least 100 points
								of identification. Minimum of one each; left/near side and
								right/offside photo is required.
							</div>
							<div
								className={score < 100 ? classes.pointsError : classes.points}
								style={{ float: 'right' }}
							>
								{score}/100 Points
							</div>
							<div>
								<br />
								<a
									href={'/photo-upload-guide'}
									target="_blank"
									rel="noreferrer"
									className={classes.mailLink}
								>
									<b>
										Need help with photo requirements? Click here to view
										examples of suitable photos of your thoroughbred
									</b>
								</a>
							</div>
						</MessageBar>
						<MessageBar type="info" className={classes.horseInfo}>
							<div className={classes.horseName}>
								{addNameToUnnamedHorse(horseProfile.name)}
							</div>
							<div className={classes.horseAdditionalInfo}>
								{horseProfile.colour}&nbsp;&nbsp;&nbsp;&nbsp;{horseProfile.sex}
								&nbsp;&nbsp;&nbsp;&nbsp;{horseProfile.age}&nbsp;years
							</div>
						</MessageBar>
						<div className={classes.claimHorseContainer}>
							<Row>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.uploadFieldTitle}>
											{`Brand Photo - Left* (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES} Points - jpg,png)`}
										</div>
										<UploadPhoto
											id={ClaimThoroughbredFormKeys.BRAND_PHOTO_LEFT}
											formats={['png', 'jpeg']}
											error={uploadError}
											data={brandLeftImages}
											sizeLimitMB={5}
											success={brandLeftPhotoUpload}
											getImage={getImage}
											removeImage={removeBrandLeftImage}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</div>
									{isShowErrorMessages &&
										(!brandLeftImages || brandLeftImages?.length == 0) && (
											<span className={classes.errorText}>
												Brand Photo - Left is required
											</span>
										)}
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.uploadFieldTitle}>
											{`Brand Photo - Right* (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES} Points - jpg,png)`}
										</div>
										<UploadPhoto
											id={ClaimThoroughbredFormKeys.BRAND_PHOTO_RIGHT}
											formats={['png', 'jpeg']}
											error={uploadError}
											data={brandRightImages}
											sizeLimitMB={5}
											success={brandRightPhotoUpload}
											getImage={getImage}
											removeImage={removeBrandRightImage}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</div>
									{isShowErrorMessages &&
										(!brandRightImages || brandRightImages?.length == 0) && (
											<span className={classes.errorText}>
												Brand Photo - Right is required
											</span>
										)}
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.uploadFieldTitle}>
											{`Markings Photo - Side (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO} Points - jpg,png)`}
										</div>
										<UploadPhoto
											id={ClaimThoroughbredFormKeys.MARKINGS_PHOTO_SIDE}
											formats={['png', 'jpeg']}
											error={uploadError}
											data={markingsSideImages}
											sizeLimitMB={5}
											success={markingSidePhotoUpload}
											getImage={getImage}
											removeImage={removeMarkingSideImage}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.uploadFieldTitle}>
											{`Markings Photo - Front (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO} Points - jpg,png)`}
										</div>
										<UploadPhoto
											id={ClaimThoroughbredFormKeys.MARKINGS_PHOTO_FRONT}
											formats={['png', 'jpeg']}
											error={uploadError}
											data={markingsFrontImages}
											sizeLimitMB={5}
											success={markingFrontPhotoUpload}
											getImage={getImage}
											removeImage={removeMarkingFrontImage}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.uploadFieldTitle}>
											{`Microchip Document (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_IMAGES} Points - jpg,png)`}
										</div>
										<UploadPhoto
											id={ClaimThoroughbredFormKeys.MICROCHIP_DOCUMENT}
											formats={['png', 'jpeg']}
											sizeLimitMB={5}
											data={microchipImages}
											error={uploadError}
											success={microchipPhotoUpload}
											getImage={getImage}
											removeImage={removeMicrochipImage}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div
											className={classes.fieldTitle}
										>{`Foal Year (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.FOAL_YEAR} Points)`}</div>
										<FormDatePicker
											id={ClaimThoroughbredFormKeys.FOAL_YEAR}
											DateValue={foalDate}
											format="yyyy"
											validFoalDate={validFoalDate}
											isValidation={true}
											isValid={validFoalDate}
											updateSelection={(value) => updateFoalDate(value)}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div className={classes.fieldTitle}>
											{`Microchip Number (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_NUMBER} Points)`}
										</div>
										<FormValidateTextField
											id={ClaimThoroughbredFormKeys.MICROCHIP_NUMBER}
											isValid={validMicrochip}
											value={microchip}
											onChange={(e) => setMicrochip(e.target.value)}
											// canEdit={false} //TODO: Check and remove any updates.
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div
											className={classes.fieldTitle}
										>{`Sire Name (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.SIRE_NAME} Points)`}</div>
										<FormValidateTextField
											id={ClaimThoroughbredFormKeys.SIRE_NAME}
											isValid={validSire}
											value={sire}
											onChange={(e) => updateSireName(e.target.value)}
										/>
									</div>
								</Col>
								<Col lg={4} md={4} sm={12}>
									<div className={classes.claimFieldContainer}>
										<div
											className={classes.fieldTitle}
										>{`Dam Name (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.DAM_NAME} Points)`}</div>
										<FormValidateTextField
											id={ClaimThoroughbredFormKeys.DAM_NAME}
											isValid={validDam}
											value={dam}
											onChange={(e) => updateDamName(e.target.value)}
										/>
									</div>
								</Col>
							</Row>
						</div>
						<hr className={classes.IdentificationDivider}></hr>
						<div className={classes.buttonContainer}>
							<div
								id={ClaimThoroughbredFormKeys.CANCEL_REQUEST}
								className={classNames({
									[classes.cancelRequest]: true,
									invisible: formStatus === APPROVED
								})}
								disabled={isUploadingPreviousImages}
								onClick={() => cancelClaim()}
							>
								Cancel Request
							</div>
							<div className={classes.saveRequest}>
								{isShowErrorMessages && (
									<span className={classes.errorMessage}>
										Please complete all required fields
									</span>
								)}
								<span
									id={CommonKeys.SAVE_DRAFT}
									className={classes.saveButton}
									disabled={isUploadingPreviousImages}
									onClick={() => updateClaim()}
									style={isSubmitted ? { display: 'none' } : {}}
								>
									Save Draft
								</span>
								<span>
									<input
										id={ClaimThoroughbredFormKeys.SUBMIT_REQUEST}
										type="button"
										value="Submit Request"
										disabled={isUploadingPreviousImages}
										className={classes.completeFormButton}
										onClick={() =>
											isSubmitOnBehalf
												? onSubmitOnBehalfByAdmin()
												: completeClaim()
										}
									/>
								</span>
							</div>
						</div>
						<div className={classes.clear}></div>
					</div>
				</>
			)}

			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			<ConfirmationModal
				showModal={confirmationModal}
				closeModal={() => setConfirmationModal(false)}
				styles={modalStylesConfirmation}
				heading="Cancel Horse Claim"
				positiveLabel="Confirm"
				negativeLabel="Cancel"
				onClickPositive={() => cancelClaim()}
			>
				Are you sure you wish to delete this form?
			</ConfirmationModal>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</PageContainer>
	);
};

export default ClaimThoroughbred;

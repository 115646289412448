import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { HeaderBanner } from '../Mobile';
import AppSyncService from '../../graphql/AppSyncService';
import { getEvents } from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import { convertToMultiListData } from '../../utils/helpers';
import EventDataRow from '../EventDataRow';
import DataTable from '../DataTable';
import FormMultiSelectList from '../FormMultiSelectList';
import SEO from '../seo';
import moment from 'moment';
import { useScreenClass } from 'react-grid-system';

const statusArrayList = [
	{
		id: 1,
		value: 'Draft'
	},
	{
		id: 2,
		value: 'Approved'
	}
];

export const tableHeadings = [
	'Event Name',
	'User',
	'Start Date/Time',
	'End Date/Time',
	'Location',
	'Discipline',
	'Last Saved'
];

const EventDrafts = () => {
	const screenClassForRender = useScreenClass();
	const { addToast } = useToast();
	const classes = useStyles();
	const [eventsFormList, setEventsFormList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [rows, setRows] = useState([]);

	const [selectedEventsFormStatus, setSelectedEventsFormStatus] = useState([
		{ label: 'Draft', value: 1 }
	]);

	const fetchEventsForms = (status) => {
		AppSyncService.execute(getEvents, { status }).then((data) => {
			try {
				setEventsFormList(data.data.getEvents);
			} catch {
				addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
			}
		});
	};

	useEffect(() => {
		setStatusList(convertToMultiListData(statusArrayList, 'value'));
		fetchEventsForms(selectedEventsFormStatus.map((s) => s.label));
	}, [selectedEventsFormStatus]);

	useEffect(() => {
		buildRows();
	}, [eventsFormList]);

	const updateEventsForms = (value) => {
		setSelectedEventsFormStatus(value);
		fetchEventsForms(value.map((d) => d.label));
	};

	const sortRowsDown = () => {
		let eventRows = Object.assign([], eventsFormList);
		eventRows.sort((event1, event2) => {
			if (event1?.eventStartDate && event2?.eventStartDate) {
				if (moment(event1?.eventStartDate).isBefore(event2?.eventStartDate)) {
					return -1;
				}
			} else if (event2?.eventStartDate) {
				return -1;
			}
			return 1;
		});
		setEventsFormList(eventRows);
	};

	const sortRowsUp = () => {
		let eventRows = Object.assign([], eventsFormList);
		eventRows.sort((event1, event2) => {
			if (event2?.eventStartDate && event1?.eventStartDate) {
				if (moment(event2?.eventStartDate).isBefore(event1?.eventStartDate)) {
					return -1;
				}
			} else if (event1?.eventStartDate) {
				return -1;
			}
			return 1;
		});
		setEventsFormList(eventRows);
	};

	const buildRows = () => {
		const numRows = eventsFormList.length;
		const constructedRows = eventsFormList.map((event, index) => {
			return (
				<EventDataRow
					isMobile={['xs', 'sm', 'md'].includes(screenClassForRender)}
					event={event}
					key={event.id}
					index={index}
					isLastRow={numRows === index + 1}
				/>
			);
		});
		setRows(constructedRows);
	};

	return (
		<>
			<SEO title="Event Drafts" />
			<HeaderBanner
				title={`Events Calendar - Drafts (${eventsFormList.length})`}
				styles={{
					backgroundSize: 'cover',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: '1px solid #b3bcc1',
					marginBottom: 0,
					fontSize: 20,
					fontWeight: 600,
					color: '#505153'
				}}
				buttonText={'Add a new Event'}
				buttonLink={'/add-event'}
				buttonStyles={{
					width: '150px',
					float: 'right',
					marginTop: 0,
					cursor: 'pointer'
				}}
			/>
			<div className={classes.filterContainer}>
				<div className={classes.filterTitle}>Filter by Status</div>
				<div className={classes.filterComponentContainer}>
					<FormMultiSelectList
						name="filterStatus"
						listOptions={statusList}
						selectedIds={selectedEventsFormStatus}
						onSelectChange={updateEventsForms}
					/>
				</div>
			</div>
			<DataTable
				headings={tableHeadings}
				rows={rows}
				sortHeading="Date/Time"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
			/>
		</>
	);
};
export default EventDrafts;

  import { createUseStyles } from "react-jss";

  export default createUseStyles(() => ({
    container: {
      maxWidth: 1000,
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 50,
    },
    leftContainer: {
      float: "left",
      width: "75%",
      marginBottom: 40,
    },
    headTitle: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 15,
    },
    leftContent: {
      display: "flex",
      justifyContent: "space-between",
    },
    card: {
      width: "100%",
      paddingRight: 20,
      position: "relative",
      height: 340,
    },
    image: {
      objectFit: "cover",
      height: 140,
      width: "100%",
    },
    readMore: {
      background: "#dcebf3",
      textAlign: "center",
      padding: "10px 10px 10px 10px",
      color: "#006da6",
      fontWeight: 600,
      borderRadius: 5,
      fontSize: 16,
      position: "absolute",
      left: 0,
      right: 20,
      bottom: 0,
    },
    program: {
      fontSize: 16,
      fontWeight: 600,
    },
    description: {
      fontSize: 12,
      marginTop: 7,
      marginBottom: 10,
      maxHeight: 105,
      textOverflow: "hidden",
      overflow: "hidden",
    },
    rightContainer: {
      float: "right",
      width: "25%",
    },
    rightContent: {
      height: 340,
      width: "100%",
      background: "#006da6",
      borderRadius: 5,
      color: "white"
    },
    clear: {
      clear: "both",
    },
    ottOverview: {
      fontSize: 18,
      fontWeight: 600,
      marginBottom: 15,
      marginTop: 40,
    },
    number: {
      paddingLeft: 20,
      paddingTop: 10,
      fontSize: 36,
      fontWeight: 600,
    },
    stats: {
      paddingLeft: 20,
      fontSize: 12
    },
    row: {
      width: '100%',
      display: 'inline-flex'
    },
    col8: {
      width: '66%',
      padding: '10px'
    },
    col4: {
      width: '33%',
      padding: '10px'
    }
  }));

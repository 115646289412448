import {
	BUSINESS_PROFILE,
	HORSE_CLAIM,
	PROFILE,
	FULL_CIRCLE,
	FIND_THOROUGHBRED,
	HORSE_PLACEMENT,
	RESET_NOTIFICATION,
	RESET_WEEKLY_PROGRESS,
	RESET_INITIAL_ASSESSMENT,
	RESET,
	RESET_RESET_IN_TRAINING_DUE,
	RESET_RESET_IN_TRAINING_OVERDUE,
	RESET_RESET_SPELLING_OVERDUE,
	RESET_RESET_REHABILITATION_OVERDUE,
	ACK_RETRAINER,
	ACK_RETIREMENT,
	SHARE_INFORMATION,
	HORSE_PLACEMENT_INTEREST,
	RETRAINER,
	RETRAINING_REPORT,
	OHEP_NOTIFICATION,
	OHEP_EOI_NOTIFICATION,
	COMPETITION_ADMIN,
	ACK_RETIREMENT_REJECTED,
	ACK_FOSTER,
	HORSE_CLAIM_STAR,
	SPONSORSHIP_OUTCOME_REJECTED
} from '../../utils/constants';
import {
	MY_STABLE_ROUTE,
	SPONSORSHIP_OUTCOME_ROUTE
} from '../../utils/constants/routes';

export const formatUserNotifications = (rawNotifications) => {
	return rawNotifications.map((d) => {
		var link = d.link;
		var selection;
		var id = d.id;
		if (!link) {
			switch (d.type) {
				case HORSE_CLAIM:
				case HORSE_CLAIM_STAR:
					link = `/claim-thoroughbred-form/${d.id}`;
					break;
				case FIND_THOROUGHBRED:
					link = `/thoroughbred-registration-form/${d.id}`;
					break;
				case FULL_CIRCLE:
					link = `/full-circle-form/${d.id}`;
					break;
				case HORSE_PLACEMENT:
					link = `/horse-placement-form/${d.id}`;
					break;
				case ACK_RETRAINER:
					link = `/acknowledged-retrainer-form`;
					break;
				case SPONSORSHIP_OUTCOME_REJECTED:
					link = `${SPONSORSHIP_OUTCOME_ROUTE}/${d.id}`;
					break;
				case ACK_RETIREMENT_REJECTED:
				case ACK_RETIREMENT:
					link = `/acknowledged-retirement-form-application`;
					break;
				case OHEP_NOTIFICATION:
					link = `/ohep-form/${d.id}`;
					break;
				case OHEP_EOI_NOTIFICATION:
					link = `/ohep-eoi-form/${d.id}`;
					break;
				case RESET_NOTIFICATION:
					link = `/reset-form/${d.id}`;
					break;
				case SHARE_INFORMATION:
					link = `/share-information-form/${d.id}`;
					break;
				case HORSE_PLACEMENT_INTEREST:
					link = `/horse-details/${d.id}`;
					break;
				case RESET_WEEKLY_PROGRESS:
				case RESET_INITIAL_ASSESSMENT:
				case RESET_RESET_IN_TRAINING_DUE:
				case RESET_RESET_IN_TRAINING_OVERDUE:
				case RESET_RESET_SPELLING_OVERDUE:
				case RESET_RESET_REHABILITATION_OVERDUE:
					link = MY_STABLE_ROUTE;
					selection = RESET;
					break;
				case RETRAINING_REPORT:
					link = MY_STABLE_ROUTE;
					selection = RETRAINER;
					break;
				case PROFILE:
				case BUSINESS_PROFILE:
					link = `/account`;
					break;
				case COMPETITION_ADMIN:
					link = '/contact-us';
					break;
				case ACK_FOSTER:
					link = MY_STABLE_ROUTE;
					break;
				default:
					break;
			}
		}
		return {
			type: d.type,
			text: d.message,
			id: id,
			link: link,
			selection: selection,
			source: d?.identifiers?.length > 0 ? d?.identifiers[0]?.value : null
		};
	});
};

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	sectionRow: {
		padding: '15px 15px 0px 15px',
		'@media (min-width:992px)': {
			padding: 15
		}
	}
});

export const generatePayload = (values, guid) => {
	const payload = {};
	payload.guid = guid;
	payload.relationshipToHorse = +values.relationshipToHorse || null;
	if (payload.relationshipToHorse === 5) {
		payload.relationshipToHorseOther = values.relationshipToHorseOther;
	} else {
		payload.relationshipToHorseOther = null;
	}
	payload.preferredContactType = +values.preferredContactType || null;
	payload.contactDetails = values.contactDetails;
	payload.horseName = values.horseName;
	payload.microchip = values.microchip;
	payload.sire = values.sire;
	payload.dam = values.dam;
	payload.horseGender = +values.horseGender || null;
	payload.age = +values.age || null;
	payload.eligibilityCriteria = +values.eligibilityCriteria || null;

	payload.currentAddress = values.currentAddress;
	payload.currentSuburb = values.currentSuburb;
	payload.currentPostcode = values.currentPostcode;
	payload.currentState = values.currentState;
	payload.currentCountry = values.currentCountry;
	payload.currentAddressValidated = values.currentAddressValidated;
	return payload;
};

export const setFormValuesFromApiResult = (resultValues, setValue) => {
	setValue('relationshipToHorse', resultValues.relationshipToHorse?.id || 0);
	setValue(
		'relationshipToHorseOther',
		resultValues.relationshipToHorseOther || ''
	);
	setValue('preferredContactType', resultValues.preferredContactType?.id || 0);
	setValue('contactDetails', resultValues.contactDetails || '');
	setValue('horseName', resultValues.horseName || '');
	setValue('microchip', resultValues.microchip || '');
	setValue('sire', resultValues.sire || '');
	setValue('dam', resultValues.dam || '');
	setValue('horseGender', resultValues.horseGender?.id || 0);
	setValue('age', resultValues.age || null);
	setValue('eligibilityCriteria', resultValues.eligibilityCriteria?.id || 0);
	setValue('currentAddress', resultValues.currentAddress || '');
	setValue('currentSuburb', resultValues.currentSuburb || '');
	setValue('currentPostcode', resultValues.currentPostcode || '');
	setValue('currentState', resultValues.currentState || '');
	setValue('currentCountry', resultValues.currentCountry || '');
	setValue(
		'currentAddressValidated',
		resultValues.currentAddressValidated || false
	);

	// Photos
	setValue('leftSidePhotos', resultValues.leftSidePhotos || []);
	setValue('rightSidePhotos', resultValues.rightSidePhotos || []);
};

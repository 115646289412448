import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formContainer: {
		paddingTop: 5,
		paddingBottom: 6,
		border: '1px solid #b3bcc1',
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px'
	},
	formParentDiv: {
		padding: '0px 18px 0px 18px'
	},
	instructionContainer: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749',
		padding: '13px 16px 0px 19px'
	},
	checkBoxContainer: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: 15,
		height: 20
	},
	textFieldContainer: {
		width: '100%',
		padding: '5px 16px 15px 15px'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},
	lastSaved: {
		paddingRight: '15px'
	},
	errorText: {
		color: '#db0000'
	},
	extraPadding: {
		paddingBottom: 12
	}
}));

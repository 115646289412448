import { createUseStyles } from 'react-jss';

export default createUseStyles({
	nonEditableValue: {
		marginTop: 17,
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	}
});

import styled from 'styled-components';

export const StyledSectionContainer = styled.div`
	border: none;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		border: 1px solid #b3bcc1;
		border-top: none;
	}
`;

export const StyledItalic = styled.span`
	font-style: italic;
`;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	infoBarButton: {
		width: 180,
		float: 'right'
	},
	infoBarText: {
		float: 'left',
		whiteSpace: 'pre-wrap'
	},
	verticalCentre: {
		display: 'flex',
		alignItems: 'center'
	},
	verticalCentreNoButton: {
		//need a bit of extra padding when the button is not present
		extend: 'verticalCentre',
		padding: 10
	},
	mobileButton: {
		marginTop: 15
	}
}));

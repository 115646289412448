import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomColorCheckbox = withStyles({
	root: {
		'&$checked': {
			color: '#0c70a8'
		}
	},
	checked: {
		'&.Mui-disabled': {
			color: '#7a7f81'
		}
	}
})((props) => <Checkbox color="default" {...props} />);

const CustomFormControlLabel = withStyles({
	label: {
		'&.Mui-disabled': {
			color: '#464749'
		},
		fontSize: 15,
		fontWeight: 'normal',
		color: '#464749',
		fontFamily: 'Open-sans !important'
	}
})((props) => <FormControlLabel color="default" {...props} />);

//TODO handle checkbox group
const BasicCheckbox = ({
	value = false,
	handleChange,
	label,
	disabled,
	name
}) => {
	return (
		<FormGroup>
			<CustomFormControlLabel
				control={
					<CustomColorCheckbox
						checked={value}
						disabled={disabled}
						onChange={handleChange}
						name={name}
					/>
				}
				label={label}
			/>
		</FormGroup>
	);
};

export default BasicCheckbox;

import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	headerContainer: {
		background: `url(${BannerImage})`,
		backgroundSize: 'contain',
		minWidth: '330px',
		width: '100%',
		boxSizing: 'border-box',
		height: 65,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontWeight: 800,
		color: '#464749',
		paddingLeft: 20,
		fontSize: 15,
		'@media (min-width:992px)': {
			fontSize: 20
		}
	},
	singleResultContainer: {
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 15
	},
	sectionContainer: {
		width: '100%'
	},
	resultContainer: {
		maxHeight: 304,
		border: '1px solid #d9dde0',
		borderTop: 'none',
		overflowY: 'auto'
	},
	filterContainer: {
		background: '#dcebf3',
		height: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingTop: 12
	},
	noResultFound: {
		marginLeft: 15,
		marginTop: 20
	},
	button: {
		textAlign: 'right',
		marginRight: 30,
		height: '100%'
	},
	actionButton: {
		display: 'block',
		textAlign: 'center',
		paddingLeft: 26,
		paddingRight: 26,
		borderRadius: 4,
		background: '#006da6',
		padding: 8,
		color: 'white',
		fontWeight: 600,
		float: 'right',
		marginTop: 13,
		cursor: 'pointer'
	},
	container: {
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	'@media only screen and (min-width: 64.063em)': {
		headerContainer: {
			backgroundSize: 'cover'
		}
	}
}));

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import {
	retirementCategoryList,
	retirementReason,
	retirementEducation,
	vulnerabilityTypeList
} from './constants';
import FormDropDown from '../FormDropDown';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import ResizingTextArea from '../ResizingTextArea';
import LoaderSpinner from '../LoaderSpinner';
import FormLabelField from '../FormLabelField';
import { getEligibilityReferences } from '../../graphql/custom.queries';
import { extractLookUpOptionsOther } from '../../utils/helpers';
import AppSyncService from '../../graphql/AppSyncService';
import useStyles from './styles';
import FormDatePicker from '../FormDatePicker';

const RetirementCategory = ({
	adminData,
	adminDataErrors,
	handleMultiFieldChange,
	isReadOnly,
	noContainerPadding = false,
	isHorseProfile = false
}) => {
	const classes = useStyles();
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [adminOption, setAdminOption] = useState([]);
	const [sourceDetailsOption, setSourceDetailsOption] = useState([]);
	const [informationSourcesOptions, setInformationSourcesOptions] = useState(
		[]
	);
	const [viceOptions, setViceOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		AppSyncService.execute(getEligibilityReferences).then((result) => {
			if (result.data) {
				setInjuryOptions(
					extractLookUpOptionsOther(result, 'listAckRetirementInjuryTypes')
				);
				setIllnessOptions(
					extractLookUpOptionsOther(result, 'listAckRetirementIllnessTypes')
				);
				setViceOptions(extractLookUpOptionsOther(result, 'listHabitsAndVices'));
				if (isHorseProfile) {
					setAdminOption([
						{ id: 0, value: '' },
						...result.data.getAdminMembers?.map(
							({ id, externalId, firstName, lastName }) => ({
								id,
								value: `${firstName} ${lastName}`,
								externalId
							})
						)
					]);
					setSourceDetailsOption([
						{ id: 0, value: '' },
						...extractLookUpOptionsOther(result, 'listSourceDetails')
					]);
					setInformationSourcesOptions([
						{ id: 0, value: '' },
						...extractLookUpOptionsOther(result, 'listInformationSources')
					]);
				}
			}
			setIsLoading(false);
		});
	}, []);

	const handleInformationSourceChange = (value) => {
		handleMultiFieldChange(value, 'informationSource', [
			{ item: 'sourceDetail', value: value == 4 ? 20 : 0 },
			{ item: 'sourceDetailOther', value: '' }
		]);
	};

	return isLoading ? (
		<div className={classes.loadingDiv}>
			<LoaderSpinner status={true} />
		</div>
	) : (
		<Container
			style={noContainerPadding ? { paddingLeft: 0, paddingRight: 0 } : {}}
		>
			<Row>
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>
							Select which Retirement Category*
						</div>
						<FormDropDown
							items={retirementCategoryList.sort((a, b) =>
								a.value.localeCompare(b.value)
							)}
							selectedId={adminData.selectedCategory}
							onChange={(e) => {
								handleMultiFieldChange(e.target.value, 'selectedCategory');
							}}
							showBlank
							id={'retirement-category'}
							disabled={isReadOnly}
						/>
					</div>
				</Col>
				{adminData.selectedCategory != 0 ? (
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<FormLabelField
								title={'Reason*'}
								value={adminData.selectedReason
									.map((reason) => reason.label)
									.join(', ')}
								styles={{
									padding: '10px 0 0 0',
									marginBottom: 0,
									marginTop: 0
								}}
							/>
						) : (
							<>
								<MultiSelectInputField
									idPrefix={'retirement-reason'}
									fieldLabel={'Reason*'}
									selectedIds={adminData.selectedReason}
									options={retirementReason}
									onSelectChange={(newReason) => {
										handleMultiFieldChange(newReason, 'selectedReason');
									}}
									otherDisabled
									noMargin
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.reasonRetirement.value
										? adminDataErrors.reasonRetirement.message
										: null}
								</div>
							</>
						)}
					</Col>
				) : null}
				<Col xs={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>
							Vulnerability Type
							{adminData.selectedCategory == 3 ||
							adminData.selectedCategory == 4
								? '*'
								: ''}
						</div>
						<FormDropDown
							items={vulnerabilityTypeList}
							selectedId={adminData.selectedVulnerabilityType}
							onChange={(e) => {
								handleMultiFieldChange(
									e.target.value,
									'selectedVulnerabilityType'
								);
							}}
							showBlank
							id={'retirement-category-vulnerability-type'}
							disabled={isReadOnly}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.vulnerabilityType.value
								? adminDataErrors.vulnerabilityType.message
								: null}
						</div>
					</div>
				</Col>
				{adminData.selectedReason.some((reason) => reason.value == 2) ? (
					<Col xs={12} lg={12}>
						{isReadOnly ? (
							<>
								<FormLabelField
									title={'Behaviour*'}
									value={adminData.selectedVicesRetirement
										.map((reason) => reason.label)
										.join(', ')}
									styles={{ padding: '5px 0 0 0', marginBottom: 0 }}
								/>
								{adminData.selectedVicesRetirement.some(
									(vice) => vice.value == 12
								) && adminData.otherVicesRetirement.length > 0 ? (
									<FormLabelField
										title={'Other*'}
										value={adminData.otherVicesRetirement
											.map((reason) => reason.label)
											.join(', ')}
										styles={{ padding: '5px 0 0 0', marginBottom: 0 }}
									/>
								) : null}
							</>
						) : (
							<>
								<MultiSelectInputField
									idPrefix={'retirement-behaviour'}
									fieldLabel={'Behaviour*'}
									selectedIds={adminData.selectedVicesRetirement}
									options={viceOptions}
									onSelectChange={(newVices) => {
										handleMultiFieldChange(newVices, 'selectedVicesRetirement');
									}}
									otherValue={adminData.otherVicesRetirement}
									onOtherChange={(newOtherValue) => {
										handleMultiFieldChange(
											newOtherValue,
											'otherVicesRetirement'
										);
									}}
									isOtherFieldRequired
									otherDisabled
									otherErrorMessage={adminDataErrors.behaviourOtherRetirement}
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.behaviourRetirement.value
										? adminDataErrors.behaviourRetirement.message
										: null}
								</div>
							</>
						)}
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 3) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Conformational Defect/s-details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-conformational'}
							onChange={(e) => {
								handleMultiFieldChange(
									e.target.value,
									'conformationalDefectsRetirement'
								);
							}}
							maxChars={700}
							value={adminData.conformationalDefectsRetirement}
							canEdit={!isReadOnly}
							styles={{ paddingTop: 5 }}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.conformationalRetirement.value
								? adminDataErrors.conformationalRetirement.message
								: null}
						</div>
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 4) ? (
					<Col xs={12} lg={12}>
						{isReadOnly ? (
							<FormLabelField
								title={'Education*'}
								value={adminData.selectedEducation
									.map((reason) => reason.label)
									.join(', ')}
								styles={{ padding: '5px 0 0 0', marginBottom: 0 }}
							/>
						) : (
							<MultiSelectInputField
								idPrefix={'retirement-education'}
								fieldLabel={'Education*'}
								selectedIds={adminData.selectedEducation}
								options={retirementEducation}
								onSelectChange={(newEducation) => {
									handleMultiFieldChange(newEducation, 'selectedEducation');
								}}
								otherDisabled
							/>
						)}

						<div className={classes.fieldRequiredError}>
							{adminDataErrors.education.value
								? adminDataErrors.education.message
								: null}
						</div>
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Other education-details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-other-education'}
							onChange={(e) => {
								handleMultiFieldChange(e.target.value, 'otherEducationDetails');
							}}
							maxChars={700}
							value={adminData.otherEducationDetails}
							canEdit={!isReadOnly}
							styles={{ paddingTop: 5 }}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.educationDetails.value
								? adminDataErrors.educationDetails.message
								: null}
						</div>
					</Col>
				) : null}
				{adminData.selectedReason.some((reason) => reason.value == 5) ? (
					<Col xs={12} lg={5}>
						{isReadOnly ? (
							<FormLabelField
								title={'Injury Type*'}
								value={adminData.selectedInjuriesRetirement
									.map((injury) => injury.label)
									.join(', ')}
								styles={{ padding: '5px 0 0 0', marginBottom: 0 }}
							/>
						) : (
							<>
								<MultiSelectInputField
									idPrefix={'retirement-injury'}
									fieldLabel={'Injury Type*'}
									selectedIds={adminData.selectedInjuriesRetirement}
									options={injuryOptions}
									onSelectChange={(newInjuries) => {
										handleMultiFieldChange(
											newInjuries,
											'selectedInjuriesRetirement'
										);
									}}
									otherDisabled
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.injuryRetirement.value
										? adminDataErrors.injuryRetirement.message
										: null}
								</div>
							</>
						)}
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 6) ? (
					<Col xs={12} lg={5}>
						{isReadOnly ? (
							<FormLabelField
								title={'Illness Type*'}
								value={adminData.selectedIllnessRetirement
									.map((illness) => illness.label)
									.join(', ')}
								styles={{ padding: '5px 0 0 0', marginBottom: 0 }}
							/>
						) : (
							<>
								<MultiSelectInputField
									idPrefix={'retirement-illness'}
									fieldLabel={'Illness Type*'}
									selectedIds={adminData.selectedIllnessRetirement}
									options={illnessOptions}
									onSelectChange={(newIllness) => {
										handleMultiFieldChange(
											newIllness,
											'selectedIllnessRetirement'
										);
									}}
									otherDisabled
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.illnessRetirement.value
										? adminDataErrors.illnessRetirement.message
										: null}
								</div>
							</>
						)}
					</Col>
				) : null}
				{adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) && adminData.selectedReason.some((reason) => reason.value == 5) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Other injury details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-other-injury'}
							onChange={(e) => {
								handleMultiFieldChange(e.target.value, 'otherInjuryDetails');
							}}
							maxChars={700}
							value={adminData.otherInjuryDetails}
							canEdit={!isReadOnly}
							styles={{ paddingTop: 5 }}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.otherInjuryRetirement.value
								? adminDataErrors.otherInjuryRetirement.message
								: null}
						</div>
					</Col>
				) : null}

				{adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) && adminData.selectedReason.some((reason) => reason.value == 6) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Other illness details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-other-illness'}
							onChange={(e) => {
								handleMultiFieldChange(e.target.value, 'otherIllnessDetails');
							}}
							maxChars={700}
							value={adminData.otherIllnessDetails}
							canEdit={!isReadOnly}
							styles={{ paddingTop: 5 }}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.otherIllnessRetirement.value
								? adminDataErrors.otherIllnessRetirement.message
								: null}
						</div>
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 7) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Rehoming history - details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-rehoming-details'}
							onChange={(e) => {
								handleMultiFieldChange(
									e.target.value,
									'rehomingHistoryDetails'
								);
							}}
							maxChars={700}
							value={adminData.rehomingHistoryDetails}
							canEdit={!isReadOnly}
							styles={{ paddingTop: 5 }}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.rehomingHistory.value
								? adminDataErrors.rehomingHistory.message
								: null}
						</div>
					</Col>
				) : null}

				{adminData.selectedReason.some((reason) => reason.value == 8) ? (
					<Col xs={12} lg={12}>
						<ResizingTextArea
							title="Other - details*"
							titleStyles={{ fontSize: '14px' }}
							id={'retirement-other-details'}
							onChange={(e) => {
								handleMultiFieldChange(
									e.target.value,
									'otherDetailsRetirement'
								);
							}}
							styles={{ paddingTop: 5 }}
							maxChars={700}
							value={adminData.otherDetailsRetirement}
							canEdit={!isReadOnly}
						/>
						<div className={classes.fieldRequiredError}>
							{adminDataErrors.otherDetailsRetirement.value
								? adminDataErrors.otherDetailsRetirement.message
								: null}
						</div>
					</Col>
				) : null}
			</Row>
			{isHorseProfile && (
				<>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Information sourced from*
								</div>
								<FormDropDown
									items={informationSourcesOptions}
									selectedId={adminData.informationSource}
									onChange={(e) => {
										handleInformationSourceChange(e.target.value);
									}}
									showBlank
									id={'information-source'}
									disabled={isReadOnly}
								/>
							</div>
							<div className={classes.fieldRequiredError}>
								{adminDataErrors.informationSource.value
									? adminDataErrors.informationSource.message
									: null}
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Source Details*</div>
								<FormDropDown
									items={[
										{ id: 0, value: '' },
										...sourceDetailsOption?.filter(
											(item) =>
												item.informationSourceId == adminData.informationSource
										)
									]}
									selectedId={adminData.sourceDetail}
									onChange={(e) => {
										handleMultiFieldChange(e.target.value, 'sourceDetail');
									}}
									showBlank
									id={'source-detail'}
									disabled={isReadOnly}
								/>
							</div>
							<div className={classes.fieldRequiredError}>
								{adminDataErrors.sourceDetail.value
									? adminDataErrors.sourceDetail.message
									: null}
							</div>
						</Col>

						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Date information was provided*
								</div>
								<FormDatePicker
									DateValue={adminData.dateInformationProvided}
									id={'date-information-provided'}
									updateSelection={(value) => {
										handleMultiFieldChange(value, 'dateInformationProvided');
									}}
								/>
							</div>
							<div className={classes.fieldRequiredError}>
								{adminDataErrors.dateInformationProvided.value
									? adminDataErrors.dateInformationProvided.message
									: null}
							</div>
						</Col>
					</Row>
					{(adminData.sourceDetail == 3 ||
						adminData.sourceDetail == 10 ||
						adminData.sourceDetail == 19 ||
						adminData.sourceDetail == 20) && (
						<Row>
							<Col xs={12}>
								<ResizingTextArea
									title={'Source Details - Other*'}
									titleStyles={{ fontSize: '14px' }}
									id={'sourceDetailOther'}
									onChange={(e) => {
										handleMultiFieldChange(e.target.value, 'sourceDetailOther');
									}}
									maxChars={700}
									value={adminData.sourceDetailOther}
									canEdit={!isReadOnly}
									styles={{ paddingTop: 5 }}
								/>
								<div className={classes.fieldRequiredError}>
									{adminDataErrors.sourceDetailOther.value
										? adminDataErrors.sourceDetailOther.message
										: null}
								</div>
							</Col>
						</Row>
					)}
					<Row>
						{adminData.informationSource == 2 && (
							<>
								<Col xs={12}>
									<ResizingTextArea
										title="Details of how the information was obtained*"
										titleStyles={{ fontSize: '14px' }}
										id={'detailsInformationObtained'}
										onChange={(e) => {
											handleMultiFieldChange(
												e.target.value,
												'detailsInformationObtained'
											);
										}}
										maxChars={700}
										value={adminData.detailsInformationObtained}
										canEdit={!isReadOnly}
										styles={{ paddingTop: 5 }}
									/>
									<div className={classes.fieldRequiredError}>
										{adminDataErrors.detailsInformationObtained.value
											? adminDataErrors.detailsInformationObtained.message
											: null}
									</div>
								</Col>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Admin user*</div>
										<FormDropDown
											items={adminOption}
											selectedId={adminData.adminId}
											onChange={(e) => {
												handleMultiFieldChange(e.target.value, 'adminId');
											}}
											showBlank
											id={'admin-user'}
											disabled={isReadOnly}
										/>
									</div>
									<div className={classes.fieldRequiredError}>
										{adminDataErrors.adminId.value
											? adminDataErrors.adminId.message
											: null}
									</div>
								</Col>
							</>
						)}
					</Row>
					<Row>
						<Col xs={12}>
							<ResizingTextArea
								title="Other relevant details"
								titleStyles={{ fontSize: '14px' }}
								id={'other-relevant-details'}
								onChange={(e) => {
									handleMultiFieldChange(
										e.target.value,
										'otherRelevantDetails'
									);
								}}
								maxChars={700}
								value={adminData.otherRelevantDetails}
								canEdit={!isReadOnly}
								styles={{ paddingTop: 5 }}
							/>
							<div className={classes.fieldRequiredError}>
								{adminDataErrors.otherRelevantDetails.value
									? adminDataErrors.otherRelevantDetails.message
									: null}
							</div>
						</Col>
					</Row>
				</>
			)}
		</Container>
	);
};

export default RetirementCategory;

import React from 'react';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';
import PrimaryButton from '../PrimaryButton';
import { navigate } from '@reach/router';

const HeroBannerImage = ({
	title,
	imgSrc,
	text,
	buttonLink,
	buttonText,
	isLoggedIn,
	isTackArticle
}) => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();

	return (
		<div
			style={
				!isLoggedIn && !['sm', 'xs'].includes(screenClassForRender)
					? { height: 275 }
					: {}
			}
			className={
				['sm', 'xs'].includes(screenClassForRender)
					? classes.bannerContainerMobile
					: classes.bannerContainer
			}
		>
			<div
				className={
					['sm', 'xs'].includes(screenClassForRender)
						? classes.heroImageMobile
						: isTackArticle
						? classes.heroImageArticle
						: classes.heroImage
				}
			>
				<img
					src={imgSrc}
					className={
						['sm', 'xs'].includes(screenClassForRender)
							? classes.imageMobile
							: classes.image
					}
				/>
			</div>
			<div
				className={
					['sm', 'xs'].includes(screenClassForRender)
						? classes.textBlockContainerMobile
						: isTackArticle
						? classes.textBlockContainerArticle
						: classes.textBlockContainer
				}
			>
				<div
					className={
						['sm', 'xs'].includes(screenClassForRender)
							? classes.titleMobile
							: classes.title
					}
				>
					{title}
				</div>
				<div
					className={
						['sm', 'xs'].includes(screenClassForRender)
							? classes.summaryMobile
							: classes.summary
					}
				>
					{text}
					{!isLoggedIn && (
						<PrimaryButton onClick={() => navigate(`/${buttonLink}`)}>
							{buttonText}
						</PrimaryButton>
					)}
				</div>
			</div>
		</div>
	);
};

export default HeroBannerImage;

import React, { useRef, useState } from 'react';
import Arrow from '../../assets/icons/Arrow_right.svg';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';

const ScrollMenu = ({ onClick, selectedSearch, menuList }) => {
	const dataLength = menuList.length;
	const node = useRef();
	const [scrollLeftPostion, setscrollLeftPostion] = useState(0);
	const screenClassForRender = useScreenClass();
	const classes = useStyles();

	const scrollRight = () => {
		let scrollAmount = 0;
		let slideTimer = setInterval(function () {
			node.current.scrollLeft -= 10;
			scrollAmount += 10;
			if (scrollAmount >= 300) {
				window.clearInterval(slideTimer);
			}
		}, 10);
	};

	const scrollLeft = () => {
		let scrollAmount = 0;
		let slideTimer = setInterval(function () {
			node.current.scrollLeft += 10;
			scrollAmount += 10;
			if (scrollAmount >= 300) {
				window.clearInterval(slideTimer);
			}
		}, 10);
	};

	const renderFilterList = (dataLength) => {
		return menuList
			.filter((s) => s.show)
			.map((f) => {
				return (
					<div
						id={f.id}
						key={f.key}
						onClick={() => onClick(f.menuItem)}
						className={classes.menuItem}
						style={
							selectedSearch === f.menuItem
								? dataLength <= 3 && ['sm', 'xs'].includes(screenClassForRender)
									? {
											width: '100%',
											background: '#006da6',
											color: 'white',
											fontWeight: 600,
											borderRadius: 50
									  }
									: {
											background: '#006da6',
											color: 'white',
											fontWeight: 600,
											borderRadius: 50
									  }
								: dataLength <= 3 && ['sm', 'xs'].includes(screenClassForRender)
								? {
										width: '100%'
								  }
								: {}
						}
					>
						{f.title}
					</div>
				);
			});
	};

	return (
		<>
			<div
				className={
					['sm', 'xs'].includes(screenClassForRender)
						? classes.menuContentContainer
						: classes.menuContentContainerDesktop
				}
			>
				{menuList.length > 3 && (
					<>
						{scrollLeftPostion != 0 &&
							['sm', 'xs'].includes(screenClassForRender) && (
								<div
									className={classes.rightScroll}
									onClick={() => scrollRight()}
								>
									<img src={Arrow} style={{ transform: 'rotate(180deg)' }} />
								</div>
							)}
					</>
				)}

				<div
					ref={node}
					onScroll={() => setscrollLeftPostion(node.current.scrollLeft)}
					className={
						['sm', 'xs'].includes(screenClassForRender)
							? classes.menuContainerMobile
							: classes.menuContainer
					}
				>
					{renderFilterList(dataLength)}
				</div>
				{dataLength > 3 && (
					<>
						{(scrollLeftPostion === 0 ||
							scrollLeftPostion <=
								node.current.scrollWidth - node.current.clientWidth - 1) &&
							['sm', 'xs'].includes(screenClassForRender) && (
								<div
									className={classes.leftScroll}
									onClick={() => scrollLeft()}
								>
									<img src={Arrow} />
								</div>
							)}
					</>
				)}
			</div>
			<div style={{ clear: 'both' }} />
		</>
	);
};
export default ScrollMenu;

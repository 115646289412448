import { createUseStyles } from 'react-jss';

export default createUseStyles({
	stepperContainer: {
		display: 'flex'
	},
	step: {
		width: 40,
		height: 40,
		marginRight: 5,
		fontWeight: 'bold',
		color: '#006da6',
		background: 'rgba(255,255,255, 0.5)',
		border: 'none',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'@media (min-width:992px)': {
			width: 30,
			height: 30,
			marginRight: 3
		}
	},
	selected: {
		background: '#006da6',
		color: '#fff'
	},
	disabled: {
		color: '#b2b2b2',
		background: '#ededed',
		border: '1px solid #d8d8d8'
	}
});

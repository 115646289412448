export const saveRetrainerFormAdminData = /* GraphQL */ `
	mutation saveRetrainerFormAdminData(
		$adminMemberId: Int!
		$formId: Int!
		$formComplete: Boolean
		$adminStatusId: Int
		$videoAssessmentComments: String
		$videoAssessmentDate: String
		$phoneInterviewComments: String
		$phoneInterviewDate: String
		$propertyAssessmentComments: String
		$propertyAssessmentDate: String
		$ridingAssessmentComments: String
		$ridingAssessmentdate: String
		$ridingScore: Int
		$applicationRecommendation: String
		$ewgmComment: String
		$ewgmReviewDate: String
		$rejectionReasonIds: [Int]
		$personalDetailUploads: [UploadedFileInput]
	) {
		saveAcknowledgedRetrainerAdminData(
			adminMemberId: $adminMemberId
			formId: $formId
			formComplete: $formComplete
			adminStatusId: $adminStatusId
			videoAssessmentComments: $videoAssessmentComments
			videoAssessmentDate: $videoAssessmentDate
			phoneInterviewComments: $phoneInterviewComments
			phoneInterviewDate: $phoneInterviewDate
			propertyAssessmentComments: $propertyAssessmentComments
			propertyAssessmentDate: $propertyAssessmentDate
			ridingAssessmentComments: $ridingAssessmentComments
			ridingAssessmentdate: $ridingAssessmentdate
			ridingScore: $ridingScore
			applicationRecommendation: $applicationRecommendation
			ewgmComment: $ewgmComment
			ewgmReviewDate: $ewgmReviewDate
			rejectionReasonIds: $rejectionReasonIds
			personalDetailUploads: $personalDetailUploads
		) {
			id
			message
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveUploadedFile = `
mutation saveAdminUploadedFile($filename: String!, $key: String!, $memberExternalId: String!, $formId: Int!, $type:  UploadedFileTypeEnum) {
  saveUploadedFile(filename: $filename, key: $key, memberExternalId: $memberExternalId, formId: $formId, type: $type) {
    error {
      errorCode
      errorDetails
      errorMessage
    }
    id
    message
    success
  }
}
`;

export const saveUploadedRetirementFile = /* GraphQL */ `
	mutation saveUploadedRetirementFile(
		$filename: String!
		$key: String!
		$memberExternalId: String!
		$formId: Int!
		$type: UploadedFileTypeEnum!
	) {
		saveUploadedRetirementFile(
			filename: $filename
			key: $key
			memberExternalId: $memberExternalId
			formId: $formId
			type: $type
		) {
			id
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const deleteUploadedFile = `
mutation deleteUploadFile($key: String!) {
  deleteUploadedFile(key: $key)
}
`;

export const saveAcknowledgedRetainerFormStatus = `
mutation saveAcknowledgedRetrainerFormStatus($formId: Int!, $status: FormStatusEnum!, $reviewMessage: String) {
  saveAcknowledgedReTrainerFormStatus(id: $formId, status: $status, reviewMessages: $reviewMessage) {
    id
    message
    success
    error {
      errorCode
      errorDetails
      errorMessage
    }
  }
}
`;

export const saveAcknowledgedRetirementFormStatus = /* GraphQL */ `
	mutation saveAcknowledgedRetirementFormStatus(
		$formId: Int!
		$status: FormStatusEnum!
		$reviewMessage: String
	) {
		saveAcknowledgedRetirementFormStatus(
			id: $formId
			status: $status
			reviewMessages: $reviewMessage
		) {
			id
			message
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

export const saveRetirementFormAdminData = /* GraphQL */ `
	mutation saveRetirementFormAdminData(
		$adminMemberId: Int!
		$formId: Int!
		$formComplete: Boolean
		$adminStatusId: Int
		$phoneInterviewComments: String
		$phoneInterviewDate: String
		$propertyAssessmentDate: String
		$applicationRecommendation: String
		$ewgmComment: String
		$ewgmReviewDate: String
		$rejectionReasonIds: [Int]
		$rejectReasonNotification: String
		$personalDetailUploads: [UploadedFileInput]
	) {
		saveAcknowledgedRetirementAdminData(
			adminMemberId: $adminMemberId
			formId: $formId
			formComplete: $formComplete
			adminStatusId: $adminStatusId
			phoneInterviewComments: $phoneInterviewComments
			phoneInterviewDate: $phoneInterviewDate
			propertyAssessmentDate: $propertyAssessmentDate
			applicationRecommendation: $applicationRecommendation
			ewgmComment: $ewgmComment
			ewgmReviewDate: $ewgmReviewDate
			rejectionReasonIds: $rejectionReasonIds
			rejectReasonNotification: $rejectReasonNotification
			personalDetailUploads: $personalDetailUploads
		) {
			id
			message
			success
			error {
				errorCode
				errorDetails
				errorMessage
			}
		}
	}
`;

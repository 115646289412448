export const getMember = `
  query getMemberId($externalId: String!) {
    getMember(externalId: $externalId) {
      id
    }
  }
`;

export const getFollowingHorses = `
  query getFollowingHorses($memberId: Int!) {
    getHorsesFollowedByMember(member_id: $memberId) {
      items {
        horse_code
        memberHasClaimOnHorse
        horse {
          name
          age
          colour
          ottInformation {
            profilePhoto {
              key
            }
            profilePhotoVisibility {
              id
            }
            paddockName
            paddockNameVisibility {
              id
            }
          }
          sex
        }
        claims {
          id
        }
      }
    }
  }
`;

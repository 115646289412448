import React, { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Container } from 'react-grid-system';
import { navigate } from 'gatsby';
import SEO from '../seo';
import TwoColLayout from '../TwoColLayout';
import { useAuth } from '../../hooks/useAuth';
import { Hidden } from 'react-grid-system';
import useStyles from './styles';
import FormTextField from '../FormTextField';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import MessageBar from '../MessageBar';
import { INVALID_EMAIL_MESSAGE } from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
// form validation rules
const schema = yup.object().shape({
	loginEmail: yup.string().nullable('Enter Email').email(INVALID_EMAIL_MESSAGE),
	passwordResetCode: yup.string().required('Password Reset Code is required'),
	newPassword: yup
		.string()
		.required('New Password is required')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Must Contain at-least 8 Characters with One Uppercase, One Lowercase, One Number and One Special Case Character(!@#$%^&*)'
		),
	confirmPassword: yup
		.string()
		.required('Confirm Password is required')
		.oneOf([yup.ref('newPassword'), null], 'Passwords do not match')
});

const PasswordReset = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const {
		receiveVerificationCode,
		changePasswordWithVerificationCode
	} = useAuth();
	const { handleSubmit, errors, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const [loginEmail, setLoginEmail] = useState('');
	const [passwordResetCode, setpasswordResetCode] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
	const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(
		false
	);
	const [sending, setSending] = useState(false);
	const [sendText, setSendText] = useState('Send my reset code');

	useEffect(() => {
		setPasswordErrorMessage('');
	}, [loginEmail]);

	const validatePasswordFields = () => {
		if (
			passwordResetCode.trim() == '' ||
			newPassword.trim() == '' ||
			confirmPassword.trim() == '' ||
			newPassword != confirmPassword
		) {
			setBackendErrorMessage('Please ented valid details');
			return false;
		} else {
			setBackendErrorMessage(null);
			return true;
		}
	};

	const onSubmit = async () => {
		setBackendErrorMessage(null);
		if (validatePasswordFields()) {
			try {
				await changePasswordWithVerificationCode(
					loginEmail,
					passwordResetCode,
					newPassword
				);
				addToast({ Message: 'Password changed', IsSuccess: true });
				navigate(DASHBOARD_ROUTE);
			} catch (error) {
				if (error && error.message) {
					setBackendErrorMessage(error.message);
				} else {
					setBackendErrorMessage(
						'Unknown error while updating password. Please contact support'
					);
				}
			}
		}
	};

	const sendResetCode = async () => {
		const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailPattern.test(loginEmail)) {
			setPasswordErrorMessage('');
			try {
				setSending(true);
				await receiveVerificationCode(loginEmail);
				setShowPasswordResetMessage(true);
				setSendText('Resend');
				setSending(false);
			} catch (error) {
				setBackendErrorMessage(
					'Unknown error while sending verification code. Please contact support'
				);
			}
		} else {
			setPasswordErrorMessage('Please enter valid email address.');
		}
	};

	return (
		<>
			<SEO title="Login" />
			<div className={classes.pushFloor}>
				<TwoColLayout>
					<TwoColLayout.Col1>
						<Container>
							<Row>
								<Col lg={2}></Col>
								<Col sm={12} lg={8}>
									<form onSubmit={handleSubmit(onSubmit)}>
										<div>
											<h2 className={classes.Header}>Password Reset</h2>
											<p className={classes.description}>
												Please enter your email address associated with that
												account, and we&apos;ll email you a code to reset your
												password.
											</p>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>Email*</div>
												<Controller
													control={control}
													name="loginEmail"
													render={({ onChange }) => (
														<FormTextField
															value={loginEmail}
															onChange={(e) => {
																onChange(e.target.value);
																setLoginEmail(e.target.value);
															}}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.loginEmail?.message}
												</span>
												{passwordErrorMessage && (
													<span className={classes.errorText}>
														{passwordErrorMessage}
													</span>
												)}
											</div>

											<PrimaryButton
												onClick={() => sendResetCode()}
												isNonSubmit
												disabled={sending}
											>
												{sending ? 'Sending' : sendText}
											</PrimaryButton>
										</div>
										{showPasswordResetMessage && (
											<div className={classes.passwordResetMessage}>
												<br />
												If the account exists, then you will receive an email
												with a password reset link. If you do not receive the
												email, please{' '}
												<span
													className={classes.link}
													onClick={() => navigate('/register')}
												>
													Register
												</span>
												.
											</div>
										)}
										<hr
											className={(classes.divider, classes.dividerMargin)}
										></hr>
										<div>
											<p className={classes.subHeading}>
												Once you have received your reset code, please enter it
												below and select a new password
											</p>

											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Password Reset Code
												</div>
												<Controller
													control={control}
													name="passwordResetCode"
													render={({ onChange }) => (
														<FormTextField
															value={passwordResetCode}
															onChange={(e) => {
																onChange(e.target.value);
																setpasswordResetCode(e.target.value);
															}}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.passwordResetCode?.message}
												</span>
											</div>

											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>New Password*</div>
												<Controller
													control={control}
													name="newPassword"
													render={({ onChange }) => (
														<FormTextField
															value={newPassword}
															fieldType="password"
															onChange={(e) => {
																onChange(e.target.value);
																setNewPassword(e.target.value);
															}}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.newPassword?.message}
												</span>
											</div>

											<MessageBar type="info" className={classes.passwordInfo}>
												Your password must contain:
												<br />
												&nbsp;&nbsp;.&nbsp;&nbsp;At least 8 Characters
												<br />
												&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 uppercase letter
												<br />
												&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 lowercase letter
												<br />
												&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 number
												<br />
												&nbsp;&nbsp;.&nbsp;&nbsp;At least 1 special character
												(!@#$%^&*)
												<br />
											</MessageBar>

											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													Confirm Password*
												</div>
												<Controller
													control={control}
													name="confirmPassword"
													render={({ onChange }) => (
														<FormTextField
															value={confirmPassword}
															fieldType="password"
															onChange={(e) => {
																onChange(e.target.value);
																setConfirmPassword(e.target.value);
															}}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.confirmPassword?.message}
												</span>
											</div>

											{backendErrorMessage && (
												<span className={classes.errorMessage}>
													{backendErrorMessage}
												</span>
											)}

											<PrimaryButton style={{ width: '165px' }}>
												Reset Password
											</PrimaryButton>
										</div>
									</form>
								</Col>
								<Col lg={2}></Col>
							</Row>
						</Container>
					</TwoColLayout.Col1>
					<Hidden xs sm>
						<TwoColLayout.Col2>
							<div className={classes.loginImageDisplay}></div>
						</TwoColLayout.Col2>
					</Hidden>
				</TwoColLayout>
			</div>
		</>
	);
};
export default PasswordReset;

import { ReviewListKeys } from './../../utils/constants/index';

export const FORM_MANAGEMENT = 'form_management';
export const ROLE_MANAGEMENT = 'role_management';
export const USER_SEARCH = 'user_search';
export const COMPETITIONS = 'competitions';
export const FOLLOWING_FEED = 'following_feed';
export const ADMIN_TAB_LIST = [
	{
		id: ReviewListKeys.FORM_MANAGEMENT,
		key: 1,
		menuItem: FORM_MANAGEMENT,
		title: 'Form Management',
		show: true
	},
	{
		id: ReviewListKeys.ROLE_MANAGEMENT,
		key: 2,
		menuItem: ROLE_MANAGEMENT,
		title: 'Role Management',
		show: true
	},
	{
		id: ReviewListKeys.USER,
		key: 3,
		menuItem: USER_SEARCH,
		title: 'Users',
		show: true
	},
	{
		id: ReviewListKeys.COMPETITIONS,
		key: 4,
		menuItem: COMPETITIONS,
		title: 'Competitions',
		show: true
	},
	{
		id: ReviewListKeys.FOLLOWING_FEED,
		key: 5,
		menuItem: FOLLOWING_FEED,
		title: 'Following Feed',
		show: true
	}
];
export const DEFAULT_STATUS_FILTER = [{ label: 'Review', value: 2 }];
export const FORM_STATUSES = [
	{
		id: 1,
		value: 'Draft'
	},
	{
		id: 2,
		value: 'Review'
	},
	{
		id: 3,
		value: 'Approved'
	},
	{
		id: 4,
		value: 'Rejected'
	}
];

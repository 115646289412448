import { createUseStyles } from 'react-jss';
import loginRightColumnImage from '../../assets/images/Team_walking_out_from_behind.jpg';

export default createUseStyles(() => ({
	loginImageDisplay: {
		backgroundImage: `url('${loginRightColumnImage}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: '100%',
		width: '100%'
	},
	Header: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#464749',
		marginBlockStart: 0
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	ProfilefieldContainer: {
		paddingTop: 15,
		paddingBottom: 10,
		width: '100%'
	},
	errorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	},
	errorText: {
		color: '#db0000'
	},
	passwordInfo: {
		fontSize: 13
	},
	divider: {
		height: '1px',
		backgroundColor: '#dcebf3',
		border: 'none'
	},
	dividerMargin: {
		marginTop: 30,
		marginLeft: -15,
		marginRight: -15,
		marginBottom: 30
	},
	pushFloor: {
		marginBottom: 25
	},
	passwordResetMessage: {
		fontSize: 15
	},
	link: {
		textDecoration: 'underline',
		color: '#0085ca',
		cursor: 'pointer'
	}
}));

import React, { useState, useRef } from 'react';
import { Col, Row } from 'react-grid-system';
import classnames from 'classnames';
import useStyles from '../../../AdminActions/styles';
import axios from 'axios';
import LoaderSpinner from '../../../LoaderSpinner';
import ResizingTextArea from '../../../ResizingTextArea';
import AppSyncService from '../../../../graphql/AppSyncService';
import FormDropDown from '../../../FormDropDown';
import FormMultiSelectList from '../../../FormMultiSelectList';
import HeaderBanner from '../../../HeaderBanner';
import Toggle from '../../../Toggle';
import FormDatePicker from '../../../FormDatePicker';
import UploadPhoto from '../../../UploadPhoto';
import {
	uploadImageToS3,
	deleteImageFromS3,
	deleteResetAdminUploadedFile,
	saveResetAdminUploadedFile,
	getUserImage
} from '../../../../graphql/custom.queries';
import { useToast } from '../../../../hooks/useToast';
import { useAuth } from '../../../../hooks/useAuth';
import Lightbox from 'react-image-lightbox';
import { formatDate } from '../../../../utils/helpers';
import { REVIEW, WORD_DOC_MIME_TYPE } from '../../../../utils/constants';
import UploadPhotoErrorModal from '../../../UploadPhotoErrorModal';

const AdminActions = ({
	adminActionOptions,
	adminActions,
	setAdminActions,
	formName,
	isLoading,
	error,
	updateAdminAction,
	noBorderBottom,
	status,
	setIsFormInvalid,
	invalidateAdminActionFields,
	retirementCategory
}) => {
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [imgSrcPdf, setImageSrcPdf] = useState(null);
	const [isSaveAdminDetailsInvalid, setIsSaveAdminDetailsInvalid] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const hrefLink = useRef(null);
	const classes = useStyles();

	const {
		adminExternalId,
		ewdAccept,
		ewdComments,
		ewdRecommendationDate,
		ewgmComments,
		ewgmProceedToVetAssessment,
		ewgmRecommendationDate,
		illnessId,
		ilnessesOther,
		recommendation,
		injuryIds,
		injuriesOther,
		rejectReasonId,
		rejectReasonOther,
		rejectingApplication,
		vetAssessmentDate,
		vetAssessmentId,
		vetAssessmentSummary,
		vetUploads,
		adminStatusId,
		formComplete,
		resetNominationId
	} = adminActions;

	const {
		adminMembers,
		resetAdminStatuses,
		resetInjuries,
		resetIllnesses,
		resetVetCategories,
		resetRejectReasons
	} = adminActionOptions;

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const updateAdminActionFieldValue = (key, value) => {
		setIsFormInvalid(false);
		setAdminActions({
			...adminActions,
			[key]: {
				...adminActions[key],
				isInvalid: false,
				value
			}
		});
	};

	const validateForm = () => {
		if (Number(adminExternalId.value) === 0) {
			setIsSaveAdminDetailsInvalid(true);
			invalidateAdminActionFields([adminExternalId.key]);
			return false;
		}
		return true;
	};

	const handlePhotoUpload = (image, name, uploadType) => {
		{
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data) {
					let imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							AppSyncService.execute(saveResetAdminUploadedFile, {
								filename: image.name,
								key: imageKey,
								memberExternalId: currentUser?.UserId,
								resetNominationId: resetNominationId.value,
								type: uploadType
							})
								.then(() => {
									updateAdminActionFieldValue(name, [
										...adminActions[name].value,
										{ filename: image.name, key: imageKey }
									]);
									addToast({ Message: 'File uploaded', IsSuccess: true });
								})
								.catch(() => {
									addToast({
										Message: 'Error Uploading to S3...',
										IsSuccess: false
									});
								});
						});
				}
			});
		}
	};

	const removeImage = (img, name) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteResetAdminUploadedFile, {
						key: img.key
					})
						.then((data) => {
							if (data.data.deleteResetAdminUploadedFile) {
								updateAdminActionFieldValue(
									name,
									adminActions[name].value.filter(
										(item) => item.key !== img.key
									)
								);
								addToast({ Message: 'Image removed', IsSuccess: true });
							}
						})
						.catch(() => {
							addToast({
								Message: 'Error Deleting from S3...',
								IsSuccess: false
							});
						});
				}
			}
		);
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			if (!img.key.includes('.pdf')) {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			} else {
				setImageSrcPdf(data.data.getUserImage.signedUrl);
				hrefLink.current.click();
			}
		});
	};

	const showInjuriesOtherField =
		injuryIds && injuryIds.value?.find(({ label }) => label === 'Other');

	return (
		<>
			<HeaderBanner
				title="Admin"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			></HeaderBanner>
			<div
				className={classnames({
					[classes.container]: true
				})}
			>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div>
					<div
						className={classes.adminActionContainer}
						style={noBorderBottom ? { borderBottom: 'none' } : {}}
					>
						<div className={classes.notesContainer}>
							<Row>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>
										{adminExternalId.title}
									</div>
									<FormDropDown
										showBlank
										id={`${formName}-${adminExternalId.key}`}
										items={adminMembers}
										selectedId={adminExternalId.value}
										disabled={status != REVIEW}
										onChange={(e) => {
											updateAdminActionFieldValue(
												adminExternalId.key,
												e.target.value
											);
											setIsSaveAdminDetailsInvalid(false);
										}}
									/>
									{adminExternalId.isInvalid && (
										<span className={classes.errorText}>
											{adminExternalId.invalidMessage}
										</span>
									)}
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>
										{adminStatusId.title}
									</div>
									<FormDropDown
										showBlank
										id={`${formName}-${adminStatusId.key}`}
										items={resetAdminStatuses}
										selectedId={adminStatusId.value}
										disabled={status != REVIEW}
										onChange={(e) =>
											updateAdminActionFieldValue(
												adminStatusId.key,
												e.target.value
											)
										}
									/>
									{adminStatusId.isInvalid && (
										<span className={classes.errorText}>
											{adminStatusId.invalidMessage}
										</span>
									)}
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>{formComplete.title}</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										disabled={status != REVIEW}
										onClick={(v) =>
											updateAdminActionFieldValue(formComplete.key, v)
										}
										value={formComplete.value}
									/>
								</Col>
							</Row>
						</div>
						<hr
							className={classes.divider}
							style={{ background: '#e7eff3' }}
						></hr>
						<div className={classes.notesContainer}>
							<Row>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>{ewdAccept.title}</div>
									<Toggle
										labelNegative="Reject"
										labelPositive="Accept"
										disabled={status != REVIEW}
										onClick={(v) =>
											updateAdminActionFieldValue(ewdAccept.key, v)
										}
										value={ewdAccept.value}
									/>
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>
										{ewdRecommendationDate.title}
									</div>
									<FormDatePicker
										id={`${formName}-${ewdRecommendationDate.key}`}
										DateValue={ewdRecommendationDate.value}
										canEdit={status == REVIEW}
										updateSelection={(v) =>
											updateAdminActionFieldValue(
												ewdRecommendationDate.key,
												formatDate(v)
											)
										}
									/>
									{ewdRecommendationDate.isInvalid && (
										<span className={classes.errorText}>
											{ewdRecommendationDate.invalidMessage}
										</span>
									)}
								</Col>
								<Col xs={12} style={{ paddingRight: 0 }}>
									<ResizingTextArea
										id={`${formName}-${ewdComments.key}`}
										value={ewdComments.value || ''}
										title={ewdComments.title}
										maxChars={200}
										canEdit={status == REVIEW}
										onChange={(e) =>
											updateAdminActionFieldValue(
												ewdComments.key,
												e.target.value
											)
										}
									/>
								</Col>
							</Row>
						</div>
						<hr
							className={classes.divider}
							style={{ background: '#e7eff3' }}
						></hr>
						<div className={classes.notesContainer}>
							<Row>
								<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
									<div className={classes.fieldTitle}>
										{ewgmProceedToVetAssessment.title}
									</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										disabled={status != REVIEW}
										value={ewgmProceedToVetAssessment.value}
										onClick={(v) =>
											updateAdminActionFieldValue(
												ewgmProceedToVetAssessment.key,
												v
											)
										}
									/>
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0, marginTop: 20 }}>
									<div className={classes.fieldTitle}>
										{ewgmRecommendationDate.title}
									</div>
									<FormDatePicker
										id={`${formName}-${ewgmRecommendationDate.key}`}
										DateValue={ewgmRecommendationDate.value}
										canEdit={status == REVIEW}
										updateSelection={(v) =>
											updateAdminActionFieldValue(
												ewgmRecommendationDate.key,
												formatDate(v)
											)
										}
									/>
									{ewgmRecommendationDate.isInvalid && (
										<span className={classes.errorText}>
											{ewgmRecommendationDate.invalidMessage}
										</span>
									)}
								</Col>
								<Col xs={12} style={{ paddingRight: 0 }}>
									<ResizingTextArea
										id={`${formName}-${ewgmComments.key}`}
										value={ewgmComments.value || ''}
										title={ewgmComments.title}
										maxChars={200}
										canEdit={status == REVIEW}
										onChange={(e) =>
											updateAdminActionFieldValue(
												ewgmComments.key,
												e.target.value
											)
										}
									/>
									{ewgmComments.isInvalid && (
										<span className={classes.errorText}>
											{ewgmComments.invalidMessage}
										</span>
									)}
								</Col>
							</Row>
						</div>
						<hr
							className={classes.divider}
							style={{ background: '#e7eff3' }}
						></hr>
						{ewgmProceedToVetAssessment.value && (
							<>
								<div className={classes.notesContainer}>
									<Row>
										<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
											<div className={classes.fieldTitle}>
												{vetAssessmentId.title}
											</div>
											<FormDropDown
												showBlank
												id={`${formName}-${vetAssessmentId.key}`}
												items={resetVetCategories}
												selectedId={vetAssessmentId.value}
												disabled={status != REVIEW}
												onChange={(e) =>
													updateAdminActionFieldValue(
														vetAssessmentId.key,
														e.target.value
													)
												}
											/>
											{vetAssessmentId.isInvalid && (
												<span className={classes.errorText}>
													{vetAssessmentId.invalidMessage}
												</span>
											)}
										</Col>
										<Col xs={12} lg={4} style={{ paddingRight: 0 }}>
											<div className={classes.fieldTitle}>
												{vetAssessmentDate.title}
											</div>
											<FormDatePicker
												id={`${formName}-${vetAssessmentDate.key}`}
												DateValue={vetAssessmentDate.value}
												canEdit={status == REVIEW}
												updateSelection={(v) =>
													updateAdminActionFieldValue(
														vetAssessmentDate.key,
														formatDate(v)
													)
												}
											/>
											{vetAssessmentDate.isInvalid && (
												<span className={classes.errorText}>
													{vetAssessmentDate.invalidMessage}
												</span>
											)}
										</Col>
										{status == REVIEW && (
											<Col xs={12} style={{ paddingRight: 0, marginTop: 20 }}>
												<div className={classes.fieldTitle}>
													{injuryIds.title}
												</div>
												<FormMultiSelectList
													id={`${formName}-${injuryIds.key}`}
													listOptions={resetInjuries}
													selectedIds={injuryIds.value}
													onSelectChange={(v) => {
														updateAdminActionFieldValue(injuryIds.key, v);
													}}
												/>
											</Col>
										)}
										{status !== REVIEW && (
											<Col xs={12} style={{ paddingRight: 0 }}>
												<ResizingTextArea
													id={`${formName}-${injuriesOther.key}`}
													value={injuryIds.value
														?.map((item) => item.value)
														.join(', ')}
													title={injuryIds.title}
													canEdit={status == REVIEW}
												/>
											</Col>
										)}
										{showInjuriesOtherField && (
											<Col xs={12} style={{ paddingRight: 0 }}>
												<ResizingTextArea
													id={`${formName}-${injuriesOther.key}`}
													value={injuriesOther.value || ''}
													title={injuriesOther.title}
													maxChars={200}
													canEdit={status == REVIEW}
													onChange={(e) =>
														updateAdminActionFieldValue(
															injuriesOther.key,
															e.target.value
														)
													}
												/>
											</Col>
										)}
										<Col xs={12} style={{ paddingRight: 0, marginTop: 20 }}>
											<div className={classes.fieldTitle}>
												{illnessId.title}
											</div>
											<FormDropDown
												showBlank
												id={`${formName}-${illnessId.key}`}
												items={resetIllnesses}
												selectedId={illnessId.value}
												disabled={status != REVIEW}
												onChange={(e) => {
													updateAdminActionFieldValue(
														illnessId.key,
														e.target.value
													);
												}}
											/>
										</Col>
										{Number(illnessId.value) === 7 && (
											<Col xs={12} style={{ paddingRight: 0 }}>
												<ResizingTextArea
													id={`${formName}-${ilnessesOther.key}`}
													value={ilnessesOther.value || ''}
													title={ilnessesOther.title}
													maxChars={200}
													canEdit={status == REVIEW}
													onChange={(e) =>
														updateAdminActionFieldValue(
															ilnessesOther.key,
															e.target.value
														)
													}
												/>
											</Col>
										)}
										<Col
											xs={12}
											lg={4}
											style={{ paddingRight: 0, marginTop: 20 }}
										>
											<div className={classes.fieldTitle}>
												{vetUploads.title}
											</div>
											<UploadPhoto
												id={`${formName}-${vetUploads.key}`}
												formats={[WORD_DOC_MIME_TYPE, 'pdf']}
												labelName="Upload Document(s)"
												error={handleUploadError}
												data={vetUploads.value}
												sizeLimitMB={5}
												numberOfImages={3}
												canEdit={status == REVIEW}
												success={(image) =>
													handlePhotoUpload(
														image,
														vetUploads.key,
														'VetDocuments'
													)
												}
												getImage={getImage}
												removeImage={(image) =>
													removeImage(image, vetUploads.key)
												}
											/>
											{vetUploads.isInvalid && (
												<span className={classes.errorText}>
													{vetUploads.invalidMessage}
												</span>
											)}
										</Col>
										<Col xs={12} style={{ paddingRight: 0 }}>
											<ResizingTextArea
												id={`${formName}-${vetAssessmentSummary.key}`}
												value={vetAssessmentSummary.value || ''}
												title={vetAssessmentSummary.title}
												maxChars={500}
												canEdit={status == REVIEW}
												onChange={(e) =>
													updateAdminActionFieldValue(
														vetAssessmentSummary.key,
														e.target.value
													)
												}
											/>
											{vetAssessmentSummary.isInvalid && (
												<span className={classes.errorText}>
													{vetAssessmentSummary.invalidMessage}
												</span>
											)}
										</Col>
									</Row>
									<hr
										className={classes.divider}
										style={{ background: '#e7eff3' }}
									></hr>
									<Row>
										<Col>{retirementCategory ? retirementCategory : null}</Col>
									</Row>
								</div>
								<hr
									className={classes.divider}
									style={{ background: '#e7eff3' }}
								></hr>
							</>
						)}
						<div className={classes.notesContainer} style={{ paddingTop: 0 }}>
							<Row>
								<Col xs={12} style={{ paddingRight: 0 }}>
									<ResizingTextArea
										id={`${formName}-${recommendation.key}`}
										value={recommendation.value || ''}
										title={recommendation.title}
										canEdit={status == REVIEW}
										maxChars={500}
										onChange={(e) =>
											updateAdminActionFieldValue(
												recommendation.key,
												e.target.value
											)
										}
									/>
									{recommendation.isInvalid && (
										<span className={classes.errorText}>
											{recommendation.invalidMessage}
										</span>
									)}
								</Col>
								<Col xs={12} lg={4} style={{ paddingRight: 0, marginTop: 20 }}>
									<div className={classes.fieldTitle}>
										{rejectingApplication.title}
									</div>
									<Toggle
										id={`${formName}-${rejectingApplication.key}`}
										labelNegative="No"
										labelPositive="Yes"
										disabled={status != REVIEW}
										value={rejectingApplication.value}
										onClick={(v) =>
											updateAdminActionFieldValue(rejectingApplication.key, v)
										}
									/>
								</Col>
								{rejectingApplication.value && (
									<Col xs={12} style={{ paddingRight: 0, marginTop: 20 }}>
										<div className={classes.fieldTitle}>
											{rejectReasonId.title}
										</div>
										<FormDropDown
											showBlank
											id={`${formName}-${rejectReasonId.key}`}
											items={resetRejectReasons}
											selectedId={rejectReasonId.value}
											disabled={status != REVIEW}
											onChange={(e) =>
												updateAdminActionFieldValue(
													rejectReasonId.key,
													e.target.value
												)
											}
										/>
									</Col>
								)}
								{Number(rejectReasonId.value) === 7 && (
									<Col xs={12} style={{ paddingRight: 0 }}>
										<ResizingTextArea
											id={`${formName}-${rejectReasonOther.key}`}
											value={rejectReasonOther.value || ''}
											title={rejectReasonOther.title}
											maxChars={200}
											canEdit={status == REVIEW}
											onChange={(e) =>
												updateAdminActionFieldValue(
													rejectReasonOther.key,
													e.target.value
												)
											}
										/>
									</Col>
								)}
							</Row>
						</div>
						{status === REVIEW && (
							<>
								<hr
									className={classes.divider}
									style={{ background: '#e7eff3' }}
								></hr>
								<div className={classes.notesContainer}>
									<Row>
										<Col>
											{error && (
												<span className={classes.errorMessage}>{error}</span>
											)}
										</Col>
										<Col style={{ paddingRight: 0 }}>
											<div className={classes.saveRequest}>
												{isSaveAdminDetailsInvalid && (
													<span className={classes.errorMessage}>
														Please complete actioning field
													</span>
												)}
												<span>
													<input
														id={`${formName}-saveAdminDetails`}
														type="button"
														value="Save Admin Details"
														className={classes.primaryFormButton}
														onClick={() => {
															const isValidForm = validateForm();
															if (isValidForm) {
																updateAdminAction();
															}
														}}
													/>
												</span>
											</div>
										</Col>
									</Row>
								</div>
							</>
						)}
					</div>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
					{imgSrcUrl && (
						<Lightbox
							mainSrc={imgSrcUrl}
							onCloseRequest={() => setImgSrcUrl(false)}
						/>
					)}
					<a
						style={{ visibility: 'hidden', height: 0, display: 'block' }}
						href={imgSrcPdf}
						ref={hrefLink}
						target="_blank"
						rel="noreferrer"
					>
						Pdf Link
					</a>
				</div>
			</div>
		</>
	);
};
export default AdminActions;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	boxContainer: {
		border: 'none',
		borderRadius: 5,
		overflow: 'visible',
		marginTop: 15,
		'@media (min-width:992px)': {
			border: '1px solid #b3bcc1'
		}
	},
	claimButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		marginRight: 10,
		height: 38,
		width: '100%',
		fontSize: 14,
		'-webkitAppearance': 'none',
		'&:focus': {
			outline: 'none'
		},
		'&:disabled': {
			backgroundColor: '#d8d8d8',
			color: '#afafaf'
		},
		cursor: 'pointer'
	},
	claimText: {
		width: '100%',
		fontSize: 12,
		marginBottom: 10,
		'@media (min-width: 992px)': {
			fontSize: 14
		}
	}
}));

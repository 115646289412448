import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	listContainer: {
		position: 'relative',
		zIndex: 2
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		cursor: 'pointer',
		background: 'white',
		top: 5,
		width: '100%',
		height: 150,
		overflow: 'hidden',
		overflowY: 'scroll',
		border: '1px solid #b3bcc1'
	},
	listContainerOnTable: {
		position: 'absolute',
		zIndex: 2
	},
	listOnTable: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		cursor: 'pointer',
		background: 'white',
		top: 5,
		width: '140%',
		height: 150,
		overflow: 'hidden',
		overflowY: 'scroll',
		border: '1px solid #b3bcc1'
	},
	listItem: {
		padding: 10,
		fontSize: 13
	},
	hrLine: {
		background: '#d4dbde',
		height: 1,
		border: 'none',
		margin: 0
	},
	selected: {
		background: '#dcebf3',
		color: '#016ea6',
		fontWeight: 600
	}
}));

import { Chip } from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { WORD_DOC_MIME_TYPE } from '../../utils/constants';
import useStyles from './styles';

const ProfilePhoto = ({
	id,
	sizeLimitMB,
	formats,
	error,
	success,
	data,
	getImage,
	removeImage,
	setActivePhoto,
	labelName,
	numberOfImages,
	canEdit = true,
	disableApproved = false,
	ignoreApprovedInLimit = false
}) => {
	const classes = useStyles();
	let maxImages = numberOfImages ? numberOfImages : 3;
	if (
		formats &&
		formats.find((item) => item === 'jpg') &&
		!formats.find((item) => item === 'jpeg')
	) {
		formats.push('jpeg');
	} else if (
		formats &&
		formats.find((item) => item === 'jpeg') &&
		!formats.find((item) => item === 'jpg')
	) {
		formats.push('jpg');
	}
	if (
		formats &&
		(formats.find((item) => item === 'doc') ||
			formats.find((item) => item === 'msword') ||
			formats.find((item) => item === 'docx') ||
			formats.find((item) => item === WORD_DOC_MIME_TYPE))
	) {
		formats.push('doc,docx,application/msword,' + WORD_DOC_MIME_TYPE);
	}
	const accept = formats?.length > 0 ? '.' + formats.join(',.') : '*.*';
	const checkFile = (file) => {
		let fileNameVerified = true;
		let totalSizeMB = file[0].size / Math.pow(1024, 2);
		let checkFileFormat = checkFormat(file[0]);
		data.map((img) => {
			if (img.filename === file[0].name) {
				fileNameVerified = false;
			}
		});
		if (fileNameVerified && checkFileFormat && totalSizeMB < sizeLimitMB) {
			success(file[0]);
		} else {
			error();
		}
	};

	const inactiveProfilePictureStyle = {
		backgroundColor: 'rgb(0 144 41)',
		color: 'white',
		borderRadius: 3,
		height: 20,
		marginRight: 4
	};

	const activeProfilePictureStyle = {
		backgroundColor: '#e0e0e0',
		color: 'rgb(120 120 120)',
		borderRadius: 3,
		height: 20,
		marginRight: 4
	};

	const checkFormat = (file) => {
		let formatCheck = false;
		formats.map((format) => {
			if (file.type.includes(format)) {
				formatCheck = true;
			}
		});
		return formatCheck;
	};

	const renderImageLinks = () => {
		if (data && data.length > 0) {
			return data.map((img) => {
				return (
					<div key={img.filename} style={{ marginTop: 10 }}>
						<Row style={{ alignItems: 'center' }}>
							<Col xs={12}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center'
									}}
								>
									<span
										className={
											img.approved ? classes.approved : classes.uploadedFile
										}
										onClick={() => getImage(img)}
									>
										{img.filename} ({img?.uploadedBy?.firstName}{' '}
										{img?.uploadedBy?.lastName})
									</span>
									<div className={classes.chipContainer}>
										<Chip
											size="small"
											label={
												img.activeProfilePicture
													? 'Active Profile Photo'
													: 'Make Profile Photo'
											}
											clickable={img.activeProfilePicture ? false : true}
											onClick={() => setActivePhoto(img)}
											style={
												img.activeProfilePicture
													? activeProfilePictureStyle
													: inactiveProfilePictureStyle
											}
										/>
										{!(disableApproved && img.approved) && canEdit && (
											<span
												className={classes.clearUpload}
												onClick={() => removeImage(img)}
											>
												{img.approved}
												&#10006;
											</span>
										)}
									</div>
								</div>
							</Col>
						</Row>
					</div>
				);
			});
		}
	};

	return (
		<>
			{canEdit && (
				<label id={id}>
					<input
						type="file"
						disabled={
							data &&
							(ignoreApprovedInLimit
								? data.filter((img) => img.approved !== true).length < maxImages
								: data.length < maxImages)
								? false
								: true
						}
						style={{ display: 'none' }}
						onChange={(e) => checkFile(e.target.files)}
						value=""
						accept={accept}
					/>
					<div
						className={
							data &&
							(ignoreApprovedInLimit
								? data.filter((img) => img.approved !== true).length < maxImages
								: data.length < maxImages)
								? classes.uploadButton
								: classes.uploadButtonDisabled
						}
					>
						<div style={{ marginTop: 8 }}>
							{labelName ? labelName : 'Upload Image'}
						</div>
					</div>
				</label>
			)}
			{renderImageLinks()}
		</>
	);
};

export default ProfilePhoto;

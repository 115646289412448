import { createUseStyles } from 'react-jss';

export default createUseStyles({
	closeButtonStyles: {
		color: 'white',
		background: '#006da6',
		marginTop: 20,
		width: '35%',
		textAlign: 'center',
		height: 30,
		paddingTop: 8,
		marginLeft: 'auto',
		marginRight: 'auto',
		cursor: 'pointer',
		borderRadius: 4
	},
	headingStyles: {
		color: '#464749'
	}
});

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import HorseUpdate from '../../../HorseUpdate';
import AppSyncService from '../../../../graphql/AppSyncService';
import { countHorseUpdates, getHorseUpdates } from '../../graphql/queries';
import InfiniteScroll from 'react-infinite-scroll-component';
import HorseUpdatesContainer from '../../../HorseUpdatesContainer';
import InfiniteScrollLoadRow from '../../../InfiniteScrollLoadRow';
import PostUpdateSection from '../../../PostUpdateSection';
import { removeHorseUpdate } from '../../../../graphql/custom.queries';
import { useToast } from '../../../../hooks/useToast';

const HORSE_UPDATES_CONTAINER_ID = 'horse-updates-container';

const HorseUpdates = ({
	horseAvatarVisible,
	horseProfile,
	memberData,
	isOwner
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [horseUpdates, setHorseUpdates] = useState([]);
	const [totalHorseUpdates, setTotalHorseUpdates] = useState(0);
	const [paginationIndex, setPaginationIndex] = useState(0);
	const [isLoadOrRefresh, setIsLoadOrRefresh] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const fetchHorseUpdates = () => {
		AppSyncService.execute(getHorseUpdates, {
			horseCode: horseProfile.horse.horseCode,
			memberId: memberData.id,
			paginationIndex,
			paginationSize: 10
		}).then((result) => {
			if (result?.data?.getUpdatesForHorse) {
				setHorseUpdates((prevState) => [
					...prevState,
					...result.data.getUpdatesForHorse
				]);
				setPaginationIndex((prevState) => prevState + 1);
			}
		});
	};

	useEffect(() => {
		if (isLoadOrRefresh) {
			setIsLoading(true);
			AppSyncService.execute(getHorseUpdates, {
				horseCode: horseProfile.horse.horseCode,
				memberId: memberData.id,
				paginationIndex: 0,
				paginationSize: 10
			}).then((result) => {
				if (result?.data?.getUpdatesForHorse) {
					setHorseUpdates(result.data.getUpdatesForHorse);
					setPaginationIndex(1);
					setIsLoadOrRefresh(false);
					setIsLoading(false);
				} else setIsLoading(false);
			});
			AppSyncService.execute(countHorseUpdates, {
				horseCode: horseProfile.horse.horseCode,
				memberId: memberData.id
			}).then((result) => {
				if (result?.data?.countUpdatesForHorse) {
					setTotalHorseUpdates(result.data.countUpdatesForHorse);
				}
			});
		}
	}, [isLoadOrRefresh]);

	const onRemoveHorseUpdate = (postId) => {
		AppSyncService.execute(removeHorseUpdate, {
			memberExternalId: memberData.externalId,
			postId
		}).then((result) => {
			if (result?.data?.removeHorseUpdate?.success) {
				addToast({ Message: 'Removed horse update', IsSuccess: true });
				setIsLoadOrRefresh(true);
			} else {
				addToast({
					Message: 'Failed to remove horse update',
					IsSuccess: false
				});
			}
		});
	};

	return (
		<div className={classes.updateSectionContainer}>
			<div className={classes.topHeader}>Updates</div>
			{isOwner && (
				<PostUpdateSection
					idPrefix="horse-profile"
					horseData={horseProfile}
					refreshData={() => setIsLoadOrRefresh(true)}
					isLoadingHorseUpdates={isLoading}
				/>
			)}
			{horseUpdates.length > 0 && (
				<HorseUpdatesContainer
					id={HORSE_UPDATES_CONTAINER_ID}
					style={{ maxHeight: 500 }}
				>
					<InfiniteScroll
						dataLength={horseUpdates.length}
						next={fetchHorseUpdates}
						hasMore={totalHorseUpdates !== horseUpdates.length}
						loader={<InfiniteScrollLoadRow />}
						scrollableTarget={HORSE_UPDATES_CONTAINER_ID}
					>
						{horseUpdates.map(
							({
								post_id,
								horse_code: horseCode,
								message,
								created_at,
								files,
								postingMember,
								source
							}) => (
								<HorseUpdate
									postId={post_id}
									horseCode={horseCode}
									horseAvatarVisible={horseAvatarVisible}
									profileImageSrc={
										horseProfile?.horse?.ottInformation?.profilePhoto?.key
									}
									key={post_id}
									horseName={horseProfile?.horse?.name}
									message={message}
									createdAt={created_at}
									files={files}
									onRemoveUpdate={onRemoveHorseUpdate}
									showMoreOptions={
										memberData?.externalId === postingMember?.externalId
									}
									source={source}
								/>
							)
						)}
					</InfiniteScroll>
				</HorseUpdatesContainer>
			)}
		</div>
	);
};

export default HorseUpdates;

import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { saveBusinessSocialLinksDetails } from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import FormTextField from '../FormTextField';

// form validation rules
const schema = yup.object().shape({});

const BusinessSocialDetails = ({
	currentUser,
	businessProfileData,
	isLoading,
	updateIsLoading,
	setIsSubmitted,
	businessProfileType,
	onSaveBusinessProfileData,
	validateFields
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, errors, control, setValue } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const [facebook, setFacebook] = useState('');
	const [instagram, setInstagram] = useState('');
	const [twitter, setTwitter] = useState('');
	const [website, setWebsite] = useState('');
	const [isFormDisabled, setIsFormDisabled] = useState(true);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);

	useEffect(() => {
		setFacebook(businessProfileData ? businessProfileData.facebook : '');
		setValue(
			'facebook',
			businessProfileData ? businessProfileData.facebook : ''
		);
		setInstagram(businessProfileData ? businessProfileData.instagram : '');
		setValue(
			'instagram',
			businessProfileData ? businessProfileData.instagram : ''
		);
		setTwitter(businessProfileData ? businessProfileData.twitter : '');
		setValue('twitter', businessProfileData ? businessProfileData.twitter : '');
		setWebsite(businessProfileData ? businessProfileData.website : '');
		setValue('website', businessProfileData ? businessProfileData.website : '');
		updateIsLoading(false);
		setIsFormDisabled(true);
	}, [businessProfileData]);

	const onSubmit = async () => {
		validateFields();
		setBackendErrorMessage(null);
		const currentState = {
			facebook: facebook,
			instagram: instagram,
			twitter: twitter,
			website: website,
			status: 'Draft'
		};
		try {
			AppSyncService.execute(saveBusinessSocialLinksDetails, {
				facebook: facebook,
				instagram: instagram,
				memberExternalId: currentUser?.UserId,
				twitter: twitter,
				website: website,
				businessProfileTypeId: businessProfileType
			}).then((data) => {
				if (data.data != null) {
					addToast({ Message: 'Saved', IsSuccess: true });
					setIsSubmitted(false);
					onSaveBusinessProfileData(currentState);
				}
			});
			setIsFormDisabled(true);
		} catch (error) {
			if (error) {
				setBackendErrorMessage(error);
			} else {
				setBackendErrorMessage(
					'Unknown error while saving user Business Inforamtion data. Please contact support'
				);
			}
		}
	};

	return (
		<>
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<form
				className={classes.formContainer}
				onSubmit={handleSubmit(onSubmit, () => validateFields())}
			>
				<div className={classes.personalProfile}>
					<Row>
						<Col>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>
									Twitter Username (Optional)
								</div>
								<Controller
									control={control}
									name="twitter"
									render={({ onChange }) => (
										<FormTextField
											value={twitter}
											preSymbol="@"
											onChange={(e) => {
												onChange(e.target.value);
												setTwitter(e.target.value);
												setIsFormDisabled(false);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.twitter?.message}
								</span>
							</div>
						</Col>
						<Col>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>
									Instagram Username (Optional)
								</div>
								<Controller
									control={control}
									name="instagram"
									render={({ onChange }) => (
										<FormTextField
											value={instagram}
											styles={{ hight: '60px' }}
											preSymbol="@"
											onChange={(e) => {
												onChange(e.target.value);
												setInstagram(e.target.value);
												setIsFormDisabled(false);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.instagram?.message}
								</span>
							</div>
						</Col>
						<Col>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>
									Facebook Username (Optional)
								</div>
								<Controller
									control={control}
									name="facebook"
									render={({ onChange }) => (
										<FormTextField
											value={facebook}
											preSymbol="@"
											onChange={(e) => {
												onChange(e.target.value);
												setFacebook(e.target.value);
												setIsFormDisabled(false);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.facebook?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>Website (Optional)</div>
								<Controller
									control={control}
									name="website"
									render={({ onChange }) => (
										<FormTextField
											value={website}
											onChange={(e) => {
												onChange(e.target.value);
												setWebsite(e.target.value);
												setIsFormDisabled(false);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.website?.message}
								</span>
							</div>
						</Col>
						<Col></Col>
						<Col></Col>
					</Row>
				</div>
				<hr className={classes.divider}></hr>

				<div className={classes.buttonContainer}>
					<div className={classes.errorContainer}>
						{backendErrorMessage && (
							<span className={classes.errorMessage}>
								{backendErrorMessage}
							</span>
						)}
					</div>
					<div className={classes.saveRequest}>
						<span className={classes.lastSaved}></span>
						<span>
							<PrimaryButton
								style={{ width: '165px' }}
								disabled={isFormDisabled}
							>
								{isFormDisabled ? 'Saved' : 'Save Changes'}
							</PrimaryButton>
						</span>
					</div>
				</div>
				<div className={classes.clear}></div>
			</form>
		</>
	);
};
export default BusinessSocialDetails;

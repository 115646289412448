import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	/* The hero image */
	heroImage: {
		minHeight: '222px',
		borderRadius: '5px',
		marginBottom: '55px',
		/* Position and center the image to scale nicely on all screens */
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
		cursor: 'pointer'
	},

	heroImageMobile: {
		minHeight: '350px',
		borderRadius: '5px',
		marginBottom: '45px',
		/* Position and center the image to scale nicely on all screens */
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
		width: 'unset!important',
		marginLeft: '10px!important',
		marginRight: '10px!important'
	},

	/* Place text in the middle of the image */
	heroText: {
		marginRight: 'auto',
		marginLeft: 'auto',
		width: '75%',
		padding: 10,
		transform: 'translate(0px, 125px)',
		minHeight: 130,
		textAlign: 'left',
		borderRadius: '5px 5px 0 0',
		backgroundColor: 'white',
		'-webkit-mask-image': '-webkit-radial-gradient(white, black)'
	},
	heroTextMobile: {
		textAlign: 'left',
		padding: '10px',
		'-webkit-mask-image': '-webkit-radial-gradient(white, black)',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, 0%)',
		backgroundColor: 'white',
		width: '75%',
		marginTop: '55px',
		minHeight: 130,
		borderRadius: '5px 5px 0 0'
	},
	mull: {
		backgroundColor: '#FFFFFF',
		width: '100%'
	},
	title: {
		margin: 0,
		padding: 0,
		background: '#ffffff',
		color: '#464749',
		fontWeight: 'bold',
		fontFamily: 'Open-sans !important',
		marginBottom: 10
	},
	content: {
		wordBreak: 'break-word',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': 4 /* number of lines to show */,
		'-webkit-box-orient': 'vertical'
	},

	datetime: {
		color: '#939393',
		marginTop: 15,
		fontSize: 'small'
	},

	mobileSegment: {
		width: '100%'
	},
	desktopSegment: {
		width: '50%'
	},
	desktopContainer: {
		display: 'flex'
	},
	mobileContainer: {
		display: 'unset'
	}
}));

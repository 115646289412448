import React, { useState, useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup1 from 'yup';
import { Row, Col, Container, useScreenClass } from 'react-grid-system';
import { navigate } from 'gatsby';
import useStyles from '../Register/styles';
import SEO from '../seo';
import TwoColLayout from '../TwoColLayout';
import PrimaryButton from '../PrimaryButton';
import { useAuth } from '../../hooks/useAuth';
import { Hidden } from 'react-grid-system';
import qs from 'query-string';
import { useToast } from '../../hooks/useToast';
import LoaderSpinner from '../LoaderSpinner';
import { useLocation } from '@reach/router';
import {
	ADMINWHITELISTURL,
	INVALID_EMAIL_MESSAGE,
	XXL_SCREEN_SIZE,
	LogInKeys,
	OTT_INFO_MAIL,
	RETRAINERWHITELISTURL,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_RV_EQUINE_WELFARE,
	WHITELISTURL
} from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

// form validation rules
const loginSchema = yup1.object().shape({
	loginEmail: yup1
		.string()
		.required('Email is required')
		.email(INVALID_EMAIL_MESSAGE),
	loginPassword: yup1.string().required('Password is required')
});

const Login = () => {
	const screenSize = useScreenClass();
	const isXXLScreenSize = XXL_SCREEN_SIZE.includes(screenSize);
	const location = useLocation();
	const loginPageContentful = useStaticQuery(
		graphql`
			query loginPageContentful {
				allContentfulInfoCards(filter: { tags: { eq: "Login Page" } }) {
					nodes {
						image {
							file {
								url
							}
							title
						}
					}
				}
			}
		`
	);

	const loginImage =
		loginPageContentful?.allContentfulInfoCards?.nodes &&
		loginPageContentful?.allContentfulInfoCards?.nodes?.length > 0
			? loginPageContentful.allContentfulInfoCards.nodes[0]?.image?.file?.url
			: null;
	const classes = useStyles({ loginRightColumnImage: loginImage });
	const queryParam = qs.parse(location.search);
	const { addToast } = useToast();
	const { signIn, confirmSignupEmailCode, currentUser } = useAuth();
	const { register, handleSubmit, errors } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(loginSchema)
	});
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [showVerificationSuccessful, setShowVerificationSuccessful] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (queryParam.code != null) {
			setIsLoading(true);
			let isConfirmed = confirmSignupEmailCode(
				queryParam.username,
				queryParam.code
			);
			isConfirmed
				.then(() => {
					addToast({ Message: 'Verification is successful', IsSuccess: true });
					setShowVerificationSuccessful(true);
				})
				.catch((error) => {
					if (error && error.code) {
						switch (error.code) {
							case 'UserNotConfirmedException':
								navigate(`/verification-landing-page/${queryParam.email}`);
								break;
							default:
								setBackendErrorMessage(
									error.message
										? error.message
										: 'Unknown error while confirming the user. Please contact support'
								);
								break;
						}
					} else {
						setBackendErrorMessage(
							'Unknown error while confirming the user. Please contact support'
						);
					}
				});
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		let previousUrl = window?.history?.state?.prevLocation;
		if (currentUser && WHITELISTURL.includes(previousUrl)) {
			window.history.replaceState({}, 'pre', window.history.back(2));
			navigate(previousUrl);
		} else if (
			currentUser &&
			RETRAINERWHITELISTURL.includes(previousUrl) &&
			currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)
		) {
			window.history.replaceState({}, 'pre', window.history.back(2));
			navigate(previousUrl);
		} else if (
			currentUser &&
			ADMINWHITELISTURL.includes(previousUrl) &&
			currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)
		) {
			window.history.replaceState({}, 'pre', window.history.back(2));
			navigate(previousUrl);
		} else {
			if (currentUser) {
				navigate(DASHBOARD_ROUTE);
			}
		}
	}, [currentUser]);

	const onSubmit = (data) => {
		setIsLoading(true);
		loginUser(data).then((isLoggedIn) => {
			setIsLoading(false);
			if (isLoggedIn) {
				navigate(DASHBOARD_ROUTE);
			}
		});
	};

	const loginUser = async (data) => {
		try {
			setBackendErrorMessage(null);
			await signIn(data.loginEmail, data.loginPassword);
			return true;
		} catch (error) {
			if (error?.code) {
				if (error.message.includes('LambdaEmailNotVerified')) {
					setBackendErrorMessage(
						`We were unable to verify your mail, please contact ${OTT_INFO_MAIL} to get it verified`
					);
					return false;
				} else if (error.message.includes('User is disabled')) {
					setBackendErrorMessage(
						`This user account is disabled, please contact ${OTT_INFO_MAIL} to reactivate the account.`
					);
					return false;
				}
				switch (error.code) {
					case 'NotAuthorizedException':
						setBackendErrorMessage(
							'The email address or password you have entered is incorrect, please try again'
						);
						break;
					case 'UserLambdaValidationException':
					case 'UserNotConfirmedException':
						navigate(`/verification-landing-page/${data.loginEmail}`);
						break;
					default:
						setBackendErrorMessage(
							error.message
								? error.message
								: 'Unknown error while logging in the user. Please contact support'
						);
						break;
				}
			} else {
				setBackendErrorMessage(
					'Unknown error while logging in the user. Please contact support'
				);
			}
			return false;
		}
	};

	const LoginFormHeader = () => {
		return (
			<>
				<h2 className={classes.loginHeader}>
					{showVerificationSuccessful
						? 'Verification successful Please login'
						: 'Login'}
				</h2>
				<p className={classes.description}>
					{showVerificationSuccessful
						? 'Your verification was successful, please login with the details you entered during registration.'
						: 'Log in with your details you entered during registration.'}
				</p>
				{!showVerificationSuccessful && (
					<p className={classes.subHeading}>
						Don&apos;t have an account?{' '}
						<Link id={LogInKeys.REGISTER} to="/register">
							REGISTER
						</Link>
					</p>
				)}
			</>
		);
	};

	return (
		<div style={isXXLScreenSize ? { height: 'calc(100vh - 380px)' } : {}}>
			<SEO title="Login" />
			<TwoColLayout style={{ height: '100%' }}>
				<TwoColLayout.Col3>
					<Container>
						<Row>
							<Col sm={3}></Col>
							<Col sm={9}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<LoginFormHeader />
									<hr className={(classes.divider, classes.dividerMargin)}></hr>
									<div className={classes.formInputs}>
										<label className={classes.formLabel}>Email*</label>
										<input
											name="loginEmail"
											id={LogInKeys.EMAIL}
											type="email"
											placeholder="Enter your email"
											className={classes.formInput}
											ref={register}
										/>
										<span className={classes.errorText}>
											{errors.loginEmail?.message}
										</span>
									</div>
									<div className={classes.formInputs}>
										<div className={classes.passwordLabel}>
											<label className={classes.formLabel}>Password*</label>
											<span>
												<a
													id={LogInKeys.FORGOT_PASSWORD}
													href="/password-reset"
												>
													Forgot Password?
												</a>
											</span>
										</div>
										<input
											className={classes.formInput}
											id={LogInKeys.PASSWORD}
											name="loginPassword"
											type="password"
											placeholder="Enter your password"
											ref={register}
										/>
										<span className={classes.errorText}>
											{errors.loginPassword?.message}
										</span>
									</div>
									{/* <div className={classes.keepLoggedInDiv}>
										<input
											className={classes.checkboxstyle}
											name="keepLoggedIn"
											type="checkbox"
										/>
										<label className={classes.checkboxtext}>
											Keep me logged in
										</label>
									</div> */}
									{backendErrorMessage && (
										<>
											<div className={classes.errorText}>
												{backendErrorMessage}
											</div>
											{backendErrorMessage ===
												'The quota has been exceeded.' && (
												<div className={classes.errorText}>
													Please clear your browser history and try again in a
													new tab. Submit a Contact Us form if you continue to
													experience this. For help on clearing your browser
													history, visit{' '}
													<a href="https://support.apple.com/en-za/HT201265">
														https://support.apple.com/en-za/HT201265
													</a>
												</div>
											)}
										</>
									)}
									{isLoading && (
										<div className={classes.loadingDiv}>
											<LoaderSpinner status={true} />
										</div>
									)}
									<PrimaryButton id={LogInKeys.LOGIN}>Log In</PrimaryButton>
								</form>
							</Col>
						</Row>
					</Container>
				</TwoColLayout.Col3>
				<Hidden xs sm>
					<TwoColLayout.Col4>
						<div className={classes.loginImageDisplay}></div>
					</TwoColLayout.Col4>
				</Hidden>
			</TwoColLayout>
		</div>
	);
};

export default Login;

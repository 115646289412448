import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageLoadSpinnerContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 500
	}
}));

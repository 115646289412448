export const mapArrayToMultiSelectValues = (array, options) => {
	let utilizationOfOttSponsorshipWithValues = [];
	options.forEach((item) => {
		if (array.includes(item.value)) {
			utilizationOfOttSponsorshipWithValues.push(item);
		}
	});
	return utilizationOfOttSponsorshipWithValues;
};

export const mapFileArray = (array) => {
	return array.map((item) => ({
		filename: item.filename,
		key: item.key
	}));
};

export const validateNumeric = (enteredString) => {
	const regex = RegExp(/^(\d*\,*)*\d*\.*\d*$/);
	// const regex = RegExp(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/);
	const isValidNumeric = regex.test(enteredString);
	return isValidNumeric;
};

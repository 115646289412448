import { createUseStyles } from "react-jss";
import BG from "../../assets/icons/offthetrack-bg.jpg"
export default createUseStyles(() => ({
  container: {
    backgroundImage: `url(${BG})`,
    backgroundPosition: "top center",
    backgroundSize: "cover",
    backgroundColor: "#000",
    marginBottom: 80
  },
  sectionDiv: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    height: "100vh",
  },
  
  headerRow :{
    backgroundImage: "linear-gradient(228deg, #FF168D 12%, #964A9B 41%, #0069A5 100%)",
    borderRadius: "0px 3px 3px 0px",

    "& h2" : {
        fontWeight: "bold",
        fontSize: 30,
        color: "#FFFFFF",
        textAlign: "right",
        textTransform: "uppercase",
        padding: "0.5em 1.4em 0.5em 0.5em"
    },
    "& a" :{
      color: "#006DA6"
    }
  },
  
  infoBox : {
    width: 680,
    backgroundImage: "linear-gradient(-87deg, #FFFFFF 60%, rgba(255,255,255,0.00) 100%)",
    boxShadow: "0 12px 24px 0 rgba(0,0,0,0.32)",
    borderRadius: "0 3px 3px 0",
    marginBottom: "8em",
    "& a":{
      display: "flex"
    }
  },

  content : {
    marginBottom: "2em",
    padding: "3em 3em 3em 16em",
    boxSizing: "border-box",
    "& p" :{
      fontSize: 20,
      color: "#464749",
      letterSpacing: 0,
      lineHeight: "1.5em"
    },
  },

 logo: {
    width: 375,
    marginBottom: "2em"
  },

  emailLink :{
    "& img" : {
      paddingRight: "0.4em"
    }
  },
  image: {
    width: 300
  },


  // Mobile

  containerBoxMobile : {
    margin: "1em",
    width: "90%"
  },

  headerRowMobile :{
    backgroundImage: "linear-gradient(228deg, #FF168D 12%, #964A9B 41%, #0069A5 100%)",
    borderRadius: "3px 3px 0 0",
    "& h2":{
      textAlign: "center",
      padding: "0.5em",
      color: "#FFFFFF",
      textTransform: "uppercase",
    }
  },

  infoBoxMobile :{
    background: "#fff",
    width: "100%",
    borderRadius: 4,
    marginBottom: "0em"
  },

  contentMobile : {
    width: "100%",
    padding: "2em",
    "& p" :{
      fontSize: 15,
    }
  },
  logoMobile :{
    width: "100%",
  }


}));

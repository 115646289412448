import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';
import { useAuth } from '../../hooks/useAuth';
import HeaderBanner from '../HeaderBanner';
import {
	AVAILABLE_FOR_RETRAINING,
	ACKNOWLEDGED_RETIREMENT_FARMS,
	ACKNOWLEDGED_RETRAINER,
	EVENTS,
	THOROUGHBRED,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_RV_EQUINE_WELFARE,
	SearchKeys,
	HorseStatusType,
	AVAILABLE_FOR_RETRAINING_LISTINGS,
	RESET_ELIGIBLE_LISTINGS,
	RESET_HORSE_EXPRESS,
	//HORSE_AVAILABILITY_REMOVED_STATUS,
	ACKNOWLEDGED_FOSTER_FARMS,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ROLE_ACKNOWLEDGED_RESET_RETRAINER
} from '../../utils/constants';
import RetrainerSearch from '../RetrainerSearch';
import ScrollMenu from '../ScrollMenu';
import SearchThoroughBred from '../SearchThoroughBred';
import AvailableForRetraining from '../AvailableForRetraining';
import {
	getPublishedHorsePlacementListings,
	getAvailableHorsePlacement,
	getInterestedHorseListings,
	getAdminHorsePlacementListings,
	getRetrainerHorsePlacementListings,
	getResetExpressHorsePlacement
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import { checkIsSmallScreen } from '../../utils/helpers';
import RetirementFarmSearch from '../RetirementFarmSearch';
import FosterProviderSearch from '../FosterProviderSearch';

const GlobalSearch = ({ location }) => {
	const auth = useAuth();
	const screenSize = useScreenClass();
	const classes = useStyles();
	const [selectedSearch, setSelectedSearch] = useState(THOROUGHBRED);
	const [approvedPlacementOwner, setApprovedPlacementOwner] = useState(false);
	const [menuList, setmenuList] = useState([]);
	const { currentUser } = useAuth();
	const membersExternalId = currentUser?.UserId;
	const [listingsData, setListingsData] = useState([]);
	const [placementsListed, setPlacementsListed] = useState([]);
	const bannerStyles = {
		boxSizing: 'border-box',
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto'
	};
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const [isLoading, setIsLoading] = useState(false);

	const isAdmin = currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
	const isRetrainer = currentUser?.Roles.includes(
		ROLE_RV_ACKNOWLEDGED_RETRAINER
	);
	const isRetrainerWithResetExpress = currentUser?.Roles.includes(
		ROLE_ACKNOWLEDGED_RESET_RETRAINER
	);

	useEffect(() => {
		if (currentUser) {
			setIsLoading(true);
			if (isAdmin) {
				AppSyncService.execute(getAdminHorsePlacementListings).then(
					(result) => {
						if (result?.data?.getAdminHorsePlacementListings) {
							let fullList = result.data.getAdminHorsePlacementListings;
							let availableForRetrainingList = fullList
								.filter(
									(record) =>
										AVAILABLE_FOR_RETRAINING_LISTINGS.includes(
											record.horseOTTStatusID
										) &&
										(record.removeReason.id === 5 || !record.removeReason.id)
								)
								.map((item) => ({
									horseStatusSubtype: HorseStatusType.AvailableForRetraining,
									...item
								}));

							//Todo list of removed status
							// let placementRemoved = fullList
							// 	.filter(
							// 		(record) =>
							// 			record.horseOTTStatusID == HORSE_AVAILABILITY_REMOVED_STATUS
							// 	)
							// 	.map((item) => ({
							// 		horseStatusSubtype: HorseStatusType.AvailableForRetraining,
							// 		...item
							// 	}));
							// availableForRetrainingList = [
							// 	...availableForRetrainingList,
							// 	...placementRemoved
							// ];
							let eligibleForResetList = fullList
								.filter((record) =>
									RESET_ELIGIBLE_LISTINGS.includes(record.horseOTTStatusID)
								)
								.map((item) => ({
									horseStatusSubtype: HorseStatusType.EligibleForReset,
									...item
								}));

							let horseResetExpress = fullList
								.filter((record) =>
									RESET_HORSE_EXPRESS.includes(record.horseOTTStatusID)
								)
								.map((item) => ({
									horseStatusSubtype: HorseStatusType.ResetExpressHorses,
									isAssigned: !!item.assignedRetrainer,
									...item
								}));

							const combinedListings = [
								...availableForRetrainingList,
								...eligibleForResetList,
								...horseResetExpress
							];

							setPlacementsListed(
								availableForRetrainingList.filter((x) => !x.removeReason.id)
							);
							setListingsData(combinedListings);
							setIsLoading(false);
						}
					}
				);
			} else if (isRetrainer) {
				let listingsForDisplay = [];
				AppSyncService.execute(getInterestedHorseListings, {
					memberExternalId: membersExternalId
				}).then((result) => {
					if (result?.data?.getInterestedHorseListings?.length > 0) {
						listingsForDisplay = listingsForDisplay
							.concat(result.data.getInterestedHorseListings)
							.filter((record) =>
								AVAILABLE_FOR_RETRAINING_LISTINGS.includes(
									record.horseOTTStatusID
								)
							)
							.map((item) => ({
								horseStatusSubtype: HorseStatusType.AvailableForRetraining,
								...item
							}));
					}
					AppSyncService.execute(getAvailableHorsePlacement).then((result) => {
						if (result?.data?.getAvailableHorsePlacement?.length > 0) {
							listingsForDisplay = listingsForDisplay
								.concat(result.data.getAvailableHorsePlacement)
								.filter((record) =>
									AVAILABLE_FOR_RETRAINING_LISTINGS.includes(
										record.horseOTTStatusID
									)
								)
								.map((item) => ({
									horseStatusSubtype: HorseStatusType.AvailableForRetraining,
									...item
								}));
						}

						AppSyncService.execute(getRetrainerHorsePlacementListings, {
							memberExternalId: membersExternalId
						}).then((result) => {
							let eligibleForResetList = [];
							let horseResetExpress = [];
							if (
								result?.data?.getRetrainerHorsePlacementListings?.length > 0
							) {
								let fullList = result.data.getRetrainerHorsePlacementListings;
								eligibleForResetList = fullList
									.filter((record) =>
										RESET_ELIGIBLE_LISTINGS.includes(record.horseOTTStatusID)
									)
									.map((item) => ({
										horseStatusSubtype: HorseStatusType.EligibleForReset,
										...item
									}));
								horseResetExpress = fullList
									.filter((record) =>
										RESET_HORSE_EXPRESS.includes(record.horseOTTStatusID)
									)
									.map((item) => ({
										isAssigned: true,
										horseStatusSubtype: HorseStatusType.ResetExpressHorses,
										...item
									}));
							}

							if (isRetrainerWithResetExpress) {
								let resetExpressHorsesOnly = [];
								AppSyncService.execute(getResetExpressHorsePlacement).then(
									(result) => {
										if (
											result?.data?.getResetExpressHorsePlacement?.length > 0
										) {
											resetExpressHorsesOnly = resetExpressHorsesOnly
												.concat(result.data.getResetExpressHorsePlacement)
												.map((item) => ({
													horseStatusSubtype:
														HorseStatusType.ResetExpressHorses,
													...item
												}));

											let combinedListing = [
												...listingsForDisplay,
												...eligibleForResetList,
												...horseResetExpress,
												...resetExpressHorsesOnly
											];
											setPlacementsListed(listingsForDisplay);
											setListingsData(combinedListing);
											setIsLoading(false);
										}
									}
								);
							} else {
								let combinedListing = [
									...listingsForDisplay,
									...eligibleForResetList,
									...horseResetExpress
								];
								setPlacementsListed(listingsForDisplay);
								setListingsData(combinedListing);
								setIsLoading(false);
							}
						});
					});
				});
			} else {
				AppSyncService.execute(getPublishedHorsePlacementListings, {
					memberExternalId: membersExternalId
				}).then((result) => {
					if (result?.data?.getHorsePlacementListings?.length > 0) {
						setListingsData(result.data.getHorsePlacementListings);
						setApprovedPlacementOwner(true);
					}
					setIsLoading(false);
				});
			}
		}
	}, [currentUser]);

	useEffect(() => {
		if (location?.state?.searchSelected) {
			setSelectedSearch(location.state.search);
		} else {
			setSelectedSearch(THOROUGHBRED);
		}
	}, [location?.state]);

	useEffect(() => {
		let menuList = [
			{
				id: SearchKeys.THOROUGHBRED,
				key: 1,
				menuItem: THOROUGHBRED,
				title: 'Thoroughbred',
				show: true
			},
			{
				id: SearchKeys.ACKNOWLEDGED_RETRAINERS,
				key: 2,
				menuItem: ACKNOWLEDGED_RETRAINER,
				title: 'Acknowledged Retrainers',
				show: true
			},
			{
				id: 3,
				menuItem: ACKNOWLEDGED_RETIREMENT_FARMS,
				title: 'Acknowledged Retirement Farms',
				show: true
			}
		];
		if (auth.currentUser) {
			menuList = [
				{
					id: SearchKeys.THOROUGHBRED,
					key: 1,
					menuItem: THOROUGHBRED,
					title: 'Thoroughbred',
					show: true
				},
				{
					id: SearchKeys.ACKNOWLEDGED_RETRAINERS,
					key: 2,
					menuItem: ACKNOWLEDGED_RETRAINER,
					title: 'Acknowledged Retrainers',
					show: true
				},
				{
					id: 3,
					menuItem: ACKNOWLEDGED_RETIREMENT_FARMS,
					title: 'Acknowledged Retirement Farms',
					show: true
				},
				{
					id: SearchKeys.ACKNOWLEDGED_FOSTER_PROVIDERS,
					menuItem: ACKNOWLEDGED_FOSTER_FARMS,
					title: 'Acknowledged STAR Providers',
					show:
						auth?.currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER) ||
						auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)
				},
				{
					id: SearchKeys.AVAILABLE_FOR_RETRAINING,
					key: 4,
					menuItem: AVAILABLE_FOR_RETRAINING,
					title: `Available for Retraining${
						listingsData ? ` (${placementsListed.length})` : ''
					}`,
					show:
						approvedPlacementOwner ||
						auth?.currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER) ||
						auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE)
				}
			];
		}
		setmenuList(menuList);
	}, [currentUser, approvedPlacementOwner, listingsData.length]);
	return (
		<>
			<div className={isSmallScreen ? classes.pageContainerMobile : {}}>
				{!isSmallScreen && (
					<HeaderBanner title="Search" styles={bannerStyles} />
				)}

				<ScrollMenu
					selectedSearch={selectedSearch}
					menuList={menuList}
					onClick={(s) => setSelectedSearch(s)}
				/>

				{selectedSearch === THOROUGHBRED && (
					<div>
						<SearchThoroughBred />
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_RETRAINER && (
					<div>
						<RetrainerSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === AVAILABLE_FOR_RETRAINING && (
					<div>
						<AvailableForRetraining
							isRetrainerOnly={isRetrainer && !isAdmin}
							listingsData={listingsData}
							isLoading={isLoading}
							isRetrainerWithResetExpress={isRetrainerWithResetExpress}
							isAdmin={isAdmin}
						/>
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_RETIREMENT_FARMS && (
					<div>
						<RetirementFarmSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === ACKNOWLEDGED_FOSTER_FARMS && (
					<div>
						<FosterProviderSearch isSmallScreen={isSmallScreen} />
					</div>
				)}
				{selectedSearch === EVENTS && <div></div>}
			</div>
		</>
	);
};

export default GlobalSearch;

import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	toggleHeader: {
		display: 'flex',
		padding: 6,
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		borderRadius: 50,
		color: '#006da6',
		fontSize: 14,
		width: 300,
		height: 30,
		alignItems: 'center',
		justifyContent: 'center',
		'@media (min-width:992px)': {
			display: 'inline',
			padding: 10,
			fontSize: 15
		}
	},
	toggleContainer: {
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		background: '#dcebf3',
		marginBottom: 20,
		padding: 4,
		borderRadius: 50,
		display: 'flex',
		alignItems: 'center',
		overflowX: 'scroll',
		whiteSpace: 'nowrap',
		'&::-webkit-scrollbar': {
			display: 'none'
		},
		'@media (min-width:992px)': {
			padding: 12,
			display: 'inline-block'
		}
	}
}));

import React from 'react';
import FullArticleEmbeddedAsset from '../../../FullArticleEmbeddedAsset';
import { Col } from 'react-grid-system';
import useStyles from './styles';

const MediaAsset = ({ title, type, src }) => {
	const classes = useStyles();
	return (
		<Col lg={4} sm={12}>
			<div className={classes.mediaAssetTitle}>{title}</div>
			<FullArticleEmbeddedAsset contentType={type} contentSrc={src} />
		</Col>
	);
};

export default MediaAsset;

import { createUseStyles } from 'react-jss';
import BannerImage from '../../../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	sectionContainer: {
		width: '100%'
	},
	headerContainer: {
		background: `url(${BannerImage})`,
		backgroundSize: 'contain',
		minWidth: '330px',
		width: '100%',
		boxSizing: 'border-box',
		height: 65,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontWeight: 800,
		color: '#464749',
		paddingLeft: 20,
		fontSize: 15,
		'@media (min-width:992px)': {
			fontSize: 20
		}
	},
	sectionContentContainer: {
		padding: '10px 20px 10px 20px',
		width: '100%',
		boxSizing: 'border-box',
		'& > div:first-child': {
			paddingTop: 0
		},
		'& > div:last-child': {
			paddingBottom: 0,
			borderBottom: 'none'
		}
	},
	darkContentBackground: {
		backgroundColor: '#f5f5f5'
	}
}));

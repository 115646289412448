import React from 'react';
import useStyles from './styles';
import { Row } from 'react-grid-system';
import PrimaryButton from '../../PrimaryButton';
import { CommonKeys } from './../../../utils/constants/index';

const FormFooter = ({
	onCancel,
	onSave,
	submitEnabled = true,
	validationErrorsExist = false,
	showSaveButton = true,
	cancelButtonText = 'Cancel',
	submitButtonText = 'Submit Form'
}) => {
	const classes = useStyles();
	return (
		<Row>
			<div className={classes.formFooterContainer}>
				<button
					id={CommonKeys.CANCEL_APPLICATION}
					onClick={onCancel}
					className={classes.additionalButtonStyles}
				>
					{cancelButtonText}
				</button>
				<div className={classes.submitButtonContainer}>
					{validationErrorsExist && (
						<span className={classes.validationErrorMessage}>
							Please complete all required fields
						</span>
					)}
					{showSaveButton && (
						<button
							id={CommonKeys.SAVE_DRAFT}
							onClick={onSave}
							className={classes.additionalButtonStyles}
						>
							Save Draft
						</button>
					)}
					<PrimaryButton
						id={CommonKeys.COMPLETE_FORM}
						style={{ width: '165px', marginLeft: 15, marginTop: 0 }}
						disabled={!submitEnabled}
					>
						{submitButtonText}
					</PrimaryButton>
				</div>
			</div>
		</Row>
	);
};

export default FormFooter;

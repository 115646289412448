import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from '@reach/router';
import SEO from '../seo';
import {
	StyledAccordionContainer,
	StyledAdminAccordionContainer,
	StyledContainer,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledHeaderBanner,
	StyledHorseDetailsContainer,
	StyledLoadingContainer,
	StyledRehomingAttemptDetailsRow,
	StyledActionsButtonRow,
	StyledActionsButtonCol,
	StyledEligibilityAssessmentDocsRow,
	StyledFormMainErrorContainer,
	StyledFieldLabelWithCharRemainingContainer,
	StyledFileFieldContainer,
	StyledWarningText,
	StyledFormSubmitButton
} from './style';
import schema from './schema';
import LoaderSpinner from '../LoaderSpinner';
import { useScreenClass } from 'react-grid-system';
import StyledAccordionWithBanner from '../Accordion/StyledAccordionWithBanner';
import { Col, Row } from 'react-grid-system';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import useFilePreview from '../../hooks/useFilePreview';
import FormTagsListField from '../FormTagsListField';
import FormTextField from '../FormTextField';
import { getOhepNominationReviewFormData } from './queries';
import AppSyncService from '../../graphql/AppSyncService';
import { useToast } from '../../hooks/useToast';
import {
	convertBlankOptionToNull,
	formatDate,
	formatDateReadableVariant,
	formatToggleValue,
	parseUTCToLocal
} from '../../utils/helpers';
import FormDropDown from '../FormDropDown';
import {
	APPROVED,
	REJECTED,
	REVIEW,
	WORD_DOC_MIME_TYPE
} from '../../utils/constants';
import Toggle from '../Toggle';
import { StyledErrorText, StyledFormTextArea } from '../OHEPForm/style';
import FormDatePicker from '../FormDatePicker';
import {
	HAS_INJURY_PREVENTED_CAREER_OPTIONS,
	OHEP_FORM_REVIEW_DEFAULT_VALUES
} from './constants';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FileUploadInputField from '../FormComponents/FileUploadInputField';
import Divider from '../Divider';
import { useAuth } from '../../hooks/useAuth';
import {
	generatePayload,
	setFormValuesFromApiResult,
	sortLookupArrayAlphabeticallyWithOtherAtEnd
} from './helper';
import {
	deleteOhepAdminFile,
	generateAdminReviewNotification,
	generateOhepAcceptanceNotice,
	saveOhepAdminData,
	saveOhepAdminFile,
	saveOhepNominationFormStatus,
	saveHorseProfileRetirement,
	saveOTTStatusAndProgram
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import FormMultiSelectList from '../FormMultiSelectList';
import RetirementCategory from '../RetirementCategory';
import { generateDeceasedInputPayload } from '../DeceasedThoroughbredForm/helper';
import {
	NOMINATION_REASONS,
	retirementReason
} from '../RetirementCategory/constants';

const OhepReviewForm = () => {
	const PAGE_ID = 'ohep-form-review';
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isFormError, setIsFormError] = useState(false);
	const [adminOptions, setAdminOptions] = useState([]);
	const [adminStatusOptions, setAdminStatusOptions] = useState([]);
	const [eligibilityReasonOptions, setEligibilityReasonOptions] = useState([]);
	const [primaryConcernOptions, setPrimaryConcernOptions] = useState([]);
	const hasInjuryPreventedCareerOptions = HAS_INJURY_PREVENTED_CAREER_OPTIONS;
	const [nominationCategoryOptions, setNominationCategoryOptions] = useState(
		[]
	);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [bodyPartOptions, setBodyPartOptions] = useState([]);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const screenClassForRender = useScreenClass();
	const { id: formId } = useParams();
	const filePreviewHandler = useFilePreview();
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const { addToast } = useToast();
	const {
		watch,
		setValue,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: OHEP_FORM_REVIEW_DEFAULT_VALUES
	});
	const values = watch();
	const isFormInReview = formData?.status === REVIEW;
	const isEwdRecommendationApprove = values.ewdRecommendation;
	const { currentUser } = useAuth();
	const [adminData, setAdminData] = useState();
	const [onValidate, setOnValidate] = useState(false);
	const [adminDataErrors, setAdminDataErrors] = useState({
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		}
	});

	useEffect(() => {
		if (onValidate) {
			evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) && adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) && adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;

		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const fetchData = async () => {
		try {
			const firstOption = { id: 0, value: null };
			const { data } = await AppSyncService.execute(
				getOhepNominationReviewFormData,
				{
					id: formId
				}
			);
			const {
				getOhepNominationFormById: { adminData }
			} = data;
			let splitOtherVicesRetirement = [];
			let mapBehaviourRetirement = [];
			if (adminData.behaviourOthersRetirement) {
				splitOtherVicesRetirement = adminData.behaviourOthersRetirement.split(
					','
				);
				mapBehaviourRetirement = splitOtherVicesRetirement.map(
					(otherVices) => ({
						label: otherVices,
						value: otherVices
					})
				);
			}

			const ohepData = data.getOhepNominationFormById;
			const isPrefillRetirementCategory = adminData.retirementCategory === null;
			const prefilledRetirementCategory = 4;
			let prefilledReasons = [];
			if (isPrefillRetirementCategory) {
				ohepData?.reasonsForNomination?.forEach((reason) => {
					if (reason.id === NOMINATION_REASONS.DANGEROUS_BEHAVIOUR)
						prefilledReasons.push({
							value: retirementReason[1].id,
							label: retirementReason[1].value
						});
					else if (reason.id === NOMINATION_REASONS.CHRONIC_INJURY_OR_ILLNESS) {
						prefilledReasons.push({
							value: retirementReason[4].id,
							label: retirementReason[4].value
						});
						prefilledReasons.push({
							value: retirementReason[5].id,
							label: retirementReason[5].value
						});
					} else if (reason.id === NOMINATION_REASONS.REHOMING_UNSUCCESSFUL) {
						prefilledReasons.push({
							value: retirementReason[6].id,
							label: retirementReason[6].value
						});
					}
				});
			}
			setAdminData({
				//Retirement
				selectedCategory: isPrefillRetirementCategory
					? prefilledRetirementCategory
					: adminData.retirementCategory
					? adminData.retirementCategory
					: 0,
				selectedVulnerabilityType: adminData.vulnerabilityType
					? adminData.vulnerabilityType
					: 0,
				selectedReason: isPrefillRetirementCategory
					? prefilledReasons
					: adminData.reasonRetirement?.length > 0
					? adminData.reasonRetirement.map((reason) => {
							return { value: reason.id, label: reason.value };
					  })
					: [],
				selectedVicesRetirement:
					adminData.habitsRetirement?.length > 0
						? adminData.habitsRetirement.map((selectedHabitsRetirement) => {
								return {
									value: selectedHabitsRetirement.id,
									label: selectedHabitsRetirement.value
								};
						  })
						: [],
				otherVicesRetirement: adminData.behaviourOthersRetirement
					? mapBehaviourRetirement
					: '',
				conformationalDefectsRetirement: adminData.conformationalDetailsRetirement
					? adminData.conformationalDetailsRetirement
					: '',
				selectedEducation:
					adminData.education?.length > 0
						? adminData.education.map((selectedEducation) => {
								return {
									value: selectedEducation.id,
									label: selectedEducation.value
								};
						  })
						: [],
				otherEducationDetails: adminData.otherEducationDetails
					? adminData.otherEducationDetails
					: '',
				selectedInjuriesRetirement:
					adminData.injuriesRetirement?.length > 0
						? adminData.injuriesRetirement.map((injuriesRetirement) => {
								return {
									value: injuriesRetirement.id,
									label: injuriesRetirement.value
								};
						  })
						: [],
				selectedIllnessRetirement:
					adminData.illnessRetirement?.length > 0
						? adminData.illnessRetirement.map((illnessRetirement) => {
								return {
									value: illnessRetirement.id,
									label: illnessRetirement.value
								};
						  })
						: [],
				otherInjuryDetails: adminData.otherInjuryDetailsRetirement
					? adminData.otherInjuryDetailsRetirement
					: '',
				otherIllnessDetails: adminData.otherIllnessDetailsRetirement
					? adminData.otherIllnessDetailsRetirement
					: '',
				rehomingHistoryDetails: adminData.rehomingHistoryDetails
					? adminData.rehomingHistoryDetails
					: '',
				otherDetailsRetirement: adminData.otherDetailsRetirement
					? adminData.otherDetailsRetirement
					: ''
			});

			if (isPrefillRetirementCategory) setOnValidate(true);

			setFormData(data.getOhepNominationFormById);
			const localAdminOptions = data.getAdminMembers.map((item) => ({
				id: item.externalId,
				value: `${item.firstName} ${item.lastName}`
			}));
			setAdminOptions([firstOption, ...localAdminOptions]);
			setAdminStatusOptions([firstOption, ...data.listOhepAdminStatuses]);
			setEligibilityReasonOptions([
				firstOption,
				...data.listOhepEligibilityReasons
			]);
			setPrimaryConcernOptions([firstOption, ...data.listOhepPrimaryConcerns]);
			setNominationCategoryOptions([
				firstOption,
				...data.listOhepNominationCategories
			]);
			setInjuryOptions([
				firstOption,
				...sortLookupArrayAlphabeticallyWithOtherAtEnd(data.listInjuryDetails)
			]);
			setIllnessOptions([
				firstOption,
				...sortLookupArrayAlphabeticallyWithOtherAtEnd(data.listIllnessDetails)
			]);
			setBodyPartOptions(
				sortLookupArrayAlphabeticallyWithOtherAtEnd(data.listOhepBodyParts).map(
					(item) => ({
						...item,
						label: item.value
					})
				)
			);
			if (
				data.getOhepNominationFormById?.adminData?.adminAction?.admin
					?.externalId ||
				data.getOhepNominationFormById?.adminData?.vetCertOrOtherFiles?.length >
					0 ||
				data.getOhepNominationFormById?.adminData?.xRayFiles?.length > 0 ||
				data.getOhepNominationFormById?.adminData?.brandImages?.length > 0
			) {
				setFormValuesFromApiResult(
					data.getOhepNominationFormById.adminData,
					setValue
				);
			} else if (!values.adminExternalId) {
				setValue('adminExternalId', currentUser.UserId);
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			addToast({
				Message: 'Failed to fetch for the given id',
				IsSuccess: false
			});
		}
	};

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setAdminData(items);
		setOnValidate(true);
	};

	const handleChange = (e) => {
		setValue(e.target.name, e.target.value);
	};

	const handleChangeKeyValuePair = (name, value) => {
		setValue(name, value);
		if (name === 'illness' && +value !== 7) {
			setValue('illnessOther', null);
		} else if (name === 'injury' && value !== 12) {
			setValue('injuryOther', null);
		} else if (name === 'eligibilityReason') {
			if (+value !== 4) {
				setValue('injury', null);
				setValue('illness', null);
				setValue('injuryOther', null);
				setValue('illnessOther', null);
			} else if (+value !== 2) {
				setValue('rehomingAttemptsSatisfactory', null);
				setValue('rehomingAttemptsComments', null);
			}
			if (+value === 2) {
				setValue(
					'rehomingAttemptsSatisfactory',
					OHEP_FORM_REVIEW_DEFAULT_VALUES.rehomingAttemptsSatisfactory
				);
			}
		} else if (
			name === 'bodyParts' &&
			!value.find(({ label }) => label === 'Other')
		) {
			setValue('bodyPartOther', null);
		} else if (name === 'primaryConcern' && +value !== 4) {
			setValue('primaryConcernOther', null);
		} else if (name === 'primaryConcern' && +value !== 3) {
			setValue('hasInjuryIdentified', false);
			setValue('dateInjuryReported', null);
			setValue('hasInjuryPreventedCareer', null);
		} else if (name === 'primaryConcern' && +value === 3) {
			setValue('hasInjuryIdentified', true);
		} else if (name === 'addEwgmSignatureOnAcceptanceNotice' && !value) {
			setValue('automaticallySendAcceptanceNoticeToOwner', false);
		} else if (name === 'ewgmApprovalToProceed' && !value) {
			setValue('ewgmApprovalToProceedDate', null);
			setValue('automaticallySendAcceptanceNoticeToOwner', false);
			setValue('addEwgmSignatureOnAcceptanceNotice', false);
		}
	};

	const handleTextFieldChange = (e) => {
		const { name, value } = e.target;
		if (value.length <= 500) {
			setValue(name, value);
		}
	};

	const handleDateChange = (name, value) => {
		const formattedDate = formatDate(value);
		if (formattedDate !== '1970-01-01') {
			setValue(name, formattedDate);
		} else {
			setValue(name, null);
		}
	};

	const handleUploadFile = async (file, key, fieldName, uploadType) => {
		try {
			const { data } = await AppSyncService.execute(saveOhepAdminFile, {
				filename: file.name,
				key,
				type: uploadType,
				ohepNominationId: +formId,
				memberExternalId: currentUser.UserId
			});
			if (!data.saveOhepAdminFile.success) {
				throw new Error('Error uploading file');
			}
			setValue(fieldName, [...values[fieldName], { filename: file.name, key }]);
			addToast({
				Message: 'File uploaded',
				IsSuccess: true
			});
		} catch (error) {
			addToast({
				Message: 'Failed to upload file',
				IsSuccess: false
			});
		}
	};

	const handleRemoveFile = async (file, fieldName) => {
		try {
			const { data } = await AppSyncService.execute(deleteOhepAdminFile, {
				key: file.key
			});
			if (!data.deleteOhepAdminFile) {
				throw new Error('Error removing file');
			}
			setValue(
				fieldName,
				values[fieldName].filter((val) => val.key !== file.key)
			);
			addToast({ Message: 'File removed', IsSuccess: true });
		} catch (error) {
			addToast({
				Message: 'Failed to remove file',
				IsSuccess: false
			});
		}
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const updateAdminDataAndActions = async () => {
		const deceasedInput = generateDeceasedInputPayload(adminData);
		const { data } = await AppSyncService.execute(saveOhepAdminData, {
			input: { ...generatePayload(values, formId), ...deceasedInput },
			adminExternalId: values.adminExternalId,
			ohepNominationId: +formId,
			adminStatusId: convertBlankOptionToNull(values.adminStatus),
			formComplete: values.formComplete
		});
		if (
			data?.saveOhepAdminForm?.error?.errorMessage ||
			data?.saveOhepAdminAction?.error?.errorMessage
		) {
			throw new Error('Failed to Updated OHEP admin data and actions');
		}
	};

	const handleSaveAdminDetails = async () => {
		try {
			setIsLoading(true);
			if (!values.adminExternalId || +values.adminExternalId === 0) {
				setIsFormError(true);
				setError('adminExternalId', {
					type: 'manual',
					message: 'Actioning is a required field'
				});
				return;
			}
			clearErrors('adminExternalId');
			setIsFormError(false);
			await updateAdminDataAndActions();
			if (+values.adminStatus === 3) {
				sendAdminEmail();
			}
			addToast({ Message: 'Saved Admin updates', IsSuccess: true });
			navigate(`/review-list`);
		} catch (error) {
			addToast({ Message: 'Unable to Save Form', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const sendAdminEmail = () => {
		AppSyncService.execute(generateAdminReviewNotification, {
			formId: +formId,
			formType: 'OHEPNominationForm'
		});
	};

	const handleSubmitError = () => {
		setIsFormError(true);
	};

	const handleApproveSubmitSuccess = async () => {
		try {
			setIsFormError(false);
			setIsLoading(true);
			await updateAdminDataAndActions();
			const { data } = await AppSyncService.execute(
				saveOhepNominationFormStatus,
				{
					id: +formId,
					status: APPROVED
				}
			);
			const horseProfilePayload = generateDeceasedInputPayload(adminData);
			const horseProfileRetirementData = await AppSyncService.execute(
				saveHorseProfileRetirement,
				{
					input: {
						...horseProfilePayload,
						memberId: formData?.member?.externalId,
						horseCode: formData?.horse?.horseCode
					}
				}
			);
			if (
				!data?.saveOhepNominationFormStatus?.success &&
				!horseProfileRetirementData?.data?.saveHorseProfileRetirement?.success
			) {
				throw new Error('Failed to submit form, server error');
			}
			await AppSyncService.execute(generateOhepAcceptanceNotice, {
				ohepNominationId: +formId,
				addEwgmSignatureOnAcceptanceNotice:
					values.addEwgmSignatureOnAcceptanceNotice,
				automaticallySendAcceptanceNoticeToOwner:
					values.automaticallySendAcceptanceNoticeToOwner
			});
			addToast({ Message: 'Form Approved', IsSuccess: true });
			navigate(`/review-list`);
		} catch (error) {
			addToast({ Message: 'Unable to Approve Form', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleApprove = (e) => {
		e.preventDefault();
		handleSubmit(handleApproveSubmitSuccess, handleSubmitError)(e);
	};

	const handleRejectSubmitSuccess = async () => {
		try {
			setIsFormError(false);
			setIsLoading(true);
			await updateAdminDataAndActions();
			const { data } = await AppSyncService.execute(
				saveOhepNominationFormStatus,
				{
					id: +formId,
					status: REJECTED
				}
			);
			if (!data?.saveOhepNominationFormStatus?.success) {
				throw new Error('Failed to submit form, server error');
			} else {
				const payload = {
					asOfDate: new Date(),
					horseCode: formData?.horse?.horseCode,
					programId: 6,
					statusId: 22,
					memberExternalId: currentUser.UserId
				};
				await AppSyncService.execute(saveOTTStatusAndProgram, payload);
			}
			addToast({ Message: 'Form Rejected', IsSuccess: true });
			navigate(`/review-list`);
		} catch (error) {
			console.log(error);
			addToast({ Message: 'Unable to Reject Form', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleReject = (e) => {
		e.preventDefault();
		handleSubmit(handleRejectSubmitSuccess, handleSubmitError)(e);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Fragment>
			<SEO title="OHEP Form Review" />
			<StyledLoadingContainer>
				<LoaderSpinner status={isLoading} />
			</StyledLoadingContainer>
			{!isLoading && (
				<StyledContainer>
					<StyledHeaderBanner
						title={`OHEP Form Review - ${formData?.horse?.name} `}
						top
						type="primary"
						mobile={['xs', 'sm'].includes(screenClassForRender)}
						rightTitle={`Status: ${formData?.status}`}
						onBehalfText={
							formData?.behalfAdmin?.firstName &&
							formData?.behalfAdmin?.lastName ? (
								<span style={{ fontSize: 13, color: '#006da6' }}>
									(submitted on-behalf of user by{' '}
									{`${formData?.behalfAdmin?.firstName} ${formData?.behalfAdmin?.lastName}`}
									)
								</span>
							) : null
						}
						dateText={
							formData?.lastUpdated ? (
								<span style={{ fontSize: 13 }}>
									Date Submitted:{' '}
									{parseUTCToLocal(formData.lastUpdated).format('DD/MM/YYYY')}
								</span>
							) : null
						}
					/>
					<StyledAccordionWithBanner title="Horse Details" isOpen>
						<StyledHorseDetailsContainer>
							<StyledAccordionContainer>
								<Row>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Thoroughbred Name"
											value={formData?.horse?.name}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField title="Sex" value={formData?.horse?.sex} />
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Country of Birth"
											value={formData?.horse?.country}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Dam Name"
											value={formData?.horse?.damName}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Sire Name"
											value={formData?.horse?.sireName}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Foal Date"
											value={formatDateReadableVariant(
												formData?.horse?.foalDate
											)}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Microchip Number"
											value={formData?.horse?.microChipNumber}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Brand Description"
											value={formData?.horse?.horseBrand}
										/>
									</Col>
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Marking Description"
											value={formData?.horse?.horseMarkings}
										/>
									</Col>
								</Row>
							</StyledAccordionContainer>
						</StyledHorseDetailsContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Contact Details" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Name"
										value={
											formData.member
												? `${formData.member?.firstName} ${formData.member?.lastName}`
												: ''
										}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Email"
										value={formData?.member?.email}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Number"
										value={formData?.member?.phone}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Address"
										value={formData?.member?.residentialAddress?.address}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Contact Suburb</StyledFieldLabel>
										<FormTextField
											canEdit={false}
											value={formData?.member.residentialAddress?.suburb}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Contact State</StyledFieldLabel>
										<FormTextField
											canEdit={false}
											value={formData?.member.residentialAddress?.state}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Contact Postcode</StyledFieldLabel>
										<FormTextField
											canEdit={false}
											value={formData?.member.residentialAddress?.postcode}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Contact Country</StyledFieldLabel>
										<FormTextField
											canEdit={false}
											value={formData?.member.residentialAddress?.country}
										/>
									</StyledFieldContainer>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Relationship to this Horse"
										value={formData?.relationshipToHorse?.value}
									/>
								</Col>
								{formData?.relationshipToHorse?.value === 'Other' && (
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Other relationship to this horse"
											value={formData?.relationshipToHorseOther}
										/>
									</Col>
								)}
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Thoroughbred Photos" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Left Side Brand Photos</StyledFieldLabel>
										<UploadPhoto
											data={formData?.leftSidePhotos}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Right Side Brand Photos</StyledFieldLabel>
										<UploadPhoto
											data={formData?.rightSidePhotos}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Address of Current Location of Nominee Horse"
						isOpen
					>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Address"
										value={formData?.currentAddress}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Suburb"
										value={formData?.currentSuburb}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred State"
										value={formData?.currentState}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Postcode"
										value={formData?.currentPostcode}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Country"
										value={formData?.currentCountry}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={5}>
									<FormLabelField
										title="Time horse has been at the above location?"
										value={`${formData.currentAddressDurationYears} year${
											formData.currentAddressDurationYears > 1 ? 's' : ''
										} and ${formData.currentAddressDurationMonths} month${
											formData.currentAddressDurationYears > 1 ? 's' : ''
										}`}
									/>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Address of Previous Location of Nominee Horse"
						isOpen
					>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Address"
										value={formData?.previousAddress}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Suburb"
										value={formData?.previousSuburb}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred State"
										value={formData?.previousState}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Postcode"
										value={formData?.previousPostcode}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Country"
										value={formData?.previousCountry}
									/>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Reason for Nomination for Euthanasia"
						isOpen
					>
						<StyledAccordionContainer>
							<FormTagsListField title="Reasons for Nomination for Euthanasia">
								{formData?.reasonsForNomination?.map((reasonForNomination) => (
									<span key={reasonForNomination.id}>
										<strong>{reasonForNomination.label}</strong>.{' '}
										{reasonForNomination.value}
									</span>
								))}
							</FormTagsListField>
							{formData.dangerousBehaviourDetails && (
								<FormLabelField
									title="Dangerous Behaviour Details"
									value={formData?.dangerousBehaviourDetails}
								/>
							)}
							{formData.rehomingAttemptDetails && (
								<StyledFieldContainer>
									<StyledFieldLabel>Rehoming Attempt Details</StyledFieldLabel>
									<StyledRehomingAttemptDetailsRow>
										<Col xs={0.5} lg={0.5}>
											<span>1.</span>
										</Col>
										<Col xs={10} lg={8}>
											<FormTextField
												value={formData.rehomingAttemptDetails}
												canEdit={false}
											/>
										</Col>
										<Col xs={12} lg={3}>
											<Row>
												<Col lg={3}>
													<StyledFieldLabel>Date:</StyledFieldLabel>
												</Col>
												<Col lg={9}>
													<StyledFieldLabel style={{ fontWeight: 400 }}>
														{formatDateReadableVariant(
															formData.rehomingAttemptDetailsDate
														)}
													</StyledFieldLabel>
												</Col>
											</Row>
										</Col>
									</StyledRehomingAttemptDetailsRow>
									<StyledRehomingAttemptDetailsRow>
										<Col xs={0.5} lg={0.5}>
											2.
										</Col>
										<Col xs={10} lg={8}>
											<StyledFieldLabel>
												Advertised via RV EW Delegate for 14 days
											</StyledFieldLabel>
										</Col>
										<Col xs={12} lg={3}>
											<Row>
												<Col lg={3}>
													<StyledFieldLabel>Date:</StyledFieldLabel>
												</Col>
												<Col lg={9}>
													<StyledFieldLabel style={{ fontWeight: 400 }}>
														{formatDateReadableVariant(
															formData.advertisedToRvDate
														)}
													</StyledFieldLabel>
												</Col>
											</Row>
										</Col>
									</StyledRehomingAttemptDetailsRow>
								</StyledFieldContainer>
							)}
							{formData.thoroughbredOverTwentyDetails && (
								<FormLabelField
									title="Thoroughbred Over 20 years further details"
									value={formData?.thoroughbredOverTwentyDetails}
								/>
							)}
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Supporting Documentation" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Veterinary Certificate</StyledFieldLabel>
										<UploadPhoto
											data={formData?.veterinaryCertificateFiles}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>RSPCA Notice to Comply</StyledFieldLabel>
										<UploadPhoto
											data={formData?.rspcaNoticeToComplyFiles}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											Evidence of Dangerous Behaviour
										</StyledFieldLabel>
										<UploadPhoto
											data={formData?.evidenceOfDangerousBehaviourFiles}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Other Documents</StyledFieldLabel>
										<UploadPhoto
											data={formData?.otherFiles}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Acknowledgement" isOpen>
						<StyledAccordionContainer>
							<FormLabelField
								title="I acknowledge that I have read and understood the Privacy Statement set out below, and consent to the collection, use and disclosure of my personal information in accordance with that Privacy Policy)*."
								value={formatToggleValue(formData?.acknowledgedPrivacy)}
							/>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Consent to Participate" isOpen>
						<StyledAccordionContainer>
							<FormLabelField
								title="By signing this Nomination Form, I consent to the Nominee Horse's participation in the OHEP and euthanasia to be performed by an Australian-registered veterinarian of my choice (provided that veterinarian has signed up as a Service Provider) or RV-nominated and approved service provider (Service Provider)*."
								value={formatToggleValue(
									formData?.acknowledgedConsentToParticipate
								)}
							/>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Ownership Declaration and Warranty"
						isOpen
					>
						<StyledAccordionContainer>
							<StyledFieldContainer>
								<StyledFieldLabel>
									<p>I represent and warrant that*:</p>
									<ul>
										<li>
											I am the sole legal and beneficial owner of the Nominee
											Horse, or I have authority on behalf of all other legal
											and beneficial owners of the Nominee Horse to apply to
											have the Nominee Horse euthanised and to sign this
											Nomination Form;
										</li>
										<li>
											to the extent necessary, I have obtained all necessary
											consents to the euthanasia of the Nominee Horse;
										</li>
										<li>
											the Nominee Horse has been domiciled in Victoria for the 6
											months immediately prior to the date of this Nomination
											Form;
										</li>
										<li>
											the Nominee Horse satisfies the eligibility criteria set
											out in the OHEP Owner&apos;s Manual;
										</li>
										<li>
											I have read and understood the OHEP Owner&apos;s Manual;
											and the details set out in this Nomination Form are true
											and correct
										</li>
									</ul>
								</StyledFieldLabel>
								<p>
									{formatToggleValue(formData?.acknowledgedOwnershipWarranty)}
								</p>
							</StyledFieldContainer>
							<StyledFieldContainer>
								<StyledFieldLabel>
									<p>
										I acknowledge and agree that, if the Nominee Horse is
										accepted into the OHEP*:
									</p>
									<ul>
										<li>
											I will be subject to the terms of the OHEP Owner&apos;s
											Manual;
										</li>
										<li>
											I will be responsible for engaging the Approved Provider
											to perform the euthanasia and removal and disposal
											services (Services), including satisfying myself as to the
											suitability, experience and qualifications of the Approved
											Provider to perform those Services, and entering into
											binding arrangements directly with the Approved Provider
										</li>
									</ul>
								</StyledFieldLabel>
								<p>
									{formatToggleValue(formData?.acknowledgedOwnershipBinding)}
								</p>
							</StyledFieldContainer>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Owner Indemnity" isOpen>
						<StyledAccordionContainer>
							<FormLabelField
								title="
								I indemnify Racing Victoria Limited and the Approved Provider on demand against any claim which may be made by me, or any other person, for any loss or damage arising as a result of the euthanasia of the Nominee Horse*."
								value={formatToggleValue(formData?.acknowledgedOwnerIndemnity)}
							/>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Owner Confirmation" isOpen>
						<StyledAccordionContainer>
							<StyledFieldContainer>
								<StyledFieldLabel>
									<p>I, the applicant hereby:*</p>
									<ul>
										<li>
											Declare that all details in my application are true and
											correct
										</li>
										<li>
											Acknowledge that Racing Victoria may request further
											information from me in respect of this application;
										</li>
										<li>
											Consent to Racing Victoria obtaining personal information
											about me from others and using & disclosing my personal
											information as noted in the attached Privacy Policy .
										</li>
									</ul>
								</StyledFieldLabel>
								<p>
									{formatToggleValue(formData?.acknowledgedOwnerConfirmation)}
								</p>
							</StyledFieldContainer>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Statutory Declaration" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>Statutory Declaration</StyledFieldLabel>
										<UploadPhoto
											data={formData?.statuaryDeclarationFiles}
											getImage={filePreviewHandler}
											canEdit={false}
										/>
									</StyledFieldContainer>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Admin" isOpen>
						<StyledAdminAccordionContainer>
							<Row>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Actioning*</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="adminExternalId"
											selectedId={values.adminExternalId}
											onChange={handleChange}
											items={adminOptions}
											isError={errors.adminExternalId?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.adminExternalId?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Admin Status</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="adminStatus"
											selectedId={values.adminStatus}
											onChange={handleChange}
											items={adminStatusOptions}
											isError={false}
											disabled={!isFormInReview}
										/>
									</StyledFieldContainer>
								</Col>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											Application Form Complete?
										</StyledFieldLabel>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											disabled={!isFormInReview}
											onClick={(v) =>
												handleChangeKeyValuePair('formComplete', v)
											}
											value={values.formComplete}
										/>
									</StyledFieldContainer>
								</Col>
							</Row>
							<Divider color="#e3e3e3" />
							<Row>
								<Col xs={12}>
									<RetirementCategory
										isReadOnly={!isFormInReview}
										adminData={adminData}
										adminDataErrors={adminDataErrors}
										handleMultiFieldChange={handleMultiFieldChange}
									/>
								</Col>
							</Row>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Eligibility Assessment - Initial"
						isOpen
					>
						<StyledAdminAccordionContainer>
							<Row>
								<Col lg={6} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>EWD Recommendation</StyledFieldLabel>
										<Toggle
											labelNegative="Reject"
											labelPositive="Approve"
											disabled={!isFormInReview}
											onClick={(v) =>
												handleChangeKeyValuePair('ewdRecommendation', v)
											}
											value={values.ewdRecommendation}
										/>
									</StyledFieldContainer>
								</Col>
								<Col lg={6} xs={12} style={{ paddingRight: 0 }}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											EWD Recommendation Date
											{isEwdRecommendationApprove ? '*' : ''}
										</StyledFieldLabel>
										<FormDatePicker
											DateValue={
												!isFormInReview
													? formatDateReadableVariant(
															values.ewdRecommendationDate
													  )
													: values.ewdRecommendationDate
											}
											updateSelection={(value) =>
												handleDateChange('ewdRecommendationDate', value)
											}
											canEdit={isFormInReview}
											minDate={null}
											isError={errors.ewdRecommendationDate?.message}
										/>
										<StyledErrorText>
											{errors.ewdRecommendationDate?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabelWithCharRemainingContainer>
											<StyledFieldLabel>EWD Comments</StyledFieldLabel>
											<span className="characters-remaining">
												{500 - (values.ewdComments?.length || 0)} characters
												remaining
											</span>
										</StyledFieldLabelWithCharRemainingContainer>
										<StyledFormTextArea
											name="ewdComments"
											value={values.ewdComments}
											onChange={handleTextFieldChange}
											isError={errors?.ewdComments?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.ewdComments?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								<Col lg={6} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											Eligibility Reason{isEwdRecommendationApprove ? '*' : ''}
										</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="eligibilityReason"
											selectedId={values.eligibilityReason}
											onChange={(e) =>
												handleChangeKeyValuePair(
													'eligibilityReason',
													e.target.value
												)
											}
											items={eligibilityReasonOptions}
											isError={errors.eligibilityReason?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.eligibilityReason?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							</Row>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner
						title="Eligibility Assessment - Progressing"
						isOpen
					>
						<StyledAdminAccordionContainer>
							<Row>
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Primary Concern</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="primaryConcern"
											selectedId={values.primaryConcern}
											onChange={handleChange}
											items={primaryConcernOptions}
											isError={false}
											disabled={!isFormInReview}
										/>
									</StyledFieldContainer>
								</Col>
								{+values.primaryConcern === 4 && (
									<Col xs={12}>
										<StyledFieldContainer>
											<StyledFieldLabelWithCharRemainingContainer>
												<StyledFieldLabel>
													Other Primary Concern
													{values.ewdRecommendation &&
													+values.primaryConcern == 4
														? '*'
														: ''}
												</StyledFieldLabel>
												<span className="characters-remaining">
													{500 - (values.primaryConcernOther?.length || 0)}{' '}
													characters remaining
												</span>
											</StyledFieldLabelWithCharRemainingContainer>
											<StyledFormTextArea
												name="primaryConcernOther"
												value={values.primaryConcernOther}
												onChange={handleTextFieldChange}
												isError={errors?.primaryConcernOther?.message}
												disabled={!isFormInReview}
											/>
											<StyledErrorText>
												{errors.primaryConcernOther?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								)}
								{+values.primaryConcern === 3 && (
									<>
										<Col xs={12} lg={6}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Has this injury been identified on SNS?
												</StyledFieldLabel>
												<Toggle
													labelNegative="No"
													labelPositive="Yes"
													disabled={!isFormInReview}
													onClick={(v) =>
														handleChangeKeyValuePair('hasInjuryIdentified', v)
													}
													value={values.hasInjuryIdentified}
												/>
											</StyledFieldContainer>
										</Col>
										<Col xs={12}></Col>
										{values.hasInjuryIdentified && (
											<>
												<Col xs={12} lg={6} style={{ paddingRight: 0 }}>
													<StyledFieldContainer>
														<StyledFieldLabel>
															Select date injury reported on SNS*
														</StyledFieldLabel>
														<FormDatePicker
															DateValue={values.dateInjuryReported}
															updateSelection={(value) =>
																handleDateChange('dateInjuryReported', value)
															}
															minDate={null}
															isError={errors.dateInjuryReported?.message}
															canEdit={isFormInReview}
														/>
														<StyledErrorText>
															{errors.dateInjuryReported?.message}
														</StyledErrorText>
													</StyledFieldContainer>
												</Col>
												<Col xs={12}></Col>
											</>
										)}
										<Col xs={12} lg={6} style={{ paddingTop: 0 }}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Has this injury prevented a successful post racing
													ridden career?*
												</StyledFieldLabel>
												<FormDropDown
													showBlank
													name="hasInjuryPreventedCareer"
													selectedId={values.hasInjuryPreventedCareer}
													onChange={handleChange}
													items={hasInjuryPreventedCareerOptions}
													isError={false}
													disabled={!isFormInReview}
												/>
												<StyledErrorText>
													{errors.hasInjuryPreventedCareer?.message}
												</StyledErrorText>
											</StyledFieldContainer>
										</Col>
									</>
								)}
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Nomination Category</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="nominationCategory"
											selectedId={values.nominationCategory}
											onChange={handleChange}
											items={nominationCategoryOptions}
											isError={false}
											disabled={!isFormInReview}
										/>
									</StyledFieldContainer>
								</Col>
								{+values.eligibilityReason === 4 && (
									<Fragment>
										<Col xs={12}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Illness noted as concern
												</StyledFieldLabel>
												<FormDropDown
													showBlank
													name="illness"
													selectedId={values.illness}
													onChange={(e) =>
														handleChangeKeyValuePair(
															e.target.name,
															e.target.value
														)
													}
													items={illnessOptions}
													isError={false}
													disabled={!isFormInReview}
												/>
											</StyledFieldContainer>
										</Col>
										{+values.illness === 7 && (
											<Col xs={12}>
												<StyledFieldContainer>
													<StyledFieldLabelWithCharRemainingContainer>
														<StyledFieldLabel>
															Other illnesses noted as a concern
															{values.ewdRecommendation && +values.illness == 7
																? '*'
																: ''}
														</StyledFieldLabel>
														<span className="characters-remaining">
															{500 - (values.illnessOther?.length || 0)}{' '}
															characters remaining
														</span>
													</StyledFieldLabelWithCharRemainingContainer>
													<StyledFormTextArea
														name="illnessOther"
														value={values.illnessOther}
														onChange={handleTextFieldChange}
														isError={errors?.illnessOther?.message}
														disabled={!isFormInReview}
													/>
													<StyledErrorText>
														{errors.illnessOther?.message}
													</StyledErrorText>
												</StyledFieldContainer>
											</Col>
										)}
									</Fragment>
								)}
								{+values.eligibilityReason === 4 && (
									<Fragment>
										<Col xs={12}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Injury noted as concern
												</StyledFieldLabel>
												<FormDropDown
													showBlank
													name="injury"
													selectedId={values.injury}
													onChange={(e) =>
														handleChangeKeyValuePair(
															e.target.name,
															e.target.value
														)
													}
													items={injuryOptions}
													isError={false}
													disabled={!isFormInReview}
												/>
											</StyledFieldContainer>
										</Col>
										{+values.injury === 12 && (
											<Col xs={12}>
												<StyledFieldContainer>
													<StyledFieldLabelWithCharRemainingContainer>
														<StyledFieldLabel>
															Other injuries noted as a concern
															{values.ewdRecommendation && +values.injury == 12
																? '*'
																: ''}
														</StyledFieldLabel>
														<span className="characters-remaining">
															{500 - (values.injuryOther?.length || 0)}{' '}
															characters remaining
														</span>
													</StyledFieldLabelWithCharRemainingContainer>
													<StyledFormTextArea
														name="injuryOther"
														value={values.injuryOther}
														onChange={handleTextFieldChange}
														isError={errors?.injuryOther?.message}
														disabled={!isFormInReview}
													/>
													<StyledErrorText>
														{errors.injuryOther?.message}
													</StyledErrorText>
												</StyledFieldContainer>
											</Col>
										)}
									</Fragment>
								)}
								{+values.eligibilityReason === 2 && (
									<Fragment>
										<Col xs={12}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Rehoming Attempts Satisfactory?
												</StyledFieldLabel>
												<Toggle
													labelNegative="No"
													labelPositive="Yes"
													onClick={(v) =>
														handleChangeKeyValuePair(
															'rehomingAttemptsSatisfactory',
															v
														)
													}
													value={values.rehomingAttemptsSatisfactory}
													disabled={!isFormInReview}
												/>
											</StyledFieldContainer>
										</Col>
										<Col xs={12}>
											<StyledFieldContainer>
												<StyledFieldLabelWithCharRemainingContainer>
													<StyledFieldLabel>
														Rehoming Attempts Comments
													</StyledFieldLabel>
													<span className="characters-remaining">
														{500 -
															(values.rehomingAttemptsComments?.length ||
																0)}{' '}
														characters remaining
													</span>
												</StyledFieldLabelWithCharRemainingContainer>
												<StyledFormTextArea
													name="rehomingAttemptsComments"
													value={values.rehomingAttemptsComments}
													onChange={handleTextFieldChange}
													isError={errors?.rehomingAttemptsComments?.message}
													disabled={!isFormInReview}
												/>
												<StyledErrorText>
													{errors.rehomingAttemptsComments?.message}
												</StyledErrorText>
											</StyledFieldContainer>
										</Col>
									</Fragment>
								)}
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Part of Body</StyledFieldLabel>
										<FormMultiSelectList
											id={`${PAGE_ID}-bodyParts`}
											listOptions={bodyPartOptions}
											selectedIds={values.bodyParts}
											onSelectChange={(v) => {
												handleChangeKeyValuePair('bodyParts', v);
											}}
										/>
									</StyledFieldContainer>
								</Col>
								{values.bodyParts.find(({ label }) => label === 'Other') && (
									<Col xs={12}>
										<StyledFieldContainer>
											<StyledFieldLabelWithCharRemainingContainer>
												<StyledFieldLabel>
													Other Parts of Body
													{values.ewdRecommendation ? '*' : ''}
												</StyledFieldLabel>
												<span className="characters-remaining">
													{500 - (values.bodyPartOther?.length || 0)} characters
													remaining
												</span>
											</StyledFieldLabelWithCharRemainingContainer>
											<StyledFormTextArea
												name="bodyPartOther"
												value={values.bodyPartOther}
												onChange={handleTextFieldChange}
												isError={errors?.bodyPartOther?.message}
												disabled={!isFormInReview}
											/>
											<StyledErrorText>
												{errors.bodyPartOther?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								)}
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabelWithCharRemainingContainer>
											<StyledFieldLabel>EWD Final Comments</StyledFieldLabel>
											<span className="characters-remaining">
												{500 - (values.ewdFinalComments?.length || 0)}{' '}
												characters remaining
											</span>
										</StyledFieldLabelWithCharRemainingContainer>
										<StyledFormTextArea
											name="ewdFinalComments"
											value={values.ewdFinalComments}
											onChange={handleTextFieldChange}
											isError={errors?.ewdFinalComments?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.ewdFinalComments?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							</Row>
							<StyledEligibilityAssessmentDocsRow>
								<Col xs={12} lg={6}>
									<StyledFileFieldContainer>
										<FileUploadInputField
											idPrefix={`${PAGE_ID}-vet-cert-or-other-files`}
											fieldLabel="Vet Cert or Other Supporting Documents"
											buttonText="Upload Supporting Documents"
											fileData={values.vetCertOrOtherFiles}
											onSuccessfulS3Upload={(file, key) =>
												handleUploadFile(
													file,
													key,
													'vetCertOrOtherFiles',
													'vetCertOrOther'
												)
											}
											onSuccessfulS3Delete={(file) =>
												handleRemoveFile(file, 'vetCertOrOtherFiles')
											}
											onSuccessfulS3ImageDownload={filePreviewHandler}
											formats={['pdf', WORD_DOC_MIME_TYPE]}
											numberOfFiles={10}
											inputErrorHandler={handleUploadError}
											formFieldWrapperProps={{ fullWidth: true }}
											isEditable={isFormInReview}
										/>
									</StyledFileFieldContainer>
								</Col>
								<Col xs={12} lg={6}>
									<StyledFileFieldContainer>
										<FileUploadInputField
											idPrefix={`${PAGE_ID}-x-ray-files`}
											fieldLabel="X-rays"
											buttonText="Upload X-rays"
											fileData={values.xRayFiles}
											onSuccessfulS3Upload={(file, key) =>
												handleUploadFile(file, key, 'xRayFiles', 'xRay')
											}
											onSuccessfulS3Delete={(file) =>
												handleRemoveFile(file, 'xRayFiles')
											}
											onSuccessfulS3ImageDownload={filePreviewHandler}
											formats={['pdf', WORD_DOC_MIME_TYPE]}
											numberOfFiles={10}
											inputErrorHandler={handleUploadError}
											formFieldWrapperProps={{ fullWidth: true }}
											isEditable={isFormInReview}
										/>
									</StyledFileFieldContainer>
								</Col>
								<Col xs={12} lg={6}>
									<StyledFileFieldContainer style={{ paddingBottom: '0px' }}>
										<FileUploadInputField
											idPrefix={`${PAGE_ID}-brand-images`}
											fieldLabel={`Brand Image${
												isEwdRecommendationApprove ? '*' : ''
											}`}
											buttonText="Upload Brand Image"
											fileData={values.brandImages}
											onSuccessfulS3Upload={(file, key) =>
												handleUploadFile(file, key, 'brandImages', 'brandImage')
											}
											onSuccessfulS3Delete={(file) =>
												handleRemoveFile(file, 'brandImages')
											}
											onSuccessfulS3ImageDownload={filePreviewHandler}
											formats={['png', 'jpeg']}
											numberOfFiles={1}
											inputErrorHandler={handleUploadError}
											formFieldWrapperProps={{ fullWidth: true }}
											isEditable={isFormInReview}
										/>
										<StyledErrorText className="pl-4">
											{errors.brandImages?.message}
										</StyledErrorText>
									</StyledFileFieldContainer>
								</Col>
							</StyledEligibilityAssessmentDocsRow>
							<StyledFieldContainer
								style={{ paddingTop: '0', paddingBottom: '0' }}
							>
								<p className="text-purple m-0 pb-4">
									This brand image will be added into the Acceptance Notice and
									Notice to Proceed forms. A screen snip from ASB record is
									recommended.
								</p>
							</StyledFieldContainer>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="EWGM Approval" isOpen>
						<StyledAdminAccordionContainer>
							<Row>
								<Col lg={6} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Approval to Proceed</StyledFieldLabel>
										<Toggle
											labelNegative="Reject"
											labelPositive="Approve"
											disabled={!isFormInReview}
											onClick={(v) =>
												handleChangeKeyValuePair('ewgmApprovalToProceed', v)
											}
											value={values.ewgmApprovalToProceed}
										/>
									</StyledFieldContainer>
								</Col>
								{values.ewgmApprovalToProceed && (
									<Col lg={6} xs={12} style={{ paddingRight: 0 }}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Approval to Proceed Date
												{isEwdRecommendationApprove ? '*' : ''}
											</StyledFieldLabel>
											<FormDatePicker
												DateValue={
													!isFormInReview
														? formatDateReadableVariant(
																values.ewgmApprovalToProceedDate
														  )
														: values.ewgmApprovalToProceedDate
												}
												updateSelection={(value) =>
													handleDateChange('ewgmApprovalToProceedDate', value)
												}
												minDate={null}
												isError={errors.ewgmApprovalToProceedDate?.message}
												canEdit={isFormInReview}
											/>
											<StyledErrorText>
												{errors.ewgmApprovalToProceedDate?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								)}
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabelWithCharRemainingContainer>
											<StyledFieldLabel>EWGM Comments</StyledFieldLabel>
											<span className="characters-remaining">
												{500 - (values.ewgmComments?.length || 0)} characters
												remaining
											</span>
										</StyledFieldLabelWithCharRemainingContainer>
										<StyledFormTextArea
											name="ewgmComments"
											value={values.ewgmComments}
											onChange={handleTextFieldChange}
											isError={errors?.ewgmComments?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.ewgmComments?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								{values.ewgmApprovalToProceed && (
									<Fragment>
										<Col lg={6} xs={12}>
											<StyledFieldContainer>
												<StyledFieldLabel>
													Add EWGM as the Signature on Acceptance Notice?
												</StyledFieldLabel>
												<Toggle
													labelNegative="No"
													labelPositive="Yes"
													disabled={!isFormInReview}
													onClick={(v) =>
														handleChangeKeyValuePair(
															'addEwgmSignatureOnAcceptanceNotice',
															v
														)
													}
													value={values.addEwgmSignatureOnAcceptanceNotice}
												/>
											</StyledFieldContainer>
										</Col>
										{values.addEwgmSignatureOnAcceptanceNotice && (
											<Col lg={6} xs={12}>
												<StyledFieldContainer>
													<StyledFieldLabel>
														Automatically Send Acceptance Notice to Owner?
													</StyledFieldLabel>
													<Toggle
														labelNegative="No"
														labelPositive="Yes"
														disabled={!isFormInReview}
														onClick={(v) =>
															handleChangeKeyValuePair(
																'automaticallySendAcceptanceNoticeToOwner',
																v
															)
														}
														value={
															values.automaticallySendAcceptanceNoticeToOwner
														}
													/>
												</StyledFieldContainer>
											</Col>
										)}
										{(!values.automaticallySendAcceptanceNoticeToOwner ||
											!values.addEwgmSignatureOnAcceptanceNotice) && (
											<Col xs={12}>
												<StyledFieldContainer>
													<StyledWarningText>
														Reminder this form needs to be manually sent to the
														Owner
													</StyledWarningText>
												</StyledFieldContainer>
											</Col>
										)}
									</Fragment>
								)}
							</Row>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Notes & Actions" isOpen>
						<StyledAdminAccordionContainer isLast>
							<Row>
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabelWithCharRemainingContainer>
											<StyledFieldLabel>Admin Notes</StyledFieldLabel>
											<span className="characters-remaining">
												{500 - (values.adminNotes?.length || 0)} characters
												remaining
											</span>
										</StyledFieldLabelWithCharRemainingContainer>
										<StyledFormTextArea
											name="adminNotes"
											value={values.adminNotes}
											onChange={handleTextFieldChange}
											isError={errors?.adminNotes?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.adminNotes?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							</Row>
							{isFormError && (
								<StyledFormMainErrorContainer>
									<StyledErrorText>
										Please complete all required fields
									</StyledErrorText>
								</StyledFormMainErrorContainer>
							)}
							<StyledActionsButtonRow>
								<StyledActionsButtonCol>
									<StyledFormSubmitButton
										isNonSubmit
										onClick={handleSaveAdminDetails}
										id={`${PAGE_ID}-submit`}
										disabled={!isFormInReview}
									>
										Save Admin Details
									</StyledFormSubmitButton>
								</StyledActionsButtonCol>
							</StyledActionsButtonRow>
							<Divider color="#e3e3e3" />
							<StyledActionsButtonRow>
								<StyledActionsButtonCol lg={4} xs={12}>
									{values.ewdRecommendation && values.ewgmApprovalToProceed && (
										<StyledFormSubmitButton
											onClick={handleApprove}
											id={`${PAGE_ID}-approve`}
											disabled={!isFormInReview}
										>
											Approve
										</StyledFormSubmitButton>
									)}
									{!values.ewdRecommendation && !values.ewgmApprovalToProceed && (
										<StyledFormSubmitButton
											onClick={handleReject}
											id={`${PAGE_ID}-reject`}
											disabled={!isFormInReview}
											secondary
										>
											Reject
										</StyledFormSubmitButton>
									)}
								</StyledActionsButtonCol>
							</StyledActionsButtonRow>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</StyledContainer>
			)}
		</Fragment>
	);
};

export default OhepReviewForm;

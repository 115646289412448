import { createUseStyles } from 'react-jss';

export const HEADER_BANNER_STYLES = {
	marginBottom: 20,
	color: '#006da',
	padding: 0
};

export const INLINE_CONTAINER_COLUMN_STYLES = { padding: '0px 15px 15px 15px' }; // Inline styles used here over class based styling as native styles for <Col> element override class styles

export default createUseStyles({
	headerContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		'@media (min-width:992px)': {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		}
	},
	headerTitle: {
		color: '#464749',
		fontSize: 15,
		margin: '0px 0px 8px 0px',
		'@media (min-width:992px)': {
			fontSize: 20,
			margin: 0
		}
	}
});

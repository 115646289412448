import React, { useState, useEffect } from 'react';
import BasicSwitch from '../BasicSwitch';
import DynamicAutoSuggestion from '../DynamicAutoSuggestion';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getAllMembersVerified,
	getSelectedMemberAddress
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';

const SubmitOnBehalf = ({
	horseCode,
	onToggleSubmitBehalf = () => {},
	onSelectUserVerified = () => {},
	isGetUsersUnclaim = false,
	isGetAllUsersVerified = true,
	selectUserRequiredError,
	isSelectUserRequired,
	typeOfUsers = null
}) => {
	const classes = useStyles();
	const [submitOnBehalf, setSubmitOnBehalf] = useState(false);
	const [listOfUsersVerified, setListOfUsersVerified] = useState([]);
	const [selectedUserVerifiedInput, setSelectedUserVerifiedInput] = useState(
		''
	);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (selectedUserVerifiedInput.length >= 3) {
			// setIsLoading(true);
			AppSyncService.execute(getAllMembersVerified, {
				horseCode: horseCode,
				isGetUsersUnclaim: isGetUsersUnclaim,
				isGetAllUsersVerified: isGetAllUsersVerified,
				typeOfUsers,
				input: selectedUserVerifiedInput ?? ''
			}).then((data) => {
				if (data?.data?.getAllMembersVerified !== null) {
					const {
						data: { getAllMembersVerified }
					} = data;
					setListOfUsersVerified(getAllMembersVerified);
				}
				setIsLoading(false);
			});
		}
	}, [selectedUserVerifiedInput]);

	const onSubmitOnBehalf = (event) => {
		setSubmitOnBehalf(event.target.checked);
		onToggleSubmitBehalf(event.target.checked);
		if (!event.target.checked) {
			onSelectUserVerified(null);
		}
	};

	const onChangeSelectedUserVerified = async (e, newVal) => {
		let selectedUser = newVal;
		if (newVal) {
			const result = await AppSyncService.execute(getSelectedMemberAddress, {
				memberExternalId: newVal.memberExternalId
			});
			const { data } = result;
			selectedUser = {
				...selectedUser,
				residentialAddress: data?.getSelectedMemberAddress
			};
		}

		onSelectUserVerified(selectedUser);
	};

	const onChangeInputSelectedUserVerified = (e, newInputVal) => {
		setSelectedUserVerifiedInput(newInputVal);
	};

	return isLoading ? (
		<div className={classes.loadingDiv}>
			<LoaderSpinner status={true} />
		</div>
	) : (
		<>
			<BasicSwitch
				checked={submitOnBehalf}
				onChange={onSubmitOnBehalf}
				label={'Submit this form on behalf of another user'}
			/>
			{submitOnBehalf ? (
				<>
					<div style={{ marginTop: 10 }}>
						<DynamicAutoSuggestion
							options={listOfUsersVerified}
							label="Select a user"
							onChangeFunc={onChangeSelectedUserVerified}
							onInputChangeFunc={onChangeInputSelectedUserVerified}
							inputValue={selectedUserVerifiedInput}
							noOptionsText={'No result found'}
						/>
					</div>
					{isSelectUserRequired && selectUserRequiredError.length > 0 ? (
						<span style={{ fontSize: 12, color: 'red' }}>
							{selectUserRequiredError}
						</span>
					) : null}
				</>
			) : null}
		</>
	);
};

export default SubmitOnBehalf;

import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import useStyles from './styles';

const ThreeCards = ({ data, cardStyle }) => {
	const classes = useStyles();

	const renderCards = () => {
		return (
			<Container>
				<Row>
					{data.map((d, index) => {
						return (
							<Col sm={4} key={index}>
								<div className={classes.cardsContainer} style={cardStyle}>
									<div>
										<img src={d.image} className={classes.image} />
									</div>
									<div className={classes.articleContainer}>
										<div className={classes.articleHeader}>{d.title}</div>
										<div className={classes.articleSummary}>{d.summary}</div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>
		);
	};

	return (
		<>
			<div className={classes.container}>{renderCards()}</div>
		</>
	);
};

export default ThreeCards;

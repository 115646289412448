import React, { Fragment, useEffect, useState } from 'react';
import FormDatePicker from '../FormDatePicker';
import useStyles from './styles';
import SaveCancelButton from '../SaveCancelButton';
import { Row, Col } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import { formatDate } from '../../utils/helpers';
import FormMultiSelectList from '../FormMultiSelectList';
import { convertToMultiListData } from '../../utils/helpers';
import RetrainerConcludedFullCircleForm from '../RetrainerConcludedFullCircleForm';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';

const RetrainerConcludedSoldForm = ({
	priceBracket,
	rehomingPurposeList,
	disciplineOptions,
	cancel,
	submitForm,
	showFullCircleForm
}) => {
	const classes = useStyles();

	const [disciplineList, setDisciplineList] = useState([]);
	const [selectedPrice, setSelectedPrice] = useState(0);
	const [selectedPurpose, setSelectedPurpose] = useState(0);
	const [selectedDisciplineList, setSelectedDisciplineList] = useState([]);
	const [fullName, setFullName] = useState('');
	const [email, setEmail] = useState('');
	const [postcode, setPostcode] = useState('');
	const [date, setDate] = useState(null);
	const [other, setOther] = useState('');
	const [error, setError] = useState('');
	const [wantFullCircle, setWantFullCircle] = useState(true);
	const [assistanceType, setAssistanceType] = useState(0);

	useEffect(() => {
		setDisciplineList(convertToMultiListData(disciplineOptions, 'value'));
	}, []);

	const saveData = () => {
		if (
			date != null &&
			postcode != '' &&
			selectedDisciplineList.length > 0 &&
			selectedPrice != 0 &&
			selectedPurpose != 0 &&
			(!showFullCircleForm ||
				!wantFullCircle ||
				(wantFullCircle && assistanceType.length > 0))
		) {
			let disciplineListArray = [];
			selectedDisciplineList.map((d) => {
				disciplineListArray.push(d.value);
			});
			let data = {
				date: date,
				disciplineIds: disciplineListArray,
				disciplineOther: other,
				purposeId: selectedPurpose,
				priceBracketId: selectedPrice,
				price: selectedPrice,
				newOwnerFullName: fullName,
				newOwnerEmail: email,
				newOwnerPostcode: postcode,
				wantFullCircle: !showFullCircleForm ? false : wantFullCircle,
				assistanceType
			};
			submitForm(data);
		} else {
			setError('Fields cannot be empty');
		}
	};

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDate(formattedDate);
			} else {
				setDate(null);
			}
		}
	};

	const updateDiscipline = (value) => {
		setSelectedDisciplineList(value);
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<Row>
					<Col lg={4}>
						<div className={classes.dateFieldTitle}>Date Sold*</div>
						<div>
							<FormDatePicker
								id={HorseManagementKeys.DATE_SOLD}
								styles={{ width: '100%', color: 'blue' }}
								DateValue={date}
								updateSelection={(value) => updateDate(value)}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className={classes.dateFieldTitle}>Price Bracket*</div>
						<div>
							<FormDropDown
								id={HorseManagementKeys.PRICE_BRACKET}
								onChange={(e) => setSelectedPrice(e.target.value)}
								items={priceBracket}
								showBlank
								selectedId={selectedPrice}
							/>
						</div>
					</Col>
					<Col lg={4}>
						<div className={classes.dateFieldTitle}>Purpose*</div>
						<div>
							<FormDropDown
								id={HorseManagementKeys.CONCLUDED_PURPOSE}
								onChange={(e) => setSelectedPurpose(e.target.value)}
								items={rehomingPurposeList}
								showBlank
								selectedId={selectedPurpose}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<div className={classes.dateFieldTitle}>Disciplines*</div>
						<FormMultiSelectList
							id={HorseManagementKeys.CONCLUDED_DISCIPLINE}
							name="Discipline"
							listOptions={disciplineList}
							selectedIds={selectedDisciplineList}
							onSelectChange={updateDiscipline}
						/>
					</Col>
				</Row>
				<Row>
					{selectedDisciplineList.filter((r) => r.label === 'Other').length >
						0 && (
						<Col lg={4}>
							<div className={classes.dateFieldTitle}>Other</div>
							<FormTextField
								id={HorseManagementKeys.CONCLUDED_DISCIPLINE_OTHER}
								value={other}
								onChange={(e) => setOther(e.target.value)}
								styles={{ height: 38 }}
							/>
						</Col>
					)}
				</Row>
				<hr className={classes.divider}></hr>
				<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
					New Owner Details
				</div>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.dateFieldTitle}>Full Name</div>
						<FormTextField
							id={HorseManagementKeys.FULL_NAME}
							value={fullName}
							onChange={(e) => setFullName(e.target.value)}
							styles={{ height: 38 }}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.dateFieldTitle}>Email</div>
						<FormTextField
							id={HorseManagementKeys.EMAIL}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							styles={{ height: 38 }}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.dateFieldTitle}>Postcode*</div>
						<FormTextField
							id={HorseManagementKeys.POSTCODE}
							value={postcode}
							onChange={(e) => setPostcode(e.target.value)}
							styles={{ height: 38 }}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				{showFullCircleForm && (
					<Fragment>
						<RetrainerConcludedFullCircleForm
							wantFullCircle={wantFullCircle}
							updateWantFullCircle={setWantFullCircle}
							assistanceType={assistanceType}
							updateAssistanceType={setAssistanceType}
						/>
						<hr className={classes.divider}></hr>
					</Fragment>
				)}
				<div className={classes.saveButtonContainer}>
					<div className={classes.error}>{error}</div>
					<SaveCancelButton
						idPositive={CommonKeys.SAVE}
						idNegative={CommonKeys.CANCEL}
						positiveLabel="Save"
						onClickPositive={saveData}
						cancel={cancel}
						negativeLabel="Cancel"
					/>
				</div>
			</div>
		</div>
	);
};
export default RetrainerConcludedSoldForm;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	searchFieldContainer: {
		marginTop: 15
	},
	searchField: {
		'&:focus': {
			outline: 'none'
		},
		width: '100%',
		border: 'none',
		boxSizing: 'border-box'
	},
	addFilter: {
		color: '#006da6',
		fontWeight: 600,
		fontSize: 15,
		cursor: 'pointer',
		marginBottom: 5
	},
	showMapCheckBoxLabel: {
		fontSize: 13,
		color: '#464749'
	},
	filterContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		backgroundColor: '#ededed',
		padding: '10px 20px 20px 20px',
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4
	},
	filters: {
		color: '#016ea6',
		fontWeight: 600,
		fontSize: 13,
		width: '100%',
		minHeight: 40,
		background: '#dcebf3'
	},
	filterContent: {
		padding: '10px 10px 10px 20px',
		display: 'flex',
		flexWrap: 'wrap'
	},
	serFilter: {
		width: '100%',
		marginRight: 15
	},
	specFilter: {
		width: '100%',
		marginLeft: 15
	},
	filterMobileSer: {
		width: '100%',
		marginRight: 4
	},
	filterMobileSpec: {
		width: '100%',
		marginLeft: 4
	},
	appliedFilter: {
		marginRight: 30,
		cursor: 'default',
		position: 'relative'
	},
	appliedFilterMobile: {
		marginRight: 10,
		cursor: 'default',
		position: 'relative'
	},
	appliedFilterCross: {
		cursor: 'pointer',
		float: 'right',
		marginTop: 4,
		fontSize: 8,
		paddingLeft: 5
	},
	searchDetails: {
		marginTop: 25,
		fontSize: 15,
		marginBottom: 5
	},
	clear: {
		clear: 'both'
	},
	filterOrderBy: {
		float: 'left',
		marginLeft: 15,
		marginTop: 2,
		cursor: 'pointer',
		fontSize: 12
	},
	sortIcon: {
		float: 'right',
		marginTop: '-1px'
	},

	numberOfRecords: {
		float: 'right',
		marginRight: 15,
		color: '#757575',
		fontSize: 12
	},
	results: {
		fontSize: 15,
		float: 'left',
		fontWeight: 600,
		color: '#464749'
	},
	resultContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		overflow: 'auto',
		'& $resultContent:nth-child(3n+2)': {
			marginRight: 20,
			marginLeft: 20
		},
		'@media (min-width:992px)': {
			overflowY: 'scroll',
			'-ms-overflow-style': 'none',
			scrollbarWidth: 'none',
			maxHeight: 600,
			'&::-webkit-scrollbar': {
				display: 'none'
			}
		}
	},
	resultContent: {
		width: 320,
		marginBottom: 25
	},
	resultContentMobile: {
		marginTop: 25
	},
	resultImage: {
		width: 318,
		height: 200
	},
	resultImageMobile: {
		height: 200
	},
	resultTitle: {
		fontSize: 16,
		fontWeight: 600,
		color: '#464749',
		marginTop: 10,
		wordBreak: 'break-word',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	resultSummary: {
		fontSize: 13,
		marginTop: 5,
		marginBottom: 10,
		minHeight: 100,
		wordBreak: 'break-word'
	},
	resultTags: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	resultTag: {
		background: '#ededed',
		fontSize: 12,
		padding: '1px 5px 1px 5px',
		color: '#464749',
		marginRight: 7,
		borderRadius: 3,
		marginTop: 5
	},
	resultAddress: {
		fontStyle: 'italic',
		fontSize: 12,
		color: 'grey',
		marginTop: 3,
		wordBreak: 'break-word'
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: 7
	},
	loadMoreButton: {
		textAlign: 'center',
		background: '#dcebf3',
		color: '#026ea7',
		paddingTop: 10,
		paddingBottom: 10,
		fontWeight: 800,
		cursor: 'pointer',
		marginTop: 40
	},
	loadingDiv: {
		display: 'flex',
		width: '100%',
		height: 300,
		justifyContent: 'center',
		alignItems: 'center',
		'@media (min-width: 992px)': {
			height: 500
		}
	}
}));

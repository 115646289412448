import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  filterContainerHeader: {
    fontSize: 15,
    color: "#464749",
    fontWeight: 800,
    padding: "10px 10px 6px 0px",
  },
  filterField: {
    height: 40,
    borderRadius: 4,
    border: "1px solid #c7ced1",
    paddingLeft: 10,
    boxSizing: "border-box",
    width : '100%', 
    fontSize: 15,
    "&:focus": {
      outline: "none",
    },
  },
  filterContainerHeaderMobile: {
    fontSize: 12,
    color: "#464749",
    fontWeight: 800,
    padding: "10px 10px 6px 0px",
  },
  
}));

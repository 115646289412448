import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		marginTop: 40,
		display: 'flex',
		justifyContent: 'space-between'
	},
	mobileContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginLeft: 15,
		marginRight: 15,
		marginTop: 30
	},
	title: {
		color: '#464749',
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 10,
		textTransform: 'uppercase'
	},
	text: {
		fontSize: 12,
		marginBottom: 10
	},
	mobileTitle: {
		color: '#464749',
		fontSize: 20,
		fontWeight: 600,
		marginBottom: 10,
		textTransform: 'uppercase'
	},
	mobileText: {
		fontSize: 12,
		marginBottom: 10
	},
	buttonLink: {
		background: '#dcebf3',
		width: '100%',
		color: '#006da6',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		padding: 10,
		fontWeight: 600,
		fontSize: 15,
		borderRadius: 5,
		marginTop: 'auto',
		cursor: 'pointer',
		boxSizing: 'border-box',
		'&:hover': {
			backgroundColor: '#cde3ef'
		}
	},
	flexCol: {
		paddingRight: 20,
		width: '50%',
		display: 'flex',
		flexDirection: 'column'
	}
}));

import React from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import StyledSelectWrapper from '../StyledSelectWrapper';

const CountrySelector = ({
	id,
	value,
	changeHandler,
	isDisabled = false,
	isError
}) => {
	return (
		<StyledSelectWrapper isDisabled={isDisabled} isError={isError}>
			<CountryDropdown
				id={id}
				value={value}
				onChange={changeHandler}
				disabled={isDisabled}
			/>
		</StyledSelectWrapper>
	);
};

export default CountrySelector;

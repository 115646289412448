import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	topBannerText: {
		marginBottom: 10,
		color: '#464749',
		fontSize: 20,
		fontWeight: 800
	},
	backLink: {
		color: '#006da6',
		cursor: 'pointer'
	},
	arrowLeft: {
		marginTop: -2,
		height: 16,
		marginRight: 7
	},
	eventBanner: {
		borderRadius: '5px 5px 0px 0px',
		minHeight: 70,
		fontSize: 25,
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		padding: '5px 20px',
		justifyContent: 'space-between'
	},
	leftCol: {
		borderLeft: '2px solid rgb(187 195 199)',
		borderBottom: '2px solid rgb(187 195 199)',
		fontWeight: 500,
		fontSize: 19,
		'@media (max-width: 992px)': {
			borderRight: '2px solid rgb(187 195 199)'
		}
	},
	rightCol: {
		border: '2px solid rgb(187 195 199)',
		borderTop: 'none',
		backgroundColor: '#f5f5f5',
		padding: 10
	},
	addressSectionContent: {
		color: '#006da6',
		marginTop: 10,
		display: 'inline-flex'
	},
	bottomRow: {
		borderLeft: '2px solid rgb(187 195 199)',
		borderBottom: '2px solid rgb(187 195 199)',
		borderRight: '2px solid rgb(187 195 199)',
		padding: 10,
		borderRadius: '0px 0px 5px 5px'
	},
	organiserImage: {
		maxWidth: 125
	},
	icon: {
		marginRight: 10,
		height: 24
	},
	colContent: {
		marginTop: 15,
		color: 'rgb(70 71 73)',
		flexWrap: 'wrap',
		overflowWrap: 'break-word'
	},
	leftColContent: {
		extend: 'colContent',
		display: 'inline-flex'
	},
	eventDisciplineSection: {
		display: 'flex',
		justifyContent: 'flex-start',
		width: '100%',
		marginTop: 15
	},
	eventDisciplineContent: {
		color: 'rgb(70 71 73)',
		overflowX: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},
	autoLink: {
		'& a': {
			color: '#006da6'
		}
	},
	sponsoredClass: {
		background: '#d8d8d8',
		fontWeight: 500,
		borderRadius: 5,
		margin: 5,
		padding: 5,
		overflowWrap: 'break-word',
		maxWidth: 120
	},
	warningIcon: {
		marginRight: 10
	}
}));

export const generatePayload = (values, horseCode) => {
	const payload = { horseCode };
	payload.relationshipToHorse =
		!isNaN(+values.relationshipToHorse) && +values.relationshipToHorse !== 0
			? +values.relationshipToHorse
			: null;
	payload.relationshipToHorseOther =
		payload.relationshipToHorse === 5 ? values.relationshipToHorseOther : null;
	payload.currentAddress = values.currentAddress;
	payload.currentSuburb = values.currentSuburb;
	payload.currentPostcode = values.currentPostcode;
	payload.currentState = values.currentState;
	payload.currentCountry = values.currentCountry;
	payload.currentAddressValidated = values.currentAddressValidated;
	payload.currentAddressDurationMonths = +values.currentAddressDurationMonths;
	payload.currentAddressDurationYears = +values.currentAddressDurationYears;
	const isOneMonthOrLess =
		payload.currentAddressDurationMonths <= 1 &&
		payload.currentAddressDurationYears === 0;
	payload.previousAddress = isOneMonthOrLess ? values.previousAddress : null;
	payload.previousSuburb = isOneMonthOrLess ? values.previousSuburb : null;
	payload.previousPostcode = isOneMonthOrLess ? values.previousPostcode : null;
	payload.previousState = isOneMonthOrLess ? values.previousState : null;
	payload.previousCountry = isOneMonthOrLess ? values.previousCountry : null;
	payload.previousAddressValidated = isOneMonthOrLess
		? values.previousAddressValidated
		: null;
	payload.reasonsForNomination = values.reasonsForNomination;
	payload.thoroughbredOverTwentyDetails = values.reasonsForNomination.includes(
		6
	)
		? values.thoroughbredOverTwentyDetails
		: null;
	payload.rehomingAttemptDetails = values.reasonsForNomination.includes(5)
		? values.rehomingAttemptDetails
		: null;
	payload.rehomingAttemptDetailsDate = values.reasonsForNomination.includes(5)
		? values.rehomingAttemptDetailsDate
		: null;
	payload.advertisedToRvDate = values.reasonsForNomination.includes(5)
		? values.advertisedToRvDate
		: null;
	payload.acknowledgedPrivacy = values.acknowledgedPrivacy;
	payload.acknowledgedConsentToParticipate =
		values.acknowledgedConsentToParticipate;
	payload.acknowledgedOwnershipWarranty = values.acknowledgedOwnershipWarranty;
	payload.acknowledgedOwnershipBinding = values.acknowledgedOwnershipBinding;
	payload.acknowledgedOwnerIndemnity = values.acknowledgedOwnerIndemnity;
	payload.acknowledgedOwnerConfirmation = values.acknowledgedOwnerConfirmation;
	return payload;
};

export const setFormValuesFromApiResult = (resultValues, setValue) => {
	setValue('relationshipToHorse', resultValues.relationshipToHorse?.id || 0);
	setValue(
		'relationshipToHorseOther',
		resultValues.relationshipToHorseOther || ''
	);
	setValue('currentAddress', resultValues.currentAddress || '');
	setValue('currentSuburb', resultValues.currentSuburb || '');
	setValue('currentPostcode', resultValues.currentPostcode || '');
	setValue('currentState', resultValues.currentState || '');
	setValue('currentCountry', resultValues.currentCountry || '');
	setValue(
		'currentAddressValidated',
		resultValues.currentAddressValidated || false
	);
	setValue(
		'currentAddressDurationMonths',
		resultValues.currentAddressDurationMonths || 0
	);
	setValue(
		'currentAddressDurationYears',
		resultValues.currentAddressDurationYears || 0
	);
	setValue('previousAddress', resultValues.previousAddress || '');
	setValue('previousSuburb', resultValues.previousSuburb || '');
	setValue('previousPostcode', resultValues.previousPostcode || '');
	setValue('previousState', resultValues.previousState || '');
	setValue('previousCountry', resultValues.previousCountry || '');
	setValue(
		'previousAddressValidated',
		resultValues.previousAddressValidated || false
	);
	setValue(
		'reasonsForNomination',
		resultValues.reasonsForNomination?.map(({ id }) => id) || []
	);
	setValue(
		'thoroughbredOverTwentyDetails',
		resultValues.thoroughbredOverTwentyDetails || ''
	);
	setValue('rehomingAttemptDetails', resultValues.rehomingAttemptDetails || '');
	setValue(
		'rehomingAttemptDetailsDate',
		resultValues.rehomingAttemptDetailsDate
	);
	setValue('advertisedToRvDate', resultValues.advertisedToRvDate);

	// acknowledged tick boxes
	setValue(
		'acknowledgedConsentToParticipate',
		resultValues.acknowledgedConsentToParticipate || false
	);
	setValue(
		'acknowledgedOwnerConfirmation',
		resultValues.acknowledgedOwnerConfirmation || false
	);
	setValue(
		'acknowledgedOwnerIndemnity',
		resultValues.acknowledgedOwnerIndemnity || false
	);
	setValue(
		'acknowledgedOwnershipBinding',
		resultValues.acknowledgedOwnershipBinding || false
	);
	setValue(
		'acknowledgedOwnershipWarranty',
		resultValues.acknowledgedOwnershipWarranty || false
	);
	setValue('acknowledgedPrivacy', resultValues.acknowledgedPrivacy || false);

	// Photos and files
	setValue(
		'veterinaryCertificateFiles',
		resultValues.veterinaryCertificateFiles || []
	);
	setValue(
		'rspcaNoticeToComplyFiles',
		resultValues.rspcaNoticeToComplyFiles || []
	);
	setValue(
		'evidenceOfDangerousBehaviourFiles',
		resultValues.evidenceOfDangerousBehaviourFiles || []
	);
	setValue('otherFiles', resultValues.otherFiles || []);
	setValue(
		'statuaryDeclarationFiles',
		resultValues.statuaryDeclarationFiles || []
	);
	setValue('leftSidePhotos', resultValues.leftSidePhotos || []);
	setValue('rightSidePhotos', resultValues.rightSidePhotos || []);
};

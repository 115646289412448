import styled from 'styled-components';

export const StyledContainer = styled.div`
	padding: 20px 18px 20px 18px;
	label {
		color: #464749;
		display: block;
		font-size: 15px;
		align-items: center;
		font-weight: 600;
		margin-bottom: 7px;
	}
`;

import React from 'react';
import PropTypes from 'prop-types';
import FormFieldWrapper from '../FormFieldWrapper';
import UploadPhoto from '../../UploadPhoto';
import AppSyncService from '../../../graphql/AppSyncService';
import { useToast } from '../../../hooks/useToast';
import {
	uploadImageToS3,
	deleteImageFromS3,
	getUserImage
} from '../../../graphql/custom.queries';
import axios from 'axios';

const FileUploadInputField = ({
	idPrefix,
	fieldLabel,
	buttonText,
	sizeLimitMB = 5,
	numberOfFiles = 1,
	formats = ['png', 'jpeg'],
	fileData,
	onSuccessfulS3Upload,
	onSuccessfulS3Delete,
	onSuccessfulS3ImageDownload,
	inputErrorHandler,
	isEditable = true,
	isRequired = false,
	validationError,
	visiblitySelectable = false,
	visibilitySelector,
	formFieldWrapperProps = {},
	isDisabled = false
}) => {
	const { addToast } = useToast();

	const uploadFileToS3 = (file) => {
		const headers = {
			'Content-Type': file.type
		};
		const imageKey = { imageKey: file.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((result) => {
			if (result?.data?.putUserImage?.imageKey) {
				const imageKey = result.data.putUserImage.imageKey;
				axios
					.put(result.data.putUserImage.signedUrl, file, { headers })
					.then(() => {
						onSuccessfulS3Upload(file, imageKey);
					});
			} else {
				addToast({ Message: 'Failed to upload file', IsSuccess: true });
			}
		});
	};

	const deleteFromS3 = (file) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: file.key }).then(
			(result) => {
				if (result?.data?.deleteUserImage?.success) {
					onSuccessfulS3Delete(file);
				} else {
					addToast({ Message: 'Failed to remove file', IsSuccess: true });
				}
			}
		);
	};

	const retrieveFileFromS3 = (file) => {
		const imageKey = { imageKey: file.key };
		AppSyncService.execute(getUserImage, imageKey).then((result) => {
			if (result?.data?.getUserImage?.signedUrl) {
				onSuccessfulS3ImageDownload({
					...file,
					signedUrl: result?.data?.getUserImage?.signedUrl
				});
			} else {
				addToast({ Message: 'Failed to get file', IsSuccess: false });
			}
		});
	};

	return (
		<FormFieldWrapper
			textParenthesesNormal
			fieldLabel={fieldLabel}
			isRequired={isRequired}
			errorMessage={validationError}
			visiblitySelectable={visiblitySelectable}
			visibilitySelector={visibilitySelector}
			{...formFieldWrapperProps}
		>
			<UploadPhoto
				id={`${idPrefix}-file-upload`}
				formats={formats}
				labelName={buttonText}
				sizeLimitMB={sizeLimitMB}
				numberOfImages={numberOfFiles}
				success={uploadFileToS3}
				data={fileData}
				removeImage={deleteFromS3}
				error={inputErrorHandler}
				getImage={retrieveFileFromS3}
				canEdit={isEditable}
				isDisabled={isDisabled}
			/>
		</FormFieldWrapper>
	);
};

FileUploadInputField.propTypes = {
	idPrefix: PropTypes.string.isRequired,
	fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	buttonText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	sizeLimitMB: PropTypes.number,
	numberOfFiles: PropTypes.number,
	formats: PropTypes.arrayOf(PropTypes.string),
	fileData: PropTypes.arrayOf(
		PropTypes.shape({ filename: PropTypes.string, key: PropTypes.string })
	),
	onSuccessfulS3Upload: PropTypes.func.isRequired,
	onSuccessfulS3Delete: PropTypes.func.isRequired,
	onSuccessfulS3ImageDownload: PropTypes.func.isRequired,
	inputErrorHandler: PropTypes.func.isRequired,
	isEditable: PropTypes.bool,
	isRequired: PropTypes.bool,
	visiblitySelectable: PropTypes.bool
};

export default FileUploadInputField;

import React, { useEffect, useState } from 'react';
import DefaultHorseProfileImage from '../../assets/images/default-horse-icon.png';
import useStyles from './styles';
import { getImageSrcUrl } from '../../utils/helpers';

const HorseAvatar = ({
	avatarImageKey,
	visibleToUser = true,
	idPrefix,
	...rest
}) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	useEffect(() => {
		if (avatarImageKey) {
			getImageSrcUrl(avatarImageKey, setImgSrcUrl);
		}
	}, [avatarImageKey]);

	return (
		<img
			id={`${idPrefix}_horse_profile_image`}
			src={(visibleToUser && imgSrcUrl) || DefaultHorseProfileImage}
			alt="Horse avatar image"
			className={classes.horseProfileImageBase}
			{...rest}
		/>
	);
};

export default HorseAvatar;

import React, { useState, useEffect } from 'react';
import HorseAvatar from '../HorseAvatar';
import useStyles, { HORSE_AVATAR_INLINE_STYLES } from './styles';
import {
	getImageSrcUrl,
	parseUTCToLocal,
	navigateToHorseProfile
} from '../../utils/helpers';
import CardMedia from '@material-ui/core/CardMedia';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Carrot from '../../assets/icons/Carrot.svg';
import CarrotNoFill from '../../assets/icons/CarrotNoFill.svg';
import AppSyncService from '../../graphql/AppSyncService';
import {
	createPrpPostLikes,
	deletePrpPostLikes,
	getPrpPostLikes
} from './queries';

const StyledMoreOptionsIcon = withStyles({ root: { color: '#006da6' } })(
	MoreHoriz
);

const StyledIconButton = withStyles({
	root: { '&:hover': { background: 'none' }, height: '1.25em', width: '1.25em' }
})(IconButton);

const StyledCardMedia = withStyles({
	root: { borderRadius: 3, marginTop: 10 }
})(CardMedia);

const HorseUpdate = ({
	postId,
	horseCode,
	horseAvatarVisible,
	profileImageSrc,
	horseName,
	message,
	createdAt,
	files,
	imageStyles,
	containerStyles,
	showMoreOptions = false,
	onRemoveUpdate,
	source
}) => {
	const horseUpdateIdPrefix = `horse-update-${postId}`;
	const menuLabelId = `${horseUpdateIdPrefix}-more-options-button`;
	const classes = useStyles();

	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [postLikes, setPostLikes] = useState(null);
	const [userLikesPost, setUserLikesPost] = useState(false);

	useEffect(() => {
		if (files.length > 0) {
			getImageSrcUrl(files[0].key, setImgSrcUrl);
		}
	}, [files]);

	useEffect(() => {
		if (
			source === 'program change' ||
			source === 'claimer' ||
			source === 'status change'
		) {
			let input = {
				post_id: postId
			};
			AppSyncService.execute(getPrpPostLikes, input).then((data) => {
				if (data?.data?.getPrpPostLikes) {
					setUserLikesPost(true);
				}
				setPostLikes(data?.data?.countPrpPostLikes);
			});
		}
	}, [postId]);

	const handleProfileLinkClick = () => navigateToHorseProfile(horseCode);

	const unlikePost = () => {
		AppSyncService.execute(deletePrpPostLikes, { postId: +postId }).then(() => {
			AppSyncService.execute(getPrpPostLikes, { post_id: postId }).then(
				(data) => {
					setUserLikesPost(false);
					setPostLikes(data?.data?.countPrpPostLikes);
				}
			);
		});
	};

	const likePost = () => {
		AppSyncService.execute(createPrpPostLikes, { post_id: postId }).then(() => {
			let input = {
				post_id: postId
			};
			AppSyncService.execute(getPrpPostLikes, input).then((data) => {
				if (data?.data?.getPrpPostLikes) {
					setUserLikesPost(true);
				}
				setPostLikes(data.data.countPrpPostLikes);
			});
		});
	};

	return (
		<div
			id={horseUpdateIdPrefix}
			style={{
				padding: 10,
				...containerStyles
			}}
		>
			<div className={classes.horseUpdateContainer}>
				<div style={{ display: 'flex' }}>
					<HorseAvatar
						idPrefix={horseUpdateIdPrefix}
						avatarImageKey={profileImageSrc}
						visibleToUser={horseAvatarVisible}
						style={HORSE_AVATAR_INLINE_STYLES}
						onClick={handleProfileLinkClick}
					/>
					<div className={classes.updateContentContainer}>
						<p className={classes.horseName} onClick={handleProfileLinkClick}>
							{horseName}
						</p>
						{message && (
							<div
								className={classes.horseMessage}
								dangerouslySetInnerHTML={{
									__html: message
								}}
							/>
						)}
						<p className={classes.updateCreatedTime}>
							{parseUTCToLocal(createdAt).fromNow()}
						</p>
					</div>
				</div>
				{showMoreOptions && (
					<>
						<StyledIconButton
							id={menuLabelId}
							onClick={(e) => setAnchorEl(e.currentTarget)}
						>
							<StyledMoreOptionsIcon />
						</StyledIconButton>
						<Menu
							id="basic-menu"
							anchorEl={anchorEl}
							open={!!anchorEl}
							onClose={() => setAnchorEl(null)}
							MenuListProps={{
								'aria-labelledby': menuLabelId
							}}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
						>
							<MenuItem
								id={`${horseUpdateIdPrefix}-delete-option`}
								onClick={() => {
									setAnchorEl(null);
									onRemoveUpdate(postId);
								}}
							>
								Delete
							</MenuItem>
						</Menu>
					</>
				)}
			</div>
			{imgSrcUrl && (
				<div style={imageStyles}>
					<StyledCardMedia
						id={`${horseUpdateIdPrefix}-image`}
						component="img"
						image={imgSrcUrl}
					/>
				</div>
			)}
			{(source === 'program change' ||
				source === 'claimer' ||
				source === 'status change') && (
				<>
					<div className={classes.like}>
						{userLikesPost && (
							<img
								src={Carrot}
								className={classes.likeButton}
								onClick={() => unlikePost()}
							/>
						)}
						{!userLikesPost && (
							<img
								src={CarrotNoFill}
								className={classes.likeButton}
								onClick={() => likePost()}
							/>
						)}
						{postLikes !== 0 && (
							<div className={classes.likeText}>{postLikes}</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default HorseUpdate;

import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	topBanner: {
		maxWidth: '1000px',
		minHeight: 65,
		marginBottom: 30,
		marginTop: 10,
		borderRadius: 5
	},
	topBannerMobile: {
		maxWidth: '1000px',
		minHeight: 65,
		marginBottom: 30,
		marginTop: 10,
		borderRadius: 5,
		marginLeft: 15,
		marginRight: 15
	},
	primary: {
		background: `url(${BannerImage})`
	},
	info: {
		background: '#f0f0f0',
		border: '1px solid #b3bcc1'
	},
	topBannerInline: {
		borderRadius: 0
	},
	topBannerDefault: {
		borderRadius: '5px 5px 0px 0px'
	},
	topBannerText: {
		padding: 20,
		fontSize: 20,
		fontWeight: 800,
		color: '#464749'
	},
	topBannerTextMobile: {
		padding: 20,
		fontSize: 15,
		fontWeight: 800,
		color: '#464749'
	},
	'@media (max-width: 480px)': {
		topBannerText: {
			fontSize: 18
		}
	}
}));

import React from 'react';
import useStyles from './styles';
import { MessageBarTypeEnum } from '../../utils/enums';

const MessageBar = ({
	children,
	type = MessageBarTypeEnum.info,
	className,
	...props
}) => {
	const classes = useStyles();

	return (
		<div
			className={`${classes.container} ${classes[type]} ${className}`}
			{...props}
		>
			{children}
		</div>
	);
};
export default MessageBar;

import * as Yup from 'yup';

const schema = Yup.object().shape({
	adminExternalId: Yup.string()
		.required('Actioning is required')
		.typeError('Actioning is required')
		.test('empty-check', 'Actioning is required', (val) => val !== '0'),
	adminStatus: Yup.number()
		.required('Admin Status is required')
		.typeError('Admin Status is required')
		.moreThan(0, 'Admin Status is required'),
	reviewOutcome: Yup.number()
		.required('Outcome is required')
		.typeError('Outcome is required')
		.moreThan(0, 'Outcome is required'),
	adminNotes: Yup.string()
		.nullable()
		.max(500, 'Admin Notes cannot be more than 500 characters long')
});

export default schema;

import React, { useState, useEffect } from 'react';
import { Container, ScreenClassRender } from 'react-grid-system';
import qs from 'query-string';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getRetirementInitialAssessmentById,
	getUserImage,
	getRetirementQuarterlyAssessmentById,
	getFosterInitialAssessmentById,
	getFosterWeeklyAssessmentById
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import { FOSTER_FORMS, RETIREMENT_FORMS } from '../../utils/constants';
import RetirementInitialFormReviewInfo from '../RetirementInitialFormReviewInfo';
import RetirementQuarterlyFormReviewInfo from '../RetirementQuarterlyFormReviewInfo';
import FosterInitialFormReviewInfo from '../FosterInitialFormReviewInfo';
import FosterWeeklyFormReviewInfo from '../FosterWeeklyFormReviewInfo';

const SafetyNetProgramReview = () => {
	const classes = useStyles();
	let { id } = useParams();
	const subType = qs.parse(location?.search)?.subType;
	const [isLoading, setIsLoading] = useState(false);
	const [safeNetProgramReviewForm, setSafeNetProgramReviewForm] = useState();
	const [data, setData] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		switch (subType) {
			case RETIREMENT_FORMS.RETIREMENT_INITIAL_ASSESSMENT:
				AppSyncService.execute(getRetirementInitialAssessmentById, { id }).then(
					(result) => {
						if (result?.data?.getRetirementInitialAssessmentFormById !== null) {
							const {
								data: { getRetirementInitialAssessmentFormById }
							} = result;
							setData(getRetirementInitialAssessmentFormById);
							setSafeNetProgramReviewForm(
								<RetirementInitialFormReviewInfo
									getImage={getImage}
									data={getRetirementInitialAssessmentFormById}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			case RETIREMENT_FORMS.RETIREMENT_QUARTERLY_ASSESSMENT:
				AppSyncService.execute(getRetirementQuarterlyAssessmentById, {
					id
				}).then((result) => {
					if (result?.data?.getRetirementQuarterlyAssessmentFormById !== null) {
						const {
							data: { getRetirementQuarterlyAssessmentFormById }
						} = result;
						setData(getRetirementQuarterlyAssessmentFormById);
						setSafeNetProgramReviewForm(
							<RetirementQuarterlyFormReviewInfo
								getImage={getImage}
								data={getRetirementQuarterlyAssessmentFormById}
							/>
						);
					}
					setIsLoading(false);
				});
				break;
			case FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT:
				AppSyncService.execute(getFosterInitialAssessmentById, { id }).then(
					(result) => {
						if (result?.data?.getFosterInitialAssessmentFormById !== null) {
							const {
								data: { getFosterInitialAssessmentFormById }
							} = result;
							setData(getFosterInitialAssessmentFormById);
							setSafeNetProgramReviewForm(
								<FosterInitialFormReviewInfo
									getImage={getImage}
									data={getFosterInitialAssessmentFormById}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			case FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT:
				AppSyncService.execute(getFosterWeeklyAssessmentById, { id }).then(
					(result) => {
						if (result?.data?.getFosterWeeklyAssessmentFormById !== null) {
							const {
								data: { getFosterWeeklyAssessmentFormById }
							} = result;
							setData(getFosterWeeklyAssessmentFormById);
							setSafeNetProgramReviewForm(
								<FosterWeeklyFormReviewInfo
									data={getFosterWeeklyAssessmentFormById}
									getImage={getImage}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			default:
		}
	}, []);

	const getImage = (img, setImgSrcUrl) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const desktopComponent = (
		<Container className={classes.pageContainer}>
			<SEO title="Safety Net Program Review" />
			<HeaderBanner
				title={`Safety Net Program Review - ${data?.horse?.name}`}
				top
				type="primary"
				rightTitle={`Status: ${data?.status}`}
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			{data && <>{safeNetProgramReviewForm}</>}
		</Container>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default SafetyNetProgramReview;

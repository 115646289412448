const theme = {
	breakpoints: {
		xs: '0px',
		sm: '576px',
		md: '768px',
		lg: '992px',
		xl: '1200px',
		xxl: '1400px'
	},
	colors: {
		link: '#0085ca',
		text: '#464749',
		warning: '#fcf7da',
		lightBlue: '#dcebf3',
		darkBlue: '#006da6',
		gray: '#ededed',
		pink: '#cc3094'
	}
};

export default theme;

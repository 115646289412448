import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		background: '#f5f5f5',
		paddingBottom: 20,
		borderBottom: '1px solid #e5f0f6'
	},
	formContainer: {
		paddingRight: 20,
		paddingLeft: 20
	},
	formHeading: {
		fontSize: 18,
		fontWeight: 600,
		color: '#464749',
		padding: '20px 0 20px 0'
	},
	divider: {
		background: '#e2e2e2',
		height: 2,
		border: 'none',
		marginTop: 35,
		marginBottom: 10
	},
	saveRequest: {
		display: 'flex',
		alignItems: 'center',
		color: '#0085ca',
		fontSize: 14
	},
	saveRequestMobile: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		color: '#0085ca',
		fontSize: 14
	},
	saveButton: {
		marginRight: 20
	},
	topMessage: {
		padding: '10px 0px 10px 5px'
	},
	dateFieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 5,
		marginTop: 20
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	saveButtonContainer: {
		marginTop: 20
	},
	clearFormLink: {
		color: '#0085ca',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	dialogTextColor: {
		color: '#0f0f0f !important'
	},
	statusTitle: {
		color: '#777777',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		fontSize: 20
	},
	fontWeightNormal: {
		fontWeight: 'normal'
	},
	errorStyle: {
		color: '#db0000',
		fontSize: 15
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	table: {
		borderCollapse: 'collapse',
		width: '100%'
	},
	th: {
		paddingTop: 12,
		paddingBottom: 12,
		textAlign: 'left',
		fontSize: 12,
		fontWeight: 400
	},
	thead: {
		border: '1px solid #b3bcc1'
	},
	tr: {
		background: '#f5f5f5'
	},
	td: {
		paddingTop: 12,
		paddingBottom: 12
	},
	thname: {
		paddingLeft: 20,
		paddingTop: 12,
		paddingBottom: 12,
		textAlign: 'left',
		fontSize: 12,
		fontWeight: 400
	},
	thButton: {
		paddingRight: 20
	},
	tbodyName: {
		paddingLeft: 20,
		fontWeight: 600
	},
	tbodyButton: {
		paddingRight: 20,
		marginTop: 5
	},
	tbody: {
		border: '1px solid #b3bcc1'
	},
	trbody: {
		fontSize: 14,
		borderBottom: '1px solid #dcebf3',
		'&:last-child': {
			borderBottom: 'none'
		}
	},
	trbodyLast: {
		fontSize: 14,
		borderBottom: '1px solid #b3bcc1'
	},
	sortIcon: {
		position: 'absolute'
	},
	sort: {
		height: 18
	},
	tdEmptyList: {
		padding: 12,
		fontSize: 14
	}
}));

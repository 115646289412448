import React, { useState, useEffect } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getShareInformationFormById,
	getAdminActionOptionsList
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import ShareInformationDetailsView from '../ShareInformationDetailsView';

const ShareInformationReview = () => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	let { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [shareInformationFormData, setShareInformationFormData] = useState(
		null
	);
	const [adminActionList, setAdminActionList] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getAdminActionOptionsList).then((list) => {
			setAdminActionList(list.data);
			AppSyncService.execute(getShareInformationFormById, {
				id
			}).then((result) => {
				if (result?.data?.getShareInformationFormById != null) {
					setShareInformationFormData(
						result?.data?.getShareInformationFormById
					);
					setIsLoading(false);
				}
			});
		});
	}, []);

	return (
		<Container className={classes.pageContainer}>
			<SEO title="Share Information Review" />
			<HeaderBanner
				title={`Share Information - ${shareInformationFormData?.horse?.name}`}
				top
				type="primary"
				rightTitle={`Status: ${shareInformationFormData?.status}`}
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{shareInformationFormData && (
					<ShareInformationDetailsView
						shareInformationFormData={shareInformationFormData}
						adminActionList={adminActionList}
						mobile={['xs', 'sm'].includes(screenClassForRender)}
					/>
				)}
			</div>
		</Container>
	);
};
export default ShareInformationReview;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	heroImage: {
		/* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
		'background-blend-mode': 'overlay',

		/* Set a specific height */
		height: '285px',

		/* Position and center the image to scale nicely on all screens */
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
		borderRadius: 5,
		backgroundBlendMode: 'multiply'
	},

	heroImageMobile: {
		/* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */

		'background-blend-mode': 'overlay',

		/* Set a specific height */
		height: '285px',

		/* Position and center the image to scale nicely on all screens */
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		position: 'relative',
		borderRadius: 5,
		marginLeft: 10,
		marginRight: 10,
		backgroundBlendMode: 'multiply'
	},

	/* Place text in the middle of the image */
	heroText: {
		textAlign: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: 'black'
	},
	signUpButton: {
		color: '#FFF',
		backgroundColor: '#007706',
		borderColor: '#007706',
		display: 'inline-block',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		verticalAlign: 'middle',
		borderRadius: '.25rem',
		height: 35,
		width: 200,
		border: 'solid #007706',
		fontSize: 20,
		fontWeight: 'bold',
		marginBottom: 10,
		cursor: 'pointer'
	},
	fifteen: {
		fontSize: 15,
		marginBottom: 20
	},
	twenty: {
		fontSize: 20
	},
	twentyBold: {
		extend: 'twenty',
		fontWeight: 'bold'
	}
}));

export const mapArrayToMultiSelectValues = (array, options) => {
	let utilizationOfOttSponsorshipWithValues = [];
	options.forEach((item) => {
		if (array.includes(item.value)) {
			utilizationOfOttSponsorshipWithValues.push(item);
		}
	});
	return utilizationOfOttSponsorshipWithValues;
};

export const mapFileArray = (array) => {
	return array.map((item) => ({
		filename: item.filename,
		key: item.key
	}));
};

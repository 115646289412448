import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';

const StaffDetailsSection = ({ formData }) => {
	return (
		<AdminReviewSection title="Staff Details">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Do you have any staff?"
						value={formData?.staffs?.length > 0 ? 'Yes' : 'No'}
					/>
				</SectionRow>
				{formData?.staffs?.map(({ id, fullName, role: { value: role } }) => (
					<SectionRow key={`ack-retrainer-admin-review-staff-${id}`}>
						<AdminReviewDatum label="Full name" value={fullName} />
						<AdminReviewDatum label="Role" value={role} />
					</SectionRow>
				))}
			</Col>
		</AdminReviewSection>
	);
};

export default StaffDetailsSection;

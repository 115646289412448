import React from 'react';
import { PROGRAMS_CLAIM_THOROUGHBRED_ROUTE } from '../../utils/constants/routes';
import { StyledClaimCountLink, StyledClaimCountWidget } from './styles';

const ClaimCountWidget = ({ claimCount }) =>
	claimCount && (
		<StyledClaimCountLink to={PROGRAMS_CLAIM_THOROUGHBRED_ROUTE}>
			<StyledClaimCountWidget
				title={claimCount}
				description="Horses which are currently claimed on the OTT Community"
			/>
		</StyledClaimCountLink>
	);

export default ClaimCountWidget;

export const getOhepEoiFormReferenceData = `  
    query getOhepEoiFormReferenceData($memberExternalId: String) {
        listOhepEoiRelationships {
            id
            value
        }
        listOhepEoiEligibilityCriteria {
            id
            value
        }
        listHorseGenderTypes {
            id
            value
        }
        listOhepEoiPreferredContactMethod {
            id
            value
        }                        
        getMember(externalId: $memberExternalId) {
            firstName
            lastName
            email
            phone
        }
    }
`;

export const getOhepEoiFormData = `
    query getOhepEoiFormData($guid: String!) {
        getOhepEoiForm(guid: $guid) {
            memberId
            status		
            relationshipToHorse {
                id
                value
            }
            relationshipToHorseOther
			horseName
			microchip
			sire
			dam			
            horseGender {
                id
                value
            }
			age
            eligibilityCriteria {
                id
                value
            }
            preferredContactType {
                id
                value
            }
			contactDetails
            currentAddress 
            currentSuburb 
            currentState 
            currentPostcode 
            currentCountry 
            currentAddressValidated			
            rightSidePhotos {
                created
                filename
                key
                type
            }
            leftSidePhotos {
                created
                filename
                key
                type
            }
        }
    }
`;

import React from 'react';
import useStyles from './styles';
import { navigate } from 'gatsby';
import HorseAvatar from '../../../HorseAvatar';

const FollowedHorseRow = ({ rowData, isLastRow, isMobile }) => {
	const classes = useStyles();

	return (
		<tr className={isLastRow ? classes.trbodyLast : classes.trbody}>
			<td className={classes.tbodyProfilePic}>
				<HorseAvatar
					avatarImageKey={rowData?.horse?.ottInformation?.profilePhoto?.key}
					idPrefix={`my-stable-followed-horses-${rowData?.horse_code}`}
					style={{ marginRight: 10, width: 45, height: 45 }}
					onClick={() => navigate(`/horse-profile/${rowData?.horse_code}`)}
					className={classes.profilePic}
				/>
			</td>
			<td className={classes.td}>
				<span
					className={classes.tbodyName}
					onClick={() => navigate(`/horse-profile/${rowData.horse_code}`)}
				>
					{rowData?.horse?.ottInformation?.paddockName ?? rowData?.horse?.name}
				</span>
			</td>
			<td className={classes.td}>{rowData?.horse?.colour}</td>
			{!isMobile && (
				<>
					<td className={classes.td}>{rowData?.horse?.sex}</td>
					<td className={classes.td}>
						{rowData?.claims.length > 0 ? 'Yes' : 'No'}
					</td>
				</>
			)}
		</tr>
	);
};

export default FollowedHorseRow;

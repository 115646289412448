import * as yup from 'yup';

export const schema = yup.object().shape({
	organisation: yup
		.string()
		.required('Organisation is required')
		.typeError('Organisation is required'),
	representativeName: yup
		.string()
		.required('Representative Name is required')
		.typeError('Representative Name is required'),
	role: yup.string().required('Role is required').typeError('Role is required'),
	contactDetails: yup
		.string()
		.required('Contact Details is required')
		.typeError('Contact Details is required'),
	reportDate: yup
		.date()
		.required('Report Date is required')
		.typeError('Report Date is required'),
	effectiveDateAgreement: yup
		.date()
		.required('Effective date of Sponsorship Agreement is required')
		.typeError('Effective date of Sponsorship Agreement is required'),
	conclusionDateAgreement: yup
		.date()
		.required('Conclusion date of Sponsorship Agreement is required')
		.typeError('Conclusion date of Sponsorship Agreement is required'),
	totalValueAgreement: yup
		.string()
		.required('Total value of Sponsorship Agreement is required')
		.typeError('Total value of Sponsorship Agreement is required'),
	utilizationOfOttSponsorship: yup
		.array()
		.required('Question is required')
		.min(1, 'At least one selection is required'),
	utilizationOfOttSponsorshipOther: yup
		.string()
		.when('utilizationOfOttSponsorship', {
			is: (items) => {
				return items.some((item) => item?.value == 4);
			},
			then: () =>
				yup
					.string()
					.required('Other details of Sponsorship Agreement is required')
					.typeError('Other details of Sponsorship Agreement is required'),
			otherwise: () => yup.string().nullable()
		}),
	numberOfEventsIncluded: yup
		.string()
		.required('Selection is required')
		.typeError('Selection is required'),
	totalOttClassesRun: yup
		.number()
		.required(
			'Total OTT classes run during this Sponsorship Agreement is required'
		)
		.typeError(
			'Total OTT classes run during this Sponsorship Agreement is required'
		),
	overallEntrantsNumber: yup
		.number()
		.required('Number of overall entrants is required')
		.typeError('Number of overall entrants is required'),
	ottEntrantsNumber: yup
		.number()
		.required('Number of OTT entrants is required')
		.typeError('Number of OTT entrants is required'),
	discipline: yup
		.array()
		.required('Discipline is required')
		.min(1, 'At least one selection is required'),
	disciplineOther: yup.string().when('discipline', {
		is: (items) => {
			return items.some((item) => item.label === 'Other');
		},
		then: () =>
			yup
				.string()
				.required('Other Discipline/s included is required')
				.typeError('Other Discipline/s included is required'),
		otherwise: () => yup.string().nullable()
	}),
	isPrizeMoneyDistributed: yup.boolean().nullable(),
	amountOfPrizeMoneyDistributed: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	numberOfFacebookEventPosts: yup
		.number()
		.required('Number of event posts, Facebook is required')
		.typeError('Number of event posts, Facebook is required'),
	numberOfInstagramEventPosts: yup
		.number()
		.required('Number of event posts, Instagram is required')
		.typeError('Number of event posts, Instagram is required'),
	otherSocialMediaUpdates: yup.string().nullable(),
	numberOfFacebookPostShare: yup
		.number()
		.required('Number of OTT post shares, Facebook is required')
		.typeError('Number of OTT post shares, Facebook is required'),
	numberOfInstagramPostShare: yup
		.number()
		.required('Number of OTT post shares, Instagram is required')
		.typeError('Number of OTT post shares, Instagram is required'),
	otherComments: yup.string().nullable(),
	signageDescription: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	signageOrPromotionPhotos: yup.array().nullable(),
	eventPhotos: yup.array().nullable(),
	whatWorkedWellQuestion: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	whatDidNotWorkQuestion: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	howToImproveQuestion: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	keyAchievements: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	feedbackFromMembers: yup
		.string()
		.required('Question is required')
		.typeError('Question is required'),
	isAnyPathwaysInititiativesCreated: yup.boolean().nullable(),
	competitiveOrNonCompetitive: yup
		.array()
		.when('isAnyPathwaysInititiativesCreated', {
			is: true,
			then: () =>
				yup
					.array()
					.required('Competitive or Non-competitive question is required')
					.min(1, 'At least one selection is required'),
			otherwise: () => yup.array().nullable()
		}),
	numberOfNewPathways: yup.number().when('isAnyPathwaysInititiativesCreated', {
		is: true,
		then: () =>
			yup
				.number()
				.required('Number of new pathways is required')
				.typeError('Number of new pathways is required'),
		otherwise: () => yup.number().nullable()
	}),
	isIncreasedUpstakeOfThoroughbreds: yup.boolean().nullable(),
	numberOfNewOtts: yup.number().when('isIncreasedUpstakeOfThoroughbreds', {
		is: true,
		then: () =>
			yup
				.number()
				.required('Number of new OTTs is required')
				.typeError('Number of new OTTs is required'),
		otherwise: () => yup.number().nullable()
	}),
	commentsOrSuggestions: yup.string().nullable()
});

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
    container: {
        border: "1px solid #b3bcc1",
    },
    fieldTitle: {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 7,
        color: "#464749"
    },
    divider: {
        background: "#edf5f9",
        height: 2,
        border: "none",
    },
    notesContainer: {
        padding: "20px 18px 20px 18px",
        marginLeft: 20,
        marginRight: 20,
    },
    loadingDiv: {
        marginTop: 20,
        position: "absolute",
        left: "45%",
        top: "50%",
        zIndex: 3,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 17px 17px 17px",
        marginTop: 20,
        marginBottom: 15,
    },
    saveRequest: {
        color: "#0085ca",
        fontSize: 14,
        cursor: "pointer",
    },
    primaryFormButton: {
        marginLeft: 20,
        backgroundColor: "#006da6",
        border: "none",
        borderRadius: 4,
        color: "white",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        height: 38,
        fontWeight: 600,
        width: 170,
        fontSize: 16,
        "&:focus": {
            outline: "none",
        },
    },
    secondaryFormButton: {
        marginLeft: 20,
        backgroundColor: "#DCEBF3",
        border: "none",
        borderRadius: 4,
        color: "#006DA6",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        height: 38,
        fontWeight: 600,
        width: 170,
        fontSize: 16,
        "&:focus": {
            outline: "none",
        },
    },
    disabledFormButton: {
        marginLeft: 20,
        backgroundColor: "#d8d8d8",
        border: "none",
        borderRadius: 4,
        color: "#b2b2b2",
        textAlign: "center",
        textDecoration: "none",
        display: "inline-block",
        fontWeight: 600,
        height: 38,
        width: 170,
        fontSize: 16,
        "&:focus": {
            outline: "none",
        },
    },
}));

import React from 'react';
import { Col, Row } from 'react-grid-system';
import classnames from 'classnames';
import useStyles from '../../../AdminReviewFormApproval/styles';
import LoaderSpinner from '../../../LoaderSpinner';
import { REVIEW } from '../../../../utils/constants';
import ResizingTextArea from '../../../ResizingTextArea';

const AdminReviewFormApproval = ({
	isProcessingApprove,
	isProcessingReject,
	mobile,
	processReject,
	processApprove,
	notificationToUser,
	updateNotificationToUser,
	noHeader,
	errorMessage,
	totalLength,
	formName,
	rejectingApplication,
	status,
	isFormInvalid
}) => {
	const classes = useStyles();

	return (
		<>
			<div
				className={classnames({
					[classes.container]: true
				})}
				style={
					mobile ? { border: 'none' } : noHeader ? { borderTop: 'none' } : {}
				}
			>
				<div>
					{rejectingApplication && (
						<div>
							<Row>
								<Col
									className={classes.notesContainer}
									style={{ marginLeft: 20 }}
								>
									<ResizingTextArea
										id={`${formName}-formReview-rejectReason`}
										value={notificationToUser}
										title="Notification to user*"
										maxChars={totalLength}
										canEdit={status == REVIEW}
										onChange={(e) => updateNotificationToUser(e.target.value)}
									/>
								</Col>
							</Row>
							<hr className={classes.divider}></hr>
						</div>
					)}
					{status === REVIEW && (
						<>
							<div className={classes.buttonContainer}>
								{!mobile && (
									<div className={classes.errorMessage}>{errorMessage}</div>
								)}
								<div
									className={
										mobile ? classes.saveRequestMobile : classes.saveRequest
									}
								>
									{isFormInvalid && (
										<span className={classes.errorMessage}>
											Please complete all required fields
										</span>
									)}
									{rejectingApplication ? (
										<div
											style={
												!mobile
													? { display: 'inline-block' }
													: { dispaly: 'block' }
											}
										>
											<button
												id={`${formName}-formReview-reject`}
												type="button"
												onClick={() => processReject()}
												disabled={!notificationToUser}
												className={
													mobile && !notificationToUser
														? `${classes.secondaryFormButtonMobile} ${classes.disabledFormButtonMobile}`
														: mobile
														? classes.secondaryFormButtonMobile
														: !notificationToUser
														? `${classes.secondaryFormButton} ${classes.disabledFormButtonMobile}`
														: classes.secondaryFormButton
												}
											>
												{isProcessingReject && (
													<LoaderSpinner
														status={true}
														height="14"
														color="black"
													/>
												)}
												{!isProcessingReject && 'Reject'}
											</button>
										</div>
									) : (
										<div
											style={
												!mobile
													? { display: 'inline-block' }
													: { dispaly: 'block' }
											}
										>
											<button
												id={`${formName}-formReview-approve`}
												type="button"
												className={
													mobile
														? classes.primaryFormButtonMobile
														: classes.primaryFormButton
												}
												onClick={() => processApprove()}
											>
												{isProcessingApprove && (
													<LoaderSpinner
														status={true}
														height="14"
														color="black"
													/>
												)}
												{!isProcessingApprove && 'Approve'}
											</button>
										</div>
									)}
								</div>
							</div>
							{mobile && (
								<div className={classes.errorMessageMobile}>{errorMessage}</div>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};
export default AdminReviewFormApproval;

import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'react-grid-system';
import { ScreenClassRender } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import {
	saveBusinessProfileStatusMutation,
	getBusinessProfilesByMemberId
} from '../../graphql/custom.queries';

import LoaderSpinner from '../LoaderSpinner';
import BusinessProfileDetails from '../BusinessProfileDetails';
import BusinessContactDetails from '../BusinessContactDetails';
import BusinessPropertyDetails from '../BusinessPropertyDetails';
import BusinessInfoDetails from '../BusinessInfoDetails';
import BusinessSocialDetails from '../BusinessSocialDetails';
import BusinessMediaDetails from '../BusinessMediaDetails';
import PrimaryButton from '../PrimaryButton';
import { navigate } from 'gatsby-link';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import {
	BusinessProfileType,
	ProfileKeys,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	APPROVED
} from '../../utils/constants';
import FosterProviderInformation from '../FosterProviderInformation';

const BusinessProfile = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [businessProfileData, setBusinessProfileData] = useState(null);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [hasValidProfileData, setHasValidProfileData] = useState(false);
	const [hasValidContactData, setHasValidContactData] = useState(false);
	const [hasValidPropertyData, setHasValidPropertyData] = useState(false);
	const [hasValidInfoData, setHasValidInfoData] = useState(false);
	const [hasValidMediaData, setHasValidMediaData] = useState(false);
	const [isLoadedBusinessProfiles, setIsLoadedBusinessProfiles] = useState(
		false
	);
	const businessContactDetailsRef = useRef();
	const businessPropertyDetailsRef = useRef();
	const businessInfoDetailsRef = useRef();
	const businessMediaDetailsRef = useRef();
	const businessDetailsRef = useRef();
	const businessDetailsFosterRef = useRef();
	const businessInfoDetailsFosterRef = useRef();
	const REVIEW_STATUS = 'Review';
	const [businessProfilesData, setBusinessProfilesData] = useState([]);
	const [
		selectedBusinessProfileType,
		setSelectedBusinessProfileType
	] = useState(1);
	const [isSubmittingForReview, setIsSubmittingForReview] = useState(false);

	const [hasValidBusinessDetails, setHasValidBusinessDetails] = useState(false);
	const [
		hasValidProviderInformation,
		setHasValidProviderInformation
	] = useState(false);

	const ackRetirementFarmRoleExist = currentUser?.Roles.some(
		(role) => role === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
	);
	const ackRetrainerRoleExist = currentUser?.Roles.some(
		(role) => role === ROLE_RV_ACKNOWLEDGED_RETRAINER
	);

	const ackFosterRoleExist = currentUser?.Roles.some(
		(role) => role === ROLE_ACKNOWLEDGED_FOSTER_CARER
	);

	const validateFields = () => {
		businessContactDetailsRef?.current?.triggerValidation();
		businessPropertyDetailsRef?.current?.triggerValidation();
		businessInfoDetailsRef?.current?.triggerValidation();
		businessMediaDetailsRef?.current?.triggerValidation();
		businessDetailsRef?.current?.triggerValidation();
		businessDetailsFosterRef?.current?.triggerValidation();
		businessInfoDetailsFosterRef?.current?.triggerValidation();
	};

	useEffect(() => {
		setIsLoading(true);
		//Retrainer default
		let currentTempSelectBusinessProfile = 1;
		// Default retrainer selected if retrainer role exist
		if (currentUser?.Roles.length === 1) {
			switch (currentUser.Roles[0]) {
				case ROLE_RV_ACKNOWLEDGED_RETRAINER:
					setSelectedBusinessProfileType(1);
					currentTempSelectBusinessProfile = 1;
					break;
				case ROLE_ACKNOWLEDGED_RETIREMENT_FARM:
					setSelectedBusinessProfileType(2);
					currentTempSelectBusinessProfile = 2;
					break;
				case ROLE_ACKNOWLEDGED_FOSTER_CARER:
					setSelectedBusinessProfileType(3);
					currentTempSelectBusinessProfile = 3;
					break;
				default:
					setSelectedBusinessProfileType(1);
					currentTempSelectBusinessProfile = 1;
					break;
			}
		} else {
			//TODO REFACTOR
			if (
				currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER) &&
				(!currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER) ||
					!currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_RETIREMENT_FARM))
			) {
				if (currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
					setSelectedBusinessProfileType(1);
					currentTempSelectBusinessProfile = 1;
				} else if (
					currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER)
				) {
					setSelectedBusinessProfileType(3);
					currentTempSelectBusinessProfile = 3;
				} else {
					setSelectedBusinessProfileType(2);
					currentTempSelectBusinessProfile = 2;
				}
			} else {
				if (currentUser?.Roles.includes(ROLE_RV_ACKNOWLEDGED_RETRAINER)) {
					setSelectedBusinessProfileType(1);
					currentTempSelectBusinessProfile = 1;
				} else if (
					currentUser?.Roles.includes(ROLE_ACKNOWLEDGED_RETIREMENT_FARM)
				) {
					setSelectedBusinessProfileType(2);
					currentTempSelectBusinessProfile = 2;
				}
			}
		}

		AppSyncService.execute(getBusinessProfilesByMemberId, {
			memberExternalId: currentUser?.UserId
		}).then((result) => {
			setIsLoadedBusinessProfiles(true);
			if (result?.data?.getBusinessProfilesByMemberId.length !== 0) {
				const {
					data: { getBusinessProfilesByMemberId }
				} = result;
				setBusinessProfilesData(getBusinessProfilesByMemberId);

				//get the first item if not null or undefined
				let filterBusinessProfileToDisplay = getBusinessProfilesByMemberId.filter(
					(businessProfileItem) =>
						businessProfileItem?.businessProfileType?.id ===
						currentTempSelectBusinessProfile
				);

				if (filterBusinessProfileToDisplay.length === 0) {
					let selectedProfileType = 1;
					if (ackRetirementFarmRoleExist && ackRetrainerRoleExist) {
						selectedProfileType = 1;
					} else {
						if (ackRetrainerRoleExist) {
							selectedProfileType = 1;
						} else if (ackRetirementFarmRoleExist) {
							selectedProfileType = 2;
						} else if (ackFosterRoleExist) {
							selectedProfileType = 3;
						}
					}

					setBusinessProfileData(onHandleState(selectedProfileType));
					filterBusinessProfileToDisplay = onHandleState(selectedProfileType);
				} else {
					filterBusinessProfileToDisplay = filterBusinessProfileToDisplay.find(
						() => true
					);
				}

				setBusinessProfileData(filterBusinessProfileToDisplay);
				setSelectedBusinessProfileType(
					filterBusinessProfileToDisplay?.businessProfileType?.id
				);
				if (currentTempSelectBusinessProfile === 3) {
					onValidateFieldsToSubmitFoster(filterBusinessProfileToDisplay);
				} else {
					onValidateFieldsToSubmit(filterBusinessProfileToDisplay);
				}
			} else {
				setIsSubmitDisabled(true);
			}
		});

		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (isSubmitted === false) {
			if (selectedBusinessProfileType === 3) {
				if (
					hasValidBusinessDetails &&
					hasValidProviderInformation &&
					hasValidMediaData
				) {
					setIsSubmitDisabled(false);
				} else {
					setIsSubmitDisabled(true);
				}
			} else {
				if (
					hasValidProfileData &&
					hasValidContactData &&
					hasValidPropertyData &&
					hasValidInfoData &&
					hasValidMediaData
				) {
					setIsSubmitDisabled(false);
				} else {
					setIsSubmitDisabled(true);
				}
			}
		}
	}, [
		hasValidProfileData,
		hasValidContactData,
		hasValidPropertyData,
		hasValidInfoData,
		hasValidMediaData,
		isSubmitted,
		hasValidBusinessDetails,
		hasValidProviderInformation
	]);

	const onValidateFieldsToSubmitFoster = (filterBusinessProfileToDisplay) => {
		if (
			filterBusinessProfileToDisplay &&
			filterBusinessProfileToDisplay.status &&
			filterBusinessProfileToDisplay.status !== 'Draft'
		) {
			setIsSubmitted(true);
			setIsSubmitDisabled(true);
		}
		if (
			filterBusinessProfileToDisplay &&
			filterBusinessProfileToDisplay.status &&
			filterBusinessProfileToDisplay.status === 'Draft'
		) {
			setIsSubmitted(false);
		}

		if (
			!filterBusinessProfileToDisplay.firstName &&
			!filterBusinessProfileToDisplay.lastName &&
			!filterBusinessProfileToDisplay.email &&
			!filterBusinessProfileToDisplay.businessPhone &&
			!filterBusinessProfileToDisplay.businessName &&
			!filterBusinessProfileToDisplay.state &&
			!filterBusinessProfileToDisplay.postcode &&
			!filterBusinessProfileToDisplay.streetAddress &&
			!filterBusinessProfileToDisplay.suburb
		) {
			setHasValidBusinessDetails(false);
		} else {
			setHasValidBusinessDetails(true);
		}

		if (filterBusinessProfileToDisplay.facilitiesOnProperty.length !== 0) {
			setHasValidProviderInformation(true);
		} else {
			setHasValidProviderInformation(false);
		}
	};

	const onValidateFieldsToSubmit = (filterBusinessProfileToDisplay) => {
		if (
			filterBusinessProfileToDisplay &&
			filterBusinessProfileToDisplay.status &&
			filterBusinessProfileToDisplay.status !== 'Draft'
		) {
			setIsSubmitted(true);
			setIsSubmitDisabled(true);
		}
		if (
			filterBusinessProfileToDisplay &&
			filterBusinessProfileToDisplay.status &&
			filterBusinessProfileToDisplay.status === 'Draft'
		) {
			setIsSubmitted(false);
		}
		const filteredLogoPhotos = filterBusinessProfileToDisplay
			? filterBusinessProfileToDisplay.logo.filter(
					(logo) => logo.businessProfileId == filterBusinessProfileToDisplay.id
			  )
			: [];

		if (
			!filterBusinessProfileToDisplay.businessName &&
			filteredLogoPhotos.length < 0
		) {
			setHasValidProfileData(false);
		} else {
			setHasValidProfileData(true);
		}
		if (
			!filterBusinessProfileToDisplay.firstName &&
			!filterBusinessProfileToDisplay.lastName &&
			!filterBusinessProfileToDisplay.email &&
			!filterBusinessProfileToDisplay.businessPhone
		) {
			setHasValidContactData(false);
		} else {
			setHasValidContactData(true);
		}
		if (
			!filterBusinessProfileToDisplay.streetAddress &&
			!filterBusinessProfileToDisplay.suburb &&
			!filterBusinessProfileToDisplay.state &&
			!filterBusinessProfileToDisplay.postcode
		) {
			setHasValidPropertyData(false);
		} else {
			setHasValidPropertyData(true);
		}
		const validatationForRetirementFarm = !ackRetirementFarmRoleExist
			? filterBusinessProfileToDisplay.specialtyDisciplines.length !== 0
			: true;
		if (
			filterBusinessProfileToDisplay.businessSummary &&
			filterBusinessProfileToDisplay.facilitiesOnProperty.length !== 0 &&
			validatationForRetirementFarm &&
			filterBusinessProfileToDisplay.servicesOffered.length !== 0
		) {
			setHasValidInfoData(true);
		} else {
			setHasValidInfoData(false);
		}

		const filteredRiddenPhotos = filterBusinessProfileToDisplay
			? filterBusinessProfileToDisplay.riddenPhotos.filter(
					(ridden) =>
						ridden.businessProfileId == filterBusinessProfileToDisplay.id
			  )
			: [];
		const filteredPropertyPhotos = filterBusinessProfileToDisplay
			? filterBusinessProfileToDisplay.propertyPhotos.filter(
					(property) =>
						property.businessProfileId == filterBusinessProfileToDisplay.id
			  )
			: [];

		const filteredGeneralPhotos = filterBusinessProfileToDisplay
			? filterBusinessProfileToDisplay.generalPhotos.filter(
					(property) =>
						property.businessProfileId == filterBusinessProfileToDisplay.id
			  )
			: [];
		if (filterBusinessProfileToDisplay.businessProfileType.id !== 2) {
			if (
				filteredRiddenPhotos.length > 0 &&
				filteredPropertyPhotos.length > 0
			) {
				setHasValidMediaData(true);
			} else {
				setHasValidMediaData(false);
			}
		} else {
			const totalCountOfImages =
				filteredRiddenPhotos.length +
				filteredPropertyPhotos.length +
				filteredGeneralPhotos.length;
			if (totalCountOfImages < 2 && filteredPropertyPhotos.length === 0) {
				setHasValidMediaData(false);
			} else {
				setHasValidMediaData(true);
			}
		}
	};

	const onChangeBusinessType = (selectedProfileType) => {
		const selectedBusinessProfile = businessProfilesData.find(
			(profileType) => profileType.businessProfileType.id == selectedProfileType
		);
		if (selectedBusinessProfile) {
			setBusinessProfileData(selectedBusinessProfile);
			if (selectedProfileType == 3) {
				onValidateFieldsToSubmitFoster(selectedBusinessProfile);
			} else {
				onValidateFieldsToSubmit(selectedBusinessProfile);
			}
		} else {
			setBusinessProfileData(onHandleState(selectedProfileType));
			if (selectedProfileType == 3) {
				onValidateFieldsToSubmitFoster(onHandleState(selectedProfileType));
			} else {
				onValidateFieldsToSubmit(onHandleState(selectedProfileType));
			}
		}
		setSelectedBusinessProfileType(parseInt(selectedProfileType));
	};

	const onSaveBusinessProfileData = async (state) => {
		onHandleChangeState(state);
	};

	const onHandleChangeState = (state) => {
		let tempBusinessProfilesData = businessProfilesData;
		const businessProfileTypeExist = tempBusinessProfilesData.some(
			(profileItem) =>
				profileItem?.businessProfileType?.id === selectedBusinessProfileType
		);
		if (!businessProfileTypeExist) {
			const handleState = state?.businessProfileId
				? onHandleState(selectedBusinessProfileType, state.businessProfileId)
				: onHandleState(selectedBusinessProfileType);

			tempBusinessProfilesData = [...tempBusinessProfilesData, handleState];
		}

		// handle changing state for each business type
		const stateName = Object.keys(state);
		stateName.forEach((name) => {
			tempBusinessProfilesData.map((profileItem) => {
				if (
					profileItem?.businessProfileType?.id == selectedBusinessProfileType
				) {
					profileItem[`${name}`] = state[`${name}`];
				}
				return profileItem;
			});
		});
		const selectedBusinessProfileTemp = tempBusinessProfilesData.find(
			(selectedBusiness) =>
				selectedBusiness?.businessProfileType?.id ===
				selectedBusinessProfileType
		);

		setBusinessProfilesData(tempBusinessProfilesData);
		if (selectedBusinessProfileType === 3) {
			onValidateFieldsToSubmitFoster(selectedBusinessProfileTemp);
		} else {
			onValidateFieldsToSubmit(selectedBusinessProfileTemp);
		}
	};

	const updateIsLoading = (value) => {
		setIsLoading(value);
	};

	const submitForReview = () => {
		setIsSubmittingForReview(true);

		try {
			AppSyncService.execute(getBusinessProfilesByMemberId, {
				memberExternalId: currentUser?.UserId
			}).then((result) => {
				if (result?.data?.getBusinessProfilesByMemberId.length !== 0) {
					const {
						data: { getBusinessProfilesByMemberId }
					} = result;
					const businessProfileID = getBusinessProfilesByMemberId.find(
						(selectedBusinessProfile) =>
							selectedBusinessProfile?.businessProfileType?.id ===
							selectedBusinessProfileType
					)?.id;
					AppSyncService.execute(saveBusinessProfileStatusMutation, {
						id: businessProfileID,
						status: selectedBusinessProfileType === 3 ? APPROVED : REVIEW_STATUS
					}).then((data) => {
						if (data.data != null) {
							addToast({
								Message: 'Business Profile Submitted',
								IsSuccess: true
							});
							navigate(DASHBOARD_ROUTE);
							setIsSubmittingForReview(false);
						}
					});
				}
			});
		} catch (error) {
			if (error) {
				setBackendErrorMessage(error);
			} else {
				setBackendErrorMessage(
					'Unknown error while submitting data. Please contact support'
				);
			}
			setIsSubmittingForReview(false);
		}
	};

	//TODO: moved to other file
	const onHandleState = (
		selectedProfileType,
		selectedBusinessProfileId = 0
	) => {
		return {
			id: selectedBusinessProfileId,
			status: 'Draft',
			businessProfileType: {
				id: selectedProfileType,
				value: BusinessProfileType.find(
					(profileType) => profileType.id == selectedBusinessProfileType
				).value
			},
			businessName: '',
			logo: [],
			firstName: '',
			firstNameDisplayOption: {
				value: '',
				id: 1
			},
			lastName: '',
			lastNameDisplayOption: {
				id: 1,
				value: ''
			},
			email: '',
			emailDisplayOption: {
				id: 1,
				value: ''
			},
			businessPhone: '',
			businessPhoneDisplayOption: {
				id: 1,
				value: ''
			},
			fullAddress: '',
			streetAddress: '',
			streetAddressDisplayOption: {
				id: 1,
				value: ''
			},
			suburb: '',
			suburbDisplayOption: {
				id: 1,
				value: ''
			},
			postcode: '',
			postcodeDisplayOption: {
				id: 1,
				value: ''
			},
			state: '',
			stateDisplayOption: {
				id: 1,
				value: ''
			},
			finalNotes: '',
			isMaximumCapacity: '',
			maximumCapacity: '',
			rangeOfFosterCare: '',
			isAdviseDatesUnavailable: false,
			dateUnavailableEnd: null,
			dateUnavailableStart: null,
			alternativeContact: '',
			alternativePhone: null,
			businessSummary: '',
			facilitiesOnProperty: [],
			facilitiesOffSite: [],
			specialtyDisciplines: [],
			servicesOffered: [],
			facilitiesOffSiteOther: null,
			facilitiesOnPropertyOther: null,
			specialtyDisciplinesOther: null,
			servicesOfferedOther: null,
			priceRange: null,
			experiences: '',
			twitter: null,
			instagram: null,
			facebook: null,
			website: null,
			riddenPhotos: [],
			propertyPhotos: [],
			generalPhotos: []
		};
	};

	const desktopComponent = (
		<>
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<Container>
				<Row align="center" justify="around" className={classes.infoDiv}>
					<Col lg={9} sm={12}>
						{selectedBusinessProfileType !== 3
							? `Once your changes have been submitted, Racing Victoria will review
								before publishing.`
							: `Racing Victoria’s Equine Welfare team will be able to view these details on a STAR Provider
								profile once submitted. You can return to this page and edit as required.`}
					</Col>
					<Col lg={3} sm={12}>
						{!isSubmittingForReview ? (
							<PrimaryButton
								id={ProfileKeys.SUBMIT_FOR_REVIEW}
								onClick={submitForReview}
								style={{ marginTop: 0, float: 'right' }}
								disabled={isSubmitDisabled}
							>
								{isSubmitted
									? 'Submitted'
									: selectedBusinessProfileType !== 3
									? 'Submit for Review'
									: 'Submit Profile'}
							</PrimaryButton>
						) : (
							<div>
								<LoaderSpinner status={true} />
							</div>
						)}
					</Col>
				</Row>
			</Container>
			<HeaderBanner
				title="Business Profile Type"
				top
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			<BusinessProfileDetails
				currentUser={currentUser}
				businessProfileData={businessProfileData}
				isLoading={isLoading}
				updateIsLoading={updateIsLoading}
				setIsSubmitted={setIsSubmitted}
				onChangeBusinessType={onChangeBusinessType}
				businessProfileType={selectedBusinessProfileType}
				onSaveBusinessProfileData={onSaveBusinessProfileData}
				setHasValidProfileData={setHasValidProfileData}
				businessProfilesData={businessProfilesData}
				setBusinessProfilesData={setBusinessProfilesData}
				ref={businessDetailsRef}
				validateFields={validateFields}
				businessDetailsFosterRef={businessDetailsFosterRef}
			/>

			{selectedBusinessProfileType !== 3 && (
				<>
					<HeaderBanner
						title="Business Contact Details"
						inline
						styles={{ marginBottom: '0px', marginTop: '-5px' }}
					/>
					<BusinessContactDetails
						currentUser={currentUser}
						isLoadedBusinessProfiles={isLoadedBusinessProfiles}
						businessProfileData={businessProfileData}
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						setIsSubmitted={setIsSubmitted}
						businessProfileType={selectedBusinessProfileType}
						ref={businessContactDetailsRef}
						validateFields={validateFields}
						onSaveBusinessProfileData={onSaveBusinessProfileData}
					/>
					<HeaderBanner
						title="Business Property"
						inline
						styles={{ marginBottom: '0px', marginTop: '-5px' }}
					/>
					<BusinessPropertyDetails
						currentUser={currentUser}
						businessProfileData={businessProfileData}
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						setIsSubmitted={setIsSubmitted}
						ref={businessPropertyDetailsRef}
						validateFields={validateFields}
						businessProfileType={selectedBusinessProfileType}
						onSaveBusinessProfileData={onSaveBusinessProfileData}
					/>
				</>
			)}

			<HeaderBanner
				title={
					selectedBusinessProfileType !== 3
						? 'Business Information'
						: 'STAR Provider Information'
				}
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>
			{selectedBusinessProfileType !== 3 ? (
				<BusinessInfoDetails
					currentUser={currentUser}
					businessProfileData={businessProfileData}
					isLoading={isLoading}
					updateIsLoading={updateIsLoading}
					setIsSubmitted={setIsSubmitted}
					ref={businessInfoDetailsRef}
					validateFields={validateFields}
					businessProfileType={selectedBusinessProfileType}
					onSaveBusinessProfileData={onSaveBusinessProfileData}
				/>
			) : (
				<FosterProviderInformation
					currentUser={currentUser}
					businessProfileData={businessProfileData}
					isLoading={isLoading}
					updateIsLoading={updateIsLoading}
					setIsSubmitted={setIsSubmitted}
					businessProfileType={selectedBusinessProfileType}
					onSaveBusinessProfileData={onSaveBusinessProfileData}
					ref={businessInfoDetailsFosterRef}
					validateFields={validateFields}
				/>
			)}

			{selectedBusinessProfileType !== 3 && (
				<>
					<HeaderBanner
						title="Social Links"
						inline
						styles={{ marginBottom: '0px', marginTop: '-5px' }}
					/>
					<BusinessSocialDetails
						currentUser={currentUser}
						businessProfileData={businessProfileData}
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						setIsSubmitted={setIsSubmitted}
						validateFields={validateFields}
						businessProfileType={selectedBusinessProfileType}
						onSaveBusinessProfileData={onSaveBusinessProfileData}
					/>
				</>
			)}

			<HeaderBanner
				title="Media"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>
			<BusinessMediaDetails
				currentUser={currentUser}
				businessProfileData={businessProfileData}
				isLoading={isLoading}
				updateIsLoading={updateIsLoading}
				setIsSubmitted={setIsSubmitted}
				businessProfileType={selectedBusinessProfileType}
				ref={businessMediaDetailsRef}
				validateFields={validateFields}
				onSaveBusinessProfileData={onSaveBusinessProfileData}
				setHasValidMediaData={setHasValidMediaData}
				businessProfilesData={businessProfilesData}
			/>

			<Container>
				<Row
					align="center"
					justify="around"
					className={classes.infoDiv}
					style={{ marginTop: 20 }}
				>
					<Col lg={9} sm={12}>
						<p>
							{selectedBusinessProfileType !== 3
								? `Once your changes have been submitted, Racing Victoria will review
								before publishing.`
								: `Racing Victoria’s Equine Welfare team will be able to view these details on a STAR Provider
								profile once submitted. You can return to this page and edit as required.`}
						</p>
					</Col>
					<Col lg={3} sm={12}>
						{!isSubmittingForReview ? (
							<PrimaryButton
								id={ProfileKeys.SUBMIT_FOR_REVIEW}
								onClick={submitForReview}
								style={{ marginTop: 0, float: 'right' }}
								disabled={isSubmitDisabled}
							>
								{isSubmitted
									? 'Submitted'
									: selectedBusinessProfileType !== 3
									? 'Submit for Review'
									: 'Submit Profile'}
							</PrimaryButton>
						) : (
							<div>
								<LoaderSpinner status={true} />
							</div>
						)}
					</Col>
				</Row>
			</Container>

			<div>
				{backendErrorMessage && (
					<span className={classes.errorMessage}>{backendErrorMessage}</span>
				)}
			</div>
		</>
	);

	//const mobileComponent = <div>Coming soon</div>;

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default BusinessProfile;

import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import axios from 'axios';
import SEO from '../seo';
import LoaderSpinner from '../LoaderSpinner';
import {
	StyledCancelRequest,
	StyledContainer,
	StyledErrorMessage,
	StyledErrorText,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledForm,
	StyledFormActionLink,
	StyledFormActionsRow,
	StyledFormEndDivider,
	StyledFieldText,
	StyledFormFieldsContainer,
	StyledFormSubmitButton,
	StyledLoadingContainer,
	StyledSaveDraft
} from './style';
import { APPROVED, DRAFT } from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import StyledAccordion from './StyledAccordion';
import { Col, Row } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteOhepNTPFile,
	saveOhepNTPFile,
	saveOhepNTPForm,
	uploadImageToS3,
	generateOhepNoticeToProceed
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './schema';
import { useParams } from '@reach/router';
import UploadPhoto from '../UploadPhoto';
import useFilePreview from '../../hooks/useFilePreview';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import {
	OHEP_NTP_FORM_DEFAULT_VALUES,
	OHEP_NTP_FORM_CLEAR_VALUES
} from './constants';
import {
	getOhepNTPFormData,
	getOhepNominationApprovedFormData
} from './queries';
import {
	generatePayload,
	setFormValuesFromApiResult,
	setDefaultValuesFromNominationForm
} from './helper';
import { logToServer } from '../../utils/logger';
import { navigate } from 'gatsby';
import { formatDateReadableVariant } from '../../utils/helpers';

const OHEPNoticeToProceedForm = () => {
	const PAGE_ID = 'ohep-NTP-Form';
	const [formId, setFormId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [typeOfServiceOptions, setTypeOfServiceOptions] = useState([]);
	const [horseGenderOptions, setHorseGenderOptions] = useState([]);
	const [horseColourOptions, setHorseColourOptions] = useState([]);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isFormError, setIsFormError] = useState(false);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		clearErrors,
		formState: { errors, isSubmitted },
		reset
	} = useForm({
		mode: 'onSubmit',
		defaultValues: OHEP_NTP_FORM_DEFAULT_VALUES,
		resolver: yupResolver(schema)
	});
	const { id: horseCode } = useParams();
	const filePreviewHandler = useFilePreview();
	const values = watch();

	const fetchData = async () => {
		try {
			const { data } = await AppSyncService.execute(getOhepNTPFormData, {
				memberExternalId: currentUser.UserId,
				horseCode
			});
			setTypeOfServiceOptions([
				{ id: 0, value: '' },
				...data.listOhepNTPServiceTypes
			]);

			let modifiedListHorseGenders = data.listHorseGenders;
			modifiedListHorseGenders.unshift('');
			setHorseGenderOptions(
				modifiedListHorseGenders.map((item) => {
					return {
						id: item,
						value: item
					};
				})
			);
			let modifiedListHorseColours = data.listHorseColours;
			modifiedListHorseColours.unshift('');
			setHorseColourOptions(
				modifiedListHorseColours.map((item) => {
					return {
						id: item,
						value: item
					};
				})
			);

			if (data.getOhepNTPForm.id) {
				setFormValuesFromApiResult(data.getOhepNTPForm, setValue);
				setFormId(data.getOhepNTPForm.id);
				setIsFormSubmitted(data.getOhepNTPForm.status === APPROVED);
			} else {
				setDefaultNominationData(data.getHorseProfile.horse);
			}
			setIsLoading(false);
		} catch (error) {
			addToast({
				Message: 'Failed to fetch data',
				IsSuccess: false
			});
		}
	};

	const setDefaultNominationData = async (horseData) => {
		const { data } = await AppSyncService.execute(
			getOhepNominationApprovedFormData,
			{
				horseCode
			}
		);
		setDefaultValuesFromNominationForm(
			data.getOhepNominationApprovedForm,
			horseData,
			setValue
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleSubmitSuccess = async (data) => {
		setIsFormError(false);
		setIsLoading(true);
		try {
			// create the new form
			const ohepNTPFormData = generatePayload(data, horseCode, APPROVED);
			const { data: saveOhepNTPFormData } = await AppSyncService.execute(
				saveOhepNTPForm,
				{
					input: ohepNTPFormData
				}
			);
			if (!saveOhepNTPFormData?.saveOhepNTPForm?.success) {
				throw new Error('Failed to submit form, server error');
			}
			await AppSyncService.execute(generateOhepNoticeToProceed, {
				id: +saveOhepNTPFormData?.saveOhepNTPForm?.id
			});
			addToast({ Message: 'Form Submitted!', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		} catch (error) {
			addToast({ Message: 'Failed to submit form!', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmitError = () => {
		setIsFormError(true);
	};

	const handleDraft = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const data = getValues();
		try {
			const ohepNTPFormData = generatePayload(data, horseCode, DRAFT);
			const { data: saveOhepNTPFormData } = await AppSyncService.execute(
				saveOhepNTPForm,
				{
					input: ohepNTPFormData
				}
			);
			if (!saveOhepNTPFormData?.saveOhepNTPForm?.success) {
				throw new Error('Failed to save draft, server error');
			}
			setIsLoading(false);
			addToast({ Message: 'Form Saved!', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		} catch (error) {
			addToast({ Message: 'Failed to save draft!', IsSuccess: false });
			logToServer(
				currentUser?.UserId,
				data,
				`Failed to save OHEP Notice to Proceed draft due to backend error.`
			);
			setIsLoading(false);
		}
	};

	const handleCancel = async () => {
		reset((formValues) => ({
			...formValues,
			...OHEP_NTP_FORM_CLEAR_VALUES
		}));
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		handleSubmit(handleSubmitSuccess, handleSubmitError)(e);
	};

	const handleUploadPhoto = async (image, name, uploadType) => {
		if (isFormSubmitted) {
			return;
		}
		clearErrors(name);
		try {
			const { data } = await AppSyncService.execute(uploadImageToS3, {
				imageKey: image.name
			});
			const imageKey = data.putUserImage.imageKey;

			await axios.put(data.putUserImage.signedUrl, image, {
				headers: {
					'Content-Type': image.type
				}
			});
			const { data: saveOhepNTPFileData } = await AppSyncService.execute(
				saveOhepNTPFile,
				{
					filename: image.name,
					id: horseCode,
					key: imageKey,
					type: uploadType
				}
			);
			if (!saveOhepNTPFileData.saveOhepNTPFile.success) {
				throw new Error('Error uploading image');
			}
			const values = getValues(name);
			setValue(name, [
				...values,
				{
					filename: image.name,
					key: imageKey
				}
			]);
			if (!formId) {
				setFormId(saveOhepNTPFileData.saveOhepNTPFile.id);
			}
			addToast({ Message: 'Photo uploaded', IsSuccess: true });
		} catch (error) {
			addToast({
				Message: 'Error uploading image',
				IsSuccess: false
			});
		}
	};

	const handleRemovePhoto = async (image, name) => {
		if (isFormSubmitted) {
			return;
		}
		try {
			const { data: deleteImageFromS3Data } = await AppSyncService.execute(
				deleteImageFromS3,
				{
					imageKey: image.key
				}
			);
			if (!deleteImageFromS3Data.deleteUserImage?.success) {
				throw new Error('Error deleting user image');
			}
			const { data: deleteOhepNTPFileData } = await AppSyncService.execute(
				deleteOhepNTPFile,
				{
					key: image.key
				}
			);
			if (!deleteOhepNTPFileData.deleteOhepNTPFile) {
				throw new Error('Error deleting  image');
			}
			const photos = [...values[name]];
			setValue(
				name,
				photos.filter((val) => val.key !== image.key)
			);
			addToast({ Message: 'Image removed', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Error removing image', IsSuccess: false });
		}
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleTypeOfService = (e) => {
		setValue('typeOfService', e.target.value);
	};

	const handleHorseGender = (e) => {
		setValue('horseGender', e.target.value);
	};

	const handleHorseColour = (e) => {
		setValue('horseColour', e.target.value);
	};

	const handleCheckbox = (name) => {
		setValue(name, !values[name]);
	};

	return (
		<Fragment>
			<SEO title="OHEP Form" />
			<StyledLoadingContainer>
				<LoaderSpinner status={isLoading} />
			</StyledLoadingContainer>
			{!isLoading && (
				<StyledContainer>
					<StyledForm>
						<StyledAccordion title="Letter to Service Provider" isOpen={true}>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={8}>
										<StyledFieldContainer>
											<StyledFieldLabel>Type of Service*</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="typeOfService"
												selectedId={values.typeOfService}
												onChange={handleTypeOfService}
												items={typeOfServiceOptions}
												isError={errors.typeOfService?.message}
											/>
											<StyledErrorText>
												{errors.typeOfService?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>{`Letter Send Date`}</StyledFieldLabel>
											<StyledFieldText
												value={formatDateReadableVariant(values.letterSentDate)}
											/>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>{`Provider's Full Name*`}</StyledFieldLabel>
											<StyledFieldText
												isError={errors.serviceProviderFullname?.message}
												{...register('serviceProviderFullname', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.serviceProviderFullname?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												{`Provider's Short Name*`}
											</StyledFieldLabel>
											<StyledFieldText
												isError={errors.serviceProviderShortName?.message}
												{...register('serviceProviderShortName', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.serviceProviderShortName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>{`Provider's Email*`}</StyledFieldLabel>
											<StyledFieldText
												isError={errors.serviceProviderEmail?.message}
												{...register('serviceProviderEmail', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.serviceProviderEmail?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>{`Horse Owner's Name*`}</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerName?.message}
												{...register('horseOwnerName', { required: true })}
											/>
											<StyledErrorText>
												{errors.horseOwnerName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>{`Date After 30 Days`}</StyledFieldLabel>
											<StyledFieldText
												value={formatDateReadableVariant(
													values.letterSentPostDate
												)}
											/>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion
							title="Service Provider Address Details"
							isOpen={true}
						>
							<StyledFormFieldsContainer>
								<FormAddressEntryGeneric
									titlePrefix="Service Provider"
									address={values.spAddress}
									onAddressChange={(address) => setValue('spAddress', address)}
									isPostalAddressManual={false}
									suburb={values.spSuburb}
									onSuburbChange={(suburb) => setValue('spSuburb', suburb)}
									postcode={values.spPostcode}
									onPostCodeChange={(postcode) =>
										setValue('spPostcode', postcode)
									}
									country={values.spCountry}
									onCountryChange={(country) => setValue('spCountry', country)}
									manualAddressCheckBoxData={{
										id: 'manualCheckBox',
										label: 'Manually add address',
										value: 'Manually add address',
										isSelected: values.spAddressValidated
									}}
									toggleManualAddressCheckBox={() =>
										handleCheckbox('spAddressValidated')
									}
									state={values.spState}
									onAddressStateChange={(state) => setValue('spState', state)}
									isSubmitClicked={isSubmitted}
								/>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion
							title="Schedule 1 - Approved Horse Details"
							isOpen={true}
						>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Syndicate Name</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerSyndicateName?.message}
												{...register('horseOwnerSyndicateName', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerSyndicateName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Title*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerTitle?.message}
												{...register('horseOwnerTitle', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerTitle?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>First Name*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerFirstName?.message}
												{...register('horseOwnerFirstName', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerFirstName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Surname*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerLastName?.message}
												{...register('horseOwnerLastName', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerLastName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Mobile*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerMobile?.message}
												{...register('horseOwnerMobile', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerMobile?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Email*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseOwnerEmail?.message}
												{...register('horseOwnerEmail', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseOwnerEmail?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Registered Name</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseName?.message}
												{...register('horseName', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseName?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Sire*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseSire?.message}
												{...register('horseSire', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseSire?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Dam*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseDam?.message}
												{...register('horseDam', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseDam?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Microchip Number*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseMicrochip?.message}
												{...register('horseMicrochip', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseMicrochip?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Age*</StyledFieldLabel>
											<StyledFieldText
												isError={errors.horseAge?.message}
												{...register('horseAge', {
													required: true
												})}
											/>
											<StyledErrorText>
												{errors.horseAge?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>

									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Sex*</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="horseGender"
												selectedId={values.horseGender}
												onChange={handleHorseGender}
												isError={errors.horseGender?.message}
												items={horseGenderOptions}
											/>
											<StyledErrorText>
												{errors.horseGender?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>

									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Colour*</StyledFieldLabel>
											<FormDropDown
												showBlank
												name="horseColour"
												selectedId={values.horseColour}
												onChange={handleHorseColour}
												items={horseColourOptions}
												isError={errors.horseColour?.message}
											/>
											<StyledErrorText>
												{errors.horseColour?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Thoroughbred Photos" isOpen={true}>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Brand Image*</StyledFieldLabel>
											<UploadPhoto
												id={`${PAGE_ID}-photos`}
												formats={['png', 'jpeg']}
												labelName="Upload Brand Image"
												error={handleUploadError}
												data={values?.photos}
												sizeLimitMB={5}
												numberOfImages={1}
												success={(image) =>
													handleUploadPhoto(image, 'photos', 'brandImage')
												}
												getImage={filePreviewHandler}
												removeImage={(image) =>
													handleRemovePhoto(image, 'photos')
												}
												canEdit={!isFormSubmitted}
											/>
											<StyledErrorText>
												{errors.photos?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion
							title="Address of Current Location of Nominee Horse"
							isOpen={true}
						>
							<StyledFormFieldsContainer>
								<FormAddressEntryGeneric
									address={values.currentAddress}
									onAddressChange={(address) =>
										setValue('currentAddress', address)
									}
									isPostalAddressManual={false}
									suburb={values.currentSuburb}
									onSuburbChange={(suburb) => setValue('currentSuburb', suburb)}
									postcode={values.currentPostcode}
									onPostCodeChange={(postcode) =>
										setValue('currentPostcode', postcode)
									}
									country={values.currentCountry}
									onCountryChange={(country) =>
										setValue('currentCountry', country)
									}
									manualAddressCheckBoxData={{
										id: 'manualCheckBox',
										label: 'Manually add address',
										value: 'Manually add address',
										isSelected: values.currentAddressValidated
									}}
									toggleManualAddressCheckBox={() =>
										handleCheckbox('currentAddressValidated')
									}
									state={values.currentState}
									onAddressStateChange={(state) =>
										setValue('currentState', state)
									}
									isSubmitClicked={isSubmitted}
								/>
							</StyledFormFieldsContainer>
						</StyledAccordion>

						<StyledFormEndDivider />
						<StyledFormActionsRow>
							<Col xs={3} lg={7}>
								<StyledCancelRequest onClick={handleCancel} isVisible={true}>
									Clear Form
								</StyledCancelRequest>
							</Col>
							<StyledSaveDraft xs={2} lg={2.5}>
								<StyledFormActionLink onClick={handleDraft} isVisible={true}>
									Save Draft
								</StyledFormActionLink>
							</StyledSaveDraft>
							<Col xs={7} lg={2.5}>
								<StyledFormSubmitButton
									onClick={onSubmit}
									id={`${PAGE_ID}-submit`}
								>
									Complete Request
								</StyledFormSubmitButton>
							</Col>
						</StyledFormActionsRow>
						{isFormError && (
							<StyledErrorMessage>
								Please complete all required fields
							</StyledErrorMessage>
						)}
					</StyledForm>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</StyledContainer>
			)}
		</Fragment>
	);
};

export default OHEPNoticeToProceedForm;

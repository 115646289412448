export const DROPDOWN = 'dropdown';
export const HORSESUGGESTION = 'horseSuggestion';
export const LOGO = 'logo';
export const MULTILINETEXT = 'multilineText';
export const RESULT_STATUS = {
	PUBLISHED: 'Published',
	REVIEW: 'Review',
	REMOVED: 'Removed'
};
export const REMOVE_ALL_EVENT_RESULTS = '@REMOVE_ALL_EVENT_RESULTS';

export const getColumnsEventResultReadOnly = () => {
	return [
		{
			Header: 'Class',
			accessor: 'class',
			labelAccessor: 'class'
		},
		{
			Header: '',
			accessor: 'profilePhotoKey',
			labelAccessor: 'profilePhotoKey',
			type: LOGO
		},
		{
			Header: 'Registered Thoroughbred Name',
			accessor: 'horse',
			labelAccessor: 'horse',
			type: HORSESUGGESTION
		},
		{
			Header: 'Horse name',
			accessor: 'racingHorseName',
			labelAccessor: 'racingHorseName'
		},
		{
			Header: 'Rider',
			accessor: 'riderName',
			labelAccessor: 'riderName'
		},
		{
			Header: 'Result',
			accessor: 'result',
			labelAccessor: 'result'
		},
		{
			Header: 'Comments',
			accessor: 'comments',
			labelAccessor: 'comments',
			type: MULTILINETEXT
		}
	];
};

export const getColumnsEventResult = () => {
	return [
		{
			Header: 'Class',
			accessor: 'class',
			labelAccessor: 'class'
		},
		{
			Header: 'Registered Thoroughbred Name',
			accessor: 'horse',
			labelAccessor: 'horse',
			editableType: HORSESUGGESTION
		},
		{
			Header: 'Horse name',
			accessor: 'racingHorseName',
			labelAccessor: 'racingHorseName'
		},
		{
			Header: 'Rider',
			accessor: 'riderName',
			labelAccessor: 'riderName'
		},
		{
			Header: 'Result',
			accessor: 'result',
			labelAccessor: 'result'
		},
		{
			Header: 'Comments',
			accessor: 'comments',
			labelAccessor: 'comments',
			editableType: MULTILINETEXT
		}
	];
};

import React, { useEffect, useState } from 'react';
import {
	Col,
	Container,
	Row,
	Visible,
	useScreenClass
} from 'react-grid-system';
import useStyles, {
	LARGE_SCREEN_TOOLTIP_INLINE_STYLES,
	SMALL_SCREEN_TOOLTIP_INLINE_STYLES
} from './styles';
import FormDropDown from '../FormDropDown';
import ResizingTextArea from '../ResizingTextArea';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import {
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	deleteImagesFromS3,
	getResetWeeklyProgressFormByWeekId,
	saveResetWeeklyProgressForm,
	saveResetReportingImage,
	listResetNewStatuses,
	submitResetWeeklyReportsForReview,
	saveForSaleHorseImage,
	checkImageExist
} from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import Toggle from '../Toggle';
import Lightbox from 'react-image-lightbox';
import PrimaryButton from '../PrimaryButton';
import { checkIsSmallScreen, RESETWeeksAvailable } from '../../utils/helpers';
import { useAuth } from '../../hooks/useAuth';
import FormTextField from '../FormTextField';
import SimpleCheckBox from '../SimpleCheckBox';
import { getHorseStatus } from './queries';
import MessageBar from '../MessageBar';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormToolTip from '../FormToolTip';
import { logToServer } from '../../utils/logger';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';
//import ValidationErrorText from '../FormComponents/ValidationErrorText';
import LoaderSpinner from '../LoaderSpinner';
import { readFile } from '../HorseProfileEdit/helpers';
import ImageCropModal from '../ImageCropModal';

const RESETWeeklyProgressForm = ({
	horseData,
	horseCode,
	index,
	reload,
	isCurrentForSaleStatus = false
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [progressPhotos, setProgressPhotos] = useState([]);
	const [inWorkThisWeek, setInWorkThisWeek] = useState(false);
	const [reasonWhy, setReasonWhy] = useState('');
	const [workingDetails, setWorkingDetails] = useState('');
	const [needsAttention, setNeedsAttention] = useState('');
	const [workPlan, setWorkPlan] = useState('');
	const [keyProgress, setKeyProgress] = useState('');
	const [physicalConcerns, setPhysicalConcerns] = useState(false);
	const [physicalConcernDetails, setPhysicalConcernDetails] = useState('');
	const [vetTreatment, setVetTreatment] = useState(false);
	const [vetTreatmentDetails, setVetTreatmentDetails] = useState('');
	const [otherConcerns, setOtherConcerns] = useState('');
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [selectedWeek, setSelectedWeek] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? 51
			: horseData?.lastSubmittedForm?.weekId
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);

	const [weekNumber, setWeekNumber] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [horseStatusSame, setHorseStatusSame] = useState(false);
	const [newStatus, setNewStatus] = useState(0);
	const [statusDropDownItems, setStatusDropDownItems] = useState([]);
	const [completeRemainder, setCompleteRemainder] = useState(false);
	const [previousStatus, setPreviousStatus] = useState(null);
	const [previousStatusName, setPreviousStatusName] = useState(null);
	// const [formValidationComplete, setFormValidationComplete] = useState(false);
	const [formSaved, setFormSaved] = useState(false);
	const [formIsEditable, setFormIsEditable] = useState(true);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	let isValidationErrors = false;

	const debouncedWeekNumber = useDebounce(weekNumber, 700);

	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	const [addImageForSaleNotice, setAddImageForSaleNotice] = useState(false);
	const [imageBeingCropped, setImageBeingCropped] = useState(null);
	const [forSaleImage, setForSaleImage] = useState([]);
	const [isForSale, setIsForSale] = useState(false);

	useEffect(() => {
		loadResetWeeklyForm();
	}, [selectedWeek, debouncedWeekNumber]);

	useEffect(() => {
		AppSyncService.execute(listResetNewStatuses).then((data) => {
			if (data?.data?.listResetNewStatuses) {
				setStatusDropDownItems(
					[{ id: 0, value: '' }].concat(data.data.listResetNewStatuses)
				);
			}
		});
	}, []);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const checkIfImageExist = async (imageForSale) => {
		const evaluatedImages = await Promise.all(
			imageForSale.map((img) =>
				AppSyncService.execute(checkImageExist, { imageKey: img.key })
			)
		);
		setForSaleImage(
			imageForSale.filter((item, index) => {
				if (evaluatedImages[index].data.checkImageExist) {
					return item;
				}
			})
		);
	};

	const loadResetWeeklyForm = () => {
		if (+selectedWeek !== 0 && +selectedWeek <= 50) {
			const input = {
				horseCode,
				memberExternalId: currentUser?.UserId,
				weekId: +selectedWeek
			};
			AppSyncService.execute(getResetWeeklyProgressFormByWeekId, input).then(
				(data) => {
					if (data?.data?.getResetWeeklyProgressForm) {
						const initialData = data.data.getResetWeeklyProgressForm;
						if (!initialData?.id) setIsShowErrorMessages(false);
						else setIsShowErrorMessages(true);
						setNeedsAttention(initialData.extraAttention ?? '');
						setVetTreatment(initialData.hasTreatements ?? false);
						setVetTreatmentDetails(initialData.hasTreatementsDetails ?? '');
						setInWorkThisWeek(initialData.inWork ?? false);
						setWorkingDetails(initialData.inWorkDetails ?? '');
						setPhysicalConcerns(initialData.isConcerned ?? false);
						setPhysicalConcernDetails(initialData.isConcernedDetails ?? '');
						setReasonWhy(initialData.notInWorkReason ?? '');
						setWorkPlan(initialData.plansForNextWeek ?? '');
						setKeyProgress(initialData.progressDetails ?? '');
						setProgressPhotos(initialData.progressPhotos ?? []);
						setOtherConcerns(initialData.reportConcerns ?? '');
						setNewStatus(initialData.newResetStatus?.id ?? 0);
						checkIfImageExist(initialData.imageForSale);
						if (
							initialData.newResetStatus?.id == 16 ||
							isCurrentForSaleStatus
						) {
							setAddImageForSaleNotice(!!initialData.addImageForSale);
						}
						if (!initialData.id) {
							AppSyncService.execute(getHorseStatus, { horseCode }).then(
								(data) => {
									if (data?.data?.getHorseProfile?.status?.status) {
										setPreviousStatus(
											data.data.getHorseProfile.status.status.id
										);
										setPreviousStatusName(
											data.data.getHorseProfile.status.status.value
										);
										setIsForSale(isCurrentForSaleStatus);
									}
								}
							);
						} else if (!initialData.newResetStatus?.id) {
							setHorseStatusSame(true);
							AppSyncService.execute(getHorseStatus, { horseCode }).then(
								(data) => {
									if (data?.data?.getHorseProfile?.status?.status) {
										setPreviousStatus(
											data.data.getHorseProfile.status.status.id
										);
										setPreviousStatusName(
											data.data.getHorseProfile.status.status.value
										);
										setIsForSale(isCurrentForSaleStatus);
									}
								}
							);
						} else {
							setPreviousStatus(initialData.newResetStatus?.id);
							setPreviousStatusName(
								statusDropDownItems.find(
									(x) => +x.id === +initialData.newResetStatus?.id
								)?.value
							);
							setHorseStatusSame(false);
							setIsForSale(initialData.newResetStatus?.id == 16);
						}
						if (
							initialData.status === 'Draft' ||
							initialData.status === 'Rejected' ||
							initialData.status === null
						) {
							setFormIsEditable(true);
							setFormSaved(false);
						} else {
							setFormIsEditable(false);
							setFormSaved(true);
						}
					}
				}
			);
		} else {
			if (+selectedWeek === 51 || +weekNumber >= 51) {
				const input = {
					horseCode,
					memberExternalId: currentUser?.UserId,
					weekId: +weekNumber
				};
				AppSyncService.execute(getResetWeeklyProgressFormByWeekId, input).then(
					(data) => {
						if (data?.data?.getResetWeeklyProgressForm) {
							const initialData = data.data.getResetWeeklyProgressForm;
							setNeedsAttention(initialData.extraAttention ?? '');
							setVetTreatment(initialData.hasTreatements ?? false);
							setVetTreatmentDetails(initialData.hasTreatementsDetails ?? '');
							setInWorkThisWeek(initialData.inWork ?? false);
							setWorkingDetails(initialData.inWorkDetails ?? '');
							setPhysicalConcerns(initialData.isConcerned ?? false);
							setPhysicalConcernDetails(initialData.isConcernedDetails ?? '');
							setReasonWhy(initialData.notInWorkReason ?? '');
							setWorkPlan(initialData.plansForNextWeek ?? '');
							setKeyProgress(initialData.progressDetails ?? '');
							setProgressPhotos(initialData.progressPhotos ?? []);
							setOtherConcerns(initialData.reportConcerns ?? '');
							setNewStatus(initialData.newResetStatus?.id ?? 0);
							checkIfImageExist(initialData.imageForSale);
							if (
								initialData.newResetStatus?.id == 16 ||
								isCurrentForSaleStatus
							) {
								setAddImageForSaleNotice(!!initialData.addImageForSale);
							}
							if (!initialData.id) {
								AppSyncService.execute(getHorseStatus, { horseCode }).then(
									(data) => {
										if (data?.data?.getHorseProfile?.status?.status) {
											setPreviousStatus(
												data.data.getHorseProfile.status.status.id
											);
											setPreviousStatusName(
												data.data.getHorseProfile.status.status.value
											);
											setIsForSale(isCurrentForSaleStatus);
										}
									}
								);
							} else if (!initialData.newResetStatus?.id) {
								setHorseStatusSame(true);
								AppSyncService.execute(getHorseStatus, { horseCode }).then(
									(data) => {
										if (data?.data?.getHorseProfile?.status?.status) {
											setPreviousStatus(
												data.data.getHorseProfile.status.status.id
											);
											setPreviousStatusName(
												data.data.getHorseProfile.status.status.value
											);
											setIsForSale(isCurrentForSaleStatus);
										}
									}
								);
							} else {
								setPreviousStatus(initialData.newResetStatus?.id);
								setIsForSale(initialData.newResetStatus?.id == 16);
								setPreviousStatusName(
									statusDropDownItems.find(
										(x) => +x.id === +initialData.newResetStatus?.id
									)?.value
								);
								setHorseStatusSame(false);
							}
							if (
								initialData.status === 'Draft' ||
								initialData.status === 'Rejected' ||
								initialData.status === null
							) {
								setFormIsEditable(true);
								setFormSaved(false);
							} else {
								setFormIsEditable(false);
								setFormSaved(true);
							}
						}
					}
				);
			}
		}
	};

	const clearForm = () => {
		deleteAllProgressPhotos();
		setSelectedWeek(0);
		setWeekNumber(0);
		setInWorkThisWeek(false);
		setReasonWhy('');
		setWorkingDetails('');
		setNeedsAttention('');
		setWorkPlan('');
		setKeyProgress('');
		setPhysicalConcerns(false);
		setPhysicalConcernDetails('');
		setVetTreatment(false);
		setVetTreatmentDetails('');
		setOtherConcerns('');
		setNewStatus(0);
	};

	const validateUncontrolledErrors = () => {
		if (+newStatus === 14) {
			validateProgressPhotos();
			if (addImageForSaleNotice) {
				validateForSalePhoto();
			}
			if (completeRemainder) {
				validateReasonWhy();
				validateWeeklyWorkPlan();
				validatePhysicalConcernDetails();
				validateVetTreatmentDetails();
				if (inWorkThisWeek) {
					validateWorkingDetails();
					validateKeyProgress();
					validateNeedsAttention();
				}
			}
		} else if (
			+newStatus !== 14 ||
			completeRemainder ||
			(horseStatusSame && +previousStatus === 14)
		) {
			validateProgressPhotos();
			if (addImageForSaleNotice) {
				validateForSalePhoto();
			}
			validateReasonWhy();
			validateWorkingDetails();
			validateKeyProgress();
			validateNeedsAttention();
			validateNewStatus();
			validateWeekNumber();
			validateWeeklyWorkPlan();
			validatePhysicalConcernDetails();
			validateVetTreatmentDetails();
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: null },
				reasonWhy: { message: null },
				WorkingDetails: { message: null },
				KeyProgress: { message: null },
				NeedsAttention: { message: null },
				NewStatusDropDown: { message: null },
				WeeklyWorkPlan: { message: null },
				PhysicalConcernDetails: { message: null },
				VetTreatmentDetails: { message: null }
			}));
		}
		//setFormValidationComplete(true);
	};

	// useEffect(() => {
	// 	if (formValidationComplete) {
	// 		submitForm();
	// 	}
	// }, [formValidationComplete]);

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			submitForm(true);
		}
	};

	const validateProgressPhotos = () => {
		if (progressPhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: 'You must have at least one progress photo' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: null }
			}));
		}
	};

	const validateForSalePhoto = () => {
		if (forSaleImage.length === 0 && formIsEditable) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				forSaleImage: { message: 'You must have for sale photo' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				forSaleImage: { message: null }
			}));
		}
	};

	const validateWeeklyWorkPlan = () => {
		if (workPlan === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WeeklyWorkPlan: { message: 'A work plan for the week must be entered.' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WeeklyWorkPlan: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.WeeklyWorkPlan?.message) {
			validateWeeklyWorkPlan();
		}
	}, [workPlan, inWorkThisWeek]);

	const validatePhysicalConcernDetails = () => {
		if (physicalConcerns && physicalConcernDetails === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalConcernDetails: {
					message: 'Physical health concerns details must be entered.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalConcernDetails: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.PhysicalConcernDetails?.message) {
			validatePhysicalConcernDetails();
		}
	}, [physicalConcernDetails, physicalConcerns]);

	const validateVetTreatmentDetails = () => {
		if (vetTreatment && vetTreatmentDetails === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				VetTreatmentDetails: {
					message: 'Vet attention or treatment details must be entered.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				VetTreatmentDetails: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.VetTreatmentDetails?.message) {
			validateVetTreatmentDetails();
		}
	}, [vetTreatmentDetails, vetTreatment]);

	const validateWeekNumber = () => {
		if (+selectedWeek === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedWeek: { message: 'Please select a week number' }
			}));
		} else if (
			+selectedWeek === 51 &&
			(weekNumber === '' || isNaN(weekNumber))
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekNumber: { message: 'Please enter a week number (numeric only)' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedWeek: { message: null },
				weekNumber: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (
			uncontrolledErrors?.selectedWeek?.message ||
			uncontrolledErrors?.weekNumber?.message
		) {
			validateWeekNumber();
		}
	}, [weekNumber, selectedWeek]);

	useEffect(() => {
		if (uncontrolledErrors?.progressPhotos?.message) {
			validateProgressPhotos();
		}
	}, [progressPhotos]);

	useEffect(() => {
		if (uncontrolledErrors?.forSaleImage?.message && addImageForSaleNotice) {
			validateForSalePhoto();
		}
	}, [forSaleImage]);

	const validateReasonWhy = () => {
		if (!inWorkThisWeek && reasonWhy === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				reasonWhy: {
					message:
						'You must enter a reason for the horse to not have been in work.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				reasonWhy: { message: null }
			}));
		}
	};

	const validateNewStatus = () => {
		if (!horseStatusSame && +newStatus === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				NewStatusDropDown: {
					message: 'You must select a new status for the horse.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				NewStatusDropDown: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.NewStatusDropDown?.message) {
			validateNewStatus();
		}
	}, [horseStatusSame, newStatus]);

	useEffect(() => {
		if (uncontrolledErrors?.reasonWhy?.message) {
			validateReasonWhy();
		}
	}, [reasonWhy, inWorkThisWeek]);

	const validateWorkingDetails = () => {
		if (inWorkThisWeek && workingDetails === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WorkingDetails: {
					message: 'You must enter working details for this week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WorkingDetails: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.WorkingDetails?.message) {
			validateWorkingDetails();
		}
	}, [workingDetails, inWorkThisWeek]);

	const validateKeyProgress = () => {
		if (inWorkThisWeek && keyProgress === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				KeyProgress: { message: 'You must enter key progress for this week.' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				KeyProgress: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.KeyProgress?.message) {
			validateKeyProgress();
		}
	}, [keyProgress, inWorkThisWeek]);

	const validateNeedsAttention = () => {
		if (inWorkThisWeek && needsAttention === '') {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				NeedsAttention: {
					message: 'You must enter needs attention details for this week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				NeedsAttention: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.NeedsAttention?.message) {
			validateNeedsAttention();
		}
	}, [needsAttention, inWorkThisWeek]);

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const saveForSaleImageNotice = async () => {
		const horseUpdateInput = {
			horseCode: horseCode,
			fileName: forSaleImage[0].filename,
			fileKey: forSaleImage[0].key
		};
		const result = await AppSyncService.execute(
			saveForSaleHorseImage,
			horseUpdateInput
		);
		console.log(result);
	};

	const submitForm = async (isSubmitReport = false) => {
		validateUncontrolledErrors();
		let input = {};
		const fullForm =
			+newStatus !== 14 || (+newStatus === 14 && completeRemainder);
		const partialForm = +newStatus === 14 || !completeRemainder;

		if (fullForm) {
			input = {
				horseCode: horseCode,
				weekId: +selectedWeek === 51 ? weekNumber : selectedWeek,
				extraAttention: needsAttention,
				hasTreatements: vetTreatment,
				hasTreatementsDetails: vetTreatmentDetails,
				inWork: inWorkThisWeek,
				inWorkDetails: workingDetails,
				isConcerned: physicalConcerns,
				isConcernedDetails: physicalConcernDetails,
				notInWorkReason: reasonWhy,
				plansForNextWeek: workPlan,
				progressDetails: keyProgress,
				reportConcerns: otherConcerns,
				newResetStatusId: horseStatusSame ? null : +newStatus,
				...(isForSale && { addImageForSale: addImageForSaleNotice })
			};
		} else if (partialForm) {
			input = {
				horseCode: horseCode,
				weekId: +selectedWeek === 51 ? weekNumber : selectedWeek,
				newResetStatusId: horseStatusSame ? null : +newStatus
			};
		} else {
			deleteAllProgressPhotos();
			input = {
				horseCode: horseCode,
				weekId: +selectedWeek === 51 ? weekNumber : selectedWeek,
				newResetStatusId: horseStatusSame ? null : +newStatus
			};
		}
		setIsLoading(true);
		try {
			const data = await AppSyncService.execute(saveResetWeeklyProgressForm, {
				input
			});
			if (data.data) {
				if (fullForm || partialForm) {
					progressPhotos.forEach((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetWeekly',
							reportId: data.data.saveResetWeeklyProgressForm.id,
							type: 'progress'
						});
					});
					if (forSaleImage.length > 0 && addImageForSaleNotice) {
						console.log('saving image for sale');
						saveForSaleImageNotice();
					}
				}

				if (!isSubmitReport) {
					reload();
					addToast({ Message: 'Draft Saved', IsSuccess: true });
					setFormSaved(true);
				} else {
					AppSyncService.execute(submitResetWeeklyReportsForReview, {
						horseCode: horseCode,
						weekId: selectedWeek
					}).then((data) => {
						if (data?.data?.submitResetWeeklyReportsForReview) {
							addToast({
								Message: 'Weekly RESET Form Submitted',
								IsSuccess: true
							});
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
						}
					});
				}
				if (!isSubmitReport) {
					if (!isValidationErrors) {
						setDisableSubmitbutton(false);
					}
				}
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				'RESET form Submission Draft failed'
			);
			addToast({ Message: 'Unable to Save Draft', IsSuccess: false });
		}
		setIsLoading(false);
		//setFormValidationComplete(false);
	};

	const deleteAllProgressPhotos = () => {
		let listOfKeys = [];
		progressPhotos.map((d) => {
			listOfKeys.push(d.key);
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setProgressPhotos([]);
				}
			});
		}
	};

	const progressPhotosUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						setProgressPhotos((progressPhotos) => [
							...progressPhotos,
							{ filename: image.name, key: imageKey }
						]);
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						setShouldValidate(true);
					});
			}
		});
	};

	const photosUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: imageBeingCropped.imageFile.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						setForSaleImage((photoArray) => [
							...photoArray,
							{ filename: imageBeingCropped.imageFile.name, key: imageKey }
						]);
						setImageBeingCropped(null);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removeProgressPhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					setProgressPhotos(
						progressPhotos.filter((item) => item.key !== img.key)
					);
					setShouldValidate(true);
				}
			}
		);
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
					setShouldValidate(true);
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const updateInWorkThisWeek = (value) => {
		setInWorkThisWeek(value);
		if (value === true) {
			setReasonWhy('');
		} else {
			setWorkingDetails('');
			setKeyProgress('');
			setNeedsAttention('');
		}
	};

	const cropImage = (image) => {
		readFile(image).then((result) =>
			setImageBeingCropped({ imageFile: image, src: result })
		);
	};
	return (
		<div className={classes.container}>
			<div className={classes.formContainer}>
				<div>
					<div className={classes.formHeading}>Weekly Progress Form</div>
					<Container>
						{!formSaved && (
							<MessageBar>
								<div className={classes.topMessage}>
									We need more information for RESET Weekly progress. Please
									fill in and save all the fields below.
								</div>
							</MessageBar>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Week Number*
										<FormToolTip
											toolTipContainerStyle={{
												LARGE_SCREEN_TOOLTIP_INLINE_STYLES,
												...(isSmallScreen && SMALL_SCREEN_TOOLTIP_INLINE_STYLES)
											}}
										>
											The week number indicates how long the horse has been in
											your care
										</FormToolTip>
									</div>
									<FormDropDown
										items={RESETWeeksAvailable()}
										selectedId={selectedWeek}
										onChange={(e) => {
											setSelectedWeek(e.target.value);
											setShouldValidate(true);
										}}
										id={HorseManagementKeys.WEEK_NUMBER_DROPDOWN}
										showBlank
									/>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.selectedWeek?.message}
										</span>
									)}
								</div>
							</Col>
							{+selectedWeek === 51 && (
								<Visible xs sm md>
									<Col xs={12}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Week Number*</div>
											<FormTextField
												id={HorseManagementKeys.WEEK_NUMBER_TEXT}
												value={weekNumber}
												onChange={(e) => {
													setWeekNumber(e.target.value);
													setShouldValidate(true);
												}}
											/>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.weekNumber?.message}
												</span>
											)}
										</div>
									</Col>
								</Visible>
							)}
							{+selectedWeek !== 0 && (
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Has the horse&apos;s status changed?
										</div>
										<Toggle
											id={HorseManagementKeys.HORSE_STATUS_CHANGE}
											labelNegative="Yes"
											labelPositive="No"
											onClick={(value) => {
												if (value) {
													if (!previousStatus)
														setIsForSale(isCurrentForSaleStatus);
													else setIsForSale(previousStatus == 16);
												}

												setHorseStatusSame(value);
												setNewStatus(0);
												setShouldValidate(true);
											}}
											value={horseStatusSame}
											disabled={!formIsEditable}
										/>
									</div>
								</Col>
							)}
							{+selectedWeek !== 0 && !horseStatusSame && (
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>New Status*</div>
										<FormDropDown
											items={statusDropDownItems}
											selectedId={newStatus}
											onChange={(e) => {
												setNewStatus(e.target.value);
												setIsForSale(e.target.value == 16);
												setShouldValidate(true);
											}}
											id={HorseManagementKeys.NEW_STATUS}
											showBlank
											disabled={!formIsEditable}
										/>
										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.NewStatusDropDown?.message}
											</span>
										)}
									</div>
								</Col>
							)}
							{horseStatusSame && (
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>Current Status</div>
										<div className={classes.statusTitle}>
											{previousStatusName}
										</div>
									</div>
								</Col>
							)}
						</Row>
						{+selectedWeek === 51 && (
							<Visible lg xl xxl>
								<Row>
									<Col lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Week Number*</div>
											<FormTextField
												id={HorseManagementKeys.WEEK_NUMBER_TEXT}
												value={weekNumber}
												onChange={(e) => {
													setWeekNumber(e.target.value);
													setShouldValidate(true);
												}}
											/>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.weekNumber?.message}
												</span>
											)}
										</div>
									</Col>
								</Row>
							</Visible>
						)}
						<>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Progress Photo(s)* (1 required - jpeg, png)
										</div>
										<UploadPhoto
											id={HorseManagementKeys.WEEKLY_PROGRESS_PHOTOS}
											formats={['png', 'jpeg']}
											labelName="Upload Progress Photo(s)"
											error={uploadError}
											data={progressPhotos}
											sizeLimitMB={5}
											numberOfImages={10}
											success={progressPhotosUpload}
											getImage={getImage}
											removeImage={removeProgressPhotos}
											canEdit={formIsEditable}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.progressPhotos?.message}
										</span>
									)}
								</Col>
								{isForSale ? (
									<>
										<Col xs={12} lg={4}>
											<div className={classes.ProfilefieldContainer}>
												<div className={classes.fieldTitle}>
													{`Add image to For-Sale notice?`}{' '}
													<FormToolTip>
														This photo will be displayed in the For Sale post in
														the <b>Suggestions to Follow</b> {`section and `}
														<b>Follow</b> updates.
													</FormToolTip>
												</div>
												<div className="mt-5">
													<Toggle
														labelNegative="No"
														labelPositive="Yes"
														onClick={(value) => {
															setAddImageForSaleNotice(value);
															setShouldValidate(true);
														}}
														value={addImageForSaleNotice}
														disabled={!formIsEditable}
													/>
												</div>
											</div>
										</Col>
										{addImageForSaleNotice ? (
											<Col lg={4} sm={12}>
												<div className={classes.ProfilefieldContainer}>
													<div className={classes.fieldTitle}>
														Upload photo (jpg,png)*
													</div>
													<div className="mt-5">
														<UploadPhoto
															formats={['png', 'jpeg']}
															error={uploadError}
															data={forSaleImage}
															sizeLimitMB={5}
															success={cropImage}
															getImage={getImage}
															removeImage={(e) =>
																removePhoto(e, forSaleImage, setForSaleImage)
															}
															labelName="Upload"
															numberOfImages={1}
															canEdit={formIsEditable}
														/>
													</div>
												</div>
												{isShowErrorMessages && (
													<span style={{ color: 'red' }}>
														{uncontrolledErrors?.forSaleImage?.message}
													</span>
												)}
											</Col>
										) : null}
									</>
								) : null}
							</Row>
						</>
						{+newStatus === 14 && (
							<Row>
								<Col xs={12}>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={() => {
												setCompleteRemainder(!completeRemainder);
												setShouldValidate(true);
											}}
											custom
											customStyle={{ float: 'left' }}
											data={{
												id: HorseManagementKeys.COMPLETE_REMAINDER,
												isSelected: completeRemainder
											}}
											isHidden={formIsEditable}
										/>
										<div className={classes.checkboxField}>
											Completing the remainder of this form is optional if the
											horse status is Spelling. Please select this checkbox if
											you wish to complete the form.
										</div>
									</div>
								</Col>
							</Row>
						)}
						{(+newStatus !== 14 || completeRemainder) && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Has the horse been in work this week?
											</div>
											<Toggle
												id={HorseManagementKeys.WEEK_HORSE_WORK}
												labelNegative="No"
												labelPositive="Yes"
												onClick={(value) => {
													updateInWorkThisWeek(value);
													setShouldValidate(true);
												}}
												value={inWorkThisWeek}
												disabled={!formIsEditable}
											/>
										</div>
									</Col>
								</Row>
								{!inWorkThisWeek && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="Please explain why*"
													id={`ResizingTextArea-pleaseExplain-${index}`}
													onChange={(e) => {
														setReasonWhy(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={reasonWhy}
													canEdit={formIsEditable}
												/>
											</div>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.reasonWhy?.message}
												</span>
											)}
										</Col>
									</Row>
								)}
								{inWorkThisWeek && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="Details of work sessions this week*"
													id={HorseManagementKeys.WEEK_WORK_SESSIONS}
													onChange={(e) => {
														setWorkingDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={workingDetails}
													canEdit={formIsEditable}
												/>
											</div>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.WorkingDetails?.message}
												</span>
											)}
										</Col>
									</Row>
								)}
								{inWorkThisWeek && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="Key progress/achievements to date*"
													id={HorseManagementKeys.KEY_PROGRESS}
													onChange={(e) => {
														setKeyProgress(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={keyProgress}
													canEdit={formIsEditable}
												/>
											</div>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.KeyProgress?.message}
												</span>
											)}
										</Col>
									</Row>
								)}
								{inWorkThisWeek && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="What area(s) need extra work / attention?*"
													id={HorseManagementKeys.NEED_EXTRA_WORK}
													onChange={(e) => {
														setNeedsAttention(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={needsAttention}
													canEdit={formIsEditable}
												/>
											</div>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.NeedsAttention?.message}
												</span>
											)}
										</Col>
									</Row>
								)}
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<ResizingTextArea
												title="Work plan for the coming week?*"
												id={HorseManagementKeys.WORK_PLAN}
												onChange={(e) => {
													setWorkPlan(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												value={workPlan}
												canEdit={formIsEditable}
											/>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.WeeklyWorkPlan?.message}
												</span>
											)}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Are there any lameness or physical health concerns?
											</div>
											<Toggle
												id={HorseManagementKeys.LAMENESS}
												labelNegative="No"
												labelPositive="Yes"
												onClick={(value) => {
													setPhysicalConcerns(value);
													setShouldValidate(true);
												}}
												value={physicalConcerns}
												disabled={!formIsEditable}
											/>
										</div>
									</Col>
								</Row>
								{physicalConcerns && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="Please give details*"
													id={HorseManagementKeys.LAMENESS_DETAILS}
													onChange={(e) => {
														setPhysicalConcernDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={physicalConcernDetails}
													canEdit={formIsEditable}
												/>
												{isShowErrorMessages && (
													<span style={{ color: 'red' }}>
														{
															uncontrolledErrors?.PhysicalConcernDetails
																?.message
														}
													</span>
												)}
											</div>
										</Col>
									</Row>
								)}
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Has the horse required vet attention or treatment in the
												past week?
											</div>
											<Toggle
												id={HorseManagementKeys.VET_ATTENTION}
												labelNegative="No"
												labelPositive="Yes"
												onClick={(value) => {
													setVetTreatment(value);
													setShouldValidate(true);
												}}
												value={vetTreatment}
												disabled={!formIsEditable}
											/>
										</div>
									</Col>
								</Row>
								{vetTreatment && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<ResizingTextArea
													title="Please explain why*"
													id={HorseManagementKeys.VET_ATTENTION_DETAILS}
													onChange={(e) => {
														setVetTreatmentDetails(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													value={vetTreatmentDetails}
													canEdit={formIsEditable}
												/>
												{isShowErrorMessages && (
													<span style={{ color: 'red' }}>
														{uncontrolledErrors?.VetTreatmentDetails?.message}
													</span>
												)}
											</div>
										</Col>
									</Row>
								)}
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<ResizingTextArea
												title="Do you have any other concerns to report? Changes to your Initial Assessment?"
												id={HorseManagementKeys.OTHER_CONCERNS}
												onChange={(e) => setOtherConcerns(e.target.value)}
												maxChars={500}
												value={otherConcerns}
												canEdit={formIsEditable}
											/>
										</div>
									</Col>
								</Row>
							</>
						)}
						<hr className={classes.divider} />
						{isLoading ? (
							<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									flexDirection: isSmallScreen ? 'column' : 'row'
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: isSmallScreen ? 'column' : 'row',
										alignItems: isSmallScreen ? 'center' : 'start'
									}}
								>
									{formIsEditable && (
										<span
											id={CommonKeys.CLEAR_FORM}
											onClick={() => clearForm()}
											className={classes.clearFormLink}
										>
											Clear Form
										</span>
									)}
									<span className={classes.redInfoText}>
										Click on the week number when you return to complete your
										Weekly Form
									</span>
								</div>
								<div
									className={
										isSmallScreen
											? classes.saveRequestMobile
											: classes.saveRequest
									}
								>
									<span className={isSmallScreen ? null : classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '130px' }}
											onClick={() => {
												submitForm();
											}}
											disabled={!formIsEditable}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											disabled={disableSubmitButton}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						)}
					</Container>
					{imgSrcUrl && (
						<Lightbox
							mainSrc={imgSrcUrl}
							onCloseRequest={() => setImgSrcUrl(false)}
						/>
					)}
					{showImageUploadErrorModal && (
						<UploadPhotoErrorModal
							showErrorModal={showImageUploadErrorModal}
							closeModal={() => setShowImageUploadErrorModal(false)}
							isMinimumUploadSize={isMinimumUploadSize}
						/>
					)}
					<ImageCropModal
						imgSrc={imageBeingCropped?.src}
						isOpen={!!imageBeingCropped}
						closeModal={() => setImageBeingCropped(null)}
						onSaveCrop={photosUpload}
					/>
				</div>
			</div>
		</div>
	);
};

export default RESETWeeklyProgressForm;

import React, { useEffect, useState } from 'react';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getUserImage,
	verifyHorseClaimData
} from '../../graphql/custom.queries';
import HeaderBanner from '../HeaderBanner';
import MessageBar from '../MessageBar';
import UploadPhoto from '../UploadPhoto';
import useStyles from './styles';
import { Col, Row } from 'react-grid-system';
import FormDatePicker from '../FormDatePicker';
import FormValidateTextField from '../FormValidateTextField';
import Lightbox from 'react-image-lightbox';
import {
	ClaimThoroughbredFormKeys,
	HORSE_IDENTIFICATION_POINTS_ALLOCATION
} from '../../utils/constants';
import { calculateHorseIdentificationScore } from '../../utils/helpers';
import { addNameToUnnamedHorse } from '../../utils/helpers';

const ThoroughbredIdentificationView = ({
	claimData,
	isPreviousClaim = false
}) => {
	const [score, setScore] = useState(0);
	const classes = useStyles();
	const [validMicrochip, setValidMicrochip] = useState(false);
	const [validDam, setValidDam] = useState(false);
	const [validSire, setValidSire] = useState(false);
	const [validFoalYear, setValidFoalYear] = useState(false);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const calculateTotalScore = () => {
		const newTotalScore = calculateHorseIdentificationScore({
			validDam,
			validFoalDate: validFoalYear,
			validMicrochip,
			validSire,
			brandLeftImagesCount: claimData?.brandLeftImages?.length || 0,
			brandRightImagesCount: claimData?.brandRightImages?.length || 0,
			markingsFrontImagesCount: claimData?.markingsFrontImages?.length || 0,
			markingsSideImagesCount: claimData?.markingsSideImages?.length || 0,
			microchipImagesCount: claimData?.microchipImages?.length || 0
		});
		setScore(newTotalScore);
	};

	useEffect(() => {
		const { sireName, damName, microChipNumber, foalYear, horse } = claimData;
		const verifyClaimData = {
			damName: damName || null,
			foalYear: foalYear || null,
			horseCode: horse.horseCode,
			microChipNumber: microChipNumber || null,
			sireName: sireName || null
		};
		AppSyncService.execute(verifyHorseClaimData, { verifyClaimData }).then(
			({ data }) => {
				if (data) {
					setValidDam(data.isMatched?.damName);
					setValidFoalYear(data.isMatched?.foalYear);
					setValidMicrochip(data.isMatched?.microChipNumber);
					setValidSire(data.isMatched?.sireName);
					calculateTotalScore();
				}
			}
		);
	}, [claimData, validDam, validFoalYear, validMicrochip, validSire, score]);

	return (
		<>
			<HeaderBanner
				title={`Thoroughbred Identification ${
					!isPreviousClaim ? `(${score}/100 Points)` : ''
				}`}
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<MessageBar type="info" className={classes.horseInfo}>
					<div className={classes.horseName}>
						{addNameToUnnamedHorse(claimData?.horse?.name)}
					</div>
					<div className={classes.horseAdditionalInfo}>
						{claimData?.horse?.colour}&nbsp;&nbsp;&nbsp;&nbsp;
						{claimData?.horse?.sex}
						&nbsp;&nbsp;&nbsp;&nbsp;{claimData?.horse?.age}&nbsp;years
					</div>
				</MessageBar>
				<div className={classes.claimHorseContainer}>
					<Row>
						{claimData?.brandLeftImages?.length > 0 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.uploadFieldTitle}>
										{`Brand Photo - Left (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES} Points - jpg,png)`}
									</div>
									<UploadPhoto
										id={ClaimThoroughbredFormKeys.BRAND_PHOTO_LEFT}
										formats={['png', 'jpeg']}
										data={claimData.brandLeftImages}
										getImage={getImage}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.brandRightImages?.length > 0 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.uploadFieldTitle}>
										{`Brand Photo - Right (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES} Points - jpg,png)`}
									</div>
									<UploadPhoto
										id={ClaimThoroughbredFormKeys.BRAND_PHOTO_RIGHT}
										formats={['png', 'jpeg']}
										data={claimData.brandRightImages}
										getImage={getImage}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.markingsSideImages?.length > 0 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.uploadFieldTitle}>
										{`Markings Photo - Side (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO} Points - jpg,png)`}
									</div>
									<UploadPhoto
										id={ClaimThoroughbredFormKeys.MARKINGS_PHOTO_SIDE}
										formats={['png', 'jpeg']}
										data={claimData.markingsSideImages}
										getImage={getImage}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.markingsFrontImages?.length > 0 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.uploadFieldTitle}>
										{`Markings Photo - Front (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO} Points - jpg,png)`}
									</div>
									<UploadPhoto
										id={ClaimThoroughbredFormKeys.MARKINGS_PHOTO_FRONT}
										formats={['png', 'jpeg']}
										data={claimData.markingsFrontImages}
										getImage={getImage}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.microchipImages?.length > 0 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.uploadFieldTitle}>
										{`Microchip Document (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_IMAGES} Points - jpg,png)`}
									</div>
									<UploadPhoto
										id={ClaimThoroughbredFormKeys.MICROCHIP_DOCUMENT}
										formats={['png', 'jpeg']}
										data={claimData.microchipImages}
										getImage={getImage}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.foalYear && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										{`Foal Year (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.FOAL_YEAR} Points)`}
									</div>
									<FormDatePicker
										id={ClaimThoroughbredFormKeys.FOAL_YEAR}
										DateValue={claimData?.foalYear}
										validFoalYear={validFoalYear}
										isValidation={true}
										isValid={validFoalYear}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.microChipNumber && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										{`Microchip Number (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_NUMBER} Points)`}
									</div>
									<FormValidateTextField
										id={ClaimThoroughbredFormKeys.MICROCHIP_NUMBER}
										isValid={validMicrochip}
										value={claimData?.microChipNumber}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.sireName && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										{`Sire Name (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.SIRE_NAME} Points)`}
									</div>
									<FormValidateTextField
										id={ClaimThoroughbredFormKeys.SIRE_NAME}
										isValid={validSire}
										value={claimData?.sireName}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
						{claimData?.damName && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div
										className={classes.fieldTitle}
									>{`Dam Name (${HORSE_IDENTIFICATION_POINTS_ALLOCATION.DAM_NAME} Points)`}</div>
									<FormValidateTextField
										id={ClaimThoroughbredFormKeys.DAM_NAME}
										isValid={validDam}
										value={claimData?.damName}
										canEdit={false}
									/>
								</div>
							</Col>
						)}
					</Row>
				</div>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</div>
		</>
	);
};
export default ThoroughbredIdentificationView;

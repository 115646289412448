export const OHEP_FORM_REVIEW_DEFAULT_VALUES = {
	adminExternalId: null,
	adminStatus: null,
	formComplete: false,
	ewdRecommendation: true,
	ewdRecommendationDate: null,
	ewdComments: '',
	eligibilityReason: null,
	primaryConcern: null,
	primaryConcernOther: null,
	nominationCategory: null,
	illness: null,
	injury: null,
	rehomingAttemptsSatisfactory: true,
	rehomingAttemptsComments: null,
	bodyParts: [],
	bodyPartOther: null,
	ewdFinalComments: null,
	vetCertOrOtherFiles: [],
	xRayFiles: [],
	brandImages: [],
	ewgmApprovalToProceed: true,
	ewgmComments: null,
	automaticallySendAcceptanceNoticeToOwner: true,
	addEwgmSignatureOnAcceptanceNotice: true,
	adminNotes: '',
	hasInjuryIdentified: true,
	dateInjuryReported: null,
	hasInjuryPreventedCareer: null
};

export const HAS_INJURY_PREVENTED_CAREER_OPTIONS = [
	{ id: null, value: '' },
	{ id: 'Yes', value: 'Yes' },
	{ id: 'No', value: 'No' },
	{ id: 'Undetermined', value: 'Undetermined' }
];

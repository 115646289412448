import React, { useState } from 'react';
import FormDatePicker from '../FormDatePicker';
import MessageBar from '../MessageBar';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SaveCancelButton from '../SaveCancelButton';
import { formatDate } from '../../utils/helpers';
import { CommonKeys } from './../../utils/constants/index';
import Toggle from '../Toggle';
import FormToolTip from '../FormToolTip';
import UploadPhoto from '../UploadPhoto';
import ImageCropModal from '../ImageCropModal';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
import AppSyncService from '../../graphql/AppSyncService';
import { readFile } from '../HorseProfileEdit/helpers';
import axios from 'axios';
import { useToast } from '../../hooks/useToast';

import {
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3
} from '../../graphql/custom.queries';

const RetrainerMonthlyForms = ({
	id,
	title,
	submitForm,
	cancel,
	effectiveDateLabel
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [date, setDate] = useState(null);
	const [error, seterror] = useState({
		dateError: {
			value: ''
		},
		imageError: {
			value: ''
		}
	});
	const [addImageForSaleNotice, setAddImageForSaleNotice] = useState(false);
	const [forSaleImage, setForSaleImage] = useState([]);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [imageBeingCropped, setImageBeingCropped] = useState(null);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				let errorItems = { ...error };
				errorItems['dateError'] = { value: '' };
				seterror(errorItems);
				setDate(formattedDate);
			} else {
				setDate(null);
			}
		}
	};

	const submitData = () => {
		let errorItems = { ...error };
		if (
			date != null &&
			((addImageForSaleNotice && forSaleImage.length >= 1) ||
				!addImageForSaleNotice)
		) {
			let data = {
				date: date,
				addImageForSaleNotice,
				...(addImageForSaleNotice &&
					forSaleImage.length >= 1 && {
						forSaleImage: forSaleImage
					})
			};
			submitForm(data);
		} else {
			let dateError = {
				...errorItems['dateError'],
				value: !date ? 'Date cannnot be empty' : ''
			};
			errorItems['dateError'] = dateError;
			seterror(errorItems);

			let imageError = {
				...errorItems['imageError'],
				value:
					addImageForSaleNotice && forSaleImage.length === 0
						? 'Upload photo cannot be empty'
						: ''
			};
			errorItems['imageError'] = imageError;
			seterror(errorItems);
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const cropImage = (image) => {
		readFile(image).then((result) =>
			setImageBeingCropped({ imageFile: image, src: result })
		);
	};

	const photosUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: imageBeingCropped.imageFile.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'File uploaded', IsSuccess: true });
						setForSaleImage((photoArray) => [
							...photoArray,
							{ filename: imageBeingCropped.imageFile.name, key: imageKey }
						]);
						setImageBeingCropped(null);
						let errorItems = { ...error };
						errorItems['imageError'] = { value: '' };
						seterror(errorItems);
					});
			}
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<div className={classes.formHeading}>{title}</div>
				<MessageBar>
					<div className={classes.topMessage}>
						We need more information for a Retraining Status change. Please fill
						in and save all the fields below.
					</div>
				</MessageBar>
				<Row style={{ marginTop: 20 }}>
					<Col xs={12} lg={12}>
						<div className={classes.fieldTitle}>
							{effectiveDateLabel != null
								? effectiveDateLabel
								: 'Date Commenced'}
							*
						</div>
						<div>
							<FormDatePicker
								id={id}
								styles={{ width: '100%', color: 'blue' }}
								DateValue={date}
								updateSelection={(value) => updateDate(value)}
							/>
							<div className={classes.error}>{error?.dateError?.value}</div>
						</div>
					</Col>
				</Row>
				{effectiveDateLabel ? (
					<Row style={{ marginTop: 20 }}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									{`Add image to For-Sale notice?`}{' '}
									<FormToolTip>
										This photo will be displayed in the For Sale post in the{' '}
										<b>Suggestions to Follow</b> {`section and `}
										<b>Follow</b> updates.
									</FormToolTip>
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setAddImageForSaleNotice(value);
									}}
									value={addImageForSaleNotice}
									// disabled={isReadOnly}
								/>
							</div>
						</Col>
						{addImageForSaleNotice ? (
							<Col lg={4} sm={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Upload photo (jpg,png)*
									</div>
									<UploadPhoto
										formats={['png', 'jpeg']}
										error={uploadError}
										data={forSaleImage}
										sizeLimitMB={5}
										success={cropImage}
										getImage={getImage}
										removeImage={(e) =>
											removePhoto(e, forSaleImage, setForSaleImage)
										}
										labelName="Upload"
										numberOfImages={1}
									/>
								</div>
								<div className={classes.error}>{error?.imageError?.value}</div>
							</Col>
						) : (
							''
						)}
					</Row>
				) : null}

				<hr className={classes.divider}></hr>
				<div>
					<SaveCancelButton
						idPositive={CommonKeys.SAVE}
						idNegative={CommonKeys.CANCEL}
						positiveLabel="Save"
						onClickPositive={submitData}
						cancel={cancel}
						negativeLabel="Cancel"
					/>
				</div>
				<ImageCropModal
					imgSrc={imageBeingCropped?.src}
					isOpen={!!imageBeingCropped}
					closeModal={() => setImageBeingCropped(null)}
					onSaveCrop={photosUpload}
				/>
				<UploadPhotoErrorModal
					showErrorModal={showImageUploadErrorModal}
					closeModal={() => setShowImageUploadErrorModal(false)}
					isMinimumUploadSize={isMinimumUploadSize}
				/>

				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</div>
		</div>
	);
};
export default RetrainerMonthlyForms;

import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import dropdown from '../../assets/icons/Dropdown_Down_icon.svg';
import { useScreenClass } from 'react-grid-system';

const MultiDropDown = ({
	id,
	list,
	resetThenSet,
	headTitle,
	dotColor,
	dotColorsWithTexts = []
}) => {
	const screenClassForRender = useScreenClass();
	const node = useRef();
	const classes = useStyles();
	const [isListOpen, setIsListOpen] = useState(false);
	const [input, setInput] = useState('');

	useEffect(() => {
		document.addEventListener('mousedown', closeDropDown);

		return () => {
			document.removeEventListener('mousedown', closeDropDown);
		};
	}, []);

	const closeDropDown = (e) => {
		if (!node.current.contains(e.target)) {
			setIsListOpen(false);
			setInput('');
		} else {
			setIsListOpen(true);
		}
	};

	const toggleList = () => {
		setIsListOpen(!isListOpen);
		setInput('');
	};

	const selectItem = (item) => {
		const { id, key } = item;
		setInput('');
		setIsListOpen(false);
		resetThenSet(id, key);
	};

	const constructHeader = () => {
		const count = list.filter((item) => item.selected).length;

		if (count === 0) {
			return '0 selected';
		}
		if (count === 1) {
			return `${count} selected`;
		}
		if (count > 1) {
			return `${count} selected`;
		}
		return null;
	};

	return (
		<div ref={node}>
			<div
				className={classes.dropDownTitle}
				style={{
					fontSize: ['xs'].includes(screenClassForRender) ? '12px' : ''
				}}
			>
				{headTitle}
				{dotColor && (
					<span className={classes.dot} style={{ background: dotColor }}></span>
				)}
				{dotColorsWithTexts.length > 0 &&
					dotColorsWithTexts.map((item) => {
						return (
							<>
								<span
									className={classes.dot}
									style={{
										background: item.dotColor,
										marginLeft: 8,
										marginRight: 4
									}}
								></span>
								<span className={classes.dotTitle}>{item.title}</span>
							</>
						);
					})}
			</div>
			{!isListOpen && (
				<div
					id={id}
					className={classes.headerTitle}
					onClick={() => toggleList()}
				>
					<div>
						{constructHeader()} {isListOpen && <input type="text" />}
						{isListOpen ? (
							<span></span>
						) : (
							<span className={classes.dropDown}>
								<img src={dropdown} />
							</span>
						)}
					</div>
				</div>
			)}
			{isListOpen && (
				<input
					id={id}
					style={{
						width: ['xs'].includes(screenClassForRender) ? '120px' : ''
					}}
					type="text"
					value={input}
					className={classes.inputText}
					onChange={(e) => setInput(e.target.value)}
				/>
			)}
			{isListOpen && (
				<div className={classes.listContainer}>
					<div role="list" className={classes.list}>
						{list
							.filter((i) =>
								i.title.toLowerCase().includes(input.toLowerCase())
							)
							.map((item) => (
								<div
									className={classes.dropDownList}
									key={item.id}
									onClick={() => selectItem(item)}
								>
									{!item.selected && (
										<>
											<div className={classes.notSelected}>{item.title}</div>
											<hr className={classes.hrLine}></hr>
										</>
									)}{' '}
									{item.selected && (
										<>
											<div className={classes.selected}>{item.title}</div>
											<hr className={classes.hrLine}></hr>
										</>
									)}
								</div>
							))}
					</div>
				</div>
			)}
		</div>
	);
};

export default MultiDropDown;

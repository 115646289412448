import React from 'react';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';

const PageLoadSpinner = ({ isLoading, children }) => {
	const classes = useStyles();
	return (
		<>
			{isLoading ? (
				<div className={classes.pageLoadSpinnerContainer}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				children
			)}
		</>
	);
};

export default PageLoadSpinner;

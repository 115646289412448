import React, { useState } from 'react';
import useStyles from './styles';
import LazyImage from '../LazyImage';
import SocialIcon from '../SocialIcons';
import { FOSTER } from '../RetrainerProfileTemplate/constants';

const formatContactName = (
	firstName,
	showFirstName,
	lastName,
	showLastName
) => {
	return `${showFirstName ? firstName : `${firstName[0].toUpperCase()}.`}  ${
		showLastName ? lastName : `${lastName[0].toUpperCase()}.`
	}`;
};

const ProfileHeader = ({
	logoKey,
	title,
	address,
	website,
	email,
	phone,
	twitter,
	facebook,
	instagram,
	mobile,
	showFirstName,
	showLastName,
	firstName,
	lastName,
	alternativePhone = '',
	alternativeContact = '',
	profileType
}) => {
	const [showPopup, setShowPopup] = useState(null);
	const showAtLeastPartOfName = showFirstName || showLastName;
	const triggerGACb = (label, iconType) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'GAEvent',
			eventCategory: `CTA ${iconType.toUpperCase()}`,
			eventAction: `Click on ${iconType.toUpperCase()}`,
			eventLabel: `${label}`
		});
	};
	const renderPage = () => {
		if (mobile) {
			return (
				<>
					<div className={classes.imageContainerMobile}>
						{profileType !== FOSTER && (
							<div style={{ width: 100, height: 50 }}>
								<LazyImage imageKey={logoKey} />
							</div>
						)}
						<div className={classes.textMobile}>
							<h3 className={classes.titleMobile}>{title}</h3>
							{showAtLeastPartOfName && (
								<p
									id="retrainerprofile-profileheader-contactname-mobile"
									className={classes.addressLine}
								>
									{formatContactName(
										firstName,
										showFirstName,
										lastName,
										showLastName
									)}
								</p>
							)}
							<p className={classes.addressLine}>{address} </p>
							{profileType === FOSTER && (
								<>
									<p className={classes.addressLine}>
										Alternative Contact: {alternativeContact}.{' '}
										{alternativePhone}
									</p>
								</>
							)}
							<div className={classes.iconContainer}>
								{website && (
									<SocialIcon
										iconType="website"
										value={website}
										url={`//${website}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'website' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{email && (
									<SocialIcon
										iconType="email"
										value={email}
										url={`mailto:${email}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'email' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{phone && (
									<SocialIcon
										iconType="phone"
										value={phone}
										url={`tel:${phone}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'phone' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{twitter && (
									<SocialIcon
										iconType="twitter"
										value={twitter}
										url={`https://twitter.com/${twitter}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'twitter' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{facebook && (
									<SocialIcon
										iconType="facebook"
										value={facebook}
										url={`https://www.facebook.com/${facebook}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'facebook' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{instagram && (
									<SocialIcon
										iconType="instagram"
										value={instagram}
										url={`https://www.instagram.com/${instagram}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'instagram' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className={classes.imageContainer}>
						{profileType !== FOSTER && (
							<div>
								<LazyImage
									imageKey={logoKey}
									styles={{
										width: 170,
										objectFit: 'contain',
										background: 'white'
									}}
								/>
							</div>
						)}
						<div className={classes.textMobile}>
							<h3 className={classes.titleMobile}>{title}</h3>
							{showAtLeastPartOfName && (
								<p id="retrainerprofile-profileheader-contactname-desktop">
									{formatContactName(
										firstName,
										showFirstName,
										lastName,
										showLastName
									)}
								</p>
							)}
							<p>{address} </p>
							{profileType === FOSTER && (
								<>
									<p>
										Alternative Contact: {alternativeContact}.{' '}
										{alternativePhone}
									</p>
								</>
							)}
							<div className={classes.iconContainer}>
								{website && (
									<SocialIcon
										iconType="website"
										value={website}
										url={`//${website}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'website' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{email && (
									<SocialIcon
										iconType="email"
										value={email}
										url={`mailto:${email}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'email' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{phone && (
									<SocialIcon
										iconType="phone"
										value={phone}
										url={`tel:${phone}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'phone' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{twitter && (
									<SocialIcon
										iconType="twitter"
										value={twitter}
										url={`https://twitter.com/${twitter}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'twitter' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{facebook && (
									<SocialIcon
										iconType="facebook"
										value={facebook}
										url={`https://www.facebook.com/${facebook}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'facebook' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
								{instagram && (
									<SocialIcon
										iconType="instagram"
										value={instagram}
										url={`https://www.instagram.com/${instagram}`}
										updateShowPopup={(value) => setShowPopup(value)}
										showPopup={showPopup === 'instagram' ? true : false}
										triggerGA={(value, iconType) =>
											triggerGACb(value, iconType)
										}
									/>
								)}
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	const classes = useStyles();
	return renderPage();
};
export default ProfileHeader;

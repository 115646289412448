import { DEFAULT_COUNTRY, BLANK_OPTION } from '../../utils/constants';

export const PAGE_ID = 'horse-profile-edit';

export const DEFAULT_PROGRAM = 4;
export const DEFAULT_OTT_STATUS = 9;
export const DEFAULT_LOCATION_SOURCE = 9;
export const SOURCE_LOCATION_STAR = 15;
export const SOURCE_LOCATION_PRINCIPAL_AUTHORITY = 19;
export const WELFARE_CHECK = 'Welfare check';
export const FOSTER_CHECK = 'RV STAR Program';
export const PRINCIPAL_RACING_AUTHORITY = 'Principal Racing Authority';
export const AUDIT = 'Audit';
export const EMERGENCY_AID_PROGRAM = 8;

export const PRIVATE_VISIBLITY = 1;
export const FOLLOWER_VISIBLITY = 2;
export const PUBLIC_VISIBLITY = 3;

export const INITIAL_STABLE_LOCATION_FORM_STATE = {
	address: {
		value: '',
		validationError: '',
		visibility: PRIVATE_VISIBLITY
	},
	suburb: {
		value: '',
		validationError: '',
		visibility: PRIVATE_VISIBLITY
	},
	postcode: {
		value: '',
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	state: {
		value: '',
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	country: {
		value: DEFAULT_COUNTRY,
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	stabledDate: {
		value: null,
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	addressEnteredManually: {
		value: false
	}
};

export const INITIAL_OTT_INFORMATION_FORM_STATE = {
	profileImage: {
		value: [],
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	knownAsName: {
		value: '',
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	purpose: {
		value: BLANK_OPTION.id,
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	otherPurpose: {
		value: '',
		validationError: ''
	},
	disciplines: {
		value: [],
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	otherDisciplines: {
		value: '',
		validationError: ''
	},
	personalityInfo: {
		value: '',
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	happinessInfo: {
		value: '',
		validationError: '',
		visibility: FOLLOWER_VISIBLITY
	},
	vices: {
		value: [],
		validationError: '',
		visibility: PRIVATE_VISIBLITY
	},
	otherVices: {
		value: '',
		validationError: ''
	},
	injuriesPresent: {
		value: null,
		validationError: '',
		visibility: PRIVATE_VISIBLITY
	},
	injuryTypes: {
		value: [],
		validationError: ''
	},
	otherInjuryTypes: { value: '' },
	illnessesPresent: {
		value: null,
		validationError: '',
		visibility: PRIVATE_VISIBLITY
	},
	illnessTypes: {
		value: [],
		validationError: ''
	},
	otherIllnessTypes: {
		value: '',
		validationError: ''
	}
};

export const INITIAL_OTT_STATUS_FORM_STATE = {
	program: {
		value: DEFAULT_PROGRAM,
		validationError: ''
	},
	ottStatus: {
		value: DEFAULT_OTT_STATUS,
		validationError: ''
	},
	asOfDate: {
		value: null,
		validationError: ''
	},
	locationSource: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	otherLocationSource: {
		value: '',
		validationError: ''
	},
	welfareSource: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	otherWelfareSource: {
		value: '',
		validationError: ''
	},
	personsOfInterest: {
		value: '',
		validationError: ''
	},
	welfareRelationship: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	otherWelfareRelationship: {
		value: '',
		validationError: ''
	},
	welfareComments: {
		value: '',
		validationError: ''
	},
	auditName: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	relationshipAudit: {
		value: '',
		validationError: ''
	},
	personOfInterestAudit: {
		value: '',
		validationError: ''
	},
	auditComments: {
		value: '',
		validationError: ''
	},
	auditOther: {
		value: '',
		validationError: ''
	},
	otherRelationshipAudit: {
		value: '',
		validationError: ''
	},
	fosterReasonForEnteringProgram: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	principalRacingAuthority: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	fosterAssistanceType: {
		value: BLANK_OPTION.id,
		validationError: ''
	},
	fosterReasonForEnteringProgramOther: {
		value: '',
		validationError: ''
	},
	principalRacingAuthorityOther: {
		value: '',
		validationError: ''
	},
	fosterAssistanceTypeOther: {
		value: '',
		validationError: ''
	}
};

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import PageContainer from '../PageContainer';
import AppSyncService from '../../graphql/AppSyncService';
import {
	countHorseClaims,
	dismissNotification,
	saveCompetitionAdminDismissableNotif,
	updateCompetitionAdminDismissableNotif,
	getDismissableNotification,
	saveDismissableNotif,
	saveRetirementDismissableNotification
} from '../../graphql/custom.queries';
import { getMemberData } from './graphql/queries';
import SEO from '../seo';
import { useAuth } from '../../hooks/useAuth';
import ScrollMenuGeneric from '../ScrollMenuGeneric';
import { DASHBOARD_TAB_OPTIONS, HOME, MYSTABLE, FOLLOWING } from './constants';
import HorseManagement from '../HorseManagment';
import NotificationIcon from './Components/NotificationIcon';
import Home from './Components/Home';
import useStyles from './styles';
import { navigate } from 'gatsby-link';
import qs from 'query-string';
import FollowingTab from '../FollowingTab';
import { useStaticQuery, graphql } from 'gatsby';
import {
	ACKNOWLEDGED_RETRAINER,
	APPROVED,
	RETRAINER_SEARCH,
	ROLE_COMPETITION_ADMIN,
	REMOVE_COMPETITION_ADMIN,
	COMPETITION_ADMIN_LINK,
	COMPETITION_ADMIN,
	DISMISS,
	COMPETITION_ADMIN_EXIST,
	IS_REMOVE_IDENTIFIER,
	COMPETITIONS,
	COMPETITION_LINK,
	DashboardKeys,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	BUSINESS_PROFILE,
	ACKNOWLEDGED_RETIREMENT,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ACK_FOSTER,
	ACK_FOSTER_EXIST,
	REMOVE_ACK_FOSTER,
	FOSTER_FORMS,
	HORSE_CLAIM_STAR,
	RETRAINER_NOTIF
} from '../../utils/constants';
import { logToServer } from '../../utils/logger';
import { getCurrentCompetition } from '../CompetitionPage/queries';

import { parseToTimeStamp } from '../../utils/helpers';

const Dashboard = ({ location }) => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const [userNotifications, setUserNotifications] = useState([]);
	const [memberQuickLinks, setMemberQuickLinks] = useState([]);
	const [memberData, setMemberData] = useState(null);
	const [claimCount, setClaimCount] = useState(null);

	const { activeTab } = qs.parse(location.search);

	useEffect(() => {
		if (!activeTab) {
			navigate('/?activeTab=home');
		}
	}, [activeTab]);

	const contentfulQuickLinks = useStaticQuery(
		graphql`
			query landingPageContent {
				allContentfulQuickLink {
					edges {
						node {
							contentfulid
							link
							title
							image {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
			}
		`
	);

	const toggleTab = (newTab) => {
		navigate(`/?activeTab=${newTab}`);
	};

	const fetchClaimsCount = async () => {
		try {
			const { data } = await AppSyncService.execute(countHorseClaims, {
				filterStatuses: [APPROVED],
				uniqueHorsesOnly: true
			});
			setClaimCount(data.countHorseClaims);
		} catch (error) {
			setClaimCount(null);
		}
	};

	const addCompetitionAdminNotif = async (memberInfo) => {
		const { id } = memberInfo;
		try {
			const result = await AppSyncService.execute(
				saveCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_COMPETITION_ADMIN,
					link: COMPETITION_ADMIN_LINK
				}
			);
			if (result?.data) {
				console.log('notification');
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => addCompetitionAdminNotif`
			);
		}
	};

	const addAckFosterNotif = async (memberInfo) => {
		const { id } = memberInfo;
		try {
			const result = await AppSyncService.execute(
				saveCompetitionAdminDismissableNotif,
				{
					memberId: id,
					source: REMOVE_ACK_FOSTER,
					link: COMPETITION_ADMIN_LINK
				}
			);
			if (result?.data) {
				console.log('notification');
			}
		} catch (error) {
			logToServer(currentUser?.UserId, error, `Dashboard => addAckFosterNotif`);
		}
	};

	const checkCurrentCompetitionOnGoingExist = async () => {
		if (currentUser?.UserId) {
			const result = await AppSyncService.execute(getCurrentCompetition, {
				externalId: currentUser.UserId
			});

			if (result?.data?.getCompetition[0]) {
				const currentCompetition = result?.data?.getCompetition[0];
				const selectedDate = parseToTimeStamp(currentCompetition?.endDate);

				const dismissableNotifResult = await AppSyncService.execute(
					getDismissableNotification,
					{
						externalId: currentUser.UserId,
						autoDismissDate: selectedDate,
						source: 'Competitions'
					}
				);

				if (
					dismissableNotifResult?.data?.getDismissableNotificationExist
						.length <= 0
				) {
					try {
						await AppSyncService.execute(saveDismissableNotif, {
							externalId: currentUser.UserId,
							autoDismissDate: selectedDate,
							source: COMPETITIONS,
							message: currentCompetition?.notificationText,
							link: COMPETITION_LINK
						});
					} catch (error) {
						logToServer(
							currentUser?.UserId,
							error,
							`Dashboard => dismissableNotifResult`
						);
					}
				}
			}
			fetchMemberData();
		}
	};

	const evaluateAddingNotifAckFoster = (dataNotif) => {
		let ackFosterNotifExist = false;
		let ackFosterNotifRemove = false;
		dataNotif.notifications.forEach((memberNotif) => {
			if (memberNotif.type === ACK_FOSTER) {
				let ackFosterExistTempVal = memberNotif.identifiers.find(
					(memberIdentifier) => memberIdentifier.id === ACK_FOSTER_EXIST
				)?.value;
				ackFosterNotifExist = ackFosterExistTempVal === 'true';
				let ackFosterNotifRemoveTempVal = memberNotif.identifiers.find(
					(memberIdentifier) => memberIdentifier.id === IS_REMOVE_IDENTIFIER
				)?.value;
				ackFosterNotifRemove = ackFosterNotifRemoveTempVal === 'true';
			}
		});
		if (!ackFosterNotifExist && !ackFosterNotifRemove) {
			addAckFosterNotif(dataNotif);
		}
	};

	const fetchMemberData = async () => {
		if (currentUser?.UserId) {
			try {
				const result = await AppSyncService.execute(getMemberData, {
					externalId: currentUser.UserId
				});
				if (result?.data?.getMember) {
					let filteredMemberNotifications = result.data.getMember.notifications.filter(
						(memberNotif) =>
							!(
								(memberNotif.type === DISMISS &&
									memberNotif.link === '/contacts') ||
								memberNotif.link === ''
							) &&
							(memberNotif.link !== COMPETITION_ADMIN_LINK || !memberNotif.link)
					);
					let finalFilteredMemberNotifications = [];
					filteredMemberNotifications.forEach((filteredMemberNotif) => {
						if (
							!(
								(filteredMemberNotif.type === COMPETITION_ADMIN &&
									filteredMemberNotif.identifiers.find(
										(memberIdentifier) =>
											memberIdentifier.id === IS_REMOVE_IDENTIFIER
									)?.value === 'true') ||
								(filteredMemberNotif.type === ACK_FOSTER &&
									filteredMemberNotif.identifiers.find(
										(memberIdentifier) =>
											memberIdentifier.id === IS_REMOVE_IDENTIFIER
									)?.value === 'true')
							)
						) {
							finalFilteredMemberNotifications.push(filteredMemberNotif);
						}
					});

					//TODO refactor when foster provider role is available
					const isRetrainerRole = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_RV_ACKNOWLEDGED_RETRAINER
					);
					const isRetirementFarmRole = result.data.getMember.roles.some(
						(memberRole) =>
							memberRole.code === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
					);
					const isFosterRole = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
					);
					if (!isRetrainerRole) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(notif) =>
								!(
									(notif.type === BUSINESS_PROFILE &&
										notif?.identifiers?.find(
											(identifier) => identifier.id === 'businessType'
										).value == 1) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.ACK_RETRAINER_APPLICATION_APPROVED
									) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.ACK_RETRAINER_APPLICATION_DECLINED
									) ||
									notif?.message?.includes(
										RETRAINER_NOTIF.HORSE_AVAIABLE_FOR_RETRAINING
									) ||
									notif?.identifiers?.find((memberIdentifier) =>
										memberIdentifier.value.includes(
											RETRAINER_NOTIF.BUSINESS_PROFILE_REQUEST_REJECTED
										)
									) ||
									notif?.identifiers?.find((memberIdentifier) =>
										memberIdentifier.value.includes(
											RETRAINER_NOTIF.ACK_RETRAINER_SUBMITTED_REPORT
										)
									)
								)
						);
					}
					if (!isRetirementFarmRole) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(notif) =>
								!(
									notif.type === BUSINESS_PROFILE &&
									notif?.identifiers?.find(
										(identifier) => identifier.id === 'businessType'
									).value == 2
								)
						);
					}

					if (!isFosterRole) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(notif) =>
								!(
									notif?.message?.includes(
										FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.STAR_WEEKLY_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT
									) ||
									notif?.message?.includes(
										FOSTER_FORMS.STAR_INITIAL_ASSESSMENT
									) ||
									notif.type === HORSE_CLAIM_STAR
								)
						);
					}
					//check if there's a dismissable notif in localstorage to be filtered
					let storedNotif = JSON.parse(
						localStorage.getItem('notificationListIds')
					);
					if (storedNotif) {
						finalFilteredMemberNotifications = finalFilteredMemberNotifications.filter(
							(item) => !storedNotif.includes(item.id)
						);
					}
					setUserNotifications(finalFilteredMemberNotifications);
					setMemberData(result.data.getMember);
					const competitionRoleAdminExist = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_COMPETITION_ADMIN
					);
					const ackFosterRoleExist = result.data.getMember.roles.some(
						(memberRole) => memberRole.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
					);
					let competitionAdminNotifExist = false;
					let competitionAdminNotifRemove = false;
					result.data.getMember.notifications.forEach((memberNotif) => {
						if (memberNotif.type === COMPETITION_ADMIN) {
							let competitionAdminExistTempVal = memberNotif.identifiers.find(
								(memberIdentifier) =>
									memberIdentifier.id === COMPETITION_ADMIN_EXIST
							)?.value;
							competitionAdminNotifExist =
								competitionAdminExistTempVal === 'true';
							let competitionAdminNotifRemoveTempVal = memberNotif.identifiers.find(
								(memberIdentifier) =>
									memberIdentifier.id === IS_REMOVE_IDENTIFIER
							)?.value;
							competitionAdminNotifRemove =
								competitionAdminNotifRemoveTempVal === 'true';
						}
					});

					if (competitionRoleAdminExist) {
						if (!competitionAdminNotifExist && !competitionAdminNotifRemove) {
							addCompetitionAdminNotif(result.data.getMember);
						}
					}

					if (ackFosterRoleExist) {
						evaluateAddingNotifAckFoster(result.data.getMember);
					}

					setMemberQuickLinks(
						result.data.getMember.quickLinks.map(({ linkId }) => {
							const contentfulLink = contentfulQuickLinks.allContentfulQuickLink.edges.find(
								(n) => n.node.contentfulid === linkId
							);
							return {
								id: linkId,
								title: contentfulLink.node.title,
								image: contentfulLink.node.image.fluid.src,
								link:
									contentfulLink.node.link === RETRAINER_SEARCH
										? () =>
												navigate('/search', {
													state: {
														searchSelected: true,
														search: ACKNOWLEDGED_RETRAINER
													}
												})
										: () => navigate(`/${contentfulLink.node.link}`)
							};
						})
					);
				}
				fetchClaimsCount();
			} catch (error) {
				logToServer(currentUser?.UserId, error, `Dashboard => getMemberData`);
			}
		}
	};

	useEffect(() => {
		checkCurrentCompetitionOnGoingExist();
	}, []);

	const removeNotification = (id) => {
		AppSyncService.execute(dismissNotification, { notificationId: +id }).then(
			(result) => {
				if (result?.data?.dismissNotification) {
					setUserNotifications((prevState) =>
						prevState.filter((notification) => notification.id != id)
					);
				}
			}
		);
	};

	const tempRemoveNotification = (id) => {
		const filteredNotif = userNotifications.filter((item) => item.id !== id);
		let storedNotif = JSON.parse(localStorage.getItem('notificationListIds'));
		if (storedNotif) {
			storedNotif = [...storedNotif, id];
		} else {
			storedNotif = [id];
		}
		localStorage.setItem('notificationListIds', JSON.stringify(storedNotif));
		setUserNotifications([...filteredNotif]);
	};

	const updateRetirementAdminNotification = async (id, type, link) => {
		try {
			console.log(link + id);
			await AppSyncService.execute(saveRetirementDismissableNotification, {
				externalId: currentUser.UserId,
				source: type,
				link: ACKNOWLEDGED_RETIREMENT,
				reportId: id
			});
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => retirementdismissableNotifResult`
			);
		}
	};

	const updateCompetitionAdminNofitcation = async (id, type) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: parseInt(id),
					source: REMOVE_COMPETITION_ADMIN,
					link: ''
				}
			);
			if (result?.data) {
				const filteredMemberNotifications = userNotifications.filter(
					(userNotif) => userNotif.type !== type
				);
				setUserNotifications(filteredMemberNotifications);
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateCompetitionAdminNofitcation`
			);
		}
	};

	const updateAckFosterNofitcation = async (id, type) => {
		try {
			const result = await AppSyncService.execute(
				updateCompetitionAdminDismissableNotif,
				{
					memberId: parseInt(id),
					source: REMOVE_ACK_FOSTER,
					link: ''
				}
			);
			if (result?.data) {
				const filteredMemberNotifications = userNotifications.filter(
					(userNotif) => userNotif.type !== type
				);
				setUserNotifications(filteredMemberNotifications);
			}
		} catch (error) {
			logToServer(
				currentUser?.UserId,
				error,
				`Dashboard => updateAckFosterNofitcation`
			);
		}
	};

	return (
		<PageContainer>
			<SEO title="Dashboard" />
			<Row>
				<Col>
					<div className={classes.dashboardTabsContainer}>
						<ScrollMenuGeneric
							menuList={[
								{
									id: DashboardKeys.HOME,
									navigation: HOME,
									title: (
										<>
											Home
											{userNotifications.length > 0 && <NotificationIcon />}
										</>
									),
									show: true
								},
								...DASHBOARD_TAB_OPTIONS
							]}
							selectedSearch={activeTab}
							onClick={toggleTab}
							customStyles={{
								tabItem: { background: '#f8ecff', color: '#39289a' },
								selectedTabItem: { background: '#39289a', color: 'white' },
								tabContainer: { background: '#f8ecff' }
							}}
						/>
					</div>
					<Row>
						{activeTab === HOME && (
							<Home
								claimCount={claimCount}
								notifications={userNotifications}
								removeNotification={removeNotification}
								memberQuickLinks={memberQuickLinks}
								updateCompetitionAdminNofitcation={
									updateCompetitionAdminNofitcation
								}
								updateRetirementAdminNotification={
									updateRetirementAdminNotification
								}
								updateAckFosterNofitcation={updateAckFosterNofitcation}
								tempRemoveNotification={tempRemoveNotification}
							/>
						)}
						{activeTab === MYSTABLE && <HorseManagement location={location} />}
						{activeTab === FOLLOWING && (
							<FollowingTab
								claimCount={claimCount}
								memberData={memberData}
								memberQuickLinks={memberQuickLinks}
							/>
						)}
					</Row>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default Dashboard;

import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import useStyles from './styles';
import { getCroppedImage } from './helpers';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../utils/helpers';
import PrimaryButton from '../PrimaryButton';

const ThemedSlider = withStyles({
	thumb: { backgroundColor: '#006da6' }
})(Slider);

const MOBILE_MODAL_STYLES = {
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	border: 'none'
};

const DESKTOP_MODAL_STYLES = {
	maxWidth: 500,
	maxHeight: 500,
	top: '40%',
	left: '50%',
	transform: 'translate(-50%, -50%)'
};

const ImageCropModal = ({ imgSrc, isOpen, closeModal, onSaveCrop }) => {
	const classes = useStyles();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	}, []);
	const saveCroppedImage = useCallback(async () => {
		const croppedImage = await getCroppedImage(imgSrc, croppedAreaPixels);
		onSaveCrop(croppedImage);
	}, [imgSrc, croppedAreaPixels]);

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			style={{
				content: {
					padding: 0,
					...(!isSmallScreen ? DESKTOP_MODAL_STYLES : MOBILE_MODAL_STYLES)
				}
			}}
		>
			<div className={classes.cropperContainer}>
				<Cropper
					image={imgSrc}
					crop={crop}
					zoom={zoom}
					aspect={1}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
				/>
			</div>
			<div className={classes.imageControlsContainer}>
				<span className={classes.zoomControlLabel}>ZOOM</span>
				<ThemedSlider
					value={zoom}
					min={1}
					max={3}
					step={0.1}
					aria-labelledby="Zoom"
					onChange={(e, zoom) => setZoom(zoom)}
				/>
			</div>
			<div className={classes.actionsContainer}>
				<button className={classes.cancelButton} onClick={closeModal}>
					Cancel
				</button>
				<PrimaryButton
					style={{ marginTop: 0, width: 150 }}
					onClick={saveCroppedImage}
				>
					Crop
				</PrimaryButton>
			</div>
		</Modal>
	);
};

export default ImageCropModal;

import React from 'react';
import { Row, Col, Container } from 'react-grid-system';
import useStyles from '../Register/styles';
import SEO from '../seo';
import TwoColLayout from '../TwoColLayout';
import { Hidden } from 'react-grid-system';
import { useParams } from '@reach/router';
import { useLocation } from '@reach/router';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

const VerificationLandingPage = () => {
	const classes = useStyles();
	let { id } = useParams();
	const { addToast } = useToast();
	const location = useLocation();
	const { resendVerificationLink } = useAuth();
	const username = location.pathname.replace('/verification-landing-page/', '');
	const resendClickHandler = (event) => {
		event.preventDefault();
		resendVerificationLink(username).then(() => {
			addToast({ Message: 'Verification link resent.', IsSuccess: true });
		});
		// LimitExceedException not handled here as probability of it occuring does not justify the effort of handling it at this stage
	};

	return (
		<>
			<SEO title="Verification Landing Page" />
			<TwoColLayout>
				<TwoColLayout.Col1>
					<Container>
						<Row>
							<Col sm={4}></Col>
							<Col sm={6}>
								<h2 className={classes.Header}>
									Please verify your email address
								</h2>
								<p className={classes.description}>
									To confirm your registration as an OTT Community member, we
									need to verify your email address.
									<br />
									We sent a verification email to <b>{id}</b>
									<br />
									Please ensure to check your spam or junk folder, depending on
									your settings the verification email may not appear in your
									inbox. <br />
									Click the link to confirm and be part of the OTT Community.
								</p>
								<p className={classes.subHeading}>
									Didn&apos;t receive an email?{' '}
									<a href="" onClick={resendClickHandler}>
										RESEND
									</a>
								</p>
							</Col>
							<Col sm={2}></Col>
						</Row>
					</Container>
				</TwoColLayout.Col1>
				<Hidden xs sm>
					<TwoColLayout.Col2>
						<div className={classes.verificationImageDisplay}></div>
					</TwoColLayout.Col2>
				</Hidden>
			</TwoColLayout>
		</>
	);
};

export default VerificationLandingPage;

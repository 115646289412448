import React from 'react';
import useStyles from './styles';
import { Col, Row, useScreenClass } from 'react-grid-system';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import FormTextField from '../FormTextField';
import SimpleCheckBox from '../SimpleCheckBox';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';
import { checkIsSmallScreen } from '../../utils/helpers';
import { CommonKeys } from './../../utils/constants/index';

export const ADDRESS_FIELDS = {
	COUNTRY: 'country',
	STATE: 'state',
	POSTCODE: 'postcode',
	SUBURB: 'suburb',
	ADDRESS: 'address'
};

const FormAddressEntryGeneric = ({
	address,
	requiredFields = [
		ADDRESS_FIELDS.COUNTRY,
		ADDRESS_FIELDS.STATE,
		ADDRESS_FIELDS.SUBURB,
		ADDRESS_FIELDS.POSTCODE,
		ADDRESS_FIELDS.ADDRESS
	],
	onAddressChange,
	suburb,
	errorWithoutBorder = false,
	onSuburbChange,
	postcode,
	onPostCodeChange,
	country,
	onCountryChange,
	state,
	onAddressStateChange,
	isSubmitClicked,
	autoCompleteFunction = null,
	manualAddressCheckBoxData = null,
	toggleManualAddressCheckBox,
	titlePrefix = 'Thoroughbred',
	areAllFieldsOptional = false,
	disabled = false
}) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const populateResidentialAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		if (autoCompleteFunction) {
			autoCompleteFunction(address, addressDetails);
		} else {
			onAddressChange(address);
			onSuburbChange(addressDetails.Suburb);
			onPostCodeChange(addressDetails.Postcode);
			onAddressStateChange(addressDetails.State);
			onCountryChange(addressDetails.Country);
		}
	};
	const asterisk = areAllFieldsOptional ? '' : '*';

	return (
		<>
			<Row>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div
							className={
								isSmallScreen ? classes.fieldTitleMobile : classes.fieldTitle
							}
						>
							{titlePrefix} Address
							{requiredFields.includes(ADDRESS_FIELDS.ADDRESS) ? asterisk : ''}
						</div>
						<AddressAutoSuggestion
							value={address}
							onChange={onAddressChange}
							onAddressSelect={populateResidentialAddressFields}
							disableAutoSuggestion={manualAddressCheckBoxData.isSelected}
							formTextFieldStyle={
								isSubmitClicked &&
								!areAllFieldsOptional &&
								(address == null || address.trim().length === 0) &&
								!errorWithoutBorder
									? { border: '1px solid red' }
									: {}
							}
							canEdit={!disabled}
						/>
						{isSubmitClicked &&
							!areAllFieldsOptional &&
							requiredFields.includes(ADDRESS_FIELDS.ADDRESS) &&
							(address == null || address.trim().length === 0) && (
								<span
									className={
										isSmallScreen ? classes.errorTextMobile : classes.errorText
									}
								>
									Address is required
								</span>
							)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div
							className={
								isSmallScreen ? classes.fieldTitleMobile : classes.fieldTitle
							}
						>
							{titlePrefix} Suburb
							{requiredFields.includes(ADDRESS_FIELDS.SUBURB) ? asterisk : ''}
						</div>
						<FormTextField
							id={CommonKeys.SUBURB}
							value={suburb}
							onChange={(e) => onSuburbChange(e.target.value)}
							isDisabled={!manualAddressCheckBoxData.isSelected}
							styles={
								isSubmitClicked &&
								!areAllFieldsOptional &&
								requiredFields.includes(ADDRESS_FIELDS.SUBURB) &&
								(suburb == null || suburb.trim().length === 0) &&
								!errorWithoutBorder
									? { border: '1px solid red' }
									: {}
							}
							canEdit={!disabled}
						/>
						{isSubmitClicked &&
							!areAllFieldsOptional &&
							requiredFields.includes(ADDRESS_FIELDS.SUBURB) &&
							(suburb == null || suburb.trim().length === 0) && (
								<span
									className={
										isSmallScreen ? classes.errorTextMobile : classes.errorText
									}
								>
									Suburb is required
								</span>
							)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div
							className={
								isSmallScreen ? classes.fieldTitleMobile : classes.fieldTitle
							}
						>
							{titlePrefix} State
							{requiredFields.includes(ADDRESS_FIELDS.STATE) ? asterisk : ''}
						</div>
						<StateSelector
							id={CommonKeys.STATE}
							country={country}
							value={state}
							changeHandler={(newStateValue) =>
								onAddressStateChange(newStateValue)
							}
							isDisabled={!manualAddressCheckBoxData.isSelected || disabled}
							isError={
								!errorWithoutBorder &&
								isSubmitClicked &&
								!areAllFieldsOptional &&
								(state == null || state.trim().length === 0)
							}
						/>
						{isSubmitClicked &&
							!areAllFieldsOptional &&
							requiredFields.includes(ADDRESS_FIELDS.STATE) &&
							(state == null || state.trim().length === 0) && (
								<span
									className={
										isSmallScreen ? classes.errorTextMobile : classes.errorText
									}
								>
									State is required
								</span>
							)}
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div
							className={
								isSmallScreen ? classes.fieldTitleMobile : classes.fieldTitle
							}
						>
							{titlePrefix} Postcode
							{requiredFields.includes(ADDRESS_FIELDS.POSTCODE) ? asterisk : ''}
						</div>
						<FormTextField
							id={CommonKeys.POSTCODE}
							value={postcode}
							onChange={(e) => onPostCodeChange(e.target.value)}
							isDisabled={!manualAddressCheckBoxData.isSelected}
							styles={
								isSubmitClicked &&
								!areAllFieldsOptional &&
								(postcode == null || postcode.toString().length != 4) &&
								!errorWithoutBorder
									? { border: '1px solid red' }
									: {}
							}
							canEdit={!disabled}
						/>
						{isSubmitClicked &&
							!areAllFieldsOptional &&
							requiredFields.includes(ADDRESS_FIELDS.POSTCODE) &&
							(postcode == null || postcode.toString().length != 4) && (
								<span
									className={
										isSmallScreen ? classes.errorTextMobile : classes.errorText
									}
								>
									Post code must be a 4 digit number
								</span>
							)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}>
					<div className={classes.ProfilefieldContainer}>
						<div
							className={
								isSmallScreen ? classes.fieldTitleMobile : classes.fieldTitle
							}
						>
							{titlePrefix} Country
							{requiredFields.includes(ADDRESS_FIELDS.COUNTRY) ? asterisk : ''}
						</div>
						<CountrySelector
							id={CommonKeys.COUNTRY}
							value={country}
							changeHandler={(newCountryValue) =>
								onCountryChange(newCountryValue)
							}
							isDisabled={!manualAddressCheckBoxData.isSelected || disabled}
							isError={
								!errorWithoutBorder &&
								isSubmitClicked &&
								!areAllFieldsOptional &&
								(country == null || country.trim().length === 0)
							}
						/>
						{isSubmitClicked &&
							!areAllFieldsOptional &&
							requiredFields.includes(ADDRESS_FIELDS.COUNTRY) &&
							(country == null || country.trim().length === 0) && (
								<span
									className={
										isSmallScreen ? classes.errorTextMobile : classes.errorText
									}
								>
									Country is required
								</span>
							)}
					</div>
				</Col>
				<Col lg={4} md={4} sm={12}></Col>
			</Row>
			<Row>
				{disabled ? null : (
					<Col>
						<SimpleCheckBox
							small={true}
							noCapitalize={true}
							data={manualAddressCheckBoxData}
							changeCheckboxState={toggleManualAddressCheckBox}
							key={manualAddressCheckBoxData?.id}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default FormAddressEntryGeneric;

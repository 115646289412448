import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formSectionBody: {
		'& > div:first-child > div:first-child': {
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5
		}
	}
}));

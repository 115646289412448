import React from 'react';
import classNames from 'classnames';
import { WORD_DOC_MIME_TYPE } from '../../utils/constants';
import useStyles from './styles';
import { Col, Row } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
// import { Typography } from '@material-ui/core';

const UploadPhoto = ({
	id,
	textToRender = null,
	sizeLimitMB,
	formats,
	error,
	success,
	status,
	data,
	getImage,
	removeImage,
	labelName,
	numberOfImages,
	canEdit = true,
	disableApproved = false,
	ignoreApprovedInLimit = false,
	handleChangeDropDown,
	showDropdownType = false,
	documentTypeReference = [],
	showPersonalDetailError = false,
	isDisabled = false
}) => {
	const classes = useStyles();
	let maxImages = numberOfImages ? numberOfImages : 3;
	if (
		formats &&
		formats.find((item) => item === 'jpg') &&
		!formats.find((item) => item === 'jpeg')
	) {
		formats.push('jpeg');
	} else if (
		formats &&
		formats.find((item) => item === 'jpeg') &&
		!formats.find((item) => item === 'jpg')
	) {
		formats.push('jpg');
	}
	if (
		formats &&
		(formats.find((item) => item === 'doc') ||
			formats.find((item) => item === 'msword') ||
			formats.find((item) => item === 'docx') ||
			formats.find((item) => item === WORD_DOC_MIME_TYPE))
	) {
		formats.push('doc,docx,application/msword,' + WORD_DOC_MIME_TYPE);
	}
	const accept = formats?.length > 0 ? '.' + formats.join(',.') : '*.*';
	const minimumUploadSize = 0.001;
	const checkFile = (file) => {
		let fileNameVerified = true;
		let totalSizeMB = file[0].size / Math.pow(1024, 2);
		let isMinimumUploadSize = totalSizeMB < minimumUploadSize;
		let checkFileFormat = checkFormat(file[0]);
		data.map((img) => {
			if (img.filename === file[0].name) {
				fileNameVerified = false;
			}
		});
		if (
			fileNameVerified &&
			checkFileFormat &&
			!isMinimumUploadSize &&
			totalSizeMB < sizeLimitMB
		) {
			success(file[0]);
		} else {
			error(isMinimumUploadSize);
		}
	};

	const checkFormat = (file) => {
		let formatCheck = false;
		formats.map((format) => {
			if (file.type.includes(format)) {
				formatCheck = true;
			}
		});
		return formatCheck;
	};

	const renderImageLinks = () => {
		if (data && data.length > 0) {
			return data.map((img, index) => {
				if (showDropdownType) {
					return (
						<>
							<Row style={{ marginTop: 10 }}>
								<Col sm={12} lg={3}>
									<div key={img.filename}>
										<span
											id={`${id}_${index}`}
											className={
												img.approved ? classes.approved : classes.uploadedFile
											}
											onClick={() => getImage(img)}
										>
											{img.filename}
										</span>
										{!(disableApproved && img.approved) &&
											status !== 'Review' &&
											canEdit && (
												<span
													className={classes.clearUpload}
													onClick={() => removeImage(img)}
												>
													{img.approved}
													&#10006;
												</span>
											)}
									</div>
								</Col>
								<Col sm={12} lg={3}>
									{showDropdownType && (
										<>
											<FormDropDown
												showBlank
												name="documentType"
												id={`document-type-${img.filename}`}
												items={documentTypeReference}
												selectedId={img.documentType}
												onChange={(e) => handleChangeDropDown(e, img.key)}
												placeholder={'Select document type'}
											/>
											{showPersonalDetailError && !img.documentType && (
												<span style={{ color: '#db0000', fontSize: 15 }}>
													Document type is required
												</span>
											)}{' '}
										</>
									)}
								</Col>
							</Row>
						</>
					);
				} else {
					return (
						<div key={img.filename}>
							<span
								id={`${id}_${index}`}
								className={
									img.approved ? classes.approved : classes.uploadedFile
								}
								onClick={() => getImage(img)}
							>
								{img.filename}
							</span>
							{!(disableApproved && img.approved) &&
								status !== 'Review' &&
								canEdit && (
									<span
										className={classes.clearUpload}
										onClick={() => removeImage(img)}
									>
										{img.approved}
										&#10006;
									</span>
								)}
						</div>
					);
				}
			});
		}
	};
	const isDataPrepared =
		data &&
		(ignoreApprovedInLimit
			? data.filter((img) => img.approved !== true).length < maxImages
			: data.length < maxImages);

	return (
		<>
			{canEdit && (
				<label style={{ minWidth: '100%' }}>
					<input
						id={id}
						type="file"
						disabled={!isDataPrepared || isDisabled}
						className="hidden"
						onChange={(e) => checkFile(e.target.files)}
						value=""
						accept={accept}
					/>
					<div
						className={classNames({
							'border-0 rounded text-white text-center no-underline inline-block w-full text-tiny font-normal focus:outline-none cursor-pointer h-fit py-2 px-2 lg:h-10 lg:p-0': true,
							'bg-primary': isDataPrepared || !isDisabled,
							'bg-disabled': !isDataPrepared || isDisabled
						})}
					>
						<div className="lg:mt-2">
							{labelName ? labelName : 'Upload Image'}
						</div>
					</div>
				</label>
			)}
			{textToRender
				? // <span style={{ fontSize: 14 }}>
				  textToRender
				: // </span>
				  renderImageLinks()}
		</>
	);
};

export default UploadPhoto;

import React from 'react';
import useStyles, { StyledHorseDetailsHeaderButtons } from './styles';

const HeaderActionsContainer = ({ children }) => {
	return (
		<StyledHorseDetailsHeaderButtons>
			{children}
		</StyledHorseDetailsHeaderButtons>
	);
};

const HeaderAction = ({
	label,
	onClick,
	id,
	showAction,
	variant = 'filled'
}) => {
	const classes = useStyles();
	return (
		showAction && (
			<input
				id={id}
				type="button"
				value={label}
				className={variant === 'unfilled' ? classes.unfilled : classes.filled}
				onClick={onClick}
			/>
		)
	);
};

export { HeaderActionsContainer, HeaderAction };

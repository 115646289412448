import React from 'react';
import useStyles from './styles';
import RetrainerMonthlyForms from '../RetrainerMonthlyForms';
import RetrainerRehabilitationForm from '../RetrainerRehabilitationForm';
import RetrainerConcludedForm from '../RetrainerConcludedForm';
import {
	FOR_SALE,
	REHABILITATION,
	SPELLING,
	IN_WORK
} from '../../utils/constants';
import LoaderSpinner from '../LoaderSpinner';
import { HorseManagementKeys } from './../../utils/constants/index';
import { navigate } from '@reach/router';
import { Container, Row, Col } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import CheckboxInputField from '../FormComponents/CheckboxInputField';
import Toggle from '../Toggle';
import { formatDateReadableVariant } from '../../utils/helpers';

const RetrainerMonthlyDataTable = ({
	retrainerHorseList,
	isLoading,
	updateCheckBox,
	submitForm,
	injuryOptions,
	priceBracket,
	rehomingPurposeList,
	disciplineOptions,
	retrainPurpose,
	concludedReturnedReasons,
	conclusionReasons,
	illnessOptions,
	isMobile,
	fundingTypes,
	statusList,
	onChangeToggle,
	onChangeDropDown
}) => {
	const mobileRowRender = (horse, statusList) => {
		return (
			<Container className={classes.mobileContainer}>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<span
								className={classes.tbodyNameSpan}
								onClick={() => navigate(`horse-profile/${horse.id}`)}
							>
								{horse?.ottInformation?.paddockName ?? horse.horseName}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						{!horse.isSelected && (
							<>
								<div className={classes.fieldTitleMobile}>Funded by</div>
								{fundingTypes?.find((item) => item.id === horse.funding)
									?.value ||
									fundingTypes?.find(
										(item) => item.id === 1 //Self
									)?.value}
							</>
						)}
						{horse.isSelected && (
							<>
								<div className={classes.fieldTitleMobile}>Funded by</div>
								<Toggle
									labelNegative="Self"
									labelPositive="Client"
									onClick={(e) => onChangeToggle(e, horse.id)}
									value={
										fundingTypes?.find((item) => item.id === horse.funding)
											?.value === 'Self'
											? false
											: true
									}
								/>
							</>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Retraining Status</div>
							{!horse.isSelected && (
								<td className={classes.td}>{horse.retrainStatus}</td>
							)}
							{horse.isSelected && (
								<div className={classes.cellDropDownWrapper}>
									<FormDropDown
										items={statusList}
										selectedId={horse.retrainerStatus}
										onChange={(e) => {
											onChangeDropDown(e.target.value, horse.id);
										}}
									/>
								</div>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>As Of Date</div>
							{formatDateReadableVariant(horse.asOfDate)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Edit</div>
							<div className={classes.checkboxMobileContainer}>
								<CheckboxInputField
									isChecked={horse.isSelected}
									onChange={() => updateCheckBox(horse.id, horse.isSelected)}
									idPrefix={`${horse.id}_change_status`}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		);
	};

	const renderRetrainerList = () => {
		if (retrainerHorseList.length > 0) {
			return retrainerHorseList.map((d) => {
				return (
					<React.Fragment key={d.id}>
						<tr
							style={
								!isMobile ? { display: 'flex', flexDirection: 'column' } : null
							}
						>
							{isMobile && <Col xs={12}>{mobileRowRender(d, statusList)}</Col>}
							{d.openForm && (
								<Col xs={12}>
									{d.retrainerStatus === 4 && (
										<RetrainerMonthlyForms
											id={HorseManagementKeys.DATE_ADVERTIZED}
											title="Change Status: Retrainer - For Sale"
											cancel={() => updateCheckBox(d.id, true)}
											effectiveDateLabel="Date Advertised"
											submitForm={(data) =>
												submitForm(
													data,
													FOR_SALE,
													d.id,
													d.retrainerStatus,
													d.funding
												)
											}
										/>
									)}
									{d.retrainerStatus === 1 && (
										<RetrainerMonthlyForms
											id={HorseManagementKeys.DATE_COMMENCED}
											title="Change Status: Retraining - Currently In Work"
											cancel={() => updateCheckBox(d.id, true)}
											submitForm={(data) =>
												submitForm(
													data,
													IN_WORK,
													d.id,
													d.retrainerStatus,
													d.funding
												)
											}
										/>
									)}
									{d.retrainerStatus === 3 && (
										<RetrainerMonthlyForms
											id={HorseManagementKeys.DATE_COMMENCED}
											title="Change Status: Retraining - Spelling"
											cancel={() => updateCheckBox(d.id, true)}
											submitForm={(data) =>
												submitForm(
													data,
													SPELLING,
													d.id,
													d.retrainerStatus,
													d.funding
												)
											}
										/>
									)}
									{d.retrainerStatus === 2 &&
										injuryOptions.length > 0 &&
										injuryOptions &&
										illnessOptions.length > 0 &&
										illnessOptions && (
											<RetrainerRehabilitationForm
												cancel={() => updateCheckBox(d.id, true)}
												title="Change Status: Retraining - Rehabilitation"
												injuryOptions={injuryOptions}
												illnessOptions={illnessOptions}
												submitForm={(data) =>
													submitForm(
														data,
														REHABILITATION,
														d.id,
														d.retrainerStatus,
														d.funding
													)
												}
											/>
										)}
									{d.retrainerStatus === 5 && (
										<RetrainerConcludedForm
											priceBracket={priceBracket}
											cancel={() => updateCheckBox(d.id, true)}
											rehomingPurposeList={rehomingPurposeList}
											disciplineOptions={disciplineOptions}
											retrainPurpose={retrainPurpose}
											concludedReturnedReasons={concludedReturnedReasons}
											conclusionReasons={conclusionReasons}
											title="Change Status: Concluded Retraining"
											horseCode={d.id}
											submitForm={(data, type) =>
												submitForm(
													data,
													type,
													d.id,
													d.retrainerStatus,
													d.funding
												)
											}
										/>
									)}
								</Col>
							)}
						</tr>
					</React.Fragment>
				);
			});
		}
	};

	const classes = useStyles();
	return (
		<>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			{!isLoading && (
				<table className={classes.table}>
					<tbody className={classes.tbody}>{renderRetrainerList()}</tbody>
				</table>
			)}
		</>
	);
};

export default RetrainerMonthlyDataTable;

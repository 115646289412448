import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useAuth } from '../../hooks/useAuth';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';
import { useTheme } from 'react-jss';
import { ROLE_RV_EQUINE_WELFARE } from '../../utils/constants';
import {
	DASHBOARD_ROUTE,
	PAGE_NOT_FOUND_ROUTE
} from '../../utils/constants/routes';

const LOGIN_ROUTE = '/login';

const ProtectedRoute = (props) => {
	const [loading, setLoading] = useState(true);
	const { getCurrentUser } = useAuth();
	const [currentUser, setCurrentUser] = useState(null);
	const theme = useTheme();
	const classes = useStyles(theme);

	const { component: Component } = props;

	useEffect(() => {
		const fetchData = async () => {
			const result = await getCurrentUser();
			setCurrentUser(result);
			setLoading(false);
		};
		fetchData();
	}, []);

	const GoToLogin = () => {
		navigate(LOGIN_ROUTE);
	};

	const LoadingDiv = () => {
		return (
			<div className={classes.loadingDiv}>
				<LoaderSpinner status={true} />
			</div>
		);
	};

	const GoToHome = () => {
		navigate(DASHBOARD_ROUTE);
	};

	const GoToPageNotFound = () => {
		navigate(PAGE_NOT_FOUND_ROUTE);
	};

	const renderComponent = () => {
		if (currentUser) {
			if (props.rolesRequired) {
				let isAuthorized = false;
				props.rolesRequired.forEach((roleRequired) => {
					if (currentUser?.Roles?.includes(roleRequired)) {
						isAuthorized = true;
					}
				});
				if (isAuthorized) {
					return <Component {...props} />;
				} else {
					if (props.isUseNotFoundPage) {
						return GoToPageNotFound();
					} else {
						return GoToHome();
					}
				}
			}
			if (props.adminOnly) {
				if (currentUser?.Roles?.includes(ROLE_RV_EQUINE_WELFARE)) {
					return <Component {...props} />;
				} else {
					return GoToHome();
				}
			} else {
				return <Component {...props} />;
			}
		} else if (loading) {
			return <LoadingDiv />;
		} else {
			return GoToLogin();
		}
	};

	return <>{renderComponent()}</>;
};

export default ProtectedRoute;

import { navigate } from 'gatsby';
import { ACKNOWLEDGED_RETRAINER, THOROUGHBRED } from '../../utils/constants';

/**
 * Maps links from contentful to in app pages where the links map to particular tabs in pages
 * @param {string} link - Page link configured in contentful
 * @returns {function} - Navigation function
 */
export const mapLinksToAppPages = (link) => {
	switch (link) {
		case 'search-retrainer':
			return () =>
				navigate('/search', {
					state: { searchSelected: true, search: ACKNOWLEDGED_RETRAINER }
				});
		case 'search-horse':
			return () =>
				navigate('/search', {
					state: { searchSelected: true, search: THOROUGHBRED }
				});
		default:
			return () => navigate(link);
	}
};

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseOTTStatus,
	getUserFosterForms,
	reviewFosterWeeklyAssessmentForm,
	setOttProgramAndStatus,
	unclaimHorse,
	unclaimHorseForAll
} from '../../graphql/custom.queries';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import {
	CONCLUDED_REASON,
	REHABILIATION,
	MAXREASONLENGTH,
	SPELLING,
	CONCLUDED
} from './constants';
import { formatDate, formatDateReadableVariant } from '../../utils/helpers';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { navigate } from '@reach/router';
import { useToast } from '../../hooks/useToast';
import { ROLE_RV_ACKNOWLEDGED_RETRAINER } from '../../utils/constants';

const FosterWeeklyFormReviewInfo = ({ data, mobile, getImage }) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [currentStatus, setCurrentStatus] = useState(REHABILIATION);
	const { addToast } = useToast();
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [horseCurrentStatus, setHorseCurrentStatus] = useState(null);

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
		AppSyncService.execute(getHorseOTTStatus, {
			horseCode: data?.horseCode
		}).then((response) => {
			if (response.data?.getHorseOTTStatus?.horseOTTStatusId)
				setHorseCurrentStatus(response.data.getHorseOTTStatus.horseOTTStatusId);
		});
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewFosterWeeklyAssessmentForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveFosterWeeklyAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveFosterWeeklyAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const generatePayloadOttStatus = ({
		data,
		horseCode,
		memberExternalId,
		horseCurrentStatus
	}) => {
		let ottStatusPayload = {
			programId: 3,
			horseCode: horseCode,
			memberExternalId: memberExternalId
		};
		if (!data.hasHorseStatusChanged) {
			ottStatusPayload = {
				...ottStatusPayload,
				statusId: horseCurrentStatus,
				asOfDate: formatDate(data.dateOfAssessment)
			};
		} else {
			if (data.newStatus?.id === 1) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 25,
					asOfDate: formatDate(data.rehabDateCommenced)
				};
			} else if (data.newStatus?.id === 2) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 26,
					asOfDate: formatDate(
						data.concludedReason?.id === CONCLUDED_REASON.EUTHANISED
							? data.deceasedDateOfDeath
							: data.concludedReason?.id ===
							  CONCLUDED_REASON.RETAINING_FOR_OWN_USE
							? data.dateCommenced
							: data.dateSold
					)
				};
			} else if (data.newStatus?.id === 3) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 27,
					asOfDate: formatDate(data.dateCommenced)
				};
			} else if (data.newStatus?.id === 4) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 35,
					asOfDate: formatDate(data.dateFoundationTraining)
				};
			} else if (data.newStatus?.id === 5) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 36,
					asOfDate: formatDate(data.dateFoundationTraining)
				};
			} else return null;
		}
		return ottStatusPayload;
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewFosterWeeklyAssessmentForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveFosterWeeklyAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				if (
					data.newStatus?.id === 2 &&
					data.concludedReason?.id === CONCLUDED_REASON.RETAINING_FOR_OWN_USE &&
					data.isRetrainHorse
				) {
					if (
						data.member?.roles?.filter(
							(role) => role.code === ROLE_RV_ACKNOWLEDGED_RETRAINER
						)?.length > 0
					) {
						const ottStatus = {
							programId: 2,
							horseCode: data.horseCode,
							memberExternalId: data.member?.externalId,
							statusId: 1,
							asOfDate: formatDate(data.dateCommenced)
						};
						if (ottStatus) {
							AppSyncService.execute(setOttProgramAndStatus, ottStatus).then(
								() => {
									navigate(`/review-list`);
								}
							);
						}
					}
				} else {
					const ottStatus = generatePayloadOttStatus({
						data,
						horseCode: data.horseCode,
						memberExternalId: data.member?.externalId,
						horseCurrentStatus
					});
					if (ottStatus) {
						AppSyncService.execute(setOttProgramAndStatus, ottStatus).then(
							() => {
								if (data.concludedReason?.id === CONCLUDED_REASON.EUTHANISED) {
									const input = {
										horse_code: data.horseCode,
										member_id: data.member?.externalId,
										unclaim_reason: 1, //1 is equivalent to Deceased on unclaim_reason
										full_circle_interested: false,
										cause_of_death_id: 2,
										date_of_death: formatDate(data.deceasedDateOfDeath),
										other_details: null,
										deceased_other_info: null,
										knackery_location: null
									};
									AppSyncService.execute(unclaimHorseForAll, {
										input
									}).then(() => {
										navigate(`/review-list`);
									});
								} else if (
									data.concludedReason?.id === CONCLUDED_REASON.SOLD_REHOMED
								) {
									const input = {
										date_rehomed: formatDate(data.dateSold),
										horse_code: data.horseCode,
										member_id: data.member?.externalId,
										price: data.priceBracket?.id,
										purpose: data.purpose?.id,
										rehomed_disciplines: data.selectedDisciplines?.map(
											(discipline) => discipline.id
										)
									};

									AppSyncService.execute(unclaimHorse, { input }).then(() => {
										navigate(`/review-list`);
									});
								} else navigate(`/review-list`);
							}
						);
					}
				}
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveFosterWeeklyAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	useEffect(() => {
		AppSyncService.execute(getUserFosterForms, {
			memberExternalId: data.member.externalId,
			horseCode: data.horseCode
		}).then((response) => {
			const {
				data: { getFosterWeeklyAssessmentForms }
			} = response;
			if (getFosterWeeklyAssessmentForms.length >= 2) {
				const selectedFosterHasPrevious = getFosterWeeklyAssessmentForms.some(
					(fosterWeekly) => data.weekId > fosterWeekly.weekId
				);
				let previousFosterWeekly = null;
				if (selectedFosterHasPrevious) {
					previousFosterWeekly = getFosterWeeklyAssessmentForms.find(
						(fosterWeekly) => data.weekId - 1 === fosterWeekly.weekId
					);
					if (!previousFosterWeekly) {
						setCurrentStatus(
							horseCurrentStatus === 26
								? CONCLUDED
								: horseCurrentStatus === 27
								? SPELLING
								: REHABILIATION
						);
					} else {
						setCurrentStatus(previousFosterWeekly?.newStatus?.value);
					}
				} else {
					setCurrentStatus(
						horseCurrentStatus === 26
							? CONCLUDED
							: horseCurrentStatus === 27
							? SPELLING
							: REHABILIATION
					);
				}
			}
		});
	}, []);

	const onHasHorseStatusChangedYesRehabilitation = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Please indicate reason for rehabilitation*
							</div>
							{data.rehabReason?.value}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Other Retirement Rehabilitation*
							</div>
							{data.rehabReasonOther}
						</div>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Date Rehabilitation Commenced*"
							value={
								data.rehabDateCommenced
									? formatDateReadableVariant(data.rehabDateCommenced)
									: ''
							}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								{`Please provide an update on the horse's progress and care plan for the week ahead*`}
							</div>
							{data.horseProgressUpdate}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Have you encountered any issues with the horse this week?
							</div>
							{data.horseIssuesEncountered}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Feeding requirements/plan{' '}
								<span className={classes.fontWeightNormal}>
									(including whether the horse is currently eating well)
								</span>
							</div>
							{data.feedingPlan}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Are there any lameness or physical health concerns?{' '}
								<span className={classes.fontWeightNormal}>
									(including recurring)
								</span>
							</div>
							{data.physicalConcerns}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please provide details*</div>
							{data.physicalConcernDetails}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Has the horse required vet attention or treatment in the past
								week?
							</div>
							{data.vetTreatment ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please provide details*</div>
							{data.vetTreatmentDetails}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onHasHorseStatusChangedYesConcludedEuthanised = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Date of death*"
							value={
								data.deceasedDateOfDeath
									? formatDateReadableVariant(data.deceasedDateOfDeath)
									: ''
							}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Reason*</div>
							{data.deceasedReasonOfDeath?.value}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Injury Type*</div>
							{data.injuryType.map((injury) => injury.value).join(', ')}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Other injury details*</div>
							{data.deceasedInjuryDetailsOther}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Illness Type*</div>
							{data.illnessType.map((illness) => illness.value).join(', ')}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Other illness details*</div>
							{data.deceasedIllnessDetailsOther}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Unsuitable for rehoming reason*
							</div>
							{data.unsuitableReason
								.map((unsuitable) => unsuitable.value)
								.join(', ')}
						</div>
					</Col>
					<Col xs={12} lg={6}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Other Unsuitable for rehoming reason*
							</div>
							{data.unsuitableReasonOther}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Other reason for death*</div>
							{data.deceasedReasonForDeathOther?.value}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onHasHorseStatusChangedYesConcludedSoldRehomed = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Date Sold*"
							value={
								data.dateSold ? formatDateReadableVariant(data.dateSold) : ''
							}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Price Bracket*</div>
							{data.priceBracket?.value}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Purpose*</div>
							{data.purpose?.value}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Discipline*</div>
							{data.selectedDisciplines
								.map((discipline) => discipline.value)
								.join(', ')}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Other Disciplines*</div>
							{data.selectedDisciplinesOther}
						</div>
					</Col>
				</Row>

				<hr
					style={{
						height: 2,
						backgroundColor: '#edf5f9',
						border: 'none'
					}}
				/>

				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.subSectionHeader}>New Owner Details</div>
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Full Name</div>
							{data.fullName}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Email</div>
							{data.email}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Postcode*</div>
							{data.postcode}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Do you want to Full Circle this horse?
							</div>
							{data.isFullCircle ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Type of Assistance*</div>
							{data.assistanceType
								.map((assistance) => assistance.value)
								.join(', ')}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onHasHorseStatusChangedYesConcludedRetainForOwnUse = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Date Commenced*"
							value={
								data.dateCommenced
									? formatDateReadableVariant(data.dateCommenced)
									: ''
							}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Are you going to be retraining this horse?
							</div>
							{data.isRetrainHorse ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Purpose*</div>
							{data.purpose?.value}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Other Purpose*</div>
							{data.purposeOther}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Do you want to Full Circle this horse?
							</div>
							{data.isFullCircle ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Type of Assistance*</div>
							{data.assistanceType?.value}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onHasHorseStatusChangedYesSpelling = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Date Commenced*"
							value={
								data.dateCommenced
									? formatDateReadableVariant(data.dateCommenced)
									: ''
							}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								What is the expected timeframe for Spelling and the reason?*
							</div>
							{data.spellingExpectedTimeframe}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Feeding requirements/ plan?{' '}
								<span className={classes.fontWeightNormal}>
									(including whether the horse is currently eating well)
								</span>
							</div>
							{data.feedingPlan}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Are there any lameness or physical health conerns?
								<span className={classes.fontWeightNormal}>
									(including recurring)
								</span>
							</div>
							{data.physicalConcerns ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please provide details*</div>
							{data.physicalConcernDetails}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Has the horse required vet attention or treatment in the past
								week?
							</div>
							{data.vetTreatment ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please provide details*</div>
							{data.vetTreatmentDetails}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onHasTheHorseBeenInWorkThisWeekNo = () => {
		return (
			<>
				<Col xs={12} lg={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.labelField}>Please explain why*</div>
						{data.hasHorseBeenInWorkThisWeekExplaination}
					</div>
				</Col>
			</>
		);
	};

	const onHasTheHorseBeenInWorkThisWeekYes = () => {
		return (
			<>
				<Col xs={12} lg={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.labelField}>
							Details of work sessions this week*
						</div>
						{data.detailsOfWorkSessionThisWeek}
					</div>
				</Col>
				<Col xs={12} lg={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.labelField}>
							Key progress/achievements to date*
						</div>
						{data.keyProgressAchievementsToDate}
					</div>
				</Col>
				<Col xs={12} lg={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.labelField}>
							What area(s) need extra work / attention?*
						</div>
						{data.areaNeedExtraWorkAttention}
					</div>
				</Col>
			</>
		);
	};

	const onHasHorseStatusChangedYesFoundation = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Date Foundation Training Commenced*"
							value={
								data.dateFoundationTraining
									? formatDateReadableVariant(data.dateFoundationTraining)
									: ''
							}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Has the horse been in work this week?
							</div>
							{data.hasHorseBeenInWorkThisWeek ? 'Yes' : 'No'}
						</div>
					</Col>
					{data.hasHorseBeenInWorkThisWeek
						? onHasTheHorseBeenInWorkThisWeekYes()
						: onHasTheHorseBeenInWorkThisWeekNo()}
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Work plan for the coming week?*
							</div>
							{data.workPlanForTheComingWeek}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Are there any lameness or physical health conerns?
							</div>
							{data.anyLamenessOrPhysicalHealthConcern ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please give details*</div>
							{data.physicalHealthConcern}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Has the horse required vet attention or treatment in the past
								week?
							</div>
							{data.horseRequiredVetAttention ? 'Yes' : 'No'}
						</div>
					</Col>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Please explain why*</div>
							{data.requiredVetAttentionExplaination}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const onEvaluateEuthanised = () => {
		switch (data.concludedReason?.id) {
			// Case 3a - New status Concluded with the reason of euthanised
			case CONCLUDED_REASON.EUTHANISED:
				return onHasHorseStatusChangedYesConcludedEuthanised();

			//Case 3b - New status Concluded with the reason of sold/rehomed
			case CONCLUDED_REASON.SOLD_REHOMED:
				return onHasHorseStatusChangedYesConcludedSoldRehomed();

			//New status Concluded with the reason of Retain for own use
			case CONCLUDED_REASON.RETAINING_FOR_OWN_USE:
				return onHasHorseStatusChangedYesConcludedRetainForOwnUse();
		}
	};

	return (
		<>
			<div
				style={{
					borderTop: '1px solid #b3bcc1',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: '1px solid #b3bcc1'
				}}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="STAR Weekly Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row>
					<Col xs={12}>
						<FormLabelField
							title="Date of assessment*"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.dateOfAssessment)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.lastSubmitted)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField title="Week Number*" value={data.weekId} />
					</Col>

					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Progress photo/s*</div>
							<UploadPhoto
								data={data.progressPhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div
								className={classes.labelField}
							>{`Has the horse's status changed?`}</div>
							{data.hasHorseStatusChanged ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Current Status</div>
							{currentStatus}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>New Status*</div>
							{!data.hasHorseStatusChanged
								? currentStatus
								: data.newStatus?.value}
						</div>
					</Col>
				</Row>
				{/* Case 1 - Has horse status change no */}
				{/* Case 2 - New status Rehabilitation */}
				{data.hasHorseStatusChanged && data.newStatus?.id === 1
					? onHasHorseStatusChangedYesRehabilitation()
					: null}
				{/* Case 3 - New status Concluded */}
				{data.hasHorseStatusChanged && data.newStatus.id === 2 ? (
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Reason for Concluded*</div>
							{data.concludedReason?.value}
						</div>
					</Col>
				) : null}

				{data.hasHorseStatusChanged && data.newStatus.id === 2
					? onEvaluateEuthanised()
					: null}
				{/* Case 4 - New status Spelling */}
				{data.hasHorseStatusChanged && data.newStatus?.id === 3
					? onHasHorseStatusChangedYesSpelling()
					: null}

				{/* Case 5 - New status Foundation Training – In Hand */}
				{/* {data.hasHorseStatusChanged && data.newStatus?.id === 4
					? onHasHorseStatusChangedYesSpelling()
					: null} */}

				{/* Case 6 - New status Foundation Training – Under Saddle/In hand  */}
				{data.hasHorseStatusChanged &&
				(data.newStatus?.id === 5 || data.newStatus?.id === 4)
					? onHasHorseStatusChangedYesFoundation()
					: null}

				<Col xs={12} lg={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.labelField}>
							Do you have any other concerns to report? Changes to your Initial
							Assessment?
						</div>
						{data.otherConcerns}
					</div>
				</Col>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
				isReadOnly={data.status !== 'Review'}
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};

export default FosterWeeklyFormReviewInfo;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	}
}));

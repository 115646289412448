import { createUseStyles } from 'react-jss';

export default createUseStyles({
	dropDown: {
		float: 'right',
		margin: '20px 30px 0px'
	},
	dropDownMobile: {
		float: 'right',
		margin: '11px 30px 0px'
	}
});

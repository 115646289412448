import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
const ResizingTextArea = ({
	id,
	title,
	placeholder,
	onChange,
	maxChars,
	name,
	value,
	rows = 1,
	styles,
	charLengthStyle,
	borderColor,
	canEdit = true,
	titleStyles,
	visibilitySelector,
	isShowRemainingChars = true
}) => {
	const classes = useStyles();
	const textAreaRef = useRef(null);
	const [text, setText] = useState('');
	const [remainingChars, setRemainingChars] = useState('');
	const [textAreaHeight, setTextAreaHeight] = useState('auto');
	const [parentHeight, setParentHeight] = useState('auto');

	useEffect(() => {
		setParentHeight(`${textAreaRef.current?.scrollHeight}px`);
		setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
	}, [text]);

	const onChangeHandler = (event) => {
		setTextAreaHeight('auto');
		setParentHeight(`${textAreaRef.current?.scrollHeight}px`);
		setText(event.target.value);
		if (onChange) {
			onChange(event);
		}
	};

	useEffect(() => {
		setText(value);
	}, [value]);

	const pluraliseCharRem = (numChars) => {
		if (numChars === 0 || numChars > 1) {
			return numChars + ' characters remaining';
		} else {
			return numChars + ' character remaining';
		}
	};

	const textAreaStyling = {
		border: `1px solid ${borderColor}`,
		height: textAreaHeight
	};
	useEffect(() => {
		setRemainingChars(pluraliseCharRem(maxChars - (value?.length || 0)));
	}, [value]);

	const renderField = () => {
		if (canEdit == false) {
			return (
				<div className={classes.notesContainer} style={styles}>
					<div>
						{title && (
							<div className={classes.fieldTitle} style={titleStyles}>
								{title}
							</div>
						)}
					</div>
					<div style={{ fontWeight: 'normal' }}>{value}</div>
				</div>
			);
		} else {
			return (
				<div className={classes.notesContainer} style={styles}>
					<div>
						{title && (
							<span style={titleStyles} className={classes.fieldTitle}>
								{title}
							</span>
						)}
						{visibilitySelector && (
							<div
								style={{
									float: 'right',
									marginLeft: 10,
									borderRadius: 3,
									overflow: 'hidden'
								}}
							>
								{visibilitySelector}
							</div>
						)}
						{maxChars && isShowRemainingChars && (
							<span className={classes.charLengthInfo} style={charLengthStyle}>
								{remainingChars}
							</span>
						)}
					</div>
					<div style={{ minHeight: parentHeight }}>
						<textarea
							id={id}
							name={name}
							placeholder={placeholder}
							className={classes.textArea}
							maxLength={maxChars}
							ref={textAreaRef}
							rows={rows}
							value={value ? value : ''}
							style={textAreaStyling}
							onChange={onChangeHandler}
						/>
					</div>
				</div>
			);
		}
	};
	return renderField();
};

ResizingTextArea.propTypes = {
	id: PropTypes.string.isRequired
};

export default ResizingTextArea;

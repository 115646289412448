import React from 'react';
import useStyles from './styles';

const OTTInformationDatum = ({ label, value, showDatum }) => {
	const classes = useStyles();
	return showDatum ? (
		<div className={classes.ottInfoDatumContainer}>
			<div className={classes.datumLabel}>{label}</div>
			<div>{value}</div>
		</div>
	) : null;
};

export default OTTInformationDatum;

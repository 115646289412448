import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'block',
		position: 'relative',
		fontSize: 22,
		userSelect: 'none',
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		padding: 20
	},
	link: {
		color: '#006da6',
		cursor: 'pointer',
		textDecoration: 'underline'
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	saveAdminActionContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: 5
	}
});

export const findHorses = /* GraphQL */ `
	query findHorses($input: FindHorseInput) {
		findHorses(input: $input) {
			name
			horseCode
			microChipNumber
		}
		countMatchingHorses(input: $input)
	}
`;

export const findThoroughbreds = /* GraphQL */ `
	query findThoroughbreds($input: FindHorseInput) {
		findThoroughbreds(input: $input) {
			name
			horseCode
			microChipNumber
		}
		countMatchingHorses(input: $input)
	}
`;

export const getHorseProfile = /* GraphQL */ `
	query getHorseProfile($horseCode: Int!) {
		getHorseProfile(horseCode: $horseCode) {
			claims {
				id
			}
		}
	}
`;

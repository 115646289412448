import React, { useState } from 'react';
import useStyles from './styles';
import Sorting_Default_icon from '../../assets/icons/Sorting_Default_icon.svg';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import LoaderSpinner from '../LoaderSpinner';
import { navigate } from '@reach/router';
import { formatDateReadableVariant } from '../../utils/helpers';
import { Container, Row, Col } from 'react-grid-system';
import HorseAvatar from '../HorseAvatar';
import { Box, TablePagination } from '@material-ui/core';
import {
	DRAFT,
	HorseManagementKeys,
	REJECTED,
	REVIEW
} from './../../utils/constants/index';
import { CONCLUDED_REASONS_SHOW_LINK } from './constants';

const ConcludedRetrainingDataTable = ({
	concludedRetrainingList,
	isLoading,
	retrainerStatusList,
	sortUpFunction,
	sortDownFunction,
	isMobile,
	count,
	currentPage,
	onPageChange
}) => {
	const [orderBy, setOrderBy] = useState(0);
	const [sortIcon, setSortIcon] = useState(Sorting_Default_icon);

	const sort = () => {
		if (sortIcon === Sorting_Down_icon) {
			setSortIcon(Sorting_Up_icon);
			sortUpFunction();
		} else {
			setSortIcon(Sorting_Down_icon);
			sortDownFunction();
		}
	};

	const mobileRowRender = (horse) => {
		return (
			<Container className={classes.mobileContainer}>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<span
								className={classes.tbodyNameSpan}
								onClick={() => navigate(`horse-profile/${horse.horseCode}`)}
							>
								{horse?.paddockName ?? horse.horseName}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Retraining Status</div>
							{!horse.isSelected && (
								<td className={classes.td}>{horse.retrainStatus}</td>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>As Of Date</div>
							{formatDateReadableVariant(horse.asOfDate)}
						</div>
					</Col>
				</Row>
			</Container>
		);
	};

	const renderRetrainerList = () => {
		let statusList = [];
		let numRows = concludedRetrainingList.length;
		if (concludedRetrainingList.length > 0) {
			return (
				<>
					{concludedRetrainingList.map((d, index) => {
						if (retrainerStatusList.length > 0) {
							statusList = retrainerStatusList;
						}
						return (
							<React.Fragment key={d.id}>
								<tr
									id={d?.horseCode}
									style={d.isSaved ? { background: '#fcf7da' } : {}}
									className={
										numRows === index + 1 ? classes.trbodyLast : classes.trbody
									}
								>
									{isMobile && mobileRowRender(d, statusList)}
									{!isMobile && (
										<>
											<td className={classes.tbodyProfilePic}>
												<HorseAvatar
													avatarImageKey={d?.key}
													idPrefix={`my-stable-retrainer-${d?.horseCode}`}
													style={{ marginRight: 10, width: 45, height: 45 }}
													onClick={() =>
														navigate(`/horse-profile/${d?.horseCode}`)
													}
													className={classes.profilePic}
												/>
											</td>
											<td className={classes.tbodyName}>
												<span
													id={HorseManagementKeys.HORSE_NAME}
													className={classes.tbodyNameSpan}
													onClick={() =>
														navigate(`horse-profile/${d.horseCode}`)
													}
												>
													{d?.paddockName ?? d.horseName}
												</span>
											</td>
											<td
												id={HorseManagementKeys.CONCLUDED_REASON}
												className={classes.td}
												style={{ width: 240 }}
											>
												{d.conclusionReason}
											</td>
											{!d.isSelected && (
												<td
													id={HorseManagementKeys.ARRIVAL_DATE}
													className={classes.td}
												>
													{formatDateReadableVariant(d.stabledDate)}
												</td>
											)}
											<td
												id={HorseManagementKeys.CONCLUDED_DATE}
												className={classes.td}
											>
												{formatDateReadableVariant(d.asOfDate)}
											</td>
											<td
												id={HorseManagementKeys.FULL_CIRCLE}
												className={classes.td}
											>
												{d.fullcircle ? 'Yes' : 'No'}
											</td>
											{!d.currentClaimStatus &&
											CONCLUDED_REASONS_SHOW_LINK.includes(
												d.conclusionReasonId
											) ? (
												<td className={classes.td}>
													<span
														id={HorseManagementKeys.PREVIOUS_CLAIM_FORM}
														className={classes.tbodyNameSpan}
														onClick={() =>
															navigate(
																`claim-thoroughbred-form/${d.horseCode}?isPreviousClaim=true`
															)
														}
													>
														{formatDateReadableVariant(d.previousClaimDate)}
													</span>
												</td>
											) : (d.currentClaimStatus == DRAFT ||
													d.currentClaimStatus == REJECTED ||
													d.currentClaimStatus == REVIEW) &&
											  CONCLUDED_REASONS_SHOW_LINK.includes(
													d.conclusionReasonId
											  ) ? (
												<td className={classes.td}>
													<span
														id={HorseManagementKeys.PREVIOUS_CLAIM_FORM}
														className={classes.tbodyNameSpan}
														onClick={() =>
															navigate(`claim-thoroughbred-form/${d.horseCode}`)
														}
													>
														{formatDateReadableVariant(d.previousClaimDate)}
													</span>
												</td>
											) : (
												<td
													id={HorseManagementKeys.PREVIOUS_CLAIM_FORM}
													className={classes.td}
												>
													{formatDateReadableVariant(d.previousClaimDate)}
												</td>
											)}
										</>
									)}
								</tr>
							</React.Fragment>
						);
					})}
					{count > 10 && (
						<tr>
							<td colSpan={'100%'}>
								<div style={{ width: '100%', display: 'flex' }}>
									<Box m="auto">
										<TablePagination
											rowsPerPageOptions={[10]}
											rowsPerPage={[10]}
											count={count}
											page={currentPage}
											onPageChange={onPageChange}
										/>
									</Box>
								</div>
							</td>
						</tr>
					)}
				</>
			);
		} else {
			return (
				<tr>
					<td colSpan={6} className={classes.tdEmptyList}>
						No thoroughbreds have concluded Retraining
					</td>
				</tr>
			);
		}
	};

	const classes = useStyles();
	return (
		<>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			{!isLoading && (
				<table className={classes.table}>
					{!isMobile && (
						<thead className={classes.thead}>
							<tr className={classes.tr}>
								<th className={classes.th}>{''}</th>
								<th
									className={classes.thname}
									onClick={() =>
										orderBy === 0 || orderBy === 2
											? setOrderBy(1)
											: setOrderBy(2)
									}
								>
									Name
									<img src={sortIcon} onClick={() => sort()} />
								</th>
								<th className={classes.th}>Concluded Reason</th>
								<th className={classes.th}>Arrival Date</th>
								<th className={classes.th}>Concluded Date</th>
								<th className={classes.th}>Full Circle</th>
								<th className={classes.th}>Previous Claim Form</th>
							</tr>
						</thead>
					)}
					<tbody className={classes.tbody}>{renderRetrainerList()}</tbody>
				</table>
			)}
		</>
	);
};

export default ConcludedRetrainingDataTable;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	horseDetailsHeaderButtonBase: {
		border: 'none',
		borderRadius: 4,
		textAlign: 'center',
		textDecoration: 'none',
		fontWeight: 600,
		height: 38,
		width: 85,
		fontSize: 16,
		'-webkitAppearance': 'none',
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	horseDetailsHeaderButtonPrimary: {
		backgroundColor: '#006da6',
		color: 'white',
		extend: 'horseDetailsHeaderButtonBase'
	},
	horseDetailsHeaderButtonCancel: {
		backgroundColor: 'white',
		color: '#006da6',
		marginRight: 15,
		extend: 'horseDetailsHeaderButtonBase'
	},
	formHeaderContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	horseNameContainer: {
		minWidth: 100,
		marginRight: 5,
		display: 'flex'
	},
	longTextWrapper: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	headerButtonsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end'
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	postUpdatesContainer: {
		width: '100%',
		boxSizing: 'border-box',
		padding: 15,
		backgroundColor: '#f5f5f5',
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3
	},
	footerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	footerActionsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 5,
		alignItems: 'center'
	},
	label: {
		fontSize: 15,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center'
	},
	baseButtonStyles: {
		cursor: 'pointer',
		textAlign: 'center',
		fontWeight: 800,
		border: 'none',
		borderRadius: 3,
		minWidth: 130,
		height: 38,
		fontFamily: 'Open-sans !important',
		fontSize: 15
	},
	addPhotoButton: {
		color: '#026ea7',
		background: '#dcebf3',
		extend: 'baseButtonStyles'
	},
	postUpdateButton: {
		background: '#026ea7',
		color: '#fff',
		extend: 'baseButtonStyles'
	},
	addPhotoButtonContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	addPhotoButtonIcon: {
		marginRight: 5
	},
	disabledAddPhotoButton: {
		background: '#d8d8d8',
		color: '#b2b2b2'
	},
	uploadedFileLinksContainer: {
		width: '100%',
		marginTop: 5
	}
});

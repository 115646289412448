import React from 'react';
import ContentTypeDisplay from '../ContentTypeDisplay';
import useStyles from './styles';

const PictureArticle = ({
	title,
	text,
	mobile,
	imageSrc,
	ottContentType,
	small,
	dashboard
}) => {
	const renderArticle = () => {
		if (mobile) {
			return (
				<div
					className={classes.containerMobile}
					style={dashboard ? { marginRight: 0, marginLeft: 0 } : {}}
				>
					<ContentTypeDisplay
						contentType={ottContentType}
						contentSrc={imageSrc}
						mobile
					/>
					<div className={classes.contentContainerMobile}>
						<div>{title}</div>
						{text != null && <div className={classes.text}>{text}</div>}
					</div>
				</div>
			);
		} else if (small) {
			return (
				<div className={classes.containerSmall}>
					<ContentTypeDisplay
						contentType={ottContentType}
						contentSrc={imageSrc}
						small
					/>
					<div className={classes.contentContainerSmall}>
						<div>{title}</div>
						{text != null && <div className={classes.textSmall}>{text}</div>}
					</div>
				</div>
			);
		} else {
			return (
				<div className={classes.container}>
					<ContentTypeDisplay
						contentType={ottContentType}
						contentSrc={imageSrc}
					/>
					<div className={classes.contentContainer}>
						<div>{title}</div>
						{text != null && <div className={classes.text}>{text}</div>}
					</div>
				</div>
			);
		}
	};

	const classes = useStyles();
	return renderArticle();
};

export default PictureArticle;

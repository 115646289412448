import React from 'react';
import useStyles from './styles';
import toast from 'react-hot-toast';
import { CommonKeys } from '../../utils/constants';

const Toast = ({ type, message, toastId }) => {
	const classes = useStyles();
	return (
		<div className={classes.toast}>
			<div className={classes.toastMessageContainer}>
				<span id={CommonKeys.TOAST_TYPE} className={classes.toastType}>
					{type}
				</span>
				<span id={CommonKeys.TOAST_MESSAGE} className={classes.toastMessage}>
					{message}
				</span>
			</div>
			<div className={classes.closeButtonContainer}>
				<button
					id={CommonKeys.CLOSE_TOAST}
					onClick={() => toast.dismiss(toastId)}
					className={classes.closeButton}
				>
					&times;
				</button>
			</div>
		</div>
	);
};

export default Toast;

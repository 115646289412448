import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useAuth } from '../../hooks/useAuth';
import MessageBar from '../MessageBar';
import { Button } from '../Mobile';
import FormButton from '../FormButton';
import FormDropDown from '../FormDropDown';
import FormTextArea from '../FormTextArea';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import {
	listHorsePlacementRemoveReasonOptions,
	getAllUserVerifiedByRole,
	updateHorsePlacementMemberId
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import FormTextField from '../FormTextField';
import { Row, Col } from 'react-grid-system';

import { Typography } from '@material-ui/core';
import DynamicAutoSuggestion from '../DynamicAutoSuggestion';

import {
	RESET_ELIGIBLE_LISTINGS,
	ROLE_RV_EQUINE_WELFARE,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_RESET_RETRAINER,
	HORSE_REMOVED_LISTING,
	RESET_HORSE_EXPRESS_ONGOING_STATUS
} from '../../utils/constants';
import PrimaryButton from '../PrimaryButton';
import classNames from 'classnames';

const MAXREASONLENGTH = 100;

const HorseListingEditForm = ({
	placementData,
	placementRemovalConfirmed,
	confirmPlacementRemovalAction,
	mobile,
	horseOTTStatusID,
	isResetExpress
}) => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const isAdmin = currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
	const isRetrainer = currentUser?.Roles.includes(
		ROLE_RV_ACKNOWLEDGED_RETRAINER
	);
	const isResetRetrainer = currentUser?.Roles.includes(
		ROLE_ACKNOWLEDGED_RESET_RETRAINER
	);
	const isRetrainerOnly = isRetrainer && !isAdmin;
	const { addToast } = useToast();
	const [removeReasonId, setRemoveReasonId] = useState(0);
	const [
		horsePlacementRemoveReasonList,
		setHorsePlacementRemoveReasonList
	] = useState([]);
	const [removeDetails, setRemoveDetails] = useState('');
	const [removeReasonOther, setRemoveReasonOther] = useState('');
	const [removeListingPressed, setRemoveListingPressed] = useState(false);
	const [characters, setCharacters] = useState(MAXREASONLENGTH);
	const [acknowledgedRetrainerList, setAcknowledgedRetrainerList] = useState(
		[]
	);
	const [selectedAckRetrainer, setSelectedAckRetrainer] = useState(null);
	const [selectedAckRetrainerInput, setSelectedAckRetrainerInput] = useState(
		''
	);

	const [originalAssignedRetrainer, setOriginalAssignedRetrainer] = useState(
		''
	);

	const [showCancelAction, setShowCancelAction] = useState(false);

	useEffect(() => {
		AppSyncService.execute(listHorsePlacementRemoveReasonOptions).then(
			(data) => {
				try {
					let list = data.data.listHorsePlacementRemoveReason;
					list.unshift({ id: 0, value: '' });
					setHorsePlacementRemoveReasonList(list);
				} catch {
					addToast({ Message: 'Unable to fetch list', IsSuccess: false });
				}
			}
		);
	}, []);

	useEffect(() => {
		if (isAdmin) {
			AppSyncService.execute(getAllUserVerifiedByRole, {
				roleId: isResetExpress ? 16 : 5
			}).then((data) => {
				try {
					const {
						data: { getAllUserVerifiedByRole }
					} = data;
					if (getAllUserVerifiedByRole.length !== 0) {
						const finalRetrainerList = [
							{ id: null, firstName: '', lastName: '' },
							...getAllUserVerifiedByRole
						];
						setAcknowledgedRetrainerList(finalRetrainerList);
					}
					const {
						horsePlacementForm: { assignedRetrainer, assignedRetrainerDetails }
					} = placementData;
					if (assignedRetrainer) {
						const fullName = `${assignedRetrainerDetails.firstName} ${assignedRetrainerDetails.lastName}`;
						setOriginalAssignedRetrainer(assignedRetrainerDetails);
						setSelectedAckRetrainerInput(fullName);
					}
				} catch {
					addToast({ Message: 'Unable to fetch list', IsSuccess: false });
				}
			});
		}
	}, []);

	const removalReason = (value) => {
		setRemoveReasonId(value);
		if (value != 4) {
			setRemoveReasonOther('');
		}
	};

	const updateRemoveDetails = (val) => {
		let valueLength = val.length;
		setCharacters(MAXREASONLENGTH - valueLength);
		setRemoveDetails(val);
	};

	const confirmPlacementRemoval = () => {
		confirmPlacementRemovalAction(
			placementData.horsePlacementForm.id,
			removeDetails,
			removeReasonId,
			removeReasonOther
		);
	};

	const evaluateDisableRemoveListing = () => {
		return (
			(removeDetails === '' && removeReasonId != 5) ||
			removeReasonId == 0 ||
			(removeReasonId == 4 && removeReasonOther?.trim() == '')
		);
	};

	const onChangeAckRetrainer = (e, newVal) => {
		setSelectedAckRetrainer(newVal);
		setShowCancelAction(newVal?.id !== originalAssignedRetrainer?.id);
	};

	const onChangeInputAckRetrainer = (e, newInputVal) => {
		setSelectedAckRetrainerInput(newInputVal);
	};

	const onAssignAckRetrainer = async () => {
		if (selectedAckRetrainer) {
			const result = await AppSyncService.execute(
				updateHorsePlacementMemberId,
				{
					memberId: selectedAckRetrainer.id,
					horsePlacementId: placementData?.horsePlacementForm?.id
				}
			);
			if (result) {
				addToast({
					Message: `${isResetExpress ? 'RESET' : ''} Retrainer assigned`,
					IsSuccess: true
				});
			}
		}
		setOriginalAssignedRetrainer(selectedAckRetrainer);
		setShowCancelAction(false);
	};

	const onRenderListingAction = () => {
		if (
			placementData?.horsePlacementForm?.removeReason?.id &&
			!RESET_ELIGIBLE_LISTINGS.includes(horseOTTStatusID) &&
			!isResetExpress
		) {
			return onRenderRemovedListing();
		} else if (
			horseOTTStatusID === HORSE_REMOVED_LISTING ||
			RESET_ELIGIBLE_LISTINGS.includes(horseOTTStatusID) ||
			RESET_HORSE_EXPRESS_ONGOING_STATUS.includes(horseOTTStatusID)
		) {
			return onRenderRESETAction();
		} else {
			return onRenderDefaultAction();
		}
	};

	const onCancel = () => {
		setSelectedAckRetrainer(originalAssignedRetrainer);
		let fullName = '';
		if (typeof originalAssignedRetrainer !== 'string') {
			fullName = `${originalAssignedRetrainer?.firstName} ${originalAssignedRetrainer?.lastName}`;
		}

		setSelectedAckRetrainerInput(fullName);
		setShowCancelAction(false);
	};

	const onRenderRESETAction = () => {
		return (isResetRetrainer || isRetrainer) && !isAdmin ? null : (
			<MessageBar
				type="message"
				style={
					mobile
						? { padding: '15px 12px', fontSize: 13 }
						: { padding: '15px 12px' }
				}
			>
				<Typography paragraph>
					This listing has been removed for all RV Acknowledged Retrainers
					{isResetExpress ? ' with RESET express access' : ''}.
				</Typography>

				{isResetExpress ? (
					<>
						<Typography paragraph>
							<b>{placementData?.horse?.name}</b> has entered RESET Express.
							Once the horse is accepted by the Retrainer Admin users can
							manually select this RESET Express Retrainer from the dropdown
							below to whom this listing would be made visible, please inform
							the Retrainer they can now claim the horse and view this listing.
						</Typography>
					</>
				) : (
					<Typography paragraph>
						<b>{placementData?.horse?.name}</b> is now eligible for RESET.
						Select a RV Acknowledged Retrainers from the dropdown below to whom
						this listing would be made visible.
					</Typography>
				)}
				{!removeListingPressed && (
					<>
						<Row className={classes.cancelListingContainer}>
							<Col>
								<DynamicAutoSuggestion
									options={acknowledgedRetrainerList}
									label={`Acknowledged Retrainer${
										isResetExpress ? ' - RESET Express' : ''
									}`}
									onChangeFunc={onChangeAckRetrainer}
									onInputChangeFunc={onChangeInputAckRetrainer}
									inputValue={selectedAckRetrainerInput}
								/>
							</Col>
						</Row>

						<div
							style={{
								display: 'flex',
								justifyContent: `${
									showCancelAction ? 'space-between' : 'flex-end'
								}`,
								alignItems: 'center',
								marginTop: '40px'
							}}
						>
							{showCancelAction ? (
								<div
									id="cancel-horseListingEditForm-removeListing"
									className={classNames({
										[classes.cancelRequest]: true
									})}
									onClick={() => onCancel()}
								>
									Cancel
								</div>
							) : null}

							<div>
								<PrimaryButton
									id="availableForRetraining-horseListingEditForm-removeListing"
									disabled={!selectedAckRetrainer || !showCancelAction}
									isNonSubmit={false}
									style={{
										minWidth: '170px',
										width: '100px'
									}}
									onClick={() => onAssignAckRetrainer()}
								>
									Assign Retrainer{' '}
								</PrimaryButton>
							</div>
						</div>
						{/* <Row
							className={classes.cancelListingContainer}
							style={{
								display: 'flex',
								justifyContent: 'space-between'
								// margin: '10px 0px'
							}}
						>
							<Col>
								<div
									id="cancel-horseListingEditForm-removeListing"
									className={classNames({
										[classes.cancelRequest]: true
										// invisible: formStatus === APPROVED
									})}
									onClick={() => {}}
								>
									Cancel Request
								</div>
							</Col>

							<Col>
								<PrimaryButton
									id="availableForRetraining-horseListingEditForm-removeListing"
									disabled={!selectedAckRetrainer || disableAssignRetrainer}
									isNonSubmit={false}
									style={{
										minWidth: '170px',
										width: '100px'
									}}
									onClick={() => onAssignAckRetrainer()}
								>
									Assign Retrainer{' '}
								</PrimaryButton>
							</Col>
						</Row> */}
					</>
				)}
			</MessageBar>
		);
	};

	const onRenderRemovedListing = () => {
		return isResetExpress ? null : (
			<MessageBar
				type="message"
				style={
					mobile
						? { padding: '15px 12px', fontSize: 13 }
						: { padding: '15px 12px' }
				}
			>
				<Typography paragraph>
					This listing has been removed for all RV Acknowledged Retrainers.
				</Typography>

				<Typography style={{ fontWeight: 'bold' }} paragraph>
					Reason this listing was removed:
				</Typography>

				<Typography paragraph>
					{placementData?.horsePlacementForm?.removeReason?.value}
				</Typography>

				{placementData?.horsePlacementForm?.removeReason?.id == 4 && (
					<Typography paragraph>
						{placementData?.horsePlacementForm?.removeReasonOther}
					</Typography>
				)}
			</MessageBar>
		);
	};

	const onRenderDefaultAction = () => {
		return isResetExpress ? null : (
			<MessageBar
				type="message"
				style={
					mobile
						? { padding: '15px 12px', fontSize: 13 }
						: { padding: '15px 12px' }
				}
			>
				Thank you for listing your horse as available for Retraining. All RV
				Acknowledged Retrainers can now see this listing.
				{!removeListingPressed && (
					<Row className={classes.cancelListingContainer}>
						<Col>
							<Button
								id="availableForRetraining-horseListingEditForm-editListing"
								text="Edit this listing"
								styles={
									mobile
										? {
												background: '#006da6',
												color: 'white',
												padding: '15px 5px',
												fontSize: 13
										  }
										: {
												background: '#006da6',
												color: 'white',
												padding: '15px 5px',
												fontSize: 15
										  }
								}
								onClick={() =>
									navigate(
										`/horse-placement-form/${placementData.horse.horseCode}`
									)
								}
							/>
						</Col>
						<Col>
							<Button
								id="availableForRetraining-horseListingEditForm-removeListing"
								text="Remove this listing"
								styles={
									mobile
										? { padding: '15px 5px', fontSize: 13 }
										: { padding: '15px 5px', fontSize: 15 }
								}
								onClick={() => setRemoveListingPressed(true)}
							/>
						</Col>
					</Row>
				)}
			</MessageBar>
		);
	};

	return (
		<>
			{!placementRemovalConfirmed && (
				<>
					<div className={mobile ? {} : classes.formContainer}>
						{onRenderListingAction()}
						{removeListingPressed && (
							<MessageBar
								type="message"
								style={{ padding: '5px 12px 20px 12px' }}
							>
								<div>
									<div
										className={classes.cancelListingField}
										style={mobile ? { fontSize: 13 } : {}}
									>
										Reason for removing this listing?*
									</div>
									<FormDropDown
										showBlank
										id="availableForRetraining-horseListingEditForm-removalReason"
										items={horsePlacementRemoveReasonList}
										selectedId={removeReasonId}
										onChange={(e) => {
											removalReason(e.target.value);
										}}
										styles={{ width: '100%' }}
									/>
									{removeReasonId == 4 && (
										<div>
											<div
												className={classes.cancelListingField}
												style={mobile ? { fontSize: 13 } : {}}
											>
												Other Reason*
											</div>
											<FormTextField
												id="availableForRetraining-horseListingEditForm-removeReasonOther"
												value={removeReasonOther}
												onChange={(e) => setRemoveReasonOther(e.target.value)}
											/>
										</div>
									)}
									{removeReasonId == 5 ? null : (
										<>
											<div
												className={classes.cancelListingFieldMoreInfo}
												style={mobile ? { fontSize: 13 } : {}}
											>
												Please provide more information*{' '}
												<span
													className={classes.remainingCharacters}
													style={mobile ? { fontSize: 13 } : {}}
												>
													{characters} characters {mobile ? '' : 'remaining'}
												</span>
											</div>
											<FormTextArea
												maxLength={100}
												styles={{ height: 70, float: 'initial' }}
												value={removeDetails}
												id="availableForRetraining-horseListingEditForm-removeDetails"
												onChange={(e) => {
													updateRemoveDetails(e.target.value);
												}}
											/>
										</>
									)}

									<hr className={classes.divider} />
									<div className={classes.cancelListingContainer}>
										<div
											className={classes.cancelListingCancelButton}
											onClick={() => setRemoveListingPressed(false)}
										>
											Cancel
										</div>
										<div className={classes.cancelListingSubmit}>
											<FormButton
												id="availableForRetraining-horselListingEditForm-submitRemoveListing"
												title="Remove Listing"
												styles={{ fontSize: 15 }}
												isDisabled={evaluateDisableRemoveListing()}
												onClick={() => confirmPlacementRemoval()}
											/>
										</div>
									</div>
									<div className={classes.clear}></div>
								</div>
							</MessageBar>
						)}
					</div>
					<div className={mobile ? {} : classes.formContainer}>
						{isResetExpress || isRetrainerOnly ? null : (
							<MessageBar
								type="info"
								style={
									mobile
										? { padding: '15px 12px', fontSize: 13 }
										: { padding: '15px 12px' }
								}
							>
								Please note: Only remove this listing if you are not rehoming to
								an RV Retrainer. If an RV Retrainer is taking this horse from
								you, there is no need to remove the listing as it will be
								removed once they complete this form.
							</MessageBar>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default HorseListingEditForm;

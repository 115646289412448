import { createUseStyles } from 'react-jss';
import checked from '../../assets/images/checkboxes/checkbox-selected.svg';
import unchecked from '../../assets/images/checkboxes/checkbox-unselected.svg';
export default createUseStyles({
	checkBox: {
		height: 20, //28,
		width: 20, //28,
		backgroundImage: `url(${unchecked})`,
		backgroundRepeat: 'no-repeat',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:focus': {
			outline: 'none'
		}
	},
	selected: {
		backgroundImage: `url(${checked})`
	},
	checkBoxLabel: {
		color: '#0085ca'
	},
	checkBoxContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 15
	},
	container: {
		display: 'block',
		position: 'relative',
		paddingLeft: 20, //35,
		marginBottom: 0, //12,
		cursor: 'pointer',
		fontSize: 22,
		userSelect: 'none',
		'&:hover $inputClass ~ $checkmark': {},
		'& $checkmark:after': {
			top: 9,
			left: 9,
			width: 8,
			height: 8,
			borderRadius: '50%'
		}
	},
	inputClass: {
		position: 'absolute',
		opacity: 0,
		cursor: 'pointer',
		'&:checked ~ $checkmark': {
			backgroundColor: '#2196F3',
			border: '4px solid #fff',
			boxShadow: '0px 1px 7px 1px #2196f3',
			width: 17,
			height: 17
		},
		'&:checked ~ $checkmark:after': {
			display: 'block'
		}
	},
	checkmark: {
		position: 'absolute',
		top: 7,
		left: 0,
		right: 0,
		marginRight: 'auto',
		marginLeft: 'auto',
		height: 25,
		width: 25,
		border: '1px solid #2196f3',
		backgroundColor: '#fff',
		borderRadius: '50%',
		'&:after': {
			content: '""',
			position: 'absolute',
			display: 'none'
		}
	},
	headingtitle: {
		display: 'inline-block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		textTransform: 'capitalize',
		padding: '30px 0 13px 10px',
		fontSize: 13,
		wordBreak: 'break-all',
		color: '#464749'
	},
	noCapitalizeTitle: {
		display: 'inline-block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '30px 0 13px 10px',
		fontSize: 13,
		wordBreak: 'break-word',
		color: '#464749'
	},
	icon: {
		float: 'left',
		height: 16,
		marginTop: 10,
		marginRight: 10
	},
	labelContainer: {},
	additionalInfo: {
		display: 'inline-block',
		position: 'absolute',
		paddingLeft: '19px',
		fontSize: '12px',
		paddingTop: '12px'
	},
	helper: {
		position: 'absolute',
		top: '50%',
		display: 'table-cell',
		verticalAlign: 'middle'
	},

	flexContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline'
	},

	verticalLabel: {
		display: 'inline-block'
	},
	custom: {}
});

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	sectionContainer: {
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		borderBottom: 'none'
	},
	sectionContainerMobile: {
		border: 'none'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	warningInfo: {
		padding: '10px 18px 5px 18px',
		fontSize: 13,
		marginBottom: 20,
		borderRadius: 5
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	errorText: {
		color: 'red'
	},
	fieldsContainer: {
		padding: '10px 18px 5px 18px'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0px 18px 17px 18px'
	},
	updateButtonContainer: {
		padding: '10px 18px 15px 18px'
	},
	rightContainer: {
		fontSize: 14,
		marginTop: 20
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		marginRight: 20,
		cursor: 'pointer',
		fontWeight: 500,
		fontSize: 17
	},
	fieldsContainerNoTopMargin: {
		padding: '0px 20px'
	}
}));

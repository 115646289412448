import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		borderRadius: 7
	},
	newsImage: {
		borderRadius: 4,
		objectFit: 'cover',
		width: '100%',
		height: '300px'
	},
	tackRoomImage: {
		width: '100%',
		borderRadius: 4,
		height: 225,
		objectFit: 'cover'
	}
}));

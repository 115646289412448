import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper';
import FormTextField from '../../FormTextField';
import useStyles from './styles';

const TextInputField = ({
	id,
	label,
	isRequired,
	value,
	onChange,
	fullWidth = false,
	validationError,
	isDisabled = false,
	isEditable = true,
	visibilitySelector,
	...rest
}) => {
	const classes = useStyles();
	return (
		<FormFieldWrapper
			fieldLabel={label}
			isRequired={isRequired}
			fullWidth={fullWidth}
			errorMessage={validationError}
			visibilitySelector={visibilitySelector}
		>
			{isEditable ? (
				<FormTextField
					id={id}
					value={value}
					onChange={onChange}
					canEdit
					isDisabled={isDisabled}
					{...rest}
				/>
			) : (
				<p className={classes.nonEditableValue}>{value}</p>
			)}
		</FormFieldWrapper>
	);
};

export default TextInputField;

import React from 'react';
import { StyledTag, StyledTagsContainer } from './styles';

const TagsList = ({ tagsList, children }) => {
	return (
		<StyledTagsContainer>
			{children
				? React.Children.map(children, (child, index) => (
						<StyledTag key={`ott-info-tab-${index}`}>
							{React.cloneElement(child)}
						</StyledTag>
				  ))
				: tagsList.map((value) => (
						<StyledTag key={`ott-info-tab-${value}`}>{value}</StyledTag>
				  ))}
		</StyledTagsContainer>
	);
};

export default TagsList;

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseOTTStatus,
	reviewRetirementInitialAssessmentForm,
	setOttProgramAndStatus
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { formatDate, formatDateReadableVariant } from '../../utils/helpers';
import { newStatusList, physicalHealthItems } from './constants';

const RetirementInitialFormReviewInfo = ({ data, mobile, getImage }) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const { addToast } = useToast();
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [horseCurrentStatus, setHorseCurrentStatus] = useState(null);

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
		AppSyncService.execute(getHorseOTTStatus, {
			horseCode: data.horse.horseCode
		}).then((response) => {
			if (response.data?.getHorseOTTStatus?.horseOTTStatusId)
				setHorseCurrentStatus(response.data.getHorseOTTStatus.horseOTTStatusId);
		});
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewRetirementInitialAssessmentForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveRetirementInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveRetirementInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const generatePayloadOttStatus = ({
		data,
		horseCode,
		memberExternalId,
		horseCurrentStatus
	}) => {
		let ottStatusPayload = {
			programId: 7,
			horseCode: horseCode,
			memberExternalId: memberExternalId,
			asOfDate: formatDate(data.dateOfAssessment)
		};
		if (!data.horseStatusChanged) {
			ottStatusPayload = {
				...ottStatusPayload,
				statusId: horseCurrentStatus
			};
		} else {
			if (data.newStatus === 1) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 28
				};
			} else if (data.newStatus === 2) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 29
				};
			} else return null;
		}
		return ottStatusPayload;
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewRetirementInitialAssessmentForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveRetirementInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				const ottStatus = generatePayloadOttStatus({
					data,
					horseCode: data.horse?.horseCode,
					memberExternalId: data.member?.externalId,
					horseCurrentStatus
				});
				if (ottStatus) {
					AppSyncService.execute(setOttProgramAndStatus, ottStatus).then(() => {
						navigate(`/review-list`);
					});
				}
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveRetirementInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	return (
		<>
			<div
				style={{
					borderTop: '1px solid #b3bcc1',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: 'none'
				}}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="Retirement Initial Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date of assessment"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.dateOfAssessment)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastSubmitted
									? formatDateReadableVariant(data.lastSubmitted)
									: ''
							}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Left side photo/s</div>
							<UploadPhoto
								data={data.leftSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Right side photo/s</div>
							<UploadPhoto
								data={data.rightSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div
								className={classes.labelField}
							>{`Has the horse's status changed?`}</div>
							{data.horseStatusChanged ? 'Yes' : 'No'}
						</div>
					</Col>

					{/* <Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Current Status</div>
							{data.horse?.status ? data.horse?.status : ''}
						</div>
					</Col> */}

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>New Status</div>
							{data.newStatus
								? newStatusList.find(
										(selectedStatus) => selectedStatus.id === data.newStatus
								  )?.value
								: ''}
						</div>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Describe overall condition (body condition, feet condition, note any superficial wounds/abrasions)"
							value={data.overallCondition}
						/>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Worming</div>
							{data.worming ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Date of Completion</div>
							{data.worming ? formatDateReadableVariant(data.worming) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Farrier</div>
							{data.farrier ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Date of Completion</div>
							{data.farrier ? formatDateReadableVariant(data.farrier) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Dentist</div>
							{data.dentist ? 'Yes' : 'No'}
						</div>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Date of Completion</div>
							{data.dentist ? formatDateReadableVariant(data.dentist) : ''}
						</div>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Other Husbandry Information"
							value={data.otherHusbandryInfo}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Assessment of feeding requirements/plan (feeding to maintain or increase condition, feeding to let down etc)"
							value={data.feedingRequirementsPlan}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Assessment of temperament/behaviour (nervous/relaxed/aggressive/sensitive)"
							value={data.temperament}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Select vices or bad habits that ${data.horse?.name} has`}
							value={data.habits
								.map((selectedHabit) => selectedHabit.value)
								.join()}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Other" value={data.habitsOther} />
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Are there any concerns with the horse's physical health?`}
							value={data.horsePyhsicalHealth ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Please identify the concern/s"
							value={
								physicalHealthItems.find(
									(selectedPhysicalHealth) =>
										selectedPhysicalHealth.id === data.horsePyhsicalHealth
								)?.value
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Other concern"
							value={data.horsePyhsicalHealthOtherConcern}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Are there any concerns with the horse's`}
							value={data.horseBehaviour ? 'Yes' : 'No'}
						/>
					</Col>{' '}
					<Col xs={12}>
						<FormLabelField
							title="Please provided more information"
							value={data.horseBehaviour}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Final comments" value={data.finalComments} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default RetirementInitialFormReviewInfo;

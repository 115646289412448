import React, { useEffect, useState } from 'react';
import { MessageBarTypeEnum } from '../../utils/enums';
import InfoBar from '../InfoBar';
import useStyles from './styles';
import {
	formatDate,
	extractLookUpOptions,
	formatDateReadableVariant
} from '../../utils/helpers';
import { useToast } from '../../hooks/useToast';
import { Col, Container, Row } from 'react-grid-system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getUserImage,
	uploadImageToS3,
	saveResetReportingImage,
	getListOfHabitsAndVices,
	saveRetirementInitialAssessmentForm,
	getRetirementInitialAssessment,
	submitRetirementReportsForReview
} from '../../graphql/custom.queries';
import axios from 'axios';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import ResizingTextArea from '../ResizingTextArea';
import PrimaryButton from '../PrimaryButton';
import Toggle from '../Toggle';
import { useAuth } from '../../hooks/useAuth';
import FormDatePicker from '../FormDatePicker';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormDropDown from '../FormDropDown';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import { newStatus, physicalHealthItems } from './constants';
import { REVIEW, APPROVED, CommonKeys } from '../../utils/constants/index';
import LoaderSpinner from '../LoaderSpinner';
import Dialog from '../Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import FormLabelField from '../FormLabelField';
import { HorseManagementKeys } from './../../utils/constants/index';

const schema = yup.object().shape({});

const infoText =
	'As this is your first report, please complete the following Initial Assessment form within 14 days of the horse arriving.';

const AcknowledgedRetirementInitialAssessmentForm = ({
	horseCode,
	horseData,
	reload
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});
	const { currentUser } = useAuth();
	const [dateOfAssessment, setDateOfAssessment] = useState(null);
	const [leftSidePhotos, setLeftSidePhotos] = useState([]);
	const [rightSidePhotos, setRightSidePhotos] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [horseStatusChanged, setHorseStatusChanged] = useState(false);
	const [selectedNewStatus, setSelectedNewStatus] = useState(0);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [overallCondition, setOverallCondition] = useState('');
	const [worming, setWorming] = useState(false);
	const [farrier, setFarrier] = useState(false);
	const [dentist, setDentist] = useState(false);
	const [wormingDate, setWormingDate] = useState(null);
	const [farrierDate, setFarrierDate] = useState(null);
	const [dentistDate, setDentistDate] = useState(null);
	const [otherHusbandryInfo, setOtherHusbandryInfo] = useState('');
	const [feedingPlan, setFeedingPlan] = useState('');
	const [temperament, setTemperament] = useState('');
	const [viceOptions, setViceOptions] = useState([]);
	const [selectedVices, setSelectedVices] = useState([]);
	const [otherVices, setOtherVices] = useState('');
	const [physicalHealth, setPhysicalHealth] = useState(false);
	const [physicalHealthVal, setPhysicalHealthVal] = useState(0);
	const [physicalHealthConcern, setPhysicalHealthConcern] = useState('');
	const [behaviourConcern, setBehaviourConcern] = useState(false);
	const [behaviourConcernVal, setBehaviourConcernVal] = useState('');
	const [finalComments, setFinalComments] = useState('');
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingForm, setIsLoadingForm] = useState(false);
	const [openClearConfirmationDiag, setOpenClearConfirmationDiag] = useState(
		false
	);
	const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
	const [feedingError, setFeedingError] = useState('');
	const [overallConditionError, setOverallConditionError] = useState('');
	const [temperamentError, setTemperamentError] = useState('');
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	//const [formSubmitted, setformSubmitted] = useState(false);
	const [habitsOtherReadOnly, setHabitsOtherReadOnly] = useState('');
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isRetirementDetailsLoaded, setIsRetirementDetailsLoaded] = useState(
		false
	);
	const [shouldValidate, setShouldValidate] = useState(false);
	let isValidationErrors = false;
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const validateUncontrolledErrors = () => {
		validateLeftSidePhotos();
		validateRightSidePhotos();
		validateWormingDate();
		validateFarrierDate();
		validateDentistDate();
		validateAssessmentDate();
		validateHorseNewStatus();
		validatePhysicalHealth();
		validatePhysicalHealthOther();
		if (feedingPlan.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setFeedingError('You must enter the initial feeding plan assessment.');
		} else {
			setFeedingError('');
		}
		if (overallCondition.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setOverallConditionError(
				'You must enter the overall condition assessment.'
			);
		} else {
			setOverallConditionError('');
		}
		if (temperament.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setTemperamentError('You must enter the initial temperament assessment.');
		} else {
			setTemperamentError('');
		}
	};

	const validateLeftSidePhotos = () => {
		if (leftSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: {
					message: 'You must upload at least one left side photo.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: { message: null }
			}));
		}
	};

	const validateRightSidePhotos = () => {
		if (rightSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: {
					message: 'You must upload at least one right side photo.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.leftSidePhotos?.message) {
			validateLeftSidePhotos();
		}
	}, [leftSidePhotos]);

	useEffect(() => {
		if (uncontrolledErrors?.rightSidePhotos?.message) {
			validateRightSidePhotos();
		}
	}, [rightSidePhotos]);

	const validateWormingDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WormingDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (worming && !wormingDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: {
						message: 'You must enter a Date of Completion for Worming.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.WormingDate?.message) {
			validateWormingDate();
		}
	}, [worming, wormingDate]);

	const validateAssessmentDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				AssessmentDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!dateOfAssessment) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: {
						message: 'You must enter Date of Assessment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.AssessmentDate?.message) {
			validateAssessmentDate();
		}
	}, [dateOfAssessment]);

	const validateFarrierDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FarrierDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (farrier && !farrierDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: {
						message: 'You must enter a Date of Completion for the Farrier.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.FarrierDate?.message) {
			validateFarrierDate();
		}
	}, [farrier, farrierDate]);

	const validateDentistDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				DentistDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (dentist && !dentistDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: {
						message: 'You must enter a Date of Completion for Dentistry.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.DentistDate?.message) {
			validateDentistDate();
		}
	}, [dentist, dentistDate]);

	const validateHorseNewStatus = () => {
		if (!selectedNewStatus && horseStatusChanged) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorseNewStatus: {
					message: 'You must select new status.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorseNewStatus: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.HorseNewStatus?.message) {
			validateHorseNewStatus();
		}
	}, [horseStatusChanged, selectedNewStatus]);

	const validatePhysicalHealth = () => {
		if (!physicalHealthVal && physicalHealth) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealth: {
					message: 'You must select physical health concern.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealth: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.PhysicalHealth?.message) {
			validatePhysicalHealth();
		}
	}, [physicalHealth, physicalHealthVal]);

	const validatePhysicalHealthOther = () => {
		if (physicalHealthVal == 4 && physicalHealthConcern.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealthOther: {
					message: 'You must enter the other concern.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealthOther: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.PhysicalHealthOther?.message) {
			validatePhysicalHealthOther();
		}
	}, [physicalHealthConcern, physicalHealthVal]);

	useEffect(() => {
		AppSyncService.execute(getListOfHabitsAndVices).then((result) => {
			setViceOptions(extractLookUpOptions(result, 'listHabitsAndVices'));
		});
	}, []);

	useEffect(() => {
		const payload = {
			horseCode: horseCode,
			memberExternalId: currentUser?.UserId
		};
		setIsLoadingForm(true);
		AppSyncService.execute(getRetirementInitialAssessment, payload).then(
			(data) => {
				if (data.data != null) {
					let initialData = data.data.getRetirementInitialAssessmentForm;
					if (initialData == null) setIsShowErrorMessages(false);
					else setIsShowErrorMessages(true);

					updateDate(
						initialData.dateOfAssessment,
						setDateOfAssessment,
						validateAssessmentDate
					);
					setLeftSidePhotos(initialData.leftSidePhotos);
					setRightSidePhotos(initialData.rightSidePhotos);
					setHorseStatusChanged(initialData.horseStatusChanged);
					setSelectedNewStatus(initialData.newStatus ?? 0);
					setOverallCondition(initialData.overallCondition ?? '');
					setOverallConditionError(
						initialData.overallCondition ||
							initialData.overallCondition.length !== 0
							? ''
							: 'You must enter the overall condition assessment.'
					);
					setWorming(!!initialData.worming);
					setFarrier(!!initialData.farrier);
					setDentist(!!initialData.dentist);
					updateDate(
						initialData.worming ? initialData.worming : null,
						setWormingDate,
						validateWormingDate
					);
					updateDate(
						initialData.farrier ? initialData.farrier : null,
						setFarrierDate,
						validateFarrierDate
					);
					updateDate(
						initialData.dentist ? initialData.dentist : null,
						setDentistDate,
						validateDentistDate
					);
					setOtherHusbandryInfo(initialData.otherHusbandryInfo ?? '');
					setFeedingPlan(initialData.feedingRequirementsPlan ?? '');
					setFeedingError(
						initialData.feedingRequirementsPlan ||
							initialData.feedingRequirementsPlan.length !== 0
							? ''
							: 'You must enter the initial feeding plan assessment.'
					);
					setTemperament(initialData.temperament ?? '');
					setTemperamentError(
						initialData.temperament || initialData.temperament.length !== 0
							? ''
							: 'You must enter the initial temperament assessment.'
					);
					if (initialData.habits.length > 0) {
						const mapHabits = initialData.habits.map((habit) => ({
							value: habit.id,
							label: habit.value
						}));
						setSelectedVices(mapHabits);
					}
					if (initialData.habitsOther.length > 0) {
						setHabitsOtherReadOnly(initialData.habitsOther);
						let splitOtherHabits = initialData.habitsOther.split(',');
						const mapHabitsOther = splitOtherHabits.map((otherHabit) => ({
							label: otherHabit,
							value: otherHabit
						}));
						setOtherVices(mapHabitsOther);
					}
					setPhysicalHealth(!!initialData.horsePyhsicalHealth);
					setPhysicalHealthVal(initialData.horsePyhsicalHealth ?? 0);
					setPhysicalHealthConcern(initialData.horsePyhsicalHealthOtherConcern);
					setBehaviourConcern(initialData.horseBehaviour.length !== 0);
					setBehaviourConcernVal(initialData.horseBehaviour);
					setFinalComments(initialData.finalComments);
					setIsReadOnly(
						initialData.status === REVIEW || initialData.status === APPROVED
					);
				}
				setIsRetirementDetailsLoaded(true);
				setIsLoadingForm(false);
			}
		);
	}, []);

	useEffect(() => {
		if (isRetirementDetailsLoaded) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
	}, [isRetirementDetailsLoaded]);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const updateDate = (value, stateAssignmentFunction, validationFunction) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			validationFunction(false);
		} else {
			validationFunction(true);
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const onSubmit = (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredSelectedVices = selectedVices.map(
			(selectedVice) => selectedVice.value
		);
		const input = {
			horseCode: horseCode,
			dateOfAssessment: dateOfAssessment,
			horseStatusChanged: horseStatusChanged,
			newStatus: selectedNewStatus === 0 ? null : selectedNewStatus,
			overallCondition: overallCondition,
			worming: wormingDate,
			farrier: farrierDate,
			dentist: dentistDate,
			otherHusbandryInfo: otherHusbandryInfo,
			feedingRequirementsPlan: feedingPlan,
			temperament: temperament,
			habits: filteredSelectedVices,
			habitsOther:
				otherVices.length === 0
					? ''
					: otherVices.map((item) => item.value).toString(),
			horsePyhsicalHealthOtherConcern: physicalHealthConcern,
			horsePyhsicalHealth: physicalHealthVal === 0 ? null : physicalHealthVal,
			horseBehaviour: behaviourConcernVal,
			finalComments: finalComments
		};
		setIsLoading(true);
		AppSyncService.execute(saveRetirementInitialAssessmentForm, {
			input
		}).then((data) => {
			if (data.data !== null) {
				let photoArrays = [
					{ name: 'leftSide', arr: leftSidePhotos },
					{ name: 'rightSide', arr: rightSidePhotos }
				];
				photoArrays.forEach((photoArray, index) => {
					photoArray.arr.map((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'RetirementInitial',
							reportId: data.data.saveRetirementInitialAssessmentForm.id,
							type: photoArrays[index].name
						});
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					reload();
					addToast({ Message: 'Draft Saved', IsSuccess: true });
				} else {
					AppSyncService.execute(submitRetirementReportsForReview, {
						horseCode: horseCode
					}).then((data) => {
						if (data?.data?.submitRetirementReportsForReview) {
							addToast({
								Message: 'Initial Assessment Form Submitted',
								IsSuccess: true
							});
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
						}
					});
				}
			}
			if (!isSubmitReport) {
				if (!isValidationErrors) {
					setDisableSubmitbutton(false);
				}
			}
			setIsLoading(false);
		});
	};

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			onSubmit(true);
		}
	};

	const onClearConfirmationDialog = () => {
		setOpenClearConfirmationDiag(true);
	};

	const onCloseClearConfirmationDialog = (e, reason) => {
		e.preventDefault();
		if (reason && reason == 'backdropClick') return;
		setOpenClearConfirmationDiag(false);
	};

	const onRenderClearDialogContent = () => {
		setConfirmationDialogTitle('Clear Form');
		return (
			<>
				<>
					<DialogContentText className={classes.dialogTextColor}>
						Are you sure you want to clear the form?
					</DialogContentText>
				</>
			</>
		);
	};

	const onRenderActionButtons = () => {
		return (
			<>
				<PrimaryButton
					id={CommonKeys.YES}
					onClick={() => clearForm()}
					isNonSubmit
				>
					Yes
				</PrimaryButton>

				<PrimaryButton
					id={CommonKeys.CANCEL}
					style={{ backgroundColor: '#c62828' }}
					onClick={(e) => onCloseClearConfirmationDialog(e)}
					isNonSubmit
				>
					Cancel
				</PrimaryButton>
			</>
		);
	};

	const clearForm = () => {
		setDateOfAssessment(null);
		deleteAllPhotos();
		setHorseStatusChanged(false);
		setSelectedNewStatus(0);
		setOverallCondition('');
		setWorming(false);
		setFarrier(false);
		setDentist(false);
		setWormingDate(null);
		setFarrierDate(null);
		setDentistDate(null);
		setOtherHusbandryInfo('');
		setFeedingPlan('');
		setTemperament('');
		setSelectedVices([]);
		setOtherVices('');
		setPhysicalHealth(false);
		setPhysicalHealthVal(0);
		setPhysicalHealthConcern('');
		setBehaviourConcern(false);
		setBehaviourConcernVal('');
		setFinalComments('');
		setOpenClearConfirmationDiag(false);
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[leftSidePhotos, rightSidePhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setLeftSidePhotos([]);
					setRightSidePhotos([]);
				}
			});
		}
	};

	return (
		<div className={classes.container}>
			{isLoadingForm ? (
				<LoaderSpinner
					status={isLoadingForm}
					styles={{ display: 'flex', justifyContent: 'center', padding: 20 }}
				/>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit()}>
						<div className={classes.formHeading}>Initial Assessment Form</div>
						<InfoBar
							type={MessageBarTypeEnum.info}
							infoText={infoText}
							noButton
						/>
						<Container>
							<Row>
								{isReadOnly ? (
									<Col>
										<FormLabelField
											title="Date of Assessment*"
											value={formatDateReadableVariant(dateOfAssessment)}
										/>
									</Col>
								) : (
									<Col xs={12} lg={4}>
										<>
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date of Assessment*
												</div>
												<FormDatePicker
													DateValue={dateOfAssessment}
													id={HorseManagementKeys.DATE_OF_ASSESSMENT}
													updateSelection={(value) => {
														updateDate(
															value,
															setDateOfAssessment,
															validateAssessmentDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>

											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.AssessmentDate?.message}
												</span>
											)}
										</>
									</Col>
								)}
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Upload Left Side (jpg, png)*
										</div>
										<UploadPhoto
											id={HorseManagementKeys.INITIAL_LEFT_SIDE_PHOTO}
											formats={['png', 'jpeg']}
											labelName="Upload Left Side"
											error={uploadError}
											data={leftSidePhotos}
											sizeLimitMB={5}
											numberOfImages={10}
											success={(e) => photosUpload(e, setLeftSidePhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, leftSidePhotos, setLeftSidePhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.leftSidePhotos?.message}
										</span>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Upload Right Side (jpg, png)*
										</div>
										<UploadPhoto
											id={HorseManagementKeys.INITIAL_RIGHT_SIDE_PHOTO}
											formats={['png', 'jpeg']}
											labelName="Upload Right Side"
											error={uploadError}
											data={rightSidePhotos}
											sizeLimitMB={5}
											numberOfImages={10}
											success={(e) => photosUpload(e, setRightSidePhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, rightSidePhotos, setRightSidePhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span style={{ color: 'red' }}>
											{uncontrolledErrors?.rightSidePhotos?.message}
										</span>
									)}
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Has the horse's status changed?`}
										</div>
										<Toggle
											id={HorseManagementKeys.HORSE_STATUS_CHANGE}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setHorseStatusChanged(value);
												if (!value) {
													setSelectedNewStatus(0);
												}
												setShouldValidate(true);
											}}
											value={horseStatusChanged}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{!horseStatusChanged ? (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Current Status</div>

											<div className={classes.statusTitle}>
												{horseData?.currentStatus?.value}
											</div>
										</div>
									</Col>
								) : (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>New Status*</div>
											<FormDropDown
												items={newStatus}
												selectedId={selectedNewStatus}
												onChange={(e) => {
													setSelectedNewStatus(e.target.value);
													setShouldValidate(true);
												}}
												showBlank
												id={HorseManagementKeys.NEW_STATUS}
												disabled={isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.HorseNewStatus?.message}
											</span>
										)}{' '}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Describe overall condition{' '}
													<span className={classes.fontWeightNormal}>
														(body condition, feet condition, note any
														superficial wounds/abrasions)
													</span>
													*
												</div>
											}
											id={HorseManagementKeys.OVERALL_CONDITION}
											onChange={(e) => {
												setOverallCondition(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={overallCondition}
											titleStyles={{
												fontSize: 14
											}}
											canEdit={!isReadOnly}
										/>
										{overallConditionError && isShowErrorMessages && (
											<ValidationErrorText errorText={overallConditionError} />
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Worming</div>
										<Toggle
											id={HorseManagementKeys.WORMING}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setWorming(value);
												setShouldValidate(true);
											}}
											value={worming}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{worming && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion*"
														value={formatDateReadableVariant(wormingDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion*
												</div>
												<FormDatePicker
													id={HorseManagementKeys.WORMING_DATE_OF_COMPLETION}
													DateValue={wormingDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setWormingDate,
															validateWormingDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.WormingDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Farrier</div>
										<Toggle
											id={HorseManagementKeys.FARRIER}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setFarrier(value);
												setShouldValidate(true);
											}}
											value={farrier}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{farrier && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion*"
														value={formatDateReadableVariant(farrierDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion*
												</div>
												<FormDatePicker
													id={HorseManagementKeys.FARRIER_DATE_OF_COMPLETION}
													DateValue={farrierDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setFarrierDate,
															validateFarrierDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.FarrierDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Dentist</div>
										<Toggle
											id={HorseManagementKeys.DENTIST}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setDentist(value);
												setShouldValidate(true);
											}}
											value={dentist}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{dentist && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion*"
														value={formatDateReadableVariant(dentistDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion*
												</div>
												<FormDatePicker
													id={HorseManagementKeys.DENTIST_DATE_OF_COMPLETION}
													DateValue={dentistDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setDentistDate,
															validateDentistDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.DentistDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title="Other Husbandry Information"
											id={HorseManagementKeys.OTHER_HUSBANDRY_INFO}
											onChange={(e) => setOtherHusbandryInfo(e.target.value)}
											maxChars={500}
											value={otherHusbandryInfo}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Assessment of feeding requirements/plan{' '}
													<span className={classes.fontWeightNormal}>
														(feeding to maintain or increase condition, feeding
														to let down etc)
													</span>
													*
												</div>
											}
											id={HorseManagementKeys.FEEDING_REQUIREMENT_PLAN}
											onChange={(e) => {
												setFeedingPlan(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={feedingPlan}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{feedingError && isShowErrorMessages && (
											<ValidationErrorText errorText={feedingError} />
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Assessment of temperament/behaviour{' '}
													<span className={classes.fontWeightNormal}>
														(nervous/relaxed/aggressive/sensitive)
													</span>
													*
												</div>
											}
											id={HorseManagementKeys.TEMPERAMENT}
											onChange={(e) => {
												setTemperament(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={temperament}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{temperamentError && isShowErrorMessages && (
											<ValidationErrorText errorText={temperamentError} />
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									{isReadOnly ? (
										<div style={{ padding: '10px 0px 10px 0px' }}>
											<FormLabelField
												title={`Select vices or bad habits that ${horseData?.horseName} has`}
												value={selectedVices
													.map((selectedHabit) => selectedHabit.label)
													.join()}
												style={{ padding: 0 }}
											/>
										</div>
									) : (
										<div className={classes.fieldContainer}>
											<MultiSelectInputField
												idPrefix={HorseManagementKeys.VICES}
												fieldLabel={`Select vices or bad habits that ${horseData?.horseName} has`}
												selectedIds={selectedVices}
												options={viceOptions}
												onSelectChange={(newVices) => {
													setSelectedVices(newVices);
													if (
														!newVices.find(({ label }) => label === 'Other')
													) {
														setOtherVices('');
													}
												}}
												otherValue={otherVices}
												onOtherChange={(newOtherValue) => {
													setOtherVices(newOtherValue);
												}}
											/>
										</div>
									)}
								</Col>
								{isReadOnly && (
									<Col xs={12}>
										<FormLabelField
											title="Other"
											value={habitsOtherReadOnly}
											style={{ padding: 0 }}
										/>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Are there any concerns with the horse's physical health?*`}
										</div>
										<Toggle
											id={HorseManagementKeys.PHYSICAL_HEALTH_CONCERN}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setPhysicalHealth(value);
												if (!value) {
													setPhysicalHealthVal(0);
												}
												setShouldValidate(true);
											}}
											value={physicalHealth}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{physicalHealth && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												{`Please identify the concern/s*`} <br /> &nbsp; <br />
											</div>

											<FormDropDown
												items={physicalHealthItems}
												selectedId={physicalHealthVal}
												onChange={(e) => {
													setPhysicalHealthVal(e.target.value);
													setShouldValidate(true);
												}}
												showBlank
												id={HorseManagementKeys.CONCERN}
												disabled={isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span style={{ color: 'red' }}>
												{uncontrolledErrors?.PhysicalHealth?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							{physicalHealthVal == 4 && (
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<Controller
												control={control}
												name="PhysicalHealthOtherConcern"
												render={({ onChange }) => (
													<ResizingTextArea
														title="Other Concern*"
														titleStyles={{ fontSize: '14px' }}
														id={HorseManagementKeys.OTHER_CONCERNS}
														onChange={(e) => {
															onChange(e.target.value);
															setPhysicalHealthConcern(e.target.value);
															setShouldValidate(true);
														}}
														maxChars={500}
														value={physicalHealthConcern}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											{isShowErrorMessages && (
												<span style={{ color: 'red' }}>
													{uncontrolledErrors?.PhysicalHealthOther?.message}
												</span>
											)}
										</div>
									</Col>
								</Row>
							)}
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<div
											className={classes.fieldTitle}
										>{`Are there any concerns with the horse's behaviour?*`}</div>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<Toggle
											id={HorseManagementKeys.BEHAVIOUR_CONCERN}
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setBehaviourConcern(value);
												if (!value) {
													setBehaviourConcernVal('');
												}
												setShouldValidate(true);
											}}
											value={behaviourConcern}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{behaviourConcern && (
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<Controller
												control={control}
												name="BehaviourConcern"
												render={({ onChange }) => (
													<ResizingTextArea
														title="Please provide more information"
														id={HorseManagementKeys.PROVIDE_MORE_INFORMATION}
														onChange={(e) => {
															onChange(e.target.value);
															setBehaviourConcernVal(e.target.value);
														}}
														maxChars={500}
														value={behaviourConcernVal}
														titleStyles={{ fontSize: '14px' }}
														canEdit={!isReadOnly}
													/>
												)}
											/>
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<Controller
											control={control}
											name="FinalComments"
											render={({ onChange }) => (
												<ResizingTextArea
													title="Final Comments"
													id={HorseManagementKeys.FINAL_COMMENTS}
													onChange={(e) => {
														onChange(e.target.value);
														setFinalComments(e.target.value);
													}}
													maxChars={700}
													value={finalComments}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											)}
										/>
									</div>
								</Col>
							</Row>

							<hr className={classes.divider} />
							{isLoading ? (
								<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: `${
											isReadOnly ? 'flex-end' : 'space-between'
										}`,
										alignItems: 'center'
									}}
								>
									{!isReadOnly && (
										<div
											id={CommonKeys.CLEAR_FORM}
											onClick={() => onClearConfirmationDialog()}
											className={classes.clearFormLink}
										>
											Clear Form
										</div>
									)}

									<div className={classes.saveRequest}>
										<span className={classes.saveButton}>
											<PrimaryButton
												id={CommonKeys.SAVE}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												onClick={() => onSubmit()}
												disabled={isReadOnly}
											>
												Save
											</PrimaryButton>
										</span>
										<span>
											<PrimaryButton
												id={HorseManagementKeys.SUBMIT_REPORT}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												disabled={disableSubmitButton || isReadOnly}
												onClick={() => handleOnSubmit()}
											>
												Submit Report
											</PrimaryButton>
										</span>
									</div>
								</div>
							)}
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
						{showImageUploadErrorModal && (
							<UploadPhotoErrorModal
								showErrorModal={showImageUploadErrorModal}
								closeModal={() => setShowImageUploadErrorModal(false)}
								isMinimumUploadSize={isMinimumUploadSize}
							/>
						)}
					</form>
					<Dialog
						openDialog={openClearConfirmationDiag}
						handleClose={onCloseClearConfirmationDialog}
						title={confirmationDialogTitle}
						contentChildren={onRenderClearDialogContent}
						actionButtons={onRenderActionButtons}
					/>
				</div>
			)}
		</div>
	);
};

export default AcknowledgedRetirementInitialAssessmentForm;

import React, { useEffect, useState } from 'react';
import useStyles from './styles.js';
import { Row, Col } from 'react-grid-system';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import {
	deleteImageFromS3,
	getContactUsInfo,
	getUserImage,
	saveContactUsForm,
	saveContactUsFormImages,
	uploadImageToS3
} from '../../graphql/custom.queries.js';
import AppSyncService from '../../graphql/AppSyncService.js';
import LoaderSpinner from '../LoaderSpinner/index.js';
import FormDropDown from '../FormDropDown/index.js';
import { useAuth } from '../../hooks/useAuth';
import {
	validation,
	DEPENDENT_DROPDOWN,
	DEPENDENT_DROPDOWN_WITH_TOGGLE,
	UNAUTHENTICATED_REQUIRED
} from './validation';
import { BLANK_OPTION } from '../../utils/constants';
import PrimaryButton from '../PrimaryButton/index.js';
import { checkDropDownValue } from '../../utils/helpers/index.js';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper/index.js';
import ResizingTextArea from '../ResizingTextArea/index.js';
import Toggle from '../Toggle/index.js';
import UploadPhoto from '../UploadPhoto/index.js';
import axios from 'axios';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { useToast } from '../../hooks/useToast';
import Lightbox from 'react-image-lightbox';
import FormTextField from '../FormTextField/index.js';
import { navigate } from 'gatsby-link';

const PAGE_ID = 'Contact-Us-Page';
const CONTACT_INFORMATION_ERROR =
	'Please enter your preferred contact information.';
const EW_HOTLINE_PHONE = '03 9258 4258';

const validationFields = [
	'contactReason',
	'generalFeedback',
	'contactDetails',
	'whichFeature',
	'whichDevice',
	'moreInformation',
	'otherInformation',
	'howCanWeHelp',
	'firstName',
	'lastName',
	'preferredContact'
];

const ContactUsPage = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [isLoading, setIsLoading] = useState(true);
	const [contactReasons, setContactReasons] = useState([]);
	const { currentUser } = useAuth();
	const [browserTypes, setBrowserTypes] = useState([]);
	const [deviceTypes, setDeviceTypes] = useState([]);
	const [whichFeatures, setWhichFeatures] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [submitInProgress, setSubmitInProgress] = useState(false);
	const [validationOn, setValidationOn] = useState(false);
	const [errors, setErrors] = useState({});
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [values, setValues] = useState({
		contactReason: {
			value: 0,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'You must select a reason for contact'
		},
		generalFeedback: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		wantContact: {
			value: true
		},
		contactDetails: {
			value: '',
			conditions: [],
			isRequired: false
		},
		whichFeature: {
			value: 0
		},
		whichDevice: {
			value: 0
		},
		whichBrowser: {
			value: 0
		},
		moreInformation: {
			value: ''
		},
		errorPhotos: {
			value: []
		},
		urgentAttention: {
			value: true
		},
		otherInformation: {
			value: ''
		},
		whichFeatureOther: {
			value: ''
		},
		howCanWeHelp: {
			value: ''
		},
		preferredContact: {
			value: '',
			conditions: [
				{
					condition: UNAUTHENTICATED_REQUIRED,
					user: currentUser,
					errorMessage: 'Please enter your preferred contact information.'
				}
			]
		},
		firstName: {
			value: '',
			conditions: [
				{
					condition: UNAUTHENTICATED_REQUIRED,
					user: currentUser,
					errorMessage: 'Please enter your first name.'
				}
			]
		},
		lastName: {
			value: '',
			conditions: [
				{
					condition: UNAUTHENTICATED_REQUIRED,
					user: currentUser,
					errorMessage: 'Please enter your last name.'
				}
			]
		}
	});

	const errorPhotosUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						setValues({
							...values,
							errorPhotos: {
								...values.errorPhotos,
								value: values.errorPhotos.value.concat({
									filename: image.name,
									key: imageKey
								})
							}
						});
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removeErrorPhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					setValues({
						...values,
						errorPhotos: {
							...values.errorPhotos,
							value: values.errorPhotos.value.filter(
								(item) => item.key !== img.key
							)
						}
					});
				}
			}
		);
	};

	const handleChangeDropDown = (e) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value),
			isTouched: true
		};
		items[e.target.name] = item;
		setValues(items);
	};

	const handleChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
	};

	const handleChangeToggle = (val, name, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val
		};
		items[name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
	};

	useEffect(() => {
		setErrors(validation(values, validationOn, validationFields));
	}, [values]);

	useEffect(() => {
		AppSyncService.execute(getContactUsInfo).then((result) => {
			if (result?.data) {
				setContactReasons(
					[BLANK_OPTION].concat(result?.data?.listContactReasons)
				);
				setBrowserTypes([BLANK_OPTION].concat(result?.data?.listBrowserTypes));
				setDeviceTypes([BLANK_OPTION].concat(result?.data?.listDeviceTypes));
				setWhichFeatures(
					[BLANK_OPTION].concat(result?.data?.listWhichFeatures)
				);
				setValues({
					...values,
					generalFeedback: {
						...values.generalFeedback,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'General feedback',
								errorMessage: 'Feedback is required'
							}
						]
					},
					contactDetails: {
						...values.contactDetails,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN_WITH_TOGGLE,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'General feedback',
								errorMessage: CONTACT_INFORMATION_ERROR,
								toggleField: 'wantContact',
								toggleValue: true
							},
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Help me with something',
								errorMessage: CONTACT_INFORMATION_ERROR
							},
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Page or feature not working',
								errorMessage: CONTACT_INFORMATION_ERROR
							}
						]
					},
					whichFeature: {
						...values.whichFeature,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Page or feature not working',
								errorMessage: 'Please select the feature that caused the issue.'
							}
						]
					},
					whichDevice: {
						...values.whichDevice,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Page or feature not working',
								errorMessage:
									'Please select the device that you were using when the issue was encountered.'
							}
						]
					},
					moreInformation: {
						...values.moreInformation,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Page or feature not working',
								errorMessage:
									'Please provide more information about how the issue was encountered.'
							}
						]
					},
					otherInformation: {
						...values.otherInformation,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Other',
								errorMessage:
									'Please provide more information about your enquiry.'
							}
						]
					},
					howCanWeHelp: {
						...values.howCanWeHelp,
						conditions: [
							{
								condition: DEPENDENT_DROPDOWN,
								dependantArray: result?.data?.listContactReasons,
								dependantField: 'contactReason',
								value: 'Help me with something',
								errorMessage:
									'Please explain how the Equine Welfare team can help you.'
							}
						]
					}
				});
			}
			setIsLoading(false);
		});
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			setSubmitInProgress(true);
			let input = {
				browserType: +values.whichBrowser.value || null,
				contactDetails: values.contactDetails.value || null,
				contactReason: +values.contactReason.value || null,
				deviceType: +values.whichDevice.value || null,
				firstName: values.firstName.value || null,
				generalFeedback: values.generalFeedback.value || null,
				howCanWeHelp: values.howCanWeHelp.value || null,
				lastName: values.lastName.value || null,
				moreInformation: values.moreInformation.value || null,
				otherInformation: values.otherInformation.value || null,
				preferredContact: values.preferredContact.value || null,
				status: 'Review',
				wantContact:
					(checkDropDownValue(
						contactReasons,
						values.contactReason.value,
						'Welfare concern'
					) &&
						values.wantContact.value) ||
					null,
				whichFeature: +values.whichFeature.value || null,
				whichFeatureOther: values.whichFeatureOther.value || null
			};
			AppSyncService.execute(saveContactUsForm, { input }).then((data) => {
				if (data?.data?.saveContactUsForm?.id) {
					let input = values.errorPhotos.value.map((photo) => {
						return {
							contactUsId: data.data.saveContactUsForm.id,
							filename: photo.filename,
							key: photo.key
						};
					});
					if (input.length > 0) {
						AppSyncService.execute(saveContactUsFormImages, { input }).then(
							(result) => {
								if (result?.data?.saveContactUsImages) {
									addToast({ Message: 'Enquiry Submitted', IsSuccess: true });
									navigate('/contact-outcome');
								}
							}
						);
					} else {
						addToast({ Message: 'Enquiry Submitted', IsSuccess: true });
						navigate('/contact-outcome');
					}
				} else {
					addToast({
						Message:
							'An error occured submitting the enquiry. Please contact the EW team directly.',
						IsSuccess: false
					});
					setSubmitInProgress(false);
				}
			});
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	return (
		<PageContainer>
			<SEO title="Feedback or Enquiry" />
			<HeaderBanner
				title="Feedback or Enquiry"
				styles={{ marginBottom: '0px' }}
			/>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{!isLoading && (
					<div className={classes.formContainer}>
						{!currentUser && !isLoading && (
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>First Name*</div>
										<FormTextField
											name="firstName"
											id={`${PAGE_ID}-firstName`}
											onChange={(e) => {
												handleChange(e, 'firstName');
											}}
											value={values.firstName.value}
											styles={
												errors.firstName ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.firstName}
										</span>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Last Name*</div>
										<FormTextField
											name="lastName"
											id={`${PAGE_ID}-lastName`}
											onChange={(e) => {
												handleChange(e, 'lastName');
											}}
											value={values.lastName.value}
											styles={
												errors.lastName ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>{errors.lastName}</span>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Preferred contact (email or phone)*
										</div>
										<FormTextField
											name="preferredContact"
											id={`${PAGE_ID}-preferredContact`}
											onChange={(e) => {
												handleChange(e, 'preferredContact');
											}}
											value={values.preferredContact.value}
											styles={
												errors.preferredContact
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.preferredContact}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Contact Reason*</div>
									<FormDropDown
										items={contactReasons}
										name="contactReason"
										selectedId={values.contactReason.value}
										onChange={handleChangeDropDown}
										id={`${PAGE_ID}-contact-reason`}
										showBlank
										styles={
											errors.contactReason ? { border: '1px solid red' } : {}
										}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.contactReason}
									</div>
								</div>
							</Col>
						</Row>
						{checkDropDownValue(
							contactReasons,
							values.contactReason.value,
							'General feedback'
						) && (
							<>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={'Feedback'}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="generalFeedback"
												id={`${PAGE_ID}-generalFeedback`}
												value={values.generalFeedback.value}
												onChange={(e) => {
													handleChange(e, 'generalFeedback');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={errors.generalFeedback ? 'red' : '#b3bcc1'}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.generalFeedback}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainerToggle}>
											<div className={classes.fieldTitle}>
												Do you want us to contact you about this?*
											</div>
											<Toggle
												labelNegative="No"
												labelPositive="Yes"
												onClick={(val) =>
													handleChangeToggle(val, 'wantContact', [])
												}
												value={values.wantContact.value}
											/>
										</div>
									</Col>
								</Row>
								{values.wantContact.value && (
									<Row>
										<Col xs={12}>
											<FormFieldWrapper
												fieldLabel={'Please let us know your contact details'}
												isRequired={true}
												fullWidth={true}
											>
												<ResizingTextArea
													name="contactDetails"
													id={`${PAGE_ID}-contactDetails`}
													value={values.contactDetails.value}
													onChange={(e) => {
														handleChange(e, 'contactDetails');
													}}
													styles={{ paddingTop: 0 }}
													borderColor={
														errors.contactDetails ? 'red' : '#b3bcc1'
													}
												/>
											</FormFieldWrapper>
											<div className={classes.errorTextResizingArea}>
												{errors.contactDetails}
											</div>
										</Col>
									</Row>
								)}
							</>
						)}
						{checkDropDownValue(
							contactReasons,
							values.contactReason.value,
							'Help me with something'
						) && (
							<>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={'How can we help?'}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="contactDetails"
												id={`${PAGE_ID}-howCanWeHelp`}
												value={values.howCanWeHelp.value}
												onChange={(e) => {
													handleChange(e, 'howCanWeHelp');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={errors.howCanWeHelp ? 'red' : '#b3bcc1'}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.howCanWeHelp}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={'Please let us know your contact details'}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="contactDetails"
												id={`${PAGE_ID}-contactDetails`}
												value={values.contactDetails.value}
												onChange={(e) => {
													handleChange(e, 'contactDetails');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={errors.contactDetails ? 'red' : '#b3bcc1'}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.contactDetails}
										</div>
									</Col>
								</Row>
							</>
						)}
						{checkDropDownValue(
							contactReasons,
							values.contactReason.value,
							'Page or feature not working'
						) && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Which Feature?*</div>
											<FormDropDown
												items={whichFeatures}
												selectedId={values.whichFeature.value}
												onChange={handleChangeDropDown}
												id={`${PAGE_ID}-which-feature`}
												name="whichFeature"
												showBlank
												styles={
													errors.whichFeature ? { border: '1px solid red' } : {}
												}
											/>
											<div className={classes.errorTextDropDown}>
												{errors.whichFeature}
											</div>
										</div>
									</Col>
									{checkDropDownValue(
										whichFeatures,
										values.whichFeature.value,
										'Other'
									) && (
										<Col xs={12} lg={8}>
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Please describe the feature as best you can.
												</div>
												<ResizingTextArea
													name="whichFeatureOther"
													id={`${PAGE_ID}-whichFeatureOther`}
													value={values.whichFeatureOther.value}
													onChange={(e) => {
														handleChange(e, 'whichFeatureOther');
													}}
													styles={{ paddingTop: 0 }}
												/>
											</div>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12} lg={6}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Which device did you experience this issue on?*
											</div>
											<FormDropDown
												items={deviceTypes}
												selectedId={values.whichDevice.value}
												onChange={handleChangeDropDown}
												id={`${PAGE_ID}-which-device`}
												name="whichDevice"
												showBlank
												styles={
													errors.whichDevice ? { border: '1px solid red' } : {}
												}
											/>
											<div className={classes.errorTextDropDown}>
												{errors.whichDevice}
											</div>
										</div>
									</Col>
									<Col xs={12} lg={6}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Which browser type?
											</div>
											<FormDropDown
												items={browserTypes}
												selectedId={values.whichBrowser.value}
												onChange={handleChangeDropDown}
												id={`${PAGE_ID}-which-browser`}
												name="whichBrowser"
												showBlank
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={
												'Please provide more information (for example, What actions did you complete or fields filled in? Did any error message show?)'
											}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="moreInformation"
												id={`${PAGE_ID}-moreInformation`}
												value={values.moreInformation.value}
												onChange={(e) => {
													handleChange(e, 'moreInformation');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={errors.moreInformation ? 'red' : '#b3bcc1'}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.moreInformation}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div
											className={classes.fieldContainer}
											style={{ marginBottom: 10 }}
										>
											<div className={classes.fieldTitle}>
												Supporting image upload (jpeg, png)
											</div>
											<UploadPhoto
												id={`${PAGE_ID}-errorPhotos`}
												formats={['png', 'jpeg']}
												labelName="Supporting image upload"
												error={uploadError}
												data={values.errorPhotos.value}
												sizeLimitMB={5}
												numberOfImages={6}
												success={errorPhotosUpload}
												getImage={getImage}
												removeImage={removeErrorPhotos}
												canEdit={true}
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={'Please let us know your contact details'}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="contactDetails"
												id={`${PAGE_ID}-contactDetails`}
												value={values.contactDetails.value}
												onChange={(e) => {
													handleChange(e, 'contactDetails');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={errors.contactDetails ? 'red' : '#b3bcc1'}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.contactDetails}
										</div>
									</Col>
								</Row>
							</>
						)}
						{checkDropDownValue(
							contactReasons,
							values.contactReason.value,
							'Welfare concern'
						) && (
							<>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainerToggle}>
											<div className={classes.fieldTitle}>
												Is this an emergency, requiring urgent attention?
											</div>
											<Toggle
												labelNegative="No"
												labelPositive="Yes"
												onClick={(val) =>
													handleChangeToggle(val, 'urgentAttention', [])
												}
												value={values.urgentAttention.value}
											/>
										</div>
									</Col>
								</Row>
								{values.urgentAttention.value && (
									<Row>
										<Col xs={12}>
											<div
												className={classes.fieldTitle}
												style={{ padding: 10 }}
											>
												Please call our Equine Welfare hotline on{' '}
												<a
													className={classes.basicLink}
													href={'tel:' + EW_HOTLINE_PHONE}
												>
													{EW_HOTLINE_PHONE}
												</a>{' '}
												for immediate assistance
											</div>
										</Col>
									</Row>
								)}
								{!values.urgentAttention.value && (
									<Row>
										<Col xs={12}>
											<div
												className={classes.fieldTitle}
												style={{ padding: 10 }}
											>
												Please use this online form to report your concern:{' '}
												<a
													className={classes.basicLink}
													href="https://www.racingvictoria.com.au/integrity/reporting-form"
													rel="noreferrer"
													target="_blank"
												>
													Report a concern | Racing Victoria
												</a>
											</div>
										</Col>
									</Row>
								)}
							</>
						)}
						{checkDropDownValue(
							contactReasons,
							values.contactReason.value,
							'Other'
						) && (
							<>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={'Please provide more information'}
											isRequired={true}
											fullWidth={true}
										>
											<ResizingTextArea
												name="otherInformation"
												id={`${PAGE_ID}-otherInformation`}
												value={values.otherInformation.value}
												onChange={(e) => {
													handleChange(e, 'otherInformation');
												}}
												styles={{ paddingTop: 0 }}
												borderColor={
													errors.otherInformation ? 'red' : '#b3bcc1'
												}
											/>
										</FormFieldWrapper>
										<div className={classes.errorTextResizingArea}>
											{errors.otherInformation}
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<FormFieldWrapper
											fieldLabel={
												'If you would like us to contact you, please confirm your contact details'
											}
											isRequired={false}
											fullWidth={true}
										>
											<ResizingTextArea
												name="contactDetails"
												id={`${PAGE_ID}-contactDetails`}
												value={values.contactDetails.value}
												onChange={(e) => {
													handleChange(e, 'contactDetails');
												}}
												styles={{ paddingTop: 0 }}
											/>
										</FormFieldWrapper>
									</Col>
								</Row>
							</>
						)}
						<div className={classes.saveRequest}>
							<span>
								<PrimaryButton
									onClick={handleSubmit}
									id="contact-us-submit"
									style={{ width: '165px', marginTop: 20, float: 'right' }}
									disabled={submitInProgress}
								>
									{submitInProgress ? 'Submitting...' : 'Submit Form'}
								</PrimaryButton>
							</span>
						</div>
					</div>
				)}
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
				{showImageUploadErrorModal && (
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				)}
			</div>
		</PageContainer>
	);
};

export default ContactUsPage;

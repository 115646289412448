export const newStatusList = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Retired Agistment'
	},
	{
		id: 2,
		value: 'Retirement Rehabilitation'
	}
];

export const physicalHealthItems = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Injury'
	},
	{
		id: 2,
		value: 'Illness'
	},
	{
		id: 3,
		value: 'Lameness'
	},
	{
		id: 4,
		value: 'Other'
	}
];

export const habitItems = [
	{
		id: 1,
		value: 'Biting'
	},
	{
		id: 2,
		value: 'Box walking'
	},
	{
		id: 3,
		value: 'Bucking'
	},
	{
		id: 4,
		value: 'Chewing'
	},
	{
		id: 5,
		value: 'Crib biting'
	},
	{
		id: 6,
		value: 'Kicking'
	},
	{
		id: 7,
		value: 'Pulling when tied'
	},
	{
		id: 7,
		value: 'Rearing'
	},
	{
		id: 9,
		value: 'Tail rubbing'
	},
	{
		id: 10,
		value: 'Weaving'
	},
	{
		id: 11,
		value: 'Wind sucking'
	},
	{
		id: 12,
		value: 'Other'
	}
];

export const MAXREASONLENGTH = 300;

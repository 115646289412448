import React from 'react';
import useStyles from './styles';

const HorseUpdatesContainer = ({ children, id, ...rest }) => {
	const classes = useStyles();
	return (
		<div id={id} className={classes.horseUpdatesContainer} {...rest}>
			{children}
		</div>
	);
};

export default HorseUpdatesContainer;

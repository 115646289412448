import React from 'react';
import useStyles from './styles';

const AdditionalOption = ({
	idPrefix,
	description,
	buttonText,
	onButtonClick,
	displayOption
}) => {
	const classes = useStyles();
	return (
		<>
			{displayOption && (
				<div className={classes.additionalOption}>
					<div className={classes.additionalOptionDescription}>
						{description}
					</div>
					<div className={classes.additionalOptionsButtonContainer}>
						<button
							className={classes.additionalOptionsButton}
							id={idPrefix}
							onClick={onButtonClick}
						>
							{buttonText}
						</button>
					</div>
				</div>
			)}
		</>
	);
};

const AdditionalOptionsContainer = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.additionalOptionsContainer}>{children}</div>;
};

export { AdditionalOption, AdditionalOptionsContainer };

import React from 'react';
import { Row } from 'react-grid-system';
import useStyles from './styles';
import classnames from 'classnames';

const AdminReviewSection = ({
	title,
	children,
	darkContentBackground = false,
	nogutter
}) => {
	const classes = useStyles();
	return (
		<Row className={classes.sectionContainer} nogutter={nogutter}>
			<div className={classes.sectionHeader}>{title}</div>
			<div
				className={classnames({
					[classes.sectionContent]: true,
					[classes.darkContentBackground]: darkContentBackground
				})}
			>
				{children}
			</div>
		</Row>
	);
};

export default AdminReviewSection;

export const filterAcknowledgedRetrainers = `
  query filterAcknowledgedRetrainerForms($filterStatuses: [FormStatusEnum], $order: OrderByEnum = DESC, $paginationIndex: Int, $paginationSize: Int, $businessProfileTypeId: [Int] ) {
    getAcknowledgedReTrainerForms(status: $filterStatuses, order: $order, paginationSize: $paginationSize, paginationIndex: $paginationIndex, businessProfileTypeId: $businessProfileTypeId) {
      id
      member {
        firstName
        lastName
        id
      }
      status
      business_profile_type_id
    }
  }
`;

export const countTotalFilteredHorseClaims = `
  query countTotalFilteredHorsesClaims($filterStatuses: [FormStatusEnum!]) {
    countHorseClaims(status: $filterStatuses)
  }
`;

export const countTotalFilteredAckRetrainerForms = `
  query countTotalFilteredAckRetrainerForms($filterStatuses: [FormStatusEnum], $businessProfileTypeId: [Int]){
    countAcknowledgedRetrainerForms(status: $filterStatuses, businessProfileTypeId: $businessProfileTypeId)
  }
`;

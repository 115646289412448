import React, { useEffect, useState } from 'react';
import AppSyncService from '../../graphql/AppSyncService';
import { checkImageExist, getUserImage } from '../../graphql/custom.queries';
import useStyles from './styles';
import { useToast } from '../../hooks/useToast';

const LazyImage = ({
	imageKey,
	styles,
	onImageClick,
	defaultImage,
	horseForSale,
	isMobile
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imageUrl, setImageUrl] = useState('');
	const [isDefaultImageUsed, setIsDefaultImageUsed] = useState(false);

	useEffect(() => {
		if (imageKey) {
			if (horseForSale) {
				AppSyncService.execute(checkImageExist, { imageKey }).then((data) => {
					if (data?.data?.checkImageExist) {
						AppSyncService.execute(getUserImage, { imageKey }).then((d) => {
							try {
								setImageUrl(d.data.getUserImage.signedUrl);
							} catch {
								addToast({ Message: 'Error Fetching Image', IsSuccess: false });
							}
						});
					} else {
						setIsDefaultImageUsed(true);
						setImageUrl(defaultImage);
					}
				});
			} else {
				AppSyncService.execute(getUserImage, { imageKey }).then((d) => {
					try {
						setImageUrl(d.data.getUserImage.signedUrl);
					} catch {
						addToast({ Message: 'Error Fetching Image', IsSuccess: false });
					}
				});
			}
		} else {
			setIsDefaultImageUsed(true);
			setImageUrl(defaultImage);
		}
	}, [imageKey]);

	return (
		<img
			src={imageUrl}
			className={
				!horseForSale
					? classes.image
					: isMobile
					? classes.newsImage
					: classes.tackRoomImage
			}
			style={!horseForSale ? styles : isDefaultImageUsed ? styles : null}
			onClick={onImageClick ? () => onImageClick() : function () {}}
		/>
	);
};

export default LazyImage;

import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

const StyledSelectWrapper = ({ children, isDisabled, isError }) => {
	const classes = useStyles();
	const wrapperClasses = classnames({
		[classes.customDropdownStyles]: true,
		[classes.disabledStyles]: isDisabled,
		[classes.errorStyle]: isError
	});
	return <div className={wrapperClasses}>{children}</div>;
};

export default StyledSelectWrapper;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formSectionContent: {
		marginTop: 0,
		'@media (min-width:992px)': {
			'& > div:not(:first-child)': {
				marginTop: 15
			}
		}
	}
}));

export const fullCircleList = `
query getFullCircles($memberExternalId: String!) {
  getFullCircles(memberExternalId: $memberExternalId, status: [Reviewed], orderBy: "last_updated", order: DESC) {
    memberHasClaimOnHorse
    memberFollowsHorse
    horse {
      name
      colour
      sex
      age
      horseCode
      location {
        address
        postcode
        suburb
        state
        country
        stabledDate
      }
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
        paddockNameVisibility {
          id
        }
        profilePhotoVisibility {
          id
        }
      }
    }
  }
}`;

import * as Yup from 'yup';
import {
	AT_LEAST_ONE_ERROR_MESSAGE,
	MICROCHIP_LENGTH_ERROR_MESSAGE,
	AGE_LENGTH_ERROR_MESSAGE
} from './constants';

const schema = Yup.object()
	.shape({
		contactNumber: Yup.string()
			.required('Contact number is required')
			.matches(/^\+\d*$|^\d*$/, 'Contact number must be valid'),
		relationshipToHorse: Yup.number()
			.required('Relationship is required')
			.moreThan(0, 'Relationship is required'),
		relationshipToHorseOther: Yup.string().when('relationshipToHorse', {
			is: (relationship) => relationship == 5,
			then: () => Yup.string().required('Other Relationship is required')
		}),
		preferredContactType: Yup.number()
			.required('Preferred contact method is required')
			.moreThan(0, 'Preferred contact method is required'),
		contactDetails: Yup.string().required('Contact details is required'),
		horseName: Yup.string().required(
			'Horse name is a required field, if your horse does not have a registered name please add either the known as name or the breeding history'
		),
		microchip: Yup.string(),
		sire: Yup.string().nullable(),
		dam: Yup.string().nullable(),
		horseGender: Yup.number()
			.required('Sex is required')
			.moreThan(0, 'Sex is required'),
		age: Yup.string().nullable(),
		eligibilityCriteria: Yup.number()
			.required('Eligibility criteria is required')
			.moreThan(0, 'Eligibility criteria is required'),
		leftSidePhotos: Yup.array().nullable().default([]),
		rightSidePhotos: Yup.array().nullable().default([]),
		currentAddress: Yup.string().required(),
		currentSuburb: Yup.string().required(),
		currentPostcode: Yup.string().required(),
		currentState: Yup.string().required(),
		currentCountry: Yup.string().required(),
		currentAddressValidated: Yup.boolean()
	})
	.test('test-microchip-18digits', '', (values, context) => {
		if (values.microchip && values.microchip.length > 18) {
			return context.createError({
				path: 'microchip',
				message: MICROCHIP_LENGTH_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-age-2digits', '', (values, context) => {
		if (parseInt(values.age) > 40) {
			return context.createError({
				path: 'age',
				message: AGE_LENGTH_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-3-mandatory-fields', '', (values, context) => {
		if (checkAtleast2FieldsAreNotPopulated(values)) {
			return context.createError({
				path: 'mandatoryErrors',
				message: AT_LEAST_ONE_ERROR_MESSAGE
			});
		}
		return true;
	});

const checkAtleast2FieldsAreNotPopulated = (values) => {
	let counter = 0;
	if (
		values.microchip?.toString().length > 0 &&
		values.microchip?.toString().length <= 18
	) {
		counter++;
	}
	if (values.sire.length !== 0) {
		counter++;
	}
	if (values.dam.length !== 0) {
		counter++;
	}
	if (values.leftSidePhotos.length !== 0) {
		counter++;
	}
	if (values.rightSidePhotos.length !== 0) {
		counter++;
	}
	return counter < 2;
};

export default schema;

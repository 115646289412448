import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';

const ProgramsLandingPage = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [programDescription, setProgramDescription] = useState('');
	const [programTitle, setProgramTitle] = useState('');

	useEffect(() => {
		setProgramDescription(
			programInfo.allContentfulOttPrograms.nodes[0].description?.description
		);
		setProgramTitle(programInfo.allContentfulOttPrograms.nodes[0].title?.title);
	}, [programInfo]);

	const programInfo = useStaticQuery(
		graphql`
			query programInfo {
				allContentfulOttPrograms {
					nodes {
						title {
							title
						}
						description {
							description
						}
					}
				}
				allContentfulOttProgramInfoCards {
					nodes {
						text {
							text
						}
						title
						order
						link
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		const programInfoCards = programInfo.allContentfulOttProgramInfoCards.nodes;
		let OttProgramInfoCards = [];
		if (programInfoCards.length > 0) {
			programInfoCards.map((d) => {
				OttProgramInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order,
					link: d?.link
				});
			});
			OttProgramInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(OttProgramInfoCards);
		}
	}, []);

	const programInformation = (isMobile) => {
		return (
			<>
				<SEO title="Post Racing Programs" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="Post Racing Programs"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{
					<>
						<div
							className={isMobile ? classes.textTitleMobile : classes.textTitle}
						>
							{programTitle}
						</div>
						<div
							className={
								isMobile
									? classes.textDescriptionMobile
									: classes.textDescription
							}
						>
							{programDescription}
						</div>
					</>
				}
				{cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>
				{programInformation(true)}
			</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{programInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default ProgramsLandingPage;

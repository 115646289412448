import { navigate } from 'gatsby-link';
import React from 'react';
import { useScreenClass } from 'react-grid-system';
import {
	checkIsSmallScreen,
	eventColourMap,
	formatDateCalendar
} from '../../utils/helpers';
import useStyles from './styles';
import cancelEventIcon from '../../assets/icons/Cancel_Event_icon.svg';

const EventPopover = ({ event, setHeight = false }) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	return (
		<div
			onClick={() => navigate(`/event/${event.id}`)}
			style={{ cursor: 'pointer' }}
		>
			<div
				style={{
					background: eventColourMap(event),
					padding: 10,
					borderRadius: '5px 5px 0px 0px',
					fontWeight: 500,
					fontSize: 15,
					textDecoration: event.isCancelled ? 'line-through' : ''
				}}
			>
				{event.isCancelled && (
					<img src={cancelEventIcon} className={classes.warningIcon} />
				)}
				{event.title}
			</div>
			<div
				className={
					setHeight
						? isSmallScreen
							? classes.mobileUpNext
							: classes.upNext
						: classes.eventPopover
				}
				style={{ fontSize: 15 }}
			>
				{formatDateCalendar(new Date(event.startDate))}{' '}
				{event.endDate && event.endDate !== event.startDate && (
					<>{` - ${formatDateCalendar(new Date(event.endDate))}`}</>
				)}
				<br />
				{event.startTime && ` ${event.startTime}`}
				{event.endTime && ` -  ${event.endTime}`}
				{event.allDay ? ' All Day' : ''}
				<br />
				{`${event.address ?? ''}`}
				<br />
				{`${event.suburb ?? ''}, ${event.state ?? ''} ${event.postcode ?? ''}
				 ${event.country ?? ''}`}
			</div>
		</div>
	);
};

export default EventPopover;

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	reviewHorsePlacementForm,
	updateMemberProfile,
	saveHorseProfileRetirement,
	saveDismissableNotif,
	generateRequestRetrainingNotice
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { addNameToUnnamedHorse } from '../../utils/helpers';
import SNSAdmin from '../SNSAdmin';
import useFilePreview from '../../hooks/useFilePreview';
import EligibilityForBCTS from '../EligibilityForBCTS/index';
import RetirementCategory from '../RetirementCategory/index';
import {
	APPROVED,
	AVAILABLE_FOR_RETRAINING_APPROVED,
	B2_INJURY_LISTING,
	B2_VICES_LISTING,
	C_INJURY_LISTING
} from '../../utils/constants';
import { logToServer } from '../../utils/logger';
import { useAuth } from '../../hooks/useAuth';

const MAXREASONLENGTH = 300;

const AvailableForRetrainReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [onValidate, setOnValidate] = useState(false);

	const evaluateRetirementCategory = () => {
		//Default to B1
		let evaluateRetirementCategoryVal = 1;
		if (data.status == 'Review') {
			if (
				data.horse?.age >= 10 ||
				data.injuriesPast.some((injury) =>
					B2_INJURY_LISTING.includes(injury.id)
				) ||
				data.vicesCurrent.some((vice) => B2_VICES_LISTING.includes(vice.id)) ||
				data.vicesPast.some((vice) => B2_VICES_LISTING.includes(vice.id)) ||
				data.vicesCurrent.length >= 2 ||
				data.vicesPast.length >= 2
			) {
				//B2
				evaluateRetirementCategoryVal = 5;
			}
			if (
				data.injuries.length > 0 &&
				data.injuries.every((injury) => !C_INJURY_LISTING.includes(injury.id))
			) {
				//C
				evaluateRetirementCategoryVal = 2;
			}
		}
		return evaluateRetirementCategoryVal;
	};

	const [adminDataErrors, setAdminDataErrors] = useState({
		behaviourBCTS: {
			value: false,
			message: `List of vice/s is required*`
		},
		conformationalBCTS: {
			value: false,
			message: `Conformational defect/s - details is required*`
		},
		otherReasonDetailsBCTS: {
			value: false,
			message: `Other reason - details is required*`
		},
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		otherCurrentInjuriesBCTS: {
			value: false,
			message: `Current - other* is required`
		},
		otherPreviousInjuriesBCTS: {
			value: false,
			message: `Previous - other* is required`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		},
		behaviourOtherBCTS: {
			value: false,
			message: 'Other is required*'
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		}
	});
	let splitOtherVicesBCTS = [];
	let mapBehaviourBCTS = [];
	if (data.behaviourOthersBCTS) {
		splitOtherVicesBCTS = data.behaviourOthersBCTS.split(',');
		mapBehaviourBCTS = splitOtherVicesBCTS.map((otherVices) => ({
			label: otherVices,
			value: otherVices
		}));
	}

	let splitOtherVicesRetirement = [];
	let mapBehaviourRetirement = [];
	if (data.behaviourOthersRetirement) {
		splitOtherVicesRetirement = data.behaviourOthersRetirement.split(',');
		mapBehaviourRetirement = splitOtherVicesRetirement.map((otherVices) => ({
			label: otherVices,
			value: otherVices
		}));
	}
	const eligilibleForBCTS =
		data.horse?.age >= 10 ||
		data.isKnownToWindsuck?.value === 'Yes' ||
		data.injuries.length > 0;
	const [adminData, setAdminData] = useState({
		//BCTS
		eligibilityForBCTS:
			data.status == 'Review'
				? eligilibleForBCTS
				: data.eligibilityForBCTS
				? data.eligibilityForBCTS
				: false,
		selectedVices:
			data.habitsBCTS.length > 0
				? data.habitsBCTS.map((selectedVice) => {
						return { value: selectedVice.id, label: selectedVice.value };
				  })
				: [],
		otherVices: data.behaviourOthersBCTS ? mapBehaviourBCTS : '',
		selectedCurrentInjuries:
			data.status == 'Review'
				? data.injuries.map((selectedInjury) => {
						return { value: selectedInjury.id, label: selectedInjury.value };
				  })
				: data.currentInjuries
				? data.currentInjuries.map((selectedInjury) => {
						return { value: selectedInjury.id, label: selectedInjury.value };
				  })
				: [],
		otherCurrentInjuries: data.currentInjuryOther
			? data.currentInjuryOther
			: '',
		selectedPreviousInjuries:
			data.previousInjuries.length > 0
				? data.previousInjuries.map((selectedInjury) => {
						return { value: selectedInjury.id, label: selectedInjury.value };
				  })
				: [],
		otherPreviousInjuries: data.previousInjuryOther
			? data.previousInjuryOther
			: '',
		conformationalDefects: data.conformationalDetailsBCTS
			? data.conformationalDetailsBCTS
			: '',
		hasValidAge:
			data.status == 'Review' ? data.horse?.age >= 10 : data.ageGreaterBCTS,
		hasValidBehaviour: data.behaviourBCTS ? data.behaviourBCTS : false,
		hasValidConformationalDefects: data.conformationalBCTS
			? data.conformationalBCTS
			: false,
		hasValidInjury:
			data.status == 'Review' ? data.injuries.length > 0 : data.injuryBCTS,
		hasValidWindSucker:
			data.status == 'Review'
				? data.isKnownToWindsuck?.value === 'Yes'
				: data.windsucker,
		hasValidUnbroken: data.unbroken ? data.unbroken : false,
		hasValidOtherReason: data.otherReasonBCTS ? data.otherReasonBCTS : false,
		otherReasonDetails: data.otherReasonDetailsBCTS
			? data.otherReasonDetailsBCTS
			: '',
		//Retirement
		selectedCategory:
			data.retirementCategory !== null
				? data.retirementCategory
				: evaluateRetirementCategory(),
		selectedVulnerabilityType: data.vulnerabilityType
			? data.vulnerabilityType
			: 0,
		selectedReason:
			data.reasonRetirement.length > 0
				? data.reasonRetirement.map((reason) => {
						return { value: reason.id, label: reason.value };
				  })
				: [],
		selectedVicesRetirement:
			data.habitsRetirement.length > 0
				? data.habitsRetirement.map((selectedHabitsRetirement) => {
						return {
							value: selectedHabitsRetirement.id,
							label: selectedHabitsRetirement.value
						};
				  })
				: [],
		otherVicesRetirement: data.behaviourOthersRetirement
			? mapBehaviourRetirement
			: '',
		conformationalDefectsRetirement: data.conformationalDetailsRetirement
			? data.conformationalDetailsRetirement
			: '',
		selectedEducation:
			data.education.length > 0
				? data.education.map((selectedEducation) => {
						return {
							value: selectedEducation.id,
							label: selectedEducation.value
						};
				  })
				: [],
		otherEducationDetails: data.otherEducationDetails
			? data.otherEducationDetails
			: '',
		selectedInjuriesRetirement:
			data.injuriesRetirement.length > 0
				? data.injuriesRetirement.map((injuriesRetirement) => {
						return {
							value: injuriesRetirement.id,
							label: injuriesRetirement.value
						};
				  })
				: [],
		selectedIllnessRetirement:
			data.illnessRetirement.length > 0
				? data.illnessRetirement.map((illnessRetirement) => {
						return {
							value: illnessRetirement.id,
							label: illnessRetirement.value
						};
				  })
				: [],
		otherInjuryDetails: data.otherInjuryDetailsRetirement
			? data.otherInjuryDetailsRetirement
			: '',
		otherIllnessDetails: data.otherIllnessDetailsRetirement
			? data.otherIllnessDetailsRetirement
			: '',
		rehomingHistoryDetails: data.rehomingHistoryDetails
			? data.rehomingHistoryDetails
			: '',
		otherDetailsRetirement: data.otherDetailsRetirement
			? data.otherDetailsRetirement
			: ''
	});

	useEffect(() => {
		setRejectReason(data.reviewMessages === null ? '' : data.reviewMessages);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	useEffect(() => {
		if (onValidate) {
			evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	const handleCheckbox = (name) => {
		let items = { ...adminData };
		items[name] = !items[name];
		setAdminData(items);
	};

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setAdminData(items);
		setOnValidate(true);
	};

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const updateProfile = async () => {
		try {
			if (!data?.member?.phone && data.contactNumber) {
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData: {
						phone: data.contactNumber,
						externalId: data?.member?.externalId
					}
				});
				addToast({ Message: 'Updated member profile', IsSuccess: true });
			}
		} catch (error) {
			addToast({
				Message: 'Could not update member profile',
				IsSuccess: false
			});
		}
	};

	const reject = () => {
		setIsProcessingReject(true);
		const hasError = evaluateAdminError();
		if (hasError) {
			setIsProcessingReject(false);
		} else {
			const payload = horsePlacementFormStatusPayload();
			const horsePlacementFormStatusInput = {
				id: parseInt(data.id),
				reviewNotes: notes,
				reviewMessages: rejectReason,
				status: 'Rejected',
				...payload
			};
			AppSyncService.execute(reviewHorsePlacementForm, {
				horsePlacementFormStatusInput
			}).then((data) => {
				setIsProcessingReject(false);
				if (data.data?.saveHorsePlacementFormStatus?.success) {
					addToast({ Message: 'Form updated', IsSuccess: true });
					navigate(`/review-list`);
				} else {
					addToast({ Message: 'Error processing form', IsSuccess: false });
					setErrorMessage(
						data.data?.saveHorsePlacementFormStatus?.error?.errorMessage
					);
				}
			});
		}
	};

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let behaviourBCTS = {
			...errorItems['behaviourBCTS'],
			value: adminData.hasValidBehaviour && adminData.selectedVices.length === 0
		};
		errorItems['behaviourBCTS'] = behaviourBCTS;

		let conformationalBCTS = {
			...errorItems['conformationalBCTS'],
			value:
				adminData.hasValidConformationalDefects &&
				adminData.conformationalDefects.length === 0
		};
		errorItems['conformationalBCTS'] = conformationalBCTS;

		let otherReasonDetailsBCTS = {
			...errorItems['otherReasonDetailsBCTS'],
			value:
				adminData.hasValidOtherReason &&
				adminData.otherReasonDetails.length === 0
		};
		errorItems['otherReasonDetailsBCTS'] = otherReasonDetailsBCTS;

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) && //@NOTE: If selected reason has injury
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) &&
				adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) && //@NOTE: If selected reason has illness
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) &&
				adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let otherCurrentInjuriesBCTS = {
			...errorItems['otherCurrentInjuriesBCTS'],
			value:
				adminData.hasValidInjury &&
				adminData.selectedCurrentInjuries.some(
					(injury) => injury.value == 12
				) &&
				adminData.otherCurrentInjuries.length === 0
		};
		errorItems['otherCurrentInjuriesBCTS'] = otherCurrentInjuriesBCTS;

		let otherPreviousInjuriesBCTS = {
			...errorItems['otherPreviousInjuriesBCTS'],
			value:
				adminData.hasValidInjury &&
				adminData.selectedPreviousInjuries.some(
					(injury) => injury.value == 12
				) &&
				adminData.otherPreviousInjuries.length === 0
		};
		errorItems['otherPreviousInjuriesBCTS'] = otherPreviousInjuriesBCTS;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;

		let behaviourOtherBCTS = {
			...errorItems['behaviourOtherBCTS'],
			value:
				adminData.selectedVices.some((vice) => vice.value == 12) &&
				adminData.otherVices.length === 0
		};
		errorItems['behaviourOtherBCTS'] = behaviourOtherBCTS;
		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const getApprovedNotifMessage = (horseName) => {
		return `Your horse ${horseName} has 
			been listed and is visible to our Acknowledged Retrainer network. 
			You will be contacted directly by an interested Retrainer.`;
	};

	const approve = async () => {
		setIsProcessingApprove(true);
		const hasError = evaluateAdminError();
		if (hasError) {
			setIsProcessingApprove(false);
		} else {
			const payload = horsePlacementFormStatusPayload();
			const horseProfilePayload = horseProfileRetirementPayload();
			const horsePlacementFormStatusInput = {
				id: parseInt(data.id),
				status: 'Approved',
				...payload
			};

			const reviewHorsePlacementFormData = await AppSyncService.execute(
				reviewHorsePlacementForm,
				{
					horsePlacementFormStatusInput
				}
			);
			const horseProfileRetirementData = await AppSyncService.execute(
				saveHorseProfileRetirement,
				{
					input: horseProfilePayload
				}
			);
			await updateProfile();
			setIsProcessingApprove(false);
			if (
				reviewHorsePlacementFormData.data?.saveHorsePlacementFormStatus
					?.success &&
				horseProfileRetirementData?.data?.saveHorseProfileRetirement.success
			) {
				try {
					await AppSyncService.execute(saveDismissableNotif, {
						externalId: data.member?.externalId,
						autoDismissDate: '9999-01-01',
						source: AVAILABLE_FOR_RETRAINING_APPROVED,
						message: getApprovedNotifMessage(data?.horse?.name),
						link: `/horse-details/${data?.horse?.horseCode}`
					});
				} catch (error) {
					logToServer(
						currentUser?.UserId,
						error,
						`AvailableForRetrainReview => dismissableNotifResult`
					);
				}
				try {
					await AppSyncService.execute(generateRequestRetrainingNotice, {
						horseCode: data?.horse?.horseCode,
						memberExternalId: data.member?.externalId,
						declineReason: null,
						status: APPROVED
					});
				} catch (error) {
					logToServer(
						currentUser?.UserId,
						error,
						`AvailableForRetrainReview => generateRequestRetrainingNotice`
					);
				}
				addToast({ Message: 'Form updated', IsSuccess: true });
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					reviewHorsePlacementFormData.data?.saveHorsePlacementFormStatus?.error
						?.errorMessage
				);
			}
		}
	};

	const horsePlacementFormStatusPayload = () => {
		const filteredHabitsBCTS = adminData.selectedVices.map(
			(selectedVice) => selectedVice.value
		);
		const filteredCurrentInjuries = adminData.selectedCurrentInjuries.map(
			(selectedVice) => selectedVice.value
		);
		const filteredPreviousInjuries = adminData.selectedPreviousInjuries.map(
			(selectedVice) => selectedVice.value
		);

		const filteredViceRetirement = adminData.selectedVicesRetirement.map(
			(selectedViceRetirement) => selectedViceRetirement.value
		);
		const filteredEducation = adminData.selectedEducation.map(
			(selectedEduc) => selectedEduc.value
		);
		const filteredInjuryRetirement = adminData.selectedInjuriesRetirement.map(
			(selectedInjuryRetirement) => selectedInjuryRetirement.value
		);
		const filteredIllnessRetirement = adminData.selectedIllnessRetirement.map(
			(selectedIllnessRetirement) => selectedIllnessRetirement.value
		);
		const filteredReasonRetirement = adminData.selectedReason.map(
			(selectedReason) => selectedReason.value
		);

		const horsePlacementFormStatusInput = {
			//BCTS
			eligibilityForBCTS: adminData.eligibilityForBCTS,
			ageGreaterBCTS: adminData.hasValidAge,
			behaviourBCTS: adminData.hasValidBehaviour,
			behaviourOthersBCTS:
				adminData.otherVices.length === 0
					? ''
					: adminData.otherVices.map((item) => item.value).toString(),
			habitsBCTS: filteredHabitsBCTS,
			conformationalBCTS: adminData.hasValidConformationalDefects,
			conformationalDetailsBCTS: adminData.conformationalDefects,
			injuryBCTS: adminData.hasValidInjury,
			currentInjuries: filteredCurrentInjuries,
			previousInjuries: filteredPreviousInjuries,
			windsucker: adminData.hasValidWindSucker,
			unbroken: adminData.hasValidUnbroken,
			otherReasonBCTS: adminData.hasValidOtherReason,
			otherReasonDetailsBCTS: adminData.otherReasonDetails,
			//Retirement
			retirementCategory: adminData.selectedCategory,
			vulnerabilityType: adminData.selectedVulnerabilityType,
			reasonRetirement: filteredReasonRetirement,
			habitsRetirement: filteredViceRetirement,
			behaviourOthersRetirement:
				adminData.otherVicesRetirement.length === 0
					? ''
					: adminData.otherVicesRetirement.map((item) => item.value).toString(),
			conformationalDetailsRetirement:
				adminData.conformationalDefectsRetirement,
			education: filteredEducation,
			otherEducationDetails: adminData.otherEducationDetails,
			injuriesRetirement: filteredInjuryRetirement,
			otherInjuryDetailsRetirement: adminData.otherInjuryDetails,
			illnessRetirement: filteredIllnessRetirement,
			otherIllnessDetailsRetirement: adminData.otherIllnessDetails,
			rehomingHistoryDetails: adminData.rehomingHistoryDetails,
			otherDetailsRetirement: adminData.otherDetailsRetirement,
			currentInjuryOther: adminData.otherCurrentInjuries,
			previousInjuryOther: adminData.otherPreviousInjuries
		};
		return horsePlacementFormStatusInput;
	};

	const horseProfileRetirementPayload = () => {
		const filteredViceRetirement = adminData.selectedVicesRetirement.map(
			(selectedViceRetirement) => selectedViceRetirement.value
		);
		const filteredEducation = adminData.selectedEducation.map(
			(selectedEduc) => selectedEduc.value
		);
		const filteredInjuryRetirement = adminData.selectedInjuriesRetirement.map(
			(selectedInjuryRetirement) => selectedInjuryRetirement.value
		);
		const filteredIllnessRetirement = adminData.selectedIllnessRetirement.map(
			(selectedIllnessRetirement) => selectedIllnessRetirement.value
		);
		const filteredReasonRetirement = adminData.selectedReason.map(
			(selectedReason) => selectedReason.value
		);

		const horseProfileRetirementInput = {
			memberId: data?.member?.externalId,
			horseCode: data?.horse?.horseCode,
			retirementCategory: adminData.selectedCategory,
			vulnerabilityType: adminData.selectedVulnerabilityType,
			behaviourOthersRetirement:
				adminData.otherVicesRetirement.length === 0
					? ''
					: adminData.otherVicesRetirement.map((item) => item.value).toString(),
			conformationalDetailsRetirement:
				adminData.conformationalDefectsRetirement,
			otherEducationDetails: adminData.otherEducationDetails,
			otherInjuryDetailsRetirement: adminData.otherInjuryDetails,
			otherIllnessDetailsRetirement: adminData.otherIllnessDetails,
			rehomingHistoryDetails: adminData.rehomingHistoryDetails,
			otherDetailsRetirement: adminData.otherDetailsRetirement,
			habitsRetirement: filteredViceRetirement,
			injuriesRetirement: filteredInjuryRetirement,
			illnessRetirement: filteredIllnessRetirement,
			education: filteredEducation,
			reasonRetirement: filteredReasonRetirement
		};

		return horseProfileRetirementInput;
	};

	const getInjuryList = () => {
		let injuries = '';
		injuries = data.injuries.map((injuries) => injuries.value).join(', ');
		return injuries.length > 0 ? injuries : '-';
	};

	const getPastInjuryList = () => {
		let pastInjuries = '';
		pastInjuries = data.injuriesPast
			.map((injuries) => injuries.value)
			.join(', ');
		return pastInjuries.length > 0 ? pastInjuries : '-';
	};

	const getIllnessList = () => {
		let illness = '';
		illness = data.illnesses.map((illness) => illness.value).join(', ');
		return illness.length > 0 ? illness : '-';
	};

	const getCurrentVices = () => {
		let vices = '';
		vices = data.vicesCurrent.map((vices) => vices.value).join(', ');
		return vices.length > 0 ? vices : '-';
	};

	const getPastVices = () => {
		let pastVices = '';
		pastVices = data.vicesPast.map((vices) => vices.value).join(', ');
		return pastVices.length > 0 ? pastVices : '-';
	};

	const getDisciplines = () => {
		let disciplines = '';
		if (data.disciplines != null && data.disciplines.length > 0) {
			data.disciplines.map((i, index) => {
				disciplines = disciplines.concat(
					`${index === 0 ? '' : ','} ${i.value}`
				);
			});
		}
		return disciplines.length > 0 ? disciplines : '-';
	};

	return (
		<>
			<div className={classes.adminReviewBody}>
				{data?.horse && <SNSAdmin horseProfileData={data.horse} />}
				<HeaderBanner
					title="Thoroughbred Details"
					mobile={mobile}
					styles={{ marginBottom: 0, marginTop: 0, borderRadius: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row className={classes.d}>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Racing Name"
								value={addNameToUnnamedHorse(data.horse?.name)}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Age" value={data.horse?.age} />
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Colour" value={data.horse?.colour} />
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField title="Sire Name" value={data.sireName} />
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Dam Name" value={data.damName} />
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Horse Sex" value={data.horse?.sex} />
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Reason for Retirement"
								value={data.retirementReason?.value}
							/>
						</Col>
						{data.retirementOtherReason && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other reasons for Retirement"
									value={data.retirementOtherReason}
								/>
							</Col>
						)}
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Approx. Horse Height"
								value={data.height}
							/>
						</Col>
					</Row>
					<hr className={classes.divider} />
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Thoroughbred Address"
								value={data.address}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Thoroughbred Suburb" value={data.suburb} />
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Thoroughbred State" value={data.state} />
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Thoroughbred Postcode"
								value={data.postcode}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Thoroughbred Country"
								value={data.country}
							/>
						</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Are you willing to contribute towards the retraining costs?"
								value={data.contributeRetrainingCosts ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Are you willing to help with transport costs?"
								value={data.isHelpingTransportCosts ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Contact"
					mobile={mobile}
					inline
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Contact Name"
								value={`${data.member?.firstName} ${data?.member?.lastName}`}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Contact Email"
								value={data.member?.email}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Contact Number"
								value={data.member?.phone}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Your relationship to the horse"
								value={data.relationshipToHorse?.value}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Length of relationship to the horse"
								value={data.lengthOfRelationship?.value}
							/>
						</Col>
						<Col></Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Who are the current owner(s)?"
								value={data.currentOwners}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Who are the current/most recent trainer(s)?"
								value={data.currentRetrainers}
							/>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Injury and Health"
					inline
					mobile={mobile}
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Has this horse been known to windsuck?"
								value={data.isKnownToWindsuck?.value}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Is this horse impacted by a current or long term injury?"
								value={data.isInjuryImpactedHorse ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField title="Injury type(s)" value={getInjuryList()} />
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Injury type(s)-Other"
								value={data.injuryOther}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Does this injury currently impact the horses soundness?"
								value={data.isImpactSoundness ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Veterinary reports
								</div>
								<UploadPhoto
									data={data.vetReports}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Previous Injury?"
								value={data.isPastInjuryImpactedHorse ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Previous Injury type(s)"
								value={getPastInjuryList()}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Previous Injury type(s)-Other"
								value={data.injuryPastOther}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Current or long term illness?"
								value={data.isIllnessImpactedHorse ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Illness type(s)"
								value={getIllnessList()}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Illness type(s)-Other"
								value={data.illnessOther}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Please explain in detail the nature of the injury/ illness and the veterinary care and any rehabilitation that has occurred both during and prior to the horse being in your care. This information is very important for the review of the horses long term options and support that may be available for retraining and rehoming successfully"
								value={data.illnessAndInjuriesDetails}
							/>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Information"
					mobile={mobile}
					inline
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col>
							<FormLabelField
								title="To the best of your knowledge, how would you describe this horse's temperament"
								value={data?.temperament}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Does this horse have current vices?"
								value={data.doesHorseHasCurrentVices ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Current vice(s)"
								value={data.doesHorseHasCurrentVices ? getCurrentVices() : '-'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Current vice(s)-Other"
								value={
									data.doesHorseHasCurrentVices ? data.habitsCurrentOther : ''
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Has this horse ever had reported vice(s)?"
								value={data.doesHorseHasPastVices ? 'Yes' : 'No'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Vice type(s)"
								value={data.doesHorseHasPastVices ? getPastVices() : '-'}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Previous vice(s)-Other"
								value={
									data.doesHorseHasPastVices
										? data.vicesPast.some((vice) => vice.id == 12)
											? data.habitsPastOther
											: ''
										: ''
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Based on your experience, what discipline(s) do you believe this horse will be suited to?"
								value={getDisciplines()}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField title="Other" value={data.disciplinesOther} />
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Please provide a detailed history of this horse's ridden education"
								value={data.riddenHistory}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="Please provide information on any other relevant matters or details regarding the horse’s temperament, physical condition, mental condition or education, including any specific requirements they may have (inclusive of feed, care or medication) to enable the new owner/ retrainer to successfully manage the horse."
								value={data.otherDetails}
							/>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Photos"
					mobile={mobile}
					inline
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Face On - Full Length & Legs
								</div>
								<UploadPhoto
									data={data.faceOnPhotos}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Left Side Full Length & Brand
								</div>
								<UploadPhoto
									data={data.leftSidePhotos}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Right Side Full Length & Brand
								</div>
								<UploadPhoto
									data={data.rightSidePhotos}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Behind - Full Length & Legs
								</div>
								<UploadPhoto
									data={data.behindFullPhotos}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Additional Photos
								</div>
								<UploadPhoto
									data={data.additionalPhotos}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Eligibility for the RESET Program"
					inline
					mobile={mobile}
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col>
							<FormLabelField
								title="If this horse is not successful in this process, would you be interested in this horse being nominated for the RESET Program?"
								value={data?.isEligibleForRESET ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Declarations"
					inline
					mobile={mobile}
					styles={{ marginTop: 0, marginBottom: 0 }}
				/>
				<div
					className={
						mobile ? classes.sectionContainerMobile : classes.sectionContainer
					}
				>
					<Row>
						<Col>
							<FormLabelField
								containsLink
								title='I declare that, to the best of knowledge and belief, the information provided in this form is true and correct, in particular the information provided regarding the horse’s health, veterinary related issues and temperament, and acknowledge that I may be subject to Racing Victoria enquiries, including pursuant to the <a href="https://www.racingvictoria.com.au/the-sport/racing/rules-of-racing" target="_blank" rel="noopener noreferrer">Racing Victoria Rules of Racing</a>, for providing any false or misleading information, or deliberately omitting relevant information.'
								value={data?.registerDeclaration ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormLabelField
								title="I declare that I am the Registered Owner, Registered Managing Owner (in the case of a syndicate), Registered Managing Lessee (in the case of a lease) or Registered Trainer of this horse (as per Racing Victoria’s records)."
								value={data?.termsDeclaration ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
				</div>
				<AdminReviewFormApproval
					notes={notes}
					updateNotes={(value) => setNotes(value)}
					rejectReason={rejectReason}
					updateRejectReason={(value) => updateRejectReason(value)}
					mobile={mobile}
					isProcessingApprove={isProcessingApprove}
					isProcessingReject={isProcessingReject}
					processReject={reject}
					processApprove={approve}
					status={data.status}
					errorMessage={errorMessage}
					maxReasonCharacters={maxCharacters}
					totalLength={300}
					formName="availableForRetraining"
					eligibilityForBCTS={
						<EligibilityForBCTS
							isReadOnly={data.status !== 'Review'}
							data={data}
							adminData={adminData}
							adminDataErrors={adminDataErrors}
							handleCheckbox={handleCheckbox}
							handleMultiFieldChange={handleMultiFieldChange}
						/>
					}
					retirementCategory={
						<RetirementCategory
							isReadOnly={data.status !== 'Review'}
							adminData={adminData}
							adminDataErrors={adminDataErrors}
							handleMultiFieldChange={handleMultiFieldChange}
						/>
					}
				/>
			</div>
		</>
	);
};
export default AvailableForRetrainReviewInfo;

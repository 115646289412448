import React from 'react';
import { Col, Row } from 'react-grid-system';
import SEO from '../components/seo';
import { AuthProvider } from '../hooks/useAuth';

const NotFoundPage = () => (
	<AuthProvider>
		<SEO title="404: Not found" />
		<Row>
			<Col
				xs={8}
				lg={4}
				style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
			>
				<h1>404: Not Found</h1>
				<p>Page doesn&#39;t exists.</p>
			</Col>
		</Row>
	</AuthProvider>
);

export default NotFoundPage;

export const COLUMNS_RACE_SUMMARY = [
	{
		Header: ' ',
		columns: [
			{
				Header: 'Starts',
				accessor: 'starts'
			},
			{
				Header: 'Career',
				accessor: 'career'
			},
			{
				Header: 'Prize Money',
				accessor: 'prizeMoney'
			},
			{
				Header: 'Bonus',
				accessor: 'bonus'
			}
		]
	}
];

export const COLUMNS_CLASS_SUMMARY = [
	{
		Header: ' ',
		columns: [
			{
				Header: 'Class',
				accessor: 'class'
			},
			{
				Header: 'Starts',
				accessor: 'starts'
			},
			{
				Header: '1st',
				accessor: 'firsts'
			},
			{
				Header: '2nd',
				accessor: 'seconds'
			},
			{
				Header: '3rd',
				accessor: 'thirds'
			},
			{
				Header: 'Prize Money',
				accessor: 'prizeMoney'
			}
		]
	}
];

export const DEFAULT_PROGRAM = 4;
export const DEFAULT_OTT_STATUS = 9;

export const ALLOWED_RETRAINING_FORM_ACCESS_MEMBER_TYPES = [
	'Racehorse Owner',
	'Breeder',
	'RV Licensed Person',
	'OTT Owner/ Rider',
	'Retrainer'
];

export const NO_UPDATES_TEXT = 'No updates available';
export const PRINCIPAL_RACING_AUTHORITY = 'Principal Racing Authority';

import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

export const StyledContainer = styled(Row)`
	padding: 1rem 0.5rem;
	a {
		text-decoration: none;
		h2 {
			font-size: 1.5rem;
			color: #464749;
		}
	}
	@media (min-width: ${({ theme: { breakpoints } }) =>
			breakpoints.md}) and (max-width: ${({ theme: { breakpoints } }) =>
			breakpoints.lg}) {
		justify-content: center !important;
	}
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		h2 {
			margin-top: 0px;
		}
	}
`;

export const StyledLogoCol = styled(Col)`
	display: flex;
	justify-content: center;
	img,
	video {
		display: block;
		width: 100%;
		border-radius: 5px;
	}
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		img,
		video {
			max-height: 220px;
			object-fit: cover;
		}
	}
`;

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  searchPageContainer: {
    maxWidth: "1000px !important",
    marginBottom: 150,
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
    marginRight: 15,
    marginLeft: 15
  },
  claimContainer : {
    background: "#fcf7da",
    fontSize: 12,
    marginTop: 10,
    padding: 10
  },
  searchContainerHeader: {
    fontSize: 15,
    color: "#464749",
    fontWeight: 800,
    padding: "10px 15px 6px 15px",
  },
  filterContainer: {
    marginTop: 15,
    maxHeight: "100%",
    padding: "0px !important"
  },
  filterContainerTitle:{
    paddingBottom : 5,
    fontSize: 15,
    color: "#464749",
    fontWeight: 800,
    paddingLeft: 15
  },
  searchContainer: {
    maxHeight: "100%",
    overflow: "hidden",
  },
  searchFieldContainer: {
    marginLeft: 15,
    marginRight: 15,
  },
  searchField: {
    float: "left",
    height: "34px",
    borderRadius: "4px 0px 0px 4px",
    border: "1px solid #b3bcc1",
    minWidth: "66%",
    paddingLeft: 10,
    fontSize: 15,
    margin: 0,
    "&:focus": {
      outline: "none",
    },
  },
  searchButton: {
    backgroundColor: "#006da6",
    border: "none",
    borderRadius: "0px 4px 4px 0px",
    color: "white",
    float: "left",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    height: 38,
    minWidth: "29%",
    fontSize: 16,
    margin: 0,
    "&:focus": {
      outline: "none",
    },
  },
  clear: {
    clear: "both",
  },
}));

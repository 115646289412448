import { createUseStyles } from 'react-jss';
import styled from 'styled-components';

export default createUseStyles(() => ({
	horseDetailsHeaderButtons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	horseDetailsHeaderButtonPrimary: {
		border: 'none',
		borderRadius: 4,
		textAlign: 'center',
		textDecoration: 'none',
		fontWeight: 600,
		height: 38,
		minWidth: 100,
		paddingLeft: 10,
		paddingRight: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 4,
		marginBottom: 4,
		marginRight: 8,
		'@media (min-width:992px)': {
			marginTop: 0,
			marginBottom: 0,
			marginRight: 20
		},
		fontSize: 16,
		'-webkitAppearance': 'none',
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	filled: {
		extend: 'horseDetailsHeaderButtonPrimary',
		backgroundColor: '#006da6',
		color: 'white'
	},
	unfilled: {
		extend: 'horseDetailsHeaderButtonPrimary',
		backgroundColor: 'white',
		color: '#006da6'
	}
}));

export const StyledHorseDetailsHeaderButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.sm}) {
		justify-content: flex-end;
		flex-direction: row;
	}
`;

import React, { useEffect, useState } from 'react';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import HeaderBanner from '../HeaderBanner';
import DataTable from '../DataTable';
import AppSyncService from '../../graphql/AppSyncService';
import FormMultiSelectList from '../FormMultiSelectList';
import useStyles from './styles';
import {
	getResetHorses,
	listResetNewStatuses,
	submitResetReportsForReview
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import HorseManagementDataRow from '../HorseManagementDataRow';
import { useToast } from '../../hooks/useToast';
import { getResetDraftForms } from './queries';
import { convertToMultiListData } from '../../utils/helpers';
import { RESET } from '../../utils/constants';
import { logToServer } from '../../utils/logger';
import { navigate } from 'gatsby-link';
import LoaderSpinner from '../LoaderSpinner';
import { HorseManagementKeys } from './../../utils/constants/index';

const infoBarText =
	'Please complete your weekly report(s) for all RESET horse(s) and Save each form. When you are ready to submit for review, click Submit Report.';

const tableHeadings = [
	'',
	'Name',
	'Current Address',
	'Form Select',
	'Last Report Submitted'
];

const mobileHeadings = null;

const RESETManagement = ({ isMobile }) => {
	const classes = useStyles();

	const [resetHorses, setResetHorses] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const { addToast } = useToast();
	const [outstandingForms, setOutstandingForms] = useState(0);
	const [originalHorseList, setOriginalHorseList] = useState([]);
	const [selectedStatus, setselectedStatus] = useState([]);
	const [currentSortDirection, setCurrentSortDirection] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		const memberExternalId = { memberExternalId: currentUser?.UserId };
		AppSyncService.execute(listResetNewStatuses).then((data) => {
			if (data?.data?.listResetNewStatuses) {
				setStatusOptions(
					convertToMultiListData(data.data.listResetNewStatuses, 'value')
				);
			}
		});
		AppSyncService.execute(getResetHorses, memberExternalId).then((data) => {
			if (data?.data?.getResetHorses) {
				setResetHorses(data.data.getResetHorses);
				const allHorses = JSON.parse(JSON.stringify(data.data.getResetHorses));
				setOriginalHorseList(allHorses);
			}
			setIsLoading(false);
		});
	}, []);

	const submitReports = async () => {
		try {
			const data = await AppSyncService.execute(
				submitResetReportsForReview,
				{}
			);
			if (data?.data?.submitResetReportsForReview) {
				addToast({ Message: 'RESET Form(s) Submitted', IsSuccess: true });
				logToServer(
					currentUser?.UserId,
					data,
					'RESET form Submission Submitted and Passed'
				);
				navigate('/');
			} else {
				addToast({
					Message: 'An error submitting. Racing Victoria are investigating.',
					IsSuccess: false
				});
				logToServer(
					currentUser?.UserId,
					data,
					'RESET form Submission Submitted and Failed'
				);
			}
		} catch (error) {
			addToast({
				Message:
					'An Error occured when submitting the RESET forms. Please try again later',
				IsSuccess: false
			});
			logToServer(currentUser?.UserId, error, 'RESET form Submission Failed');
		}
	};

	useEffect(() => {
		buildRows();
	}, [resetHorses]);

	const reloadReportNumbers = () => {
		AppSyncService.execute(getResetDraftForms, {
			memberExternalId: currentUser?.UserId
		}).then((data) => {
			if (
				data?.data?.getResetInitialAssessmentForms?.length > 0 ||
				data?.data?.getResetWeeklyProgressForms?.length > 0
			) {
				setOutstandingForms(
					data?.data?.getResetInitialAssessmentForms?.length +
						data?.data?.getResetWeeklyProgressForms?.length
				);
			}
		});
	};

	useEffect(() => {
		reloadReportNumbers();
	}, [currentUser]);

	const buildRows = () => {
		const numRows = resetHorses.length;
		const constructedRows = resetHorses.map((resetHorse, index) => {
			return (
				<HorseManagementDataRow
					horseData={resetHorse}
					key={index}
					index={index}
					isLastRow={numRows === index + 1}
					isMobile={isMobile}
					reload={reloadReportNumbers}
					program={RESET}
				/>
			);
		});
		setRows(constructedRows);
	};

	const sortRowsDown = (horseList) => {
		setCurrentSortDirection(1);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = resetHorses;
		}
		setResetHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horseName
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = (horseList) => {
		setCurrentSortDirection(2);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = resetHorses;
		}
		setResetHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horseName
				)
			)
		);
		buildRows();
	};

	const updateHorseList = (value) => {
		setselectedStatus(value);
		let tempList = Object.assign([], originalHorseList);
		let array = [];
		if (value.length > 0) {
			value.map((flist) => {
				let filter = flist.value;
				tempList.map((d) => {
					if (d?.currentStatus?.id === filter) {
						array.push(d);
					}
				});
			});
			if (currentSortDirection === 1) {
				sortRowsDown(array);
			} else if (currentSortDirection === 2) {
				sortRowsUp(array);
			} else {
				setResetHorses(array);
			}
		} else {
			if (currentSortDirection === 1) {
				sortRowsDown(tempList);
			} else if (currentSortDirection === 2) {
				sortRowsUp(tempList);
			} else {
				setResetHorses(tempList);
			}
		}
	};

	const SubmitInfoBar = () => {
		return (
			<InfoBar
				id={HorseManagementKeys.SUBMIT_REPORT}
				infoText={
					outstandingForms === 0
						? infoBarText
						: `You have ${outstandingForms} unsubmitted RESET form${
								outstandingForms > 1 ? 's' : ''
						  }. Please submit ${
								outstandingForms > 1 ? 'these forms' : 'this form'
						  } to the Equine Welfare Team.`
				}
				buttonText="Submit Report"
				buttonOnClick={() => submitReports()}
				noButton
				isDisabled={outstandingForms === 0}
				type={
					outstandingForms === 0
						? MessageBarTypeEnum.message
						: MessageBarTypeEnum.info
				}
			/>
		);
	};

	return (
		<div>
			<SubmitInfoBar />
			<HeaderBanner
				title={'RESET Thoroughbreds (' + resetHorses.length + ')'}
				top
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			{!isMobile && (
				<div className={classes.filterContainer}>
					<div className={classes.filterTitle}>Filter by</div>
					<div className={classes.filterComponentContainer}>
						<FormMultiSelectList
							name="filterStatus"
							listOptions={statusOptions}
							selectedIds={selectedStatus}
							onSelectChange={updateHorseList}
						/>
					</div>
				</div>
			)}
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
			<SubmitInfoBar />
		</div>
	);
};

export default RESETManagement;

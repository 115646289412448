export const REGEX = 'REGEX';
export const CHARACTER_LIMIT = 'CHARACTER_LIMIT';
export const MULTI_DEPENDENT = 'MULTI_DEPENDENT';
export const TOGGLE_DEPENDENT = 'TOGGLE_DEPENDENT';
export const DEPENDENT_DROPDOWN = 'DEPENDENT_DROPDOWN';
export const CHECKBOX_DEPENDENCY = 'CHECKBOX_DEPENDENCY';
export const MULTI_INPUT_FIELD_WITH_DROPDOWN =
	'MULTI_INPUT_FIELD_WITH_DROPDOWN';
export const MULTI_INPUT_FIELD = 'MULTI_INPUT_FIELD';
export const MIN_LENGTH = 'MIN_LENGTH';
export const MUST_BE_TRUE = 'MUST_BE_TRUE';
export const DEPENDENT_DROPDOWN_WITH_TOGGLE = 'DEPENDENT_DROPDOWN_WITH_TOGGLE';
export const DEPENDENT_DROPDOWN_NOT_EQUAL_WITH_TOGGLE =
	'DEPENDENT_DROPDOWN_NOT_EQUAL_WITH_TOGGLE';

export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (initial && values[f].conditions.length > 0) {
			values[f].conditions.map((c) => {
				if (c.condition === REGEX) {
					if (
						c.conditional &&
						values[c.conditional].value === c.conditionalValue
					) {
						let regex = new RegExp(c.regex);
						if (!regex.test(values[f].value)) {
							errors[f] = c.errorMessage;
						}
					}
				}

				if (c.condition === CHARACTER_LIMIT) {
					if (values[f].value.length > c.limit) {
						errors[f] = c.errorMessage;
					}
				}

				if (c.condition === TOGGLE_DEPENDENT) {
					if (
						String(values[c.field].value) === String(c.value) &&
						(values[f].value === null ||
							values[f].value === 0 ||
							values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === DEPENDENT_DROPDOWN) {
					let dependentValueId = c.dependantArray.filter(
						(rec) =>
							rec.value === c.value &&
							rec.id === parseInt(values[c.dependantField].value)
					);
					if (
						dependentValueId.length > 0 &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === DEPENDENT_DROPDOWN_WITH_TOGGLE) {
					let dependentValueId = c.dependantArray.filter(
						(rec) =>
							rec.id === c.value &&
							rec.id === parseInt(values[c.dependantField].value)
					);
					if (
						dependentValueId.length > 0 &&
						values[c.toggleField].value === c.toggleValue &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === DEPENDENT_DROPDOWN_NOT_EQUAL_WITH_TOGGLE) {
					if (
						values[c.dependantField].value !== c.value &&
						values[c.toggleField].value === c.toggleValue &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === MULTI_INPUT_FIELD_WITH_DROPDOWN) {
					let isError = false;
					values[f].value.map((v) => {
						if (v[c.field] === '' || v[c.field] === null) {
							isError = true;
						}
						if (parseInt(v[c.dependantField]) === 0) {
							isError = true;
						}
						let dependentValueId = c.dependantArray.filter(
							(rec) =>
								rec.value === c.value &&
								rec.id === parseInt(v[c.dependantField])
						);
						if (
							dependentValueId.length > 0 &&
							(v[c.conditionFieldIfVal] === null ||
								v[c.conditionFieldIfVal].length === 0)
						) {
							isError = true;
						}
						if (isError && values[c.dependsOn].value === c.dependsOnValue) {
							errors[f] = c.errorMessage;
						}
					});
				}
				if (c.condition === MULTI_DEPENDENT) {
					let dependentValueId = values[c.field].value.filter(
						(rec) => rec.label === c.value
					);
					if (
						dependentValueId.length > 0 &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === MULTI_INPUT_FIELD) {
					if (values[c.field].value === c.value) {
						const items = values[f].value;
						const allVals = items.map((x) => Object.values(x)).flat();
						if (Object.values(allVals).some((value) => value === '')) {
							errors[f] = c.errorMessage;
						}
					}
				}
				if (c.condition === MIN_LENGTH) {
					if (values[f].value.length < c.minLength) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === MUST_BE_TRUE) {
					if (values[f].value !== true) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === CHECKBOX_DEPENDENCY) {
					let conditionCount = c.dependantArray.filter(
						(rec) => values[rec].value === c.value
					).length;
					if (
						conditionCount === c.dependantArray.length &&
						values[f].value === false
					) {
						errors[f] = c.errorMessage;
					}
				}
			});
		}
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value.length === 0 ||
				values[f].value === false) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'Field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	topContainer: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		height: 560
	},
	caroContainer: {
		position: 'absolute',
		left: 0,
		right: 0,
		margin: 'auto',
		width: '100%'
	},
	active: {
		left: 0,
		right: 0,
		margin: 'auto',
		position: 'absolute',
		height: 520,
		zIndex: 1,
		borderRadius: 10,
		opacity: 1,
		transition: 'opacity 1000ms ease'
	},
	next: {
		right: 75,
		position: 'absolute',
		height: 490,
		top: 10,
		borderRadius: 10,
		opacity: 0.5
	},
	prev: {
		left: 75,
		position: 'absolute',
		height: 490,
		top: 10,
		borderRadius: 10,
		opacity: 0.5
	},
	hideImage: {
		display: 'none'
	},
	image: {
		width: '100%',
		height: '100%',
		borderRadius: 10
	},
	back: {
		position: 'absolute',
		left: 25,
		top: 215,
		zIndex: 1,
		cursor: 'pointer'
	},
	forward: {
		position: 'absolute',
		right: 25,
		top: 215,
		zIndex: 1,
		cursor: 'pointer'
	},
	textButtonContainer: {
		position: 'absolute',
		top: 132,
		color: 'white',
		left: 50,
		fontSize: 24,
		fontWeight: 600,
		width: 400,
		wordBreak: 'break-word'
	},
	accountButton: {
		background: '#006da6',
		width: 220,
		height: 40,
		display: 'block',
		lineHeight: '40px',
		textAlign: 'center',
		fontSize: 15,
		borderRadius: 5,
		marginTop: 20,
		cursor: 'pointer',
		color: 'white',
		textDecoration: 'none'
	},
	overlay: {
		backgroundImage: 'linear-gradient(to right, black, rgba(255,0,0,0))',
		position: 'absolute'
	},
	bannerText: {
		minHeight: 200,
		textShadow: '0 2px 4px rgba(0,0,0,0.50)'
	},
	mobileCaroContainer: {
		position: 'relative',
		overflow: 'hidden',
		height: 320,
		marginTop: 80,
		width: '100%',
		marginBottom: 10
	},
	mobileLeft: {
		width: 400,
		height: 185,
		position: 'absolute',
		zIndex: 1,
		left: -390,
		borderRadius: 5
	},
	mobileRight: {
		width: 400,
		height: 185,
		position: 'absolute',
		right: -390,
		borderRadius: 5
	},
	mobileActive: {
		width: '90%',
		height: 185,
		position: 'absolute',
		left: 0,
		margin: '0 auto',
		zIndex: 2,
		right: 0,
		borderRadius: 5
	},
	mobileBack: {
		position: 'absolute',
		left: 15,
		top: 70,
		zIndex: 3
	},
	mobileForward: {
		position: 'absolute',
		right: 15,
		top: 70,
		zIndex: 3
	},
	mobileButton: {
		height: 40
	},
	mobileTextBannerContainer: {
		width: '87%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 166,
		background: 'white',
		height: 130,
		borderRadius: 5
	},
	mobileText: {
		paddingLeft: 12,
		paddingRight: 12,
		fontSize: 16,
		fontWeight: 600,
		color: '#464749'
	},
	mobileButtonLink: {
		background: '#006da6',
		width: '90%',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		padding: 8,
		fontWeight: 600,
		fontSize: 15,
		borderRadius: 5,
		marginTop: 20,
		cursor: 'pointer',
		boxSizing: 'border-box',
		color: 'white',
		textDecoration: 'none',
		display: 'block'
	},
	mobileContentContainer: {
		padding: 10
	}
}));

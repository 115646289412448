import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  container: {
    width: 1000,
    marginBottom: 50,
    paddingLeft: "0 !important",
    paddingRight: "0 !important"
  },
  mobileContainer: {
    width: "100%",
    marginBottom: 50,
    paddingLeft: "0 !important",
    paddingRight: "0 !important"
  },
  desktopPageContainer: {
    maxWidth: "1000px !important",
    marginBottom: 150,
  },
  mobileTopContainer: {
    maxWidth: "100%",
    background: "white",
    margin: "80px 20px 40px 20px"
  },
  topContainer: {
    maxWidth: "100%",
    background: "white",
  },
  text: {
    width: "100%",
    fontSize: 14,
    marginBottom: 10
  },
  textMobile: {
    width: "100%",
    fontSize: 12,
    marginBottom: 10
  },
  buttonText: {
    display: "block",
    color: "inherit",
    textDecoration: "inherit",
  },
  messageBar: {
    display: "flex",
    justifyContent: "space-between"
  },
  claimText: {
    marginTop: 16
  }
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	userSearchHeader: {
		backgroundColor: '#ededed',
		width: '100%',
		boxSizing: 'border-box',
		borderTopRightRadius: 3,
		borderTopLeftRadius: 3
	},
	userSearchTitle: {
		float: 'left',
		margin: '18px 0px 18px 20px',
		fontSize: 20,
		fontWeight: 600,
		maxWidth: '70%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	searchInputContainer: {
		width: '100%',
		backgroundColor: '#dcebf3',
		boxSizing: 'border-box'
	},
	searchResultsHeaderContainer: {
		width: '100%',
		backgroundColor: '#ededed',
		fontSize: 13,
		fontWeight: 'bold',
		boxSizing: 'border-box',
		padding: '5px 10px 5px 20px',
		display: 'flex'
	},
	searchResultRow: {
		width: '100%',
		boxSizing: 'border-box',
		display: 'flex',
		fontSize: 15,
		borderBottom: '1px solid #d9dde0',
		cursor: 'pointer'
	},
	searchResultsRowContainer: {
		width: '100%',
		border: '1px solid #d9dde0',
		'& > div > div:last-child': {
			borderBottom: 'none'
		}
	},
	scrollableContainer: {
		width: '100%',
		maxHeight: 300,
		overflowY: 'scroll'
	},
	nonResultContentContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: 200
	}
}));

import React from 'react';
import FormToolTip from '../FormToolTip';
import { useScreenClass } from 'react-grid-system';
import { MOBILE_SCREEN_SIZES } from '../../utils/constants';

const MainPhotoUploadToolTip = ({ children, mobilePositionStyles }) => {
	const screenSize = useScreenClass();
	const isMobile = MOBILE_SCREEN_SIZES.includes(screenSize);
	return (
		<FormToolTip
			toolTipContainerStyle={{
				fontWeight: 'normal',
				left: 40,
				width: 290,
				padding: 15,
				...(isMobile && mobilePositionStyles)
			}}
		>
			{children}
		</FormToolTip>
	);
};

export default MainPhotoUploadToolTip;

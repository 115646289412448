import React, { useState, useEffect } from 'react';
import { DEFAULT_STATUS_FILTER, FORM_STATUSES } from '../../constants';
import {
	convertToMultiListData,
	formatDateReadableVariant,
	checkIsSmallScreen
} from '../../../../utils/helpers';
import AppSyncService from '../../../../graphql/AppSyncService';
import { useScreenClass } from 'react-grid-system';
import {
	ColumnContent,
	ResponsiveText,
	ResultHeading,
	ResultRow,
	ReviewButton
} from '../ResultComponents';
import { navigate } from 'gatsby';
import FormReviewList from '../FormReviewList';
import {
	ReviewListKeys,
	ACK_RETIREMENT_FARM,
	BusinessProfileType,
	ACK_RETRAINER_TEXT
} from '../../../../utils/constants';
import {
	countTotalFilteredAcknowledgedProgramApplications,
	filterAcknowledgedProgramApplications
} from '../../../../graphql/custom.queries';
import {
	RETIREMENT,
	RETRAINER
} from '../../../RetrainerProfileTemplate/constants';

const AcknowledgedRetrainerFormsReview = () => {
	const [acknowledgedRetrainerForms, setAcknowledgedRetrainerForms] = useState(
		[]
	);
	const [totalAckRetrainerForms, setTotalAcknowledgedRetrainerForms] = useState(
		0
	);
	const [selectedStatusFilters, setSelectedStatusFilters] = useState(
		DEFAULT_STATUS_FILTER
	);
	const [
		selectedStatusFiltersByName,
		setSelectedStatusFiltersByName
	] = useState([]);
	const [paginationIndex, setPaginationIndex] = useState(0);

	const fetchMoreAckRetrainerForms = () => {
		const filterStatuses = selectedStatusFilters.map(({ label }) => label);
		AppSyncService.execute(filterAcknowledgedProgramApplications, {
			filterStatuses,
			paginationIndex,
			paginationSize: 50
		}).then((result) => {
			const filteredAckRetrainerForms =
				result?.data?.getAcknowledgedReTrainerForms;
			const filteredAckRetirementForms =
				result?.data?.getAcknowledgedRetirementForms;
			if (filteredAckRetrainerForms) {
				setAcknowledgedRetrainerForms((prevState) => [
					...prevState,
					...filteredAckRetrainerForms.map((item) => ({
						...item,
						formType: RETRAINER
					}))
				]);
				setPaginationIndex((prevState) => ++prevState);
			}
			if (filteredAckRetirementForms) {
				setAcknowledgedRetrainerForms((prevState) => [
					...prevState,
					...filteredAckRetirementForms.map((item) => ({
						...item,
						formType: RETIREMENT
					}))
				]);
				setPaginationIndex((prevState) => ++prevState);
			}
		});
	};
	const headings = [
		'Name',
		'Form',
		'Date Submitted',
		'Review Status',
		'Status',
		''
	];
	const screenSize = useScreenClass();
	const mobile = checkIsSmallScreen(screenSize);

	useEffect(() => {
		const filterStatuses = selectedStatusFilters.map(({ label }) => label);
		const businessProfileTypeId = selectedStatusFiltersByName.map(
			({ value }) => value
		);
		const hasFilterByRetrainer =
			businessProfileTypeId.length === 0 ||
			businessProfileTypeId.some(
				(selectedBusinessProfileType) => selectedBusinessProfileType === 1
			);
		const hasFilterByRetirement =
			businessProfileTypeId.length === 0 ||
			businessProfileTypeId.some(
				(selectedBusinessProfileType) => selectedBusinessProfileType === 2
			);

		AppSyncService.execute(filterAcknowledgedProgramApplications, {
			filterStatuses,
			paginationIndex: 0,
			paginationSize: 50
		}).then((result) => {
			const filteredAcknowledgedRetrainerForms =
				result?.data?.getAcknowledgedReTrainerForms;
			const filteredAcknowledgedRetirementForms =
				result?.data?.getAcknowledgedRetirementForms;

			let ackRetirementFormsTemp = [];

			if (filteredAcknowledgedRetrainerForms && hasFilterByRetrainer) {
				ackRetirementFormsTemp = ackRetirementFormsTemp.concat(
					filteredAcknowledgedRetrainerForms.map((item) => ({
						...item,
						formType: RETRAINER,
						formText: ACK_RETRAINER_TEXT,
						sortDate: item.lastSubmitted || item.last_updated
					}))
				);
				setPaginationIndex(1);
			}
			if (filteredAcknowledgedRetirementForms && hasFilterByRetirement) {
				ackRetirementFormsTemp = ackRetirementFormsTemp.concat(
					filteredAcknowledgedRetirementForms.map((item) => ({
						...item,
						formType: RETIREMENT,
						formText: ACK_RETIREMENT_FARM,
						sortDate: item.lastSubmitted || item.last_updated
					}))
				);
				setPaginationIndex(1);
			}
			ackRetirementFormsTemp = ackRetirementFormsTemp.sort((a, b) => {
				return a.sortDate > b.sortDate ? -1 : 1;
			});
			setAcknowledgedRetrainerForms(ackRetirementFormsTemp);
		});
		AppSyncService.execute(countTotalFilteredAcknowledgedProgramApplications, {
			filterStatuses
		}).then((result) => {
			let totalAckRetrainerFormsTemp = 0;
			if (
				result?.data?.countAcknowledgedRetrainerForms != null &&
				hasFilterByRetrainer
			) {
				const {
					data: { countAcknowledgedRetrainerForms }
				} = result;
				totalAckRetrainerFormsTemp =
					totalAckRetrainerFormsTemp + countAcknowledgedRetrainerForms;
			}
			if (
				result?.data?.countAcknowledgedRetirementForms != null &&
				hasFilterByRetirement
			) {
				const {
					data: { countAcknowledgedRetirementForms }
				} = result;
				totalAckRetrainerFormsTemp =
					totalAckRetrainerFormsTemp + countAcknowledgedRetirementForms;
			}
			setTotalAcknowledgedRetrainerForms(totalAckRetrainerFormsTemp);
		});
	}, [selectedStatusFilters, selectedStatusFiltersByName]);

	const RowMobile = ({ form, isHeading }) => {
		return !isHeading ? (
			<ResultRow key={`acknowledged-retrainer-form-${form.id}`}>
				<ColumnContent style={{ width: '20%' }}>
					<ResponsiveText
						bolder
					>{`${form.member.firstName} ${form.member.lastName}`}</ResponsiveText>
				</ColumnContent>
				<ColumnContent
					style={mobile ? { paddingLeft: 0, minWidth: 75 } : { maxWidth: 200 }}
				>
					{' '}
					<ResponsiveText>{form.formText}</ResponsiveText>
				</ColumnContent>
				<ColumnContent
					style={mobile ? { paddingLeft: 0, minWidth: 75 } : { maxWidth: 200 }}
				>
					{form.lastSubmitted
						? formatDateReadableVariant(form.lastSubmitted)
						: ''}
				</ColumnContent>
				<ColumnContent
					style={mobile ? { paddingLeft: 10, minWidth: 75 } : { maxWidth: 200 }}
				>
					{form.ewgmAdminStatusId === 1 && form.formType === RETIREMENT ? (
						<ResponsiveText>Contacting User</ResponsiveText>
					) : form.ewgmAdminStatusValue ? (
						<ResponsiveText>{form.ewgmAdminStatusValue}</ResponsiveText>
					) : (
						''
					)}
				</ColumnContent>
				<ColumnContent>{form.status}</ColumnContent>
				<ReviewButton
					isAckFormsReview={true}
					onClickHandler={() =>
						navigate(
							form.formType == RETRAINER
								? `/acknowledged-retrainer-form-review/${form.id}`
								: `/acknowledged-retirement-form-review/${form.id}`
						)
					}
				/>
			</ResultRow>
		) : null;
	};

	const Row = ({ form, isHeading, headings }) => {
		return !isHeading ? (
			<ResultRow key={`acknowledged-retrainer-form-${form.id}`}>
				<ColumnContent style={{ width: '20%' }}>
					<ResponsiveText
						bolder
					>{`${form.member.firstName} ${form.member.lastName}`}</ResponsiveText>
				</ColumnContent>
				<ColumnContent style={{ width: '18%' }}>{form.formText}</ColumnContent>
				<ColumnContent style={{ width: '18%' }}>
					{form.lastSubmitted
						? formatDateReadableVariant(form.lastSubmitted)
						: ''}
				</ColumnContent>
				<ColumnContent style={{ width: '18%' }}>
					{form.ewgmAdminStatusId === 1 && form.formType === RETIREMENT
						? 'Contacting User'
						: form.ewgmAdminStatusValue}
				</ColumnContent>
				<ColumnContent style={{ width: '18%' }}>{form.status}</ColumnContent>
				<ReviewButton
					isAckFormsReview={true}
					onClickHandler={() =>
						navigate(
							form.formType == RETRAINER
								? `/acknowledged-retrainer-form-review/${form.id}`
								: `/acknowledged-retirement-form-review/${form.id}`
						)
					}
				/>
			</ResultRow>
		) : (
			<ResultHeading key={`acknowledged-retrainer-form-table-header`}>
				{headings.map((heading, index) => (
					<ColumnContent
						key={`acknowledged-retrainer-form-heading-${index}`}
						style={heading === 'Name' ? { width: '23%' } : { width: '18%' }}
					>
						{heading}
					</ColumnContent>
				))}
			</ResultHeading>
		);
	};

	return (
		<FormReviewList
			sectionTitle={`Acknowledged Program Applications (${totalAckRetrainerForms})`}
			selectableFilters={convertToMultiListData(FORM_STATUSES, 'value')}
			selectedFilters={selectedStatusFilters}
			onFiltersChange={(value) => setSelectedStatusFilters(value)}
			selectableFiltersByName={convertToMultiListData(
				BusinessProfileType.filter((item) => item.id !== 3),
				'value'
			)}
			selectedFiltersByName={selectedStatusFiltersByName}
			onFiltersChangeByName={(value) => setSelectedStatusFiltersByName(value)}
			totalResults={totalAckRetrainerForms}
			results={acknowledgedRetrainerForms}
			fetchData={fetchMoreAckRetrainerForms}
			sectionId={ReviewListKeys.ACKNOWLEDGED_RETRAINER_REQUESTS_FORMS}
			Row={mobile ? RowMobile : Row}
			headings={headings}
			isAckFormsReview={true}
		/>
	);
};

export default AcknowledgedRetrainerFormsReview;

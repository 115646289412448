export const generateDeceasedInputPayload = (adminData) => {
	const filteredViceRetirement = adminData.selectedVicesRetirement.map(
		(selectedViceRetirement) => selectedViceRetirement.value
	);
	const filteredEducation = adminData.selectedEducation.map(
		(selectedEduc) => selectedEduc.value
	);
	const filteredInjuryRetirement = adminData.selectedInjuriesRetirement.map(
		(selectedInjuryRetirement) => selectedInjuryRetirement.value
	);
	const filteredIllnessRetirement = adminData.selectedIllnessRetirement.map(
		(selectedIllnessRetirement) => selectedIllnessRetirement.value
	);
	const filteredReasonRetirement = adminData.selectedReason.map(
		(selectedReason) => selectedReason.value
	);

	const deceasedInput = {
		retirementCategory: adminData.selectedCategory,
		reasonRetirement: filteredReasonRetirement,
		habitsRetirement: filteredViceRetirement,
		behaviourOthersRetirement:
			adminData.otherVicesRetirement.length === 0
				? ''
				: adminData.otherVicesRetirement.map((item) => item.value).toString(),
		conformationalDetailsRetirement: adminData.conformationalDefectsRetirement,
		education: filteredEducation,
		otherEducationDetails: adminData.otherEducationDetails,
		injuriesRetirement: filteredInjuryRetirement,
		otherInjuryDetailsRetirement: adminData.otherInjuryDetails,
		illnessRetirement: filteredIllnessRetirement,
		otherIllnessDetailsRetirement: adminData.otherIllnessDetails,
		rehomingHistoryDetails: adminData.rehomingHistoryDetails,
		otherDetailsRetirement: adminData.otherDetailsRetirement,
		vulnerabilityType: adminData.selectedVulnerabilityType
	};

	return deceasedInput;
};

import React from 'react';
import useStyles from './styles';

const FormTextArea = ({
	onChange,
	styles,
	value,
	rows,
	cols,
	id,
	maxLength,
	disabled
}) => {
	const classes = useStyles();
	return (
		<textarea
			id={id}
			rows={rows}
			cols={cols}
			style={styles}
			className={classes.fieldEntry}
			onChange={onChange}
			value={value ? value : ''}
			maxLength={maxLength ? maxLength : ''}
			ng-trim="false"
			disabled={disabled}
		></textarea>
	);
};

export default FormTextArea;

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatToggleValue } from '../../../../utils/helpers';

const RehomingHistorySection = ({ formData }) => {
	return (
		<AdminReviewSection title="Rehoming History">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Number of OTT horses retrained and rehomed in the past 12 months"
						value={formData?.numberOfHorses?.value}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Detail of OTT horses rehomed in the past 12 months (race name, retraining undertaken, new career/purpose)"
						value={formData?.rehomedHorsesDetails}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please list the race names (or breeding if not named) of all the OTT horses you have successfully retrained and/or rehomed"
						value={formData?.raceNames}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please confirm that you have the capacity to retrain and/or rehome at least two off the track thoroughbreds per calendar year"
						value={formatToggleValue(formData?.confirmCapacityToRetrain)}
						largeScreenWidth={12}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default RehomingHistorySection;

export const OHEP_FORM_DEFAULT_VALUES = {
	contactNumber: '',
	relationshipToHorse: 0,
	relationshipToHorseOther: '',
	preferredContactType: 0,
	contactDetails: '',
	horseName: '',
	microchip: '',
	sire: '',
	dam: '',
	horseGender: 0,
	age: '',
	eligibilityCriteria: 0,
	leftSidePhotos: [],
	rightSidePhotos: [],
	currentAddress: '',
	currentSuburb: '',
	currentPostcode: '',
	currentState: '',
	currentCountry: '',
	currentAddressValidated: false,
	mandatoryErrors: false
};

export const AT_LEAST_ONE_ERROR_MESSAGE =
	'Please ensure at least 2 fields filled out of (Microchip, Sire, Dam)';

export const MICROCHIP_LENGTH_ERROR_MESSAGE =
	'Microchip number must be 18 digits';

export const AGE_LENGTH_ERROR_MESSAGE = 'Age must be less than or equal to 40 ';

import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';

const EventsLandingPage = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [richText, setRichText] = useState(null);

	const renderCollectionOfVideos = (isMobile) => {
		const UniqueVideoByUrl = [
			...new Map(videoCollection.map((item) => [item['src'], item])).values()
		];
		return UniqueVideoByUrl.map((video, index) => {
			return (
				<div
					key={index}
					className={
						isMobile ? classes.mobileVideoContainer : classes.videoContainer
					}
				>
					<div className={classes.videoTitle}>{video.title}</div>
					<FullArticleEmbeddedAsset
						contentType={video.type}
						contentSrc={video.src}
					/>
				</div>
			);
		});
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				let video = [];
				video = {
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				};
				videoCollection.push(video);
			}
		}
	};

	useEffect(() => {
		setRichText(
			eventLandingPage.allContentfulOttEventsLandingPage.nodes[0].body
		);
	}, []);

	const eventLandingPage = useStaticQuery(
		graphql`
			query eventLandingPage {
				allContentfulOttEventsLandingPage {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
								}
								title
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "OTT Events Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		const ottEventsInfoCards = eventLandingPage.allContentfulInfoCards.nodes;
		let eventInfoCards = [];
		if (ottEventsInfoCards.length > 0) {
			ottEventsInfoCards.map((d) => {
				eventInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			eventInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(eventInfoCards);
		}
	}, []);

	const eventInformation = (isMobile) => {
		return (
			<>
				<SEO title="OTT Events and Sponsorship" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="OTT Events and Sponsorship"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<Row>
					<Col lg={8}>{richText && renderRichText(richText, options)}</Col>
				</Row>
				<Row>
					<Col>
						<div className={classes.multipleVideos}>
							{renderCollectionOfVideos(isMobile)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col style={{ marginTop: 20 }}>
						<MessageBar>
							<div
								className={classes.messageBar}
								style={isMobile ? { display: 'block' } : {}}
							>
								<div
									className={classes.claimText}
									style={
										isMobile
											? { fontSize: 13, marginBottom: 10, marginTop: 0 }
											: {}
									}
								>
									Want to view upcoming OTT Sponsored Events and Competitions?
								</div>
								<PrimaryButton
									style={
										isMobile
											? {
													marginTop: 0,
													width: '100%',
													height: 50,
													fontWeight: 600
											  }
											: {
													marginTop: 0,
													width: 320,
													height: 50,
													fontWeight: 600
											  }
									}
									onClick={() => {
										navigate('/events');
									}}
								>
									View Events Calendar
								</PrimaryButton>
							</div>
						</MessageBar>
					</Col>
				</Row>
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>{eventInformation(true)}</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{eventInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default EventsLandingPage;

export const mapArrayValues = (arr, referenceArray) => {
	let newArray = [...arr];
	if (newArray.length > 0) {
		newArray = newArray.map((newArrayItem) => {
			const mappedItem = referenceArray.find(
				(referenceItem) => referenceItem.id === newArrayItem.id
			);
			if (mappedItem) {
				return {
					id: mappedItem.id,
					value: mappedItem.value ?? ''
				};
			} else {
				return newArrayItem;
			}
		});
	}
	return newArray;
};

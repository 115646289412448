import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	button: {
		boxSizing: 'border-box',
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		padding: '10px 10px',
		fontWeight: 600,
		width: '100%',
		fontSize: 16,
		cursor: 'pointer',
		'&:focus': {
			outline: 'none'
		}
	},
	disabledButton: {
		backgroundColor: '#d8d8d8',
		color: '#b2b2b2',
		boxSizing: 'border-box',
		border: 'none',
		borderRadius: 4,
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		padding: '10px 10px',
		fontWeight: 600,
		width: '100%',
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	}
}));

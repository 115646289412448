import React from 'react';
import { Row } from 'react-grid-system';
import useStyles from './styles';

const AdminReviewHeader = ({ title, status }) => {
	const classes = useStyles();
	return (
		<Row className={classes.header}>
			<div className={classes.contentContainer}>
				<div>{title}</div>
				<div>
					<span className={classes.statusLabel}>Status: </span>
					<span>{status}</span>
				</div>
			</div>
		</Row>
	);
};

export default AdminReviewHeader;

import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';

const BusinessProfileFosterView = ({ businessProfileData }) => {
	const classes = useStyles();

	return (
		<>
			<HeaderBanner
				title="Business Details"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<FormLabelField
							title="I am a"
							value={businessProfileData?.businessProfileType?.value}
						/>
					</Col>
					<Col></Col>
					<Col></Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Business Name*"
							value={businessProfileData?.businessName}
						/>
					</Col>
					<Col></Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col>
						<FormLabelField
							title="First Name"
							value={businessProfileData?.firstName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Last Name"
							value={businessProfileData?.lastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Business Phone*"
							value={businessProfileData?.businessPhone}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Business Email*"
							value={businessProfileData?.email}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Alternative Contact"
							value={businessProfileData?.alternativeContact}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Alternative Phone"
							value={businessProfileData?.alternativePhone}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col>
						<FormLabelField
							title="Street Address*"
							value={businessProfileData?.streetAddress}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Suburb*"
							value={businessProfileData?.suburb}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Postcode*"
							value={businessProfileData?.postcode}
						/>
					</Col>
					<Col>
						<FormLabelField title="State*" value={businessProfileData?.state} />
					</Col>
				</Row>
			</div>
		</>
	);
};
export default BusinessProfileFosterView;

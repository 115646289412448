import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	dashboardDesktopPageContainer: {
		maxWidth: '1000px !important',
		marginTop: 20,
		marginBottom: 40
	},
	articleMobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	image: {
		objectFit: 'cover',
		width: '100%'
	},
	articleTitle: {
		textAlign: 'left',
		fontWeight: '600',
		marginBottom: 20
	},
	date: { fontSize: 12, color: '#9a9a9a', marginTop: 5 },
	articleText: {
		fontSize: 13,
		marginTop: 15
	},
	hr: {
		marginBottom: 40,
		marginTop: 50,
		background: '#dcebf3',
		height: 1,
		border: 'none'
	}
}));

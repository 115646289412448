import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import {
	formatToggleValue,
	formatDateReadableVariant
} from '../../../../utils/helpers';
import UploadPhoto from '../../../UploadPhoto';

const InsuranceDetailsSection = ({ formData, previewImage }) => {
	const workersCompensation =
		formData?.workersCompensationCoverage ||
		formData?.workersCompensationExpiryDate ||
		formData?.workersCompensationPolicyNumber;

	const isInsurancesUploadHasNoDeleted = formData?.insuranceCertificateOfCurrencyUploads.some(
		(detail) => !detail.isDeleted
	);
	return (
		<AdminReviewSection title="Insurance Details">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Public liability policy number"
						value={formData?.liabilityPolicyNumber}
					/>
					<AdminReviewDatum
						label="Public liability expiry date"
						value={formatDateReadableVariant(formData?.liabilityExpiryDate)}
					/>
					<AdminReviewDatum
						label="Public liability coverage detail"
						value={formData?.liabilityCoverage}
					/>
					<AdminReviewDatum
						label="Insurer Name"
						value={formData?.publicLiabilityInsurerName}
					/>
					<AdminReviewDatum
						label="Name of Insured Party or Parties"
						value={formData?.publicLiabilityInsuredPartyName}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Workers compensation"
						value={formatToggleValue(workersCompensation)}
					/>
				</SectionRow>
				{workersCompensation && (
					<>
						<SectionRow>
							<AdminReviewDatum
								label="Name of Insured Party or Parties"
								value={formData?.workersCompensationInsuredPartyName}
							/>
							<AdminReviewDatum
								label="Workers Compensation policy number"
								value={formData?.workersCompensationPolicyNumber}
							/>
							<AdminReviewDatum
								label="Workers Compensation expiry date"
								value={formatDateReadableVariant(
									formData?.workersCompensationExpiryDate
								)}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Workers Compensation coverage detail"
								value={formData?.workersCompensationCoverage}
								largeScreenWidth={12}
							/>
						</SectionRow>
					</>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="Professional Indemnity"
						value={formatToggleValue(formData?.professionalIndemnity)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{!!formData?.professionalIndemnity && (
					<>
						<SectionRow>
							<AdminReviewDatum
								label="Insurer Name"
								value={formData?.indemnityInsurerName}
							/>
							<AdminReviewDatum
								label="Name of Insured Party or Parties"
								value={formData?.indemnityInsuredPartyName}
							/>
						</SectionRow>

						<SectionRow>
							<AdminReviewDatum
								label="Professional Indemnity policy number"
								value={formData?.indemnityPolicyNumber}
							/>
							<AdminReviewDatum
								label="Professional Indemnity expiry date"
								value={formatDateReadableVariant(formData?.indemnityExpiryDate)}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Professional Indemnity coverage detail"
								value={formData?.indemnityCoverage}
								largeScreenWidth={12}
							/>
						</SectionRow>
					</>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="Upload insurance certificate/s of currency (jpg, png, pdf)"
						value={
							isInsurancesUploadHasNoDeleted ? (
								<UploadPhoto
									data={formData?.insuranceCertificateOfCurrencyUploads.filter(
										(detail) => !detail.isDeleted
									)}
									getImage={previewImage}
									canEdit={false}
								/>
							) : (
								formData?.insuranceCertificateOfCurrencyUploads
									.filter((detail) => detail.isDeleted)
									.map((detail, index) => (
										<div key={index}>
											Insurance certificate, sighted by{' '}
											{detail.sightedBy?.firstName} {detail.sightedBy?.lastName}{' '}
											{formatDateReadableVariant(detail.dateApprovedRejected)} -{' '}
											{detail.status}
										</div>
									))
							)
						}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default InsuranceDetailsSection;

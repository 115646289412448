import React, { useState, useEffect } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { getHorsePlacementFormById } from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import AvailableForRetrainReviewInfo from '../AvailableForRetrainReviewInfo';
import { addNameToUnnamedHorse, parseUTCToLocal } from '../../utils/helpers';

const AvailableForRetrainReview = () => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getHorsePlacementFormById, { id }).then((result) => {
			if (result?.data?.getHorsePlacementFormById) {
				setData(result.data.getHorsePlacementFormById);
				setIsLoading(false);
			} else {
				setIsLoading(false);
			}
		});
	}, []);

	return (
		<Container
			className={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? classes.pageContainerMobile
					: classes.pageContainer
			}
		>
			<SEO title="Available for Retraining Review" />
			<HeaderBanner
				title={`Available for Retraining - ${addNameToUnnamedHorse(
					data?.horse?.name
				)}`}
				top
				mobile={['xs', 'sm'].includes(screenClassForRender)}
				type="primary"
				rightTitle={`Status: ${data?.status}`}
				styles={{ borderRadius: '5px 5px 5px 5px', fontSize: 13 }}
				onBehalfText={
					data?.behalfAdmin?.firstName && data?.behalfAdmin?.lastName ? (
						<span style={{ fontSize: 13, color: '#006da6' }}>
							(submitted on-behalf of user by{' '}
							{`${data?.behalfAdmin?.firstName} ${data?.behalfAdmin?.lastName}`}
							)
						</span>
					) : null
				}
				dateText={
					data?.lastUpdated ? (
						<span style={{ fontSize: 13 }}>
							Date Submitted:{' '}
							{parseUTCToLocal(data.lastUpdated).format('DD/MM/YYYY')}
						</span>
					) : null
				}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
			</div>
			{data != null && (
				<AvailableForRetrainReviewInfo
					data={data}
					mobile={['xs', 'sm'].includes(screenClassForRender)}
				/>
			)}
		</Container>
	);
};
export default AvailableForRetrainReview;

import React from 'react';
import ConfirmationModal from '../../ConfirmationModal';

const modalStylesConfirmation = {
	content: {
		minWidth: 300,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const FormCancelConfirmModal = ({
	showCancelConfirmModal,
	onCloseHandler,
	onConfirmHandler,
	modalTitle
}) => {
	return (
		<ConfirmationModal
			showModal={showCancelConfirmModal}
			closeModal={onCloseHandler}
			styles={modalStylesConfirmation}
			heading={modalTitle}
			positiveLabel="Confirm"
			negativeLabel="Cancel"
			onClickPositive={onConfirmHandler}
		>
			Are you sure you wish to delete this form?
		</ConfirmationModal>
	);
};

export default FormCancelConfirmModal;

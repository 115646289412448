import React from 'react';
import useStyles from './styles';

const ListItem = ({ item, onClick, render }) => {
	const classes = useStyles();
	return (
		<div className={classes.dropDownList} onClick={() => onClick(item)}>
			<div
				className={`${classes.listItem} ${
					item.selected ? classes.selected : ''
				}`}
			>
				{render(item)}
			</div>
			<hr className={classes.hrLine}></hr>
		</div>
	);
};

const List = ({ listItems, itemRender, onItemClick, isInTable = false }) => {
	const classes = useStyles();
	return (
		<div
			className={
				!isInTable ? classes.listContainer : classes.listContainerOnTable
			}
		>
			<div
				role="list"
				className={!isInTable ? classes.list : classes.listOnTable}
			>
				{listItems.map((item) => (
					<ListItem
						key={item.id}
						item={item}
						render={itemRender}
						onClick={onItemClick}
					/>
				))}
			</div>
		</div>
	);
};

List.ListItem = ListItem;

export default List;

import React from 'react';
import useStyles from './styles.js';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import InfoBar from '../InfoBar/index.js';
import { MessageBarTypeEnum } from '../../utils/enums/index.js';
import { navigate } from 'gatsby-link';

const PAGE_ID = 'Contact-Us-Outcome';

const ContactUsOutcome = () => {
	const classes = useStyles();

	return (
		<PageContainer>
			<SEO title="Feedback or Enquiry" />
			<HeaderBanner
				title="Feedback or Enquiry"
				styles={{ marginBottom: '0px' }}
			/>
			<div className={classes.container}>
				<InfoBar
					type={MessageBarTypeEnum.info}
					noButton
					id={`${PAGE_ID}-infobar`}
					style={{ borderRadius: 5 }}
				>
					<div>
						Thank you for contacting us, our team will be in touch within 2
						business days.
						<br />
						If the matter is urgent, please phone the Equine Welfare hotline on
						03 9258 4258. <br />
						Complete another{' '}
						<a className={classes.link} onClick={() => navigate('/contact-us')}>
							Contact Us
						</a>{' '}
						form if you need to contact us about something else.
					</div>
				</InfoBar>
			</div>
		</PageContainer>
	);
};

export default ContactUsOutcome;

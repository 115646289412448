import { formatDate } from '../../utils/helpers';

export const DEFAULT_FORM_VALUES = {
	id: null,
	organisation: null,
	representativeName: '',
	role: '',
	contactDetails: '',
	reportDate: formatDate(new Date()),
	effectiveDateAgreement: null,
	conclusionDateAgreement: null,
	totalValueAgreement: null,
	utilizationOfOttSponsorship: [],
	utilizationOfOttSponsorshipOther: '',
	numberOfEventsIncluded: '0',
	totalOttClassesRun: null,
	overallEntrantsNumber: null,
	ottEntrantsNumber: null,
	discipline: [],
	disciplineOther: '',
	isPrizeMoneyDistributed: true,
	amountOfPrizeMoneyDistributed: null,
	numberOfFacebookEventPosts: null,
	numberOfInstagramEventPosts: null,
	otherSocialMediaUpdates: '',
	numberOfFacebookPostShare: null,
	numberOfInstagramPostShare: null,
	otherComments: '',
	signageDescription: '',
	whatWorkedWellQuestion: '',
	whatDidNotWorkQuestion: '',
	howToImproveQuestion: '',
	keyAchievements: '',
	feedbackFromMembers: '',
	isAnyPathwaysInititiativesCreated: true,
	competitiveOrNonCompetitive: [],
	numberOfNewPathways: null,
	isIncreasedUpstakeOfThoroughbreds: true,
	numberOfNewOtts: null,
	commentsOrSuggestions: '',
	signageOrPromotionPhotos: [],
	eventPhotos: [],
	status: '',
	created: '',
	lastUpdated: '',
	lastSubmitted: ''
};

export const ottSponsorshipOptions = [
	{ label: 'Prizemoney', value: 1 },
	{ label: 'Promotion of event', value: 2 },
	{ label: 'Prizes', value: 3 },
	{ label: 'Other', value: 4 }
];

export const numberOfEventsOptions = [
	{ value: 'None', id: 0 },
	{ value: '1-5', id: 1 },
	{ value: '6-10', id: 2 },
	{ value: '11-20', id: 3 },
	{ value: '21-30', id: 4 },
	{ value: '31-50', id: 5 },
	{ value: '51+', id: 6 }
];

export const competitiveOrNonOptions = [
	{ label: 'Competitive', value: 1 },
	{ label: 'Non-competitive', value: 2 }
];

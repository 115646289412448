import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper';
import Toggle from '../../Toggle';

const ToggleInputField = ({
	fieldLabel,
	isRequired,
	onChange,
	value,
	disabled
}) => {
	return (
		<FormFieldWrapper fieldLabel={fieldLabel} isRequired={isRequired}>
			<Toggle
				labelNegative="No"
				labelPositive="Yes"
				onClick={onChange}
				value={value}
				disabled={disabled}
			/>
		</FormFieldWrapper>
	);
};

export default ToggleInputField;

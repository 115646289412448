import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import MessageBar from '../MessageBar';
import { THOROUGHBRED } from '../../utils/constants';
import { TopContent } from '../Mobile';
import PrimaryButton from '../PrimaryButton';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';
import AppSyncService from '../../graphql/AppSyncService';
import FullCircleStats from '../FullCircleStats';
import { getFullCircleStats } from '../../graphql/custom.queries';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';

const FullCircle = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [stats, setStats] = useState([]);
	const [richText, setRichText] = useState(null);

	const fullCircleContent = useStaticQuery(
		graphql`
			query fullCircleLandingPageContent {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Full Circle Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
						isActive
						link
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Full Circle Info Cards" } }
				) {
					nodes {
						order
						title
						image {
							file {
								url
								fileName
							}
						}
						text {
							text
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		// Set data to state here?
		const fullCircleInfo = fullCircleContent.allContentfulInfoCards.nodes;
		let fullCircleInfoCards = [];
		if (fullCircleInfo.length > 0) {
			fullCircleInfo.map((d) => {
				fullCircleInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			fullCircleInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(fullCircleInfoCards);
		}

		AppSyncService.execute(getFullCircleStats).then((result) => {
			if (result?.data?.getFullCircleStats) {
				setStats([
					{
						description: 'Horses with at least one Full Circle contact',
						value: result?.data?.getFullCircleStats?.totalHorses,
						color: '#CC3094'
					},
					{
						description: 'Number of horses you can Full Circle',
						value: 'Unlimited',
						color: '#8B4C9C'
					},
					{
						description: 'Members who have Full Circle at least one horse',
						value: result?.data?.getFullCircleStats?.totalMembers,
						color: '#CC3094'
					},
					{
						description: 'Time taken to complete a Full Circle form',
						value: '30 seconds',
						color: '#8B4C9C'
					}
				]);
			}
		});
	}, []);

	useEffect(() => {
		setRichText(
			fullCircleContent.allContentfulLandingPageContent.nodes[0].body
		);
	}, []);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};

	const ottInformation = (isMobile) => {
		return (
			<>
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner title="Full Circle" styles={{ marginBottom: 0 }} />
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<MessageBar>
					<div
						className={classes.messageBar}
						style={isMobile ? { display: 'block' } : {}}
					>
						<div
							className={classes.claimText}
							style={
								isMobile ? { fontSize: 13, marginBottom: 10, marginTop: 0 } : {}
							}
						>
							Ready to get started with the Full Circle program?
						</div>
						<Link
							className={classes.buttonText}
							to={'/search'}
							state={{ searchSelected: true, search: THOROUGHBRED }}
						>
							<PrimaryButton
								style={
									isMobile
										? {
												marginTop: 0,
												width: '100%',
												height: 50,
												fontWeight: 600
										  }
										: {
												marginTop: 0,
												width: 320,
												height: 50,
												fontWeight: 600
										  }
								}
							>
								<div>Search for a Thoroughbred</div>
							</PrimaryButton>
						</Link>
					</div>
				</MessageBar>
				<Row nogutter>
					<Col lg={8} xs={12}>
						<TopContent
							textLeftStyle={{ fontSize: 18 }}
							styles={{ marginTop: 20, marginBottom: 20 }}
							leftText={
								fullCircleContent.allContentfulLandingPageContent.nodes[0].title
									.title
							}
						/>
						<div
							className={isMobile == true ? classes.textMobile : classes.text}
						>
							{richText && renderRichText(richText, options)}
						</div>
					</Col>
					<Col lg={4} xs={12}>
						<FullCircleStats stats={stats} mobile={isMobile} />
					</Col>
				</Row>
			</>
		);
	};

	const mobileComponent = (
		<>
			<div style={{ width: '100%' }}>
				<SEO title="Full Circle" />

				<div className={classes.mobileTopContainer}>{ottInformation(true)}</div>
			</div>
		</>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>
						<div style={{ width: '100%' }}>
							<SEO title="Full Circle" />

							<div className={classes.topContainer}>
								{ottInformation(false)}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default FullCircle;

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({

  filterBarContainer: {
    color: "#464749",
    backgroundColor:"#ebe8e8",
    padding: "10px 0px 10px 0px",
    width:"100%",
    height: 20,
    fontSize: 12,
  },
  filtersDiv:{
    float: "left",
    width: 275
  },
  clearAllFiltersSpan:{
    textDecoration: "underline",
    color:"blue",
    cursor: "pointer",
  },
  filerItem :{
    paddingRight : 10,
    fontWeight: 'bold',
  },
  filteredItemsDelete: {
    color: "#b3bcc1",
    marginLeft: 5,
    fontWeight: 'normal',
    cursor: "pointer",
  },
  filterContents: {
    display: "flex",
    flexWrap: "wrap"
  },
  filterContainer: {
    background: "#ededed",
    display: "flex",
    padding: 20,
    justifyContent: "space-between",
    borderRadius: 4
  },
  filters: {
    color: "#016ea6",
    fontWeight: 600,
    fontSize: 13,
    width: "100%",
    minHeight: 40,
    background: "#dcebf3"
  },
  filterContent: {
    padding: "10px 10px 10px 20px",
    display: "flex",
    flexWrap: "wrap"
  },
  serFilter: {
    width: "100%",
    marginRight: 15
  },
  specFilter: {
    width: "100%",
    marginLeft: 15
  },
  appliedFilter:{
    marginRight: 30,
    cursor: "default",
    position: "relative"
  },
  appliedFilterMobile:{
    marginRight: 10,
    cursor: "default",
    position: "relative"
  },
  appliedFilterCross:{
    cursor: "pointer",  
    float: "right",
    marginTop: 4,
    fontSize: 8,
    paddingLeft: 5
  },
}));

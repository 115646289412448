import React from 'react';
import useStyles from './styles';
import RDALogo from '../../assets/icons/RV_Partners/RDAV.jpg';
import BoneoParkLogo from '../../assets/icons/RV_Partners/Boneo_Park.jpg';
import EquestrionVictoriaLogo from '../../assets/icons/RV_Partners/EQUES_VIC_RGB.jpg';
import HRCAVLogo from '../../assets/icons/RV_Partners/HRCAV_Logo.jpg';
import PCVLogo from '../../assets/icons/RV_Partners/pc_vic_logo_rgb_text.jpg';
import RVLogo from '../../assets/icons/RV_Partners/RV_Masterbrand_RGB_Pos.jpg';
import SHALogo from '../../assets/icons/RV_Partners/SHA_Logo.png';
import SHCVLogo from '../../assets/icons/RV_Partners/SHC-logo-reverse.png';
import VASLLogo from '../../assets/icons/RV_Partners/VASL_Logo.jpg';
import VicPoloLogo from '../../assets/icons/RV_Partners/vicpolo-logo-bordered.png';
import MarcusOldhamLogo from '../../assets/icons/RV_Partners/MOPositiveLandLogo.jpg';
import EquinePathways from '../../assets/icons/RV_Partners/equine_pathways.png';
import Equitana from '../../assets/icons/RV_Partners/2024_EQMELB_Logo_Stacked_RGB.png';
import { Col, Container, Row } from 'react-grid-system';

const PartnersWidget = () => {
	const classes = useStyles();
	return (
		<Container>
			<Row>
				<Col xs={12} lg={4}>
					<div className={classes.logoSection}>
						<div className={classes.brandingHeader}>Industry Partners</div>
					</div>
					<div>
						<div className={classes.iconContainer}>
							<LinkImage
								imageSrc={RVLogo}
								destination="https://www.racingvictoria.com.au/"
							/>
						</div>
					</div>
				</Col>
				<Col xs={12} lg={4}>
					<div className={classes.logoSection}>
						<div className={classes.brandingHeader}>
							OTT Sponsorship & Event Partners
						</div>
						<div className={classes.iconContainer}>
							<LinkImage
								imageSrc={BoneoParkLogo}
								destination="https://boneopark.com.au/"
							/>
							<LinkImage
								imageSrc={EquestrionVictoriaLogo}
								destination="https://www.vic.equestrian.org.au/"
							/>
							<LinkImage
								imageSrc={Equitana}
								destination="https://www.equitana.com.au/"
							/>
							<LinkImage
								imageSrc={HRCAVLogo}
								destination="https://hrcav.com.au/"
							/>
							<LinkImage
								imageSrc={PCVLogo}
								destination="http://www.ponyclubvic.org.au/"
							/>
							<LinkImage
								imageSrc={SHALogo}
								destination="https://sportinghorseaustralia.org/"
							/>
							<LinkImage
								imageSrc={SHCVLogo}
								destination="https://shcv.com.au/"
							/>
							<LinkImage
								imageSrc={VASLLogo}
								destination="http://www.vicagshows.com.au/"
							/>
							<LinkImage
								imageSrc={VicPoloLogo}
								destination="https://www.vicpolo.com.au/"
							/>
						</div>
					</div>
				</Col>
				<Col xs={12} lg={4}>
					<div className={classes.logoSection}>
						<div className={classes.brandingHeader}>OTT Program Partners</div>
						<div className={classes.iconContainer}>
							<LinkImage
								imageSrc={EquinePathways}
								destination="https://equinepathways.org.au/"
							/>
							<LinkImage
								imageSrc={MarcusOldhamLogo}
								destination="https://marcusoldham.vic.edu.au/"
							/>
							<LinkImage
								imageSrc={RDALogo}
								destination="https://www.rdav.asn.au/"
							/>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

const LinkImage = ({ imageSrc, destination }) => {
	const classes = useStyles();
	return (
		<a href={destination} rel="noreferrer" target="_blank">
			<img
				className={classes.brandingLogo}
				src={imageSrc}
				style={{ padding: 2 }}
			></img>
		</a>
	);
};

export default PartnersWidget;

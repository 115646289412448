import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { Col } from 'react-grid-system';
import useStyles from './styles';
import { QuickLinks } from '../Mobile';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getAllHorseUpdatesForMember,
	getHorsesFollowedByMember,
	getTotalHorseUpdatesForMember
} from './graphql/queries';
import HorseUpdatesContainer from '../HorseUpdatesContainer';
import HorseUpdate from '../HorseUpdate';
import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScrollLoadRow from '../InfiniteScrollLoadRow';
import {
	checkUserAdmin,
	shouldShowHorseProfileDetail
} from '../../utils/helpers';
import { useAuth } from '../../hooks/useAuth';
import EmptyFollowsBanner from './Components/EmptyFollowsBanner';
import LoaderSpinner from '../LoaderSpinner';
import { useToast } from '../../hooks/useToast';
import {
	removeHorseUpdate,
	getListOfHorseLatestPostUpdate,
	getForSaleHorseList
} from '../../graphql/custom.queries';
import PostUpdateSection from '../PostUpdateSection';
import ClaimedHorseSelect from './Components/ClaimedHorseSelect';
import ClaimCountWidget from '../ClaimCountWidget';
const PAGE_ID = 'following-tab';
import SuggestionsToFollow from '../SuggestionsToFollow';
import DynamicCarousel from '../DynamicCarousel';

const FollowingTab = ({ memberData, memberQuickLinks, claimCount }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [followedHorses, setFollowedHorses] = useState([]);
	const [horseUpdates, setHorseUpdates] = useState([]);
	const [totalHorseUpdates, setTotalHorseUpdates] = useState(0);
	const [paginationIndex, setPaginationIndex] = useState(0);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	const [isLoading, setIsLoading] = useState(true);
	const [isLoadOrRefresh, setIsLoadOrRefresh] = useState(true);
	const [selectedHorseForPosting, setSelectedHorseForPosting] = useState(null);
	const [suggestionsToFollow, setSuggestionsToFollow] = useState([]);
	const [horseForSale, setHorseForSale] = useState([]);
	const [isLoadingHorseUpdates, setIsLoadingHorseUpdates] = useState(false);
	const [isFollowedHorsesCalled, setIsFollowedHorsesCalled] = useState(false);

	useEffect(() => {
		if (memberData) {
			AppSyncService.execute(getHorsesFollowedByMember, {
				member_id: memberData.id
			}).then((result) => {
				if (result?.data?.getHorsesFollowedByMember?.items) {
					setFollowedHorses(
						result?.data?.getHorsesFollowedByMember?.items.map(
							({ horse_code }) => horse_code
						)
					);
					setIsFollowedHorsesCalled(true);
					if (result?.data?.getHorsesFollowedByMember?.items.length === 0) {
						setIsLoading(false);
					}
					let horsesFollowed = result?.data?.getHorsesFollowedByMember?.items.map(
						({ horse_code }) => horse_code
					);
					fetchHorseForSale(horsesFollowed);
				}
			});
		}
	}, [memberData]);

	const fetchHorseForSale = async (horsesFollowed) => {
		const result = await AppSyncService.execute(getForSaleHorseList, {
			horsesCode: horsesFollowed
		});

		if (result?.data?.getForSaleHorseListNotFollowing) {
			onBuildForSaleHorseItems(result.data.getForSaleHorseListNotFollowing);
		}
	};

	const fetchHorseUpdates = () => {
		AppSyncService.execute(getAllHorseUpdatesForMember, {
			horseCodes: followedHorses,
			memberId: memberData.id,
			paginationIndex,
			paginationSize: 20
		}).then((result) => {
			if (result?.data?.getAllHorseUpdatesForMember) {
				setHorseUpdates((prevState) => [
					...prevState,
					...result.data.getAllHorseUpdatesForMember
				]);
				setPaginationIndex((prevState) => prevState + 1);
			}
		});
	};

	useEffect(() => {
		if (isFollowedHorsesCalled) {
			AppSyncService.execute(getListOfHorseLatestPostUpdate, {
				horsesCode: followedHorses
			}).then((result) => {
				if (result?.data?.getListOfHorseCodesWithLatestPost) {
					const listOfHorseSuggestion = result.data.getListOfHorseCodesWithLatestPost.map(
						(suggested) => ({ ...suggested, following: false })
					);
					setSuggestionsToFollow(listOfHorseSuggestion);
				}
			});
		}
	}, [isFollowedHorsesCalled]);

	useEffect(() => {
		if (isLoadOrRefresh) {
			if (followedHorses.length > 0) {
				setIsLoadingHorseUpdates(true);
				AppSyncService.execute(getAllHorseUpdatesForMember, {
					horseCodes: followedHorses,
					memberId: memberData.id,
					paginationIndex: 0,
					paginationSize: 20
				}).then((result) => {
					const claimHorseForPosting =
						memberData.claims.length > 0
							? memberData.claims.find(() => true)
							: null;
					if (result?.data?.getAllHorseUpdatesForMember) {
						setHorseUpdates(result.data.getAllHorseUpdatesForMember);
						setIsLoading(false);
						setIsLoadingHorseUpdates(false);
						setPaginationIndex(1);
						setIsLoadOrRefresh(false);
						setSelectedHorseForPosting(claimHorseForPosting?.horse?.horseCode);
					} else setIsLoadingHorseUpdates(false);
				});
				AppSyncService.execute(getTotalHorseUpdatesForMember, {
					horseCodes: followedHorses,
					memberId: memberData.id
				}).then((result) => {
					if (result?.data?.getTotalHorseUpdatesCountForMember) {
						setTotalHorseUpdates(
							result?.data?.getTotalHorseUpdatesCountForMember
						);
					}
				});
			}
		}
	}, [followedHorses, isLoadOrRefresh]);

	const onRemoveHorseUpdate = (postId) => {
		AppSyncService.execute(removeHorseUpdate, {
			memberExternalId: currentUser.UserId,
			postId
		}).then((result) => {
			if (result?.data?.removeHorseUpdate?.success) {
				addToast({ Message: 'Removed horse update', IsSuccess: true });
				setIsLoadOrRefresh(true);
				setIsLoading(true);
			} else {
				addToast({
					Message: 'Failed to remove horse update',
					IsSuccess: false
				});
			}
		});
	};

	const onSetFollowingUnfollowing = (selectedHorse, status) => {
		let existInSuggestionToFollow = suggestionsToFollow.some(
			(suggested) => suggested.horse_code === selectedHorse
		);
		let existInForSale = horseForSale.some(
			(suggested) => suggested.horse_code === selectedHorse
		);
		if (existInSuggestionToFollow) {
			const updatedSuggestionsToFollow = suggestionsToFollow.map(
				(suggested) => {
					if (suggested.horse_code == selectedHorse) {
						suggested.following = status;
					}
					return suggested;
				}
			);
			setSuggestionsToFollow(updatedSuggestionsToFollow);
		} else if (existInForSale) {
			const updatedForSaleList = horseForSale.map((suggested) => {
				if (suggested.horse_code == selectedHorse) {
					suggested.following = status;
				}
				return suggested;
			});
			setHorseForSale(updatedForSaleList);
		}
	};

	const onBuildAcknowledgedMessage = (horseItem) => {
		return (
			<div>
				{horseItem.horseName} has been listed{' '}
				<strong className={classes.statusChange}>For Sale </strong> in the{' '}
				<strong className={classes.statusChange}>Acknowledged Retrainer</strong>{' '}
				program.{' '}
				<i>
					{' '}
					Interested in me? Contact my{' '}
					<u
						className={classes.contactRetrainer}
						onClick={() =>
							navigate(`/retrainer-profile/${horseItem.businessProfileId}`)
						}
					>
						Acknowledged Retrainer
					</u>{' '}
					for more information.{' '}
				</i>
			</div>
		);
	};

	const onBuildResetMessage = (horseItem) => {
		return (
			<div>
				{horseItem.horseName} has been listed{' '}
				<strong className={classes.statusChange}>For Sale </strong> in the{' '}
				<strong className={classes.statusChange}>RESET</strong>.
				<i>
					{' '}
					Interested in me? Contact my{' '}
					<u
						className={classes.contactRetrainer}
						onClick={() =>
							navigate(`/retrainer-profile/${horseItem.businessProfileId}`)
						}
					>
						Acknowledged Retrainer
					</u>{' '}
					for more information.{' '}
				</i>
			</div>
		);
	};

	const onBuildForSaleHorseItems = (horseForSaleList) => {
		let horseItemTemp = [];

		horseForSaleList.forEach((horseItem) => {
			if (horseItem.horseOTTStatusId === 16) {
				horseItemTemp = [
					...horseItemTemp,
					{
						created_at: horseItem.created,
						files: horseItem.files,
						horse: { name: horseItem.horseName },
						horse_code: horseItem.horseCode,
						member_id: horseItem.byMemberId,
						message: onBuildResetMessage(horseItem),
						following: false
					}
				];
			} else if (horseItem.horseOTTStatusId === 4) {
				horseItemTemp = [
					...horseItemTemp,
					{
						created_at: horseItem.created,
						files: horseItem.files,
						horse: { name: horseItem.horseName },
						horse_code: horseItem.horseCode,
						member_id: horseItem.byMemberId,
						message: onBuildAcknowledgedMessage(horseItem),
						following: false
					}
				];
			}
		});
		setHorseForSale(horseItemTemp);
	};

	const onRenderCarouselItems = () => {
		const suggestionsToFollowSummary = [
			...suggestionsToFollow,
			...horseForSale
		];
		suggestionsToFollowSummary.sort(
			(prev, current) =>
				new Date(current.created_at) - new Date(prev.created_at)
		);

		return suggestionsToFollowSummary.map(
			({
				post_id,
				message,
				horse,
				files,
				created_at,
				horse_code,
				following
			}) => (
				<SuggestionsToFollow
					key={`${horse.name}-${post_id}`}
					message={message}
					horse={horse}
					files={files}
					createdAt={created_at}
					horseCode={horse_code}
					following={following}
					onSetFollowingUnfollowing={onSetFollowingUnfollowing}
				/>
			)
		);
	};

	return (
		<Col style={{ padding: 0 }}>
			<div className={classes.bannerContainer}>
				<h1 className={classes.bannerHeading}>Following</h1>
			</div>
			{isLoading ? (
				<LoaderSpinner status={true} />
			) : (
				<div className={classes.followingTabContainer}>
					<Col sm={12} lg={9}>
						<>
							{selectedHorseForPosting && (
								<PostUpdateSection
									idPrefix={PAGE_ID}
									isLoadingHorseUpdates={isLoadingHorseUpdates}
									horseData={memberData.claims.find(
										({ horse }) => horse.horseCode === selectedHorseForPosting
									)}
									refreshData={() => setIsLoadOrRefresh(true)}
									horseSelectable={true}
									horseSelect={
										selectedHorseForPosting ? (
											<ClaimedHorseSelect
												idPrefix={PAGE_ID}
												selectedValue={selectedHorseForPosting}
												selectOptions={memberData.claims.map(({ horse }) => ({
													id: horse.horseCode,
													value: horse.name
												}))}
												onChange={(e) =>
													setSelectedHorseForPosting(e.target.value)
												}
											/>
										) : null
									}
								/>
							)}
							<div>
								<HorseUpdatesContainer style={{ height: 550 }}>
									{followedHorses.length > 0 ? (
										<InfiniteScroll
											dataLength={
												horseUpdates.length <= 6 ? 6 : horseUpdates.length
											}
											next={fetchHorseUpdates}
											hasMore={false}
											loader={<InfiniteScrollLoadRow />}
										>
											{horseUpdates
												.slice(0, 6)
												.map(
													({
														post_id,
														horse_code: horseCode,
														message,
														created_at,
														horse,
														claims,
														files,
														postingMember,
														source
													}) => (
														<HorseUpdate
															postId={post_id}
															horseCode={horseCode}
															key={`${horse.name}-${post_id}`}
															horseAvatarVisible={shouldShowHorseProfileDetail(
																horse.ottInformation?.profilePhotoVisibility,
																true,
																isAdminUser,
																!!claims.find(
																	({ member }) => member?.id === memberData?.id
																)
															)}
															profileImageSrc={
																horse.ottInformation.profilePhoto.key
															}
															horseName={horse.name}
															message={message}
															createdAt={created_at}
															files={files}
															imageStyles={{ marginLeft: 45 }}
															containerStyles={{ padding: 15 }}
															showMoreOptions={
																postingMember?.externalId ===
																currentUser?.UserId
															}
															onRemoveUpdate={onRemoveHorseUpdate}
															source={source}
														/>
													)
												)}
										</InfiniteScroll>
									) : (
										<EmptyFollowsBanner />
									)}
								</HorseUpdatesContainer>
							</div>
						</>
					</Col>
					<Col sm={12} lg={3} className={classes.quickLinksColumn}>
						<ClaimCountWidget claimCount={claimCount} />
						<QuickLinks links={memberQuickLinks} />
					</Col>
				</div>
			)}

			<div className={classes.bannerContainer}>
				<h1 className={classes.bannerHeading}>Suggestions to Follow</h1>
			</div>

			<div className={classes.suggestionsContainer}>
				<Col sm={12}>
					{suggestionsToFollow.length <= 0 ? (
						<LoaderSpinner status={true} />
					) : (
						<DynamicCarousel renderCarouselItems={onRenderCarouselItems} />
					)}
				</Col>
			</div>

			<div style={{ marginTop: '30px' }}>
				{horseUpdates.length >= 7 ? (
					<HorseUpdatesContainer style={{ height: 550 }}>
						{followedHorses.length > 0 ? (
							<InfiniteScroll
								dataLength={horseUpdates.length}
								next={fetchHorseUpdates}
								hasMore={totalHorseUpdates > horseUpdates.length}
								loader={<InfiniteScrollLoadRow />}
							>
								{horseUpdates
									.slice(6, totalHorseUpdates)
									.map(
										({
											post_id,
											horse_code: horseCode,
											message,
											created_at,
											horse,
											claims,
											files,
											postingMember,
											source
										}) => (
											<HorseUpdate
												postId={post_id}
												horseCode={horseCode}
												key={`${horse.name}-${post_id}`}
												horseAvatarVisible={shouldShowHorseProfileDetail(
													horse.ottInformation?.profilePhotoVisibility,
													true,
													isAdminUser,
													!!claims.find(
														({ member }) => member?.id === memberData?.id
													)
												)}
												profileImageSrc={horse.ottInformation.profilePhoto.key}
												horseName={horse.name}
												message={message}
												createdAt={created_at}
												files={files}
												imageStyles={{ marginLeft: 45 }}
												containerStyles={{ padding: 15 }}
												showMoreOptions={
													postingMember?.externalId === currentUser?.UserId
												}
												onRemoveUpdate={onRemoveHorseUpdate}
												source={source}
											/>
										)
									)}
							</InfiniteScroll>
						) : (
							<EmptyFollowsBanner />
						)}
					</HorseUpdatesContainer>
				) : (
					<div> </div>
				)}
			</div>
		</Col>
	);
};

export default FollowingTab;

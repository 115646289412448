import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	fieldEntry: {
		float: 'left',
		fontFamily: 'Open-sans !important',
		height: 95,
		borderRadius: '4px',
		border: '1px solid #c7ced1',
		width: '100%',
		paddingLeft: 16,
		fontSize: 15,
		paddingTop: 10,
		paddingRight: 15,
		resize: 'none',
		boxSizing: 'border-box',
		'&:focus': {
			outline: 'none'
		}
	}
}));

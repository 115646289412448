import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import AdminReviewDivider from '../../../AdminReviewComponents/AdminReviewDivider';

const BusinessSuppliersSection = ({ formData }) => {
	return (
		<AdminReviewSection title="Business Suppliers">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Veterinary practice name"
						value={formData?.veterinaryPracticeName}
					/>
					<AdminReviewDatum
						label="Veterinary address"
						value={formData?.veterinaryAddress}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Regular veterinarian"
						value={formData?.regularVeterinarian}
					/>
					<AdminReviewDatum
						label="Veterinary phone number"
						value={formData?.veterinaryPhone}
					/>
					<AdminReviewDatum
						label="Veterinary email"
						value={formData?.veterinaryEmail}
					/>
				</SectionRow>
			</Col>
			<AdminReviewDivider />
			<Col>
				<SectionRow>
					<AdminReviewDatum label="Farrier" value={formData?.farrier} />
					<AdminReviewDatum
						label="Farrier phone number"
						value={formData?.farrierPhone}
					/>
					<AdminReviewDatum
						label="Farrier email"
						value={formData?.farrierEmail}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default BusinessSuppliersSection;

import React from 'react';
import useStyles from './styles';
import Logo from '../../assets/icons/Racing-Victoria-OTT-Logo.svg';
import { useScreenClass } from 'react-grid-system';
import MailIcon from '../../assets/icons/mail-icon.svg';
import SEO from '../seo';

const ComingSoon = () => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();

	return (
		<>
			<SEO title="Coming Soon" />
			<div className={classes.container}>
				<div className={classes.sectionDiv}>
					<div
						className={
							['md', 'sm', 'xs'].includes(screenClassForRender)
								? classes.containerBoxMobile
								: classes.containerBox
						}
					>
						<div
							className={
								['md', 'sm', 'xs'].includes(screenClassForRender)
									? classes.infoBoxMobile
									: classes.infoBox
							}
						>
							<div
								className={
									['md', 'sm', 'xs'].includes(screenClassForRender)
										? classes.headerRowMobile
										: classes.headerRow
								}
							>
								<h2>Coming Soon</h2>
							</div>
							<div
								className={
									['md', 'sm', 'xs'].includes(screenClassForRender)
										? classes.contentMobile
										: classes.content
								}
							>
								<div className={classes.logo}>
									<img
										className={classes.image}
										src={Logo}
										alt="Racing Victoria - Off The Track Community Logo"
									/>
								</div>
								<p>
									Increasing the visibility and traceability <br />
									of thoroughbreds post-retirement.
									<br />
									<br />
									<a
										className={classes.emailLink}
										target="_blank"
										rel="noreferrer"
										href="mailto:offthetrack@racingvictoria.net.au"
									>
										<img src={MailIcon} alt="Email" />
										offthetrack@racingvictoria.net.au
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ComingSoon;

import React, { useState, useEffect } from 'react';
import { Container, Col, Row, ScreenClassRender } from 'react-grid-system';
import HorseFilterSearch from '../HorseFilterSearch';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { findHorses } from '../../graphql/custom.queries';
import SEO from '../seo';
import { getSearchReferenceData } from '../../graphql/custom.queries';
import { formatDate, formatiOSTextField, getYear } from '../../utils/helpers';
import SearchResultsDisplay from '../SearchResultsDisplay';
import FilterBarGlobal from '../FilterBarGlobal';
import SearchField from '../SearchField';
import Accordion from '../Accordion';

const SearchThoroughBred = () => {
	const classes = useStyles();
	const [searchResults, setSearchResults] = useState(null);
	const [searchName, setSearchName] = useState('');
	const [horseMicrochipNumber, setHorseMicrochipNumber] = useState('');
	const [damName, setDamName] = useState('');
	const [sireName, setSireName] = useState('');
	const [horseCountryOfBirth, setHorseCountryOfBirth] = useState('');
	const [horseFoalDate, setHorseFoalDate] = useState(null);
	const [horseFoalYear, setHorseFoalYear] = useState(null);
	const [horseColour, setHorseColour] = useState('');
	const [horseGender, setHorseGender] = useState('');
	const [horseBrand, setHorseBrand] = useState('');
	const [order, setOrder] = useState(0);
	const [horseCount, setHorseCount] = useState(0);
	const [paginationIndex, setPaginationIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [referenceListCountries, setReferenceListCountries] = useState([]);
	const [referenceListColours, setReferenceListColours] = useState([]);
	const [referenceListGenders, setReferenceListGenders] = useState([]);
	const [toggleFilter, setToggleFilter] = useState(true);
	const [searchByNameValidation, setSearchByNameValidation] = useState({
		invalid: false,
		message: 'You must select at least one filter, if the name is blank'
	});

	const getHorseSearchResult = (paginationIndex, order = 0) => {
		let orderSearch = 'ASC';
		order === 0 || order === 1 ? (orderSearch = 'ASC') : (orderSearch = 'DESC');
		let input = {};
		if (searchName) {
			input.name = searchName;
		}
		if (horseMicrochipNumber) {
			input.microChipNumber = horseMicrochipNumber;
		}
		if (damName) {
			input.damName = damName;
		}
		if (sireName) {
			input.sireName = sireName;
		}
		if (horseBrand) {
			input.horseBrand = horseBrand;
		}
		if (horseCountryOfBirth) {
			input.countryOfBirth = horseCountryOfBirth;
		}
		if (horseFoalDate) {
			input.foalYear = horseFoalYear;
		}
		if (horseColour) {
			input.colour = horseColour;
		}
		if (horseGender) {
			input.sex = horseGender;
		}
		input.paginationIndex = paginationIndex;
		input.orderBy = orderSearch;

		if (
			searchName != '' ||
			horseMicrochipNumber != '' ||
			damName != '' ||
			sireName != '' ||
			horseBrand != '' ||
			horseCountryOfBirth != '' ||
			horseFoalDate != null ||
			horseColour != '' ||
			horseGender != ''
		) {
			setSearchByNameValidation({
				...searchByNameValidation,
				invalid: false
			});
			setIsLoading(true);
			AppSyncService.execute(findHorses, { input }).then((data) => {
				if (data?.data?.findHorses != null) {
					if (paginationIndex > 0) {
						setSearchResults(searchResults.concat(data.data.findHorses));
						setHorseCount(data.data.countMatchingHorses);
						setIsLoading(false);
					} else {
						setSearchResults(data.data.findHorses);
						setHorseCount(data.data.countMatchingHorses);
						setIsLoading(false);
					}
				} else {
					setSearchResults([]);
					setIsLoading(false);
				}
			});
		} else if (
			searchName === '' &&
			(horseMicrochipNumber === '' ||
				damName === '' ||
				sireName === '' ||
				horseBrand === '' ||
				horseCountryOfBirth === '' ||
				horseFoalDate === null ||
				horseColour === '' ||
				horseGender === '')
		) {
			setSearchByNameValidation({
				...searchByNameValidation,
				invalid: true
			});
		} else {
			setSearchByNameValidation({
				...searchByNameValidation,
				invalid: false
			});
		}
	};

	const onHorseSearchClick = () => {
		setPaginationIndex(0);
		getHorseSearchResult(0);
	};

	const updateSearchName = (name) => {
		setSearchName(formatiOSTextField(name));
	};

	const updateHorseMicrochipNumber = (mcnumber) => {
		if (mcnumber.match(/^\d*$/)) {
			setHorseMicrochipNumber(mcnumber);
		}
	};

	const updateDamName = (name) => {
		setDamName(formatiOSTextField(name));
	};

	const updateSireName = (name) => {
		setSireName(formatiOSTextField(name));
	};

	const updateHorseCountryOfBirth = (cob) => {
		setHorseCountryOfBirth(cob);
	};

	const updateHorseFoalDate = (foalDate) => {
		if (foalDate == '') {
			setHorseFoalDate(null);
			setHorseFoalYear(null);
		} else {
			let formattedDate = formatDate(foalDate);
			if (formattedDate != '1970-01-01') {
				setHorseFoalDate(formattedDate);
				setHorseFoalYear(getYear(foalDate));
			} else {
				setHorseFoalDate(null);
				setHorseFoalYear(null);
			}
		}
	};

	const updateHorseColour = (colour) => {
		setHorseColour(colour);
	};

	const updateHorseGender = (gender) => {
		setHorseGender(gender);
	};

	const updateHorseBrand = (horseBrand) => {
		setHorseBrand(horseBrand);
	};

	const updateOrdering = (order) => {
		setPaginationIndex(0);
		setOrder(order);
		getHorseSearchResult(0, order);
	};

	const updatePaginationIndex = (pageIndex) => {
		setPaginationIndex(pageIndex);
	};

	useEffect(() => {
		if (paginationIndex === 0) return;
		getHorseSearchResult(paginationIndex);
	}, [paginationIndex]);

	useEffect(() => {
		//  Load values from the DB
		AppSyncService.execute(getSearchReferenceData).then((result) => {
			if (result.data) {
				let modifiedCountriesArray = result.data.listCountries;
				modifiedCountriesArray.unshift('');
				setReferenceListCountries(modifiedCountriesArray.sort());

				let modifiedListHorseColours = result.data.listHorseColours;
				modifiedListHorseColours.unshift('');
				setReferenceListColours(modifiedListHorseColours.sort());

				let modifiedListHorseGenders = result.data.listHorseGenders;
				modifiedListHorseGenders.unshift('');
				setReferenceListGenders(modifiedListHorseGenders.sort());
			}
		});
	}, []);

	const keyCheck = (key) => {
		if (key === 13) {
			onHorseSearchClick();
		}
	};

	const desktopComponent = (
		<Container className={classes.searchPageContainer}>
			<SEO title="Horse Search" />
			<Row>
				<Col sm={12} md={12} lg={12} xl={12} xxl={12}>
					<SearchField
						onInputChange={updateSearchName}
						title="Search by Name"
						onSearchClick={() => onHorseSearchClick()}
						keyCheck={keyCheck}
						validation={searchByNameValidation}
						inputValue={searchName}
					/>
					{(horseMicrochipNumber ||
						damName ||
						sireName ||
						horseBrand ||
						horseCountryOfBirth ||
						horseFoalDate ||
						horseColour ||
						horseGender) && (
						<FilterBarGlobal
							filters={[
								[horseMicrochipNumber, updateHorseMicrochipNumber],
								[damName, updateDamName],
								[sireName, updateSireName],
								[horseBrand, updateHorseBrand],
								[horseCountryOfBirth, updateHorseCountryOfBirth],
								[horseFoalYear, updateHorseFoalDate],
								[horseColour, updateHorseColour],
								[horseGender, updateHorseGender]
							]}
						/>
					)}
					<div className={classes.clear}></div>

					<Container className={classes.filterContainer}>
						<div
							className={classes.addFilter}
							onClick={() => setToggleFilter(!toggleFilter)}
						></div>
						<Accordion
							title="Add Filters"
							isOpen={toggleFilter}
							mobile
							styles={{ marginTop: 15 }}
						>
							<HorseFilterSearch
								referenceListCountries={referenceListCountries}
								referenceListColours={referenceListColours}
								referenceListGenders={referenceListGenders}
								updateHorseMicrochipNumber={updateHorseMicrochipNumber}
								horseMicrochipNumber={horseMicrochipNumber}
								updateDamName={updateDamName}
								damName={damName}
								updateSireName={updateSireName}
								sireName={sireName}
								updateHorseCountryOfBirth={updateHorseCountryOfBirth}
								horseCountryOfBirth={horseCountryOfBirth}
								updateHorseFoalDate={updateHorseFoalDate}
								horseFoalDate={horseFoalDate}
								updateHorseColour={updateHorseColour}
								horseColour={horseColour}
								updateHorseGender={updateHorseGender}
								horseGender={horseGender}
								updateHorseBrand={updateHorseBrand}
								horseBrand={horseBrand}
							/>
						</Accordion>
					</Container>

					<SearchResultsDisplay
						searchResults={searchResults}
						updateSearchOrdering={updateOrdering}
						updatePaginationIndex={updatePaginationIndex}
						orderBy={order}
						searchName={searchName}
						horseCount={horseCount}
						paginationIndex={paginationIndex}
						isLoading={isLoading}
					/>
				</Col>
			</Row>
		</Container>
	);

	const mobileComponent = (
		<Container className={classes.searchPageContainer}>
			<SEO title="Horse Search" />
			<Row>
				<Col sm={12} md={12} lg={12} xl={12} xxl={12}>
					<SearchField
						onInputChange={updateSearchName}
						title="Search by Name"
						onSearchClick={() => onHorseSearchClick()}
						keyCheck={keyCheck}
						validation={searchByNameValidation}
						inputValue={searchName}
					/>
					{(horseMicrochipNumber ||
						damName ||
						sireName ||
						horseBrand ||
						horseCountryOfBirth ||
						horseFoalDate ||
						horseColour ||
						horseGender) && (
						<FilterBarGlobal
							mobile
							filters={[
								[horseMicrochipNumber, updateHorseMicrochipNumber],
								[damName, updateDamName],
								[sireName, updateSireName],
								[horseCountryOfBirth, updateHorseCountryOfBirth],
								[horseFoalYear, updateHorseFoalDate],
								[horseColour, updateHorseColour],
								[horseGender, updateHorseGender]
							]}
						/>
					)}
					<div className={classes.clear}></div>
					<Container className={classes.filterContainer}>
						<div
							className={classes.addFilter}
							onClick={() => setToggleFilter(!toggleFilter)}
						></div>
						<Accordion
							title="Add Filters"
							isOpen={toggleFilter}
							mobile
							styles={{ marginTop: 15 }}
						>
							<HorseFilterSearch
								referenceListCountries={referenceListCountries}
								referenceListColours={referenceListColours}
								referenceListGenders={referenceListGenders}
								updateHorseMicrochipNumber={updateHorseMicrochipNumber}
								horseMicrochipNumber={horseMicrochipNumber}
								updateDamName={updateDamName}
								damName={damName}
								updateSireName={updateSireName}
								sireName={sireName}
								updateHorseCountryOfBirth={updateHorseCountryOfBirth}
								horseCountryOfBirth={horseCountryOfBirth}
								updateHorseFoalDate={updateHorseFoalDate}
								horseFoalDate={horseFoalDate}
								updateHorseColour={updateHorseColour}
								horseColour={horseColour}
								updateHorseGender={updateHorseGender}
								horseGender={horseGender}
								horseBrand={horseBrand}
								updateHorseBrand={updateHorseBrand}
								mobile
							/>
						</Accordion>
					</Container>
					<SearchResultsDisplay
						searchResults={searchResults}
						updateSearchOrdering={updateOrdering}
						updatePaginationIndex={updatePaginationIndex}
						orderBy={order}
						searchName={searchName}
						horseCount={horseCount}
						paginationIndex={paginationIndex}
						isLoading={isLoading}
						mobile
					/>
				</Col>
			</Row>
		</Container>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default SearchThoroughBred;

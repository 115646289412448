import React from 'react';
import {
	StyledFollowerCount,
	StyledHeader,
	StyledHorseName,
	StyledLongText
} from './styles';
import HorseAvatar from '../../../HorseAvatar';
import { addNameToUnnamedHorse } from '../../../../utils/helpers';

const HorseInfoPaneHeader = ({
	horseProfile,
	headerActions,
	showFollowersCount,
	avatarVisible
}) => {
	return (
		<StyledHeader>
			<StyledHorseName>
				<HorseAvatar
					avatarImageKey={
						horseProfile?.horse?.ottInformation?.profilePhoto?.key
					}
					idPrefix="horse-profile-page-header"
					visibleToUser={avatarVisible}
					style={{ marginRight: 10, minWidth: 55 }}
				/>
				<div>
					<StyledLongText>
						{addNameToUnnamedHorse(horseProfile.horse?.name)}
					</StyledLongText>
					{showFollowersCount && (
						<StyledFollowerCount>
							<span className="count">{horseProfile.numberOfFollowers} </span>
							{horseProfile.numberOfFollowers > 1 ? 'Followers' : 'Follower'}
						</StyledFollowerCount>
					)}
				</div>
			</StyledHorseName>
			{headerActions}
		</StyledHeader>
	);
};

export default HorseInfoPaneHeader;

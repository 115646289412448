import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import { navigate } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveHorseClaimStatusMutation,
	saveBusinessProfileStatusMutation,
	saveHorseLocation,
	setOttProgramAndStatus,
	generateClaimDeclinedNotice
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useToast } from '../../hooks/useToast';
import FormTextArea from '../FormTextArea';
import HeaderBanner from '../HeaderBanner';
import {
	REASON_FOR_REJECTION_SIZE,
	APPROVED,
	REJECTED,
	ClaimThoroughbredFormKeys,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ACK_RETIREMENT_FARM,
	CommonKeys,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ACK_FOSTER_PROVIDER
} from '../../utils/constants';

const AdminApproval = ({
	sourceOfAddress,
	reviewNotesData,
	reviewReasonsData,
	id,
	reviewStatus,
	reviewType,
	isLoading,
	updateIsLoading,
	isAtAddress,
	stabledDate,
	allClaimData,
	hCode,
	residentialAddress,
	onDataSubmit,
	updateBusinessProfileCorrections,
	ownershipType,
	member,
	headerTitle
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const isAckRetirementFarmRole = member?.roles.some(
		(role) => role.code === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
	);
	const isAckFosterProviderRole = member?.roles.some(
		(role) => role.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
	);
	const [reviewNotes, setReviewNotes] = useState('');
	const [reviewReasons, setReviewReasons] = useState('');
	const [
		reviewReasonsRemainingChars,
		setReviewReasonsRemainingChars
	] = useState(REASON_FOR_REJECTION_SIZE);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [isSubmitDisabled, seIsSubmitDisabled] = useState(false);
	const [isRejectDisabled, setIsRejectDisabled] = useState(true);
	const [isAdminFormDisabled, setIsAdminFormDisabled] = useState(false);

	useEffect(() => {
		if (reviewStatus === 'Draft') {
			seIsSubmitDisabled(true);
		}

		if (
			reviewType === 'Thoroughbred' &&
			(reviewStatus === 'Approved' || reviewStatus === 'Rejected')
		) {
			setIsAdminFormDisabled(true);
		}

		if (reviewStatus === 'Review' && reviewReasonsData !== '') {
			setIsRejectDisabled(false);
		}
	}, []);

	useEffect(() => {
		setReviewNotes(reviewNotesData);
		setReviewReasons(reviewReasonsData);
		updateIsLoading(false);
	}, [reviewNotesData, reviewReasonsData]);

	const storeData = async (reviewStatus) => {
		if (reviewStatus === APPROVED && sourceOfAddress) {
			let horseLocation = {};
			if (isAtAddress) {
				horseLocation.address = residentialAddress?.address;
				horseLocation.suburb = residentialAddress?.suburb;
				horseLocation.state = residentialAddress?.state;
				horseLocation.postcode = residentialAddress?.postcode;
				horseLocation.country = residentialAddress?.country;
			} else {
				horseLocation.address = allClaimData?.stabledAddressLine;
				horseLocation.suburb = allClaimData?.stabledSuburb;
				horseLocation.state = allClaimData?.stabledState;
				horseLocation.postcode = allClaimData?.stabledPostcode;
				horseLocation.country = allClaimData?.stabledCountry;
			}
			try {
				AppSyncService.execute(saveHorseLocation, {
					input: {
						address: horseLocation.address,
						country: horseLocation.country,
						horseCode: hCode,
						isValidated: allClaimData.stabledAddressValidated,
						postcode: horseLocation.postcode,
						stabledDate: stabledDate,
						state: horseLocation.state,
						suburb: horseLocation.suburb,
						sourceId: sourceOfAddress
					}
				}).then((result) => {
					if (result?.data?.saveHorseLocation) {
						addToast({ Message: 'Updated Horse Address', IsSuccess: true });
					} else {
						addToast({
							Message: 'Failed to save horse address',
							IsSuccess: false
						});
					}
				});
				if (isAckRetirementFarmRole && ownershipType === ACK_RETIREMENT_FARM) {
					const payload = {
						horseCode: +hCode,
						memberExternalId: member?.externalId,
						asOfDate: stabledDate,
						programId: '7',
						statusId: 28
					};
					saveOttProgramAndStatus(payload);
				}

				if (isAckFosterProviderRole && ownershipType === ACK_FOSTER_PROVIDER) {
					const payload = {
						horseCode: +hCode,
						memberExternalId: member?.externalId,
						asOfDate: stabledDate,
						programId: '3',
						statusId: 25
					};
					saveOttProgramAndStatus(payload);
				}
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving data. Please contact support'
					);
				}
			}
		}
		setBackendErrorMessage(null);
		let mutation = saveHorseClaimStatusMutation;
		let input = {
			id: parseInt(id),
			reviewNotes: reviewNotes,
			reviewReasons: reviewReasons,
			status: reviewStatus
		};
		if (reviewType == 'BusinessProfile') {
			updateBusinessProfileCorrections();
			mutation = saveBusinessProfileStatusMutation;
			input = {
				id: parseInt(id),
				reviewNotes: reviewNotes,
				reviewReasons: reviewReasons,
				status: reviewStatus,
				source: headerTitle
			};
		}
		if (reviewStatus == REJECTED) {
			const resultEmail = await AppSyncService.execute(
				generateClaimDeclinedNotice,
				{
					horseCode: hCode,
					memberExternalId: member?.externalId,
					declineReason: reviewReasons
				}
			);
			if (resultEmail.data?.generateClaimDeclinedNotice) {
				console.log('success');
			}
		}
		try {
			AppSyncService.execute(mutation, input).then((data) => {
				if (data.data != null) {
					onDataSubmit(reviewStatus);
					addToast({ Message: reviewStatus, IsSuccess: true });
					navigate(`/review-list`);
				}
			});
		} catch (error) {
			if (error) {
				setBackendErrorMessage(error);
			} else {
				setBackendErrorMessage(
					'Unknown error while saving data. Please contact support'
				);
			}
		}
	};

	const saveOttProgramAndStatus = async (payload) => {
		await AppSyncService.execute(setOttProgramAndStatus, payload);
	};

	const setReviewNotesData = (value) => {
		if (
			value.trim().length != 0 &&
			reviewReasons.trim().length > 0 &&
			reviewReasons.trim().length <= 300
		) {
			setIsRejectDisabled(false);
		} else {
			setIsRejectDisabled(true);
		}
		setReviewNotes(value);
	};

	const setReviewReasonsData = (value) => {
		setReviewReasonsRemainingChars(
			REASON_FOR_REJECTION_SIZE - value.trim().length
		);
		if (value.trim().length > 300) {
			setBackendErrorMessage(
				'Reason for Rejection must have maximum of 300 characters'
			);
		} else {
			setBackendErrorMessage('');
		}
		if (
			value.trim().length > 0 &&
			value.trim().length <= 300 &&
			reviewNotes.trim().length != 0
		) {
			setIsRejectDisabled(false);
		} else {
			setIsRejectDisabled(true);
		}
		setReviewReasons(value);
	};

	return (
		<>
			<HeaderBanner
				title="Additional Notes (Admin Only)"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div>
					<div>
						<Row>
							<Col
								className={classes.notesContainer}
								style={{ marginLeft: 20 }}
							>
								<div>
									<span className={classes.fieldTitle}>
										Notes (Required if Rejecting form)
									</span>
								</div>
								<FormTextArea
									id={CommonKeys.NOTES}
									value={reviewNotes}
									disabled={isAdminFormDisabled}
									onChange={(e) => {
										setReviewNotesData(e.target.value);
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col
								className={classes.notesContainer}
								style={{ marginLeft: 20 }}
							>
								<div>
									<span className={classes.fieldTitle}>
										Reason for Rejection (Required if Rejecting form - This is a
										Notification to the User)
									</span>
									<span className={classes.charLengthInfo}>
										{reviewReasonsRemainingChars} characters remaining
									</span>
								</div>
								<FormTextArea
									id={ClaimThoroughbredFormKeys.REASON_FOR_REJECTION}
									value={reviewReasons}
									disabled={isAdminFormDisabled}
									onChange={(e) => {
										setReviewReasonsData(e.target.value);
									}}
								/>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>
					<div className={classes.buttonContainer}>
						<div>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span>
								<button
									id={ClaimThoroughbredFormKeys.REJECT}
									type="button"
									disabled={
										isSubmitDisabled || isRejectDisabled || isAdminFormDisabled
									}
									className={
										isSubmitDisabled || isRejectDisabled || isAdminFormDisabled
											? classes.disabledFormButton
											: classes.secondaryFormButton
									}
									onClick={() => storeData(REJECTED)}
								>
									Reject
								</button>
							</span>
							<span>
								<button
									id={ClaimThoroughbredFormKeys.APPROVE}
									type="button"
									disabled={
										isSubmitDisabled ||
										reviewStatus === APPROVED ||
										isAdminFormDisabled ||
										reviewReasons
									}
									className={
										isSubmitDisabled ||
										reviewStatus === APPROVED ||
										isAdminFormDisabled ||
										reviewReasons
											? classes.disabledFormButton
											: classes.primaryFormButton
									}
									onClick={() => storeData(APPROVED)}
								>
									Approve
								</button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AdminApproval;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	disabledInputStyle: {
		'&:disabled': {
			cursor: 'not-allowed'
		}
	},
	fieldEntry: {
		height: 40,
		borderRadius: '4px',
		border: '1px solid #c7ced1',
		width: '100%',
		paddingLeft: 16,
		fontSize: 15,
		boxSizing: 'border-box',
		'&:focus': {
			outline: 'none'
		},
		extend: 'disabledInputStyle'
	},
	symbol: {
		float: 'left',
		height: '100%',
		background: '#dcebf3',
		width: 44
	},
	fieldEntrySymbolWrapper: {
		border: '1px solid #c7ced1',
		borderRadius: '4px',
		height: 40,
		boxSizing: 'border-box'
	},
	fieldEntrySymbol: {
		float: 'left',
		height: '100%',
		border: 'none',
		outline: 'none',
		paddingLeft: 16,
		fontSize: 15,
		boxSizing: 'border-box',
		'&:focus': {
			outline: 'none'
		},
		extend: 'disabledInputStyle'
	},
	symbolContainer: {
		marginTop: 8,
		marginLeft: 14,
		color: '#026ea7',
		fontFamily: 'system-ui',
		fontWeight: 600
	},
	readonly: {
		fontSize: 15,
		marginTop: 17,
		wordBreak: 'break-word',
		lineHeight: '38px'
	}
}));

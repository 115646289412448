import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import { getUserImage } from '../../graphql/custom.queries';
import UploadPhoto from '../UploadPhoto';
import AppSyncService from '../../graphql/AppSyncService';
import Lightbox from 'react-image-lightbox';
import FormLabelField from '../FormLabelField';
import { RELATIONSHIP_ORGANISATION } from '../../utils/constants';

const ThoroughbredDetailsView = ({ thoroughbredFormData }) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	return (
		<>
			<HeaderBanner
				title="Thoroughbred Relationship"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<FormLabelField
							title="I am a"
							value={thoroughbredFormData?.relationship?.value}
						/>
					</Col>
					<Col>
						{thoroughbredFormData?.relationship?.value ===
							RELATIONSHIP_ORGANISATION && (
							<FormLabelField
								title="Organisation Name*"
								value={thoroughbredFormData?.organisation}
							/>
						)}
					</Col>
					<Col></Col>
				</Row>
			</div>

			<HeaderBanner
				title="Thoroughbred Details"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<FormLabelField
							title="Horse Name*"
							value={thoroughbredFormData.horseName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Age*"
							value={thoroughbredFormData.horseAge}
						/>
					</Col>
					<Col>
						<FormLabelField title="Sex*" value={thoroughbredFormData.sex} />
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Colour"
							value={thoroughbredFormData.colour}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Country of Birth"
							value={thoroughbredFormData.country}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Microchip Number"
							value={thoroughbredFormData.microchip}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Sire Name"
							value={thoroughbredFormData.sireName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Dam Name"
							value={thoroughbredFormData.damName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Foal Year"
							value={thoroughbredFormData.foalYear}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Additional Information"
							value={thoroughbredFormData.notes}
						/>
					</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Brand Photo - Left*</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={thoroughbredFormData.brandLeftImages}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Brand Photo - Right*</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={thoroughbredFormData.brandRightImages}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Markings Photo - Side</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={thoroughbredFormData.markings}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</div>
		</>
	);
};
export default ThoroughbredDetailsView;

export const REGEX = 'REGEX';
export const CHARACTER_LIMIT = 'CHARACTER_LIMIT';
export const MULTI_DEPENDENT = 'MULTI_DEPENDENT';
export const TOGGLE_DEPENDENT = 'TOGGLE_DEPENDENT';
export const DEPENDENT_DROPDOWN = 'DEPENDENT_DROPDOWN';
export const CHECKBOX_DEPENDENCY = 'CHECKBOX_DEPENDENCY';
export const ARRAY_LENGTH_DEPENDENT = 'ARRAY_LENGTH_DEPENDENT';
export const ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE =
	'ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE';

export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (initial && values[f].conditions?.length > 0) {
			values[f].conditions.map((c) => {
				if (c.condition === REGEX) {
					let regex = new RegExp(c.regex);
					if (!regex.test(values[f].value)) {
						errors[f] = c.errorMessage;
					}
				}

				if (c.condition === CHARACTER_LIMIT) {
					if (values[f].value.length > c.limit) {
						errors[f] = c.errorMessage;
					}
				}

				if (c.condition === TOGGLE_DEPENDENT) {
					if (
						String(values[c.field].value) === String(c.value) &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === DEPENDENT_DROPDOWN) {
					let dependentValueId = c.dependantArray.filter(
						(rec) =>
							rec.value === c.value &&
							rec.id === parseInt(values[c.dependantField].value)
					);
					if (
						dependentValueId.length > 0 &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === MULTI_DEPENDENT) {
					let dependentValueId = values[c.field].value.filter(
						(rec) => rec.label === c.value
					);
					if (
						dependentValueId.length > 0 &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === CHECKBOX_DEPENDENCY) {
					let conditionCount = c.dependantArray.filter(
						(rec) => values[rec].value === c.value
					).length;
					if (
						conditionCount === c.dependantArray.length &&
						values[f].value === false
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === ARRAY_LENGTH_DEPENDENT) {
					if (values[f].value.length === 0) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE) {
					if (
						c.toggleValue === values[c.toggleField].value &&
						values[f].value.length === 0
					) {
						errors[f] = c.errorMessage;
					}
				}
			});
		}
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value?.length === 0 ||
				values[f].value === false) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

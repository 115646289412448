import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
  container: {
    display: 'block',
    position: 'relative',
    marginBottom: 12,
    cursor: 'pointer',
    fontSize: 22,
    userSelect: 'none',
    '&:hover $inputClass ~ $checkmark': {},
    '& $checkmark:after': {
      top: 9,
      left: 9,
      width: 8,
      height: 8,
      borderRadius: '50%'
    }
  },
  inputClass: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    '&:checked ~ $checkmark': {
      backgroundColor: '#2196F3',
      border: '2px solid #fff',
      boxShadow: '0px 0px 0px 2px #2196f3',
      width: 15,
      height: 15
    },
    '&:checked ~ $checkmark:after': {
      display: 'block'
    }
  },
  checkmark: {
    position: 'relative',
    top: 7,
    left: 0,
    right: 0,
    marginRight: 'auto',
    marginLeft: 'auto',
    height: 15,
    width: 15,
    border: '2px solid #2196f3',
    backgroundColor: '#fff',
    borderRadius: '50%',
    '&:after': {
      content: '""',
      position: 'relative',
      display: 'none'
    }
  },
  radioContainer : {
    borderRadius: 3,
    position: 'relative',
    float: 'left',
  },
  radioTextContainer: {
    marginTop: 50,
    textAlign: 'center'
  },
  radioTextLabel: {
    fontSize: 14,
    marginTop: 6,
    padding: "0 !important",
    whiteSpace: "nowrap"
  },

  radioLink: {
  },
  icon: {
    float: 'left',
    height: 16,
    marginTop: 10,
    marginRight: 10
  },
  labelContainer: {
    marginTop: 10,
    float: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '80%'
  },
  dot: {
    marginLeft: 2,
    marginRight: 2,
    fontWeight: 800
  }
}));

import React from 'react';
import {
	StyledFilledInput,
	StyledSelect,
	StyledListItemIconContainer,
	StyledMenuItem
} from './styles';
import { VISIBILITY_CONFIG } from './constants';

const HorseDetailVisibilitySelect = ({
	value: selectedValue,
	options,
	onChange,
	disabled = false
}) => {
	const selectedValueColor =
		VISIBILITY_CONFIG[options.find(({ id }) => id === selectedValue)?.value]
			?.color;
	return (
		<StyledSelect
			value={selectedValue}
			onChange={onChange}
			disableUnderline
			variant="filled"
			disabled={disabled}
			style={{
				color: selectedValueColor
			}}
			input={<StyledFilledInput />}
		>
			{options.map(({ id, value }) => (
				<StyledMenuItem
					key={id}
					value={id}
					style={{ color: VISIBILITY_CONFIG[value].color }}
				>
					<StyledListItemIconContainer>
						{VISIBILITY_CONFIG[value].icon}
					</StyledListItemIconContainer>
					{value}
				</StyledMenuItem>
			))}
		</StyledSelect>
	);
};

export default HorseDetailVisibilitySelect;

import React from 'react';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';

const InfiniteScrollLoadRow = () => {
	const classes = useStyles();
	return (
		<div className={classes.loadingPlaceholder}>
			<LoaderSpinner status={true} />
		</div>
	);
};

export default InfiniteScrollLoadRow;

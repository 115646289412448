import { createUseStyles } from 'react-jss';

export default createUseStyles({
	label: {
		fontSize: 13,
		marginTop: 15,
		marginBottom: 5,
		fontWeight: 600
	},
	subsequentRows: {
		'@media (min-width:992px)': {
			marginTop: 15
		}
	},
	infoContent: {
		background: '#fcf7da',
		width: '100%',
		height: '100%'
	},
	name: {
		padding: '10px 10px 5px 10px',
		fontSize: 20,
		fontWeight: 600,
		color: '#464749'
	},
	horseInfo: {
		padding: '0px 10px 10px',
		fontStyle: 'italic',
		color: 'grey',
		fontSize: 13
	},
	divider: {
		marginTop: 20,
		background: '#edf5f9',
		height: 1,
		border: 'none',
		marginBottom: 20
	},
	link: {
		color: '#0085ca',
		fontSize: 12,
		textDecoration: 'underline',
		marginTop: 11,
		cursor: 'pointer'
	},
	buttonContainer: {
		marginLeft: 15,
		marginRight: 15
	},
	button: {
		boxSizing: 'border-box',
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		padding: '10px 10px',
		fontWeight: 600,
		width: '100%',
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	},
	errorList: {
		fontWeight: 600,
		fontSize: 13
	}
});

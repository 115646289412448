import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper';
import FormDropDown from '../../FormDropDown';

const SelectInputField = ({
	id,
	label,
	isRequired,
	options,
	value,
	onChange,
	validationError,
	fullWidth = false,
	visibilitySelector,
	name,
	disabled = false,
	placeholder = ''
}) => {
	return (
		<FormFieldWrapper
			fieldLabel={label}
			isRequired={isRequired}
			errorMessage={validationError}
			fullWidth={fullWidth}
			visibilitySelector={visibilitySelector}
		>
			<FormDropDown
				id={id}
				items={options}
				selectedId={value}
				onChange={onChange}
				showBlank
				name={name}
				disabled={disabled}
				placeholder={placeholder}
			/>
		</FormFieldWrapper>
	);
};
export default SelectInputField;

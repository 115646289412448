import React, { useEffect, useState } from 'react';
import useStyles from './styles.js';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import { useParams } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService.js';
import {
	getContactUsAdminInfo,
	getContactUsFormById,
	getUserImage,
	saveAdminContactUsStatus
} from '../../graphql/custom.queries.js';
import { Col, Row } from 'react-grid-system';
import FormLabelField from '../FormLabelField/index.js';
import {
	checkDropDownValue,
	isOptionOther
} from '../../utils/helpers/index.js';
import UploadPhoto from '../UploadPhoto/index.js';
import Lightbox from 'react-image-lightbox';
import { navigate } from 'gatsby-link';
import { BLANK_OPTION, REVIEWED } from '../../utils/constants/index.js';
import AdminReviewSection from '../AdminReviewComponents/AdminReviewSection/index.js';
import SectionRow from '../SectionRow/index.js';
import SelectInputField from '../FormComponents/SelectInputField/index.js';
import FormTextArea from '../FormTextArea/index.js';
import LoaderSpinner from '../LoaderSpinner/index.js';
import { useToast } from '../../hooks/useToast';

const PAGE_ID = 'Contact-Us-Review';

const ContactUsReview = () => {
	const classes = useStyles();
	const { id } = useParams();
	const { addToast } = useToast();
	const [contactReasons, setContactReasons] = useState('');
	const [selectedContactReason, setSelectedContactReason] = useState(0);
	const [formData, setFormData] = useState([]);
	const [selectedContactReasonValue, setSelectedContactReasonValue] = useState(
		''
	);
	const [whichFeatures, setWhichFeatures] = useState('');
	const [selectedWhichFeature, setSelectedWhichFeature] = useState(0);
	const [selectedWhichFeatureValue, setSelectedWhichFeatureValue] = useState(
		''
	);
	const [selectedDeviceTypeValue, setSelectedDeviceTypeValue] = useState('');
	const [selectedBrowserTypeValue, setSelectedBrowserTypeValue] = useState('');
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [registeredUser, setRegisteredUser] = useState(null);
	const [unregisteredUser, setUnregisteredUser] = useState(null);
	const [actioning, setActioning] = useState(null);
	const [adminMemberLookupValues, setAdminMemberLookupValues] = useState([]);
	const [formError, setFormError] = useState('');
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [reviewStatus, setReviewStatus] = useState('');
	const [adminStatus, setAdminStatus] = useState(null);
	const [adminStatusLookupValues, setAdminStatusLookupValues] = useState([]);
	const [adminOutcome, setAdminOutcome] = useState(null);
	const [adminOutcomeLookupValues, setAdminOutcomeLookupValues] = useState([]);
	const [adminNotesOther, setAdminNotesOther] = useState('');
	const [adminNotes, setAdminNotes] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const updateAdminAction = () => {
		const input = {
			formId: +id,
			formStatus: 'Review',
			actioningMember: +actioning || null,
			outcome: +adminOutcome || null,
			status: +adminStatus || null,
			notes: adminNotes || null,
			notesOther: adminNotesOther || null
		};
		AppSyncService.execute(saveAdminContactUsStatus, { input }).then(() => {
			setIsAdminActionUpdated(false);
		});
	};

	const completeForm = (status) => {
		if (+actioning === 0 || +adminStatus === 0 || +adminOutcome === 0) {
			setFormError('Please complete all required fields');
		} else {
			const input = {
				formId: +id,
				formStatus: status,
				actioningMember: +actioning || null,
				outcome: +adminOutcome || null,
				status: +adminStatus || null,
				notes: adminNotes || null,
				notesOther: adminNotesOther || null
			};
			AppSyncService.execute(saveAdminContactUsStatus, { input }).then(() => {
				setIsAdminActionUpdated(false);
				setReviewStatus(REVIEWED);
				addToast({ Message: 'Form status updated', IsSuccess: true });
				navigate('/review-list');
			});
		}
	};

	useEffect(() => {
		if (id) {
			AppSyncService.execute(getContactUsFormById, { id }).then((data) => {
				if (
					data?.data?.getContactUsFormById &&
					data?.data?.listContactReasons &&
					data?.data?.listWhichFeatures
				) {
					setAdminMemberLookupValues({
						actioningOptions: [BLANK_OPTION].concat(
							data.data.getAdminMembers?.map(
								({ id, externalId, firstName, lastName }) => ({
									id,
									value: `${firstName} ${lastName}`,
									externalId
								})
							)
						)
					});
					setFormData(data.data.getContactUsFormById);
					setContactReasons(data.data.listContactReasons);
					setSelectedContactReason(
						data.data.getContactUsFormById.contactReason
					);
					setSelectedContactReasonValue(
						data.data.listContactReasons?.find(
							(item) => item.id === data.data.getContactUsFormById.contactReason
						)?.value
					);
					setWhichFeatures(data.data.listWhichFeatures);
					setSelectedWhichFeature(data.data.getContactUsFormById.whichFeature);
					setSelectedWhichFeatureValue(
						data?.data?.listWhichFeatures?.find(
							(item) => item.id === data.data.getContactUsFormById.whichFeature
						)?.value
					);
					setSelectedDeviceTypeValue(
						data?.data?.listDeviceTypes?.find(
							(item) => item.id === data.data.getContactUsFormById.deviceType
						)?.value
					);
					setSelectedBrowserTypeValue(
						data?.data?.listBrowserTypes?.find(
							(item) => item.id === data.data.getContactUsFormById.browserType
						)?.value
					);
					if (data.data.getContactUsFormById?.member) {
						setRegisteredUser(data?.data?.getContactUsFormById.member);
					} else {
						setUnregisteredUser({
							firstName: data?.data?.getContactUsFormById?.firstName,
							lastName: data?.data?.getContactUsFormById?.lastName,
							preferredContact:
								data?.data?.getContactUsFormById?.preferredContact
						});
					}
					setReviewStatus(data?.data?.getContactUsFormById?.status);
					setAdminStatusLookupValues({
						statusOptions: [BLANK_OPTION].concat(
							data.data.listAdminActionStauses
						)
					});
					setAdminOutcomeLookupValues({
						outcomeOptions: [BLANK_OPTION].concat(
							data.data.listContactFormOutcomes
						)
					});
				}
				AppSyncService.execute(getContactUsAdminInfo, { id }).then((data) => {
					if (data?.data) {
						if (data?.data?.getContactUsAdminInfo) {
							setActioning(
								data.data.getContactUsAdminInfo.actioningMember || 0
							);
							setAdminNotes(data.data.getContactUsAdminInfo.notes || '');
							setAdminStatus(data.data.getContactUsAdminInfo.status || 0);
							setAdminOutcome(data.data.getContactUsAdminInfo.outcome || 0);
							setAdminNotesOther(
								data.data.getContactUsAdminInfo.notesOther || ''
							);
						}
					}
					setIsLoading(false);
				});
			});
		}
	}, [id]);

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const ContactUsAdminActions = () => {
		return (
			<>
				<AdminReviewSection
					title="Additional Notes (Admin Only)"
					darkContentBackground
					nogutter
				>
					<Col>
						<SectionRow>
							<SelectInputField
								id={`${PAGE_ID}-actioning-select-input`}
								label="Actioning"
								value={actioning}
								options={adminMemberLookupValues.actioningOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setActioning(event.target.value);
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
							<SelectInputField
								id={`${PAGE_ID}-status-select-input`}
								label="Admin Status"
								value={adminStatus}
								options={adminStatusLookupValues.statusOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setAdminStatus(event.target.value);
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
							<SelectInputField
								id={`${PAGE_ID}-outcome-select-input`}
								label="Outcome"
								value={adminOutcome}
								options={adminOutcomeLookupValues.outcomeOptions}
								onChange={(event) => {
									setFormError('');
									setIsAdminActionUpdated(true);
									setAdminOutcome(event.target.value);
								}}
								isRequired
								disabled={reviewStatus === REVIEWED}
							/>
						</SectionRow>
						{adminOutcomeLookupValues &&
							isOptionOther(
								adminOutcomeLookupValues.outcomeOptions,
								adminOutcome
							) && (
								<SectionRow>
									<Col className={classes.notesContainer}>
										<div className={classes.fieldTitle}>Other</div>
										<FormTextArea
											id={`${PAGE_ID}-notes-input-other`}
											key={`${PAGE_ID}-notes-input-other`}
											rows="1"
											value={adminNotesOther}
											onChange={(e) => {
												setIsAdminActionUpdated(true);
												setAdminNotesOther(e.target.value);
											}}
											disabled={reviewStatus === REVIEWED}
										/>
									</Col>
								</SectionRow>
							)}
						<SectionRow>
							<Col className={classes.notesContainer}>
								<div className={classes.fieldTitle}>Notes</div>
								<FormTextArea
									id={`${PAGE_ID}-notes-input`}
									key={`${PAGE_ID}-notes-input`}
									value={adminNotes}
									onChange={(e) => {
										setIsAdminActionUpdated(true);
										setAdminNotes(e.target.value);
									}}
									disabled={reviewStatus === REVIEWED}
								/>
							</Col>
						</SectionRow>
					</Col>
					<Row>
						<Col>
							<div className={classes.saveRequest}>
								<span className={classes.saveContainer}>
									<input
										type="button"
										value={
											isAdminActionUpdated && reviewStatus !== REVIEWED
												? 'Save Admin Details'
												: 'Saved'
										}
										className={
											isAdminActionUpdated && reviewStatus !== REVIEWED
												? classes.primaryFormButton
												: classes.disabledFormButton
										}
										onClick={() => updateAdminAction()}
									/>
								</span>
								{isLoading && (
									<span style={{ float: 'right', marginTop: 10 }}>
										<LoaderSpinner
											status={true}
											height="22"
											color="cadetblue"
										/>
									</span>
								)}
							</div>
						</Col>
					</Row>
					<hr className={classes.rule} />
					<Row nogutter style={{ borderBottom: '1px solid #b2b2b2' }}>
						<Col>
							{formError && (
								<span className={classes.errorMessage}>{formError}</span>
							)}
						</Col>
						<Col>
							<div className={classes.saveRequest}>
								<span className={classes.saveContainer}>
									<div
										className={classes.saveRequest}
										style={{ marginTop: 20, marginBottom: 20 }}
									>
										<span>
											<input
												type="button"
												value={
													reviewStatus === REVIEWED ? 'Completed' : 'Complete'
												}
												className={
													reviewStatus === REVIEWED
														? classes.disabledFormButtonComplete
														: classes.primaryFormButtonComplete
												}
												onClick={() => completeForm(REVIEWED)}
											/>
										</span>
									</div>
								</span>
							</div>
						</Col>
					</Row>
				</AdminReviewSection>
			</>
		);
	};

	return (
		<PageContainer>
			<SEO title="Contact Us Review" />
			<HeaderBanner
				title="Feedback or Enquiry Review"
				rightTitle={`Status: ${reviewStatus}`}
				styles={{ marginBottom: '0px' }}
			/>
			<HeaderBanner
				title={
					<>
						Personal Profile
						{registeredUser && (
							<a
								className={classes.profileLink}
								onClick={() => navigate(`/member/${formData.member.id}`)}
							>
								View Personal Profile
							</a>
						)}
					</>
				}
				styles={{ marginBottom: '0px' }}
			/>
			{registeredUser && (
				<div className={classes.container}>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								id={`${PAGE_ID}-first-name`}
								title="First Name"
								value={registeredUser.firstName}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								id={`${PAGE_ID}-last-name`}
								title="Last Name"
								value={registeredUser.lastName}
							/>
						</Col>
					</Row>
				</div>
			)}
			{unregisteredUser && (
				<div className={classes.container}>
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								id={`${PAGE_ID}-first-name`}
								title="First Name"
								value={unregisteredUser.firstName}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								id={`${PAGE_ID}-last-name`}
								title="Last Name"
								value={unregisteredUser.lastName}
							/>
						</Col>
						<Col xs={12} lg={4}>
							<FormLabelField
								id={`${PAGE_ID}-preferred-contact`}
								title="	Preferred contact (email or phone)"
								value={unregisteredUser.preferredContact}
							/>
						</Col>
					</Row>
				</div>
			)}
			<HeaderBanner title="Form Details" styles={{ marginBottom: '0px' }} />
			<div className={classes.container}>
				<Row>
					<Col xs={12}>
						<FormLabelField
							id={`${PAGE_ID}-contact-reason`}
							title="Contact Reason*"
							value={selectedContactReasonValue}
						/>
					</Col>
				</Row>
				{contactReasons &&
					checkDropDownValue(
						contactReasons,
						selectedContactReason,
						'General feedback'
					) && (
						<>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-contact-reason`}
										title="Feedback*"
										value={formData.generalFeedback}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-want-contact`}
										title="Do you want us to contact you about this?*"
										value={formData.wantContact ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						</>
					)}
				{contactReasons &&
					checkDropDownValue(
						contactReasons,
						selectedContactReason,
						'Help me with something'
					) && (
						<>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-want-contact`}
										title="How can we help?*"
										value={formData.howCanWeHelp}
									/>
								</Col>
							</Row>
						</>
					)}
				{contactReasons &&
					checkDropDownValue(
						contactReasons,
						selectedContactReason,
						'Page or feature not working'
					) && (
						<>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-which-feature`}
										title="Which Feature?*"
										value={selectedWhichFeatureValue}
									/>
								</Col>
							</Row>
							{selectedWhichFeature &&
								checkDropDownValue(
									whichFeatures,
									selectedWhichFeature,
									'Other'
								) && (
									<Col xs={12}>
										<FormLabelField
											id={`${PAGE_ID}-which-feature-other`}
											title="Please describe the feature as best you can."
											value={formData.whichFeatureOther}
										/>
									</Col>
								)}
							{selectedDeviceTypeValue && (
								<Row>
									<Col xs={12}>
										<FormLabelField
											id={`${PAGE_ID}-which-device`}
											title="Which Device?*"
											value={selectedDeviceTypeValue}
										/>
									</Col>
								</Row>
							)}
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-browser-type`}
										title="Which Browser Type?"
										value={
											selectedBrowserTypeValue ||
											'User did not select a browser.'
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-more-information`}
										title="Please provide more information (for example, what actions did you complete or fields filled in? Did any error message show?)"
										value={
											formData.moreInformation ||
											'User did not provide additional information.'
										}
									/>
								</Col>
							</Row>
							<Row>
								{formData?.supportingImages?.length > 0 && (
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												{`Supporting image upload `}
											</div>
											<UploadPhoto
												formats={['png', 'jpeg']}
												data={formData.supportingImages}
												getImage={getImage}
												canEdit={false}
											/>
										</div>
									</Col>
								)}
							</Row>
						</>
					)}
				{contactReasons &&
					checkDropDownValue(
						contactReasons,
						selectedContactReason,
						'Other'
					) && (
						<>
							<Row>
								<Col xs={12}>
									<FormLabelField
										id={`${PAGE_ID}-other-info`}
										title="Please provide more information*"
										value={formData?.otherInformation}
									/>
								</Col>
							</Row>
						</>
					)}
				{contactReasons &&
					formData.contactDetails &&
					(formData.wantContact ||
						checkDropDownValue(
							contactReasons,
							selectedContactReason,
							'Help me with something'
						)) && (
						<Row>
							<Col xs={12}>
								<FormLabelField
									id={`${PAGE_ID}-general-contact-details`}
									title="Please let us know your contact details"
									value={formData.contactDetails}
								/>
							</Col>
						</Row>
					)}
			</div>
			{ContactUsAdminActions()}
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</PageContainer>
	);
};

export default ContactUsReview;

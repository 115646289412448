import React from 'react';
import Table from '../../../Table';
import { COLUMNS_RACE_SUMMARY, COLUMNS_CLASS_SUMMARY } from '../../constants';

const FormSummary = ({ formSummary }) => {
	const { raceTypeSummary, classSummary } = formSummary;
	return (
		<>
			<Table
				id="race-summary-table"
				columns={COLUMNS_RACE_SUMMARY}
				data={raceTypeSummary || []}
				nullString="0-0-0-0"
			/>
			<Table
				id="class-summary-table"
				columns={COLUMNS_CLASS_SUMMARY}
				data={classSummary || []}
			/>
		</>
	);
};

export default FormSummary;

import React, { useRef } from 'react';
import { checkValidFileType, checkValidFileSize } from './helpers';

const FileUploader = ({
	renderComponent,
	onFileUpload,
	validFileTypes,
	onUploadError,
	maxFileSizeInMB
}) => {
	const fileUploadInputRef = useRef(null);

	const onButtonClick = () => {
		fileUploadInputRef.current.click();
	};

	const onUpload = (e) => {
		const file = e.target.files[0];
		const isValidFileType = checkValidFileType(validFileTypes, file);
		const isValidFileSize = checkValidFileSize(maxFileSizeInMB, file);
		if (isValidFileType && isValidFileSize) {
			onFileUpload(e.target.files[0]);
		} else {
			onUploadError();
		}
	};

	return (
		<>
			<input
				id="file-uploade-input"
				type="file"
				accept=".png, .jpg, .jpeg"
				ref={fileUploadInputRef}
				style={{ display: 'none' }}
				onChange={onUpload}
			/>
			{renderComponent(onButtonClick)}
		</>
	);
};

export default FileUploader;

import { createUseStyles } from 'react-jss';
import CalendarIcon from '../../assets/icons/Calendar_icon.svg';
import Tick from '../../assets/icons/Light_Check_Validated_icon.svg';
import Cross from '../../assets/icons/Light_Cross_Not_Validated_icon.svg';

export default createUseStyles(() => ({
	dateContainerDefault: {
		width: '100%'
	},
	dateContainerVerified: {
		width: '100%',
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${Tick})`,
			marginTop: 7,
			right: 50
		}
	},
	dateContainerNotVerified: {
		width: '100%',
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${Cross})`,
			marginTop: 7,
			right: 50
		}
	},
	dateContainer: {
		width: '100%',
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${CalendarIcon})`,
			marginTop: 7,
			right: 25
		}
	},
	errorDateContainer: {
		width: '100%',
		border: '1px solid red',
		borderRadius: 5,
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${CalendarIcon})`,
			marginTop: 7,
			right: 25
		}
	},
	dateContainerBasic: {
		width: '100%',
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${CalendarIcon})`,
			marginTop: 7,
			right: 10
		}
	},
	dateContainerProfile: {
		width: '100%',
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${CalendarIcon})`,
			marginTop: 7,
			right: 45
		}
	},
	dateField: {
		border: '1px solid #c7ced1',
		fontSize: 15,
		minWidth: '100%',
		boxSizing: 'border-box',
		height: 39,
		paddingLeft: 16,
		borderRadius: 4,
		'&:focus': {
			outline: 'none'
		},
		reactDatepicker__closeIcon: {
			padding: 50
		}
	},
	filterFoalDate: {
		border: '1px solid #c7ced1',
		fontSize: 15,
		width: '100%',
		boxSizing: 'border-box',
		height: 40,
		paddingLeft: 10,
		borderRadius: 4,
		'&:focus': {
			outline: 'none'
		},
		reactDatepicker__closeIcon: {
			padding: 50
		}
	},
	popperClassName: {
		zIndex: 10
	}
}));

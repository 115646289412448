import React, { useState, useEffect } from 'react';
import useStyles from './styles.js';
import PageContainer from '../PageContainer/index.js';
import SEO from '../seo.js';
import HeaderBanner from '../HeaderBanner';
import LoaderSpinner from '../LoaderSpinner';
import AppSyncService from '../../graphql/AppSyncService.js';
import { concludeOHEP, getOHEPData } from './queries.js';
import { useParams } from '@reach/router';
import { BLANK_OPTION } from '../../utils/constants/index.js';
import { Col, Row } from 'react-grid-system';
import FormDropDown from '../FormDropDown/index.js';
import { validation, TOGGLE_DEPENDENT, REGEX } from './validation.js';
import ResizingTextArea from '../ResizingTextArea/index.js';
import { formatDate } from '../../utils/helpers/index.js';
import FormDatePicker from '../FormDatePicker/index.js';
import Toggle from '../Toggle';
import { DRAFT, APPROVED } from '../../utils/constants';
import { useToast } from '../../hooks/useToast';
import { navigate } from 'gatsby';
import { useAuth } from '../../hooks/useAuth';
import {
	getHorseLocation,
	saveDeceasedThoroughbredForm,
	saveHorseLocation,
	setOttProgramAndStatus,
	unclaimHorseForAll
} from '../../graphql/custom.queries';
import PrimaryButton from '../PrimaryButton/index.js';
import { getOhepNominationApprovedFormData } from '../OHEPNoticeToProceedForm/queries.js';

const PAGE_ID = 'Conclude-OHEP';
const validationFields = [
	'areaOfEuthanasia',
	'serviceProviderOfEuthanasia',
	'euthanasiaDate',
	'methodOfEuthanasia',
	'serviceProviderWhoCollected',
	'collectionDate',
	'totalCost',
	'acceptanceNotice',
	'noticeToProceed',
	'actioning',
	'confirmedServiceProvider'
];

const ConcludeOHEP = () => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [recentOhepNomination, setRecentOhepNomination] = useState({});
	const [areasOfEuthanasia, setAreasOfEuthanasia] = useState([]);
	const [methodsOfEuthanasia, setMethodsOfEuthanasia] = useState([]);
	const [confirmedServiceProviders, setConfirmedServiceProviders] = useState(
		[]
	);
	const [ohepPrices, setOHEPPrices] = useState([]);
	let { horseCode } = useParams();
	const [validationOn, setValidationOn] = useState(false);
	const [errors, setErrors] = useState({});
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [submission, setSubmission] = useState(false);
	const [adminMemberLookupValues, setAdminMemberLookupValues] = useState([]);
	const [horseLocation, setHorseLocation] = useState(null);

	const [values, setValues] = useState({
		id: {
			value: null,
			isRequired: false
		},
		areaOfEuthanasia: {
			value: 0,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select the Area of Euthanasia.'
		},
		serviceProviderOfEuthanasia: {
			value: '',
			isRequired: true,
			conditions: [],
			errorMessage: 'Please enter the Euthanasia Service Provider details.'
		},
		euthanasiaDate: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select the Euthanasia Date.'
		},
		methodOfEuthanasia: {
			value: 0,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select the method of euthanasia.'
		},
		horseRemoved: {
			value: false,
			isRequired: false
		},
		serviceProviderWhoCollected: {
			value: '',
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'horseRemoved',
					value: true,
					errorMessage: 'Service Provider who collected is required'
				}
			]
		},
		collectionDate: {
			value: null,
			isRequired: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'horseRemoved',
					value: true,
					errorMessage: 'Collection Date is required'
				}
			]
		},
		totalCost: {
			value: '',
			isTouched: false,
			errorMessage: 'Total Cost is required',
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for total cost is required'
				}
			],
			isRequired: true
		},
		confirmedServiceProvider: {
			value: 0,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must confirm the service provider.'
		},
		acceptanceNotice: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select the acceptance notice date.'
		},
		noticeToProceed: {
			value: null,
			isRequired: true,
			conditions: [],
			errorMessage: 'You must select the notice to proceed date.'
		},
		euthanasiaInvoiceReceived: {
			value: null,
			isRequired: false
		},
		removalInvoiceReceived: {
			value: null,
			isRequired: false
		},
		invoiceToProcurement: {
			value: null,
			isRequired: false
		},
		actioning: {
			value: 0,
			isRequired: true,
			conditions: [],
			errorMessage: 'Please select the actioning admin member.'
		}
	});

	const generatePayload = (status) => {
		return {
			horseCode: horseCode,
			id: values.id.value || null,
			areaOfEuthanasia: values.areaOfEuthanasia.value || null,
			providerOfEuthanasia: values.serviceProviderOfEuthanasia.value || null,
			euthanasiaDate: values.euthanasiaDate.value
				? formatDate(values.euthanasiaDate.value)
				: null,
			methodOfEuthanasia: values.methodOfEuthanasia.value || null,
			horseRemoved: values.horseRemoved.value || null,
			providerWhoCollected: values.serviceProviderWhoCollected.value || null,
			collectionDate: values.collectionDate.value
				? formatDate(values.collectionDate.value)
				: null,
			totalCost: values.totalCost.value || null,
			acceptanceNotice: values.acceptanceNotice.value
				? formatDate(values.acceptanceNotice.value)
				: null,
			noticeToProceed: values.noticeToProceed.value
				? formatDate(values.noticeToProceed.value)
				: null,
			euthanasiaInvoiceReceived: values.euthanasiaInvoiceReceived.value
				? formatDate(values.euthanasiaInvoiceReceived.value)
				: null,
			removalInvoiceReceived: values.removalInvoiceReceived.value
				? formatDate(values.removalInvoiceReceived.value)
				: null,
			invoiceToProcurement: values.invoiceToProcurement.value
				? formatDate(values.invoiceToProcurement.value)
				: null,
			status: status,
			byMemberId: values.actioning.value || null,
			confirmedProvider: values.confirmedServiceProvider.value || null
		};
	};

	const populateFormValues = (data) => {
		let dataModel = { ...values };
		dataModel = {
			resAddress: {
				...dataModel.resAddress,
				value: data.member?.residentialAddress?.address
					? data.member?.residentialAddress?.address
					: data.residentalAddress
			},
			acceptanceNotice: {
				...values.acceptanceNotice,
				value: data.acceptanceNotice
			},
			areaOfEuthanasia: {
				...values.areaOfEuthanasia,
				value: data.areaOfEuthanasia
			},
			byMemberID: {
				...values.byMemberID,
				value: data.byMemberID
			},
			collectionDate: {
				...values.collectionDate,
				value: data.collectionDate
			},
			euthanasiaDate: {
				...values.euthanasiaDate,
				value: data.euthanasiaDate
			},
			euthanasiaInvoiceReceived: {
				...values.euthanasiaInvoiceReceived,
				value: data.euthanasiaInvoiceReceived
			},
			horseRemoved: {
				...values.horseRemoved,
				value: data.horseRemoved
			},
			id: {
				...values.id,
				value: data.id
			},
			invoiceToProcurement: {
				...values.invoiceToProcurement,
				value: data.invoiceToProcurement
			},
			methodOfEuthanasia: {
				...values.methodOfEuthanasia,
				value: data.methodOfEuthanasia
			},
			noticeToProceed: {
				...values.noticeToProceed,
				value: data.noticeToProceed
			},
			serviceProviderOfEuthanasia: {
				...values.serviceProviderOfEuthanasia,
				value: data.providerOfEuthanasia
			},
			serviceProviderWhoCollected: {
				...values.serviceProviderWhoCollected,
				value: data.providerWhoCollected
			},
			removalInvoiceReceived: {
				...values.removalInvoiceReceived,
				value: data.removalInvoiceReceived
			},
			totalCost: {
				...values.totalCost,
				value: data.totalCost
			},
			actioning: {
				...values.actioning,
				value: data.byMemberId
			},
			confirmedServiceProvider: {
				...values.confirmedServiceProvider,
				value: data.confirmedProvider
			}
		};
		setValues(dataModel);
	};

	useEffect(() => {
		const fetchNomination = async () => {
			const { data } = await AppSyncService.execute(
				getOhepNominationApprovedFormData,
				{
					horseCode: parseInt(horseCode)
				}
			);
			if (data?.getOhepNominationApprovedForm) {
				setRecentOhepNomination(data.getOhepNominationApprovedForm);
			}
		};

		if (horseCode) fetchNomination();
	}, [horseCode]);

	useEffect(() => {
		AppSyncService.execute(getOHEPData, { horseCode: horseCode }).then(
			(data) => {
				if (data?.data?.listAreaOfEuthanasia) {
					setAreasOfEuthanasia(
						[BLANK_OPTION].concat(data.data.listAreaOfEuthanasia)
					);
				}
				if (data?.data?.listMethodOfEuthanasia) {
					setMethodsOfEuthanasia(
						[BLANK_OPTION].concat(data.data.listMethodOfEuthanasia)
					);
				}
				if (data?.data?.retrieveConcludeOHEPConfirmedProviders) {
					setConfirmedServiceProviders(
						[BLANK_OPTION].concat(
							data.data.retrieveConcludeOHEPConfirmedProviders
						)
					);
				}
				if (data?.data?.retrieveConcludeOHEPPrices) {
					setOHEPPrices(
						[BLANK_OPTION].concat(data.data.retrieveConcludeOHEPPrices)
					);
				}
				if (data?.data?.retrieveConcludeOHEP?.id) {
					populateFormValues(data.data.retrieveConcludeOHEP);
				}
				if (data?.data?.getAdminMembers) {
					let adminMemberLookupValues = [BLANK_OPTION].concat(
						data.data.getAdminMembers?.map(
							({ id, externalId, firstName, lastName }) => ({
								id,
								value: `${firstName} ${lastName}`,
								externalId
							})
						)
					);
					setAdminMemberLookupValues(adminMemberLookupValues);
				}
			}
		);
		AppSyncService.execute(getHorseLocation, { horseCode: horseCode }).then(
			(result) => {
				if (result?.data?.getHorseProfile?.location) {
					setHorseLocation(result.data.getHorseProfile.location);
				}
			}
		);
		setIsLoading(false);
	}, [horseCode]);

	useEffect(() => {
		setErrors(validation(values, validationOn, validationFields));
	}, [values]);

	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			setSubmission(true);
			const payload = generatePayload(APPROVED);
			AppSyncService.execute(concludeOHEP, { input: payload }).then((data) => {
				if (data?.data?.concludeOHEP?.id) {
					let programInput = {
						programId: 6,
						statusId: 23,
						asOfDate: formatDate(values.euthanasiaDate.value),
						horseCode: +horseCode,
						memberExternalId: currentUser?.UserId
					};
					AppSyncService.execute(setOttProgramAndStatus, programInput).then(
						() => {
							addToast({
								Message: 'Conclude OHEP form recorded.',
								IsSuccess: true
							});
							const deceasedCauseOfDeathId = 2; //@NOTE - 2 is equal to Euthanised
							const primaryConcernId =
								recentOhepNomination?.adminData?.primaryConcern?.id;
							const deceasedInput = {
								horseCode: Number(horseCode),
								deceasedThoroughbredSourceId: 4, //@NOTE - 4 is equal to Conclude OHEP
								deceasedDateOfDeath: formatDate(values.euthanasiaDate.value),
								deceasedCauseOfDeathId,
								memberId: recentOhepNomination?.member?.id,
								euthanisedReasonId:
									primaryConcernId == 3
										? 1
										: primaryConcernId == 2
										? 2
										: primaryConcernId == 4
										? 4
										: primaryConcernId == 1
										? 2
										: null,
								deceasedReasonForDeathOtherText:
									primaryConcernId == 4
										? recentOhepNomination?.adminData?.primaryConcernOther
										: null,
								euthanisedInjuryOther: null,
								euthanisedIllnessOther: null,
								unsuitableReasons: [],
								euthanisedUnsuitableOther: null,
								deceasedReasonForDeathOther: [],
								deceasedKnackeryPostcode: null,
								deceasedKnackerySuburb: null,
								deceasedOtherPostcode: null,
								deceasedOtherSuburb: null,
								informationAboutDeath: null,
								hasOwnerBeenNotified: false,
								status: 'Review',
								injuries: [],
								illnesses: [],
								lastSeenDate: formatDate(values.euthanasiaDate.value),
								relationshipId: recentOhepNomination?.relationshipToHorse?.id,
								relationshipOther:
									recentOhepNomination?.relationshipToHorseOther
							};
							AppSyncService.execute(saveDeceasedThoroughbredForm, {
								input: deceasedInput
							});
							const input = {
								horse_code: +horseCode,
								member_id: currentUser?.UserId,
								unclaim_reason: 1, //1 is equivalent to Deceased on unclaim_reason
								full_circle_interested: false,
								cause_of_death_id: 2,
								date_of_death: formatDate(values.euthanasiaDate.value),
								other_details: null,
								deceased_other_info: null,
								knackery_location: null
							};
							AppSyncService.execute(unclaimHorseForAll, {
								input
							}).then((unclaimResult) => {
								if (unclaimResult?.data?.unclaimHorseForAll) {
									if (horseLocation) {
										AppSyncService.execute(saveHorseLocation, {
											input: {
												address: horseLocation.address,
												country: horseLocation.country,
												horseCode: +horseCode,
												isValidated: horseLocation.isValidated,
												postcode: horseLocation.postcode,
												stabledDate: horseLocation.stabledDate,
												state: horseLocation.state,
												suburb: horseLocation.suburb,
												sourceId: 17 //@NOTE: 17 is equal to OHEP Conclude
											}
										}).then((result) => {
											if (result?.data?.saveHorseLocation) {
												navigate('/');
											} else {
												addToast({
													Message: 'Failed to save horse location',
													IsSuccess: false
												});
												setSubmission(false);
											}
										});
									} else navigate('/');
								} else {
									addToast({
										Message: 'Failed to unclaim horse',
										IsSuccess: false
									});
									setSubmission(false);
								}
							});
						}
					);
				} else {
					addToast({
						Message: 'Conclude OHEP form failed to submit.',
						IsSuccess: false
					});
					setSubmission(false);
				}
			});
		}
	};

	const handleSave = (e) => {
		e.preventDefault();
		setValidationOn(false);
		setErrors({});
		const payload = generatePayload(DRAFT);
		AppSyncService.execute(concludeOHEP, { input: payload }).then((data) => {
			if (data?.data?.concludeOHEP?.id) {
				let items = { ...values };
				let item = {
					...items['id'],
					value: data.data.concludeOHEP.id
				};
				items['id'] = item;
				setValues(items);
				addToast({
					Message: 'Draft Conclude OHEP form saved.',
					IsSuccess: true
				});
			} else {
				addToast({
					Message: 'Draft Conclude OHEP form failed to save.',
					IsSuccess: false
				});
			}
		});
	};

	const handleChange = (e) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: e.target.value
		};
		items[e.target.name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};

	const handleChangeToggle = (val, name, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val
		};
		items[name] = item;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	return (
		<PageContainer>
			<SEO title="Conclude OHEP" />
			<HeaderBanner title="Conclude OHEP" styles={{ marginBottom: '0px' }} />
			<HeaderBanner
				title="OHEP Details"
				top
				styles={{ marginBottom: '0px', marginTop: '25px' }}
			/>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{!isLoading && (
					<>
						<Row>
							<Col md={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Area of Euthanasia*</div>

									<FormDropDown
										id={`${PAGE_ID}-area-of-euthanasia-select`}
										name="areaOfEuthanasia"
										selectedId={values.areaOfEuthanasia.value}
										items={areasOfEuthanasia.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.areaOfEuthanasia}
									/>
									<span className={classes.errorTextDropDown}>
										{errors.areaOfEuthanasia}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Service Provider of Euthanasia.*
									</div>
									<ResizingTextArea
										borderColor={
											errors.serviceProviderOfEuthanasia ? 'red' : '#c7ced1'
										}
										name="serviceProviderOfEuthanasia"
										id={`${PAGE_ID}-serviceProviderOfEuthanasia`}
										value={values.serviceProviderOfEuthanasia.value}
										onChange={handleChange}
										maxChars={500}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
									/>
									<span className={classes.errorTextResizingArea}>
										{errors.serviceProviderOfEuthanasia}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} lg={6}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Euthanasia Date*
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-euthanasiaDate`}
										isError={errors.euthanasiaDate ? true : false}
										DateValue={values.euthanasiaDate.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'euthanasiaDate')
										}
									/>
									<span className={classes.errorText}>
										{errors.euthanasiaDate}
									</span>
								</div>
							</Col>
							<Col md={12} lg={6}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Method of Euthanasia*
									</div>

									<FormDropDown
										id={`${PAGE_ID}-method-of-euthanasia-select`}
										name="methodOfEuthanasia"
										selectedId={values.methodOfEuthanasia.value}
										items={methodsOfEuthanasia.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.methodOfEuthanasia}
									/>
									<span className={classes.errorTextDropDown}>
										{errors.methodOfEuthanasia}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Was the horse removed from the property?
									</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) =>
											handleChangeToggle(value, 'horseRemoved', [
												{ item: 'serviceProviderWhoCollected', value: '' },
												{ item: 'collectionDate', value: null }
											])
										}
										value={values.horseRemoved.value}
									/>
								</div>
							</Col>
						</Row>
						{values.horseRemoved.value && (
							<>
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: 'left' }}
											>
												Service Provider who collected.*
											</div>
											<ResizingTextArea
												borderColor={
													errors.serviceProviderWhoCollected ? 'red' : '#c7ced1'
												}
												name="serviceProviderWhoCollected"
												id={`${PAGE_ID}-serviceProviderWhoCollected`}
												value={values.serviceProviderWhoCollected.value}
												onChange={handleChange}
												maxChars={500}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
											/>
											<span className={classes.errorTextResizingArea}>
												{errors.serviceProviderWhoCollected}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div
												className={classes.fieldTitle}
												style={{ float: 'left' }}
											>
												Collection date*
											</div>
											<FormDatePicker
												id={`${PAGE_ID}-collectionDate`}
												isError={errors.collectionDate ? true : false}
												DateValue={values.collectionDate.value}
												updateSelection={(value) =>
													handleDateUpdate(value, 'collectionDate')
												}
											/>
											<span className={classes.errorText}>
												{errors.collectionDate}
											</span>
										</div>
									</Col>
								</Row>
							</>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Total Cost*</div>

									<FormDropDown
										id={`${PAGE_ID}-totalCost-select`}
										name="totalCost"
										selectedId={values.totalCost.value}
										items={ohepPrices.map((item) => {
											return {
												id: item.id,
												value: item.id === 0 ? item.value : `$${item.value}`
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.totalCost}
									/>
									<span className={classes.errorTextDropDown}>
										{errors.totalCost}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Service Providers Used*
									</div>

									<FormDropDown
										id={`${PAGE_ID}-confirmedServiceProvider-select`}
										name="confirmedServiceProvider"
										selectedId={values.confirmedServiceProvider.value}
										items={confirmedServiceProviders.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.confirmedServiceProvider}
									/>
									<span className={classes.errorTextDropDown}>
										{errors.confirmedServiceProvider}
									</span>
								</div>
							</Col>
						</Row>
					</>
				)}
			</div>
			<HeaderBanner
				title="Forms Received"
				top
				styles={{ marginBottom: '0px', marginTop: '25px' }}
			/>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{!isLoading && (
					<>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Acceptance Notice*
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-acceptanceNotice`}
										isError={errors.acceptanceNotice ? true : false}
										DateValue={values.acceptanceNotice.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'acceptanceNotice')
										}
									/>
									<span className={classes.errorText}>
										{errors.acceptanceNotice}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Notice To Proceed*
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-noticeToProceed`}
										isError={errors.noticeToProceed ? true : false}
										DateValue={values.noticeToProceed.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'noticeToProceed')
										}
									/>
									<span className={classes.errorText}>
										{errors.noticeToProceed}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Euthanasia Invoice Received
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-euthanasiaInvoiceReceived`}
										DateValue={values.euthanasiaInvoiceReceived.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'euthanasiaInvoiceReceived')
										}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Removal Invoice received
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-removalInvoiceReceived`}
										DateValue={values.removalInvoiceReceived.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'removalInvoiceReceived')
										}
									/>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Invoice to Procurement
									</div>
									<FormDatePicker
										id={`${PAGE_ID}-invoiceToProcurement`}
										DateValue={values.invoiceToProcurement.value}
										updateSelection={(value) =>
											handleDateUpdate(value, 'invoiceToProcurement')
										}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Actioning*</div>

									<FormDropDown
										id={`${PAGE_ID}-actioning`}
										name="actioning"
										selectedId={values.actioning.value}
										items={adminMemberLookupValues.map((item) => {
											return {
												id: item.id,
												value: item.value
											};
										})}
										onChange={(e) => handleChangeDropDown(e, [])}
										showBlank
										isError={errors.actioning}
									/>
									<span className={classes.errorTextDropDown}>
										{errors.actioning}
									</span>
								</div>
							</Col>
						</Row>
					</>
				)}
			</div>
			<div className={classes.buttonContainer}>
				<span
					id={`${PAGE_ID}-cancel-button`}
					className={classes.cancelButton}
					onClick={() => {
						navigate('/');
					}}
				>
					Cancel
				</span>
				<div className={classes.saveRequest}>
					{errors.length > 0 && (
						<span className={classes.errorMessage}>
							Please complete all required fields
						</span>
					)}
					<span
						id={`${PAGE_ID}-save-draft-button`}
						className={classes.saveButton}
						onClick={handleSave}
					>
						Save Draft
					</span>
					<span>
						<PrimaryButton
							onClick={handleSubmit}
							className={
								submission
									? classes.disabledCompleteFormButton
									: classes.completeFormButton
							}
							id={`${PAGE_ID}-submit`}
							disabled={submission ? true : false}
						>
							{submission ? 'Submitting' : 'Submit'}
						</PrimaryButton>
					</span>
				</div>
			</div>
		</PageContainer>
	);
};

export default ConcludeOHEP;

import React, { useEffect, useState } from 'react';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import { OTTNews } from '../Mobile';
import SEO from '../seo';
import useStyles from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { formatDateReadable } from '../../utils/helpers';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { navigate } from '@reach/router';
import HeaderBanner from '../HeaderBanner';
import { useAuth } from '../../hooks/useAuth';
import { useParams } from '@reach/router';
import { Link, Typography } from '@material-ui/core';
import { boldSearch } from '../../utils/helpers/index';
import SearchInput from '../SearchInput/index';
import BasicPagination from '../BasicPagination/index';
import DefaultImage from '../../assets/images/OTT_Community_Logo_RGB.jpg';

const INITIAL_ARTICLES = 6;
const NEXT_ARTICLES = 3;
const currentDate = new Date();

const OTTNewsreel = () => {
	const classes = useStyles();
	const [newsArticles, setNewsArticles] = useState([]);
	const [unFilteredArticles, setUnFilteredArticles] = useState([]);
	const [counter, setCounter] = useState(INITIAL_ARTICLES);
	const { currentUser } = useAuth();
	const [articles, setArticles] = useState([]);
	const { tag } = useParams();
	const [searchValue, setSearchValue] = useState('');
	const [searchActive, setSearchActive] = useState(false);
	const [searchedArticles, setSearchedArticles] = useState([]);
	const [searchedArticlesCount, setSearchedArticlesCount] = useState(0);
	const [canSearch, setCanSearch] = useState(false);
	const [showNotFound, setShowNotFound] = useState(false);
	const [paginationPage, setPaginationPage] = useState(0);
	const [paginationCount, setPaginationCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const contentFulArticles = useStaticQuery(
		graphql`
			query ottNewsContent {
				newsFeed: allContentfulArticle(
					filter: { tags: { in: "News Feed" } }
					sort: { fields: publishTime, order: DESC }
				) {
					edges {
						node {
							id
							title
							summary
							publishTime
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
				communityContent: allContentfulArticle(
					filter: { tags: { in: "Community Contributors" } }
					sort: { fields: publishTime, order: DESC }
				) {
					edges {
						node {
							id
							title
							summary
							publishTime
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		retrieveContentfulArticles(currentUser);
	}, [currentUser, tag]);

	const retrieveContentfulArticles = (user) => {
		if (user) {
			if (tag === 'community') {
				setArticles(contentFulArticles.communityContent);
				setCanSearch(false);
				setSearchActive(false);
			} else {
				setArticles(contentFulArticles.newsFeed);
				setCanSearch(true);
			}
		} else if (tag === 'news') {
			setArticles(contentFulArticles.newsFeed);
			setCanSearch(true);
		}
	};

	useEffect(() => {
		if (articles.length !== 0) {
			let allArticles = articles.edges;
			let filteredArticles = allArticles.filter((a) => {
				var publishTime = new Date(a.node.publishTime);
				return publishTime.getTime() <= currentDate.getTime();
			});
			setUnFilteredArticles(filteredArticles);
			setNewsArticles(filteredArticles.slice(0, counter));
		}
	}, [articles]);

	const handleContainerOnBottom = () => {
		if (counter < articles.edges.length) {
			let nextCounter = counter + NEXT_ARTICLES;
			setNewsArticles(articles.edges.slice(0, nextCounter));
			setCounter(nextCounter);
		}
	};

	const goToArticle = (id) => {
		if (searchActive) {
			const newWindow = window.open(
				`/ott-news/${id}`,
				'_blank',
				'noopener,noreferrer'
			);
			if (newWindow) newWindow.opener = null;
		} else {
			navigate(`/ott-news/${id}`);
		}
	};

	const onHandleChange = (event) => {
		setSearchValue(event.target.value);
	};

	const onHandleChangePagination = (event, val) => {
		setPaginationPage(val);
	};

	const onSearch = () => {
		setSearchActive(true);
		if (searchValue.length !== 0) {
			let tempArticleNews = [];
			let copiedArticle = JSON.parse(JSON.stringify(unFilteredArticles));
			copiedArticle = copiedArticle.filter(
				(copied) =>
					copied.node.summary
						.toLowerCase()
						.includes(searchValue.toLowerCase()) ||
					copied.node.title.toLowerCase().includes(searchValue.toLowerCase())
			);
			copiedArticle.forEach((copied) => {
				copied.node.summary = boldSearch(copied.node.summary, searchValue);
				copied.node.title = boldSearch(copied.node.title, searchValue);
				tempArticleNews.push(copied);
			});

			setSearchedArticlesCount(tempArticleNews.length);
			setSearchedArticles(tempArticleNews);
			setShowNotFound(tempArticleNews.length === 0);
			setPaginationCount(tempArticleNews.length);
			setRowsPerPage(
				tempArticleNews.length >= 10 ? 10 : tempArticleNews.length
			);
			if (tempArticleNews.length <= 10) {
				setPaginationPage(0);
			}
		} else {
			setSearchedArticlesCount(unFilteredArticles.length);
			setShowNotFound(false);
			setPaginationCount(unFilteredArticles.length);
			setPaginationPage(0);
			setRowsPerPage(10);
		}
	};

	const onBackToLatestArticles = () => {
		setSearchActive(false);
		setSearchValue('');
		setShowNotFound(false);
		setSearchedArticles([]);
		setPaginationPage(0);
	};

	const renderArticles = () => {
		return newsArticles.map((news) => {
			return (
				<OTTNews
					isMobile
					key={news.node.id}
					id={news.node.id}
					onClick={() => goToArticle(news.node.id)}
					heading={news.node.title}
					body={news.node.summary}
					date={formatDateReadable(news.node.publishTime)}
					imageLink={news.node.previewImage?.fluid?.src}
				/>
			);
		});
	};

	const renderDesktopArticles = () => {
		let evaluateArticle = searchActive ? unFilteredArticles : newsArticles;
		let finalArticle =
			searchActive &&
			searchedArticles.length !== 0 &&
			searchedArticlesCount !== 0
				? searchedArticles
				: evaluateArticle;
		return showNotFound
			? onRenderNoResultsFound()
			: finalArticle
					.slice(
						paginationPage * rowsPerPage,
						searchActive
							? paginationPage * rowsPerPage + rowsPerPage
							: finalArticle.length
					)
					.map((news) => {
						return (
							<div
								className={
									searchActive
										? classes.resultContentSearch
										: classes.resultContent
								}
								key={news.node.id}
								onClick={() => goToArticle(news.node.id)}
							>
								{searchActive ? (
									<div>
										<div
											className={classes.summaryContainerSearch}
											id={news.node.id}
											onClick={() => goToArticle(news.node.id)}
											style={{
												marginBottom: 60
											}}
										>
											<div
												className={classes.summaryHeadingSearch}
												dangerouslySetInnerHTML={{ __html: news.node.title }}
											></div>
											<div className={classes.summaryDateSearch}>
												{formatDateReadable(news.node.publishTime)}
											</div>
											<Row>
												<Col sm={12} lg={7}>
													<div
														className={classes.summaryBody}
														dangerouslySetInnerHTML={{
															__html: news.node.summary
														}}
													></div>
												</Col>
												<Col sm={12} lg={5}>
													<img
														src={
															news.node.previewImage?.fluid?.src
																? news.node.previewImage?.fluid?.src
																: DefaultImage
														}
														className={classes.newsImage}
													/>
												</Col>
											</Row>
										</div>
									</div>
								) : (
									<div>
										<img
											src={news.node.previewImage?.fluid?.src}
											className={classes.newsImage}
										/>
										<div
											className={classes.summaryContainer}
											id={news.node.id}
											onClick={() => goToArticle(news.node.id)}
											style={{
												marginBottom: 60
											}}
										>
											<div className={classes.summaryHeading}>
												{news.node.title}
											</div>
											<div className={classes.summaryBody}>
												{news.node.summary}
											</div>
											<div className={classes.summaryDate}>
												{formatDateReadable(news.node.publishTime)}
											</div>
										</div>
									</div>
								)}
							</div>
						);
					});
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<SEO
				title={tag === 'news' ? 'OTT News' : 'Community Contributor Stories'}
			/>

			<div className={classes.ottNewsMobileTopContainer}>
				{renderArticles()}
			</div>
			<BottomScrollListener onBottom={() => handleContainerOnBottom()} />
		</div>
	);

	const onRenderNoResultsFound = () => {
		return (
			<>
				<div>
					<Container className={classes.pageDesktopContainer}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								gutterBottom
							>
								NEIGH!
							</Typography>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								gutterBottom
							>
								Sorry, no results were found
							</Typography>
							<Typography variant="h6" gutterBottom>
								Check your spelling or try more general words.
							</Typography>
							<Typography variant="h6" gutterBottom>
								{`If you still can't find what you are looking for`}{' '}
								<Link
									underline="always"
									onClick={() => navigate('/contact-us')}
								>
									Contact Us
								</Link>{' '}
							</Typography>
						</div>
					</Container>
				</div>
			</>
		);
	};

	const desktopComponent = (
		<>
			<div>
				<SEO
					title={tag === 'news' ? 'OTT News' : 'Community Contributor Stories'}
				/>{' '}
				{searchActive ? (
					<Container className={classes.pageDesktopContainer}>
						<HeaderBanner
							title={
								tag === 'news' ? 'OTT News' : 'Community Contributor Stories'
							}
							className={classes.desktopBannerStyles}
						/>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div>
								{`${searchedArticlesCount} Result${
									searchedArticlesCount < 2 ? '' : 's'
								}`}
							</div>
							<div>
								<SearchInput
									value={searchValue}
									onChangeFunc={onHandleChange}
									onClick={onSearch}
								/>
							</div>
						</div>
						<div>
							<div>
								<Link
									style={{ color: '#0085ca' }}
									onClick={() => onBackToLatestArticles()}
									underline="always"
								>
									Back to latest articles
								</Link>
							</div>
						</div>
						<div
							className={
								searchActive
									? classes.ottNewsDesktopTopContainerSearch
									: classes.ottNewsDesktopTopContainer
							}
						>
							{renderDesktopArticles()}
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<BasicPagination
									page={paginationPage}
									count={paginationCount}
									handleChange={onHandleChangePagination}
								/>
							</div>
						</div>
						<BottomScrollListener onBottom={() => handleContainerOnBottom()} />
					</Container>
				) : (
					<Container className={classes.pageDesktopContainer}>
						<HeaderBanner
							title={
								tag === 'news' ? 'OTT News' : 'Community Contributor Stories'
							}
							className={classes.desktopBannerStyles}
						/>{' '}
						{canSearch && (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<SearchInput
									value={searchValue}
									onChangeFunc={onHandleChange}
									onClick={onSearch}
								/>
							</div>
						)}
						<div className={classes.ottNewsDesktopTopContainer}>
							{renderDesktopArticles()}
						</div>
						<BottomScrollListener onBottom={() => handleContainerOnBottom()} />
					</Container>
				)}
			</div>
		</>
	);
	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default OTTNewsreel;

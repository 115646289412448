import React from 'react';
import { formatDateReadableVariant } from '../../utils/helpers';
import useStyles from './styles';
import { Link, navigate } from 'gatsby';
import HorseAvatar from '../HorseAvatar';

const ClaimedThoroughbredDataRow = ({ thoroughbred, isLastRow, isMobile }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<tr className={isLastRow ? classes.trbodyLast : classes.trbody}>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={
							thoroughbred?.horse?.ottInformation?.profilePhoto?.key
						}
						idPrefix={`my-stable-${thoroughbred?.horse?.horseCode}`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() =>
							navigate(`/horse-profile/${thoroughbred?.horse?.horseCode}`)
						}
						className={classes.profilePic}
					/>
				</td>
				<td className={classes.td}>
					<Link
						to={`/horse-profile/${thoroughbred?.horse?.horseCode}`}
						className="no-underline"
					>
						<span className={classes.tbodyName}>
							{thoroughbred?.horse?.ottInformation?.paddockName ??
								thoroughbred?.horse?.name}
						</span>
					</Link>
				</td>
				<td className={classes.td}>{thoroughbred?.horse?.colour}</td>
				{!isMobile && (
					<>
						<td className={classes.td}>{thoroughbred?.horse?.sex}</td>
						<td className={classes.td}>
							{`${thoroughbred?.horse?.location?.address ?? ''} 
						${
							thoroughbred?.horse?.location?.suburb ||
							thoroughbred?.horse?.location?.postcode
								? ','
								: ''
						} 
					${thoroughbred?.horse?.location?.suburb ?? ''} ${
								thoroughbred?.horse?.location?.postcode ?? ''
							}`}
						</td>
					</>
				)}
				{!isMobile && (
					<>
						<td className={classes.td}>
							{thoroughbred?.horse?.location?.stabledDate
								? formatDateReadableVariant(
										thoroughbred?.horse?.location?.stabledDate
								  )
								: ''}
						</td>
					</>
				)}
			</tr>
		</React.Fragment>
	);
};

export default ClaimedThoroughbredDataRow;

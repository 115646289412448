import React from 'react';
import useStyles from './styles';

const ResultColumn = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<div className={classes.resultColumn} {...rest}>
			<span>{children}</span>
		</div>
	);
};

export default ResultColumn;

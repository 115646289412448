import React from 'react';
import {
	formatDateReadableVariant,
	convertUTCTimeToLocal
} from '../../utils/helpers';
import useStyles from './styles';
import { navigate } from 'gatsby';

const EventDataRow = ({ event, isLastRow, isMobile }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<tr className={isLastRow ? classes.trbodyLast : classes.trbody}>
				<td className={classes.tbodyProfilePic}>
					<span
						className={
							!isMobile ? classes.tbodyNameTruncated : classes.tbodyName
						}
						onClick={() => navigate(`/add-event/${event.id}`)}
					>
						{`${event.eventName ? event.eventName : 'Unnamed Event'}`}
					</span>
				</td>
				<td className={classes.td}>
					<span className={!isMobile ? classes.longText : null}>
						{event.firstName && event.lastName && (
							<>
								{`${event.firstName} ${event.lastName} ${
									event.userEventOrganiser
										? `, ${event.userEventOrganiser}`
										: ''
								}`}
							</>
						)}
					</span>
				</td>
				<td className={classes.td}>
					<span className={!isMobile ? classes.longText : null}>
						{formatDateReadableVariant(event.eventStartDate)}{' '}
						{event.eventStartTime
							? convertUTCTimeToLocal(event.eventStartTime)
							: ''}
						{(event.eventStartTime || event.eventEndTime) && !event.eventEndDate
							? ' - '
							: ''}
						{!event.eventEndDate && (
							<>
								{' '}
								{event.eventEndTime
									? convertUTCTimeToLocal(event.eventEndTime)
									: ''}
							</>
						)}
					</span>
				</td>
				<td className={classes.td}>
					<span className={!isMobile ? classes.longText : null}>
						{event.eventEndDate && (
							<>
								{formatDateReadableVariant(event.eventEndDate)}{' '}
								{event.eventEndTime
									? convertUTCTimeToLocal(event.eventEndTime)
									: ''}
							</>
						)}
					</span>
				</td>
				<td className={classes.td}>
					<span className={!isMobile ? classes.longText : null}>
						{`${event.eventAddress ?? ''} 
						${event.eventSuburb || event.eventPostcode ? ',' : ''} 
					${event.eventSuburb ?? ''} ${event.eventPostcode ?? ''}`}
					</span>
				</td>
				<td className={classes.td}>
					<span className={!isMobile ? classes.longText : null}>
						{event.eventDiscipline
							? event.eventDiscipline
							: 'No Event Discipline Set'}
					</span>
				</td>
				<td className={classes.td}>
					{formatDateReadableVariant(event.lastSaved)}
				</td>
			</tr>
		</React.Fragment>
	);
};

export default EventDataRow;

import React from 'react';
import { useScreenClass } from 'react-grid-system';
import BannerImage from '../../assets/images/header_banner.png';
import Accordion from '../Accordion';

const StyledAccordion = ({ children, ...rest }) => {
	const screenClassForRender = useScreenClass();

	return (
		<Accordion
			dropDownStyle={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? { marginTop: 17 }
					: {}
			}
			titleStyle={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? { color: 'black', fontSize: 15 }
					: {}
			}
			styles={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? {
							marginTop: 0,
							background: `url(${BannerImage})`,
							backgroundSize: 'contain'
					  }
					: { marginTop: 0, background: `url(${BannerImage})` }
			}
			{...rest}
		>
			{children}
		</Accordion>
	);
};

export default StyledAccordion;

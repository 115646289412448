import React from 'react';
import useStyles from './styles';
import MenuItem from './Components/MenuItem';

const ScrollMenuGeneric = ({
	onClick,
	selectedSearch,
	menuList,
	customStyles
}) => {
	const classes = useStyles();
	return (
		<div
			className={classes.toggleContainer}
			style={{
				...(customStyles?.tabContainer && customStyles.tabContainer)
			}}
		>
			{menuList
				.filter((menuItem) => menuItem.show)
				.map((menuItem) => (
					<MenuItem
						id={menuItem.id}
						key={menuItem.key}
						onClick={() => onClick(menuItem.navigation)}
						title={menuItem.title}
						isSelectedTab={selectedSearch === menuItem.navigation}
						customStyles={customStyles}
					/>
				))}
		</div>
	);
};
export default ScrollMenuGeneric;

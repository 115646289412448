import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		height: 38,
		border: '1px solid #c7ced1',
		display: 'flex',
		justifyContent: 'space-between',
		background: 'white',
		width: '100%',
		borderRadius: 5
	},
	passive: {
		width: '100%',
		alignItems: 'center',
		border: '2px solid white',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: 4,
		cursor: 'pointer'
	},
	active: {
		width: '100%',
		color: 'white',
		fontWeight: 600,
		borderRadius: 4,
		background: '#006da6',
		alignItems: 'center',
		border: '2px solid white',
		display: 'flex',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	disabledpassive: {
		width: '100%',
		alignItems: 'center',
		border: '2px solid white',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: 4
	},
	disabledactive: {
		width: '100%',
		color: 'white',
		fontWeight: 600,
		borderRadius: 4,
		background: '#7a7f81',
		alignItems: 'center',
		border: '2px solid white',
		display: 'flex',
		justifyContent: 'center'
	}
}));

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import AccordionToggle from '../Accordion/AccordionToggle';
import {
	checkIsSmallScreen,
	convertToMultiListData,
	formatDateReadableVariant
} from '../../utils/helpers';
import { Col, Row, useScreenClass } from 'react-grid-system';
import {
	CommonKeys,
	DECLINED,
	DRAFT,
	REJECTED,
	ROLE_COMPETITION_ADMIN,
	ROLE_RV_EQUINE_WELFARE
} from '../../utils/constants';
import Accordion from '../Accordion';
import {
	FORM_TYPES,
	formTypeListOptions,
	statusListOptions
} from './constants';
import FormMultiSelectList from '../FormMultiSelectList';
import { useToast } from '../../hooks/useToast';
import PrimaryButton from '../PrimaryButton';
import MessageBar from '../MessageBar';
import { useAuth } from '../../hooks/useAuth';
import {
	SPONSORSHIP_OUTCOME_REVIEW_ROUTE,
	SPONSORSHIP_OUTCOME_ROUTE
} from '../../utils/constants/routes';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getSponsorshipOutcomeForms,
	getSponsorshipOutcomeFormsAdmin
} from '../../graphql/custom.queries';
import { ColumnContent } from '../ReviewList/components/ResultComponents';
import { navigate } from 'gatsby';
import PageContainer from '../PageContainer';
import SEO from '../seo';

const EventReporting = () => {
	const { addToast } = useToast();
	const [isToggleActive, setIsToggleActive] = useState(true);
	const [formList, setFormList] = useState([]);
	const [statusList, setStatusList] = useState([]);
	const [formTypeList, setFormTypeList] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState([
		{ label: DRAFT, value: 1 }
	]);
	const { currentUser } = useAuth();
	const isCompetitionAdmin = currentUser?.Roles.some(
		(role) => role === ROLE_COMPETITION_ADMIN
	);
	const isAdmin = currentUser?.Roles.some(
		(role) => role === ROLE_RV_EQUINE_WELFARE
	);
	const [selectedFormType, setSelectedFormType] = useState([]);
	const screenSize = useScreenClass();
	const isMobile = checkIsSmallScreen(screenSize);
	const classes = useStyles();

	useEffect(() => {
		fetchFormList(
			selectedStatus.map((d) => d.label),
			selectedFormType.map((d) => d.label)
		);
		setStatusList(convertToMultiListData(statusListOptions, 'value'));
		setFormTypeList(convertToMultiListData(formTypeListOptions, 'value'));
	}, []);

	const fetchFormList = async (status, formTypes) => {
		let finalStatus = status.map((item) => {
			if (item === DECLINED) return REJECTED;
			else return item;
		});
		let formListData = [];
		if (
			formTypes.length === 0 ||
			formTypes?.includes(FORM_TYPES.SPONSORSHIP_OUTCOME_REPORT)
		) {
			try {
				console.log(`Fetching forms with status: ${finalStatus}`);
				if (isAdmin) {
					const result = await AppSyncService.execute(
						getSponsorshipOutcomeFormsAdmin,
						{
							status: finalStatus
						}
					);
					formListData = formListData.concat(
						result.data.getSponsorshipOutcomeFormsAdmin?.map((item) => ({
							...item,
							status: item.status === REJECTED ? DECLINED : item.status,
							subType: FORM_TYPES.SPONSORSHIP_OUTCOME_REPORT
						}))
					);
				} else if (isCompetitionAdmin) {
					const result = await AppSyncService.execute(
						getSponsorshipOutcomeForms,
						{
							status: finalStatus
						}
					);
					formListData = formListData.concat(
						result.data.getSponsorshipOutcomeForms?.map((item) => ({
							...item,
							status: item.status === REJECTED ? DECLINED : item.status,
							subType: FORM_TYPES.SPONSORSHIP_OUTCOME_REPORT
						}))
					);
				}
			} catch {
				addToast({
					Message: 'Error Fetching event reporting forms...',
					IsSuccess: false
				});
			}
		}
		setFormList(sortAsOfDate(formListData));
	};

	const updateSelectedStatus = (value) => {
		setSelectedStatus(value);
		fetchFormList(
			value.map((d) => d.label),
			selectedFormType.map((d) => d.label)
		);
	};

	const updateSelectedFormType = (value) => {
		setSelectedFormType(value);
		fetchFormList(
			selectedStatus.map((d) => d.label),
			value.map((d) => d.label)
		);
	};

	const sortAsOfDate = (list, sortOrder = 'ASC') => {
		const newList = [...list];
		newList.sort((a, b) => {
			if (sortOrder === 'ASC')
				return a['lastUpdated'] > b['lastUpdated'] ? -1 : 1;
			else return a['lastUpdated'] > b['lastUpdated'] ? 1 : -1;
		});
		return newList;
	};

	const gotoForm = (id, type) => {
		if (type === FORM_TYPES.SPONSORSHIP_OUTCOME_REPORT) {
			navigate(
				`${
					isAdmin ? SPONSORSHIP_OUTCOME_REVIEW_ROUTE : SPONSORSHIP_OUTCOME_ROUTE
				}/${id}`
			);
		}
	};

	const onToggleClick = () => {
		setIsToggleActive((prev) => !prev);
	};
	return (
		<PageContainer className={classes.container}>
			<SEO title="Off The Track Events" />
			<Row>
				<Col xs={12}>
					<div className={classes.sectionContainer}>
						<div className={classes.headerContainer} onClick={onToggleClick}>
							OTT Sponsorship and Event Reporting
							<AccordionToggle
								onToggleClick={() => onToggleClick()}
								isOpen={isToggleActive}
								mobile={isMobile}
							/>
						</div>
						{isToggleActive && (
							<>
								<Accordion
									isOpen={true}
									id={CommonKeys.EVENT_REPORTING_REVIEW_LIST}
									title={`Reports (${formList.length})`}
								>
									<div className={classes.filterContainer}>
										<div className={classes.filterTitle}>Filter by Status</div>
										<div className={classes.filterComponentContainer}>
											<FormMultiSelectList
												name="filterStatus"
												listOptions={statusList}
												selectedIds={selectedStatus}
												onSelectChange={updateSelectedStatus}
											/>
										</div>
									</div>
									<div className={classes.filterContainer}>
										<div className={classes.filterTitle}>Filter by Name</div>
										<div className={classes.filterComponentContainer}>
											<FormMultiSelectList
												name="filterFormName"
												listOptions={formTypeList}
												selectedIds={selectedFormType}
												onSelectChange={updateSelectedFormType}
											/>
										</div>
									</div>
									<div
										id={CommonKeys.EVENT_REPORTING_CONTAINER}
										className={classes.resultContainer}
									>
										{formList?.length > 0 ? (
											formList?.map((d) => (
												<div
													className={classes.singleResultContainer}
													key={d.id}
												>
													<ColumnContent>{d?.subType}</ColumnContent>
													<ColumnContent style={{ maxWidth: 200 }}>
														<strong
															className={classes.longText}
														>{`${d.member.firstName} ${d.member.lastName}`}</strong>
													</ColumnContent>
													<ColumnContent>
														<span className={classes.longText}>
															{d.organisation?.value || ''}
														</span>
													</ColumnContent>
													<ColumnContent>
														{formatDateReadableVariant(d.lastUpdated)}
													</ColumnContent>
													<ColumnContent>{d.status}</ColumnContent>
													<ColumnContent>
														<div className={classes.button}>
															<div
																className={classes.actionButton}
																onClick={() => gotoForm(d.id, d.subType)}
															>
																{'>'}
															</div>
														</div>
													</ColumnContent>
												</div>
											))
										) : (
											<div className={classes.noResultFound}>
												No result found.
											</div>
										)}
									</div>
								</Accordion>

								{isAdmin && (
									<Row style={{ display: 'flex', justifyContent: 'end' }}>
										<Col xs={12} lg={3}>
											<PrimaryButton
												id={CommonKeys.REQUEST_APPLICATION}
												onClick={() =>
													navigate('/event-request-outcome-report')
												}
											>
												Request Sponsorship Outcome Report
											</PrimaryButton>
										</Col>
									</Row>
								)}
								{isCompetitionAdmin && (
									<MessageBar style={{ marginTop: 20 }}>
										<Row>
											<Col xs={12} lg={8}>
												To view the report requirements, or start an OTT
												Sponsorship Outcome report click Start Report. You can
												return to your Draft anytime from this page.
											</Col>
											<Col xs={12} lg={4}>
												<PrimaryButton
													id={CommonKeys.START_APPLICATION}
													onClick={() => navigate(SPONSORSHIP_OUTCOME_ROUTE)}
												>
													Start Sponsorship Outcome Report
												</PrimaryButton>
											</Col>
										</Row>
									</MessageBar>
								)}
							</>
						)}
					</div>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default EventReporting;

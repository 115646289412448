import React from 'react';
import InfoModal from '../../../InfoModal';

const modalStyles = {
	content: {
		maxWidth: 500,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const FollowConfirmModal = ({ horseName, showModal, closeModal }) => {
	return (
		<InfoModal
			showModal={showModal}
			closeModal={closeModal}
			styles={modalStyles}
			heading="Followed Horse"
			bodyContent={`You have successfully followed ${horseName}, updates will appear on the horse profile. View all your Following horses in My Stable`}
		/>
	);
};

export default FollowConfirmModal;

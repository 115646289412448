import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';
import AccordionToggle from '../../../Accordion/AccordionToggle';

const HorseInfoSection = ({
	headerContent,
	children,
	darkContentBackground,
	showSection,
	isShowToggle = false,
	isToggleActive = false,
	isMobile,
	onToggleClick
}) => {
	const isShowContent = (isShowToggle && isToggleActive) || !isShowToggle;
	const classes = useStyles();
	return showSection ? (
		<div className={classes.sectionContainer}>
			{headerContent && (
				<div
					className={classes.headerContainer}
					onClick={onToggleClick ? () => onToggleClick() : null}
				>
					{headerContent}
					{isShowToggle && (
						<AccordionToggle
							onToggleClick={onToggleClick}
							isOpen={isToggleActive}
							mobile={isMobile}
						/>
					)}
				</div>
			)}
			{isShowContent && (
				<div
					className={classnames({
						[classes.sectionContentContainer]: true,
						[classes.darkContentBackground]: darkContentBackground
					})}
				>
					{children}
				</div>
			)}
		</div>
	) : null;
};

export default HorseInfoSection;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important'
	},
	pageContainerMobile: {
		width: '100%',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important'
	},
	// info Container
	horseInfoContainer: {
		borderRadius: 5,
		border: '1px solid #b3bcc1',
		height: 365,
		background: 'white',
		marginLeft: '0 !important',
		marginRight: '0 !important',
		marginTop: 30,
		lineHeight: '14px'
	},
	horseInfoContainerMobile: {
		borderRadius: 5,
		border: '1px solid #b3bcc1',
		background: 'white',
		marginLeft: '0 !important',
		marginRight: '0 !important',
		marginTop: 30
	},
	horseImageContainer: {
		width: 365,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	image: {
		objectFit: 'cover',
		borderRadius: '5px 0px 0px 5px',
		height: 365,
		width: '100%'
	},
	horseInfo: {
		marginTop: 20
	},
	horseNameGrey: {
		marginLeft: 20,
		fontSize: 20,
		fontWeight: 600,
		color: 'grey'
	},
	horseInfoLeft: {
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	horseName: {
		marginLeft: 20,
		fontSize: 20,
		fontWeight: 600
	},
	moreDetails: {
		paddingLeft: '20px !important',
		paddingRight: '20px !important'
	},
	horseHistory: {
		fontSize: 13,
		color: 'grey'
	},
	riddenHistoryHeading: {
		fontWeight: 500,
		marginBottom: 5,
		color: '#464749'
	},
	mobileButton: {
		marginTop: 20,
		marginBottom: 20
	},
	location: {
		marginTop: 18
	},
	locationName: {
		marginLeft: 5,
		fontStyle: 'italic',
		color: 'grey',
		fontSize: 13
	},
	mapPin: {
		float: 'left',
		marginTop: 5
	},
	tagsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 5
	},
	tags: {
		background: '#ffe2f1',
		marginTop: 10,
		fontSize: 13,
		padding: '2px 10px 4px 10px',
		marginRight: 7,
		borderRadius: 4
	}
}));

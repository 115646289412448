import React from 'react';
import { Row } from 'react-grid-system';
import FormFieldWrapper from '../FormFieldWrapper';
import FormMultiSelectList from '../../FormMultiSelectList';
import MultiSelectInput from '../../MultiSelectInput';
import { HorseManagementKeys } from './../../../utils/constants/index';

const MultiSelectInputField = ({
	idPrefix,
	fieldLabel,
	selectedIds,
	options,
	onSelectChange,
	otherValue,
	onOtherChange,
	validationError,
	isRequired = false,
	otherDisabled = false,
	visibilitySelector,
	noMargin = false,
	isOtherFieldRequired = false,
	otherErrorMessage
}) => {
	const showOtherInputField =
		selectedIds && selectedIds.find(({ label }) => label === 'Other');
	return (
		<>
			<Row style={{ marginTop: noMargin ? 0 : 15 }}>
				<FormFieldWrapper
					fieldLabel={fieldLabel}
					isRequired={isRequired}
					fullWidth
					errorMessage={validationError}
					visibilitySelector={visibilitySelector}
				>
					<FormMultiSelectList
						id={idPrefix}
						selectedIds={selectedIds}
						listOptions={options.map(({ id, value }) => ({
							value: id,
							label: value
						}))}
						onSelectChange={onSelectChange}
					/>
				</FormFieldWrapper>
			</Row>
			{!otherDisabled && showOtherInputField && (
				<Row style={{ marginTop: noMargin ? 0 : 15 }}>
					<FormFieldWrapper
						fieldLabel={
							<div>
								Other{' '}
								<span style={{ fontWeight: 'normal' }}>
									(Please separate individual values by using a comma)
								</span>
							</div>
						}
						fullWidth
					>
						<MultiSelectInput
							id={`${idPrefix}_${HorseManagementKeys.OTHER}`}
							value={otherValue}
							updateValue={onOtherChange}
						/>
					</FormFieldWrapper>
				</Row>
			)}
			{isOtherFieldRequired && showOtherInputField && (
				<Row style={{ marginTop: noMargin ? 0 : 15 }}>
					<FormFieldWrapper
						fieldLabel={
							<div>
								Other*{' '}
								<span style={{ fontWeight: 'normal' }}>
									(Please separate individual values by using a comma)
								</span>
							</div>
						}
						fullWidth
					>
						<MultiSelectInput
							id={`${idPrefix}_${HorseManagementKeys.OTHER}`}
							value={otherValue}
							updateValue={onOtherChange}
						/>
					</FormFieldWrapper>
					<div
						style={{
							color: 'red',
							marginLeft: 15,
							marginTop: 10,
							fontSize: 14
						}}
					>
						{otherErrorMessage.value ? otherErrorMessage.message : null}
					</div>
				</Row>
			)}
		</>
	);
};

export default MultiSelectInputField;

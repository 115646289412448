import { Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import MessageBar from '../MessageBar';
import BannerImage from '../../assets/images/header_banner.png';
import HeaderBanner from '../HeaderBanner';
import theme from '../../config/theme';

export const StyledHeaderBanner = styled(HeaderBanner)`
	margin-bottom: 0px !important;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
`;

export const StyledContainer = styled(Container)`
	font-family: Open-sans !important;
	margin-top: 5rem;
	width: 100%;
	margin-bottom: 50px;
	padding-left: 15px !important;
	padding-right: 15px !important;
	@media (min-width: 768px) {
		margin-top: 0.5rem;
		width: 1000px;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
`;

export const StyledMessageBar = styled(MessageBar)`
	margin-bottom: 2rem;
	p {
		margin: 0 0 0.5rem 0;
	}
`;

export const StyledFormFieldContainer = styled(Col)`
	display: block;
	margin-bottom: 1rem;
	label {
		display: block;
		font-weight: 600;
		color: #464749;
		font-size: 13px;
		margin-bottom: 0.5rem;
	}
	textarea {
		font-family: Open-sans !important;
		height: 80px;
		width: 100%;
		border: 1px solid ${(props) => (props.isError ? '#ff0000' : '#c7ced1')};
		font-size: 15px;
		box-sizing: border-box;
		margin-left: 10px;
		padding-left: 16px;
		border-radius: 4px;
		&:focus {
			outline: none;
		}
	}
	@media (min-width: 992px) {
		display: flex;
		align-items: flex-start;
		textarea {
			width: 70%;
		}
		label {
			display: inline;
			margin-bottom: 0rem;
		}
	}
`;

export const StyledSearchButtonContainer = styled(Col)`
	margin: 0 auto 2rem auto !important;
`;

export const StyledTable = styled.table`
	border-collapse: collapse;
	width: 100%;
	thead {
		background: url(${BannerImage});
	}
	font-size: 12px;
	thead th {
		padding: 0.75rem 0.5rem;
	}
	tbody td {
		padding: 0.5rem 0.625rem;
	}
	tbody td,
	thead th {
		border: 1px solid #d9dde0;
	}
	tbody tr:nth-child(even) {
		background-color: #f5f5f5;
	}
	tbody tr:hover {
		background-color: #ddd;
	}
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		font-size: 16px;
		thead th {
			padding: 1rem 0.5rem;
			text-align: left;
		}
		tbody td {
			padding: 0.75rem 0.5rem;
		}
	}
`;

export const StyledPaginationContainer = styled.div`
	display: flex;
`;

export const LoadingSpinnerContainer = styled(Container)`
	width: 100%;
	padding: 0 15px 0 15px !important;
	display: flex;
	justify-content: center;
	flex-direction: column;
	@media (min-width: 768px) {
		width: 1000px;
		padding-left: 0 !important;
		padding-right: 0 !important;
		height: 300px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
`;

export const StyledInitialContainer = styled.div`
	display: flex;
	justify-content: center;
	span {
		font-weight: bold;
	}
`;

export const ThemeFont = styled.div`
	color: ${theme.colors.text};
	font-style: italic;
`;

import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseClaimByIdQuery,
	getHorseOTTStatus,
	getHorseProgram,
	listHorseClaimSources,
	saveHorseProfileRetirement,
	saveHorseProgram,
	saveRetrainerHorseStatus
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import {
	APPROVED,
	RELATIONSHIP_RETRAINER,
	RESET,
	FOSTER,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ACKNOWLEDGED_RETIREMENT,
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ACK_RETIREMENT_FARM,
	ACK_FOSTER_PROVIDER,
	ACK_RETRAINER_TEXT,
	RESET_EXPRESS,
	ACKNOWLEDGED_REHOMING,
	ROLE_REHOMER,
	ACK_REHOMER
} from '../../utils/constants';
import SEO from '../seo';
import AdminApproval from '../AdminApproval';
import ClaimThoroughbredReview from '../ClaimThoroughbredReview';

const HORSE_LOCATION_CLAIM_SOURCE = 7;

const CurrentClaimThoroughbredReview = () => {
	let { id } = useParams();
	const [data, setData] = useState({
		address: null,
		claimPurpose: '',
		claimsSource: null,
		claimsSourceOther: null,
		horse: null,
		horseCode: null,
		horseName: '',
		horseProgram: null,
		isHorseAtAddress: false,
		member: null,
		memberId: null,
		organisation: '',
		ownershipType: '',
		reviewStatus: '',
		stabledAddress: null,
		stabledDate: null
	});
	const [allClaimData, setAllClaimData] = useState({});
	const [reviewNotesData, setReviewNotesData] = useState('');
	const [reviewReasonsData, setReviewReasonsData] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { currentUser } = useAuth();

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(listHorseClaimSources).then((sourceData) => {
			if (sourceData?.data?.listHorseClaimSources) {
				AppSyncService.execute(getHorseClaimByIdQuery, {
					id: parseInt(id)
				}).then((result) => {
					if (result.data != null && result.data.getHorseClaimById != null) {
						setAllClaimData(result.data.getHorseClaimById);
						let stabledAddress, claimsSource, horseName;

						if (result?.data?.getHorseClaimById?.claimsSource) {
							claimsSource =
								sourceData.data.listHorseClaimSources.find(
									(item) =>
										item.id === result.data.getHorseClaimById.claimsSource
								)?.value || '';
						}

						if (result.data.getHorseClaimById.reviewNotes) {
							setReviewNotesData(result.data.getHorseClaimById.reviewNotes);
						}

						if (result.data.getHorseClaimById.reviewReasons) {
							setReviewReasonsData(result.data.getHorseClaimById.reviewReasons);
						}

						if (result.data.getHorseClaimById.horse != null) {
							horseName = result.data.getHorseClaimById.horse.name;
						}

						if (result.data.getHorseClaimById.isAtMyAddress === false) {
							stabledAddress = {
								stabledAddressLine:
									result.data.getHorseClaimById.stabledAddressLine,
								stabledCountry: result.data.getHorseClaimById.stabledCountry,
								stabledPostcode: result.data.getHorseClaimById.stabledPostcode,
								stabledSuburb: result.data.getHorseClaimById.stabledSuburb,
								stabledState: result.data.getHorseClaimById.stabledState
							};
						}

						setData((state) => ({
							...state,
							...result.data.getHorseClaimById,
							member: {
								...result.data.getHorseClaimById.member,
								residentialAddress: {
									address: result.data.getHorseClaimById.residentialAddress,
									country: result.data.getHorseClaimById.residentialCountry,
									postcode: result.data.getHorseClaimById.residentialPostcode,
									state: result.data.getHorseClaimById.residentialState,
									suburb: result.data.getHorseClaimById.residentialSuburb
								}
							},
							address: {
								address: result.data.getHorseClaimById.residentialAddress,
								country: result.data.getHorseClaimById.residentialCountry,
								postcode: result.data.getHorseClaimById.residentialPostcode,
								state: result.data.getHorseClaimById.residentialState,
								suburb: result.data.getHorseClaimById.residentialSuburb
							},
							claimsSource,
							horseCode: result.data.getHorseClaimById.horse.horseCode,
							horseName,
							isHorseAtAddress: result.data.getHorseClaimById.isAtMyAddress,
							memberId: result.data.getHorseClaimById.member.externalId,
							reviewStatus: result.data.getHorseClaimById.status,
							stabledAddress
						}));
						setIsLoading(false);

						AppSyncService.execute(getHorseProgram, {
							horseCode: parseInt(result.data.getHorseClaimById.horse.horseCode)
						}).then((getHorseProgramResult) => {
							if (
								getHorseProgramResult.data != null &&
								getHorseProgramResult.data.getHorseProfile != null
							) {
								setData((state) => ({
									...state,
									horseProgram:
										getHorseProgramResult.data.getHorseProfile.program.value
								}));
							}
						});
					}
				});
			}
		});
	}, []);

	const updateIsLoading = (value) => {
		setIsLoading(value);
	};

	const approvalValidation = (approvalStatus) => {
		const isAckRetirementFarmRole = data.member?.roles.some(
			(role) => role.code === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
		);
		const isAckFosterProviderRole = data.member?.roles.some(
			(role) => role.code === ROLE_ACKNOWLEDGED_FOSTER_CARER
		);
		const isAckRetrainerRole = data.member?.roles.some(
			(role) => role.code === ROLE_RV_ACKNOWLEDGED_RETRAINER
		);

		const isAckRehomer = data.member?.roles.some(
			(role) => role.code === ROLE_REHOMER
		);

		let retirementCategory = null;
		if (isAckRetirementFarmRole && data.ownershipType === ACK_RETIREMENT_FARM)
			retirementCategory = 3;
		else if (
			isAckFosterProviderRole &&
			data.ownershipType === ACK_FOSTER_PROVIDER
		)
			retirementCategory = 2;
		else if (isAckRetrainerRole && data.ownershipType === ACK_RETRAINER_TEXT)
			retirementCategory = 0;

		if (
			approvalStatus === APPROVED &&
			data.ownershipType === RELATIONSHIP_RETRAINER &&
			!!data.member?.roles?.find(
				({ code }) => code === ROLE_RV_ACKNOWLEDGED_RETRAINER
			) &&
			data.horseProgram !== RESET &&
			data.horseProgram !== FOSTER &&
			data.horseProgram !== ACKNOWLEDGED_RETIREMENT &&
			data.horseProgram !== RESET_EXPRESS &&
			data.horseProgram !== ACKNOWLEDGED_REHOMING
		) {
			retirementCategory = 0;
			AppSyncService.execute(getHorseOTTStatus, {
				horseCode: data.horseCode
			}).then((response) => {
				let isConcludedRetraining = false;
				if (response.data?.getHorseOTTStatus?.horseOTTStatusId)
					isConcludedRetraining =
						response.data.getHorseOTTStatus.horseOTTStatusId == 5;

				AppSyncService.execute(saveHorseProgram, {
					memberExternalId: currentUser?.UserId,
					programId: 2,
					horseCode: data.horseCode
				});
				let input = {
					asOfDate: new Date(),
					horseCode: data.horseCode,
					statusId: isConcludedRetraining ? 2 : 1,
					funding: data.claimPurpose === 'Retrain on behalf of client' ? 2 : 1
				};
				if (isConcludedRetraining) delete input.funding;
				AppSyncService.execute(saveRetrainerHorseStatus, { input });
			});
		}
		if (
			approvalStatus === APPROVED &&
			data.ownershipType === RELATIONSHIP_RETRAINER &&
			!!data.member?.roles?.find(
				({ code }) => code === ROLE_RV_ACKNOWLEDGED_RETRAINER
			) &&
			data.horseProgram === FOSTER
		) {
			retirementCategory = 2;
			AppSyncService.execute(saveHorseProgram, {
				memberExternalId: currentUser?.UserId,
				programId: 3,
				horseCode: data.horseCode
			});
			let input = {
				asOfDate: new Date(),
				horseCode: data.horseCode,
				statusId: 25,
				funding: data.claimPurpose === 'Retrain on behalf of client' ? 2 : 1
			};
			AppSyncService.execute(saveRetrainerHorseStatus, { input });
		}
		if (
			data.horseProgram === RESET &&
			approvalStatus === APPROVED &&
			data.ownershipType === RELATIONSHIP_RETRAINER
		) {
			retirementCategory = 0;
			AppSyncService.execute(saveRetrainerHorseStatus, {
				asOfDate: new Date(),
				horseCode: data.horseCode,
				statusId: 13 // RESET - In Retraining
			});
		}

		if (
			approvalStatus === APPROVED &&
			data.ownershipType === ACK_REHOMER &&
			isAckRehomer
		) {
			AppSyncService.execute(saveHorseProgram, {
				memberExternalId: currentUser?.UserId,
				programId: 10, //Ack Rehoming
				horseCode: data.horseCode
			});
			let input = {
				asOfDate: new Date(),
				horseCode: data.horseCode,
				statusId: 47
			};
			AppSyncService.execute(saveRetrainerHorseStatus, { input });
		}

		if (
			approvalStatus === APPROVED &&
			retirementCategory !== null &&
			(isAckRetirementFarmRole || isAckFosterProviderRole || isAckRetrainerRole)
		) {
			const payload = {
				memberId: data.member?.externalId,
				horseCode: data.horseCode,
				retirementCategory,
				vulnerabilityType: null,
				behaviourOthersRetirement: null,
				conformationalDetailsRetirement: null,
				otherEducationDetails: null,
				otherInjuryDetailsRetirement: null,
				otherIllnessDetailsRetirement: null,
				rehomingHistoryDetails: null,
				otherDetailsRetirement: null,
				habitsRetirement: [],
				injuriesRetirement: [],
				illnessRetirement: [],
				education: [],
				reasonRetirement: [],
				informationSource: null,
				sourceDetail: null,
				sourceDetailOther: null,
				detailsInformationObtained: null,
				dateInformationProvided: null,
				otherRelevantDetails: null
			};
			AppSyncService.execute(saveHorseProfileRetirement, { input: payload });
		}
	};

	return (
		<Fragment>
			<SEO title="Claim Thoroughbred Review" />

			<ClaimThoroughbredReview
				allClaimData={allClaimData}
				data={data}
				isCurrent={true}
				isLoading={isLoading}
				title={`Claim Thoroughbred - ${data.horseName}`}
			>
				{data.reviewStatus != '' && (
					<AdminApproval
						sourceOfAddress={HORSE_LOCATION_CLAIM_SOURCE}
						reviewNotesData={reviewNotesData}
						reviewReasonsData={reviewReasonsData}
						id={id}
						isAtAddress={data.isHorseAtAddress}
						stabledDate={data.stabledDate}
						allClaimData={allClaimData}
						hCode={data.horseCode}
						residentialAddress={data.address}
						reviewStatus={data.reviewStatus}
						reviewType="Thoroughbred"
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						onDataSubmit={approvalValidation}
						ownershipType={data.ownershipType}
						member={allClaimData.member}
					/>
				)}
			</ClaimThoroughbredReview>
		</Fragment>
	);
};

export default CurrentClaimThoroughbredReview;

import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from '@reach/router';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import { useForm } from 'react-hook-form-v7';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveOhepEoiAdminData,
	saveOhepEoiFormStatus
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { OHEP_EOI_FORM_NAME, REVIEW, REVIEWED } from '../../utils/constants';
import { convertBlankOptionToNull } from '../../utils/helpers';
import StyledAccordionWithBanner from '../Accordion/StyledAccordionWithBanner';
import Divider from '../Divider';
import FormDropDown from '../FormDropDown';
import FormLabelField from '../FormLabelField';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import {
	StyledErrorText,
	StyledFormSubmitButton,
	StyledFormTextArea
} from '../OHEPForm/style';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import SEO from '../seo';
import { OHEP_FORM_REVIEW_DEFAULT_VALUES } from './constants';
import { setFormValuesFromApiResult } from './helper';
import { getOhepEoiReviewFormData } from './queries';
import schema from './schema';
import {
	StyledAccordionContainer,
	StyledActionsButtonCol,
	StyledActionsButtonRow,
	StyledAdminAccordionContainer,
	StyledContainer,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledFieldLabelWithCharRemainingContainer,
	StyledFormMainErrorContainer,
	StyledHeaderBanner,
	StyledLoadingContainer
} from './style';

const OhepEoiReview = () => {
	const PAGE_ID = 'ohep-form-review';
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isFormError, setIsFormError] = useState(false);
	const [adminOptions, setAdminOptions] = useState([]);
	const [adminStatusOptions, setAdminStatusOptions] = useState([]);
	const [reviewOutcomeOptions, setReviewOutcomeOptions] = useState([]);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const screenClassForRender = useScreenClass();
	let { id } = useParams();
	const { addToast } = useToast();
	const {
		watch,
		setValue,
		handleSubmit,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: OHEP_FORM_REVIEW_DEFAULT_VALUES
	});
	const values = watch();
	const isFormInReview = formData?.status === REVIEW;
	const { currentUser } = useAuth();

	const fetchData = async () => {
		try {
			const firstOption = { id: 0, value: null };
			const { data } = await AppSyncService.execute(getOhepEoiReviewFormData, {
				id
			});
			setFormData(data.getOhepEoiFormById);
			const localAdminOptions = data.getAdminMembers.map((item) => ({
				id: item.externalId,
				value: `${item.firstName} ${item.lastName}`
			}));
			setAdminOptions([firstOption, ...localAdminOptions]);
			setAdminStatusOptions([firstOption, ...data.listAdminActionStauses]);
			setReviewOutcomeOptions([firstOption, ...data.listAdminActionOutcomes]);

			if (data.getOhepEoiFormById?.adminAction?.admin?.externalId) {
				setFormValuesFromApiResult(data.getOhepEoiFormById, setValue);
			} else if (!values.adminExternalId) {
				setValue('adminExternalId', currentUser.UserId);
			}
			setIsLoading(false);
		} catch (error) {
			addToast({
				Message: 'Failed to fetch for the given id',
				IsSuccess: false
			});
		}
	};

	const handleChange = (e) => {
		setValue(e.target.name, e.target.value);
	};

	const handleTextFieldChange = (e) => {
		const { name, value } = e.target;
		if (value.length <= 500) {
			setValue(name, value);
		}
	};

	const updateAdminDataAndActions = async () => {
		const { data } = await AppSyncService.execute(saveOhepEoiAdminData, {
			adminExternalId: values.adminExternalId,
			adminOutcomeId: convertBlankOptionToNull(values.reviewOutcome),
			adminStatusId: convertBlankOptionToNull(values.adminStatus),
			formType: OHEP_EOI_FORM_NAME,
			ohepEoiGuid: id
		});
		if (data.saveOhepAdminAction?.error?.errorMessage) {
			throw new Error('Failed to Updated OHEP EOI admin actions');
		}
	};

	const updateFormStatus = async (formStatus) => {
		const { data } = await AppSyncService.execute(saveOhepEoiFormStatus, {
			guid: id,
			reviewNotes: values.adminNotes,
			status: formStatus
		});
		if (!data?.saveOhepEoiFormStatus?.success) {
			throw new Error('Failed to submit form, server error');
		}
	};

	const handleSaveAdminDetails = async () => {
		try {
			setIsLoading(true);
			clearErrors('adminStatus');
			clearErrors('reviewOutcome');
			if (!values.adminExternalId || +values.adminExternalId === 0) {
				setIsFormError(true);
				setError('adminExternalId', {
					type: 'manual',
					message: 'Actioning is a required field'
				});
				return;
			}
			clearErrors('adminExternalId');
			setIsFormError(false);
			await updateAdminDataAndActions();
			if (values.adminNotes != values.initialAdminNotes) {
				await updateFormStatus(REVIEW);
			}
			addToast({ Message: 'Form Saved', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Unable to Save Form', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmitError = () => {
		setIsFormError(true);
	};

	const handleCompleteReview = async () => {
		try {
			setIsFormError(false);
			setIsLoading(true);
			await updateAdminDataAndActions();
			await updateFormStatus(REVIEWED);
			addToast({ Message: 'Form Reviewed', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Unable to Review Form', IsSuccess: false });
		} finally {
			setIsLoading(false);
		}
	};

	const handleApprove = (e) => {
		e.preventDefault();
		handleSubmit(handleCompleteReview, handleSubmitError)(e);
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Fragment>
			<SEO title="OHEP EOI Form Review" />
			<StyledLoadingContainer>
				<LoaderSpinner status={isLoading} />
			</StyledLoadingContainer>
			{!isLoading && (
				<StyledContainer>
					<StyledHeaderBanner
						title={`OHEP EOI Form Review - ${formData?.horseName}`}
						top
						type="primary"
						mobile={['xs', 'sm'].includes(screenClassForRender)}
						rightTitle={`Status: ${formData?.status}`}
					/>
					<StyledAccordionWithBanner title="Contact Details" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Name"
										value={
											formData.member
												? `${formData.member?.firstName} ${formData.member?.lastName}`
												: ''
										}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Email"
										value={formData?.member?.email}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact Number"
										value={formData?.member?.phone}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<StyledFieldContainer>
										<StyledFieldLabel>
											Preferred contact method
										</StyledFieldLabel>
										<FormTextField
											canEdit={false}
											value={formData?.preferredContactType?.value}
										/>
									</StyledFieldContainer>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Contact details"
										value={formData?.contactDetails}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Relationship to this horse"
										value={formData?.relationshipToHorse?.value}
									/>
								</Col>
								{formData?.relationshipToHorse?.value === 'Other' && (
									<Col xs={12} lg={4}>
										<FormLabelField
											title="Other relationship to this horse"
											value={formData?.relationshipToHorseOther}
										/>
									</Col>
								)}
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Horse Details" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Horse Name"
										value={formData?.horseName}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Microchip Number"
										value={formData?.microchip}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField title="Sire Name" value={formData?.sire} />
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField title="Dam Name" value={formData?.dam} />
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Sex"
										value={formData?.horseGender?.value}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField title="Age" value={formData?.age} />
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Which eligibility criteria do you believe your horse meets?"
										value={formData?.eligibilityCriteria?.value}
									/>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Current address of Horse" isOpen>
						<StyledAccordionContainer>
							<Row>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Address"
										value={formData?.currentAddress}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Suburb"
										value={formData?.currentSuburb}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred State"
										value={formData?.currentState}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Postcode"
										value={formData?.currentPostcode}
									/>
								</Col>
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Thoroughbred Country"
										value={formData?.currentCountry}
									/>
								</Col>
							</Row>
						</StyledAccordionContainer>
					</StyledAccordionWithBanner>
					<StyledAccordionWithBanner title="Admin" isOpen>
						<StyledAdminAccordionContainer isLast>
							<Row>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Actioning*</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="adminExternalId"
											selectedId={values.adminExternalId}
											onChange={handleChange}
											items={adminOptions}
											isError={errors.adminExternalId?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.adminExternalId?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Admin Status*</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="adminStatus"
											selectedId={values.adminStatus}
											onChange={handleChange}
											items={adminStatusOptions}
											isError={errors.adminStatus?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.adminStatus?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
								<Col lg={4} xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabel>Outcome*</StyledFieldLabel>
										<FormDropDown
											showBlank
											name="reviewOutcome"
											selectedId={values.reviewOutcome}
											onChange={handleChange}
											items={reviewOutcomeOptions}
											isError={errors.reviewOutcome?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.reviewOutcome?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<StyledFieldContainer>
										<StyledFieldLabelWithCharRemainingContainer>
											<StyledFieldLabel>Admin Notes</StyledFieldLabel>
											<span className="characters-remaining">
												{500 - (values.adminNotes?.length || 0)} characters
												remaining
											</span>
										</StyledFieldLabelWithCharRemainingContainer>
										<StyledFormTextArea
											name="adminNotes"
											value={values.adminNotes}
											onChange={handleTextFieldChange}
											isError={errors?.adminNotes?.message}
											disabled={!isFormInReview}
										/>
										<StyledErrorText>
											{errors.adminNotes?.message}
										</StyledErrorText>
									</StyledFieldContainer>
								</Col>
							</Row>
							{isFormError && (
								<StyledFormMainErrorContainer>
									<StyledErrorText>
										Please complete all required fields
									</StyledErrorText>
								</StyledFormMainErrorContainer>
							)}
							<StyledActionsButtonRow>
								<StyledActionsButtonCol lg={4} xs={12}>
									<StyledFormSubmitButton
										isNonSubmit
										onClick={handleSaveAdminDetails}
										id={`${PAGE_ID}-submit`}
										disabled={!isFormInReview}
									>
										Save Admin Details
									</StyledFormSubmitButton>
								</StyledActionsButtonCol>
							</StyledActionsButtonRow>
							<Divider color="#e3e3e3" />
							<StyledActionsButtonRow>
								<StyledActionsButtonCol lg={4} xs={12}>
									<StyledFormSubmitButton
										disabled={!isFormInReview}
										onClick={handleApprove}
										id={`${PAGE_ID}-approve`}
									>
										Complete
									</StyledFormSubmitButton>
								</StyledActionsButtonCol>
							</StyledActionsButtonRow>
						</StyledAdminAccordionContainer>
					</StyledAccordionWithBanner>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
					/>
				</StyledContainer>
			)}
		</Fragment>
	);
};

export default OhepEoiReview;

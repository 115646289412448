import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	topContainer: {
		marginBottom: 20
	},
	horseName: {
		marginBottom: 6,
		fontSize: 20,
		fontWeight: 600,
		color: '#464749'
	},
	mobileHorseName: {
		marginBottom: 6,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749'
	},
	location: {
		color: '#167aae',
		fontSize: 15
	},
	mobileLocation: {
		fontSize: 15,
		color: '#167aae'
	},
	mapIcon: {
		height: 16,
		float: 'left',
		marginRight: 6,
		marginTop: 2
	},
	buttonContainer: {
		width: 170,
		float: 'right'
	},
	mobileButtonContainer: {
		width: '100%',
		marginTop: 20
	},
	heading: {
		fontSize: 20,
		fontWeight: 600,
		color: '#464749'
	},
	mobileHeading: {
		fontSize: 15,
		fontWeight: 600,
		color: '#464749'
	},
	body: {
		fontSize: 15,
		color: '#464749',
		marginTop: 13,
		marginBottom: 30
	},
	mobileBody: {
		fontSize: 13,
		color: '#464749',
		marginTop: 13,
		marginBottom: 40
	},
	bodyDetails: {
		border: '1px solid #b3bcc1',
		paddingTop: 20,
		paddingBottom: 30,
		marginTop: 15
	},
	mobileBodyDetails: {
		border: '1px solid #b3bcc1',
		marginTop: 20,
		paddingTop: 20,
		paddingBottom: 10,
		marginBottom: 40
	},
	tagsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 5,
		marginLeft: 15,
		marginRight: 15
	},
	tags: {
		background: '#ffe2f1',
		marginTop: 10,
		fontSize: 15,
		padding: '2px 10px 4px 10px',
		marginRight: 7,
		borderRadius: 4
	},
	mobileTags: {
		background: '#ffe2f1',
		marginTop: 10,
		fontSize: 13,
		padding: '2px 10px 4px 10px',
		marginRight: 7,
		borderRadius: 4
	},
	divider: {
		background: '#dfedf4',
		height: 1,
		border: 'none',
		marginTop: 20,
		marginBottom: 30
	},
	noLongerInterestedContainer: {
		marginTop: 10
	},
	tooltip: {
		position: 'absolute',
		background: 'black',
		borderRadius: 5,
		padding: 10,
		color: 'white',
		fontWeight: 600,
		top: 100
	},
	adminContainer: {
		backgroundColor: '#f0f0f0'
	},
	historyContainer: {
		padding: '0px 18px 20px 18px'
	},
	historyText: {
		fontSize: 15,
		wordBreak: 'break-word',
		paddingTop: 3,
		display: 'flex',
		alignItems: 'baseline',
		flexWrap: 'wrap'
	},
	historyTimestamp: {
		color: '#888888',
		fontSize: 12,
		fontWeight: 600,
		whiteSpace: 'pre'
	},
	interestHistory: {
		whiteSpace: 'pre-wrap',
		display: 'flex'
	},
	viewHorseProfileLink: {
		color: '#006da6',
		fontWeight: 600,
		cursor: 'pointer'
	}
}));

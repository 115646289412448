import * as Yup from 'yup';
import { isNumeric } from '../../utils/helpers';
import { AT_LEAST_ONE_ERROR_MESSAGE } from './constants';

const schema = Yup.object()
	.shape({
		contactNumber: Yup.string()
			.required('Contact Number is required')
			.matches(/^\+\d*$|^\d*$/, 'Contact Number must be valid'),
		contactAddress: Yup.string().required('Contact Address is required'),
		contactSuburb: Yup.string().required('Contact Suburb is required'),
		contactPostcode: Yup.string().required('Contact Postcode is required'),
		contactState: Yup.string().required('Contact State is required'),
		contactCountry: Yup.string().required('Contact Country is required'),
		contactAddressValidated: Yup.boolean(),
		relationshipToHorse: Yup.number()
			.required('Relationship is required')
			.moreThan(0, 'Relationship is required'),
		relationshipToHorseOther: Yup.string().when('relationshipToHorse', {
			is: (relationship) => relationship == 5,
			then: () => Yup.string().required('Other Relationship is required')
		}),
		leftSidePhotos: Yup.array()
			.required('Left Side Brand Image is required')
			.min(1, 'Left Side Brand Image is required')
			.default([]),
		rightSidePhotos: Yup.array()
			.required('Right Side Brand Image is required')
			.min(1, 'Right Side Brand Image is required')
			.default([]),
		currentAddress: Yup.string().required(),
		currentSuburb: Yup.string().required(),
		currentPostcode: Yup.string().required(),
		currentState: Yup.string().required(),
		currentCountry: Yup.string().required(),
		currentAddressValidated: Yup.boolean(),
		currentAddressDurationMonths: Yup.number()
			.nullable()
			.transform((_, val) => (isNumeric(val) ? +val : null)),
		currentAddressDurationYears: Yup.number()
			.nullable()
			.transform((_, val) => (isNumeric(val) ? +val : null)),
		previousAddress: Yup.string().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					isNaN(month) || isNaN(year) || (month <= 1 && year === 0),
				then: () => Yup.string().required(),
				otherwise: () => Yup.string().nullable()
			}
		),
		previousSuburb: Yup.string().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					isNaN(month) || isNaN(year) || (month <= 1 && year === 0),
				then: () => Yup.string().required(),
				otherwise: () => Yup.string().nullable()
			}
		),
		previousPostcode: Yup.string().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					isNaN(month) || isNaN(year) || (month <= 1 && year === 0),
				then: () => Yup.string().required(),
				otherwise: () => Yup.string().nullable()
			}
		),
		previousState: Yup.string().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					isNaN(month) || isNaN(year) || (month <= 1 && year === 0),
				then: () => Yup.string().required(),
				otherwise: () => Yup.string().nullable()
			}
		),
		previousCountry: Yup.string().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					isNaN(month) || isNaN(year) || (month <= 1 && year === 0),
				then: () => Yup.string().required(),
				otherwise: () => Yup.string().nullable()
			}
		),
		previousAddressValidated: Yup.boolean().when(
			['currentAddressDurationMonths', 'currentAddressDurationYears'],
			{
				is: (month, year) =>
					!(isNaN(month) || isNaN(year) || (month <= 1 && year === 0)),
				then: () => Yup.boolean().nullable()
			}
		),
		reasonsForNomination: Yup.array()
			.required()
			.min(1, 'Reason for Nomination for Euthanasia is required')
			.default([]),
		thoroughbredOverTwentyDetails: Yup.string().when('reasonsForNomination', {
			is: (reasons) => reasons.includes(6),
			then: () =>
				Yup.string().required('Thoroughbred over 20 years details are required')
		}),
		rehomingAttemptDetails: Yup.string().when('reasonsForNomination', {
			is: (reasons) => reasons.includes(5),
			then: () => Yup.string().required('Rehoming Attempt details are required')
		}),
		rehomingAttemptDetailsDate: Yup.string().when('reasonsForNomination', {
			is: (reasons) => reasons.includes(5),
			then: () =>
				Yup.string().required('Date is required').typeError('Date is required'),
			otherwise: () => Yup.string().nullable()
		}),
		advertisedToRvDate: Yup.string().when('reasonsForNomination', {
			is: (reasons) => reasons.includes(5),
			then: () =>
				Yup.string().required('Date is required').typeError('Date is required'),
			otherwise: () => Yup.string().nullable()
		}),
		veterinaryCertificateFiles: Yup.array().when('reasonsForNomination', {
			is: (reasons) => reasons.includes(1) || reasons.includes(2),
			then: () => Yup.array().min(1, 'Veterinary Certificate is required')
		}),
		rspcaNoticeToComplyFiles: Yup.array(),
		evidenceOfDangerousBehaviourFiles: Yup.array(),
		otherFiles: Yup.array(),
		acknowledgedPrivacy: Yup.boolean()
			.required('Acknowledgment is required')
			.oneOf([true], 'Acknowledgment is required'),
		acknowledgedConsentToParticipate: Yup.boolean()
			.required('Consent To Participate is required')
			.oneOf([true], 'Consent To Participate is required'),
		acknowledgedOwnershipWarranty: Yup.boolean()
			.required('Ownership Declaration and Warranty is required')
			.oneOf([true], 'Ownership Declaration and Warranty is required'),
		acknowledgedOwnershipBinding: Yup.boolean()
			.required('Ownership Declaration and Warranty is required')
			.oneOf([true], 'Ownership Declaration and Warranty is required'),
		acknowledgedOwnerIndemnity: Yup.boolean()
			.required('Ownership Indemnity is required')
			.oneOf([true], 'Ownership Indemnity is required'),
		acknowledgedOwnerConfirmation: Yup.boolean()
			.required('Ownership Confirmation is required')
			.oneOf([true], 'Ownership Confirmation is required'),
		statuaryDeclarationFiles: Yup.array()
			.required()
			.min(1, 'Statutory Declaration is required')
	})
	.test('test-currentAddressDurationMonths', '', (values, context) => {
		if (
			(!values.currentAddressDurationMonths &&
				!values.currentAddressDurationYears) ||
			(values.currentAddressDurationMonths === 0 &&
				values.currentAddressDurationYears === 0)
		) {
			return context.createError({
				path: 'currentAddressDurationMonths',
				message: 'Months is required'
			});
		}
		return true;
	})
	.test('test-veterinaryCertificateFiles', '', (values, context) => {
		if (
			(values.reasonsForNomination.includes(3) ||
				values.reasonsForNomination.includes(4) ||
				values.reasonsForNomination.includes(7)) &&
			values.veterinaryCertificateFiles.length === 0 &&
			values.rspcaNoticeToComplyFiles.length === 0 &&
			values.evidenceOfDangerousBehaviourFiles.length === 0 &&
			values.otherFiles.length === 0
		) {
			return context.createError({
				path: 'veterinaryCertificateFiles',
				message: AT_LEAST_ONE_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-rspcaNoticeToComplyFiles', '', (values, context) => {
		if (
			(values.reasonsForNomination.includes(3) ||
				values.reasonsForNomination.includes(4) ||
				values.reasonsForNomination.includes(7)) &&
			values.veterinaryCertificateFiles.length === 0 &&
			values.rspcaNoticeToComplyFiles.length === 0 &&
			values.evidenceOfDangerousBehaviourFiles.length === 0 &&
			values.otherFiles.length === 0
		) {
			return context.createError({
				path: 'rspcaNoticeToComplyFiles',
				message: AT_LEAST_ONE_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-evidenceOfDangerousBehaviourFiles', '', (values, context) => {
		if (
			(values.reasonsForNomination.includes(3) ||
				values.reasonsForNomination.includes(4) ||
				values.reasonsForNomination.includes(7)) &&
			values.veterinaryCertificateFiles.length === 0 &&
			values.rspcaNoticeToComplyFiles.length === 0 &&
			values.evidenceOfDangerousBehaviourFiles.length === 0 &&
			values.otherFiles.length === 0
		) {
			return context.createError({
				path: 'evidenceOfDangerousBehaviourFiles',
				message: AT_LEAST_ONE_ERROR_MESSAGE
			});
		}
		return true;
	})
	.test('test-otherFiles', '', (values, context) => {
		if (
			(values.reasonsForNomination.includes(3) ||
				values.reasonsForNomination.includes(4) ||
				values.reasonsForNomination.includes(7)) &&
			values.veterinaryCertificateFiles.length === 0 &&
			values.evidenceOfDangerousBehaviourFiles.length === 0 &&
			values.rspcaNoticeToComplyFiles.length === 0 &&
			values.otherFiles.length === 0
		) {
			return context.createError({
				path: 'otherFiles',
				message: AT_LEAST_ONE_ERROR_MESSAGE
			});
		}
		return true;
	});

export default schema;

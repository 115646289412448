import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formContainer: {
		marginTop: 14
	},
	divider: {
		background: '#dfedf4',
		height: 1,
		border: 'none',
		marginTop: 20,
		marginBottom: 20
	},
	noLongerInterestedContainer: {
		marginTop: 10
	},
	checkboxFieldContainer: {
		marginTop: 10,
		marginBottom: 18
	},
	checkboxField: {
		marginLeft: 30
	},
	dateField: {
		marginLeft: 30,
		marginTop: 10,
		marginBottom: 10
	},
	dateContainer: {
		position: 'relative',
		marginLeft: 30,
		width: 190
	},
	notInterestedField: {
		marginBottom: 10,
		marginTop: 10,
		fontWeight: 600
	},
	notInterestedFieldMoreInfo: {
		marginBottom: 10,
		marginTop: 15,
		fontWeight: 600
	},
	remainingCharacters: {
		color: '#98999d',
		fontWeight: 100,
		float: 'right'
	},
	// not interested form
	notInterestedContainer: {
		marginTop: 10
	},
	notInterestedCancelButton: {
		float: 'left',
		marginTop: 10,
		color: '#1b7caf',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	notInterestedSubmit: {
		width: 135,
		float: 'right'
	},
	clear: {
		clear: 'both'
	}
}));

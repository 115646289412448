import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		border: '1px solid #b3bcc1',
		borderTop: 'none'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	ProfilefieldContainer: {
		padding: '20px 18px 20px 18px'
	}
}));

import React from 'react';
import { StyledContainer } from './styles';

const PageContainer = ({ children, style, ...props }) => (
	<StyledContainer {...props} style={{ ...style, maxWidth: 1000 }}>
		{children}
	</StyledContainer>
);

export default PageContainer;

import React, { useState, useEffect } from 'react';
import SEO from '../seo';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import PageContainer from '../PageContainer';
import FormDropDown from '../FormDropDown';
import FormDatePicker from '../FormDatePicker';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import FormTextField from '../FormTextField';
import StateSelector from '../StateSelector';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {
	DEFAULT_COUNTRY,
	BLANK_OPTION,
	APPROVED,
	REVIEW
} from '../../utils/constants';
import CountrySelector from '../CountrySelector';
import SimpleCheckBox from '../SimpleCheckBox';
import PrimaryButton from '../PrimaryButton';
import AppSyncService from '../../graphql/AppSyncService';
import axios from 'axios';
import {
	getShareInformationOptions,
	getHorses,
	createShareInformationForm,
	getShareInformationForm,
	uploadImageToS3,
	getUserImage,
	deleteImageFromS3,
	deleteImagesFromS3,
	deleteShareInformationImage,
	uploadShareInformationImage,
	deleteShareInformationForm,
	updateShareInformationFormStatus,
	getHorseClaim,
	saveHorseClaim,
	isClaimedByMember,
	saveDeceasedThoroughbredForm
} from '../../graphql/custom.queries';
import { useParams, navigate } from '@reach/router';
import {
	checkDropDownValue,
	isOptionOther,
	formatDate,
	validatePostCode,
	sortArray,
	isMultiSelectOptionOther
} from '../../utils/helpers';
import ResizingTextArea from '../ResizingTextArea';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import PhotoHelpMessage from '../PhotoHelpMessage';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import FormSectionContentDivider from '../FormComponents/FormSectionContentDivider';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import FormCancelConfirmModal from '../FormComponents/FormCancelConfirmModal';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import FormMultiSelectList from '../FormMultiSelectList';

const TERMS_NOTICE =
	'I confirm the information I have provided is accurate to the best of my knowledge. My account is up to date and includes a phone number (we may need to contact you to confirm the details you have submitted).*';
const MANUAL_ADDRESS_LABEL = 'Manually add horse address';
const RELATIONSHIP_OWNER_PARTOWNER_ID = 1;
const RELATIONSHIP_PREV_OWNER_ID = 2;
const RELATIONSHIP_PREV_PART_OWNER_ID = 7;

const FormHeader = ({ formTitle, children }) => {
	const classes = useStyles();
	return (
		<Row className={classes.formHeader}>
			<div className={classes.formHeaderTitleSection}>{formTitle}</div>
			<div className={classes.formHeaderDescriptionContent}>{children}</div>
		</Row>
	);
};

const HorseStatusIndicator = ({ status }) => {
	const classes = useStyles();
	return (
		<Col lg={4} sm={12}>
			<div className={classes.horseStatusIndicator}>
				{`Horse status: ${status}`}
			</div>
		</Col>
	);
};

const FormFooter = ({
	formId,
	onCancel,
	onSave,
	submitEnabled = true,
	validationErrorsExist
}) => {
	const classes = useStyles();
	return (
		<div className={classes.footerDiv}>
			<Row
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '0px 15px 10px'
				}}
			>
				<button
					id={`${formId}-cancel-button`}
					onClick={onCancel}
					className={classes.additionalButtonStyles}
				>
					Cancel
				</button>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button
						id={`${formId}-save-draft-button`}
						onClick={onSave}
						className={classes.additionalButtonStyles}
					>
						Save Draft
					</button>
					<PrimaryButton
						id={`${formId}-submit-button`}
						style={{ width: '165px', marginLeft: 15, marginTop: 0 }}
						disabled={!submitEnabled}
					>
						Submit Form
					</PrimaryButton>
				</div>
			</Row>
			<Row>
				<Col>
					{' '}
					{validationErrorsExist && (
						<div className={classes.errorMessage}>
							Please complete all required fields
						</div>
					)}
				</Col>
			</Row>
		</div>
	);
};

const FormDropDownInputField = ({
	id,
	fieldLabel,
	options,
	selectedItemId,
	onChange,
	errorMessage
}) => {
	return (
		<FormFieldWrapper
			fieldLabel={fieldLabel}
			errorMessage={errorMessage}
			isRequired
		>
			<FormDropDown
				id={id}
				items={options}
				selectedId={selectedItemId}
				onChange={onChange}
				showBlank
			/>
		</FormFieldWrapper>
	);
};

const FormOtherInput = ({ id, value, onChange }) => {
	return (
		<Row style={{ marginTop: 15 }}>
			<FormFieldWrapper fieldLabel="Other*" fullWidth>
				<FormTextField
					id={id}
					value={value}
					onChange={onChange}
					canEdit={true}
				/>
			</FormFieldWrapper>
		</Row>
	);
};

const PAGE_PREFIX = 'share-information-form';

const ShareInformationForm = () => {
	const classes = useStyles();
	const { horseCode } = useParams();
	const { currentUser } = useAuth();
	// FORM ID STATE
	const [shareInformationFormId, setShareInformationFormId] = useState('');
	const memberExternalId = currentUser?.UserId;
	const { addToast } = useToast();
	const [horseName, setHorseName] = useState('');
	const [horseRelationship, setHorseRelationship] = useState(0);
	const [horseRelationshipOther, setHorseRelationshipOther] = useState('');
	const [lastSeenDate, setLastSeenDate] = useState(null);
	const [isListedStatusCorrect, setIsListedStatusCorrect] = useState(true);
	const [infoToUpdate, setInfoToUpdate] = useState('');
	const [otherStatusValue, setOtherStatusValue] = useState('');
	const [newStatus, setNewStatus] = useState(0);
	const [horseDeathCause, setHorseDeathCause] = useState(0);
	const [horseImages, setHorseImages] = useState([]);
	// HORSE ADDRESS STATE
	const [horseAddress, setHorseAddress] = useState('');
	const [horseSuburb, setHorseSuburb] = useState('');
	const [horseState, setHorseState] = useState('');
	const [horsePostCode, setHorsePostCode] = useState('');
	const [horseCountry, setHorseCountry] = useState(DEFAULT_COUNTRY);
	const [isAddressManual, setIsAddressManual] = useState(false);

	const [additionalInfo, setAdditionalInfo] = useState('');
	const [isTermsAccepted, setIsTermsAccepted] = useState(false);
	const [dateOfDeath, setDateOfDeath] = useState(null);
	const [euthanasiaReason, setEuthanasiaReason] = useState(0);
	const [injuryType, setInjuryType] = useState([]);
	const [otherInjury, setOtherInjury] = useState('');
	const [illnessType, setIllnessType] = useState([]);
	const [otherIllness, setOtherIllness] = useState('');
	const [unsuitableForRehomingType, setUnsuitableForRehomingType] = useState(0);
	const [otherUnsuitableForRehoming, setOtherUnsuitableForRehoming] = useState(
		''
	);
	const [horseRelationshipOptions, setHorseRelationshipOptions] = useState([]);
	const [horseStatuses, setHorseStatuses] = useState([BLANK_OPTION]);
	const [horseDeathReasonOptions, setHorseDeathReasonOptions] = useState([]);
	const [euthanasiaReasons, setEuthanasiaReasons] = useState([BLANK_OPTION]);
	const [euthanasiaInjuryTypes, setEuthanasiaInjuryTypes] = useState([]);
	const [euthanasiaIllnessTypes, setEuthanasiaIllnessTypes] = useState([]);
	const [euthanasiaOtherTypes, setEuthanasiaOtherTypes] = useState([]);
	const [
		euthansiaUnsuitableForRehomingReasons,
		setEuthanasiaUnsuitableForReasons
	] = useState([BLANK_OPTION]);
	const [knackeryPostcode, setKnackeryPostcode] = useState('');
	const [knackerySuburb, setKnackerySuburb] = useState('');
	const [retiredPostcode, setRetiredPostcode] = useState('');
	const [retiredSuburb, setRetiredSuburb] = useState('');
	const [otherPostcode, setOtherPostcode] = useState('');
	const [otherSuburb, setOtherSuburb] = useState('');
	const [euthanasiaOtherType, setEuthanasiaOtherType] = useState(0);
	const [infoAboutDeath, setInfoAboutDeath] = useState('');
	const [retiredDate, setRetiredDate] = useState(null);
	const [otherDate, setOtherDate] = useState(null);
	const [updateLocation, setUpdateLocation] = useState(false);
	const [currentHorseStatusValue, setCurrentHorseStatusValue] = useState('');
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	// Form Validation
	const [horseRelationshipError, setHorseRelationshipError] = useState('');
	const [
		horseRelationshipOtherError,
		setHorseRelationshipOtherError
	] = useState('');
	const [lastSeenDateError, setLastSeenDateError] = useState('');
	const [infoToUpdateError, setInfoToUpdateError] = useState('');
	const [newStatusError, setNewStatusError] = useState('');
	const [submitEnabled, setSubmitEnabled] = useState(true);
	const [causeOfDeathError, setCauseOfDeathError] = useState('');
	const [dateOfDeathError, setDateOfDeathError] = useState('');
	const [euthanasiaReasonError, setEuthanasiaReasonError] = useState('');
	const [injuryTypeError, setInjuryTypeError] = useState('');
	const [injuryTypeOtherError, setInjuryTypeOtherError] = useState('');
	const [illnessTypeError, setIllnessTypeError] = useState('');
	const [illnessTypeOtherError, setIllnessTypeOtherError] = useState('');
	const [
		unsuitableForRehomingTypeOtherError,
		setUnsuitableForRehomingTypeOtherError
	] = useState('');
	const [otherStatusValueError, setOtherStatusValueError] = useState('');

	const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	// Address validation
	const [addressValidationError, setAddressValidationError] = useState('');
	const [suburbValidationError, setSuburbValidationError] = useState('');
	const [postcodeValidationError, setPostcodeValidationError] = useState('');
	const [stateValidationError, setStateValidationError] = useState('');
	const [countryValidationError, setCountryValidationError] = useState('');
	const [isOwner, setIsOwner] = useState(false);
	const [
		hasClickedClaimThoroughbred,
		setHasClickedClaimThoroughbred
	] = useState(false);
	const [validationErrorsPresent, setValidationErrorsPresent] = useState(false);
	const [
		confirmAccurateInfoValidationError,
		setConfirmAccurateInfoValidationError
	] = useState('');
	const [imageUploadValidationError, setImageUploadValidationError] = useState(
		''
	);
	const [
		unsuitableForRehomingTypeError,
		setUnsuitableForRehomingTypeError
	] = useState('');
	const [euthanasiaOtherTypeError, setEuthanasiaOtherTypeError] = useState('');
	const termsCheckBoxData = {
		id: 'termsCheckBox',
		label: TERMS_NOTICE,
		value: TERMS_NOTICE,
		isSelected: isTermsAccepted
	};
	const manualAddressCheckBoxData = {
		id: 'manualAddressCheckbox',
		label: MANUAL_ADDRESS_LABEL,
		value: MANUAL_ADDRESS_LABEL,
		isSelected: isAddressManual
	};

	useEffect(() => {
		if (currentUser) {
			AppSyncService.execute(isClaimedByMember, {
				horseCode,
				memberExternalId: currentUser?.UserId
			}).then((result) => {
				if (result.data?.isClaimedByMember) {
					setIsOwner(true);
				}
			});
		}
	}, [currentUser]);

	useEffect(() => {
		if (currentUser) {
			AppSyncService.execute(getHorseClaim, {
				horseCodeForClaim: horseCode,
				memberExternalId: currentUser?.UserId
			}).then((result) => {
				if (result.data?.getHorseClaim?.ownershipType) {
					setHasClickedClaimThoroughbred(true);
				}
			});
		}
	}, [currentUser]);

	useEffect(() => {
		AppSyncService.execute(getShareInformationOptions).then((result) => {
			if (result.data) {
				setHorseRelationshipOptions([
					BLANK_OPTION,
					...result.data.listShareInfoRelationships
				]);
				setHorseStatuses([BLANK_OPTION, ...result.data.listShareInfoStatuses]);
				setHorseDeathReasonOptions([
					BLANK_OPTION,
					...result.data.listShareInfoCauseOfDeaths
				]);
				setEuthanasiaReasons([
					BLANK_OPTION,
					...result.data.listShareInfoEuthanisedReasons
				]);
				setEuthanasiaInjuryTypes(
					sortArray(result.data.listShareInfoEuthanisedInjuries, true)
				);
				setEuthanasiaIllnessTypes(
					sortArray(result.data.listShareInfoEuthanisedIllness, true)
				);
				setEuthanasiaOtherTypes([
					BLANK_OPTION,
					...result.data.listShareInfoEuthanisedOtherReasons
				]);
				setEuthanasiaUnsuitableForReasons([
					BLANK_OPTION,
					...result.data.listShareInfoEuthanisedUnsuitableReasons
				]);
			}
		});
	}, []);

	useEffect(() => {
		AppSyncService.execute(getHorses, { horseCode: +horseCode }).then(
			(result) => {
				if (result.data) {
					setHorseName(result.data.getHorse.name);
					if (result.data.getHorse?.status) {
						setCurrentHorseStatusValue(result.data.getHorse.status);
					}
				}
			}
		);
	}, []);

	const initiliaseState = (savedShareInformationFormData) => {
		const savedInfo = savedShareInformationFormData;
		if (savedInfo.id) {
			setShareInformationFormId(savedInfo.id);
		}
		if (savedInfo.relationship.id) {
			setHorseRelationship(savedInfo.relationship.id);
		}
		if (savedInfo.relationshipOther) {
			setHorseRelationshipOther(savedInfo.relationshipOther);
		}
		if (savedInfo.lastSeenDate) {
			setLastSeenDate(savedInfo.lastSeenDate);
		}
		if (savedInfo.isStatusCorrect != null) {
			setIsListedStatusCorrect(savedInfo.isStatusCorrect);
		}
		if (savedInfo.informationToUpdate != null) {
			setInfoToUpdate(savedInfo.informationToUpdate);
		}
		if (savedInfo.otherStatusValue != null) {
			setOtherStatusValue(savedInfo.otherStatusValue);
		}

		if (savedInfo.address != null) {
			setHorseAddress(savedInfo.address);
		}
		if (savedInfo.suburb != null) {
			setHorseSuburb(savedInfo.suburb);
		}
		if (savedInfo.postcode != null) {
			setHorsePostCode(savedInfo.postcode);
		}
		if (savedInfo.state != null) {
			setHorseState(savedInfo.state);
		}
		if (savedInfo.country != null) {
			setHorseCountry(savedInfo.country);
		}
		if (savedInfo.locationValidated != null) {
			setIsAddressManual(!savedInfo.locationValidated);
		}
		if (savedInfo.info != null) {
			setAdditionalInfo(savedInfo.info);
		}
		if (savedInfo.confirmAccurateInfo != null) {
			setIsTermsAccepted(savedInfo.confirmAccurateInfo);
		}
		if (savedInfo.newStatus.id) {
			setNewStatus(savedInfo.newStatus.id);
		}
		if (savedInfo.causeOfDeath.id) {
			setHorseDeathCause(savedInfo.causeOfDeath.id);
		}
		if (savedInfo.naturalDateOfDeath) {
			setDateOfDeath(savedInfo.naturalDateOfDeath);
		}
		if (savedInfo.ohepDate) {
			setDateOfDeath(savedInfo.ohepDate);
		}
		if (savedInfo.knackeryDate) {
			setDateOfDeath(savedInfo.knackeryDate);
		}
		if (savedInfo.knackeryPostcode) {
			setKnackeryPostcode(savedInfo.knackeryPostcode);
		}
		if (savedInfo.knackerySuburb) {
			setKnackerySuburb(savedInfo.knackerySuburb);
		}
		if (savedInfo.deceasedUnknownDate) {
			setDateOfDeath(savedInfo.deceasedUnknownDate);
		}
		if (savedInfo.deceasedOtherDate) {
			setDateOfDeath(savedInfo.deceasedOtherDate);
		}
		if (savedInfo.deceasedOtherInfo) {
			setInfoAboutDeath(savedInfo.deceasedOtherInfo);
		}
		if (savedInfo.euthanisedDate) {
			setDateOfDeath(savedInfo.euthanisedDate);
		}
		if (savedInfo.euthanisedReason?.id) {
			setEuthanasiaReason(savedInfo.euthanisedReason.id);
		}
		if (savedInfo.euthanisedInjuryOther) {
			setOtherInjury(savedInfo.euthanisedInjuryOther);
		}
		if (savedInfo.euthanisedIllnessOther) {
			setOtherIllness(savedInfo.euthanisedIllnessOther);
		}
		if (savedInfo.euthanisedUnsuitable?.id) {
			setUnsuitableForRehomingType(savedInfo.euthanisedUnsuitable.id);
		}
		if (savedInfo.euthanisedUnsuitableOther) {
			setOtherUnsuitableForRehoming(savedInfo.euthanisedUnsuitableOther);
		}
		if (savedInfo.euthanisedOther?.id) {
			setEuthanasiaOtherType(savedInfo.euthanisedOther.id);
		}
		if (savedInfo.retiredStatusDate) {
			setRetiredDate(savedInfo.retiredStatusDate);
		}
		if (savedInfo.retiredPostcode) {
			setRetiredPostcode(savedInfo.retiredPostcode);
		}
		if (savedInfo.retiredSuburb) {
			setRetiredSuburb(savedInfo.retiredSuburb);
		}
		if (savedInfo.updateLocationAndStatus) {
			setUpdateLocation(savedInfo.updateLocationAndStatus);
		}
		if (savedInfo.otherStatusDate) {
			setOtherDate(savedInfo.otherStatusDate);
		}
		if (savedInfo.otherStatusPostcode) {
			setOtherPostcode(savedInfo.otherStatusPostcode);
		}
		if (savedInfo.otherStatusSuburb) {
			setOtherSuburb(savedInfo.otherStatusSuburb);
		}
		if (savedInfo.uploads) {
			setHorseImages(savedInfo.uploads);
		}
		if (savedInfo.injuries) {
			setInjuryType(getInjuryTypes(savedInfo.injuries));
		}
		if (savedInfo.illnesses) {
			setIllnessType(getIllnessTypes(savedInfo.illnesses));
		}
	};
	const isHorseStatusDeceased = checkDropDownValue(
		horseStatuses,
		newStatus,
		'Deceased'
	);
	const isHorseStatusRetired = checkDropDownValue(
		horseStatuses,
		newStatus,
		'Retired'
	);
	const isHorseStatusOther = isOptionOther(horseStatuses, newStatus);
	const isHorseRelationshipsOther = isOptionOther(
		horseRelationshipOptions,
		horseRelationship
	);
	const isHorseDeathCauseEuthanasia = checkDropDownValue(
		horseDeathReasonOptions,
		horseDeathCause,
		'Euthanised'
	);
	const isHorseDeathCauseKnackery = checkDropDownValue(
		horseDeathReasonOptions,
		horseDeathCause,
		'Knackery/Abattoir'
	);
	const isHorseDeathCauseOther = isOptionOther(
		horseDeathReasonOptions,
		horseDeathCause
	);
	const isEuthanasiaReasonInjury = checkDropDownValue(
		euthanasiaReasons,
		euthanasiaReason,
		'Injury'
	);
	const isInuryTypeOther = isMultiSelectOptionOther(injuryType);
	const isEuthanasaiReasonIllness = euthanasiaReason == 2;
	const isIllnessTypeOther = isMultiSelectOptionOther(illnessType);
	const isEuthanasiaReasonUnsuitableForRehoming = euthanasiaReason == 3;
	const isUnsuitableForRehomingTypeOther = isOptionOther(
		euthansiaUnsuitableForRehomingReasons,
		unsuitableForRehomingType
	);
	const isHorseDeathCauseNatural = checkDropDownValue(
		horseDeathReasonOptions,
		horseDeathCause,
		'Natural'
	);
	const isHorseDeathCauseOHEP = checkDropDownValue(
		horseDeathReasonOptions,
		horseDeathCause,
		'OHEP'
	);
	const isHorseDeathCauseUnknown = checkDropDownValue(
		horseDeathReasonOptions,
		horseDeathCause,
		'Unknown'
	);
	const isEuthanasaiReasonOther = isOptionOther(
		euthanasiaReasons,
		euthanasiaReason
	);

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const validateFormFields = () => {
		let isErrorsPresent = false;
		if (horseRelationship == 0) {
			setHorseRelationshipError('Relationship to horse is required');
			isErrorsPresent = true;
		}
		if (isHorseRelationshipsOther && !horseRelationshipOther) {
			setHorseRelationshipOtherError('Other Relationship to horse is required');
			isErrorsPresent = true;
		}
		if (!lastSeenDate) {
			setLastSeenDateError('Last seen date is required');
			isErrorsPresent = true;
		}
		if (isListedStatusCorrect && !infoToUpdate) {
			setInfoToUpdateError('Horse information to update is required');
			isErrorsPresent = true;
		}
		if (!isListedStatusCorrect && newStatus == BLANK_OPTION.id) {
			setNewStatusError('New horse status is required');
			isErrorsPresent = true;
		}
		if (isHorseStatusDeceased && horseDeathCause == BLANK_OPTION.id) {
			setCauseOfDeathError('Cause of death is required');
			isErrorsPresent = true;
		}
		if (isHorseStatusDeceased && !dateOfDeath) {
			setDateOfDeathError('Date of death is required');
			isErrorsPresent = true;
		}
		if (isHorseDeathCauseEuthanasia && euthanasiaReason == BLANK_OPTION.id) {
			setEuthanasiaReasonError('Euthanasia reason is required');
			isErrorsPresent = true;
		}
		if (isEuthanasiaReasonInjury && injuryType == BLANK_OPTION.id) {
			setInjuryTypeError('Injury type is required');
			isErrorsPresent = true;
		}
		if (isInuryTypeOther && otherInjury.trim() === '') {
			setInjuryTypeOtherError('Other is required');
			isErrorsPresent = true;
		}
		if (isIllnessTypeOther && otherIllness.trim() === '') {
			setIllnessTypeOtherError('Other is required');
			isErrorsPresent = true;
		}
		if (
			isUnsuitableForRehomingTypeOther &&
			otherUnsuitableForRehoming.trim() === ''
		) {
			setUnsuitableForRehomingTypeOtherError('Other is required');
			isErrorsPresent = true;
		}
		if (isEuthanasaiReasonIllness && illnessType == BLANK_OPTION.id) {
			setIllnessTypeError('Illness type is required');
			isErrorsPresent = true;
		}
		if (
			isEuthanasiaReasonUnsuitableForRehoming &&
			unsuitableForRehomingType == BLANK_OPTION.id
		) {
			setUnsuitableForRehomingTypeError(
				'Unsuitable for rehoming type is required'
			);
			isErrorsPresent = true;
		}
		if (isEuthanasaiReasonOther && euthanasiaOtherType == BLANK_OPTION.id) {
			setEuthanasiaOtherTypeError('Other type is required');
			isErrorsPresent = true;
		}
		if (isHorseStatusOther && !otherStatusValue.trim()) {
			setOtherStatusValueError('Please advise the correct status.');
			isErrorsPresent = true;
		}
		if (
			(horseRelationship == RELATIONSHIP_OWNER_PARTOWNER_ID ||
				horseRelationship == RELATIONSHIP_PREV_OWNER_ID ||
				horseRelationship == RELATIONSHIP_PREV_PART_OWNER_ID) &&
			horseImages.length < 2
		) {
			setImageUploadValidationError('At least 2 horse images are required');
			isErrorsPresent = true;
		} else {
			setImageUploadValidationError('');
		}
		if (updateLocation) {
			if (!horseAddress) {
				setAddressValidationError('Address is required');
				isErrorsPresent = true;
			}
			if (!horseSuburb) {
				setSuburbValidationError('Suburb is required');
				isErrorsPresent = true;
			}
			if (!horsePostCode) {
				setPostcodeValidationError('Postcode is required');
				isErrorsPresent = true;
			}
			if (!horseState) {
				setStateValidationError('State is required');
				isErrorsPresent = true;
			}
			if (!horseCountry) {
				setCountryValidationError('Country is required');
				isErrorsPresent = true;
			}
		}
		if (!isTermsAccepted) {
			setConfirmAccurateInfoValidationError(
				'Please confirm that this information is accurate'
			);
			isErrorsPresent = true;
		}
		setSubmitEnabled(!isErrorsPresent);
		setValidationErrorsPresent(isErrorsPresent);
		return isErrorsPresent;
	};

	const clearError = (errorUpdateFunction) => {
		errorUpdateFunction('');
		setSubmitEnabled(true);
		setValidationErrorsPresent(false);
	};

	const clearAddressValidation = () => {
		setAddressValidationError('');
		setSuburbValidationError('');
		setPostcodeValidationError('');
		setStateValidationError('');
		setCountryValidationError('');
		setSubmitEnabled(true);
	};

	const populateHorseAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setHorseAddress(address);
		setHorseSuburb(addressDetails.Suburb);
		setHorsePostCode(addressDetails.Postcode);
		setHorseState(addressDetails.State);
		setHorseCountry(addressDetails.Country);
		clearAddressValidation();
	};

	const generateShareInformationSavePayload = () => {
		// Saving the latest snapshot of data to the DB
		return {
			horseCode,
			...(shareInformationFormId && { id: shareInformationFormId }),
			relationshipId: horseRelationship != 0 ? horseRelationship : null,
			relationshipOther: horseRelationshipOther || null,
			lastSeenDate,
			isStatusCorrect: isListedStatusCorrect,
			otherStatusValue,
			informationToUpdate: infoToUpdate || null,
			address: horseAddress,
			suburb: horseSuburb,
			state: horseState,
			country: horseCountry,
			postcode: horsePostCode,
			locationValidated: !isAddressManual,
			info: additionalInfo || null,
			confirmAccurateInfo: isTermsAccepted,
			newStatusId: newStatus != 0 ? newStatus : null,
			causeOfDeathId: horseDeathCause != 0 ? horseDeathCause : null,
			naturalDateOfDeath: isHorseDeathCauseNatural ? dateOfDeath : null,
			ohepDate: isHorseDeathCauseOHEP ? dateOfDeath : null,
			knackeryDate: isHorseDeathCauseKnackery ? dateOfDeath : null,
			knackeryPostcode: knackeryPostcode || null,
			knackerySuburb: knackerySuburb || null,
			deceasedUnknownDate: isHorseDeathCauseUnknown ? dateOfDeath : null,
			deceasedOtherDate: isHorseDeathCauseOther ? dateOfDeath : null,
			deceasedOtherInfo: infoAboutDeath || null,
			euthanisedReasonId: euthanasiaReason != 0 ? euthanasiaReason : null,
			euthanisedDate: isHorseDeathCauseEuthanasia ? dateOfDeath : null,
			euthanisedInjuryOther: otherInjury || null,
			euthanisedIllnessOther: otherIllness || null,
			euthanisedUnsuitableId:
				unsuitableForRehomingType != 0 ? unsuitableForRehomingType : null,
			euthanisedUnsuitableOther: otherUnsuitableForRehoming || null,
			euthanisedOtherId: euthanasiaOtherType != 0 ? euthanasiaOtherType : null,
			retiredStatusDate: retiredDate,
			updateLocationAndStatus: updateLocation,
			retiredPostcode: retiredPostcode || null,
			retiredSuburb: retiredSuburb || null,
			otherStatusDate: otherDate,
			otherStatusPostcode: otherPostcode || null,
			otherStatusSuburb: otherSuburb || null,
			injuries: setInjuryTypes(injuryType),
			illnesses: setIllnessTypes(illnessType)
		};
	};

	useEffect(() => {
		AppSyncService.execute(getShareInformationForm, {
			horseCode: +horseCode,
			memberExternalId
		}).then((data) => {
			if (data.data?.getShareInformationForm) {
				initiliaseState(data.data.getShareInformationForm);
			}
			setSubmitEnabled(true);
		});
	}, []);

	const updateInjuryType = (value) => {
		const newInjuryType = value;
		setInjuryType(newInjuryType);
		clearError(setInjuryTypeError);
		clearError(setInjuryTypeOtherError);
		if (!isOptionOther(euthanasiaInjuryTypes, injuryType)) {
			setOtherInjury('');
		}
	};

	const InjuryTypeInput = (
		<div>
			<div className={classes.fieldTitle}>
				If yes, please select injury type(s)*
			</div>
			<FormMultiSelectList
				id={`${PAGE_PREFIX}-injury-type-dropdown`}
				name="injuryTypes"
				listOptions={euthanasiaInjuryTypes.map(({ id, value }) => ({
					value: id,
					label: value
				}))}
				selectedIds={injuryType}
				onSelectChange={updateInjuryType}
			/>
			{injuryTypeError && <ValidationErrorText errorText={injuryTypeError} />}
		</div>
	);

	const OtherInjuryInput = (
		<>
			<FormOtherInput
				id={`${PAGE_PREFIX}-injury-other-textfield`}
				value={otherInjury}
				onChange={(e) => {
					clearError(setInjuryTypeOtherError);
					setOtherInjury(e.target.value);
				}}
			/>
			{injuryTypeOtherError && (
				<ValidationErrorText errorText={injuryTypeOtherError} />
			)}
		</>
	);

	const updateIllnessType = (value) => {
		const newIllnessType = value;
		setIllnessType(newIllnessType);
		clearError(setIllnessTypeError);
		clearError(setIllnessTypeOtherError);
		if (!isOptionOther(euthanasiaIllnessTypes, newIllnessType)) {
			setOtherIllness('');
		}
	};

	const IllnessTypeInput = (
		<div className={classes.fieldContainer}>
			<div className={classes.fieldTitle}>
				If yes, please select illness type(s)*
			</div>
			<FormMultiSelectList
				id={`${PAGE_PREFIX}-illness-type-dropdown`}
				name="illnessTypes"
				listOptions={euthanasiaIllnessTypes.map(({ id, value }) => ({
					value: id,
					label: value
				}))}
				selectedIds={illnessType}
				onSelectChange={updateIllnessType}
			/>
			{illnessTypeError && <ValidationErrorText errorText={illnessTypeError} />}
		</div>
	);

	const OtherIllnessInput = (
		<>
			<FormOtherInput
				id="share-information-other-illness-textfield"
				value={otherIllness}
				onChange={(e) => {
					clearError(setIllnessTypeOtherError);
					setOtherIllness(e.target.value);
				}}
			/>
			{illnessTypeOtherError && (
				<ValidationErrorText errorText={illnessTypeOtherError} />
			)}
		</>
	);

	const updateUnsuitableForRehomingType = (event) => {
		const newUnsuitableForRehomingType = event.target.value;
		setUnsuitableForRehomingType(newUnsuitableForRehomingType);
		clearError(setUnsuitableForRehomingTypeError);
		clearError(setUnsuitableForRehomingTypeOtherError);
		if (
			!isOptionOther(
				euthansiaUnsuitableForRehomingReasons,
				unsuitableForRehomingType
			)
		) {
			setOtherUnsuitableForRehoming('');
		}
	};

	const UnsuitableForRehomingTypeIput = (
		<FormDropDownInputField
			id={`${PAGE_PREFIX}-unsuitable-for-rehoming-reason-type-dropdown`}
			fieldLabel="Unsuitable for rehoming reason type"
			options={euthansiaUnsuitableForRehomingReasons}
			selectedItemId={unsuitableForRehomingType}
			onChange={updateUnsuitableForRehomingType}
			errorMessage={unsuitableForRehomingTypeError}
		/>
	);

	const OtherUnsuitableForRehomingInput = (
		<>
			<FormOtherInput
				id="share-information-other-unsuitable-for-rehoming-textfield"
				value={otherUnsuitableForRehoming}
				onChange={(e) => {
					clearError(setUnsuitableForRehomingTypeOtherError);
					setOtherUnsuitableForRehoming(e.target.value);
				}}
			/>
			{unsuitableForRehomingTypeOtherError && (
				<ValidationErrorText errorText={unsuitableForRehomingTypeOtherError} />
			)}
		</>
	);

	const updateEuthanasiaOtherType = (event) => {
		const newUnsuitableForRehomingType = event.target.value;
		setEuthanasiaOtherType(newUnsuitableForRehomingType);
		clearError(setEuthanasiaOtherTypeError);
	};

	const EuthanasiaOtherTypeInput = (
		<FormDropDownInputField
			id={`${PAGE_PREFIX}-euthanasia-other-dropdown`}
			fieldLabel="Other type"
			options={euthanasiaOtherTypes}
			selectedItemId={euthanasiaOtherType}
			onChange={updateEuthanasiaOtherType}
			errorMessage={euthanasiaOtherTypeError}
		/>
	);

	const HorseDeathCauseOtherInput = (
		<Row style={{ marginTop: 15 }}>
			<FormFieldWrapper fieldLabel="Information about death" fullWidth>
				<FormTextField
					id="share-information-info-about-death-textfield"
					value={infoAboutDeath}
					onChange={(e) => setInfoAboutDeath(e.target.value)}
					canEdit
				/>
			</FormFieldWrapper>
		</Row>
	);

	const updateDate = (date, updateFunction) => {
		if (date == '') {
			updateFunction(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				updateFunction(formattedDate);
			} else {
				updateFunction(null);
			}
		}
	};

	const updateHorseRelationship = (event) => {
		const newHorseRelationshipType = event.target.value;
		setHorseRelationship(newHorseRelationshipType);
		clearError(setHorseRelationshipError);
		if (
			newHorseRelationshipType != RELATIONSHIP_OWNER_PARTOWNER_ID &&
			newHorseRelationshipType != RELATIONSHIP_PREV_OWNER_ID &&
			newHorseRelationshipType != RELATIONSHIP_PREV_PART_OWNER_ID
		) {
			clearError(setImageUploadValidationError);
		}
		if (!isOptionOther(horseRelationshipOptions, newHorseRelationshipType)) {
			setHorseRelationshipOther('');
		}
	};

	const setInjuryTypes = (injuries) => {
		let types = [];
		injuries.map((val) => {
			types.push(val.value);
		});
		return types;
	};

	const getInjuryTypes = (injuries) => {
		let types = [];
		injuries.map((val) => {
			types.push({ value: val.id, label: val.value });
		});
		return types;
	};

	const setIllnessTypes = (injuries) => {
		let types = [];
		injuries.map((val) => {
			types.push(val.value);
		});
		return types;
	};

	const getIllnessTypes = (illnesses) => {
		let types = [];
		illnesses.map((val) => {
			types.push({ value: val.id, label: val.value });
		});
		return types;
	};

	const HorseDeathCauseEuthanasiaInput = (
		<>
			{isEuthanasiaReasonInjury && (
				<>
					<Row style={{ marginTop: 15, marginLeft: -15 }}>
						<Col xs={12}>{InjuryTypeInput}</Col>
					</Row>
					{isInuryTypeOther && OtherInjuryInput}
				</>
			)}
			{isEuthanasaiReasonIllness && (
				<>
					<Row style={{ marginTop: 15, marginLeft: -15 }}>
						<Col xs={12}>{IllnessTypeInput}</Col>
					</Row>
					{isIllnessTypeOther && OtherIllnessInput}
				</>
			)}
			{isEuthanasiaReasonUnsuitableForRehoming && (
				<>
					<Row style={{ marginTop: 15 }}>{UnsuitableForRehomingTypeIput}</Row>
					{isUnsuitableForRehomingTypeOther && OtherUnsuitableForRehomingInput}
				</>
			)}
			{isEuthanasaiReasonOther && (
				<Row style={{ marginTop: 15 }}>{EuthanasiaOtherTypeInput}</Row>
			)}
		</>
	);

	const KnackeryLocationInput = (
		<Row style={{ marginTop: 15 }}>
			<FormFieldWrapper fieldLabel="Knackery Postcode">
				<FormTextField
					value={knackeryPostcode}
					onChange={(e) => {
						const isValidPostCode = validatePostCode(e.target.value);
						if (isValidPostCode) {
							setKnackeryPostcode(e.target.value);
						}
					}}
					canEdit
				/>
			</FormFieldWrapper>
			<FormFieldWrapper fieldLabel="Knackery Suburb">
				<FormTextField
					value={knackerySuburb}
					onChange={(e) => setKnackerySuburb(e.target.value)}
					canEdit
				/>
			</FormFieldWrapper>
		</Row>
	);

	const DateOfDeathInput = (
		<FormFieldWrapper
			fieldLabel="Date of death"
			errorMessage={dateOfDeathError}
			isRequired
		>
			<FormDatePicker
				DateValue={dateOfDeath}
				updateSelection={(value) => {
					clearError(setDateOfDeathError);
					updateDate(value, setDateOfDeath);
				}}
			/>
		</FormFieldWrapper>
	);

	const updateEuthanasiaReason = (event) => {
		const newReason = event.target.value;
		setEuthanasiaReason(newReason);
		clearError(setEuthanasiaReasonError);
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Injury')) {
			clearEuthanaiseInuryInputFields();
		}
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Illness')) {
			clearEuthanaiseIllnessInputFields();
		}
		if (
			!checkDropDownValue(
				euthanasiaReasons,
				newReason,
				'Unsuitable for rehoming'
			)
		) {
			clearEuthanaiseUnsuitableForRehomingInputFields();
		}
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Other')) {
			clearEuthanaiseOtherInputFields();
		}
	};

	const updateHorseDeathCause = (event) => {
		const newHorseDeathCause = event.target.value;
		clearError(setCauseOfDeathError);
		setHorseDeathCause(newHorseDeathCause);
		setDateOfDeath(null);
		if (
			!checkDropDownValue(
				horseDeathReasonOptions,
				newHorseDeathCause,
				'Euthanised'
			)
		) {
			clearEuthanaiseInputFields();
		}
		if (
			!checkDropDownValue(
				horseDeathReasonOptions,
				newHorseDeathCause,
				'Knackery/Abbatoir'
			)
		) {
			clearKnackeryInputFields();
		}
		if (
			!checkDropDownValue(horseDeathReasonOptions, newHorseDeathCause, 'Other')
		) {
			clearDeceasedOtherInputFields();
		}
	};

	const DeceasedHorseInput = (
		<>
			<Row style={{ marginTop: 15 }}>
				<FormFieldWrapper
					fieldLabel="What is the cause of death?"
					errorMessage={causeOfDeathError}
					isRequired={true}
				>
					<FormDropDown
						id={`${PAGE_PREFIX}-death-cause-dropdown`}
						items={horseDeathReasonOptions}
						selectedId={horseDeathCause}
						onChange={updateHorseDeathCause}
						showBlank
					/>
				</FormFieldWrapper>
			</Row>
			<Row style={{ marginTop: 15 }}>
				{DateOfDeathInput}
				{isHorseDeathCauseEuthanasia && (
					<FormFieldWrapper
						fieldLabel="Reason"
						errorMessage={euthanasiaReasonError}
						isRequired
					>
						<FormDropDown
							id={`${PAGE_PREFIX}-euthanasia-reasons-dropdown`}
							items={euthanasiaReasons}
							selectedId={euthanasiaReason}
							onChange={updateEuthanasiaReason}
							showBlank
						/>
					</FormFieldWrapper>
				)}
			</Row>
			{isHorseDeathCauseEuthanasia && HorseDeathCauseEuthanasiaInput}
			{isHorseDeathCauseKnackery && KnackeryLocationInput}
			{isHorseDeathCauseOther && HorseDeathCauseOtherInput}
		</>
	);

	const RetiredDateInput = (
		<Row style={{ marginTop: 15 }}>
			<FormFieldWrapper fieldLabel="What was the approximate date of this status change?">
				<FormDatePicker
					DateValue={retiredDate}
					updateSelection={(value) => {
						updateDate(value, setRetiredDate);
					}}
				/>
			</FormFieldWrapper>
		</Row>
	);

	const OtherDateInput = (
		<Row style={{ marginTop: 15 }}>
			<FormFieldWrapper fieldLabel="What was the approximate date of this status change?">
				<FormDatePicker
					DateValue={otherDate}
					updateSelection={(value) => {
						updateDate(value, setOtherDate);
					}}
				/>
			</FormFieldWrapper>
		</Row>
	);

	const OtherCorrectStatus = (
		<Row>
			<Col>
				<ResizingTextArea
					title={`Please advise the correct status*`}
					id={`${PAGE_PREFIX}-other-correct-status-textarea`}
					onChange={(e) => {
						clearError(setOtherStatusValueError);
						setOtherStatusValue(e.target.value);
					}}
					value={otherStatusValue}
				/>
				{otherStatusValueError && (
					<ValidationErrorText errorText={otherStatusValueError} />
				)}
			</Col>
		</Row>
	);

	const claimThoroughbred = () => {
		const horseClaimData = {
			horseCode,
			ownershipType: 'OTT Owner',
			score: 0
		};
		AppSyncService.execute(saveHorseClaim, { horseClaimData }).then(
			(result) => {
				if (result.data?.saveHorseClaim?.id) {
					setHasClickedClaimThoroughbred(true);
					addToast({ Message: 'Claim horse form setup', IsSuccess: true });
				}
			}
		);
	};

	const UpdateLocationAndAddressInput = (
		<>
			{!hasClickedClaimThoroughbred && !isOwner && (
				<Row style={{ marginTop: 15 }}>
					<Col>
						<div className={classes.claimThorougbredLinkContainer}>
							<Col lg={8} sm={12} style={{ fontSize: 15 }}>
								If the horse is currently in your possession, please also claim
								the horse to add to your stable and update the ownership
								information
							</Col>
							<Col lg={4} sm={12}>
								<div
									style={{
										width: '100%',
										height: '100%',
										display: 'flex',
										justifyContent: 'flex-end',
										alignItems: 'center'
									}}
								>
									<button
										className={classes.claimButton}
										onClick={() => claimThoroughbred()}
									>
										Claim Thoroughbred
									</button>
								</div>
							</Col>
						</div>
					</Col>
				</Row>
			)}
		</>
	);

	const RetiredHorseInput = <>{RetiredDateInput}</>;

	const OtherHorseInput = (
		<>
			{OtherCorrectStatus}
			{OtherDateInput}
		</>
	);

	const clearRetiredHorseInputFields = () => {
		setRetiredDate(null);
		setRetiredPostcode('');
		setRetiredSuburb('');
	};

	const clearOtherHorseInputFields = () => {
		setOtherDate(null);
		setOtherPostcode('');
		setOtherSuburb('');
	};

	const updateHorseStatus = (event) => {
		const newHorseStatus = event.target.value;
		setNewStatus(newHorseStatus);
		clearError(setNewStatusError);
		if (!checkDropDownValue(horseStatuses, newStatus, 'Deceased')) {
			clearHorseDeathDeceasedInputFields();
		}
		if (!checkDropDownValue(horseStatuses, newStatus, 'Retired')) {
			clearRetiredHorseInputFields();
		}
		if (!checkDropDownValue(horseStatuses, newStatus, 'Other')) {
			clearOtherHorseInputFields();
		}
	};

	const ListedStatusIncorrectInput = (
		<>
			<Row style={{ marginTop: 15 }}>
				<FormFieldWrapper
					fieldLabel="Please select the new status"
					errorMessage={newStatusError}
					isRequired={true}
				>
					<FormDropDown
						id={`${PAGE_PREFIX}-horse-status-dropdown`}
						items={horseStatuses}
						selectedId={newStatus}
						onChange={updateHorseStatus}
						showBlank
					/>
				</FormFieldWrapper>
			</Row>
			{isHorseStatusDeceased && DeceasedHorseInput}
			{isHorseStatusRetired && RetiredHorseInput}
			{isHorseStatusOther && OtherHorseInput}
		</>
	);

	const clearKnackeryInputFields = () => {
		setKnackeryPostcode('');
		setKnackerySuburb('');
	};

	const clearDeceasedOtherInputFields = () => {
		setInfoAboutDeath('');
	};

	const clearEuthanaiseInuryInputFields = () => {
		setInjuryType([]);
		setOtherInjury('');
	};

	const clearEuthanaiseIllnessInputFields = () => {
		setIllnessType([]);
		setOtherIllness('');
	};

	const clearEuthanaiseUnsuitableForRehomingInputFields = () => {
		setUnsuitableForRehomingType(BLANK_OPTION.id);
		setOtherUnsuitableForRehoming('');
	};

	const clearEuthanaiseOtherInputFields = () => {
		setEuthanasiaOtherType(BLANK_OPTION.id);
	};

	const clearEuthanaiseInputFields = () => {
		setEuthanasiaReason(BLANK_OPTION.id);
		clearEuthanaiseInuryInputFields();
		clearEuthanaiseIllnessInputFields();
		clearEuthanaiseUnsuitableForRehomingInputFields();
		clearEuthanaiseOtherInputFields();
	};

	const clearHorseDeathDeceasedInputFields = () => {
		setHorseDeathCause(BLANK_OPTION.id);
		setDateOfDeath(null);
		clearKnackeryInputFields();
		clearDeceasedOtherInputFields();
		clearEuthanaiseInputFields();
	};

	const clearHorseStatusInputFields = () => {
		clearHorseDeathDeceasedInputFields();
		clearRetiredHorseInputFields();
		clearOtherHorseInputFields();
	};

	const clearBranches = (correctStatusListed) => {
		if (correctStatusListed) {
			setNewStatus(BLANK_OPTION.id);
			clearHorseStatusInputFields();
		} else {
			setInfoToUpdate('');
		}
	};

	const uploadImage = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data) {
				const imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						if (shareInformationFormId) {
							AppSyncService.execute(uploadShareInformationImage, {
								filename: image.name,
								key: imageKey,
								shareInformationFormId
							}).then(() => {
								const currentHorseImages = horseImages;
								setHorseImages([
									...currentHorseImages,
									{ filename: image.name, key: imageKey }
								]);
								addToast({ Message: 'Horse image uploaded', IsSuccess: true });
							});
						} else {
							AppSyncService.execute(createShareInformationForm, {
								shareInformationFormInputData: { horseCode }
							}).then((data) => {
								if (data.data?.saveShareInformationForm) {
									setShareInformationFormId(
										data.data.saveShareInformationForm.id
									);
									AppSyncService.execute(uploadShareInformationImage, {
										filename: image.name,
										key: imageKey,
										shareInformationFormId:
											data.data.saveShareInformationForm.id
									}).then(() => {
										const currentHorseImages = horseImages;
										setHorseImages([
											...currentHorseImages,
											{ filename: image.name, key: imageKey }
										]);
										addToast({
											Message: 'Horse image uploaded',
											IsSuccess: true
										});
									});
								}
							});
						}
					});
			}
		});
	};

	const deleteHorseImage = (image) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: image.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteShareInformationImage, {
						key: image.key
					}).then((data) => {
						if (data.data.deleteShareInformationFormUpload) {
							const currentHorseImages = horseImages;
							const newHorseImages = currentHorseImages.filter(
								({ key }) => key != image.key
							);
							setHorseImages(newHorseImages);
							addToast({ Message: 'Horse image removed', IsSuccess: true });
						}
					});
				}
			}
		);
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removeShareInformationForm = () => {
		AppSyncService.execute(deleteShareInformationForm, {
			id: shareInformationFormId
		}).then(() => {
			addToast({ Message: 'Form Cancelled', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		});
	};

	const cancelShareInformationForm = () => {
		const listOfImageKeys = horseImages.map(({ key }) => key);
		if (listOfImageKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfImageKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					removeShareInformationForm();
				}
			});
		} else {
			removeShareInformationForm();
		}
	};

	const saveFormData = () => {
		const shareInformationSavePayload = generateShareInformationSavePayload();
		AppSyncService.execute(createShareInformationForm, {
			shareInformationFormInputData: shareInformationSavePayload
		}).then((data) => {
			if (data.data?.saveShareInformationForm) {
				setShareInformationFormId(data.data.saveShareInformationForm.id);
				addToast({ Message: 'Form Saved', IsSuccess: true });
			}
		});
	};

	const onSaveDraft = (event) => {
		event.preventDefault();
		saveFormData();
	};

	const onSubmit = (event) => {
		event.preventDefault();
		const isValidationErrorsPresent = validateFormFields();
		if (!isValidationErrorsPresent) {
			const shareInformationSavePayload = generateShareInformationSavePayload();
			AppSyncService.execute(createShareInformationForm, {
				shareInformationFormInputData: shareInformationSavePayload
			}).then((data) => {
				if (
					data.data?.saveShareInformationForm &&
					data.data.saveShareInformationForm.id
				) {
					if (newStatus == 1) {
						const deceasedCauseOfDeathId =
							horseDeathCause != 0 ? Number(horseDeathCause) : null;
						const euthanisedReasonId =
							deceasedCauseOfDeathId == 2 && euthanasiaReason != 0
								? Number(euthanasiaReason)
								: null;
						const deceasedInput = {
							horseCode: Number(horseCode),
							euthanisedReasonId,
							deceasedThoroughbredSourceId: 7, //@NOTE - 7 is equal to Share Information
							deceasedDateOfDeath: dateOfDeath,
							deceasedCauseOfDeathId: deceasedCauseOfDeathId,
							memberId: null,
							unsuitableReasons:
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 3 &&
								unsuitableForRehomingType != 0
									? [unsuitableForRehomingType]
									: [],
							euthanisedUnsuitableOther:
								deceasedCauseOfDeathId == 2 && euthanisedReasonId == 3
									? otherUnsuitableForRehoming
									: null,
							euthanisedInjuryOther:
								deceasedCauseOfDeathId == 2 && euthanisedReasonId == 1
									? otherInjury
									: null,
							euthanisedIllnessOther:
								deceasedCauseOfDeathId == 2 && euthanisedReasonId == 2
									? otherIllness
									: null,
							euthanisedUnsuitableId: null,
							deceasedReasonForDeathOther:
								euthanasiaOtherType &&
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 4 &&
								!isNaN(Number(euthanasiaOtherType))
									? [Number(euthanasiaOtherType)]
									: [],
							deceasedKnackeryPostcode:
								deceasedCauseOfDeathId == 4 ? knackeryPostcode : null,
							deceasedKnackerySuburb:
								deceasedCauseOfDeathId == 4 ? knackerySuburb : null,
							deceasedOtherPostcode: null,
							deceasedOtherSuburb: null,
							informationAboutDeath: infoAboutDeath,
							hasOwnerBeenNotified: false,
							status: REVIEW,
							illnesses:
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 2 &&
								illnessType?.length > 0
									? illnessType.map((item) => item.value)
									: [],
							injuries:
								deceasedCauseOfDeathId == 2 &&
								euthanisedReasonId == 1 &&
								injuryType?.length > 0
									? injuryType.map((item) => item.value)
									: [],
							lastSeenDate,
							relationshipId: horseRelationship != 0 ? horseRelationship : null,
							relationshipOther: horseRelationshipOther || null
						};
						AppSyncService.execute(saveDeceasedThoroughbredForm, {
							input: deceasedInput
						});
					}

					setShareInformationFormId(data.data.saveShareInformationForm.id);
					AppSyncService.execute(updateShareInformationFormStatus, {
						id: shareInformationFormId || data.data.saveShareInformationForm.id,
						status: newStatus == 1 ? APPROVED : REVIEW
					}).then(() => {
						addToast({ Message: 'Form Submitted', IsSuccess: true });
						navigate(DASHBOARD_ROUTE);
					});
				}
			});
		}
	};

	return (
		<PageContainer>
			<SEO title="Share information form" />
			<form onSubmit={onSubmit}>
				<FormHeader formTitle={`Share Information about ${horseName}`}>
					If you have information about this horse that can help keep our
					records up to date please share it with us.
				</FormHeader>
				<FormBody>
					<FormSection sectionTitle="Thoroughbred Relationship">
						<Col xs={12}>
							<Row>
								<FormFieldWrapper
									fieldLabel={'Your relationship to this horse'}
									errorMessage={horseRelationshipError}
									isRequired
								>
									<FormDropDown
										id={`${PAGE_PREFIX}-horse-relationship-dropdown`}
										items={horseRelationshipOptions}
										selectedId={horseRelationship}
										onChange={(e) => updateHorseRelationship(e)}
										showBlank
									/>
								</FormFieldWrapper>
								{isHorseRelationshipsOther && (
									<FormFieldWrapper
										fieldLabel={'Other relationship to this horse'}
										errorMessage={horseRelationshipOtherError}
										isRequired
									>
										<FormTextField
											name="relationshipOther"
											id={`${PAGE_PREFIX}-horse-relationshipOther-textfield`}
											value={horseRelationshipOther}
											onChange={(e) => {
												clearError(setHorseRelationshipOtherError);
												setHorseRelationshipOther(e.target.value);
											}}
											canEdit
										/>
									</FormFieldWrapper>
								)}
								<FormFieldWrapper
									fieldLabel={'When did you last see this horse?'}
									errorMessage={lastSeenDateError}
									isRequired
								>
									<FormDatePicker
										DateValue={lastSeenDate}
										updateSelection={(value) => {
											clearError(setLastSeenDateError);
											updateDate(value, setLastSeenDate);
										}}
									/>
								</FormFieldWrapper>
							</Row>
						</Col>
					</FormSection>
					<FormSection sectionTitle="Thoroughbred Status">
						<Col>
							<Row>
								<HorseStatusIndicator status={currentHorseStatusValue} />
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Is the listed status of this horse correct?*
									</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={(newListedStatusValue) => {
											setIsListedStatusCorrect(newListedStatusValue);
											clearBranches(newListedStatusValue);
										}}
										value={isListedStatusCorrect}
									/>
								</div>
							</Row>
							<Row>
								<Col>
									<ResizingTextArea
										title={`Please tell us which information needs to be updated about this horse${
											isListedStatusCorrect ? '*' : ''
										}`}
										id={`${PAGE_PREFIX}-info-to-update-textarea`}
										onChange={(e) => {
											clearError(setInfoToUpdateError);
											setInfoToUpdate(e.target.value);
										}}
										maxChars={200}
										value={infoToUpdate}
									/>
									{infoToUpdateError && (
										<ValidationErrorText errorText={infoToUpdateError} />
									)}
								</Col>
							</Row>
							{!isListedStatusCorrect && ListedStatusIncorrectInput}
							{!isHorseStatusDeceased && UpdateLocationAndAddressInput}
						</Col>
					</FormSection>
					<FormSection sectionTitle="Thoroughbred Photos">
						<Col lg={12} sm={12}>
							<PhotoHelpMessage />
							<Row>
								<FormFieldWrapper
									fieldLabel="Horse Photo (jpg,png)"
									errorMessage={imageUploadValidationError}
									isRequired={
										+horseRelationship === RELATIONSHIP_OWNER_PARTOWNER_ID ||
										+horseRelationship === RELATIONSHIP_PREV_OWNER_ID ||
										+horseRelationship === RELATIONSHIP_PREV_PART_OWNER_ID
									}
								>
									<UploadPhoto
										id="share-information-upload-photo-input"
										formats={['png', 'jpeg']}
										labelName="Upload Photo"
										error={uploadError}
										data={horseImages}
										sizeLimitMB={5}
										numberOfImages={12}
										success={(image) => {
											clearError(setImageUploadValidationError);
											uploadImage(image);
										}}
										getImage={getImage}
										removeImage={deleteHorseImage}
									/>
								</FormFieldWrapper>
							</Row>
						</Col>
					</FormSection>
					<FormSection sectionTitle="Address and Supporting Information">
						<Col>
							<Row>
								<FormFieldWrapper
									fieldLabel="Do you want to update the location of this horse?"
									isRequired={true}
								>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={setUpdateLocation}
										value={updateLocation}
									/>
								</FormFieldWrapper>
							</Row>
							{updateLocation && (
								<>
									<Row style={{ marginBottom: 10, marginTop: 15 }}>
										<FormFieldWrapper
											fieldLabel="Horse Address"
											isRequired
											errorMessage={addressValidationError}
										>
											<AddressAutoSuggestion
												id={`${PAGE_PREFIX}-horse-address-input`}
												value={horseAddress}
												onChange={(value) => {
													clearError(setAddressValidationError);
													setHorseAddress(value);
												}}
												onAddressSelect={populateHorseAddressFields}
												disableAutoSuggestion={isAddressManual}
												canEdit
											/>
										</FormFieldWrapper>
										<FormFieldWrapper
											fieldLabel="Horse Suburb"
											isRequired
											errorMessage={suburbValidationError}
										>
											<FormTextField
												id={`${PAGE_PREFIX}-horse-suburb-textfield`}
												value={horseSuburb}
												onChange={(e) => {
													clearError(setSuburbValidationError);
													setHorseSuburb(e.target.value);
												}}
												isDisabled={!isAddressManual}
												canEdit
											/>
										</FormFieldWrapper>
										<FormFieldWrapper
											fieldLabel="Horse State"
											isRequired
											errorMessage={stateValidationError}
										>
											<StateSelector
												id={`${PAGE_PREFIX}-state-selector`}
												country={horseCountry}
												value={horseState}
												changeHandler={(newStateValue) => {
													clearError(setStateValidationError);
													setHorseState(newStateValue);
												}}
												isDisabled={!isAddressManual}
											/>
										</FormFieldWrapper>
									</Row>
									<Row style={{ marginBottom: 5 }}>
										<FormFieldWrapper
											fieldLabel="Horse Postcode"
											isRequired
											errorMessage={postcodeValidationError}
										>
											<FormTextField
												id={`${PAGE_PREFIX}-postcode-textfield`}
												value={horsePostCode}
												onChange={(e) => {
													const isValidPostcode = validatePostCode(
														e.target.value
													);
													if (isValidPostcode) {
														clearError(setPostcodeValidationError);
														setHorsePostCode(e.target.value);
													}
												}}
												isDisabled={!isAddressManual}
												canEdit={true}
											/>
										</FormFieldWrapper>
										<FormFieldWrapper
											fieldLabel="Horse Country"
											isRequired
											errorMessage={countryValidationError}
										>
											<CountrySelector
												id={`${PAGE_PREFIX}-country-selector`}
												value={horseCountry}
												changeHandler={(newCountryValue) => {
													clearError(setCountryValidationError);
													setHorseCountry(newCountryValue);
												}}
												isDisabled={!isAddressManual}
											/>
										</FormFieldWrapper>
									</Row>
									<Row className={classes.manualEntryCheck}>
										<Col>
											<div>
												<div
													style={{ display: 'flex', alignItems: 'flex-start' }}
												>
													<SimpleCheckBox
														small={true}
														noCapitalize={true}
														custom
														customStyle={{ float: 'left' }}
														data={manualAddressCheckBoxData}
														changeCheckboxState={() => {
															setIsAddressManual(!isAddressManual);
														}}
														key={
															manualAddressCheckBoxData &&
															manualAddressCheckBoxData.id
														}
													/>
													<label style={{ fontSize: 13, paddingLeft: 10 }}>
														{MANUAL_ADDRESS_LABEL}
													</label>
												</div>
											</div>
										</Col>
									</Row>
								</>
							)}
						</Col>
						<FormSectionContentDivider />
						<Col>
							<Row>
								<Col style={{ marginBottom: 15 }}>
									<ResizingTextArea
										title="Is there any additional information that needs to be
                  changed or updated for this horse?"
										id="share-information-additional-info-textarea"
										onChange={(e) => {
											setAdditionalInfo(e.target.value);
										}}
										maxChars={200}
										value={additionalInfo}
									/>
								</Col>
							</Row>
							<div>
								<div style={{ display: 'flex', alignItems: 'flex-start' }}>
									<SimpleCheckBox
										small={true}
										noCapitalize={true}
										custom
										customStyle={{ float: 'left' }}
										data={termsCheckBoxData}
										changeCheckboxState={() => {
											clearError(setConfirmAccurateInfoValidationError);
											setIsTermsAccepted(!isTermsAccepted);
										}}
										key={termsCheckBoxData && termsCheckBoxData.id}
									/>
									<label style={{ fontSize: 13, paddingLeft: 10 }}>
										{TERMS_NOTICE}
									</label>
								</div>
								{confirmAccurateInfoValidationError && (
									<ValidationErrorText
										errorText={confirmAccurateInfoValidationError}
									/>
								)}
							</div>
						</Col>
					</FormSection>
				</FormBody>
				<FormFooter
					onCancel={(event) => {
						event.preventDefault();
						setShowCancelConfirmModal(true);
					}}
					validationErrorsExist={validationErrorsPresent}
					onSave={onSaveDraft}
					submitEnabled={submitEnabled}
				/>
			</form>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			<FormCancelConfirmModal
				showCancelConfirmModal={showCancelConfirmModal}
				onCloseHandler={() => setShowCancelConfirmModal(false)}
				onConfirmHandler={cancelShareInformationForm}
				modalTitle="Cancel Share information form"
			/>
		</PageContainer>
	);
};

export default ShareInformationForm;

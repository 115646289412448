import React, { useState } from 'react';
import { Row, Col, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import { getUserImage } from '../../graphql/custom.queries';
import UploadPhoto from '../UploadPhoto';
import AppSyncService from '../../graphql/AppSyncService';
import Lightbox from 'react-image-lightbox';
import FormLabelField from '../FormLabelField';
import ResizingTextArea from '../ResizingTextArea';
import FormTextField from '../FormTextField';
import { REVIEW } from '../../utils/constants';

const BusinessInformationView = ({
	businessProfileData,
	businessSummary,
	updateBusinessSummary,
	experiences,
	updateExperiences,
	priceRange,
	updatePriceRange,
	facebook,
	updateFacebook,
	instagram,
	updateInstagram,
	twitter,
	updateTwitter,
	website,
	updateWebsite
}) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const screenClassForRender = useScreenClass();
	let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	return (
		<>
			<HeaderBanner
				title="Business Information"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div>
								<ResizingTextArea
									title="Business Summary*"
									charLengthStyle={
										isMobile ? { float: 'right', marginBottom: 10 } : {}
									}
									name="businessSummary"
									id="businessReview-businessInformation-businessSummary"
									value={businessSummary}
									onChange={(e) => {
										updateBusinessSummary(e.target.value);
									}}
									maxChars={700}
									titleStyles={{ paddingBottom: 5 }}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Facilities - On Property*"
							value={businessProfileData.facilitiesOnProperty
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.facilitiesOnProperty.filter(
					(r) => r.value === 'Other'
				).length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Facilities - On Property"
								value={businessProfileData.facilitiesOnPropertyOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Facilities - Off Property"
							value={businessProfileData.facilitiesOffSite
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.facilitiesOffSite.filter(
					(r) => r.value === 'Other'
				).length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Facilities - Off Property"
								value={businessProfileData.facilitiesOffSiteOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title={`Specialty Disciplines${
								businessProfileData.businessProfileType?.id === 1 ? '*' : ''
							}`}
							value={businessProfileData.specialtyDisciplines
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.specialtyDisciplines.filter(
					(r) => r.value === 'Other'
				).length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Specialty Disciplines"
								value={businessProfileData.specialtyDisciplinesOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Services Offered*"
							value={businessProfileData.servicesOffered
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.servicesOffered.filter((r) => r.value === 'Other')
					.length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Services Offered"
								value={businessProfileData.servicesOfferedOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div>
								<ResizingTextArea
									title="Price Range"
									charLengthStyle={
										isMobile ? { float: 'right', marginBottom: 10 } : {}
									}
									name="priceRange"
									id="businessReview-businessInformation-priceRange"
									value={priceRange}
									onChange={(e) => {
										updatePriceRange(e.target.value);
									}}
									maxChars={700}
									titleStyles={{ paddingBottom: 5 }}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div>
								<ResizingTextArea
									title="Experience / Qualifications"
									charLengthStyle={
										isMobile ? { float: 'right', marginBottom: 10 } : {}
									}
									name="experiences"
									id="businessReview-businessInformation-experiences"
									value={experiences}
									onChange={(e) => {
										updateExperiences(e.target.value);
									}}
									maxChars={700}
									titleStyles={{ paddingBottom: 5 }}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Social Links"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Twitter Username</div>
							{businessProfileData?.status === REVIEW && (
								<FormTextField
									id="businessReview-businessInformation-twitter"
									value={twitter}
									preSymbol="@"
									onChange={(e) => {
										updateTwitter(e.target.value);
									}}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							)}
							<span className={classes.profileInfo}>
								<a
									href={`https://twitter.com/${twitter}`}
									rel="noreferrer"
									target="_blank"
								>
									https://twitter.com/{twitter}
								</a>
							</span>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Instagram Username</div>
							{businessProfileData?.status === REVIEW && (
								<FormTextField
									id="businessReview-businessInformation-instagram"
									value={instagram}
									preSymbol="@"
									onChange={(e) => {
										updateInstagram(e.target.value);
									}}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							)}
							<span className={classes.profileInfo}>
								<a
									href={`https://www.instagram.com/${instagram}`}
									rel="noreferrer"
									target="_blank"
								>
									https://www.instagram.com/{instagram}
								</a>
							</span>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Facebook Username</div>
							{businessProfileData?.status === REVIEW && (
								<FormTextField
									id="businessReview-businessInformation-facebook"
									value={facebook}
									preSymbol="@"
									onChange={(e) => {
										updateFacebook(e.target.value);
									}}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							)}
							<span className={classes.profileInfo}>
								<a
									href={`https://www.facebook.com/${facebook}`}
									rel="noreferrer"
									target="_blank"
								>
									https://www.facebook.com/{facebook}
								</a>
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Website</div>
							{businessProfileData?.status === REVIEW && (
								<FormTextField
									id="businessReview-businessInformation-website"
									value={website}
									onChange={(e) => {
										updateWebsite(e.target.value);
									}}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							)}
							<span className={classes.profileInfo}>
								<a href={`//${website}`} rel="noreferrer" target="_blank">
									{website}
								</a>
							</span>
						</div>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Media"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>{`Photo - ${
								businessProfileData.businessProfileType.id === 2
									? `Facilities`
									: `Ridden*`
							}`}</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.riddenPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Photo - Property*</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.propertyPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Photo - General</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.generalPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</div>
		</>
	);
};
export default BusinessInformationView;

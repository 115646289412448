import React from 'react';
import { Col } from 'react-grid-system';
import useStyles from './styles';

const OptionExplanation = ({
	img,
	imgAlt,
	imgHeight,
	imgWidth,
	explanation
}) => {
	const classes = useStyles();
	return (
		<Col lg={'content'} sm={12} style={{ marginTop: 7 }}>
			<div className={classes.explanationContentContainer}>
				<img
					src={img}
					height={imgHeight}
					width={imgWidth}
					className={classes.baseImageStyles}
					alt={imgAlt}
				/>
				{explanation}
			</div>
		</Col>
	);
};

export default OptionExplanation;

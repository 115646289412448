import React from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import { formatDateReadableVariant } from '../../utils/helpers';
const SNSAdmin = ({ horseProfileData, needsBorder = true }) => {
	const classes = useStyles();
	return (
		<>
			<HeaderBanner
				title="SNS (Admin Only)"
				inline
				type="primary"
				styles={{
					marginBottom: '0px',
					marginTop: '0px',
					border: !needsBorder ? 'none' : undefined
				}}
			/>
			<div
				className={classes.container}
				style={!needsBorder ? { border: 'none' } : {}}
			>
				<Row>
					<Col>
						<FormLabelField
							title="Thoroughbred Name"
							value={horseProfileData?.name}
						/>
					</Col>
					<Col>
						<FormLabelField title="Sex" value={horseProfileData?.sex} />
					</Col>
					<Col>
						<FormLabelField
							title="Country of Birth"
							value={horseProfileData?.country}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Dam Name"
							value={horseProfileData?.damName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Sire Name"
							value={horseProfileData?.sireName}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Foal Date"
							value={formatDateReadableVariant(horseProfileData?.foalDate)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Microchip Number"
							value={horseProfileData?.microChipNumber}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Brand Description"
							value={horseProfileData?.horseBrand}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Marking Description"
							value={horseProfileData?.horseMarkings}
						/>
					</Col>
				</Row>
			</div>
		</>
	);
};
export default SNSAdmin;

import React, { useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'white'
	},
	popupIndicator: {
		transform: 'none'
	}
}));

const DynamicAutoSuggestion = ({
	placeholder = '',
	label = '',
	options = [],
	onChangeFunc = () => {},
	onInputChangeFunc = () => {},
	inputValue = '',
	noOptionsText,
	isFilter = false,
	onSearch = () => {}
}) => {
	const classes = useStyles();
	//for filter
	const [openPopup, setOpenPopup] = useState(false);
	const [selectedVal, setSelectedVal] = useState('');

	const onRenderFilterAutoComplete = () => {
		return (
			<Autocomplete
				inputValue={inputValue}
				id="dynamic-auto-suggestion"
				options={options}
				//To change depends on the option props
				getOptionLabel={(option) => {
					return option.horseName || '';
				}}
				clearOnBlur={false}
				onChange={(e, newVal) => onChangeFunc(e, newVal)}
				onInputChange={(e, newValInput) => {
					if (e && newValInput) {
						setOpenPopup(true);
					}
					onInputChangeFunc(e, newValInput);
					setSelectedVal(newValInput);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label={placeholder}
						size="small"
						variant="outlined"
						placeholder="Search..."
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onSearch(e, e.target.value);
								setOpenPopup(false);
							}
						}}
					/>
				)}
				noOptionsText={noOptionsText}
				popupIcon={
					<SearchIcon
						onClick={(e) => {
							onSearch(e, selectedVal);
						}}
					/>
				}
				classes={{
					popupIndicator: classes.popupIndicator,
					root: classes.root
				}}
				open={openPopup}
				onClose={() => {
					setOpenPopup(false);
				}}
			/>
		);
	};

	return (
		<>
			{label.length !== 0 && <Typography paragraph>{label}</Typography>}

			{!isFilter ? (
				<Autocomplete
					inputValue={inputValue}
					id="dynamic-auto-suggestion"
					options={options}
					//To change depends on the option props
					getOptionLabel={(option) => {
						return `${option.firstName} ${option.lastName} ${
							option.email ? `- ${option.email}` : ''
						}`;
					}}
					clearOnBlur={false}
					onChange={(e, newVal) => onChangeFunc(e, newVal)}
					onInputChange={(e, newValInput) => onInputChangeFunc(e, newValInput)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={placeholder}
							size="small"
							variant="outlined"
						/>
					)}
					noOptionsText={noOptionsText}
				/>
			) : (
				onRenderFilterAutoComplete()
			)}
		</>
	);
};

export default DynamicAutoSuggestion;

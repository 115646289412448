import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formFooterContainer: {
		borderBottom: '1px solid #b3bcc1',
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1',
		borderTop: '1px solid #dcebf3',
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: 15,
		width: '100%',
		boxSizing: 'border-box'
	},
	additionalButtonStyles: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer',
		background: 'none',
		border: 'none'
	},
	submitButtonContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	validationErrorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	}
}));

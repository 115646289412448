import React, { useState, useEffect } from 'react';
import { Container, ScreenClassRender } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getBusinessProfileByIdQuery,
	saveBusinessProfileCorrectionsMutation
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import BusinessInformationView from '../BusinessInformationView';
import BusinessProfileView from '../BusinessProfileView';
import BusinessInformationFosterView from '../BusinessInformationFosterView';
import BusinessProfileFosterView from '../BusinessProfileFosterView';
import HeaderBanner from '../HeaderBanner';
import AdminApproval from '../AdminApproval';
import { useToast } from '../../hooks/useToast';

const BusinessProfileReview = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	let { id } = useParams();
	const [businessProfileData, setBusinessProfileData] = useState(null);
	const [businessName, setBusinessName] = useState('');
	const [reviewNotesData, setReviewNotesData] = useState('');
	const [reviewReasonsData, setReviewReasonsData] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [reviewStatus, setReviewStatus] = useState('');
	const [businessSummary, setBusinessSummary] = useState('');
	const [priceRange, setPriceRange] = useState('');
	const [experiences, setExperiences] = useState('');
	const [facebook, setFacebook] = useState('');
	const [instagram, setInstagram] = useState('');
	const [twitter, setTwitter] = useState('');
	const [website, setWebsite] = useState('');
	const [correctionsMade, setCorrectionsMade] = useState(false);
	const [headerTitle, setHeaderTitle] = useState('');
	const [
		selectedBusinessProfileType,
		setSelectedBusinessProfileType
	] = useState(1);

	useEffect(() => {
		const input = {
			id: parseInt(id),
			byBusinessProfile: true
		};
		setIsLoading(true);
		AppSyncService.execute(getBusinessProfileByIdQuery, input).then((data) => {
			if (data.data != null && data.data.getBusinessProfileById != null) {
				setHeaderTitle(
					data.data.getBusinessProfileById.businessProfileType?.value
				);

				setSelectedBusinessProfileType(
					data.data.getBusinessProfileById.businessProfileType?.id
				);

				data.data.getBusinessProfileById = {
					...data.data.getBusinessProfileById
				};

				data.data.getBusinessProfileById.logo = data.data.getBusinessProfileById.logo.filter(
					(selectedBusinessProfile) =>
						selectedBusinessProfile.businessProfileId ==
						data.data.getBusinessProfileById.id
				);
				data.data.getBusinessProfileById.generalPhotos = data.data.getBusinessProfileById.generalPhotos.filter(
					(selectedBusinessProfile) =>
						selectedBusinessProfile.businessProfileId ==
						data.data.getBusinessProfileById.id
				);
				data.data.getBusinessProfileById.riddenPhotos = data.data.getBusinessProfileById.riddenPhotos.filter(
					(selectedBusinessProfile) =>
						selectedBusinessProfile.businessProfileId ==
						data.data.getBusinessProfileById.id
				);
				data.data.getBusinessProfileById.propertyPhotos = data.data.getBusinessProfileById.propertyPhotos.filter(
					(selectedBusinessProfile) =>
						selectedBusinessProfile.businessProfileId ==
						data.data.getBusinessProfileById.id
				);

				setBusinessProfileData(data.data.getBusinessProfileById);
				if (data.data.getBusinessProfileById.businessName != null) {
					setBusinessName(data.data.getBusinessProfileById.businessName);
				}
				if (data.data.getBusinessProfileById.reviewNotes) {
					setReviewNotesData(data.data.getBusinessProfileById.reviewNotes);
				}
				if (data.data.getBusinessProfileById.reviewReasons) {
					setReviewReasonsData(data.data.getBusinessProfileById.reviewReasons);
				}
				setBusinessSummary(data.data.getBusinessProfileById.businessSummary);
				setPriceRange(data.data.getBusinessProfileById.priceRange);
				setExperiences(data.data.getBusinessProfileById.experiences);
				setFacebook(data.data.getBusinessProfileById.facebook);
				setInstagram(data.data.getBusinessProfileById.instagram);
				setTwitter(data.data.getBusinessProfileById.twitter);
				setWebsite(data.data.getBusinessProfileById.website);
				setReviewStatus(data.data.getBusinessProfileById.status);
				setIsLoading(false);
			}
		});
	}, []);

	const updateIsLoading = (value) => {
		setIsLoading(value);
	};

	const approvalValidation = () => {
		// TODO: Make onDataSubmit optional
	};

	const updateBusinessSummary = (value) => {
		setBusinessSummary(value);
		setCorrectionsMade(true);
	};

	const updateExperiences = (value) => {
		setExperiences(value);
		setCorrectionsMade(true);
	};

	const updatePriceRange = (value) => {
		setPriceRange(value);
		setCorrectionsMade(true);
	};

	const updateFacebook = (value) => {
		setFacebook(value);
		setCorrectionsMade(true);
	};

	const updateInstagram = (value) => {
		setInstagram(value);
		setCorrectionsMade(true);
	};

	const updateTwitter = (value) => {
		setTwitter(value);
		setCorrectionsMade(true);
	};

	const updateWebsite = (value) => {
		setWebsite(value);
		setCorrectionsMade(true);
	};

	const updateBusinessProfileCorrections = () => {
		if (correctionsMade) {
			try {
				AppSyncService.execute(saveBusinessProfileCorrectionsMutation, {
					id: id,
					businessSummary: businessSummary,
					experiences: experiences,
					priceRange: priceRange,
					facebook: facebook,
					instagram: instagram,
					twitter: twitter,
					website: website
				}).then((data) => {
					if (data.data != null) {
						addToast({ Message: 'Profile corrections Saved', IsSuccess: true });
					}
				});
			} catch (error) {
				addToast({
					Message: 'Error saving Profile corrections',
					IsSuccess: false
				});
			}
		}
	};

	const onRenderBusinessProfileView = () => {
		return (
			<>
				{businessProfileData != null && (
					<BusinessProfileView businessProfileData={businessProfileData} />
				)}
				{businessProfileData != null && (
					<BusinessInformationView
						businessProfileData={businessProfileData}
						businessSummary={businessSummary}
						updateBusinessSummary={updateBusinessSummary}
						experiences={experiences}
						updateExperiences={updateExperiences}
						priceRange={priceRange}
						updatePriceRange={updatePriceRange}
						facebook={facebook}
						updateFacebook={updateFacebook}
						instagram={instagram}
						updateInstagram={updateInstagram}
						twitter={twitter}
						updateTwitter={updateTwitter}
						website={website}
						updateWebsite={updateWebsite}
					/>
				)}
				{reviewStatus != '' && (
					<AdminApproval
						reviewNotesData={reviewNotesData}
						reviewReasonsData={reviewReasonsData}
						id={id}
						reviewStatus={reviewStatus}
						reviewType="BusinessProfile"
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						onDataSubmit={approvalValidation}
						updateBusinessProfileCorrections={updateBusinessProfileCorrections}
						headerTitle={headerTitle}
					/>
				)}
			</>
		);
	};

	const onRenderBusinessProfileFosterView = () => {
		return (
			<>
				{businessProfileData != null && (
					<BusinessProfileFosterView
						businessProfileData={businessProfileData}
					/>
				)}
				{businessProfileData != null && (
					<BusinessInformationFosterView
						businessProfileData={businessProfileData}
						businessSummary={businessSummary}
						updateBusinessSummary={updateBusinessSummary}
						experiences={experiences}
						updateExperiences={updateExperiences}
						priceRange={priceRange}
						updatePriceRange={updatePriceRange}
						facebook={facebook}
						updateFacebook={updateFacebook}
						instagram={instagram}
						updateInstagram={updateInstagram}
						twitter={twitter}
						updateTwitter={updateTwitter}
						website={website}
						updateWebsite={updateWebsite}
					/>
				)}
			</>
		);
	};

	const desktopComponent = (
		<Container className={classes.pageContainer}>
			<SEO title="Business Profile Review" />
			<HeaderBanner
				title={`${headerTitle} - ${businessName}`}
				top
				type="primary"
				rightTitle={`Status: ${reviewStatus}`}
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			<div className={classes.formContainer}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{selectedBusinessProfileType === 3
					? onRenderBusinessProfileFosterView()
					: onRenderBusinessProfileView()}
			</div>
		</Container>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default BusinessProfileReview;

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { graphql, useStaticQuery } from 'gatsby';

const EventsAndMoreWidget = ({ mobile }) => {
	const [event, setEvent] = useState([]);

	const eventsInfo = useStaticQuery(
		graphql`
			query eventsInfo {
				allContentfulEventsAndMore(sort: { fields: order }) {
					nodes {
						buttonColor
						eventInfo
						image {
							file {
								url
								fileName
							}
						}
						title
						link
						order
						location
						preamble
					}
				}
			}
		`
	);

	useEffect(() => {
		buildEvents(eventsInfo.allContentfulEventsAndMore.nodes);
	}, [eventsInfo]);

	const buildEvents = (eventInfo) => {
		if (eventsInfo) {
			setEvent([
				{
					preamble: eventInfo[0]?.preamble,
					title: eventInfo[0]?.title,
					info: eventInfo[0]?.eventInfo,
					location: eventInfo[0]?.location,
					image: eventInfo[0]?.image?.file.url,
					buttonColor: eventInfo[0]?.buttonColor,
					onClick: () => window.open(eventInfo[0]?.link)
				},
				{
					preamble: eventInfo[1]?.preamble,
					title: eventInfo[1]?.title,
					info: eventInfo[1]?.eventInfo,
					location: eventInfo[1]?.location,
					image: eventInfo[1]?.image?.file.url,
					buttonColor: eventInfo[1]?.buttonColor,
					onClick: () => window.open(eventInfo[1]?.link)
				}
			]);
		}
	};

	return (
		<div>
			{event?.length > 0 && (
				<>
					<p style={{ fontSize: 20, color: '#464749', fontWeight: 'bold' }}>
						EVENTS
					</p>
					<>
						<Event event={event[0]} mobile={mobile} />
						{event?.length > 1 && <Event event={event[1]} mobile={mobile} />}
					</>
				</>
			)}
		</div>
	);
};

const Event = ({ event, mobile }) => {
	const classes = useStyles();
	return (
		<div
			className={mobile ? classes.heroImageMobile : classes.heroImage}
			style={{
				marginTop: 10,
				minHeight: 400,
				backgroundImage: `url(${event.image})`,
				cursor: 'pointer'
			}}
			onClick={() => event.onClick()}
		>
			<div className={classes.heroText}>
				<p className={classes.fifteen}>{event.preamble}</p>
				<p className={classes.twentyBold}>{event.title}</p>
				<p className={classes.twenty}>{event.info}</p>
				<p className={classes.fifteen}>{event.location}</p>
				<button
					className={classes.signUpButton}
					style={{
						border: `solid ${event.buttonColor}`,
						borderColor: `${event.buttonColor}`,
						backgroundColor: `${event.buttonColor}`
					}}
				>
					More Info
				</button>
			</div>
		</div>
	);
};

export default EventsAndMoreWidget;

import React, { useEffect, useState } from 'react';
import HeaderBanner from '../HeaderBanner';
import { useAuth } from '../../hooks/useAuth';
import { getFollowingHorses, getMember } from './grapqhl/queries';
import AppSyncService from '../../graphql/AppSyncService';
import DataTable from '../DataTable';
import FollowedHorseRow from './components/FollowedHorseRow';
import { useScreenClass } from 'react-grid-system';
import {
	checkIsSmallScreen,
	checkUserAdmin,
	formatFollowRecordVisibility
} from '../../utils/helpers';
import LoaderSpinner from '../LoaderSpinner';

const DESKTOP_TABLE_HEADINGS = ['', 'Name', 'Colour', 'Sex', 'Claimed'];
const MOBILE_TABLE_HEADINGS = ['', 'Name', 'Colour'];

const HorseFollowListings = () => {
	const { currentUser } = useAuth();
	const [isLoading, setIsLoading] = useState(true);
	const [memberData, setMemberData] = useState(null);
	const [followedHorses, setFollowedHorses] = useState([]);
	const screenSize = useScreenClass();
	const isMobile = checkIsSmallScreen(screenSize);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	useEffect(() => {
		if (currentUser) {
			AppSyncService.execute(getMember, {
				externalId: currentUser.UserId
			}).then((result) => {
				if (result?.data?.getMember) {
					setMemberData(result.data.getMember);
				}
			});
		}
	}, [currentUser]);

	useEffect(() => {
		setIsLoading(true);
		if (memberData?.id) {
			AppSyncService.execute(getFollowingHorses, {
				memberId: memberData.id
			}).then((result) => {
				if (result?.data?.getHorsesFollowedByMember?.items) {
					setFollowedHorses(
						formatFollowRecordVisibility(
							result?.data?.getHorsesFollowedByMember?.items,
							isAdminUser
						)
					);
				}
				setIsLoading(false);
			});
		}
	}, [memberData]);

	const tableRows = followedHorses.map((horse, index) => (
		<FollowedHorseRow
			key={`followed-horse-${horse.horse_code}`}
			rowData={horse}
			isLastRow={index === followedHorses.length - 1}
			isMobile={isMobile}
		/>
	));

	return (
		<div>
			<HeaderBanner
				title={`Following (${followedHorses.length})`}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: 0 }}
			/>
			<DataTable
				headings={isMobile ? MOBILE_TABLE_HEADINGS : DESKTOP_TABLE_HEADINGS}
				rows={tableRows}
				sortHeading="Name"
				sortUpFunction={() =>
					setFollowedHorses((prevState) =>
						[...prevState].sort((horseA, horseB) =>
							(
								horseB.horse.ottInformation?.paddockName ?? horseB.horse.name
							).localeCompare(
								horseA.horse.ottInformation?.paddockName ?? horseA.horse.name
							)
						)
					)
				}
				sortDownFunction={() =>
					setFollowedHorses((prevState) =>
						[...prevState].sort((horseA, horseB) =>
							(
								horseA.horse.ottInformation?.paddockName ?? horseA.horse.name
							).localeCompare(
								horseB.horse.ottInformation?.paddockName ?? horseB.horse.name
							)
						)
					)
				}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
		</div>
	);
};

export default HorseFollowListings;

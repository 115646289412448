import React, {
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveBusinessPropDetails,
	getDisplayOptionsReferenceData
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import SimpleCheckBox from '../SimpleCheckBox';

// form validation rules
const schema = yup.object().shape({
	streetAddress: yup
		.string() //Street Address can have special characters
		.required('Street Address is required')
		.typeError('Street Address is required'),
	suburb: yup
		.string() //Suburb can have special characters
		.required('Suburb is required')
		.typeError('Suburb is required'),
	state: yup
		.string()
		.required('State is required')
		.typeError('State is required'),
	postcode: yup
		.string()
		.required('Postcode is required')
		.min(4, 'Postal code must be a minimum of 4 characters')
		.matches(
			/^\d+$/i,
			'Please enter valid postcode, postcode should only be numeric'
		)
		.typeError('Postcode is required')
});

const BusinessPropertyDetails = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			updateIsLoading,
			setIsSubmitted,
			businessProfileType,
			onSaveBusinessProfileData,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();
		const { handleSubmit, trigger, errors, control, setValue } = useForm({
			mode: 'onSubmit',
			resolver: yupResolver(schema)
		});

		const [streetAddress, setStreetAddress] = useState('');
		const [
			selectedStreetAddressDisplayOption,
			setSelectedStreetAddressDisplayOption
		] = useState(1);
		const [suburb, setSuburb] = useState('');
		const [
			selectedSuburbDisplayOption,
			setSelectedSuburbDisplayOption
		] = useState(1);
		const [state, setState] = useState('');
		const [
			selectedStateDisplayOption,
			setSelectedStateDisplayOption
		] = useState(1);
		const [postcode, setPostcode] = useState('');
		const [
			selectedPostcodeDisplayOption,
			setSelectedPostcodeDisplayOption
		] = useState(1);
		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [referenceDisplayOptions, setReferenceDisplayOptions] = useState([]);
		const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
		const manualAddressCheckBoxData = {
			id: 'manualCheckBox',
			label: 'Manually add my address',
			value: 'Manually add my address',
			isSelected: isPostalAddressManual
		};

		useEffect(() => {
			AppSyncService.execute(getDisplayOptionsReferenceData).then((result) => {
				if (result.data) {
					setReferenceDisplayOptions(result.data.listDisplayOptions);
				}
			});
		}, []);

		useEffect(() => {
			setStreetAddress(
				businessProfileData ? businessProfileData.streetAddress : ''
			);
			setValue(
				'streetAddress',
				businessProfileData ? businessProfileData.streetAddress : ''
			);
			setSuburb(businessProfileData ? businessProfileData.suburb : '');
			setValue('suburb', businessProfileData ? businessProfileData.suburb : '');
			setState(businessProfileData ? businessProfileData.state : '');
			setValue('state', businessProfileData ? businessProfileData.state : '');
			setPostcode(businessProfileData ? businessProfileData.postcode : '');
			setValue(
				'postcode',
				businessProfileData ? businessProfileData.postcode : ''
			);
			setSelectedStreetAddressDisplayOption(
				businessProfileData
					? businessProfileData.streetAddressDisplayOption?.id
					: 1
			);
			setValue(
				'streetAddressDisplayOption',
				businessProfileData
					? businessProfileData.streetAddressDisplayOption?.id
					: ''
			);
			setSelectedSuburbDisplayOption(
				businessProfileData ? businessProfileData.suburbDisplayOption?.id : 1
			);
			setValue(
				'suburbDisplayOption',
				businessProfileData ? businessProfileData.suburbDisplayOption?.id : ''
			);
			setSelectedStateDisplayOption(
				businessProfileData ? businessProfileData.stateDisplayOption?.id : 1
			);
			setValue(
				'stateDisplayOption',
				businessProfileData ? businessProfileData.stateDisplayOption?.id : ''
			);
			setSelectedPostcodeDisplayOption(
				businessProfileData ? businessProfileData.postcodeDisplayOption?.id : 1
			);
			setValue(
				'postcodeDisplayOption',
				businessProfileData ? businessProfileData.postcodeDisplayOption?.id : ''
			);
			updateIsLoading(false);
			setIsFormDisabled(true);
		}, [businessProfileData]);

		useImperativeHandle(ref, () => ({
			async triggerValidation() {
				await trigger();
			}
		}));

		const onSubmit = async () => {
			validateFields();
			setBackendErrorMessage(null);
			const currentState = {
				fullAddress: '',
				postcode: postcode,
				postcodeDisplayOption: selectedPostcodeDisplayOption,
				state: state,
				stateDisplayOption: {
					id: selectedStateDisplayOption,
					value: referenceDisplayOptions.find(
						(reference) => reference.id == selectedStateDisplayOption
					)?.value
				},
				streetAddressDisplayOption: {
					id: selectedStreetAddressDisplayOption,
					value: referenceDisplayOptions.find(
						(reference) => reference.id == selectedStreetAddressDisplayOption
					)?.value
				},
				suburbDisplayOption: {
					id: selectedSuburbDisplayOption,
					value: referenceDisplayOptions.find(
						(reference) => reference.id == selectedSuburbDisplayOption
					)?.value
				},
				streetAddress: streetAddress,
				suburb: suburb,
				status: 'Draft'
			};
			try {
				AppSyncService.execute(saveBusinessPropDetails, {
					fullAddress: '',
					postcode: postcode,
					postcodeDisplayOption: selectedPostcodeDisplayOption,
					state: state,
					stateDisplayOption: selectedStateDisplayOption,
					streetAddress: streetAddress,
					streetAddressDisplayOption: selectedStreetAddressDisplayOption,
					suburb: suburb,
					suburbDisplayOption: selectedSuburbDisplayOption,
					memberExternalId: currentUser?.UserId,
					businessProfileTypeId: businessProfileType
				}).then((data) => {
					if (data.data != null) {
						addToast({ Message: 'Saved', IsSuccess: true });
						setIsSubmitted(false);
						onSaveBusinessProfileData(currentState);
					}
				});
				setIsFormDisabled(true);
			} catch (error) {
				if (error && error.message) {
					setBackendErrorMessage(error.message);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving user Business contact data. Please contact support'
					);
				}
			}
		};

		const updateStreetAddressDisplayOption = (value) => {
			setSelectedStreetAddressDisplayOption(value);
		};
		const updateSuburbDisplayOption = (value) => {
			setSelectedSuburbDisplayOption(value);
		};
		const updateStateDisplayOption = (value) => {
			setSelectedStateDisplayOption(value);
		};
		const updatePostcodeDisplayOption = (value) => {
			setSelectedPostcodeDisplayOption(value);
		};

		const populateResidentialAddressFields = (addressDetails) => {
			const address = `${addressDetails.Line1}${
				addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
			}`;
			setStreetAddress(address);
			setValue('streetAddress', address);
			setSuburb(addressDetails.Suburb);
			setValue('suburb', addressDetails.Suburb);
			setState(addressDetails.State);
			setValue('state', addressDetails.State);
			setPostcode(addressDetails.Postcode);
			setValue('postcode', addressDetails.Postcode);
		};

		const toggleManualAddress = () => {
			setIsPostalAddressManual(!isPostalAddressManual);
		};

		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<form
					className={classes.formContainer}
					onSubmit={handleSubmit(onSubmit, () => validateFields())}
				>
					<div className={classes.businessProfile}>
						<Row>
							<Col lg={4} sm={12}>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Street Address*</div>
											<Controller
												control={control}
												name="streetAddress"
												render={({ onChange }) => (
													<AddressAutoSuggestion
														value={streetAddress}
														onChange={(value) => {
															onChange(value);
															setStreetAddress(value);
															setIsFormDisabled(false);
														}}
														onAddressSelect={populateResidentialAddressFields}
														disableAutoSuggestion={isPostalAddressManual}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.streetAddress?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Suburb*</div>
											<Controller
												control={control}
												name="suburb"
												render={({ onChange }) => (
													<FormTextField
														value={suburb}
														onChange={(e) => {
															onChange(e.target.value);
															setSuburb(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.suburb?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>State*</div>
											<Controller
												control={control}
												name="state"
												render={({ onChange }) => (
													<FormTextField
														value={state}
														onChange={(e) => {
															onChange(e.target.value);
															setState(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.state?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Postcode*</div>
											<Controller
												control={control}
												name="postcode"
												render={({ onChange }) => (
													<FormTextField
														value={postcode}
														onChange={(e) => {
															onChange(e.target.value);
															setPostcode(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.postcode?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<SimpleCheckBox
											small={true}
											noCapitalize={true}
											data={manualAddressCheckBoxData}
											changeCheckboxState={toggleManualAddress}
											key={
												manualAddressCheckBoxData &&
												manualAddressCheckBoxData.id
											}
										/>
									</Col>
								</Row>
							</Col>
							<Col lg={4} sm={12} />
							<Col lg={4} sm={12} className={classes.rightSide}>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Street Address?
											</div>
											<Controller
												control={control}
												name="streetAddressDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedStreetAddressDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateStreetAddressDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.streetAddressDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Suburb?
											</div>
											<Controller
												control={control}
												name="suburbDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedSuburbDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateSuburbDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.suburbDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see State?
											</div>
											<Controller
												control={control}
												name="stateDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedStateDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateStateDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.stateDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Postcode?
											</div>
											<Controller
												control={control}
												name="postcodeDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedPostcodeDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updatePostcodeDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.postcodeDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>

					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									style={{ width: '165px' }}
									disabled={isFormDisabled}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
			</>
		);
	}
);
export default BusinessPropertyDetails;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	listToggleContainer: {
		background: '#ededed',
		height: 60,
		cursor: 'pointer'
	},
	listToggleContainerMobile: {
		background: '#ededed',
		height: 40,
		cursor: 'pointer'
	},
	listTitle: {
		float: 'left',
		margin: '18px 0px 0px 20px',
		fontSize: 20,
		fontWeight: 600,
		maxWidth: '70%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap'
	},
	listTitleMobile: {
		float: 'left',
		margin: '12px 0px 0px 20px',
		fontSize: 14,
		fontWeight: 600,
		maxWidth: '70%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#016ea6',
		whiteSpace: 'nowrap'
	},
	dropDown: {
		float: 'right',
		margin: '20px 30px 0px'
	},
	dropDownMobile: {
		float: 'right',
		margin: '11px 30px 0px'
	}
});

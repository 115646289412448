export const addCompetition = `
mutation addCompetition($input: CompetitionInput) {
    addCompetition(input: $input){
        error {
            errorCode
            errorDetails
            errorMessage
        }
        id
        message
        success
    }
}
`;

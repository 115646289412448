import React, { useState, useEffect } from 'react';
import { ScreenClassRender } from 'react-grid-system';
import useStyles, { StyledPreferencesIframe } from './styles';
import HeaderBanner from '../HeaderBanner';

import AppSyncService from '../../graphql/AppSyncService';
import { useAuth } from '../../hooks/useAuth';
import { getMemberProfile } from '../../graphql/custom.queries';

import LoaderSpinner from '../LoaderSpinner';
import MemberProfile from '../MemberProfile';
import MemberContentPreferences from '../MemberContentPreferences';
import MemberInterests from '../MemberInterests';
import MemberMemberships from '../MemberMemberships';
import PasswordChange from '../PasswordChange';

const PersonalProfile = () => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const [memberProfileData, setMemberProfileData] = useState(null);
	const [
		secondaryMemebershipTypesData,
		setSecondaryMemebershipTypesData
	] = useState([]);
	const [interestsData, setInterestsData] = useState([]);
	const [otherInterestsData, setOtherInterestsData] = useState('');
	const [membershipsData, setMembershipsData] = useState([]);
	const [otherMembershipsData, setOtherMembershipsData] = useState([]);
	const [raceClubMembershipsData, setRaceClubMembershipsData] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const externalId = { externalId: currentUser?.UserId };
		setIsLoading(true);

		AppSyncService.execute(getMemberProfile, externalId).then((data) => {
			if (data.data != null) {
				setMemberProfileData(data.data.getMember);
				if (data.data.getMember.secondaryMemebershipTypes) {
					setSecondaryMemebershipTypesData(
						data.data.getMember.secondaryMemebershipTypes
					);
				}
				if (data.data.getMember.interests) {
					setInterestsData(data.data.getMember.interests);
				}
				if (data.data.getMember.otherInterests) {
					setOtherInterestsData(data.data.getMember.otherInterests);
				}
				if (data.data.getMember.memberships) {
					setMembershipsData(data.data.getMember.memberships);
				}
				if (data.data.getMember.otherMemberships) {
					setOtherMembershipsData(data.data.getMember.otherMemberships);
				}
				if (data.data.getMember.raceClubMemberships) {
					setRaceClubMembershipsData(data.data.getMember.raceClubMemberships);
				}
				setIsLoading(false);
			}
		});
	}, []);

	const updateIsLoading = (value) => {
		setIsLoading(value);
	};

	const desktopComponent = (
		<>
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<HeaderBanner
				title="Personal Profile Details"
				top
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<MemberProfile
				memberProfileData={memberProfileData}
				isLoading={isLoading}
				updateIsLoading={updateIsLoading}
			/>
			<HeaderBanner
				title="Password"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>
			<div className={classes.passwordChangeContainer}>
				<div></div>

				<PasswordChange />
			</div>
			{'62012dd6e4ed0b32d79a6f60bf60f4a4' && (
				<StyledPreferencesIframe
					seamless
					src={`https://mcm6sxvzg1q3y6vlqfr8j7q55dsy.pub.sfmc-content.com/uoiyo0yfa0a?skey=62012dd6e4ed0b32d79a6f60bf60f4a4`}
				/>
			)}

			<HeaderBanner
				title="Content Preferences"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>

			<MemberContentPreferences
				secondaryMemebershipTypesData={secondaryMemebershipTypesData.map(
					(preference) => preference.id
				)}
			/>

			<HeaderBanner
				title="Interests"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>

			<MemberInterests
				interestsData={interestsData.map((interest) => interest.id)}
				otherInterestsData={otherInterestsData}
			/>

			<HeaderBanner
				title="Memberships"
				inline
				styles={{ marginBottom: '0px', marginTop: '-5px' }}
			/>
			<MemberMemberships
				membershipsData={membershipsData.map((membership) => membership.id)}
				otherMembershipsData={otherMembershipsData}
				raceClubMembershipsData={raceClubMembershipsData}
			/>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default PersonalProfile;

import { createUseStyles } from 'react-jss';
import BannerImage from '../../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	formSectionHeader: {
		color: '#464749',
		fontSize: 15,
		background: `url(${BannerImage})`,
		display: 'flex',
		backgroundSize: 'cover',
		alignItems: 'center',
		height: 65,
		fontWeight: 800,
		paddingLeft: 15,
		paddingRight: 15,
		width: '100%',
		boxSizing: 'border-box',
		'@media (min-width:992px)': {
			fontSize: 20
		}
	},
	formSectionBody: {
		width: '100%',
		boxSizing: 'border-box',
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1',
		paddingBottom: 20,
		paddingTop: 20
	},
	formSectionBodyWithoutPadding: {
		width: '100%',
		boxSizing: 'border-box',
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1'
	},
	formSectionContainer: {
		width: '100%'
	},
	adminSection: {
		backgroundColor: '#f0f0f0'
	},
	borderRadius: {
		borderRadius: '5px 5px 0px 0px'
	},
	bottomBorder: {
		borderBottom: '1px solid #b3bcc1'
	}
}));

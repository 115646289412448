import React from 'react';
import Switch from '@material-ui/core/Switch';
import { FormGroup, FormControlLabel } from '@material-ui/core';

const BasicSwitch = ({
	checked,
	disabled = false,
	label,
	onChange = () => {},
	color = 'primary'
}) => {
	return (
		<FormGroup>
			<FormControlLabel
				disabled={disabled}
				control={<Switch checked={checked} color={color} onChange={onChange} />}
				label={label}
			/>
		</FormGroup>
	);
};

export default BasicSwitch;

export const getOhepEoiReviewFormData = `
    query GetOhepEoiReviewFormData($id: String!) {
        getAdminMembers {
            id
            externalId
            firstName
            lastName
        }
        listAdminActionStauses {
            id
            value
        }
        listAdminActionOutcomes {
            id
            value
        }
        getOhepEoiFormById(id: $id) {
            memberId
            member {
                firstName
                lastName
                email
                phone
                residentialAddress {
                    address
                    suburb
                    state
                    postcode
                    country
                }
            }
            status		
            relationshipToHorse {
                id
                value
            }
            relationshipToHorseOther
			horseName
			microchip
			sire
			dam			
            horseGender {
                id
                value
            }
			age
            eligibilityCriteria {
                id
                value
            }
            preferredContactType {
                id
                value
            }
			contactDetails
            currentAddress 
            currentSuburb 
            currentState 
            currentPostcode 
            currentCountry 
            currentAddressValidated			
            rightSidePhotos {
                created
                filename
                key
                type
            }
            leftSidePhotos {
                created
                filename
                key
                type
            }
            reviewNotes
			adminAction {
				id
				formId
				admin {
					id
					lastName
					firstName
					externalId
				}
				adminOutcome {
					id
					value
				}
				adminStatus {
					id
					value
				}
			}
        }
    }
`;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	mobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	desktopBannerStyles: {
		boxSizing: 'border-box',
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	desktopContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important'
	},
	articleText: {
		fontSize: 13,
		marginTop: 15
	},
	termsConditionImage: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: 460
	}
}));

export const MAXREASONLENGTH = 300;
export const RESET_NIMONATION_ADMIN_ACTION_FIELDS = {
	adminExternalId: {
		title: 'Actioning',
		key: 'adminExternalId',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Actioning is required'
	},
	resetNominationId: {
		key: 'resetNominationId',
		value: null
	},
	ewdAccept: {
		title: 'EWD recommendation*',
		key: 'ewdAccept',
		value: true,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	ewdComments: {
		title: 'EWD comments',
		key: 'ewdComments',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	ewdRecommendationDate: {
		title: 'EWD recommendation date*',
		key: 'ewdRecommendationDate',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'EWD recommendation date is required'
	},
	ewgmComments: {
		title: 'EWGM comments*',
		key: 'ewgmComments',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'EWGM comments is required'
	},
	ewgmProceedToVetAssessment: {
		title: 'EWGM recommendation to proceed to veterinarian assessment*?',
		key: 'ewgmProceedToVetAssessment',
		value: true,
		isRequired: true,
		isInvalid: false,
		invalidMessage: ''
	},
	ewgmRecommendationDate: {
		title: 'EWGM recommendation date*',
		key: 'ewgmRecommendationDate',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'EWGM recommendation date is required'
	},
	illnessId: {
		title: 'Illnesses noted as a concern in Veterinarian Assessment',
		key: 'illnessId',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	ilnessesOther: {
		title: 'Other illnesses noted as a concern in Veterinarian Assessment',
		key: 'ilnessesOther',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: 'Other illnesses is required'
	},
	recommendation: {
		title: 'Application recommendation*',
		key: 'recommendation',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Application recommendation is required'
	},
	injuryIds: {
		title: 'Injuries noted as a concern in Veterinarian Assessment',
		key: 'injuryIds',
		value: [],
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	injuriesOther: {
		title: 'Other injuries noted as a concern in Veterinarian Assessment',
		key: 'injuriesOther',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: 'Other injuries is required'
	},
	rejectReasonId: {
		title: 'Rejection reason',
		key: 'rejectReasonId',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	rejectReasonOther: {
		title: 'Additional comments',
		key: 'rejectReasonOther',
		value: null,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	rejectingApplication: {
		title: 'Are you rejecting this application?*',
		key: 'rejectingApplication',
		value: true,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	},
	vetAssessmentDate: {
		title: 'Veterinarian assessment date*',
		key: 'vetAssessmentDate',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Veterinarian assessment date is required'
	},
	vetAssessmentId: {
		title: 'Veterinarian category assessment*',
		key: 'vetAssessmentId',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Veterinarian category assessment is required'
	},
	vetAssessmentSummary: {
		title: 'Veterinarian assessment summary comments*',
		key: 'vetAssessmentSummary',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Veterinarian assessment summary comments is required'
	},
	vetUploads: {
		title: 'Veterinarian document (pdf, doc)',
		key: 'vetUploads',
		value: [],
		isRequired: false,
		isInvalid: false,
		invalidMessage: 'Maximun is 3'
	},
	adminStatusId: {
		title: 'Admin status',
		key: 'adminStatusId',
		value: null,
		isRequired: true,
		isInvalid: false,
		invalidMessage: 'Admin status is required'
	},
	formComplete: {
		title: 'Application form complete?',
		key: 'formComplete',
		value: false,
		isRequired: false,
		isInvalid: false,
		invalidMessage: ''
	}
};

export const MODAL_STYLES = {
	content: {
		width: 500,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

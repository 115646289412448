import { createUseStyles } from 'react-jss';

export const LARGE_SCREEN_TOOLTIP_INLINE_STYLES = { width: 290, left: 40 };

export const SMALL_SCREEN_TOOLTIP_INLINE_STYLES = { top: -70, left: -130 };

export default createUseStyles(() => ({
	container: {
		background: '#f5f5f5',
		paddingBottom: 20,
		borderBottom: '1px solid #e5f0f6'
	},
	formContainer: {
		paddingRight: 20,
		paddingLeft: 20
	},
	formHeading: {
		fontSize: 18,
		fontWeight: 600,
		color: '#464749',
		padding: '20px 0 20px 0'
	},
	divider: {
		background: '#e2e2e2',
		height: 2,
		border: 'none',
		marginTop: 35,
		marginBottom: 10
	},
	topMessage: {
		padding: '10px 0px 10px 5px'
	},
	dateFieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 5,
		marginTop: 20
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	saveButtonContainer: {
		marginTop: 20
	},
	ProfilefieldContainer: {
		paddingLeft: 3,
		paddingTop: 15,
		paddingBottom: 10
	},
	clearFormLink: {
		color: '#0085ca',
		textDecoration: 'underline',
		cursor: 'pointer',
		whiteSpace: 'nowrap !important'
	},
	checkboxFieldContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 10,
		marginBottom: 18
	},
	checkboxField: {
		marginLeft: 10
	},
	statusTitle: {
		color: '#777777',
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		fontSize: 20
	},
	redInfoText: {
		color: 'red',
		padding: '0px 15px'
	},
	saveRequest: {
		display: 'flex',
		alignItems: 'center',
		color: '#0085ca',
		fontSize: 14
	},
	saveRequestMobile: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		color: '#0085ca',
		fontSize: 14
	},
	saveButton: {
		marginRight: 20
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	dashboardDesktopPageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 40,
		minHeight: 700
	},
	articleMobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	image: {
		objectFit: 'cover',
		width: '100%'
	},
	articleTitle: {
		textAlign: 'left',
		fontWeight: '600',
		marginBottom: 20
	},
	date: { fontSize: 12, color: '#9a9a9a', marginTop: 5 },
	articleText: {
		fontSize: 13,
		marginTop: 15
	},
	hr: {
		marginBottom: 40,
		marginTop: 50,
		background: '#dcebf3',
		height: 1,
		border: 'none'
	},
	loggedOut: {
		maxHeight: 350,
		overflow: 'hidden'
	},
	smallContainer: {
		height: 65,
		marginBottom: 10,
		position: 'relative'
	},
	loggedIn: {},
	joinNow: {
		position: 'absolute',
		top: '210px',
		left: 0,
		display: 'block',
		width: '100%',
		height: 100,
		backgroundImage:
			'linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255, 1))',
		content: ''
	},
	membersOnlyBanner: {
		paddingTop: 100
	},
	membersOnlyMobileBanner: {
		extend: 'membersOnlyBanner',
		width: '95%',
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	mobileMoreArticles: {
		padding: 15
	},
	moreArticles: {
		flex: '0 0 33%',
		maxWidth: '33%',
		width: '33%',
		padding: 10
	}
}));

import * as yup from 'yup';
import {
	checkDropDownValue,
	checkNotEqualDropDownValue
} from '../../utils/helpers';

const otherReasonOptions = [
	{ id: 0, value: '' },
	{ id: 1, value: 'Deceased' },
	{ id: 2, value: 'Other' }
];

export const rehomedSchema = () => {
	return yup.object().shape({
		price: yup
			.string()
			.required('You must select a price bracket.')
			.test(
				'empty-check',
				'You must select a price bracket.',
				(price) => price !== '0'
			),
		purpose: yup
			.string()
			.required('You must select a purpose.')
			.test(
				'empty-check',
				'You must select a purpose.',
				(purpose) => purpose !== '0'
			)
	});
};

export const otherUnclaimedSchema = (
	HORSE_DEATH_REASON_OPTION,
	EUTHANASIA_REASONS,
	INJURY_TYPES,
	ILLNESS_TYPES,
	UNSUITABLE_FOR_REHOMING_TYPES,
	EUTHANASIA_OTHER_TYPES
) => {
	return yup.object().shape({
		horseDeathCause: yup.string().when('unclaimReason', {
			is: (unclaimReason) =>
				checkDropDownValue(otherReasonOptions, unclaimReason, 'Deceased'),
			then: yup
				.string()
				.required('You must select a a cause of death.')
				.test(
					'empty-check',
					'You must select a a cause of death.',
					(horseDeathCause) =>
						checkNotEqualDropDownValue(
							HORSE_DEATH_REASON_OPTION,
							horseDeathCause,
							''
						)
				)
		}),
		unclaimReason: yup
			.string()
			.required('You must select a reason for unclaiming the thoroughbred.')
			.test(
				'empty-check',
				'You must select a reason for unclaiming the thoroughbred.',
				(unclaimReason) =>
					checkNotEqualDropDownValue(otherReasonOptions, unclaimReason, '')
			),
		otherReason: yup.string().when('unclaimReason', {
			is: (unclaimReason) =>
				checkDropDownValue(otherReasonOptions, unclaimReason, 'Other'),
			then: yup
				.string()
				.required('Please enter some details for the other reason')
				.typeError('Please enter some details for the other reason')
		}),
		euthanasiaReason: yup.string().when('horseDeathCause', {
			is: (horseDeathCause) =>
				checkDropDownValue(
					HORSE_DEATH_REASON_OPTION,
					horseDeathCause,
					'Euthanised'
				),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please select the reason the thoroughbred was euthanised.',
					(euthanasiaReason) =>
						checkNotEqualDropDownValue(EUTHANASIA_REASONS, euthanasiaReason, '')
				)
				.required('Please select the reason the thoroughbred was euthanised.')
				.typeError('Please select the reason the thoroughbred was euthanised.')
		}),
		injuryType: yup.string().when('euthanasiaReason', {
			is: (euthanasiaReason) =>
				checkDropDownValue(EUTHANASIA_REASONS, euthanasiaReason, 'Injury'),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please select the injury type for which the thoroughbred was euthanised.',
					(injuryType) =>
						checkNotEqualDropDownValue(INJURY_TYPES, injuryType, '')
				)
				.required(
					'Please select the injury type for which the thoroughbred was euthanised.'
				)
				.typeError(
					'Please select the injury type for which the thoroughbred was euthanised.'
				)
		}),
		injuryOther: yup.string().when('injuryType', {
			is: (injuryType) => checkDropDownValue(INJURY_TYPES, injuryType, 'Other'),
			then: yup
				.string()
				.required('Please enter some details for the other reason')
				.typeError('Please enter some details for the other reason')
		}),
		illnessType: yup.string().when('euthanasiaReason', {
			is: (euthanasiaReason) =>
				checkDropDownValue(EUTHANASIA_REASONS, euthanasiaReason, 'Illness'),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please select the illness type for which the thoroughbred was euthanised.',
					(illnessType) =>
						checkNotEqualDropDownValue(ILLNESS_TYPES, illnessType, '')
				)
				.required(
					'Please select the illness type for which the thoroughbred was euthanised.'
				)
				.typeError(
					'Please select the illness type for which the thoroughbred was euthanised.'
				)
		}),
		illnessOther: yup.string().when('illnessType', {
			is: (illnessType) =>
				checkDropDownValue(ILLNESS_TYPES, illnessType, 'Other'),
			then: yup
				.string()
				.required('Please enter some details for the other reason')
				.typeError('Please enter some details for the other reason')
		}),
		unsuitableReason: yup.string().when('euthanasiaReason', {
			is: (euthanasiaReason) =>
				checkDropDownValue(
					EUTHANASIA_REASONS,
					euthanasiaReason,
					'Unsuitable for rehoming'
				),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please select the reason why the thoroughbred was unsuitable for rehoming.',
					(illnessType) =>
						checkNotEqualDropDownValue(ILLNESS_TYPES, illnessType, '')
				)
				.required(
					'Please select the reason why the thoroughbred was unsuitable for rehoming.'
				)
				.typeError(
					'Please select the reason why the thoroughbred was unsuitable for rehoming.'
				)
		}),
		unsuitableOther: yup.string().when('unsuitableReason', {
			is: (unsuitableReason) =>
				checkDropDownValue(
					UNSUITABLE_FOR_REHOMING_TYPES,
					unsuitableReason,
					'Other'
				),
			then: yup
				.string()
				.required('Please enter some details for the other reason')
				.typeError('Please enter some details for the other reason')
		}),
		euthanasiaOther: yup.string().when('euthanasiaReason', {
			is: (euthanasiaReason) =>
				checkDropDownValue(EUTHANASIA_REASONS, euthanasiaReason, 'Other'),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please select the reason why the thoroughbred was euthanised.',
					(euthanasiaOther) =>
						checkNotEqualDropDownValue(
							EUTHANASIA_OTHER_TYPES,
							euthanasiaOther,
							''
						)
				)
				.required(
					'Please select the reason why the thoroughbred was euthanised.'
				)
				.typeError(
					'Please select the reason why the thoroughbred was euthanised.'
				)
		}),
		otherDeath: yup.string().when('horseDeathCause', {
			is: (horseDeathCause) =>
				checkDropDownValue(HORSE_DEATH_REASON_OPTION, horseDeathCause, 'Other'),
			then: yup
				.string()
				.test(
					'empty-check',
					'Please enter some information about the death of the horse.',
					(euthanasiaReason) =>
						checkNotEqualDropDownValue(EUTHANASIA_REASONS, euthanasiaReason, '')
				)
				.required('Please enter some information about the death of the horse.')
				.typeError(
					'Please enter some information about the death of the horse.'
				)
		})
	});
};

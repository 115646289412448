import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import PeopleIcon from '@material-ui/icons/People';

const BASE_ICON_ROOT_STYLES = { fontSize: 13 };
const PRIVATE_COLOR = '#2b2b2b';
const PUBLIC_COLOR = 'rgb(0,144,41)';
const FOLLOWERS_COLOR = '#730ec0';

const StyledPublicIcon = withStyles({
	root: { ...BASE_ICON_ROOT_STYLES, color: PUBLIC_COLOR }
})(PublicIcon);

const StyledPrivateIcon = withStyles({
	root: { ...BASE_ICON_ROOT_STYLES, color: PRIVATE_COLOR }
})(LockIcon);

const StyledFollowersIcon = withStyles({
	root: { ...BASE_ICON_ROOT_STYLES, color: FOLLOWERS_COLOR }
})(PeopleIcon);

export const VISIBILITY_CONFIG = {
	Private: {
		color: PRIVATE_COLOR,
		icon: <StyledPrivateIcon />
	},
	Public: {
		color: PUBLIC_COLOR,
		icon: <StyledPublicIcon />
	},
	Followers: {
		color: FOLLOWERS_COLOR,
		icon: <StyledFollowersIcon />
	}
};

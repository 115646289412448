import React from 'react';
import { Row } from 'react-grid-system';
import FormFieldWrapper from '../FormFieldWrapper';
import MultiSelectInputField from '../MultiSelectInputField';
import Toggle from '../../Toggle';

const ToggleWithMultiSelect = ({
	toggleId,
	toggleLabel,
	toggleChangeHandler,
	showMultiSelect,
	multiSelectId,
	multiSelectLabel,
	multiSelectSelectedOptions,
	multiSelectOptions,
	multiSelectChangeHandler,
	otherValue,
	onOtherChange,
	validationError,
	isRequired = false,
	visibilitySelector
}) => {
	return (
		<>
			<Row style={{ marginTop: 15 }}>
				<FormFieldWrapper
					fieldLabel={toggleLabel}
					isRequired={isRequired}
					visibilitySelector={visibilitySelector}
				>
					<Toggle
						id={toggleId}
						labelNegative="No"
						labelPositive="Yes"
						onClick={toggleChangeHandler}
						value={showMultiSelect}
					/>
				</FormFieldWrapper>
			</Row>
			{showMultiSelect && (
				<MultiSelectInputField
					idPrefix={multiSelectId}
					fieldLabel={multiSelectLabel}
					selectedIds={multiSelectSelectedOptions}
					options={multiSelectOptions}
					onSelectChange={multiSelectChangeHandler}
					otherValue={otherValue}
					onOtherChange={onOtherChange}
					validationError={validationError}
					isRequired={isRequired}
				/>
			)}
		</>
	);
};

export default ToggleWithMultiSelect;

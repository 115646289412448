import { createUseStyles } from "react-jss";
export default createUseStyles({
  container: {
    border: "1px solid #b3bcc1",
    paddingTop: 30
  },
  claimHorseContainer: {
    padding: "0px 18px 0px 18px",
  },
  fieldContainer: {
    paddingTop: 15,
    paddingBottom: 20,
  },
  horseInfo: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 20px 5px 20px",
  },
  horseName: {
    fontSize: 20,
    fontWeight: 800,
    color: "#464749",
  },
  horseAdditionalInfo: {
    fontStyle: "italic",
    color: "#757575",
    marginTop: 5,
  },
  uploadFieldTitle: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 7,
    color: "#464749",
  },
  fieldTitle: {
    fontWeight: 600,
    fontSize: 15,
    marginBottom: 7,
    color: "#464749",
  },
});

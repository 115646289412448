import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	leftMenu: {
		minHeight: '100vh!important',
		background: '#f4f5f7',
		maxWidth: 'inherit!important',
		position: 'fixed!important',
		left: '0!important',
		width: 240
	},
	wrapper: {
		marginRight: 'auto!important',
		maxWidth: 'inherit!important'
		// position: 'fixed'
	},
	container: {
		position: 'fixed',
		top: 0,
		zIndex: 99,
		background: 'white',
		height: 55,
		width: '100%',
		textAlign: 'center',
		borderBottom: '1px solid #deecf4'
	},
	menuBarContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		marginLeft: 20,
		marginRight: 20
	},
	arrow: {
		position: 'fixed',
		paddingTop: 17
	},
	pageTitle: {
		paddingTop: 20,
		fontSize: 13,
		fontWeight: 600,
		color: '#464749',
		paddingBottom: 18,
		margin: 'auto'
	},
	hamburger: { paddingTop: 17 },
	backArrow: {
		'-webkitTransform': 'scaleX(-1)',
		transform: 'scaleX(-1)'
	},
	navLink: {
		color: 'blue',
		'& a': {
			textDecoration: 'none',
			fontSize: 13,
			color: '#0085ca'
		}
	},
	logo: {
		height: 30,
		marginTop: 13,
		imageRendering: '-webkit-optimize-contrast',
		margin: 'auto'
	},
	dropDown: {
		position: 'relative',
		marginLeft: 25,
		width: 'available',
		'min-width': 'fill-available'
	},
	dropdownLink: {
		padding: '12px 16px',
		fontSize: '13px',
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		width: 'available',
		'min-width': 'fill-available',
		textAlign: 'left',
		borderLeft: '1px solid #dcebf3'
	},
	menuItem: {
		color: '#0085ca',
		width: 'available',
		'min-width': 'fill-available',
		textTransform: 'uppercase',
		textDecoration: 'none',
		float: 'left',
		height: 40
	},
	standardLink: {
		float: 'left',
		margin: '12px 0px 0px 20px',
		fontSize: 13,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#0085ca',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textDecoration: 'none'
	},
	imNew: {
		float: 'left',
		margin: '12px 0px 0px 20px',
		fontSize: 13,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#0085ca',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textDecoration: 'none',
		background: '#e0eef4',
		padding: '2px 10px 2px 10px',
		borderRadius: 2
	},
	sidebarMenuOption: {
		boxAlign: 'center',
		alignItems: 'center',
		borderRadius: 3,
		boxSizing: 'border-box',
		cursor: 'pointer',
		display: 'flex',
		flex: '0 0 auto',
		padding: '8px 12px 7px 20px',
		// color: 'rgb(0, 82, 204)',
		fill: 'rgba(9, 30, 66, 0.04)',
		textDecoration: 'none',
		height: 40,
		width: 'auto'
	},
	sidebarMenuOptionSelected: {
		extend: 'sidebarMenuOption',
		backgroundColor: 'rgba(9, 30, 66, 0.04)'
	},
	sideBar: {
		boxSizing: 'border-box',
		position: 'relative',
		width: 250,
		maxWidth: '33.3333%',
		marginRight: 'auto',
		right: 'auto',
		minHeight: 'calc( 100vh - 272px )!important',
		background: '#f4f5f7'
	},
	rightSide: {
		boxSizing: 'border-box',
		position: 'relative',
		paddingLeft: '0px!important',
		paddingRight: 15,
		width: 'calc( 100vw - 240px)',
		marginLeft: 0,
		right: 'auto',
		left: '240px!important'
	},
	affix: {
		// position: 'fixed',
		left: 0,
		minWidth: '100vw'
	}
}));

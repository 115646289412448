import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import LoaderSpinner from '../LoaderSpinner';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { useParams } from '@reach/router';
import ThoroughbredRelationshipSection from './ThoroughbredRelationshipSection';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getAdminActionOptionsList,
	getDeceasedThoroughbredFormById,
	getDeceasedThoroughbredReferences,
	getHorsePreviousClaim,
	getHorseProgram,
	listHorseClaimSources,
	saveDeceasedThoroughbredFormStatus,
	saveAdminAction,
	getHorseClaim
} from '../../graphql/custom.queries';
import MemberProfileView from '../MemberProfileView';
import { APPROVED, REVIEWED } from '../../utils/constants';
import SNSAdmin from '../SNSAdmin';
import AdminActions from '../AdminActions';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import { navigate } from '@reach/router';
import { sortArray } from '../../utils/helpers';
import RetirementCategory from '../RetirementCategory';
import { generateDeceasedInputPayload } from './helper';
import DeceasedUpdateSection from './DeceasedUpdateSection';
import HeaderBanner from '../HeaderBanner';
import { CONCLUDED_OHEP_SOURCE, SHARE_INFORMATION_SOURCE } from './constants';

const DeceasedThoroughbredForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [shareInformationFormData, setShareInformationFormData] = useState(
		null
	);
	const [deceasedThoroughbredForm, setDeceasedThoroughbredForm] = useState(
		null
	);
	const { addToast } = useToast();
	const { currentUser } = useAuth();
	const [data, setData] = useState(null);
	const classes = useStyles();
	let { id } = useParams();
	const [outcomeId, setOutcomeId] = useState(0);
	const [actionMemberId, setActionMemberId] = useState(1);
	const [adminStatusId, setAdminStatusId] = useState(0);
	const [notes, setNotes] = useState('');
	const [outcomeList, setOutcomeList] = useState([]);
	const [actionMemberList, setActionMemberList] = useState([]);
	const [adminStatusList, setAdminStatusList] = useState([]);
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [adminActions, setAdminActions] = useState(null);
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [error, setError] = useState('');
	const [adminActionList, setAdminActionList] = useState([]);
	const [references, setReferences] = useState(null);
	const [adminData, setAdminData] = useState();
	const [onValidate, setOnValidate] = useState(false);
	const [adminDataErrors, setAdminDataErrors] = useState({
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		}
	});

	useEffect(() => {
		if (onValidate) {
			evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	useEffect(() => {
		AppSyncService.execute(getAdminActionOptionsList).then((list) => {
			setAdminActionList(list.data);
		});
	}, []);

	useEffect(() => {
		if (adminActionList && deceasedThoroughbredForm) {
			let modifiedOutcomeList = sortArray(
				adminActionList.listAdminActionOutcomes,
				true
			);
			modifiedOutcomeList.unshift({ id: 0, value: '' });
			setOutcomeList(modifiedOutcomeList);
			let modifiedActionList = adminActionList.listAdminActionStauses;
			modifiedActionList.unshift({ id: 0, value: '' });
			setAdminStatusList(modifiedActionList);
			let memberList = [];
			adminActionList.getAdminMembers.map((v) => {
				memberList.push({
					id: v.externalId,
					value: `${v.firstName} ${v.lastName}`
				});
			});
			setActionMemberList(memberList);

			setAdminActions(deceasedThoroughbredForm.adminAction);
			let memberId = deceasedThoroughbredForm.adminAction?.admin?.externalId
				? deceasedThoroughbredForm.adminAction?.admin?.externalId
				: currentUser?.UserId;
			let outComeId = deceasedThoroughbredForm.adminAction?.adminOutcome?.id
				? deceasedThoroughbredForm.adminAction?.adminOutcome?.id
				: 0;
			let adminStatus = deceasedThoroughbredForm.adminAction?.adminStatus?.id
				? deceasedThoroughbredForm.adminAction?.adminStatus?.id
				: 0;
			setOutcomeId(outComeId);
			setActionMemberId(memberId);
			setAdminStatusId(adminStatus);
		}
	}, [deceasedThoroughbredForm, adminActionList]);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const referencesResult = await AppSyncService.execute(
				getDeceasedThoroughbredReferences
			);
			setReferences(referencesResult);
			const sourceData = await AppSyncService.execute(listHorseClaimSources);
			if (!sourceData?.data?.listHorseClaimSources) {
				return;
			}
			const resultForm = await AppSyncService.execute(
				getDeceasedThoroughbredFormById,
				{
					id
				}
			);

			if (
				!resultForm?.data ||
				!resultForm?.data?.getDeceasedThoroughbredFormById
			) {
				setIsLoading(false);
				return;
			}
			let deceasedThoroughbredForm =
				resultForm.data.getDeceasedThoroughbredFormById;
			setDeceasedThoroughbredForm(deceasedThoroughbredForm);
			setShareInformationFormData({
				relationship: deceasedThoroughbredForm.relationship,
				relationshipOther: deceasedThoroughbredForm.relationshipOther,
				lastSeenDate: deceasedThoroughbredForm.lastSeenDate
			});
			const horseCode = deceasedThoroughbredForm?.horse?.horseCode;
			const memberExternalId = deceasedThoroughbredForm?.member?.externalId;
			const horsePreviousClaim = await AppSyncService.execute(
				getHorsePreviousClaim,
				{
					horseCode: horseCode,
					memberExternalId: memberExternalId
				}
			);
			let previousClaimData = null;

			if (
				horsePreviousClaim?.data != null &&
				horsePreviousClaim?.data?.getHorsePreviousClaim != null
			) {
				previousClaimData = horsePreviousClaim.data.getHorsePreviousClaim;
			}

			if (!previousClaimData) {
				// @NOTE: If previous claim is unavailable, get from current claim
				const horseCurrentClaim = await AppSyncService.execute(getHorseClaim, {
					horseCodeForClaim: horseCode,
					memberExternalId
				});

				if (
					horseCurrentClaim?.data != null &&
					horseCurrentClaim?.data?.getHorseClaim != null
				) {
					previousClaimData = horseCurrentClaim.data.getHorseClaim;
				}
			}
			if (previousClaimData) {
				let stabledAddress, horseName, claimsSource;
				let isHorseAtAddress = true;
				if (previousClaimData?.claimsSource) {
					claimsSource =
						sourceData.data.listHorseClaimSources.find(
							(item) => item.id === previousClaimData.claimsSource
						)?.value || '';
				}
				if (previousClaimData?.horse) {
					horseName = previousClaimData.horse.name;
				}

				if (
					previousClaimData?.stabledAddressLine &&
					previousClaimData?.stabledCountry &&
					previousClaimData?.stabledPostcode &&
					previousClaimData?.stabledSuburb &&
					previousClaimData?.stabledState
				) {
					stabledAddress = {
						stabledAddressLine: previousClaimData.stabledAddressLine,
						stabledCountry: previousClaimData.stabledCountry,
						stabledPostcode: previousClaimData.stabledPostcode,
						stabledSuburb: previousClaimData.stabledSuburb,
						stabledState: previousClaimData.stabledState
					};
					isHorseAtAddress = false;
				}
				setData({
					...previousClaimData,
					address: previousClaimData.member.residentialAddress,
					claimsSource,
					horseCode: horseCode,
					horseName,
					horse: previousClaimData.horse,
					isHorseAtAddress,
					memberId: memberExternalId,
					reviewStatus: APPROVED,
					stabledAddress,
					status: deceasedThoroughbredForm.status
				});
			}
			let splitOtherVicesRetirement = [];
			let mapBehaviourRetirement = [];
			if (deceasedThoroughbredForm.behaviourOthersRetirement) {
				splitOtherVicesRetirement = deceasedThoroughbredForm.behaviourOthersRetirement.split(
					','
				);
				mapBehaviourRetirement = splitOtherVicesRetirement.map(
					(otherVices) => ({
						label: otherVices,
						value: otherVices
					})
				);
			}
			setNotes(deceasedThoroughbredForm.reviewNotes);
			setAdminData({
				//Retirement
				selectedCategory: deceasedThoroughbredForm.retirementCategory
					? deceasedThoroughbredForm.retirementCategory
					: 0,
				selectedVulnerabilityType: deceasedThoroughbredForm.vulnerabilityType
					? deceasedThoroughbredForm.vulnerabilityType
					: 0,
				selectedReason:
					deceasedThoroughbredForm.reasonRetirement.length > 0
						? deceasedThoroughbredForm.reasonRetirement.map((reason) => {
								return { value: reason.id, label: reason.value };
						  })
						: [],
				selectedVicesRetirement:
					deceasedThoroughbredForm.habitsRetirement.length > 0
						? deceasedThoroughbredForm.habitsRetirement.map(
								(selectedHabitsRetirement) => {
									return {
										value: selectedHabitsRetirement.id,
										label: selectedHabitsRetirement.value
									};
								}
						  )
						: [],
				otherVicesRetirement: deceasedThoroughbredForm.behaviourOthersRetirement
					? mapBehaviourRetirement
					: '',
				conformationalDefectsRetirement: deceasedThoroughbredForm.conformationalDetailsRetirement
					? deceasedThoroughbredForm.conformationalDetailsRetirement
					: '',
				selectedEducation:
					deceasedThoroughbredForm.education.length > 0
						? deceasedThoroughbredForm.education.map((selectedEducation) => {
								return {
									value: selectedEducation.id,
									label: selectedEducation.value
								};
						  })
						: [],
				otherEducationDetails: deceasedThoroughbredForm.otherEducationDetails
					? deceasedThoroughbredForm.otherEducationDetails
					: '',
				selectedInjuriesRetirement:
					deceasedThoroughbredForm.injuriesRetirement.length > 0
						? deceasedThoroughbredForm.injuriesRetirement.map(
								(injuriesRetirement) => {
									return {
										value: injuriesRetirement.id,
										label: injuriesRetirement.value
									};
								}
						  )
						: [],
				selectedIllnessRetirement:
					deceasedThoroughbredForm.illnessRetirement.length > 0
						? deceasedThoroughbredForm.illnessRetirement.map(
								(illnessRetirement) => {
									return {
										value: illnessRetirement.id,
										label: illnessRetirement.value
									};
								}
						  )
						: [],
				otherInjuryDetails: deceasedThoroughbredForm.otherInjuryDetailsRetirement
					? deceasedThoroughbredForm.otherInjuryDetailsRetirement
					: '',
				otherIllnessDetails: deceasedThoroughbredForm.otherIllnessDetailsRetirement
					? deceasedThoroughbredForm.otherIllnessDetailsRetirement
					: '',
				rehomingHistoryDetails: deceasedThoroughbredForm.rehomingHistoryDetails
					? deceasedThoroughbredForm.rehomingHistoryDetails
					: '',
				otherDetailsRetirement: deceasedThoroughbredForm.otherDetailsRetirement
					? deceasedThoroughbredForm.otherDetailsRetirement
					: ''
			});
			const getHorseProgramResult = await AppSyncService.execute(
				getHorseProgram,
				{
					horseCode: parseInt(
						resultForm.data?.getDeceasedThoroughbredFormById?.horse?.horseCode
					)
				}
			);
			if (
				getHorseProgramResult.data != null &&
				getHorseProgramResult.data.getHorseProfile != null
			) {
				setData((state) => ({
					...state,
					horseProgram: getHorseProgramResult.data.getHorseProfile.program.value
				}));
			}

			setIsLoading(false);
		};
		fetchData();
	}, []);

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) && adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) && adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;

		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);
	};

	const updateFields = (name, value) => {
		if (name === 'outcome') {
			setOutcomeId(value);
		}
		if (name === 'adminStatus') {
			setAdminStatusId(value);
		}

		if (name === 'memberId') {
			setActionMemberId(value);
		}
		setIsAdminActionUpdated(true);
	};

	const updateAdminActions = (status = null) => {
		setIsLoadingAdminAction(true);
		AppSyncService.execute(saveAdminAction, {
			formId: parseInt(deceasedThoroughbredForm.id),
			formType: 'DeceasedThoroughbred',
			memberExternalId: actionMemberId,
			adminOutcomeId: parseInt(outcomeId) === 0 ? null : parseInt(outcomeId),
			adminStatusId:
				parseInt(adminStatusId) === 0 ? null : parseInt(adminStatusId),
			id:
				adminActions.admin.id === actionMemberId ? adminActions.admin.id : null
		}).then((data) => {
			if (
				data.data != null &&
				!data.data?.saveAdminAction?.error?.errorMessage
			) {
				const deceasedInput = generateDeceasedInputPayload(adminData);
				AppSyncService.execute(saveDeceasedThoroughbredFormStatus, {
					id: deceasedThoroughbredForm.id,
					reviewNotes: notes,
					status,
					deceasedInput
				}).then((result) => {
					setIsLoadingAdminAction(false);
					if (
						result.data != null &&
						!result.data?.saveDeceasedThoroughbredFormStatus?.error
							?.errorMessage
					) {
						addToast({ Message: 'Updated Admin Action', IsSuccess: true });
						if (status === REVIEWED) navigate(`/review-list`);
					} else {
						addToast({ Message: 'Failed to Update', IsSuccess: false });
						setError(
							result.data?.saveDeceasedThoroughbredFormStatus?.error
								?.errorMessage
						);
					}
				});
			} else {
				setIsLoadingAdminAction(false);
				addToast({ Message: 'Failed to Update', IsSuccess: false });
				setError(data.data?.saveAdminAction?.error?.errorMessage);
			}
		});
	};

	const updateReviewNotes = (val) => {
		setNotes(val);
		setIsAdminActionUpdated(true);
	};

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setAdminData(items);
		setOnValidate(true);
		setIsAdminActionUpdated(true);
	};

	const completeForm = (status) => {
		const hasError = evaluateAdminError();
		if (hasError) {
			return;
		} else {
			updateAdminActions(status);
		}
	};

	return (
		<PageContainer>
			<SEO title="Deceased Thoroughbred form" />
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			{!isLoading && (
				<form onSubmit={onSubmit}>
					<FormBody>
						<HeaderBanner
							title={`Deceased Thoroughbred Update Form`}
							top
							type="primary"
							styles={{ width: '100%', borderRadius: '5px 5px 5px 5px' }}
						/>
						{(deceasedThoroughbredForm?.deceasedThoroughbredSource?.id ===
							CONCLUDED_OHEP_SOURCE ||
							deceasedThoroughbredForm?.deceasedThoroughbredSource?.id ===
								SHARE_INFORMATION_SOURCE) && (
							<FormSection sectionTitle="Thoroughbred Relationship">
								<ThoroughbredRelationshipSection
									relationship={shareInformationFormData?.relationship}
									relationshipOther={
										shareInformationFormData?.relationshipOther
									}
									lastSeenDate={shareInformationFormData?.lastSeenDate}
								/>
							</FormSection>
						)}

						{data?.member != null && (
							<FormSection withoutPadding={true} showHeader={false}>
								<MemberProfileView
									memberProfileData={data.member}
									ownershipType={data.ownershipType}
									organisation={data.organisation}
									claimPurpose={data.claimPurpose}
									horseProgram={data.horseProgram}
									stabledAddress={data.stabledAddress}
									stabledDate={data.stabledDate}
									claimsSource={data.claimsSource}
									claimsSourceOther={data.claimsSourceOther}
									title={'Personal Profile - Details'}
									adminReview
								/>
							</FormSection>
						)}
						{data?.horse != null && (
							<FormSection withoutPadding={true} showHeader={false}>
								<SNSAdmin horseProfileData={data?.horse} />
							</FormSection>
						)}
						{references && deceasedThoroughbredForm && (
							<FormSection sectionTitle="Death Information">
								<DeceasedUpdateSection
									data={deceasedThoroughbredForm}
									references={references.data}
								/>
							</FormSection>
						)}
						<FormSection withoutPadding={true} showHeader={false}>
							<AdminActions
								actionMemberId={actionMemberId}
								adminStatusId={adminStatusId}
								outComeId={outcomeId}
								memberList={actionMemberList}
								statusList={adminStatusList}
								outComeList={outcomeList}
								formName="deceasedThoroughbred"
								updateFields={updateFields}
								reviewNotes={notes}
								updateReviewNotes={updateReviewNotes}
								reviewStatus={deceasedThoroughbredForm?.status}
								isAdminActionUpdated={isAdminActionUpdated}
								isShowMiniLoader={false}
								isLoading={isLoadingAdminAction}
								updateAdminAction={() => updateAdminActions()}
								completeForm={completeForm}
								error={error}
								noBorderBottom
								retirementCategory={
									adminData && (
										<RetirementCategory
											isReadOnly={deceasedThoroughbredForm?.status !== 'Review'}
											adminData={adminData}
											adminDataErrors={adminDataErrors}
											handleMultiFieldChange={handleMultiFieldChange}
										/>
									)
								}
							/>
						</FormSection>
					</FormBody>
				</form>
			)}
		</PageContainer>
	);
};

export default DeceasedThoroughbredForm;

import React from 'react';
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const SearchInput = ({
	value,
	type = 'text',
	onChangeFunc = () => {},
	onClick = () => {},
	fullwidth = false,
	size = 'small'
}) => {
	const keyPress = (e) => {
		if (e.keyCode == 13) {
			onChangeFunc(e);
			onClick();
		}
	};

	return (
		<FormControl size={size} fullwidth={fullwidth} variant="outlined">
			<InputLabel htmlFor="outlined-adornment-password">Search...</InputLabel>
			<OutlinedInput
				id="outlined-adornment-password"
				type={type}
				value={value}
				onChange={(e) => onChangeFunc(e)}
				onKeyDown={(e) => keyPress(e)}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => onClick()}
							edge="end"
						>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				}
				labelWidth={70}
			/>
		</FormControl>
	);
};

export default SearchInput;

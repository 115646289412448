import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	uploadBusinessProfileImage,
	removeBusinessProfileImage,
	checkImageExist
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useToast } from '../../hooks/useToast';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import MainPhotoUploadToolTip from '../MainPhotoUploadToolTip';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
import { ProfileKeys } from '../../utils/constants';

const BusinessMediaDetails = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			updateIsLoading,
			setIsSubmitted,
			businessProfileType,
			onSaveBusinessProfileData,
			setHasValidMediaData,
			businessProfilesData,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();

		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [businessRiddenImages, setBusinessRiddenImages] = useState([]);
		const [businessPropertyImages, setBusinessPropertyImages] = useState([]);
		const [businessGeneralImages, setBusinessGeneralImages] = useState([]);
		const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
			false
		);
		const [needMorePhoto, setNeedMorePhoto] = useState(false);
		const [imgSrcUrl, setImgSrcUrl] = useState(null);
		const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

		const RIDDEN_IMAGE_TYPE = 'ridden';
		const PROPERTY_IMAGE_TYPE = 'property';
		const GENERAL_IMAGE_TYPE = 'general';

		useEffect(() => {
			const filteredRiddenPhotos = businessProfileData
				? async () => {
						const flags = await Promise.all(
							businessProfileData.riddenPhotos.map((x) =>
								AppSyncService.execute(checkImageExist, { imageKey: x.key })
							)
						);
						setBusinessRiddenImages(
							businessProfileData.riddenPhotos.filter((riddenData, index) => {
								if (flags[index].data.checkImageExist) {
									return (
										riddenData.businessProfileId == businessProfileData.id &&
										flags[index]
									);
								}
							})
						);
				  }
				: [];
			const filteredPropertyPhotos = businessProfileData
				? async () => {
						const flags = await Promise.all(
							businessProfileData.propertyPhotos.map((property) =>
								AppSyncService.execute(checkImageExist, {
									imageKey: property.key
								})
							)
						);
						setBusinessPropertyImages(
							businessProfileData.propertyPhotos.filter(
								(propertyData, index) => {
									if (flags[index].data.checkImageExist) {
										return (
											propertyData.businessProfileId ==
												businessProfileData.id && flags[index]
										);
									}
								}
							)
						);
				  }
				: [];
			const filteredGeneralPhotos = businessProfileData
				? async () => {
						const flags = await Promise.all(
							businessProfileData.generalPhotos.map((general) =>
								AppSyncService.execute(checkImageExist, {
									imageKey: general.key
								})
							)
						);
						setBusinessGeneralImages(
							businessProfileData.generalPhotos.filter((generalData, index) => {
								if (flags[index].data.checkImageExist) {
									return (
										generalData.businessProfileId == businessProfileData.id &&
										flags[index]
									);
								}
							})
						);
				  }
				: [];

			setBusinessRiddenImages(filteredRiddenPhotos);
			setBusinessPropertyImages(filteredPropertyPhotos);
			setBusinessGeneralImages(filteredGeneralPhotos);
			updateIsLoading(false);
		}, [businessProfileData]);

		useEffect(() => {
			if (
				businessRiddenImages.length > 0 ||
				businessPropertyImages.length > 0 ||
				businessGeneralImages.length > 0
			) {
				validateImages();
			}
		}, [businessRiddenImages, businessPropertyImages, businessGeneralImages]);

		useImperativeHandle(ref, () => ({
			triggerValidation() {
				validateImages();
			}
		}));

		const validateImages = () => {
			if (businessProfileType === 2 || businessProfileType === 3) {
				const totalCountOfImages =
					businessRiddenImages.length +
					businessPropertyImages.length +
					businessGeneralImages.length;
				if (totalCountOfImages < 2 && totalCountOfImages !== 0) {
					setNeedMorePhoto(totalCountOfImages < 2);
					if (businessPropertyImages.length === 0) {
						setBackendErrorMessage(`1 photo of "Photo - Property" is required`);
					} else {
						setBackendErrorMessage(
							`Please upload at least 1 or more photo in either "Photo - Facilities" or "Photo - General"`
						);
					}
					setHasValidMediaData(false);
				} else if (businessPropertyImages.length === 0) {
					setNeedMorePhoto(true);
					setBackendErrorMessage(`1 photo of "Photo - Property" is required`);
					setHasValidMediaData(false);
				} else {
					setBackendErrorMessage('');
					setHasValidMediaData(true);
				}
				// if (totalCountOfImages === 0) {
				// 	setBackendErrorMessage('');
				// 	setHasValidMediaData(false);
				// }
			} else {
				const hasRequiredImages =
					businessRiddenImages.length !== 0 &&
					businessPropertyImages.length !== 0;
				setHasValidMediaData(hasRequiredImages);
				setBackendErrorMessage('');
			}
		};

		const businessImagesUpload = (image, imageType) => {
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					let imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							AppSyncService.execute(uploadBusinessProfileImage, {
								filename: image.name,
								key: imageKey,
								memberExternalId: currentUser?.UserId,
								type: imageType,
								businessProfileTypeId: businessProfileType
							}).then((data) => {
								const {
									data: { saveBusinessProfileImage }
								} = data;
								if (saveBusinessProfileImage?.id) {
									validateFields();
									if (imageType == RIDDEN_IMAGE_TYPE) {
										let dataList = Object.assign([], businessRiddenImages);
										dataList.push({
											approved: false,
											filename: image.name,
											key: imageKey,
											businessProfileId: !businessProfileData?.id
												? saveBusinessProfileImage?.id
												: businessProfileData.id,
											type: imageType
										});

										setBusinessRiddenImages(dataList);
										const currentState = {
											riddenPhotos: dataList,
											status: 'Draft',
											businessProfileId: !businessProfileData?.id
												? saveBusinessProfileImage?.id
												: businessProfileData.id
										};
										onSaveBusinessProfileData(currentState);
									} else if (imageType == PROPERTY_IMAGE_TYPE) {
										let dataList = Object.assign([], businessPropertyImages);
										dataList.push({
											approved: false,
											filename: image.name,
											key: imageKey,
											businessProfileId: !businessProfileData?.id
												? saveBusinessProfileImage?.id
												: businessProfileData.id,
											type: imageType
										});
										setBusinessPropertyImages(dataList);
										const currentState = {
											propertyPhotos: dataList,
											status: 'Draft'
										};

										onSaveBusinessProfileData(currentState);
									} else if (imageType == GENERAL_IMAGE_TYPE) {
										let dataList = Object.assign([], businessGeneralImages);
										dataList.push({
											approved: false,
											filename: image.name,
											key: imageKey,
											businessProfileId: !businessProfileData?.id
												? saveBusinessProfileImage?.id
												: businessProfileData.id,
											type: imageType
										});
										setBusinessGeneralImages(dataList);
										const currentState = {
											generalPhotos: dataList,
											status: 'Draft'
										};

										onSaveBusinessProfileData(currentState);
									}
									setIsSubmitted(false);
									addToast({ Message: 'Photo uploaded', IsSuccess: true });
								}
							});
						});
				}
			});
		};

		const uploadError = (isMinimumUploadSize) => {
			setIsMinimumUploadSize(isMinimumUploadSize);
			setShowImageUploadErrorModal(true);
		};

		const businessRiddenImagesUpload = (image) => {
			businessImagesUpload(image, RIDDEN_IMAGE_TYPE);
		};

		const businessPropertyImagesUpload = (image) => {
			businessImagesUpload(image, PROPERTY_IMAGE_TYPE);
		};

		const businessGeneralImagesUpload = (image) => {
			businessImagesUpload(image, GENERAL_IMAGE_TYPE);
		};

		const getImageRidden = (img) => {
			const imageKey = { imageKey: img.key };
			AppSyncService.execute(getUserImage, imageKey).then((data) => {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			});
		};

		const getImageProperty = (img) => {
			const imageKey = { imageKey: img.key };
			AppSyncService.execute(getUserImage, imageKey).then((data) => {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			});
		};

		const getImageGeneral = (img) => {
			const imageKey = { imageKey: img.key };
			AppSyncService.execute(getUserImage, imageKey).then((data) => {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			});
		};

		const removeBusinessRiddenImages = (img) => {
			removeBusinessImages(img, RIDDEN_IMAGE_TYPE);
		};

		const removeBusinessPropertyImages = (img) => {
			removeBusinessImages(img, PROPERTY_IMAGE_TYPE);
		};

		const removeBusinessGeneralImages = (img) => {
			removeBusinessImages(img, GENERAL_IMAGE_TYPE);
		};

		const removeBusinessImages = (img, imageType) => {
			setBackendErrorMessage(null);
			AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
				(data) => {
					const selectedBusinessProfileId = businessProfilesData.find(
						(item) => item.businessProfileType?.id === businessProfileType
					)?.id;
					if (data?.data?.deleteUserImage?.success) {
						AppSyncService.execute(removeBusinessProfileImage, {
							filename: img.filename,
							type: imageType,
							businessProfileId: selectedBusinessProfileId
						}).then((data) => {
							if (data.data.deleteBusinessProfileImage) {
								validateFields();
								if (imageType == RIDDEN_IMAGE_TYPE) {
									let dataList = Object.assign([], businessRiddenImages);
									let newDataList = dataList.filter((d) => d.key != img.key);
									setBusinessRiddenImages(newDataList);
									if (businessProfileData?.riddenPhotos) {
										businessProfileData.riddenPhotos = businessProfileData.riddenPhotos.filter(
											(ridden) =>
												ridden.businessProfileId !== businessProfileData.id
										);
									}
								} else if (imageType == PROPERTY_IMAGE_TYPE) {
									let dataList = Object.assign([], businessPropertyImages);
									let newDataList = dataList.filter((d) => d.key != img.key);
									setBusinessPropertyImages(newDataList);
									if (businessProfileData?.propertyPhotos) {
										businessProfileData.propertyPhotos = businessProfileData.propertyPhotos.filter(
											(property) =>
												property.businessProfileId !== businessProfileData.id
										);
									}
								} else if (imageType == GENERAL_IMAGE_TYPE) {
									let dataList = Object.assign([], businessGeneralImages);
									let newDataList = dataList.filter((d) => d.key != img.key);
									setBusinessGeneralImages(newDataList);
									if (businessProfileData?.generalPhotos) {
										businessProfileData.generalPhotos = businessProfileData.generalPhotos.filter(
											(general) => {
												if (
													general.businessProfileId ===
														businessProfileData.id &&
													general.filename === img.filename
												) {
													return false;
												}
												return true;
											}
										);
									}
								}
								addToast({ Message: 'Photo removed', IsSuccess: true });
							}
						});
					}
				}
			);
		};
		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<form id={ProfileKeys.MEDIA} className={classes.formContainer}>
					<div className={classes.mediaInfo}>
						<span>
							{businessProfileType !== 3
								? `A minimum of 2 photos are required.${
										businessProfileType === 2
											? ``
											: `1x"Photo - Ridden" and
						1x"Photo - Property".`
								  } `
								: `A minimum of 2 photos are required for the business profiles.`}
						</span>
					</div>
					<div className={classes.personalProfile}>
						<Row>
							<Col lg={4} md={4} sm={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										{`Photo - ${
											businessProfileType === 2 || businessProfileType === 3
												? 'Facilities'
												: 'Ridden*'
										}`}
										{businessProfileType !== 3 && (
											<MainPhotoUploadToolTip
												mobilePositionStyles={{ top: -70, left: -70 }}
											>
												{`Users viewing your ${
													businessProfileType === 2 || businessProfileType === 3
														? 'Business Profile'
														: 'Retrainer Profile'
												} will see this image
										first.`}
											</MainPhotoUploadToolTip>
										)}
									</div>
									<UploadPhoto
										id={ProfileKeys.FACILITIES_RIDDEN}
										formats={['png', 'jpeg']}
										error={uploadError}
										data={businessRiddenImages}
										sizeLimitMB={5}
										success={businessRiddenImagesUpload}
										getImage={getImageRidden}
										removeImage={removeBusinessRiddenImages}
										labelName={`Upload Photo - ${
											businessProfileType === 2 || businessProfileType === 3
												? 'Facilities'
												: 'Ridden'
										} (jpg,png)`}
										numberOfImages={businessProfileType !== 3 ? 1 : 5}
									/>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Photo - Property*</div>
									<UploadPhoto
										id={ProfileKeys.PROPERTY}
										formats={['png', 'jpeg']}
										error={uploadError}
										data={businessPropertyImages}
										sizeLimitMB={5}
										success={businessPropertyImagesUpload}
										getImage={getImageProperty}
										removeImage={removeBusinessPropertyImages}
										labelName="Upload Photo - Property (jpg,png)"
										numberOfImages={1}
									/>
								</div>
							</Col>
							<Col lg={4} md={4} sm={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Photo - General (Optional - max of 10)
									</div>
									<UploadPhoto
										id={ProfileKeys.GENERAL}
										formats={['png', 'jpeg']}
										error={uploadError}
										data={businessGeneralImages}
										sizeLimitMB={5}
										success={businessGeneralImagesUpload}
										getImage={getImageGeneral}
										removeImage={removeBusinessGeneralImages}
										labelName="Upload Photo - General (jpg,png)"
										numberOfImages={10}
									/>
								</div>
							</Col>
						</Row>
					</div>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{needMorePhoto && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</>
		);
	}
);
export default BusinessMediaDetails;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	table: {
		width: '100%',
		borderCollapse: 'collapse',
		'& h4': { marginBottom: 10 },
		maxWidth: 'calc(100% - 30px)',
		margin: '15px auto'
	},
	tableCol2: {},
	tableCol3: {},
	tableHeader: {
		fontSize: 12,
		textAlign: 'left',
		backgroundColor: '#f5f5f5',
		'& th': {
			borderStyle: 'solid',
			borderColor: 'transparent',
			borderWidth: 0,
			borderBottomWidth: 1,
			padding: '5px 10px'
		},
		'&$tableCol2': {
			'& th': { width: '16.66%' }
		},
		'&$tableCol3': {
			'& th': { width: '25%' }
		}
	},
	tableBody: {
		fontSize: 14,
		textAlign: 'left',
		'& td': {
			borderStyle: 'solid',
			borderColor: '#c1c1c3',
			borderWidth: 0,
			borderBottomWidth: 1,
			fontSize: 12,
			padding: '5px 10px',
			'&:first-child': {
				fontWeight: 'bold'
			}
		}
	}
});

import React from 'react';
import FormFieldWrapper from '../FormFieldWrapper';
import FormDatePicker from '../../FormDatePicker';

const DateInputField = ({
	label,
	isRequired,
	value,
	onChange,
	validationError,
	fullWidth,
	noMaxDate,
	showTimeSelect,
	showTimeInput,
	visibilitySelector,
	minDate,
	disabled = false,
	id
}) => {
	return (
		<FormFieldWrapper
			fieldLabel={label}
			isRequired={isRequired}
			errorMessage={validationError}
			fullWidth={fullWidth}
			visibilitySelector={visibilitySelector}
		>
			<FormDatePicker
				id={id}
				DateValue={value}
				updateSelection={onChange}
				noMaxDate={noMaxDate}
				showTimeSelect={showTimeSelect}
				showTimeInput={showTimeInput}
				minDate={minDate}
				canEdit={!disabled}
			/>
		</FormFieldWrapper>
	);
};

export default DateInputField;

import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'block',
		position: 'relative',
		userSelect: 'none',
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		padding: 20
	},
	fieldContainer: {
		paddingLeft: 3,
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	errorTextDropDown: {
		color: '#db0000',
		fontSize: 15,
		marginTop: 5
	},
	errorTextResizingArea: {
		extend: 'errorTextDropDown',
		marginBottom: 10
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	},
	cancelButton: {
		extend: 'saveRequest',
		textDecoration: 'underline',
		marginRight: 20,
		display: 'flex',
		alignItems: 'center'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},
	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	disabledCompleteFormButton: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	}
}));

import React from 'react';
import useStyles from './styles';
const Toggle = ({
	id,
	labelNegative,
	labelPositive,
	onClick,
	value,
	disabled
}) => {
	const classes = useStyles();
	return (
		<div id={id} className={classes.container}>
			<div
				id={`${id}_no`}
				onClick={() => {
					if (!disabled) {
						onClick(false);
					}
				}}
				className={
					disabled
						? value
							? classes.disabledpassive
							: classes.disabledactive
						: value
						? classes.passive
						: classes.active
				}
			>
				{labelNegative}
			</div>
			<div
				id={`${id}_yes`}
				onClick={() => {
					if (!disabled) {
						onClick(true);
					}
				}}
				className={
					disabled
						? [value ? classes.disabledactive : classes.disabledpassive]
						: value
						? classes.active
						: classes.passive
				}
			>
				{labelPositive}
			</div>
		</div>
	);
};
export default Toggle;

import { Link } from 'gatsby';
import React from 'react';
import { ScreenClassRender } from 'react-grid-system';
import {
	THOROUGHBRED,
	OTT_INFO_MAIL,
	OTT_INFO_PHONE
} from '../../utils/constants';
import useStyles from './styles';
import facebookIcon from '../../assets/icons/facebook-icon.png';
import twitterIcon from '../../assets/icons/twitter-icon.png';
import instagramIcon from '../../assets/icons/instagram-icon.png';
import linkedInIcon from '../../assets/icons/linkedIn-icon.png';
import footerIcon from '../../assets/images/OTT_community_email.jpg';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

const Footer = () => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const location = useLocation();
	const FooterComponent = ({ mobile }) => {
		return (
			<>
				{location.pathname !== '/styleguide' && (
					<div className={classes.footer}>
						<div
							className={
								mobile ? classes.columnContainerMobile : classes.columnContainer
							}
						>
							<div className={mobile ? classes.mobilecolumn : classes.column}>
								<div className={classes.colHeader}>Site Links</div>
								<div
									className={
										mobile
											? classes.linksContainerMobile
											: classes.linksContainer
									}
								>
									<a
										href="https://www.racingvictoria.com.au/"
										target="_blank"
										rel="noreferrer"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
									>
										Racing Victoria
									</a>
									<a
										href="https://www.racingvictoria.com.au/the-horse/equine-welfare/equine-welfare-strategy"
										target="_blank"
										rel="noreferrer"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
									>
										Equine Welfare Strategy
									</a>
								</div>
							</div>
						</div>
						<div
							className={
								mobile ? classes.columnContainerMobile : classes.columnContainer
							}
						>
							<div className={mobile ? classes.mobilecolumn : classes.column}>
								<div className={classes.colHeader}>About the Site</div>
								<div
									className={
										mobile
											? classes.linksContainerMobile
											: classes.linksContainer
									}
								>
									{!currentUser && (
										<Link
											to="/im-new"
											className={
												mobile ? classes.linkItemMobile : classes.linkItem
											}
										>
											I&apos;m New
										</Link>
									)}
									<a
										id="footer-link-termsandconditions"
										href="/terms"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
										target="_blank"
									>
										Terms and Conditions
									</a>
									<a
										id="footer-link-privacypolicy"
										href="/privacy"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
										target="_blank"
									>
										Privacy Policy
									</a>
									<a
										id="footer-link-privacycollectionstatement"
										href="/privacy-collection-statement"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
										target="_blank"
									>
										Privacy Collection Statement
									</a>
									<Link
										to="/login"
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
									>
										Login
									</Link>
									<Link
										to="/search"
										state={{ searchSelected: true, search: THOROUGHBRED }}
										className={
											mobile ? classes.linkItemMobile : classes.linkItem
										}
									>
										Search for a Horse
									</Link>
								</div>
							</div>
						</div>
						<div
							className={
								mobile ? classes.columnContainerMobile : classes.columnContainer
							}
						>
							<div className={mobile ? classes.mobilecolumn : classes.column}>
								<div className={classes.colHeader}>Connect With Us</div>
								<div
									className={
										mobile
											? classes.linksContainerMobile
											: classes.linksContainer
									}
								>
									<div
										className={
											mobile
												? classes.iconContainerMobile
												: classes.iconContainer
										}
									>
										<img src={facebookIcon} className={classes.squareIcon} />
										<a
											className={classes.iconLinkItem}
											href="https://www.facebook.com/OffTheTrackRV"
											rel="noreferrer"
											target="_blank"
										>
											Facebook
										</a>
									</div>
									<div
										className={
											mobile
												? classes.iconContainerMobile
												: classes.iconContainer
										}
									>
										<img src={instagramIcon} className={classes.squareIcon} />
										<a
											className={classes.iconLinkItem}
											href="https://www.instagram.com/rv_offthetrack/"
											rel="noreferrer"
											target="_blank"
										>
											Instagram
										</a>
									</div>
									<div
										className={
											mobile
												? classes.iconContainerMobile
												: classes.iconContainer
										}
									>
										<img src={twitterIcon} className={classes.squareIcon} />
										<a
											className={classes.iconLinkItem}
											href="https://twitter.com/RV_OffTheTrack"
											rel="noreferrer"
											target="_blank"
										>
											Twitter
										</a>
									</div>
									<div
										className={
											mobile
												? classes.iconContainerMobile
												: classes.iconContainer
										}
									>
										<img src={linkedInIcon} className={classes.squareIcon} />
										<a
											className={classes.iconLinkItem}
											href="https://au.linkedin.com/company/racing-victoria-limited"
											rel="noreferrer"
											target="_blank"
										>
											LinkedIn
										</a>
									</div>
								</div>
							</div>
						</div>
						<div
							className={
								mobile ? classes.columnContainerMobile : classes.columnContainer
							}
						>
							<div className={mobile ? classes.mobilecolumn : classes.column}>
								<div className={classes.colHeader}>Contact Us</div>
								<div
									style={{ textTransform: 'initial', fontWeight: 'lighter' }}
								>
									<div
										onClick={() => navigate('/contact-us')}
										className={classes.basicLink}
									>
										Contact Us Form
									</div>
									400 Epsom Road, Flemington, Victoria, Australia 3031
									<br />
									Email:{' '}
									<a
										className={classes.basicLink}
										href={'mailto:' + OTT_INFO_MAIL}
									>
										{OTT_INFO_MAIL}
									</a>
									<br />
									Phone:{' '}
									<a
										className={classes.basicLink}
										href={'tel:' + OTT_INFO_PHONE}
									>
										{OTT_INFO_PHONE}
									</a>
									<br />
									<br />
									We acknowledge Aboriginal Traditional Owners of Country
									throughout Victoria and pay respect to their cultures and
									Elders past, present and emerging.
									{/* This is just to get the relative S3 path for this image */}
									<img src={footerIcon} style={{ display: 'none' }} />
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	};
	const mobileComponent = <FooterComponent mobile />;
	const desktopComponent = <FooterComponent />;

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default Footer;

import React from 'react';
import Accordion from '../../../Accordion';
import FormMultiSelectList from '../../../FormMultiSelectList';
import useStyles from './styles';
import { ResultContainer } from '../ResultComponents';
import InfiniteScrollLoadRow from '../../../InfiniteScrollLoadRow';
import InfiniteScroll from 'react-infinite-scroll-component';

const FormReviewList = ({
	sectionId,
	sectionTitle,
	results,
	Row,
	headings,
	isAckFormsReview = false,
	totalResults,
	fetchData,
	selectableFilters,
	selectedFilters,
	onFiltersChange,
	selectableFiltersByName,
	selectedFiltersByName,
	onFiltersChangeByName,
	noFilterByName = false
}) => {
	const classes = useStyles();
	const RESULT_CONTAINER_ID = `${sectionId}_result_container`;
	return (
		<Accordion id={sectionId} title={sectionTitle} styles={{ marginTop: 20 }}>
			<div className={classes.filterContainer}>
				<div className={classes.filterTitle}>Filter by Status</div>
				<div className={classes.filterComponentContainer}>
					<FormMultiSelectList
						name="filterStatus"
						listOptions={selectableFilters}
						selectedIds={selectedFilters}
						onSelectChange={onFiltersChange}
					/>
				</div>
			</div>
			{!noFilterByName && (
				<div className={classes.filterContainer}>
					<div className={classes.filterTitle}>
						Filter by {`${isAckFormsReview ? 'Form' : 'Name'}`}
					</div>
					<div className={classes.filterComponentContainer}>
						<FormMultiSelectList
							name="filterFormName"
							listOptions={selectableFiltersByName}
							selectedIds={selectedFiltersByName}
							onSelectChange={onFiltersChangeByName}
						/>
					</div>
				</div>
			)}
			<ResultContainer id={RESULT_CONTAINER_ID}>
				{isAckFormsReview && <Row isHeading={true} headings={headings} />}
				<InfiniteScroll
					dataLength={results.length}
					next={fetchData}
					hasMore={totalResults > results.length}
					loader={<InfiniteScrollLoadRow />}
					scrollableTarget={RESULT_CONTAINER_ID}
				>
					{results.map((form) => (
						<Row key={form} form={form} />
					))}
				</InfiniteScroll>
			</ResultContainer>
		</Accordion>
	);
};

export default FormReviewList;

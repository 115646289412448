import React from 'react';
import useStyles from './styles';
import { Row } from 'react-grid-system';

const FormBody = ({ children }) => {
	const classes = useStyles();
	return <Row className={classes.formSectionBody}>{children}</Row>;
};

export default FormBody;

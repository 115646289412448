import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'flex',
		marginBottom: 40,
		flexWrap: 'wrap',
		'& $cardsContainer:nth-child(3n+2)': {
			marginRight: 20,
			marginLeft: 20
		}
	},
	mobileContainer: {
		display: 'flex',
		marginBottom: 40,
		flexWrap: 'wrap',
		justifyContent: 'center'
	},

	cardsContainer: {
		marginTop: 20
	},

	image: {
		width: '100%',
		display: 'block',
		borderRadius: '5px 5px 0px 0px',
		height: 220,
		objectFit: 'cover'
	},

	articleContainer: {
		width: '100%',
		border: '2px solid #ebebeb',
		boxSizing: 'border-box',
		borderTop: 'none',
		padding: 15,
		minHeight: 200
	},

	articleHeader: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: 15,
		color: '#464749'
	},
	articleLink: {
		extend: 'articleHeader',
		color: '#0085ca',
		cursor: 'pointer'
	},
	articleSummary: {
		fontSize: 14
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	fieldContainer: {
		padding: '20px 18px 20px 18px'
	},
	labelField: {
		color: '#464749',
		fontSize: 15,
		fontWeight: 600,
		marginBottom: 7
	},
	sectionContainer: {
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		borderBottom: 'none'
	},
	sectionContainerMobile: {
		border: 'none'
	}
}));

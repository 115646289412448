import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	toggleHeader: {
		display: 'inline',
		padding: 10,
		cursor: 'pointer',
		borderRadius: 50,
		color: '#006da6',
		fontSize: 15,
		width: 300
	},
	toggleContainer: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: 'fit-content',
		textAlign: 'center',
		background: '#dcebf3',
		marginBottom: 20,
		padding: '12px 5px 12px 5px',
		borderRadius: 50,
		whiteSpace: 'nowrap',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	toggleContainerMobile: {
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		background: '#dcebf3',
		marginBottom: 20,
		padding: 4,
		borderRadius: 50,
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	},
	toggleHeaderMobile: {
		display: 'inline-block',
		padding: 0,
		whiteSpace: 'break-spaces',
		cursor: 'pointer',
		borderRadius: 50,
		color: '#006da6',
		fontSize: 14,
		width: '100%'
	},
	scrollBar: {
		backgroundColor: '#dcebf3',
		width: '100%'
	},
	menuContainerMobile: {
		display: 'flex',
		overflowX: 'scroll',
		overflow: 'hidden',
		height: 50,
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#dcebf3',
		borderRadius: 40,
		whiteSpace: 'nowrap',
		float: 'left',
		'-msOverflowStyle': 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			// remove display none to see the scrollbar
			display: 'none',
			height: '5px',
			width: 10,
			background: 'white'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#0085ca',
			height: '5px'
		},
		'&::-webkit-scrollbar-track-piece': {
			margin: 20
		},
		'& div': {
			display: 'inline-block',
			color: '#0085ca',
			fontWeight: 500,
			textAlign: 'center',
			padding: '13px 20px'
		},
		'& div:first-child': {
			marginLeft: 20
		},
		'& div:last-child': {
			marginRight: 20
		}
	},
	menuContentContainer: {
		width: '100%',
		position: 'relative'
	},
	menuContentContainerDesktop: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: 'auto',
		position: 'relative'
	},
	menuContainer: {
		overflowX: 'scroll',
		overflow: 'hidden',
		height: 'auto',
		width: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#dcebf3',
		borderRadius: 40,
		whiteSpace: 'nowrap',
		position: 'relative',
		display: 'inline-block',
		'-msOverflowStyle': 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			// remove display none to see the scrollbar
			display: 'none',
			height: '5px',
			width: 10,
			background: 'white'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#0085ca',
			height: '5px'
		},
		'&::-webkit-scrollbar-track-piece': {
			margin: 20
		},
		'& div': {
			display: 'inline-block',
			color: '#0085ca',
			fontWeight: 500,
			textAlign: 'center',
			padding: '13px 8px'
		},
		'& div:first-child': {
			marginLeft: 10
		},
		'& div:last-child': {
			marginRight: 10
		}
	},
	leftScroll: {
		backgroundColor: '#dcebf3',
		display: 'inline-block',
		color: '#0085ca',
		fontWeight: 500,
		textAlign: 'center',
		paddingTop: 12,
		height: 50,
		position: 'absolute',
		width: 40,
		marginRight: -4,
		right: 0,
		boxSizing: 'border-box',
		borderRadius: '0px 50px 50px 0px',
		zIndex: 98
	},
	rightScroll: {
		backgroundColor: '#dcebf3',
		display: 'inline-block',
		color: '#0085ca',
		fontWeight: 500,
		textAlign: 'center',
		paddingTop: 12,
		marginLeft: -3,
		position: 'absolute',
		width: 40,
		left: 0,
		height: 50,
		boxSizing: 'border-box',
		borderRadius: '50px 0px 0px 50px',
		zIndex: 98
	},
	menuItem: {
		cursor: 'pointer',
		fontSize: 14
	}
}));

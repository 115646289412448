import React from 'react';
import { PAGE_ID } from '../../constants';
import { Row, Col } from 'react-grid-system';
import FormFieldWrapper from '../../../FormComponents/FormFieldWrapper';
import AddressAutoSuggestion from '../../../AddressAutoSuggestion';
import TextInputField from '../../../FormComponents/TextInputField';
import DateInputField from '../../../FormComponents/DateInputField';
import StateSelector from '../../../StateSelector';
import CountrySelector from '../../../CountrySelector';
import ManualAddressEntryCheckbox from '../../../ManualAddressEntryCheckbox';
import { formatDate, validatePostCode } from '../../../../utils/helpers';
import useStyles from './styles';
import HorseDetailVisibilitySelect from '../../../HorseDetailVisibilitySelect';

const StableLocationForm = ({
	stableLocationFormState,
	updateFormState,
	visibilityOptions
}) => {
	const {
		address,
		suburb,
		country,
		state,
		postcode,
		stabledDate,
		addressEnteredManually
	} = stableLocationFormState;

	const classes = useStyles();

	const onAddressSelect = (addressDetails) => {
		const address = `${addressDetails.Line1}${addressDetails.Line2 || ''}`;
		updateFormState((prevState) => ({
			...prevState,
			address: {
				...prevState.address,
				value: address,
				validationError: ''
			},
			suburb: {
				...prevState.suburb,
				value: addressDetails.Suburb,
				validationError: ''
			},
			postcode: {
				...prevState.postcode,
				value: addressDetails.Postcode,
				validationError: ''
			},
			state: {
				...prevState.state,
				value: addressDetails.State,
				validationError: ''
			},
			country: {
				...prevState.country,
				value: addressDetails.Country,
				validationError: ''
			},
			locationSource: { validationError: '' }
		}));
	};

	return (
		<Col className={classes.formSectionContent}>
			<Row>
				<FormFieldWrapper
					fieldLabel="Stable Address"
					errorMessage={address.validationError}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={address.visibility}
							options={visibilityOptions}
							disabled
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									address: {
										...prevState.address,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
					isRequired
				>
					<AddressAutoSuggestion
						id={`${PAGE_ID}-stable-address-input`}
						value={address.value}
						onAddressSelect={onAddressSelect}
						onChange={(newAddress) =>
							updateFormState((prevState) => ({
								...prevState,
								address: {
									...prevState.address,
									value: newAddress,
									validationError: ''
								},
								locationSource: { validationError: '' }
							}))
						}
						disableAutoSuggestion={addressEnteredManually.value}
						canEdit
					/>
				</FormFieldWrapper>
				<TextInputField
					id={`${PAGE_ID}-stable-suburb-input`}
					label="Stable Suburb"
					value={suburb.value}
					onChange={(event) => {
						const newSuburb = event.target.value;
						updateFormState((prevState) => ({
							...prevState,
							suburb: {
								...prevState.suburb,
								value: newSuburb,
								validationError: ''
							},
							locationSource: { validationError: '' }
						}));
					}}
					isDisabled={!addressEnteredManually.value}
					validationError={suburb.validationError}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={suburb.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									suburb: {
										...prevState.suburb,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
					isRequired
				/>
				<FormFieldWrapper
					fieldLabel="Stable State"
					errorMessage={state.validationError}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={state.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									state: {
										...prevState.state,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
					isRequired
				>
					<StateSelector
						id={`${PAGE_ID}-stable-state-input`}
						country={country.value}
						value={state.value}
						changeHandler={(newState) =>
							updateFormState((prevState) => ({
								...prevState,
								state: {
									...prevState.state,
									value: newState,
									validationError: ''
								},
								locationSource: { validationError: '' }
							}))
						}
						isDisabled={!addressEnteredManually.value}
					/>
				</FormFieldWrapper>
			</Row>
			<Row>
				<TextInputField
					id={`${PAGE_ID}-stable-postcode-input`}
					label="Stable Postcode"
					value={postcode.value}
					onChange={(event) => {
						const newPostcode = event.target.value;
						const isValidPostcode = validatePostCode(newPostcode);
						if (isValidPostcode) {
							updateFormState((prevState) => ({
								...prevState,
								postcode: {
									...prevState.postcode,
									value: newPostcode,
									validationError: ''
								},
								locationSource: { validationError: '' }
							}));
						}
					}}
					isDisabled={!addressEnteredManually.value}
					validationError={postcode.validationError}
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={postcode.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									postcode: {
										...prevState.postcode,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
					isRequired
				/>
				<FormFieldWrapper
					fieldLabel="Stable Country"
					errorMessage={country.validationError}
					isRequired
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={country.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									country: {
										...prevState.country,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				>
					<CountrySelector
						id={`${PAGE_ID}-stable-country-input`}
						value={country.value}
						changeHandler={(newCountryValue) =>
							updateFormState((prevState) => ({
								...prevState,
								country: {
									...prevState.country,
									value: newCountryValue,
									validationError: ''
								},
								locationSource: { validationError: '' }
							}))
						}
						isDisabled={!addressEnteredManually.value}
					/>
				</FormFieldWrapper>
				<DateInputField
					label="Date Arrived"
					value={stabledDate.value}
					onChange={(newDateValue) =>
						updateFormState((prevState) => ({
							...prevState,
							stabledDate: {
								...prevState.stabledDate,
								value: newDateValue ? formatDate(newDateValue) : newDateValue,
								validationError: ''
							}
						}))
					}
					validationError={stabledDate.validationError}
					isRequired
					visibilitySelector={
						<HorseDetailVisibilitySelect
							value={stabledDate.visibility}
							options={visibilityOptions}
							onChange={(e) => {
								const newVisibilityValue = e.target.value;
								updateFormState((prevState) => ({
									...prevState,
									stabledDate: {
										...prevState.stabledDate,
										visibility: newVisibilityValue
									}
								}));
							}}
						/>
					}
				/>
			</Row>
			<Row>
				<Col lg={4} sm={12}>
					<ManualAddressEntryCheckbox
						isSelected={addressEnteredManually.value}
						onChangeHandler={() =>
							updateFormState((prevState) => ({
								...prevState,
								addressEnteredManually: {
									value: !prevState.addressEnteredManually.value
								}
							}))
						}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default StableLocationForm;

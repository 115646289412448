export const PAGE_ID = 'transition-guide';
export const OUTCOME_PAGE_TYPE = 'outcome';
export const INITIAL_FORM_STATE = {
	horseName: '',
	concerns: '',
	step1: null,
	step2: null,
	step3: null,
	step4: null,
	step5: null,
	step6: null,
	step7: null,
	step8: null
};
export const INITIAL_STEPPER_STATE = {
	activeStep: 1,
	previousActiveSteps: [1]
};

import { Link } from 'gatsby';
import styled from 'styled-components';
import StatisticWidget from '../StatisticWidget';

export const StyledClaimCountLink = styled(Link)`
	text-decoration: none;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		display: flex;
		justify-content: center;
	}
`;

export const StyledClaimCountWidget = styled(StatisticWidget)`
	margin-bottom: 0.5rem;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		width: 92%;
	}
`;

import React from 'react';
import Modal from 'react-modal';
import useStyles from './styles';

const InfoModal = ({ heading, bodyContent, styles, showModal, closeModal }) => {
	const classes = useStyles();
	return (
		<div>
			<Modal
				isOpen={showModal}
				onRequestClose={closeModal}
				style={styles}
				contentLabel="Example Modal"
				ariaHideApp={false}
			>
				<h2 className={classes.headingStyles}>{heading}</h2>
				{bodyContent}
				<div className={classes.closeButtonStyles} onClick={closeModal}>
					Close
				</div>
			</Modal>
		</div>
	);
};

export default InfoModal;

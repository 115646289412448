import React, { useState, useEffect } from 'react';
import MultiDropDown from '../MultiDropDown';
import { navigate } from '@reach/router';
import useStyles from './styles';
import { ScreenClassRender, Row, Col } from 'react-grid-system';
import SearchField from '../SearchField';
import AppSyncService from '../../graphql/AppSyncService';
import {
	searchRetirementFormHorses,
	listServicesOffered
} from '../../graphql/custom.queries';
import Sorting_Default_icon from '../../assets/icons/Sorting_Default_icon.svg';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import { useToast } from '../../hooks/useToast';
import LoaderSpinner from '../LoaderSpinner';
import LazyImage from '../LazyImage';
import SEO from '../seo';
import Accordion from '../Accordion';
import RetirementFarmMap from './components/RetirementFarmMap';
import PageContainer from '../PageContainer';

const SERVICES_OFFERED_COLORS = {
	dot: '#dab9fb',
	containers: 'rgb(244 240 255)'
};
const MAX_CONTAINERS_TO_DISPLAY = 8;

const RetirementFarmSearch = ({ isSmallScreen }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [toggleFilter, setToggleFilter] = useState(!isSmallScreen);
	const [servicesOffered, setServiceOffered] = useState([]);

	const [selectedFilter, setSelectedFilter] = useState([]);
	const [selectedServiceOffer, setSelectedServiceOffer] = useState([]);
	const [businessProfileIdFilter, setBusinessProfileIdFilter] = useState(null);
	const [orderByName, setOrderByName] = useState(0);
	const [nameFilterValue, setNameFilterValue] = useState(null);
	const [resultCount, setResultCount] = useState(0);
	const [searchActive, setSearchActive] = useState(true);
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		AppSyncService.execute(listServicesOffered).then((data) => {
			let listServiceArray = [];
			let count = 0;
			data.data.listServicesOffered
				.filter((i) => i.value != 'Other')
				.map((rec) => {
					listServiceArray.push({
						id: count,
						key: rec.id,
						title: rec.value,
						selected: false
					});
					count++;
				});
			setServiceOffered(listServiceArray);
		});
	}, []);

	const searchByInput = () => {
		setSearchActive(true);
	};

	useEffect(() => {
		if (searchResults.length > 0) {
			searchByInput();
		}
	}, [businessProfileIdFilter]);

	useEffect(() => {
		if (searchActive === true) {
			updateSearch();
		}
		setSearchActive(false);
	}, [searchActive]);

	const resetServiceOffer = (id) => {
		const temp = servicesOffered;

		temp[id].selected = !temp[id].selected;

		let filter = Object.assign([], selectedFilter);
		let selectedOffer = Object.assign([], selectedServiceOffer);

		if (temp[id].selected) {
			filter.push(temp[id].title);
			selectedOffer.push({ key: temp[id].key, value: temp[id].title });
		}

		if (!temp[id].selected) {
			const index = filter.indexOf(temp[id].title);
			const key = temp[id].key;
			const filterIndex = selectedOffer.indexOf(key);
			if (index > -1) {
				filter.splice(index, 1);
				selectedOffer.splice(filterIndex, 1);
			}
		}

		setSelectedFilter(filter);
		setServiceOffered(temp);
		setSelectedServiceOffer(selectedOffer);

		setSearchActive(true);
	};

	const updateValue = (val) => {
		setNameFilterValue(val);
	};

	const removeAllFilters = () => {
		selectedFilter.forEach((value) => {
			removeFilter(value);
		});
		setSelectedServiceOffer([]);
		setSelectedFilter([]);
		setBusinessProfileIdFilter(null);
		setSearchActive(true);
	};

	const removeFilter = (val) => {
		let filters = Object.assign([], selectedFilter);
		const index = filters.indexOf(val);
		if (index > -1) {
			filters.splice(index, 1);
		}

		setSelectedFilter(filters);

		const tempA = servicesOffered;

		tempA
			.filter((i) => i.title === val)
			.map((d) => {
				d.selected = false;
			});

		let service = selectedServiceOffer.filter((i) => i.value != val);

		setServiceOffered(tempA);

		setSelectedServiceOffer(service);
		setSearchActive(true);
	};

	const updateOrdering = () => {
		let order = orderByName === 0 ? 1 : orderByName === 1 ? 2 : 0;
		setOrderByName(order);
		setSearchActive(true);
	};

	const selectedList = (data) => {
		let array = [];
		data.map((d) => {
			array.push(d.key);
		});
		return array;
	};

	const updateSearch = () => {
		let businessProfileType = 2;
		let servicesOffered = selectedList(selectedServiceOffer);

		let orderBy = orderByName === 2 ? 'DESC' : 'ASC';
		setIsLoading(true);
		AppSyncService.execute(searchRetirementFormHorses, {
			businessProfileType,
			name: nameFilterValue,
			servicesOffered,
			orderBy,
			businessProfileIds: businessProfileIdFilter
				? [businessProfileIdFilter]
				: []
		}).then((data) => {
			try {
				setResultCount(data.data.countRetrainers);
			} catch {
				addToast({ Message: 'Error Fetching Rec..', IsSuccess: false });
			}
			try {
				setSearchResults(data.data.findRetrainers);
			} catch {
				addToast({ Message: 'Error Fetching Images', IsSuccess: false });
			}
			setIsLoading(false);
		});
	};

	const keyCheck = (key) => {
		if (key === 13) {
			setSearchActive(true);
		}
	};

	const reduceString = (data) => {
		if (data != null) {
			let processedSummary = data.substring(0, 200);
			let result = processedSummary.concat('...');
			return result;
		}
	};

	const getContainerLimitsForSearchResult = (data) => {
		if (data.servicesOffered.length < MAX_CONTAINERS_TO_DISPLAY / 2) {
			return {
				numServicesToDisplay: data.servicesOffered.length,
				numDisciplinesToDisplay:
					MAX_CONTAINERS_TO_DISPLAY - data.servicesOffered.length
			};
		} else {
			return {
				numServicesToDisplay: MAX_CONTAINERS_TO_DISPLAY / 2,
				numDisciplinesToDisplay: MAX_CONTAINERS_TO_DISPLAY / 2
			};
		}
	};

	const renderSearchResults = (forMobile) => {
		return searchResults.map((data) => {
			const { numServicesToDisplay } = getContainerLimitsForSearchResult(data);
			return (
				<div
					className={
						forMobile ? classes.resultContentMobile : classes.resultContent
					}
					key={data.id}
				>
					<div
						className={
							forMobile ? classes.resultImage : classes.resultImageMobile
						}
					>
						<LazyImage
							imageKey={data.riddenPhotos.filter((f) => f.approved)[0]?.key}
						/>
					</div>
					<div
						className={classes.resultTitle}
						onClick={() => navigate(`/retirement-farm-profile/${data.id}`)}
					>
						{data.businessName}
					</div>
					<div
						className={classes.resultAddress}
					>{`${data.suburb}, ${data.state} ${data.postcode}`}</div>
					<div className={classes.resultSummary}>
						{reduceString(data.businessSummary)}
					</div>
					<div className={classes.resultTags}>
						{data.servicesOffered.map((tag, index) => {
							return (
								<div key={index}>
									{index < numServicesToDisplay && (
										<div
											className={classes.resultTag}
											style={{ background: SERVICES_OFFERED_COLORS.containers }}
											key={tag.id}
										>
											{tag.value}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	};

	const SearchResults = isLoading ? (
		<Row>
			<div className={classes.loadingDiv}>
				<LoaderSpinner status={isLoading} />
			</div>
		</Row>
	) : (
		<Row style={{ flexDirection: isSmallScreen ? 'column-reverse' : 'row' }}>
			<Col sm={12} lg={4}>
				<div className={classes.resultContainer}>
					{renderSearchResults(false)}
				</div>
			</Col>
			<Col sm={12} lg={8} style={isSmallScreen && { marginBottom: 15 }}>
				<RetirementFarmMap
					searchResults={searchResults}
					businessProfileIdFilter={businessProfileIdFilter}
					setBusinessProfileIdFilter={setBusinessProfileIdFilter}
				/>
			</Col>
		</Row>
	);

	const desktopComponent = (
		<PageContainer>
			<SEO title="Retirement Farm Search" />
			<SearchField
				onInputChange={updateValue}
				title="Search by Name"
				onSearchClick={searchByInput}
				keyCheck={keyCheck}
			/>
			{selectedFilter.length > 0 && (
				<div className={classes.filters}>
					<div className={classes.filterContent}>
						{selectedFilter.map((f) => {
							return (
								<span key={f} className={classes.appliedFilter}>
									{f}{' '}
									<span
										className={classes.appliedFilterCross}
										onClick={() => removeFilter(f)}
									>
										&#10006;
									</span>
								</span>
							);
						})}
						<span
							className={classes.appliedFilter}
							style={{ cursor: 'pointer' }}
							onClick={() => removeAllFilters()}
						>
							Clear all
						</span>
					</div>
				</div>
			)}
			<div className={classes.searchFieldContainer}>
				<div
					className={classes.addFilter}
					onClick={() => setToggleFilter(!toggleFilter)}
				></div>
				<Accordion title="Add Filters" isOpen={toggleFilter} mobile>
					<div className={classes.filterContainer}>
						<div className={classes.serFilter}>
							<MultiDropDown
								dotColor={SERVICES_OFFERED_COLORS.dot}
								list={servicesOffered}
								resetThenSet={resetServiceOffer}
								headTitle="Services Offered"
							/>
						</div>
					</div>
				</Accordion>
				<Row>
					<Col>
						<div className={classes.searchDetails}>
							<span className={classes.results}>Results</span>
							{searchResults.length > 0 && (
								<span
									className={classes.filterOrderBy}
									onClick={() => updateOrdering()}
								>
									Order by Name
									<span className={classes.sortIcon}>
										{orderByName === 0 && <img src={Sorting_Default_icon} />}
										{orderByName === 1 && <img src={Sorting_Down_icon} />}
										{orderByName === 2 && <img src={Sorting_Up_icon} />}
									</span>
								</span>
							)}
							<span className={classes.numberOfRecords}>
								{resultCount} {resultCount > 1 ? 'results' : 'result'} found
							</span>
							<div className={classes.clear}></div>
						</div>
					</Col>
				</Row>
				{SearchResults}
			</div>
		</PageContainer>
	);

	const mobileComponent = (
		<PageContainer>
			<SEO title="Retirement Farm Search" />
			<SearchField
				onInputChange={updateValue}
				title="Search by Name"
				onSearchClick={searchByInput}
				keyCheck={keyCheck}
			/>
			{selectedFilter.length > 0 && (
				<div className={classes.filters}>
					<div className={classes.filterContent}>
						{selectedFilter.map((f) => {
							return (
								<span key={f} className={classes.appliedFilterMobile}>
									{f}{' '}
									<span
										className={classes.appliedFilterCross}
										onClick={() => removeFilter(f)}
									>
										&#10006;
									</span>
								</span>
							);
						})}
						<span
							className={classes.appliedFilterMobile}
							onClick={() => removeAllFilters()}
						>
							Clear all
						</span>
					</div>
				</div>
			)}
			<div className={classes.searchFieldContainer}>
				<div
					className={classes.addFilter}
					onClick={() => setToggleFilter(!toggleFilter)}
				></div>

				<Accordion title="Add Filters" isOpen={toggleFilter} mobile>
					<div className={classes.filterContainer}>
						<div className={classes.filterMobileSer}>
							<MultiDropDown
								dotColor={SERVICES_OFFERED_COLORS.dot}
								list={servicesOffered}
								resetThenSet={resetServiceOffer}
								headTitle="Services Offered"
							/>
						</div>
					</div>
				</Accordion>
				<div className={classes.searchDetails}>
					<span className={classes.results}>Results</span>
					{searchResults.length > 0 && (
						<span
							className={classes.filterOrderBy}
							onClick={() => updateOrdering()}
						>
							Order by Name
							<span className={classes.sortIcon}>
								{orderByName === 0 && <img src={Sorting_Default_icon} />}
								{orderByName === 1 && <img src={Sorting_Down_icon} />}
								{orderByName === 2 && <img src={Sorting_Up_icon} />}
							</span>
						</span>
					)}
					<span className={classes.numberOfRecords}>
						{resultCount} {resultCount > 1 ? 'results' : 'result'} found
					</span>
					<div className={classes.clear}></div>
				</div>
				{SearchResults}
			</div>
		</PageContainer>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default RetirementFarmSearch;

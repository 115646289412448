import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: '100%',
		marginBottom: 25
	},
	col: {
		margin: 'auto!important'
	}
}));

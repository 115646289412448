import React, { useState } from 'react';
import PrimaryButton from '../PrimaryButton';
import ResizingTextArea from '../ResizingTextArea';
import useStyles from './styles';
import FileUploader from '../FileUploader';
import classnames from 'classnames';
import UploadFileLink from '../UploadFileLink';
import Lightbox from 'react-image-lightbox';
import PostUpdateConfirmModal from '../PostUpdateConfirmModal';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import AppSyncService from '../../graphql/AppSyncService';
import { imageUploadS3 } from '../../utils/helpers';
import { addClaimerHorseUpdate } from './queries';
import { useToast } from '../../hooks/useToast';
import InsertPhotoOutlined from '@material-ui/icons/InsertPhotoOutlined';

const COMPONENT_ID = 'post-horse-updates';

const PostUpdateSection = ({
	idPrefix,
	horseData,
	refreshData,
	horseSelectable,
	horseSelect,
	isLoadingHorseUpdates
}) => {
	const classes = useStyles();
	const [message, setMessage] = useState('');
	const [updateImages, setUpdateImages] = useState([]);
	const [previewImage, setPreviewImage] = useState(null);
	const [showPostUpdateConfirmModal, setShowPostUpdateConfirmModal] = useState(
		false
	);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isPosting, setIsPosting] = useState(false);
	const { addToast } = useToast();

	const postUpdate = () => {
		setIsPosting(true);
		imageUploadS3(updateImages[0], (imageKey) => {
			AppSyncService.execute(addClaimerHorseUpdate, {
				input: {
					horseCode: horseData.horse.horseCode,
					message,
					files: updateImages.map((file) => ({
						filename: file.name,
						key: imageKey
					}))
				}
			}).then((result) => {
				if (result?.data?.addClaimerHorseUpdate?.success) {
					setMessage('');
					setUpdateImages([]);
					refreshData();
					addToast({ Message: 'Horse update posted', IsSuccess: true });
					setIsPosting(false);
				} else {
					addToast({
						Message: 'Failed to post horse update',
						IsSuccess: false
					});
					setIsPosting(false);
				}
			});
		});
	};

	return (
		<>
			<section className={classes.postUpdatesContainer}>
				<ResizingTextArea
					title={
						<span className={classes.label}>
							Post as{' '}
							{horseSelectable ? (
								<span style={{ marginLeft: 7 }}>{horseSelect}</span>
							) : (
								horseData?.horse?.name
							)}
						</span>
					}
					id={`${idPrefix}-${COMPONENT_ID}-textarea`}
					onChange={(e) => {
						setMessage(e.target.value);
					}}
					maxChars={200}
					value={message}
					styles={{ paddingTop: 0 }}
				/>
				<footer className={classes.footerContainer}>
					<div className={classes.footerActionsContainer}>
						<FileUploader
							renderComponent={(onClick) => (
								<button
									id={`${idPrefix}-${COMPONENT_ID}-upload-photo`}
									className={classnames({
										[classes.addPhotoButton]: true,
										[classes.disabledAddPhotoButton]: updateImages.length > 0
									})}
									onClick={onClick}
									disabled={updateImages.length > 0}
								>
									<span className={classes.addPhotoButtonContent}>
										<InsertPhotoOutlined style={{ marginRight: 5 }} />
										Add Photo
									</span>
								</button>
							)}
							onFileUpload={(newFile) =>
								setUpdateImages((prevState) => [...prevState, newFile])
							}
							onUploadError={() => setShowImageUploadErrorModal(true)}
							validFileTypes={['image/jpeg', 'image/png']}
							maxFileSizeInMB={5}
						/>
						<PrimaryButton
							id={`${idPrefix}-${COMPONENT_ID}-post-button`}
							style={{
								minWidth: 150,
								maxWidth: 160,
								marginTop: 0,
								height: 38,
								minHeight: 38,
								padding: 0
							}}
							onClick={() => setShowPostUpdateConfirmModal(true)}
							disabled={updateImages.length === 0}
							isLoading={isPosting || isLoadingHorseUpdates}
						>
							Post Update
						</PrimaryButton>
					</div>
					<div className={classes.uploadedFileLinksContainer}>
						{updateImages.map((file) => (
							<UploadFileLink
								key={file.name}
								file={file}
								onPreview={() => setPreviewImage(file)}
								onRemove={() =>
									setUpdateImages((prevState) =>
										prevState.filter((img) => img.name !== file.name)
									)
								}
							/>
						))}
					</div>
				</footer>
			</section>
			{previewImage && (
				<Lightbox
					mainSrc={previewImage ? URL.createObjectURL(previewImage) : null}
					onCloseRequest={() => {
						URL.revokeObjectURL(previewImage);
						setPreviewImage(null);
					}}
				/>
			)}
			<PostUpdateConfirmModal
				showModal={showPostUpdateConfirmModal}
				onCancel={() => setShowPostUpdateConfirmModal(false)}
				onConfirm={() => {
					setShowPostUpdateConfirmModal(false);
					postUpdate();
				}}
			/>
			{showImageUploadErrorModal && (
				<UploadPhotoErrorModal
					showErrorModal={showImageUploadErrorModal}
					closeModal={() => setShowImageUploadErrorModal(false)}
				/>
			)}
		</>
	);
};

export default PostUpdateSection;

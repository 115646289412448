import React, { useState, useEffect } from 'react';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import SaveAdminDetailsSection from '../../../AdminReviewComponents/SaveAdminDetailsSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import AppSyncService from '../../../../graphql/AppSyncService';
import { getApplicationReviewLookupValues } from '../../graphql/queries';
import { generateAdminReviewNotification } from '../../../../graphql/custom.queries';
import SelectInputField from '../../../FormComponents/SelectInputField';
import MultiSelectInputField from '../../../FormComponents/MultiSelectInputField';
import ToggleInputField from '../../../FormComponents/ToggelnputField';
import TextInputField from '../../../FormComponents/TextInputField';
import DateInputField from '../../../FormComponents/DateInputField';
import FileUploadInputField from '../../../FormComponents/FileUploadInputField';
import { BLANK_OPTION, WORD_DOC_MIME_TYPE } from '../../../../utils/constants';
import { extractLookUpOptions, formatDate } from '../../../../utils/helpers';
import {
	INITIAL_APPLICATION_REVIEW_STATE,
	INITIAL_LOOKUP_STATE,
	PAGE_ID
} from '../../constants';
import {
	initialiseFormState,
	generateSaveAdminDataPayload,
	validateFormData,
	validateFormRetirementData
} from '../../helpers';
import { useAuth } from '../../../../hooks/useAuth';
import {
	deleteUploadedFile,
	saveAcknowledgedRetainerFormStatus,
	saveRetrainerFormAdminData,
	saveRetirementFormAdminData,
	saveUploadedFile,
	saveUploadedRetirementFile,
	saveAcknowledgedRetirementFormStatus
} from '../../graphql/mutations';
import AdminReviewDivider from '../../../AdminReviewComponents/AdminReviewDivider';
import AdminReviewFooter from '../../../AdminReviewComponents/AdminReviewFooter';
import { useToast } from '../../../../hooks/useToast';
import TextAreaInputField from '../../../FormComponents/TextAreaInputField';
import { navigate } from 'gatsby';
import {
	RETIREMENT,
	RETRAINER
} from '../../../RetrainerProfileTemplate/constants';
import {
	deleteUploadedRetirementFile,
	deleteImagesFromS3,
	getMemberProfile
} from '../../../../graphql/custom.queries';
import FormLabelField from '../../../FormLabelField';
import { logToServer } from '../../../../utils/logger';

const ApplicationReviewSection = ({
	formType,
	formData,
	previewImage,
	setShowPersonalDetailError,
	onImageUploadError,
	isReadOnly
}) => {
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const [lookupValues, setLookupValues] = useState(INITIAL_LOOKUP_STATE);
	const [formState, setFormState] = useState(INITIAL_APPLICATION_REVIEW_STATE);
	const [previousAdmin, setPreviousAdmin] = useState('');

	useEffect(() => {
		AppSyncService.execute(getApplicationReviewLookupValues).then((result) => {
			if (result?.data) {
				let lookupValues = {
					actioningOptions: result.data.getAdminMembers?.map(
						({ id, externalId, firstName, lastName }) => ({
							id,
							value: `${firstName} ${lastName}`,
							externalId
						})
					),
					adminStatusOptions: extractLookUpOptions(
						result,
						'listRetrainerFormAdminActions'
					),
					rejectReasonOptions: extractLookUpOptions(
						result,
						formType === RETRAINER
							? 'listRetrainerFormRejectReasons'
							: 'listRetirementFormRejectReasons'
					)
				};

				const adminExist = lookupValues.actioningOptions.some(
					(item) => item.externalId == formData?.adminAction?.admin?.externalId
				);
				if (!adminExist) {
					onGetMemberData(formData?.adminAction?.admin?.externalId);
				}
				if (
					formType === RETIREMENT &&
					lookupValues &&
					lookupValues.adminStatusOptions.length > 0
				) {
					const contactingObj = lookupValues.adminStatusOptions.find(
						(item) => item.id === 1 //@NOTE: 1 is id of Contacting Retrainer
					);
					if (contactingObj) contactingObj.value = 'Contacting User';
				}
				setFormState(initialiseFormState(formData, currentUser, lookupValues));
				setLookupValues(lookupValues);
			}
		});
	}, []);

	useEffect(() => {
		let tempData = {
			...formState,
			personalDetailUploads: {
				...formState.personalDetailUploads,
				value: formData.personalDetailUploads
			}
		};
		setFormState(tempData);
		validateAdminForm(null, tempData);
	}, [formData]);

	const saveApplicationReviewState = () => {
		const payload = generateSaveAdminDataPayload(formState, formData.id);
		const request =
			formType === RETRAINER
				? saveRetrainerFormAdminData
				: saveRetirementFormAdminData;
		AppSyncService.execute(request, payload).then((result) => {
			const response =
				formType === RETRAINER
					? result?.data?.saveAcknowledgedRetrainerAdminData
					: result?.data?.saveAcknowledgedRetirementAdminData;
			if (response?.success) {
				if (formState.adminStatus.value == 3) {
					sendAdminEmail();
				}
				addToast({
					Message: 'Saved Admin updates',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Failed to save admin data', IsSuccess: false });
			}
		});
	};

	const onGetMemberData = async (memberExternalId) => {
		try {
			const result = await AppSyncService.execute(getMemberProfile, {
				externalId: memberExternalId
			});
			if (result?.data?.getMember) {
				const {
					data: { getMember }
				} = result;
				const fullName = `${getMember.firstName} ${getMember.lastName}`;
				setPreviousAdmin(fullName);
			}
		} catch (error) {
			logToServer(currentUser?.UserId, error, `Application Retrainer Error`);
		}
	};

	const sendAdminEmail = () => {
		AppSyncService.execute(generateAdminReviewNotification, {
			formId: +formData.id,
			formType: formType === RETRAINER ? 'RetrainerForm' : 'RetirementForm'
		});
	};

	const saveFileUpload = (fileData, fieldData, userNotificationData) => {
		const { filename, key } = fileData;
		const { fieldName, type } = fieldData;
		const { successMessage, errorMessage } = userNotificationData;
		const saveUploadMutationFunction =
			formType === RETRAINER ? saveUploadedFile : saveUploadedRetirementFile;
		AppSyncService.execute(saveUploadMutationFunction, {
			filename,
			key,
			memberExternalId: currentUser?.UserId,
			formId: formData?.id,
			type
		}).then((result) => {
			if (
				(formType === RETRAINER && result.data.saveUploadedFile.success) ||
				(formType === RETIREMENT &&
					result.data.saveUploadedRetirementFile.success)
			) {
				setFormState((prevState) => ({
					...prevState,
					[fieldName]: {
						value: [...prevState[fieldName].value, { filename, key }],
						validationError: ''
					}
				}));
				addToast({ Message: successMessage, IsSuccess: true });
			} else {
				addToast({
					Message: errorMessage,
					IsSuccess: false
				});
			}
		});
	};

	const removeFileUpload = (removedFile, fieldName, userNotificationData) => {
		const { successMessage, errorMessage } = userNotificationData;
		const deleteUploadedFunction =
			formType === RETRAINER
				? deleteUploadedFile
				: deleteUploadedRetirementFile;
		AppSyncService.execute(deleteUploadedFunction, {
			key: removedFile.key
		}).then((result) => {
			if (
				(formType === RETRAINER && result.data.deleteUploadedFile) ||
				(formType === RETIREMENT && result.data.deleteUploadedRetirementFile)
			) {
				setFormState((prevState) => ({
					...prevState,
					[fieldName]: {
						value: prevState[fieldName].value.filter(
							(file) => file.key != removedFile.key
						),
						validationError: ''
					}
				}));
				addToast({
					Message: successMessage,
					IsSuccess: true
				});
			} else {
				addToast({
					Message: errorMessage,
					IsSuccess: false
				});
			}
		});
	};

	const saveVideoAssessmentUploads = (file, key) => {
		saveFileUpload(
			{ filename: file.name, key },
			{ fieldName: 'videoAssessmentUploads', type: 'VideoAssessment' },
			{
				successMessage: 'Saved video assessment upload',
				errorMessage: 'Failed to save video assessment upload'
			}
		);
	};

	const removeVideoAssessmentUpload = (file) => {
		removeFileUpload(file, 'videoAssessmentUploads', {
			successMessage: 'Removed video assessment upload',
			errorMessage: 'Failed to remove video assessment upload'
		});
	};

	const savePropertyAssessmentUpload = (file, key) => {
		saveFileUpload(
			{ filename: file.name, key },
			{ fieldName: 'propertyAssessmentUploads', type: 'PropertyAssessment' },
			{
				successMessage: 'Saved property assessment upload',
				errorMessage: 'Failed to save property assessment upload'
			}
		);
	};

	const removePropertyAssessmentUpload = (file) => {
		removeFileUpload(file, 'propertyAssessmentUploads', {
			successMessage: 'Removed property assessment upload',
			errorMessage: 'Failed to property video assessment upload'
		});
	};

	const savePropertyAndRidingPhotoUpload = (file, key) => {
		saveFileUpload(
			{ filename: file.name, key },
			{
				fieldName: 'propertyAndRidingPhotoUploads',
				type: 'PropertyRiding'
			},
			{
				successMessage: 'Saved property and riding photo upload',
				errorMessage: 'Failed to save property and riding photo upload'
			}
		);
	};

	const removePropertyAndRidingPhotoUpload = (file) => {
		removeFileUpload(file, 'propertyAndRidingPhotoUploads', {
			successMessage: 'Removed property and riding photo upload',
			errorMessage: 'Failed property and riding photo upload'
		});
	};

	const saveRidingAssessmentUpload = (file, key) => {
		saveFileUpload(
			{ filename: file.name, key },
			{
				fieldName: 'ridingAssessmentUploads',
				type: 'RidingAssessment'
			},
			{
				successMessage: 'Saved riding assessment upload',
				errorMessage: 'Failed to save riding assessment upload'
			}
		);
	};

	const removeRidingAssessmentUpload = (file) => {
		removeFileUpload(file, 'ridingAssessmentUploads', {
			successMessage: 'Removed riding assessment upload',
			errorMessage: 'Failed riding assessment upload'
		});
	};

	const validateAdminForm = (newFormStatus, newFormState = null) => {
		const validationErrorState =
			formType === RETRAINER
				? validateFormData(newFormState ?? formState, newFormStatus)
				: validateFormRetirementData(newFormState ?? formState, newFormStatus);
		console.log({ validationErrorState });
		if (validationErrorState?.personalDetailUploads?.validationError) {
			setShowPersonalDetailError(true);
		} else {
			setShowPersonalDetailError(false);
		}
		setFormState(validationErrorState);
		return Object.values(validationErrorState).find(
			({ validationError }) => validationError
		);
	};

	const saveFormStatus = (status) => {
		const validationErrorsPresent = validateAdminForm(status);
		if (!validationErrorsPresent) {
			saveApplicationReviewState();
			onRemoveUploads();
			const mutationReq =
				formType === RETRAINER
					? saveAcknowledgedRetainerFormStatus
					: saveAcknowledgedRetirementFormStatus;
			AppSyncService.execute(mutationReq, {
				formId: formData.id,
				status,
				...(status === 'Rejected' && {
					reviewMessage: formState.rejectionNotification.value
				})
			}).then((result) => {
				const mutationResp =
					formType === RETRAINER
						? result?.data?.saveAcknowledgedReTrainerFormStatus
						: result?.data?.saveAcknowledgedRetirementFormStatus;
				if (mutationResp?.success) {
					addToast({
						Message:
							status === 'Approved' || status === 'Rejected'
								? 'Form status update successfully'
								: 'Form status updated',
						IsSuccess: true
					});
					navigate('/review-list');
				} else {
					addToast({
						Message: 'Failed to update form status',
						IsSuccess: false
					});
				}
			});
		}
	};

	const getListOfKeys = () => {
		let listOfKeys = [];
		if (formData.personalDetailUploads.length > 0) {
			formData.personalDetailUploads
				.filter((details) => !details.isDeleted)
				.map((d) => {
					listOfKeys.push(d.key);
				});
		}
		if (formData.insuranceCertificateOfCurrencyUploads.length > 0) {
			formData.insuranceCertificateOfCurrencyUploads
				.filter((details) => !details.isDeleted)
				.map((d) => {
					listOfKeys.push(d.key);
				});
		}
		return listOfKeys;
	};

	const onRemoveUploads = () => {
		let listOfKeys = getListOfKeys();
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					console.log('removed');
				}
			});
		}
	};
	console.log(previousAdmin, 'previousAdmin');
	return (
		<>
			<AdminReviewSection title="Application Review" darkContentBackground>
				<Col>
					<SectionRow>
						<SelectInputField
							id={`${PAGE_ID}-actioning-select-input`}
							label="Actioning"
							value={formState.actioning.value}
							options={[BLANK_OPTION, ...lookupValues.actioningOptions]}
							onChange={(event) => {
								const newActioningValue = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									actioning: { value: newActioningValue, validationError: '' }
								}));
							}}
							isRequired
							disabled={isReadOnly}
							placeholder={previousAdmin}
						/>
						<SelectInputField
							id={`${PAGE_ID}-admin-status-select-input`}
							label="Admin Status"
							value={formState.adminStatus.value}
							options={[BLANK_OPTION, ...lookupValues.adminStatusOptions]}
							onChange={(event) => {
								const newAdminStatus = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									adminStatus: { value: newAdminStatus, validationError: '' }
								}));
							}}
							disabled={isReadOnly}
						/>
						<ToggleInputField
							fieldLabel="Application form complete?"
							value={formState.applicationFormComplete.value}
							onChange={(newValue) =>
								setFormState((prevState) => ({
									...prevState,
									applicationFormComplete: { value: newValue }
								}))
							}
							disabled={isReadOnly}
						/>
					</SectionRow>
				</Col>
				{formType === RETRAINER && (
					<>
						<AdminReviewDivider />
						<Col>
							<SectionRow>
								<TextAreaInputField
									id={`${PAGE_ID}-video-assessment-comments-textarea`}
									fieldLabel="Video Assessment Comments"
									onChange={(event) => {
										const newComment = event.target.value;
										setFormState((prevState) => ({
											...prevState,
											videoAssessmentComments: {
												value: newComment,
												validationError: ''
											}
										}));
									}}
									maxChars={200}
									value={formState.videoAssessmentComments.value}
									validationError={
										formState.videoAssessmentComments.validationError
									}
									canEdit={!isReadOnly}
								/>
							</SectionRow>
							<SectionRow>
								<DateInputField
									label="Video Assessment Date"
									value={formState.videoAssessmentDate.value}
									onChange={(newDateValue) =>
										setFormState((prevState) => ({
											...prevState,
											videoAssessmentDate: {
												value: newDateValue
													? formatDate(newDateValue)
													: newDateValue
											}
										}))
									}
									validationError={
										formState.videoAssessmentDate.validationError
									}
									isRequired
									disabled={isReadOnly}
								/>
								<FileUploadInputField
									idPrefix={`${PAGE_ID}-video-assessment-uploads`}
									fieldLabel="Video Assessment Upload (docx,pdf)"
									buttonText="Upload Docs"
									fileData={formState.videoAssessmentUploads.value}
									onSuccessfulS3Upload={saveVideoAssessmentUploads}
									onSuccessfulS3Delete={removeVideoAssessmentUpload}
									onSuccessfulS3ImageDownload={previewImage}
									formats={['pdf', WORD_DOC_MIME_TYPE]}
									numberOfFiles={5}
									validationError={
										formState.videoAssessmentUploads.validationError
									}
									isRequired
									inputErrorHandler={onImageUploadError}
									isEditable={!isReadOnly}
								/>
							</SectionRow>
						</Col>
					</>
				)}
				<AdminReviewDivider />
				<Col>
					<SectionRow>
						<TextAreaInputField
							id={`${PAGE_ID}-phone-interview-comments-textarea`}
							fieldLabel="Phone Interview Comments"
							onChange={(event) => {
								const newComment = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									phoneInterviewComments: {
										value: newComment,
										validationError: ''
									}
								}));
							}}
							maxChars={200}
							value={formState.phoneInterviewComments.value}
							validationError={formState.phoneInterviewComments.validationError}
							canEdit={!isReadOnly}
						/>
					</SectionRow>
					<SectionRow>
						<DateInputField
							label="Phone Interview Date"
							value={formState.phoneInterviewDate.value}
							onChange={(newDateValue) =>
								setFormState((prevState) => ({
									...prevState,
									phoneInterviewDate: {
										value: newDateValue
											? formatDate(newDateValue)
											: newDateValue
									}
								}))
							}
							validationError={formState.phoneInterviewDate.validationError}
							disabled={isReadOnly}
						/>
					</SectionRow>
				</Col>
				<AdminReviewDivider />
				<Col>
					{formType === RETRAINER && (
						<SectionRow>
							<TextAreaInputField
								id={`${PAGE_ID}-property-assessment-comments-textarea`}
								fieldLabel="Property Assessment Comments*"
								onChange={(event) => {
									const newComment = event.target.value;
									setFormState((prevState) => ({
										...prevState,
										propertyAssessmentComments: {
											value: newComment,
											validationError: ''
										}
									}));
								}}
								maxChars={200}
								value={formState.propertyAssessmentComments.value}
								validationError={
									formState.propertyAssessmentComments.validationError
								}
								canEdit={!isReadOnly}
							/>
						</SectionRow>
					)}
					<SectionRow>
						<DateInputField
							label="Property Assessment Date"
							value={formState.propertyAssessmentDate.value}
							onChange={(newDateValue) =>
								setFormState((prevState) => ({
									...prevState,
									propertyAssessmentDate: {
										value: newDateValue
											? formatDate(newDateValue)
											: newDateValue
									}
								}))
							}
							validationError={formState.propertyAssessmentDate.validationError}
							isRequired
							disabled={isReadOnly}
						/>
						<FileUploadInputField
							idPrefix={`${PAGE_ID}-property-assessment-uploads`}
							fieldLabel="Property Assessment Upload (docx,pdf)"
							buttonText="Upload Docs"
							fileData={formState.propertyAssessmentUploads.value}
							onSuccessfulS3Upload={savePropertyAssessmentUpload}
							onSuccessfulS3Delete={removePropertyAssessmentUpload}
							onSuccessfulS3ImageDownload={previewImage}
							formats={['pdf', WORD_DOC_MIME_TYPE]}
							numberOfFiles={formType === RETRAINER ? 5 : 1}
							validationError={
								formState.propertyAssessmentUploads.validationError
							}
							inputErrorHandler={onImageUploadError}
							isRequired
							isEditable={!isReadOnly}
						/>
					</SectionRow>
				</Col>
				<AdminReviewDivider />
				<Col>
					{formType === RETRAINER && (
						<SectionRow>
							<ToggleInputField
								fieldLabel="Was riding assessment on the same day?"
								value={formState.ridingAssessmentSameDay.value}
								onChange={(newValue) =>
									setFormState((prevState) => ({
										...prevState,
										ridingAssessmentSameDay: { value: newValue },
										ridingAssessmentDate: { value: null, validationError: '' }
									}))
								}
								disabled={isReadOnly}
							/>
						</SectionRow>
					)}
					<SectionRow>
						{formType === RETRAINER &&
							!formState.ridingAssessmentSameDay.value && (
								<DateInputField
									label="Riding Assessment Date"
									value={formState.ridingAssessmentDate.value}
									onChange={(newDateValue) =>
										setFormState((prevState) => ({
											...prevState,
											ridingAssessmentDate: {
												value: newDateValue
													? formatDate(newDateValue)
													: newDateValue
											}
										}))
									}
									validationError={
										formState.ridingAssessmentDate.validationError
									}
									isRequired
									disabled={isReadOnly}
								/>
							)}
						{formType === RETRAINER && (
							<TextInputField
								id={`${PAGE_ID}-riding-score-text-input`}
								label="Riding Score %"
								value={formState.ridingScore.value}
								onChange={(event) => {
									const newValue = event.target.value;
									setFormState((prevState) => ({
										...prevState,
										ridingScore: { value: newValue, validationError: '' }
									}));
								}}
								isRequired
								validationError={formState.ridingScore.validationError}
								isDisabled={isReadOnly}
							/>
						)}
						<FileUploadInputField
							idPrefix={`${PAGE_ID}-property-and-riding-uploads`}
							fieldLabel={`Property ${
								formType === RETRAINER ? 'and Riding ' : ''
							}Photos (jpg, png, pdf) (Max 10)`}
							buttonText="Upload Photos"
							fileData={formState.propertyAndRidingPhotoUploads.value}
							onSuccessfulS3Upload={savePropertyAndRidingPhotoUpload}
							onSuccessfulS3Delete={removePropertyAndRidingPhotoUpload}
							onSuccessfulS3ImageDownload={previewImage}
							formats={['jpeg', 'png', 'pdf']}
							inputErrorHandler={onImageUploadError}
							numberOfFiles={10}
							isEditable={!isReadOnly}
						/>
					</SectionRow>
					{formType === RETRAINER && (
						<>
							<SectionRow>
								<TextAreaInputField
									id={`${PAGE_ID}-riding-assessment-comments-textarea`}
									fieldLabel="Riding Assessment Comments*"
									onChange={(event) => {
										const newComment = event.target.value;
										setFormState((prevState) => ({
											...prevState,
											ridingAssessmentComments: {
												value: newComment,
												validationError: ''
											}
										}));
									}}
									maxChars={200}
									value={formState.ridingAssessmentComments.value}
									validationError={
										formState.ridingAssessmentComments.validationError
									}
									canEdit={!isReadOnly}
								/>
							</SectionRow>
							<SectionRow>
								<FileUploadInputField
									idPrefix={`${PAGE_ID}-riding-assessment-uploads`}
									fieldLabel="Riding Assessment Upload (docx,pdf)"
									buttonText="Upload Docs"
									fileData={formState.ridingAssessmentUploads.value}
									onSuccessfulS3Upload={saveRidingAssessmentUpload}
									onSuccessfulS3Delete={removeRidingAssessmentUpload}
									onSuccessfulS3ImageDownload={previewImage}
									formats={['pdf', WORD_DOC_MIME_TYPE]}
									numberOfFiles={5}
									validationError={
										formState.ridingAssessmentUploads.validationError
									}
									inputErrorHandler={onImageUploadError}
									isRequired
									isEditable={!isReadOnly}
								/>
							</SectionRow>
						</>
					)}
				</Col>
				<AdminReviewDivider />
				<Col>
					<SectionRow>
						<TextAreaInputField
							id={`${PAGE_ID}-application-recommendation-textarea`}
							fieldLabel="Application Recommendation*"
							onChange={(event) => {
								const newComment = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									applicationRecommendation: {
										value: newComment,
										validationError: ''
									}
								}));
							}}
							maxChars={200}
							value={formState.applicationRecommendation.value}
							validationError={
								formState.applicationRecommendation.validationError
							}
							canEdit={!isReadOnly}
						/>
					</SectionRow>
					<SectionRow>
						<TextAreaInputField
							id={`${PAGE_ID}-ewgm-comments-textarea`}
							fieldLabel="EWGM Comment*"
							onChange={(event) => {
								const newComment = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									ewgmComment: {
										value: newComment,
										validationError: ''
									}
								}));
							}}
							maxChars={200}
							value={formState.ewgmComment.value}
							validationError={formState.ewgmComment.validationError}
							canEdit={!isReadOnly}
						/>
					</SectionRow>
					<SectionRow>
						<DateInputField
							label="Application Review Date"
							value={formState.applicationReviewDate.value}
							onChange={(newDateValue) =>
								setFormState((prevState) => ({
									...prevState,
									applicationReviewDate: {
										value: newDateValue
											? formatDate(newDateValue)
											: newDateValue
									}
								}))
							}
							validationError={formState.applicationReviewDate.validationError}
							isRequired
							disabled={isReadOnly}
						/>
					</SectionRow>
				</Col>
				<Col>
					<SaveAdminDetailsSection
						onSave={saveApplicationReviewState}
						disabled={isReadOnly}
					/>
				</Col>
			</AdminReviewSection>
			<AdminReviewSection title="Notes and Rejection" darkContentBackground>
				<Col>
					<SectionRow>
						<Col>
							{isReadOnly ? (
								<FormLabelField
									title={'Reasons for rejection'}
									value={formState?.rejectionReasons?.value
										.map((rejectReason) => rejectReason.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<MultiSelectInputField
									idPrefix={`${PAGE_ID}-retrainer-reject-reasons`}
									fieldLabel="Reasons for rejection"
									selectedIds={formState.rejectionReasons.value}
									options={lookupValues.rejectReasonOptions}
									onSelectChange={(newValue) =>
										setFormState((prevState) => ({
											...prevState,
											rejectionReasons: { value: newValue, validationError: '' }
										}))
									}
									otherDisabled
									validationError={formState.rejectionReasons.validationError}
								/>
							)}
						</Col>
					</SectionRow>
					<SectionRow style={{ backgroundColor: '#fcf7da' }}>
						<TextAreaInputField
							id={`${PAGE_ID}-rejection-notification-textarea`}
							fieldLabel="Reason for rejection notification (required if rejecting the form. This is a notification to the user)"
							onChange={(event) => {
								const newValue = event.target.value;
								setFormState((prevState) => ({
									...prevState,
									rejectionNotification: {
										value: newValue,
										validationError: ''
									}
								}));
							}}
							maxChars={300}
							value={formState.rejectionNotification.value}
							validationError={formState.rejectionNotification.validationError}
							canEdit={!isReadOnly}
						/>
					</SectionRow>
				</Col>
			</AdminReviewSection>
			<AdminReviewFooter
				status={formData.status}
				formState={!formState ? INITIAL_APPLICATION_REVIEW_STATE : formState}
				onReject={() => saveFormStatus('Rejected')}
				onApprove={() => saveFormStatus('Approved')}
			/>
		</>
	);
};

export default ApplicationReviewSection;

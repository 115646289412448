import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';

const EquineBusinessGrantLandingPage = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [richText, setRichText] = useState(null);
	const [isPdfActive, setIsPdfActive] = useState(false);
	const [pdfLink, setPdfLink] = useState('');

	const renderCollectionOfVideos = (isMobile) => {
		const UniqueVideoByUrl = [
			...new Map(videoCollection.map((item) => [item['src'], item])).values()
		];
		return UniqueVideoByUrl.map((video, index) => {
			return (
				<div
					key={index}
					className={
						isMobile ? classes.mobileVideoContainer : classes.videoContainer
					}
				>
					<div className={classes.videoTitle}>{video.title}</div>
					<FullArticleEmbeddedAsset
						contentType={video.type}
						contentSrc={video.src}
					/>
				</div>
			);
		});
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				let video = [];
				video = {
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				};
				videoCollection.push(video);
			}
		}
	};

	useEffect(() => {
		setIsPdfActive(
			grantLandingPage.allContentfulLandingPageContent.nodes[0].isActive
		);
		setPdfLink(grantLandingPage.allContentfulLandingPageContent.nodes[0].link);
		setRichText(grantLandingPage.allContentfulLandingPageContent.nodes[0].body);
	}, []);

	const grantLandingPage = useStaticQuery(
		graphql`
			query equineBusinessGrantsLandingPage {
				allContentfulInfoCards(
					filter: { tags: { in: "Life After Racing Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
				allContentfulLandingPageContent(
					filter: { tags: { in: "Life After Racing Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
						isActive
						link
					}
				}
			}
		`
	);

	useEffect(() => {
		const grantLandingPageInfoCards =
			grantLandingPage.allContentfulInfoCards.nodes;
		let grantInfoCards = [];
		if (grantLandingPageInfoCards.length > 0) {
			grantLandingPageInfoCards.map((d) => {
				grantInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			grantInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(grantInfoCards);
		}
	}, []);

	const equineBusinessGrantsInformation = (isMobile) => {
		return (
			<>
				<SEO title="Life After Racing" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="Life After Racing"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<Row>
					<Col lg={8}>{richText && renderRichText(richText, options)}</Col>
				</Row>
				<Row>
					<Col>
						<div className={classes.multipleVideos}>
							{renderCollectionOfVideos(isMobile)}
						</div>
					</Col>
				</Row>
				{isPdfActive && (
					<Row>
						<Col style={{ marginTop: 20 }}>
							<MessageBar>
								<div
									className={classes.messageBar}
									style={isMobile ? { display: 'block' } : {}}
								>
									<div
										className={classes.claimText}
										style={
											isMobile
												? {
														fontSize: 13,
														marginBottom: 10,
														marginTop: 0,
														fontWeight: 'bold'
												  }
												: { fontWeight: 'bold' }
										}
									>
										Get in touch with the Off The Track team for more
										information via{' '}
										<a
											href="mailto:offthetrack@racingvictoria.net.au"
											style={{ fontWeight: 'normal' }}
										>
											offthetrack@racingvictoria.net.au
										</a>
									</div>
									<PrimaryButton
										style={
											isMobile
												? {
														marginTop: 0,
														width: '100%',
														height: 50,
														fontWeight: 600
												  }
												: {
														marginTop: 0,
														width: 220,
														height: 50,
														fontWeight: 600
												  }
										}
									>
										<a
											style={{
												textDecoration: 'unset',
												color: '#FFF'
											}}
											href={pdfLink}
											target="_blank"
											rel="noreferrer"
										>
											More Info
										</a>
									</PrimaryButton>
								</div>
							</MessageBar>
						</Col>
					</Row>
				)}
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>
				{equineBusinessGrantsInformation(true)}
			</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{equineBusinessGrantsInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default EquineBusinessGrantLandingPage;

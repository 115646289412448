import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'block',
		position: 'relative',
		fontSize: 22,
		userSelect: 'none',
		border: '1px solid #b3bcc1',
		borderTop: 'none'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		minHeight: 70
	},
	rule: {
		marginLeft: 20,
		marginRight: 20,
		color: '#b2b2b2'
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	fieldContainer: {
		paddingLeft: 15,
		paddingRight: 15,
		paddingBottom: 10
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	errorTextDropDown: {
		color: '#db0000',
		fontSize: 15,
		marginTop: 5
	},
	errorTextResizingArea: {
		extend: 'errorTextDropDown',
		marginLeft: 15,
		marginBottom: 10
	},
	formContainer: {
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px',
		padding: '10px 18px'
	},
	basicLink: {
		color: '#006da6',
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	fieldContainerToggle: {
		extend: 'fieldContainer',
		fontSize: 15
	}
}));

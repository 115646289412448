import React from 'react';
import PropTypes from 'prop-types';
import { StyledDivider } from './style';

const Divider = ({ color, ...props }) => (
	<StyledDivider color={color} {...props} />
);

Divider.propTypes = {
	color: PropTypes.string
};

export default Divider;

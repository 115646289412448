import React from 'react';
import { logToServer } from '../../utils/logger';
import ErrorIcon from '../../assets/images/OTT_community_email.jpg';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		try {
			let logString =
				error.toString() + ', ' + errorInfo.componentStack.toString();

			logToServer('', logString, 'component error');
		} catch (error) {
			//not much else we can do...
		}
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<img src={ErrorIcon} />
					<h2>Something went wrong.</h2>
					<h2>Racing Victoria is looking into this problem.</h2>
					{this.state.error && this.state.error.toString()}
					<details style={{ whiteSpace: 'pre-wrap' }}>
						<br />
						{this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;

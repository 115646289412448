export const getHorseProfileEditLookupData = /* GraphQL */ `
	query horseProfileEditLookups($horseCode: Int!, $memberExternalId: String!) {
		getHorseProfile(horseCode: $horseCode) {
			horse {
				name
				ottInformation {
					profilePhoto {
						filename
						key
					}
					uploadedFiles {
						id
						filename
						key
						type
						activeProfilePicture
						uploadedBy {
							firstName
							lastName
							email
						}
					}
					disciplines {
						id
						value
					}
					disciplinesOther
					habits {
						id
						value
					}
					habitsOther
					happyDetails
					hasIllnesses
					hasInjuries
					illnesses {
						id
						value
					}
					illnessesOther
					injuries {
						id
						value
					}
					injuriesOther
					paddockName
					personalityDetails
					purpose {
						id
					}
					purposeOther
					disciplinesVisibility {
						id
						value
					}
					habitsVisibility {
						id
						value
					}
					happyDetailsVisibility {
						id
						value
					}
					illnessesVisibility {
						id
						value
					}
					injuriesVisibility {
						id
						value
					}
					profilePhotoVisibility {
						id
						value
					}
					paddockNameVisibility {
						id
						value
					}
					purposeVisibility {
						id
						value
					}
					personalityDetailsVisibility {
						id
						value
					}
				}
				location {
					address
					addressVisibility {
						id
						value
					}
					auditName {
						id
						value
					}
					postcodeVisibility {
						id
						value
					}
					countryVisibility {
						id
						value
					}
					stateVisibility {
						id
						value
					}
					suburbVisibility {
						id
						value
					}
					stabledDateVisibility {
						id
						value
					}
					country
					isValidated
					postcode
					source {
						id
					}
					sourceOther
					stabledDate
					state
					suburb
					welfareCheckDetails {
						comments
						names
						relationship {
							id
							value
						}
						relationshipOther
						source {
							id
							value
						}
						sourceOther
					}
					fosterCheckDetails {
						assistanceTypes
						assistanceTypeOther
						reasonForEnteringProgram
						reasonForEnteringProgramOther
					}
					emergencyAidCheckDetails {
						assistanceTypes
						assistanceTypeOther
						reasonForEnteringProgram
						reasonForEnteringProgramOther
					}
					auditCheckDetails {
						comments
						names
						relationship {
							id
							value
						}
						relationshipOther
						auditOther
					}
					principalRacingAuthorityDetails {
						principalRacingAuthority
						principalRacingAuthorityOther
						principalRacingAuthorityValue
					}
				}
			}
			program {
				id
			}
			status {
				status {
					id
				}
				effectiveDate
			}
		}
		isClaimedByMember(
			memberExternalId: $memberExternalId
			horseCode: $horseCode
		)
		getOttPrograms(roles: ["ROLE_RV_EQUINE_WELFARE"]) {
			id
			value
			statuses {
				id
				value
				default
			}
		}
		listHorseLocationSources {
			id
			value
		}
		listSpecialtyDisciplines {
			id
			value
		}
		listHabitsAndVices {
			id
			value
		}
		listInjuryDetails {
			id
			value
		}
		listHorsePurposes {
			id
			value
		}
		listShareInfoEuthanisedIllness {
			id
			value
		}
		listHorseWelfareSources {
			id
			value
		}
		listHorseWelfareRelationships {
			id
			value
		}
		listHorseDetailVisibilityOptions {
			id
			value
		}
		listAuditNames {
			id
			value
		}
		listFosterReasonForEnteringProgramSources {
			id
			value
		}
		listPrincipalRacingAuthoritySources {
			id
			value
		}
		listFosterAssistanceTypeSources {
			id
			value
		}
		getHorseProfileRetirement(horseCode: $horseCode) {
			id
			memberId
			horseCode
			retirementCategory
			behaviourOthersRetirement
			conformationalDetailsRetirement
			otherEducationDetails
			otherInjuryDetailsRetirement
			otherIllnessDetailsRetirement
			rehomingHistoryDetails
			otherDetailsRetirement
			vulnerabilityType
			informationSource
			sourceDetail
			sourceDetailOther
			detailsInformationObtained
			adminId
			dateInformationProvided
			otherRelevantDetails
			habits {
				id
				value
			}
			injuries {
				id
				value
			}
			illness {
				id
				value
			}
			education {
				id
				value
			}
			reason {
				id
				value
			}
		}
	}
`;

import React, { useState } from 'react';
import FormDatePicker from '../FormDatePicker';
import MessageBar from '../MessageBar';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
import SaveCancelButton from '../SaveCancelButton';
import SimpleRadioGroup from '../SimpleRadioGroup';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import { formatDate, isOptionOther } from '../../utils/helpers';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';

const RetrainerRehabilitationForm = ({
	title,
	submitForm,
	injuryOptions,
	cancel,
	illnessOptions
}) => {
	const classes = useStyles();

	const [selectedOption, setSelectedOption] = useState(0);
	const [illness, setIllness] = useState(0);
	const [illnessOther, setIllnessOther] = useState('');
	const [injury, setInjury] = useState(0);
	const [injuryOther, setInjuryOther] = useState('');
	const [otherText, setOtherText] = useState('');
	const [error, setError] = useState('');
	const [date, setDate] = useState(null);

	const clearForm = () => {
		setInjury(0);
		setIllness(0);
		setInjuryOther('');
		setIllnessOther('');
		setOtherText('');
		setError('');
	};

	const submitData = () => {
		let data = null;
		if (selectedOption === 1) {
			if (date === null || injury === 0) {
				setError('Fields cannot be empty');
			} else {
				if (isOptionOther(injuryOptions, injury)) {
					data = {
						date: date,
						injury: injury,
						otherInjury: injuryOther,
						rehabReason: 1
					};
				} else {
					data = {
						date: date,
						injury: injury,
						rehabReason: 1
					};
				}
			}
		} else if (selectedOption === 2) {
			if (date === null || illness === 0) {
				setError('Fields cannot be empty');
			} else {
				if (isOptionOther(illnessOptions, illness)) {
					data = {
						date: date,
						injury: illness,
						otherIllness: illnessOther,
						rehabReason: 2
					};
				} else {
					data = {
						date: date,
						illness: illness,
						rehabReason: 2
					};
				}
			}
		} else {
			if (date === null || otherText === '') {
				setError('Fields cannot be empty');
			} else {
				data = {
					date: date,
					other: otherText,
					rehabReason: 3
				};
			}
		}
		if (data != null) {
			submitForm(data);
		}
	};

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDate(formattedDate);
				setError('');
			} else {
				setDate(null);
			}
		}
	};

	const updateSelectionChange = (e) => {
		let radioInt = parseInt(e.target.value);
		setSelectedOption(radioInt);
	};

	const renderDropDownSelection = () => {
		switch (selectedOption) {
			case 1:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Injury Details*</div>
							<FormDropDown
								id={HorseManagementKeys.INJURY_DETAILS}
								showBlank
								selectedId={injury}
								onChange={(e) => {
									setInjuryOther('');
									setInjury(e.target.value);
								}}
								items={injuryOptions}
							/>
						</Col>
						{injuryOptions.filter(
							(i) => i.id === parseInt(injury) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.INJURY_OTHER_DETAILS}
									value={injuryOther}
									onChange={(e) => setInjuryOther(e.target.value)}
								/>
							</Col>
						)}
					</Row>
				);
			case 2:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Illness Details*</div>
							<FormDropDown
								id={HorseManagementKeys.ILLNESS_DETAILS}
								showBlank
								selectedId={illness}
								onChange={(e) => {
									setIllnessOther('');
									setIllness(e.target.value);
								}}
								items={illnessOptions}
							/>
						</Col>
						{illnessOptions.filter(
							(i) => i.id === parseInt(illness) && i.value === 'Other'
						).length > 0 && (
							<Col lg={4}>
								<div className={classes.fieldTitle}>Other Details</div>
								<FormTextField
									id={HorseManagementKeys.ILLNESS_OTHER_DETAILS}
									value={illnessOther}
									onChange={(e) => setIllnessOther(e.target.value)}
								/>
							</Col>
						)}
					</Row>
				);
			case 3:
				return (
					<Row>
						<Col lg={4}>
							<div className={classes.fieldTitle}>Other Details*</div>
							<FormTextField
								id={HorseManagementKeys.OTHER_DETAILS}
								value={otherText}
								onChange={(e) => setOtherText(e.target.value)}
							/>
						</Col>
					</Row>
				);
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer} style={{}}>
				<div className={classes.formHeading}>{title}</div>
				<MessageBar>
					<div className={classes.topMessage}>
						We need more information for a Retraining Status change. Please fill
						in and save all the fields below.
					</div>
				</MessageBar>
				<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
					Reason for Rehabilitation
				</div>
				<Row style={{ marginTop: 20 }}>
					<Col lg={12}>
						<SimpleRadioGroup
							groupName="sampleType"
							onChange={(e) => {
								clearForm();
								updateSelectionChange(e);
							}}
							verticalDisplay
							items={[
								{
									id: 1,
									label: 'Injury',
									value: 1,
									isSelected: false
								},
								{
									id: 2,
									label: 'Illness',
									value: 2,
									isSelected: false
								},
								{
									id: 3,
									label: 'Other',
									value: 3,
									isSelected: false
								}
							]}
						/>
					</Col>
				</Row>
				<Row>
					<Col>{renderDropDownSelection()}</Col>
				</Row>
				<hr className={classes.divider}></hr>
				<Row>
					{selectedOption != 0 && (
						<Col lg={4}>
							<div className={classes.dateFieldTitle}>Date Commenced*</div>
							<div>
								<FormDatePicker
									id={HorseManagementKeys.DATE_COMMENCED}
									styles={{ width: '100%', color: 'blue' }}
									DateValue={date}
									updateSelection={(value) => updateDate(value)}
								/>
							</div>
							<div className={classes.error}>{error}</div>
						</Col>
					)}
				</Row>
				{selectedOption != 0 && (
					<>
						<hr className={classes.divider}></hr>
						<div className={classes.saveButtonContainer}>
							<SaveCancelButton
								idPositive={CommonKeys.SAVE}
								idNegative={CommonKeys.CANCEL}
								positiveLabel="Save"
								cancel={cancel}
								onClickPositive={submitData}
								negativeLabel="Cancel"
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
export default RetrainerRehabilitationForm;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	profilePageContainer: {
		maxWidth: '1000px !important',
		paddingTop: 15,
		marginBottom: 150
	},
	navigationLinks: {
		margin: '0 auto 20px auto',
		fontSize: 15,
		padding: '15px 5px',
		backgroundColor: '#dcebf3',
		display: 'table',
		width: 'auto',
		borderRadius: 50
	},
	siteHeaderMenuItem: {
		padding: 10,
		color: '#006da6',
		cursor: 'pointer',
		borderRadius: 50
	},
	siteHeaderMenuItemSelected: {
		color: 'white',
		backgroundColor: '#006da6',
		fontWeight: 600
	}
}));

import React, { Fragment, useState } from 'react';
import { Row } from 'react-grid-system';
import { useForm } from 'react-hook-form-v7';
import { Box, TablePagination } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from '../../hooks/useToast';
import SEO from '../seo';
import PrimaryButton from '../PrimaryButton';
import { findThoroughbreds } from './queries';
import LoaderSpinner from '../LoaderSpinner';
import AppSyncService from '../../graphql/AppSyncService';
import { countHorseClaims } from '../../graphql/custom.queries';
import { APPROVED } from '../../utils/constants';
import {
	StyledContainer,
	StyledFormFieldContainer,
	StyledHeaderBanner,
	StyledMessageBar,
	StyledPaginationContainer,
	StyledSearchButtonContainer,
	StyledTable,
	LoadingSpinnerContainer,
	StyledInitialContainer,
	ThemeFont
} from './style';
// import theme from '../../config/theme';
import classNames from 'classnames';

const MAX_PAGE_SIZE = 100;
let unverifiedCountInitial = 0;
let searchInitialized = false;

const SearchMultipleHorses = () => {
	const [horses, setHorses] = useState([]);
	const [unverifiedHorses, setUnverifiedHorses] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [paginationIndex, setPaginationIndex] = useState(0);
	const [recordsCount, setRecordsCount] = useState(0);
	// const [maxPageSize, setMaxPageSize] = useState(MAX_PAGE_SIZE);
	const [isNoResultFound, setIsNoResultFound] = useState(true);

	const { register, handleSubmit, getValues } = useForm({
		defaultValues: {
			thoroughbredNames: '',
			microChipNumbers: ''
		},
		mode: 'onSubmit'
	});
	const { addToast } = useToast();

	const getHorseSearchResult = async (paginationIndex) => {
		try {
			const formValues = getValues();
			setIsLoading(true);
			const input = {
				isBulkSearch: true,
				paginationSize: MAX_PAGE_SIZE,
				paginationIndex
			};
			// todo: sort names alphabetically
			const names =
				formValues.thoroughbredNames
					?.trim()
					?.split(',')
					.map((val) => val.trim())
					.filter((val) => val !== '') || [];
			if (names.length > 0) {
				input.names = names;
			}
			const microChipNumbers =
				formValues.microChipNumbers
					?.trim()
					?.split(',')
					.map((val) => val.trim())
					.filter((val) => val !== '') || [];
			if (microChipNumbers.length > 0) {
				input.microChipNumbers = microChipNumbers;
			}
			unverifiedCountInitial =
				unverifiedCountInitial != 0
					? unverifiedCountInitial
					: names.length + microChipNumbers.length;
			input.paginationSize = MAX_PAGE_SIZE + unverifiedCountInitial;
			const { data: findThoroughbredsData } = await AppSyncService.execute(
				findThoroughbreds,
				{
					input
				}
			);

			const horsesData = [];
			//let unVerifiedCount = 0;

			findThoroughbredsData.findThoroughbreds.map((horse) => {
				const result = { ...horse, id: horse.horseCode, isVerified: true };
				// check if the horse exists with that name
				if (
					names.some((val) => val.toLowerCase() === horse.name.toLowerCase())
				) {
					// if it does, mark it as bold with its text
					result.searchedNameField = 'name';
				}
				// check if the horse exists with that microchip
				if (microChipNumbers.some((val) => val === horse.microChipNumber)) {
					// if it does, mark it as bold with its text
					result.searchedChipField = 'microChipNumber';
				}
				// otherwise just push that record as it is with isVerified to true
				horsesData.push(result);
			});

			const unverifiedHorsesTemp =
				paginationIndex === 0 ? [] : [...unverifiedHorses];

			names.forEach((name) => {
				const alreadyExists =
					findThoroughbredsData.findThoroughbreds.find(
						(val) => val.name.toLowerCase() === name.toLowerCase()
					) ||
					unverifiedHorsesTemp.find(
						(val) => val.name.toLowerCase() === name.toLowerCase()
					);
				if (alreadyExists) return;
				unverifiedHorsesTemp.push({
					id: uuidv4(),
					name,
					microChipNumber: null,
					isClaimed: false,
					isVerified: false,
					searchedNameField: 'name'
				});
			});

			microChipNumbers.forEach((microChipNumber) => {
				const alreadyExists =
					findThoroughbredsData.findThoroughbreds.find(
						(horse) => horse.microChipNumber === microChipNumber
					) ||
					unverifiedHorsesTemp.find(
						(val) => val.microChipNumber === microChipNumber
					);
				if (alreadyExists) return;
				unverifiedHorsesTemp.push({
					id: uuidv4(),
					name: null,
					microChipNumber: microChipNumber,
					isClaimed: false,
					isVerified: false,
					searchedChipField: 'microChipNumber'
				});
			});

			setUnverifiedHorses(unverifiedHorsesTemp);

			if (findThoroughbredsData.findThoroughbreds.length < MAX_PAGE_SIZE) {
				unverifiedHorsesTemp.sort((currentHorse, previousHorse) => {
					return currentHorse.name?.toUpperCase() >
						previousHorse.name?.toUpperCase()
						? 1
						: previousHorse.name?.toUpperCase() >
						  currentHorse.name?.toUpperCase()
						? -1
						: 0;
				});
				unverifiedHorsesTemp.forEach((val) => horsesData.push(val));
			}

			if (unverifiedCountInitial === 0) {
				unverifiedCountInitial = unverifiedHorsesTemp.length;
			}

			// setMaxPageSize(unverifiedCountInitial + MAX_PAGE_SIZE);

			for await (const horse of horsesData) {
				if (horse.isVerified) {
					const { data: countHorseClaimsData } = await AppSyncService.execute(
						countHorseClaims,
						{
							filterStatuses: [APPROVED],
							horseCode: horse.horseCode
						}
					);
					horse.isClaimed = countHorseClaimsData?.countHorseClaims > 0;
				}
			}

			setIsLoading(false);
			setHorses(horsesData);
			searchInitialized = true;
			const count =
				findThoroughbredsData.findThoroughbreds.length +
				unverifiedHorsesTemp.length;
			if (count !== null && count !== undefined) {
				setRecordsCount(count);
				console.log(count);
				console.log(findThoroughbredsData.findThoroughbreds.length);
				console.log(unverifiedHorsesTemp.length);
			}
		} catch (error) {
			addToast({ Message: 'Failed to Search Horses', IsSuccess: false });
			setIsLoading(false);
		}
	};

	const handleSubmitSuccess = async () => {
		const { microChipNumbers, thoroughbredNames } = getValues();
		const evalResult =
			microChipNumbers.length !== 0 || thoroughbredNames.length !== 0;
		setIsNoResultFound(evalResult);
		if (!evalResult) {
			setHorses([]);
			return;
		}
		setPaginationIndex(0);
		setUnverifiedHorses([]);
		getHorseSearchResult(0);
		unverifiedCountInitial = 0;
	};

	const handleSubmitError = () => {};

	const handlePageChange = (event, newPage) => {
		setPaginationIndex(newPage);
		getHorseSearchResult(newPage);
	};

	return (
		<Fragment>
			<SEO title="Search Multiple Thoroughbreds" />
			<StyledContainer>
				<StyledHeaderBanner title="Search Multiple Thoroughbreds" />
				<StyledMessageBar>
					<p>
						All individual values (Thoroughbred name {'&'} microchip) need to be
						separated by a comma before clicking Search All.
					</p>
					<p>
						Entered Thoroughbred name and microchip number must be exact, so
						please ensure you check your entries carefully.
					</p>
				</StyledMessageBar>
				<form onSubmit={handleSubmit(handleSubmitSuccess, handleSubmitError)}>
					<Row>
						<StyledFormFieldContainer lg={6}>
							<label htmlFor="thoroughbredNames">Thoroughbred Name</label>
							<textarea {...register('thoroughbredNames', {})} />
						</StyledFormFieldContainer>
						<StyledFormFieldContainer lg={6}>
							<label htmlFor="microChipNumbers">Microchip Number</label>
							<textarea {...register('microChipNumbers', {})} />
						</StyledFormFieldContainer>
						<StyledSearchButtonContainer lg={3}>
							<PrimaryButton disabled={isLoading}>Search All</PrimaryButton>
						</StyledSearchButtonContainer>
					</Row>
				</form>
				{horses.length !== 0 ? (
					<div>
						<StyledTable className="mb-4">
							<thead>
								<tr>
									<th>Thoroughbred Name</th>
									<th>Microchip Number</th>
									<th>Verified</th>
									<th>Claimed</th>
								</tr>
							</thead>
							<tbody>
								{!isLoading &&
									horses.map((horse) => (
										<tr key={horse.id}>
											<td
												className={classNames({
													'font-bold': horse.searchedNameField === 'name'
												})}
											>
												{horse.name ? (
													horse.name
												) : (
													<ThemeFont>No Thoroughbred name found</ThemeFont>
												)}
											</td>
											<td
												className={classNames({
													'font-bold':
														horse.searchedChipField === 'microChipNumber'
												})}
											>
												{horse.microChipNumber ? (
													horse.microChipNumber
												) : (
													<ThemeFont>No microchip number found</ThemeFont>
												)}
											</td>
											<td>{horse.isVerified ? 'Yes' : 'No'}</td>
											<td>{horse.isClaimed ? 'Yes' : 'No'}</td>
										</tr>
									))}
							</tbody>
						</StyledTable>
						{isLoading ? (
							<LoadingSpinnerContainer>
								<LoaderSpinner status={isLoading} />
							</LoadingSpinnerContainer>
						) : null}
						<StyledPaginationContainer>
							<Box m="auto">
								<TablePagination
									rowsPerPageOptions={[]}
									rowsPerPage={recordsCount}
									count={recordsCount}
									page={paginationIndex}
									onPageChange={handlePageChange}
								/>
							</Box>
						</StyledPaginationContainer>
					</div>
				) : null}
				{isLoading && !searchInitialized ? (
					<LoadingSpinnerContainer>
						<LoaderSpinner status={isLoading} />
					</LoadingSpinnerContainer>
				) : !isNoResultFound || (!isLoading && horses.length === 0) ? (
					<StyledInitialContainer>
						<span>No Results Found</span>
					</StyledInitialContainer>
				) : (
					''
				)}
			</StyledContainer>
		</Fragment>
	);
};

export default SearchMultipleHorses;

import React, { useEffect, useState } from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Col, Row } from 'react-grid-system';
import HeaderBanner from '../HeaderBanner';
import TextInputField from '../FormComponents/TextInputField';
import { validation } from './validation';
import PrimaryButton from '../PrimaryButton';
import AppSyncService from '../../graphql/AppSyncService';
import { getCurrentCompetition } from '../CompetitionPage/queries';
import useStyles from './styles';
import DateInputField from '../FormComponents/DateInputField';
import { formatDate, parseUTCToLocal } from '../../utils/helpers';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import { addCompetition } from './queries';
import { useToast } from '../../hooks/useToast';
import { navigate } from 'gatsby';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const validationFields = ['competitionName', 'endDate', 'notificationText'];

const PAGE_ID = 'add-competition';

const AddCompetitionPage = () => {
	const classes = useStyles();
	const [errors, setErrors] = useState({});
	const [validationOn, setValidationOn] = useState(false);
	const [current, setCurrent] = useState(null);
	const { addToast } = useToast();
	const [values, setValues] = useState({
		competitionName: {
			value: '',
			isRequired: true
		},
		endDate: {
			value: null,
			isRequired: true,
			errorMessage: 'Competition end date is required'
		},
		notificationText: {
			value: '',
			isRequired: true
		}
	});
	const handleChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};
	const handleDateUpdate = (value, name) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let submitInput = {
				name: values.competitionName.value,
				endDate: values.endDate.value,
				notificationText: values.notificationText.value
			};
			AppSyncService.execute(addCompetition, { input: submitInput })
				.then(() => {
					addToast({
						Message: 'Competition Successfully Saved.',
						IsSuccess: true
					});
					navigate(DASHBOARD_ROUTE);
				})
				.catch(() => {
					addToast({
						Message: 'An error occured while saving the competition.',
						IsSuccess: true
					});
				});
		}
	};

	useEffect(() => {
		AppSyncService.execute(getCurrentCompetition).then((data) => {
			if (data?.data?.getCompetition[0]) {
				setCurrent(data?.data?.getCompetition[0]);
			}
		});
	}, []);

	return (
		<PageContainer>
			<SEO title="Add Competition Page" />
			<HeaderBanner title="Add Competition Page" />
			<Row className={classes.rowStyle}>
				<Col xs={12}>
					<b>
						Please ensure there are no other active competitions before adding a
						new one.
					</b>
				</Col>
			</Row>
			{current && (
				<Row className={classes.rowStyle}>
					<b className={classes.contestDetails}>
						There is currently the following competition that is still ongoing:
					</b>
					<Col xs={6}>
						<b>Name: {current?.name}</b>
					</Col>
					<Col xs={6}>
						<b>
							End date:{' '}
							{parseUTCToLocal(current?.endDate).format('DD/MM/YYYY h:mmA')}
						</b>
					</Col>
				</Row>
			)}
			<Row className={classes.rowStyle}>
				<Col xs={12}>
					<TextInputField
						id={`${PAGE_ID}-competition-name-input`}
						label="Competition Name"
						value={values.competitionName.value}
						onChange={(e) => {
							handleChange(e, 'competitionName');
						}}
						validationError={errors.competitionName}
						isRequired
						fullWidth
					/>
				</Col>
			</Row>
			<Row className={classes.rowStyle}>
				<Col xs={12}>
					<DateInputField
						label="Competition End Date"
						value={values.endDate.value}
						isRequired
						fullWidth
						validationError={errors.endDate ? true : false}
						onChange={(value) => handleDateUpdate(value, 'endDate')}
						noMaxDate
						showTimeSelect
						showTimeInput
					/>
					{errors.endDate && (
						<div className={classes.errorText}>
							<ValidationErrorText errorText={errors.endDate} />
						</div>
					)}
				</Col>
			</Row>
			<Row className={classes.rowStyle}>
				<Col xs={12}>
					<TextInputField
						id={`${PAGE_ID}-notification-text`}
						label="Competition Notification Text"
						value={values.notificationText.value}
						onChange={(e) => {
							handleChange(e, 'notificationText');
						}}
						validationError={errors.notificationText}
						isRequired
						fullWidth
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<PrimaryButton
						onClick={handleSubmit}
						id={`${PAGE_ID}-submit`}
						style={{
							float: 'right',
							maxWidth: '150px',
							marginRight: 45
						}}
					>
						Submit
					</PrimaryButton>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default AddCompetitionPage;

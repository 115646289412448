import { createUseStyles } from 'react-jss';
import MarkerImage from '../../assets/images/horse-pin-blue-icon.png';
import MarkerSelectedImage from '../../assets/images/horse-pin-pink-icon.png';
export default createUseStyles({
	mapMarker: {
		position: 'absolute',
		top: -22.5,
		left: -22.5,
		height: 45,
		width: 45,
		backgroundImage: `url(${MarkerImage})`
	},
	selected: {
		backgroundImage: `url(${MarkerSelectedImage})`
	},
	markerDescriptionPopup: {
		position: 'absolute',
		top: -22.5,
		left: 30,
		background: '#fff',
		minWidth: 150,
		padding: 10,
		borderRadius: 3,
		zIndex: 100
	},
	markerDescriptionContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	businessName: {
		fontSize: 14,
		color: '#464749'
	},
	location: {
		marginTop: 5,
		fontSize: 13,
		color: 'grey'
	}
});

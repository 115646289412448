import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import EventPopover from '../EventPopover';
import useStyles from './styles';

const EventsUpcoming = ({ upcomingEvents }) => {
	const classes = useStyles();
	return (
		<Container>
			<>
				{upcomingEvents && (
					<>
						<div className={classes.topBannerText}>
							<div>Next Up</div>
						</div>
						<Row>
							<Col xs={12} lg={3}>
								{upcomingEvents.length > 0 && (
									<EventPopover event={upcomingEvents[0]} setHeight />
								)}
							</Col>
							<Col xs={12} lg={3}>
								{upcomingEvents.length > 1 && (
									<EventPopover event={upcomingEvents[1]} setHeight />
								)}
							</Col>
							<Col xs={12} lg={3}>
								{upcomingEvents.length > 2 && (
									<EventPopover event={upcomingEvents[2]} setHeight />
								)}
							</Col>
							<Col xs={12} lg={3}>
								{upcomingEvents.length > 3 && (
									<EventPopover event={upcomingEvents[3]} setHeight />
								)}
							</Col>
						</Row>
					</>
				)}
			</>
		</Container>
	);
};
export default EventsUpcoming;

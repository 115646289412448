import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

export const ColumnContent = ({
	isAckFormsReview = false,
	children,
	...rest
}) => {
	const classes = useStyles();
	return (
		<div
			className={
				isAckFormsReview
					? classes.resultColumnAckApplication
					: classes.resultColumn
			}
			{...rest}
		>
			{children}
		</div>
	);
};

export const ResultContainer = ({ children, id }) => {
	const classes = useStyles();
	return (
		<div id={id} className={classes.resultContainer}>
			{children}
		</div>
	);
};

export const ResultRow = ({ children, id }) => {
	const classes = useStyles();
	return (
		<div id={id} className={classes.singleResultContainer}>
			{children}
		</div>
	);
};

export const ResultHeading = ({ children, id }) => {
	const classes = useStyles();
	return (
		<div id={id} className={classes.resultHeading}>
			{children}
		</div>
	);
};

export const ReviewButton = ({ onClickHandler, isAckFormsReview = false }) => {
	const classes = useStyles();
	return (
		<ColumnContent isAckFormsReview={isAckFormsReview}>
			<div className={classes.button}>
				<div className={classes.actionButton} onClick={onClickHandler}>
					Review
				</div>
			</div>
		</ColumnContent>
	);
};

export const ResponsiveText = ({ children, bolder = false }) => {
	const classes = useStyles();
	return (
		<span
			className={classnames({
				[classes.longText]: true,
				[classes.bolder]: bolder
			})}
		>
			{children}
		</span>
	);
};

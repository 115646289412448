import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	textArea: {
		boxSizing: 'border-box',
		resize: 'none',
		overflow: 'hidden',
		width: '100%',
		padding: 8,
		border: '1px solid #b3bcc1',
		margin: 0,
		fontSize: 15,
		borderRadius: 4,
		fontFamily: 'Open-sans !important',
		outline: 'none'
	},
	charLengthInfo: {
		color: '#909195',
		float: 'right',
		fontSize: 12
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749',
		display: 'inline-block'
	},
	divider: {
		background: '#edf5f9',
		height: 2,
		border: 'none'
	},
	notesContainer: {
		paddingTop: 20
	}
}));

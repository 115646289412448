/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getInterests = /* GraphQL */ `
	query GetInterests($id: Int!) {
		getInterests(id: $id) {
			description
			id
			title
		}
	}
`;
export const getInterestsByTile = /* GraphQL */ `
	query GetInterestsByTile($title: String!) {
		getInterestsByTile(title: $title) {
			description
			id
			title
		}
	}
`;
export const getMember = /* GraphQL */ `
	query GetMember($externalId: String!) {
		getMember(externalId: $externalId) {
			email
			externalId
			firstName
			id
			interests {
				description
				id
				title
			}
			lastName
			postcode
			primaryMembershipType {
				description
				id
				name
			}
			secondaryMemebershipTypes {
				description
				id
				name
			}
			state
			createdAt
			updatedAt
		}
	}
`;
export const getPrimaryMemebership = /* GraphQL */ `
	query GetPrimaryMemebership($id: Int!) {
		getPrimaryMemebership(id: $id) {
			description
			id
			name
			secondaryMemeberships {
				description
				id
				name
			}
		}
	}
`;
export const getSecondaryMemebershipType = /* GraphQL */ `
	query GetSecondaryMemebershipType($id: Int!) {
		getSecondaryMemebershipType(id: $id) {
			description
			id
			name
			primaryMemebershipType {
				description
				id
				name
			}
		}
	}
`;
export const listInterestss = /* GraphQL */ `
	query ListInterestss {
		listInterestss {
			description
			id
			title
		}
	}
`;
export const listPrimaryMemeberships = /* GraphQL */ `
	query ListPrimaryMemeberships {
		listPrimaryMemeberships {
			description
			id
			name
			secondaryMemeberships {
				description
				id
				name
			}
		}
	}
`;
export const getMemberType = /* GraphQL */ `
	query GetMemberType($id: ID!) {
		getMemberType(id: $id) {
			email
			externalId
			firstName
			id
			interests {
				description
				id
				title
			}
			lastName
			postcode
			primaryMembershipType {
				description
				id
				name
			}
			secondaryMemebershipTypes {
				description
				id
				name
			}
			state
			createdAt
			updatedAt
		}
	}
`;
export const listMemberTypes = /* GraphQL */ `
	query ListMemberTypes(
		$filter: ModelMemberTypeFilterInput
		$limit: Int
		$nextToken: String
	) {
		listMemberTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
			items {
				email
				externalId
				firstName
				id
				lastName
				postcode
				state
				createdAt
				updatedAt
			}
			nextToken
		}
	}
`;

export const filterAcknowledgedRetrainers = /* GraphQL */ `
	query filterAcknowledgedRetrainerForms(
		$filterStatuses: [FormStatusEnum]
		$orderBy: String = "ar.created"
		$order: OrderByEnum = DESC
		$paginationIndex: Int
		$paginationSize: Int
	) {
		getAcknowledgedReTrainerForms(
			status: $filterStatuses
			orderBy: $orderBy
			order: $order
			paginationSize: $paginationSize
			paginationIndex: $paginationIndex
		) {
			id
			member {
				firstName
				lastName
				id
			}
			status
		}
	}
`;

import React, { useState, useEffect } from 'react';
import { Container, ScreenClassRender } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { getFullCircleByIdQuery } from '../../graphql/custom.queries';

import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import FullCircleMemberProfileView from '../FullCircleMemberProfileView';
import AdminApprovalOnly from '../AdminApprovalOnly';
import SNSAdmin from '../SNSAdmin';

const FullCircleReview = () => {
	const classes = useStyles();
	let { id } = useParams();
	const [
		fullCircleMemberProfileData,
		setFullCircleMemberProfileData
	] = useState(null);
	const [horseProfileData, setHorseProfileData] = useState(null);
	const [reviewNotesData, setReviewNotesData] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [relationshipType, setRelationshipType] = useState('');
	const [typeOfAssistance, setTypeOfAssistance] = useState(null);
	const [organisation, setOrganisation] = useState('');
	const [reviewStatus, setReviewStatus] = useState('');
	useEffect(() => {
		const input = {
			id: parseInt(id)
		};
		setIsLoading(true);
		AppSyncService.execute(getFullCircleByIdQuery, input).then((data) => {
			if (data.data != null && data.data.getFullCircleById != null) {
				setReviewStatus(data.data.getFullCircleById.status);
				setRelationshipType(data.data.getFullCircleById.relationship.value);
				setTypeOfAssistance(
					data.data.getFullCircleById.typeOfAssistance
						?.map((item) => item.value)
						.join(', ')
				);
				if (data.data.getFullCircleById.reviewNotes) {
					setReviewNotesData(data.data.getFullCircleById.reviewNotes);
				}
				setFullCircleMemberProfileData(data.data.getFullCircleById.member);
				setHorseProfileData(data.data.getFullCircleById.horse);
				setOrganisation(data.data.getFullCircleById.organisation);
				setIsLoading(false);
			}
		});
	}, []);

	const updateIsLoading = (value) => {
		setIsLoading(value);
	};

	const component = (isMobile) => (
		<Container className={classes.pageContainer}>
			<SEO title="Full Circle Review" isMobile={isMobile} />
			<HeaderBanner
				title={`Full Circle - ${horseProfileData?.name}`}
				top
				type="primary"
				rightTitle={`Status: ${reviewStatus}`}
				styles={{
					borderRadius: '5px 5px 5px 5px',
					height: isMobile ? 'auto' : '',
					display: 'flow-root'
				}}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{fullCircleMemberProfileData != null && (
					<FullCircleMemberProfileView
						fullCircleMemberProfileData={fullCircleMemberProfileData}
						relationshipType={relationshipType}
						typeOfAssistance={typeOfAssistance}
						organisation={organisation}
					/>
				)}
				{horseProfileData && (
					<>
						<SNSAdmin horseProfileData={horseProfileData} />
					</>
				)}
				{reviewStatus != '' && (
					<AdminApprovalOnly
						reviewNotesData={reviewNotesData}
						id={id}
						reviewStatus={reviewStatus}
						reviewType="FullCircle"
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
					/>
				)}
			</div>
		</Container>
	);

	const page = (screenClass) => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClass) ? true : false;
		return component(isMobile);
	};

	return <ScreenClassRender render={page} />;
};
export default FullCircleReview;

import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import { navigate } from '@reach/router';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';

const AcknowledgedRetrainerInfo = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [richText, setRichText] = useState(null);

	const renderCollectionOfVideos = (isMobile) => {
		const UniqueVideoByUrl = [
			...new Map(videoCollection.map((item) => [item['src'], item])).values()
		];
		return UniqueVideoByUrl.map((video, index) => {
			return (
				<div
					key={index}
					className={
						isMobile ? classes.mobileVideoContainer : classes.videoContainer
					}
				>
					<div className={classes.videoTitle}>{video.title}</div>
					<FullArticleEmbeddedAsset
						videoStyle={{ borderRadius: 5 }}
						imageStyle={{ borderRadius: 5 }}
						contentType={video.type}
						contentSrc={video.src}
					/>
				</div>
			);
		});
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				let video = [];
				video = {
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				};
				videoCollection.push(video);
			}
		}
	};

	useEffect(() => {
		setRichText(ackRetrainerInfo.allContentfulLandingPageContent.nodes[0].body);
	}, []);

	const ackRetrainerInfo = useStaticQuery(
		graphql`
			query ackRetrainerInfo {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Acknowledged Retrainer Program Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Acknowledged Retrainer Program Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		// Set data to state here?
		const ackRetrainerProgramCards =
			ackRetrainerInfo.allContentfulInfoCards.nodes;
		let ackRetrainerInfoCards = [];
		if (ackRetrainerProgramCards.length > 0) {
			ackRetrainerProgramCards.map((d) => {
				ackRetrainerInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			ackRetrainerInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(ackRetrainerInfoCards);
		}
	}, []);

	const ackRetrainerInformation = (isMobile) => {
		return (
			<>
				<SEO title="Acknowledged Retrainers" />
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="Acknowledged Retrainers"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<Row>
					<Col lg={8}>{richText && renderRichText(richText, options)}</Col>
				</Row>
				<Row>
					<Col>
						<div className={classes.multipleVideos}>
							{renderCollectionOfVideos(isMobile)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col style={{ marginTop: 20 }}>
						<MessageBar>
							<div
								className={classes.messageBar}
								style={isMobile ? { display: 'block' } : {}}
							>
								<div
									className={classes.claimText}
									style={
										isMobile
											? { fontSize: 13, marginBottom: 10, marginTop: 0 }
											: {}
									}
								>
									<b>Acknowledged Retrainer of the Year Award Honour Roll</b>
								</div>
								<PrimaryButton
									onClick={() => navigate('/retrainer-of-the-year')}
									style={!isMobile ? { marginTop: 5, width: 175 } : {}}
									id="acknowledgeRetrainerLandingPage-retrainerOfTheYear"
								>
									Find out more
								</PrimaryButton>
							</div>
						</MessageBar>
					</Col>
				</Row>
				<Row>
					<Col style={{ marginTop: 20 }}>
						<MessageBar>
							<div
								className={classes.messageBar}
								style={isMobile ? { display: 'block' } : {}}
							>
								<div
									className={classes.claimText}
									style={
										isMobile
											? { fontSize: 13, marginBottom: 10, marginTop: 0 }
											: {}
									}
								>
									<b>Interested in becoming an Acknowledged Retrainer?</b>
								</div>
								<PrimaryButton
									onClick={() => navigate('/acknowledged-retrainers-program')}
									style={!isMobile ? { marginTop: 5, width: 175 } : {}}
									id="acknowledgeRetrainerProgramLandingPageLink"
								>
									Find out more
								</PrimaryButton>
							</div>
						</MessageBar>
					</Col>
				</Row>
			</>
		);
	};

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<div className={classes.mobileTopContainer}>
				{ackRetrainerInformation(true)}
			</div>
		</div>
	);

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{ackRetrainerInformation(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default AcknowledgedRetrainerInfo;

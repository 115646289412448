import React, {
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveBusinessContactDetails,
	getDisplayOptionsReferenceData,
	getMemberProfile
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import MessageBar from '../MessageBar';
import { INVALID_EMAIL_MESSAGE } from '../../utils/constants';

// form validation rules
const schema = yup.object().shape({
	firstName: yup
		.string() //First name can have special characters
		.required('First Name is required')
		.typeError('First Name is required'),
	lastName: yup
		.string() //Last name can have special characters
		.required('Last Name is required')
		.typeError('Last Name is required'),
	email: yup
		.string()
		.required('Email is required')
		.email(INVALID_EMAIL_MESSAGE)
		.typeError('Email is required'),
	phone: yup
		.string()
		.required('Phone number is required')
		.matches(/^\+\d*$|^\d*$/, 'Please enter valid Phone number')
		.typeError('Phone number is required')
});

const BusinessContactDetails = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			isLoadedBusinessProfiles,
			updateIsLoading,
			setIsSubmitted,
			businessProfileType,
			onSaveBusinessProfileData,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();
		const { handleSubmit, trigger, errors, control, setValue } = useForm({
			mode: 'onSubmit',
			resolver: yupResolver(schema)
		});

		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');
		const [email, setEmail] = useState('');
		const [phone, setPhone] = useState('');
		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [privateOnlyErrorMessage, setPrivateOnlyErrorMessage] = useState(
			null
		);
		const [referenceDisplayOptions, setReferenceDisplayOptions] = useState([]);
		const [
			selectedFirstNameDisplayOption,
			setSelectedFirstNameDisplayOption
		] = useState(1);
		const [
			selectedLastNameDisplayOption,
			setSelectedLastNameDisplayOption
		] = useState(1);
		const [
			selectedEmailDisplayOption,
			setSelectedEmailDisplayOption
		] = useState(1);
		const [
			selectedPhoneDisplayOption,
			setSelectedPhoneDisplayOption
		] = useState(1);
		const RACING_VICTORIA_ONLY_PRIVATE = 2;

		useEffect(() => {
			AppSyncService.execute(getDisplayOptionsReferenceData).then((result) => {
				if (result.data) {
					setReferenceDisplayOptions(result.data.listDisplayOptions);
				}
			});
		}, []);

		useEffect(() => {
			setFirstName(businessProfileData ? businessProfileData.firstName : '');
			setValue(
				'firstName',
				businessProfileData ? businessProfileData.firstName : ''
			);
			setLastName(businessProfileData ? businessProfileData.lastName : '');
			setValue(
				'lastName',
				businessProfileData ? businessProfileData.lastName : ''
			);
			setEmail(businessProfileData ? businessProfileData.email : '');
			setValue('email', businessProfileData ? businessProfileData.email : '');
			setPhone(businessProfileData ? businessProfileData.businessPhone : '');
			setValue(
				'phone',
				businessProfileData ? businessProfileData.businessPhone : ''
			);
			setSelectedFirstNameDisplayOption(
				businessProfileData ? businessProfileData.firstNameDisplayOption?.id : 1
			);
			setValue(
				'firstNameDisplayOption',
				businessProfileData
					? businessProfileData.firstNameDisplayOption?.id
					: ''
			);
			setSelectedLastNameDisplayOption(
				businessProfileData ? businessProfileData.lastNameDisplayOption?.id : 1
			);
			setValue(
				'lastNameDisplayOption',
				businessProfileData ? businessProfileData.lastNameDisplayOption?.id : ''
			);
			setSelectedEmailDisplayOption(
				businessProfileData ? businessProfileData.emailDisplayOption?.id : 1
			);
			setValue(
				'emailDisplayOption?',
				businessProfileData ? businessProfileData.emailDisplayOption?.id : ''
			);
			setSelectedPhoneDisplayOption(
				businessProfileData
					? businessProfileData.businessPhoneDisplayOption?.id
					: 1
			);
			setValue(
				'phoneDisplayOption',
				businessProfileData
					? businessProfileData.businessPhoneDisplayOption?.id
					: ''
			);

			if (currentUser !== null) {
				const externalId = { externalId: currentUser?.UserId };
				AppSyncService.execute(getMemberProfile, externalId).then((data) => {
					if (data.data != null) {
						if (
							!isLoadedBusinessProfiles ||
							(isLoadedBusinessProfiles && !businessProfileData?.firstName)
						) {
							setFirstName(data.data.getMember?.firstName);
							setValue('firstName', data.data.getMember?.firstName);
						}
						if (
							!isLoadedBusinessProfiles ||
							(isLoadedBusinessProfiles && !businessProfileData?.lastName)
						) {
							setLastName(data.data.getMember?.lastName);
							setValue('lastName', data.data.getMember?.lastName);
						}
						if (
							!isLoadedBusinessProfiles ||
							(isLoadedBusinessProfiles && !businessProfileData?.businessPhone)
						) {
							setPhone(data.data.getMember?.businessPhone);
							setValue('phone', data.data.getMember?.businessPhone);
						}
					}
				});
			}

			updateIsLoading(false);
			setIsFormDisabled(true);
		}, [businessProfileData]);

		useImperativeHandle(ref, () => ({
			async triggerValidation() {
				await trigger();
			}
		}));

		const onSubmit = async () => {
			validateFields();
			setBackendErrorMessage(null);
			try {
				if (firstName.trim().length == 0) {
					throw 'First name is required';
				}
				if (lastName.trim().length == 0) {
					throw 'Last name is required';
				}
				// Both Business Email and Business phone number cannot be private
				if (
					selectedEmailDisplayOption == RACING_VICTORIA_ONLY_PRIVATE &&
					selectedPhoneDisplayOption == RACING_VICTORIA_ONLY_PRIVATE &&
					selectedFirstNameDisplayOption == RACING_VICTORIA_ONLY_PRIVATE &&
					selectedLastNameDisplayOption == RACING_VICTORIA_ONLY_PRIVATE
				) {
					setPrivateOnlyErrorMessage(
						'At least one contact method must be visible to the public'
					);
					return;
				}
				if (
					selectedEmailDisplayOption == RACING_VICTORIA_ONLY_PRIVATE &&
					selectedPhoneDisplayOption == RACING_VICTORIA_ONLY_PRIVATE
				) {
					setPrivateOnlyErrorMessage(
						'Both business email and business phone details cannot be set to private'
					);
					return;
				}

				const payload = {
					businessPhone: phone,
					businessPhoneDisplayOption: selectedPhoneDisplayOption,
					email: email,
					emailDisplayOption: selectedEmailDisplayOption,
					firstName: firstName.trim(),
					firstNameDisplayOption: selectedFirstNameDisplayOption,
					lastName: lastName.trim(),
					lastNameDisplayOption: selectedLastNameDisplayOption,
					memberExternalId: currentUser?.UserId,
					businessProfileTypeId: businessProfileType
				};

				const state = {
					...payload,
					businessPhoneDisplayOption: {
						id: selectedPhoneDisplayOption,
						value: referenceDisplayOptions.find(
							(reference) => reference.id == selectedPhoneDisplayOption
						)?.value
					},
					emailDisplayOption: {
						id: selectedEmailDisplayOption,
						value: referenceDisplayOptions.find(
							(reference) => reference.id == selectedEmailDisplayOption
						)?.value
					},
					firstNameDisplayOption: {
						id: selectedFirstNameDisplayOption,
						value: referenceDisplayOptions.find(
							(reference) => reference.id == selectedFirstNameDisplayOption
						)?.value
					},
					lastNameDisplayOption: {
						id: selectedLastNameDisplayOption,
						value: referenceDisplayOptions.find(
							(reference) => reference.id == selectedLastNameDisplayOption
						)?.value
					},
					status: 'Draft'
				};

				AppSyncService.execute(saveBusinessContactDetails, payload).then(
					(data) => {
						if (data.data != null) {
							setPrivateOnlyErrorMessage(false);
							addToast({ Message: 'Saved', IsSuccess: true });
							setIsSubmitted(false);
							onSaveBusinessProfileData(state);
						}
					}
				);
				setIsFormDisabled(true);
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving user Business contact data. Please contact support'
					);
				}
			}
		};

		const updateFirstNameDisplayOption = (value) => {
			setSelectedFirstNameDisplayOption(value);
		};
		const updateLastNameDisplayOption = (value) => {
			setSelectedLastNameDisplayOption(value);
		};
		const updateEmailDisplayOption = (value) => {
			setSelectedEmailDisplayOption(value);
		};
		const updatePhoneDisplayOption = (value) => {
			setSelectedPhoneDisplayOption(value);
		};

		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<form
					className={classes.formContainer}
					onSubmit={handleSubmit(onSubmit, () => validateFields())}
				>
					<div className={classes.businessProfile}>
						<Row>
							<Col lg={4} sm={12}>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>First Name*</div>
											<Controller
												control={control}
												name="firstName"
												render={({ onChange }) => (
													<FormTextField
														value={firstName}
														onChange={(e) => {
															onChange(e.target.value);
															setFirstName(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.firstName?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Last Name*</div>
											<Controller
												control={control}
												name="lastName"
												render={({ onChange }) => (
													<FormTextField
														value={lastName}
														onChange={(e) => {
															onChange(e.target.value);
															setLastName(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.lastName?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Business Email*</div>
											<Controller
												control={control}
												name="email"
												render={({ onChange }) => (
													<FormTextField
														value={email}
														onChange={(e) => {
															onChange(e.target.value);
															setEmail(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.email?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Business Phone*</div>
											<Controller
												control={control}
												name="phone"
												render={({ onChange }) => (
													<FormTextField
														value={phone}
														onChange={(e) => {
															onChange(e.target.value);
															setPhone(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.phone?.message}
											</span>
										</div>
									</Col>
								</Row>
							</Col>
							<Col lg={4} sm={12} />
							<Col lg={4} sm={12} className={classes.rightSide}>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see First Name?
											</div>
											<Controller
												control={control}
												name="firstNameDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedFirstNameDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateFirstNameDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.firstNameDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Last Name?
											</div>
											<Controller
												control={control}
												name="lastNameDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedLastNameDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateLastNameDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.lastNameDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Business Email?
											</div>
											<Controller
												control={control}
												name="emailDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedEmailDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updateEmailDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.emailDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Who can see Business Phone?
											</div>
											<Controller
												control={control}
												name="phoneDisplayOption"
												render={({ onChange }) => (
													<FormDropDown
														items={referenceDisplayOptions}
														selectedId={selectedPhoneDisplayOption}
														onChange={(e) => {
															onChange(e.target.value);
															updatePhoneDisplayOption(e.target.value);
															setIsFormDisabled(false);
														}}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.phoneDisplayOption?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col>
										<div className={classes.listfieldContainer}>
											{privateOnlyErrorMessage && (
												<MessageBar>{privateOnlyErrorMessage}</MessageBar>
											)}
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>

					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									style={{ width: '165px' }}
									disabled={isFormDisabled}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
			</>
		);
	}
);
export default BusinessContactDetails;

import React from 'react';
import NotificationIconImage from '../../../../assets/icons/notification_icon.png';
import useStyles from './styles';

const NotificationIcon = () => {
	const classes = useStyles();
	return (
		<img
			className={classes.notificationIcon}
			src={NotificationIconImage}
			alt="Has notifications icon"
		/>
	);
};

export default NotificationIcon;

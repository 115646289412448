import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	additionalOptionsContainer: {
		border: '1px solid rgba(220,235,243)',
		borderRadius: 3,
		width: '100%',
		boxSizing: 'border-box',
		'& > div': {
			borderBottom: '1px solid rgb(220,235,243)'
		},
		'& > div:last-child': {
			borderBottom: 'none'
		}
	},
	additionalOption: {
		display: 'flex',
		minHeight: 50,
		alignItems: 'center',
		padding: 7,
		justifyContent: 'space-between'
	},
	additionalOptionDescription: {
		paddingRight: 5,
		fontSize: 13
	},
	additionalOptionsButtonContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	additionalOptionsButton: {
		color: '#026ea7',
		cursor: 'pointer',
		background: '#dcebf3',
		textAlign: 'center',
		fontWeight: 800,
		border: 'none',
		borderRadius: 3,
		minWidth: 170,
		padding: 10,
		fontFamily: 'Open-sans !important',
		fontSize: 13
	}
}));

export const getDataModel = (data) => {
	let model = { ...data };
	model.registeredOwner = data.iAmRegisteredOwner;
	model.registeredManagingOwner = data.iAmRegisteredManagingOwner;
	model.proofOwner = data.attachedProofOfPurchase;
	model.name = data.horse?.name;
	model.age = data.horse?.age;
	model.colour = data.horse?.colour;
	model.sireName = data.sireName;
	model.damName = data.damName;
	model.height = data.horseHeight;
	model.retirementReason = data.retirementReason?.value;
	model.otherRetirementReason = data.retirementReasonOther;
	model.suburb = data.suburb;
	model.postcode = data.postcode;
	model.memberName = `${data.member?.firstName} ${data.member?.lastName}`;
	model.email = data.member?.email;
	model.phone = data.member?.phone;
	model.relationshipToHorse = data.relationshipToHorse?.value;
	model.otherRelationship = data.relationshipToHorseOther;
	model.lengthOfRelationship = data.lengthOfRelationship?.value;
	model.currentOwners = data.currentOwners;
	model.currentRetrainers = data.currentRetrainers;
	model.isKnownToWindsuck = data.isKnownToWindsuck?.value;
	model.injuries = data.injuries;
	model.injuriesDetails = data.injuriesDetails;
	model.heritableDisorder = data.heritableDisorder;
	model.disease = data.disease;
	model.accidents = data.accidents;
	model.eyeDisease = data.eyeIssues;
	model.lameness = data.lameness;
	model.headShaking = data.headShaking;
	model.vices = data.vices;
	model.abnormality = data.breathingAbnormalities;
	model.surgical = data.surgicalProcedures;
	model.vaccine = data.vaccinationsAdministered;
	model.strangles = data.stranglesTetanusHerpesEquity;
	model.breeding = data.breedingHistory;
	model.temperament = data.temperamentDetails;
	model.disciplines = data.disciplines;
	model.disciplinesOther = data.disciplinesOther;
	model.riddenHistory = data.riddenHistory;
	model.otherDetails = data.otherReleventDetails;
	model.faceOnPhotos = data.faceOnPhotos;
	model.leftSidePhotos = data.leftSidePhotos;
	model.rightSidePhotos = data.rightSidePhotos;
	model.behindFullPhotos = data.behindFullPhotos;
	model.additionalPhotos = data.additionalPhotos;
	model.rehomingAttempt = data.rehomingDeclarationMinOfTwo;
	model.noBehaviouralissues = data.rehomingDeclarationNoKnownBehaviours;
	model.noHealthIssues = data.rehomingDeclarationNoKnownVeterinary;
	model.responsibleOwner = data.programDeclerationResponsibleOwner;
	model.cantBeReHomed = data.programDeclerationContinueAsOwner;
	model.rehomingProgram = data.programDeclerationRehomeIntention;
	model.agreeCompletionOfForm =
		data.programDeclerationNotGuaranteeAcknowledgement;
	model.disabledAssociation = data.programDeclerationRDAVAcknowledgement;
	model.salePrice = data.programDeclerationRacingVicDeterminesSalePrice;
	model.RVagreement = data.programDeclerationEntryIntoReHomed;
	model.privacyStatement = data.programDeclerationReadPrivacyStatement;
	model.concent = data.consentToVeterinaryExamination;
	model.transport = data.isHelpingTransportCosts;
	model.reviewMessages = data.reviewMessages;
	model.reviewNotes = data.reviewNotes;
	model.status = data.status;
	model.id = data.id;

	return model;
};

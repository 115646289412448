import React from 'react';
import Carousel from 'react-material-ui-carousel';

const DynamicCarousel = ({ renderCarouselItems, autoPlay = false }) => {
	return (
		<Carousel
			autoPlay={autoPlay}
			navButtonsAlwaysVisible={true}
			animation="slide"
			fullHeightHover={false}
		>
			{renderCarouselItems()}
		</Carousel>
	);
};

export default DynamicCarousel;

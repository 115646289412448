export const retirementCategoryList = [
	{
		id: 0,
		value: 'A'
	},
	{
		id: 1,
		value: 'B1'
	},
	{
		id: 2,
		value: 'C'
	},
	{
		id: 3,
		value: 'D'
	},
	{
		id: 4,
		value: 'E'
	},
	{
		id: 5,
		value: 'B2'
	}
];

export const vulnerabilityTypeList = [
	{
		id: 0,
		value: ''
	},
	{
		id: 1,
		value: 'Short-term'
	},
	{
		id: 2,
		value: 'Chronic'
	}
];

export const retirementReason = [
	{
		id: 1,
		value: 'Age'
	},
	{
		id: 2,
		value: 'Behaviour'
	},
	{
		id: 3,
		value: 'Confirmational Defect/s'
	},
	{
		id: 4,
		value: 'Education'
	},
	{
		id: 5,
		value: 'Injury'
	},
	{
		id: 6,
		value: 'Illness'
	},
	{
		id: 7,
		value: 'Rehoming history'
	},
	{
		id: 8,
		value: 'Other'
	}
];

export const windSuckBehaviour = {
	value: 11,
	label: 'Wind Sucking'
};

export const retirementEducation = [
	{
		id: 1,
		value: 'Limited ridden education'
	},
	{
		id: 2,
		value: 'Unbroken'
	},
	{
		id: 3,
		value: 'Unraced'
	},
	{
		id: 4,
		value: 'Other'
	}
];

export const NOMINATION_REASONS = {
	CHRONIC_INJURY_OR_ILLNESS: 1,
	DANGEROUS_BEHAVIOUR: 4,
	REHOMING_UNSUCCESSFUL: 5
};

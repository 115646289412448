import { createUseStyles } from 'react-jss';

export default createUseStyles({
	stepOption: {
		color: '#fff',
		border: '#fff 2px solid',
		background: 'none',
		marginRight: 20,
		fontSize: 15,
		borderRadius: 3,
		fontWeight: 700,
		padding: '7px 30px',
		'@media (min-width:992px)': {
			padding: '9px 50px'
		}
	},
	filled: {
		background: '#fff',
		color: '#006da6'
	},
	outcomeButton: {
		background: '#006da6',
		color: '#fff',
		border: '2px solid #006da6'
	},
	disabled: {
		color: '#b2b2b2',
		background: '#d8d8d8',
		border: '1px solid #d8d8d8'
	}
});

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	contestDetails: {
		margin: 20
	},
	rowStyle: {
		marginTop: 20
	}
});

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import UploadPhoto from '../../../UploadPhoto';

const CreditReferencesSection = ({ formData, previewImage }) => {
	return (
		<AdminReviewSection title="Credit References">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Upload Credit References"
						value={
							<UploadPhoto
								data={formData?.creditReferencesUploads}
								getImage={previewImage}
								canEdit={false}
							/>
						}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default CreditReferencesSection;

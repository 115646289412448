import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	fieldTitleMobile: {
		extend: 'fieldTitle',
		fontSize: 13
	},
	profileInfo: {
		fontSize: 15,
		display: 'block',
		marginTop: 17,
		wordBreak: 'break-word'
	},
	ProfilefieldContainer: {
		paddingTop: 10,
		paddingBottom: 10
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	errorTextMobile: {
		extend: 'errorText',
		fontSize: 13
	}
}));

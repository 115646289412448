import React, { useState } from 'react';
import useStyles from './styles';
import { useScreenClass, ScreenClassRender } from 'react-grid-system';
import { Link } from 'gatsby';
import Forward from '../../assets/icons/Forward_Carousel.svg';
import Back from '../../assets/icons/Back_Carousel.svg';

const Carousel = ({ data }) => {
	const screenClassForRender = useScreenClass();
	const classes = useStyles();
	const [activeImage, setActiveImage] = useState(0);
	const [prevImage, setPrevImage] = useState(data.length - 1);
	const [nextImage, setNextImage] = useState(1);

	const updateImagePositionRight = () => {
		let aImage = activeImage === data.length - 1 ? 0 : activeImage + 1;
		let pImage = prevImage === data.length - 1 ? 0 : prevImage + 1;
		let nImage = nextImage === data.length - 1 ? 0 : nextImage + 1;
		setActiveImage(aImage);
		setPrevImage(pImage);
		setNextImage(nImage);
	};

	const updateImagePositionLeft = () => {
		let aImage = activeImage === 0 ? data.length - 1 : activeImage - 1;
		let pImage = prevImage === 0 ? data.length - 1 : prevImage - 1;
		let nImage = nextImage === 0 ? data.length - 1 : nextImage - 1;
		setActiveImage(aImage);
		setPrevImage(pImage);
		setNextImage(nImage);
	};

	const desktop = (
		<>
			<div
				className={classes.topContainer}
				style={{
					width: ['xxl'].includes(screenClassForRender)
						? '1260px'
						: ['xl'].includes(screenClassForRender)
						? '1140px'
						: ['lg'].includes(screenClassForRender)
						? '970px'
						: ''
				}}
			>
				<div className={classes.caroContainer}>
					<div
						className={classes.back}
						onClick={() => updateImagePositionLeft()}
					>
						<img src={Back} />
					</div>
					<div
						style={{
							width: ['xxl'].includes(screenClassForRender)
								? '1055px'
								: ['xl'].includes(screenClassForRender)
								? '940px'
								: ['lg'].includes(screenClassForRender)
								? '800px'
								: ''
						}}
						className={classes.prev}
					>
						<div className={classes.textButtonContainer}>
							<div className={classes.bannerText}>{data[prevImage].text}</div>
							<div className={classes.accountButton}>Create an account</div>
						</div>
						<div
							className={classes.image}
							style={{
								backgroundImage: `linear-gradient(to right, black, rgba(255,0,0,0)), url(${data[prevImage].image})`,
								backgroundSize: 'cover'
							}}
						/>
					</div>
					<div
						style={{
							width: ['xxl'].includes(screenClassForRender)
								? '1055px'
								: ['xl'].includes(screenClassForRender)
								? '940px'
								: ['lg'].includes(screenClassForRender)
								? '800px'
								: ''
						}}
						className={classes.active}
					>
						<div className={classes.textButtonContainer}>
							<div className={classes.bannerText}>{data[activeImage].text}</div>
							<Link to="/register" className={classes.accountButton}>
								Join Now
							</Link>
						</div>
						<div
							className={classes.image}
							style={{
								backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[activeImage].image})`,
								backgroundSize: 'cover'
							}}
						/>
					</div>
					<div
						style={{
							width: ['xxl'].includes(screenClassForRender)
								? '1055px'
								: ['xl'].includes(screenClassForRender)
								? '940px'
								: ['lg'].includes(screenClassForRender)
								? '800px'
								: ''
						}}
						className={classes.next}
					>
						<div></div>
						<div className={classes.textButtonContainer}>
							<div className={classes.bannerText}>{data[nextImage].text}</div>
							<div className={classes.accountButton}>Create an account</div>
						</div>
						<div
							className={classes.image}
							style={{
								backgroundImage: `linear-gradient(to right, black, rgba(255,0,0,0)), url(${data[nextImage].image})`,
								backgroundSize: 'cover'
							}}
						/>
					</div>
					<div
						className={classes.forward}
						onClick={() => updateImagePositionRight()}
					>
						<img src={Forward} />
					</div>
				</div>
			</div>
		</>
	);

	const mobile = (
		<>
			<div className={classes.mobileCaroContainer}>
				<div
					className={classes.mobileBack}
					onClick={() => updateImagePositionLeft()}
				>
					<img src={Back} className={classes.mobileButton} />
				</div>
				<div
					className={classes.mobileLeft}
					style={{
						backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[prevImage].image})`,
						backgroundSize: 'cover',
						opacity: 0.5
					}}
				></div>
				<div
					className={classes.mobileActive}
					style={{
						backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[activeImage].image})`,
						backgroundSize: 'cover'
					}}
				>
					<div className={classes.mobileTextBannerContainer}>
						<div className={classes.mobileContentContainer}>
							<div className={classes.mobileText}>{data[activeImage].text}</div>
							<Link to="/register" className={classes.mobileButtonLink}>
								Join Now
							</Link>
						</div>
					</div>
				</div>
				<div
					className={classes.mobileForward}
					onClick={() => updateImagePositionRight()}
				>
					<img src={Forward} className={classes.mobileButton} />
				</div>
				<div
					className={classes.mobileRight}
					style={{
						backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[nextImage].image})`,
						backgroundSize: 'cover',
						opacity: 0.5
					}}
				></div>
			</div>
		</>
	);

	const carousel = (screenClass) => {
		return ['xxl', 'xl', 'lg'].includes(screenClass) ? desktop : mobile;
	};

	return <ScreenClassRender render={carousel} />;
};

export default Carousel;

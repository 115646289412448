import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		display: 'flex',
		marginBottom: 30,
		background: '#ededed',
		borderRadius: '0px 5px 5px 0px',
		minHeight: 330
	},
	containerSmall: {
		display: 'flex',
		marginBottom: 30,
		background: '#ededed',
		borderRadius: '0px 5px 5px 0px',
		height: 200
	},
	containerMobile: {
		display: 'flex',
		marginBottom: 30,
		marginRight: 15,
		marginLeft: 15,
		flexWrap: 'wrap'
	},
	image: {
		width: 590,
		objectFit: 'cover',
		borderRadius: '5px 0px 0px 5px'
	},
	imageMobile: {
		width: '100%',
		height: 180,
		objectFit: 'cover',
		borderRadius: '5px 5px 0px 0px'
	},
	contentContainer: {
		width: '100%',
		padding: 30,
		fontSize: 20,
		fontWeight: 600,
		color: '#464749',
		textTransform: 'uppercase',
		boxSizing: 'border-box',
		height: 330,
		borderRadius: '0px 5px 5px 0px'
	},
	contentContainerSmall: {
		width: '100%',
		padding: 30,
		fontSize: 15,
		fontWeight: 600,
		color: '#464749',
		textTransform: 'uppercase',
		boxSizing: 'border-box',
		borderRadius: '0px 5px 5px 0px'
	},
	contentContainerMobile: {
		width: '100%',
		padding: 20,
		background: '#ededed',
		fontSize: 20,
		fontWeight: 600,
		color: '#464749',
		textTransform: 'uppercase',
		boxSizing: 'border-box',
		height: 330,
		paddingTop: 30,
		borderRadius: '0px 0px 5px 5px'
	},
	text: {
		width: '100%',
		background: '#ededed',
		height: 240,
		overflow: 'hidden',
		fontSize: 15,
		fontWeight: 400,
		color: '#464749',
		textTransform: 'initial',
		wordBreak: 'break-word',
		marginTop: 10
	},
	textSmall: {
		width: '100%',
		overflow: 'hidden',
		fontSize: 12,
		fontWeight: 400,
		color: '#464749',
		textTransform: 'initial',
		wordBreak: 'break-word',
		marginTop: 10
	}
}));

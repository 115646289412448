import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	filterContainer: {
		background: '#dcebf3',
		height: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingTop: 12
	}
}));

import React, { useState } from 'react';
import useStyles from './styles';
import Sorting_Default_icon from '../../assets/icons/Sorting_Default_icon.svg';
import Sorting_Down_icon from '../../assets/icons/Sorting_Down_icon.svg';
import { Col } from 'react-grid-system';
import Sorting_Up_icon from '../../assets/icons/Sorting_Up_icon.svg';
import { navigate } from '@reach/router';
import LoaderSpinner from '../LoaderSpinner';
import { addNameToUnnamedHorse } from '../../utils/helpers';
import Dialog from '../Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import BasicRadioButtonGroup from '../BasicRadioButtonGroup';
import {
	FIND_YOUR_THOROUGHBRED_FORM,
	THOROUGHBRED_UNREGISTERED_FORM
} from '../../utils/constants/routes';

const radioItems = [
	{ id: 0, value: 'yes', label: 'Yes' },
	{ id: 1, value: 'no', label: 'No' },
	{ id: 2, value: 'unknown', label: 'Unknown' }
];

const radioItemsValue = {
	YES: 'yes',
	NO: 'no',
	UNKNOWN: 'unknown'
};

const dialogButtonValue = {
	FIND_YOUR_THOROUGHBRED: 'Find your Thoroughbred form',
	THOROUGHBRED_UNREGISTERED: 'Unregistered Thoroughbred in Australia Form'
};

const SearchResultsDisplay = ({
	searchResults,
	updateSearchOrdering,
	updatePaginationIndex,
	horseCount,
	orderBy,
	isLoading,
	paginationIndex,
	mobile
}) => {
	const classes = useStyles();
	const [
		openThoroughbredRequestDialog,
		setOpenThoroughbredRequestDialog
	] = useState(false);
	const [dialogTitle, setDialogTitle] = useState('');
	const [selectedItem, setSelectedItem] = useState('yes');

	const updateOrdering = () => {
		orderBy === 0
			? updateSearchOrdering(1)
			: orderBy === 1
			? updateSearchOrdering(2)
			: updateSearchOrdering(0);
	};

	const loadMoreRecords = () => {
		if (!isLoading) {
			updatePaginationIndex(paginationIndex + 1);
		}
	};

	const goToHorseProfile = (horseCode) => {
		navigate(`/horse-profile/${horseCode}`);
	};

	const ClaimThoroughbredButton = () => {
		return (
			<Col sm={12} md={12} lg={12} xl={12} xxl={12} style={{ padding: 0 }}>
				<div className={classes.claimContainer}>
					Unable to find your thoroughbred in the list?
					<br />
					Complete a{' '}
					<span
						className={classes.link}
						onClick={onOpenThoroughbredRequestDiag}
					>
						Find your Thoroughbred form
					</span>
				</div>
			</Col>
		);
	};

	const onOpenThoroughbredRequestDiag = () => {
		setOpenThoroughbredRequestDialog(true);
	};

	const onCloseThoroughbredRequestDiag = () => {
		setOpenThoroughbredRequestDialog(false);
	};

	const onRenderDialogContent = () => {
		setDialogTitle('Thoroughbred not found');
		return (
			<>
				<>
					<DialogContentText className={classes.dialogTextColor}>
						Do you believe your thoroughbred is registered in the Australian
						Stud Book, or did the horse ever race in Australia?
					</DialogContentText>
					<BasicRadioButtonGroup
						value={selectedItem}
						radioItems={radioItems}
						onHandleChange={onSelectRadioItem}
					/>
				</>
			</>
		);
	};

	const onSelectRadioItem = (e) => {
		setSelectedItem(e.target.value);
	};

	const onRenderActionButtons = () => {
		return (
			<div className={classes.buttonContainer}>
				<div
					className={classes.cancelRequest}
					onClick={() => onCloseThoroughbredRequestDiag()}
				>
					Cancel
				</div>
				<div className={classes.saveRequest}>
					<span>
						<input
							type="button"
							value={
								selectedItem === radioItemsValue.YES
									? dialogButtonValue.FIND_YOUR_THOROUGHBRED
									: dialogButtonValue.THOROUGHBRED_UNREGISTERED
							}
							className={classes.completeFormButton}
							onClick={() => onFindYourThoroughbredForm()}
						/>
					</span>
				</div>
			</div>
		);
	};

	const onFindYourThoroughbredForm = () => {
		if (selectedItem === radioItemsValue.YES) {
			navigate(FIND_YOUR_THOROUGHBRED_FORM);
		} else {
			navigate(THOROUGHBRED_UNREGISTERED_FORM);
		}
	};

	const searchResultsRender = () => {
		if (searchResults !== null && searchResults.length > 0) {
			return searchResults.map((res) => {
				return (
					<div
						key={res.horseCode}
						className={classes.searchResult}
						onClick={() => goToHorseProfile(res.horseCode)}
					>
						<span
							className={mobile ? classes.horseNameMobile : classes.horseName}
						>
							{addNameToUnnamedHorse(res.name)}
						</span>
						<span
							className={
								mobile
									? classes.additionalDetailsMobile
									: classes.additionalDetails
							}
						>
							<span
								className={
									mobile ? classes.additionalInfoMobile : classes.additionalInfo
								}
							>
								{res.colour}
							</span>
							<span
								className={
									mobile ? classes.additionalInfoMobile : classes.additionalInfo
								}
							>
								{res.sex}
							</span>
							<span
								className={
									mobile ? classes.additionalInfoMobile : classes.additionalInfo
								}
							>
								{res.age} years
							</span>
						</span>
					</div>
				);
			});
		}
	};

	return (
		<>
			<div className={classes.componentContainer}>
				<div className={classes.searchContainer} style={{ paddingBottom: 0 }}>
					{searchResults !== null && searchResults.length > 0 && (
						<div className={classes.searchDetails}>
							<span
								className={classes.filterOrderBy}
								onClick={() => updateOrdering()}
							>
								Order by Name
								<span className={classes.sortIcon}>
									{orderBy === 0 && <img src={Sorting_Default_icon} />}
									{orderBy === 1 && <img src={Sorting_Down_icon} />}
									{orderBy === 2 && <img src={Sorting_Up_icon} />}
								</span>
							</span>
							<span className={classes.numberOfRecords}>
								{horseCount} {horseCount > 1 ? 'results' : 'result'} found
							</span>
							<div className={classes.clear}></div>
						</div>
					)}
					<div
						className={classes.searchResultContainer}
						style={
							isLoading && searchResults !== null && searchResults.length < 1
								? { minHeight: 400 }
								: {}
						}
					>
						{isLoading && (
							<div className={classes.loadingDiv}>
								<LoaderSpinner status={true} />
							</div>
						)}
						<div>{searchResultsRender()}</div>
					</div>
				</div>

				<ClaimThoroughbredButton />
				{searchResults !== null &&
					searchResults.length > 0 &&
					searchResults.length != horseCount && (
						<div
							className={classes.loadMoreButton}
							onClick={() => loadMoreRecords()}
						>
							Load More
						</div>
					)}
				<Dialog
					openDialog={openThoroughbredRequestDialog}
					handleClose={onCloseThoroughbredRequestDiag}
					title={dialogTitle}
					contentChildren={onRenderDialogContent}
					actionButtons={onRenderActionButtons}
				/>
			</div>
		</>
	);
};

export default SearchResultsDisplay;

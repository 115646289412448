import React, { useState, useEffect } from 'react';
import useStyles from './styles';
import SEO from '../seo';
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import Accordion from '../Accordion';
import BannerImage from '../../assets/images/header_banner.png';
import MessageBar from '../MessageBar';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import SimpleCheckBox from '../SimpleCheckBox';
import PrimaryButton from '../PrimaryButton';
import { navigate, useParams } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import AddressAutoSuggestion from '../AddressAutoSuggestion';

import {
	getDataModel,
	getDisciplines,
	getInjuryTypes,
	setDataModel
} from './helper';
import {
	convertToSortedMultiListData,
	sortArray,
	checkUserAdmin
} from '../../utils/helpers';
import axios from 'axios';
import {
	deleteImagesFromS3,
	getPlacementReferenceData,
	saveResetForm,
	uploadImageToS3,
	resetFormUploadImage,
	updateResetFormStatus,
	getResetForm,
	deleteImageFromS3,
	deleteResetNominationImage,
	deleteResetForm,
	saveRetrainerHorseStatus,
	saveResidentialAddress,
	getHorsePlacementFormForReset,
	deleteResetNominationImageByResetNominationFormId,
	updateMemberProfile
} from '../../graphql/custom.queries';
import {
	validation,
	MULTI_DEPENDENT,
	DEPENDENT_DROPDOWN,
	TOGGLE_DEPENDENT,
	CHECKBOX_DEPENDENCY,
	REGEX,
	ARRAY_LENGTH_DEPENDENT,
	ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE
} from './validation';
import FormTextField from '../FormTextField';
import ResizingTextArea from '../ResizingTextArea';
import FormDropDown from '../FormDropDown';
import Toggle from '../Toggle';
import FormMultiSelectList from '../FormMultiSelectList';
import UploadPhoto from '../UploadPhoto';
import ConfirmationModal from '../ConfirmationModal';
import LoaderSpinner from '../LoaderSpinner';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import { DEFAULT_COUNTRY } from '../../utils/constants';
import useFilePreview from '../../hooks/useFilePreview';
import {
	DASHBOARD_ROUTE,
	PAGE_NOT_FOUND_ROUTE
} from '../../utils/constants/routes';
import { logToServer } from '../../utils/logger';
import {
	CommonKeys,
	ResetNominationFormKeys
} from './../../utils/constants/index';
import SubmitOnBehalf from '../SubmitOnBehalf';
import StateSelector from '../StateSelector';
import CountrySelector from '../CountrySelector';

const validationFields = [
	'registeredOwner',
	'registeredManagingOwner',
	'proofOwner',
	'proofOfPurchaseDocuments',
	'sireName',
	'damName',
	'approxHorseHeight',
	'reasonForRetirement',
	'reasonForRetirementOther',
	'residentialAddress',
	'residentialSuburb',
	'residentialPostcode',
	'residentialCountry',
	'residentialState',
	'address',
	'suburb',
	'postcode',
	'state',
	'country',
	'relationship',
	'otherRelationship',
	'relationshipLength',
	'currentOwners',
	'currentTrainers',
	'windSuck',
	'injured',
	'vetReports',
	'injuryTypes',
	'injuryNature',
	'heritableDisorder',
	'heritableDisorderDetails',
	'disease',
	'diseaseDetails',
	'accidents',
	'accidentsDetails',
	'eyeDisease',
	'eyeDiseaseDetails',
	'lameness',
	'lamenessDetails',
	'headShaking',
	'headShakingDetails',
	'vices',
	'vicesDetails',
	'abnormality',
	'abnormalityDetails',
	'surgical',
	'surgicalDetails',
	'vaccination',
	'vaccinationDetails',
	'breeding',
	'breedingOther',
	'temperament',
	'disciplines',
	'disciplinesOther',
	'riddenHistory',
	'vicesBehaviour',
	'faceOn',
	'leftSide',
	'rightSide',
	'behind',
	'additional',
	'rehomingAttempt',
	'noBehaviouralissues',
	'noHealthIssues',
	'responsibleOwner',
	'cantBeReHomed',
	'rehomingProgram',
	'agreeCompletionOfForm',
	'disabledAssociation',
	'salePrice',
	'RVagreement',
	'privacyStatement',
	'concent'
];

const ResetForm = () => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const classes = useStyles();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	const { currentUser } = useAuth();
	let { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [validationOn, setValidationOn] = useState(false);
	const [errors, setErrors] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [
		referenceSpecialtyDisciplines,
		setReferenceSpecialtyDisciplines
	] = useState([]);
	const [
		referenceLengthOfRelationship,
		setReferenceLengthOfRelationship
	] = useState([]);
	const [
		referenceRelationshipToHorse,
		setReferenceRelationshipToHorse
	] = useState([]);
	const [referenceRetirementReason, setReferenceRetirementReason] = useState(
		[]
	);
	const [referenceIsKnownToWindsuck, setReferenceIsKnownToWindsuck] = useState(
		[]
	);
	const [referenceInjuries, setReferenceInjuries] = useState([]);
	const [formId, setFormId] = useState(null);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [hasResidentialAddress, setHasResidentialAddress] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');
	const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);

	const [country, setCountry] = useState(DEFAULT_COUNTRY);

	const [values, setValues] = useState({
		addressValidated: {
			value: true,
			isRequired: false,
			isTouched: false
		},
		residentialAddressValidated: {
			value: true,
			isRequired: false,
			isTouched: false
		},
		registeredOwner: {
			value: false,
			isTouched: false,
			conditions: [
				{
					condition: CHECKBOX_DEPENDENCY,
					dependantArray: ['registeredManagingOwner', 'proofOwner'],
					value: false,
					errorMessage: 'Select at least one option'
				}
			],
			isRequired: false
		},
		registeredManagingOwner: {
			value: false,
			isTouched: false,
			conditions: [
				{
					condition: CHECKBOX_DEPENDENCY,
					dependantArray: ['registeredOwner', 'proofOwner'],
					value: false,
					errorMessage: 'Select at least one option'
				}
			],
			isRequired: false
		},
		proofOwner: {
			value: false,
			isTouched: false,
			conditions: [
				{
					condition: CHECKBOX_DEPENDENCY,
					dependantArray: ['registeredManagingOwner', 'registeredOwner'],
					value: false,
					errorMessage: 'Select at least one option'
				}
			],
			isRequired: false
		},
		proofOfPurchaseDocuments: {
			value: [],
			isTouched: false,
			conditions: [
				{
					condition: ARRAY_LENGTH_DEPENDENT_WITH_TOGGLE,
					toggleField: 'proofOwner',
					toggleValue: true,
					errorMessage:
						'Proof of purchase and/or a Statutory Declaration is required'
				}
			],
			isRequired: false
		},
		racingName: {
			value: '-'
		},
		age: { value: '-' },
		colour: { value: '-' },
		sireName: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Sire name is required'
		},
		damName: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Dam name is required'
		},
		approxHorseHeight: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Approx horse height is required'
		},
		reasonForRetirement: {
			value: [],
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Reason for retirement is required'
		},
		reasonForRetirementOther: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: DEPENDENT_DROPDOWN,
					dependantArray: [],
					dependantField: 'reasonForRetirement',
					value: 'Other',
					errorMessage: 'Other reason For Retirement is required'
				}
			],
			isRequired: false
		},
		address: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Thoroughbred address is required'
		},
		suburb: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Thoroughbred suburb is required'
		},
		postcode: {
			value: '',
			isTouched: false,
			errorMessage: 'Thoroughbred postcode is required',
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required'
				}
			],
			isRequired: true
		},
		country: {
			value: DEFAULT_COUNTRY,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Thoroughbred country is required'
		},
		state: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Thoroughbred state is required'
		},
		residentialAddress: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Residential address is required'
		},
		residentialSuburb: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Residential suburb is required'
		},
		residentialPostcode: {
			value: '',
			isTouched: false,
			errorMessage: 'Residential postcode is required',
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required'
				}
			],
			isRequired: true
		},
		residentialCountry: {
			value: DEFAULT_COUNTRY,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Residential country is required'
		},
		residentialState: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Residential state is required'
		},
		contactName: {
			value: '-'
		},
		contactEmail: {
			value: '-'
		},
		contactNumber: {
			value: '-'
		},
		relationship: {
			value: 0,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Your relationship to this horse is required'
		},
		otherRelationship: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: DEPENDENT_DROPDOWN,
					dependantArray: [],
					dependantField: 'relationship',
					value: 'Other',
					errorMessage: 'Other relationship is required'
				}
			],
			isRequired: false
		},
		relationshipLength: {
			value: 0,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Length of relationship is required'
		},
		currentOwners: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Current owner(s) is required'
		},
		currentTrainers: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Current/Most recent trainer(s) is required'
		},
		windSuck: {
			value: 0,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Windsuck information is required'
		},
		injured: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		vetReports: {
			value: [],
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		injuryTypes: {
			value: [],
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'injured',
					value: true,
					errorMessage: 'Injury types are required'
				}
			],
			isRequired: false
		},
		injuryNature: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'injured',
					value: true,
					errorMessage: 'Injury details are required'
				}
			],
			isRequired: false
		},
		heritableDisorder: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		heritableDisorderDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'heritableDisorder',
					value: true,
					errorMessage: 'Heritable disorder details required'
				}
			],
			isRequired: false
		},
		disease: { value: '', isTouched: false, conditions: [], isRequired: false },
		diseaseDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'disease',
					value: true,
					errorMessage: 'Disease details required'
				}
			],
			isRequired: false
		},
		accidents: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		accidentsDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'accidents',
					value: true,
					errorMessage: 'Accident details required'
				}
			],
			isRequired: false
		},
		eyeDisease: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		eyeDiseaseDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'eyeDisease',
					value: true,
					errorMessage: 'Eye disease details required'
				}
			],
			isRequired: false
		},
		lameness: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		lamenessDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'lameness',
					value: true,
					errorMessage: 'Lameness details required'
				}
			],
			isRequired: false
		},
		headShaking: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		headShakingDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'headShaking',
					value: true,
					errorMessage: 'Head shaking details required'
				}
			],
			isRequired: false
		},
		vices: { value: '', isTouched: false, conditions: [], isRequired: false },
		vicesDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'vices',
					value: true,
					errorMessage: 'Vices details required'
				}
			],
			isRequired: false
		},
		abnormality: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		abnormalityDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'abnormality',
					value: true,
					errorMessage: 'Abnormalities details required'
				}
			],
			isRequired: false
		},
		surgical: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		surgicalDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'surgical',
					value: true,
					errorMessage: 'Surgical procedures details required'
				}
			],
			isRequired: false
		},
		vaccination: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		vaccinationDetails: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'vaccination',
					value: true,
					errorMessage: 'Vaccinations details required'
				}
			],
			isRequired: false
		},
		breeding: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		breedingOther: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'breeding',
					value: true,
					errorMessage: 'Breeding details required'
				}
			],
			isRequired: false
		},
		temperament: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Temperament is required'
		},
		disciplines: {
			value: [],
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Discipline is required'
		},
		disciplinesOther: {
			value: '',
			isTouched: false,
			conditions: [
				{
					condition: MULTI_DEPENDENT,
					field: 'disciplines',
					value: 'Other',
					errorMessage: 'Other Discipline is required'
				}
			],
			isRequired: false
		},
		riddenHistory: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Ridden education is required'
		},
		vicesBehaviour: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Other relevant matters or details are required'
		},
		faceOn: {
			value: [],
			isTouched: false,
			conditions: [ARRAY_LENGTH_DEPENDENT],
			isRequired: true,
			errorMessage: 'Face on photo is required'
		},
		leftSide: {
			value: [],
			isTouched: false,
			conditions: [ARRAY_LENGTH_DEPENDENT],
			isRequired: true,
			errorMessage: 'Left side photo is required'
		},
		rightSide: {
			value: [],
			isTouched: false,
			conditions: [ARRAY_LENGTH_DEPENDENT],
			isRequired: true,
			errorMessage: 'Right side photo is required'
		},
		behind: {
			value: [],
			isTouched: false,
			conditions: [ARRAY_LENGTH_DEPENDENT],
			isRequired: true,
			errorMessage: 'Behind photo is required'
		},
		additional: {
			value: [],
			isTouched: false,
			conditions: [],
			isRequired: false
		},
		rehomingAttempt: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		noBehaviouralissues: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		noHealthIssues: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		responsibleOwner: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		cantBeReHomed: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		rehomingProgram: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		agreeCompletionOfForm: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		disabledAssociation: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		salePrice: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		RVagreement: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		privacyStatement: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		concent: {
			value: false,
			isTouched: false,
			conditions: [],
			isRequired: true
		},
		status: {
			value: null
		},
		transport: {
			value: null
		}
	});

	const manualAddressCheckBoxData = {
		id: CommonKeys.MANUALLY_ADD_ADDRESS,
		label: 'Manually add my address',
		value: 'Manually add my address',
		isSelected: !values.addressValidated.value
	};

	const manualResidentialAddressCheckBoxData = {
		id: ResetNominationFormKeys.MANUAL_RESIDENTIAL_ADDRESS,
		label: 'Manually add my residential address',
		value: 'Manually add my residential address',
		isSelected: !values.residentialAddressValidated.value
	};

	const manualResidentialAddressCheckBoxDataByAdmin = {
		id: ResetNominationFormKeys.MANUAL_RESIDENTIAL_ADDRESS,
		label: 'Manually add my residential address',
		value: 'Manually add my residential address',
		isSelected: isPostalAddressManual
	};

	const toggleManualAddressCheckBox = (name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: !values[name].value,
			isTouched: true
		};
		items[name] = item;
		setValues(items);
	};

	useEffect(() => {
		setIsLoading(true);
		if (currentUser) {
			AppSyncService.execute(getPlacementReferenceData).then((result) => {
				if (result.data) {
					setReferenceSpecialtyDisciplines(
						convertToSortedMultiListData(result.data.listSpecialtyDisciplines)
					);
					setReferenceInjuries(
						convertToSortedMultiListData(result.data.listInjuryDetails)
					);

					let modifiedLengthOfRelationship =
						result.data.listRelationshipToHorseLength;
					modifiedLengthOfRelationship.unshift({ id: 0, value: '' });
					setReferenceLengthOfRelationship(modifiedLengthOfRelationship);

					let modifiedRelationshipToHorse = result.data.listRelationshipToHorse;
					modifiedRelationshipToHorse.unshift({ id: 0, value: '' });
					setReferenceRelationshipToHorse(modifiedRelationshipToHorse);

					let modifiedRetirementReason = result.data.listRetirementLookup;
					modifiedRetirementReason.unshift({ id: 0, value: '' });
					setReferenceRetirementReason(
						sortArray(modifiedRetirementReason, true)
					);

					let modifiedIsKnownToWindsuck = result.data.listYesNo;
					modifiedIsKnownToWindsuck.unshift({ id: 0, value: '' });
					setReferenceIsKnownToWindsuck(modifiedIsKnownToWindsuck);
					AppSyncService.execute(getResetForm, {
						memberExternalId: currentUser?.UserId,
						horseCode: +id
					}).then((data) => {
						if (
							data.data?.getHorseProfile?.status?.status?.id !== 10 &&
							!isAdminUser
						) {
							navigate(PAGE_NOT_FOUND_ROUTE);
						}
						if (data.data?.getResetNominationForm) {
							let modelData;
							if (data.data?.getResetNominationForm?.id != null) {
								setFormId(data.data.getResetNominationForm.id);
								modelData = getDataModel(
									values,
									data.data?.getResetNominationForm,
									modifiedRelationshipToHorse,
									modifiedRetirementReason,
									data.data?.getResetNominationForm?.id
								);
								setValues(modelData);
							} else {
								AppSyncService.execute(getHorsePlacementFormForReset, {
									horseCode: +id
								}).then((horsePlacementData) => {
									if (horsePlacementData?.data?.getHorsePlacementFormForReset) {
										populateFormWithRetrainerData(
											horsePlacementData.data.getHorsePlacementFormForReset,
											data.data?.getResetNominationForm
										);
										if (
											data.data?.getResetNominationForm.member
												?.residentialAddress.address
										) {
											setHasResidentialAddress(true);
										}
									}
								});
							}
						}
						setIsLoading(false);
					});
				}
			});
		} else {
			setIsLoading(false);
		}
	}, [currentUser]);

	const populateFormWithRetrainerData = (resetData, originalData) => {
		setValues((prevState) => ({
			...prevState,
			contactName: {
				...values.contactName,
				value: `${originalData.member?.firstName} ${originalData.member?.lastName}`
			},
			contactEmail: {
				...values.contactEmail,
				value: originalData.member?.email
			},
			contactNumber: {
				...values.contactNumber,
				value: originalData.member?.phone
			},
			racingName: {
				...values.racingName,
				value: originalData.horse?.name
			},
			age: {
				...values.age,
				value: originalData.horse?.age
			},
			colour: {
				...values.colour,
				value: originalData.horse?.colour
			},
			address: {
				...values.address,
				value: resetData?.address ? resetData.address : values.address.value
			},
			suburb: {
				...values.suburb,
				value: resetData?.suburb ? resetData.suburb : values.suburb.value
			},
			postcode: {
				...values.postcode,
				value: resetData?.postcode ? resetData.postcode : values.postcode.value
			},
			state: {
				...values.state,
				value: resetData?.state ? resetData.state : values.state.value
			},
			country: {
				...values.country,
				value: resetData?.country ? resetData.country : values.country.value
			},
			residentialAddress: {
				...values.residentialAddress,
				value: originalData.member?.residentialAddress.address
					? originalData.member?.residentialAddress.address
					: values.residentialAddress.value
			},
			residentialSuburb: {
				...values.residentialSuburb,
				value: originalData.member?.residentialAddress.suburb
					? originalData.member?.residentialAddress.suburb
					: values.residentialSuburb.value
			},
			residentialPostcode: {
				...values.residentialPostcode,
				value: originalData.member?.residentialAddress.postcode
					? originalData.member?.residentialAddress.postcode
					: values.residentialPostcode.value
			},
			residentialState: {
				...values.residentialState,
				value: originalData.member?.residentialAddress.state
					? originalData.member?.residentialAddress.state
					: values.residentialState.value
			},
			residentialCountry: {
				...values.residentialCountry,
				value: originalData.member?.residentialAddress.country
					? originalData.member?.residentialAddress.country
					: values.residentialCountry.value
			},
			sireName: {
				...values.sireName,
				value: resetData?.sireName ? resetData.sireName : values.sireName.value
			},
			damName: {
				...values.damName,
				value: resetData?.damName ? resetData.damName : values.damName.value
			},
			approxHorseHeight: {
				...values.approxHorseHeight,
				value: resetData?.height
					? resetData.height
					: values.approxHorseHeight.value
			},
			relationship: {
				...values.relationship,
				value: resetData?.relationshipToHorse?.id
					? resetData.relationshipToHorse?.id
					: values.relationship.value
			},
			reasonForRetirement: {
				...values.reasonForRetirement,
				value: resetData?.retirementReason?.id
					? resetData.retirementReason?.id
					: values.reasonForRetirement.value
			},
			relationshipLength: {
				...values.relationshipLength,
				value: resetData?.lengthOfRelationship?.id
					? resetData.lengthOfRelationship?.id
					: values.relationshipLength.value
			},
			currentOwners: {
				...values.currentOwners,
				value: resetData?.currentOwners
					? resetData.currentOwners
					: values.currentOwners.value
			},
			currentTrainers: {
				...values.currentTrainers,
				value: resetData?.currentRetrainers
					? resetData.currentRetrainers
					: values.currentTrainers.value
			},
			windSuck: {
				...values.windSuck,
				value: resetData?.isKnownToWindsuck?.id
					? resetData.isKnownToWindsuck?.id
					: values.windSuck.value
			},
			injured: {
				...values.injured,
				value: resetData?.injuries?.length > 0 ? true : false
			},
			injuryTypes: {
				...values.injuryTypes,
				value: resetData?.injuries
					? getInjuryTypes(resetData.injuries)
					: values.injuries.value
			},
			temperament: {
				...values.temperament,
				value: resetData?.temperament
					? resetData?.temperament
					: values.temperament.value
			},
			disciplines: {
				...values.disciplines,
				value: resetData?.disciplines
					? getDisciplines(resetData.disciplines)
					: values.disciplines.value
			},
			riddenHistory: {
				...values.riddenHistory,
				value: resetData?.riddenHistory
					? resetData.riddenHistory
					: values.riddenHistory.value
			},
			vicesBehaviour: {
				...values.vicesBehaviour,
				value: resetData?.otherDetails
					? resetData.otherDetails
					: values.vicesBehaviour.value
			},
			addressValidated: {
				...values.addressValidated,
				value: resetData?.isManualAddress
					? !resetData.isManualAddress
					: values.addressValidated.value
			},
			disciplinesOther: {
				...values.disciplinesOther,
				value: resetData?.disciplinesOther
					? resetData.disciplinesOther
					: values.disciplinesOther.value
			}
		}));
	};

	const removeImage = (img, name) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteResetNominationImage, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteResetNominationImage) {
							let items = { ...values };
							let dataList = Object.assign([], items[name].value);
							let newDataList = dataList.filter((d) => d.key != img.key);
							let item = {
								...items[name],
								value: newDataList,
								isTouched: true
							};
							items[name] = item;
							setValues(items);
							addToast({ Message: 'Image removed', IsSuccess: true });
							setErrors(validation(values, validationOn, validationFields));
						}
					});
				}
			}
		);
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleChange = (e) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: e.target.value,
			isTouched: true
		};
		items[e.target.name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const addressAutoComplete = (address, addressDetails) => {
		setValues((prevState) => ({
			...prevState,
			address: {
				value: address,
				isTouched: true
			},
			suburb: {
				value: addressDetails.Suburb,
				isTouched: true
			},
			postcode: {
				value: addressDetails.Postcode,
				isTouched: true
			},
			state: {
				value: addressDetails.State,
				isTouched: true
			},
			country: {
				value: addressDetails.Country,
				isTouched: true
			}
		}));
		setErrors(validation(values, validationOn, validationFields));
	};

	const residentialAddressAutoComplete = (address, addressDetails) => {
		setValues((prevState) => ({
			...prevState,
			residentialAddress: {
				value: address,
				isTouched: true
			},
			residentialSuburb: {
				value: addressDetails.Suburb,
				isTouched: true
			},
			residentialPostcode: {
				value: addressDetails.Postcode,
				isTouched: true
			},
			residentialState: {
				value: addressDetails.State,
				isTouched: true
			},
			residentialCountry: {
				value: addressDetails.Country,
				isTouched: true
			}
		}));
		setErrors(validation(values, validationOn, validationFields));
	};

	const saveResidentialAddressProfile = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		let saveAddress = {
			memberExternalId:
				isSubmitOnBehalf && selectedUserVerifiedInfo
					? selectedUserVerifiedInfo.memberExternalId
					: '',
			address: values.residentialAddress.value,
			country: values.residentialCountry.value,
			postcode: values.residentialPostcode.value,
			state: values.residentialState.value,
			suburb: values.residentialSuburb.value
		};
		if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
			saveAddress = {
				...saveAddress,
				address: residentialAddress?.address,
				country: residentialAddress?.country,
				postcode: residentialAddress?.postcode,
				state: residentialAddress?.state,
				suburb: residentialAddress?.suburb
			};
		}

		AppSyncService.execute(saveResidentialAddress, {
			saveAddress
		});
	};

	const updateProfile = () => {
		const { phone } = selectedUserVerifiedInfo || {};

		if (isSubmitOnBehalf && selectedUserVerifiedInfo && phone) {
			let updateMemberProfileData = {
				...(phone && { phone: phone }),
				...(isSubmitOnBehalf &&
					selectedUserVerifiedInfo && {
						externalId: selectedUserVerifiedInfo.memberExternalId
					})
			};
			AppSyncService.execute(updateMemberProfile, {
				updateMemberProfileData
			});
		}
	};

	const onAddressChange = (address) => {
		let items = { ...values };
		let item = {
			...items.address,
			value: address,
			isTouched: true
		};
		items.address = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onSuburbChange = (suburb) => {
		let items = { ...values };
		let item = {
			...items.suburb,
			value: suburb,
			isTouched: true
		};
		items.suburb = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onPostcodeChange = (postcode) => {
		let items = { ...values };
		let item = {
			...items.postcode,
			value: postcode,
			isTouched: true
		};
		items.postcode = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onCountryChange = (country) => {
		let items = { ...values };
		let item = {
			...items.country,
			value: country,
			isTouched: true
		};
		items.country = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onAddressStateChange = (state) => {
		let items = { ...values };
		let item = {
			...items.state,
			value: state,
			isTouched: true
		};
		items.state = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onResidentialAddressChange = (residentialAddress) => {
		let items = { ...values };
		let item = {
			...items.residentialAddress,
			value: residentialAddress,
			isTouched: true
		};
		items.residentialAddress = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onResidentialSuburbChange = (residentialSuburb) => {
		let items = { ...values };
		let item = {
			...items.residentialSuburb,
			value: residentialSuburb,
			isTouched: true
		};
		items.residentialSuburb = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onResidentialPostcodeChange = (residentialPostcode) => {
		let items = { ...values };
		let item = {
			...items.residentialPostcode,
			value: residentialPostcode,
			isTouched: true
		};
		items.residentialPostcode = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onResidentialCountryChange = (residentialCountry) => {
		let items = { ...values };
		let item = {
			...items.residentialCountry,
			value: residentialCountry,
			isTouched: true
		};
		items.residentialCountry = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onResidentialAddressStateChange = (residentialState) => {
		let items = { ...values };
		let item = {
			...items.residentialState,
			value: residentialState,
			isTouched: true
		};
		items.residentialState = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeDropDown = (e) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value),
			isTouched: true
		};
		items[e.target.name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeToggle = (val, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val,
			isTouched: true
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeMultiSelect = (val, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val,
			isTouched: true
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleCheckbox = (id, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: !items[name].value,
			isTouched: true
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handlePhotoUpload = (image, name, uploadType) => {
		{
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					let imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							AppSyncService.execute(resetFormUploadImage, {
								filename: image.name,
								horseCode: id,
								key: imageKey,
								type: uploadType,
								...(isSubmitOnBehalf && {
									memberExternalId: selectedUserVerifiedInfo.memberExternalId
								})
							}).then((uploadResponse) => {
								try {
									let items = { ...values };
									setFormId(uploadResponse.data.saveResetNominationImage?.id);
									let dataList = Object.assign([], items[name].value);
									dataList.push({ filename: image.name, key: imageKey });
									let item = {
										...items[name],
										value: dataList,
										isTouched: true
									};
									items[name] = item;
									setValues(items);
									setErrors(validation(values, validationOn, validationFields));
									addToast({ Message: 'Photo uploaded', IsSuccess: true });
								} catch {
									addToast({
										Message: 'Error Uploading to S3...',
										IsSuccess: false
									});
								}
							});
						});
				}
			});
		}
	};

	useEffect(() => {
		setErrors(validation(values, validationOn, validationFields));
	}, [values]);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	const screenClassForRender = useScreenClass();

	const toggleManualAddress = () => {
		setIsPostalAddressManual(!isPostalAddressManual);
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		let listOfKeys = getImagesList();
		if (submitOnBehalf && listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys);
		}
		if (!submitOnBehalf) {
			onClearFields();
		}
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
	};

	const removeImagesFromSelectedUser = async (listOfKeys) => {
		const result = await AppSyncService.execute(deleteImagesFromS3, {
			imageKeys: listOfKeys
		});
		if (result?.data?.deleteUserImages?.success) {
			const deletedImageResult = await AppSyncService.execute(
				deleteResetNominationImageByResetNominationFormId,
				{
					resetNominationFormId: formId
				}
			);
			if (
				deletedImageResult?.data
					?.deleteResetNominationImageByResetNominationFormId
			) {
				let items = { ...values };
				items = {
					...items,
					additional: {
						value: [],
						isTouched: false,
						conditions: [],
						isRequired: false
					},
					proofOfPurchaseDocuments: {
						value: [],
						isTouched: false,
						conditions: [],
						isRequired: false
					},
					behind: {
						value: [],
						isTouched: false,
						conditions: [ARRAY_LENGTH_DEPENDENT],
						isRequired: false
					},
					rightSide: {
						value: [],
						isTouched: false,
						conditions: [ARRAY_LENGTH_DEPENDENT],
						isRequired: false
					},
					leftSide: {
						value: [],
						isTouched: false,
						conditions: [ARRAY_LENGTH_DEPENDENT],
						isRequired: false
					},
					faceOn: {
						value: [],
						isTouched: false,
						conditions: [ARRAY_LENGTH_DEPENDENT],
						isRequired: false
					},
					vetReports: {
						value: [],
						isTouched: false,
						conditions: [],
						isRequired: false
					}
				};
				setValues(items);
			}
		}
	};

	const onClearFields = async () => {
		let listOfKeys = getImagesList();
		if (listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys);
		}
	};

	const renderThorougbredDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Thoroughbred Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.THOROUGHBRED_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Racing Name</div>
									<FormTextField
										name="racingName"
										id="resetForm-racingName"
										value={values.racingName.value}
										canEdit={false}
										onChange={handleChange}
									/>
									<span className={classes.errorText}>{errors.racingName}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Age</div>
									<FormTextField
										name="age"
										id="resetForm-age"
										value={values.age.value}
										canEdit={false}
										onChange={handleChange}
									/>
									<span className={classes.errorText}>{errors.age}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Colour</div>
									<FormTextField
										name="colour"
										id="resetForm-colour"
										value={values.colour.value}
										canEdit={false}
										onChange={handleChange}
									/>
									<span className={classes.errorText}>{errors.colour}</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Sire Name*</div>
									<FormTextField
										name="sireName"
										id={ResetNominationFormKeys.SIRE_NAME}
										value={values.sireName.value}
										canEdit={true}
										onChange={handleChange}
										styles={errors.sireName ? { border: '1px solid red' } : {}}
									/>
									<span className={classes.errorText}>{errors.sireName}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Dam Name*</div>
									<FormTextField
										name="damName"
										id={ResetNominationFormKeys.DAM_NAME}
										value={values.damName.value}
										canEdit={true}
										onChange={handleChange}
										styles={errors.damName ? { border: '1px solid red' } : {}}
									/>
									<span className={classes.errorText}>{errors.damName}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Approx. Horse Height*
									</div>
									<FormTextField
										name="approxHorseHeight"
										id={ResetNominationFormKeys.APPROX_HORSE_HEIGHT}
										value={values.approxHorseHeight.value}
										canEdit={true}
										onChange={handleChange}
										styles={
											errors.approxHorseHeight
												? { border: '1px solid red' }
												: {}
										}
									/>
									<span className={classes.errorText}>
										{errors.approxHorseHeight}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Reason for Retirement*
									</div>
									<FormDropDown
										showBlank
										name="reasonForRetirement"
										id={ResetNominationFormKeys.REASON_FOR_RETIREMENT}
										items={referenceRetirementReason}
										selectedId={values.reasonForRetirement.value}
										onChange={handleChangeDropDown}
										styles={
											errors.reasonForRetirement
												? { border: '1px solid red' }
												: {}
										}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.reasonForRetirement}
									</div>
								</div>
							</Col>
							{referenceRetirementReason.filter(
								(r) =>
									r.value === 'Other' &&
									r.id === values.reasonForRetirement.value
							).length > 0 && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other Reason for retirement*
										</div>
										<FormTextField
											name="reasonForRetirementOther"
											id={ResetNominationFormKeys.OTHER_REASON_FOR_RETIREMENT}
											value={values.reasonForRetirementOther.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.reasonForRetirementOther
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.reasonForRetirementOther}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<hr className={classes.divider} />
						<FormAddressEntryGeneric
							address={values.address.value}
							onAddressChange={onAddressChange}
							isPostalAddressManual={false}
							suburb={values.suburb.value}
							onSuburbChange={onSuburbChange}
							postcode={values.postcode.value}
							onPostCodeChange={onPostcodeChange}
							country={values.country.value}
							onCountryChange={onCountryChange}
							manualAddressCheckBoxData={manualAddressCheckBoxData}
							toggleManualAddressCheckBox={() =>
								toggleManualAddressCheckBox('addressValidated')
							}
							state={values.state.value}
							onAddressStateChange={onAddressStateChange}
							isSubmitClicked={validationOn}
							autoCompleteFunction={addressAutoComplete}
						/>
					</div>
				</Accordion>
			</>
		);
	};

	const populateResidentialAddressFieldsSubmitByAdmin = (addressDetails) => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setSelectedUserVerifiedInfo({
			...selectedUserVerifiedInfo,
			residentialAddress: {
				...residentialAddress,
				address: address,
				suburb: addressDetails.Suburb,
				postcode: addressDetails.Postcode,
				state: addressDetails.State,
				country: addressDetails.Country
			}
		});
	};

	const onRenderResidentialSubmitByAdmin = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		return (
			<>
				<Row>
					<Col md={12} lg={4}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Address*</div>
							<AddressAutoSuggestion
								value={residentialAddress ? residentialAddress.address : ''}
								onChange={(val) =>
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: { ...residentialAddress, address: val }
									})
								}
								onAddressSelect={populateResidentialAddressFieldsSubmitByAdmin}
								disableAutoSuggestion={isPostalAddressManual}
							/>

							<span className={classes.errorText}>
								{errors.address?.message}
							</span>
						</div>
					</Col>
					<Col md={12} lg={4}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Suburb*</div>
							<FormTextField
								name="residentialSuburb"
								id={ResetNominationFormKeys.RESIDENTIAL_SUBURB}
								value={residentialAddress ? residentialAddress.suburb : ''}
								onChange={(val) =>
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											suburb: val.target.value
										}
									})
								}
								isDisabled={!isPostalAddressManual}
							/>
							<span className={classes.errorText}>
								{errors.suburb?.message}
							</span>
						</div>
					</Col>
					<Col md={12} lg={4}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Postcode*</div>
							<FormTextField
								name="residentialPostcode"
								id={ResetNominationFormKeys.RESIDENTIAL_POSTCODE}
								value={residentialAddress ? residentialAddress.postcode : ''}
								onChange={(val) =>
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											postcode: val.target.value
										}
									})
								}
								isDisabled={!isPostalAddressManual}
							/>
							<span className={classes.errorText}>
								{errors.postcode?.message}
							</span>
						</div>
					</Col>
				</Row>
				<Row style={{ marginTop: '15px' }}>
					<Col md={12} lg={4}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential State*</div>
							<StateSelector
								id={ResetNominationFormKeys.RESIDENTIAL_STATE}
								country={country}
								value={residentialAddress ? residentialAddress?.state : ''}
								changeHandler={(newStateValue) =>
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											state: newStateValue
										}
									})
								}
								isDisabled={!isPostalAddressManual}
							/>
							<span className={classes.errorText}>{errors.state?.message}</span>
						</div>
					</Col>
					<Col md={12} lg={4}>
						<div className={classes.ProfilefieldContainer}>
							<div className={classes.fieldTitle}>Residential Country*</div>
							<CountrySelector
								id={ResetNominationFormKeys.RESIDENTIAL_COUNTRY}
								value={residentialAddress ? residentialAddress?.country : ''}
								changeHandler={(newCountryValue) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											country: newCountryValue
										}
									});
									setCountry(newCountryValue);
								}}
								isDisabled={!isPostalAddressManual}
							/>
							<span className={classes.errorText}>
								{errors.country?.message}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<SimpleCheckBox
							small={true}
							noCapitalize={true}
							data={manualResidentialAddressCheckBoxDataByAdmin}
							changeCheckboxState={toggleManualAddress}
							key={
								manualResidentialAddressCheckBoxDataByAdmin &&
								manualResidentialAddressCheckBoxDataByAdmin.id
							}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const renderContactDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Contact Details"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.CONTACT_DETAILS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Contact Name</div>
									<FormTextField
										name="contactName"
										id="resetForm-contactName"
										value={
											isSubmitOnBehalf && selectedUserVerifiedInfo
												? selectedUserVerifiedInfo
													? `${selectedUserVerifiedInfo.firstName} ${selectedUserVerifiedInfo.lastName}`
													: ''
												: values.contactName.value
										}
										canEdit={false}
									/>
									<span className={classes.errorText}>
										{errors.contactName}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Contact Email</div>
									<FormTextField
										name="contactEmail"
										id="resetForm-contactEmail"
										value={
											isSubmitOnBehalf && selectedUserVerifiedInfo
												? selectedUserVerifiedInfo
													? selectedUserVerifiedInfo.email
													: ''
												: values.contactEmail.value
										}
										canEdit={false}
									/>
									<span className={classes.errorText}>
										{errors.contactEmail}
									</span>
								</div>
							</Col>
							{isSubmitOnBehalf && selectedUserVerifiedInfo ? (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Contact Number</div>
										<FormTextField
											name="contactNumber"
											id={ResetNominationFormKeys.CONTACT_NUMBER}
											value={
												selectedUserVerifiedInfo
													? selectedUserVerifiedInfo.phone
													: ''
											}
											onChange={(e) =>
												setSelectedUserVerifiedInfo({
													...selectedUserVerifiedInfo,
													phone: e.target.value
												})
											}
											canEdit={true}
										/>
										<span className={classes.errorText}>
											{errors.contactNumber}
										</span>
									</div>
								</Col>
							) : (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Contact Number</div>
										<FormTextField
											name="contactNumber"
											id={ResetNominationFormKeys.CONTACT_NUMBER}
											value={values.contactNumber.value}
											canEdit={false}
											onChange={handleChange}
										/>
										<span className={classes.errorText}>
											{errors.contactNumber}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<hr className={classes.divider} />
						{isSubmitOnBehalf && selectedUserVerifiedInfo ? (
							onRenderResidentialSubmitByAdmin()
						) : hasResidentialAddress ? (
							<>
								<Row>
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Residential Address*
											</div>
											<FormTextField
												name="residentialAddress"
												id={ResetNominationFormKeys.RESIDENTIAL_ADDRESS}
												value={values.residentialAddress.value}
												canEdit={false}
											/>
											<span className={classes.errorText}>
												{errors.address?.message}
											</span>
										</div>
									</Col>
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Residential Suburb*
											</div>
											<FormTextField
												name="residentialSuburb"
												id={ResetNominationFormKeys.RESIDENTIAL_SUBURB}
												value={values.residentialSuburb.value}
												canEdit={false}
											/>
											<span className={classes.errorText}>
												{errors.suburb?.message}
											</span>
										</div>
									</Col>
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Residential Postcode*
											</div>
											<FormTextField
												name="residentialPostcode"
												id={ResetNominationFormKeys.RESIDENTIAL_POSTCODE}
												value={values.residentialPostcode.value}
												canEdit={false}
											/>
											<span className={classes.errorText}>
												{errors.postcode?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row style={{ marginTop: '15px' }}>
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Residential State*
											</div>
											<FormTextField
												name="residentialState"
												id={ResetNominationFormKeys.RESIDENTIAL_STATE}
												value={values.residentialState.value}
												canEdit={false}
											/>
											<span className={classes.errorText}>
												{errors.state?.message}
											</span>
										</div>
									</Col>
									<Col md={12} lg={4}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Residential Country*
											</div>
											<FormTextField
												name="residentialCountry"
												id={ResetNominationFormKeys.RESIDENTIAL_COUNTRY}
												value={values.residentialCountry.value}
												canEdit={false}
											/>
											<span className={classes.errorText}>
												{errors.country?.message}
											</span>
										</div>
									</Col>
								</Row>
							</>
						) : (
							<FormAddressEntryGeneric
								address={values.residentialAddress.value}
								onAddressChange={onResidentialAddressChange}
								isPostalAddressManual={false}
								suburb={values.residentialSuburb.value}
								onSuburbChange={onResidentialSuburbChange}
								postcode={values.residentialPostcode.value}
								onPostCodeChange={onResidentialPostcodeChange}
								country={values.residentialCountry.value}
								onCountryChange={onResidentialCountryChange}
								manualAddressCheckBoxData={manualResidentialAddressCheckBoxData}
								toggleManualAddressCheckBox={() =>
									toggleManualAddressCheckBox('residentialAddressValidated')
								}
								state={values.residentialState.value}
								onAddressStateChange={onResidentialAddressStateChange}
								isSubmitClicked={validationOn}
								autoCompleteFunction={residentialAddressAutoComplete}
								titlePrefix="Residential"
								disableState={values.residentialAddress.value}
							/>
						)}

						<hr className={classes.divider} />
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Your relationship to this horse*
									</div>
									<FormDropDown
										showBlank
										name="relationship"
										id={ResetNominationFormKeys.RELATIONSHIP}
										items={referenceRelationshipToHorse}
										selectedId={values.relationship.value}
										onChange={handleChangeDropDown}
										styles={
											errors.relationship ? { border: '1px solid red' } : {}
										}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.relationship}
									</div>
								</div>
							</Col>
							{referenceRelationshipToHorse.filter(
								(r) => r.value === 'Other' && r.id === values.relationship.value
							).length > 0 && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Other relationship to this horse*
										</div>
										<FormTextField
											name="otherRelationship"
											id={ResetNominationFormKeys.OTHER_RELATIONSHIP}
											value={values.otherRelationship.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.otherRelationship
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.otherRelationship}
										</span>
									</div>
								</Col>
							)}
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Length of relationship to this horse*
									</div>
									<FormDropDown
										showBlank
										name="relationshipLength"
										id={ResetNominationFormKeys.RELATIONSHIP_LENGTH}
										items={referenceLengthOfRelationship}
										selectedId={values.relationshipLength.value}
										onChange={handleChangeDropDown}
										styles={
											errors.relationshipLength
												? { border: '1px solid red' }
												: {}
										}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.relationshipLength}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Who are the current owner(s)? (comma separated)*
									</div>
									<ResizingTextArea
										borderColor={errors.currentOwners ? 'red' : '#c7ced1'}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="currentOwners"
										id={ResetNominationFormKeys.CURRENT_OWNERS}
										value={values.currentOwners.value}
										onChange={handleChange}
										maxChars={200}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.currentOwners}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Who are the current/most recent trainer(s)? (comma
										separated)*
									</div>
									<ResizingTextArea
										borderColor={errors.currentTrainers ? 'red' : '#c7ced1'}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="currentTrainers"
										id={ResetNominationFormKeys.CURRENT_TRAINERS}
										value={values.currentTrainers.value}
										onChange={handleChange}
										maxChars={100}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.currentTrainers}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderThorougbredInjuryDetails = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Thoroughbred Injury and Health"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={
						isMobile ? { color: 'black', fontSize: 15, maxWidth: '65%' } : {}
					}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.THOROUGHBRED_INJURY_AND_HEALTH_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Has this horse been known to Windsuck?
									</div>
									<FormDropDown
										showBlank
										name="windSuck"
										id={ResetNominationFormKeys.WIND_SUCK}
										items={referenceIsKnownToWindsuck}
										selectedId={values.windSuck.value}
										onChange={handleChangeDropDown}
										styles={errors.windSuck ? { border: '1px solid red' } : {}}
									/>
									<div className={classes.errorTextDropDown}>
										{errors.windSuck}
									</div>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Has this horse been injured?
									</div>
									<Toggle
										id={ResetNominationFormKeys.HAS_HORSE_BEEN_INJURED}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'injured')}
										value={values.injured.value}
									/>
									<span className={classes.errorText}>{errors.injured}</span>
								</div>
							</Col>
							{values.injured.value && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Please upload any veterinarian reports
										</div>
										<UploadPhoto
											id={ResetNominationFormKeys.VET_REPORTS}
											formats={['png', 'jpeg', 'pdf']}
											labelName="Upload Veterinarian Reports"
											error={handleUploadError}
											data={values.vetReports.value}
											sizeLimitMB={5}
											numberOfImages={10}
											success={(image) =>
												handlePhotoUpload(image, 'vetReports', 'vetReport')
											}
											getImage={filePreviewHandler}
											removeImage={(image) => removeImage(image, 'vetReports')}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
										<span className={classes.errorText}>
											{errors.vetReports}
										</span>
									</div>
								</Col>
							)}
						</Row>
						{values.injured.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please select injury type(s)*
										</div>
										<FormMultiSelectList
											id={ResetNominationFormKeys.INJURY_TYPES}
											name="injuryTypes"
											listOptions={referenceInjuries}
											selectedIds={values.injuryTypes.value}
											onSelectChange={(val) =>
												handleChangeMultiSelect(val, 'injuryTypes')
											}
											border={errors.injuryTypes ? 'red' : null}
										/>
										<span className={classes.errorText}>
											{errors.injuryTypes}
										</span>
									</div>
								</Col>
							</Row>
						)}
						{values.injured.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div
											className={classes.fieldTitle}
											style={{ float: 'left' }}
										>
											If yes, please explain the nature of injury including
											veterinary care and/or rehabilitation provided*
										</div>
										<ResizingTextArea
											borderColor={errors.injuryNature ? 'red' : '#c7ced1'}
											charLengthStyle={
												isMobile ? { float: 'left', marginBottom: 10 } : {}
											}
											name="injuryNature"
											id={ResetNominationFormKeys.INJURY_NATURE}
											value={values.injuryNature.value}
											onChange={handleChange}
											maxChars={700}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										/>
										<span className={classes.errorText}>
											{errors.injuryNature}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<MessageBar
									type="info"
									style={{ marginTop: 5, marginBottom: 5 }}
								>
									Do you have any knowledge of the following in relation to this
									horse, past or present?
								</MessageBar>
							</Col>
						</Row>
						<Row>
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Heritable disorder?</div>
									<Toggle
										id={ResetNominationFormKeys.HERITABLE_DISORDER}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'heritableDisorder')
										}
										value={values.heritableDisorder.value}
									/>
									<span className={classes.errorText}>
										{errors.heritableDisorder}
									</span>
								</div>
							</Col>
							{isMobile && values.heritableDisorder.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of heritable
											disorder*
										</div>
										<FormTextField
											name="heritableDisorderDetails"
											id={ResetNominationFormKeys.HERITABLE_DISORDER_DETAILS}
											value={values.heritableDisorderDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.heritableDisorderDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.heritableDisorderDetails}
										</span>
									</div>
								</Col>
							)}
							<Col
								sm={12}
								lg={4}
								style={!isMobile ? { paddingRight: 2, paddingLeft: 2 } : {}}
							>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Disease?</div>
									<Toggle
										id={ResetNominationFormKeys.DISEASE}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'disease')}
										value={values.disease.value}
									/>
									<span className={classes.errorText}>{errors.disease}</span>
								</div>
							</Col>
							{isMobile && values.disease.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of diseases*
										</div>
										<FormTextField
											name="diseaseDetails"
											id={ResetNominationFormKeys.DISEASE_DETAILS}
											value={values.diseaseDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.diseaseDetails ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.diseaseDetails}
										</span>
									</div>
								</Col>
							)}
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Accidents?</div>
									<Toggle
										id={ResetNominationFormKeys.ACCIDENTS}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'accidents')}
										value={values.accidents.value}
									/>
									<span className={classes.errorText}>{errors.accidents}</span>
								</div>
							</Col>
							{isMobile && values.accidents.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of accidents*
										</div>
										<FormTextField
											name="accidentsDetails"
											id={ResetNominationFormKeys.ACCIDENTS_DETAILS}
											value={values.accidentsDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.accidentsDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.accidentsDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.heritableDisorder.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of heritable
											disorder*
										</div>
										<FormTextField
											name="heritableDisorderDetails"
											id={ResetNominationFormKeys.HERITABLE_DISORDER_DETAILS}
											value={values.heritableDisorderDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.heritableDisorderDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.heritableDisorderDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.disease.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of diseases*
										</div>
										<FormTextField
											name="diseaseDetails"
											id={ResetNominationFormKeys.DISEASE_DETAILS}
											value={values.diseaseDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.diseaseDetails ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.diseaseDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.accidents.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of accidents*
										</div>
										<FormTextField
											name="accidentsDetails"
											id={ResetNominationFormKeys.ACCIDENTS_DETAILS}
											value={values.accidentsDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.accidentsDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.accidentsDetails}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Eye disease, trauma or impaired vision?
									</div>
									<Toggle
										id={ResetNominationFormKeys.EYE_DISEASE}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'eyeDisease')}
										value={values.eyeDisease.value}
									/>
									<span className={classes.errorText}>{errors.eyeDisease}</span>
								</div>
							</Col>
							{isMobile && values.eyeDisease.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of eye diseases,
											trauma or impaired vision*
										</div>
										<FormTextField
											name="eyeDiseaseDetails"
											id={ResetNominationFormKeys.EYE_DISEASE_DETAILS}
											value={values.eyeDiseaseDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.eyeDiseaseDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.eyeDiseaseDetails}
										</span>
									</div>
								</Col>
							)}
							<Col
								sm={12}
								lg={4}
								style={!isMobile ? { paddingRight: 2, paddingLeft: 2 } : {}}
							>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Lameness?</div>
									<Toggle
										id={ResetNominationFormKeys.LAMENESS}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'lameness')}
										value={values.lameness.value}
									/>
									<span className={classes.errorText}>{errors.lameness}</span>
								</div>
							</Col>
							{isMobile && values.lameness.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of lameness*
										</div>
										<FormTextField
											name="lamenessDetails"
											id={ResetNominationFormKeys.LAMENESS_DETAILS}
											value={values.lamenessDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.lamenessDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.lamenessDetails}
										</span>
									</div>
								</Col>
							)}
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Head shaking, stringhalt or locking stifles?
									</div>
									<Toggle
										id={ResetNominationFormKeys.HEAD_SHAKING}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'headShaking')}
										value={values.headShaking.value}
									/>
									<span className={classes.errorText}>
										{errors.headShaking}
									</span>
								</div>
							</Col>
							{isMobile && values.headShaking.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of head shaking,
											stringhalt or locking stifles*
										</div>
										<FormTextField
											name="headShakingDetails"
											id={ResetNominationFormKeys.HEAD_SHAKING_DETAILS}
											value={values.headShakingDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.headShakingDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.headShakingDetails}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							{!isMobile && values.eyeDisease.value && (
								<Col lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of eye diseases,
											trauma or impaired vision*
										</div>
										<FormTextField
											name="eyeDiseaseDetails"
											id={ResetNominationFormKeys.EYE_DISEASE_DETAILS}
											value={values.eyeDiseaseDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.eyeDiseaseDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.eyeDiseaseDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.lameness.value && (
								<Col lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of lameness*
										</div>
										<FormTextField
											name="lamenessDetails"
											id={ResetNominationFormKeys.LAMENESS_DETAILS}
											value={values.lamenessDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.lamenessDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.lamenessDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.headShaking.value && (
								<Col lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of head shaking,
											stringhalt or locking stifles*
										</div>
										<FormTextField
											name="headShakingDetails"
											id={ResetNominationFormKeys.HEAD_SHAKING_DETAILS}
											value={values.headShakingDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.headShakingDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.headShakingDetails}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Vices (windsucks, weaves, kicks, bucks etc)
									</div>
									<Toggle
										id={ResetNominationFormKeys.VICES}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'vices')}
										value={values.vices.value}
									/>
									<span className={classes.errorText}>{errors.vices}</span>
								</div>
							</Col>
							{isMobile && values.vices.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of vices(windsucks,
											weaves, kicks, bucks etc)*
										</div>
										<FormTextField
											name="vicesDetails"
											id={ResetNominationFormKeys.VICES_DETAILS}
											value={values.vicesDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.vicesDetails ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.vicesDetails}
										</span>
									</div>
								</Col>
							)}
							<Col
								sm={12}
								lg={4}
								style={!isMobile ? { paddingRight: 2, paddingLeft: 2 } : {}}
							>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Abnormalities of breathing in rest/exercise?
									</div>
									<Toggle
										id={ResetNominationFormKeys.ABNORMALITY}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'abnormality')}
										value={values.abnormality.value}
									/>
									<span className={classes.errorText}>
										{errors.abnormality}
									</span>
								</div>
							</Col>
							{isMobile && values.abnormality.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of abnormalities of
											breathing in rest/exercise*
										</div>
										<FormTextField
											name="abnormalityDetails"
											id={ResetNominationFormKeys.ABNORMALITY_DETAILS}
											value={values.abnormalityDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.abnormalityDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.abnormalityDetails}
										</span>
									</div>
								</Col>
							)}
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Surgical procedures (colic, orthopedic etc)?
									</div>
									<Toggle
										id={ResetNominationFormKeys.SURGICAL}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'surgical')}
										value={values.surgical.value}
									/>
									<span className={classes.errorText}>{errors.surgical}</span>
								</div>
							</Col>
							{isMobile && values.surgical.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of surgical
											procedures(colic, orthopedic etc)*
										</div>
										<FormTextField
											name="surgicalDetails"
											id={ResetNominationFormKeys.SURGICAL_DETAILS}
											value={values.surgicalDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.surgicalDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.surgicalDetails}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							{!isMobile && values.vices.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of vices(windsucks,
											weaves, kicks, bucks etc)*
										</div>
										<FormTextField
											name="vicesDetails"
											id={ResetNominationFormKeys.VICES_DETAILS}
											value={values.vicesDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.vicesDetails ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.vicesDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.abnormality.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of abnormalities of
											breathing in rest/exercise*
										</div>
										<FormTextField
											name="abnormalityDetails"
											id={ResetNominationFormKeys.ABNORMALITY_DETAILS}
											value={values.abnormalityDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.abnormalityDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.abnormalityDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.surgical.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of surgical
											procedures(colic, orthopedic etc)*
										</div>
										<FormTextField
											name="surgicalDetails"
											id={ResetNominationFormKeys.SURGICAL_DETAILS}
											value={values.surgicalDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.surgicalDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.surgicalDetails}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Vaccinations administered?
									</div>
									<Toggle
										id={ResetNominationFormKeys.VACCINATION}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'vaccination')}
										value={values.vaccination.value}
									/>
									<span className={classes.errorText}>
										{errors.vaccination}
									</span>
								</div>
							</Col>
							{isMobile && values.vaccination.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of vaccinations
											administered*
										</div>
										<FormTextField
											name="vaccinationDetails"
											id={ResetNominationFormKeys.VACCINATION_DETAILS}
											value={values.vaccinationDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.vaccinationDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.vaccinationDetails}
										</span>
									</div>
								</Col>
							)}
							<Col sm={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Breeding history?</div>
									<Toggle
										id={ResetNominationFormKeys.BREEDING}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'breeding')}
										value={values.breeding.value}
									/>
									<span className={classes.errorText}>{errors.breeding}</span>
								</div>
							</Col>
							{isMobile && values.breeding.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of breeding history*
										</div>
										<FormTextField
											name="breedingOther"
											id={ResetNominationFormKeys.BREEDING_DETAILS}
											value={values.breedingOther.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.breedingOther ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.breedingOther}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							{!isMobile && values.vaccination.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of vaccinations
											administered (Strangles, tetanus, herpes, equity or
											other)*
										</div>
										<FormTextField
											name="vaccinationDetails"
											id={ResetNominationFormKeys.VACCINATION_DETAILS}
											value={values.vaccinationDetails.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.vaccinationDetails
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.vaccinationDetails}
										</span>
									</div>
								</Col>
							)}
							{!isMobile && values.breeding.value && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											If yes, please provide further detail of breeding history*
										</div>
										<FormTextField
											name="breedingOther"
											id={ResetNominationFormKeys.BREEDING_DETAILS}
											value={values.breedingOther.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.breedingOther ? { border: '1px solid red' } : {}
											}
										/>
										<span className={classes.errorText}>
											{errors.breedingOther}
										</span>
									</div>
								</Col>
							)}
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderThoroughbredInformation = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Thoroughbred Information"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.THOROUGHBRED_INFORMATION_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										To the best of your knowledge, how would you describe this
										horse&apos;s temperament?*
									</div>
									<ResizingTextArea
										borderColor={errors.temperament ? 'red' : '#c7ced1'}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="temperament"
										value={values.temperament.value}
										onChange={handleChange}
										maxChars={500}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.temperament}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Based on your experience, what discipline(s) do you believe
										this horse will be suited to?*
									</div>
									<FormMultiSelectList
										id={ResetNominationFormKeys.DISCIPLINES}
										name="disciplines"
										listOptions={referenceSpecialtyDisciplines}
										selectedIds={values.disciplines.value}
										onSelectChange={(val) =>
											handleChangeMultiSelect(val, 'disciplines')
										}
										border={errors.disciplines ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.disciplines}
									</span>
								</div>
							</Col>
							{values.disciplines.value.filter((rec) => rec.label === 'Other')
								.length > 0 && (
								<Col xs={12} lg={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Please specify Other disciplines*
										</div>
										<FormTextField
											name="disciplinesOther"
											id={ResetNominationFormKeys.OTHER_DISCIPLINES}
											value={values.disciplinesOther.value}
											canEdit={true}
											onChange={handleChange}
											styles={
												errors.disciplinesOther
													? { border: '1px solid red' }
													: {}
											}
										/>
										<span className={classes.errorText}>
											{errors.disciplinesOther}
										</span>
									</div>
								</Col>
							)}
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Please provide a detailed history of this horse&apos;s
										ridden education. If applicable, please specifically include
										any training undertaken away from the racetrack (e.g.
										dressage training).*
									</div>
									<ResizingTextArea
										borderColor={errors.riddenHistory ? 'red' : '#c7ced1'}
										charLengthStyle={
											isMobile ? { float: 'left', marginBottom: 10 } : {}
										}
										name="riddenHistory"
										id={ResetNominationFormKeys.RIDDEN_HISTORY}
										value={values.riddenHistory.value}
										onChange={handleChange}
										maxChars={700}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.riddenHistory}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Any other relevant matters or details we should be aware of
										(known vices, behaviour etc)?*
									</div>
									<FormTextField
										name="vicesBehaviour"
										id={ResetNominationFormKeys.VICES_BEHAVIOUR}
										value={values.vicesBehaviour.value}
										canEdit={true}
										onChange={handleChange}
										styles={
											errors.vicesBehaviour ? { border: '1px solid red' } : {}
										}
									/>
									<span className={classes.errorText}>
										{errors.vicesBehaviour}
									</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderThoroughbredPhotos = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Thoroughbred Photos"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.THOROUGHBRED_PHOTOS_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col>
								<MessageBar type="primary" style={{ marginTop: 10 }}>
									To meet the requirements, please provide{' '}
									<b>at least 4 photos.</b>
									<br />
									Videos are encouraged, please email to{' '}
									<a
										className={classes.mailLink}
										href="mailto:offthetrack@racingvictoria.net.au"
									>
										offthetrack@racingvictoria.net.au
									</a>
									<br />
									<br />
									<a
										href={'/photo-upload-guide'}
										target="_blank"
										rel="noreferrer"
										className={classes.mailLink}
									>
										<b>
											Need help with photo requirements? Click here to view
											examples of suitable photos of your thoroughbred
										</b>
									</a>
								</MessageBar>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Face On - Full Length & Legs (jpg, png)*
									</div>
									<UploadPhoto
										id={ResetNominationFormKeys.FACE_ON}
										formats={['png', 'jpeg']}
										labelName="Upload Face On Photo"
										error={handleUploadError}
										data={values.faceOn.value}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(image) =>
											handlePhotoUpload(image, 'faceOn', 'faceOn')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'faceOn')}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
									<span className={classes.errorText}>{errors.faceOn}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Left Side - Full Length & Brand (jpg, png)*
									</div>
									<UploadPhoto
										id={ResetNominationFormKeys.LEFT_SIDE}
										formats={['png', 'jpeg']}
										labelName="Upload Left Side Photo"
										error={handleUploadError}
										data={values.leftSide.value}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(image) =>
											handlePhotoUpload(image, 'leftSide', 'leftSide')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'leftSide')}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
									<span className={classes.errorText}>{errors.leftSide}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Right Side - Full Length & Brand (jpg, png)*
									</div>
									<UploadPhoto
										id={ResetNominationFormKeys.RIGHT_SIDE}
										formats={['png', 'jpeg']}
										labelName="Upload Right Side Photo"
										error={handleUploadError}
										data={values.rightSide.value}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(image) =>
											handlePhotoUpload(image, 'rightSide', 'rightSide')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'rightSide')}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
									<span className={classes.errorText}>{errors.rightSide}</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Behind - Full Length & Legs (jpg, png)*
									</div>
									<UploadPhoto
										id={ResetNominationFormKeys.BEHIND}
										formats={['png', 'jpeg']}
										labelName="Upload Behind Photo"
										error={handleUploadError}
										data={values.behind.value}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(image) =>
											handlePhotoUpload(image, 'behind', 'behindFull')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'behind')}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
									<span className={classes.errorText}>{errors.behind}</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Additional Photos (jpg, png)
									</div>
									<UploadPhoto
										id={ResetNominationFormKeys.ADDITIONAL}
										formats={['png', 'jpeg']}
										labelName="Upload Additional Photo"
										error={handleUploadError}
										data={values.additional.value}
										sizeLimitMB={5}
										numberOfImages={10}
										success={(image) =>
											handlePhotoUpload(image, 'additional', 'additional')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'additional')}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
									<span className={classes.errorText}>{errors.additional}</span>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderRehomingDeclaration = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Rehoming Declaration"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.REHOMING_DECLARATION_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'rehomingAttempt')
										}
										custom
										customStyle={
											errors.rehomingAttempt
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.REHOMING_ATTEMPT,
											isSelected: values.rehomingAttempt.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I declare there has been a minimum of two rehoming attempts
										prior to nomination.*
									</div>
								</div>
							</Col>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'noBehaviouralissues')
										}
										custom
										customStyle={
											errors.noBehaviouralissues
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.NO_BEHAVIOURAL_ISSUES,
											isSelected: values.noBehaviouralissues.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I do not know of any behavioural issues that would prohibit
										safe rehoming of this horse.*
									</div>
								</div>
							</Col>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'noHealthIssues')
										}
										custom
										customStyle={
											errors.noHealthIssues
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.NO_HEALTH_ISSUES,
											isSelected: values.noHealthIssues.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I do not know of any health or veterinary related issues
										that would prohibit safe rehoming.*
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderProgramDeclaration = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Program Declaration"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.PROGRAM_DECLARATION_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'responsibleOwner')
										}
										custom
										customStyle={
											errors.responsibleOwner
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.RESPONSIBLE_OWNER,
											isSelected: values.responsibleOwner.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I remain the responsible owner of this horse throughout
										RESET until successfully rehomed*
									</div>
								</div>
							</Col>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'cantBeReHomed')
										}
										custom
										customStyle={
											errors.cantBeReHomed
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.CANT_BE_REHOMED,
											isSelected: values.cantBeReHomed.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										If the horse can&apos;t be re-homed following RESET, I will
										continue to be the owner of the horse and the horse will be
										returned to my care.*
									</div>
								</div>
							</Col>
							<Col xs={12} lg={12}>
								<div
									className={classes.checkboxFieldContainer}
									style={isMobile ? { fontSize: 13 } : {}}
								>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'rehomingProgram')
										}
										custom
										customStyle={
											errors.rehomingProgram
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.REHOMING_PROGRAM,
											isSelected: values.rehomingProgram.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										This is a re-homing program and it is my intention to
										re-home this horse as a result of RESET.*
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'agreeCompletionOfForm')
										}
										custom
										customStyle={
											errors.agreeCompletionOfForm
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.AGREE_COMPLETION_OF_FORM,
											isSelected: values.agreeCompletionOfForm.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I acknowledge and agree that completion of this form does
										not guarantee my horse&apos;s entry into RESET and that the
										decision as to whether to accept my horse into RESET shall
										be at RV&apos;s absolute discretion.*
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'disabledAssociation')
										}
										custom
										customStyle={
											errors.disabledAssociation
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.DISABLED_ASSOCIATION,
											isSelected: values.disabledAssociation.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										If the horse is deemed suitable for use in Riding for the
										Disabled Association of Victoria Inc ABN 20 130 814 132
										(RDAV) activities, ownership of that horse may, subject to
										the RDAV&apos;s agreement, be transferred to RDAV at the
										conclusion of that horse&apos;s involvement in RESET, at no
										cost, and there will be no attempt to sell the horse.*
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'salePrice')
										}
										custom
										customStyle={
											errors.salePrice
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.SALE_PRICE,
											isSelected: values.salePrice.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										If the horse is able to be re-homed, Racing Victoria will,
										in its absolute discretion determine the sale price, if any,
										of the horse.*
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'RVagreement')
										}
										custom
										customStyle={
											errors.RVagreement
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.RV_AGREEMENT,
											isSelected: values.RVagreement.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										My horse&apos;s entry into RESET is subject to me executing
										an agreement with Racing Victoria and the relevant service
										provider (e.g. the retrainer), a copy of which will be
										provided to me for review if my horse is deemed suitable for
										the program.*
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) =>
											handleCheckbox(id, 'privacyStatement')
										}
										custom
										customStyle={
											errors.privacyStatement
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.PRIVACY_STATEMENT,
											isSelected: values.privacyStatement.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : { fontSize: 15 }}
									>
										<b>I, the applicant hereby:*</b>
										<div style={isMobile ? {} : { marginLeft: 20 }}>
											&bull;&nbsp;Declare that all details in my application are
											true and correct; <br />
											&bull;&nbsp;Acknowledge that Racing Victoria may request
											further information from me in respect of this
											application; <br />
											&bull;&nbsp;Consent to Racing Victoria obtaining personal
											information about me from others and using & disclosing my
											personal information as noted in the attached
											<a
												href={'/reset-form-privacy-policy'}
												target="_blank"
												rel="noreferrer"
												className={classes.mailLink}
											>
												{' '}
												Privacy Policy.
											</a>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const renderVeterinaryExamination = () => {
		let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);
		return (
			<>
				<Accordion
					title="Veterinary Examination"
					isOpen
					dropDownStyle={isMobile ? { marginTop: 17 } : {}}
					titleStyle={isMobile ? { color: 'black', fontSize: 15 } : {}}
					styles={
						isMobile
							? {
									marginTop: 0,
									background: `url(${BannerImage})`,
									backgroundSize: 'contain'
							  }
							: {
									marginTop: 0,
									background: `url(${BannerImage})`,
									borderBottom: '1px solid #b3bcc1'
							  }
					}
				>
					<div
						id={ResetNominationFormKeys.VETERINARY_EXAMINATION_FORM}
						className={classes.formFieldsContainer}
					>
						<Row>
							<Col>
								<div className={classes.checkboxFieldContainer}>
									<SimpleCheckBox
										changeCheckboxState={(id) => handleCheckbox(id, 'concent')}
										custom
										customStyle={
											errors.concent
												? { float: 'left', border: '1px solid red' }
												: { float: 'left' }
										}
										data={{
											id: ResetNominationFormKeys.CONCENT,
											isSelected: values.concent.value
										}}
									/>

									<div
										className={classes.checkboxField}
										style={isMobile ? { fontSize: 13 } : {}}
									>
										I consent to a veterinary examination on the above horse by
										a Racing Victoria appointed veterinary as part of entry into
										the Racing Victoria RESET Program; I understand that the
										examination will include a physical examination; and I
										understand that every examination carries a finite risk. I
										accept that information gained in the course of this
										examination is the property of Racing Victoria.*
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Accordion>
			</>
		);
	};

	const getImagesList = () => {
		let listOfKeys = [];
		if (values.additional.value.length > 0) {
			values.additional.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.behind.value.length > 0) {
			values.behind.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.faceOn.value.length > 0) {
			values.faceOn.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.leftSide.value.length > 0) {
			values.leftSide.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.rightSide.value.length > 0) {
			values.rightSide.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.vetReports.value.length > 0) {
			values.vetReports.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.proofOfPurchaseDocuments.value.length > 0) {
			values.proofOfPurchaseDocuments.value.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		return listOfKeys;
	};

	const onSubmitOnBehalfByAdmin = (e) => {
		e.preventDefault();
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
			return;
		} else {
			setIsSelectUserRequired(false);
		}
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let items = { ...values };
			if (isSubmitOnBehalf) {
				items = {
					...items,
					residentialAddress: {
						value: selectedUserVerifiedInfo?.residentialAddress?.address
					},
					residentialSuburb: {
						value: selectedUserVerifiedInfo?.residentialAddress?.suburb
					},
					residentialPostcode: {
						value: selectedUserVerifiedInfo?.residentialAddress?.postcode
					},
					residentialState: {
						value: selectedUserVerifiedInfo?.residentialAddress?.state
					},
					residentialCountry: {
						value: selectedUserVerifiedInfo?.residentialAddress?.country
					}
				};
			}

			let resetFormData = setDataModel(items, id);
			resetFormData = {
				...resetFormData,
				isSubmitOnBehalf: true,
				memberId: selectedUserVerifiedInfo.id
			};

			AppSyncService.execute(saveResetForm, {
				resetFormData
			}).then((data) => {
				AppSyncService.execute(updateResetFormStatus, {
					id: data.data?.saveResetNominationForm?.id,
					status: 'Review'
				}).then((data) => {
					if (data.data?.saveResetNominationFormStatus?.success) {
						addToast({ Message: 'Form Submitted', IsSuccess: true });
						navigate(DASHBOARD_ROUTE);
					} else {
						addToast({ Message: 'Failed to submit form', IsSuccess: true });
						logToServer(
							currentUser?.UserId,
							data,
							`Failed to save RESET nomination form due to backend error.`
						);
					}
				});
				saveResidentialAddressProfile();
				updateProfile();
				const input = {
					horseCode: id,
					statusId: 12 // RESET - In Review, TODO - figure out a better constant ref system
				};
				AppSyncService.execute(saveRetrainerHorseStatus, {
					input
				});
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let resetFormData = setDataModel(values, id);
			AppSyncService.execute(saveResetForm, {
				resetFormData
			}).then((data) => {
				AppSyncService.execute(updateResetFormStatus, {
					id: data.data?.saveResetNominationForm?.id,
					status: 'Review'
				}).then((data) => {
					if (data.data?.saveResetNominationFormStatus?.success) {
						addToast({ Message: 'Form Submitted', IsSuccess: true });
						navigate(DASHBOARD_ROUTE);
					} else {
						addToast({ Message: 'Failed to submit form', IsSuccess: true });
						logToServer(
							currentUser?.UserId,
							data,
							`Failed to save RESET nomination form due to backend error.`
						);
					}
				});
				saveResidentialAddressProfile();
				const input = {
					horseCode: id,
					statusId: 12 // RESET - In Review, TODO - figure out a better constant ref system
				};
				AppSyncService.execute(saveRetrainerHorseStatus, {
					input
				});
			});
		}
	};

	const handleDraft = (e) => {
		e.preventDefault();
		let resetFormData = setDataModel(values, id);
		AppSyncService.execute(saveResetForm, {
			resetFormData
		}).then((data) => {
			if (data.data?.saveResetNominationForm?.success) {
				addToast({ Message: 'Form Saved', IsSuccess: true });
				navigate(DASHBOARD_ROUTE);
			} else {
				addToast({ Message: 'Failed to save draft', IsSuccess: false });
				logToServer(
					currentUser?.UserId,
					data,
					`Failed to save RESET nomination draft due to backend error.`
				);
			}
		});
	};

	const handleCancel = (e) => {
		if (formId) {
			e.preventDefault();
			let listOfKeys = getImagesList();
			if (listOfKeys.length > 0) {
				AppSyncService.execute(deleteImagesFromS3, {
					imageKeys: listOfKeys
				}).then((data) => {
					if (data?.data?.deleteUserImages?.success) {
						removeResetForm();
					}
				});
			} else {
				removeResetForm();
			}
		} else {
			onCancelSuccess();
		}
	};

	const removeResetForm = () => {
		AppSyncService.execute(deleteResetForm, {
			id: formId
		}).then((data) => {
			if (data.data != null) {
				onCancelSuccess();
			} else {
				addToast({ Message: 'Error Cancelling Form', IsSuccess: false });
			}
		});
	};

	const onCancelSuccess = () => {
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigate(DASHBOARD_ROUTE);
	};

	return (
		<>
			<SEO title="RESET form" />
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			{!isLoading && (
				<Container
					className={
						['xs', 'sm', 'md'].includes(screenClassForRender)
							? classes.mobileContainer
							: classes.container
					}
				>
					{isAdminUser ? (
						<SubmitOnBehalf
							horseCode={id}
							onToggleSubmitBehalf={onToggleSubmitBehalf}
							onSelectUserVerified={onSelectUserVerified}
							isGetAllUsersVerified
							selectUserRequiredError={selectUserRequiredError}
							isSelectUserRequired={isSelectUserRequired}
						/>
					) : null}
					<MessageBar type="message">
						<div
							className={classes.messageBar}
							style={
								['xs', 'sm', 'md'].includes(screenClassForRender)
									? { display: 'block' }
									: {}
							}
						>
							<div
								className={classes.claimText}
								style={
									['xs', 'sm', 'md'].includes(screenClassForRender)
										? { fontSize: 13, marginBottom: 10, marginTop: 0 }
										: {}
								}
							>
								New to RESET? Find out all about the RESET program&apos;s
								benefits
							</div>
							<a
								style={{ textDecoration: 'none' }}
								href="/programs/reset-landing-page"
								target="_blank"
								rel="noreferrer"
							>
								<PrimaryButton
									id={ResetNominationFormKeys.WHAT_IS_RESET}
									style={
										['xs', 'sm', 'md'].includes(screenClassForRender)
											? {
													marginTop: 0,
													width: '100%',
													height: 50,
													fontWeight: 600
											  }
											: {
													marginTop: 0,
													width: 220,
													height: 50,
													fontWeight: 600
											  }
									}
								>
									What is RESET?
								</PrimaryButton>
							</a>
						</div>
					</MessageBar>
					<Row>
						<Col>
							<Accordion
								title="RESET Application Form"
								isOpen
								dropDownStyle={
									['xs', 'sm', 'md'].includes(screenClassForRender)
										? { marginTop: 17 }
										: {}
								}
								titleStyle={
									['xs', 'sm', 'md'].includes(screenClassForRender)
										? { color: 'black', fontSize: 15 }
										: {}
								}
								styles={
									['xs', 'sm', 'md'].includes(screenClassForRender)
										? {
												marginTop: 20,
												background: `url(${BannerImage})`,
												backgroundSize: 'contain'
										  }
										: { marginTop: 20, background: `url(${BannerImage})` }
								}
							>
								<MessageBar
									style={
										['xs', 'sm', 'md'].includes(screenClassForRender)
											? { fontSize: 13 }
											: {}
									}
								>
									The <b>RESET</b> (Racehorse, Evaluation, Support, Education
									and Transition) Program provides direct support for those
									horses that are sound and have good prospects but have not
									been successful in transitioning to a second career outside of
									racing.
									<br />
									<br />
									Horses accepted into RESET will undergo retraining funded by
									RV&apos;s equine welfare fund with the goal of responsible
									rehoming at the conclusion of the program, thereby improving
									welfare outcomes for graduates. This Nomination Form will
									assist Racing Victoria in determining a horse&apos;s
									suitability for RESET.
									<br />
									<br />
									If you have a horse you think is suitable for the program,
									please complete the below form or if you need more information
									please <br />
									email{' '}
									<a
										className={classes.mailLink}
										href="mailto:offthetrack@racingvictoria.net.au"
									>
										offthetrack@racingvictoria.net.au
									</a>
									<br />
									<br />
									<b>I declare that I am (select at least 1 option):</b>
									<br />
									<br />
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'registeredOwner')
											}
											custom
											customStyle={
												errors.registeredOwner
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: ResetNominationFormKeys.REGISTERED_OWNER,
												isSelected: values.registeredOwner.value
											}}
										/>

										<div className={classes.checkboxField}>
											<b>
												The Registered Owner of this horse (as per Racing
												Victoria records); OR
											</b>
										</div>
									</div>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'registeredManagingOwner')
											}
											custom
											customStyle={
												errors.registeredManagingOwner
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: ResetNominationFormKeys.REGISTERED_MANAGING_OWNER,
												isSelected: values.registeredManagingOwner.value
											}}
										/>

										<div className={classes.checkboxField}>
											<b>
												The Registered Managing Owner of this horse (as per
												Racing Victoria records); OR
											</b>
										</div>
									</div>
									<div className={classes.checkboxFieldContainer}>
										<SimpleCheckBox
											changeCheckboxState={(id) =>
												handleCheckbox(id, 'proofOwner')
											}
											custom
											customStyle={
												errors.proofOwner
													? { float: 'left', border: '1px solid red' }
													: { float: 'left' }
											}
											data={{
												id: ResetNominationFormKeys.PROOF_OWNER,
												isSelected: values.proofOwner.value
											}}
										/>

										<div className={classes.checkboxField}>
											<b>
												I have attached proof of purchase and/or a Statutory
												Declaration documenting me as the owner of this horse.
											</b>
										</div>
									</div>
									{values.proofOwner.value && (
										<Row>
											<Col xs={12} lg={4}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>
														Relevant Document*
													</div>
													<UploadPhoto
														id={
															ResetNominationFormKeys.PROOF_OF_PURCHASE_DOCUMENTS
														}
														formats={['png', 'jpeg', 'pdf']}
														labelName="Upload Documents"
														error={handleUploadError}
														data={values.proofOfPurchaseDocuments.value}
														sizeLimitMB={5}
														numberOfImages={5}
														success={(image) =>
															handlePhotoUpload(
																image,
																'proofOfPurchaseDocuments',
																'proofOfPurchase'
															)
														}
														getImage={filePreviewHandler}
														removeImage={(image) =>
															removeImage(image, 'proofOfPurchaseDocuments')
														}
														isDisabled={
															isSubmitOnBehalf && !selectedUserVerifiedInfo
														}
													/>
													<span className={classes.errorText}>
														{errors.proofOfPurchaseDocuments}
													</span>
												</div>
											</Col>
										</Row>
									)}
								</MessageBar>
							</Accordion>
						</Col>
					</Row>
					<form>
						<div
							className={classes.formContainer}
							style={
								['xs', 'sm', 'md'].includes(screenClassForRender)
									? { border: 'none' }
									: {}
							}
						>
							<Row>
								<Col>{renderThorougbredDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderContactDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderThorougbredInjuryDetails()}</Col>
							</Row>
							<Row>
								<Col>{renderThoroughbredInformation()}</Col>
							</Row>
							<Row>
								<Col>{renderThoroughbredPhotos()}</Col>
							</Row>
							<Row>
								<Col>{renderRehomingDeclaration()}</Col>
							</Row>
							<Row>
								<Col>{renderProgramDeclaration()}</Col>
							</Row>
							<Row>
								<Col>{renderVeterinaryExamination()}</Col>
							</Row>
							<hr className={classes.IdentificationDivider}></hr>
							{!['xs', 'sm', 'md'].includes(screenClassForRender) && (
								<Row>
									<Col>
										<div
											className={classes.buttonContainer}
											style={
												['xs', 'sm', 'md'].includes(screenClassForRender)
													? { padding: 0 }
													: {}
											}
										>
											<div
												style={
													values.status.value != 'Review'
														? {}
														: { visibility: 'hidden' }
												}
												className={classes.cancelRequest}
												onClick={() => setConfirmationModal(true)}
												id={CommonKeys.CANCEL_APPLICATION}
											>
												Cancel Request
											</div>
											<div className={classes.saveRequest}>
												<span
													style={
														values.status.value != 'Review'
															? {}
															: { visibility: 'hidden' }
													}
													className={classes.saveButton}
													onClick={handleDraft}
													id={CommonKeys.SAVE_DRAFT}
												>
													Save Draft
												</span>
												<span>
													<PrimaryButton
														onClick={
															isSubmitOnBehalf
																? onSubmitOnBehalfByAdmin
																: handleSubmit
														}
														id={CommonKeys.SUBMIT}
														style={{ width: '165px', marginTop: 0 }}
														disabled={values.status.value === 'Review'}
													>
														Complete Request
													</PrimaryButton>
												</span>
											</div>
										</div>
									</Col>
								</Row>
							)}
							{['xs', 'sm', 'md'].includes(screenClassForRender) && (
								<Row>
									<Col xs={3}>
										<div
											style={
												values.status.value != 'Review'
													? {}
													: { visibility: 'hidden' }
											}
											className={classes.cancelRequestMobile}
											onClick={() => setConfirmationModal(true)}
											id={CommonKeys.CANCEL_APPLICATION}
										>
											Cancel Request
										</div>
									</Col>

									<Col xs={2}>
										<div className={classes.saveRequest}>
											<div
												style={
													values.status.value != 'Review'
														? {}
														: { visibility: 'hidden' }
												}
												className={classes.saveButton}
												onClick={handleDraft}
												id={CommonKeys.SAVE_DRAFT}
											>
												Save Draft
											</div>
										</div>
									</Col>

									<Col xs={7}>
										<PrimaryButton
											onClick={
												isSubmitOnBehalf
													? onSubmitOnBehalfByAdmin
													: handleSubmit
											}
											id={CommonKeys.SUBMIT}
											style={{ width: '100%', marginTop: 0 }}
											disabled={values.status.value === 'Review'}
										>
											Complete Request
										</PrimaryButton>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									{' '}
									{(Object.keys(errors).length > 0 || isSelectUserRequired) && (
										<div
											className={classes.errorMessage}
											style={
												['xs', 'sm', 'md'].includes(screenClassForRender)
													? {}
													: { marginRight: 20, marginTop: 0, marginBottom: 20 }
											}
										>
											Please complete all required fields
										</div>
									)}
								</Col>
							</Row>
						</div>
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Cancel Reset Form"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Are you sure you wish to delete this form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</Container>
			)}
		</>
	);
};

export default ResetForm;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	horseDatumContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: 13,
		paddingBottom: 8,
		paddingTop: 8,
		borderBottom: '2px solid #edf5f9',
		'@media (min-width:992px)': {
			fontSize: 15
		}
	},
	datumLabel: {
		color: 'grey'
	},
	datumValue: {
		fontWeight: 600,
		wordBreak: 'break-all'
	}
}));

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTable } from 'react-table';
import { useTheme } from 'react-jss';
import classnames from 'classnames';
import useStyles from './styles';

const Table = ({
	columns,
	data,
	nullString = '',
	id = '',
	title = '',
	tableHeaderExtraClassName = null
}) => {
	const theme = useTheme();
	const classes = useStyles(theme);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	return (
		<div className={classes.tableContainer}>
			{title && <h4>{title}</h4>}
			<table {...getTableProps()} className={classes.table} id={id}>
				<thead>
					{headerGroups.map((headerGroup, trIndex) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							className={classnames({
								[classes.tableHeader]: true,
								[classes[
									tableHeaderExtraClassName
								]]: !!tableHeaderExtraClassName
							})}
							key={`${id}-thead-tr-${trIndex}`}
						>
							{headerGroup.headers.map((column, thIndex) => {
								if (column.Header !== ' ') {
									return (
										<th
											{...column.getHeaderProps()}
											key={`${id}-thead-tr-${trIndex}-th-${thIndex}`}
										>
											{column.render('Header')}
										</th>
									);
								}
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, trIndex) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								className={classes.tableBody}
								key={`${id}-tbody-tr-${trIndex}`}
							>
								{row.cells.map((cell, tdIndex) => {
									return (
										<td
											{...cell.getCellProps()}
											key={`${id}-tbody-tr-${trIndex}-td-${tdIndex}`}
										>
											{cell.value !== null && cell.value !== nullString
												? cell.render('Cell')
												: 'N/A'}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	title: PropTypes.string,
	id: PropTypes.string
};

export default memo(Table);

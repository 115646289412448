import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewDivider from '../../../AdminReviewComponents/AdminReviewDivider';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import { formatDateReadableVariant } from '../../../../utils/helpers';
import UploadPhoto from '../../../UploadPhoto';
import SectionRow from '../../../SectionRow';
import { DOCUMENT_TYPES } from '../../../../utils/constants';

const PersonalDetailsSection = ({
	formData,
	handleChangeDropdownImage,
	previewImage,
	showPersonalDetailError = false
}) => {
	const isPostalAddressDifferent =
		formData?.member?.postalAddress?.address ||
		formData?.postalAddress ||
		formData?.postalSuburb ||
		formData?.postalState ||
		formData?.postalPostcode ||
		formData?.postalCountry;
	const isPersonalUploadHasNoDeleted = formData?.personalDetailUploads.some(
		(detail) => !detail.isDeleted
	);

	if (isPersonalUploadHasNoDeleted) {
		formData.personalDetailUploads = formData?.personalDetailUploads.filter(
			(detail) => !detail.isDeleted
		);
	}
	return (
		<AdminReviewSection title="Personal Details">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="First Name"
						value={formData?.member?.firstName}
					/>
					<AdminReviewDatum
						label="Last Name"
						value={formData?.member?.lastName}
					/>
					<AdminReviewDatum label="Email" value={formData?.member?.email} />
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Contact Number*"
						value={formData?.contactNumber || formData?.member?.phone}
					/>
					<AdminReviewDatum label="Gender" value={formData?.member?.gender} />
					<AdminReviewDatum
						label="Date of Birth"
						value={
							formData?.member?.dateOfBirth
								? formatDateReadableVariant(formData?.member?.dateOfBirth)
								: ''
						}
					/>
				</SectionRow>
			</Col>
			<AdminReviewDivider />
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Residential Address"
						value={
							formData?.member?.residentialAddress?.address ||
							formData?.residentalAddress
						}
					/>
					<AdminReviewDatum
						label="Residential Suburb"
						value={
							formData?.member?.residentialAddress?.suburb ||
							formData?.residentalSuburb
						}
					/>
					<AdminReviewDatum
						label="Residential State"
						value={
							formData?.member?.residentialAddress?.state ||
							formData?.residentalState
						}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Residential Postcode"
						value={
							formData?.member?.residentialAddress?.postcode ||
							formData?.residentalPostcode
						}
					/>
					<AdminReviewDatum
						label="Residential Country"
						value={
							formData?.member?.residentialAddress?.country ||
							formData?.residentalCountry
						}
					/>
				</SectionRow>
			</Col>
			<AdminReviewDivider />
			{isPostalAddressDifferent && (
				<>
					<Col>
						<SectionRow>
							<AdminReviewDatum
								label="Postal Address"
								value={
									formData?.member?.postalAddress?.address ||
									formData?.postalAddress
								}
							/>
							<AdminReviewDatum
								label="Postal Suburb"
								value={
									formData?.member?.postalAddress?.suburb ||
									formData?.postalSuburb
								}
							/>
							<AdminReviewDatum
								label="Postal State"
								value={
									formData?.member?.postalAddress?.state ||
									formData?.postalState
								}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="Postal Postcode"
								value={
									formData?.member?.postalAddress?.postcode ||
									formData?.postalPostcode
								}
							/>
							<AdminReviewDatum
								label="Postal Country"
								value={
									formData?.member?.postalAddress?.country ||
									formData?.postalCountry
								}
							/>
						</SectionRow>
					</Col>
					<AdminReviewDivider />
				</>
			)}
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Stable Address"
						value={formData?.stableAddress}
					/>
					<AdminReviewDatum
						label="Stable Suburb"
						value={formData?.stableSuburb}
					/>
					<AdminReviewDatum
						label="Stable State"
						value={formData?.stableState}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Stable Postcode"
						value={formData?.stablePostcode}
					/>
					<AdminReviewDatum
						label="Stable Country"
						value={formData?.stableCountry}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="I own the above stable address"
						value={formData?.propertyRented ? 'Yes' : 'No'}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						largeScreenWidth={12}
						label={`Upload a valid passport or driver's licence (jpg, png, pdf)`}
						value={
							// isPersonalUploadHasNoDeleted && isApprovedOrReject  ?
							// formData?.personalDetailUploads.filter((detail) => detail.isDeleted).map((detail, index) =>
							// 	<div key={index}>{DOCUMENT_TYPES.find((docType => docType.id === detail.documentType)).value}, sighted by {detail.sightedBy?.firstName} {detail.sightedBy?.lastName} {formatDateReadableVariant(detail.dateApprovedRejected)} </div>
							// )
							// :
							// <UploadPhoto
							// 	handleChangeDropDown={handleChangeDropdownImage}
							// 	data={formData?.personalDetailUploads.filter((detail) => isPersonalUploadHasDeleted && isApprovedOrReject ? detail.isDeleted : !detail.isDeleted)}
							// 	getImage={previewImage}
							// 	showPersonalDetailError={showPersonalDetailError}
							// 	canEdit={false}
							// 	showDropdownType={true}
							// 	documentTypeReference={DOCUMENT_TYPES}
							// />
							isPersonalUploadHasNoDeleted ? (
								<UploadPhoto
									handleChangeDropDown={handleChangeDropdownImage}
									data={formData?.personalDetailUploads}
									getImage={previewImage}
									showPersonalDetailError={showPersonalDetailError}
									canEdit={false}
									showDropdownType={true}
									documentTypeReference={DOCUMENT_TYPES}
								/>
							) : (
								formData?.personalDetailUploads
									.filter((detail) => detail.isDeleted)
									.map((detail, index) => (
										<div key={index}>
											{
												DOCUMENT_TYPES.find(
													(docType) => docType.id === detail.documentType
												).value
											}
											, sighted by {detail.sightedBy?.firstName}{' '}
											{detail.sightedBy?.lastName}{' '}
											{formatDateReadableVariant(detail.dateApprovedRejected)} -{' '}
											{detail.status}
										</div>
									))
							)
						}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default PersonalDetailsSection;

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	generateAdminReviewNotification,
	reviewHorseResetForm,
	saveResetAdminData,
	saveHorseProfileRetirement,
	saveOTTStatusAndProgram
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import { useToast } from '../../hooks/useToast';
import { MAXREASONLENGTH } from './constants';
import AdminActions from './components/AdminActions';
import AdminReviewFormApproval from './components/AdminReviewFormApproval';
import { RESET_NIMONATION_ADMIN_ACTION_FIELDS } from './constants';
import {
	mapAdminActionDataForLocal,
	mapAdminActionForUpdateAPI,
	mapAdminDataForUpdateAPI
} from './utils';
import { APPROVED, REVIEW } from '../../utils/constants';
import { formatDateReadableVariant } from '../../utils/helpers';
import useFilePreview from '../../hooks/useFilePreview';
import { useAuth } from '../../hooks/useAuth';
import SNSAdmin from '../SNSAdmin';
import RetirementCategory from '../RetirementCategory';
import {
	retirementReason,
	windSuckBehaviour
} from '../RetirementCategory/constants';
import { generateDeceasedInputPayload } from '../DeceasedThoroughbredForm/helper';

const PAGE_ID = 'resetReviewForm';
const ResetNominationFormReviewInfo = ({
	data,
	adminActionOptions,
	mobile,
	initialAdminData
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [notificationToUser, setNotificationToUser] = useState('');
	const [adminActions, setAdminActions] = useState(
		RESET_NIMONATION_ADMIN_ACTION_FIELDS
	);
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [error, setError] = useState('');
	const [isFormInvalid, setIsFormInvalid] = useState(false);
	const { currentUser } = useAuth();
	let splitOtherVicesRetirement = [];
	let mapBehaviourRetirement = [];
	if (initialAdminData.behaviourOthersRetirement) {
		splitOtherVicesRetirement = initialAdminData.behaviourOthersRetirement.split(
			','
		);
		mapBehaviourRetirement = splitOtherVicesRetirement.map((otherVices) => ({
			label: otherVices,
			value: otherVices
		}));
	}
	const isPrefillRetirementCategory =
		initialAdminData.retirementCategory === null;
	const isWindsuck = data.isKnownToWindsuck === 'Yes';
	const isInjured = data.injuries?.length > 0;
	const isRehomingHistory = data.rehomingAttempt;
	const isBRetirementCategory = isWindsuck || isInjured || isRehomingHistory;
	let prefilledReasons = [];
	let prefilledBehaviours = [];
	if (isPrefillRetirementCategory) {
		if (isWindsuck) {
			prefilledReasons.push({
				value: retirementReason[1].id,
				label: retirementReason[1].value
			});
			prefilledBehaviours.push(windSuckBehaviour);
		}
		if (isInjured)
			prefilledReasons.push({
				value: retirementReason[4].id,
				label: retirementReason[4].value
			});
		if (isRehomingHistory)
			prefilledReasons.push({
				value: retirementReason[6].id,
				label: retirementReason[6].value
			});
	}
	const [adminData, setAdminData] = useState({
		//Retirement
		selectedCategory:
			isPrefillRetirementCategory && isBRetirementCategory
				? 1
				: initialAdminData.retirementCategory || 0,
		selectedVulnerabilityType: initialAdminData.vulnerabilityType
			? initialAdminData.vulnerabilityType
			: 0,
		selectedReason: isPrefillRetirementCategory
			? prefilledReasons
			: initialAdminData.reasonRetirement?.length > 0
			? initialAdminData.reasonRetirement.map((reason) => {
					return { value: reason.id, label: reason.value };
			  })
			: [],
		selectedVicesRetirement: isPrefillRetirementCategory
			? prefilledBehaviours
			: initialAdminData.habitsRetirement?.length > 0
			? initialAdminData.habitsRetirement.map((selectedHabitsRetirement) => {
					return {
						value: selectedHabitsRetirement.id,
						label: selectedHabitsRetirement.value
					};
			  })
			: [],
		otherVicesRetirement: initialAdminData.behaviourOthersRetirement
			? mapBehaviourRetirement
			: '',
		conformationalDefectsRetirement: initialAdminData.conformationalDetailsRetirement
			? initialAdminData.conformationalDetailsRetirement
			: '',
		selectedEducation:
			initialAdminData.education?.length > 0
				? initialAdminData.education.map((selectedEducation) => {
						return {
							value: selectedEducation.id,
							label: selectedEducation.value
						};
				  })
				: [],
		otherEducationDetails: initialAdminData.otherEducationDetails
			? initialAdminData.otherEducationDetails
			: '',
		selectedInjuriesRetirement: isPrefillRetirementCategory
			? data.injuries?.map((item) => ({
					value: item.id,
					label: item.value
			  })) || []
			: initialAdminData.injuriesRetirement?.length > 0
			? initialAdminData.injuriesRetirement.map((injuriesRetirement) => {
					return {
						value: injuriesRetirement.id,
						label: injuriesRetirement.value
					};
			  })
			: [],
		selectedIllnessRetirement:
			initialAdminData.illnessRetirement?.length > 0
				? initialAdminData.illnessRetirement.map((illnessRetirement) => {
						return {
							value: illnessRetirement.id,
							label: illnessRetirement.value
						};
				  })
				: [],
		otherInjuryDetails: initialAdminData.otherInjuryDetailsRetirement
			? initialAdminData.otherInjuryDetailsRetirement
			: '',
		otherIllnessDetails: initialAdminData.otherIllnessDetailsRetirement
			? initialAdminData.otherIllnessDetailsRetirement
			: '',
		rehomingHistoryDetails: initialAdminData.rehomingHistoryDetails
			? initialAdminData.rehomingHistoryDetails
			: '',
		otherDetailsRetirement: initialAdminData.otherDetailsRetirement
			? initialAdminData.otherDetailsRetirement
			: ''
	});
	const [onValidate, setOnValidate] = useState(isPrefillRetirementCategory);
	const [adminDataErrors, setAdminDataErrors] = useState({
		reasonRetirement: {
			value: false,
			message: `Reason is required*`
		},
		vulnerabilityType: {
			value: false,
			message: 'Vulnerability Type is required*'
		},
		behaviourRetirement: {
			value: false,
			message: `Behaviour is required*`
		},
		conformationalRetirement: {
			value: false,
			message: `Conformational defect/s is required*`
		},
		education: {
			value: false,
			message: `Education is required*`
		},
		educationDetails: {
			value: false,
			message: `Other education - details is required*`
		},
		injuryRetirement: {
			value: false,
			message: `Injury is required*`
		},
		otherInjuryRetirement: {
			value: false,
			message: `Other injury details is required*`
		},
		illnessRetirement: {
			value: false,
			message: `Illness is required*`
		},
		otherIllnessRetirement: {
			value: false,
			message: `Other illness details is required*`
		},
		rehomingHistory: {
			value: false,
			message: `Rehoming history - details is required*`
		},
		otherDetailsRetirement: {
			value: false,
			message: `Other - details is required*`
		},
		behaviourOtherRetirement: {
			value: false,
			message: 'Other is required*'
		}
	});

	useEffect(() => {
		if (onValidate) {
			evaluateAdminError();
			setOnValidate(false);
		}
	}, [onValidate]);

	const handleMultiFieldChange = (value, name, fieldsToClear) => {
		let items = { ...adminData };
		items[name] = value;
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setAdminData(items);
		setOnValidate(true);
	};

	const evaluateAdminError = () => {
		let errorItems = { ...adminDataErrors };

		let reasonRetirement = {
			...errorItems['reasonRetirement'],
			value:
				adminData.selectedCategory != 0 && adminData.selectedReason.length === 0
		};
		errorItems['reasonRetirement'] = reasonRetirement;

		let vulnerabilityType = {
			...errorItems['vulnerabilityType'],
			value:
				(adminData.selectedCategory == 3 || adminData.selectedCategory == 4) &&
				adminData.selectedVulnerabilityType == 0
		};
		errorItems['vulnerabilityType'] = vulnerabilityType;

		let behaviourRetirement = {
			...errorItems['behaviourRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value === 2) &&
				adminData.selectedVicesRetirement.length === 0
		};
		errorItems['behaviourRetirement'] = behaviourRetirement;

		let conformationalRetirement = {
			...errorItems['conformationalRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 3) &&
				adminData.conformationalDefectsRetirement.length === 0
		};
		errorItems['conformationalRetirement'] = conformationalRetirement;

		let education = {
			...errorItems['education'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.length === 0
		};
		errorItems['education'] = education;

		let educationDetails = {
			...errorItems['educationDetails'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 4) &&
				adminData.selectedEducation.some((educ) => educ.value == 4) &&
				adminData.otherEducationDetails.length === 0
		};
		errorItems['educationDetails'] = educationDetails;

		let injuryRetirement = {
			...errorItems['injuryRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 5) &&
				adminData.selectedInjuriesRetirement.length === 0
		};
		errorItems['injuryRetirement'] = injuryRetirement;

		let otherInjuryRetirement = {
			...errorItems['otherInjuryRetirement'],
			value:
				adminData.selectedInjuriesRetirement.some(
					(injury) => injury.value == 12
				) && adminData.otherInjuryDetails.length === 0
		};
		errorItems['otherInjuryRetirement'] = otherInjuryRetirement;

		let illnessRetirement = {
			...errorItems['illnessRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 6) &&
				adminData.selectedIllnessRetirement.length === 0
		};
		errorItems['illnessRetirement'] = illnessRetirement;

		let otherIllnessRetirement = {
			...errorItems['otherIllnessRetirement'],
			value:
				adminData.selectedIllnessRetirement.some(
					(illness) => illness.value == 4
				) && adminData.otherIllnessDetails.length === 0
		};
		errorItems['otherIllnessRetirement'] = otherIllnessRetirement;

		let rehomingHistory = {
			...errorItems['rehomingHistory'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 7) &&
				adminData.rehomingHistoryDetails.length === 0
		};
		errorItems['rehomingHistory'] = rehomingHistory;

		let otherDetailsRetirement = {
			...errorItems['otherDetailsRetirement'],
			value:
				adminData.selectedReason.some((reason) => reason.value == 8) &&
				adminData.otherDetailsRetirement.length === 0
		};
		errorItems['otherDetailsRetirement'] = otherDetailsRetirement;

		let behaviourOtherRetirement = {
			...errorItems['behaviourOtherRetirement'],
			value:
				adminData.selectedVicesRetirement.some((vice) => vice.value == 12) &&
				adminData.otherVicesRetirement.length === 0
		};
		errorItems['behaviourOtherRetirement'] = behaviourOtherRetirement;

		setAdminDataErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	useEffect(() => {
		setNotificationToUser(
			data.reviewMessages === null ? '' : data.reviewMessages
		);
	}, []);

	useEffect(() => {
		setAdminActions(
			mapAdminActionDataForLocal(data, adminActions, currentUser?.UserId)
		);
	}, [data]);

	const updateNotificationToUser = (val) => {
		setNotificationToUser(val);
	};

	const invalidateAdminActionFields = (keys) => {
		const newAdminActions = { ...adminActions };
		keys.forEach((key) => {
			newAdminActions[key] = {
				...newAdminActions[key],
				isInvalid: true
			};
		});
		setAdminActions(newAdminActions);
	};

	const validateForm = () => {
		const invalidKeys = [];
		for (const [key, { value }] of Object.entries(adminActions)) {
			switch (key) {
				case adminActions.adminExternalId.key:
				case adminActions.ewdRecommendationDate.key:
				case adminActions.ewgmRecommendationDate.key:
				case adminActions.recommendation.key:
					if (Number(value) === 0) {
						invalidKeys.push(key);
					}
					break;
				case adminActions.ewgmComments.key:
				case adminActions.vetAssessmentDate.key:
				case adminActions.vetAssessmentId.key:
				case adminActions.vetAssessmentSummary.key:
					if (
						adminActions.ewgmProceedToVetAssessment.value &&
						Number(value) === 0
					) {
						invalidKeys.push(key);
					}
					break;
				default:
					break;
			}
		}
		if (invalidKeys.length > 0) {
			invalidateAdminActionFields(invalidKeys);
		}
		return invalidKeys.length === 0;
	};

	const reject = async () => {
		const horseCode = data?.horse?.horseCode;
		const isValidForm = validateForm();
		setIsFormInvalid(!isValidForm);
		if (!isValidForm) return;
		await updateAdminActions();
		setIsProcessingReject(true);
		AppSyncService.execute(reviewHorseResetForm, {
			id: parseInt(data.id),
			reviewMessages: notificationToUser,
			status: 'Rejected'
		}).then(async (data) => {
			setIsProcessingReject(false);
			if (data.data?.saveResetNominationFormStatus?.success) {
				const payload = {
					asOfDate: new Date(),
					horseCode,
					programId: 1,
					statusId: 34,
					memberExternalId: currentUser.UserId
				};
				await AppSyncService.execute(saveOTTStatusAndProgram, payload);
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					data.data?.saveResetNominationFormStatus?.error?.errorMessage
				);
			}
		});
	};

	const approve = async (reviewStatus = false) => {
		const isValidForm = validateForm();
		setIsFormInvalid(!isValidForm);
		if (!isValidForm) return;
		await updateAdminActions();
		setIsProcessingApprove(true);
		const horseProfilePayload = generateDeceasedInputPayload(adminData);
		await AppSyncService.execute(saveHorseProfileRetirement, {
			input: {
				...horseProfilePayload,
				memberId: data?.member?.externalId,
				horseCode: data?.horse?.horseCode
			}
		});
		AppSyncService.execute(reviewHorseResetForm, {
			id: parseInt(data.id),
			status: reviewStatus ? reviewStatus : APPROVED
		}).then((data) => {
			setIsProcessingApprove(false);
			if (data.data?.saveResetNominationFormStatus?.success) {
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					data.data?.saveResetNominationFormStatus?.error?.errorMessage
				);
			}
		});
	};

	const updateAdminActions = async () => {
		setIsLoadingAdminAction(true);
		try {
			const mappedAdminActionForUpdate = mapAdminActionForUpdateAPI(
				adminActions
			);
			const deceasedInput = generateDeceasedInputPayload(adminData);
			const mappedAdminDataForUpdate = mapAdminDataForUpdateAPI(adminActions);
			const saveResetAdminDataResult = await AppSyncService.execute(
				saveResetAdminData,
				{
					input: { ...mappedAdminDataForUpdate, ...deceasedInput },
					...mappedAdminActionForUpdate
				}
			);
			if (
				saveResetAdminDataResult.data &&
				!saveResetAdminDataResult.data?.saveResetAdminData?.error?.errorMessage
			) {
				addToast({ Message: 'Saved Admin updates', IsSuccess: true });
				if (+adminActions.adminStatusId.value === 3) {
					sendAdminEmail(adminActions.resetNominationId.value);
				}
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Failed to Update', IsSuccess: false });
				setError(data.data?.saveResetAdminData?.error?.errorMessage);
			}
		} catch (e) {
			addToast({ Message: 'Failed to Update', IsSuccess: false });
			setError(e.message);
		}
		setIsLoadingAdminAction(false);
	};

	const sendAdminEmail = (formId) => {
		AppSyncService.execute(generateAdminReviewNotification, {
			formId: +formId,
			formType: 'RESETNominationForm'
		});
	};

	const getInjuryList = () => {
		let injuries = '';
		if (data.injuries != null && data.injuries.length > 0) {
			data.injuries.map((i, index) => {
				injuries = injuries.concat(`${index === 0 ? '' : ','} ${i.value}`);
			});
		}
		return injuries.length > 0 ? injuries : '-';
	};

	const getDisciplines = () => {
		let disciplines = '';
		if (data.disciplines != null && data.disciplines.length > 0) {
			data.disciplines.map((i, index) => {
				disciplines = disciplines.concat(
					`${index === 0 ? '' : ','} ${i.value}`
				);
			});
		}
		return disciplines.length > 0 ? disciplines : '-';
	};

	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<SNSAdmin horseProfileData={data.horse} needsBorder={false} />
				<HeaderBanner
					title="RESET Nomination Form"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="The Registered Owner of this horse (as per Racing Victoria
											records)"
							value={data.registeredOwner ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="The Registered Managing Owner of this horse (as per Racing
											Victoria records)"
							value={data.registeredManagingOwner ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="I have attached proof of purchase and/or a Statutory
											Declaration documenting me as the owner of this horse."
							value={data.proofOwner ? 'Yes' : 'No'}
						/>
					</Col>
					{data.proofOwner > 0 && (
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Uploaded Proof of Ownership/Statutory Declaration
								</div>
								<UploadPhoto
									data={data.proofOfPurchaseDocuments}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					)}
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Date Submitted:"
							value={formatDateReadableVariant(data.lastUpdated)}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.colour} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField title="Sire Name" value={data.sireName} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Dam Name" value={data.damName} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Approx. Horse Height" value={data.height} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Reason for Retirement"
							value={data.retirementReason}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Other Reason for Retirement"
							value={data.otherRetirementReason}
						/>
					</Col>
					<Col></Col>
				</Row>
				<hr className={classes.divider} />
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField title="Thoroughbred Address" value={data.address} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Thoroughbred Suburb" value={data.suburb} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Thoroughbred State" value={data.state} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Thoroughbred Postcode"
							value={data.postcode}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Thoroughbred Country" value={data.country} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Name" value={data.memberName} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.phone} />
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Address"
							value={data.residentialAddress}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Suburb"
							value={data.residentialSuburb}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential State"
							value={data.residentialState}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Postcode"
							value={data.residentialPostcode}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Residential Country"
							value={data.residentialCountry}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Your relationship to the horse"
							value={data.relationshipToHorse}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Other relationship to the horse"
							value={data.otherRelationship}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Length of relationship to the horse"
							value={data.lengthOfRelationship}
						/>
					</Col>
					<Col></Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Who are the current owner(s)?"
							value={data.currentOwners}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Who are the current/most recent trainer(s)?"
							value={data.currentRetrainers}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Injury and Health"
				inline
				mobile={mobile}
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Has this horse been known to windsuck?"
							value={data.isKnownToWindsuck}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Has this horse been injured?"
							value={data.injuries.length > 0 ? 'Yes' : 'No'}
						/>
					</Col>
					{data.vetDocuments.length > 0 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>
									Uploaded veterinarian reports
								</div>
								<UploadPhoto
									data={data.vetDocuments}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<FormLabelField title="Injury type(s)" value={getInjuryList()} />
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Please explain the nature of injury including veterinary care and/ or rehabilitation provided"
							value={data.injuriesDetails}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Heritable disorder?"
							value={data.heritableDisorder ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.heritableDisorder && (
					<Row>
						<Col>
							<FormLabelField
								title="Heritable disorder details"
								value={data.heritableDisorder}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Disease?"
							value={data.disease ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.disease && (
					<Row>
						<Col>
							<FormLabelField title="Disease details" value={data.disease} />
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Accidents?"
							value={data.accidents ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.accidents && (
					<Row>
						<Col>
							<FormLabelField title="Accident details" value={data.accidents} />
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Eye disease, trauma or impaired vision?"
							value={data.eyeDisease ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.eyeDisease && (
					<Row>
						<Col>
							<FormLabelField
								title="Eye disease, trauma or impaired vision details"
								value={data.eyeDisease}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Lameness?"
							value={data.lameness ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.lameness && (
					<Row>
						<Col>
							<FormLabelField title="Lameness details" value={data.lameness} />
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Head shaking, stringhalt or locking stifles?"
							value={data.headShaking ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.headShaking && (
					<Row>
						<Col>
							<FormLabelField
								title="Head shaking, stringhalt or locking stifles details"
								value={data.headShaking}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Vices (windsucks, weaves, kicks, bucks etc)?"
							value={data.vices ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.vices && (
					<Row>
						<Col>
							<FormLabelField
								title="Vices (windsucks, weaves, kicks, bucks etc) details"
								value={data.vices}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Abnormalities of breathing in rest/exercise?"
							value={data.abnormality ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.abnormality && (
					<Row>
						<Col>
							<FormLabelField
								title="Abnormalities of breathing in rest/exercise details"
								value={data.abnormality}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Surgical procedures (colic, orthopedic etc)?"
							value={data.surgical ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.surgical && (
					<Row>
						<Col>
							<FormLabelField
								title="Surgical procedures (colic, orthopedic etc) details"
								value={data.surgical}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Vaccinations administered?"
							value={data.vaccine ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.vaccine && (
					<Row>
						<Col>
							<FormLabelField
								title="Vaccinations administered details (Strangles, tetanus, herpes, equity or other)"
								value={data.vaccine}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<FormLabelField
							title="Breeding history?"
							value={data.breeding ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				{data.breeding && (
					<Row>
						<Col>
							<FormLabelField
								title="Breeding history details"
								value={data.breeding}
							/>
						</Col>
					</Row>
				)}
			</div>
			<HeaderBanner
				title="Thoroughbred Information"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col>
						<FormLabelField
							title="To the best of your knowledge, how would you describe this horse's temperament?"
							value={data.temperament}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Based on your experience, what discipline(s) do you believe this horse will be suited to?"
							value={getDisciplines()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Other disciplines"
							value={data.disciplinesOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Please provide a detailed history of this horse’s ridden education. If applicable, please specifically include any training undertaken away from the racetrack (e.g. dressage training)."
							value={data.riddenHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Any other relevant matters or details we should be aware of (known vices, behaviours etc)?"
							value={data.otherDetails}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Photos"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Face On - Full Length & Legs
							</div>
							<UploadPhoto
								data={data.faceOnPhotos}
								getImage={filePreviewHandler}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Left Side Full Length & Brand
							</div>
							<UploadPhoto
								data={data.leftSidePhotos}
								getImage={filePreviewHandler}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Right Side Full Length & Brand
							</div>
							<UploadPhoto
								data={data.rightSidePhotos}
								getImage={filePreviewHandler}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Behind - Full Length & Legs
							</div>
							<UploadPhoto
								data={data.behindFullPhotos}
								getImage={filePreviewHandler}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>Additional Photos</div>
							<UploadPhoto
								data={data.additionalPhotos}
								getImage={filePreviewHandler}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Rehoming Declaration"
				inline
				mobile={mobile}
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col>
						<FormLabelField
							title="I declare there has been a minimum of two rehoming attempts
							prior to nomination."
							value={data.rehomingAttempt ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="I do not know of any behavioural issues that would prohibit
							safe rehoming of this horse."
							value={data.noBehaviouralissues ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="I do not know of any health or veterinary related issues
							that would prohibit safe rehoming."
							value={data.noHealthIssues ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Program Declaration"
				inline
				mobile={mobile}
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col>
						<FormLabelField
							title="I remain the responsible owner of this horse throughout
							RESET until successfully re-homed."
							value={data.responsibleOwner ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="If the horse can't be re-homed following RESET, I will
							continue to be the owner of the horse and the horse will be
							returned to my care."
							value={data.cantBeReHomed ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="This is a re-homing program and it is my intention to
							re-home this horse as a result of RESET."
							value={data.rehomingProgram ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="I acknowledge and agree that completion of this form does
							not guarantee my horse's entry into RESET and that the
							decision as to whether to accept my horse into RESET shall
							be at RV's absolute discretion."
							value={data.agreeCompletionOfForm ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="If the horse is deemed suitable for use in Riding for the
							Disabled Association of Victoria Inc ABN 20 130 814 132
							(RDAV) activities, ownership of that horse may, subject to
							the RDAV's agreement, be transferred to RDAV at the
							conclusion of that horse's involvement in RESET, at no
							cost, and there will be no attempt to sell the horse."
							value={data.disabledAssociation ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="If the horse is able to be re-homed, Racing Victoria will,
							in its absolute discretion determine the sale price, if any,
							of the horse."
							value={data.salePrice ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="My horse's entry into RESET is subject to me executing
							an agreement with Racing Victoria and the relevant service
							provider (e.g. the retrainer), a copy of which will be
							provided to me for review if my horse is deemed suitable for
							the program."
							value={data.RVagreement ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="I have read the Privacy Statement."
							value={data.privacyStatement ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Veterinary Examination"
				inline
				mobile={mobile}
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
				style={{ borderBottom: '1px solid #b3bcc1' }}
			>
				<Row>
					<Col>
						<FormLabelField
							title="I consent to a veterinary examination on the above horse by
							a Racing Victoria appointed veterinary as part of entry into
							the Racing Victoria RESET Program; I understand that the
							examination will include a physical examination; and I
							understand that every examination carries a finite risk. I
							accept that information gained in the course of this
							examination is the property of Racing Victoria."
							value={data.concent ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
			</div>
			<AdminActions
				adminActionOptions={adminActionOptions}
				adminActions={adminActions}
				setAdminActions={setAdminActions}
				formName={PAGE_ID}
				isLoading={isLoadingAdminAction}
				updateAdminAction={() => updateAdminActions()}
				error={error}
				noBorderBottom
				status={data.status}
				setIsFormInvalid={setIsFormInvalid}
				invalidateAdminActionFields={invalidateAdminActionFields}
				retirementCategory={
					adminData && (
						<RetirementCategory
							noContainerPadding={true}
							isReadOnly={data?.status !== REVIEW}
							adminData={adminData}
							adminDataErrors={adminDataErrors}
							handleMultiFieldChange={handleMultiFieldChange}
						/>
					)
				}
			/>
			<AdminReviewFormApproval
				notificationToUser={notificationToUser}
				updateNotificationToUser={(value) => updateNotificationToUser(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				errorMessage={errorMessage}
				totalLength={MAXREASONLENGTH}
				formName={PAGE_ID}
				rejectingApplication={adminActions.rejectingApplication.value}
				status={data.status}
				isFormInvalid={isFormInvalid}
			/>
		</>
	);
};
export default ResetNominationFormReviewInfo;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important'
	},
	pageContainerMobile: {
		width: '100%',
		marginBottom: 50,
		marginTop: 80
	},
	// info Container
	horseInfoContainer: {
		borderRadius: 5,
		border: '1px solid #b3bcc1',
		height: 340,
		background: 'white',
		marginLeft: '0 !important',
		marginRight: '0 !important',
		marginTop: 30
	},
	horseImageContainer: {
		width: 340,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	image: {
		objectFit: 'cover',
		borderRadius: '5px 0px 0px 5px',
		height: 340,
		width: '100%'
	},
	horseInfo: {
		marginTop: 20
	},
	horseInfoLeft: {
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	horseName: {
		marginLeft: 20,
		marginBottom: 20,
		fontSize: 20,
		fontWeight: 600
	},
	moreDetails: {
		paddingLeft: '20px !important',
		paddingRight: '20px !important'
	},
	horseHistory: {
		marginTop: 30,
		fontSize: 13,
		color: 'grey'
	},
	loadingDiv: {
		marginTop: '20%'
	}
}));

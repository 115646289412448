import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import Add from '../../assets/icons/Add_plus_icon.svg';
import Cross from '../../assets/icons/Remove_cross_icon.svg';
import useStyles from './styles';
import { AcknowledgedAppFormKeys } from './../../utils/constants/index';

const FormMultipleInputs = ({
	fieldName,
	fieldId,
	fieldRef,
	listName,
	listRef,
	listId,
	onChange,
	fieldIdOther,
	fieldRefOther,
	dropDownList,
	value,
	isError,
	addNew,
	secondFieldName,
	secondFieldId,
	secondFieldRef,
	isMobile,
	inputName = 'staffMember',
	disabled = false
}) => {
	const [inputList, setInputList] = useState([]);
	const classes = useStyles();

	useEffect(() => {
		setInputList(value);
	}, [value]);

	// handle input change
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		onChange(list);
	};

	// handle click event of the Remove button
	const handleRemoveClick = (e, index) => {
		e.preventDefault();
		const list = [...inputList];
		list.splice(index, 1);
		onChange(list);
	};

	// handle click event of the Add button
	const handleAddClick = (e) => {
		e.preventDefault();
		addNew();
	};

	return (
		<>
			{inputList.map((x, i) => {
				return (
					<div key={i} id={inputName + `_${i + 1}`}>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>{fieldName}</div>
									<FormTextField
										id={`${fieldId}_${i + 1}`}
										value={x[fieldRef]}
										name={fieldRef}
										canEdit={!disabled}
										onChange={(e) => handleInputChange(e, i)}
										styles={
											isError && (x[fieldRef] === null || x[fieldRef] === '')
												? { border: '1px solid red' }
												: {}
										}
									/>
								</div>
							</Col>
							{dropDownList && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>{listName}</div>
										<FormDropDown
											showBlank
											id={`${listId}_${i + 1}`}
											name={listRef}
											items={dropDownList}
											selectedId={x.roleId}
											onChange={(e) => handleInputChange(e, i)}
											styles={
												isError && parseInt(x.roleId) === 0
													? { border: '1px solid red' }
													: {}
											}
											disabled={disabled}
										/>
									</div>
								</Col>
							)}
							{secondFieldName && (
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>{secondFieldName}</div>
										<FormTextField
											id={`${secondFieldId}_${i + 1}`}
											value={x[secondFieldRef]}
											name={secondFieldRef}
											canEdit={!disabled}
											onChange={(e) => handleInputChange(e, i)}
											styles={
												isError &&
												(x[secondFieldRef] === null || x[secondFieldRef] === '')
													? { border: '1px solid red' }
													: {}
											}
										/>
									</div>
								</Col>
							)}
							<Col xs={12} lg={4}>
								{disabled ? null : (
									<div
										className={
											isMobile ? classes.optionsMobile : classes.options
										}
									>
										{inputList.length !== 1 && (
											<button
												id={`${AcknowledgedAppFormKeys.REMOVE_ENTRY}_${i + 1}`}
												className={classes.remove}
												onClick={(e) => handleRemoveClick(e, i)}
											>
												<img src={Cross} />
											</button>
										)}
										{inputList.length - 1 === i && (
											<button
												id={AcknowledgedAppFormKeys.ADD_ENTRY}
												style={inputList.length === 1 ? { marginLeft: 0 } : {}}
												className={classes.add}
												onClick={(e) => handleAddClick(e)}
											>
												<img src={Add} />
											</button>
										)}
									</div>
								)}
							</Col>
						</Row>
						{dropDownList &&
							dropDownList.filter(
								(r) => r.value === 'Other' && r.id === parseInt(x.roleId)
							).length > 0 && (
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Other</div>
											<FormTextField
												id={`${fieldIdOther}_${i + 1}`}
												value={x.roleOther}
												name={fieldRefOther}
												canEdit={!disabled}
												onChange={(e) => handleInputChange(e, i)}
												styles={
													isError &&
													dropDownList.filter(
														(r) =>
															r.value === 'Other' && r.id === parseInt(x.roleId)
													).length > 0 &&
													(x.roleOther === null || x.roleOther === '')
														? { border: '1px solid red' }
														: {}
												}
											/>
										</div>
									</Col>
								</Row>
							)}
					</div>
				);
			})}
		</>
	);
};

export default FormMultipleInputs;

import React, { useState, useContext, useCallback } from 'react';
import AppSyncService from '../../graphql/AppSyncService';
import { getUserImage } from '../../graphql/custom.queries';
import FilePreviewer from '../../components/FilePreviewer';

const FilePreviewContext = React.createContext(null);

export const FilePreviewProvider = ({ children }) => {
	const [previewFile, setPreviewFile] = useState(null);

	const previewHandler = useCallback(({ key, signedUrl }) => {
		if (!signedUrl) {
			const imageKey = { imageKey: key };
			AppSyncService.execute(getUserImage, imageKey).then((result) => {
				setPreviewFile({
					format: key.split('.').pop(),
					signedUrl: result.data.getUserImage.signedUrl
				});
			});
		} else {
			setPreviewFile({
				format: key.split('.').pop(),
				signedUrl: signedUrl
			});
		}
	}, []);
	return (
		<FilePreviewContext.Provider value={previewHandler}>
			{children}
			{previewFile && (
				<FilePreviewer
					file={previewFile}
					onClose={() => setPreviewFile(null)}
				/>
			)}
		</FilePreviewContext.Provider>
	);
};

const useFilePreview = () => useContext(FilePreviewContext);

export default useFilePreview;

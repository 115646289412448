import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import { useParams, navigate } from '@reach/router';
import { useAuth } from '../../hooks/useAuth';
import AppSyncService from '../../graphql/AppSyncService';
import FormAddressEntry from '../FormAddressEntry';
import { useToast } from '../../hooks/useToast';
import {
	getRelationshipList,
	getAssistanceTypeList,
	deleteFullCircle,
	saveFullCircle,
	getFullCircle,
	saveResidentialAddress,
	updateMemberProfile
} from '../../graphql/custom.queries';
import { MobileModal } from '../Mobile';
import MessageBar from '../MessageBar';
import SEO from '../seo';
import useStyles from './styles';
import {
	COMPLETE,
	DRAFT,
	REVIEW,
	DEFAULT_COUNTRY,
	BLANK_OPTION,
	FullCircleFormKeys
} from '../../utils/constants';
import { validatePostCode } from '../../utils/helpers';
import MultiDropDown from '../MultiDropDown';
import { addNameToUnnamedHorse } from '../../utils/helpers';
import PageContainer from '../PageContainer';
import FormHeader from '../FormComponents/FormHeader';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import TextInputField from '../FormComponents/TextInputField';
import { PAGE_ID } from './constants';
import FormSectionContentDivider from '../FormComponents/FormSectionContentDivider';
import SelectInputField from '../FormComponents/SelectInputField';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import FormFooter from '../FormComponents/FormFooter';
import FormCancelConfirmModal from '../FormComponents/FormCancelConfirmModal';
import PageLoadSpinner from '../PageLoadSpinner';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const FullCircleForm = () => {
	const { addToast } = useToast();
	let { horseCode } = useParams();
	const { currentUser } = useAuth();
	const classes = useStyles();
	const [contactFN, setContactFN] = useState('');
	const [contactLN, setContactLN] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [contactAltNum, setContactAltNum] = useState('');
	const [contactAddress, setContactAddress] = useState('');
	const [contactSuburb, setContactSuburb] = useState('');
	const [contactPostCode, setContactPostCode] = useState('');
	const [contactState, setContactState] = useState('');
	const [contactCountry, setContactCountry] = useState(DEFAULT_COUNTRY);
	const [horseRelationship, setHorseRelationship] = useState(0);
	const [assistanceType, setAssistanceType] = useState(0);
	const [relationshipList, setRelationshipList] = useState([]);
	const [assistanceTypeList, setAssistanceTypeList] = useState([]);
	const [formStatus, setFormStatus] = useState(null);
	const [requiredFieldArray, setRequiredFieldArray] = useState([]);
	const [organisation, setOrganisation] = useState('');

	const [showModal, setShowModal] = useState(false);
	const [memberData, setMemberData] = useState([]);
	const [horseData, setHorseData] = useState([]);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add my address',
		value: 'Manually add my address',
		isSelected: isPostalAddressManual
	};
	const toggleManualAddress = () => {
		setIsPostalAddressManual(!isPostalAddressManual);
	};

	useEffect(() => {
		if (currentUser != null) {
			const memberExternalId = currentUser?.UserId;
			AppSyncService.execute(getRelationshipList).then((data) => {
				let relationshipList = data.data.listFullCircleRelationships;
				relationshipList.unshift({ id: 0, value: '' });
				setRelationshipList(relationshipList);
			});
			AppSyncService.execute(getFullCircle, {
				memberExternalId: memberExternalId,
				horseCode: horseCode
			}).then((data) => {
				const fullCircleFormData = data?.data?.getFullCircle;
				if (fullCircleFormData) {
					// form data
					setContactFN(fullCircleFormData.member?.firstName);
					setContactLN(fullCircleFormData.member?.lastName);
					setContactNumber(
						fullCircleFormData.phone || fullCircleFormData.member?.phone
					);
					setContactAltNum(
						fullCircleFormData.businessPhone ||
							fullCircleFormData.member?.businessPhone
					);
					setContactAddress(
						fullCircleFormData.member?.residentialAddress?.address ||
							fullCircleFormData.residentialAddress
					);
					setContactSuburb(
						fullCircleFormData.member?.residentialAddress?.suburb ||
							fullCircleFormData.residentialSuburb
					);
					setContactPostCode(
						fullCircleFormData.member?.residentialAddress?.postcode ||
							fullCircleFormData.residentialPostcode
					);
					setContactCountry(
						fullCircleFormData.member?.residentialAddress?.country ||
							fullCircleFormData.residentialCountry ||
							DEFAULT_COUNTRY
					);
					setContactState(
						fullCircleFormData.member?.residentialAddress?.state ||
							fullCircleFormData.residentialState ||
							''
					);
					setFormStatus(fullCircleFormData.status);
					setHorseRelationship(
						fullCircleFormData.relationship?.id
							? fullCircleFormData.relationship?.id
							: BLANK_OPTION.id
					);
					if (parseInt(fullCircleFormData.relationship?.id) === 9) {
						setOrganisation(fullCircleFormData.organisation);
					}
					AppSyncService.execute(getAssistanceTypeList).then((assist) => {
						let listAssistArray = [];
						let selectedArray = [];
						let count = 0;
						assist.data.listFullCircleTypesOfAssistance
							.filter((i) => i.value != 'Other')
							.map((rec) => {
								listAssistArray.push({
									id: count,
									key: rec.id,
									title: rec.value,
									selected: false
								});
								count++;
							});
						if (fullCircleFormData.typeOfAssistance.length > 0) {
							listAssistArray.map((d) => {
								fullCircleFormData.typeOfAssistance.map((s) => {
									if (d.key === s.id) {
										d.selected = true;
										selectedArray.push({ key: s.id, value: s.value });
									}
								});
							});
							setAssistanceType(selectedArray);
						} else {
							setAssistanceType([]);
						}
						setAssistanceTypeList(listAssistArray);
					});
					// end form data
					setMemberData(fullCircleFormData.member);
					setHorseData(fullCircleFormData.horse);
					setIsLoading(false);
				}
			});
		}
	}, [horseCode]);

	const modalStyles = {
		content: {
			width: '75%',
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const resetAssistType = (id) => {
		const temp = assistanceTypeList;

		temp[id].selected = !temp[id].selected;

		let selectedAssist = Object.assign([], assistanceType);

		if (temp[id].selected) {
			selectedAssist.push({ key: temp[id].key, value: temp[id].title });
		}

		if (!temp[id].selected) {
			const key = temp[id].key;
			const filterIndex = selectedAssist
				.map(function (e) {
					return e.key;
				})
				.indexOf(key);
			if (filterIndex > -1) {
				selectedAssist.splice(filterIndex, 1);
			}
		}
		setAssistanceTypeList(temp);
		setAssistanceType(selectedAssist);
	};

	const saveForm = (saveType) => {
		let errorArray = [];
		let memberExternalId = currentUser?.UserId;
		let assist = [];
		if (assistanceType.length > 0) {
			assistanceType.map((d) => {
				assist.push(d.key);
			});
		}
		let typeOfAssistance = assist;
		let input = {
			horseCode: horseCode,
			memberExternalId: currentUser?.UserId,
			relationship: horseRelationship,
			phone: memberData?.phone ? memberData?.phone : contactNumber,
			organisation: parseInt(horseRelationship) === 9 ? organisation : null,
			residentialAddress: memberData?.residentialAddress?.address
				? memberData?.residentialAddress?.address
				: contactAddress,
			residentialSuburb: memberData?.residentialAddress?.address
				? memberData?.residentialAddress?.suburb
				: contactSuburb,
			residentialState: memberData?.residentialAddress?.address
				? memberData?.residentialAddress?.state
				: contactState,
			residentialPostcode: memberData?.residentialAddress?.address
				? memberData?.residentialAddress?.postcode
				: contactPostCode,
			residentialCountry: memberData?.residentialAddress?.address
				? memberData?.residentialAddress?.country
				: contactCountry,
			businessPhone: memberData?.businessPhone
				? memberData?.businessPhone
				: contactAltNum
		};
		for (const [key, value] of Object.entries(input)) {
			if (key != 'businessPhone') {
				if (value === null || value === '') {
					if (key === 'phone') {
						errorArray.push('Contact Number');
					}
					if (key === 'residentialAddress') {
						errorArray.push('Residential Address');
					}
					if (key === 'residentialSuburb') {
						errorArray.push('Residential Suburb');
					}
					if (key === 'residentialState') {
						errorArray.push('Residential State');
					}
					if (key === 'residentialPostcode') {
						errorArray.push('Residential Postcode');
					}
					if (key === 'residentialCountry') {
						errorArray.push('Residential Country');
					}
					if (key === 'organisation' && parseInt(horseRelationship) === 9) {
						errorArray.push('Organisation Name');
					}
				}
			}
		}
		if (parseInt(horseRelationship) === 0) {
			errorArray.push('Relationship to Horse');
		}
		if (assistanceType.length === 0) {
			errorArray.push('Type of Assistance');
		}
		setRequiredFieldArray(errorArray);
		if (saveType === DRAFT) {
			AppSyncService.execute(saveFullCircle, {
				input,
				horseCode,
				memberExternalId,
				typeOfAssistance
			}).then((data) => {
				if (
					data.data.saveFullCircle &&
					data.data.saveFullCircleTypesOfAssociation
				) {
					addToast({ Message: 'Draft Saved', IsSuccess: true });
					navigate(DASHBOARD_ROUTE);
				}
			});
		}
		if (saveType === COMPLETE) {
			input.submitForm = true;
			if (errorArray.length === 0) {
				try {
					AppSyncService.execute(saveFullCircle, {
						input,
						horseCode,
						memberExternalId,
						typeOfAssistance
					}).then((data) => {
						if (
							data.data.saveFullCircle &&
							data.data.saveFullCircleTypesOfAssociation
						) {
							addToast({ Message: 'Form Completed', IsSuccess: true });
						}
						if (memberData.phone === null) {
							const saveAddress = {
								memberExternalId: '', // TODO: remove it when AddressInput in GQL fully deprecates the member external id field
								address: contactAddress,
								country: contactCountry,
								postcode: contactPostCode,
								state: contactState,
								suburb: contactSuburb
							};
							AppSyncService.execute(saveResidentialAddress, {
								saveAddress
							}).then((data) => {
								if (data.data != null) {
									addToast({ Message: 'Updating Address', IsSuccess: true });
									const updateMemberProfileData = {
										phone: contactNumber,
										businessPhone: contactAltNum
									};
									AppSyncService.execute(updateMemberProfile, {
										updateMemberProfileData
									}).then((data) => {
										if (data.data != null) {
											addToast({
												Message: 'Updating Contact',
												IsSuccess: true
											});
											navigate(DASHBOARD_ROUTE);
										}
									});
								}
							});
						} else {
							navigate(DASHBOARD_ROUTE);
						}
					});
				} catch {
					addToast({ Message: 'Error Submitting', IsSuccess: false });
				}
			} else {
				setShowModal(true);
			}
		}
	};

	const deleteForm = () => {
		AppSyncService.execute(deleteFullCircle, {
			horseCode: horseCode
		}).then(() => {
			addToast({ Message: 'Form Cancelled', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		});
	};

	const showRequiredFields = () => {
		return requiredFieldArray.map((data, index) => {
			return (
				<div key={index} className={classes.errorList}>
					- {data}
				</div>
			);
		});
	};

	const onAddressChange = (newAddress) => {
		setContactAddress(newAddress);
	};

	const onSuburbChange = (newSuburb) => {
		setContactSuburb(newSuburb);
	};

	const onPostCodeChange = (newPostCode) => {
		const isValidPostCode = validatePostCode(newPostCode);
		if (isValidPostCode) {
			setContactPostCode(newPostCode);
		}
	};

	const onCountryChange = (newCountry) => {
		setContactCountry(newCountry);
	};

	const onAddressStateChange = (newCountryState) => {
		setContactState(newCountryState);
	};

	const onCancelForm = (event) => {
		event.preventDefault();
		setConfirmationModal(true);
	};

	const onSave = (event) => {
		event.preventDefault();
		saveForm(DRAFT);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		saveForm(COMPLETE);
	};

	return (
		<PageContainer>
			<SEO title="Full Circle Form" />
			<PageLoadSpinner isLoading={isLoading}>
				<form onSubmit={onSubmit}>
					<FormHeader formTitle="Full Circle Form">
						We need more information to complete your full circle form.
					</FormHeader>
					<FormBody>
						<FormSection sectionTitle="Personal Profile Details">
							<>
								<Col>
									<Row>
										<TextInputField
											id={`${PAGE_ID}-first-name-text-input`}
											label="First Name"
											value={contactFN}
											isRequired
											onChange={(event) => setContactFN(event.target.value)}
											isEditable={false}
										/>
										<TextInputField
											id={`${PAGE_ID}-last-name-text-input`}
											label="Last Name"
											value={contactLN}
											isRequired
											onChange={(event) => setContactLN(event.target.value)}
											isEditable={false}
										/>
										<TextInputField
											id={FullCircleFormKeys.PHONE_NUMBER_PERSONAL}
											label="Phone Number (Personal)"
											value={contactNumber}
											isRequired
											onChange={(event) => setContactNumber(event.target.value)}
											isEditable={!memberData?.phone}
										/>
									</Row>
									<Row className={classes.subsequentRows}>
										<TextInputField
											id={FullCircleFormKeys.PHONE_NUMBER_BUSINESS}
											label="Phone Number (Business)"
											value={contactAltNum}
											onChange={(event) => setContactAltNum(event.target.value)}
											isEditable={!memberData?.phone}
										/>
									</Row>
								</Col>
								<FormSectionContentDivider style={{ margin: '20px 0px' }} />
								<Col>
									<FormAddressEntry
										savedAddressInfo={memberData}
										address={contactAddress}
										onAddressChange={onAddressChange}
										suburb={contactSuburb}
										onSuburbChange={onSuburbChange}
										postcode={contactPostCode}
										onPostCodeChange={onPostCodeChange}
										country={contactCountry}
										onCountryChange={onCountryChange}
										manualAddressCheckBoxData={manualAddressCheckBoxData}
										toggleManualAddressCheckBox={toggleManualAddress}
										state={contactState}
										onAddressStateChange={onAddressStateChange}
									/>
								</Col>
								<FormSectionContentDivider style={{ margin: '20px 0px' }} />
								<Col>
									<Row>
										<SelectInputField
											id={FullCircleFormKeys.RELATIONSHIP_TO_HORSE}
											label="Relationship to Horse"
											value={horseRelationship}
											options={relationshipList}
											onChange={(event) => {
												setHorseRelationship(event.target.value);
												setOrganisation('');
											}}
											isRequired
										/>
										<FormFieldWrapper
											fieldLabel="Type of Assistance"
											isRequired
										>
											<MultiDropDown
												id={FullCircleFormKeys.TYPE_OF_ASSISTANCE}
												list={assistanceTypeList}
												resetThenSet={resetAssistType}
												headTitle=""
											/>
										</FormFieldWrapper>
									</Row>
									{parseInt(horseRelationship) === 9 && (
										<Row className={classes.subsequentRows}>
											<TextInputField
												id={`${PAGE_ID}-organisation-name-text-input`}
												label="Organisation Name"
												value={organisation}
												isRequired
												onChange={(event) =>
													setOrganisation(event.target.value)
												}
											/>
										</Row>
									)}
								</Col>
							</>
						</FormSection>
						<FormSection sectionTitle="Thoroughbred Information">
							<MessageBar
								style={{
									marginLeft: 15,
									marginRight: 15,
									height: 'auto',
									padding: 5
								}}
							>
								<div className={classes.infoContent}>
									<div className={classes.name}>
										{addNameToUnnamedHorse(horseData.name)}
									</div>
									<div className={classes.horseInfo}>
										{horseData.colour}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{horseData.sex}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										{horseData.age} {horseData.age > 1 ? 'years' : 'year'}
									</div>
								</div>
							</MessageBar>
						</FormSection>
					</FormBody>
					<FormFooter
						onCancel={onCancelForm}
						onSave={onSave}
						submitEnabled={formStatus !== REVIEW}
						showSaveButton={formStatus !== REVIEW}
						submitButtonText="Complete Form"
					/>
				</form>
			</PageLoadSpinner>
			<MobileModal
				positiveButton="Ok"
				showModal={showModal}
				closeModal={() => setShowModal(false)}
				styles={modalStyles}
				heading="Error completing form following fields are required"
			>
				{showRequiredFields()}
			</MobileModal>
			<FormCancelConfirmModal
				showCancelConfirmModal={confirmationModal}
				onCloseHandler={() => setConfirmationModal(false)}
				onConfirmHandler={deleteForm}
				modalTitle="Cancel Full Circle Request"
			/>
		</PageContainer>
	);
};

export default FullCircleForm;

import React from 'react';
import useStyles from './styles';

const HeaderBannerTypeEnum = Object.freeze({
	primary: 'primary',
	info: 'info'
});

const HeaderBanner = ({
	title,
	inline,
	top,
	type = HeaderBannerTypeEnum.primary,
	styles,
	rightTitle,
	mobile,
	className,
	dateText = null,
	onBehalfText = null
}) => {
	const classes = useStyles();

	const renderHeader = (mobile) => {
		if (mobile) {
			return (
				<>
					<div
						style={styles}
						className={`
            ${
							top
								? `${classes.topBannerMobile} ${classes.topBannerDefault}`
								: inline
								? `${classes.topBannerMobile} ${classes.topBannerInline}`
								: classes.topBannerMobile
						} ${classes[type]} ${className}`}
					>
						<div
							className={classes.topBannerTextMobile}
							style={
								rightTitle != null ? { padding: '13px 0px 20px 20px' } : {}
							}
						>
							<div>{title}</div>
							{dateText && <div>{dateText}</div>}
							{onBehalfText && (
								<div>
									<br />
									{onBehalfText}
								</div>
							)}
							{rightTitle != null && (
								<div style={{ float: 'left' }}>{rightTitle}</div>
							)}
						</div>
					</div>
				</>
			);
		} else {
			return (
				<>
					<div
						style={styles}
						className={`
          ${
						top
							? `${classes.topBanner} ${classes.topBannerDefault}`
							: inline
							? `${classes.topBanner} ${classes.topBannerInline}`
							: classes.topBanner
					} ${classes[type]} ${className}`}
					>
						<div className={classes.topBannerText}>
							<div>
								{title}
								{rightTitle != null && (
									<span style={{ float: 'right' }}>{rightTitle}</span>
								)}
							</div>
							{dateText}
							{onBehalfText ? <div>{onBehalfText}</div> : ''}
						</div>
					</div>
				</>
			);
		}
	};
	return renderHeader(mobile);
};

export default HeaderBanner;

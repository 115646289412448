import React, { useEffect, useState } from 'react';
import SEO from '../seo';
import { AuthProvider } from '../../hooks/useAuth';
import Carousel from '../Carousel';
import HomePage from '../Homepage';
import { useStaticQuery, graphql } from 'gatsby';
import { ScreenClassRender } from 'react-grid-system';

const IndexPage = () => {
	const [bannerDataArray, setBannerDataArray] = useState([]);
	const [rotatingBannerData, setRotatingBannerData] = useState([]);
	const [programsData, setProgramsData] = useState([]);
	const [ottSummary, setOttSummary] = useState(null);
	const [ottImage, setOttImage] = useState(null);
	const [ottContentType, setOttContentType] = useState(null);
	const [homePageArticle, setHomePageArticle] = useState([]);

	const contentFulData = useStaticQuery(
		graphql`
			query HomePageData {
				allContentfulHomepageBanner(
					limit: 5
					filter: {}
					sort: { fields: displayOrder, order: ASC }
				) {
					edges {
						node {
							displayOrder
							shortText
							title
							bannerImage {
								id
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
				allContentfulRotatingBanner(sort: { order: ASC, fields: priority }) {
					edges {
						node {
							quote
							priority
						}
					}
				}
				allContentfulPrograms(
					sort: { fields: programImage___updatedAt, order: ASC }
					limit: 4
				) {
					edges {
						node {
							programName
							programSummary
							buttonLink
							programImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
				allContentfulOttOverview(
					sort: { order: ASC, fields: ottImage___updatedAt }
				) {
					nodes {
						ottImage {
							fluid(quality: 100) {
								src
							}
							file {
								url
								contentType
							}
						}
						ottSummary {
							ottSummary
						}
					}
				}
				allContentfulHomePageArticle(sort: { fields: id, order: ASC }) {
					edges {
						node {
							title
							buttonLink
							buttonTitle
							text {
								text
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		let bannerDataArray = [];
		let rotatingBannerArray = [];
		let programsData = [];
		let homeArticle = [];
		contentFulData.allContentfulRotatingBanner.edges.map((data) => {
			rotatingBannerArray.push(data.node.quote);
		});
		setRotatingBannerData(rotatingBannerArray);
		contentFulData.allContentfulHomepageBanner.edges.map((data) => {
			bannerDataArray.push({
				text: data.node.shortText,
				image: data?.node?.bannerImage?.fluid.src,
				order: data.node.displayOrder
			});
		});
		setBannerDataArray(bannerDataArray);
		contentFulData.allContentfulPrograms.edges.map((data) => {
			programsData.push({
				title: data.node.programName,
				image: data?.node?.programImage?.fluid.src,
				buttonLink: data?.node?.buttonLink,
				summary: data.node.programSummary
			});
		});
		setProgramsData(programsData);
		let ottSummary =
			contentFulData.allContentfulOttOverview?.nodes[0]?.ottSummary
				?.ottSummary != null
				? contentFulData.allContentfulOttOverview?.nodes[0]?.ottSummary
						?.ottSummary
				: null;
		let ottImage =
			contentFulData.allContentfulOttOverview?.nodes[0]?.ottImage?.file.url !=
			null
				? contentFulData.allContentfulOttOverview?.nodes[0]?.ottImage?.file.url
				: null;
		let ottContentType =
			contentFulData.allContentfulOttOverview?.nodes[0]?.ottImage?.file
				.contentType != null
				? contentFulData.allContentfulOttOverview?.nodes[0]?.ottImage?.file
						.contentType
				: null;
		setOttSummary(ottSummary);
		setOttImage(ottImage);
		setOttContentType(ottContentType);
		contentFulData.allContentfulHomePageArticle.edges.map((data) => {
			homeArticle.push({
				title: data.node.title,
				text: data?.node?.text?.text,
				buttonLink: data.node.buttonLink,
				buttonTitle: data.node.buttonTitle
			});
		});
		setHomePageArticle(homeArticle);
	}, []);

	const Component = () => {
		return (
			<>
				<AuthProvider>
					<SEO title="Home" />
					{bannerDataArray.length > 0 && <Carousel data={bannerDataArray} />}
					<HomePage
						rotatingBannerData={rotatingBannerData}
						programData={programsData}
						ottSummary={ottSummary}
						ottImage={ottImage}
						homePageArticle={homePageArticle}
						ottContentType={ottContentType}
					/>
				</AuthProvider>
			</>
		);
	};

	return <ScreenClassRender render={Component} />;
};

export default IndexPage;

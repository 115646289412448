import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import { reviewHorseResetWeeklyForm } from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { getImage } from './utils';
import { formatDateReadableVariant } from '../../utils/helpers';

const ResetWeeklyFormReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);

	useEffect(() => {
		setRejectReason(data.reviewMessages === null ? '' : data.reviewMessages);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};
	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewHorseResetWeeklyForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewMessages: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveResetWeeklyProgressFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetWeeklyProgressFormStatus?.error?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewHorseResetWeeklyForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveResetWeeklyProgressFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetWeeklyProgressFormStatus?.error?.errorMessage
				);
			}
		});
	};
	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="RESET Weekly Progress"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastUpdated
									? formatDateReadableVariant(data.lastUpdated)
									: ''
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Week Number" value={data.weekId} />
					</Col>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>Progress photo/s</div>
							<UploadPhoto
								data={data.progressPhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
					{data.addImageForSale ? (
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.uploadFieldTitle}>Image For-Sale</div>
								<UploadPhoto
									data={data.imageForSale}
									getImage={(img) => getImage(img, setImgSrcUrl)}
									canEdit={false}
								/>
							</div>
						</Col>
					) : null}

					<Col xs={12}>
						<FormLabelField
							title="Has the horse been in work this week?"
							value={data.inWork ? 'Yes' : 'No'}
						/>
					</Col>
					{data.notInWorkReason && (
						<Col xs={12}>
							<FormLabelField
								title="Please explain why (Not In Work Reason)"
								value={data.notInWorkReason}
							/>
						</Col>
					)}
					{data.inWork && (
						<>
							<Col xs={12}>
								<FormLabelField
									title="Details of work sessions this week"
									value={data.inWorkDetails}
								/>
							</Col>
							<Col xs={12}>
								<FormLabelField
									title="Key progress/achievements to date"
									value={data.progressDetails}
								/>
							</Col>
							<Col xs={12}>
								<FormLabelField
									title="What area(s) need extra work/attention?"
									value={data.extraAttention}
								/>
							</Col>
						</>
					)}
					<Col xs={12}>
						<FormLabelField
							title="Work plan for the coming week?"
							value={data.plansForNextWeek}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Are there any lameness or physical health concerns?"
							value={data.isConcerned ? 'Yes' : 'No'}
						/>
					</Col>
					{data.isConcerned && (
						<Col xs={12}>
							<FormLabelField
								title="Please give details"
								value={data.isConcernedDetails}
							/>
						</Col>
					)}
					<Col xs={12}>
						<FormLabelField
							title="Has the horse required vet attention or treatment in the past week?"
							value={data.hasTreatements ? 'Yes' : 'No'}
						/>
					</Col>
					{data.hasTreatements && (
						<Col xs={12}>
							<FormLabelField
								title="Please explain why"
								value={data.hasTreatementsDetails}
							/>
						</Col>
					)}
					<Col xs={12}>
						<FormLabelField
							title="Do you have any other concerns to report? Changes to your Week 1 assessment?"
							value={data.reportConcerns}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.horse?.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.horse?.colour} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${data.member?.firstName} ${data.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.member?.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.member.phone} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ResetWeeklyFormReviewInfo;

import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import PrimaryButton from '../PrimaryButton';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import AppSyncService from '../../graphql/AppSyncService';
import FormTextField from '../FormTextField';
import SimpleCheckBox from '../SimpleCheckBox';
import { useToast } from '../../hooks/useToast';
import { mapToCheckbox, convertToCheckboxData } from '../../utils/helpers';
import {
	getMembershipListData,
	saveInterests,
	saveOtherInterests
} from '../../graphql/custom.queries';

const schema = yup.object().shape({});

const MemberInterests = ({ interestsData, otherInterestsData }) => {
	const classes = useStyles();
	const { addToast } = useToast();

	const { handleSubmit, errors, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const [referenceListInterests, setReferenceListInterests] = useState([]);
	const [checkBoxData, setCheckBoxData] = useState([]);
	const [selectedInterestsIds, setSelectedInterestsIds] = useState([]);
	const [otherInterestsValues, setOtherInterestsValues] = useState('');

	const [isFormDisabled, setIsFormDisabled] = useState(true);
	const OTHERINTERESTSID = 220;

	useEffect(() => {
		AppSyncService.execute(getMembershipListData).then((result) => {
			if (result.data) {
				setReferenceListInterests(result.data.listInterests);
			}
		});
	}, []);

	useEffect(() => {
		// Update the reference list with the selected ids
		setSelectedInterestsIds(interestsData);
		setOtherInterestsValues(otherInterestsData);
		prepareCheckBoxData(referenceListInterests);

		setIsFormDisabled(true);
	}, [interestsData, referenceListInterests]);

	useEffect(() => {
		// If the Other (299) is not selected clear values
		if (!selectedInterestsIds.includes(OTHERINTERESTSID)) {
			// Clear other interests
			setOtherInterestsValues('');
		}
	}, [selectedInterestsIds]);

	useEffect(() => {
		setIsFormDisabled(false);
	}, [otherInterestsValues]);

	const prepareCheckBoxData = (referenceListArray) => {
		let dataArray = [];
		let checkBoxIDs = [...referenceListArray];
		checkBoxIDs.map((item) => {
			let status = false;
			if (interestsData.includes(item.id)) {
				status = true;
			}
			dataArray.push(
				convertToCheckboxData(item.id, item.title, item.title, status)
			);
		});
		setCheckBoxData(mapToCheckbox(dataArray));
	};

	const onSubmit = () => {
		saveInterestsData();
		setIsFormDisabled(true);
	};

	const saveInterestsData = () => {
		let idsToSave = [];
		checkBoxData.map((item) => {
			if (item.isSelected) {
				idsToSave.push(item.id);
			}
		});
		AppSyncService.execute(saveInterests, {
			interestIds: idsToSave
		}).then((data) => {
			if (data.data != null) {
				addToast({ Message: 'Saved', IsSuccess: true });
			}
		});

		AppSyncService.execute(saveOtherInterests, {
			otherInterests: otherInterestsValues
		});
	};

	const updateCheckBox = (id) => {
		// copy the previous state and update only the value that has changed
		const updatedCheckbox = [...checkBoxData];
		updatedCheckbox.map((item) => {
			if (item.id === id) {
				item.isSelected = !item.isSelected;
			}
			if (id == OTHERINTERESTSID && !item.isSelected) {
				setOtherInterestsValues('');
			}
		});
		setCheckBoxData(updatedCheckbox);
		setIsFormDisabled(false);
	};

	return (
		<>
			<form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
				<div className={classes.formParentDiv}>
					<Row>
						<div className={classes.instructionContainer}>
							<label className={classes.fieldTitle}>
								Please select all that represent you
							</label>
						</div>
					</Row>
					<Row className={classes.extraPadding}>
						{checkBoxData.map((item) => (
							<Col key={`preference-${item.id}`} sm={4}>
								<div className={classes.checkBoxContainer}>
									<SimpleCheckBox
										small={true}
										data={item}
										changeCheckboxState={updateCheckBox}
										key={item.id}
									/>
								</div>
							</Col>
						))}
					</Row>
					{checkBoxData.find(
						(element) => element.id == OTHERINTERESTSID && element.isSelected
					) && (
						<Row>
							<div className={classes.textFieldContainer}>
								<div className={classes.fieldTitle}>
									Other (separated by commas)
								</div>
								<Controller
									control={control}
									name="otherInterestsValues"
									render={({ onChange }) => (
										<FormTextField
											value={otherInterestsValues}
											onChange={(e) => {
												onChange(e.target.value);
												setOtherInterestsValues(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.otherInterestsValues?.message}
								</span>
							</div>
						</Row>
					)}
					<Row>
						<Col></Col>
						<Col></Col>
						<div className={classes.buttonContainer}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									style={{ width: '165px' }}
									disabled={isFormDisabled}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</Row>
				</div>
			</form>
		</>
	);
};

export default MemberInterests;

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatToggleValue } from '../../../../utils/helpers';
import {
	COMPANY_BUSINESS_TYPE_ID,
	PARTNERSHIP_BUSINESS_TYPE_ID
} from '../../constants';
import { RETRAINER } from '../../../RetrainerProfileTemplate/constants';

const BusinessDetailsSection = ({ formType, formData }) => {
	return (
		<AdminReviewSection title="Business Details">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label={`Is your ${
							formType === RETRAINER ? 'retraining' : 'agistment'
						} operation under a company/business?`}
						value={
							formData?.businessName || formData?.businessType?.value
								? 'Yes'
								: 'No'
						}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please select the business structure that applies to you"
						value={formData?.businessType?.value}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					{formData?.businessName && (
						<AdminReviewDatum
							label="What is the name of the company/trust/partnership?"
							value={formData?.businessName}
						/>
					)}
					<AdminReviewDatum label="ABN" value={formData?.abn} />
				</SectionRow>
				{formData?.trusteeName && (
					<>
						<SectionRow>
							<AdminReviewDatum
								label="Please provide the name of the Trustee"
								value={formData?.trusteeName}
							/>
							<AdminReviewDatum
								label="Please provide the ACN of the Trustee (if applicable)"
								value={formData?.trusteeAcn}
							/>
						</SectionRow>
						<SectionRow>
							<AdminReviewDatum
								label="If the Trustee is a company, do you own all shares in that Company?"
								value={formatToggleValue(formData?.trusteeHasShares)}
								largeScreenWidth={12}
							/>
						</SectionRow>
						{formData?.trusteeFurtherDetails && (
							<SectionRow>
								<AdminReviewDatum
									label="If not, please provide further details"
									value={formData?.trusteeFurtherDetails}
									largeScreenWidth={12}
								/>
							</SectionRow>
						)}
					</>
				)}
				{formData?.businessType?.id == COMPANY_BUSINESS_TYPE_ID && (
					<SectionRow>
						<AdminReviewDatum
							label="Do you own all shares in that Company?"
							value={formatToggleValue(formData?.ownsAllCompanyShares)}
							largeScreenWidth={12}
						/>
						{formData?.companySharesOwnershipDetails && (
							<SectionRow>
								<AdminReviewDatum
									label="If not, please provide further details"
									value={formData?.companySharesOwnershipDetails}
									largeScreenWidth={12}
								/>
							</SectionRow>
						)}
					</SectionRow>
				)}
				{formData?.businessType?.id == PARTNERSHIP_BUSINESS_TYPE_ID &&
					formData?.partners.map(({ name, contactDetails }) => (
						<SectionRow key={name}>
							<AdminReviewDatum label="Partner Name" value={name} />
							<AdminReviewDatum
								label="Partner Contact Details"
								value={contactDetails}
							/>
						</SectionRow>
					))}
				{formData.additionalDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="Additional Details"
							value={formData?.additionalDetails}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label={`Do you have any additonal employment (outside your ${
							formType === RETRAINER ? 'retraining' : 'agistment'
						} business)?`}
						value={formData?.additionalEmployment?.id ? 'Yes' : 'No'}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Full Time or Part Time"
						value={formData?.additionalEmployment.value}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please provide additional details"
						value={formData?.additionalEmploymentDetails}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={`Do you have any business activities aside from ${
							formType === RETRAINER ? 'retraining' : 'agistment'
						}?`}
						value={formData?.otherActivitiesDetails ? 'Yes' : 'No'}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Other details"
						value={formData?.otherActivitiesDetails}
						largeScreenWidth={12}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default BusinessDetailsSection;

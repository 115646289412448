import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
	getEventById,
	getUpcomingEvents,
	getSelectedMemberRoles,
	getEventResultsById
} from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import {
	convertUTCTimeToLocal,
	eventColourMap,
	formatDateCalendarVariant,
	formatDateReadableVariant,
	getImageSrcUrl
} from '../../utils/helpers';
import SEO from '../seo';
import DropdownDown from '../../assets/icons/Dropdown_Down_icon.svg';
import DropdownUp from '../../assets/icons/Dropdown_Up_icon.svg';
import { Row, Col, Container } from 'react-grid-system';
import { useParams } from '@reach/router';
import PageContainer from '../PageContainer';
import LoaderSpinner from '../LoaderSpinner';
import { navigate } from 'gatsby-link';
import Triangle from '../../assets/icons/Triangle.svg';
import PrimaryButton from '../PrimaryButton';
import { useAuth } from '../../hooks/useAuth';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import CalendarIcon from '../../assets/icons/Calendar_icon.svg';
import DisciplineIcon from '../../assets/icons/discipline.png';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import AutolinkerWrapper from 'react-autolinker-wrapper';
import EventsUpcoming from '../EventsUpcoming';
import eventCancelledIcon from '../../assets/icons/Event_Cancelled_icon.svg';
import { ROLE_RV_EQUINE_WELFARE } from '../../utils/constants';
import { getColumnsEventResultReadOnly } from '../AddEvent/constants';
import EventResultsTable from '../EventResultsTable';

const Event = () => {
	const { id } = useParams();
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [upcomingEvents, setUpcomingEvents] = useState([]);
	const [event, setEvent] = useState(null);
	const [eventResults, setEventResults] = useState([]);
	const { currentUser } = useAuth();
	const [isOpenEventResults, setIsOpenEventResults] = useState(true);
	const [showAddEvent, setShowAddEvent] = useState(
		currentUser?.Roles.some((role) => role === ROLE_RV_EQUINE_WELFARE)
	);
	const [sortOrder, setSortOrder] = useState('asc');

	const [eventOrgImg, setEventOrgImg] = useState(null);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	const convertEventStructure = (event) => {
		let start = event.eventStartTime
			? event.eventStartDate + ' ' + convertUTCTimeToLocal(event.eventStartTime)
			: event.eventStartDate;
		let end = event.eventEndTime
			? (event.eventEndDate ?? event.eventStartDate) +
			  ' ' +
			  convertUTCTimeToLocal(event.eventEndTime)
			: event.eventEndDate ?? event.eventStartDate;
		if (event?.eventOrganiserImage?.key) {
			setEventOrgImg(event.eventOrganiserImage.key);
		}
		return {
			start: new Date(start),
			end: new Date(end),
			startTime: event.eventStartTime
				? convertUTCTimeToLocal(event.eventStartTime)
				: null,
			endTime: event.eventEndTime
				? convertUTCTimeToLocal(event.eventEndTime)
				: null,
			allDay: !event.eventStartTime && !event.eventEndTime,
			eventDiscipline: event.eventDiscipline,
			title: event.eventName,
			address: event.eventAddress,
			suburb: event.eventSuburb,
			postcode: event.eventPostcode,
			state: event.eventState,
			country: event.eventCountry,
			startDate: event.eventStartDate,
			endDate: event.eventEndDate,
			id: event.id,
			eventOrganiser: event.eventOrganiser,
			organiserOther: event.organiserOther,
			organiserContactDetails: event.organiserContactDetails,
			ottSponsoredClasses: event?.ottSponsoredClasses
				? event.ottSponsoredClasses.split(',')
				: null,
			ottSponsorshipDetails: event.ottSponsorshipDetails,
			howToEnter: event.howToEnter,
			entriesOpenDate: event.entriesOpenDate,
			entriesCloseDate: event.entriesCloseDate,
			judgingDetails: event.judgingDetails,
			photographerDetails: event.photographerDetails,
			socialMedia: event.socialMedia,
			isCancelled: event.isCancelled,
			disciplineOther: event.disciplineOther,
			otherInformation: event.otherInformation,
			isAddEventResults: event.isAddEventResults,
			showBottomRow:
				event.howToEnter ||
				event.entriesOpenDate ||
				event.entriesCloseDate ||
				event.judgingDetails ||
				event.photographerDetails ||
				event.socialMedia
		};
	};

	useEffect(() => {
		if (eventOrgImg) {
			getImageSrcUrl(eventOrgImg, setImgSrcUrl);
		}
	}, [eventOrgImg]);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getEventById, { eventId: id }).then((data) => {
			if (data?.data?.getEventById) {
				setEvent(convertEventStructure(data.data.getEventById));
				const selectedEvent = data.data.getEventById;
				AppSyncService.execute(getEventResultsById, { eventId: id }).then(
					(dataEventResults) => {
						if (dataEventResults.data) {
							setEventResults(
								sortData(
									dataEventResults.data.getEventResultsById?.map(
										(eventResult) => ({
											...eventResult,
											logo: null,
											horse: {
												horseCode: eventResult.horseCode,
												name: eventResult.horseName
											}
										})
									) || [],
									sortOrder,
									'result'
								)
							);
						}
					}
				);

				AppSyncService.execute(getSelectedMemberRoles, {
					memberExternalId: currentUser?.UserId
				}).then((data) => {
					if (data?.data?.getMemberRoles.length > 0) {
						if (!showAddEvent) {
							const {
								data: { getMemberRoles }
							} = data;
							const competitionAdminRoleExist = getMemberRoles.some(
								(role) => role.roleId === 12
							);

							if (competitionAdminRoleExist) {
								const filteredCompetitionAdminRole = getMemberRoles.find(
									(member) => member.roleId === 12
								);

								if (!filteredCompetitionAdminRole) {
									setShowAddEvent(false);
								} else {
									const evalEditEvent =
										selectedEvent.eventOrganiserId !== 12
											? selectedEvent.eventOrganiserId ===
											  filteredCompetitionAdminRole.eventOrganiserId
											: selectedEvent.organiserOther ===
											  filteredCompetitionAdminRole.organiserOther;
									setShowAddEvent(evalEditEvent);
								}
							}
						}
					}
				});
			}
		});
		AppSyncService.execute(getUpcomingEvents).then((data) => {
			if (data?.data?.getUpcomingEvents) {
				let upcoming = [];
				data.data.getUpcomingEvents.forEach((event) => {
					upcoming.push(convertEventStructure(event));
				});
				setUpcomingEvents(upcoming);
			}
		});
		setIsLoading(false);
	}, [id]);

	const sortData = (data, sortOrder, sortBy) => {
		const itemsToSort = JSON.parse(JSON.stringify(data));
		return itemsToSort.sort((a, b) => {
			if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
			else return sortOrder === 'asc' ? -1 : 1;
		});
	};

	const requestSort = (sortBy) => {
		const order = sortOrder === 'asc' ? 'desc' : 'asc';
		setSortOrder(order);

		setEventResults(sortData(eventResults, order, sortBy));
	};

	return (
		<PageContainer className={classes.container}>
			{event?.title && <SEO title={event?.title} />}
			<Container>
				<div className={classes.topBannerText}>
					<div className={classes.backLink} onClick={() => navigate('/events')}>
						<img src={Triangle} className={classes.arrowLeft} />
						Back to Events Calendar
					</div>
				</div>
				<div
					style={{
						background: eventColourMap(event)
					}}
					className={classes.eventBanner}
				>
					<div
						style={{ textDecoration: event?.isCancelled ? 'line-through' : '' }}
					>
						{event?.title}
					</div>
					{showAddEvent && (
						<PrimaryButton
							onClick={() => navigate(`/add-event/${id}`)}
							id="event-banner-edit-button"
							style={{ marginLeft: 10, width: 155, marginTop: 0 }}
						>
							Edit Event
						</PrimaryButton>
					)}
				</div>
				{event?.isCancelled && (
					<div
						style={{
							background: '#ffe4e4'
						}}
						className={classes.eventBanner}
					>
						<div style={{ color: 'red' }}>
							<img src={eventCancelledIcon} className={classes.warningIcon} />
							Event Cancelled
						</div>
					</div>
				)}
				<Row nogutter>
					<Col xs={12} lg={4} className={classes.leftCol}>
						<div style={{ padding: 15 }}>
							<div className={classes.eventDisciplineSection}>
								<img src={DisciplineIcon} className={classes.icon} />
								<div className={classes.eventDisciplineContent}>
									{event?.eventDiscipline === 'Other'
										? event?.disciplineOther !== ''
											? `Other - ${event.disciplineOther}`
											: 'Other'
										: event?.eventDiscipline}
								</div>
							</div>
							<div className={classes.leftColContent}>
								<img src={CalendarIcon} className={classes.icon} />
								{formatDateCalendarVariant(event?.startDate)}
								{event?.startDate !== event?.endDate &&
									` - ${formatDateCalendarVariant(event?.endDate)}`}
							</div>
							<div className={classes.leftColContent}>
								<AccessTimeIcon
									style={{ fill: '#006da6' }}
									className={classes.icon}
								/>
								{event?.startTime && ` ${event?.startTime}`}
								{event?.endTime && ` -  ${event?.endTime}`}
								{event?.allDay ? 'All Day' : ''}
							</div>
							<div className={classes.addressSectionContent}>
								<LocationOnOutlinedIcon
									style={{ fill: '#006da6' }}
									className={classes.icon}
								/>
								{event?.address && (
									<a
										style={{ color: '#006da6' }}
										href={`https://maps.google.com/?q=${event.address} ${event?.suburb} ${event?.state} ${event?.postcode} ${event?.country}`}
										target="_blank"
										rel="noreferrer"
									>
										<div>
											{`${event.address}`}
											{event?.suburb && <>{`, ${event.suburb}`}</>}
											{event?.state && <>{`, ${event.state}`}</>}
											{event?.postcode && <>{`, ${event.postcode}`}</>}
											{event?.country && <>{`, ${event.country}`}</>}
										</div>
									</a>
								)}
							</div>
							<div className={classes.colContent}>
								<InfoBar
									type={MessageBarTypeEnum.info}
									noButton
									style={{
										borderRadius: 5,
										fontSize: 15,
										fontWeight: 'normal',
										padding: '0px 20px'
									}}
								>
									The event details are only correct at time of publication and{' '}
									<b>
										{event?.eventOrganiser === 'Other'
											? event?.organiserOther
											: event?.eventOrganiser}
									</b>{' '}
									is responsible for all event details.
								</InfoBar>
							</div>
						</div>
					</Col>
					<Col xs={12} lg={8} className={classes.rightCol}>
						<Row nogutter>
							<Col xs={12} lg={6} style={{ marginTop: 15 }}>
								<div style={{ padding: 15 }}>
									{imgSrcUrl && (
										<>
											<div>
												<img
													src={imgSrcUrl}
													className={classes.organiserImage}
												/>
											</div>
										</>
									)}
									<div className={classes.colContent}>
										<b>Event Organiser</b>
									</div>
									<div className={classes.colContent}>
										{event?.eventOrganiser === 'Other'
											? event?.organiserOther
											: event?.eventOrganiser}
									</div>
									<div className={classes.colContent}>
										<b>Organiser contact details</b>
									</div>
									<div className={classes.colContent}>
										<AutolinkerWrapper
											className={classes.autoLink}
											tagName="span"
											text={event?.organiserContactDetails}
											options={{
												newWindow: true,
												stripPrefix: false,
												mention: 'twitter'
											}}
										/>
									</div>
									{event?.otherInformation && (
										<>
											<div className={classes.colContent}>
												<b>Other Information</b>
											</div>
											<div className={classes.colContent}>
												{event?.otherInformation}
											</div>
										</>
									)}
								</div>
							</Col>
							<Col xs={12} lg={6}>
								<div style={{ padding: 15 }}>
									{event?.ottSponsoredClasses && (
										<>
											<div className={classes.colContent}>
												<b>OTT sponsored classes</b>
											</div>
											<div
												className={classes.colContent}
												style={{ display: 'inline-flex' }}
											>
												{event.ottSponsoredClasses.map(
													(sponsoredClass, index) => {
														return (
															<div
																key={index}
																className={classes.sponsoredClass}
															>
																{sponsoredClass}
															</div>
														);
													}
												)}
											</div>
										</>
									)}
									{event?.ottSponsorshipDetails && (
										<>
											<div className={classes.colContent}>
												<b>OTT sponsorship details</b>
											</div>
											<div className={classes.colContent}>
												{event.ottSponsorshipDetails}
											</div>
										</>
									)}
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
				{event?.showBottomRow && (
					<>
						<Container className={classes.bottomRow}>
							<Row nogutter>
								{event?.howToEnter && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>How to enter</b>
										</div>
										<div className={classes.colContent}>
											<AutolinkerWrapper
												className={classes.autoLink}
												tagName="span"
												text={event.howToEnter}
												options={{
													newWindow: true,
													stripPrefix: false,
													mention: 'twitter'
												}}
											/>
										</div>
									</Col>
								)}
								{event?.entriesOpenDate && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>Entries open</b>
										</div>
										<div className={classes.colContent}>
											{formatDateReadableVariant(event.entriesOpenDate)}
										</div>
									</Col>
								)}
								{event?.entriesCloseDate && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>Entries close</b>
										</div>
										<div className={classes.colContent}>
											{formatDateReadableVariant(event.entriesCloseDate)}
										</div>
									</Col>
								)}
							</Row>
							<Row nogutter>
								{event?.judgingDetails && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>Judging details</b>
										</div>
										<div className={classes.colContent}>
											{event.judgingDetails}
										</div>
									</Col>
								)}
								{event?.photographerDetails && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>Photographer details</b>
										</div>
										<div className={classes.colContent}>
											<AutolinkerWrapper
												className={classes.autoLink}
												tagName="span"
												text={event?.photographerDetails}
												options={{
													newWindow: true,
													stripPrefix: false,
													mention: 'twitter'
												}}
											/>
										</div>
									</Col>
								)}
								{event?.socialMedia && (
									<Col xs={12} lg={4}>
										<div className={classes.colContent}>
											<b>Social media links</b>
										</div>
										<div className={classes.colContent}>
											<AutolinkerWrapper
												className={classes.autoLink}
												tagName="span"
												text={event.socialMedia}
												options={{
													newWindow: true,
													stripPrefix: false,
													mention: 'twitter'
												}}
											/>
										</div>
									</Col>
								)}
							</Row>
						</Container>
					</>
				)}
			</Container>
			{event?.isAddEventResults && (
				<Container style={{ marginTop: 20 }}>
					<div
						style={{
							background: eventColourMap(event)
						}}
						className={classes.eventBanner}
					>
						Event Results
						<div
							style={{ cursor: 'pointer' }}
							onClick={() => setIsOpenEventResults(!isOpenEventResults)}
						>
							{!isOpenEventResults && <img src={DropdownDown} />}{' '}
							{isOpenEventResults && <img src={DropdownUp} />}
						</div>
					</div>
					{isOpenEventResults && (
						<Row
							className={classes.formRow}
							nogutter
							style={{ border: '2px solid #b3bcc1', borderTopStyle: 'hidden' }}
						>
							<Col xs={12} lg={12} style={{ padding: '0 20px 20px 20px' }}>
								{eventResults && eventResults.length > 0 ? (
									<EventResultsTable
										columns={getColumnsEventResultReadOnly()}
										data={eventResults}
										id="event-results-table"
										sortOrder={sortOrder}
										requestSort={requestSort}
									/>
								) : (
									<div style={{ marginTop: 10 }}>No result found.</div>
								)}
							</Col>
						</Row>
					)}
				</Container>
			)}
			<div className={classes.calendar}>
				<LoaderSpinner
					id="spinner"
					status={isLoading}
					styles={{ position: 'absolute', top: '50%', left: '50%' }}
				/>
			</div>
			{upcomingEvents && (
				<div style={{ marginTop: 20 }}>
					<EventsUpcoming upcomingEvents={upcomingEvents} />
				</div>
			)}
		</PageContainer>
	);
};
export default Event;

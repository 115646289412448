import React from 'react';
import useStyles from './styles';

const EmptyFollowsBanner = () => {
	const classes = useStyles();
	return (
		<div className={classes.emptyFollowsBanner}>
			You are not following any Thoroughbreds, Search then Follow Thoroughbreds,
			to receive updates here.
		</div>
	);
};

export default EmptyFollowsBanner;

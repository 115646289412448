export const RESET_EXPRESS_QUALIFICATIONS = {
	B1: 'Listed, B1',
	B2: 'Listed, B2',
	C: 'Listed, C'
};

export const RESET_EXPRESS_COLORS = {
	B1: '#61e474',
	B2: '#ddb34e',
	C: '#d474c8'
};

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	resultContainer: {
		maxHeight: 304,
		border: '1px solid #d9dde0',
		borderTop: 'none',
		overflowY: 'auto'
	},
	resultColumn: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: 20,
		boxSizing: 'border-box'
	},
	singleResultContainer: {
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		cursor: 'pointer',
		fontSize: 15
	},
	multiResultsContainer: {
		height: 30,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 13
	},
	singleFormResultContainer: {
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 15
	},
	longText: {
		width: 450,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		'@media (max-width:992px)': {
			width: 80
		}
	},
	filterContainer: {
		background: '#dcebf3',
		minHeight: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingBottom: 5,
		paddingTop: 12
	},
	formLinkButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginRight: 10,
		width: '100%',
		height: '100%'
	},
	memberBenefitsTable: {
		borderCollapse: 'collapse',
		width: '100%'
	},
	memberBenefitsTableData: {
		border: '1px solid #dddddd',
		textalign: 'left',
		padding: 8,
		width: 300,
		minHeight: 80,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	updatedBy: {
		maxWidth: 300,
		padding: 8,
		textAlign: 'left',
		minHeight: 30,
		fontStyle: 'italic'
	}
}));

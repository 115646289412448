import React from 'react';
import useStyles from './styles';
import { Container, Col, Row, useScreenClass } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import FormDatePicker from '../FormDatePicker';
import { checkIsSmallScreen, getMinDate } from '../../utils/helpers';
import FormToolTip from '../FormToolTip';

const HorseFilterSearch = ({
	referenceListCountries,
	referenceListColours,
	referenceListGenders,
	updateHorseMicrochipNumber,
	horseMicrochipNumber,
	updateDamName,
	damName,
	updateSireName,
	sireName,
	updateHorseCountryOfBirth,
	horseCountryOfBirth,
	updateHorseFoalDate,
	horseFoalDate,
	updateHorseColour,
	horseColour,
	updateHorseGender,
	horseGender,
	updateHorseBrand,
	horseBrand,
	mobile
}) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	return (
		<>
			<Container
				style={
					mobile
						? { background: '#ededed', minWidth: 100, paddingBottom: 20 }
						: { background: '#ededed', paddingBottom: 20 }
				}
			>
				<Row>
					<Col style={mobile ? { paddingRight: 0 } : ''}>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Microchip No.
						</div>
						<input
							name="mcno"
							type="text"
							maxLength={18}
							id="mcno"
							className={classes.filterField}
							value={horseMicrochipNumber}
							onChange={(e) => updateHorseMicrochipNumber(e.target.value)}
						/>
					</Col>
					<Col>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Country of Birth
						</div>
						<FormDropDown
							mobile={mobile}
							name="hCountryOfBirth"
							selectedId={horseCountryOfBirth}
							items={referenceListCountries.map((item) => {
								return {
									id: item,
									value: item
								};
							})}
							onChange={(e) => updateHorseCountryOfBirth(e.target.value)}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={mobile ? { paddingRight: 0 } : ''}>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Dam Name
						</div>
						<input
							name="damName"
							type="text"
							id="damName"
							className={classes.filterField}
							value={damName}
							onChange={(e) => updateDamName(e.target.value)}
						/>
					</Col>
					<Col style={mobile ? { paddingRight: 0 } : ''}>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Sire Name
						</div>
						<input
							name="sireName"
							type="text"
							id="sireName"
							className={classes.filterField}
							value={sireName}
							onChange={(e) => updateSireName(e.target.value)}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={mobile ? { paddingRight: 0 } : ''}>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Colour
						</div>
						<FormDropDown
							mobile={mobile}
							name="hColour"
							selectedId={horseColour}
							items={referenceListColours.map((item) => {
								return {
									id: item,
									value: item
								};
							})}
							onChange={(e) => updateHorseColour(e.target.value)}
						/>
					</Col>
					<Col>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Sex
						</div>
						<FormDropDown
							mobile={mobile}
							name="hGender"
							selectedId={horseGender}
							items={referenceListGenders.map((item) => {
								return {
									id: item,
									value: item
								};
							})}
							onChange={(e) => {
								updateHorseGender(e.target.value);
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Birth Year
						</div>
						<FormDatePicker
							name="foalDate"
							format="yyyy"
							styles={{ width: '100%', color: 'blue' }}
							customDatePicker
							DateValue={horseFoalDate}
							updateSelection={(value) => updateHorseFoalDate(value)}
							minDate={getMinDate(29)}
						/>
					</Col>
					<Col>
						<div
							className={
								mobile
									? classes.filterContainerHeaderMobile
									: classes.filterContainerHeader
							}
						>
							Brand Description Search (Near-side and/ or Off-side)
							<FormToolTip
								type="questionMark"
								toolTipContainerStyle={{
									fontWeight: 'normal',
									...(isSmallScreen && {
										top: -80,
										left: -200
									})
								}}
							>
								This is the brand description as described in the Australian
								Stud Book and will only provide exact matches (inclusive of
								spaces), you can use all or part of the brand description. If
								you need further assistance identifying your OTT, please
								complete the Find your Thoroughbred form.
							</FormToolTip>
						</div>
						<input
							name="horseBrand"
							type="text"
							id="horseBrand"
							className={classes.filterField}
							value={horseBrand}
							onChange={(e) => updateHorseBrand(e.target.value)}
						/>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default HorseFilterSearch;

export const CONCLUDED_REASON = {
	EUTHANISED: 1,
	SOLD_REHOMED: 2,
	RETAINING_FOR_OWN_USE: 3
};

export const REHABILIATION = 'Rehabilitation';
export const SPELLING = 'Spelling';
export const CONCLUDED = 'Concluded';

export const MAXREASONLENGTH = 300;

import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getMemberProfile,
	saveBusinessDetails
} from '../../graphql/custom.queries';
import FormTextField from '../FormTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import {
	CommonKeys,
	INVALID_EMAIL_MESSAGE,
	ProfileKeys
} from '../../utils/constants';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import SimpleCheckBox from '../SimpleCheckBox';

// form validation rules
const fosterSchema = yup.object().shape({
	businessName: yup
		.string() //Business name can have special characters
		.required('Business Name is required')
		.typeError('Business Name is required')
		.max(55, 'Business name must have maximum of 55 characters'),
	firstName: yup
		.string() //First name can have special characters
		.required('First Name is required')
		.typeError('First Name is required'),
	lastName: yup
		.string() //Last name can have special characters
		.required('Last Name is required')
		.typeError('Last Name is required'),
	email: yup
		.string()
		.required('Email is required')
		.email(INVALID_EMAIL_MESSAGE)
		.typeError('Email is required'),
	phone: yup
		.string()
		.required('Phone number is required')
		.matches(/^\+\d*$|^\d*$/, 'Please enter valid Phone number')
		.typeError('Phone number is required'),
	alternativePhone: yup
		.string()
		.matches(/^\+\d*$|^\d*$/, 'Please enter valid Alternative Phone number')
		.typeError('Please enter valid Alternative Phone number'),
	alternativeContact: yup
		.string()
		.typeError('Please enter valid Alternative Contact'),
	streetAddress: yup
		.string() //Street Address can have special characters
		.required('Street Address is required')
		.typeError('Street Address is required'),
	suburb: yup
		.string() //Suburb can have special characters
		.required('Suburb is required')
		.typeError('Suburb is required'),
	state: yup
		.string()
		.required('State is required')
		.typeError('State is required'),
	postcode: yup
		.string()
		.required('Postcode is required')
		.min(4, 'Postal code must be a minimum of 4 characters')
		.matches(
			/^\d+$/i,
			'Please enter valid postcode, postcode should only be numeric'
		)
		.typeError('Postcode is required')
});

const BusinessProfileDetailsFoster = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			updateIsLoading,
			setIsSubmitted,
			onSaveBusinessProfileData,
			businessProfileType,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();
		const { handleSubmit, errors, control, setValue, trigger } = useForm({
			mode: 'onSubmit',
			resolver: yupResolver(fosterSchema)
		});

		const [businessName, setBusinessName] = useState('');
		const [firstName, setFirstName] = useState('');
		const [lastName, setLastName] = useState('');
		const [email, setEmail] = useState('');
		const [phone, setPhone] = useState('');
		const [alternativeContact, setAlternativeContact] = useState('');
		const [alternativePhone, setAlternativePhone] = useState('');

		const [streetAddress, setStreetAddress] = useState('');
		const [suburb, setSuburb] = useState('');
		const [state, setState] = useState('');
		const [postcode, setPostcode] = useState('');
		const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
		const manualAddressCheckBoxData = {
			id: ProfileKeys.MANUAL_ADDRESS,
			label: 'Manually add my address',
			value: 'Manually add my address',
			isSelected: isPostalAddressManual
		};

		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);

		useEffect(() => {
			setBusinessName(
				businessProfileData ? businessProfileData.businessName : ''
			);
			setValue(
				'businessName',
				businessProfileData ? businessProfileData.businessName : ''
			);

			setEmail(businessProfileData ? businessProfileData.email : '');
			setValue('email', businessProfileData ? businessProfileData.email : '');

			setPhone(businessProfileData ? businessProfileData.businessPhone : '');
			setValue(
				'phone',
				businessProfileData ? businessProfileData.businessPhone : ''
			);

			setAlternativeContact(
				businessProfileData ? businessProfileData.alternativeContact : ''
			);
			setValue(
				'alternativeContact',
				businessProfileData ? businessProfileData.alternativeContact : ''
			);

			setAlternativePhone(
				businessProfileData ? businessProfileData.alternativePhone : ''
			);
			setValue(
				'alternativePhone',
				businessProfileData ? businessProfileData.alternativePhone : ''
			);

			setStreetAddress(
				businessProfileData ? businessProfileData.streetAddress : ''
			);
			setValue(
				'streetAddress',
				businessProfileData ? businessProfileData.streetAddress : ''
			);

			setSuburb(businessProfileData ? businessProfileData.suburb : '');
			setValue('suburb', businessProfileData ? businessProfileData.suburb : '');

			setState(businessProfileData ? businessProfileData.state : '');
			setValue('state', businessProfileData ? businessProfileData.state : '');

			setPostcode(businessProfileData ? businessProfileData.postcode : '');
			setValue(
				'postcode',
				businessProfileData ? businessProfileData.postcode : ''
			);

			//Exlcude business phone because its not included in the register
			if (
				!(
					businessProfileData &&
					businessProfileData.firstName &&
					businessProfileData.lastName
				)
			) {
				getMemberProfileInfo();
			} else {
				setFirstName(businessProfileData.firstName);
				setValue('firstName', businessProfileData.firstName);
				setLastName(businessProfileData.lastName);
				setValue('lastName', businessProfileData.lastName);
				setPhone(businessProfileData.businessPhone);
				setValue('phone', businessProfileData.businessPhone);
			}

			updateIsLoading(false);
			setIsFormDisabled(true);
			setIsFormDisabled(true);
		}, [businessProfileData]);

		useImperativeHandle(ref, () => ({
			async triggerValidation() {
				await trigger();
			}
		}));

		const getMemberProfileInfo = () => {
			if (currentUser !== null) {
				const externalId = { externalId: currentUser?.UserId };
				AppSyncService.execute(getMemberProfile, externalId).then((data) => {
					if (data.data != null) {
						setFirstName(data.data.getMember?.firstName);
						setLastName(data.data.getMember?.lastName);
						setPhone(data.data.getMember?.businessPhone);
						setValue('firstName', data.data.getMember?.firstName);
						setValue('lastName', data.data.getMember?.lastName);
						setValue('phone', data.data.getMember?.businessPhone);
					}
				});
			}
		};

		const onSubmit = async () => {
			validateFields();
			setBackendErrorMessage(null);
			try {
				if (businessName.trim().length == 0) {
					throw 'Business name is required';
				}
				const currentState = {
					businessName: businessName.trim(),
					status: 'Draft'
				};
				AppSyncService.execute(saveBusinessDetails, {
					businessName: businessName.trim(),
					businessProfileTypeId: businessProfileType,
					memberExternalId: currentUser?.UserId,
					firstName: firstName,
					lastName: lastName,
					businessPhone: phone,
					email: email,
					postcode: postcode,
					state: state,
					streetAddress: streetAddress,
					suburb: suburb,
					alternativeContact: alternativeContact,
					alternativePhone: alternativePhone
				}).then((data) => {
					if (data.data != null) {
						addToast({ Message: 'Saved', IsSuccess: true });
						setIsSubmitted(false);
						onSaveBusinessProfileData(currentState);
					}
				});
				setIsFormDisabled(true);
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving user Business profile data. Please contact support'
					);
				}
			}
		};

		const toggleManualAddress = () => {
			setIsPostalAddressManual(!isPostalAddressManual);
		};

		const populateResidentialAddressFields = (addressDetails) => {
			const address = `${addressDetails.Line1}${
				addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
			}`;
			setStreetAddress(address);
			setValue('streetAddress', address);
			setSuburb(addressDetails.Suburb);
			setValue('suburb', addressDetails.Suburb);
			setState(addressDetails.State);
			setValue('state', addressDetails.State);
			setPostcode(addressDetails.Postcode);
			setValue('postcode', addressDetails.Postcode);
		};
		return (
			<>
				<form
					id={ProfileKeys.BUSINESS_DETAILS}
					className={classes.formContainer}
					onSubmit={handleSubmit(onSubmit, () => validateFields())}
				>
					<div className={classes.personalProfile}>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Business Name*</div>
									<Controller
										control={control}
										name="businessName"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.BUSINESS_NAME}
												value={businessName}
												onChange={(e) => {
													onChange(e.target.value);
													setBusinessName(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.businessName?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>First Name*</div>
									<Controller
										control={control}
										name="firstName"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.FIRST_NAME}
												value={firstName}
												onChange={(e) => {
													onChange(e.target.value);
													setFirstName(e.target.value);
													setIsFormDisabled(false);
												}}
												//isDisabled={true} //pre-filled upon init
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.firstName?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Last Name*</div>
									<Controller
										control={control}
										name="lastName"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.LAST_NAME}
												value={lastName}
												onChange={(e) => {
													onChange(e.target.value);
													setLastName(e.target.value);
													setIsFormDisabled(false);
												}}
												//isDisabled={true} //pre-filled upon init
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.lastName?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Business Phone*</div>
									<Controller
										control={control}
										name="phone"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.BUSINESS_PHONE}
												value={phone}
												onChange={(e) => {
													onChange(e.target.value);
													setPhone(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.phone?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Business Email*</div>
									<Controller
										control={control}
										name="email"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.BUSINESS_EMAIL}
												value={email}
												onChange={(e) => {
													onChange(e.target.value);
													setEmail(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.email?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Alternative Contact</div>
									<Controller
										control={control}
										name="alternativeContact"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.ALTERNATIVE_CONTACT}
												value={alternativeContact}
												onChange={(e) => {
													onChange(e.target.value);
													setAlternativeContact(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.alternativeContact?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Alternative Phone</div>
									<Controller
										control={control}
										name="alternativePhone"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.ALTERNATIVE_PHONE}
												value={alternativePhone}
												onChange={(e) => {
													onChange(e.target.value);
													setAlternativePhone(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.alternativePhone?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Street Address*</div>
									<Controller
										control={control}
										name="streetAddress"
										render={({ onChange }) => (
											<AddressAutoSuggestion
												value={streetAddress}
												onChange={(value) => {
													onChange(value);
													setStreetAddress(value);
													setIsFormDisabled(false);
												}}
												onAddressSelect={populateResidentialAddressFields}
												disableAutoSuggestion={isPostalAddressManual}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.streetAddress?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Suburb*</div>
									<Controller
										control={control}
										name="suburb"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.SUBURB}
												value={suburb}
												isDisabled={!isPostalAddressManual}
												onChange={(e) => {
													onChange(e.target.value);
													setSuburb(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.suburb?.message}
									</span>
								</div>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>State*</div>
									<Controller
										control={control}
										name="state"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.STATE}
												value={state}
												isDisabled={!isPostalAddressManual}
												onChange={(e) => {
													onChange(e.target.value);
													setState(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.state?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Postcode*</div>
									<Controller
										control={control}
										name="postcode"
										render={({ onChange }) => (
											<FormTextField
												id={ProfileKeys.POSTCODE}
												value={postcode}
												isDisabled={!isPostalAddressManual}
												onChange={(e) => {
													onChange(e.target.value);
													setPostcode(e.target.value);
													setIsFormDisabled(false);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.postcode?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<SimpleCheckBox
									small={true}
									noCapitalize={true}
									data={manualAddressCheckBoxData}
									changeCheckboxState={toggleManualAddress}
									key={
										manualAddressCheckBoxData && manualAddressCheckBoxData.id
									}
								/>
							</Col>
						</Row>
					</div>
					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									id={CommonKeys.SAVE}
									style={{ width: '165px' }}
									disabled={isFormDisabled}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
			</>
		);
	}
);

export default BusinessProfileDetailsFoster;

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import UploadPhoto from '../../../UploadPhoto';
import { formatToggleValue } from '../../../../utils/helpers';

const RetrainingProcessSection = ({ formData, previewImage }) => {
	return (
		<AdminReviewSection title="Retraining Process">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Why do you want to become an Acknowledged Retrainer?"
						value={formData?.reasonToBecomeAckRetrainer}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="What are your expectations for support from Racing Victoria?"
						value={formData?.expectations}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please describe your retraining process using examples"
						value={formData?.retrainingProcess}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please outline the steps you take to ensure your OTT horses are placed in suitable long-term homes"
						value={formData?.stepsTakenForLongTermHorsePlacement}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Upload Signed References"
						value={
							<UploadPhoto
								data={formData?.signedReferences}
								getImage={previewImage}
								canEdit={false}
							/>
						}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="If you have a horse in your stable that is unsuitable to be retrained (e.g. temperament or soundness issues) how would you manage this scenario?"
						value={formData?.manageUnsuitableDetails}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="Please outline your business model and the arrangements you propose to have with your clients"
						value={formData?.businessModel}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label="From time to time, Racing Victoria receives emergency cases that require our network of retrainers to be called upon to assist horses in need. Do you have capability to take on a horse if necessary?"
						value={formatToggleValue(formData?.canReceiveEmergencyCases)}
						largeScreenWidth={12}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default RetrainingProcessSection;

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { navigate } from '@reach/router';
import React from 'react';
import { Col, Row, useScreenClass } from 'react-grid-system';
import MapPinIcon from '../../assets/icons/Map_Pin_icon.svg';
import { HORSE_AVAILABILITY_REMOVED_STATUS } from '../../utils/constants';
import {
	addNameToUnnamedHorse,
	formatDateReadableVariant,
	formatFormSummary
} from '../../utils/helpers';
import BasicChip from '../BasicChip';
import HorseProfileField from '../HorseProfileField';
import LazyImage from '../LazyImage';
import { Button } from '../Mobile';
import ResetExpressTag from '../ResetExpressTag';
import useStyles from './styles';
import { getMoreDetaillsStyle } from './utils';

const TAGS_TOTAL_LENGTH = 45;

const AvailableForRetrainingRecord = ({
	data,
	selectedHorseStatus,
	isRetrainerOnly
	// isRetrainerWithResetExpress,
	// isAdmin
}) => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();

	const renderHorseHistory = () => {
		if (data.riddenHistory != null && data.riddenHistory.length > 0) {
			if (data.riddenHistory.length > 300) {
				let horseString = data.riddenHistory.substring(0, 300);
				horseString = horseString.concat(' ...');
				return horseString;
			} else {
				return data.riddenHistory;
			}
		}
	};

	const renderTags = () => {
		let totalLength = 0;
		let disciplines = [];
		let tempString = '';
		if (data.disciplines.length > 0) {
			data.disciplines.map((t) => {
				let value = t.value;
				totalLength += tempString.concat(value).length;
				if (totalLength < TAGS_TOTAL_LENGTH) {
					disciplines.push(t.value);
				}
			});
			return disciplines.map((t) => {
				return (
					<div key={t} className={classes.tags}>
						{t}
					</div>
				);
			});
		}
	};

	const listingId = `availableforretraining-listing-${data.id}`;
	return (
		<>
			<Row
				id={listingId}
				className={
					['xs', 'sm', 'md'].includes(screenClassForRender)
						? classes.horseInfoContainerMobile
						: classes.horseInfoContainer
				}
			>
				<Col
					xs={12}
					lg={5}
					className={classes.horseImageContainer}
					id={`${listingId}-horseimage`}
				>
					{data.leftSidePhotos.filter((a) => a.isApproved).length > 0 && (
						<LazyImage
							imageKey={data.leftSidePhotos.filter((a) => a.isApproved)[0].key}
							styles={{ height: 365, borderRadius: '5px 0px 0px 5px' }}
						/>
					)}
				</Col>
				<Col xs={12} lg={7} id={`${listingId}-horseinfo`}>
					<Row className={classes.horseInfo}>
						<Col lg={7} className={classes.horseInfoLeft}>
							<div
								className={
									data.horseOTTStatusID === HORSE_AVAILABILITY_REMOVED_STATUS
										? classes.horseNameGrey
										: classes.horseName
								}
								style={{
									marginBottom: data.eligibilityForBCTS ? 15 : 35
								}}
							>
								{addNameToUnnamedHorse(data.horse?.name)}
							</div>
							{selectedHorseStatus == 8 &&
							data.latestRESETExpressQualification?.id ? (
								<ResetExpressTag
									latestRESETExpressQualification={
										data?.latestRESETExpressQualification?.value
									}
								/>
							) : null}
							{selectedHorseStatus != 8 && data.eligibilityForBCTS ? (
								<BasicChip
									containerStyle={{ marginBottom: 20, marginLeft: 20 }}
									icon={<CheckCircleIcon style={{ color: '#115293' }} />}
									styles={{
										fontSize: 13,
										fontWeight: 550,
										fontStyle: 'italic',
										backgroundColor: '#dcebf3',
										color: '#5aa3d5'
									}}
									label={'BCTS Eligible'}
								/>
							) : null}

							<HorseProfileField
								mobile
								field="Age"
								value={`${data.horse?.age} years`}
							/>
							<HorseProfileField
								mobile
								field="Sex"
								value={`${data.horse?.sex}`}
							/>
							<HorseProfileField
								mobile
								field="Approx. height"
								value={data.height}
							/>
							<HorseProfileField
								mobile
								field="Date last raced"
								value={
									data.horse?.racingSummary?.dateLastRaced
										? formatDateReadableVariant(
												data.horse?.racingSummary?.dateLastRaced
										  )
										: '-'
								}
							/>
							<HorseProfileField
								noline
								mobile
								field="Form summary"
								value={formatFormSummary(
									data.horse?.racingSummary?.raceTypeSummary
								)}
							/>
						</Col>
						<Col lg={5} className={classes.moreDetails}>
							{!['xs', 'sm', 'md'].includes(screenClassForRender) && (
								<div>
									<Button
										id="availableForRetraining-horseDetails-moreDetails"
										text="More Details"
										styles={getMoreDetaillsStyle(data, isRetrainerOnly)}
										onClick={() =>
											navigate(`/horse-details/${data.horse.horseCode}`)
										}
									/>
								</div>
							)}
							<div className={classes.location}>
								<img src={MapPinIcon} className={classes.mapPin} />
								<span
									className={classes.locationName}
								>{`${data.suburb}, ${data.postcode}`}</span>
							</div>
							<div className={classes.tagsContainer}>{renderTags()}</div>
						</Col>
					</Row>
					<Row id={`${listingId}-horseInfo-riddenHistory`}>
						<Col style={{ marginTop: 15, paddingLeft: 20, paddingRight: 20 }}>
							<div className={classes.riddenHistoryHeading}>Ridden History</div>
							<div className={classes.horseHistory}>{renderHorseHistory()}</div>
						</Col>
					</Row>
					{['xs', 'sm', 'md'].includes(screenClassForRender) && (
						<Row>
							<Col className={classes.mobileButton}>
								<Button
									id="availableForRetraining-horseDetails-moreDetailsMobile"
									text="More Details"
									styles={getMoreDetaillsStyle(data, isRetrainerOnly)}
									onClick={() =>
										navigate(`/horse-details/${data.horse.horseCode}`)
									}
								/>
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</>
	);
};
export default AvailableForRetrainingRecord;

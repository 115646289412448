import React, { useState } from 'react';
import FormDropDown from '../FormDropDown';
import {
	formatDateReadableVariant,
	getHorseDisplayName
} from '../../utils/helpers';
import {
	RESET,
	ACKNOWLEDGED_RETIREMENT,
	FOSTER,
	RETRAINER,
	RESET_EXPRESS
} from '../../utils/constants';
import { navigate } from '@reach/router';
import useStyles from './styles';
import AcknowledgedRetirementInitialAssessmentForm from '../AcknowledgedRetirementInitialAssessmentForm';
import AcknowledgedRetirementQuarterlyAssessmentForm from '../AcknowledgedRetirementQuarterlyAssessmentForm';
import RESETInitialAssessmentForm from '../RESETInitialAssessmentForm';
import RESETWeeklyProgressForm from '../RESETWeeklyProgressForm';
import { Row, Col, Container } from 'react-grid-system';
import HorseAvatar from '../HorseAvatar';
import { HorseManagementKeys } from './../../utils/constants/index';
import AcknowledgedFosterInitialAssessmentForm from '../AcknowledgedFosterInitialAssessmentForm';
import AcknowledgedFosterWeeklyAssessmentForm from '../AcknowledgedFosterWeeklyAssessmentForm';
import AcknowledgedResetExpressInitialAssessmentForm from '../AcknowledgedResetExpressInitialAssessmentForm';
import CheckboxInputField from '../FormComponents/CheckboxInputField';
import Toggle from '../Toggle';
import ResetExpressRehomeAssessmentForm from '../ResetExpressRehomeAssessmentForm';
import RehabilitationConclusionForm from '../ResetExpressRehabilitationConclusionForm';
import AcknowledgedResetExpressChangeStatus from '../AcknowledgedResetExpressChangeStatus';

const resetForms = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Initial Assessment'
	},
	{
		id: 2,
		value: 'Weekly Assessment'
	}
];

const resetExpressForms = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Initial Assessment'
	},
	{
		id: 2,
		value: 'Ready for Rehoming Assessment'
	},
	{
		id: 3,
		value: 'Rehabilitation Conclusion Report'
	},
	{
		id: 4,
		value: 'In Retraining'
	},
	{
		id: 5,
		value: 'Spelling'
	},
	{
		id: 6,
		value: 'Rehabilitation'
	}
];

const retirementForms = [
	{
		id: 0,
		value: null
	},
	{
		id: 1,
		value: 'Initial Assessment'
	},
	{
		id: 2,
		value: 'Quarterly Assessment'
	}
];

const HorseManagementDataRow = ({
	horseData,
	index,
	isLastRow,
	isMobile,
	reload,
	program,
	memberData,
	memberExternalId,
	updateCheckBox,
	onChangeDropDown,
	statusList,
	onChangeToggle
}) => {
	const classes = useStyles();
	const [selectedForm, setSelectedForm] = useState(0);
	const horseName = getHorseDisplayName(
		horseData.horseName,
		horseData?.horse?.ottInformation?.paddockName
	);

	const desktopRow =
		(program === RESET && (
			<>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={horseData?.horse?.ottInformation?.profilePhoto?.key}
						idPrefix={`${horseData?.horseCode}_my_stable_reset`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
						className={classes.profilePic}
					/>
				</td>
				<td
					id={HorseManagementKeys.HORSE_NAME}
					className={classes.tbodyName}
					onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
				>
					{horseName}
				</td>
				<td id={HorseManagementKeys.CURRENT_ADDRESS} className={classes.td}>
					{horseData?.location?.address &&
						`${horseData.location.address} 
					${horseData.location.suburb || horseData.location.postcode ? ',' : ''} 
					${horseData.location.suburb ?? ''} ${horseData.location.postcode ?? ''}`}
				</td>
				<td className={classes.td} style={{ paddingRight: '25px' }}>
					<FormDropDown
						items={resetForms}
						selectedId={selectedForm}
						onChange={(e) => {
							setSelectedForm(e.target.value);
						}}
						showBlank
						id={HorseManagementKeys.FORM}
					/>
				</td>
				<td
					id={HorseManagementKeys.LAST_REPORT_SUBMITTED}
					className={classes.td}
				>
					{horseData.lastSubmittedForm
						? `${formatDateReadableVariant(
								horseData.lastSubmittedForm.submittedDate
						  )}${
								horseData.lastSubmittedForm.weekId
									? ` - Week ${horseData.lastSubmittedForm.weekId}`
									: ''
						  }`
						: 'Never'}
				</td>
			</>
		)) ||
		(program === ACKNOWLEDGED_RETIREMENT && (
			<>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={horseData?.horse?.ottInformation?.profilePhoto?.key}
						idPrefix={`${horseData?.horseCode}_my_stable_retirement`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
						className={classes.profilePic}
					/>
				</td>
				<td
					id={HorseManagementKeys.HORSE_NAME}
					className={classes.tbodyName}
					onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
				>
					{horseName}
				</td>
				<td id={HorseManagementKeys.DATE_STABLED} className={classes.td}>
					{horseData?.location?.stabledDate
						? formatDateReadableVariant(horseData?.location?.stabledDate)
						: ''}
				</td>
				<td id={HorseManagementKeys.STATUS} className={classes.td}>
					{horseData?.currentStatus?.value}
				</td>
				<td id={HorseManagementKeys.AS_OF_DATE} className={classes.td}>
					{horseData?.effectiveDate
						? formatDateReadableVariant(horseData?.effectiveDate)
						: ''}
				</td>
				<td className={classes.td} style={{ paddingRight: '25px' }}>
					<FormDropDown
						items={retirementForms}
						selectedId={selectedForm}
						onChange={(e) => {
							setSelectedForm(e.target.value);
						}}
						showBlank
						id={HorseManagementKeys.FORM}
					/>
				</td>
				<td
					id={HorseManagementKeys.LAST_REPORT_SUBMITTED}
					className={classes.td}
				>
					{horseData.lastSubmittedForm
						? `${formatDateReadableVariant(
								horseData.lastSubmittedForm.submittedDate
						  )}`
						: 'Never'}
				</td>
			</>
		)) ||
		(program === FOSTER && (
			<>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={horseData?.horse?.ottInformation?.profilePhoto?.key}
						idPrefix={`${horseData?.horseCode}_my_stable_retirement`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
						className={classes.profilePic}
					/>
				</td>
				<td
					id={HorseManagementKeys.HORSE_NAME}
					className={classes.tbodyName}
					onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
				>
					{horseName}
				</td>
				<td id={HorseManagementKeys.STATUS} className={classes.td}>
					{horseData?.currentStatus?.value}
				</td>
				<td id={HorseManagementKeys.AS_OF_DATE} className={classes.td}>
					{horseData?.effectiveDate
						? formatDateReadableVariant(horseData?.effectiveDate)
						: ''}
				</td>
				<td className={classes.td} style={{ paddingRight: '25px' }}>
					<FormDropDown
						items={resetForms}
						selectedId={selectedForm}
						onChange={(e) => {
							setSelectedForm(e.target.value);
						}}
						showBlank
						id={HorseManagementKeys.FORM}
					/>
				</td>
				<td
					id={HorseManagementKeys.LAST_REPORT_SUBMITTED}
					className={classes.td}
				>
					{horseData.lastSubmittedForm
						? `${formatDateReadableVariant(
								horseData.lastSubmittedForm.submittedDate
						  )}${
								horseData.lastSubmittedForm.weekId
									? ` - Week ${horseData.lastSubmittedForm.weekId}`
									: ''
						  }`
						: 'Never'}
				</td>
			</>
		)) ||
		(program === RETRAINER && (
			<>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={horseData?.ottInformation?.profilePhoto?.key}
						idPrefix={`${horseData?.id}_my_stable_retrainer`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() => navigate(`/horse-profile/${horseData?.id}`)}
						className={classes.profilePic}
					/>
				</td>
				<td
					id={HorseManagementKeys.HORSE_NAME}
					className={classes.tbodyName}
					onClick={() => navigate(`horse-profile/${horseData.id}`)}
				>
					{horseData?.ottInformation?.paddockName ?? horseData.horseName}
				</td>
				<td
					id={HorseManagementKeys.FUNDED_BY}
					className={classes.td}
					style={{ paddingRight: '25px' }}
				>
					{horseData.isSelected ? (
						<Toggle
							id={HorseManagementKeys.FUNDED_BY_TOGGLE}
							labelNegative="Self"
							labelPositive="Client"
							onClick={(e) => onChangeToggle(e, horseData.id)}
							value={horseData?.fundingType === 'Self' ? false : true}
						/>
					) : (
						horseData?.fundingType
					)}
				</td>
				<td
					id={HorseManagementKeys.RETRAINING_STATUS}
					className={classes.td}
					style={{ paddingRight: '25px' }}
				>
					{horseData.isSelected ? (
						<FormDropDown
							id={HorseManagementKeys.RETRAINING_STATUS_SELECT}
							items={statusList}
							selectedId={horseData.retrainerStatus}
							onChange={(e) => {
								onChangeDropDown(e.target.value, horseData.id);
							}}
						/>
					) : (
						horseData?.retrainStatus
					)}
				</td>
				<td id={HorseManagementKeys.AS_OF_DATE} className={classes.td}>
					{horseData?.asOfDate
						? formatDateReadableVariant(horseData?.asOfDate)
						: ''}
				</td>
				<td className={classes.td}>
					<CheckboxInputField
						isChecked={horseData.isSelected}
						onChange={() => updateCheckBox(horseData.id, horseData.isSelected)}
						idPrefix={`${horseData.id}_change_status`}
					/>
				</td>
			</>
		)) ||
		(program === RESET_EXPRESS && (
			<>
				<td className={classes.tbodyProfilePic}>
					<HorseAvatar
						avatarImageKey={horseData?.horse?.ottInformation?.profilePhoto?.key}
						idPrefix={`${horseData?.horseCode}_my_stable_retirement`}
						style={{ marginRight: 10, width: 45, height: 45 }}
						onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
						className={classes.profilePic}
					/>
				</td>
				<td
					id={HorseManagementKeys.HORSE_NAME}
					className={classes.tbodyName}
					onClick={() => navigate(`/horse-profile/${horseData?.horseCode}`)}
				>
					{horseName}
				</td>
				<td id={HorseManagementKeys.CURRENT_ADDRESS} className={classes.td}>
					{horseData?.location?.address &&
						`${horseData.location.address} 
					${horseData.location.suburb || horseData.location.postcode ? ',' : ''} 
					${horseData.location.suburb ?? ''} ${horseData.location.postcode ?? ''}`}
				</td>
				<td className={classes.td} style={{ paddingRight: '25px' }}>
					<FormDropDown
						items={resetExpressForms}
						selectedId={selectedForm}
						onChange={(e) => {
							setSelectedForm(e.target.value);
						}}
						showBlank
						id={HorseManagementKeys.FORM}
					/>
				</td>
				<td
					id={HorseManagementKeys.LAST_REPORT_SUBMITTED}
					className={classes.td}
				>
					{horseData.lastSubmittedForm
						? `${formatDateReadableVariant(
								horseData.lastSubmittedForm.submittedDate
						  )}${
								horseData.lastSubmittedForm.weekId
									? ` - Week ${horseData.lastSubmittedForm.weekId}`
									: ''
						  }`
						: 'Never'}
				</td>
			</>
		));

	const mobileRow =
		(program === RESET && (
			<Container className={classes.mobileContainer}>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<span
								className={classes.tbodyName}
								onClick={() => navigate(`horse-profile/${horseData.horseCode}`)}
							>
								{horseName}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Last Report Submitted</div>
							{horseData.lastSubmittedForm
								? `${formatDateReadableVariant(
										horseData.lastSubmittedForm.submittedDate
								  )}${
										horseData.lastSubmittedForm.weekId
											? ` - Week ${horseData.lastSubmittedForm.weekId}`
											: ''
								  }`
								: 'Never'}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Form Select</div>
							<FormDropDown
								items={resetForms}
								selectedId={selectedForm}
								onChange={(e) => {
									setSelectedForm(e.target.value);
								}}
								showBlank
								id={HorseManagementKeys.FORM}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		)) ||
		(program === ACKNOWLEDGED_RETIREMENT && (
			<Container className={classes.mobileContainer}>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<span
								className={classes.tbodyName}
								onClick={() => navigate(`horse-profile/${horseData.horseCode}`)}
							>
								{horseName}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Stabled Date</div>
							{horseData?.location?.stabledDate
								? formatDateReadableVariant(horseData?.location?.stabledDate)
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Status</div>
							{horseData?.currentStatus?.value
								? horseData?.currentStatus?.value
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>As of Date</div>
							{horseData?.effectiveDate
								? formatDateReadableVariant(horseData?.effectiveDate)
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Form Select</div>
							<FormDropDown
								items={retirementForms}
								selectedId={selectedForm}
								onChange={(e) => {
									setSelectedForm(e.target.value);
								}}
								showBlank
								id={HorseManagementKeys.FORM}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Last Report Submitted</div>
							{horseData.lastSubmittedForm
								? `${formatDateReadableVariant(
										horseData.lastSubmittedForm.submittedDate
								  )}`
								: 'Never'}
						</div>
					</Col>
				</Row>
			</Container>
		)) ||
		(program === FOSTER && (
			<Container className={classes.mobileContainer}>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<span
								className={classes.tbodyName}
								onClick={() => navigate(`horse-profile/${horseData.horseCode}`)}
							>
								{horseName}
							</span>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Stabled Date</div>
							{horseData?.location?.stabledDate
								? formatDateReadableVariant(horseData?.location?.stabledDate)
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Status</div>
							{horseData?.currentStatus?.value
								? horseData?.currentStatus?.value
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>As of Date</div>
							{horseData?.effectiveDate
								? formatDateReadableVariant(horseData?.effectiveDate)
								: ''}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Form Select</div>
							<FormDropDown
								items={resetForms}
								selectedId={selectedForm}
								onChange={(e) => {
									setSelectedForm(e.target.value);
								}}
								showBlank
								id={HorseManagementKeys.FORM}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Last Report Submitted</div>
							{horseData.lastSubmittedForm
								? `${formatDateReadableVariant(
										horseData.lastSubmittedForm.submittedDate
								  )}${
										horseData.lastSubmittedForm.weekId
											? ` - Week ${horseData.lastSubmittedForm.weekId}`
											: ''
								  }`
								: 'Never'}
						</div>
					</Col>
				</Row>
			</Container>
		));
	//TODO: Refactor code to accomodate Acknowledged Retirement Program Forms when working on relevant story
	return (
		<React.Fragment>
			<tr
				id={program === RETRAINER ? horseData?.id : horseData?.horseCode}
				className={isLastRow ? classes.trbodyLast : classes.trbody}
			>
				{isMobile ? mobileRow : desktopRow}
			</tr>
			{program === RESET && selectedForm != 0 && (
				<tr>
					<td colSpan={isMobile ? 3 : 10}>
						{Number(selectedForm) === 1 && (
							<RESETInitialAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								reload={reload}
							/>
						)}
						{Number(selectedForm) === 2 && (
							<RESETWeeklyProgressForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								//For Sale and RESET status
								index={index}
								reload={reload}
								isCurrentForSaleStatus={horseData.currentStatus?.id === 16}
							/>
						)}
					</td>
				</tr>
			)}
			{program === ACKNOWLEDGED_RETIREMENT && selectedForm != 0 && (
				<tr>
					<td colSpan={isMobile ? 3 : 10}>
						{Number(selectedForm) === 1 && (
							<AcknowledgedRetirementInitialAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								reload={reload}
							/>
						)}
						{Number(selectedForm) === 2 && (
							<AcknowledgedRetirementQuarterlyAssessmentForm
								horseCode={horseData.horseCode}
								memberData={memberData}
								horseData={horseData}
								memberExternalId={memberExternalId}
								reload={reload}
							/>
						)}
					</td>
				</tr>
			)}
			{program === FOSTER && selectedForm != 0 && (
				<tr>
					<td colSpan={isMobile ? 3 : 10}>
						{Number(selectedForm) === 1 && (
							<AcknowledgedFosterInitialAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								index={index}
								reload={reload}
							/>
						)}
						{Number(selectedForm) === 2 && (
							<AcknowledgedFosterWeeklyAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								index={index}
								reload={reload}
							/>
						)}
					</td>
				</tr>
			)}
			{program === RESET_EXPRESS && selectedForm != 0 && (
				<tr>
					<td colSpan={isMobile ? 3 : 10}>
						{Number(selectedForm) === 1 && (
							<AcknowledgedResetExpressInitialAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								index={index}
								reload={reload}
							/>
						)}
						{Number(selectedForm) === 2 && (
							<ResetExpressRehomeAssessmentForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								index={index}
								reload={reload}
							/>
						)}
						{Number(selectedForm) === 3 && (
							<RehabilitationConclusionForm
								horseCode={horseData.horseCode}
								horseData={horseData}
								index={index}
							/>
						)}
						{(Number(selectedForm) === 4 ||
							Number(selectedForm) === 5 ||
							Number(selectedForm) === 6) && (
							<AcknowledgedResetExpressChangeStatus
								horseCode={horseData.horseCode}
								selectedStatus={Number(selectedForm)}
								setSelectedForm={setSelectedForm}
							/>
						)}
					</td>
				</tr>
			)}
		</React.Fragment>
	);
};

export default HorseManagementDataRow;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	visibilityHelperContentContainer: {
		backgroundColor: '#dcebf3',
		padding: 20,
		borderRadius: 5,
		fontSize: 15,
		color: '#464749',
		width: '100%',
		boxSizing: 'border-box',
		marginBottom: 15
	},
	visibilityHelperText: {
		marginTop: 0,
		marginBottom: 5,
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	}
});

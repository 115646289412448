import React, { useEffect, useState } from 'react';
import FormTextField from '../FormTextField';
import List from '../List';
import { getHorses, getHorsesByName } from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';

const HorseAutoSuggestion = ({
	value,
	onChange,
	onHorseSelect,
	formTextFieldStyle,
	disableAutoSuggestion = false,
	canEdit = true,
	id = null,
	...rest
}) => {
	const [activeSuggestion, setActiveSuggestion] = useState(0);
	const [userInput, setUserInput] = useState(null);
	const [showSuggestions, setShowSuggestions] = useState(false);

	const [timer, setTimer] = useState(null);
	const [horseSuggestions, setHorseSuggestions] = useState([]);

	useEffect(() => {
		setUserInput(value);
	}, [value]);

	useEffect(() => {
		if (disableAutoSuggestion == false) {
			clearTimeout(timer);
			if (userInput != '' && showSuggestions == true) {
				setTimer(
					setTimeout(function () {
						return AppSyncService.execute(getHorsesByName, { horseName: value })
							.then((result) => {
								if (
									result.data.getHorsesByName &&
									result.data.getHorsesByName.length > 0
								) {
									setHorseSuggestions(
										result.data.getHorsesByName.map((horse) => ({
											id: horse.horseCode,
											value: horse.name
										}))
									);
								} else {
									setHorseSuggestions([]);
								}
							})
							.catch(() => {
								setHorseSuggestions([]);
							});
					}, 1000)
				);
			}
		}
	}, [userInput]);

	const getHorseDetails = (id) => {
		if (id == null) {
			onHorseSelect({
				id: null,
				name: '',
				age: null,
				microChipNumber: null
			});
			return;
		}
		return AppSyncService.execute(getHorses, { horseCode: id })
			.then((result) => {
				if (result.data.getHorse) {
					onHorseSelect({
						id: result.data.getHorse.horseCode,
						name: result.data.getHorse.name,
						age: result.data.getHorse.age,
						microChipNumber: result.data.getHorse.microChipNumber
					});
				}
				return;
			})
			.catch(() => {
				return;
			});
	};

	const onKeyDown = (e) => {
		// User pressed the enter key
		if (e.keyCode === 13) {
			setUserInput(horseSuggestions[activeSuggestion]);
			setActiveSuggestion(0);
			setShowSuggestions(0);
		}
		// User pressed the up arrow
		else if (e.keyCode === 38) {
			if (activeSuggestion === 0) {
				return;
			}

			setActiveSuggestion(activeSuggestion - 1);
		}
		// User pressed the down arrow
		else if (e.keyCode === 40) {
			if (activeSuggestion - 1 === horseSuggestions.length) {
				return;
			}

			setActiveSuggestion(activeSuggestion + 1);
		}
	};

	const onClick = (item) => {
		const value = item.key == 'no-value' ? null : item.value;
		setActiveSuggestion(0);
		setHorseSuggestions([]);
		setShowSuggestions(false);
		onChange(value);
		setUserInput(value);
		getHorseDetails(item.id);
	};

	const onChangeInternal = (value) => {
		setActiveSuggestion(0);
		setShowSuggestions(true);
		setUserInput(value);
		if (onChange) {
			onChange(value);
		}
	};

	const renderSuggestions = () => {
		if (showSuggestions && userInput) {
			if (horseSuggestions && horseSuggestions.length) {
				return (
					<List
						listItems={horseSuggestions}
						itemRender={(item) => item.value}
						onItemClick={onClick}
						isInTable={true}
					/>
				);
			} else {
				return (
					<List
						listItems={[{ key: 'no-value', value: 'No matches' }]}
						itemRender={(item) => item.value}
						onItemClick={onClick}
						isInTable={true}
					/>
				);
			}
		}
	};

	return (
		<div {...rest}>
			<FormTextField
				styles={formTextFieldStyle}
				value={value}
				onKeyDown={onKeyDown}
				onChange={(e) => {
					onChangeInternal(e.target.value);
				}}
				canEdit={canEdit}
				{...(id && { id })}
			/>
			{disableAutoSuggestion == false && canEdit == true && renderSuggestions()}
		</div>
	);
};

export default HorseAutoSuggestion;

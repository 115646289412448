export const parseTableData = (rawString) => {
	const parsed = JSON.parse(rawString);
	let tableData = [];
	parsed.content.forEach((item) => {
		if (item.content && item.content.length > 0) {
			item.content.forEach((childItem, index) => {
				const row = [];
				if (childItem.content && childItem.content.length > 0) {
					childItem.content.forEach((childItem2) => {
						if (childItem2) row.push(childItem2);
					});
				}
				if (row && row.length > 0)
					tableData.push({ rowNumber: index, value: row });
			});
		}
	});

	return tableData.map((item) => {
		return item.value.map((child) => {
			return {
				value: child.content[0].content[0].value,
				rowNumber: item.rowNumber
			};
		});
	});
};

import React from 'react';
import { CommonKeys } from '../../utils/constants';
import useStyles from './styles';

const SearchField = ({
	title = null,
	onInputChange,
	onSearchClick,
	inputValue,
	buttonStyles,
	keyCheck,
	validation = null
}) => {
	const classes = useStyles();
	return (
		<>
			{title && <div className={classes.searchContainerHeader}>{title}</div>}
			<div className={classes.searchFieldContainer}>
				<div style={{ flexGrow: 8 }}>
					<input
						id={CommonKeys.SEARCH_BY_NAME}
						type="text"
						value={inputValue}
						className={classes.searchField}
						onChange={(e) => onInputChange(e.target.value)}
						onKeyDown={(e) => keyCheck(e.keyCode)}
					/>
				</div>
				<div style={{ flexGrow: 1 }}>
					<input
						id={CommonKeys.SEARCH}
						type="button"
						value="Search"
						className={classes.searchButton}
						style={buttonStyles}
						onClick={() => onSearchClick()}
					/>
				</div>
				<div className={classes.break}></div>
				{validation?.invalid && (
					<div style={{ flexGrow: 12 }}>
						<p className={classes.errorText}>{validation.message}</p>
					</div>
				)}
			</div>
		</>
	);
};

export default SearchField;

import React from 'react';
import useStyles from './styles';
import DropdownDown from '../../../assets/icons/Dropdown_Down_icon.svg';
import DropdownUp from '../../../assets/icons/Dropdown_Up_icon.svg';

const AccordionToggle = ({ isOpen, dropDownStyle, onToggleClick, mobile }) => {
	const classes = useStyles();

	return (
		<div
			className={mobile ? classes.dropDownMobile : classes.dropDown}
			style={dropDownStyle}
			onClick={() => {
				onToggleClick();
			}}
		>
			{!isOpen && (
				<img
					onClick={() => {
						onToggleClick();
					}}
					src={DropdownDown}
				/>
			)}{' '}
			{isOpen && (
				<img
					onClick={() => {
						onToggleClick();
					}}
					src={DropdownUp}
				/>
			)}
		</div>
	);
};

export default AccordionToggle;

import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { readFile } from '../HorseProfileEdit/helpers';
import {
	saveBusinessDetails,
	getBusinessProfileTypeReferenceData,
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	uploadBusinessProfileImage,
	removeBusinessProfileImage
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import HeaderBanner from '../HeaderBanner';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import ImageCropModal from '../ImageCropModal';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import {
	ROLE_ACKNOWLEDGED_RETIREMENT_FARM,
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	REVIEW,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ProfileKeys
} from '../../utils/constants';
import BusinessProfileDetailsFoster from '../BusinessProfileDetailsFoster';

// form validation rules
const schema = yup.object().shape({
	businessName: yup
		.string() //Business name can have special characters
		.required('Business Name is required')
		.typeError('Business Name is required')
		.max(55, 'Business name must have maximum of 55 characters'),
	businessLogo: yup
		.string()
		.required('Business Logo / Profile Photo is required.')
		.oneOf(['true'], 'Business Logo / Profile Photo is required.')
});

const BusinessProfileDetails = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			updateIsLoading,
			setIsSubmitted,
			onChangeBusinessType,
			onSaveBusinessProfileData,
			businessProfileType,
			setHasValidProfileData,
			businessProfilesData,
			setBusinessProfilesData,
			validateFields,
			businessDetailsFosterRef
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();
		const { handleSubmit, errors, control, setValue, trigger } = useForm({
			mode: 'onSubmit',
			resolver: yupResolver(schema)
		});

		const [businessName, setBusinessName] = useState('');
		const [
			selectedBusinessProfileType,
			setSelectedBusinessProfileType
		] = useState(1);
		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [
			referenceBusinessProfileTypes,
			setReferenceBusinessProfileTypes
		] = useState([]);
		const [businessLogoImages, setBusinessLogoImages] = useState([]);
		const [imageBeingCropped, setImageBeingCropped] = useState(null);
		const [formStatus, setFormStatus] = useState(null);
		const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
			false
		);
		const [imgSrcUrl, setImgSrcUrl] = useState(null);
		const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

		useEffect(() => {
			AppSyncService.execute(getBusinessProfileTypeReferenceData).then(
				(result) => {
					if (result.data) {
						const ackRetirementFarmRoleExist = currentUser?.Roles.some(
							(role) => role === ROLE_ACKNOWLEDGED_RETIREMENT_FARM
						);
						const ackRetrainerRoleExist = currentUser?.Roles.some(
							(role) => role === ROLE_RV_ACKNOWLEDGED_RETRAINER
						);
						const ackFosterCarerRoleExist = currentUser?.Roles.some(
							(role) => role === ROLE_ACKNOWLEDGED_FOSTER_CARER
						);

						let filteredBusinessProfileType =
							result.data.listBusinessProfileType;

						if (!ackFosterCarerRoleExist) {
							filteredBusinessProfileType = filteredBusinessProfileType.filter(
								(businessType) => businessType.id !== 3
							);
						}

						if (!ackRetirementFarmRoleExist) {
							filteredBusinessProfileType = filteredBusinessProfileType.filter(
								(businessType) => businessType.id !== 2
							);
						}
						if (!ackRetrainerRoleExist) {
							filteredBusinessProfileType = filteredBusinessProfileType.filter(
								(businessType) => businessType.id !== 1
							);
						}
						if (
							ackFosterCarerRoleExist &&
							ackRetirementFarmRoleExist &&
							!ackRetrainerRoleExist
						) {
							setSelectedBusinessProfileType(3);
						}

						if (filteredBusinessProfileType.length === 1) {
							setSelectedBusinessProfileType(
								filteredBusinessProfileType.find(
									(businessProfile) => businessProfile
								)?.id
							);
						}
						setReferenceBusinessProfileTypes(filteredBusinessProfileType);
					}
				}
			);
		}, []);

		useEffect(() => {
			setBusinessName(
				businessProfileData ? businessProfileData.businessName : ''
			);
			setValue(
				'businessName',
				businessProfileData ? businessProfileData.businessName : ''
			);
			setSelectedBusinessProfileType(
				businessProfileData?.businessProfileType?.id
					? businessProfileData?.businessProfileType?.id
					: businessProfileType
			);

			setValue(
				'businessProfileType',
				businessProfileType ? businessProfileType : ''
			);
			const filteredLogoPhotos = businessProfileData?.logo
				? businessProfileData.logo.filter(
						(logo) => logo.businessProfileId == businessProfileData.id
				  )
				: [];
			setBusinessLogoImages(filteredLogoPhotos);
			setFormStatus(businessProfileData ? businessProfileData.status : null);
			updateIsLoading(false);
			setIsFormDisabled(true);
			setIsFormDisabled(true);
		}, [businessProfileData]);

		useEffect(() => {
			setValue(
				'businessLogo',
				businessLogoImages != null && businessLogoImages.length > 0
					? 'true'
					: 'false'
			);

			setHasValidProfileData(businessLogoImages.length !== 0);
		}, [businessLogoImages]);

		useImperativeHandle(ref, () => ({
			async triggerValidation() {
				await trigger();
			}
		}));

		const onSubmit = async () => {
			validateFields();
			setBackendErrorMessage(null);
			try {
				if (businessName.trim().length == 0) {
					throw 'Business name is required';
				}
				const state = {
					businessName: businessName.trim(),
					status: 'Draft'
				};
				AppSyncService.execute(saveBusinessDetails, {
					businessName: businessName.trim(),
					businessProfileTypeId:
						selectedBusinessProfileType === '' ||
						selectedBusinessProfileType === null
							? 0
							: selectedBusinessProfileType,
					memberExternalId: currentUser?.UserId
				}).then((data) => {
					if (data.data != null) {
						addToast({ Message: 'Saved', IsSuccess: true });
						setIsSubmitted(false);
						onSaveBusinessProfileData(state);
					}
				});
				setIsFormDisabled(true);
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving user Business profile data. Please contact support'
					);
				}
			}
		};

		const updateBusinessProfileType = (value) => {
			setSelectedBusinessProfileType(value);
			setIsFormDisabled(false);
		};

		const cropImage = (image) => {
			readFile(image).then((result) =>
				setImageBeingCropped({ imageFile: image, src: result })
			);
		};

		const businessLogoImagesUpload = (image) => {
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: imageBeingCropped.imageFile.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					const imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							AppSyncService.execute(uploadBusinessProfileImage, {
								filename: imageBeingCropped.imageFile.name,
								key: imageKey,
								memberExternalId: currentUser?.UserId,
								type: 'logo',
								businessProfileTypeId: businessProfileType
							}).then((data) => {
								const {
									data: { saveBusinessProfileImage }
								} = data;
								if (saveBusinessProfileImage?.id) {
									validateFields();
									let dataList = Object.assign([], businessLogoImages);
									dataList.push({
										filename: imageBeingCropped.imageFile.name,
										key: imageKey,
										businessProfileId: !businessProfileData?.id
											? saveBusinessProfileImage?.id
											: businessProfileData.id,
										type: 'logo'
									});
									setBusinessLogoImages(dataList);
									setImageBeingCropped(null);
									addToast({ Message: 'Logo uploaded', IsSuccess: true });
									setIsSubmitted(false);
									const currentState = {
										logo: dataList,
										status: 'Draft',
										businessProfileId: !businessProfileData?.id
											? saveBusinessProfileImage?.id
											: businessProfileData.id
									};
									onSaveBusinessProfileData(currentState);
								}
							});
						});
				}
			});
		};

		const uploadError = (isMinimumUploadSize) => {
			setIsMinimumUploadSize(isMinimumUploadSize);
			setShowImageUploadErrorModal(true);
		};

		const getImage = (img) => {
			const imageKey = { imageKey: img.key };
			AppSyncService.execute(getUserImage, imageKey).then((data) => {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			});
		};

		const removeBusinessLogoImages = (img) => {
			AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
				(data) => {
					const selectedBusinessProfileId = businessProfilesData.find(
						(item) => item.businessProfileType?.id === businessProfileType
					)?.id;
					const filteredBusinessProfiles = businessProfilesData.filter(
						(item) => item.id !== selectedBusinessProfileId
					);
					setBusinessProfilesData(filteredBusinessProfiles);
					if (data?.data?.deleteUserImage?.success) {
						AppSyncService.execute(removeBusinessProfileImage, {
							filename: img.filename,
							type: 'logo',
							businessProfileId: selectedBusinessProfileId
						}).then((data) => {
							if (data.data.deleteBusinessProfileImage) {
								validateFields();
								let dataList = Object.assign([], businessLogoImages);
								let newDataList = dataList.filter((d) => d.key != img.key);
								setBusinessLogoImages(newDataList);
								if (businessProfileData?.logo) {
									businessProfileData.logo = businessProfileData.logo.filter(
										(photo) =>
											photo.businessProfileId !== businessProfileData.id
									);
								}

								addToast({ Message: 'Logo removed', IsSuccess: true });
							}
						});
					}
				}
			);
		};

		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}

				<div className={classes.personalProfile}>
					<Row>
						<Col lg={12} className={classes.sectionContainer}>
							<div className={classes.ProfilefieldContainer}>
								<div className={classes.fieldTitle}>I am a</div>
								<FormDropDown
									id={ProfileKeys.PROFILE_TYPE}
									items={referenceBusinessProfileTypes}
									selectedId={selectedBusinessProfileType}
									onChange={(e) => {
										updateBusinessProfileType(e.target.value);
										onChangeBusinessType(e.target.value);
									}}
									styles={{ width: '100%' }}
								/>
							</div>
						</Col>
						<Col></Col>
						<Col></Col>
					</Row>
				</div>
				<HeaderBanner
					title="Business Details"
					inline
					styles={{ marginBottom: '0px', marginTop: '-5px' }}
				/>
				{businessProfileType !== 3 ? (
					<>
						<form
							className={classes.formContainer}
							onSubmit={handleSubmit(onSubmit, () => validateFields())}
						>
							<div className={classes.personalProfile}>
								<Row>
									<Col>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>Business Name*</div>
											<Controller
												control={control}
												name="businessName"
												render={({ onChange }) => (
													<FormTextField
														value={businessName}
														onChange={(e) => {
															onChange(e.target.value);
															setBusinessName(e.target.value);
															setIsFormDisabled(false);
														}}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.businessName?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} sm={12}>
										<div className={classes.ProfilefieldContainer}>
											<div className={classes.fieldTitle}>
												Business Logo / Profile Photo*
											</div>
											<Controller
												control={control}
												name="businessLogo"
												render={() => (
													<UploadPhoto
														formats={['png', 'jpeg']}
														error={uploadError}
														data={businessLogoImages}
														sizeLimitMB={5}
														success={cropImage}
														status={formStatus}
														getImage={getImage}
														removeImage={removeBusinessLogoImages}
														labelName="Upload Logo (jpg,png)"
														numberOfImages={1}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.businessLogo?.message}
											</span>
										</div>
									</Col>
									<Col className={classes.infoTextBusinessLogoContainer}>
										{formStatus === REVIEW && (
											<span className={classes.infoTextBusinessLogo}>
												Your profile is currently under review by the EW team.
												Please wait until your profile has been approved to
												update your Business logo / Profile Photo.
											</span>
										)}
									</Col>
								</Row>
							</div>
							<ImageCropModal
								imgSrc={imageBeingCropped?.src}
								isOpen={!!imageBeingCropped}
								closeModal={() => setImageBeingCropped(null)}
								onSaveCrop={businessLogoImagesUpload}
							/>
							<UploadPhotoErrorModal
								showErrorModal={showImageUploadErrorModal}
								closeModal={() => setShowImageUploadErrorModal(false)}
								isMinimumUploadSize={isMinimumUploadSize}
							/>
							<div className={classes.buttonContainer}>
								<div className={classes.errorContainer}>
									{backendErrorMessage && (
										<span className={classes.errorMessage}>
											{backendErrorMessage}
										</span>
									)}
								</div>
								<div className={classes.saveRequest}>
									<span className={classes.lastSaved}></span>
									<span>
										<PrimaryButton
											style={{ width: '165px' }}
											disabled={isFormDisabled}
										>
											{isFormDisabled ? 'Saved' : 'Save Changes'}
										</PrimaryButton>
									</span>
								</div>
							</div>
							<div className={classes.clear}></div>
						</form>
					</>
				) : (
					<BusinessProfileDetailsFoster
						currentUser={currentUser}
						businessProfileData={businessProfileData}
						isLoading={isLoading}
						updateIsLoading={updateIsLoading}
						setIsSubmitted={setIsSubmitted}
						onChangeBusinessType={onChangeBusinessType}
						businessProfileType={selectedBusinessProfileType}
						onSaveBusinessProfileData={onSaveBusinessProfileData}
						setHasValidProfileData={setHasValidProfileData}
						businessProfilesData={businessProfilesData}
						setBusinessProfilesData={setBusinessProfilesData}
						ref={businessDetailsFosterRef}
						validateFields={validateFields}
					/>
				)}

				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</>
		);
	}
);

export default BusinessProfileDetails;

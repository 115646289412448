import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	fieldTitle: {
		fontWeight: 600,
		fontSize: 13,
		marginBottom: 7,
		color: '#464749',
		'@media (min-width:992px)': {
			fontSize: 15
		}
	},
	profileInfo: {
		fontSize: 15,
		display: 'block',
		marginTop: 17,
		wordBreak: 'break-word'
	},
	ProfilefieldContainer: {
		paddingTop: 10,
		paddingBottom: 10
	}
}));

import React from 'react';
import useStyles from './styles.js';
import { Row, Col } from 'react-grid-system';

function SimpleRadioGroup({ groupName, items, onChange, col = 3 }) {
	const classes = useStyles();
	const selectionItems = items.map((item) => {
		return (
			<Col lg={col} key={item.id}>
				<div className={classes.radioContainer}>
					<div className={classes.radioLink} onClick={() => onChange}>
						<label className={classes.container}>
							<Row>
								<Col>
									<input
										type="radio"
										id={item.id}
										name={groupName}
										value={item.value}
										onChange={onChange}
										aria-checked={item.isSelected}
										className={classes.inputClass}
									/>
									<div className={classes.checkmark}></div>
								</Col>
								<Col className={classes.radioTextLabel}>{item.label}</Col>
							</Row>
						</label>
					</div>
				</div>
			</Col>
		);
	});

	return (
		<div>
			<Row>{selectionItems}</Row>
		</div>
	);
}

export default SimpleRadioGroup;

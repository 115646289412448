import leftColumnImage from '../../assets/images/Team_walking_out_from_behind.jpg';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
	banner: {
		borderRadius: 5,
		maxWidth: '100%',
		minWidth: '100%'
	},
	container: {
		width: 1000,
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	topContainer: {
		maxWidth: '100%',
		background: 'white'
	},
	infoBoxContent: {
		borderRadius: 5,
		marginTop: 20,
		fontSize: 20
	},
	infoBoxTitle: {
		backgroundColor: '#464749',
		borderRadius: '5px 5px 0px 0px',
		color: 'white',
		height: '100%',
		padding: 10,
		fontWeight: 600
	},
	infoBoxValue: {
		backgroundColor: '#ededed',
		borderRadius: '0px 0px 5px 5px',
		color: '#4c4d4f',
		padding: 10,
		height: '100%'
	},
	formContainer: {
		border: '1px solid #b3bcc1',
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px',
		padding: '10px 18px'
	},
	formSectionContent: {
		marginTop: 30
	},
	formRowFirst: {
		padding: '0px 30px'
	},
	formRow: {
		padding: '0px 30px',
		marginTop: 15
	},
	errorText: {
		paddingLeft: 15
	},
	checkboxContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	competitionImageDisplay: {
		backgroundImage: `url('${leftColumnImage}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: '100%',
		width: '100%',
		minHeight: 500
	},
	notActiveText: {
		paddingTop: 80
	},
	competitionImageDisplayMobile: {
		extend: 'competitionImageDisplay',
		minHeight: 300
	},
	textWrapper: {
		display: 'flex',
		justifyContent: 'center'
	},
	link: {
		color: '#0085ca'
	},
	disableReasonBar: {
		padding: 20,
		marginTop: -20,
		marginBottom: -20
	}
});

import * as Yup from 'yup';

const schema = Yup.object().shape({
	adminExternalId: Yup.string()
		.required('Actioning is required')
		.typeError('Actioning is required')
		.test('empty-check', 'Actioning is required', (val) => val !== '0'),
	adminStatus: Yup.number().nullable(),
	formComplete: Yup.boolean().required().default(false),
	ewdRecommendation: Yup.boolean().required().default(true),
	ewdRecommendationDate: Yup.string().when('ewdRecommendation', {
		is: (ewdRecommendation) => ewdRecommendation,
		then: () =>
			Yup.string()
				.required('EWD Recommendation Date is required')
				.typeError('EWD Recommendation Date is required'),
		otherwise: () => Yup.string().nullable()
	}),
	ewdComments: Yup.string()
		.nullable()
		.max(500, 'EWD Comments cannot be more than 500 characters long'),
	eligibilityReason: Yup.number().when('ewdRecommendation', {
		is: (ewdRecommendation) => ewdRecommendation,
		then: () =>
			Yup.number()
				.required('Eligibility Reason is required')
				.typeError('Eligibility Reason is required')
				.moreThan(0, 'Eligibility Reason is required'),
		otherwise: () =>
			Yup.number()
				.nullable(true)
				.transform((_, val) => (val === Number(val) ? val : null))
	}),
	primaryConcern: Yup.number().nullable(),
	primaryConcernOther: Yup.string().when(
		['ewdRecommendation', 'primaryConcern'],
		{
			is: (ewdRecommendation, primaryConcern) =>
				ewdRecommendation && primaryConcern == 4,
			then: () =>
				Yup.string()
					.max(
						500,
						'Other Primary Concern cannot be more than 500 characters long'
					)
					.typeError('Other Primary Concern is required')
					.required('Other Primary Concern is required'),
			otherwise: () =>
				Yup.string()
					.max(
						500,
						'Other Primary Concern  cannot be more than 500 characters long'
					)
					.nullable()
		}
	),
	hasInjuryIdentified: Yup.boolean().default(true),
	dateInjuryReported: Yup.string().when(
		['hasInjuryIdentified', 'primaryConcern'],
		{
			is: (hasInjuryIdentified, primaryConcern) =>
				hasInjuryIdentified && primaryConcern == 3,
			then: () =>
				Yup.string()
					.typeError('Date Injury Reported on SNS is required')
					.required('Date Injury Reported on SNS is required'),
			otherwise: () => Yup.string().nullable()
		}
	),
	hasInjuryPreventedCareer: Yup.string().when(['primaryConcern'], {
		is: (primaryConcern) => primaryConcern == 3,
		then: () =>
			Yup.string().typeError('Field is required').required('Field is required'),
		otherwise: () => Yup.string().nullable()
	}),
	nominationCategory: Yup.number().nullable(),
	illness: Yup.number().nullable(),
	illnessOther: Yup.string().when(['ewdRecommendation', 'illness'], {
		is: (ewdRecommendation, illness) => ewdRecommendation && illness == 7,
		then: () =>
			Yup.string()
				.max(500, 'Other illnesses cannot be more than 500 characters long')
				.typeError('Other illnesses are required')
				.required('Other illnesses are required'),
		otherwise: () =>
			Yup.string()
				.max(500, 'Other illnesses cannot be more than 500 characters long')
				.nullable()
	}),
	injury: Yup.number().nullable(),
	injuryOther: Yup.string().when(['ewdRecommendation', 'injury'], {
		is: (ewdRecommendation, injury) => ewdRecommendation && injury == 12,
		then: () =>
			Yup.string()
				.max(500, 'Other injuries cannot be more than 500 characters long')
				.typeError('Other injuries are required')
				.required('Other injuries are required'),
		otherwise: () =>
			Yup.string()
				.max(500, 'Other injuries cannot be more than 500 characters long')
				.nullable()
	}),
	rehomingAttemptsSatisfactory: Yup.boolean().nullable(),
	rehomingAttemptsComments: Yup.string()
		.nullable()
		.max(
			500,
			'Rehoming attempts comments cannot be more than 500 characters long'
		),
	bodyParts: Yup.array(),
	bodyPartOther: Yup.string().when(['ewdRecommendation', 'bodyParts'], {
		is: (ewdRecommendation, bodyParts) =>
			ewdRecommendation && bodyParts.find(({ label }) => label === 'Other'),
		then: () =>
			Yup.string()
				.max(500, 'Other Parts of Body cannot be more than 500 characters long')
				.typeError('Other Parts of Body are required')
				.required('Other Parts of Body are required'),
		otherwise: () =>
			Yup.string()
				.max(500, 'Other Parts of Body cannot be more than 500 characters long')
				.nullable()
	}),
	ewdFinalComments: Yup.string()
		.nullable()
		.max(500, 'EWD Final comments cannot be more than 500 characters long'),
	vetCertOrOtherFiles: Yup.array(),
	xRayFiles: Yup.array(),
	brandImages: Yup.array().when(['ewdRecommendation'], {
		is: (ewdRecommendation) => ewdRecommendation,
		then: () =>
			Yup.array()
				.required('Brand Image is required')
				.min(1, 'Brand Image is required')
				.max(1, 'You cannot upload more than 1 Brand Image'),
		otherwise: () => Yup.array()
	}),
	ewgmApprovalToProceed: Yup.boolean().required(),
	ewgmApprovalToProceedDate: Yup.string().when('ewdRecommendation', {
		is: (ewdRecommendation) => ewdRecommendation,
		then: () =>
			Yup.string()
				.required('Approval to proceed, date is required')
				.typeError('Approval to proceed, date is required'),
		otherwise: () => Yup.string().nullable()
	}),
	ewgmComments: Yup.string()
		.nullable()
		.max(500, 'EWGM comments cannot be more than 500 characters long'),
	automaticallySendAcceptanceNoticeToOwner: Yup.boolean(),
	addEwgmSignatureOnAcceptanceNotice: Yup.boolean(),
	adminNotes: Yup.string()
		.nullable()
		.max(500, 'Admin Notes cannot be more than 500 characters long')
});

export default schema;

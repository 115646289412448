import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	formContainer: {
		paddingTop: 5,
		paddingBottom: 6,
		border: '1px solid #b3bcc1',
		maxHeight: '100%',
		borderTop: 'none',
		borderRadius: '0px 0px 5px 5px'
	},
	sectionContainer: {
		padding: '0px 18px 0px 18px'
	},
	fieldContainer: {
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	dividerLine: {
		background: '#edf5f9',
		height: 2,
		border: 'none',
		marginTop: 40
	},
	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	disabledCompleteFormButton: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	},
	errorMessage: {
		fontSize: 12,
		color: 'red',
		marginRight: 10
	},
	errorText: {
		color: '#db0000'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		marginTop: 10,
		cursor: 'pointer'
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	},
	uploadedFile: {
		fontStyle: 'italic',
		textDecoration: 'underline',
		fontSize: 13,
		color: '#0085ca',
		cursor: 'pointer'
	},
	clearUpload: {
		fontSize: 13,
		marginLeft: 4,
		color: '#afafaf',
		cursor: 'pointer'
	},
	charLengthInfo: {
		color: '#909195',
		float: 'right',
		fontSize: 12
	},
	mailLink: {
		textDecoration: 'none',
		color: '#006da6',
		fontWeight: 600
	}
}));

import React from 'react';
import { Col, Row } from 'react-grid-system';
import { formatDateReadableVariant } from '../../../utils/helpers';
import FormLabelField from '../../FormLabelField';
import useStyles from '../../ResetFormReviewInfo/styles';
import { mapArrayValues } from './helper';
import {
	ACK_RETIREMENT_SOURCE,
	CONCLUDED_OHEP_SOURCE,
	CONCLUDED_RESET_SOURCE
} from '../constants';
const DeceasedUpdateSection = ({
	data,
	references
	// retirementIllnesses,
	// generalIllnesses,
	// retirementInjuries,
	// generalInjuries
}) => {
	const illnessReference =
		data?.deceasedThoroughbredSource?.id === 5 ||
		data?.deceasedThoroughbredSource?.id === 6
			? references.listAckRetirementIllnessTypes
			: references.listShareInfoEuthanisedIllness;
	const injuryReference =
		data?.deceasedThoroughbredSource?.id === 5 ||
		data?.deceasedThoroughbredSource?.id === 3 ||
		data?.deceasedThoroughbredSource?.id === 6
			? references.listAckRetirementInjuryTypes
			: references.listShareInfoEuthanisedInjuries;
	const mappedIllnesses = mapArrayValues(
		data?.illnesses ?? [],
		illnessReference
	);
	const mappedInjuries = mapArrayValues(data?.injuries ?? [], injuryReference);
	const classes = useStyles();
	const causeOfDeath = data?.deceasedCauseOfDeath?.id;
	return (
		<>
			<div className={classes.sectionContainer}>
				<Row>
					<Col>
						<FormLabelField
							title="Source of Update"
							value={data?.deceasedThoroughbredSource?.value}
						/>
					</Col>
				</Row>
				{data?.deceasedCauseOfDeath?.value && (
					<Row>
						<Col>
							<FormLabelField
								title="What was the cause of death?"
								value={data?.deceasedCauseOfDeath?.value}
							/>
						</Col>
					</Row>
				)}

				<Row>
					{data?.deceasedDateOfDeath && (
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Date of Death"
								value={
									data?.deceasedDateOfDeath
										? formatDateReadableVariant(data.deceasedDateOfDeath)
										: ''
								}
							/>
						</Col>
					)}

					{causeOfDeath === 2 && data?.euthanisedReason?.value && (
						<Col xs={12} lg={4}>
							<FormLabelField
								title="Reason for Euthanised"
								value={data?.euthanisedReason?.value}
							/>
						</Col>
					)}
				</Row>
				{causeOfDeath === 2 && (
					<>
						<Row>
							{mappedInjuries?.length > 0 && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Injury Type"
										value={mappedInjuries
											.map((injury) => injury.value)
											.join(', ')}
									/>
								</Col>
							)}

							{data?.euthanisedInjuryOther && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Other Injury Details"
										value={data?.euthanisedInjuryOther}
									/>
								</Col>
							)}
						</Row>
						<Row>
							{mappedIllnesses?.length > 0 && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Illness Type"
										value={mappedIllnesses
											.map((illness) => illness.value)
											.join(', ')}
									/>
								</Col>
							)}
							{data?.euthanisedIllnessOther && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Other Illness Details"
										value={data?.euthanisedIllnessOther}
									/>
								</Col>
							)}
						</Row>
						<Row>
							{data?.unsuitableReasons?.length > 0 && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Unsuitable for rehoming reason"
										value={data?.unsuitableReasons
											?.map((reason) => reason.value)
											.join(', ')}
									/>
								</Col>
							)}
							{data?.euthanisedUnsuitableOther && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Other Unsuitable for rehoming reason"
										value={data?.euthanisedUnsuitableOther}
									/>
								</Col>
							)}
						</Row>
						{(data?.deceasedReasonForDeathOtherText ||
							data?.deceasedReasonForDeathOther?.length > 0) && (
							<Row>
								<Col>
									<FormLabelField
										title="Other reason for death"
										value={
											data?.deceasedThoroughbredSource?.id ===
												CONCLUDED_RESET_SOURCE ||
											data?.deceasedThoroughbredSource?.id ===
												CONCLUDED_OHEP_SOURCE
												? data?.deceasedReasonForDeathOtherText
												: data?.deceasedReasonForDeathOther
														?.map((item) => item.value)
														.join(', ')
										}
									/>
								</Col>
							</Row>
						)}
					</>
				)}
				{causeOfDeath === 4 && (
					<>
						<Row>
							{data?.deceasedKnackeryPostcode && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Knackery Postcode"
										value={data?.deceasedKnackeryPostcode}
									/>
								</Col>
							)}
							{data?.deceasedKnackerySuburb && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Knackery Suburb"
										value={data?.deceasedKnackerySuburb}
									/>
								</Col>
							)}
						</Row>
					</>
				)}
				{causeOfDeath === 6 && (
					<>
						{data?.informationAboutDeath && (
							<Row>
								<Col>
									<FormLabelField
										title="Information about death"
										value={data?.informationAboutDeath}
									/>
								</Col>
							</Row>
						)}
						<Row>
							{data?.deceasedOtherPostcode && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Postcode"
										value={data?.deceasedOtherPostcode}
									/>
								</Col>
							)}
							{data?.deceasedOtherSuburb && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Suburb"
										value={data?.deceasedOtherSuburb}
									/>
								</Col>
							)}
						</Row>
					</>
				)}
				{data?.deceasedThoroughbredSource?.id === ACK_RETIREMENT_SOURCE && (
					<Row>
						<Col>
							<FormLabelField
								title="Has the owner(s) been notified?"
								value={data?.hasOwnerBeenNotified ? 'Yes' : 'No'}
							/>
						</Col>
					</Row>
				)}
			</div>
		</>
	);
};
export default DeceasedUpdateSection;

import React from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Col, Row, useScreenClass } from 'react-grid-system';
import HeaderBanner from '../HeaderBanner';
import { useStaticQuery, graphql } from 'gatsby';
import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import MediaAsset from './components/MediaAsset';
import { checkIsSmallScreen } from '../../utils/helpers';
import { extractCardsList } from './helpers';
import InfoCards from '../InfoCards';
import Programs from '../Programs';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import { navigate } from 'gatsby';
import { CommonKeys } from './../../utils/constants/index';

const AcknowledgedRetrainerProgramInfo = () => {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const data = useStaticQuery(graphql`
		query getPageContent {
			allContentfulLandingPageContent(
				filter: { tags: { in: "Acknowledged Retrainer Application Page" } }
			) {
				nodes {
					title {
						title
					}
					body {
						raw
						references {
							__typename
							... on ContentfulAsset {
								contentful_id
								file {
									url
									contentType
									fileName
								}
								title
							}
						}
					}
				}
			}
			allContentfulInfoCards(
				filter: {
					tags: { in: "Acknowledged Retrainer Application Info Cards" }
				}
			) {
				nodes {
					text {
						text
					}
					title
					order
					image {
						file {
							url
							fileName
						}
					}
				}
			}
		}
	`);

	const renderVideosCollection = () => {
		return [
			...new Map(videoCollection.map((item) => [item['src'], item])).values()
		].map((video) => (
			<MediaAsset
				key={video.title}
				title={video.title}
				type={video.type}
				src={video.src}
			/>
		));
	};

	let videoCollection = [];
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>,
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				videoCollection.push({
					src: node.data.target.file.url,
					type: node.data.target.file.contentType,
					title: node.data.target.title
				});
			}
		}
	};
	const pageTitle =
		data?.allContentfulLandingPageContent?.nodes[0]?.title?.title;
	const richTextContent = data?.allContentfulLandingPageContent?.nodes[0]?.body;
	const cards = extractCardsList(data?.allContentfulInfoCards?.nodes);
	return (
		<PageContainer>
			<SEO title="Acknowledged retrainer program" />
			<Row>
				<Col
					style={{
						padding: isSmallScreen ? '0px 15px' : 0
					}}
				>
					<HeaderBanner title={pageTitle} styles={{ marginBottom: 50 }} />
					<Row>
						<Col>
							{!isSmallScreen ? (
								<InfoCards data={cards} cardStyle={{ width: 320 }} />
							) : (
								cards.length > 1 && <Programs data={cards} mobile noReadMore />
							)}
						</Col>
					</Row>
					<Row>
						<Col>{renderRichText(richTextContent, options)}</Col>
					</Row>
					<Row style={{ marginBottom: 15 }}>{renderVideosCollection()}</Row>
					<Row>
						<Col>
							<MessageBar>
								<Row>
									<Col xs={12} lg={8}>
										If you need more information please email the Off The Track
										team{' '}
										<a href="mailto:offthetrack@racingvictoria.net.au">
											offthetrack@racingvictoria.net.au
										</a>{' '}
									</Col>
									<Col xs={12} lg={4}>
										<PrimaryButton
											id={CommonKeys.START_APPLICATION}
											onClick={() => navigate('/acknowledged-retrainer-form')}
										>
											Start your Application
										</PrimaryButton>
									</Col>
								</Row>
							</MessageBar>
						</Col>
					</Row>
				</Col>
			</Row>
		</PageContainer>
	);
};

export default AcknowledgedRetrainerProgramInfo;

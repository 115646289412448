export const getRetirementAssessmentFormLatestUpdated = `
query getRetirementAssessmentFormLatestUpdated($memberExternalId: String!) {

    getRetirementQuarterlyAssessmentForms(memberExternalId: $memberExternalId status: Review orderBy: "last_updated" order: DESC) {
        id
        lastUpdated
    }
    getRetirementInitialAssessmentForms(memberExternalId: $memberExternalId status: Review orderBy: "last_updated" order: DESC) {
        id
        lastUpdated
    }
}
`;

import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveBusinessInformation,
	getBusinessInfoReferenceData
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import FormMultiSelectList from '../FormMultiSelectList';
import MultiSelectInput from '../MultiSelectInput';
import { convertToSortedMultiListData, formatDate } from '../../utils/helpers';
import {
	validation,
	MULTI_DEPENDENT,
	CHARACTER_LIMIT,
	TOGGLE_DEPENDENT
} from './validation';
import ResizingTextArea from '../ResizingTextArea';
import Toggle from '../Toggle';
import { FOSTER_CARE_ENUM } from './constants';
import FormDropDown from '../FormDropDown';
import FormDatePicker from '../FormDatePicker';
import { CommonKeys, ProfileKeys } from '../../utils/constants';

const validationFields = [
	'facilitiesOnsiteValues',
	'facilitiesOffsiteValues',
	'specialtyServices',
	'facilitiesOnSiteOtherMulti',
	'facilitiesOffSiteOtherMulti',
	'isMaximumCapacity',
	'maximumCapacity',
	'rangeOfFosterCare',
	'isAdviseDatesUnavailable',
	'dateUnavailableStart',
	'dateUnavailableEnd',
	'finalNotes'
];

export const referenceRangeOfFosterCare = [
	{ id: 0, value: '' },
	{ id: 1, value: FOSTER_CARE_ENUM.ONE_ONLY },
	{ id: 2, value: FOSTER_CARE_ENUM.TWO_FIVE },
	{ id: 3, value: FOSTER_CARE_ENUM.SIX_NINE },
	{ id: 4, value: FOSTER_CARE_ENUM.TEN_FIFTEEN },
	{ id: 5, value: FOSTER_CARE_ENUM.FIFTEEN_TWENTY },
	{ id: 6, value: FOSTER_CARE_ENUM.TWENTY_ONE_MORE }
];

const FosterProviderInformation = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			updateIsLoading,
			setIsSubmitted,
			businessProfileType,
			onSaveBusinessProfileData,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();

		const [validationOn, setValidationOn] = useState(false);
		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [errors, setErrors] = useState({});

		const [values, setValues] = useState({
			facilitiesOffsiteValues: {
				value: [],
				isRequired: false,
				isTouched: false
			},
			facilitiesOffSiteOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'facilitiesOffsiteValues',
						value: 'Other',
						errorMessage: `Other Facilities - Off Property are required`
					}
				]
			},
			facilitiesOnsiteValues: {
				value: [],
				isRequired: true,
				isTouched: false,
				errorMessage: `Facilities - On Property are required`
			},
			facilitiesOnSiteOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'facilitiesOnsiteValues',
						value: 'Other',
						errorMessage: `Other Facilities - On Property are required`
					}
				]
			},
			specialtyServices: {
				value: '',
				isTouched: false,
				conditions: [
					{
						condition: CHARACTER_LIMIT,
						limit: 500,
						errorMessage:
							'Experience/Specialty Services must have maximum of 500 characters'
					}
				],
				isRequired: false
			},
			isMaximumCapacity: {
				value: false,
				conditions: []
			},
			maximumCapacity: {
				value: '',
				isRequired: false,
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'isMaximumCapacity',
						value: true,
						errorMessage: 'Maximum Capacity field is required'
					},
					{
						condition: CHARACTER_LIMIT,
						limit: 500,
						errorMessage:
							'Maximum Capacity field must have maximum of 500 characters'
					}
				]
			},
			rangeOfFosterCare: {
				value: 0,
				isRequired: true
			},
			isAdviseDatesUnavailable: {
				value: false,
				conditions: []
			},
			dateUnavailableStart: {
				value: null,
				isRequired: false,
				errorMessage: 'Unavailable start date is required',
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'isAdviseDatesUnavailable',
						value: true,
						errorMessage: 'Unavailable start date is required'
					}
				]
			},
			dateUnavailableEnd: {
				value: null,
				isRequired: false,
				errorMessage: 'Unavailable end date is required',
				conditions: [
					{
						condition: TOGGLE_DEPENDENT,
						field: 'isAdviseDatesUnavailable',
						value: true,
						errorMessage: 'Unavailable end date is required'
					}
				]
			},
			finalNotes: {
				value: '',
				isTouched: false,
				conditions: [
					{
						condition: CHARACTER_LIMIT,
						limit: 700,
						errorMessage:
							'Final Notes or Comments must have maximum of 700 characters'
					}
				],
				isRequired: false
			}
		});

		const [referenceFacilitiesOnsite, setReferenceFacilitiesOnsite] = useState(
			[]
		);
		const [facilitiesOnsiteList, setFacilitiesOnsiteList] = useState([]);
		// const [specialtyServices, setSpecialtyServices] = useState('');
		const [
			referenceFacilitiesOffsite,
			setReferenceFacilitiesOffsite
		] = useState([]);
		const [facilitiesOffsiteList, setFacilitiesOffsiteList] = useState([]);

		const handleChange = (e) => {
			setIsFormDisabled(false);
			let items = { ...values };
			let item = {
				...items[e.target.name],
				value: e.target.value,
				isTouched: true
			};
			items[e.target.name] = item;
			setValues(items);
		};

		useEffect(() => {
			AppSyncService.execute(getBusinessInfoReferenceData).then((result) => {
				if (result.data) {
					setReferenceFacilitiesOnsite(result.data.listFacilitiesOnProperty);
					setReferenceFacilitiesOffsite(result.data.listFacilitiesOffSite);
				}
			});
		}, []);

		useEffect(() => {
			console.log(businessProfileData, 'businessProfileData');
			let items = { ...values };
			let facilitiesOnsiteValues = {
				...items['facilitiesOnsiteValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.facilitiesOnProperty : []
				)
			};
			items['facilitiesOnsiteValues'] = facilitiesOnsiteValues;
			let facilitiesOnSiteOtherMulti = {
				...items['facilitiesOnSiteOtherMulti'],
				value: businessProfileData
					? businessProfileData?.facilitiesOnPropertyOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['facilitiesOnSiteOtherMulti'] = facilitiesOnSiteOtherMulti;

			let facilitiesOffsiteValues = {
				...items['facilitiesOffsiteValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.facilitiesOffSite : []
				)
			};
			items['facilitiesOffsiteValues'] = facilitiesOffsiteValues;
			let facilitiesOffSiteOtherMulti = {
				...items['facilitiesOffSiteOtherMulti'],
				value: businessProfileData
					? businessProfileData?.facilitiesOffSiteOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['facilitiesOffSiteOtherMulti'] = facilitiesOffSiteOtherMulti;

			setValues(items);
			updateIsLoading(false);
			if (referenceFacilitiesOnsite && referenceFacilitiesOnsite.length > 1) {
				setFacilitiesOnsiteList(
					convertToSortedMultiListData(referenceFacilitiesOnsite)
				);
			}
			if (referenceFacilitiesOffsite && referenceFacilitiesOffsite.length > 1) {
				setFacilitiesOffsiteList(
					convertToSortedMultiListData(referenceFacilitiesOffsite)
				);
			}

			let specialtyServices = {
				...items['specialtyServices'],
				value: businessProfileData?.experiences
					? businessProfileData.experiences
					: ''
			};
			items['specialtyServices'] = specialtyServices;

			let isMaximumCapacity = {
				...items['isMaximumCapacity'],
				value: businessProfileData?.isMaximumCapacity
					? businessProfileData.isMaximumCapacity
					: ''
			};
			items['isMaximumCapacity'] = isMaximumCapacity;

			let maximumCapacity = {
				...items['maximumCapacity'],
				value: businessProfileData?.maximumCapacity
					? businessProfileData.maximumCapacity
					: ''
			};
			items['maximumCapacity'] = maximumCapacity;

			let rangeOfFosterCare = {
				...items['rangeOfFosterCare'],
				value: businessProfileData?.rangeOfFosterCare
					? businessProfileData.rangeOfFosterCare
					: ''
			};
			items['rangeOfFosterCare'] = rangeOfFosterCare;

			let isAdviseDatesUnavailable = {
				...items['isAdviseDatesUnavailable'],
				value: businessProfileData
					? businessProfileData.adviseDatesUnAvailable
					: false
			};
			items['isAdviseDatesUnavailable'] = isAdviseDatesUnavailable;

			let dateUnavailableStart = {
				...items['dateUnavailableStart'],
				value: businessProfileData
					? businessProfileData.dateUnavailableStart
					: null
			};
			items['dateUnavailableStart'] = dateUnavailableStart;

			let dateUnavailableEnd = {
				...items['dateUnavailableEnd'],
				value: businessProfileData
					? businessProfileData.dateUnavailableEnd
					: null
			};
			items['dateUnavailableEnd'] = dateUnavailableEnd;

			let finalNotes = {
				...items['finalNotes'],
				value: businessProfileData
					? !businessProfileData.finalNotes
						? ''
						: businessProfileData.finalNotes
					: ''
			};
			items['finalNotes'] = finalNotes;
			setIsFormDisabled(true);
		}, [
			businessProfileData,
			referenceFacilitiesOnsite,
			referenceFacilitiesOffsite
		]);

		useImperativeHandle(ref, () => ({
			triggerValidation() {
				setErrors(validation(values, true, validationFields));
			}
		}));

		const handleSubmit = async (e) => {
			e.preventDefault();
			validateFields();
			setValidationOn(true);
			setErrors(validation(values, true, validationFields));
			if (
				Object.keys(validation(values, true, validationFields)).length === 0
			) {
				setBackendErrorMessage(null);
				let businessInfoFacilitiesOnPropertyIDs = [];
				values.facilitiesOnsiteValues.value &&
					values.facilitiesOnsiteValues.value.map((item) => {
						businessInfoFacilitiesOnPropertyIDs.push(item.value);
					});
				let businessInfoFacilitiesOffsitePropertyIDs = [];
				values.facilitiesOffsiteValues.value &&
					values.facilitiesOffsiteValues.value.map((item) => {
						businessInfoFacilitiesOffsitePropertyIDs.push(item.value);
					});
				let otherfacilitiesOffSite = null;
				if (
					values.facilitiesOffsiteValues.value &&
					values.facilitiesOffsiteValues.value.filter(
						(r) => r.label === 'Other'
					).length > 0
				) {
					otherfacilitiesOffSite = values.facilitiesOffSiteOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}
				let otherfacilitiesOnProperty = null;
				if (
					values.facilitiesOnsiteValues.value &&
					values.facilitiesOnsiteValues.value.filter((r) => r.label === 'Other')
						.length > 0
				) {
					otherfacilitiesOnProperty = values.facilitiesOnSiteOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}

				let items = { ...values };
				const state = {
					facilitiesOffSite: items?.facilitiesOffsiteValues?.value.map(
						(offsite) => {
							return { id: offsite.value, value: offsite.label };
						}
					),
					facilitiesOffSiteOther: otherfacilitiesOffSite,
					facilitiesOnProperty: items?.facilitiesOnsiteValues?.value.map(
						(onsite) => {
							return { id: onsite.value, value: onsite.label };
						}
					),
					facilitiesOnPropertyOther: otherfacilitiesOnProperty,
					status: 'Draft'
				};
				try {
					AppSyncService.execute(saveBusinessInformation, {
						facilitiesOffSite: businessInfoFacilitiesOffsitePropertyIDs,
						facilitiesOffSiteOther: otherfacilitiesOffSite,
						facilitiesOnProperty: businessInfoFacilitiesOnPropertyIDs,
						facilitiesOnPropertyOther: otherfacilitiesOnProperty,
						memberExternalId: currentUser?.UserId,
						businessProfileTypeId: businessProfileType,
						isMaximumCapacity: values?.isMaximumCapacity?.value,
						maximumCapacity: values?.maximumCapacity?.value,
						rangeOfFosterCare: values?.rangeOfFosterCare?.value,
						isAdviseDatesUnavailable: values?.isAdviseDatesUnavailable?.value,
						dateUnavailableStart: values?.dateUnavailableStart?.value
							? formatDate(values?.dateUnavailableStart?.value)
							: values?.dateUnavailableStart?.value,
						dateUnavailableEnd: values?.dateUnavailableEnd?.value
							? formatDate(values?.dateUnavailableEnd?.value)
							: values?.dateUnavailableEnd?.value,
						finalNotes: values?.finalNotes?.value,
						businessSummary: '',
						experiences: values.specialtyServices.value
					}).then((data) => {
						if (data.data != null) {
							addToast({ Message: 'Saved', IsSuccess: true });
							setIsSubmitted(false);
							onSaveBusinessProfileData(state);
						}
					});
					setIsFormDisabled(true);
				} catch (error) {
					if (error) {
						setBackendErrorMessage(error);
					} else {
						setBackendErrorMessage(
							'Unknown error while saving user Business Information data. Please contact support'
						);
					}
				}
			} else {
				setBackendErrorMessage(
					'Please complete all the required fields before saving.'
				);
			}
		};

		const handleMultiFieldChange = (value, name, fieldsToClear) => {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			if (fieldsToClear && fieldsToClear.length > 0) {
				fieldsToClear.forEach((field) => {
					let newItem = {
						...items[field.item],
						value: field.value
					};
					items[field.item] = newItem;
				});
			}
			setIsFormDisabled(false);
			setValues(items);
		};

		useEffect(() => {
			setBackendErrorMessage(null);
		}, [values]);

		const handleChangeToggle = (val, name, fieldsToClear) => {
			setIsFormDisabled(false);
			let items = { ...values };
			let item = {
				...items[name],
				value: val
			};
			items[name] = item;
			if (fieldsToClear && fieldsToClear.length > 0) {
				fieldsToClear.forEach((field) => {
					let newItem = {
						...items[field.item],
						value: field.value
					};
					items[field.item] = newItem;
				});
			}
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		};

		const handleChangeDropDown = (e, fieldsToClear) => {
			setIsFormDisabled(false);
			let items = { ...values };
			let item = {
				...items[e.target.name],
				value: parseInt(e.target.value)
			};
			items[e.target.name] = item;
			if (fieldsToClear && fieldsToClear.length > 0) {
				fieldsToClear.forEach((field) => {
					let newItem = {
						...items[field.item],
						value: field.value
					};
					items[field.item] = newItem;
				});
			}
			setValues(items);
			setErrors(validation(items, validationOn, validationFields));
		};

		const handleDateUpdate = (value, name) => {
			setIsFormDisabled(false);
			let formattedDate = formatDate(value);
			if (formattedDate != '1970-01-01') {
				let items = { ...values };
				let item = {
					...items[name],
					value: value
				};
				items[name] = item;
				setValues(items);
				setErrors(validation(values, validationOn, validationFields));
			} else {
				let items = { ...values };
				let item = {
					...items[name],
					value: null
				};
				items[name] = item;
				setValues(items);
				setErrors(validation(values, validationOn, validationFields));
			}
		};

		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<form
					id={ProfileKeys.FOSTER_PROVIDER_INFORMATION}
					className={classes.formContainer}
				>
					<div className={classes.personalProfile}>
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Facilities - On Property*
									</div>
									<FormMultiSelectList
										id={ProfileKeys.FACILITIES_ON_PROPERTY}
										name="facilitiesOnsiteValues"
										listOptions={facilitiesOnsiteList}
										selectedIds={values.facilitiesOnsiteValues.value}
										onSelectChange={(e) =>
											handleMultiFieldChange(e, 'facilitiesOnsiteValues', [
												{ item: 'facilitiesOnSiteOtherMulti', value: '' }
											])
										}
										border={errors.facilitiesOnsiteValues ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.facilitiesOnsiteValues
											? `Facilities - On Property are required`
											: ''}
									</span>
								</div>
							</Col>
						</Row>
						{values.facilitiesOnsiteValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Facilities - On Property{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual facilities by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											id={ProfileKeys.OTHER_FACILITIES_ON_PROPERTY}
											value={values.facilitiesOnSiteOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'facilitiesOnSiteOtherMulti'
												);
											}}
											hasError={errors.facilitiesOnSiteOtherMulti}
										/>
									</div>
									<span className={classes.errorText}>
										{errors.facilitiesOnSiteOtherMulti
											? `Other Facilities - On Property are required`
											: ''}
									</span>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Facilities - Off Property{' '}
									</div>
									<FormMultiSelectList
										id={ProfileKeys.FACILITIES_OFF_PROPERTY}
										name="facilitiesOffsite"
										listOptions={facilitiesOffsiteList}
										selectedIds={values.facilitiesOffsiteValues.value}
										onSelectChange={(e) =>
											handleMultiFieldChange(e, 'facilitiesOffsiteValues', [
												{ item: 'facilitiesOffSiteOtherMulti', value: '' }
											])
										}
										border={errors.facilitiesOffsite ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.facilitiesOffsite
											? `Facilities - Off Property  are required`
											: ''}
									</span>
								</div>
							</Col>
						</Row>
						{values.facilitiesOffsiteValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Facilities - Off Property{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual facilities by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											id={ProfileKeys.OTHER_FACILITIES_OFF_PROPERTY}
											value={values.facilitiesOffSiteOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'facilitiesOffSiteOtherMulti'
												);
											}}
											hasError={errors.facilitiesOffSiteOtherMulti}
										/>
										<span className={classes.errorText}>
											{errors.facilitiesOffSiteOtherMulti
												? `Other Facilities - Off Property are required`
												: ''}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={`${classes.fieldTitle} float-left`}>
										Experience / Specialty Services
									</div>
									<ResizingTextArea
										borderColor={errors.specialtyServices ? 'red' : '#c7ced1'}
										name="specialtyServices"
										id={ProfileKeys.EXPERIENCE}
										value={values.specialtyServices.value}
										onChange={handleChange}
										maxChars={500}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.specialtyServices?.message}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Do you have a maximum capacity for STAR Care / Emergency Aid
										horses?
									</div>
									<Toggle
										id={ProfileKeys.MAXIMUM_CAPACITY}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'isMaximumCapacity', [
												{ item: 'maximumCapacity', value: '' }
											])
										}
										value={values.isMaximumCapacity.value}
									/>
								</div>
							</Col>
						</Row>
						{values.isMaximumCapacity.value && (
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.ProfilefieldContainer}>
										<div className={`${classes.fieldTitle} float-left`}>
											If yes, please specify your maximum capacity*
										</div>
										<ResizingTextArea
											borderColor={errors.maximumCapacity ? 'red' : '#c7ced1'}
											name="maximumCapacity"
											id={ProfileKeys.MAXIMUM_CAPACITY_DETAILS}
											value={values.maximumCapacity.value}
											onChange={handleChange}
											maxChars={500}
											styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
										/>
										<span className={classes.errorText}>
											{errors.maximumCapacity?.message}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										How many STAR Care / Emergency Aid horses are you
										comfortable having at any time?*
									</div>
									<FormDropDown
										showBlank
										name="rangeOfFosterCare"
										id={ProfileKeys.RANGE_FOSTER_CARE}
										items={referenceRangeOfFosterCare}
										selectedId={values.rangeOfFosterCare.value}
										onChange={handleChangeDropDown}
										styles={
											errors.rangeOfFosterCare
												? { border: '1px solid red' }
												: {}
										}
									/>
									<div className={classes.errorText}>
										{errors.rangeOfFosterCare}
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Do you want to advise of any dates you are unavailable for?
									</div>
									<Toggle
										id={ProfileKeys.ADVISE_UNAVAILABLE_DATE}
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) =>
											handleChangeToggle(val, 'isAdviseDatesUnavailable', [
												{ item: 'dateUnavailableStart', value: null },
												{ item: 'dateUnavailableEnd', value: null }
											])
										}
										value={values.isAdviseDatesUnavailable.value}
									/>
								</div>
							</Col>
						</Row>
						{values.isAdviseDatesUnavailable.value && (
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Select unavailable start date*{' '}
										</div>
										<FormDatePicker
											minDate={new Date()}
											noMaxDate
											isError={errors.dateUnavailableStart ? true : false}
											id={ProfileKeys.START_DATE}
											DateValue={values.dateUnavailableStart.value}
											updateSelection={(value) =>
												handleDateUpdate(value, 'dateUnavailableStart')
											}
										/>
										<span className={classes.errorText}>
											{errors.dateUnavailableStart}
										</span>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.ProfilefieldContainer}>
										<div className={classes.fieldTitle}>
											Select unavailable end date*{' '}
										</div>
										<FormDatePicker
											minDate={
												new Date(values.dateUnavailableStart.value) ||
												new Date()
											}
											noMaxDate
											isError={errors.dateUnavailableEnd ? true : false}
											id={ProfileKeys.END_DATE}
											DateValue={values.dateUnavailableEnd.value}
											updateSelection={(value) =>
												handleDateUpdate(value, 'dateUnavailableEnd')
											}
										/>
										<span className={classes.errorText}>
											{errors.dateUnavailableEnd}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col xs={12} lg={12}>
								<div className={classes.ProfilefieldContainer}>
									<div className={`${classes.fieldTitle} float-left`}>
										Final Notes or Comments
									</div>
									<ResizingTextArea
										borderColor={errors.finalNotes ? 'red' : '#c7ced1'}
										name="finalNotes"
										id={ProfileKeys.FINAL_NOTES}
										value={values.finalNotes.value}
										onChange={handleChange}
										maxChars={700}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.finalNotes?.message}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>

					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									id={CommonKeys.SAVE}
									style={{ width: '165px' }}
									disabled={isFormDisabled}
									onClick={handleSubmit}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
			</>
		);
	}
);
export default FosterProviderInformation;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	tooltipContainer: {
		position: 'relative',
		display: 'inline-block',
		color: '#464749',
		fontWeight: 'normal'
	},
	question: {},
	tooltip: {
		position: 'absolute',
		padding: 10,
		top: -12,
		background: '#ffffff',
		borderRadius: 5,
		boxShadow: '1px 1px 7px 0px #969494',
		left: 30,
		width: 200,
		zIndex: 10
	},
	questionMark: {
		backgroundColor: '#dcebf3',
		width: 20,
		height: 20,
		margin: 0,
		fontSize: 15,
		textAlign: 'center',
		borderRadius: 100,
		boxSizing: 'border-box',
		fontWeight: 'bold',
		color: '#006da6',
		marginLeft: 10,
		cursor: 'default'
	},
	image: {
		position: 'absolute',
		top: 3
	}
}));

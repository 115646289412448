import React, { useState } from 'react';
import { Row, Col, useScreenClass } from 'react-grid-system';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import { getUserImage } from '../../graphql/custom.queries';
import UploadPhoto from '../UploadPhoto';
import AppSyncService from '../../graphql/AppSyncService';
import Lightbox from 'react-image-lightbox';
import FormLabelField from '../FormLabelField';
import ResizingTextArea from '../ResizingTextArea';
import { REVIEW } from '../../utils/constants';
import { FOSTER_CARE_ENUM } from '../FosterProviderInformation/constants';
import { formatDateReadableVariant } from '../../utils/helpers';

const referenceRangeOfFosterCare = [
	{ id: 0, value: '' },
	{ id: 1, value: FOSTER_CARE_ENUM.ONE_ONLY },
	{ id: 2, value: FOSTER_CARE_ENUM.TWO_FIVE },
	{ id: 3, value: FOSTER_CARE_ENUM.SIX_NINE },
	{ id: 4, value: FOSTER_CARE_ENUM.TEN_FIFTEEN },
	{ id: 5, value: FOSTER_CARE_ENUM.FIFTEEN_TWENTY },
	{ id: 6, value: FOSTER_CARE_ENUM.TWENTY_ONE_MORE }
];

const BusinessInformationFosterView = ({
	businessProfileData,
	experiences,
	updateExperiences
}) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const screenClassForRender = useScreenClass();
	let isMobile = ['xs', 'sm', 'md'].includes(screenClassForRender);

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};
	return (
		<>
			<HeaderBanner
				title="STAR Provider Information"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Facilities - On Property*"
							value={businessProfileData.facilitiesOnProperty
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.facilitiesOnProperty.filter(
					(r) => r.value === 'Other'
				).length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Facilities - On Property"
								value={businessProfileData.facilitiesOnPropertyOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<FormLabelField
							title="Facilities - Off Property"
							value={businessProfileData.facilitiesOffSite
								?.map((item) => item.value)
								.join(', ')}
						/>
					</Col>
				</Row>
				{businessProfileData.facilitiesOffSite.filter(
					(r) => r.value === 'Other'
				).length > 0 && (
					<Row>
						<Col xs={12} lg={12}>
							<FormLabelField
								title="Other Facilities - Off Property"
								value={businessProfileData.facilitiesOffSiteOther
									?.split(',')
									.join(', ')}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12} lg={12}>
						<div className={classes.fieldContainer}>
							<div>
								<ResizingTextArea
									title="Experience / Specialty Services"
									charLengthStyle={
										isMobile ? { float: 'right', marginBottom: 10 } : {}
									}
									name="experiences"
									id="businessReview-fosterBusinessInformation-experiences"
									value={experiences}
									onChange={(e) => {
										updateExperiences(e.target.value);
									}}
									maxChars={700}
									titleStyles={{ paddingBottom: 5 }}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									canEdit={
										businessProfileData?.status === REVIEW ? true : false
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Do you have a maximum capacity for STAR Care / Emergency Aid horses?"
							value={businessProfileData?.isMaximumCapacity ? 'Yes' : 'No'}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="If yes, please specify your maximum capacity*"
							value={businessProfileData?.maximumCapacity}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="How many STAR Care / Emergency Aid horses are you comfortable having at any time?*"
							value={
								referenceRangeOfFosterCare.find(
									(selectedReference) =>
										selectedReference.id ===
										businessProfileData?.rangeOfFosterCare
								)?.value
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Do you want to advise of any dates you are unavailable for?"
							value={businessProfileData?.adviseDatesUnAvailable ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Select unavailable start date*"
							value={
								businessProfileData?.dateUnavailableStart
									? formatDateReadableVariant(
											businessProfileData.dateUnavailableStart
									  )
									: ''
							}
						/>
					</Col>
					<Col>
						<FormLabelField
							title="Select unavailable end date*"
							value={
								businessProfileData?.dateUnavailableEnd
									? formatDateReadableVariant(
											businessProfileData.dateUnavailableEnd
									  )
									: ''
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormLabelField
							title="Final Notes or Comments"
							value={businessProfileData?.finalNotes}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Media"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				<Row>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>{`Photo - ${
								businessProfileData.businessProfileType.id === 2
									? `Facilities`
									: `Ridden*`
							}`}</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.riddenPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Photo - Property*</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.propertyPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Photo - General</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={businessProfileData.generalPhotos}
								getImage={getImage}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
			</div>
		</>
	);
};
export default BusinessInformationFosterView;

import Forward from '../../assets/icons/Forward_Carousel.svg';
import Back from '../../assets/icons/Back_Carousel.svg';
import LazyImage from '../LazyImage';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import Modal from 'react-modal';
import Cross from '../../assets/icons/Light_Cross_Not_Validated_icon.svg';
import { defaultModalMobileStyles, defaultModalStyles } from './constants';
import { checkIsSmallScreen } from '../../utils/helpers';
import { useScreenClass } from 'react-grid-system';

const ImageSlider = ({ sliderImages, styles, startIndex }) => {
	const classes = useStyles();
	const [current, setCurrent] = useState(0);
	const [sliderIsOpen, setSliderIsOpen] = useState(false);
	const imgArrayLength = sliderImages.length;
	const screenSize = useScreenClass();

	useEffect(() => {
		if (imgArrayLength - 1 >= startIndex) {
			setCurrent(startIndex);
		}
	}, [startIndex]);

	const nextSlide = () => {
		setCurrent(current === imgArrayLength - 1 ? 0 : current + 1);
	};

	const prevSlide = () => {
		setCurrent(current === 0 ? imgArrayLength - 1 : current - 1);
	};

	const modalImgStyles = {
		objectFit: 'contain',
		width: '100%',
		maxHeight: 470
	};

	const CaroselComponent = ({ onImageClick }) => {
		return (
			<>
				<div
					className={
						checkIsSmallScreen(screenSize)
							? classes.sliderContainerMobile
							: classes.sliderContainer
					}
				>
					<div className={classes.mobileBackBase} onClick={() => prevSlide()}>
						<img src={Back} className={classes.mobileButton} />
					</div>
					<div className={classes.mobileActive}>
						<LazyImage
							imageKey={sliderImages[current]?.image}
							onImageClick={() => onImageClick()}
							styles={modalImgStyles}
						/>
					</div>
					<div
						className={classes.mobileForwardBase}
						onClick={() => nextSlide()}
					>
						<img src={Forward} className={classes.mobileButton} />
					</div>
				</div>
			</>
		);
	};

	return (
		<div style={{ display: 'flex', width: '100%' }}>
			<CaroselComponent onImageClick={() => setSliderIsOpen(true)} />
			<Modal
				isOpen={sliderIsOpen}
				style={
					styles
						? styles
						: checkIsSmallScreen(screenSize)
						? defaultModalMobileStyles
						: defaultModalStyles
				}
				contentLabel="View Image"
				ariaHideApp={false}
				shouldCloseOnEsc={true}
				closeModal={() => setSliderIsOpen(false)}
				onRequestClose={() => setSliderIsOpen(false)}
			>
				<img
					src={Cross}
					className={classes.xButton}
					onClick={() => setSliderIsOpen(false)}
				/>
				<CaroselComponent
					onImageClick={() => {
						setSliderIsOpen(true);
					}}
				/>
			</Modal>
		</div>
	);
};

export default ImageSlider;

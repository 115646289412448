import { navigate } from 'gatsby-link';
import React from 'react';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../utils/helpers';
import useStyles from './styles';

const InfoCards = ({ data, cardStyle }) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	const renderCards = () => {
		return data.map((d, index) => {
			return (
				<div className={classes.cardsContainer} style={cardStyle} key={index}>
					<div>
						<img src={d.image} className={classes.image} />
					</div>
					<div className={classes.articleContainer}>
						<div
							onClick={() => {
								d?.link ? navigate('/' + d.link) : null;
							}}
							className={d?.link ? classes.articleLink : classes.articleHeader}
						>
							{d.title}
						</div>
						<div className={classes.articleSummary}>{d.summary}</div>
					</div>
				</div>
			);
		});
	};

	return (
		<>
			<div
				className={isSmallScreen ? classes.mobileContainer : classes.container}
			>
				{renderCards()}
			</div>
		</>
	);
};

export default InfoCards;

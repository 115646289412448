export const MessageBarTypeEnum = Object.freeze({
	primary: 'primary',
	info: 'info',
	message: 'message'
});

export const ToolTipTypeEnum = Object.freeze({
	questionMark: 'question',
	exclamationMark: 'exclamation'
});

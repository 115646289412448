import React from 'react';
import useStyles from './styles';
import { Col } from 'react-grid-system';
import ValidationErrorText from '../ValidationErrorText';

const FormFieldWrapper = ({
	fieldLabel,
	children,
	visibilitySelector,
	errorMessage,
	isRequired = false,
	fullWidth = false,
	addTopSpace = false,
	textParenthesesNormal = false
}) => {
	const classes = useStyles();

	const renderFieldLabel = (fieldLabel) => {
		let finalDescription = fieldLabel;
		let regExp = /\(([^)]+)\)/g;
		let matches = finalDescription.match(regExp);
		for (let i = 0; i < matches?.length; i++) {
			let str = matches[i];
			finalDescription = finalDescription.replaceAll(
				str,
				`<span class="font-normal">${str}</span>`
			);
		}
		return (
			<span
				dangerouslySetInnerHTML={{
					__html: finalDescription
				}}
			/>
		);
	};

	return (
		<Col sm={12} lg={fullWidth ? 12 : 4} className={classes.formFieldWrapper}>
			{addTopSpace && <div>&nbsp;</div>}
			<div className={classes.formFieldLabel}>
				{textParenthesesNormal ? renderFieldLabel(fieldLabel) : fieldLabel}
				{`${isRequired ? '*' : ''}`}
				{visibilitySelector && (
					<div className={classes.visibilitySelectorContainer}>
						{visibilitySelector}
					</div>
				)}
			</div>
			{children}
			{errorMessage && <ValidationErrorText errorText={errorMessage} />}
		</Col>
	);
};

export default FormFieldWrapper;

export const getHorsesFollowedByMember = `
query getHorsesFollowed($member_id: Int!) {
  getHorsesFollowedByMember(member_id: $member_id) {
    items {
      horse_code
    }
  }
}
`;

export const getAllHorseUpdatesForMember = `
query getAllHorseUpdatesForMember($horseCodes: [Int!]!, $memberId: Int!, $paginationIndex: Int!, $paginationSize: Int!) {
  getAllHorseUpdatesForMember(horseCodes: $horseCodes, memberId: $memberId, paginationIndex: $paginationIndex, paginationSize: $paginationSize) {
    claims {
      member {
        id
      }
    }
    created_at
    horse_code
    post_id
    message
    member_id
    source
    horse {
      name
      ottInformation {
        profilePhotoVisibility {
          id
        }
        profilePhoto {
          key
        }
      }
    }
    files {
      filename
      key
    }
    postingMember {
      externalId
    }
  }
}
`;

export const getTotalHorseUpdatesForMember = `
  query getTotalHorseUpdatesForMember($horseCodes: [Int!]!, $memberId: Int!) {
    getTotalHorseUpdatesCountForMember(horseCodes:  $horseCodes, memberId: $memberId)
  }
`;

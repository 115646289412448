import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	formHeader: {
		marginBottom: 20
	},
	formSection: {
		color: '#464749',
		fontSize: 20,
		background: `url(${BannerImage})`,
		display: 'flex',
		alignItems: 'center',
		height: 65,
		fontWeight: 800,
		paddingLeft: 15,
		width: '100%',
		boxSizing: 'border-box'
	},
	formHeaderTitleSection: {
		extend: 'formSection',
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5
	},
	formHeaderDescriptionContent: {
		height: 65,
		display: 'flex',
		alignItems: 'center',
		backgroundColor: 'rgb(252,247,218)',
		paddingLeft: 15,
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		fontSize: 15,
		color: '#464749',
		width: '100%',
		boxSizing: 'border-box'
	},
	formSectionBody: {
		'& > div:first-child > div:first-child': {
			borderTopLeftRadius: 5,
			borderTopRightRadius: 5
		}
	},
	formFieldWrapper: {
		width: '100%',
		marginBottom: 20,
		'@media (min-width: 992px)': {
			marginBottom: 0
		}
	},
	horseStatusIndicator: {
		backgroundColor: 'rgb(252,247,218)',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 800,
		fontSize: 20,
		padding: 20,
		borderRadius: 5,
		marginBottom: 20,
		'@media (min-width: 992px)': {
			marginBottom: 0
		}
	},
	formFieldLabel: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	additionalButtonStyles: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer',
		background: 'none',
		border: 'none'
	},
	claimButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 400,
		height: 38,
		width: '100%',
		fontSize: 15,
		'-webkitAppearance': 'none',
		'&:focus': {
			outline: 'none'
		},
		'&:disabled': {
			backgroundColor: '#d8d8d8',
			color: '#afafaf'
		},
		cursor: 'pointer'
	},
	claimThorougbredLinkContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
		boxSizing: 'border-box',
		backgroundColor: '#f5f5f5',
		paddingTop: 15,
		paddingBottom: 15,
		borderRadius: 5,
		'& > div': {
			marginBottom: 10
		},
		'@media (min-width:992px)': {
			flexDirection: 'row',
			'& > div': {
				marginBottom: 0
			}
		}
	},
	manualEntryCheck: {
		marginTop: 0,
		marginBottom: 15,
		'@media (min-width:992px)': {
			marginTop: 15
		}
	},
	errorMessage: {
		fontSize: 15,
		color: '#db0000',
		marginTop: 10,
		float: 'right'
	},
	footerDiv: {
		borderBottom: '1px solid #b3bcc1',
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1',
		borderTop: '1px solid #dcebf3',
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5,
		marginLeft: -15,
		marginRight: -15,
		display: 'flex',
		flexDirection: 'column',
		padding: 15
	},
	fieldTitle: {
		fontSize: 15,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	}
}));

import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';

const CustomFormControlLabel = withStyles({
	label: {
		fontSize: 15,
		fontWeight: 600
	}
})((props) => <FormControlLabel color="default" {...props} />);

const BasicRadioButtonGroup = ({
	category,
	radioItems = [{ id: 0, value: '', label: '' }],
	disabled,
	value = '',
	onHandleChange = () => {},
	row = false
}) => {
	const classes = useStyles();
	return (
		<FormControl component="fieldset">
			{category ? <FormLabel component="legend">{category}</FormLabel> : null}

			<RadioGroup
				aria-label="basicRadioButtonGroup"
				name="basicRadioButtonGroup1"
				value={value}
				onChange={onHandleChange}
				row={row}
			>
				{radioItems.map((item) => (
					<CustomFormControlLabel
						key={item.id}
						value={item.value}
						control={
							<Radio
								checkedIcon={
									<IconButton className={classes.iconButtonContainer}>
										<CheckCircleIcon className={classes.iconColor} />
									</IconButton>
								}
							/>
						}
						label={item.label}
						disabled={disabled}
						className={classes.textLabel}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default BasicRadioButtonGroup;

import React, { Fragment, useEffect, useState } from 'react';
import { useScreenClass, Col, Row, Container } from 'react-grid-system';
import { TopContent, QuickLinks, OTTNews } from '../../../Mobile';
import { navigate } from '@reach/router';
import useStyles from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { useAuth } from '../../../../hooks/useAuth';
import {
	checkIsSmallScreen,
	formatDateReadable
} from '../../../../utils/helpers';
import PictureArticle from '../../../PictureArticle';
import PrimaryButton from '../../../PrimaryButton';
import Notifications from '../Notifications';
import ClaimCountWidget from '../../../ClaimCountWidget';
import { DashboardKeys } from '../../../../utils/constants';

const Home = ({
	notifications,
	removeNotification,
	memberQuickLinks,
	claimCount,
	updateCompetitionAdminNofitcation,
	updateRetirementAdminNotification,
	updateAckFosterNofitcation,
	tempRemoveNotification
}) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const { currentUser } = useAuth();
	const isSmallScreen = checkIsSmallScreen(screenSize);

	const [newsArticles, setNewsArticles] = useState([]);
	const [IsLoading, setIsLoading] = useState(false);
	const [mainArticle, setMainArticle] = useState();

	const contentFulQuickLinks = useStaticQuery(
		graphql`
			query homeTabContent {
				allContentfulArticle(
					filter: { tags: { in: "Tack Room" } }
					sort: { fields: publishTime, order: DESC }
				) {
					edges {
						node {
							id
							title
							summary
							publishTime
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
				allContentfulDashboardMainArticle {
					nodes {
						ottText {
							ottText
						}
						ottMedia {
							file {
								contentType
								url
							}
						}
						ottHeading
					}
				}
			}
		`
	);

	const fetchContentful = () => {
		const currentDate = new Date();
		const newArticles = contentFulQuickLinks.allContentfulArticle.edges.filter(
			(a) => {
				var publishTime = new Date(a.node.publishTime);
				return publishTime.getTime() <= currentDate.getTime();
			}
		);
		setNewsArticles(newArticles);
		let data = null;
		try {
			const article =
				contentFulQuickLinks.allContentfulDashboardMainArticle.nodes[0];
			data = {
				heading: article.ottHeading,
				summary: article.ottText.ottText,
				media: article.ottMedia.file.url,
				contentType: article.ottMedia.file.contentType
			};
		} catch {
			data = null;
		}
		setMainArticle(data);
	};

	const fetchData = async () => {
		setIsLoading(true);
		fetchContentful();
		setIsLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const updateProfile = () => {
		navigate(`/account`);
	};

	const ottTackRoom = () => {
		navigate(`/ott-tack-room`);
	};

	const goToArticle = (id) => {
		navigate(`/ott-tack-room/${id}`);
	};

	const ArticleRender = ({ index, isMobile }) => {
		return (
			<OTTNews
				isMobile={isMobile}
				id={newsArticles[index].node.id}
				imageLink={newsArticles[index].node.previewImage?.fluid?.src}
				heading={newsArticles[index].node.title}
				body={newsArticles[index].node.summary}
				onClick={() => goToArticle(newsArticles[index].node.id)}
				date={formatDateReadable(newsArticles[index].node.publishTime)}
			/>
		);
	};

	const MobileHomeTab = (
		<>
			<div className={classes.dashboardMobileTopContainer}>
				<TopContent
					isMobile
					leftId={DashboardKeys.WELCOME}
					rightId={DashboardKeys.UPDATE_PROFILE}
					RightLinkText="Update Profile"
					leftText={`Welcome ${currentUser?.Name}`}
					onClickRight={updateProfile}
					styles={{
						alignItems: 'baseline',
						justifyContent: 'flex-end',
						display: 'flex'
					}}
					textLeftStyle={{ marginRight: 'auto' }}
				/>
				<div className={classes.notificationsContainer}>
					{Notifications(
						true,
						notifications,
						removeNotification,
						updateCompetitionAdminNofitcation,
						updateRetirementAdminNotification
					)}
					{notifications.length > 4 && (
						<PrimaryButton
							id={DashboardKeys.VIEW_ALL_NOTIFICATIONS}
							onClick={() => navigate('/all-notifications')}
							secondary
						>
							View All Notifications ({notifications.length})
						</PrimaryButton>
					)}
				</div>

				{!IsLoading && (
					<Fragment>
						<ClaimCountWidget claimCount={claimCount} />
						{memberQuickLinks.length > 2 && (
							<QuickLinks links={memberQuickLinks} />
						)}
					</Fragment>
				)}
				{mainArticle != null && (
					<PictureArticle
						text={mainArticle.summary}
						title={mainArticle.heading}
						imageSrc={mainArticle.media}
						ottContentType={mainArticle.contentType}
						mobile
						dashboard
					/>
				)}
				<TopContent
					RightLinkText="View all"
					leftText="OTT Tack Room"
					onClickRight={ottTackRoom}
					styles={{
						marginTop: 30,
						alignItems: 'baseline',
						justifyContent: 'flex-end',
						display: 'flex'
					}}
					textLeftStyle={{ marginRight: 'auto' }}
				/>

				{newsArticles.length > 0 && (
					<>
						<ArticleRender isMobile index={0} />
						{newsArticles.length > 1 && <ArticleRender isMobile index={1} />}
						{newsArticles.length > 2 && (
							<>
								<ArticleRender isMobile index={2} />
								{newsArticles.length > 3 && (
									<ArticleRender isMobile index={3} />
								)}
							</>
						)}
					</>
				)}
			</div>
		</>
	);

	const HomeTab = (
		<>
			<Col lg={9}>
				<div className={classes.dashboardTopContainer}>
					<TopContent
						leftId={DashboardKeys.WELCOME}
						rightId={DashboardKeys.UPDATE_PROFILE}
						RightLinkText="Update Profile"
						leftText={`Welcome ${currentUser?.Name}`}
						onClickRight={updateProfile}
						styles={{
							alignItems: 'baseline',
							justifyContent: 'flex-end',
							display: 'flex'
						}}
						textLeftStyle={{ marginRight: 'auto' }}
					/>
					<div className={classes.notificationsContainer}>
						{Notifications(
							true,
							notifications,
							removeNotification,
							updateCompetitionAdminNofitcation,
							updateRetirementAdminNotification,
							updateAckFosterNofitcation,
							tempRemoveNotification
						)}
						{notifications.length > 4 && (
							<PrimaryButton
								id={DashboardKeys.VIEW_ALL_NOTIFICATIONS}
								onClick={() => navigate('/all-notifications')}
								secondary
							>
								View All Notifications ({notifications.length})
							</PrimaryButton>
						)}
					</div>
					{mainArticle != null && (
						<PictureArticle
							small
							text={mainArticle.summary}
							title={mainArticle.heading}
							imageSrc={mainArticle.media}
							ottContentType={mainArticle.contentType}
						/>
					)}
					<TopContent
						RightLinkText="View all"
						leftText="OTT Tack Room"
						onClickRight={ottTackRoom}
						styles={{
							marginTop: 30,
							alignItems: 'baseline',
							justifyContent: 'flex-end',
							display: 'flex'
						}}
						textLeftStyle={{ marginRight: 'auto' }}
					/>

					{newsArticles.length > 0 && (
						<Container>
							<Row>
								<Col xs={12} lg={6}>
									<ArticleRender isMobile index={0} />
								</Col>
								<Col xs={12} lg={6}>
									{newsArticles.length > 1 && (
										<ArticleRender isMobile index={1} />
									)}
								</Col>
							</Row>
							{newsArticles.length > 2 && (
								<Row>
									<Col xs={12} lg={6}>
										<ArticleRender isMobile index={2} />
									</Col>

									<Col xs={12} lg={6}>
										{newsArticles.length > 3 && (
											<ArticleRender isMobile index={3} />
										)}
									</Col>
								</Row>
							)}
						</Container>
					)}
				</div>
			</Col>
			<Col lg={3} style={{ paddingTop: 30 }}>
				{!IsLoading && (
					<Fragment>
						<ClaimCountWidget claimCount={claimCount} />
						{memberQuickLinks.length > 2 && (
							<QuickLinks links={memberQuickLinks} />
						)}
					</Fragment>
				)}
			</Col>
		</>
	);

	return isSmallScreen ? MobileHomeTab : HomeTab;
};

export default Home;

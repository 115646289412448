import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import MessageBar from '../MessageBar';
import { THOROUGHBRED } from '../../utils/constants';
import { TopContent } from '../Mobile';
import PrimaryButton from '../PrimaryButton';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import InfoCards from '../InfoCards';
import Programs from '../Programs';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';

const ClaimThoroughbredInfo = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const claimThoroughbredContent = useStaticQuery(
		graphql`
			query claimThoroughbredLandingPageContent {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Claim Thoroughbred Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Claim Thoroughbred Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		// Set data to state here?
		const claimThoroughbredCards =
			claimThoroughbredContent.allContentfulInfoCards.nodes;
		let claimThoroughbredInfoCards = [];
		if (claimThoroughbredCards.length > 0) {
			claimThoroughbredCards.map((d) => {
				claimThoroughbredInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			claimThoroughbredInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(claimThoroughbredInfoCards);
		}
	}, []);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <div>{children}</div>
		}
	};
	const richTextContent =
		claimThoroughbredContent?.allContentfulLandingPageContent?.nodes[0]?.body;

	const ottInformation = (isMobile) => {
		return (
			<>
				<Container
					className={!isMobile ? classes.container : classes.mobileContainer}
				>
					<HeaderBanner
						title="Claim Thoroughbred"
						styles={{ marginBottom: 0 }}
					/>
				</Container>
				{!isMobile && cards.length > 0 && (
					<InfoCards data={cards} cardStyle={{ width: 320 }} />
				)}
				{isMobile && cards.length > 1 && (
					<Programs data={cards} mobile noReadMore />
				)}
				<MessageBar>
					<div
						className={classes.messageBar}
						style={isMobile ? { display: 'block' } : {}}
					>
						<div
							className={classes.claimText}
							style={
								isMobile ? { fontSize: 13, marginBottom: 10, marginTop: 0 } : {}
							}
						>
							Ready to get started with the Claim Thoroughbred program?
						</div>
						<Link
							className={classes.buttonText}
							to={'/search'}
							state={{ searchSelected: true, search: THOROUGHBRED }}
						>
							<PrimaryButton
								style={
									isMobile
										? {
												marginTop: 0,
												width: '100%',
												height: 50,
												fontWeight: 600
										  }
										: {
												marginTop: 0,
												width: 320,
												height: 50,
												fontWeight: 600
										  }
								}
							>
								<div>Search for a Thoroughbred</div>
							</PrimaryButton>
						</Link>
					</div>
				</MessageBar>
				<Row>
					<Col lg={8}>
						<TopContent
							textLeftStyle={{ fontSize: 18 }}
							styles={{ marginTop: 20, marginBottom: 20 }}
							leftText={
								claimThoroughbredContent.allContentfulLandingPageContent
									.nodes[0].title.title
							}
						/>
						<div
							className={isMobile == true ? classes.textMobile : classes.text}
						>
							{renderRichText(richTextContent, options)}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const Component = (mobile) => {
		return (
			<div style={{ width: '100%' }}>
				<SEO title="Claim Thoroughbred" />
				<div
					className={mobile ? classes.mobileTopContainer : classes.topContainer}
				>
					{ottInformation(mobile)}
				</div>
			</div>
		);
	};

	const desktopComponent = (
		<>
			<Container className={classes.container}>
				<Row>
					<Col lg={12}>{Component(false)}</Col>
				</Row>
			</Container>
		</>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? Component(true)
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default ClaimThoroughbredInfo;

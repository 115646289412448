import { createUseStyles } from 'react-jss';
import checked from '../../../assets/images/checkboxes/checkbox-selected.svg';
import unchecked from '../../../assets/images/checkboxes/checkbox-unselected.svg';

export default createUseStyles(() => ({
	checkBox: {
		height: 20,
		width: 20,
		backgroundImage: `url(${unchecked})`,
		backgroundRepeat: 'no-repeat',
		marginRight: 10,
		'&:focus': {
			outline: 'none'
		}
	},
	selected: {
		backgroundImage: `url(${checked})`
	},
	checkBoxLabel: {
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	},
	checkBoxContainer: {
		display: 'flex',
		alignItems: 'center'
	}
}));

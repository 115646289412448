import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginBottom: 25,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 25,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	textTitle: {
		color: '#464749',
		marginBottom: 10,
		fontSize: 20,
		fontWeight: 600
	},
	textTitleMobile: {
		extend: 'textTitle',
		fontSize: 15
	},
	textDescription: {
		color: '#464749',
		marginBottom: 10,
		fontSize: 15
	},
	textDescriptionMobile: {
		extend: 'textDescription',
		fontSize: 12
	}
}));

import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { CUSTOM_MULTI_STYLES } from '../../utils/constants/index';

const components = {
	DropdownIndicator: null
};

const createOption = (label) => {
	return {
		label,
		value: label
	};
};

const MultiSelectInput = ({ id, value, updateValue, hasError }) => {
	const [inputValue, setInputValue] = useState('');

	const onChange = (value) => {
		updateValue(value);
	};

	const onInputChange = (newInputValue) => {
		setInputValue(newInputValue);
	};

	const onKeyDown = (event) => {
		if (!inputValue) {
			return;
		}
		switch (event.key) {
			case 'Enter':
			case ',':
			case 'Tab':
				setInputValue('');
				updateValue([...value, createOption(inputValue)]);
				event.preventDefault();
				break;
			default:
				break;
		}
	};

	return (
		<CreatableSelect
			id={id}
			styles={CUSTOM_MULTI_STYLES}
			components={components}
			inputValue={inputValue}
			isClearable
			isMulti
			border={hasError ? 'red' : 'hsl(0, 0%, 80%)'}
			menuIsOpen={false}
			onChange={onChange}
			onInputChange={onInputChange}
			onKeyDown={onKeyDown}
			placeholder={'Enter...'}
			value={value}
		/>
	);
};

export default MultiSelectInput;

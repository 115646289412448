import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginBottom: 50,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 50,
		marginTop: 80
	},
	scrollBar: {
		backgroundColor: '#dcebf3',
		width: '100%'
	},
	menuContainerMobile: {
		overflowX: 'scroll',
		overflow: 'hidden',
		height: 50,
		float: 'left',
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#dcebf3',
		borderRadius: 40,
		whiteSpace: 'nowrap',
		'-msOverflowStyle': 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			// remove display none to see the scrollbar
			display: 'none',
			height: '5px',
			width: 10,
			background: 'white'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#0085ca',
			height: '5px'
		},
		'&::-webkit-scrollbar-track-piece': {
			margin: 20
		},
		'& div': {
			display: 'inline-block',
			color: '#0085ca',
			fontWeight: 500,
			textAlign: 'center',
			padding: '13px 20px'
		},
		'& div:first-child': {
			marginLeft: 20
		},
		'& div:last-child': {
			marginRight: 20
		}
	},
	menuContentContainer: {
		width: '100%',
		position: 'relative'
	},
	menuContainer: {
		overflowX: 'scroll',
		overflow: 'hidden',
		height: 50,
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#dcebf3',
		borderRadius: 40,
		whiteSpace: 'nowrap',
		position: 'relative',
		float: 'left',
		'-msOverflowStyle': 'none',
		scrollbarWidth: 'none',
		'&::-webkit-scrollbar': {
			// remove display none to see the scrollbar
			display: 'none',
			height: '5px',
			width: 10,
			background: 'white'
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#0085ca',
			height: '5px'
		},
		'&::-webkit-scrollbar-track-piece': {
			margin: 20
		},
		'& div': {
			display: 'inline-block',
			color: '#0085ca',
			fontWeight: 500,
			textAlign: 'center',
			padding: '13px 20px'
		},
		'& div:first-child': {
			marginLeft: 20
		},
		'& div:last-child': {
			marginRight: 20
		}
	},
	leftScroll: {
		backgroundColor: '#dcebf3',
		marginTop: 4,
		display: 'inline-block',
		color: '#0085ca',
		fontWeight: 500,
		textAlign: 'center',
		padding: 9,
		height: 22,
		position: 'absolute',
		width: 35,
		right: 0,
		borderRadius: '0px 50px 50px 0px',
		zIndex: 98
	},
	rightScroll: {
		marginTop: 4,
		backgroundColor: '#dcebf3',
		display: 'inline-block',
		color: '#0085ca',
		fontWeight: 500,
		textAlign: 'center',
		padding: 9,
		position: 'absolute',
		width: 35,
		left: 0,
		height: 22,
		borderRadius: '50px 0px 0px 50px',
		zIndex: 98
	},
	singleResultContainer: {},
	icon: {
		float: 'left',
		marginRight: 8
	},
	resultContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 40,
		'& $singleResultContainer:nth-child(3n+2)': {
			marginLeft: 20,
			marginRight: 20
		}
	},
	resultContainerSearch: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		marginTop: 40
	},
	hr: {
		marginBottom: 40,
		background: '#dcebf3',
		height: 1,
		border: 'none'
	}
}));

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getThoroughbredReferenceData,
	getThoroughbredFormQuery,
	getThoroughbredRelationshipsTypeReferenceData,
	saveThoroughbredFormData,
	uploadThoroughbredImage,
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	deleteImagesFromS3,
	deleteThoroughbredImageMutation,
	deleteThoroughbredFormMutation
} from '../../graphql/custom.queries';
import { OTT_INFO_MAIL } from '../../utils/constants';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from '../../hooks/useToast';
import HeaderBanner from '../HeaderBanner';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import MessageBar from '../MessageBar';
import ConfirmationModal from '../ConfirmationModal';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useParams, navigate } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import SEO from '../seo';
import FormTextArea from '../FormTextArea';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const ThoroughbredRegistration = () => {
	const classes = useStyles();
	let { id } = useParams();
	const { addToast } = useToast();
	const { errors, control } = useForm({
		mode: 'onSubmit'
	});
	const notesSize = 700;
	const [guid, setGuid] = useState('');
	const [referenceListCountries, setReferenceListCountries] = useState([]);
	const [referenceListColours, setReferenceListColours] = useState([]);
	const [referenceListGenders, setReferenceListGenders] = useState([]);
	const [referenceRelationshipTypes, setReferenceRelationshipTypes] = useState(
		[]
	);
	const [selectedRelationshipType, setSelectedRelationshipType] = useState(1);
	const [horseName, setHorseName] = useState('');
	const [organisation, setOrganisation] = useState('');
	const [horseGender, setHorseGender] = useState(null);
	const [colour, setColour] = useState(null);
	const [country, setCountry] = useState(null);
	const [microchip, setMicrochip] = useState('');
	const [damName, setDamName] = useState('');
	const [sireName, setSireName] = useState('');
	const [foalYear, setFoalYear] = useState(null);
	const [horseAge, setHorseAge] = useState(null);
	const [brandLeftImages, setBrandLeftImages] = useState([]);
	const [brandRightImages, setBrandRightImages] = useState([]);
	const [markings, setMarkings] = useState([]);
	const [notes, setNotes] = useState('');
	const [notesRemaining, setNotesRemaining] = useState(notesSize);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);

	const fetchThoroughbredForm = async () => {
		try {
			const result = await AppSyncService.execute(getThoroughbredFormQuery, {
				guid: id
			});
			if (result?.data?.getThoroughbredForm) {
				setSelectedRelationshipType(
					result.data.getThoroughbredForm.relationship.id
				);
				setOrganisation(result.data.getThoroughbredForm.organisation);
				setHorseName(result.data.getThoroughbredForm.horseName);
				setHorseAge(result.data.getThoroughbredForm.horseAge);
				setHorseGender(result.data.getThoroughbredForm.sex);
				setColour(result.data.getThoroughbredForm.colour);
				setCountry(result.data.getThoroughbredForm.country);
				setMicrochip(result.data.getThoroughbredForm.microchip);
				setSireName(result.data.getThoroughbredForm.sireName);
				setDamName(result.data.getThoroughbredForm.damName);
				setFoalYear(result.data.getThoroughbredForm.foalYear);
				setNotes(result.data.getThoroughbredForm.notes);
				setBrandLeftImages(result.data.getThoroughbredForm.brandLeftImages);
				setBrandRightImages(result.data.getThoroughbredForm.brandRightImages);
				setMarkings(result.data.getThoroughbredForm.markings);
				if (result.data.getThoroughbredForm.status === 'Review') {
					setIsSubmitted(true);
				}
			}
		} catch (error) {
			addToast({
				Message: 'Unable to get thoroughbred Form ',
				IsSuccess: false
			});
		}
	};

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getThoroughbredReferenceData).then((result) => {
			if (result.data) {
				let modifiedCountriesArray = result.data.listCountries;
				modifiedCountriesArray.unshift('');
				setReferenceListCountries(modifiedCountriesArray.sort());

				let modifiedListHorseColours = result.data.listHorseColours;
				modifiedListHorseColours.unshift('');
				setReferenceListColours(modifiedListHorseColours.sort());

				let modifiedListHorseGenders = result.data.listHorseGenders;
				modifiedListHorseGenders.unshift('');
				setReferenceListGenders(modifiedListHorseGenders.sort());
			}
		});

		AppSyncService.execute(getThoroughbredRelationshipsTypeReferenceData).then(
			(result) => {
				if (result.data) {
					setReferenceRelationshipTypes(
						result.data.listThoroughbredRelationships
					);
				}
			}
		);
		setIsLoading(false);
	}, []);
	useEffect(() => {
		setIsLoading(true);
		if (id == null || id == undefined || id == '') {
			setGuid(uuidv4()); //new record
		} else {
			setGuid(id);
			fetchThoroughbredForm();
		}
		setIsLoading(false);
	}, [id]);

	useEffect(() => {
		if (
			horseName != null &&
			horseName.trim() != '' &&
			horseAge != null &&
			horseAge != '' &&
			horseGender != 0 &&
			brandLeftImages.length > 0 &&
			brandRightImages.length > 0 &&
			(selectedRelationshipType == 6
				? organisation != null && organisation.trim() != ''
					? true
					: false
				: true)
		) {
			setIsSubmitDisabled(false);
		} else {
			setIsSubmitDisabled(true);
		}
	}, [
		selectedRelationshipType,
		horseName,
		horseAge,
		horseGender,
		brandLeftImages,
		brandRightImages,
		organisation
	]);

	const updateRequest = () => {
		setBackendErrorMessage(null);
		if (horseName != null && horseName.trim() != '') {
			try {
				const ThoroughbredFormInputData = {
					guid: guid,
					relationshipId: selectedRelationshipType,
					organisation: organisation,
					horseName: horseName.trim(),
					horseAge: horseAge,
					sex: horseGender,
					colour: colour,
					country: country,
					microchip: microchip === '' ? null : microchip,
					sireName: sireName === '' ? null : sireName,
					damName: damName === '' ? null : damName,
					foalYear: foalYear === '' ? null : foalYear,
					notes: notes === '' ? null : notes,
					submitForm: false
				};
				AppSyncService.execute(saveThoroughbredFormData, {
					ThoroughbredFormInputData
				}).then((data) => {
					if (data?.data != null) {
						addToast({ Message: 'Draft saved', IsSuccess: true });
						navigateToDashboard();
					}
				});
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving draft Find Your Thoroughbred Request data. Please contact support'
					);
				}
			}
		} else {
			setBackendErrorMessage('Please enter atleast Horse name to save Draft');
		}
	};

	const completeRequest = () => {
		setBackendErrorMessage(null);
		try {
			const ThoroughbredFormInputData = {
				guid: guid,
				relationshipId: selectedRelationshipType,
				organisation: organisation,
				horseName: horseName.trim(),
				horseAge: horseAge,
				sex: horseGender,
				colour: colour,
				country: country,
				microchip: microchip === '' ? null : microchip,
				sireName: sireName === '' ? null : sireName,
				damName: damName === '' ? null : damName,
				foalYear: foalYear === '' ? null : foalYear,
				notes: notes === '' ? null : notes,
				submitForm: true
			};
			AppSyncService.execute(saveThoroughbredFormData, {
				ThoroughbredFormInputData
			}).then((data) => {
				if (data.data != null) {
					addToast({ Message: 'Request completed', IsSuccess: true });
					navigateToDashboard();
				}
			});
		} catch (error) {
			if (error) {
				setBackendErrorMessage(error);
			} else {
				setBackendErrorMessage(
					'Unknown error while saving Find Your Thoroughbred Request data. Please contact support'
				);
			}
		}
	};

	const cancelRequest = () => {
		if (id == null || id == undefined || id == '') {
			navigateToDashboard();
		}
		let listOfKeys = [];
		if (brandLeftImages.length > 0) {
			brandLeftImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (brandRightImages.length > 0) {
			brandRightImages.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (markings.length > 0) {
			markings.map((d) => {
				listOfKeys.push(d.key);
			});
		}
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					removeThoroughtbredRegistration();
				}
			});
		} else {
			removeThoroughtbredRegistration();
		}
	};

	const removeThoroughtbredRegistration = () => {
		AppSyncService.execute(deleteThoroughbredFormMutation, { guid: id }).then(
			(data) => {
				if (data.data != null) {
					setIsCancelled(true);
					addToast({ Message: 'Request cancelled', IsSuccess: true });
					navigateToDashboard();
				} else {
					setBackendErrorMessage(
						'Unknown error while cancelling Find Your Thoroughbred Request data. Please contact support'
					);
				}
			}
		);
	};

	const removeMarkingSideImage = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteThoroughbredImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteThoroughbredImage) {
							let dataList = Object.assign([], markings);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setMarkings(newDataList);
						}
					});
				}
			}
		);
	};

	const removeBrandLeftImage = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteThoroughbredImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteThoroughbredImage) {
							let dataList = Object.assign([], brandLeftImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setBrandLeftImages(newDataList);
						}
					});
				}
			}
		);
	};

	const removeBrandRightImage = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteThoroughbredImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteThoroughbredImage) {
							let dataList = Object.assign([], brandRightImages);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setBrandRightImages(newDataList);
						}
					});
				}
			}
		);
	};

	const updateRelationshipType = (value) => {
		if (value != 6) {
			setOrganisation('');
		}
		setSelectedRelationshipType(value);
	};

	const updateMicrochipNumber = (value) => {
		if (value.match(/^\d*$/) && value.toString().length <= 18) {
			setMicrochip(value);
		}
	};

	const updateFoalYear = (value) => {
		if (value.match(/^\d*$/) && value.toString().length <= 4) {
			setFoalYear(value);
		}
	};

	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const uploadError = () => {
		setShowImageUploadErrorModal(true);
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const brandLeftPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {
							filename: image.name,
							guid: guid,
							imageSection: 'brandLeft',
							key: imageKey
						};
						AppSyncService.execute(uploadThoroughbredImage, { saveImage }).then(
							() => {
								let dataList = Object.assign([], brandLeftImages);
								dataList.push({ filename: image.name, key: imageKey });
								setBrandLeftImages(dataList);
							}
						);
					});
			}
		});
	};

	const brandRightPhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {
							filename: image.name,
							guid: guid,
							imageSection: 'brandRight',
							key: imageKey
						};
						AppSyncService.execute(uploadThoroughbredImage, { saveImage }).then(
							() => {
								let dataList = Object.assign([], brandRightImages);
								dataList.push({ filename: image.name, key: imageKey });
								setBrandRightImages(dataList);
							}
						);
					});
			}
		});
	};

	const markingSidePhotoUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						let saveImage = {
							filename: image.name,
							guid: guid,
							imageSection: 'markings',
							key: imageKey
						};
						AppSyncService.execute(uploadThoroughbredImage, { saveImage }).then(
							() => {
								let dataList = Object.assign([], markings);
								dataList.push({ filename: image.name, key: imageKey });
								setMarkings(dataList);
							}
						);
					});
			}
		});
	};

	const navigateToDashboard = () => {
		navigate(DASHBOARD_ROUTE);
	};

	const setNotesData = (value) => {
		setNotesRemaining(notesSize - value.length);
		setNotes(value);
	};

	const updateHorseAge = (age) => {
		const regex = RegExp(/^\d*$/);
		if (regex.test(age) && age.toString().length <= 2) {
			setHorseAge(age);
		}
	};

	return (
		<Container className={classes.pageContainer}>
			<SEO title="Find Your Thoroughbred Request" />
			<HeaderBanner
				title="Find Your Thoroughbred Request"
				top
				type="primary"
				styles={{ marginBottom: '0px', borderRadius: '5px 5px 0px 0px' }}
			/>
			<MessageBar>
				A minimim of 4 fields and 2 photos is required. This process can take
				time & EW might need to contact you for more information. If you are
				unable to complete this information please email your request to{' '}
				<a href={'mailto:' + OTT_INFO_MAIL}>{OTT_INFO_MAIL}</a>
			</MessageBar>
			<HeaderBanner
				title="Thoroughbred Relationship"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '30px' }}
			/>
			<div className={classes.formContainer}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} md={4} sm={12} className={classes.sectionContainer}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>I am a*</div>
								<Controller
									control={control}
									name="relationshipType"
									render={({ onChange }) => (
										<FormDropDown
											items={referenceRelationshipTypes}
											selectedId={selectedRelationshipType}
											onChange={(e) => {
												onChange(e.target.value);
												updateRelationshipType(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.selectedRelationshipType?.message}
								</span>
							</div>
						</Col>
						{selectedRelationshipType == 6 && (
							<Col lg={4} md={4} sm={12}>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>Organisation Name*</div>
									<Controller
										control={control}
										name="organisation"
										render={({ onChange }) => (
											<FormTextField
												value={organisation}
												onChange={(e) => {
													onChange(e.target.value);
													setOrganisation(e.target.value);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.organisation?.message}
									</span>
								</div>
							</Col>
						)}
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Details"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Horse Name*</div>
								<Controller
									control={control}
									name="horseName"
									render={({ onChange }) => (
										<FormTextField
											value={horseName}
											onChange={(e) => {
												onChange(e.target.value);
												setHorseName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.horseName?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Age*</div>
								<Controller
									control={control}
									name="horseAge"
									render={({ onChange }) => (
										<FormTextField
											value={horseAge}
											onChange={(e) => {
												onChange(e.target.value);
												updateHorseAge(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.horseAge?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sex*</div>
								<FormDropDown
									name="horseGender"
									selectedId={horseGender}
									items={referenceListGenders.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setHorseGender(e.target.value);
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{errors.horseGender?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Colour</div>
								<FormDropDown
									name="colour"
									selectedId={colour}
									items={referenceListColours.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setColour(e.target.value);
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{errors.colour?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Country of Birth</div>
								<FormDropDown
									name="country"
									selectedId={country}
									items={referenceListCountries.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setCountry(e.target.value);
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{errors.country?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Microchip Number</div>
								<Controller
									control={control}
									name="microchip"
									render={({ onChange }) => (
										<FormTextField
											value={microchip}
											onChange={(e) => {
												onChange(e.target.value);
												updateMicrochipNumber(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.microchip?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sire Name</div>
								<Controller
									control={control}
									name="sireName"
									render={({ onChange }) => (
										<FormTextField
											value={sireName}
											onChange={(e) => {
												onChange(e.target.value);
												setSireName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.sireName?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Dam Name</div>
								<Controller
									control={control}
									name="damName"
									render={({ onChange }) => (
										<FormTextField
											value={damName}
											onChange={(e) => {
												onChange(e.target.value);
												setDamName(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.damName?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Foal Year</div>
								<Controller
									control={control}
									name="foalYear"
									render={({ onChange }) => (
										<FormTextField
											value={foalYear}
											onChange={(e) => {
												onChange(e.target.value);
												updateFoalYear(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.foalYear?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Additional Information
									</span>
									<span className={classes.charLengthInfo}>
										{notesRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="notes"
									render={({ onChange }) => (
										<FormTextArea
											value={notes}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setNotesData(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.notes?.message}
								</span>
							</div>
						</Col>
					</Row>
				</div>
				<hr className={classes.dividerLine}></hr>
				<div className={classes.sectionContainer}>
					<Row>
						<Col>
							<MessageBar type="primary" style={{ marginTop: 10 }}>
								To meet the requirements, please provide{' '}
								<b>at least 2 photos</b>
								<br />
								<br />
								<a
									href={'/photo-upload-guide'}
									target="_blank"
									rel="noreferrer"
									className={classes.mailLink}
								>
									<b>
										Need help with photo requirements? Click here to view
										examples of suitable photos of your thoroughbred
									</b>
								</a>
							</MessageBar>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand Photo - Left (jpg,png)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg']}
									labelName="Upload Brand Photo (Left)"
									error={uploadError}
									data={brandLeftImages}
									sizeLimitMB={5}
									numberOfImages={4}
									success={brandLeftPhotoUpload}
									getImage={getImage}
									removeImage={removeBrandLeftImage}
								/>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand Photo - Right (jpg,png)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg']}
									labelName="Upload Brand Photo (Right)"
									error={uploadError}
									data={brandRightImages}
									sizeLimitMB={5}
									numberOfImages={4}
									success={brandRightPhotoUpload}
									getImage={getImage}
									removeImage={removeBrandRightImage}
								/>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Markings Photo - Side (jpg,png)
								</div>
								<UploadPhoto
									formats={['png', 'jpeg']}
									labelName="Upload Markings Photo (Side)"
									error={uploadError}
									data={markings}
									sizeLimitMB={5}
									numberOfImages={4}
									success={markingSidePhotoUpload}
									getImage={getImage}
									removeImage={removeMarkingSideImage}
								/>
							</div>
						</Col>
					</Row>
				</div>
				<hr className={classes.dividerLine}></hr>
				<div className={classes.buttonContainer}>
					<div
						className={classes.cancelRequest}
						onClick={() => setConfirmationModal(true)}
						style={isCancelled ? { display: 'none' } : {}}
					>
						Cancel
					</div>
					<div className={classes.saveRequest}>
						{backendErrorMessage && (
							<span className={classes.errorMessage}>
								{backendErrorMessage}
							</span>
						)}
						<span
							className={classes.saveButton}
							onClick={() => updateRequest()}
							style={isSubmitted || isCancelled ? { display: 'none' } : {}}
						>
							Save Draft
						</span>
						<span>
							<input
								type="button"
								disabled={isSubmitDisabled || isSubmitted ? true : false}
								value="Complete Request"
								className={
									!isSubmitDisabled && !isSubmitted
										? classes.completeFormButton
										: classes.disabledCompleteFormButton
								}
								onClick={() => completeRequest()}
							/>
						</span>
					</div>
				</div>
			</div>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
			/>
			<ConfirmationModal
				showModal={confirmationModal}
				closeModal={() => setConfirmationModal(false)}
				styles={modalStylesConfirmation}
				heading="Cancel Find Your Thoroughbred Form"
				positiveLabel="Confirm"
				negativeLabel="Cancel"
				onClickPositive={() => cancelRequest()}
			>
				Are you sure you wish to delete this form?
			</ConfirmationModal>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</Container>
	);
};

export default ThoroughbredRegistration;

import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  container: {
    fontSize: 15,
    padding: "15px 20px",
  },
  info: {
    background: "#fcf7da",
  },
  primary: {
    background: "#dcebf3",
  },
  message: {
    background: "#f5f5f5"
  }
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	menuItem: {
		display: 'flex',
		padding: 6,
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		borderRadius: 50,
		color: '#006da6',
		fontSize: 14,
		width: 300,
		height: 30,
		alignItems: 'center',
		justifyContent: 'center',
		'@media (min-width:992px)': {
			display: 'inline',
			padding: 10,
			fontSize: 15
		}
	},
	selectedMenuItem: {
		background: '#006da6',
		color: 'white',
		fontWeight: 600
	}
});

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	messageBar: {
		marginTop: 20,
		borderRadius: 3
	},
	moreInfoParagraph: {
		fontSize: 15,
		color: '#464749'
	}
});

import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useStaticQuery, graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import SEO from '../seo';
import { Container, ScreenClassRender } from 'react-grid-system';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';
import HeaderBanner from '../HeaderBanner';

const OHEPPrivacyPolicy = () => {
	const classes = useStyles();
	const [richText, setRichText] = useState(null);
	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};
	const contentfulOHEPPrivacyPolicyContent = useStaticQuery(
		graphql`
			query OHEPPrivacyPolicyQuery {
				allContentfulOhepPrivacyPolicyContent {
					edges {
						node {
							body {
								raw
								references {
									__typename
									... on ContentfulAsset {
										contentful_id
										file {
											url
											contentType
											fileName
										}
									}
									title
								}
							}
						}
					}
				}
			}
		`
	);
	useEffect(() => {
		setRichText(
			contentfulOHEPPrivacyPolicyContent.allContentfulOhepPrivacyPolicyContent
				.edges[0].node.body
		);
	});

	const mobileComponent = (
		<div style={{ width: '100%' }}>
			<SEO title="OHEP Privacy Policy" />
			<div className={classes.mobileTopContainer}>
				{richText && renderRichText(richText, options)}
			</div>
		</div>
	);
	const desktopComponent = (
		<>
			<div>
				<SEO title="OHEP Privacy Policy" />
				<Container className={classes.desktopContainer}>
					<HeaderBanner
						title="OHEP Privacy Policy"
						className={classes.desktopBannerStyles}
					/>
					{richText && renderRichText(richText, options)}
				</Container>
			</div>
		</>
	);
	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default OHEPPrivacyPolicy;

const Reducer = (state, action) => {
	switch (action.type) {
		case 'SET_HEADER_TITLE':
			return {
				...state,
				headerTitle: action.payload
			};
		default:
			return state;
	}
};

export default Reducer;

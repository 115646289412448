import React from 'react';
import useStyles from './styles';

const UploadFileLink = ({ file, onPreview, onRemove }) => {
	const classes = useStyles();
	return (
		<div>
			<span className={classes.uploadedFile} onClick={onPreview}>
				{file.name}
			</span>
			<span className={classes.clearUpload} onClick={onRemove}>
				&#10006;
			</span>
		</div>
	);
};

export default UploadFileLink;

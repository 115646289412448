import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'block',
		position: 'relative',
		fontSize: 22,
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		padding: 20
	},
	link: {
		color: '#006da6',
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	fieldContainer: {
		paddingLeft: 15,
		paddingRight: 15,
		paddingTop: 15,
		paddingBottom: 10
	},
	profileLink: {
		extend: 'link',
		marginLeft: 20,
		fontSize: 15
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14
	},
	saveContainer: {
		float: 'right',
		marginRight: 15,
		marginBottom: 15
	},
	primaryFormButton: {
		marginLeft: 20,
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 200,
		fontSize: 15,
		cursor: 'pointer',
		'&:focus': {
			outline: 'none'
		}
	},
	primaryFormButtonComplete: {
		marginLeft: 20,
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 160,
		fontSize: 15,
		cursor: 'pointer',
		'&:focus': {
			outline: 'none'
		}
	},
	disabledFormButtonComplete: {
		marginLeft: 20,
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 160,
		fontSize: 15,
		'&:focus': {
			outline: 'none'
		}
	},
	rule: {
		marginLeft: 20,
		marginRight: 20,
		color: '#b2b2b2'
	},
	disabledFormButton: {
		marginLeft: 20,
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 200,
		fontSize: 15,
		'&:focus': {
			outline: 'none'
		}
	}
}));

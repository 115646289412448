import React from 'react';
import TextInputField from '../../../FormComponents/TextInputField';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../../../utils/helpers';
const COMPONENT_ID_PREFIX = 'horse-info-mini-form';

const HorseInformationMiniForm = ({ formState, handleFormStateChange }) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	return (
		<div className={classes.formContainer}>
			<TextInputField
				id={`${COMPONENT_ID_PREFIX}-horse-name-input`}
				name="horseName"
				label="Horse name/breeding (if unnamed)"
				value={formState.horseName}
				onChange={(e) => handleFormStateChange(e.target.name, e.target.value)}
				isRequired
				fullWidth
				styles={{ marginBottom: isSmallScreen ? 5 : 10 }}
			/>
			<TextInputField
				id={`${COMPONENT_ID_PREFIX}-horse-concerns-input`}
				name="concerns"
				label="Potential concerns with horse (injury, illness, soundness or behaviour)"
				value={formState.concerns}
				onChange={(e) => handleFormStateChange(e.target.name, e.target.value)}
				fullWidth
			/>
		</div>
	);
};

export default HorseInformationMiniForm;

import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import { reviewHorseResetInitialForm } from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { getImage } from './utils';
import { formatDateReadableVariant } from '../../utils/helpers';

const ResetInitialFormReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);

	useEffect(() => {
		setRejectReason(data.reviewMessages === null ? '' : data.reviewMessages);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewHorseResetInitialForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewMessages: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveResetInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewHorseResetInitialForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveResetInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="RESET Initial Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date of assessment*"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.dateOfAssessment)
									: ''
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastUpdated
									? formatDateReadableVariant(data.lastUpdated)
									: ''
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Week Number" value={data.weekId} />
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>Left side photo/s</div>
							<UploadPhoto
								data={data.leftSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>Right side photo/s</div>
							<UploadPhoto
								data={data.rightSidePhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Describe overall condition (body condition, feet condition, note any superficial wounds/abrasions)*"
							value={data.overallCondition}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Worming"
							value={data.worming ? 'Yes' : 'No'}
						/>
						{data.worming && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.worming)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Farrier"
							value={data.farrier ? 'Yes' : 'No'}
						/>
						{data.farrier && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.farrier)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Dentist"
							value={data.dentist ? 'Yes' : 'No'}
						/>
						{data.dentist && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.dentist)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Other Husbandry Information"
							value={data.otherHusbandryInfo}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Assessment re feeding requirements/plan (feeding to maintain or increase condition, feeding to let down etc)"
							value={data.feedingRequirementsPlan}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Assessment re temperament/behaviour (nervous/relaxed/aggressive/sensitive)"
							value={data.temperament}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Groundwork/Ridden Assessment (Describe your sessions this week, what has been done, what went well and what requires work etc.)"
							value={data.groundwork}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Your initial thoughts on possible repurposing / use?"
							value={data.possibleRepurposing}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Your initial thoughts on retraining timeframe."
							value={data.retrainingTimeframe}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Is there a physical or behavioural reason the horse should not continue in RESET?"
							value={data.notContinueDetails ? 'Yes' : 'No'}
						/>
						{data.notContinueDetails && (
							<FormLabelField
								title="If yes, please explain"
								value={data.notContinueDetails}
							/>
						)}
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.horse?.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.horse?.colour} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${data.member?.firstName} ${data.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.member?.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.member.phone} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Initial Assessment Photos"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ResetInitialFormReviewInfo;

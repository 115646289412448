import { createUseStyles } from 'react-jss';

export default createUseStyles({
	loadingPlaceholder: {
		width: '100%',
		padding: 10,
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
});

import React from 'react';
import { FormFieldMobile } from '../Mobile';
import FormTextField from '../FormTextField';
import useStyles from './styles';
import FormDatePicker from '../FormDatePicker';
import FormDropDown from '../FormDropDown';
import FormToolTip from '../FormToolTip';

const fieldType = Object.freeze({
	text: 'text',
	dropdown: 'dropdown',
	date: 'date'
});

const HorseProfileField = ({
	field,
	value,
	styles,
	noline,
	isRacing,
	showToolTip,
	mobile,
	valueStyle,
	canEdit,
	onChange,
	editValue,
	type = fieldType.text,
	dropdownOptions,
	showError,
	fieldStyle
}) => {
	const classes = useStyles();

	const renderFields = () => {
		if (mobile) {
			return (
				<div className={classes.container} style={styles}>
					<div className={classes.fieldContainerMobile}>
						<div className={classes.field} style={fieldStyle}>
							{field}{' '}
							{showToolTip && !canEdit && !isRacing && (
								<FormToolTip toolTipContainerStyle={{ width: 170, left: 40 }}>
									This is only visible to you and the RV Equine Welfare team.
								</FormToolTip>
							)}
							{showToolTip && !canEdit && isRacing && (
								<FormToolTip
									toolTipContainerStyle={{ width: 170, left: 40 }}
									type="questionMark"
								>
									This is the last known Racing Status of this horse, if you
									have an update please use Share Information form.
								</FormToolTip>
							)}
						</div>
						{!canEdit && (
							<div className={classes.value} style={valueStyle}>
								{value}
							</div>
						)}
					</div>
					{canEdit && type === fieldType.dropdown && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							<FormDropDown
								onChange={onChange}
								selectedId={editValue}
								items={dropdownOptions}
								showBlank
							/>
						</div>
					)}
					{canEdit && type === fieldType.text && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							<FormFieldMobile onChange={onChange} value={editValue} />
						</div>
					)}
					{canEdit && type === fieldType.date && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							{' '}
							<FormDatePicker
								styles={{ width: '100%', color: 'blue' }}
								customDatePicker
								DateValue={editValue}
								isProfile
								updateSelection={(value) => onChange(value)}
							/>
						</div>
					)}
					{!noline && <hr className={classes.hrLine}></hr>}
				</div>
			);
		} else {
			return (
				<div className={classes.container} style={styles}>
					<div className={classes.fieldContainer}>
						<div className={classes.field} style={fieldStyle}>
							{field}
							{showToolTip && !canEdit && isRacing && (
								<FormToolTip
									toolTipContainerStyle={{ width: 400, left: 40 }}
									type="questionMark"
								>
									This is the last known Racing Status of this horse, if you
									have an update please use Share Information form.
								</FormToolTip>
							)}
						</div>
						{!canEdit && (
							<div className={classes.value} style={valueStyle}>
								{value}
							</div>
						)}
					</div>
					{canEdit && type === fieldType.dropdown && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							<FormDropDown
								onChange={onChange}
								selectedId={editValue}
								items={dropdownOptions}
							/>
						</div>
					)}
					{canEdit && type === fieldType.text && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							<FormTextField onChange={onChange} value={editValue} />
						</div>
					)}
					{canEdit && type === fieldType.date && (
						<div
							style={{
								marginTop: 5,
								border: showError ? '1px solid red' : '',
								borderRadius: showError ? '5px' : ''
							}}
						>
							{' '}
							<FormDatePicker
								styles={{ width: '100%', color: 'blue' }}
								customDatePicker
								isProfile
								DateValue={editValue}
								updateSelection={(value) => onChange(value)}
							/>
						</div>
					)}
					{!noline && <hr className={classes.hrLine}></hr>}
				</div>
			);
		}
	};
	return renderFields();
};

export default HorseProfileField;

import React from 'react';
import useStyles from './styles';
import { Row } from 'react-grid-system';

const SectionRow = ({ children, ...rest }) => {
	const classes = useStyles();
	return (
		<Row className={classes.sectionRow} {...rest}>
			{children}
		</Row>
	);
};

export default SectionRow;

import React, { useEffect, useState } from 'react';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import HeaderBanner from '../HeaderBanner';
import DataTable from '../DataTable';
import AppSyncService from '../../graphql/AppSyncService';
import FormMultiSelectList from '../FormMultiSelectList';
import { formatDateReadableVariant } from '../../utils/helpers';
import useStyles from './styles';
import {
	getAcknowledgedRetirementHorses,
	listAckRetirementNewStatusTypes,
	getRetirementForms
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import HorseManagementDataRow from '../HorseManagementDataRow';
import { getRetirementAssessmentFormLatestUpdated } from './queries';
import { convertToMultiListData } from '../../utils/helpers';
import {
	ACKNOWLEDGED_RETIREMENT,
	OTT_INFO_MAIL,
	DRAFT
} from '../../utils/constants';
import LoaderSpinner from '../LoaderSpinner';

const tableHeadings = [
	'',
	'Name',
	'Date Stabled',
	'Status',
	'As of Date',
	'Form Select',
	'Last Report Submitted'
];

const mobileHeadings = null;

const AcknowledgedRetirementManagement = ({ isMobile }) => {
	const classes = useStyles();

	const [
		acknowledgedRetirementHorses,
		setAcknowledgedRetirementHorses
	] = useState([]);
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const [originalHorseList, setOriginalHorseList] = useState([]);
	const [hasHorseInOriginalList, setHasHorseInOriginalList] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState([]);
	const [currentSortDirection, setCurrentSortDirection] = useState(null);
	const [statusOptions, setStatusOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [retirementDraftFormsCount, setRetirementDraftFormsCount] = useState(0);
	const [latestFormSubmittedDate, setLatestFormSubmittedDate] = useState(null);

	useEffect(() => {
		setIsLoading(true);
		const memberExternalId = { memberExternalId: currentUser?.UserId };
		AppSyncService.execute(listAckRetirementNewStatusTypes).then((data) => {
			if (data?.data?.listAckRetirementNewStatusTypes) {
				setStatusOptions(
					convertToMultiListData(
						data.data.listAckRetirementNewStatusTypes.filter(
							(item) => item.id !== 3
						),
						'value'
					)
				);
			}
		});
		AppSyncService.execute(
			getAcknowledgedRetirementHorses,
			memberExternalId
		).then((data) => {
			if (data?.data?.getAcknowledgedRetirementHorses) {
				setAcknowledgedRetirementHorses(
					data.data.getAcknowledgedRetirementHorses
				);
				const allHorses = JSON.parse(
					JSON.stringify(data.data.getAcknowledgedRetirementHorses)
				);
				setOriginalHorseList(allHorses);
				setHasHorseInOriginalList(
					data.data.getAcknowledgedRetirementHorses.length > 0 ? true : false
				);
			}
			setIsLoading(false);
		});
	}, []);

	const NoRetiredHorsesInfoBarText = () => {
		return (
			<>
				<div>
					You do not have any claimed thoroughbreds which are assigned to the
					Acknowledged Retirement Program.
				</div>
				<div>
					If this is incorrect, please ensure you have claimed the thoroughbreds
					first, then email{' '}
					<a href={'mailto:' + OTT_INFO_MAIL}>{OTT_INFO_MAIL}</a> with the names
					to have the Program added.
				</div>
			</>
		);
	};

	useEffect(() => {
		buildRows();
	}, [acknowledgedRetirementHorses]);

	const reloadReportNumbers = () => {
		AppSyncService.execute(getRetirementForms, {
			memberExternalId: currentUser?.UserId,
			status: [DRAFT]
		}).then((data) => {
			if (
				data?.data?.getRetirementInitialAssessmentForms?.length > 0 ||
				data?.data?.getRetirementQuarterlyAssessmentForms?.length > 0
			) {
				setRetirementDraftFormsCount(
					data?.data?.getRetirementInitialAssessmentForms?.length +
						data?.data?.getRetirementQuarterlyAssessmentForms?.length
				);
			}
		});
	};

	const reloadReportLastSubmittedDate = () => {
		AppSyncService.execute(getRetirementAssessmentFormLatestUpdated, {
			memberExternalId: currentUser?.UserId
		}).then((data) => {
			if (
				data?.data?.getRetirementQuarterlyAssessmentForms[0]?.lastUpdated ||
				data?.data?.getRetirementInitialAssessmentForms[0]?.lastUpdated
			) {
				setLatestFormSubmittedDate(
					formatDateReadableVariant(
						data?.data?.getRetirementQuarterlyAssessmentForms[0]?.lastUpdated
					) >
						formatDateReadableVariant(
							data?.data?.getRetirementInitialAssessmentForms[0]?.lastUpdated
						)
						? formatDateReadableVariant(
								data?.data?.getRetirementQuarterlyAssessmentForms[0]
									?.lastUpdated
						  )
						: formatDateReadableVariant(
								data?.data?.getRetirementInitialAssessmentForms[0]?.lastUpdated
						  )
				);
			} else {
				setLatestFormSubmittedDate('never');
			}
		});
	};

	useEffect(() => {
		reloadReportLastSubmittedDate();
		reloadReportNumbers();
	}, [currentUser]);

	const buildRows = () => {
		const numRows = acknowledgedRetirementHorses.length;
		const constructedRows = acknowledgedRetirementHorses.map(
			(acknowledgedRetirementHorse, index) => {
				return (
					<HorseManagementDataRow
						horseData={acknowledgedRetirementHorse}
						key={index}
						index={index}
						isLastRow={numRows === index + 1}
						isMobile={isMobile}
						reload={reloadReportNumbers}
						program={ACKNOWLEDGED_RETIREMENT}
					/>
				);
			}
		);
		setRows(constructedRows);
	};

	const sortRowsDown = (horseList) => {
		setCurrentSortDirection(1);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.horse?.ottInformation?.paddockName ?? horse1?.horseName
				).localeCompare(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = (horseList) => {
		setCurrentSortDirection(2);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = acknowledgedRetirementHorses;
		}
		setAcknowledgedRetirementHorses(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.horse?.ottInformation?.paddockName ?? horse2?.horseName
				).localeCompare(
					horse1?.horse?.ottInformation?.paddockName ?? horse1.horseName
				)
			)
		);
		buildRows();
	};

	const updateHorseList = (value) => {
		setSelectedStatus(value);
		let tempList = Object.assign([], originalHorseList);
		let array = [];
		if (value.length > 0) {
			value.map((flist) => {
				let filter = flist.label;
				tempList.map((d) => {
					if (d?.currentStatus?.value === filter) {
						array.push(d);
					}
				});
			});
			if (currentSortDirection === 1) {
				sortRowsDown(array);
			} else if (currentSortDirection === 2) {
				sortRowsUp(array);
			} else {
				setAcknowledgedRetirementHorses(array);
			}
		} else {
			if (currentSortDirection === 1) {
				sortRowsDown(tempList);
			} else if (currentSortDirection === 2) {
				sortRowsUp(tempList);
			} else {
				setAcknowledgedRetirementHorses(tempList);
			}
		}
	};

	const SubmitInfoBar = () => {
		const infoBarText = latestFormSubmittedDate
			? `Last submitted Acknowledged Retirement Program report was ${latestFormSubmittedDate}. \nReports are required quarterly, we will send you a reminder two weeks before the next report is due.`
			: 'Please complete your report(s) for all Acknowledged Retirement Farm horse(s) and Save each form. When you are ready to submit for review, click Submit Report.';
		return (
			<InfoBar
				infoText={hasHorseInOriginalList && infoBarText}
				buttonText="Submit Report"
				noButton
				isDisabled={retirementDraftFormsCount === 0}
				type={MessageBarTypeEnum.message}
			>
				{!hasHorseInOriginalList && <NoRetiredHorsesInfoBarText />}
			</InfoBar>
		);
	};

	return (
		<div>
			<SubmitInfoBar />
			<HeaderBanner
				title={
					'Acknowledged Retirement Program Thoroughbreds (' +
					acknowledgedRetirementHorses.length +
					')'
				}
				top
				styles={{ marginBottom: '0px', marginTop: '20px' }}
			/>
			{!isMobile && (
				<div className={classes.filterContainer}>
					<div className={classes.filterTitle}>Filter by</div>
					<div className={classes.filterComponentContainer}>
						<FormMultiSelectList
							name="filterStatus"
							listOptions={statusOptions}
							selectedIds={selectedStatus}
							onSelectChange={updateHorseList}
						/>
					</div>
				</div>
			)}
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
				hasHorseInOriginalList={hasHorseInOriginalList}
				program={'Retirement'}
				emptyMessage={'No thoroughbreds in the Acknowledged Retirement Program'}
			/>
			<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
			<div style={{ marginBottom: '0px', marginTop: '20px' }} />
			<SubmitInfoBar />
		</div>
	);
};

export default AcknowledgedRetirementManagement;

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatToggleValue } from '../../../../utils/helpers';
import useStyles from './styles';
import { RETRAINER } from '../../../RetrainerProfileTemplate/constants';

const DeclarationsSection = ({ formType, formData }) => {
	const classes = useStyles();
	return (
		<AdminReviewSection title="Declarations">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Have you ever been the subject of an animal welfare or animal cruelty investigation by RSPCA or any other organisation?"
						value={formatToggleValue(formData?.hadRSPCAInvestigation)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.rspcaInvestigationDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.rspcaInvestigationDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="Are you currently under any ban/restriction imposed by any Racing Authority (including Racing Victoria), either in Australia or overseas?"
						value={formatToggleValue(formData?.isBannedByAnyRacingAuthority)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.bannedByAnyRacingAuthorityDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.bannedByAnyRacingAuthorityDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="Have you ever been disqualified or refused a licence, permit or registration by any Racing Authority or Racing code (including Racing Victoria), either in Australia, or overseas?"
						value={formatToggleValue(formData?.disqualified)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.disqualificationDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.disqualificationDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label={
							<>
								Have you, in the last 10 years, been:
								<ol className={classes.labelList} type="a">
									<li>found guilty of a criminal offence?</li>
									<li>imprisoned? and/or</li>
									<li>placed on parole?</li>
								</ol>
							</>
						}
						value={formatToggleValue(formData?.wasCharged)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.chargeDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.chargeDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label="Are there any current criminal proceedings (or charges) pending against you?"
						value={formatToggleValue(formData?.currentCriminalProceedings)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.criminalProceedingDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.criminalProceedingDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
				<SectionRow>
					<AdminReviewDatum
						label={
							<>
								Personal Bankruptcy and/or Company Insolvency:
								<br />
								Have you, in the last 10 years,
								<ol className={classes.labelList} type="a">
									<li>been declared bankrupt or insolvent? and/or</li>
									<li>
										Do you currently have any bankruptcy action or proceedings
										pending against you? and/or
									</li>
									<li>
										Has any Company which you use or have used as part of your
										{formType === RETRAINER
											? ' retraining'
											: ' retirement farm'}{' '}
										function/business, and/or which employs you (or has employed
										you) as a{' '}
										{formType === RETRAINER
											? 'retrainer'
											: 'retirement farm provider'}
										, become insolvent (including: Voluntary Administration,
										Liquidation or Receivership)?
									</li>
								</ol>
							</>
						}
						value={formatToggleValue(formData?.bankruptcy)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				{formData?.chargeDetails && (
					<SectionRow>
						<AdminReviewDatum
							label="If yes, please provide more details"
							value={formData?.bankruptcyDetails}
							largeScreenWidth={12}
						/>
					</SectionRow>
				)}
			</Col>
		</AdminReviewSection>
	);
};

export default DeclarationsSection;

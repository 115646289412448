import { createUseStyles } from 'react-jss';
import BannerImage from '../../../assets/images/header_banner.png';

export default createUseStyles({
	sectionHeader: {
		backgroundImage: `url(${BannerImage})`,
		width: '100%',
		padding: 20,
		boxSizing: 'border-box',
		fontWeight: 800,
		color: '#464749',
		fontSize: 15,
		'@media (min-width:992px)': {
			fontSize: 20
		}
	},
	sectionContent: {
		width: '100%',
		boxSizing: 'border-box',
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1'
	},
	sectionContainer: {
		display: 'flex',
		flexDirection: 'column'
	},
	darkContentBackground: {
		backgroundColor: '#f5f5f5'
	}
});

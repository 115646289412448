import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

const MapMarker = ({ isSelected, businessName, location, $hover, ...rest }) => {
	const classes = useStyles();
	return (
		<>
			<div
				className={classnames({
					[classes.mapMarker]: true,
					[classes.selected]: $hover || isSelected
				})}
				{...rest}
			/>
			{$hover && (
				<div className={classes.markerDescriptionPopup}>
					<div className={classes.markerDescriptionContent}>
						<strong className={classes.businessName}>{businessName}</strong>
						<i className={classes.location}>{location}</i>
					</div>
				</div>
			)}
		</>
	);
};

export default MapMarker;

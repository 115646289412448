import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	sectionContainer: {
		width: '100%',
		boxSizing: 'border-box',
		padding: '0px 20px 10px 20px'
	},
	messageBarContent: {
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	bannerContainer: {
		display: 'flex',
		width: '100%',
		height: 220,
		color: '#464749',
		overflow: 'hidden'
	},
	bannerContainerMobile: {
		display: 'flex',
		width: '100%',
		flexWrap: 'wrap',
		color: '#464749'
	},
	heroImage: {
		width: '65%'
	},
	heroImageMobile: {
		width: '100%'
	},
	heroImageArticle: {
		width: '40%'
	},
	image: {
		height: '100%',
		width: '100%',
		objectFit: 'cover',
		borderRadius: '5px 0px 0px 5px'
	},
	imageMobile: {
		height: '100%',
		width: '100%',
		objectFit: 'cover',
		borderRadius: '5px 5px 0px 0px'
	},
	textBlockContainer: {
		background: '#dcebf3',
		width: '35%',
		padding: '15px 30px',
		borderRadius: '0px 5px 5px 0px'
	},
	textBlockContainerMobile: {
		background: '#dcebf3',
		width: '100%',
		padding: 20,
		borderRadius: '0px 0px 5px 5px'
	},
	textBlockContainerArticle: {
		background: '#dcebf3',
		width: '60%',
		padding: '15px 30px',
		borderRadius: '0px 5px 5px 0px'
	},
	title: {
		fontSize: 30,
		fontWeight: 600,
		marginTop: 10
	},
	titleMobile: {
		fontSize: 18,
		fontWeight: 600
	},
	summary: {
		marginTop: 10,
		fontSize: 18
	},
	summaryMobile: {
		marginTop: 10,
		fontSize: 15
	}
}));

import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import classnames from 'classnames';

const Tabs = ({ id, index, setIndex, tabs }) => {
	const classes = useStyles();
	return (
		<div className={classes.navigationLinks} id={`${id}-tabs`}>
			<div className={classes.tabsContainer}>
				{tabs.map(
					(tab, i) =>
						tab.showTab && (
							<span
								id={`${id}-tab-${tab.title}`}
								key={`${id}-tab-${tab.title}`}
								onClick={() => setIndex(i)}
								className={classnames({
									[classes.siteHeaderMenuItem]: true,
									[classes.siteHeaderMenuItemSelected]: index === i
								})}
							>
								{tab.title}
							</span>
						)
				)}
			</div>
		</div>
	);
};

Tabs.propTypes = {
	id: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	setIndex: PropTypes.func.isRequired,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			showTab: PropTypes.bool.required
		})
	)
};

export default Tabs;

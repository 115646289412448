import { createUseStyles } from 'react-jss';
import loginRightColumnImage from '../../assets/images/Team_walking_out_from_behind.jpg';
import registrationRightColumnImage from '../../assets/images/Jen_Darby_portrait_1.jpg';

export default createUseStyles(() => ({
	Header: {
		fontSize: 20,
		fontWeight: 'bold',
		color: '#464749',
		marginBlockStart: 0
	},
	description: {
		fontSize: 12,
		marginTop: 0
	},
	subHeading: {
		fontSize: 12,
		fontWeight: 'bold',
		paddingBottom: '20',
		color: '#464749'
	},
	divider: {
		height: '1px',
		backgroundColor: '#dcebf3',
		border: 'none'
	},
	dividerMargin: {
		marginTop: 30,
		marginBottom: 30
	},
	formInputs: {
		marginBottom: '20px',
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	formInput: {
		height: 40,
		color: '#464749',
		fontSize: 15,
		borderRadius: 3,
		border: '1px solid #b3bcc1',
		paddingLeft: 10
	},
	formLabel: {
		fontSize: 15,
		color: '#464749',
		marginBottom: '0.5rem',
		fontWeight: 'bold'
	},
	errorText: {
		color: '#db0000'
	},
	tooltipicon: {
		backgroundColor: '#dcebf3',
		width: 23,
		height: 22,
		margin: 0,
		textAlign: 'center',
		borderRadius: 100,
		boxSizing: 'border-box',
		fontWeight: 'bold',
		color: '#006da6',
		marginBottom: '0.5rem',
		marginLeft: 15
	},
	tooltipdiv: {
		'& $formLabel': {
			width: 'initial',
			marginBottom: 0
		},
		display: 'flex',
		alignItems: 'center',
		marginBottom: '0.5rem'
	},
	rightside: {
		display: 'flex',
		alignItems: 'right'
	},
	passwordLabel: {
		fontSize: 12,
		display: 'flex',
		justifyContent: 'space-between'
	},
	keepLoggedInDiv: {
		marginBottom: 28
	},
	checkboxstyle: {
		transform: 'scale(1.5)',
		marginLeft: 6,
		appearance: 'checkbox',
		borderRadius: 5
	},
	checkboxtext: {
		fontSize: '15px',
		display: 'inline',
		paddingLeft: 10
	},
	imageDisplay: (props) => ({
		backgroundImage: `url('${
			props?.registrationRightColumnImage || registrationRightColumnImage
		}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: '100%',
		width: '100%'
	}),
	loginImageDisplay: (props) => ({
		backgroundImage: `url('${
			props?.loginRightColumnImage || loginRightColumnImage
		}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: '100%',
		width: '100%'
	}),
	loginHeader: {
		extend: 'Header',
		'max-width': 250
	},
	verificationImageDisplay: {
		backgroundImage: `url('${loginRightColumnImage}')`,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: 450,
		width: '75%'
	},
	loadingDiv: {
		marginTop: 20,
		position: 'absolute',
		left: '45%',
		top: '50%',
		zIndex: 3
	},
	fieldsLoadingDiv: {
		position: 'absolute',
		left: '45%',
		top: '50%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	},
	checkboxContainer: {
		display: 'inline-flex',
		alignItems: 'center'
	},
	pushFloor: {
		marginBottom: 25
	}
}));

import React, { useState } from 'react';
import { ToolTipTypeEnum } from '../../utils/enums';
import Exclamation from '../../assets/icons/Tool_Tip_icon.svg';
import useStyles from './styles';
import { useScreenClass } from 'react-grid-system';

const FormToolTip = ({
	type = ToolTipTypeEnum.exclamationMark,
	children,
	toolTipContainerStyle,
	iconStyle
}) => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const [showToolTip, setShowToolTip] = useState(false);

	return (
		<span>
			<div className={classes.tooltipContainer}>
				{!['xs', 'sm', 'md'].includes(screenClassForRender) && (
					<div
						onMouseLeave={() => setShowToolTip(false)}
						onMouseOver={() => setShowToolTip(true)}
						style={
							type === ToolTipTypeEnum.exclamationMark
								? { height: 18, marginLeft: 10 }
								: {}
						}
					>
						{type === ToolTipTypeEnum.exclamationMark && (
							<img
								src={Exclamation}
								style={iconStyle}
								className={classes.image}
							/>
						)}
						{type !== ToolTipTypeEnum.exclamationMark && (
							<div style={iconStyle} className={classes.questionMark}>
								?
							</div>
						)}
					</div>
				)}
				{['xs', 'sm', 'md'].includes(screenClassForRender) && (
					<div
						onClick={() => setShowToolTip(!showToolTip)}
						style={
							type === ToolTipTypeEnum.exclamationMark
								? { height: 18, marginLeft: 10 }
								: {}
						}
					>
						{type === ToolTipTypeEnum.exclamationMark && (
							<img
								style={iconStyle}
								src={Exclamation}
								className={classes.image}
							/>
						)}
						{type !== ToolTipTypeEnum.exclamationMark && (
							<div style={iconStyle} className={classes.questionMark}>
								?
							</div>
						)}
					</div>
				)}
				{showToolTip && (
					<div style={toolTipContainerStyle} className={classes.tooltip}>
						{children}
					</div>
				)}
			</div>
		</span>
	);
};

export default FormToolTip;

export const getOhepNominationReviewFormData = /* GraphQL */ `
	query GetOhepNominationReviewFormData($id: Int!) {
		getAdminMembers {
			id
			externalId
			firstName
			lastName
		}
		listOhepAdminStatuses {
			id
			value
		}
		listOhepEligibilityReasons {
			id
			value
		}
		listOhepPrimaryConcerns {
			id
			value
		}
		listOhepNominationCategories {
			id
			value
		}
		listInjuryDetails {
			id
			value
		}
		listOhepBodyParts {
			id
			value
		}
		listIllnessDetails {
			id
			value
		}
		getOhepNominationFormById(id: $id) {
			adminData {
				addEwgmSignatureOnAcceptanceNotice
				adminAction {
					admin {
						externalId
					}
					adminStatus {
						id
					}
					formComplete
				}
				adminNotes
				automaticallySendAcceptanceNoticeToOwner
				created
				eligibilityReason {
					id
					value
				}
				ewdComments
				ewdFinalComments
				ewdRecommendation
				ewdRecommendationDate
				ewgmApprovalToProceed
				ewgmApprovalToProceedDate
				ewgmComments
				formComplete
				illness {
					id
					value
				}
				illnessOther
				injury {
					id
					value
				}
				injuryOther
				nominationCategory {
					id
					value
				}
				bodyParts {
					id
					value
				}
				bodyPartOther
				primaryConcern {
					id
					value
				}
				primaryConcernOther
				rehomingAttemptsComments
				rehomingAttemptsSatisfactory
				vetCertOrOtherFiles {
					created
					filename
					key
					type
				}
				xRayFiles {
					created
					filename
					key
					type
				}
				brandImages {
					created
					filename
					key
					type
				}
				hasInjuryIdentified
				dateInjuryReported
				hasInjuryPreventedCareer
				#Retirement Category
				retirementCategory
				vulnerabilityType
				behaviourOthersRetirement
				conformationalDetailsRetirement
				otherEducationDetails
				otherInjuryDetailsRetirement
				otherIllnessDetailsRetirement
				rehomingHistoryDetails
				otherDetailsRetirement
				habitsRetirement {
					id
					value
				}
				injuriesRetirement {
					id
					value
				}
				illnessRetirement {
					id
					value
				}
				education {
					id
					value
				}
				reasonRetirement {
					id
					value
				}
			}
			acknowledgedConsentToParticipate
			acknowledgedOwnerConfirmation
			acknowledgedOwnerIndemnity
			acknowledgedOwnershipBinding
			acknowledgedOwnershipWarranty
			acknowledgedPrivacy
			advertisedToRvDate
			currentAddress
			currentAddressDurationMonths
			currentAddressDurationYears
			currentAddressValidated
			currentCountry
			currentPostcode
			currentState
			currentSuburb
			dangerousBehaviourDetails
			thoroughbredOverTwentyDetails
			evidenceOfDangerousBehaviourFiles {
				created
				filename
				key
				type
			}
			horse {
				name
				country
				sex
				damName
				sireName
				foalDate
				microChipNumber
				horseBrand
				horseMarkings
				horseCode
			}
			id
			leftSidePhotos {
				created
				filename
				key
				type
			}
			member {
				firstName
				lastName
				email
				phone
				residentialAddress {
					address
					suburb
					state
					postcode
					country
				}
				externalId
			}
			otherFiles {
				created
				filename
				key
				type
			}
			previousAddress
			previousAddressValidated
			previousCountry
			previousPostcode
			previousState
			previousSuburb
			reasonsForNomination {
				id
				value
				description
				label
			}
			rehomingAttemptDetails
			rehomingAttemptDetailsDate
			relationshipToHorse {
				id
				value
			}
			relationshipToHorseOther
			rightSidePhotos {
				created
				filename
				key
				type
			}
			rspcaNoticeToComplyFiles {
				created
				filename
				key
				type
			}
			statuaryDeclarationFiles {
				created
				filename
				key
				type
			}
			veterinaryCertificateFiles {
				created
				filename
				key
				type
			}
			status
			behalfAdmin {
				firstName
				lastName
			}
			lastUpdated
			lastReviewed
		}
	}
`;

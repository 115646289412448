import { FilledInput, Select, ListItemIcon, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const StyledFilledInput = withStyles({
	root: {
		'&.Mui-disabled': { backgroundColor: 'transparent' }
	}
})(FilledInput);

export const StyledSelect = withStyles({
	root: {
		fontSize: 11,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 5,
		height: 10,
		fontWeight: 'bold',
		background: 'none'
	},
	icon: {
		'&.Mui-disabled': {
			display: 'none'
		}
	}
})(Select);

export const StyledListItemIconContainer = withStyles({
	root: { minWidth: 0, marginRight: 3 }
})(ListItemIcon);

export const StyledMenuItem = withStyles({
	root: { fontSize: 12, fontWeight: 'bold' }
})(MenuItem);

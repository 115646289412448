import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	ottInfoDatumContainer: {
		width: '100%',
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		},
		marginTop: 20,
		marginBottom: 20
	},
	datumLabel: {
		color: '#464749',
		fontWeight: 600,
		marginBottom: 5
	}
}));

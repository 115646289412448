import React, { useState } from 'react';
import useStyles from './styles';
import { Link } from 'gatsby';
import Forward from '../../assets/icons/Forward_Carousel.svg';
import Back from '../../assets/icons/Back_Carousel.svg';
import { navigate } from 'gatsby';

const Programs = ({ data, mobile, noReadMore, heading }) => {
	const classes = useStyles();

	const [activeImage, setActiveImage] = useState(0);
	const [prevImage, setPrevImage] = useState(data.length - 1);
	const [nextImage, setNextImage] = useState(1);

	const updateImagePositionRight = () => {
		let aImage = activeImage === data.length - 1 ? 0 : activeImage + 1;
		let pImage = prevImage === data.length - 1 ? 0 : prevImage + 1;
		let nImage = nextImage === data.length - 1 ? 0 : nextImage + 1;
		setActiveImage(aImage);
		setPrevImage(pImage);
		setNextImage(nImage);
	};

	const updateImagePositionLeft = () => {
		let aImage = activeImage === 0 ? data.length - 1 : activeImage - 1;
		let pImage = prevImage === 0 ? data.length - 1 : prevImage - 1;
		let nImage = nextImage === 0 ? data.length - 1 : nextImage - 1;
		setActiveImage(aImage);
		setPrevImage(pImage);
		setNextImage(nImage);
	};

	const renderPrograms = () => {
		if (mobile) {
			return (
				<>
					<div className={classes.headTitleMobile}>{heading}</div>
					<div className={classes.mobileCaroContainer}>
						<div
							className={classes.mobileBack}
							onClick={() => updateImagePositionLeft()}
						>
							<img src={Back} className={classes.mobileButton} />
						</div>
						<div
							className={classes.mobileLeft}
							style={{
								backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[prevImage].image})`,
								backgroundSize: 'cover',
								opacity: 0.5
							}}
						>
							<div className={classes.mobileTextBannerContainer}>
								<div className={classes.mobileContentContainer}>
									<div className={classes.mobileTextTitle}>
										{data[prevImage].title}
									</div>
									<div className={classes.mobileText}>
										{data[prevImage].summary}
									</div>
									<div className={classes.mobileButtonLink}>
										<Link to="/register" className={classes.accountButtonlink}>
											Join Now
										</Link>
									</div>
								</div>
							</div>
						</div>

						<div className={classes.mobileTextBannerContainerActive}>
							<div
								className={classes.mobileActive}
								style={{
									backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[activeImage].image})`,
									backgroundSize: 'cover'
								}}
							/>
							<div className={classes.mobileContentContainer}>
								<div
									onClick={() => {
										data[activeImage]?.link
											? navigate('/' + data[activeImage]?.link)
											: null;
									}}
									className={
										data[activeImage]?.link
											? classes.mobileLinkTitle
											: classes.mobileTextTitle
									}
								>
									{data[activeImage].title}
								</div>
								<div className={classes.mobileText}>
									{data[activeImage].summary}
								</div>
								{!noReadMore && (
									<div
										className={classes.mobileButtonLink}
										onClick={() => navigate(`/${data[activeImage].buttonLink}`)}
									>
										Read More
									</div>
								)}
							</div>
						</div>
						<div
							className={classes.mobileForward}
							onClick={() => updateImagePositionRight()}
						>
							<img src={Forward} className={classes.mobileButton} />
						</div>
						<div
							className={classes.mobileRight}
							style={{
								backgroundImage: `linear-gradient(to right, #00000096, rgba(255,0,0,0)), url(${data[nextImage].image})`,
								backgroundSize: 'cover',
								opacity: 0.5
							}}
						>
							<div className={classes.mobileTextBannerContainer}>
								<div className={classes.mobileContentContainer}>
									<div className={classes.mobileTextTitle}>
										{data[nextImage].title}
									</div>
									<div className={classes.mobileText}>
										{data[nextImage].summary}
									</div>
									<div className={classes.mobileButtonLink}>
										<Link to="/register" className={classes.accountButtonlink}>
											Join Now
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		} else {
			return (
				<div className={classes.leftContainer}>
					<div className={classes.headTitle}>{heading}</div>
					<div className={classes.leftContent}>
						<div className={classes.card}>
							<div>
								<img src={data[0].image} className={classes.image} />
							</div>
							<div className={classes.program}>{data[0].title}</div>
							<div className={classes.description}>{data[0].summary}</div>
							<div
								className={classes.readMore}
								onClick={() => navigate(`/${data[0].buttonLink}`)}
							>
								Read more
							</div>{' '}
						</div>
						<div className={classes.card}>
							<div>
								<img src={data[1].image} className={classes.image} />
							</div>
							<div className={classes.program}>{data[1].title}</div>
							<div className={classes.description}>{data[1].summary}</div>
							<div
								className={classes.readMore}
								onClick={() => navigate(`/${data[1].buttonLink}`)}
							>
								Read more
							</div>{' '}
						</div>
						<div className={classes.card}>
							<div>
								<img src={data[2].image} className={classes.image} />
							</div>
							<div className={classes.program}>{data[2].title}</div>
							<div className={classes.description}>{data[2].summary}</div>
							<div
								className={classes.readMore}
								onClick={() => navigate(`/${data[2].buttonLink}`)}
							>
								Read more
							</div>{' '}
						</div>
						<div className={classes.card}>
							<div>
								<img src={data[3].image} className={classes.image} />
							</div>
							<div className={classes.program}>{data[3].title}</div>
							<div className={classes.description}>{data[3].summary}</div>
							<div
								className={classes.readMore}
								onClick={() => navigate(`/${data[3].buttonLink}`)}
							>
								Read more
							</div>{' '}
						</div>
					</div>
				</div>
			);
		}
	};
	return renderPrograms();
};
export default Programs;

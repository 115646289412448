import { createUseStyles } from 'react-jss';

export default createUseStyles({
	label: {
		fontWeight: 800,
		color: '#464749',
		marginBottom: 15
	},
	contentContainer: {
		width: '100%',
		boxSizing: 'border-box',
		paddingLeft: 15,
		paddingBottom: 15,
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15
		}
	},
	dataValue: {
		overflowWrap: 'break-word'
	}
});

export const getMemberBenefitsAndMerchandiseOptionsData = /* GraphQL */ `
	query getMemberBenefitsAndMerchandiseOptionsData {
		listMemberBenefitsTypes {
			id
			value
		}
		listMerchandiseTypes {
			id
			value
		}
		listMerchandiseRugSizeTypes {
			id
			value
		}
		listClinicDetailsTypes {
			id
			value
		}
	}
`;

export const saveMemberBenefitsAndMerchandiseForm = /* GraphQL */ `
	mutation saveMemberBenefitsAndMerchandiseForm(
		$input: MemberBenefitsAndMerchandiseFormInput
	) {
		saveMemberBenefitsAndMerchandiseForm(input: $input) {
			error {
				errorCode
				errorDetails
				errorMessage
			}
			id
			message
			success
		}
	}
`;

import React from 'react';
import useStyles from './styles';

const PhotoHelpMessage = () => {
	const classes = useStyles();
	return (
		<div className={classes.photoHelpMessageContainer}>
			Owners are required to submit a <strong> minimum of 2 photos</strong>.
			<br />
			<a
				href={'/photo-upload-guide'}
				target="_blank"
				rel="noreferrer"
				className={classes.photoHelpLink}
			>
				<b>
					Need help with photo requirements? Click here to view examples of
					suitable photos of your thoroughbred
				</b>
			</a>
		</div>
	);
};

export default PhotoHelpMessage;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	eventPopover: {
		backgroundColor: 'rgb(237, 237, 237)',
		padding: 10,
		borderRadius: '0px 0px 5px 5px'
	},
	upNext: {
		extend: 'eventPopover',
		minHeight: 145
	},
	mobileUpNext: {
		extend: 'eventPopover',
		marginBottom: 15
	},
	warningIcon: {
		maxWidth: 18,
		marginRight: 5
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	dashboardDesktopPageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 40,
		paddingLeft: '0px !important',
		paddingRight: '0px !important'
	},
	dashboardMobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '0px 20px 40px 20px'
	},
	dashboardTopContainer: {
		maxWidth: '100%',
		background: 'white'
	},
	desktopArticles: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	notificationsContainer: {
		width: '100%',
		marginBottom: 25
	},
	resultContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: 40
	},
	articles: {
		padding: 10
	}
});

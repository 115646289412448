import { navigate } from 'gatsby-link';
import React, { useState } from 'react';
import { UserNotification } from '../../../Mobile';
import { formatUserNotifications } from '../../helpers';
import {
	DISMISS,
	COMPETITION_ADMIN,
	ACK_RETIREMENT,
	RETIREMENT_REPORT_OVERDUE,
	RETIREMENT_REPORT_DUE,
	ACK_FOSTER,
	FOSTER_FORMS,
	FOSTER_INITIAL_NOTIFICATION_TYPE,
	FOSTER_WEEKLY_NOTIFICATION_TYPE,
	THOROUGHBRED_UNREGISTERED,
	LIST_OF_NOTIFICATION_TO_BE_DISMISSABLE,
	RESET_FORMS
} from '../../../../utils/constants';
import ConfirmationModal from '../../../ConfirmationModal';
import { EVENT_REQUEST_REPORT_STRING } from '../../constants';

const modalStylesConfirmation = {
	content: {
		minWidth: 300,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const Notifications = (
	isMobile,
	notifications,
	removeNotification,
	updateCompetitionAdminNofitcation,
	updateRetirementAdminNotification,
	updateAckFosterNofitcation,
	tempRemoveNotification,
	viewAll,
	page
) => {
	const [confirmationModal, setConfirmationModal] = useState(false);
	if (notifications.length > 0) {
		return formatUserNotifications(
			Number.isInteger(page)
				? notifications.slice(page * 10, page * 10 + 10)
				: notifications
		).map((data, index) => {
			if (index < 4 || viewAll) {
				if (
					data.type === DISMISS &&
					data?.source?.includes(EVENT_REQUEST_REPORT_STRING)
				) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={
								data.link
									? () => {
											navigate(data.link);
									  }
									: null
							}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (LIST_OF_NOTIFICATION_TO_BE_DISMISSABLE.includes(data.type)) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={() => {
								if (data.selection) {
									navigate(data.link, {
										state: { selection: data.selection }
									});
								} else {
									navigate(data.link);
								}
							}}
							negativeClick={() => {
								tempRemoveNotification(data.id);
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (data.type === DISMISS || data.type === ACK_RETIREMENT) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={
								data.link &&
								!data?.text?.includes(FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT) &&
								!data?.text?.includes(FOSTER_FORMS.STAR_WEEKLY_ASSESSMENT) &&
								!data?.text?.includes(FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT) &&
								!data?.text?.includes(FOSTER_FORMS.STAR_INITIAL_ASSESSMENT)
									? () => {
											if (
												data?.link !== '/competitions' &&
												data?.link !== 'my-stable'
											) {
												removeNotification(data.id);
											}
											navigate(
												data.link === 'my-stable'
													? `/?activeTab=${data.link}`
													: data.link
											);
									  }
									: null
							}
							negativeClick={() => {
								if (data?.link === '/competitions') {
									setConfirmationModal(true);
								} else {
									removeNotification(data.id);
								}
							}}
							noNegativeClick={
								data?.link === '/account' ||
								(data?.link === 'my-stable' &&
									!data?.text?.includes(
										FOSTER_FORMS.FOSTER_INITIAL_ASSESSMENT
									) &&
									!data?.text?.includes(FOSTER_FORMS.STAR_WEEKLY_ASSESSMENT) &&
									!data?.text?.includes(
										FOSTER_FORMS.FOSTER_WEEKLY_ASSESSMENT
									) &&
									!data?.text?.includes(FOSTER_FORMS.STAR_INITIAL_ASSESSMENT) &&
									!data?.text?.includes(
										RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT
									) &&
									!data?.text?.includes(
										RESET_FORMS.RESET_EXPRESS_REHOME_REPORT_2
									) &&
									!data?.text?.includes(
										RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT
									))
							}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
							{data?.link === '/competitions' && (
								<ConfirmationModal
									showModal={confirmationModal}
									closeModal={() => {
										setConfirmationModal(false);
									}}
									styles={modalStylesConfirmation}
									heading="Remove competition Entry notification"
									positiveLabel="Confirm"
									negativeLabel="Cancel"
									onClickPositive={() => {
										removeNotification(data.id);
										setConfirmationModal(false);
									}}
								>
									Are you sure? You will not be able to enter this competition
									if you confirm.
								</ConfirmationModal>
							)}
						</UserNotification>
					);
				} else if (data.type === COMPETITION_ADMIN) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={
								data.link
									? () => {
											navigate(data.link);
									  }
									: null
							}
							negativeClick={() => {
								updateCompetitionAdminNofitcation(data.id, data.type);
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (
					data.type === FOSTER_INITIAL_NOTIFICATION_TYPE ||
					data.type === FOSTER_WEEKLY_NOTIFICATION_TYPE
				) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={() => {
								navigate('/?activeTab=my-stable&activeRole=STAR');
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (data.type === ACK_FOSTER) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={
								data.link
									? () => {
											navigate(data.link);
									  }
									: null
							}
							negativeClick={() => {
								updateAckFosterNofitcation(data.id, data.type);
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (data.type === THOROUGHBRED_UNREGISTERED) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={() => {
								navigate(`/thoroughbred-unregistered-form/${data.id}`);
							}}
							noNegativeClick
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (
					data.type === RETIREMENT_REPORT_OVERDUE ||
					data.type === RETIREMENT_REPORT_DUE
				) {
					return (
						<UserNotification
							isMobile={isMobile}
							key={index}
							positiveClick={() => {
								navigate('/?activeTab=my-stable');
							}}
							negativeClick={() => {
								updateRetirementAdminNotification(
									data.id,
									data.type,
									data.link
								);
								removeNotification(data.id);
							}}
						>
							<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
						</UserNotification>
					);
				} else if (data.link) {
					if (data.selection) {
						return (
							<UserNotification
								isMobile={isMobile}
								key={index}
								positiveClick={() =>
									navigate(data.link, {
										state: { selection: data.selection }
									})
								}
							>
								<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
							</UserNotification>
						);
					} else {
						return (
							<UserNotification
								isMobile={isMobile}
								key={index}
								positiveClick={() => navigate(data.link)}
							>
								<div dangerouslySetInnerHTML={{ __html: data.text }}></div>
							</UserNotification>
						);
					}
				} else {
					return (
						<UserNotification isMobile={isMobile} key={index}>
							{data.text}
						</UserNotification>
					);
				}
			}
		});
	}
};

export default Notifications;

import React, { useState, useEffect, useRef } from 'react';
import useStyles from './styles';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { Container } from 'react-grid-system';
import selectedIcon from '../../assets/icons/Checkbox_tackoom_selected.svg';
import notSelectedIcon from '../../assets/icons/Checkbox_tackroom_unselected.svg';
import { formatDateReadable } from '../../utils/helpers';
import HeaderBanner from '../HeaderBanner';
import { OTTNews, TopContent } from '../Mobile';
import { useScreenClass } from 'react-grid-system';
import { useAuth } from '../../hooks/useAuth';
import Arrow from '../../assets/icons/Arrow_right.svg';
import SearchInput from '../SearchInput/index';
import { Link, Typography } from '@material-ui/core';
import { boldSearch } from '../../utils/helpers/index';
import BasicPagination from '../BasicPagination/index';
import DefaultImage from '../../assets/images/OTT_Community_Logo_RGB.jpg';

import {
	SHOW_ALL,
	OTT_HEALTH_HUB,
	RIDING_AND_SCHOOLING,
	COMPETING_YOUR_OTT,
	FOR_THE_LOVE_OF_OTT,
	BUYING_SELLING_AN_OTT,
	TACK_ROOM
} from '../../utils/constants';
import HeroBannerImage from '../HeroBannerImage';
import SEO from '../seo';

//Non-Member and Member
export const OTT_EXPLAINED = 'OTT’s Explained';
const OTTTackRoom = () => {
	const node = useRef();
	const filterMenu = [
		{
			id: 1,
			title: SHOW_ALL,
			selected: true
		},
		{
			id: 2,
			title: OTT_EXPLAINED,
			selected: false
		},
		{
			id: 3,
			title: OTT_HEALTH_HUB,
			selected: false
		},
		{
			id: 4,
			title: RIDING_AND_SCHOOLING,
			selected: false
		},
		{
			id: 5,
			title: COMPETING_YOUR_OTT,
			selected: false
		},
		{
			id: 6,
			title: FOR_THE_LOVE_OF_OTT,
			selected: false
		},
		{
			id: 7,
			title: BUYING_SELLING_AN_OTT,
			selected: false
		}
	];

	const screenClassForRender = useScreenClass();
	const [filterMenuList, setFilterMenuList] = useState(filterMenu);
	const [searchResult, setSearchResult] = useState([]);
	const [completeList, setCompleteList] = useState([]);
	const [tackRoomOverview, setTackRoomOverview] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [scrollLeftPostion, setscrollLeftPostion] = useState(0);
	const [searchBoxResult, setSearchBoxResult] = useState([]);
	const auth = useAuth();

	const [searchValue, setSearchValue] = useState('');
	const [searchActive, setSearchActive] = useState(false);
	const [showNotFound, setShowNotFound] = useState(false);
	const [searchBoxResultCount, setSearchBoxResultCount] = useState(0);
	const [paginationPage, setPaginationPage] = useState(0);
	const [paginationCount, setPaginationCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const tackRoomArticleList = useStaticQuery(
		graphql`
			query tackRoomArticleList {
				allContentfulArticle(
					filter: { tags: { in: "Tack Room" } }
					sort: { fields: publishTime, order: DESC }
				) {
					nodes {
						id
						title
						tags
						publishTime
						summary
						previewImage {
							file {
								url
								fileName
							}
							fluid {
								src
							}
						}
					}
				}
				allContentfulTackRoomOverview {
					nodes {
						link
						linkButtonTitle
						summary {
							summary
						}
						title
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	const updateFilterList = (id) => {
		filterMenuList.map((f) => {
			if (f.id === id) {
				f.selected = !f.selected;
			}
		});
		let updatedFilter = Object.assign([], filterMenuList);
		setFilterMenuList(updatedFilter);
	};

	const renderFilterList = () => {
		return filterMenuList.map((f) => {
			return (
				<div
					key={f.id}
					onClick={() => updateFilterList(f.id)}
					style={{ cursor: 'pointer' }}
				>
					<img
						src={f.selected ? selectedIcon : notSelectedIcon}
						className={classes.icon}
					/>
					{f.title}
				</div>
			);
		});
	};

	let updateSearchResult = (filterArrayList, list) => {
		let result = [];
		if (filterArrayList) {
			list.map((m) => {
				m.tags.map((f) => {
					if (filterArrayList.includes(f)) {
						result.push(m);
					}
				});
			});
		} else {
			let limitedArticles = list.filter((m) => m.tags.includes(OTT_EXPLAINED));
			result = limitedArticles;
		}
		return result;
	};

	useEffect(() => {
		let articles = tackRoomArticleList.allContentfulArticle.nodes;
		let overview = tackRoomArticleList.allContentfulTackRoomOverview.nodes[0];
		setTackRoomOverview(overview);
		let refinedList = articles.filter((m) => m.tags != null);
		setCompleteList(refinedList);
		if (auth?.currentUser) {
			setIsLoggedIn(true);
			setSearchResult(refinedList);
		} else {
			let result = updateSearchResult(null, refinedList);
			setSearchResult(result);
		}
	}, [auth?.currentUser]);

	useEffect(() => {
		let filterArrayList = [];
		let result = [];
		filterMenuList
			.filter((m) => m.selected)
			.map((m) => {
				filterArrayList.push(m.title);
			});
		let selectedIds = [];
		let filtered = [];
		if (filterArrayList.length > 0) {
			if (completeList.length > 0) {
				let list = completeList;
				if (!filterArrayList.includes(SHOW_ALL)) {
					result = updateSearchResult(filterArrayList, list);
				} else {
					result = list;
				}
				selectedIds = result.map((x) => x.id);
				filtered = result.filter(
					({ id }, index) => !selectedIds.includes(id, index + 1)
				);
				setSearchResult(filtered);
			}
			if (searchActive && searchValue.length !== 0) {
				onSearch(true, result);
			} else {
				setSearchBoxResultCount(filtered.length);
				setPaginationCount(filtered.length);
				setRowsPerPage(filtered.length >= 10 ? 10 : filtered.length);
				setPaginationPage(0);
			}
		} else {
			if (completeList.length > 0) {
				setSearchResult(completeList);
				if (searchActive) {
					onSearch(true, completeList);
				}
			}
		}
	}, [filterMenuList]);

	const renderArticleResults = () => {
		const finaSearchResult =
			searchBoxResult.length !== 0 ? searchBoxResult : searchResult;
		return finaSearchResult
			.slice(
				paginationPage * rowsPerPage,
				searchActive
					? paginationPage * rowsPerPage + rowsPerPage
					: finaSearchResult.length
			)
			.map((r) => {
				return (
					<div
						className={
							['sm', 'xs'].includes(screenClassForRender)
								? ''
								: classes.singleResultContainer
						}
						key={r.id}
					>
						<OTTNews
							isMobile={['sm', 'xs'].includes(screenClassForRender)}
							headingStyle={{ fontSize: 15 }}
							id={1}
							imageLink={
								r.previewImage?.fluid?.src
									? r.previewImage?.fluid?.src
									: DefaultImage
							}
							heading={r.title}
							date={formatDateReadable(r.publishTime)}
							onClick={() => goToArticle(r.id)}
							tags={r.tags.filter((t) => t != TACK_ROOM)}
							isTackRoom
							isSearchActive={searchActive}
							body={isLoggedIn ? r.summary : null}
						/>
					</div>
				);
			});
	};

	const goToArticle = (articleId) => {
		if (searchActive) {
			const newWindow = window.open(
				`/ott-tack-room/${articleId}`,
				'_blank',
				'noopener,noreferrer'
			);
			if (newWindow) newWindow.opener = null;
		} else {
			navigate(`/ott-tack-room/${articleId}`);
		}
	};

	const scrollRight = () => {
		let scrollAmount = 0;
		let slideTimer = setInterval(function () {
			node.current.scrollLeft += 10;
			scrollAmount += 10;
			if (scrollAmount >= 300) {
				window.clearInterval(slideTimer);
			}
		}, 10);
	};

	const scrollLeft = () => {
		let scrollAmount = 0;
		let slideTimer = setInterval(function () {
			node.current.scrollLeft -= 10;
			scrollAmount += 10;
			if (scrollAmount >= 300) {
				window.clearInterval(slideTimer);
			}
		}, 10);
	};

	const onRenderNoResultsFound = () => {
		return (
			<>
				<div>
					<Container className={classes.pageDesktopContainer}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								gutterBottom
							>
								NEIGH!
							</Typography>
							<Typography
								style={{ fontWeight: 'bold' }}
								variant="h5"
								gutterBottom
							>
								Sorry, no results were found
							</Typography>
							<Typography variant="h6" gutterBottom>
								Check your spelling or try more general words.
							</Typography>
							<Typography variant="h6" gutterBottom>
								{`If you still can't find what you are looking for`}{' '}
								<Link
									underline="always"
									onClick={() => navigate('/contact-us')}
								>
									Contact Us
								</Link>{' '}
							</Typography>
						</div>
					</Container>
				</div>
			</>
		);
	};

	const onHandleChange = (event) => {
		setSearchValue(event.target.value);
	};

	const onHandleChangePagination = (event, val) => {
		setPaginationPage(val);
	};

	const onSearch = (triggeredOnCategory = false, result) => {
		setSearchActive(true);
		let copiedArticle = JSON.parse(
			JSON.stringify(triggeredOnCategory ? result : searchResult)
		);
		if (searchValue.length !== 0) {
			copiedArticle = copiedArticle.filter(
				(copied) =>
					copied.title.toLowerCase().includes(searchValue.toLowerCase()) ||
					copied.summary.toLowerCase().includes(searchValue.toLowerCase()) ||
					copied.tags.some((tag) =>
						tag.toLowerCase().includes(searchValue.toLowerCase())
					)
			);
			let tempArticleNews = [];
			copiedArticle.forEach((copied) => {
				// copied.tags = copied.tags.map(tag => boldSearch(tag, searchValue));
				copied.title = boldSearch(copied.title, searchValue);
				copied.summary = boldSearch(copied.summary, searchValue);
				tempArticleNews.push(copied);
			});
			setShowNotFound(tempArticleNews.length === 0);
			setSearchBoxResult(tempArticleNews);
			setSearchBoxResultCount(tempArticleNews.length);
			setPaginationCount(tempArticleNews.length);
			setRowsPerPage(
				tempArticleNews.length >= 10 ? 10 : tempArticleNews.length
			);
			setPaginationPage(0);
		} else {
			setSearchBoxResult([]);
			setSearchBoxResultCount(copiedArticle.length);
			setPaginationCount(copiedArticle.length);
			setRowsPerPage(10);
		}
	};

	const onBackToLatestArticles = () => {
		setSearchActive(false);
		setSearchValue('');
		setSearchBoxResult([]);
		setShowNotFound(false);
		setPaginationPage(0);
	};

	const classes = useStyles();
	return (
		<Container
			className={
				['sm', 'xs'].includes(screenClassForRender)
					? classes.mobileContainer
					: classes.container
			}
		>
			<SEO title="OTT Tack Room" />
			<HeaderBanner title="OTT Tack Room" styles={{ marginBottom: 0 }} />
			<div
				style={{
					display: 'flex',
					justifyContent: `${searchActive ? 'space-between' : 'flex-end'}`,
					marginTop: 15
				}}
			>
				{searchActive && (
					<div style={{ marginTop: 10 }}>{`${searchBoxResultCount} Result${
						searchBoxResultCount < 2 ? '' : 's'
					}`}</div>
				)}
				<div>
					<SearchInput
						value={searchValue}
						onChangeFunc={onHandleChange}
						onClick={onSearch}
					/>
				</div>
			</div>
			{searchActive && (
				<div>
					<Link
						style={{ color: '#0085ca' }}
						onClick={() => onBackToLatestArticles()}
						underline="always"
					>
						Back to latest articles
					</Link>
				</div>
			)}

			{isLoggedIn && (
				<TopContent
					textLeftStyle={{ fontSize: 20 }}
					styles={{ marginTop: 20, marginBottom: 20 }}
					leftText="Select Categories"
				/>
			)}
			{isLoggedIn && (
				<div className={classes.menuContentContainer}>
					{scrollLeftPostion != 0 && (
						<div className={classes.rightScroll} onClick={() => scrollLeft()}>
							<img src={Arrow} style={{ transform: 'rotate(180deg)' }} />
						</div>
					)}

					<div
						ref={node}
						onScroll={() => setscrollLeftPostion(node.current.scrollLeft)}
						className={
							['sm', 'xs'].includes(screenClassForRender)
								? classes.menuContainerMobile
								: classes.menuContainer
						}
					>
						{renderFilterList()}
					</div>
					{(scrollLeftPostion === 0 ||
						scrollLeftPostion <=
							node.current.scrollWidth - node.current.clientWidth - 1) && (
						<div className={classes.leftScroll} onClick={() => scrollRight()}>
							<img src={Arrow} />
						</div>
					)}
				</div>
			)}
			<div style={{ clear: 'both' }}></div>
			<div
				className={
					searchActive ? classes.resultContainerSearch : classes.resultContainer
				}
			>
				{showNotFound ? onRenderNoResultsFound() : renderArticleResults()}
				{searchActive && (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<BasicPagination
							count={paginationCount}
							page={paginationPage}
							handleChange={onHandleChangePagination}
						/>
					</div>
				)}
			</div>
			<hr className={classes.hr} />
			{tackRoomOverview && (
				<HeroBannerImage
					title={tackRoomOverview.title}
					text={tackRoomOverview.summary?.summary}
					imgSrc={tackRoomOverview.image?.file?.url}
					buttonLink={tackRoomOverview.link}
					buttonText={tackRoomOverview.linkButtonTitle}
					isLoggedIn={isLoggedIn}
				/>
			)}
		</Container>
	);
};

export default OTTTackRoom;

import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import Divider from '../Divider';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';

import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	container: {
		display: 'block',
		position: 'relative',
		userSelect: 'none',
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		padding: 20
	},
	fieldContainer: {
		paddingLeft: 3,
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	errorTextDropDown: {
		color: '#db0000',
		fontSize: 15,
		marginTop: 5
	},
	errorTextResizingArea: {
		extend: 'errorTextDropDown',
		marginBottom: 10
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 14,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	},
	cancelButton: {
		extend: 'saveRequest',
		textDecoration: 'underline',
		marginRight: 20,
		display: 'flex',
		alignItems: 'center'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},
	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	disabledCompleteFormButton: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	}
}));

export const StyledContainer = styled(Container)`
	font-family: Open-sans !important;
	margin-top: 5rem;
	width: 100%;
	margin-bottom: 50px;
	padding-left: 15px !important;
	padding-right: 15px !important;
	@media (min-width: 768px) {
		margin-top: 0.5rem;
		width: 1000px;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	ul,
	p,
	span {
		font-size: 13px;
	}
	ul,
	p {
		margin: 0;
	}
	ul {
		padding-inline-start: 30px;
		margin-top: 0.5rem;
	}
	@media (min-width: 992px) {
		ul,
		p,
		span {
			font-size: 15px;
		}
	}
`;

export const StyledLoadingContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const StyledMessageBar = styled(MessageBar)`
	font-size: 0.8rem;
	p {
		margin: 0 0 0.5rem 0;
	}
	@media (min-width: 768px) {
		font-size: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const StyledNominationContactMessageBar = styled(MessageBar)`
	p {
		margin: 0 0 0.5rem 0;
	}
`;

export const StyledWhatIsOHEPButton = styled(PrimaryButton)`
	margin-top: 0 !important;
	width: 100%;
	height: 2.5rem;
	font-weight: 600;
	@media (min-width: 768px) {
		width: 220px;
		font-weight: 600;
	}
`;

export const StyledForm = styled.form`
	margin-top: 1.25rem;
	@media (min-width: 992px) {
		border: 1px solid #b3bcc1;
	}
`;

export const StyledFormFieldsContainer = styled.div`
	padding: 10px 20px;
`;

export const StyledFieldContainer = styled.div`
	padding: 1rem 0 0.625rem 0;
`;

export const StyledFieldLabel = styled.label`
	display: block;
	font-weight: 600;
	color: #464749;
	margin-bottom: 0.5rem;
	font-size: 13px;
	@media (min-width: 992px) {
		font-size: 15px;
	}
`;

export const StyledFormActionsRow = styled(Row)`
	justify-content: space-between !important;
	align-items: center !important;
	margin: 0 !important;
	padding: 1rem 0;
`;

export const StyledFormSubmitButton = styled(PrimaryButton)`
	margin-top: 0;
	width: 100%;
	@media (min-width: 992px) {
		width: 165px !important;
	}
`;

export const StyledFormActionLink = styled.div`
	color: #0085ca;
	cursor: pointer;
	font-size: 15px;
	text-decoration: underline;
	visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
`;

export const StyledSaveDraft = styled(Col)`
	display: flex;
	justify-content: flex-end;
`;

export const StyledCancelRequest = styled(StyledFormActionLink)``;

export const StyledFormEndDivider = styled(Divider)``;

export const StyledErrorMessage = styled.div`
	color: #db0000;
	margin-top: 10px;
	text-align: right;
	@media (min-width: 992px) {
		margin: 0 20px 20px 0;
	}
`;

export const StyledErrorText = styled.span`
	color: #db0000;
	font-size: 13px;
	@media (min-width: 992px) {
		font-size: 15px;
		margin: 0 20px 20px 0;
	}
`;

export const StyledFieldText = styled.input`
	width: 100%;
	border: 1px solid ${(props) => (props.isError ? '#ff0000' : '#c7ced1')};
	height: 40px;
	font-size: 15px;
	box-sizing: border-box;
	padding-left: 16px;
	border-radius: 4px;
	&:focus {
		outline: none;
	}
`;

export const StyledMailLink = styled.a`
	color: #006da6;
	text-decoration: none;
	font-weight: 600;
`;

export const StyledFormCheckboxFieldContainer = styled.div`
	margin: 10px 0 18px 0;
	display: flex;
	align-items: flex-start;
`;

export const StyledFormCheckboxFieldLabel = styled(StyledFieldLabel)`
	margin-left: 10px;
`;

export const StyledFormTextArea = styled.textarea`
	font-family: Open-sans !important;
	height: 80px;
	width: 100%;
	border: 1px solid ${(props) => (props.isError ? '#ff0000' : '#c7ced1')};
	font-size: 15px;
	box-sizing: border-box;
	padding-left: 16px;
	border-radius: 4px;
	&:focus {
		outline: none;
	}
`;

export const StyledSupportingDocumentationRow = styled(Row)`
	margin-top: 0.5rem;
	@media (min-width: 992px) {
		margin: 1.5rem 0;
		> * {
			margin: 0 0.5rem;
		}
	}
`;

export const StyledSupportingDocumentationCol = styled(Col)`
	margin: 0 !important;
	padding: 0 !important;
`;

export const StyledGenericStatementContainer = styled.div`
	margin-bottom: 0.5rem;
`;

export const StyledGenericStatementRow = styled.div`
	display: flex;
	align-items: flex-start;
	color: #464749;
	> p,
	div {
		margin-left: 0.5rem;
	}
`;

export const modalStylesConfirmation = {
	content: {
		minWidth: 300,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

export const StyledStatutoryDeclarationDownloadButton = styled.button`
	font-family: Open-sans !important;
	background-color: #006da6;
	border: none;
	border-radius: 4px;
	color: #fff;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	height: 38px;
	width: 100%;
	font-size: 15px;
	font-weight: 400;
	&:focus {
		outline: none;
	}
	cursor: pointer;
	svg {
		vertical-align: middle;
		margin-left: 4px;
	}
`;

export const StyledResidentialAddressCheckboxTitle = styled.div`
	color: '#464749';
	margin-left: 30px;
	font-size: 13px;
`;

export const CHECKBOX_ERROR_STYLES = {
	border: '1px solid red',
	backgroundImage: 'none'
};

import React, { useEffect, useState } from 'react';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import { Col, Row, useScreenClass } from 'react-grid-system';
import {
	checkIsSmallScreen,
	checkUserAdmin,
	formatDate,
	isOptionOther
} from '../../utils/helpers';
import useStyles from './styles';
import FormSection from '../FormComponents/FormSection';
import TextInputField from '../FormComponents/TextInputField';
import DateInputField from '../FormComponents/DateInputField';
import TimeInputField from '../FormComponents/TimeInputField';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import { useToast } from '../../hooks/useToast';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteEvent,
	deleteEventOrganiserImage,
	eventOrganiserUploadImage,
	getEventDropdowns,
	saveEvent,
	cancelExistingEvent,
	saveEventResults
} from './queries';
import { BLANK_OPTION, EVENTS_LABEL } from '../../utils/constants';
import SelectInputField from '../FormComponents/SelectInputField';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import { DEFAULT_COUNTRY } from '../../utils/constants';
import { validation, REGEX, TOGGLE_DEPENDENT } from './validation';
import MultiSelectInput from '../MultiSelectInput';
import UploadPhoto from '../UploadPhoto';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getEditEventResultsById,
	getEventById,
	uploadImageToS3
} from '../../graphql/custom.queries';
import { getMemberData } from '../Dashboard/graphql/queries';
import useFilePreview from '../../hooks/useFilePreview';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Toggle from '../Toggle';
import ConfirmationModal from '../ConfirmationModal';
import { useParams } from '@reach/router';
import axios from 'axios';
import { getDataModel, setEventResultInput } from './helper';
import { navigate } from 'gatsby-link';
import { EVENT_CALENDAR_ROUTE } from '../../utils/constants/routes';
import ResizingTextArea from '../ResizingTextArea';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import { useAuth } from '../../hooks/useAuth';
import {
	ROLE_COMPETITION_ADMIN,
	ROLE_RV_EQUINE_WELFARE
} from '../../utils/constants';
// import Table from '../Table';
import { Button } from '@material-ui/core';
import { generateUUID } from './utils';
import {
	getColumnsEventResult,
	REMOVE_ALL_EVENT_RESULTS,
	RESULT_STATUS
} from './constants';
import EditableTable from '../EditableTable';

const PAGE_ID = 'add-event';

const modalStylesConfirmation = {
	content: {
		minWidth: 300,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const validationFields = [
	'eventName',
	'eventDisciplines',
	'eventStartDate',
	'eventEndDate',
	'startTime',
	'endTime',
	'address',
	'suburb',
	'postcode',
	'state',
	'country',
	'organiserContactDetails',
	'eventOrganiser',
	'cancellationReason',
	'dateNotified'
];

const AddEvent = () => {
	const classes = useStyles();
	const { id } = useParams();
	const [formId, setFormId] = useState(id);

	const [errors, setErrors] = useState({});
	const [validationOn, setValidationOn] = useState(false);
	const { addToast } = useToast();
	const [eventDisciplines, setEventDisciplines] = useState([]);
	const [eventOrganisers, setEventOrganisers] = useState([]);
	const [eventCancellationReasons, setEventCancellationReasons] = useState([]);
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const blankEventResult = {
		id: generateUUID(),
		class: '',
		horse: { horseCode: null, name: '' },
		racingHorseName: '',
		riderName: '',
		result: '',
		comments: ''
	};
	const [eventResults, setEventResults] = useState([]);
	const [initialEventResults, setInitialEventResults] = useState([]);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isFormError, setIsFormError] = useState(false);
	const filePreviewHandler = useFilePreview();
	const { currentUser } = useAuth();
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	const competitionAdminRoleExist = currentUser?.Roles.some(
		(role) => role !== ROLE_COMPETITION_ADMIN && role === ROLE_RV_EQUINE_WELFARE
	);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);

	useEffect(() => {
		AppSyncService.execute(getEventDropdowns).then((data) => {
			if (data?.data?.listEventDisciplines) {
				setEventDisciplines(
					[BLANK_OPTION].concat(data.data.listEventDisciplines)
				);
			}
			if (data?.data?.listEventDisciplines) {
				setEventOrganisers(
					[BLANK_OPTION].concat(data.data.listEventOrganisers)
				);
			}
			if (data?.data?.listEventCancellationReasons) {
				setEventCancellationReasons(
					[BLANK_OPTION].concat(data.data.listEventCancellationReasons)
				);
			}
		});
	}, []);

	useEffect(() => {
		if (id) {
			AppSyncService.execute(getEventById, { eventId: id }).then((data) => {
				AppSyncService.execute(getEditEventResultsById, { eventId: id }).then(
					(dataEventResults) => {
						let eventResults = [];
						if (dataEventResults.data) {
							eventResults =
								dataEventResults.data.getEditEventResultsById || [];
						}
						let isDisplayReviewOnly = eventResults.some(
							(eventResult) => eventResult.status === RESULT_STATUS.REVIEW
						);
						initialiseFormState(data);
						setInitialEventResults(
							eventResults
								.filter((eventResult) =>
									isDisplayReviewOnly
										? eventResult.status === RESULT_STATUS.REVIEW
										: eventResult.status === RESULT_STATUS.PUBLISHED
								)
								.map((eventResult) => ({
									...eventResult,
									horse: {
										horseCode: eventResult.horseCode,
										name: eventResult.horseName
									}
								}))
						);
						setEventResults(
							eventResults
								.filter((eventResult) =>
									isDisplayReviewOnly
										? eventResult.status === RESULT_STATUS.REVIEW
										: eventResult.status === RESULT_STATUS.PUBLISHED
								)
								.filter(
									(eventResult) =>
										eventResult.comments !== REMOVE_ALL_EVENT_RESULTS
								)
								.map((eventResult) => ({
									...eventResult,
									horse: {
										horseCode: eventResult.horseCode,
										name: eventResult.horseName
									}
								}))
						);
					}
				);
			});
		}
	}, [id]);

	useEffect(() => {
		fetchMemberData();
	}, []);

	const fetchMemberData = async () => {
		const result = await AppSyncService.execute(getMemberData, {
			externalId: currentUser.UserId
		});

		if (result?.data?.getMember) {
			setCurrentUserMemberId(result.data.getMember.id);
			if (result?.data?.getMember?.eventOrganiser?.id) {
				setValues((prev) => {
					return {
						...prev,
						eventOrganiser: {
							label: result.data.getMember.eventOrganiser.value,
							value: result.data.getMember.eventOrganiser.id
						}
					};
				});
			}
		}
	};

	const initialiseFormState = (formData) => {
		if (formData.data?.getEventById?.id != null) {
			setFormId(formData.data.getEventById.id);
		}
		if (formData.data?.getEventById?.stagedDetails) {
			let modelData = getDataModel(
				values,
				{
					...JSON.parse(formData.data?.getEventById?.stagedDetails),
					eventOrganiserImage: formData.data?.getEventById?.eventOrganiserImage
				},
				isAdminUser
			);
			setValues((prev) => ({ ...prev, ...modelData }));
		} else {
			let modelData = getDataModel(
				values,
				formData.data?.getEventById,
				isAdminUser
			);
			setValues((prev) => ({ ...prev, ...modelData }));
		}
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const removeAddEvent = () => {
		let keys = values.organiserLogo?.value;
		if (keys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: keys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					deleteAddEvent();
				}
			});
		} else {
			deleteAddEvent();
		}
	};

	const deleteAddEvent = () => {
		if (formId) {
			values.organiserLogo.value.forEach((item) => {
				removeImage(item, 'organiserLogo');
			});
			AppSyncService.execute(deleteEvent, { eventId: formId }).then(() => {
				addToast({ Message: 'Event successfully deleted.', IsSuccess: true });
				navigate(EVENT_CALENDAR_ROUTE);
			});
		}
	};

	const cancelAddEvent = () => {
		navigate(EVENT_CALENDAR_ROUTE);
	};

	const handlePhotoUpload = (image) => {
		{
			const headers = {
				'Content-Type': image.type
			};
			let imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					imageKey = data.data.putUserImage.imageKey;
				}
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(eventOrganiserUploadImage, {
							input: {
								filename: image.name,
								key: imageKey,
								event_id: parseInt(id)
							}
						}).then((uploadResponse) => {
							try {
								let items = { ...values };
								setFormId(uploadResponse.data.saveEventOrganiserImages?.id);
								let dataList = Object.assign([], items['organiserLogo'].value);
								dataList.push({ filename: image.name, key: imageKey });
								let item = {
									...items['organiserLogo'],
									value: dataList
								};
								items['organiserLogo'] = item;
								setValues(items);
								setErrors(validation(values, validationOn, validationFields));
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			});
		}
	};

	const createDummyRowForDeletion = (status, eventId) => {
		return {
			eventId,
			memberExternalId: currentUser.UserId,
			class: '',
			horseCode: null,
			racingHorseName: '',
			riderName: '',
			result: '',
			comments: REMOVE_ALL_EVENT_RESULTS,
			status
		};
	};

	const removeImage = (img, name) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteEventOrganiserImage, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteEventOrganiserImage) {
							let items = { ...values };
							let dataList = Object.assign([], items[name].value);
							let newDataList = dataList.filter((d) => d.key != img.key);
							let item = {
								...items[name],
								value: newDataList,
								isTouched: true
							};
							items[name] = item;
							setValues(items);
							addToast({ Message: 'Image removed', IsSuccess: true });
							setErrors(validation(values, validationOn, validationFields));
						}
					});
				}
			}
		);
	};

	const handleChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e.target.value
		};
		items[field] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleChangeToggle = (val, name) => {
		let items = { ...values };
		let item = {
			...items[name],
			value: val,
			isTouched: true
		};
		items[name] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleMultiInputChange = (e, field) => {
		let items = { ...values };
		let item = {
			...items[field],
			value: e
		};
		items[field] = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const handleDateUpdate = (value, name, isTime) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			let items = { ...values };
			let item = {
				...items[name],
				value: isTime ? value : formattedDate
			};
			items[name] = item;
			if (
				name === 'eventStartDate' &&
				(values.eventEndDate.value < formattedDate ||
					!values.eventEndDate.value)
			) {
				items['eventEndDate'] = {
					...items['eventEndDate'],
					value: formattedDate
				};
			}
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		} else {
			let items = { ...values };
			let item = {
				...items[name],
				value: null
			};
			items[name] = item;
			setValues(items);
			setErrors(validation(values, validationOn, validationFields));
		}
	};

	const handleChangeDropDown = (e, fieldsToClear) => {
		let items = { ...values };
		let item = {
			...items[e.target.name],
			value: parseInt(e.target.value)
		};
		items[e.target.name] = item;

		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				let newItem = {
					...items[field.item],
					value: field.value
				};
				items[field.item] = newItem;
			});
		}
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onAddressChange = (address) => {
		let items = { ...values };
		let item = {
			...items.address,
			value: address,
			isTouched: true
		};
		items.address = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onSuburbChange = (suburb) => {
		let items = { ...values };
		let item = {
			...items.suburb,
			value: suburb,
			isTouched: true
		};
		items.suburb = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onPostcodeChange = (postcode) => {
		let items = { ...values };
		let item = {
			...items.postcode,
			value: postcode,
			isTouched: true
		};
		items.postcode = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onCountryChange = (country) => {
		let items = { ...values };
		let item = {
			...items.country,
			value: country,
			isTouched: true
		};
		items.country = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const onAddressStateChange = (state) => {
		let items = { ...values };
		let item = {
			...items.state,
			value: state,
			isTouched: true
		};
		items.state = item;
		setValues(items);
		setErrors(validation(values, validationOn, validationFields));
	};

	const addressAutoComplete = (address, addressDetails) => {
		setValues((prevState) => ({
			...prevState,
			address: {
				value: address,
				isTouched: true
			},
			suburb: {
				value: addressDetails.Suburb,
				isTouched: true
			},
			postcode: {
				value: addressDetails.Postcode,
				isTouched: true
			},
			state: {
				value: addressDetails.State,
				isTouched: true
			},
			country: {
				value: addressDetails.Country,
				isTouched: true
			}
		}));
		setErrors(validation(values, validationOn, validationFields));
	};

	const toggleManualAddressCheckBox = () => {
		let items = { ...values };
		let item = {
			...items.manualAddress,
			value: !values.manualAddress.value,
			isTouched: true
		};
		items.manualAddress = item;
		setValues(items);
	};

	const retrieveCancelInput = () => {
		return {
			eventId: parseInt(formId),
			cancellationReasonOther: values.cancellationReasonOther.value,
			dateNotified: values.dateNotified.value,
			eventCancellationReason: values.cancellationReason.value,
			otherDetails: values.otherDetails.value
		};
	};

	const retrieveFormInput = (complete) => {
		return {
			entriesCloseDate: values.entriesCloseDate.value,
			entriesOpenDate: values.entriesOpenDate.value,
			eventAddress: values.address.value,
			eventCountry: values.country.value,
			eventStartDate: values.eventStartDate.value,
			eventEndDate: values.eventEndDate.value,
			eventDisciplineId: values.eventDisciplines.value
				? values.eventDisciplines.value
				: null,
			eventEndTime: values.endTime.value
				? new Date(values.endTime.value)
				: null,
			eventName: values.eventName.value,
			eventOrganiserId: values.eventOrganiser.value
				? values.eventOrganiser.value
				: null,
			eventPostcode: values.postcode.value,
			eventStartTime: values.startTime.value
				? new Date(values.startTime.value)
				: null,
			eventState: values.state.value,
			eventSuburb: values.suburb.value,
			howToEnter: values.howToEnter.value,
			id: parseInt(formId),
			judgingDetails: values.judgingDetails.value,
			limitedEntries: values.limitedEntries.value,
			manualAddress: values.manualAddress.value,
			organiserContactDetails: values.organiserContactDetails.value,
			otherInformation: values.otherInformation.value,
			ottSponsoredClasses: values.ottSponsoredClasses.value
				.map(({ value }) => value)
				.toString(),
			ottSponsorshipDetails: values.ottSponsorshipDetails.value,
			photographerDetails: values.photographerDetails.value,
			socialMedia: values.socialMediaLinks.value,
			complete: complete,
			disciplineOther: values.otherDiscipline.value,
			organiserOther: values.organiserOther.value,
			memberId: currentUserMemberId,
			isAddEventResults: values.isAddEventResults.value
		};
	};

	const updateAddEvent = () => {
		setIsFormError(false);
		let input = retrieveFormInput(false);
		AppSyncService.execute(saveEvent, { input }).then((data) => {
			if (data.data?.saveEvent) {
				if (eventResults && eventResults.length > 0) {
					AppSyncService.execute(saveEventResults, {
						input: eventResults.map((eventResult) => ({
							...setEventResultInput(
								eventResult,
								data.data?.saveEvent.id,
								currentUser.UserId
							),
							status: RESULT_STATUS.REVIEW
						}))
					});
				} else if (
					eventResults.length === 0 &&
					initialEventResults.length > 0
				) {
					const dummyRow = createDummyRowForDeletion(
						RESULT_STATUS.REVIEW,
						data.data?.saveEvent.id
					);
					AppSyncService.execute(saveEventResults, {
						input: [dummyRow]
					});
				}
				addToast({ Message: 'Successfully saved event', IsSuccess: true });
				navigate(EVENT_CALENDAR_ROUTE);
			} else {
				addToast({
					Message: 'An error occured saving the event',
					IsSuccess: false
				});
			}
		});
	};

	const cancelEvent = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));
		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let input = retrieveFormInput(true);
			AppSyncService.execute(saveEvent, { input }).then((data) => {
				if (data.data?.saveEvent) {
					let input = retrieveCancelInput();
					AppSyncService.execute(cancelExistingEvent, { input }).then(
						(data) => {
							if (data.data?.cancelEvent) {
								addToast({
									Message: 'Event Sucessfully Cancelled',
									IsSuccess: true
								});
								navigate(EVENT_CALENDAR_ROUTE);
							} else {
								addToast({
									Message: 'An error occured while cancelling the event',
									IsSuccess: false
								});
							}
						}
					);
				} else {
					addToast({
						Message: 'An error occured saving the prior to cancellation',
						IsSuccess: false
					});
				}
			});
		} else {
			setIsFormError(true);
		}
	};

	const submitAddEvent = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));

		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let input = retrieveFormInput(true);
			AppSyncService.execute(saveEvent, { input }).then((data) => {
				if (data.data?.saveEvent) {
					if (eventResults && eventResults.length > 0) {
						AppSyncService.execute(saveEventResults, {
							input: eventResults.map((eventResult) => ({
								...setEventResultInput(
									eventResult,
									data.data?.saveEvent.id,
									currentUser.UserId
								),
								status: RESULT_STATUS.PUBLISHED
							}))
						});
					} else if (
						eventResults.length === 0 &&
						initialEventResults.length > 0
					) {
						const dummyRow = createDummyRowForDeletion(
							RESULT_STATUS.PUBLISHED,
							data.data?.saveEvent.id
						);
						AppSyncService.execute(saveEventResults, {
							input: [dummyRow]
						});
					}
					addToast({ Message: 'Successfully saved event', IsSuccess: true });
					navigate(EVENT_CALENDAR_ROUTE);
				} else {
					addToast({
						Message: 'An error occured saving the event',
						IsSuccess: false
					});
				}
			});
		} else {
			setIsFormError(true);
		}
	};

	const updateEventByEventOrganiser = (e) => {
		e.preventDefault();
		setValidationOn(true);
		setErrors(validation(values, true, validationFields));

		if (Object.keys(validation(values, true, validationFields)).length === 0) {
			let input = retrieveFormInput(false);
			AppSyncService.execute(saveEvent, { input }).then((data) => {
				if (data.data?.saveEvent) {
					if (eventResults && eventResults.length > 0) {
						AppSyncService.execute(saveEventResults, {
							input: eventResults.map((eventResult) => ({
								...setEventResultInput(
									eventResult,
									data.data?.saveEvent.id,
									currentUser.UserId
								),
								status: RESULT_STATUS.REVIEW
							}))
						});
					} else if (
						eventResults.length === 0 &&
						initialEventResults.length > 0
					) {
						const dummyRow = createDummyRowForDeletion(
							RESULT_STATUS.REVIEW,
							data.data?.saveEvent.id
						);
						AppSyncService.execute(saveEventResults, {
							input: [dummyRow]
						});
					}
					addToast({ Message: 'Successfully saved event', IsSuccess: true });
					navigate(EVENT_CALENDAR_ROUTE);
				} else {
					addToast({
						Message: 'An error occured saving the event',
						IsSuccess: false
					});
				}
			});
		} else {
			setIsFormError(true);
		}
	};

	const [values, setValues] = useState({
		manualAddress: {
			value: false,
			isRequired: false,
			isTouched: false
		},
		eventName: {
			value: '',
			isRequired: true,
			errorMessage: 'Event Name is required'
		},
		eventDisciplines: {
			value: 0,
			isRequired: true,
			errorMessage: 'Event Discipline is required'
		},
		eventStartDate: {
			value: null,
			isRequired: true,
			errorMessage: 'Start Date is required'
		},
		eventEndDate: {
			value: null,
			isRequired: true,
			errorMessage: 'End Date is required'
		},
		startTime: {
			value: null,
			isRequired: false
		},
		endTime: {
			value: null,
			isRequired: false
		},
		address: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Event address is required'
		},
		suburb: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Event suburb is required'
		},
		postcode: {
			value: '',
			isTouched: false,
			errorMessage: 'Event postcode is required',
			conditions: [
				{
					condition: REGEX,
					regex: '^[0-9]*$',
					errorMessage: 'Numeric value for postcode required'
				}
			],
			isRequired: true
		},
		state: {
			value: '',
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Event state is required'
		},
		country: {
			value: DEFAULT_COUNTRY,
			isTouched: false,
			conditions: [],
			isRequired: true,
			errorMessage: 'Event country is required'
		},
		ottSponsoredClasses: {
			value: [],
			isRequired: false
		},
		howToEnter: {
			value: '',
			isRequired: false
		},
		entriesOpenDate: {
			value: null,
			isRequired: false
		},
		entriesCloseDate: {
			value: null,
			isRequired: false
		},
		organiserLogo: {
			value: [],
			isRequired: false
		},
		eventOrganiser: {
			value: 0,
			isRequired: true,
			errorMessage: 'Event Organiser is required'
		},
		organiserContactDetails: {
			value: '',
			isRequired: true,
			errorMessage: 'Organiser Contact Details are required'
		},
		ottSponsorshipDetails: {
			value: '',
			isRequired: false
		},
		judgingDetails: {
			value: '',
			isRequired: false
		},
		photographerDetails: {
			value: '',
			isRequired: false
		},
		socialMediaLinks: {
			value: '',
			isRequired: false
		},
		limitedEntries: {
			value: false,
			isRequired: false
		},
		otherInformation: {
			value: '',
			isRequired: false
		},
		otherDiscipline: {
			value: '',
			isRequired: false
		},
		organiserOther: {
			value: '',
			isRequired: false
		},
		isCancelled: {
			value: false,
			isRequired: false
		},
		isAddEventResults: {
			value: false,
			isRequired: false
		},
		cancellationReason: {
			value: 0,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isCancelled',
					value: true,
					errorMessage: 'Cancellation Reason is required'
				}
			]
		},
		cancellationReasonOther: {
			value: '',
			isRequired: false
		},
		dateNotified: {
			value: null,
			conditions: [
				{
					condition: TOGGLE_DEPENDENT,
					field: 'isCancelled',
					value: true,
					errorMessage: 'Date Notified is required'
				}
			]
		},
		otherDetails: {
			value: '',
			isRequired: false
		},
		status: {
			value: null,
			isRequired: false
		},
		isPreviouslyCancelled: {
			value: false,
			isRequired: false
		}
	});

	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add the event address',
		value: 'Manually add the event address',
		isSelected: values.manualAddress.value
	};

	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	return (
		<PageContainer
			className={isSmallScreen ? classes.mobileTopContainer : classes.container}
		>
			<SEO title="Add New Event" />
			<div className={classes.formSectionContent}>
				<FormSection
					sectionTitle={
						competitionAdminRoleExist
							? values.status.value === EVENTS_LABEL.APPROVED
								? EVENTS_LABEL.EDIT_EVENT
								: EVENTS_LABEL.ADD_EVENT
							: `${formId ? EVENTS_LABEL.EDIT_EVENT : EVENTS_LABEL.ADD_EVENT}`
					}
					applyBorderRadius
					withBottomBorder
				>
					<>
						<Row className={classes.formRowFirst}>
							<Col xs={12}>
								<TextInputField
									id={`${PAGE_ID}-event-name`}
									label="Event Name"
									value={values.eventName.value}
									onChange={(e) => {
										handleChange(e, 'eventName');
									}}
									validationError={errors.eventName}
									isRequired
									fullWidth
								/>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12} lg={4}>
								<SelectInputField
									label="Event Discipline"
									id={`${PAGE_ID}-eventDisciplines`}
									name={`eventDisciplines`}
									options={eventDisciplines}
									value={values.eventDisciplines.value}
									isRequired
									fullWidth
									onChange={(e) =>
										handleChangeDropDown(e, [
											{ item: 'otherDiscipline', value: '' }
										])
									}
									validationError={errors.eventDisciplines}
								/>
							</Col>
							<Col xs={12} lg={4}>
								<DateInputField
									label="Start Date"
									value={values.eventStartDate.value}
									isRequired
									fullWidth
									validationError={errors.eventStartDate ? true : false}
									minDate={new Date()}
									noMaxDate
									onChange={(value) =>
										handleDateUpdate(value, 'eventStartDate', false)
									}
								/>
								{errors.eventStartDate && (
									<div className={classes.errorText}>
										<ValidationErrorText errorText={errors.eventStartDate} />
									</div>
								)}
							</Col>
							<Col xs={12} lg={4}>
								<DateInputField
									label="End Date"
									value={values.eventEndDate.value}
									isRequired
									fullWidth
									validationError={errors.eventEndDate ? true : false}
									minDate={new Date(values?.eventStartDate?.value)}
									noMaxDate
									onChange={(value) =>
										handleDateUpdate(value, 'eventEndDate', false)
									}
								/>
								{errors.eventEndDate && (
									<div className={classes.errorText}>
										<ValidationErrorText errorText={errors.eventEndDate} />
									</div>
								)}
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={6} lg={2}>
								<TimeInputField
									label="Start Time"
									fullWidth
									value={values.startTime.value}
									validationError={errors.startTime ? true : false}
									updateSelection={(time) =>
										handleDateUpdate(time, 'startTime', true)
									}
									timeCaption="Start Time"
								/>
							</Col>
							<Col xs={6} lg={2}>
								<TimeInputField
									label="End Time"
									fullWidth
									value={values.endTime.value}
									validationError={errors.endTime ? true : false}
									updateSelection={(time) =>
										handleDateUpdate(time, 'endTime', true)
									}
									timeCaption="End Time"
								/>
							</Col>
						</Row>
						{isOptionOther(eventDisciplines, values.eventDisciplines.value) && (
							<Row className={classes.formRow}>
								<Col xs={12}>
									<TextInputField
										id={`${PAGE_ID}-discipline-other`}
										label="Other Discipline Information"
										value={values.otherDiscipline.value}
										onChange={(e) => {
											handleChange(e, 'otherDiscipline');
										}}
										validationError={errors.otherDiscipline}
										fullWidth
									/>
								</Col>
							</Row>
						)}
						<div className={classes.addressEntry}>
							<FormAddressEntryGeneric
								address={values.address.value}
								onAddressChange={onAddressChange}
								isPostalAddressManual={false}
								suburb={values.suburb.value}
								onSuburbChange={onSuburbChange}
								postcode={values.postcode.value}
								onPostCodeChange={onPostcodeChange}
								country={values.country.value}
								onCountryChange={onCountryChange}
								manualAddressCheckBoxData={manualAddressCheckBoxData}
								toggleManualAddressCheckBox={toggleManualAddressCheckBox}
								state={values.state.value}
								onAddressStateChange={onAddressStateChange}
								isSubmitClicked={validationOn}
								autoCompleteFunction={addressAutoComplete}
								titlePrefix="Event"
							/>
						</div>
						<Row className={classes.formRow}>
							<Col xs={12}>
								<div className={classes.multiSelectInput}>
									<div
										className={
											isSmallScreen
												? classes.formFieldLabelMobile
												: classes.formFieldLabel
										}
									>
										OTT Sponsored Classes (Please separate individual values by
										using a comma)
									</div>
									<MultiSelectInput
										value={values.ottSponsoredClasses.value}
										updateValue={(value) => {
											handleMultiInputChange(value, 'ottSponsoredClasses');
										}}
									/>
								</div>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12}>
								<FormFieldWrapper
									fieldLabel={'How To Enter'}
									isRequired={false}
									fullWidth={true}
								>
									<ResizingTextArea
										name="howToEnter"
										id={`${PAGE_ID}-how-to-enter`}
										value={values.howToEnter.value}
										onChange={(e) => {
											handleChange(e, 'howToEnter');
										}}
										styles={{ paddingTop: 0 }}
									/>
								</FormFieldWrapper>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12} lg={4}>
								<DateInputField
									label="Entries open date"
									value={values.entriesOpenDate.value}
									fullWidth
									onChange={(value) =>
										handleDateUpdate(value, 'entriesOpenDate', false)
									}
									minDate={new Date()}
									noMaxDate
								/>
							</Col>
							<Col xs={12} lg={4}>
								<DateInputField
									label="Entries closing date"
									value={values.entriesCloseDate.value}
									fullWidth
									onChange={(value) =>
										handleDateUpdate(value, 'entriesCloseDate', false)
									}
									minDate={new Date()}
									noMaxDate
								/>
							</Col>
							<Col xs={12} lg={4}>
								<div className={classes.multiSelectInput}>
									<div
										className={
											isSmallScreen
												? classes.formFieldLabelMobile
												: classes.formFieldLabel
										}
									>
										Event Organiser Logo (jpg, png)
									</div>
									<UploadPhoto
										id={`${PAGE_ID}-organiserLogo`}
										formats={['png', 'jpeg']}
										labelName="Upload Logo"
										error={handleUploadError}
										data={values.organiserLogo.value}
										sizeLimitMB={5}
										numberOfImages={1}
										success={(image) =>
											handlePhotoUpload(image, 'organiserLogo', 'organiserLogo')
										}
										getImage={filePreviewHandler}
										removeImage={(image) => removeImage(image, 'organiserLogo')}
									/>
								</div>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12} lg={4}>
								{isAdminUser ? (
									<SelectInputField
										label="Event organiser"
										id={`${PAGE_ID}-eventOrganiser`}
										options={eventOrganisers}
										name={`eventOrganiser`}
										value={values.eventOrganiser.value}
										isRequired
										fullWidth
										onChange={(e) =>
											handleChangeDropDown(e, [
												{ item: 'organiserOther', value: '' }
											])
										}
										validationError={errors.eventOrganiser}
									/>
								) : (
									<FormFieldWrapper
										fieldLabel="Event organiser"
										isRequired
										fullWidth
									>
										<div
											className={
												isSmallScreen
													? classes.formFieldLabelMobile
													: classes.formFieldLabel
											}
										>
											{values.eventOrganiser?.label}
										</div>
									</FormFieldWrapper>
								)}
							</Col>
							<Col xs={12} lg={8}>
								<TextInputField
									id={`${PAGE_ID}-organiser-contact-details`}
									label="Organiser Contact Details (Name, Email, Phone)"
									value={values.organiserContactDetails.value}
									onChange={(e) => {
										handleChange(e, 'organiserContactDetails');
									}}
									validationError={errors.organiserContactDetails}
									isRequired
									fullWidth
								/>
							</Col>
						</Row>
						{isOptionOther(eventOrganisers, values.eventOrganiser.value) && (
							<Row className={classes.formRow}>
								<Col xs={12}>
									<TextInputField
										id={`${PAGE_ID}-organiser-other`}
										label="Other Event Organiser Information"
										value={values.organiserOther.value}
										onChange={(e) => {
											handleChange(e, 'organiserOther');
										}}
										validationError={errors.organiserOther}
										fullWidth
									/>
								</Col>
							</Row>
						)}
						<Row className={classes.formRow}>
							<Col xs={12}>
								<FormFieldWrapper
									fieldLabel={'OTT Sponsorship Details'}
									isRequired={false}
									fullWidth={true}
								>
									<ResizingTextArea
										name="ottSponsorshipDetails"
										id={`${PAGE_ID}-ott-sponsorship-details`}
										value={values.ottSponsorshipDetails.value}
										onChange={(e) => {
											handleChange(e, 'ottSponsorshipDetails');
										}}
										styles={{ paddingTop: 0 }}
									/>
								</FormFieldWrapper>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12} lg={4}>
								<TextInputField
									id={`${PAGE_ID}-judging-details`}
									label="Judging Details"
									value={values.judgingDetails.value}
									onChange={(e) => {
										handleChange(e, 'judgingDetails');
									}}
									fullWidth
								/>
							</Col>
							<Col xs={12} lg={4}>
								<TextInputField
									id={`${PAGE_ID}-photographer-details`}
									label="Photographer Details"
									value={values.photographerDetails.value}
									onChange={(e) => {
										handleChange(e, 'photographerDetails');
									}}
									fullWidth
								/>
							</Col>
							<Col xs={12} lg={4}>
								<TextInputField
									id={`${PAGE_ID}-social-media-links`}
									label="Social Media Links"
									value={values.socialMediaLinks.value}
									onChange={(e) => {
										handleChange(e, 'socialMediaLinks');
									}}
									fullWidth
								/>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col sm={12} lg={4}>
								<div className={classes.multiSelectInput}>
									<div
										className={
											isSmallScreen
												? classes.formFieldLabelMobile
												: classes.formFieldLabel
										}
									>
										Limited Entries?
									</div>
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={(val) => handleChangeToggle(val, 'limitedEntries')}
										value={values.limitedEntries.value}
									/>
								</div>
							</Col>
						</Row>
						<Row className={classes.formRow}>
							<Col xs={12}>
								<FormFieldWrapper
									fieldLabel={'Other information'}
									isRequired={false}
									fullWidth={true}
								>
									<ResizingTextArea
										name="otherInformation"
										id={`${PAGE_ID}-other-information`}
										value={values.otherInformation.value}
										onChange={(e) => {
											handleChange(e, 'otherInformation');
										}}
										styles={{ paddingTop: 0 }}
									/>
								</FormFieldWrapper>
							</Col>
						</Row>
						<div className={classes.cancelSection}>
							{values.status.value === 'Approved' && (
								<>
									<Row className={classes.formRow} nogutter>
										<Col xs={12} lg={4}>
											<div className={classes.multiSelectInput}>
												<div
													className={
														isSmallScreen
															? classes.formFieldLabelMobile
															: classes.formFieldLabel
													}
												>
													Has this event been cancelled?
												</div>
												<Toggle
													labelNegative="No"
													labelPositive="Yes"
													onClick={(val) =>
														handleChangeToggle(val, 'isCancelled')
													}
													value={values.isCancelled.value}
													disabled={values.isPreviouslyCancelled.value}
												/>
											</div>
										</Col>
									</Row>
									{values.isCancelled.value && (
										<>
											<Row className={classes.formRow} nogutter>
												<Col xs={12} lg={4}>
													<SelectInputField
														label="Event Cancellation Reason"
														id={`${PAGE_ID}-cancellationReason`}
														options={eventCancellationReasons}
														name={`cancellationReason`}
														value={values.cancellationReason.value}
														isRequired
														fullWidth
														onChange={(e) =>
															handleChangeDropDown(e, [
																{ item: 'cancellationReasonOther', value: '' }
															])
														}
														validationError={errors.cancellationReason}
														disabled={values.isPreviouslyCancelled.value}
													/>
												</Col>
											</Row>
											{isOptionOther(
												eventCancellationReasons,
												values.cancellationReason.value
											) && (
												<Row className={classes.formRow} nogutter>
													<Col xs={12}>
														<TextInputField
															id={`${PAGE_ID}-event-cancellation-other`}
															label="Other Reason"
															value={values.cancellationReasonOther.value}
															onChange={(e) => {
																handleChange(e, 'cancellationReasonOther');
															}}
															validationError={errors.cancellationReasonOther}
															fullWidth
															isDisabled={values.isPreviouslyCancelled.value}
														/>
													</Col>
												</Row>
											)}
											<Row className={classes.formRow} nogutter>
												<Col xs={12} lg={4}>
													<DateInputField
														label="Date RVEW were notified"
														value={values.dateNotified.value}
														isRequired
														fullWidth
														validationError={errors.dateNotified ? true : false}
														noMaxDate
														onChange={(value) =>
															handleDateUpdate(value, 'dateNotified', false)
														}
														disabled={values.isPreviouslyCancelled.value}
													/>
													{errors.dateNotified && (
														<div className={classes.errorText}>
															<ValidationErrorText
																errorText={errors.dateNotified}
															/>
														</div>
													)}
												</Col>
											</Row>
											<Row className={classes.formRow} nogutter>
												<Col xs={12}>
													<TextInputField
														id={`${PAGE_ID}-event-cancellation-other-details`}
														label="Other Details"
														value={values.otherDetails.value}
														onChange={(e) => {
															handleChange(e, 'otherDetails');
														}}
														validationError={errors.otherDetails}
														fullWidth
														isDisabled={values.isPreviouslyCancelled.value}
													/>
												</Col>
											</Row>
										</>
									)}
								</>
							)}
							<Row className={classes.formRow} nogutter>
								<Col xs={12} lg={4}>
									<div className={classes.multiSelectInput}>
										<div
											className={
												isSmallScreen
													? classes.formFieldLabelMobile
													: classes.formFieldLabel
											}
										>
											Ready to add event results?
										</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(val) =>
												handleChangeToggle(val, 'isAddEventResults')
											}
											value={values.isAddEventResults.value}
										/>
									</div>
								</Col>
							</Row>
							{values.isAddEventResults.value && (
								<>
									<Row className={classes.formRow} nogutter>
										<Col xs={12} lg={12}>
											<EditableTable
												id="event-results-table"
												columns={getColumnsEventResult()}
												data={eventResults}
												setData={setEventResults}
											/>
										</Col>
									</Row>
									<Row className={classes.formRow} nogutter>
										<Col xs={12} lg={12}>
											{eventResults && eventResults.length < 100 && (
												<Button
													size="small"
													variant="text"
													style={{
														color: '#006da6',
														textTransform: 'none',
														marginLeft: 12
													}}
													onClick={() => {
														setEventResults((prev) => [
															...prev,
															blankEventResult
														]);
													}}
												>
													Add row
												</Button>
											)}
											{eventResults && eventResults.length > 0 && (
												<Button
													size="small"
													variant="text"
													style={{ color: '#006da6', textTransform: 'none' }}
													onClick={() => {
														setEventResults((prev) => prev.slice(0, -1));
													}}
												>
													Remove row
												</Button>
											)}
										</Col>
									</Row>
								</>
							)}
						</div>
					</>
					<div className={classes.buttonContainer}>
						<div
							className={classes.cancelRequest}
							onClick={() => setConfirmationModal(true)}
						>
							{formId ? 'Delete Event' : 'Cancel Event'}
						</div>
						<div className={classes.saveRequest}>
							<div className={classes.buttonAdjustment}>
								{!values.isPreviouslyCancelled.value && (
									<span>
										<input
											type="button"
											value={
												competitionAdminRoleExist
													? EVENTS_LABEL.SAVE_DRAFT
													: `${
															formId
																? EVENTS_LABEL.UPDATE_EVENT
																: EVENTS_LABEL.SUBMIT_EVENT
													  }`
											}
											className={
												competitionAdminRoleExist
													? isSmallScreen
														? classes.saveFormButtonMobile
														: classes.saveFormButton
													: isSmallScreen
													? classes.saveFormButtonMobileCompetition
													: classes.saveFormButtonCompetition
											}
											onClick={(e) =>
												competitionAdminRoleExist
													? updateAddEvent(e)
													: updateEventByEventOrganiser(e)
											}
										/>
									</span>
								)}
								{!values.isCancelled.value && competitionAdminRoleExist && (
									<span>
										<input
											type="button"
											value={
												values.status.value === EVENTS_LABEL.APPROVED
													? EVENTS_LABEL.UPDATE_EVENT
													: EVENTS_LABEL.PUBLISH_EVENT
											}
											className={classes.completeFormButton}
											onClick={(e) => submitAddEvent(e)}
										/>
									</span>
								)}
								{!values.isPreviouslyCancelled.value &&
									values.isCancelled.value && (
										<span>
											<input
												type="button"
												value="Cancel Event"
												className={classes.cancelEventButton}
												onClick={(e) => cancelEvent(e)}
											/>
										</span>
									)}
								{values.isPreviouslyCancelled.value &&
									values.isCancelled.value && (
										<div style={{ cursor: 'text' }}>
											This event has already been cancelled.
										</div>
									)}
							</div>
						</div>
					</div>
					{isFormError && (
						<span className={classes.errorMessage}>
							Please complete all required fields
						</span>
					)}
				</FormSection>
			</div>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			<ConfirmationModal
				showModal={confirmationModal}
				closeModal={() => setConfirmationModal(false)}
				styles={modalStylesConfirmation}
				heading={formId ? 'Delete Event' : 'Cancel Event'}
				positiveLabel="Confirm"
				negativeLabel="Cancel"
				onClickPositive={() => (formId ? removeAddEvent() : cancelAddEvent())}
			>
				{`Are you sure you wish to ${formId ? 'delete' : 'cancel'} this form?`}
			</ConfirmationModal>
		</PageContainer>
	);
};

export default AddEvent;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	description: {
		fontSize: 12,
		backgroundColor: '#EDEDED',
		fontWeight: 400,
		width: 146,
		borderRadius: '0px 0px 5px 5px',
		height: 50,
		display: 'flex',
		alignItems: 'center',
		marginBottom: 20,
		padding: '0px 7px 0px 7px',
		textAlign: 'center'
	},
	mobileDescription: {
		extend: 'description',
		width: '100%',
		padding: 0,
		justifyContent: 'center'
	},
	mobileValue: {
		fontSize: 25,
		fontWeight: 600,
		height: 60,
		color: 'white',
		borderRadius: '5px 5px 0px 0px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	value: {
		extend: 'mobileValue',
		width: 160
	},
	statsContainer: {
		paddingTop: 12,
		width: 370
	},
	statsContainerMobile: {
		paddingTop: 15
	},
	infoContainer: {
		alignItems: 'center',
		width: '100%',
		marginLeft: '-10%'
	},
	infoContainerMobile: {
		alignItems: 'center',
		width: '100%',
		paddingBottom: 5,
		marginLeft: -15
	},
	heading: {
		fontSize: 16,
		fontWeight: 600,
		marginTop: 25,
		color: '#464749'
	},
	headingMobile: {
		fontSize: 16,
		fontWeight: 600,
		marginTop: 20,
		color: '#464749'
	}
}));

import React, { useEffect, useState } from 'react';
// import { MessageBarTypeEnum } from '../../utils/enums';
// import InfoBar from '../InfoBar';
import useStyles from './styles';
import {
	formatDate,
	extractLookUpOptions,
	formatDateReadableVariant,
	checkIsSmallScreen,
	RESETWeeksAvailable,
	convertToSortedMultiListData
} from '../../utils/helpers';
import { useToast } from '../../hooks/useToast';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getUserImage,
	uploadImageToS3,
	saveResetReportingImage,
	getListOfReferences,
	saveResetExpressInitialAssessmentForm,
	getResetExpressInitialAssessment,
	submitResetExpressInitialAssessmentForReview
} from '../../graphql/custom.queries';
import axios from 'axios';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import ResizingTextArea from '../ResizingTextArea';
import PrimaryButton from '../PrimaryButton';
import Toggle from '../Toggle';
import { useAuth } from '../../hooks/useAuth';
import FormDatePicker from '../FormDatePicker';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormDropDown from '../FormDropDown';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import { REVIEW, APPROVED, CommonKeys } from '../../utils/constants/index';
import LoaderSpinner from '../LoaderSpinner';
import Dialog from '../Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormLabelField from '../FormLabelField';
import FormTextField from '../FormTextField';
import FormMultiSelectList from '../FormMultiSelectList';
import BasicRadioButtonGroup from '../BasicRadioButtonGroup';
import { radioItems } from './constants';
import useDebounce from '../../hooks/useDebounce';
const schema = yup.object().shape({});

// const infoText =
// 	'As this is your first report, please complete the following Initial Assessment form within 14 days of the horse arriving.';

const AcknowledgedResetExpressInitialAssessmentForm = ({
	horseCode,
	horseData,
	index
	//reload
}) => {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});
	const { currentUser } = useAuth();
	const [horsePhotos, setHorsePhotos] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [overallCondition, setOverallCondition] = useState('');
	const [worming, setWorming] = useState(false);
	const [farrier, setFarrier] = useState(false);
	const [dentist, setDentist] = useState(false);
	const [vet, setVet] = useState(false);
	const [wormingDate, setWormingDate] = useState(null);
	const [farrierDate, setFarrierDate] = useState(null);
	const [dentistDate, setDentistDate] = useState(null);
	const [vetDate, setVetDate] = useState(null);
	const [otherHusbandryInfo, setOtherHusbandryInfo] = useState('');
	const [feedingPlan, setFeedingPlan] = useState('');
	const [temperament, setTemperament] = useState('');
	const [horseInteraction, setHorseInteraction] = useState('');
	const [horseHasVices, setHorseHasVices] = useState(false);
	const [viceOptions, setViceOptions] = useState([]);
	const [selectedVices, setSelectedVices] = useState([]);
	const [otherVices, setOtherVices] = useState('');
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingForm, setIsLoadingForm] = useState(false);
	const [openClearConfirmationDiag, setOpenClearConfirmationDiag] = useState(
		false
	);
	const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [habitsOtherReadOnly, setHabitsOtherReadOnly] = useState('');
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [
		isResetExpressDetailsLoaded,
		setIsResetExpressDetailsLoaded
	] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);
	let isValidationErrors = false;

	const [weekId, setWeekId] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? 51
			: horseData?.lastSubmittedForm?.weekId
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [weekIdText, setWeekIdText] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [injuriesList, setInjuriesList] = useState([]);
	const [injuriesValues, setInjuriesValues] = useState([]);
	const [wasInjured, setWasInjured] = useState(false);
	const [injuryOther, setInjuryOther] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('');
	const [horsePhysicalHealthDetails, setHorsePhysicalHealthDetails] = useState(
		''
	);
	const [
		horsePhysicalHealthDetailsFurther,
		setHorsePhysicalHealthDetailsFurther
	] = useState('');
	const debouncedWeekNumber = useDebounce(weekIdText, 700);

	const validateUncontrolledErrors = () => {
		validateWeekId();
		validateHorsePhotos();
		validateSelectedCategory();
		validateWormingDate();
		validateFarrierDate();
		validateDentistDate();
		validateVetDate();
		validateHorsePhysicalHealthFurther();
		validateInjuries();
		validateInjuriesOther();
		validateFeedingPlan();
		validateTemperament();
		validateHorseInteraction();
		validateOverallCondition();
		validateVices();
		validateVicesOther();
	};

	const validateFeedingPlan = () => {
		if (feedingPlan.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FeedingPlan: {
					message: 'You must enter the assessment of feeding requirements/plan.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FeedingPlan: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.FeedingPlan?.message) {
			validateFeedingPlan();
		}
	}, [feedingPlan]);

	const validateTemperament = () => {
		if (temperament.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Temperament: {
					message: `You must enter the assessment of the horse's temperament and general behaviour`
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Temperament: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Temperament?.message) {
			validateTemperament();
		}
	}, [temperament]);

	const validateHorseInteraction = () => {
		if (horseInteraction.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorseInteraction: {
					message: `You must enter the assessment of the horse's interactions with other horses`
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorseInteraction: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.HorseInteraction?.message) {
			validateHorseInteraction();
		}
	}, [horseInteraction]);

	const validateOverallCondition = () => {
		if (overallCondition.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OverallCondition: {
					message: `You must enter the overall condition of the horse`
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				OverallCondition: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.OverallCondition?.message) {
			validateOverallCondition();
		}
	}, [overallCondition]);

	const validateHorsePhotos = () => {
		if (horsePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horsePhotos: {
					message: 'You must upload at least one horse photo.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horsePhotos: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.horsePhotos?.message) {
			validateHorsePhotos();
		}
	}, [horsePhotos]);

	const validateSelectedCategory = () => {
		if (selectedCategory.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedCategory: {
					message: 'You must select one category.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedCategory: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.selectedCategory?.message) {
			validateSelectedCategory();
		}
	}, [selectedCategory]);

	const validateInjuries = () => {
		if (wasInjured && injuriesValues.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Injuries: {
					message: 'You must select at least one injury.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Injuries: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Injuries?.message) {
			validateInjuries();
		}
	}, [injuriesValues]);

	const validateInjuriesOther = () => {
		if (
			wasInjured &&
			injuriesValues.find(({ label }) => label === 'Other') &&
			!injuryOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				InjuriesOther: {
					message: 'Injury types - Other is required.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				InjuriesOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.InjuriesOther?.message) {
			validateInjuriesOther();
		}
	}, [injuryOther]);

	const validateVices = () => {
		if (horseHasVices && selectedVices.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Vices: {
					message: 'You must select at least one vices.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				Vices: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.Vices?.message) {
			validateVices();
		}
	}, [selectedVices]);

	const validateVicesOther = () => {
		if (
			horseHasVices &&
			selectedVices.find(({ label }) => label === 'Other') &&
			otherVices.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				VicesOther: {
					message: 'Vices - Other is required.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				VicesOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.VicesOther?.message) {
			validateVicesOther();
		}
	}, [otherVices]);

	const validateWormingDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WormingDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (worming && !wormingDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: {
						message: 'You must enter a Date for Worming.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: { message: null }
				}));
			}
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	useEffect(() => {
		if (uncontrolledErrors?.WormingDate?.message) {
			validateWormingDate();
		}
	}, [worming, wormingDate]);

	const validateFarrierDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FarrierDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (farrier && !farrierDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: {
						message: 'You must enter a Date for the Farrier.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.FarrierDate?.message) {
			validateFarrierDate();
		}
	}, [farrier, farrierDate]);

	const validateDentistDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				DentistDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (dentist && !dentistDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: {
						message: 'You must enter a Date for Dentistry.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: { message: null }
				}));
			}
		}
	};

	const validateVetDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				VetDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (vet && !vetDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					VetDate: {
						message: 'You must enter a Date for Vet.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					VetDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.DentistDate?.message) {
			validateDentistDate();
		}
	}, [dentist, dentistDate]);

	const validateHorsePhysicalHealthFurther = () => {
		if (
			horsePhysicalHealthDetails &&
			horsePhysicalHealthDetailsFurther.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorsePhysicalHealthFurther: {
					message:
						"You must provide more information with horse's physical health."
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				HorsePhysicalHealthFurther: {
					message: null
				}
			}));
		}
	};

	const validateWeekId = () => {
		if (!weekId || weekId == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekId: {
					message: 'You must select a week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekId: { message: null }
			}));
		}

		if (weekId == 51 && (!weekIdText || isNaN(weekIdText))) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekIdText: {
					message: 'You must enter a week number (numeric only).'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekIdText: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (
			uncontrolledErrors?.weekId?.message ||
			uncontrolledErrors?.weekIdText?.message
		) {
			validateWeekId();
		}
	}, [weekId, debouncedWeekNumber]);

	useEffect(() => {
		AppSyncService.execute(getListOfReferences).then((result) => {
			const injuriesLookups = extractLookUpOptions(result, 'listInjuryDetails');
			setViceOptions(extractLookUpOptions(result, 'listHabitsAndVices'));
			setInjuriesList(convertToSortedMultiListData(injuriesLookups));
		});
	}, []);

	useEffect(() => {
		if (
			(weekId && weekId != 0 && weekId != 51) ||
			(weekId == 51 && weekIdText && weekIdText != 0 && !isNaN(weekIdText))
		) {
			setDisableSubmitbutton(true);
			loadResetExpressInitialForm();
		}
	}, [weekId, debouncedWeekNumber]);

	const loadResetExpressInitialForm = () => {
		setIsLoadingForm(true);
		const input = {
			memberExternalId: currentUser?.UserId,
			horseCode,
			weekId: weekId >= 51 ? +weekIdText : weekId
		};
		try {
			AppSyncService.execute(getResetExpressInitialAssessment, input).then(
				(result) => {
					if (result?.data?.getResetExpressInitialAssessmentForm) {
						const initialData =
							result?.data?.getResetExpressInitialAssessmentForm;

						if (initialData?.status == null) {
							setIsShowErrorMessages(false);
						} else {
							setIsShowErrorMessages(true);
						}

						const ottcCategory =
							initialData.categoryId != null
								? radioItems.find((item) => item.id == initialData.categoryId)
										.value
								: '';
						setHorsePhotos(initialData.photosOfTheHorse ?? []);
						setSelectedCategory(ottcCategory);
						setOverallCondition(initialData.overallCondition ?? '');
						setWorming(!!initialData.worming);
						setFarrier(!!initialData.farrier);
						setDentist(!!initialData.dentist);
						setVet(!!initialData.vet);
						updateDate(
							initialData.worming ? initialData.worming : null,
							setWormingDate,
							validateWormingDate
						);
						updateDate(
							initialData.farrier ? initialData.farrier : null,
							setFarrierDate,
							validateFarrierDate
						);
						updateDate(
							initialData.dentist ? initialData.dentist : null,
							setDentistDate,
							validateDentistDate
						);
						updateDate(
							initialData.vet ? initialData.vet : null,
							setVetDate,
							validateVetDate
						);
						setOtherHusbandryInfo(initialData.otherHusbandryInfo ?? '');
						setFeedingPlan(initialData.feedingRequirementsPlan ?? '');
						setHorsePhysicalHealthDetails(
							initialData.horsePhysicalHealthDetails
						);
						setHorsePhysicalHealthDetailsFurther(
							initialData.horsePhysicalHealthDetailsFurther ?? ''
						);
						setWasInjured(initialData.anyInjuriesAilments);
						const injuryValues =
							initialData.injuries.length > 0
								? initialData.injuries.map((item) => ({
										label: item.value,
										value: item.id
								  }))
								: [];
						setInjuriesValues(injuryValues);
						setInjuryOther(initialData.anyInjuriesAilmentsOther);

						setHorseInteraction(initialData.assessmentHorseInteraction ?? '');

						setHorseHasVices(initialData.hasHorseDisplayedHabits);
						setTemperament(
							initialData.assessmentHorseTemperamentBehaviour ?? ''
						);
						if (initialData.habits.length > 0) {
							const mapHabits = initialData.habits.map((habit) => ({
								value: habit.id,
								label: habit.value
							}));
							setSelectedVices(mapHabits);
						}
						const mapHabits =
							initialData.habits.length > 0
								? initialData.habits.map((habit) => ({
										value: habit.id,
										label: habit.value
								  }))
								: [];
						setSelectedVices(mapHabits);
						const splitOtherHabits = initialData.habitsOther
							? initialData.habitsOther.split(',')
							: [];
						setHabitsOtherReadOnly(initialData.habitsOther ?? '');
						const mapHabitsOther =
							splitOtherHabits.length > 0
								? splitOtherHabits.map((otherHabit) => ({
										label: otherHabit,
										value: otherHabit
								  }))
								: [];
						setOtherVices(mapHabitsOther);
						setIsReadOnly(
							initialData.status === REVIEW || initialData.status === APPROVED
						);
						setUncontrolledErrors({});
					}
					setIsResetExpressDetailsLoaded(true);
					setIsLoadingForm(false);
				}
			);
		} catch (error) {
			addToast({ Message: 'Unable to fetch form', IsSuccess: false });
		}
	};

	useEffect(() => {
		if (isResetExpressDetailsLoaded) {
			validateUncontrolledErrors();
			if (isValidationErrors) {
				setDisableSubmitbutton(true);
			} else {
				setDisableSubmitbutton(false);
			}
			setIsResetExpressDetailsLoaded(false);
		}
	}, [isResetExpressDetailsLoaded]);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) {
				setDisableSubmitbutton(true);
			} else {
				setDisableSubmitbutton(false);
			}
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const updateDate = (value, stateAssignmentFunction, validationFunction) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			validationFunction(false);
		} else {
			validationFunction(true);
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const onSubmit = (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredSelectedVices = selectedVices.map(
			(selectedVice) => selectedVice.value
		);
		let injuryIDs = [];
		injuriesValues &&
			injuriesValues.map((item) => {
				injuryIDs.push(item.value);
			});
		const input = {
			horseCode: horseCode,
			weekId: +weekId === 51 ? weekIdText : weekId,
			categoryId:
				selectedCategory.length === 0
					? null
					: radioItems.find((item) => item.value === selectedCategory).id,
			overallCondition: overallCondition,
			worming: wormingDate,
			farrier: farrierDate,
			dentist: dentistDate,
			vet: vetDate,
			otherHusbandryInfo: otherHusbandryInfo,
			feedingRequirementsPlan: feedingPlan,
			horsePhysicalHealthDetails: horsePhysicalHealthDetails,
			horsePhysicalHealthDetailsFurther: horsePhysicalHealthDetailsFurther,
			habits: filteredSelectedVices,
			habitsOther:
				otherVices.length === 0
					? ''
					: otherVices.map((item) => item.value).toString(),
			injuryType: injuryIDs,
			anyInjuriesAilments: wasInjured ?? false,
			anyInjuriesAilmentsOther: injuryOther,
			assessmentHorseTemperamentBehaviour: temperament,
			assessmentHorseInteraction: horseInteraction,
			hasHorseDisplayedHabits: horseHasVices ?? false
		};
		setIsLoading(true);
		AppSyncService.execute(saveResetExpressInitialAssessmentForm, {
			input
		}).then((data) => {
			if (data.data !== null) {
				let photoArrays = [{ name: 'horsePhotos', arr: horsePhotos }];
				photoArrays.forEach((photoArray, index) => {
					photoArray.arr.map((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'ResetExpressInitial',
							reportId: data.data.saveResetExpressInitialAssessmentForm.id,
							type: photoArrays[index].name
						});
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					//reload();
					addToast({ Message: 'Draft Saved', IsSuccess: true });
				} else {
					AppSyncService.execute(submitResetExpressInitialAssessmentForReview, {
						horseCode: horseCode,
						weekId: weekId >= 51 ? weekIdText : weekId
					}).then((data) => {
						if (data?.data?.submitAckResetExpressInitialAssessmentForReview) {
							addToast({
								Message: 'Initial Assessment Form Submitted',
								IsSuccess: true
							});
							setIsReadOnly(true);
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
						}
					});
				}
			}
			if (!isSubmitReport) {
				if (!isValidationErrors) {
					setDisableSubmitbutton(false);
				}
			}
			setIsLoading(false);
		});
	};

	const handleOnSubmit = () => {
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			onSubmit(true);
		}
	};

	const onClearConfirmationDialog = () => {
		setOpenClearConfirmationDiag(true);
	};

	const onCloseClearConfirmationDialog = (e, reason) => {
		e.preventDefault();
		if (reason && reason == 'backdropClick') return;
		setOpenClearConfirmationDiag(false);
	};

	const onRenderClearDialogContent = () => {
		setConfirmationDialogTitle('Clear Form');
		return (
			<>
				<>
					<DialogContentText className={classes.dialogTextColor}>
						Are you sure you want to clear the form?
					</DialogContentText>
				</>
			</>
		);
	};

	const onRenderActionButtons = () => {
		return (
			<>
				<PrimaryButton
					id={CommonKeys.YES}
					onClick={() => clearForm()}
					isNonSubmit
				>
					Yes
				</PrimaryButton>

				<PrimaryButton
					id={CommonKeys.CANCEL}
					style={{ backgroundColor: '#c62828' }}
					onClick={(e) => onCloseClearConfirmationDialog(e)}
					isNonSubmit
				>
					Cancel
				</PrimaryButton>
			</>
		);
	};

	const clearForm = () => {
		deleteAllPhotos();
		setHorseHasVices(false);
		setOverallCondition('');
		setWorming(false);
		setFarrier(false);
		setDentist(false);
		setWormingDate(null);
		setFarrierDate(null);
		setDentistDate(null);
		setOtherHusbandryInfo('');
		setFeedingPlan('');
		setTemperament('');
		setSelectedVices([]);
		setOtherVices('');
		setOpenClearConfirmationDiag(false);
		setWeekId('');
		setWeekIdText('');
		setSelectedCategory('');
		setVet(false);
		setVetDate(null);
		setHorsePhysicalHealthDetailsFurther('');
		setWasInjured(false);
		setInjuriesValues([]);
		setInjuryOther('');
		setHorseInteraction('');
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
				setShouldValidate(true);
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[horsePhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setHorsePhotos([]);
				}
			});
		}
	};

	const onSelectRadioItem = (e) => {
		setSelectedCategory(e.target.value);
	};
	return (
		<div className={classes.container}>
			{isLoadingForm ? (
				<LoaderSpinner
					status={isLoadingForm}
					styles={{ display: 'flex', justifyContent: 'center', padding: 20 }}
				/>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit()}>
						<div className={classes.formHeading}>Initial Assessment Form</div>
						<Container>
							<div className={classes.formHeading}>Horse details</div>
							<Row>
								<Col xs={12} lg={6}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											How many weeks has the horse been in your care?*
										</div>
										<FormDropDown
											items={RESETWeeksAvailable()}
											selectedId={weekId}
											onChange={(e) => {
												setWeekId(e.target.value);
												setShouldValidate(true);
											}}
											showBlank
										/>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors.weekId?.message}
											</span>
										)}
									</div>
								</Col>
								{+weekId === 51 && (
									<Col xs={12} lg={6}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Week Number*</div>
											<FormTextField
												value={weekIdText}
												onChange={(e) => {
													setWeekIdText(e.target.value);
													setShouldValidate(true);
												}}
												styles={{ height: 38 }}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorStyle}>
													{uncontrolledErrors.weekIdText?.message}
												</span>
											)}
										</div>
									</Col>
								)}
								<Col xs={12} lg={6}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Photos of the horse (jpg, png)*
										</div>
										<UploadPhoto
											id={`RESETExpressInitial-photos-${index}`}
											formats={['png', 'jpeg']}
											labelName="Upload Photos of the horse"
											error={uploadError}
											data={horsePhotos}
											sizeLimitMB={5}
											numberOfImages={5}
											success={(e) => photosUpload(e, setHorsePhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, horsePhotos, setHorsePhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
									<Row>
										<span style={{ fontStyle: 'italic' }}>
											Please ensure clear photos, full body, front and rear,
											taken on or as close as possible to this assessment being
											completed.
										</span>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.horsePhotos?.message}
											</span>
										)}
									</Row>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Which category was the horse listed as on the OTTC?*
										</div>
										<BasicRadioButtonGroup
											value={selectedCategory}
											radioItems={radioItems}
											onHandleChange={onSelectRadioItem}
											row
											disabled={isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorStyle}>
											{uncontrolledErrors?.selectedCategory?.message}
										</span>
									)}
								</Col>
							</Row>
							<hr className={classes.divider} />
							<div className={classes.formHeading}>Physical assessment</div>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Describe the overall condition of the horse*{' '}
													<span className={classes.fontWeightNormal}>
														(body condition, feet condition, note any
														superficial wounds/abrasions)
													</span>
												</div>
											}
											id={`ResizingTextArea-overallCondition-${index}`}
											onChange={(e) => {
												setShouldValidate(true);
												setOverallCondition(e.target.value);
											}}
											maxChars={700}
											value={overallCondition}
											titleStyles={{
												fontSize: 14
											}}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.OverallCondition?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Worming</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setWorming(value);
												setShouldValidate(true);
											}}
											value={worming}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{worming && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date*"
														value={formatDateReadableVariant(wormingDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>Date*</div>
												<FormDatePicker
													DateValue={wormingDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setWormingDate,
															validateWormingDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.WormingDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Farrier</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setFarrier(value);
												setShouldValidate(true);
											}}
											value={farrier}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{farrier && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date*"
														value={formatDateReadableVariant(farrierDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>Date*</div>
												<FormDatePicker
													noMaxDate={true}
													DateValue={farrierDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setFarrierDate,
															validateFarrierDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.FarrierDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Dentist</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setDentist(value);
												setShouldValidate(true);
											}}
											value={dentist}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{dentist && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date*"
														value={formatDateReadableVariant(dentistDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>Date*</div>
												<FormDatePicker
													noMaxDate={true}
													DateValue={dentistDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setDentistDate,
															validateDentistDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.DentistDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Vet</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setVet(value);
												setShouldValidate(true);
											}}
											value={vet}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{vet && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date*"
														value={formatDateReadableVariant(vetDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>Date*</div>
												<FormDatePicker
													noMaxDate={true}
													DateValue={vetDate}
													updateSelection={(value) => {
														updateDate(value, setVetDate, validateVetDate);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.VetDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>Other husbandry care required or planned?</div>
											}
											id={`ResizingTextArea-otherHusbandryInfo-${index}`}
											onChange={(e) => setOtherHusbandryInfo(e.target.value)}
											maxChars={500}
											value={otherHusbandryInfo}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Assessment of feeding requirements/plan{' '}
													<span className={classes.fontWeightNormal}>
														(feeding to maintain or increase condition, feeding
														to let down etc)
													</span>
													*
												</div>
											}
											id={`ResizingTextArea-feedingPlan-${index}`}
											onChange={(e) => {
												setFeedingPlan(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={feedingPlan}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.FeedingPlan?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={12}>
									<div className={classes.fieldTitle}>
										{`Do you have any concerns regarding the horse's physical condition that may affect the horse's future retraining?`}
									</div>
								</Col>

								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setHorsePhysicalHealthDetails(value);
												if (!value) {
													setHorsePhysicalHealthDetails('');
												}
												setShouldValidate(true);
											}}
											value={horsePhysicalHealthDetails}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{horsePhysicalHealthDetails && (
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<Controller
												control={control}
												name="HorsePhysicalHealthFurther"
												render={({ onChange }) => (
													<ResizingTextArea
														title="Please detail further*"
														id={`ResizingTextArea-horsePhysicalHealthDetailsFurther-${index}`}
														onChange={(e) => {
															onChange(e.target.value);
															setHorsePhysicalHealthDetailsFurther(
																e.target.value
															);
															setShouldValidate(true);
														}}
														maxChars={700}
														value={horsePhysicalHealthDetailsFurther}
														titleStyles={{ fontSize: '14px' }}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorStyle}>
													{
														uncontrolledErrors?.HorsePhysicalHealthFurther
															?.message
													}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldTitle}>
										{`Have you identified any injuries or ailments that were not disclosed?`}
									</div>
									<div className={classes.fieldContainer}>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setWasInjured(value);
												setShouldValidate(true);
											}}
											value={wasInjured}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{wasInjured ? (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<div style={{ padding: '10px 0px 10px 0px' }}>
												<FormLabelField
													title={`If yes, please select injury type(s)*`}
													value={injuriesValues
														.map((injuries) => injuries.label)
														.join(', ')}
													styles={{ padding: 0 }}
												/>
											</div>
										) : (
											<div
												className={classes.fieldContainer}
												style={{ marginTop: 25 }}
											>
												<div className={classes.fieldTitle}>
													If yes, please select injury type(s)*
												</div>
												<FormMultiSelectList
													id="horsePlacement-injuries"
													name="injuries"
													listOptions={injuriesList}
													selectedIds={injuriesValues}
													onSelectChange={(value) => {
														setShouldValidate(true);
														setInjuriesValues(value);
													}}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorStyle}>
														{uncontrolledErrors?.Injuries?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								) : null}
							</Row>
							{wasInjured &&
								injuriesValues.find(({ label }) => label === 'Other') && (
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainer}>
												<Controller
													control={control}
													name="InjuryOther"
													render={({ onChange }) => (
														<ResizingTextArea
															title="Injury types - Other*"
															id={`ResizingTextArea-injuryOther-${index}`}
															onChange={(e) => {
																onChange(e.target.value);
																setInjuryOther(e.target.value);
																setShouldValidate(true);
															}}
															maxChars={500}
															value={injuryOther}
															titleStyles={{ fontSize: '14px' }}
															canEdit={!isReadOnly}
														/>
													)}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorStyle}>
														{uncontrolledErrors?.InjuriesOther?.message}
													</span>
												)}
											</div>
										</Col>
									</Row>
								)}
							<hr className={classes.divider} />
							<div className={classes.formHeading}>Behavioural assessment</div>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Your assessment of the {`horse's`} temperament and
													general behaviour{' '}
													<span className={classes.fontWeightNormal}>
														(Nervous, relaxed, aggressive, sensitive etc.)
													</span>
													*
												</div>
											}
											id={`ResizingTextArea-temperament-${index}`}
											onChange={(e) => {
												setTemperament(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={temperament}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.Temperament?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={`Your assessment of the horse's interactions with other horses*`}
											id={`ResizingTextArea-horseInteraction-${index}`}
											onChange={(e) => {
												setHorseInteraction(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={horseInteraction}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.HorseInteraction?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Any vices displayed?`}
										</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setHorseHasVices(value);
												if (!value) {
													setSelectedVices([]);
												}
												setShouldValidate(true);
											}}
											value={horseHasVices}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{horseHasVices && (
									<Col xs={12}>
										{isReadOnly ? (
											<div style={{ padding: '10px 0px 10px 0px' }}>
												<FormLabelField
													title={`Select vices or bad habits that ${horseData?.horseName} has`}
													value={selectedVices
														.map((selectedHabit) => selectedHabit.label)
														.join()}
													style={{ padding: 0 }}
												/>
											</div>
										) : (
											<div className={classes.fieldContainer}>
												<MultiSelectInputField
													idPrefix={`vices`}
													fieldLabel={`Select vices or bad habits that ${horseData?.horseName} has`}
													selectedIds={selectedVices}
													options={viceOptions}
													onSelectChange={(newVices) => {
														setSelectedVices(newVices);
														if (
															!newVices.find(({ label }) => label === 'Other')
														) {
															setOtherVices('');
														}
														setShouldValidate(true);
													}}
													otherValue={otherVices}
													onOtherChange={(newOtherValue) => {
														setOtherVices(newOtherValue);
														setShouldValidate(true);
													}}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorStyle}>
														{uncontrolledErrors?.Vices?.message}
													</span>
												)}
												{isShowErrorMessages && (
													<span className={classes.errorStyle}>
														{uncontrolledErrors?.VicesOther?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{isReadOnly && habitsOtherReadOnly.length > 0 ? (
									<Col xs={12}>
										<FormLabelField
											title="Other"
											value={habitsOtherReadOnly}
											style={{ padding: 0 }}
										/>
									</Col>
								) : null}
							</Row>
							<hr className={classes.divider} />
							{isLoading ? (
								<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: `${
											isReadOnly ? 'flex-end' : 'space-between'
										}`,
										alignItems: 'center'
									}}
								>
									{!isReadOnly && (
										<div
											onClick={() => onClearConfirmationDialog()}
											className={classes.clearFormLink}
										>
											Clear Form
										</div>
									)}

									<div
										className={
											isSmallScreen
												? classes.saveRequestMobile
												: classes.saveRequest
										}
									>
										<span className={isSmallScreen ? null : classes.saveButton}>
											<PrimaryButton
												id={`Save-Primary-Button-${index}`}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												onClick={() => onSubmit()}
												disabled={isReadOnly}
											>
												Save
											</PrimaryButton>
										</span>
										<span>
											<PrimaryButton
												id={`Submit-Primary-Button-${index}`}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												disabled={disableSubmitButton || isReadOnly}
												onClick={() => handleOnSubmit()}
											>
												Submit Report
											</PrimaryButton>
										</span>
									</div>
								</div>
							)}
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
						{showImageUploadErrorModal && (
							<UploadPhotoErrorModal
								showErrorModal={showImageUploadErrorModal}
								closeModal={() => setShowImageUploadErrorModal(false)}
								isMinimumUploadSize={isMinimumUploadSize}
							/>
						)}
					</form>
					<Dialog
						openDialog={openClearConfirmationDiag}
						handleClose={onCloseClearConfirmationDialog}
						title={confirmationDialogTitle}
						contentChildren={onRenderClearDialogContent}
						actionButtons={onRenderActionButtons}
					/>
				</div>
			)}
		</div>
	);
};

export default AcknowledgedResetExpressInitialAssessmentForm;

export const extractCardsList = (response) => {
	return response
		.map((cardData) => ({
			title: cardData?.title,
			summary: cardData?.text?.text,
			image: cardData?.image?.file?.url,
			order: cardData?.order
		}))
		.sort((a, b) => a.order - b.order);
};

import { createUseStyles } from "react-jss";
export default createUseStyles(() => ({
  container: {
    display: "flex",
    position: "relative",
  },
  iconsContainer: {
    paddingRight: 3,
  },
  iconText: {
    position: "absolute",
    zIndex: 50,
    top: 30,
    left: "50%",
    background: "#FFFFFF;",
    boxShadow: "0 2px 9px 0 rgba(0,0,0,0.29)",
    textAlign: "center",
    padding: "15px 20px",
    borderRadius: 30,
    transform: "translate(-50%, 0%)",
  }
}));
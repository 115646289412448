import React, { Fragment, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import PageContainer from '../PageContainer';
import { OTT_PARTNERSHIPS } from './constants';
import PartnershipCard from './components/PartnershipCard';
import { StyledDivider } from './styles';

const EventPartnerships = () => {
	const [partnerships, setPartnerships] = useState([]);

	const staticPartnerships = useStaticQuery(
		graphql`
			query eventPartnerships {
				allContentfulOttPartnerships(sort: { fields: order, order: ASC }) {
					nodes {
						id
						name
						url
						description {
							raw
						}
						logo {
							file {
								url
							}
							title
						}
						media {
							file {
								contentType
								url
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		setPartnerships(staticPartnerships.allContentfulOttPartnerships.nodes);
	}, []);

	return (
		<Fragment>
			<SEO title={OTT_PARTNERSHIPS} />
			<PageContainer>
				<HeaderBanner title={OTT_PARTNERSHIPS} />
				{partnerships.map((partnership, index) => (
					<Fragment key={partnership.id}>
						<PartnershipCard partnership={partnership} />
						{index !== partnerships.length - 1 && <StyledDivider />}
					</Fragment>
				))}
			</PageContainer>
		</Fragment>
	);
};

export default EventPartnerships;

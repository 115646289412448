import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import { reviewResetExpressInitialAssessmentForm } from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { getImage } from './utils';
import { formatDateReadableVariant } from '../../utils/helpers';
import { radioItems } from '../AcknowledgedResetExpressInitialAssessmentForm/constants';

const ResetExpressInitialFormReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewResetExpressInitialAssessmentForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveResetExpressInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewResetExpressInitialAssessmentForm, {
			id: parseInt(data.id),
			status: 'Approved',
			reviewNotes: notes,
			reviewReasons: rejectReason
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveResetExpressInitialAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressInitialAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const getVices = () => {
		let vices = '';
		vices = data.habits.map((vices) => vices.value).join(', ');
		return vices.length > 0 ? vices : '-';
	};

	const getInjuries = () => {
		let injury = '';
		injury = data.injuries.map((injury) => injury.value).join(', ');
		return injury.length > 0 ? injury : '-';
	};

	const getCategory = () => {
		return radioItems.find((item) => item.id == data.categoryId).label;
	};

	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="RESET 2 Initial Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastUpdated
									? formatDateReadableVariant(data.lastUpdated)
									: ''
							}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Week Number" value={data.weekId} />
					</Col>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Photos of the horse
							</div>
							<UploadPhoto
								data={data.photosOfTheHorse}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Which category was the horse listed as on the OTTC?*"
							value={getCategory()}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Describe overall condition (body condition, feet condition, note any superficial wounds/abrasions)*"
							value={data.overallCondition}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Worming"
							value={data.worming ? 'Yes' : 'No'}
						/>
						{data.worming && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.worming)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Farrier"
							value={data.farrier ? 'Yes' : 'No'}
						/>
						{data.farrier && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.farrier)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Dentist"
							value={data.dentist ? 'Yes' : 'No'}
						/>
						{data.dentist && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.dentist)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField title="Vet" value={data.vet ? 'Yes' : 'No'} />
						{data.vet && (
							<FormLabelField
								title="Date Of Completion"
								value={formatDateReadableVariant(data.vet)}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Other Husbandry care required or planned?"
							value={data.otherHusbandryInfo}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Assessment of feeding requirements/plan (feeding to maintain or increase condition, feeding to let down etc)*"
							value={data.feedingRequirementsPlan}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Do you have any concerns regarding the horse's physical condition that may affect the horse's future retraining?`}
							value={data.horsePhysicalHealthDetails ? 'Yes' : 'No'}
						/>
						{data.horsePhysicalHealthDetailsFurther && (
							<FormLabelField
								title="Please detail further*"
								value={data.horsePhysicalHealthDetailsFurther}
							/>
						)}
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Have you identified any injuries or ailments that were not disclosed?"
							value={data.anyInjuriesAilments ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Injuries" value={getInjuries()} />
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Injuries-Other"
							value={data.anyInjuriesAilmentsOther}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Your assessment of the horse's temperament and general behaviour*`}
							value={data.assessmentHorseTemperamentBehaviour}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Your assessment of the horse's interactions with other horses*`}
							value={data.assessmentHorseInteraction}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Any vices displayed?"
							value={data.hasHorseDisplayedHabits ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField title="Vice(s)" value={getVices()} />
					</Col>
					<Col xs={12}>
						<FormLabelField title="Vice(s)-Other" value={data.habitsOther} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.horse?.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.horse?.colour} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${data.member?.firstName} ${data.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.member?.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.member.phone} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ResetExpressInitialFormReviewInfo;

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileTopContainer: {
		maxWidth: '100%',
		background: 'white',
		margin: '80px 20px 40px 20px'
	},
	topContainer: {
		maxWidth: '100%',
		background: 'white'
	},
	articleText: {
		marginBottom: 10
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formSectionContentDivider: {
		height: 1,
		backgroundColor: ' #dcebf3',
		border: 'none',
		width: '100%',
		marginBottom: 0
	}
}));

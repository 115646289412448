import { Col, Row } from 'react-grid-system';
import styled, { css } from 'styled-components';
import HeaderBanner from '../HeaderBanner';
import { StyledErrorText } from '../OHEPForm/style';
import PageContainer from '../PageContainer';
import PrimaryButton from '../PrimaryButton';

export const StyledLoadingContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const StyledContainer = styled(PageContainer)`
	margin-top: 5rem;
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		margin-top: 0.5rem;
	}
`;

export const StyledHeaderBanner = styled(HeaderBanner)`
	border-radius: 5px;
	font-size: 13px;
`;

export const StyledAccordionContainer = styled.div`
	padding: 10px 20px;
	h2 {
		margin-bottom: 0;
		font-size: 14px;
		color: ${({ theme: { colors } }) => colors.text};
	}
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.md}) {
		border-left: 1px solid #b3bcc1;
		border-right: 1px solid #b3bcc1;
		h2 {
			font-size: 18px;
		}
	}
	${(props) =>
		props.isLast &&
		css`
			border-bottom: 1px solid #b3bcc1;
		`}
`;

export const StyledHorseDetailsContainer = styled.div`
	background-color: #f0f0f0;
`;

export const StyledAdminAccordionContainer = styled(StyledAccordionContainer)`
	background-color: #f3f3f3;
`;

export const StyledFieldContainer = styled.div`
	padding: 1.25rem 1.125rem;
`;

export const StyledFieldLabel = styled.label`
	display: block;
	font-weight: 600;
	color: #464749;
	font-size: 13px;
	margin-bottom: 7px;
	@media (min-width: 992px) {
		font-size: 15px;
	}
`;

export const StyledRehomingAttemptDetailsRow = styled(Row)`
	margin-bottom: 0.5rem;
	@media (min-width: 992px) {
		align-items: center;
	}
`;

export const StyledActionsButtonRow = styled(Row)`
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		justify-content: flex-end !important;
	}
`;

export const StyledActionsButtonCol = styled(Col)`
	@media (min-width: ${({ theme: { breakpoints } }) => breakpoints.lg}) {
		display: flex;
		justify-content: flex-end;
	}
`;

export const StyledEligibilityAssessmentDocsRow = styled(Row)`
	width: 100%;
`;

export const StyledFormMainErrorContainer = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const StyledFieldLabelWithCharRemainingContainer = styled.div`
	display: flex;
	justify-content: space-between;
	.characters-remaining {
		color: #909195;
		font-size: 12px;
	}
`;

export const StyledFileFieldContainer = styled(StyledFieldContainer)`
	padding-left: 0px;
	padding-right: 0px;
`;

export const StyledWarningText = styled(StyledErrorText)`
	color: #a020f0;
`;

export const StyledFormSubmitButton = styled(PrimaryButton)`
	width: 100%;
	@media (min-width: 992px) {
		width: 200px !important;
	}
`;

export const getPrpPostLikes = `
query getPrpPostLikes($post_id: Int!) {
    getPrpPostLikes(post_id: $post_id){
        member_id
        nextToken
    }
    countPrpPostLikes(post_id: $post_id)
}
`;

export const createPrpPostLikes = `
mutation createPrpPostLikes($post_id: Int!) {
    createPrpPostLikes(post_id: $post_id){
        member_id
        nextToken
    }
}
`;

export const deletePrpPostLikes = `
mutation deletePrpPostLikes($postId: Int!) {
    deletePrpPostLikes(postId: $postId){
        member_id
        nextToken
    }
}
`;

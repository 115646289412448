import React from 'react';
import useStyles, { IMAGE_CONTAINER_COLUMN_STYLES } from './styles';
import { Col, useScreenClass } from 'react-grid-system';
import classnames from 'classnames';
import { OUTCOME_PAGE_TYPE } from '../../constants';
import { checkIsSmallScreen } from '../../../../utils/helpers';
import StepOption from '../StepOption';
import MoreInformationSection from '../MoreInformationSection';

const TransitionStep = ({
	stepNumber,
	pageType,
	isActive,
	question,
	options,
	onOptionClick,
	stepImage,
	moreInformation,
	isButtonsDisabled
}) => {
	const classes = useStyles();
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const isOutcomeStep = pageType === OUTCOME_PAGE_TYPE;
	const result = isActive ? (
		<>
			<div
				className={classnames({
					[classes.transitionStepContainer]: true,
					[classes.outcomeStep]: isOutcomeStep
				})}
			>
				<Col
					lg={6}
					className={classes.questionContainer}
					style={{ padding: isSmallScreen ? 15 : 35 }}
				>
					<h1 className={classes.question}>{question}</h1>
					<div className={classes.optionsContainer}>
						{options.map((option) => (
							<StepOption
								key={`${option.optionId}`}
								id={`${stepNumber}-button-${option.optionId}`}
								onClick={() =>
									onOptionClick(stepNumber, option.optionId, option.link)
								}
								isFilled={+option.optionId === 1}
								isOutcome={isOutcomeStep}
								isDisabled={isButtonsDisabled}
							>
								{option.name}
							</StepOption>
						))}
					</div>
				</Col>
				<Col
					lg={6}
					className={classes.imageContainer}
					style={IMAGE_CONTAINER_COLUMN_STYLES}
				>
					<img
						id={`${stepNumber}-image`}
						src={stepImage.fluid.src}
						alt="Transition step image"
						className={classes.imageStyles}
					/>
				</Col>
			</div>
			{moreInformation && (
				<MoreInformationSection moreInformation={moreInformation} />
			)}
		</>
	) : null;
	return result;
};
export default TransitionStep;

import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form-v7';
import axios from 'axios';
import SEO from '../seo';
import LoaderSpinner from '../LoaderSpinner';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import {
	StyledCancelRequest,
	StyledContainer,
	StyledErrorMessage,
	StyledErrorText,
	StyledFieldContainer,
	StyledFieldLabel,
	StyledForm,
	StyledFormActionLink,
	StyledFormActionsRow,
	StyledFormEndDivider,
	StyledFieldText,
	StyledFormFieldsContainer,
	StyledFormSubmitButton,
	StyledLoadingContainer,
	StyledMessageBar,
	StyledSaveDraft,
	StyledWhatIsOHEPButton,
	StyledMailLink,
	StyledFormCheckboxFieldLabel,
	StyledFormCheckboxFieldContainer,
	StyledFormTextArea,
	StyledSupportingDocumentationRow,
	StyledGenericStatementContainer,
	StyledSupportingDocumentationCol,
	StyledGenericStatementRow,
	StyledNominationContactMessageBar,
	modalStylesConfirmation,
	StyledStatutoryDeclarationDownloadButton,
	StyledResidentialAddressCheckboxTitle,
	CHECKBOX_ERROR_STYLES
} from './style';
import { MessageBarTypeEnum } from '../../utils/enums';
import NewTabLink from '../NewTabLink';
import { REVIEW, DEFAULT_COUNTRY } from '../../utils/constants';
import {
	DASHBOARD_ROUTE,
	OHEP_PRIVACY_ROUTE,
	PAGE_NOT_FOUND_ROUTE,
	PROGRAMS_OHEP_ROUTE
} from '../../utils/constants/routes';
import StyledAccordion from './StyledAccordion';
import MessageBar from '../MessageBar';
import FormTextField from '../FormTextField';
import { Col, Row } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteOhepNominationFile,
	deleteOhepNominationForm,
	saveOhepNominationFile,
	saveOhepNominationForm,
	saveOhepNominationFormStatus,
	saveResidentialAddress,
	updateMemberProfile,
	uploadImageToS3,
	deleteImagesFromS3,
	deleteOhepNominationFileByOhepNominationFormId
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './schema';
import { useParams } from '@reach/router';
import UploadPhoto from '../UploadPhoto';
import useFilePreview from '../../hooks/useFilePreview';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import SimpleCheckBox from '../SimpleCheckBox';
import Divider from '../Divider';
import { checkUserAdmin, formatDate } from '../../utils/helpers';
import FormDatePicker from '../FormDatePicker';
import {
	AT_LEAST_ONE_ERROR_MESSAGE,
	OHEP_FORM_DEFAULT_VALUES,
	OHEP_FORM_DEFAULT_VALUES_WITHOUT_CONTACT
} from './constants';
import FileUploadInputField from '../FormComponents/FileUploadInputField';
import { WORD_DOC_MIME_TYPE } from '../../utils/constants';
import { FaDownload } from 'react-icons/fa';
import ohepStatutoryDeclarationPdfFile from '../../assets/docs/OHEP_Statutory_Declaration.pdf';
import { getOhepFormData } from './queries';
import { generatePayload, setFormValuesFromApiResult } from './helper';
import { logToServer } from '../../utils/logger';
import { navigate } from 'gatsby';
import ConfirmationModal from '../ConfirmationModal';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import CountrySelector from '../CountrySelector';
import StateSelector from '../StateSelector';
import { capitalizeFirstLetter } from './utils';
import SubmitOnBehalf from '../SubmitOnBehalf';

const OHEPForm = () => {
	const PAGE_ID = 'ohep-Form';
	const [formId, setFormId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [relationshipToHorseOptions, setRelationshipToHorseOptions] = useState(
		[]
	);
	const [
		reasonForNominationForEuthanasiaOptions,
		setReasonForNominationForEuthanasiaOptions
	] = useState([]);
	const [memberData, setMemberData] = useState({});
	const [horseData, setHorseData] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isFormError, setIsFormError] = useState(false);
	const [isFormInReview, setIsFormInReview] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');
	const [isPostalAddressManual, setIsPostalAddressManual] = useState(false);
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [isClearFields, setIsClearFields] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		getValues,
		formState: { errors, isSubmitted },
		reset
	} = useForm({
		mode: 'onSubmit',
		defaultValues: OHEP_FORM_DEFAULT_VALUES,
		resolver: yupResolver(schema)
	});
	const { id: horseCode } = useParams();
	const filePreviewHandler = useFilePreview();
	const values = watch();
	const isLocation1MonthOrLess =
		+values.currentAddressDurationMonths <= 1 &&
		(values.currentAddressDurationYears === null ||
			+values.currentAddressDurationYears <= 0);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);

	const manualContactAddressCheckBoxDataByAdmin = {
		label: 'Manually add my residential address',
		value: 'Manually add my residential address',
		isSelected: isPostalAddressManual
	};

	const fetchData = async () => {
		try {
			const { data } = await AppSyncService.execute(getOhepFormData, {
				memberExternalId: currentUser.UserId,
				horseCode
			});
			if (data?.getHorseProfile?.status?.status?.id !== 19 && !isAdminUser) {
				navigate(PAGE_NOT_FOUND_ROUTE);
			}
			setReasonForNominationForEuthanasiaOptions(
				data.listOhepNominationReasons
			);
			setRelationshipToHorseOptions([
				{ id: 0, value: '' },
				...data.listOhepNominationRelationships
			]);
			setMemberData(data.getMember);
			if (data.getMember.phone) {
				setValue('contactNumber', data.getMember.phone);
			}
			setHorseData(data.getHorseProfile.horse);
			setValue(
				'contactAddress',
				data.getMember.residentialAddress?.address ||
					OHEP_FORM_DEFAULT_VALUES.contactAddress
			);
			setValue(
				'contactSuburb',
				data.getMember.residentialAddress?.suburb ||
					OHEP_FORM_DEFAULT_VALUES.contactSuburb
			);
			setValue(
				'contactPostcode',
				data.getMember.residentialAddress?.state ||
					OHEP_FORM_DEFAULT_VALUES.contactPostcode
			);
			setValue(
				'contactState',
				data.getMember.residentialAddress?.postcode ||
					OHEP_FORM_DEFAULT_VALUES.contactState
			);
			setValue(
				'contactCountry',
				data.getMember.residentialAddress?.country ||
					OHEP_FORM_DEFAULT_VALUES.contactCountry
			);
			if (data.getOhepNominationForm.id) {
				setFormValuesFromApiResult(data.getOhepNominationForm, setValue);
				setFormId(data.getOhepNominationForm.id);
				setIsFormInReview(data.getOhepNominationForm.status === 'Review');
			}
			setIsLoading(false);
		} catch (error) {
			addToast({
				Message: 'Failed to fetch data',
				IsSuccess: false
			});
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	useEffect(() => {
		if (isClearFields) {
			reset((formValues) => ({
				...formValues,
				...OHEP_FORM_DEFAULT_VALUES_WITHOUT_CONTACT
			}));
			setIsClearFields(false);
		}
	}, [isClearFields]);

	const getImagesList = () => {
		let listOfKeys = [];
		if (values.rightSidePhotos.length > 0) {
			values.rightSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.leftSidePhotos.length > 0) {
			values.leftSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.rspcaNoticeToComplyFiles.length > 0) {
			values.rspcaNoticeToComplyFiles.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.statuaryDeclarationFiles.length > 0) {
			values.statuaryDeclarationFiles.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.veterinaryCertificateFiles.length > 0) {
			values.veterinaryCertificateFiles.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.evidenceOfDangerousBehaviourFiles.length > 0) {
			values.evidenceOfDangerousBehaviourFiles.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (values.otherFiles.length > 0) {
			values.otherFiles.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		return listOfKeys;
	};

	const onClearFields = () => {
		let listOfKeys = getImagesList();
		if (listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys);
		}
		setIsClearFields(true);
		// setValue(OHEP_FORM_DEFAULT_VALUES);
	};

	const removeImagesFromSelectedUser = async (listOfKeys) => {
		const result = await AppSyncService.execute(deleteImagesFromS3, {
			imageKeys: listOfKeys
		});
		if (result?.data?.deleteUserImages?.success) {
			const deletedFileResult = await AppSyncService.execute(
				deleteOhepNominationFileByOhepNominationFormId,
				{
					ohepNominationFormId: formId
				}
			);
			if (
				deletedFileResult?.data?.deleteOhepNominationFileByOhepNominationFormId
			) {
				setValue('leftSidePhotos', OHEP_FORM_DEFAULT_VALUES.leftSidePhotos);
				setValue('rightSidePhotos', OHEP_FORM_DEFAULT_VALUES.rightSidePhotos);
				setValue(
					'rspcaNoticeToComplyFiles',
					OHEP_FORM_DEFAULT_VALUES.rspcaNoticeToComplyFiles
				);
				setValue(
					'veterinaryCertificateFiles',
					OHEP_FORM_DEFAULT_VALUES.veterinaryCertificateFiles
				);
				setValue(
					'evidenceOfDangerousBehaviourFiles',
					OHEP_FORM_DEFAULT_VALUES.evidenceOfDangerousBehaviourFiles
				);
				setValue(
					'statuaryDeclarationFiles',
					OHEP_FORM_DEFAULT_VALUES.statuaryDeclarationFiles
				);
				setValue('otherFiles', OHEP_FORM_DEFAULT_VALUES.otherFiles);
			}
		}
	};

	const updateProfile = async ({ phone }) => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		try {
			let isUpdated = false;
			if (!memberData.phone && !isSubmitOnBehalf) {
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData: { phone }
				});
				isUpdated = true;
			} else if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
				let updateMemberProfileData = {
					...(phone && { phone: phone }),
					...(isSubmitOnBehalf &&
						selectedUserVerifiedInfo && {
							externalId: selectedUserVerifiedInfo.memberExternalId
						})
				};
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData
				});
				isUpdated = true;
			}
			if (
				!memberData?.residentialAddress?.address ||
				!memberData?.residentialAddress?.suburb ||
				!memberData?.residentialAddress?.postcode ||
				!memberData?.residentialAddress?.state ||
				(!memberData?.residentialAddress?.country && !isSubmitOnBehalf)
			) {
				await AppSyncService.execute(saveResidentialAddress, {
					saveAddress: {
						memberExternalId: '',
						address: values.contactAddress,
						suburb: values.contactSuburb,
						postcode: values.contactPostcode,
						state: values.contactState,
						country: values.contactCountry
					}
				});
				isUpdated = true;
			} else if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
				await AppSyncService.execute(saveResidentialAddress, {
					saveAddress: {
						memberExternalId:
							isSubmitOnBehalf && selectedUserVerifiedInfo
								? selectedUserVerifiedInfo.memberExternalId
								: '',
						address: residentialAddress?.address,
						country: residentialAddress?.country,
						postcode: residentialAddress?.postcode,
						state: residentialAddress?.state,
						suburb: residentialAddress?.suburb
					}
				});
			}

			if (isUpdated && !isSubmitOnBehalf) {
				addToast({ Message: 'Updated member profile', IsSuccess: true });
			}
		} catch (error) {
			addToast({
				Message: 'Could not update member profile',
				IsSuccess: false
			});
		}
	};

	const handleSubmitSuccess = async (data) => {
		setIsFormError(false);
		setIsLoading(true);
		await updateProfile({ phone: data.contactNumber });

		try {
			// create the new form
			let ohepFormData = generatePayload(data, horseCode);
			if (isSubmitOnBehalf) {
				ohepFormData = {
					...ohepFormData,
					isSubmitOnBehalf: true,
					memberId: selectedUserVerifiedInfo.id
				};
			}
			const { data: saveOhepNominationFormData } = await AppSyncService.execute(
				saveOhepNominationForm,
				{
					ohepNominationFormData: ohepFormData
				}
			);
			if (!saveOhepNominationFormData?.saveOhepNominationForm?.success) {
				throw new Error('Failed to submit form, server error');
			}
			const {
				data: saveOhepNominationFormStatusData
			} = await AppSyncService.execute(saveOhepNominationFormStatus, {
				id: saveOhepNominationFormData.saveOhepNominationForm.id,
				status: REVIEW
			});
			if (
				!saveOhepNominationFormStatusData?.saveOhepNominationFormStatus?.success
			) {
				throw new Error('Failed to submit form, server error');
			}
			setIsLoading(false);
			navigate(DASHBOARD_ROUTE);
			addToast({ Message: 'Form Submitted!', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Failed to submit form!', IsSuccess: false });
		}
	};

	const handleSubmitError = (e) => {
		console.error(e);
		setIsFormError(true);
	};

	const handleDraft = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const data = getValues();
		await updateProfile({ phone: data.contactNumber });
		try {
			const ohepFormData = generatePayload(data, horseCode);
			const { data: saveOhepNominationFormData } = await AppSyncService.execute(
				saveOhepNominationForm,
				{
					ohepNominationFormData: ohepFormData
				}
			);
			if (!saveOhepNominationFormData?.saveOhepNominationForm?.success) {
				throw new Error('Failed to save draft, server error');
			}
			setIsLoading(false);
			addToast({ Message: 'Form Saved!', IsSuccess: true });
			navigate(DASHBOARD_ROUTE);
		} catch (error) {
			addToast({ Message: 'Failed to save draft!', IsSuccess: false });
			logToServer(
				currentUser?.UserId,
				data,
				`Failed to save OHEP nomination draft due to backend error.`
			);
			setIsLoading(false);
		}
	};

	const handleCancel = async (e) => {
		e.preventDefault();
		if (!formId) {
			return onCancelSuccess();
		}
		const { data } = await AppSyncService.execute(deleteOhepNominationForm, {
			id: formId
		});
		if (!data.deleteOhepNominationForm) {
			return addToast({ Message: 'Error Cancelling Form', IsSuccess: false });
		}
		onCancelSuccess();
	};

	const onCancelSuccess = () => {
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigate(DASHBOARD_ROUTE);
	};

	const evaluatePrefilledField = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};

		setValue('contactAddress', residentialAddress?.address);
		setValue('contactSuburb', residentialAddress?.suburb);
		setValue('contactState', residentialAddress?.state);
		setValue('contactPostcode', residentialAddress?.postcode);
		setValue('contactCountry', residentialAddress?.country);
		setValue('contactNumber', selectedUserVerifiedInfo?.phone);
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
			return;
		} else {
			setIsSelectUserRequired(false);
		}
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		if (isSubmitOnBehalf) {
			evaluatePrefilledField();
		}
		handleSubmit(handleSubmitSuccess, handleSubmitError)(e);
	};

	const handleUploadPhoto = async (image, name, uploadType) => {
		if (isFormInReview) {
			return;
		}
		try {
			const { data } = await AppSyncService.execute(uploadImageToS3, {
				imageKey: image.name
			});
			const imageKey = data.putUserImage.imageKey;

			await axios.put(data.putUserImage.signedUrl, image, {
				headers: {
					'Content-Type': image.type
				}
			});
			const { data: saveOhepNominationFileData } = await AppSyncService.execute(
				saveOhepNominationFile,
				{
					filename: image.name,
					horseCode,
					key: imageKey,
					type: uploadType,
					...(isSubmitOnBehalf && {
						memberExternalId: selectedUserVerifiedInfo.memberExternalId
					})
				}
			);
			if (!saveOhepNominationFileData.saveOhepNominationFile.success) {
				throw new Error('Error uploading image');
			}
			const values = getValues(name);
			setValue(name, [
				...values,
				{
					filename: image.name,
					key: imageKey
				}
			]);
			setFormId(saveOhepNominationFileData.saveOhepNominationFile.id);
			addToast({ Message: 'Photo uploaded', IsSuccess: true });
		} catch (error) {
			addToast({
				Message: 'Error uploading image',
				IsSuccess: false
			});
		}
	};

	const handleRemovePhoto = async (image, name) => {
		if (isFormInReview) {
			return;
		}
		try {
			const { data: deleteImageFromS3Data } = await AppSyncService.execute(
				deleteImageFromS3,
				{
					imageKey: image.key
				}
			);
			if (!deleteImageFromS3Data.deleteUserImage?.success) {
				throw new Error('Error deleting user image');
			}
			const {
				data: deleteOhepNominationFileData
			} = await AppSyncService.execute(deleteOhepNominationFile, {
				key: image.key
			});
			if (!deleteOhepNominationFileData.deleteOhepNominationFile) {
				throw new Error('Error deleting  image');
			}
			const photos = [...values[name]];
			setValue(
				name,
				photos.filter((val) => val.key !== image.key)
			);
			addToast({ Message: 'Image removed', IsSuccess: true });
		} catch (error) {
			addToast({ Message: 'Error removing image', IsSuccess: false });
		}
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleUploadFile = async (file, key, fieldName, uploadType) => {
		if (isFormInReview) {
			return;
		}
		try {
			const { data } = await AppSyncService.execute(saveOhepNominationFile, {
				filename: file.name,
				horseCode,
				key,
				type: uploadType,
				...(isSubmitOnBehalf && {
					memberExternalId: selectedUserVerifiedInfo.memberExternalId
				})
			});
			if (!data.saveOhepNominationFile.success) {
				throw new Error('Error uploading file');
			}
			setValue(fieldName, [...values[fieldName], { filename: file.name, key }]);
			addToast({
				Message: 'File uploaded',
				IsSuccess: true
			});
			setFormId(data.saveOhepNominationFile.id);
		} catch (error) {
			addToast({
				Message: 'Failed to upload file',
				IsSuccess: false
			});
		}
	};

	const handleRemoveFile = async (file, fieldName) => {
		if (isFormInReview) {
			return;
		}
		try {
			const {
				data: deleteOhepNominationFileData
			} = await AppSyncService.execute(deleteOhepNominationFile, {
				key: file.key
			});
			if (!deleteOhepNominationFileData.deleteOhepNominationFile) {
				throw new Error('Error removing file');
			}
			setValue(
				fieldName,
				values[fieldName].filter((val) => val.key !== file.key)
			);
			addToast({ Message: 'File removed', IsSuccess: true });
		} catch (error) {
			addToast({
				Message: 'Failed to remove file',
				IsSuccess: false
			});
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setValue(name, value);
		// clear the previous address fields when criteria doesn't match
		if (
			(name === 'currentAddressDurationYears' &&
				(+value > 0 || +values.currentAddressDurationMonths > 1)) ||
			(name === 'currentAddressDurationMonths' &&
				(+value > 1 || +values.currentAddressDurationYears > 0))
		) {
			setValue('previousAddress', null);
			setValue('previousAddressValidated', null);
			setValue('previousCountry', null);
			setValue('previousPostcode', null);
			setValue('previousState', null);
			setValue('previousSuburb', null);
		}
	};

	const handleRelationshipToHorseChange = (e) => {
		setValue('relationshipToHorse', e.target.value);
		if (e.target.value !== 5) {
			setValue('relationshipToHorseOther', '');
		}
	};

	const handleReasonForNominationCheckboxChange = (value) => {
		let result = [...values.reasonsForNomination];
		if (result.includes(+value)) {
			result = result.filter((id) => +id !== +value);
		} else {
			result.push(value);
		}
		if (!result.includes(6)) {
			setValue('thoroughbredOverTwentyDetails', '');
		}
		if (!result.includes(5)) {
			setValue('rehomingAttemptDetails', '');
			setValue('rehomingAttemptDetailsDate', null);
			setValue('advertisedToRvDate', null);
		}
		setValue('reasonsForNomination', result);
	};

	const handleCheckbox = (name) => {
		setValue(name, !values[name]);
	};

	const handleDateChange = (name, value) => {
		const formattedDate = formatDate(value);
		if (formattedDate !== '1970-01-01') {
			setValue(name, formattedDate);
		} else {
			setValue(name, null);
		}
	};

	const renderUnsuccessfulRehoming = () => {
		return (
			<span>
				In circumstances outside of the owner’s control, where rehoming the
				horse has not been achieved following a minimum of two (2) genuine
				attempts, with one of these attempts being through RV’s Equine Welfare
				Delegate (EWD) for 14 days.
			</span>
		);
	};

	const renderCheckboxDescription = (description) => {
		let finalDescription = capitalizeFirstLetter(description);
		let regExp = /\(([^)]+)\)/g;
		let matches = finalDescription.match(regExp);
		for (let i = 0; i < matches.length; i++) {
			let str = matches[i];
			finalDescription = finalDescription.replaceAll(
				str,
				`<span class="font-normal">${str}</span>`
			);
		}
		return (
			<div
				dangerouslySetInnerHTML={{
					__html: `<span>${finalDescription}</span>`
				}}
			/>
		);
	};

	const checkIsFieldRequiredWithAtLeastOneDocLogic = () => {
		// check at least one of these document has been uploaded veterinaryCertificate, rspcaNoticeToComply, evidenceOfDangerousBehaviour and otherFiles
		if (
			values.reasonsForNomination.includes(3) ||
			values.reasonsForNomination.includes(4) ||
			values.reasonsForNomination.includes(7)
		) {
			return (
				values.veterinaryCertificateFiles.length === 0 &&
				values.rspcaNoticeToComplyFiles.length === 0 &&
				values.evidenceOfDangerousBehaviourFiles.length === 0 &&
				values.otherFiles.length === 0
			);
		}
		return false;
	};

	const populateContactAddressFields = (addressDetails) => {
		setValue(
			'contactAddress',
			`${addressDetails.Line1}${
				addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
			}`
		);
		setValue('contactSuburb', addressDetails.Suburb);
		setValue('contactPostcode', addressDetails.Postcode);
		setValue('contactState', addressDetails.State);
		setValue('contactCountry', addressDetails.Country);
	};

	const populateContactAddressFieldsOnbehalfAdmin = (addressDetails) => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setSelectedUserVerifiedInfo({
			...selectedUserVerifiedInfo,
			residentialAddress: {
				...residentialAddress,
				address: address,
				suburb: addressDetails.Suburb,
				postcode: addressDetails.Postcode,
				state: addressDetails.State,
				country: addressDetails.Country
			}
		});
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		if (!submitOnBehalf) {
			onClearFields();
		}
	};

	const toggleManualAddress = () => {
		setIsPostalAddressManual(!isPostalAddressManual);
	};

	const onRenderContactByMember = () => {
		return (
			<StyledFormFieldsContainer>
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Name</StyledFieldLabel>
							<FormTextField
								name="contactName"
								canEdit={false}
								value={`${memberData.firstName} ${memberData.lastName}`}
							/>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Email</StyledFieldLabel>
							<FormTextField
								name="contactEmail"
								canEdit={false}
								value={memberData.email}
							/>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact Number{!memberData.phone && '*'}
							</StyledFieldLabel>
							{!memberData.phone ? (
								<Fragment>
									<StyledFieldText
										isError={errors.contactNumber?.message}
										{...register('contactNumber', { required: true })}
									/>
									<StyledErrorText>
										{errors.contactNumber?.message}
									</StyledErrorText>
								</Fragment>
							) : (
								<FormTextField value={memberData.phone} canEdit={false} />
							)}
						</StyledFieldContainer>
					</Col>
				</Row>
				<Divider color="#edf5f9" />
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact Address
								{!memberData.residentialAddress?.address ? '*' : ''}
							</StyledFieldLabel>
							<AddressAutoSuggestion
								value={values.contactAddress}
								onChange={(val) => setValue('contactAddress', val)}
								onAddressSelect={populateContactAddressFields}
								disableAutoSuggestion={values.contactAddressValidated}
								formTextFieldStyle={
									errors.contactAddress ? { border: '1px solid red' } : {}
								}
								canEdit={!memberData.residentialAddress?.address}
							/>
							<StyledErrorText>
								{errors.contactAddress?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact Suburb
								{!memberData.residentialAddress?.suburb ? '*' : ''}
							</StyledFieldLabel>
							<FormTextField
								name="contactSuburb"
								canEdit={!memberData.residentialAddress?.suburb}
								value={values.contactSuburb}
								onChange={handleChange}
								isDisabled={!values.contactAddressValidated}
								styles={errors.contactSuburb ? { border: '1px solid red' } : {}}
							/>
							<StyledErrorText>{errors.contactSuburb?.message}</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact State
								{!memberData.residentialAddress?.state ? '*' : ''}
							</StyledFieldLabel>
							<FormTextField
								name="contactState"
								canEdit={!memberData.residentialAddress?.state}
								value={values.contactState}
								onChange={handleChange}
								isDisabled={!values.contactAddressValidated}
								styles={errors.contactState ? { border: '1px solid red' } : {}}
							/>
							<StyledErrorText>{errors.contactState?.message}</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact Postcode
								{!memberData.residentialAddress?.postcode ? '*' : ''}
							</StyledFieldLabel>
							<FormTextField
								name="contactPostcode"
								canEdit={!memberData.residentialAddress?.postcode}
								value={values.contactPostcode}
								onChange={handleChange}
								isDisabled={!values.contactAddressValidated}
								styles={
									errors.contactPostcode ? { border: '1px solid red' } : {}
								}
							/>
							<StyledErrorText>
								{errors.contactPostcode?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Contact Country
								{!memberData.residentialAddress?.country ? '*' : ''}
							</StyledFieldLabel>
							{!memberData.residentialAddress?.country ? (
								<CountrySelector
									value={values.contactCountry}
									isError={errors.contactCountry}
									changeHandler={(newCountryValue) =>
										setValue('contactCountry', newCountryValue)
									}
									isDisabled={!values.contactAddressValidated}
								/>
							) : (
								<FormTextField
									name="contactCountry"
									value={values.contactCountry}
									canEdit={false}
								/>
							)}
							<StyledErrorText>
								{errors.contactCountry?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					{!memberData?.residentialAddress?.address && (
						<Col xs={12}>
							<div className=" mb-4">
								<SimpleCheckBox
									changeCheckboxState={() =>
										handleCheckbox('contactAddressValidated')
									}
									custom
									customStyle={{ float: 'left' }}
									data={{
										id: `${PAGE_ID}-contactAddressValidated`,
										isSelected: values.contactAddressValidated
									}}
								/>
								<StyledResidentialAddressCheckboxTitle>
									Manually add contact address
								</StyledResidentialAddressCheckboxTitle>
							</div>
						</Col>
					)}
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Your relationship to this horse*
							</StyledFieldLabel>
							<FormDropDown
								showBlank
								name="relationshipToHorse"
								selectedId={values.relationshipToHorse}
								onChange={handleRelationshipToHorseChange}
								items={relationshipToHorseOptions}
								isError={errors.relationshipToHorse?.message}
							/>
							<StyledErrorText>
								{errors.relationshipToHorse?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					{+values.relationshipToHorse === 5 && (
						<Col xs={12} lg={4}>
							<StyledFieldContainer>
								<StyledFieldLabel>
									Other relationship to this horse*
								</StyledFieldLabel>
								<StyledFieldText
									isError={errors.relationshipToHorseOther?.message}
									{...register('relationshipToHorseOther', {
										required: true
									})}
								/>
								<StyledErrorText>
									{errors.relationshipToHorseOther?.message}
								</StyledErrorText>
							</StyledFieldContainer>
						</Col>
					)}
				</Row>
			</StyledFormFieldsContainer>
		);
	};

	const onRenderContactOnbehalfAdmin = () => {
		const { residentialAddress } = selectedUserVerifiedInfo || {};
		return (
			<StyledFormFieldsContainer>
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Name</StyledFieldLabel>
							<FormTextField
								name="contactName"
								canEdit={false}
								value={`${selectedUserVerifiedInfo?.firstName} ${selectedUserVerifiedInfo?.lastName}`}
							/>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Email</StyledFieldLabel>
							<FormTextField
								name="contactEmail"
								canEdit={false}
								value={selectedUserVerifiedInfo?.email}
							/>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Number*</StyledFieldLabel>
							<FormTextField
								name="contactNumber"
								value={
									selectedUserVerifiedInfo ? selectedUserVerifiedInfo.phone : ''
								}
								onChange={(e) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										phone: e.target.value
									});
									setValue('contactNumber', e.target.value);
								}}
								canEdit={true}
							/>
							<StyledErrorText>{errors.contactNumber?.message}</StyledErrorText>
						</StyledFieldContainer>
					</Col>
				</Row>
				<Divider color="#edf5f9" />
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Address*</StyledFieldLabel>
							<AddressAutoSuggestion
								value={residentialAddress ? residentialAddress.address : ''}
								onChange={(val) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: { ...residentialAddress, address: val }
									});
									setValue('contactAddress', val);
								}}
								onAddressSelect={populateContactAddressFieldsOnbehalfAdmin}
								formTextFieldStyle={
									errors.contactAddress ? { border: '1px solid red' } : {}
								}
								disableAutoSuggestion={isPostalAddressManual}
							/>
							<StyledErrorText>
								{errors.contactAddress?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Suburb*</StyledFieldLabel>
							<FormTextField
								name="contactSuburb"
								value={residentialAddress ? residentialAddress.suburb : ''}
								onChange={(val) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											suburb: val.target.value
										}
									});
									setValue('contactSuburb', val.target.value);
								}}
								isDisabled={!isPostalAddressManual}
								styles={errors.contactSuburb ? { border: '1px solid red' } : {}}
							/>
							<StyledErrorText>{errors.contactSuburb?.message}</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact State*</StyledFieldLabel>
							<StateSelector
								country={country}
								value={residentialAddress ? residentialAddress?.state : ''}
								changeHandler={(newStateValue) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											state: newStateValue
										}
									});
									setValue('contactState', newStateValue);
								}}
								isDisabled={!isPostalAddressManual}
							/>
							<StyledErrorText>{errors.contactState?.message}</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Postcode*</StyledFieldLabel>
							<FormTextField
								name="contactPostcode"
								value={residentialAddress ? residentialAddress.postcode : ''}
								onChange={(val) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											postcode: val.target.value
										}
									});
									setValue('contactPostcode', val.target.value);
								}}
								isDisabled={!isPostalAddressManual}
								styles={
									errors.contactPostcode ? { border: '1px solid red' } : {}
								}
							/>
							<StyledErrorText>
								{errors.contactPostcode?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>Contact Country*</StyledFieldLabel>
							<CountrySelector
								value={residentialAddress ? residentialAddress?.country : ''}
								changeHandler={(newCountryValue) => {
									setSelectedUserVerifiedInfo({
										...selectedUserVerifiedInfo,
										residentialAddress: {
											...residentialAddress,
											country: newCountryValue
										}
									});
									setCountry(newCountryValue);
									// setCountry('contactCountry', newCountryValue);
								}}
								isDisabled={!isPostalAddressManual}
							/>
							<StyledErrorText>
								{errors.contactCountry?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					<Col xs={12}>
						<div className=" mb-4">
							<SimpleCheckBox
								changeCheckboxState={toggleManualAddress}
								custom
								customStyle={{ float: 'left' }}
								data={manualContactAddressCheckBoxDataByAdmin}
								key={
									manualContactAddressCheckBoxDataByAdmin &&
									manualContactAddressCheckBoxDataByAdmin.id
								}
							/>
							<StyledResidentialAddressCheckboxTitle>
								Manually add contact address
							</StyledResidentialAddressCheckboxTitle>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<StyledFieldContainer>
							<StyledFieldLabel>
								Your relationship to this horse*
							</StyledFieldLabel>
							<FormDropDown
								showBlank
								name="relationshipToHorse"
								selectedId={values.relationshipToHorse}
								onChange={handleRelationshipToHorseChange}
								items={relationshipToHorseOptions}
								isError={errors.relationshipToHorse?.message}
							/>
							<StyledErrorText>
								{errors.relationshipToHorse?.message}
							</StyledErrorText>
						</StyledFieldContainer>
					</Col>
					{+values.relationshipToHorse === 5 && (
						<Col xs={12} lg={4}>
							<StyledFieldContainer>
								<StyledFieldLabel>
									Other relationship to this horse*
								</StyledFieldLabel>
								<StyledFieldText
									isError={errors.relationshipToHorseOther?.message}
									{...register('relationshipToHorseOther', {
										required: true
									})}
								/>
								<StyledErrorText>
									{errors.relationshipToHorseOther?.message}
								</StyledErrorText>
							</StyledFieldContainer>
						</Col>
					)}
				</Row>
			</StyledFormFieldsContainer>
		);
	};
	return (
		<Fragment>
			<SEO title="OHEP Form" />
			<StyledLoadingContainer>
				<LoaderSpinner status={isLoading} />
			</StyledLoadingContainer>
			{!isLoading && (
				<StyledContainer>
					{isAdminUser ? (
						<SubmitOnBehalf
							horseCode={horseCode}
							onToggleSubmitBehalf={onToggleSubmitBehalf}
							onSelectUserVerified={onSelectUserVerified}
							isGetAllUsersVerified
							selectUserRequiredError={selectUserRequiredError}
							isSelectUserRequired={isSelectUserRequired}
						/>
					) : null}
					<StyledAccordion title="OHEP Nomination Form" isOpen>
						<StyledNominationContactMessageBar type={MessageBarTypeEnum.info}>
							<p>
								This form is to be completed by the owner or managing owner of
								the Nominee Horse and submitted for review by Racing Victoria’s
								Equine Welfare team, together with the signed statutory
								declaration evidencing ownership of the Nominee Horse and any
								other required evidence.
							</p>
							<p>All fields marked * must be completed to submit this form.</p>
							<p>
								<strong>Background</strong>
							</p>
							<p>
								The Racing Victoria Limited (Racing Victoria) Onsite Humane
								Euthanasia Program (OHEP) is targeted at thoroughbreds where the
								decision to euthanise them for the best welfare outcome has
								already been made. The OHEP aims to enable owners to euthanise
								these horses onsite at the horse’s usual location or at a
								veterinary hospital / clinic in circumstances where the cost of
								this procedure would otherwise be inhibitive.
							</p>
							<p>
								The OHEP is not designed for or inclusive of emergency cases,
								being a case where immediate intervention for the physical
								welfare of the horse is necessary. These cases should be dealt
								with immediately by the owner in line with the Prevention of
								Cruelty to Animals Act 1986 (Vic) and the Rules of Racing.
							</p>
							<p>
								This Nomination Form will assist RV in determining a
								horse&apos;s suitability for the OHEP.
							</p>
							<div className="md:flex justify-between items-center mb-2">
								<span className="mb-2 md:mb-0 block font-bold">
									Need more information about OHEP?
								</span>
								<NewTabLink url={PROGRAMS_OHEP_ROUTE}>
									<StyledWhatIsOHEPButton id={`${PAGE_ID}-what-is-ohep-button`}>
										What is OHEP?
									</StyledWhatIsOHEPButton>
								</NewTabLink>
							</div>
						</StyledNominationContactMessageBar>
					</StyledAccordion>
					<StyledForm>
						<StyledAccordion title="Contact" isOpen={true}>
							{isSubmitOnBehalf && selectedUserVerifiedInfo
								? onRenderContactOnbehalfAdmin()
								: onRenderContactByMember()}
						</StyledAccordion>
						<StyledAccordion title="Nominee Horse Details" isOpen={true}>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Registered Name</StyledFieldLabel>
											<FormTextField canEdit={false} value={horseData.name} />
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Sex</StyledFieldLabel>
											<FormTextField canEdit={false} value={horseData.sex} />
										</StyledFieldContainer>
									</Col>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>Colour</StyledFieldLabel>
											<FormTextField canEdit={false} value={horseData.colour} />
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Thoroughbred Photos" isOpen={true}>
							<MessageBar type="primary" className="mt-2">
								<StyledMailLink
									href={'/photo-upload-guide'}
									target="_blank"
									rel="noreferrer"
								>
									Need help with photo requirements? Click here to view examples
									of suitable photos of your thoroughbred
								</StyledMailLink>
							</MessageBar>
							<StyledFormFieldsContainer>
								<Row>
									<Col xs={12} lg={4}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Photos - Left Side Brand (jpg, png)*
											</StyledFieldLabel>
											<UploadPhoto
												id={`${PAGE_ID}-leftSidePhotos`}
												formats={['png', 'jpeg']}
												labelName="Upload Left Side Brand Photo"
												error={handleUploadError}
												data={values?.leftSidePhotos}
												sizeLimitMB={5}
												numberOfImages={10}
												success={(image) =>
													handleUploadPhoto(image, 'leftSidePhotos', 'leftSide')
												}
												getImage={filePreviewHandler}
												removeImage={(image) =>
													handleRemovePhoto(image, 'leftSidePhotos')
												}
												canEdit={!isFormInReview}
												isDisabled={
													isSubmitOnBehalf && !selectedUserVerifiedInfo
												}
											/>
											<StyledErrorText>
												{errors.leftSidePhotos?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
									<Col lg={4} xs={12}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Photos - Right Side Brand (jpg, png)*
											</StyledFieldLabel>
											<UploadPhoto
												id={`${PAGE_ID}-rightSidePhotos`}
												formats={['png', 'jpeg']}
												labelName="Upload Right Side Brand Photo"
												error={handleUploadError}
												data={values?.rightSidePhotos}
												sizeLimitMB={5}
												numberOfImages={10}
												success={(image) =>
													handleUploadPhoto(
														image,
														'rightSidePhotos',
														'rightSide'
													)
												}
												getImage={filePreviewHandler}
												removeImage={(image) =>
													handleRemovePhoto(image, 'rightSidePhotos')
												}
												canEdit={!isFormInReview}
												isDisabled={
													isSubmitOnBehalf && !selectedUserVerifiedInfo
												}
											/>
											<StyledErrorText>
												{errors.rightSidePhotos?.message}
											</StyledErrorText>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion
							title="Address of Current Location of Nominee Horse"
							isOpen={true}
						>
							<StyledFormFieldsContainer>
								<FormAddressEntryGeneric
									address={values.currentAddress}
									onAddressChange={(address) =>
										setValue('currentAddress', address)
									}
									isPostalAddressManual={false}
									suburb={values.currentSuburb}
									onSuburbChange={(suburb) => setValue('currentSuburb', suburb)}
									postcode={values.currentPostcode}
									onPostCodeChange={(postcode) =>
										setValue('currentPostcode', postcode)
									}
									country={values.currentCountry}
									onCountryChange={(country) =>
										setValue('currentCountry', country)
									}
									manualAddressCheckBoxData={{
										id: 'manualCheckBox',
										label: 'Manually add my address',
										value: 'Manually add my address',
										isSelected: values.currentAddressValidated
									}}
									toggleManualAddressCheckBox={() =>
										handleCheckbox('currentAddressValidated')
									}
									state={values.currentState}
									onAddressStateChange={(state) =>
										setValue('currentState', state)
									}
									isSubmitClicked={isSubmitted}
								/>
								<Row>
									<Col lg={6} xs={12}>
										<StyledFieldContainer>
											<StyledFieldLabel>
												Time horse has been at the above location*?
											</StyledFieldLabel>
											<Row>
												<Col xs={5} md={6} lg={6}>
													<StyledFieldLabel>Years</StyledFieldLabel>
													<StyledFieldText
														isError={
															errors.currentAddressDurationYears?.message
														}
														type="number"
														min={0}
														{...register('currentAddressDurationYears', {
															required: true,
															onChange: handleChange
														})}
													/>
													<StyledErrorText>
														{errors.currentAddressDurationYears?.message}
													</StyledErrorText>
												</Col>
												<Col xs={5} md={6} lg={6}>
													<StyledFieldLabel>Months</StyledFieldLabel>
													<StyledFieldText
														isError={
															errors.currentAddressDurationMonths?.message
														}
														type="number"
														min={0}
														{...register('currentAddressDurationMonths', {
															required: true,
															onChange: handleChange
														})}
													/>
													<StyledErrorText>
														{errors.currentAddressDurationMonths?.message}
													</StyledErrorText>
												</Col>
											</Row>
										</StyledFieldContainer>
									</Col>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						{isLocation1MonthOrLess && (
							<StyledAccordion
								title="Address of Previous Location of Nominee Horse"
								isOpen={true}
							>
								<StyledFormFieldsContainer>
									<StyledFieldLabel>
										If 1 month or less, please provide address of where horse
										was previously located:
									</StyledFieldLabel>
									<FormAddressEntryGeneric
										areAllFieldsOptional={
											values.currentAddressDurationMonths > 1 ||
											values.currentAddressDurationYears > 0
										}
										address={values.previousAddress}
										onAddressChange={(address) =>
											setValue('previousAddress', address)
										}
										isPostalAddressManual={false}
										suburb={values.previousSuburb}
										onSuburbChange={(suburb) =>
											setValue('previousSuburb', suburb)
										}
										postcode={values.previousPostcode}
										onPostCodeChange={(postcode) =>
											setValue('previousPostcode', postcode)
										}
										state={values.previousState}
										onAddressStateChange={(state) =>
											setValue('previousState', state)
										}
										country={values.previousCountry}
										onCountryChange={(country) =>
											setValue('previousCountry', country)
										}
										manualAddressCheckBoxData={{
											id: 'manualCheckBox',
											label: 'Manually add my address',
											value: 'Manually add my address',
											isSelected: values.previousAddressValidated
										}}
										toggleManualAddressCheckBox={() =>
											handleCheckbox('previousAddressValidated')
										}
										isSubmitClicked={isSubmitted}
									/>
								</StyledFormFieldsContainer>
							</StyledAccordion>
						)}

						<StyledAccordion
							title="Reason for Nomination for Euthanasia"
							isOpen
						>
							<StyledFormFieldsContainer>
								<StyledMessageBar type={MessageBarTypeEnum.info}>
									<p>
										Please select at least one nomination reason from the below
										options (multiple can apply).
									</p>
								</StyledMessageBar>
								{reasonForNominationForEuthanasiaOptions.map(
									(option, index) => (
										<div key={option.id}>
											<StyledFormCheckboxFieldContainer>
												<SimpleCheckBox
													changeCheckboxState={() =>
														handleReasonForNominationCheckboxChange(option.id)
													}
													custom
													customStyle={
														errors.reasonsForNomination?.message
															? CHECKBOX_ERROR_STYLES
															: {}
													}
													data={{
														id: `${PAGE_ID}-${option.id}`,
														isSelected: values.reasonsForNomination?.includes(
															option.id
														)
													}}
												/>
												<StyledFormCheckboxFieldLabel>
													<div
														style={{
															display: 'flex',
															justifyContent: 'flex-start'
														}}
													>
														<span className="mr-1 font-semibold">
															{option.label}
															{'.'}
														</span>
														{option.id === 5
															? renderUnsuccessfulRehoming()
															: renderCheckboxDescription(option.description)}
													</div>
												</StyledFormCheckboxFieldLabel>
											</StyledFormCheckboxFieldContainer>
											{option.id === 5 &&
												values.reasonsForNomination.includes(5) && (
													<div>
														<StyledFieldLabel>
															Rehoming Attempt Details
														</StyledFieldLabel>
														<Row className="mb-2">
															<Col xs={12} lg={0.5}>
																1.
															</Col>
															<Col xs={12} lg={7.5}>
																<StyledFormTextArea
																	{...register('rehomingAttemptDetails', {
																		required: true
																	})}
																	isError={
																		errors.rehomingAttemptDetails?.message
																	}
																/>
																<StyledErrorText>
																	{errors.rehomingAttemptDetails?.message}
																</StyledErrorText>
															</Col>
															<Col lg={4}>
																<Row>
																	<Col lg={2}>
																		<StyledFieldLabel>Date</StyledFieldLabel>
																	</Col>
																	<Col lg={10}>
																		<FormDatePicker
																			DateValue={
																				values.rehomingAttemptDetailsDate
																			}
																			updateSelection={(value) =>
																				handleDateChange(
																					'rehomingAttemptDetailsDate',
																					value
																				)
																			}
																			minDate={null}
																			isError={
																				errors.rehomingAttemptDetailsDate
																					?.message
																			}
																		/>
																		<StyledErrorText>
																			{
																				errors.rehomingAttemptDetailsDate
																					?.message
																			}
																		</StyledErrorText>
																	</Col>
																</Row>
															</Col>
														</Row>
														<Row>
															<Col xs={0.5} lg={0.5}>
																2.
															</Col>
															<Col xs={10} lg={7.5}>
																<StyledFieldLabel>
																	Advertised via RV EW Delegate for 14 days
																</StyledFieldLabel>
															</Col>
															<Col lg={4}>
																<Row>
																	<Col lg={2}>
																		<StyledFieldLabel>Date</StyledFieldLabel>
																	</Col>
																	<Col lg={10}>
																		<FormDatePicker
																			DateValue={values.advertisedToRvDate}
																			updateSelection={(value) =>
																				handleDateChange(
																					'advertisedToRvDate',
																					value
																				)
																			}
																			minDate={null}
																			isError={
																				errors?.advertisedToRvDate?.message
																			}
																		/>
																		<StyledErrorText>
																			{errors?.advertisedToRvDate?.message}
																		</StyledErrorText>
																	</Col>
																</Row>
															</Col>
														</Row>
													</div>
												)}
											{option.id === 6 &&
												values.reasonsForNomination.includes(6) && (
													<div>
														<StyledFieldLabel>
															Please provide further details*
														</StyledFieldLabel>
														<StyledFormTextArea
															isError={
																errors?.thoroughbredOverTwentyDetails?.message
															}
															{...register('thoroughbredOverTwentyDetails', {
																required: true
															})}
															maxLength={700}
														/>
														<StyledErrorText>
															{errors.thoroughbredOverTwentyDetails?.message}
														</StyledErrorText>
													</div>
												)}
											{index !==
												reasonForNominationForEuthanasiaOptions.length - 1 && (
												<Divider />
											)}
										</div>
									)
								)}
								{errors.reasonsForNomination?.message && (
									<StyledErrorText>
										{errors.reasonsForNomination?.message}
									</StyledErrorText>
								)}
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Supporting Documentation" isOpen>
							<StyledFormFieldsContainer Supporting Documentation>
								<StyledMessageBar
									type={MessageBarTypeEnum.info}
									className="mb-2"
								>
									<span>
										Videos are encouraged, please email to{' '}
										<a href="mailto:equinewelfare@racingvictoria.net.au">
											{' '}
											equinewelfare@racingvictoria.net.au
										</a>
									</span>
								</StyledMessageBar>
								<p>
									I have attached the following applicable supporting
									documentation which may include{' '}
									<span style={{ fontWeight: 'bold' }}>one or more</span> of the
									following*:
								</p>
								<ul>
									<li>Veterinary certificate</li>
									<li>
										RSPCA Notice to Comply, or a Direction/advice to euthanise
										from an authorised officer/steward/vet
									</li>
									<li>Evidence of dangerous behaviour</li>
									<li>A signed witness or professional statement</li>
								</ul>
								<StyledSupportingDocumentationRow>
									<StyledSupportingDocumentationCol lg={4.5}>
										<FileUploadInputField
											idPrefix={`${PAGE_ID}-veterinary-certificate-uploads`}
											fieldLabel="Upload Veterinary Certificate (jpg, png, pdf, docx)"
											buttonText="Upload Veterinary Certificate"
											fileData={values.veterinaryCertificateFiles}
											onSuccessfulS3Upload={(file, key) =>
												handleUploadFile(
													file,
													key,
													'veterinaryCertificateFiles',
													'veterinaryCertificate'
												)
											}
											onSuccessfulS3Delete={(file) =>
												handleRemoveFile(file, 'veterinaryCertificateFiles')
											}
											onSuccessfulS3ImageDownload={filePreviewHandler}
											formats={[
												'jpeg',
												'jpg',
												'png',
												'pdf',
												'docx',
												WORD_DOC_MIME_TYPE
											]}
											numberOfFiles={3}
											validationError={
												errors.veterinaryCertificateFiles?.message &&
												errors.veterinaryCertificateFiles.message !==
													AT_LEAST_ONE_ERROR_MESSAGE
													? errors.veterinaryCertificateFiles.message
													: ''
											}
											isRequired={
												values.reasonsForNomination.includes(1) ||
												values.reasonsForNomination.includes(2)
											}
											inputErrorHandler={handleUploadError}
											formFieldWrapperProps={{ fullWidth: true }}
											isEditable={!isFormInReview}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</StyledSupportingDocumentationCol>
									<FileUploadInputField
										idPrefix={`${PAGE_ID}-rspca-notice-to-comply-file-uploads`}
										fieldLabel="Upload RSPCA Notice to Comply (jpg, png, pdf, docx)"
										buttonText="Upload RSPCA Notice to Comply"
										fileData={values.rspcaNoticeToComplyFiles}
										onSuccessfulS3Upload={(file, key) =>
											handleUploadFile(
												file,
												key,
												'rspcaNoticeToComplyFiles',
												'rspcaNoticeToComply'
											)
										}
										onSuccessfulS3Delete={(file) =>
											handleRemoveFile(file, 'rspcaNoticeToComplyFiles')
										}
										onSuccessfulS3ImageDownload={filePreviewHandler}
										formats={[
											'jpeg',
											'jpg',
											'png',
											'pdf',
											'docx',
											WORD_DOC_MIME_TYPE
										]}
										numberOfFiles={3}
										inputErrorHandler={handleUploadError}
										isEditable={!isFormInReview}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
								</StyledSupportingDocumentationRow>
								<StyledSupportingDocumentationRow>
									<StyledSupportingDocumentationCol lg={4.5}>
										<FileUploadInputField
											idPrefix={`${PAGE_ID}-evidence-of-dangerous-behaviour-uploads`}
											fieldLabel="Upload Evidence of Dangerous Behaviour (jpg, png, pdf, docx)"
											buttonText="Upload Evidence of Dangerous Behaviour"
											fileData={values.evidenceOfDangerousBehaviourFiles}
											onSuccessfulS3Upload={(file, key) =>
												handleUploadFile(
													file,
													key,
													'evidenceOfDangerousBehaviourFiles',
													'evidenceOfDangerousBehaviour'
												)
											}
											onSuccessfulS3Delete={(file) =>
												handleRemoveFile(
													file,
													'evidenceOfDangerousBehaviourFiles'
												)
											}
											onSuccessfulS3ImageDownload={filePreviewHandler}
											formats={[
												'jpeg',
												'jpg',
												'png',
												'pdf',
												'docx',
												WORD_DOC_MIME_TYPE
											]}
											numberOfFiles={3}
											inputErrorHandler={handleUploadError}
											formFieldWrapperProps={{ fullWidth: true }}
											isEditable={!isFormInReview}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									</StyledSupportingDocumentationCol>
									<FileUploadInputField
										idPrefix={`${PAGE_ID}-other-uploads`}
										fieldLabel="Upload Other Documents (jpg, png, pdf, docx)"
										buttonText="Upload Other Documents"
										fileData={values.otherFiles}
										onSuccessfulS3Upload={(file, key) =>
											handleUploadFile(file, key, 'otherFiles', 'other')
										}
										onSuccessfulS3Delete={(file) =>
											handleRemoveFile(file, 'otherFiles')
										}
										onSuccessfulS3ImageDownload={filePreviewHandler}
										formats={[
											'jpeg',
											'jpg',
											'png',
											'pdf',
											'docx',
											WORD_DOC_MIME_TYPE
										]}
										numberOfFiles={3}
										inputErrorHandler={handleUploadError}
										isEditable={!isFormInReview}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
								</StyledSupportingDocumentationRow>
								{isSubmitted &&
									checkIsFieldRequiredWithAtLeastOneDocLogic() && (
										<StyledErrorText>
											Please ensure at least one document has been uploaded
										</StyledErrorText>
									)}
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Acknowledgment" isOpen>
							<StyledFormFieldsContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedPrivacy')
											}
											custom
											customStyle={
												!values.acknowledgedPrivacy &&
												errors.acknowledgedPrivacy?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-acknowledgement`,
												isSelected: values.acknowledgedPrivacy
											}}
										/>
										<p>
											I acknowledge that I have read and understood the Privacy
											Statement set out below, and consent to the collection,
											use and disclosure of my personal information in
											accordance with that{' '}
											<NewTabLink url={OHEP_PRIVACY_ROUTE}>
												<strong>Privacy Policy</strong>
											</NewTabLink>
											*.
										</p>
									</StyledGenericStatementRow>
									{!values.acknowledgedPrivacy && (
										<StyledErrorText>
											{errors.acknowledgedPrivacy?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Consent to Participate" isOpen>
							<StyledFormFieldsContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedConsentToParticipate')
											}
											custom
											customStyle={
												!values.acknowledgedConsentToParticipate &&
												errors.acknowledgedConsentToParticipate?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-consent-to-participate`,
												isSelected: values.acknowledgedConsentToParticipate
											}}
										/>
										<p>
											By signing this Nomination Form, I consent to the Nominee
											Horse&apos;s participation in the OHEP and euthanasia to
											be performed by an Australian-registered veterinarian of
											my choice (provided that veterinarian has signed up as a
											Service Provider) or RV-nominated and approved service
											provider (Service Provider)*.
										</p>
									</StyledGenericStatementRow>
									{!values.acknowledgedConsentToParticipate && (
										<StyledErrorText>
											{errors.acknowledgedConsentToParticipate?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Ownership Declaration and Warranty" isOpen>
							<StyledFormFieldsContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedOwnershipWarranty')
											}
											custom
											customStyle={
												!values.acknowledgedOwnershipWarranty &&
												errors.acknowledgedOwnershipWarranty?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-ownership-declaration-and-warranty-represent-and-warrant`,
												isSelected: values.acknowledgedOwnershipWarranty
											}}
										/>
										<div>
											<p>I represent and warrant that*:</p>
											<ul>
												<li>
													I am the sole legal and beneficial owner of the
													Nominee Horse, or I have authority on behalf of all
													other legal and beneficial owners of the Nominee Horse
													to apply to have the Nominee Horse euthanised and to
													sign this Nomination Form;
												</li>
												<li>
													to the extent necessary, I have obtained all necessary
													consents to the euthanasia of the Nominee Horse;
												</li>
												<li>
													the Nominee Horse has been domiciled in Victoria for
													the 6 months immediately prior to the date of this
													Nomination Form;
												</li>
												<li>
													the Nominee Horse satisfies the eligibility criteria
													set out in the OHEP Owner&apos;s Manual;
												</li>
												<li>
													I have read and understood the OHEP Owner&apos;s
													Manual
												</li>
												<li>
													and the details set out in this Nomination Form are
													true and correct
												</li>
											</ul>
										</div>
									</StyledGenericStatementRow>
									{!values.acknowledgedOwnershipWarranty && (
										<StyledErrorText>
											{errors.acknowledgedOwnershipWarranty?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedOwnershipBinding')
											}
											custom
											customStyle={
												!values.acknowledgedOwnershipBinding &&
												errors.acknowledgedOwnershipBinding?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-ownership-declaration-and-warranty-nominee-accepted-implications`,
												isSelected: values.acknowledgedOwnershipBinding
											}}
										/>
										<div>
											<p>
												I acknowledge and agree that, if the Nominee Horse is
												accepted into the OHEP*:
											</p>
											<ul>
												<li>
													I will be subject to the terms of the OHEP
													Owner&apos;s Manual;
												</li>
												<li>
													I will be responsible for engaging the Approved
													Provider to perform the euthanasia and removal and
													disposal services (Services), including satisfying
													myself as to the suitability, experience and
													qualifications of the Approved Provider to perform
													those Services, and entering into binding arrangements
													directly with the Approved Provider
												</li>
											</ul>
										</div>
									</StyledGenericStatementRow>
									{!values.acknowledgedOwnershipBinding && (
										<StyledErrorText>
											{errors.acknowledgedOwnershipBinding?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Owner Indemnity" isOpen>
							<StyledFormFieldsContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedOwnerIndemnity')
											}
											custom
											customStyle={
												!values.acknowledgedOwnerIndemnity &&
												errors.acknowledgedOwnerIndemnity?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-owner-indemnity`,
												isSelected: values.acknowledgedOwnerIndemnity
											}}
										/>
										<p>
											I indemnify Racing Victoria Limited and the Approved
											Provider on demand against any claim which may be made by
											me, or any other person, for any loss or damage arising as
											a result of the euthanasia of the Nominee Horse*.
										</p>
									</StyledGenericStatementRow>
									{!values.acknowledgedOwnerIndemnity && (
										<StyledErrorText>
											{errors.acknowledgedOwnerIndemnity?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Owner Confirmation" isOpen>
							<StyledFormFieldsContainer>
								<StyledGenericStatementContainer>
									<StyledGenericStatementRow>
										<SimpleCheckBox
											changeCheckboxState={() =>
												handleCheckbox('acknowledgedOwnerConfirmation')
											}
											custom
											customStyle={
												!values.acknowledgedOwnerConfirmation &&
												errors.acknowledgedOwnerConfirmation?.message
													? { float: 'left', ...CHECKBOX_ERROR_STYLES }
													: { float: 'left' }
											}
											data={{
												id: `${PAGE_ID}-owner-confirmation`,
												isSelected: values.acknowledgedOwnerConfirmation
											}}
										/>
										<div>
											<p>I, the applicant hereby*:</p>
											<ul>
												<li>
													Declare that all details in my application are true
													and correct
												</li>
												<li>
													Acknowledge that Racing Victoria may request further
													information from me in respect of this application;
												</li>
												<li>
													Consent to Racing Victoria obtaining personal
													information about me from others and using &
													disclosing my personal information as noted in the
													attached{' '}
													<NewTabLink url={OHEP_PRIVACY_ROUTE}>
														<strong>Privacy Policy</strong>
													</NewTabLink>
													.
												</li>
											</ul>
										</div>
									</StyledGenericStatementRow>
									{!values.acknowledgedOwnerConfirmation && (
										<StyledErrorText>
											{errors.acknowledgedOwnerConfirmation?.message}
										</StyledErrorText>
									)}
								</StyledGenericStatementContainer>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledAccordion title="Statutory Declaration" isOpen>
							<StyledFormFieldsContainer>
								<StyledMessageBar type={MessageBarTypeEnum.info}>
									<p>
										Please upload a completed and signed copy of the Statutory
										Declaration.
									</p>
								</StyledMessageBar>
								<Row className="mt-2">
									<FormFieldWrapper
										addTopSpace={true}
										fieldLabel={'Download Statutory Declaration'}
									>
										<a href={ohepStatutoryDeclarationPdfFile} download>
											<StyledStatutoryDeclarationDownloadButton
												id={`${PAGE_ID}-statutory-declaration-pdf-file`}
												type="button"
											>
												Download Statutory Declaration
												<FaDownload />
											</StyledStatutoryDeclarationDownloadButton>
										</a>
									</FormFieldWrapper>
									<FileUploadInputField
										idPrefix={`${PAGE_ID}-statutory-declaration`}
										fieldLabel="Upload Statutory Declaration (jpg, png, pdf, docx)"
										buttonText="Upload Statutory Declaration"
										fileData={values.statuaryDeclarationFiles}
										onSuccessfulS3Upload={(file, key) =>
											handleUploadFile(
												file,
												key,
												'statuaryDeclarationFiles',
												'statuaryDeclaration'
											)
										}
										onSuccessfulS3Delete={(file) =>
											handleRemoveFile(file, 'statuaryDeclarationFiles')
										}
										onSuccessfulS3ImageDownload={filePreviewHandler}
										formats={[
											'jpeg',
											'jpg',
											'png',
											'pdf',
											'docx',
											WORD_DOC_MIME_TYPE
										]}
										numberOfFiles={3}
										inputErrorHandler={handleUploadError}
										isRequired
										validationError={errors.statuaryDeclarationFiles?.message}
										isEditable={!isFormInReview}
										isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
									/>
								</Row>
							</StyledFormFieldsContainer>
						</StyledAccordion>
						<StyledFormEndDivider />
						<StyledFormActionsRow>
							<Col xs={3} lg={7}>
								<StyledCancelRequest
									onClick={() => setConfirmationModal(true)}
									isVisible={!isFormInReview}
								>
									Cancel Request
								</StyledCancelRequest>
							</Col>
							<StyledSaveDraft xs={2} lg={2.5}>
								<StyledFormActionLink
									onClick={handleDraft}
									isVisible={!isFormInReview}
								>
									Save Draft
								</StyledFormActionLink>
							</StyledSaveDraft>
							<Col xs={7} lg={2.5}>
								<StyledFormSubmitButton
									onClick={onSubmit}
									disabled={isFormInReview}
									id={`${PAGE_ID}-submit`}
								>
									Complete Request
								</StyledFormSubmitButton>
							</Col>
						</StyledFormActionsRow>
						{isFormError && (
							<StyledErrorMessage>
								Please complete all required fields
							</StyledErrorMessage>
						)}
					</StyledForm>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Cancel OHEP Form"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Are you sure you wish to delete this form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</StyledContainer>
			)}
		</Fragment>
	);
};

export default OHEPForm;

import { DEFAULT_COUNTRY } from '../../utils/constants';

export const OHEP_FORM_DEFAULT_VALUES = {
	contactNumber: '',
	contactAddress: '',
	contactSuburb: '',
	contactPostcode: '',
	contactState: '',
	contactCountry: DEFAULT_COUNTRY,
	contactAddressValidated: false,
	relationshipToHorse: 0,
	relationshipToHorseOther: '',
	leftSidePhotos: [],
	rightSidePhotos: [],
	currentAddress: '',
	currentSuburb: '',
	currentPostcode: '',
	currentState: '',
	currentCountry: '',
	currentAddressValidated: false,
	currentAddressDurationMonths: null,
	currentAddressDurationYears: null,
	previousAddress: '',
	previousSuburb: '',
	previousPostcode: '',
	previousState: '',
	previousCountry: '',
	previousAddressValidated: false,
	reasonsForNomination: [],
	rehomingAttemptDetails: '',
	rehomingAttemptDetailsDate: null,
	advertisedToRvDate: null,
	veterinaryCertificateFiles: [],
	rspcaNoticeToComplyFiles: [],
	evidenceOfDangerousBehaviourFiles: [],
	otherFiles: [],
	acknowledgedPrivacy: false,
	acknowledgedConsentToParticipate: false,
	acknowledgedOwnershipWarranty: false,
	acknowledgedOwnershipBinding: false,
	acknowledgedOwnerIndemnity: false,
	acknowledgedOwnerConfirmation: false,
	statuaryDeclarationFiles: [],
	thoroughbredOverTwentyDetails: ''
};

export const OHEP_FORM_DEFAULT_VALUES_WITHOUT_CONTACT = {
	contactAddressValidated: false,
	relationshipToHorse: 0,
	relationshipToHorseOther: '',
	leftSidePhotos: [],
	rightSidePhotos: [],
	currentAddress: '',
	currentSuburb: '',
	currentPostcode: '',
	currentState: '',
	currentCountry: '',
	currentAddressValidated: false,
	currentAddressDurationMonths: null,
	currentAddressDurationYears: null,
	previousAddress: '',
	previousSuburb: '',
	previousPostcode: '',
	previousState: '',
	previousCountry: '',
	previousAddressValidated: false,
	reasonsForNomination: [],
	rehomingAttemptDetails: '',
	rehomingAttemptDetailsDate: null,
	advertisedToRvDate: null,
	veterinaryCertificateFiles: [],
	rspcaNoticeToComplyFiles: [],
	evidenceOfDangerousBehaviourFiles: [],
	otherFiles: [],
	acknowledgedPrivacy: false,
	acknowledgedConsentToParticipate: false,
	acknowledgedOwnershipWarranty: false,
	acknowledgedOwnershipBinding: false,
	acknowledgedOwnerIndemnity: false,
	acknowledgedOwnerConfirmation: false,
	statuaryDeclarationFiles: [],
	thoroughbredOverTwentyDetails: ''
};

export const AT_LEAST_ONE_ERROR_MESSAGE =
	'Please ensure at least one document has been uploaded';

import React, { useEffect, useRef, useState } from 'react';
import {
	DEFAULT_FORM_VALUES,
	competitiveOrNonOptions,
	numberOfEventsOptions,
	ottSponsorshipOptions
} from './constants';
import LoaderSpinner from '../LoaderSpinner';
import useStyles from './styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form-v7';
import { navigate, useParams } from '@reach/router';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getEventDropdowns,
	getSelectedMemberRoles,
	getSponsorshipOutcomeForm,
	getSponsorshipOutcomeFormById,
	getUserImage,
	saveSponsorshipOutcomeForm
} from '../../graphql/custom.queries';
import {
	APPROVED,
	CommonKeys,
	DRAFT,
	REVIEW,
	SponsorshipOutcomeFormKeys
} from '../../utils/constants';
import FormBody from '../FormComponents/FormBody';
import FormSection from '../FormComponents/FormSection';
import PageContainer from '../PageContainer';
import SEO from '../seo';
import FormTextField from '../FormTextField';
import { schema } from './schema';
import FormDatePicker from '../FormDatePicker';
import {
	formatDate,
	formatDateReadableVariant,
	convertToSortedMultiListData
} from '../../utils/helpers';
import FormMultiSelectList from '../FormMultiSelectList';
import ResizingTextArea from '../ResizingTextArea';
import { StyledFieldText } from '../OHEPForm/style';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import { uploadImageToS3 } from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import axios from 'axios';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import Lightbox from 'react-image-lightbox';
import Divider from '../Divider';
import PrimaryButton from '../PrimaryButton';
import { EVENT_REPORTING_ROUTE } from '../../utils/constants/routes';
import {
	mapArrayToMultiSelectValues,
	mapFileArray,
	validateNumeric
} from './helpers';
import FormLabelField from '../FormLabelField';
import { useAuth } from '../../hooks/useAuth';
import numeral from 'numeral';

// form validation rules

const SponsorshipOutcomeForm = () => {
	const { currentUser } = useAuth();
	const [referenceEventOrganisers, setReferenceEventOrganisers] = useState([]);
	const [referenceEventDisciplines, setReferenceEventDisciplines] = useState(
		[]
	);
	let { id } = useParams();
	const { addToast } = useToast();
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const hrefLink = useRef(null);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [imgSrcPdf, setImageSrcPdf] = useState(null);
	const {
		handleSubmit,
		watch,
		control,
		setValue,
		trigger,
		formState: { errors, isSubmitted }
	} = useForm({
		mode: 'onSubmit',
		defaultValues: DEFAULT_FORM_VALUES,
		resolver: yupResolver(schema)
	});

	const values = watch();

	useEffect(() => {
		if (imgSrcPdf != null) {
			hrefLink.current.click();
		}
	}, [imgSrcPdf]);

	useEffect(() => {
		const fetchSponsorshipOutcomeById = async () => {
			setIsLoading(true);
			const defaultOrganiserId = await fetchOrganiserId();
			AppSyncService.execute(getSponsorshipOutcomeFormById, { id: +id }).then(
				(result) => {
					setIsLoading(false);
					if (result) {
						if (result.data?.getSponsorshipOutcomeFormById) {
							setIsReadOnly(
								result.data?.getSponsorshipOutcomeFormById?.status ===
									APPROVED ||
									result.data?.getSponsorshipOutcomeFormById?.status === REVIEW
							);
							const formData = result.data?.getSponsorshipOutcomeFormById;
							updateFormData(formData);
						}
					}
					setValue('organisation', defaultOrganiserId);
				}
			);
		};

		const fetchSponsorshipOutcome = async () => {
			setIsLoading(true);
			const defaultOrganiserId = await fetchOrganiserId();
			AppSyncService.execute(getSponsorshipOutcomeForm).then((result) => {
				setIsLoading(false);
				if (result) {
					if (result.data?.getSponsorshipOutcomeForm) {
						setIsReadOnly(
							result.data?.getSponsorshipOutcomeForm?.status === APPROVED ||
								result.data?.getSponsorshipOutcomeForm?.status === REVIEW
						);
						const formData = result.data?.getSponsorshipOutcomeForm;
						updateFormData(formData);
					}
				}
				setValue('organisation', defaultOrganiserId);
			});
		};

		if (id) fetchSponsorshipOutcomeById();
		else fetchSponsorshipOutcome();

		AppSyncService.execute(getEventDropdowns).then((data) => {
			if (data?.data?.listEventDisciplines) {
				// setReferenceEventDisciplines(
				// 	[
				// 		{
				// 			label: null,
				// 			value: ''
				// 		}
				// 	].concat(data.data.listEventDisciplines)
				// );
				setReferenceEventDisciplines(
					convertToSortedMultiListData(data.data.listEventDisciplines)
				);
			}

			if (data?.data?.listEventOrganisers) {
				setReferenceEventOrganisers(data.data.listEventOrganisers);
			}
		});
	}, []);

	const fetchOrganiserId = async () => {
		const result = await AppSyncService.execute(getSelectedMemberRoles, {
			memberExternalId: currentUser?.UserId
		});

		const {
			data: { getMemberRoles }
		} = result;
		const competitionAdminRoleExist = getMemberRoles.some(
			(role) => role.roleId === 12
		);

		if (competitionAdminRoleExist) {
			const filteredCompetitionAdminRole = getMemberRoles.find(
				(member) => member.roleId === 12
			);

			return filteredCompetitionAdminRole.eventOrganiserId;
		} else return null;
	};

	const updateFormData = (data) => {
		setValue('id', data.id ?? null);
		setValue('representativeName', data.representativeName ?? null);
		setValue('role', data.role ?? null);
		setValue('contactDetails', data.contactDetails ?? null);
		setValue(
			'reportDate',
			data.reportDate
				? formatDate(data.reportDate)
				: DEFAULT_FORM_VALUES.reportDate
		);
		setValue(
			'effectiveDateAgreement',
			data.effectiveDateAgreement
				? formatDate(data.effectiveDateAgreement)
				: null
		);
		setValue(
			'conclusionDateAgreement',
			data.conclusionDateAgreement
				? formatDate(data.conclusionDateAgreement)
				: null
		);
		setValue(
			'totalValueAgreement',
			data.totalValueAgreement
				? numeral(data.totalValueAgreement).format('0,0.00')
				: null
		);
		setValue(
			'utilizationOfOttSponsorship',
			data.utilizationOfOttSponsorship?.length > 0
				? mapArrayToMultiSelectValues(
						data.utilizationOfOttSponsorship,
						ottSponsorshipOptions
				  )
				: DEFAULT_FORM_VALUES.utilizationOfOttSponsorship
		);
		setValue(
			'utilizationOfOttSponsorshipOther',
			data.utilizationOfOttSponsorshipOther ?? null
		);
		setValue(
			'numberOfEventsIncluded',
			data.numberOfEventsIncluded ?? DEFAULT_FORM_VALUES.numberOfEventsIncluded
		);
		setValue('totalOttClassesRun', data.totalOttClassesRun ?? null);
		setValue('overallEntrantsNumber', data.overallEntrantsNumber ?? null);
		setValue('ottEntrantsNumber', data.ottEntrantsNumber ?? null);
		// setValue('discipline', data.discipline?.id ?? null);
		setValue(
			'discipline',
			data.discipline?.length > 0
				? convertToSortedMultiListData(data.discipline)
				: DEFAULT_FORM_VALUES.discipline
		);
		setValue('disciplineOther', data.otherDiscipline ?? null);
		setValue(
			'isPrizeMoneyDistributed',
			data.isPrizeMoneyDistributed ??
				DEFAULT_FORM_VALUES.isPrizeMoneyDistributed
		);
		// setValue(
		// 	'amountOfPrizeMoneyDistributed',
		// 	data.amountOfPrizeMoneyDistributed ?? null
		// );
		setValue(
			'amountOfPrizeMoneyDistributed',
			data.amountOfPrizeMoneyDistributed
				? numeral(data.amountOfPrizeMoneyDistributed).format('0,0.00')
				: null
		);
		setValue(
			'numberOfFacebookEventPosts',
			data.numberOfFacebookEventPosts ?? null
		);
		setValue(
			'numberOfInstagramEventPosts',
			data.numberOfInstagramEventPosts ?? null
		);
		setValue('otherSocialMediaUpdates', data.otherSocialMediaUpdates ?? null);
		setValue(
			'numberOfFacebookPostShare',
			data.numberOfFacebookPostShare ?? null
		);
		setValue(
			'numberOfInstagramPostShare',
			data.numberOfInstagramPostShare ?? null
		);
		setValue('otherComments', data.otherComments ?? null);
		setValue('signageDescription', data.signageDescription ?? null);
		setValue('whatWorkedWellQuestion', data.whatWorkedWellQuestion ?? null);
		setValue('whatDidNotWorkQuestion', data.whatDidNotWorkQuestion ?? null);
		setValue('howToImproveQuestion', data.howToImproveQuestion ?? null);
		setValue('keyAchievements', data.keyAchievements ?? null);
		setValue('feedbackFromMembers', data.feedbackFromMembers ?? null);
		setValue(
			'isAnyPathwaysInititiativesCreated',
			data.isAnyPathwaysInititiativesCreated ??
				DEFAULT_FORM_VALUES.isAnyPathwaysInititiativesCreated
		);
		setValue(
			'competitiveOrNonCompetitive',
			data.competitiveOrNonCompetitive?.length > 0
				? mapArrayToMultiSelectValues(
						data.competitiveOrNonCompetitive,
						competitiveOrNonOptions
				  )
				: DEFAULT_FORM_VALUES.competitiveOrNonCompetitive
		);
		setValue('numberOfNewPathways', data.numberOfNewPathways ?? null);
		setValue(
			'isIncreasedUpstakeOfThoroughbreds',
			data.isIncreasedUpstakeOfThoroughbreds ??
				DEFAULT_FORM_VALUES.isIncreasedUpstakeOfThoroughbreds
		);
		setValue('numberOfNewOtts', data.numberOfNewOtts ?? null);
		setValue('commentsOrSuggestions', data.commentsOrSuggestions ?? null);
		setValue(
			'eventPhotos',
			data.eventPhotos?.length > 0
				? mapFileArray(data.eventPhotos)
				: DEFAULT_FORM_VALUES.eventPhotos
		);
		setValue(
			'signageOrPromotionPhotos',
			data.signageOrPromotionPhotos?.length > 0
				? mapFileArray(data.signageOrPromotionPhotos)
				: DEFAULT_FORM_VALUES.signageOrPromotionPhotos
		);
	};

	const saveData = async (payload) => {
		const result = await AppSyncService.execute(saveSponsorshipOutcomeForm, {
			input: payload
		});
		return result;
	};

	const generatePayload = (data) => {
		const { organisation, discipline, ...otherProperties } = data;
		const filteredDiscipline = discipline.map((item) => item.value);
		return {
			...otherProperties,
			lastUpdated: otherProperties.lastUpdated || null,
			lastSubmitted: otherProperties.lastSubmitted || null,
			organisationId: organisation || null,
			disciplineId: null,
			discipline: filteredDiscipline,
			competitiveOrNonCompetitive:
				otherProperties.competitiveOrNonCompetitive?.map(
					(item) => item.value
				) || [],
			utilizationOfOttSponsorship:
				otherProperties.utilizationOfOttSponsorship?.map(
					(item) => item.value
				) || [],
			totalValueAgreement: numeral(otherProperties.totalValueAgreement).value(),
			amountOfPrizeMoneyDistributed: numeral(
				otherProperties.amountOfPrizeMoneyDistributed
			).value()
		};
	};

	const onSubmit = async () => {
		const payload = generatePayload({ ...values, status: REVIEW });
		const result = await saveData(payload);
		if (
			result.data != null &&
			!result.data?.saveSponsorshipOutcomeForm?.error?.errorMessage
		) {
			addToast({ Message: 'Form Submitted', IsSuccess: true });
			navigate(EVENT_REPORTING_ROUTE);
		} else {
			addToast({ Message: 'Failed to submit form', IsSuccess: false });
		}
	};

	const handleChange = (name, value) => {
		setValue(name, value);
	};

	const handleDatechange = (name, value) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			setValue(name, formattedDate);
		} else {
			setValue(name, null);
		}
	};

	const handleChangeMultiSelect = (val, name, fieldsToClear) => {
		if (fieldsToClear && fieldsToClear.length > 0) {
			fieldsToClear.forEach((field) => {
				const valueToFind = val?.find((item) => item.value == field.value);
				if (!valueToFind) setValue(field.name, null);
			});
		}
		if (val?.length > 0) setValue(name, val);
		else setValue(name, []);
	};

	const handlePhotoUpload = (image, name) => {
		{
			const headers = {
				'Content-Type': image.type
			};
			const imageKey = { imageKey: image.name };
			AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
				if (data.data != null) {
					let imageKey = data.data.putUserImage.imageKey;
					axios
						.put(data.data.putUserImage.signedUrl, image, { headers: headers })
						.then(() => {
							let items = { ...values };
							let dataList = Object.assign([], items[name]);
							dataList.push({ filename: image.name, key: imageKey });
							setValue(name, dataList);
							addToast({ Message: 'File uploaded', IsSuccess: true });
						})
						.catch(() => {
							addToast({
								Message: 'Error Uploading to S3...',
								IsSuccess: false
							});
						});
				} else {
					addToast({
						Message: 'Error Uploading to S3...',
						IsSuccess: false
					});
				}
			});
		}
	};

	const removeImage = (img, name) => {
		let items = { ...values };
		let dataList = Object.assign([], items[name]);
		let newDataList = dataList.filter((d) => d.key != img.key);
		setValue(name, newDataList);
		addToast({ Message: 'File removed', IsSuccess: true });
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			if (!img.key.includes('.pdf')) {
				setImgSrcUrl(data.data.getUserImage.signedUrl);
			} else {
				setImageSrcPdf(data.data.getUserImage.signedUrl);
			}
		});
	};

	const handleUploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleDraft = async () => {
		const payload = generatePayload({ ...values, status: DRAFT });
		const result = await saveData(payload);
		if (
			result.data != null &&
			!result.data?.saveSponsorshipOutcomeForm?.error?.errorMessage
		) {
			addToast({ Message: 'Form Saved', IsSuccess: true });
			navigate(EVENT_REPORTING_ROUTE);
		} else {
			addToast({ Message: 'Error saving form', IsSuccess: false });
		}
	};
	return (
		<PageContainer>
			<SEO title="OTT sponsorship agreement - outcome report" />
			{isLoading && (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			)}
			<Container
				className={
					['xs', 'sm', 'md'].includes(screenClassForRender)
						? classes.mobileContainer
						: classes.container
				}
			>
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormBody>
						<FormSection sectionTitle="OTT sponsorship agreement - outcome report">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Organisation*</div>
											{/* <Controller
												control={control}
												name="organisation"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormDropDown
														showBlank
														disabled={isReadOnly}
														items={referenceEventOrganisers}
														selectedId={values.organisation}
														{...field}
														styles={{ width: '100%' }}
													/>
												)}
											/> */}
											<FormTextField
												value={
													referenceEventOrganisers.find(
														(org) => org.id === values?.organisation
													)?.value
												}
												canEdit={false}
											/>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Representative Name*
											</div>
											<Controller
												control={control}
												name="representativeName"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormTextField canEdit={!isReadOnly} {...field} />
												)}
											/>
											<span className={classes.errorText}>
												{errors.representativeName?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Role*</div>
											<Controller
												control={control}
												name="role"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormTextField canEdit={!isReadOnly} {...field} />
												)}
											/>
											<span className={classes.errorText}>
												{errors.role?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Contact Details*</div>
											<Controller
												control={control}
												name="contactDetails"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormTextField canEdit={!isReadOnly} {...field} />
												)}
											/>
											<span className={classes.errorText}>
												{errors.contactDetails?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Report Date*</div>
											<Controller
												control={control}
												name="reportDate"
												render={({ field }) => (
													<>
														{isReadOnly ? (
															<FormTextField
																name="reportDate"
																{...field}
																value={formatDateReadableVariant(
																	values.reportDate
																)}
																canEdit={false}
															/>
														) : (
															<FormDatePicker
																{...field}
																canEdit={true}
																isError={
																	errors.reportDate?.message ? true : false
																}
																DateValue={values.reportDate}
																updateSelection={async (value) => {
																	handleDatechange('reportDate', value);
																	if (isSubmitted) await trigger('reportDate');
																}}
															/>
														)}
													</>
												)}
											/>
											<span className={classes.errorText}>
												{errors.reportDate?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Effective date of Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="effectiveDateAgreement"
												render={({ field }) => (
													<>
														{isReadOnly ? (
															<FormTextField
																name="effectiveDateAgreement"
																{...field}
																value={formatDateReadableVariant(
																	values.effectiveDateAgreement
																)}
																canEdit={false}
															/>
														) : (
															<FormDatePicker
																{...field}
																canEdit={true}
																isError={
																	errors.effectiveDateAgreement?.message
																		? true
																		: false
																}
																DateValue={values.effectiveDateAgreement}
																updateSelection={async (value) => {
																	handleDatechange(
																		'effectiveDateAgreement',
																		value
																	);
																	if (isSubmitted)
																		await trigger('effectiveDateAgreement');
																}}
															/>
														)}
													</>
												)}
											/>
											<span className={classes.errorText}>
												{errors.effectiveDateAgreement?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Conclusion date of Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="conclusionDateAgreement"
												render={({ field }) => (
													<>
														{isReadOnly ? (
															<FormTextField
																name="conclusionDateAgreement"
																{...field}
																value={formatDateReadableVariant(
																	values.conclusionDateAgreement
																)}
																canEdit={false}
															/>
														) : (
															<FormDatePicker
																{...field}
																canEdit={true}
																isError={
																	errors.conclusionDateAgreement?.message
																		? true
																		: false
																}
																DateValue={values.conclusionDateAgreement}
																noMaxDate={true}
																updateSelection={async (value) => {
																	handleDatechange(
																		'conclusionDateAgreement',
																		value
																	);
																	if (isSubmitted)
																		await trigger('conclusionDateAgreement');
																}}
															/>
														)}
													</>
												)}
											/>
											<span className={classes.errorText}>
												{errors.conclusionDateAgreement?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total value of Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="totalValueAgreement"
												onChange={(e) => {
													const isValid = validateNumeric(e.target.value);
													if (isValid) {
														handleChange(
															e.target.name,
															e.target.value?.toString()
														);
													}
												}}
												render={({ field }) => (
													<FormTextField
														{...field}
														canEdit={!isReadOnly}
														preSymbol={'$'}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.totalValueAgreement?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												How was the sponsorship from OTT utilised?*
											</div>
											<Controller
												control={control}
												name="utilizationOfOttSponsorship"
												render={({ field }) => (
													<>
														{isReadOnly ? (
															<div style={{ padding: '10px 0px 10px 0px' }}>
																<FormLabelField
																	value={values.utilizationOfOttSponsorship
																		.map((item) => item.label)
																		.join(', ')}
																	styles={{ padding: 0 }}
																/>
															</div>
														) : (
															<FormMultiSelectList
																{...field}
																canEdit={!isReadOnly}
																listOptions={ottSponsorshipOptions}
																selectedIds={values.utilizationOfOttSponsorship}
																onSelectChange={async (val) => {
																	handleChangeMultiSelect(
																		val,
																		'utilizationOfOttSponsorship',
																		[
																			{
																				name:
																					'utilizationOfOttSponsorshipOther',
																				value: 4
																			}
																		]
																	);
																	if (isSubmitted) {
																		await trigger(
																			'utilizationOfOttSponsorship'
																		);
																		await trigger(
																			'utilizationOfOttSponsorshipOther'
																		);
																	}
																}}
																border={
																	errors.utilizationOfOttSponsorship?.message
																		? 'red'
																		: null
																}
															/>
														)}
													</>
												)}
											/>
											<span className={classes.errorText}>
												{errors.utilizationOfOttSponsorship?.message}
											</span>
										</div>
									</Col>
								</Row>
								{values?.utilizationOfOttSponsorship?.find(
									(item) => item.value == 4
								) && (
									<Row>
										<Col xs={12}>
											<div className={classes.listfieldContainer}>
												<Controller
													control={control}
													name="utilizationOfOttSponsorshipOther"
													onChange={(e) => {
														handleChange(e.target.name, e.target.value);
													}}
													render={({ field }) => (
														<ResizingTextArea
															{...field}
															title={'Other details of Sponsorship Agreement*'}
															value={values.utilizationOfOttSponsorshipOther}
															maxChars={500}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															canEdit={!isReadOnly}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.utilizationOfOttSponsorshipOther?.message}
												</span>
											</div>
										</Col>
									</Row>
								)}
							</div>
						</FormSection>
						<FormSection sectionTitle="Events overview">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of events which included individual OTT classes?*
											</div>
											<Controller
												control={control}
												name="numberOfEventsIncluded"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormDropDown
														showBlank
														disabled={isReadOnly}
														items={numberOfEventsOptions}
														selectedId={values.numberOfEventsIncluded}
														{...field}
														styles={{ width: '100%' }}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.numberOfEventsIncluded?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total OTT classes run during this Sponsorship Agreement*
											</div>
											<Controller
												control={control}
												name="totalOttClassesRun"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.totalOttClassesRun?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Total horses entered overall *
											</div>
											<Controller
												control={control}
												name="overallEntrantsNumber"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.overallEntrantsNumber?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT entrants*
											</div>
											<Controller
												control={control}
												name="ottEntrantsNumber"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.ottEntrantsNumber?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Discipline/s included*
											</div>
											{/* <Controller
												control={control}
												name="discipline"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<FormDropDown
														showBlank
														disabled={isReadOnly}
														items={referenceEventDisciplines}
														selectedId={values.discipline}
														{...field}
														styles={{ width: '100%' }}
													/>
												)}
											/> */}
											{/* <span className={classes.errorText}>
												{errors.discipline?.message}
											</span> */}

											<Controller
												control={control}
												name="discipline"
												render={({ field }) => (
													<>
														{isReadOnly ? (
															<div style={{ padding: '10px 0px 10px 0px' }}>
																<FormLabelField
																	value={values.discipline
																		.map((item) => item.label)
																		.join(', ')}
																	styles={{ padding: 0 }}
																/>
															</div>
														) : (
															<FormMultiSelectList
																{...field}
																canEdit={!isReadOnly}
																listOptions={referenceEventDisciplines}
																selectedIds={values.discipline}
																onSelectChange={async (val) => {
																	handleChangeMultiSelect(val, 'discipline', [
																		{
																			name: 'disciplineOther',
																			value: 4
																		}
																	]);
																	if (isSubmitted) {
																		await trigger('discipline');
																		await trigger('disciplineOther');
																	}
																}}
																border={
																	errors.discipline?.message ? 'red' : null
																}
															/>
														)}
													</>
												)}
											/>
										</div>
										<span className={classes.errorText}>
											{errors.discipline?.message}
										</span>
									</Col>
								</Row>
								{values?.discipline.filter((r) => r.label === 'Other').length >
									0 && (
									<Row>
										<Col xs={12}>
											<div className={classes.listfieldContainer}>
												<Controller
													control={control}
													name="disciplineOther"
													onChange={(e) => {
														handleChange(e.target.name, e.target.value);
													}}
													render={({ field }) => (
														<ResizingTextArea
															{...field}
															title={'Other Discipline/s included*'}
															value={values.disciplineOther}
															maxChars={500}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															canEdit={!isReadOnly}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.disciplineOther?.message}
												</span>
											</div>
										</Col>
									</Row>
								)}
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Was any prizemoney distributed?
											</div>
											<Controller
												control={control}
												name="isPrizeMoneyDistributed"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<Toggle
														{...field}
														id={
															SponsorshipOutcomeFormKeys.PRIZE_MONEY_DISTRIBUTED
														}
														labelNegative="No"
														labelPositive="Yes"
														onClick={async (val) => {
															handleChange('isPrizeMoneyDistributed', val);
															if (isSubmitted)
																await trigger('amountOfPrizeMoneyDistributed');
														}}
														value={values.isPrizeMoneyDistributed}
														disabled={isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.isPrizeMoneyDistributed?.message}
											</span>
										</div>
									</Col>
									{values.isPrizeMoneyDistributed && (
										<Col lg={4} xs={12}>
											<div className={classes.listfieldContainer}>
												<div className={classes.fieldTitle}>
													If Yes, what was the total amount of prizemoney
													distributed?*
												</div>
												<Controller
													control={control}
													name="amountOfPrizeMoneyDistributed"
													onChange={(e) => {
														const isValid = validateNumeric(e.target.value);
														if (isValid) {
															handleChange(
																e.target.name,
																e.target.value?.toString()
															);
														}
													}}
													render={({ field }) => (
														<FormTextField
															{...field}
															canEdit={!isReadOnly}
															preSymbol={'$'}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.amountOfPrizeMoneyDistributed?.message}
												</span>
											</div>
										</Col>
									)}
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="Marketing and promotion">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of event posts, Facebook*
											</div>
											<Controller
												control={control}
												name="numberOfFacebookEventPosts"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.numberOfFacebookEventPosts?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of event posts, Instagram*
											</div>
											<Controller
												control={control}
												name="numberOfInstagramEventPosts"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.numberOfInstagramEventPosts?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="otherSocialMediaUpdates"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Other social media updates?'}
														value={values.otherSocialMediaUpdates}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.otherSocialMediaUpdates?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT post shares, Facebook*
											</div>
											<Controller
												control={control}
												name="numberOfFacebookPostShare"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.numberOfFacebookPostShare?.message}
											</span>
										</div>
									</Col>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Number of OTT post shares, Instagram*
											</div>
											<Controller
												control={control}
												name="numberOfInstagramPostShare"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<StyledFieldText
														disabled={isReadOnly}
														{...field}
														type="number"
														min={0}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.numberOfInstagramPostShare?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="otherComments"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Other comments'}
														value={values.otherComments}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.otherComments?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="signageDescription"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Please describe the signage presence at the event/s*'
														}
														value={values.signageDescription}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.signageDescription?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Photos of signage or promotions
											</div>
											<Controller
												control={control}
												name="signageOrPromotionPhotos"
												render={({ field }) => (
													<UploadPhoto
														{...field}
														formats={['png', 'jpeg']}
														labelName="Upload File"
														error={handleUploadError}
														data={values.signageOrPromotionPhotos}
														sizeLimitMB={5}
														numberOfImages={10}
														success={(image) =>
															handlePhotoUpload(
																image,
																'signageOrPromotionPhotos'
															)
														}
														getImage={getImage}
														removeImage={(image) =>
															removeImage(image, 'signageOrPromotionPhotos')
														}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.signageOrPromotionPhotos}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection sectionTitle="Feedback and highlights">
							<div className={classes.sectionContainer}>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="whatWorkedWellQuestion"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'What worked well?*'}
														value={values.whatWorkedWellQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.whatWorkedWellQuestion?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="whatDidNotWorkQuestion"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'What didn’t work, why?*'}
														value={values.whatDidNotWorkQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.whatDidNotWorkQuestion?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="howToImproveQuestion"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'How can this be improved if the sponsorship is continued in the following year?*'
														}
														value={values.howToImproveQuestion}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.howToImproveQuestion?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="keyAchievements"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Key achievements and/ or highlights, specific to OTT if possible*'
														}
														value={values.keyAchievements}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.keyAchievements?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="feedbackFromMembers"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={'Feedback from your members?*'}
														value={values.feedbackFromMembers}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.feedbackFromMembers?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Row>
									<Col xs={12} lg={4}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>Photos event/s</div>
											<Controller
												control={control}
												name="eventPhotos"
												render={({ field }) => (
													<UploadPhoto
														{...field}
														formats={['png', 'jpeg']}
														labelName="Upload File"
														error={handleUploadError}
														data={values.eventPhotos}
														sizeLimitMB={5}
														numberOfImages={10}
														success={(image) =>
															handlePhotoUpload(image, 'eventPhotos')
														}
														getImage={getImage}
														removeImage={(image) =>
															removeImage(image, 'eventPhotos')
														}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.eventPhotos}
											</span>
										</div>
									</Col>
								</Row>
							</div>
						</FormSection>
						<FormSection withBottomBorder={true} sectionTitle="OTT pathways">
							<div className={classes.sectionContainer}>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Were any new pathways or initiatives activated?
											</div>
											<Controller
												control={control}
												name="isAnyPathwaysInititiativesCreated"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<Toggle
														{...field}
														id={SponsorshipOutcomeFormKeys.PATHWAYS_ACTIVATED}
														labelNegative="No"
														labelPositive="Yes"
														onClick={async (val) => {
															handleChange(
																'isAnyPathwaysInititiativesCreated',
																val
															);
															if (isSubmitted) {
																await trigger('competitiveOrNonCompetitive');
																await trigger('numberOfNewPathways');
															}
														}}
														value={values.isAnyPathwaysInititiativesCreated}
														disabled={isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.isAnyPathwaysInititiativesCreated?.message}
											</span>
										</div>
									</Col>
									{values.isAnyPathwaysInititiativesCreated && (
										<>
											<Col lg={4} xs={12}>
												<div className={classes.listfieldContainer}>
													<div className={classes.fieldTitle}>
														Competitive or Non-competitive?*
													</div>
													<Controller
														control={control}
														name="competitiveOrNonCompetitive"
														render={({ field }) => (
															<>
																{isReadOnly ? (
																	<div style={{ padding: '10px 0px 10px 0px' }}>
																		<FormLabelField
																			value={values.competitiveOrNonCompetitive
																				.map((item) => item.label)
																				.join(', ')}
																			styles={{ padding: 0 }}
																		/>
																	</div>
																) : (
																	<FormMultiSelectList
																		{...field}
																		canEdit={true}
																		listOptions={competitiveOrNonOptions}
																		selectedIds={
																			values.competitiveOrNonCompetitive
																		}
																		onSelectChange={async (val) => {
																			handleChangeMultiSelect(
																				val,
																				'competitiveOrNonCompetitive'
																			);
																			if (isSubmitted)
																				await trigger(
																					'competitiveOrNonCompetitive'
																				);
																		}}
																		border={
																			errors.competitiveOrNonCompetitive
																				?.message
																				? 'red'
																				: null
																		}
																	/>
																)}
															</>
														)}
													/>
													<span className={classes.errorText}>
														{errors.competitiveOrNonCompetitive?.message}
													</span>
												</div>
											</Col>
											<Col lg={4} xs={12}>
												<div className={classes.listfieldContainer}>
													<div className={classes.fieldTitle}>
														Number of new pathways*
													</div>
													<Controller
														control={control}
														name="numberOfNewPathways"
														onChange={(e) => {
															handleChange(e.target.name, e.target.value);
														}}
														render={({ field }) => (
															<StyledFieldText
																disabled={isReadOnly}
																{...field}
																type="number"
																min={0}
															/>
														)}
													/>
													<span className={classes.errorText}>
														{errors.numberOfNewPathways?.message}
													</span>
												</div>
											</Col>
										</>
									)}
								</Row>
								<Row>
									<Col lg={4} xs={12}>
										<div className={classes.listfieldContainer}>
											<div className={classes.fieldTitle}>
												Are you experiencing an increased uptake of
												thoroughbreds?
											</div>
											<Controller
												control={control}
												name="isIncreasedUpstakeOfThoroughbreds"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<Toggle
														{...field}
														id={SponsorshipOutcomeFormKeys.INCREASED_UPSTAKE}
														labelNegative="No"
														labelPositive="Yes"
														onClick={async (val) => {
															handleChange(
																'isIncreasedUpstakeOfThoroughbreds',
																val
															);
															if (isSubmitted) {
																await trigger('numberOfNewOtts');
															}
														}}
														value={values.isIncreasedUpstakeOfThoroughbreds}
														disabled={isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.isIncreasedUpstakeOfThoroughbreds?.message}
											</span>
										</div>
									</Col>
									{values.isIncreasedUpstakeOfThoroughbreds && (
										<Col lg={4} xs={12}>
											<div className={classes.listfieldContainer}>
												<div className={classes.fieldTitle}>
													Number of new OTTs?*
												</div>
												<Controller
													control={control}
													name="numberOfNewOtts"
													onChange={(e) => {
														handleChange(e.target.name, e.target.value);
													}}
													render={({ field }) => (
														<StyledFieldText
															disabled={isReadOnly}
															{...field}
															type="number"
															min={0}
														/>
													)}
												/>
												<span className={classes.errorText}>
													{errors.numberOfNewOtts?.message}
												</span>
											</div>
										</Col>
									)}
								</Row>
								<Row>
									<Col xs={12}>
										<div className={classes.listfieldContainer}>
											<Controller
												control={control}
												name="commentsOrSuggestions"
												onChange={(e) => {
													handleChange(e.target.name, e.target.value);
												}}
												render={({ field }) => (
													<ResizingTextArea
														{...field}
														title={
															'Comments or suggestions on how to progress new OTT pathways or opportunities'
														}
														value={values.commentsOrSuggestions}
														maxChars={500}
														styles={{
															marginLeft: 0,
															marginRight: 0,
															padding: 0
														}}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											<span className={classes.errorText}>
												{errors.commentsOrSuggestions?.message}
											</span>
										</div>
									</Col>
								</Row>
								<Divider style={{ marginBottom: 25 }} />
								<Row
									style={{ display: 'flex', justifyContent: 'space-between' }}
								>
									<Col lg={4}></Col>
									<Col lg={4} style={{ display: 'flex' }}>
										<div className={classes.saveRequest}>
											<div
												style={!isReadOnly ? {} : { visibility: 'hidden' }}
												className={classes.saveButton}
												onClick={handleDraft}
												id={CommonKeys.SAVE_DRAFT}
											>
												Save Draft
											</div>
										</div>
										<PrimaryButton
											onClick={handleSubmit}
											id={CommonKeys.SUBMIT}
											style={{ width: '70%', marginTop: 0 }}
											disabled={isReadOnly}
										>
											Submit
										</PrimaryButton>
									</Col>
								</Row>
								<Row>
									<Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
										{' '}
										{Object.keys(errors).length > 0 && (
											<div
												className={classes.errorMessage}
												style={
													['xs', 'sm', 'md'].includes(screenClassForRender)
														? {}
														: {
																marginRight: 20,
																marginTop: 20,
																marginBottom: 20
														  }
												}
											>
												Please complete all required fields
											</div>
										)}
									</Col>
								</Row>
							</div>
						</FormSection>
					</FormBody>
				</form>
				<UploadPhotoErrorModal
					showErrorModal={showImageUploadErrorModal}
					closeModal={() => setShowImageUploadErrorModal(false)}
					isMinimumUploadSize={isMinimumUploadSize}
				/>
				{imgSrcUrl && (
					<Lightbox
						mainSrc={imgSrcUrl}
						onCloseRequest={() => setImgSrcUrl(false)}
					/>
				)}
				<a
					style={{ visibility: 'hidden', cursor: 'pointer' }}
					href={imgSrcPdf}
					ref={hrefLink}
					target="_blank"
					rel="noreferrer"
				>
					Pdf Link
				</a>
			</Container>
		</PageContainer>
	);
};

export default SponsorshipOutcomeForm;

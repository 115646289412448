import React from "react";
import { JPEG, MP4 } from "../../utils/constants";
import useStyles from "./styles";

const content = Object.freeze({
  image: JPEG,
  video: MP4,
});

const ContentTypeDisplay = ({
  contentType = content.image,
  contentSrc,
  mobile,
  small
}) => {
  const classes = useStyles();
  const renderContent = () => {
    if (mobile) {
      switch (contentType) {
        case JPEG:
          return <img src={contentSrc} className={classes.imageMobile}></img>
        case MP4:
          return (
            <video className={classes.videoMobile} controls muted autoPlay preload="metadata">
              <source src={contentSrc} type={contentType} />
              Your browser does not support the video tag.
            </video>
          );
        default:
          return <></>;
      }
    } 
    else if(small){
      switch (contentType) {
        case JPEG:
          return <img src={contentSrc} className={classes.imageSmall}></img>
        case MP4:
          return (
            <video className={classes.videoSmall} controls>
              <source src={contentSrc} type={contentType} />
              Your browser does not support the video tag.
            </video>
          );
        default:
          return <></>;
      }
    }
    else {
      switch (contentType) {
        case JPEG:
          return <img src={contentSrc} className={classes.image}></img>
        case MP4:
          return (
            <video className={classes.video} controls>
              <source src={contentSrc} type={contentType} />
              Your browser does not support the video tag.
            </video>
          );
        default:
          return <></>;
      }
    }
  };

  return <>{renderContent()}</>;
};

export default ContentTypeDisplay;

import { createUseStyles } from 'react-jss';

export const IMAGE_CONTAINER_COLUMN_STYLES = { padding: 0 };

export default createUseStyles({
	questionContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box'
	},
	question: {
		fontSize: 20,
		textAlign: 'center',
		'@media (min-width:992px)': {
			fontSize: 25
		}
	},
	optionsContainer: {
		marginTop: 7,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%'
	},
	imageContainer: {
		height: 300,
		'@media (min-width:992px)': {
			height: 400
		}
	},
	imageStyles: {
		width: '100%',
		height: '100%',
		objectFit: 'cover'
	},
	transitionStepContainer: {
		background: '#006da6',
		borderRadius: 3,
		color: '#fff',
		display: 'flex',
		flexDirection: 'column-reverse',
		overflow: 'hidden',
		'@media (min-width:992px)': {
			'flex-direction': 'row'
		}
	},
	outcomeStep: {
		background: '#ededed',
		color: '#464749'
	},
	messageBar: {
		marginTop: 20,
		borderRadius: 3
	}
});

import React, { useState, useEffect } from 'react';
import { Container, useScreenClass } from 'react-grid-system';
import qs from 'query-string';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getResetFormById,
	getResetWeeklyFormById,
	getResetInitialFormById,
	getResetExpressInitialFormById,
	getResetExpressRehomeAssessmentFormById,
	getResetExpressRehabilitationConclusionFormById
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import {
	ResetNominationFormReviewInfo,
	ResetWeeklyFormReviewInfo,
	ResetInitialFormReviewInfo,
	ResetExpressInitialFormReviewInfo,
	ResetExpressRehabilitationReportReviewInfo
} from '../ResetFormReviewInfo';
import { getDataModel } from './helper';
import { RESET_FORMS } from '../../utils/constants';
import { getHorseDisplayName, parseUTCToLocal } from '../../utils/helpers';
import { mapAdminOptionsForLocal } from '../ResetFormReviewInfo/utils';
import ResetExpressRehomeReportReviewInfo from '../ResetFormReviewInfo/ResetExpressRehomeReportReviewInfo';

const ResetFormReview = ({ location }) => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState(null);
	const [resetFormReviewInfo, setResetFormReviewInfo] = useState();
	const subType = qs.parse(location?.search)?.subType;

	useEffect(() => {
		setIsLoading(true);
		switch (subType) {
			case RESET_FORMS.RESET_NOMINATION:
				{
					AppSyncService.execute(getResetFormById, { id }).then((result) => {
						if (result?.data?.getResetNominationFormById) {
							let data = getDataModel(result.data.getResetNominationFormById);
							setData(data);
							setResetFormReviewInfo(
								<ResetNominationFormReviewInfo
									data={data}
									initialAdminData={
										result?.data?.getResetNominationFormById?.adminData
									}
									adminActionOptions={mapAdminOptionsForLocal(result.data)}
									mobile={['xs', 'sm'].includes(screenClassForRender)}
								/>
							);
						}
						setIsLoading(false);
					});
				}
				break;
			case RESET_FORMS.RESET_WEEKLY_PROGRESS:
				AppSyncService.execute(getResetWeeklyFormById, { id }).then(
					(result) => {
						if (result?.data?.getResetWeeklyProgressFormById) {
							let data = {
								...result.data.getResetWeeklyProgressFormById,
								name: result.data.getResetWeeklyProgressFormById.horse.name
							};
							setData(data);
							setResetFormReviewInfo(
								<ResetWeeklyFormReviewInfo
									data={data}
									mobile={['xs', 'sm'].includes(screenClassForRender)}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			case RESET_FORMS.RESET_INITIAL_ASSESSMENT:
				AppSyncService.execute(getResetInitialFormById, { id }).then(
					(result) => {
						if (result?.data?.getResetInitialAssessmentFormById) {
							let data = {
								...result.data.getResetInitialAssessmentFormById,
								name: result.data.getResetInitialAssessmentFormById.horse.name
							};
							setData(data);
							setResetFormReviewInfo(
								<ResetInitialFormReviewInfo
									data={data}
									mobile={['xs', 'sm'].includes(screenClassForRender)}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			case RESET_FORMS.RESET_EXPRESS_INITIAL_ASSESSMENT:
				AppSyncService.execute(getResetExpressInitialFormById, { id }).then(
					(result) => {
						if (result?.data?.getResetExpressInitialAssessmentFormById) {
							let data = {
								...result.data.getResetExpressInitialAssessmentFormById,
								name:
									result.data.getResetExpressInitialAssessmentFormById.horse
										.name
							};
							setData(data);
							setResetFormReviewInfo(
								<ResetExpressInitialFormReviewInfo
									data={data}
									mobile={['xs', 'sm'].includes(screenClassForRender)}
								/>
							);
						}
						setIsLoading(false);
					}
				);
				break;
			case RESET_FORMS.RESET_EXPRESS_REHABILITATION_REPORT:
				AppSyncService.execute(
					getResetExpressRehabilitationConclusionFormById,
					{
						id
					}
				).then((result) => {
					if (result?.data?.getResetExpressRehabilitationConclusionFormById) {
						let data = {
							...result.data.getResetExpressRehabilitationConclusionFormById,
							name:
								result.data.getResetExpressRehabilitationConclusionFormById
									.horse.name
						};
						setData(data);
						setResetFormReviewInfo(
							<ResetExpressRehabilitationReportReviewInfo
								data={data}
								mobile={['xs', 'sm'].includes(screenClassForRender)}
							/>
						);
					}
					setIsLoading(false);
				});
				break;
			case RESET_FORMS.RESET_EXPRESS_REHOME_REPORT:
				AppSyncService.execute(getResetExpressRehomeAssessmentFormById, {
					id
				}).then((result) => {
					if (result?.data?.getResetExpressRehomeAssessmentFormById) {
						let data = {
							...result.data.getResetExpressRehomeAssessmentFormById,
							name:
								result.data.getResetExpressRehomeAssessmentFormById.horse.name
						};
						setData(data);
						setResetFormReviewInfo(
							<ResetExpressRehomeReportReviewInfo
								data={data}
								mobile={['xs', 'sm'].includes(screenClassForRender)}
							/>
						);
					}
					setIsLoading(false);
				});
				break;
			default:
		}
	}, []);

	return (
		<Container
			className={
				['xs', 'sm', 'md'].includes(screenClassForRender)
					? classes.pageContainerMobile
					: classes.pageContainer
			}
		>
			<SEO title="Reset Form Review" />
			<HeaderBanner
				title={`Reset Form Review - ${getHorseDisplayName(
					data?.name,
					data?.horse?.ottInformation?.paddockName
				)}`}
				top
				mobile={['xs', 'sm'].includes(screenClassForRender)}
				type="primary"
				rightTitle={`Status: ${data?.status}`}
				styles={{ borderRadius: '5px 5px 5px 5px', fontSize: 13 }}
				onBehalfText={
					data?.behalfAdmin?.firstName && data?.behalfAdmin?.lastName ? (
						<span style={{ fontSize: 13, color: '#006da6' }}>
							(submitted on-behalf of user by{' '}
							{`${data?.behalfAdmin?.firstName} ${data?.behalfAdmin?.lastName}`}
							)
						</span>
					) : null
				}
				dateText={
					data?.lastUpdated ? (
						<span style={{ fontSize: 13 }}>
							Date Submitted:{' '}
							{parseUTCToLocal(data.lastUpdated).format('DD/MM/YYYY')}
						</span>
					) : null
				}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
			</div>
			{data && <>{resetFormReviewInfo}</>}
		</Container>
	);
};
export default ResetFormReview;

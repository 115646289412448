import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		width: 1000,
		marginBottom: 50,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	mobileContainer: {
		width: '100%',
		marginBottom: 50,
		marginTop: 80,
		paddingLeft: '15px !important',
		paddingRight: '15px !important'
	},
	checkboxFieldContainer: {
		marginTop: 10,
		marginBottom: 18
	},
	checkboxField: {
		marginLeft: 30
	},
	messageBar: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	claimText: {
		marginTop: 16
	},
	fieldContainer: {
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749'
	},
	formContainer: {
		marginTop: 20,
		border: '1px solid #b3bcc1'
	},
	formFieldsContainer: {
		margin: '10px 20px 20px 20px'
	},
	errorText: {
		color: '#db0000',
		fontSize: 15
	},
	errorTextDropDown: {
		color: '#db0000',
		fontSize: 15,
		marginTop: 5
	},
	mailLink: {
		color: '#006da6',
		textDecoration: 'none',
		fontWeight: 600
	},
	errorMessage: {
		fontSize: 15,
		color: '#db0000',
		marginTop: 10,
		float: 'right'
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 15,
		marginTop: 10,
		cursor: 'pointer'
	},
	cancelRequestMobile: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 15,
		cursor: 'pointer'
	},
	saveRequest: {
		color: '#0085ca',
		fontSize: 15,
		cursor: 'pointer'
	},
	saveButton: {
		textDecoration: 'underline',
		marginRight: 20
	},
	clear: {
		clear: 'both'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '5px 17px 17px 17px'
	},

	completeFormButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		fontWeight: 600,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	disabledCompleteFormButton: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		fontWeight: 600,
		height: 38,
		width: 170,
		fontSize: 16,
		'&:focus': {
			outline: 'none'
		}
	},
	IdentificationDivider: {
		background: '#edf5f9',
		height: 2,
		border: 'none',
		marginBottom: 25
	},
	divider: {
		extend: 'IdentificationDivider',
		height: 1
	}
}));

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	fieldContainer: {
		padding: '20px 18px 20px 18px'
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 15,
		marginBottom: 7,
		color: '#464749',
		display: 'flex',
		alignItems: 'center'
	},
	fieldValue: {
		fontSize: 15,
		marginTop: 17,
		wordBreak: 'break-word',
		lineHeight: '38px'
	},
	fieldTitleMobile: {
		fontWeight: 600,
		fontSize: 13,
		marginBottom: 7,
		color: '#464749'
	},
	fieldValueMobile: {
		fontSize: 13,
		marginTop: 17,
		wordBreak: 'break-word',
		lineHeight: '38px'
	},
	warningIcon: {
		maxWidth: 18,
		marginLeft: 5
	}
}));

import React from 'react';
import ResizingTextArea from '../../ResizingTextArea';
import { Col } from 'react-grid-system';
import ValidationErrorText from '../ValidationErrorText';

const TextAreaInputField = ({
	fieldLabel,
	id,
	onChange,
	maxChars,
	value,
	validationError,
	canEdit
}) => {
	return (
		<Col>
			<ResizingTextArea
				title={fieldLabel}
				id={id}
				onChange={onChange}
				maxChars={maxChars}
				value={value}
				styles={{ paddingTop: 0 }}
				canEdit={canEdit}
			/>
			{validationError && <ValidationErrorText errorText={validationError} />}
		</Col>
	);
};

export default TextAreaInputField;

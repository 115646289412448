import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseOTTStatus,
	reviewRetirementQuarterlyAssessmentForm,
	setOttProgramAndStatus
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import useStyles from './styles';
import HeaderBanner from '../HeaderBanner';
import FormLabelField from '../FormLabelField';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import { useToast } from '../../hooks/useToast';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import { MAXREASONLENGTH } from './constants';
import { formatDate, formatDateReadableVariant } from '../../utils/helpers';
// import { newStatusList, physicalHealthItems } from './constants';

const RetirementQuarterlyFormReviewInfo = ({ data, mobile, getImage }) => {
	const classes = useStyles();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const { addToast } = useToast();
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [horseCurrentStatus, setHorseCurrentStatus] = useState(null);

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
		AppSyncService.execute(getHorseOTTStatus, {
			horseCode: data.horse.horseCode
		}).then((response) => {
			if (response.data?.getHorseOTTStatus?.horseOTTStatusId)
				setHorseCurrentStatus(response.data.getHorseOTTStatus.horseOTTStatusId);
		});
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewRetirementQuarterlyAssessmentForm, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveRetirementQuarterlyAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveRetirementQuarterlyAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const generatePayloadOttStatus = ({
		data,
		horseCode,
		memberExternalId,
		horseCurrentStatus
	}) => {
		let ottStatusPayload = {
			programId: 7,
			horseCode: horseCode,
			memberExternalId: memberExternalId
		};
		if (!data.hasHorseStatusChanged) {
			ottStatusPayload = {
				...ottStatusPayload,
				statusId: horseCurrentStatus,
				asOfDate: formatDate(data.dateOfAssessment)
			};
		} else {
			if (data.newStatus?.id === 1) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 28,
					asOfDate: formatDate(data.dateOfAssessment)
				};
			} else if (data.newStatus?.id === 2) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 29,
					asOfDate: formatDate(data.rehabDateCommenced)
				};
			} else if (data.newStatus?.id === 3) {
				ottStatusPayload = {
					...ottStatusPayload,
					statusId: 30,
					asOfDate: formatDate(data.deceasedDateOfDeath)
				};
			} else return null;
		}
		return ottStatusPayload;
	};

	const approve = () => {
		setIsProcessingApprove(true);
		AppSyncService.execute(reviewRetirementQuarterlyAssessmentForm, {
			id: parseInt(data.id),
			status: 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveRetirementQuarterlyAssessmentFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				const ottStatus = generatePayloadOttStatus({
					data,
					horseCode: data.horse?.horseCode,
					memberExternalId: data.member?.externalId,
					horseCurrentStatus
				});
				if (ottStatus) {
					AppSyncService.execute(setOttProgramAndStatus, ottStatus).then(() => {
						navigate(`/review-list`);
					});
				}
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveRetirementQuarterlyAssessmentFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const onRenderRetiredAgistmentStatus = () => {
		return (
			<>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField
							title={`Are there any concerns with the horse's physical health?`}
							value={data?.agistmentHasPhysicalHealthChanged ? 'Yes' : 'No'}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>
								Please specify what change
							</div>
							{data?.agistmentChangesToPhysicalHealth?.value
								? data?.agistmentChangesToPhysicalHealth?.value
								: ''}
						</div>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Please specify"
							value={data?.agistmentChangesToPhysicalHealthOther}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title={`Are there any changes to the horse's behaviour`}
							value={data.agistmentHasBehaviourChanged ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Please provide more information"
							value={data.agistmentChangesToBehaviourOther}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Has the horse received any treatments since the last report?"
							value={data.horsePyhsicalHealth ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const onRenderRetirementRehabilitationStatus = () => {
		return (
			<Row className={classes.d}>
				<Col xs={12} lg={4}>
					<FormLabelField
						title="Please indicate reason for rehabilitation"
						value={data?.rehabReason?.value}
					/>
				</Col>
				<Col xs={12} lg={4}>
					<FormLabelField
						title="Injury Type"
						value={data.injuryType.map((injury) => injury.value).join(', ')}
					/>
				</Col>
				<Col xs={12} lg={4}>
					<FormLabelField
						title="Illness Type"
						value={data.illnessType.map((injury) => injury.value).join(', ')}
					/>
				</Col>

				<Col xs={12}>
					<FormLabelField
						title="Other Retirement Rehabilitation"
						value={data?.rehabReasonOther}
					/>
				</Col>
				<Col xs={12}>
					<FormLabelField
						title="Date rehabilitation commenced"
						value={
							data.rehabDateCommenced
								? formatDateReadableVariant(data.rehabDateCommenced)
								: ''
						}
					/>
				</Col>
				<Col xs={12}>
					<FormLabelField
						title="Has the horse received any treatments since the last report?"
						value={data.treatmentSinceLastReport ? 'Yes' : 'No'}
					/>
				</Col>
			</Row>
		);
	};

	const onRenderDeceasedStatus = () => {
		return (
			<>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="What was the cause of death?"
							value={data.deceasedCauseOfDeath?.value}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<FormLabelField
							title="Reason"
							value={data.deceasedReasonOfDeath?.value}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<FormLabelField
							title="Other reason for death"
							value={data.deceasedReasonForDeathOther?.value}
						/>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Knackery Postcode"
							value={data.deceasedKnackeryPostcode}
						/>
					</Col>

					<Col xs={12} lg={4}>
						<FormLabelField
							title="Knackery Suburb"
							value={data.deceasedKnackerySuburb}
						/>
					</Col>
				</Row>

				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Information about death"
							value={data.informationAboutDeath}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Date of Death"
							value={
								data.deceasedDateOfDeath
									? formatDateReadableVariant(data.deceasedDateOfDeath)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title={`Has the owner(s) been notified?`}
							value={data.hasOwnerBeenNotified ? 'Yes' : 'No'}
						/>
					</Col>
				</Row>
			</>
		);
	};

	return (
		<>
			<div
				style={{
					borderTop: '1px solid #b3bcc1',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: 'none'
				}}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="Retirement Quarterly Assessment"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date of assessment"
							value={
								data.dateOfAssessment
									? formatDateReadableVariant(data.dateOfAssessment)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastSubmitted
									? formatDateReadableVariant(data.lastSubmitted)
									: ''
							}
						/>
					</Col>

					<Col xs={12}>
						<FormLabelField
							title="Quarter Number"
							value={data.quarterNumber?.value}
						/>
					</Col>

					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Progress photo/s</div>
							<UploadPhoto
								data={data.progressPhotos}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div
								className={classes.labelField}
							>{`Has the horse's status changed?`}</div>
							{data.hasHorseStatusChanged ? 'Yes' : 'No'}
						</div>
					</Col>

					{/* <Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Current Status</div>
							{data.horse?.status ? data.horse?.status : ''}
						</div>
					</Col> */}

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>New Status</div>
							{data.newStatus?.value}
						</div>
					</Col>
				</Row>
				{data.newStatus?.value !== null && data.newStatus?.id === 1
					? onRenderRetiredAgistmentStatus()
					: null}

				{data.newStatus?.value !== null && data.newStatus?.id === 2
					? onRenderRetirementRehabilitationStatus()
					: null}

				{data.newStatus?.value !== null && data.newStatus?.id === 3
					? onRenderDeceasedStatus()
					: null}

				{data.newStatus?.value === null || data.newStatus?.id === 3 ? null : (
					<>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Worming</div>
									{data.worming ? 'Yes' : 'No'}
								</div>
							</Col>

							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Date of Completion</div>
									{data.wormingDateCompleted
										? formatDateReadableVariant(data.wormingDateCompleted)
										: ''}
								</div>
							</Col>
						</Row>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Farrier</div>
									{data.farrier ? 'Yes' : 'No'}
								</div>
							</Col>

							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Date of Completion</div>
									{data.farrierDateCompleted
										? formatDateReadableVariant(data.farrierDateCompleted)
										: ''}
								</div>
							</Col>
						</Row>
						<Row className={classes.d}>
							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Dentist</div>
									{data.dentistTreatment ? 'Yes' : 'No'}
								</div>
							</Col>

							<Col xs={12} lg={4}>
								<div className={classes.fieldContainer}>
									<div className={classes.labelField}>Date of Completion</div>
									{data.dentistTreatmentDateCompleted
										? formatDateReadableVariant(
												data.dentistTreatmentDateCompleted
										  )
										: ''}
								</div>
							</Col>
						</Row>
					</>
				)}

				{data.newStatus?.value === null || data.newStatus?.id === 3 ? null : (
					<Row className={classes.d}>
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.labelField}>
									Other Husbandry Information
								</div>
								{data.husbandryOther}
							</div>
						</Col>
					</Row>
				)}

				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.labelField}>Final comments or Note</div>
							{data.finalCommentsNotes}
						</div>
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="retirementReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default RetirementQuarterlyFormReviewInfo;

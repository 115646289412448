import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getThoroughbredReferenceData,
	uploadImageToS3,
	deleteImageFromS3,
	deleteImagesFromS3,
	getThoroughbredUnregisteredForm,
	saveThoroughbredUnregisteredForm,
	saveResidentialAddress,
	savePostalAddress,
	updateMemberProfile,
	getThoroughbredUnregisteredFormById,
	deleteThoroughbredUnregisteredFormMutation,
	deleteThoroughbredUnregisteredImagesMutation
} from '../../graphql/custom.queries';
import FormDropDown from '../FormDropDown';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from '../../hooks/useToast';
import HeaderBanner from '../HeaderBanner';
import UploadPhoto from '../UploadPhoto';
import axios from 'axios';
import MessageBar from '../MessageBar';
import ConfirmationModal from '../ConfirmationModal';
import SEO from '../seo';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import AddressAutoSuggestion from '../AddressAutoSuggestion';
import StateSelector from '../StateSelector';
import CountrySelector from '../CountrySelector';
import BasicCheckbox from '../BasicCheckbox';
import Toggle from '../Toggle';
import FormDatePicker from '../FormDatePicker';
import ResizingTextArea from '../ResizingTextArea';
import { useAuth } from '../../hooks/useAuth';
import {
	DEFAULT_COUNTRY,
	REVIEW,
	DRAFT,
	WORD_DOC_MIME_TYPE
} from '../../utils/constants';
import { formatDate, getMinDate } from '../../utils/helpers';
import { navigate, useParams } from '@reach/router';
import useFilePreview from '../../hooks/useFilePreview';

const hasHorseRacedOpts = [
	{ id: 0, value: '' },
	{ id: 1, value: 'Yes' },
	{ id: 2, value: 'No' },
	{ id: 3, value: 'Unknown' }
];

const ThoroughbredUnregisteredForm = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	let { id } = useParams();
	const { errors, control } = useForm({
		mode: 'onSubmit'
	});
	const { currentUser } = useAuth();
	const [referenceListCountries, setReferenceListCountries] = useState([]);
	const [referenceListColours, setReferenceListColours] = useState([]);
	const [colour, setColour] = useState(null);
	const [microchip, setMicrochip] = useState('');
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [profileInfo, setProfileInfo] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [isDifferentPostalAddress, setIsDifferentPostalAddress] = useState(
		false
	);
	const [
		isManuallyAddMyResidentialPostalAddress,
		setIsManuallyAddMyResidentialPostalAddress
	] = useState(false);
	const [
		isHorseLocatedAtTheSameResidentialAddress,
		setIsHorseLocatedAtTheSameResidentialAddress
	] = useState(true);
	const [address, setAddress] = useState('');
	const [suburb, setSuburb] = useState('');
	const [postcode, setPostcode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [thoroughbredAddress, setThoroughbredAddress] = useState('');
	const [thoroughbredSuburb, setThoroughbredSuburb] = useState('');
	const [thoroughbredPostcode, setThoroughbredPostcode] = useState('');
	const [thoroughbredState, setThoroughbredState] = useState('');
	const [thoroughbredCountry, setThoroughbredCountry] = useState(
		DEFAULT_COUNTRY
	);
	const [postalAddress, setPostalAddress] = useState('');
	const [postalSuburb, setPostalSuburb] = useState('');
	const [postalPostcode, setPostalPostcode] = useState('');
	const [postalState, setPostalState] = useState('');
	const [postalCountry, setPostalCountry] = useState(DEFAULT_COUNTRY);
	const [
		isManuallyAddMyThoroughbredAddress,
		setIsManuallyAddMyThoroughbredAddress
	] = useState(false);
	const [horseNameBreeding, setHorseNameBreeding] = useState('');
	const [selectedGender, setSelectedGender] = useState(null);
	const [age, setAge] = useState('');
	const [sireName, setSireName] = useState('');
	const [damName, setDamName] = useState('');
	const [countryOfBirth, setCountryOfBirth] = useState(null);
	const [countryOfRegistration, setCountryOfRegistration] = useState(null);
	const [registrationNo, setRegistrationNo] = useState('');
	const [lifeNo, setLifeNo] = useState('');
	const [overseasName, setOverseasName] = useState('');
	const [brandLeftImages, setBrandLeftImages] = useState([]);
	const [brandRightImages, setBrandRightImages] = useState([]);
	const [markingsLegImages, setMarkingsLegImages] = useState([]);
	const [markingHeadImages, setMarkingHeadImages] = useState([]);
	const [
		importExportDocumentationImages,
		setImportExportDocumentationImages
	] = useState([]);
	const [hasHorseEverRaced, setHasHorseEverRaced] = useState(0);
	const [howDidHorseComeYourCase, setHowDidHorseComeYourCase] = useState('');
	const [areThereAnyDetailsIdentity, setAreThereAnyDetailsIdentity] = useState(
		''
	);
	const [foalYear, setFoalYear] = useState(null);
	const [foalDate, setFoalDate] = useState(null);
	const [importationDate, setImportationDate] = useState(null);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);

	const [hasData, setHasData] = useState(false);

	const [dataStateInfo, setDataStateInfo] = useState(null);

	const [genderOpts, setGenderOpts] = useState([]);

	const [fieldErrors, setFieldErrors] = useState({
		contactNumber: {
			value: false,
			message: 'Contact Number is required'
		},
		residentialAddress: {
			value: false,
			message: 'Residential Address is required'
		},
		residentialSuburb: {
			value: false,
			message: 'Residential Suburb is required'
		},
		residentialState: {
			value: false,
			message: 'Residential State is required'
		},
		residentialPostcode: {
			value: false,
			message: 'Residential Postcode is required'
		},
		residentialCountry: {
			value: false,
			message: 'Residential Country is required'
		},
		postalAddress: {
			value: false,
			message: 'Postal Address is required'
		},
		postalSuburb: {
			value: false,
			message: 'Postal Suburb is required'
		},
		postalState: {
			value: false,
			message: 'Postal State is required'
		},
		postalPostcode: {
			value: false,
			message: 'Postal Postcode is required'
		},
		postalCountry: {
			value: false,
			message: 'Postal Country is required'
		},
		thoroughbredAddress: {
			value: false,
			message: 'Thoroughbred Address is required'
		},
		thoroughbredSuburb: {
			value: false,
			message: 'Thoroughbred Suburb is required'
		},
		thoroughbredState: {
			value: false,
			message: 'Thoroughbred State is required'
		},
		thoroughbredPostcode: {
			value: false,
			message: 'Thoroughbred Postcode is required'
		},
		thoroughbredCountry: {
			value: false,
			message: 'Thoroughbred Country is required'
		},
		horseNameBreeding: {
			value: false,
			message: 'Horse Name / Breeding is required'
		},
		sex: {
			value: false,
			message: 'Sex is required'
		},
		age: {
			value: false,
			message: 'Age is required'
		},
		colour: {
			value: false,
			message: 'Colour is required'
		},
		countryOfBirth: {
			value: false,
			message: 'Country of Birth is required'
		},
		foalYear: {
			value: false,
			message: 'Foal Year is required'
		},
		brandLeft: {
			value: false,
			message: 'Brand-Left is required'
		},
		brandRight: {
			value: false,
			message: 'Brand-Right is required'
		},
		markingsLeg: {
			value: false,
			message: 'Markings-Leg is required'
		},
		markingsHead: {
			value: false,
			message: 'Markings-Head is required'
		},
		hasHorseRaced: {
			value: false,
			message: 'Has this horse ever raced is required'
		},
		howDidHorseComeInYourCare: {
			value: false,
			message: 'How did the horse come to be in your care is required'
		},
		maxAge: {
			value: false,
			message: 'Exceed maximum value of 40'
		},
		unmatchAge: {
			value: false,
			message: 'Age does not match selected Foal Date'
		}
	});

	useEffect(() => {
		setIsLoading(true);
		const payload = {
			memberExternalId: currentUser?.UserId,
			...(id && { id: id })
		};

		let query = id
			? getThoroughbredUnregisteredFormById
			: getThoroughbredUnregisteredForm;

		AppSyncService.execute(query, payload).then((result) => {
			if (result.data !== null) {
				const { getMember } = result.data;

				let dataInfo =
					id && result?.data?.getThoroughbredUnregisteredFormById
						? result?.data?.getThoroughbredUnregisteredFormById
						: result?.data?.getThoroughbredUnregisteredForm;

				setHasData(dataInfo?.id !== null);
				setDataStateInfo(dataInfo);

				if (dataInfo?.id !== null) {
					setIsHorseLocatedAtTheSameResidentialAddress(
						dataInfo.isThisHorseLocatedTheSameAddress
					);
					setThoroughbredAddress(
						!dataInfo.horseLocationResidentialAddress
							? ''
							: dataInfo.horseLocationResidentialAddress
					);
					setThoroughbredSuburb(
						!dataInfo.horseLocationResidentialSuburb
							? ''
							: dataInfo.horseLocationResidentialSuburb
					);
					setThoroughbredPostcode(
						!dataInfo.horseLocationResidentialPostcode
							? ''
							: dataInfo.horseLocationResidentialPostcode
					);
					setThoroughbredState(
						!dataInfo.horseLocationResidentialState
							? ''
							: dataInfo.horseLocationResidentialState
					);
					setThoroughbredCountry(
						!dataInfo.horseLocationResidentialCountry
							? DEFAULT_COUNTRY
							: dataInfo.horseLocationResidentialCountry
					);
					setHorseNameBreeding(
						!dataInfo.horseNameBreeding ? '' : dataInfo.horseNameBreeding
					);
					setSelectedGender(!dataInfo.gender ? 0 : dataInfo.gender);
					setFoalDate(!dataInfo.foalDate ? null : dataInfo.foalDate);
					setAge(!dataInfo.age ? '' : dataInfo?.age?.toString());
					setFoalYear(!dataInfo.foalYear ? null : dataInfo.foalYear.toString());
					setSireName(!dataInfo.sireName ? '' : dataInfo.sireName);
					setDamName(!dataInfo.damName ? '' : dataInfo.damName);
					setColour(!dataInfo.colour ? null : dataInfo.colour);
					setCountryOfBirth(
						!dataInfo.countryOfBirth ? null : dataInfo.countryOfBirth
					);
					setCountryOfRegistration(
						!dataInfo.countryOfRegistration ? null : dataInfo.countryOfBirth
					);
					setRegistrationNo(
						!dataInfo.registrationNo ? '' : dataInfo.registrationNo
					);
					setLifeNo(!dataInfo.lifeNo ? '' : dataInfo.lifeNo);
					setMicrochip(
						!dataInfo.microchipNumber ? '' : dataInfo.microchipNumber
					);
					setOverseasName(!dataInfo.overseasName ? '' : dataInfo.overseasName);
					setBrandLeftImages(dataInfo.brandLeftImages);
					setBrandRightImages(dataInfo.brandRightImages);
					setMarkingsLegImages(dataInfo.markingsLegImages);
					setMarkingHeadImages(dataInfo.markingsHeadImages);
					setImportationDate(
						!dataInfo.importationDate ? null : dataInfo.importationDate
					);
					setHasHorseEverRaced(
						!dataInfo.hasThisHorseEverRaced ? 0 : dataInfo.hasThisHorseEverRaced
					);
					setHowDidHorseComeYourCase(
						!dataInfo.horseToBeInYourCase ? '' : dataInfo.horseToBeInYourCase
					);
					setAreThereAnyDetailsIdentity(
						!dataInfo.anyOtherDetailsHorseIdentity
							? ''
							: dataInfo.anyOtherDetailsHorseIdentity
					);
					setImportExportDocumentationImages(dataInfo.uploadImportExportImages);
				}
				setProfileInfo(getMember);
				setContactNumber(getMember.phone ?? '');
				if (getMember?.residentialAddress !== null) {
					setAddress(getMember?.residentialAddress?.address ?? '');
					setSuburb(getMember?.residentialAddress?.suburb ?? '');
					setPostcode(getMember?.residentialAddress?.postcode ?? '');
					setState(getMember?.residentialAddress?.state || '');
					setCountry(getMember?.residentialAddress?.country || DEFAULT_COUNTRY);
				}
				if (getMember?.postalAddress !== null) {
					setPostalAddress(getMember?.postalAddress?.address ?? '');
					setPostalSuburb(getMember?.postalAddress?.suburb ?? '');
					setPostalPostcode(getMember?.postalAddress?.postcode ?? '');
					setPostalState(getMember?.postalAddress?.state || '');
					setPostalCountry(
						getMember?.postalAddress?.country || DEFAULT_COUNTRY
					);
				}
				setIsDifferentPostalAddress(getMember?.postalAddress?.address);
			}
		});

		setIsLoading(false);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getThoroughbredReferenceData).then((result) => {
			if (result.data) {
				let modifiedCountriesArray = result.data.listCountries;
				modifiedCountriesArray.unshift('');
				setReferenceListCountries(modifiedCountriesArray.sort());

				let modifiedListHorseColours = result.data.listHorseColours;
				modifiedListHorseColours.unshift('');
				setReferenceListColours(modifiedListHorseColours.sort());

				let modifiedListHorseGenders = result.data.listHorseGenders;
				modifiedListHorseGenders.unshift('');

				setGenderOpts(modifiedListHorseGenders.sort());
			}
		});

		setIsLoading(false);
	}, []);

	const getHorseAge = (birthDate) => {
		const dateNow = new Date();
		const yearNow = dateNow.getFullYear();
		const monthNow = dateNow.getMonth();
		const dayNow = dateNow.getDate();

		const horseBirthDate = new Date(birthDate);
		const horseMonth = horseBirthDate.getMonth();
		const horseYear = horseBirthDate.getFullYear();
		const horseDay = horseBirthDate.getDate();

		let horseAge = yearNow - horseYear;
		if (
			horseMonth <= 7 &&
			!(horseMonth >= 7 && horseDay >= 2) &&
			monthNow == 7 &&
			dayNow == 1
		) {
			horseAge += 1;
		}
		if (horseMonth >= 7 && horseDay >= 2) {
			horseAge -= 1;
		}
		return horseAge;
	};

	const cancelRequest = () => {
		if (!hasData) {
			navigateToDashboard();
		} else {
			let listOfKeys = [];
			if (brandLeftImages.length > 0) {
				brandLeftImages.map((d) => {
					listOfKeys.push(d.key);
				});
			}
			if (brandRightImages.length > 0) {
				brandRightImages.map((d) => {
					listOfKeys.push(d.key);
				});
			}
			if (markingsLegImages.length > 0) {
				markingsLegImages.map((d) => {
					listOfKeys.push(d.key);
				});
			}
			if (markingHeadImages.length > 0) {
				markingHeadImages.map((d) => {
					listOfKeys.push(d.key);
				});
			}
			if (importExportDocumentationImages.length > 0) {
				importExportDocumentationImages.map((d) => {
					listOfKeys.push(d.key);
				});
			}
			if (listOfKeys.length > 0) {
				AppSyncService.execute(deleteImagesFromS3, {
					imageKeys: listOfKeys
				}).then((data) => {
					if (data?.data?.deleteUserImages?.success) {
						removeThoroughtbredUnregisteredRegistration();
					}
				});
			} else {
				removeThoroughtbredUnregisteredRegistration();
			}
		}
	};

	const removeThoroughtbredUnregisteredRegistration = () => {
		AppSyncService.execute(deleteThoroughbredUnregisteredFormMutation, {
			id: id
		}).then((data) => {
			if (data.data != null) {
				addToast({ Message: 'Request cancelled', IsSuccess: true });
				navigateToDashboard();
			}
		});
	};

	const updateMicrochipNumber = (value) => {
		if (value.match(/^\d*$/) && value.toString().length <= 18) {
			setMicrochip(value);
		}
	};

	const updateAge = (value) => {
		if (value.match(/^\d*$/)) {
			setAge(value);
		}
	};

	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const photosUpload = (image, photoArraySetter, photoType) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'File uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey, type: photoType }
						]);
					});
			}
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					const hasImageId = photoArray.some(
						(selectedImg) => selectedImg.thoroughbredUnregisteredId
					);
					if (hasImageId) {
						let selectedImage = photoArray.find(
							(selectedImg) => img.key === selectedImg.key
						);
						if (selectedImage !== null) {
							AppSyncService.execute(
								deleteThoroughbredUnregisteredImagesMutation,
								{
									id: selectedImage.thoroughbredUnregisteredId,
									key: img.key
								}
							).then((data) => {
								if (data.data != null) {
									addToast({
										Message: 'File Removal Success',
										IsSuccess: true
									});
								}
							});
						}
					}

					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'File Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const navigateToDashboard = () => {
		navigate(DASHBOARD_ROUTE);
	};

	const renderDifferentPostalAddress = () => {
		return (
			<Row>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Address*</div>
						<AddressAutoSuggestion
							value={postalAddress}
							onChange={(value) => {
								setPostalAddress(value);
								setFieldErrors({
									...fieldErrors,
									postalAddress: { ...fieldErrors.postalAddress, value: false }
								});
							}}
							onAddressSelect={populatePostalAddressFields}
							disableAutoSuggestion={isManuallyAddMyResidentialPostalAddress}
							canEdit={!profileInfo.postalAddress.address}
						/>
						<span className={classes.errorText}>
							{fieldErrors.postalAddress.value
								? fieldErrors.postalAddress.message
								: null}
						</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Suburb*</div>
						{!profileInfo?.postalAddress?.suburb && (
							<FormTextField
								value={postalSuburb}
								onChange={(e) => {
									setPostalSuburb(e.target.value);
									setFieldErrors({
										...fieldErrors,
										postalSuburb: { ...fieldErrors.postalSuburb, value: false }
									});
								}}
								isDisabled={!isManuallyAddMyResidentialPostalAddress}
							/>
						)}
						{profileInfo?.postalAddress?.suburb && (
							<span className={classes.profileInfo}>{postalSuburb}</span>
						)}
						<span className={classes.errorText}>
							{fieldErrors.postalSuburb.value
								? fieldErrors.postalSuburb.message
								: null}
						</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Postcode*</div>
						{!profileInfo?.postalAddress?.postcode && (
							<FormTextField
								value={postalPostcode}
								onChange={(e) => {
									setPostalPostcode(e.target.value);
									setFieldErrors({
										...fieldErrors,
										postalPostcode: {
											...fieldErrors.postalPostcode,
											value: false
										}
									});
								}}
								isDisabled={!isManuallyAddMyResidentialPostalAddress}
							/>
						)}
						{profileInfo?.postalAddress?.postcode && (
							<span className={classes.profileInfo}>{postalPostcode}</span>
						)}
						<span className={classes.errorText}>
							{fieldErrors.postalPostcode.value
								? fieldErrors.postalPostcode.message
								: null}
						</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal State*</div>
						{!profileInfo?.postalAddress?.state ? (
							<StateSelector
								country={DEFAULT_COUNTRY}
								value={postalState}
								changeHandler={(newStateValue) => {
									setPostalState(newStateValue);
									setFieldErrors({
										...fieldErrors,
										postalState: { ...fieldErrors.postalState, value: false }
									});
								}}
								isDisabled={!isManuallyAddMyResidentialPostalAddress}
							/>
						) : null}

						{profileInfo?.postalAddress?.state && (
							<FormTextField
								name="state"
								// id={AcknowledgedAppFormKeys.RESIDENTIAL_STATE}
								value={postalState}
								canEdit={false}
							/>
						)}
						<span className={classes.errorText}>
							{fieldErrors.postalState.value
								? fieldErrors.postalState.message
								: null}
						</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Country*</div>
						{!profileInfo?.postalAddress?.country ? (
							<CountrySelector
								value={postalCountry}
								changeHandler={(newStateValue) => {
									setPostalCountry(newStateValue);
									setFieldErrors({
										...fieldErrors,
										postalCountry: {
											...fieldErrors.postalCountry,
											value: false
										}
									});
								}}
								isDisabled={!isManuallyAddMyResidentialPostalAddress}
							/>
						) : null}
						{profileInfo?.postalAddress?.country && (
							<FormTextField
								name="state"
								value={postalCountry}
								canEdit={false}
							/>
						)}

						<span className={classes.errorText}>
							{fieldErrors.postalCountry.value
								? fieldErrors.postalCountry.message
								: null}
						</span>
					</div>
				</Col>
			</Row>
		);
	};

	const populateThoroughbredAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setThoroughbredAddress(address);
		setThoroughbredSuburb(addressDetails.Suburb);
		setThoroughbredPostcode(addressDetails.Postcode);
		setThoroughbredState(addressDetails.State);
		setThoroughbredCountry(addressDetails.Country);
		setFieldErrors({
			...fieldErrors,
			thoroughbredAddress: { ...fieldErrors.thoroughbredAddress, value: false },
			thoroughbredSuburb: { ...fieldErrors.thoroughbredSuburb, value: false },
			thoroughbredPostcode: {
				...fieldErrors.thoroughbredPostcode,
				value: false
			},
			thoroughbredState: { ...fieldErrors.thoroughbredState, value: false },
			thoroughbredCountry: { ...fieldErrors.thoroughbredCountry, value: false }
		});
	};

	const populateResidentialAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setAddress(address);
		setSuburb(addressDetails.Suburb);
		setPostcode(addressDetails.Postcode);
		setState(addressDetails.State);
		setCountry(addressDetails.Country);
		setFieldErrors({
			...fieldErrors,
			residentialAddress: { ...fieldErrors.residentialAddress, value: false },
			residentialSuburb: { ...fieldErrors.residentialSuburb, value: false },
			residentialPostcode: { ...fieldErrors.residentialPostcode, value: false },
			residentialState: { ...fieldErrors.residentialState, value: false },
			residentialCountry: { ...fieldErrors.residentialCountry, value: false }
		});
	};

	const populatePostalAddressFields = (addressDetails) => {
		const address = `${addressDetails.Line1}${
			addressDetails.Line2 ? `, ${addressDetails.Line2}` : ''
		}`;
		setPostalAddress(address);
		setPostalSuburb(addressDetails.Suburb);
		setPostalPostcode(addressDetails.Postcode);
		setPostalState(addressDetails.State);
		setPostalCountry(addressDetails.Country);
		setFieldErrors({
			...fieldErrors,
			postalAddress: { ...fieldErrors.postalAddress, value: false },
			postalSuburb: { ...fieldErrors.postalSuburb, value: false },
			postalPostcode: { ...fieldErrors.postalPostcode, value: false },
			postalState: { ...fieldErrors.postalState, value: false },
			postalCountry: { ...fieldErrors.postalCountry, value: false }
		});
	};

	const updateDateYear = (value, onStateChange) => {
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			onStateChange(formattedDate);
		} else {
			onStateChange(null);
		}
	};

	const updatePostCode = (postCode) => {
		const regex = RegExp(/^\d*$/);
		if (regex.test(postCode) && postCode.toString().length <= 4) {
			setPostcode(postCode);
		}
	};

	const updateAddress = () => {
		let saveAddress = {
			memberExternalId: currentUser?.UserId,
			address: address,
			country: country,
			postcode: postcode,
			state: state,
			suburb: suburb
		};

		AppSyncService.execute(saveResidentialAddress, {
			saveAddress
		});
	};

	const updatePostalAddress = () => {
		let saveAddress = {
			memberExternalId: '',
			address: postalAddress,
			country: postalCountry,
			postcode: postalPostcode,
			state: postalState,
			suburb: postalSuburb
		};

		AppSyncService.execute(savePostalAddress, {
			saveAddress
		});
	};

	const updateProfile = () => {
		let updateMemberProfileData = {
			phone: contactNumber
		};

		AppSyncService.execute(updateMemberProfile, {
			updateMemberProfileData
		});
	};

	const evaluateFieldErrors = () => {
		let errorItems = { ...fieldErrors };

		let contactNumberField = {
			...errorItems['contactNumber'],
			value: contactNumber.length === 0
		};
		errorItems['contactNumber'] = contactNumberField;

		let residentialAddressField = {
			...errorItems['residentialAddress'],
			value: address.length === 0
		};
		errorItems['residentialAddress'] = residentialAddressField;

		let residentialSuburbField = {
			...errorItems['residentialSuburb'],
			value: suburb.length === 0
		};
		errorItems['residentialSuburb'] = residentialSuburbField;

		let residentialStateField = {
			...errorItems['residentialState'],
			value: state.length === 0
		};
		errorItems['residentialState'] = residentialStateField;

		let residentialPostcodeField = {
			...errorItems['residentialPostcode'],
			value: postcode.length === 0
		};
		errorItems['residentialPostcode'] = residentialPostcodeField;

		let residentialCountryField = {
			...errorItems['residentialCountry'],
			value: country.length === 0
		};
		errorItems['residentialCountry'] = residentialCountryField;

		let postalAddressField = {
			...errorItems['postalAddress'],
			value: postalAddress.length === 0
		};
		errorItems['postalAddress'] = postalAddressField;

		let postalSuburbField = {
			...errorItems['postalSuburb'],
			value: postalSuburb.length === 0
		};
		errorItems['postalSuburb'] = postalSuburbField;

		let postalStateField = {
			...errorItems['postalState'],
			value: postalState.length === 0
		};
		errorItems['postalState'] = postalStateField;

		let postalPostcodeField = {
			...errorItems['postalPostcode'],
			value: postalPostcode.length === 0
		};
		errorItems['postalPostcode'] = postalPostcodeField;

		let postalCountryField = {
			...errorItems['postalCountry'],
			value: postalCountry.length === 0
		};
		errorItems['postalCountry'] = postalCountryField;

		let thoroughbredAddressField = {
			...errorItems['thoroughbredAddress'],
			value:
				!isHorseLocatedAtTheSameResidentialAddress &&
				thoroughbredAddress.length === 0
		};
		errorItems['thoroughbredAddress'] = thoroughbredAddressField;

		let thoroughbredSuburbField = {
			...errorItems['thoroughbredSuburb'],
			value:
				!isHorseLocatedAtTheSameResidentialAddress &&
				thoroughbredSuburb.length === 0
		};
		errorItems['thoroughbredSuburb'] = thoroughbredSuburbField;

		let thoroughbredStateField = {
			...errorItems['thoroughbredState'],
			value:
				!isHorseLocatedAtTheSameResidentialAddress &&
				thoroughbredState.length === 0
		};
		errorItems['thoroughbredState'] = thoroughbredStateField;

		let thoroughbredPostcodeField = {
			...errorItems['thoroughbredPostcode'],
			value:
				!isHorseLocatedAtTheSameResidentialAddress &&
				thoroughbredPostcode.length === 0
		};
		errorItems['thoroughbredPostcode'] = thoroughbredPostcodeField;

		let thoroughbredCountryField = {
			...errorItems['thoroughbredCountry'],
			value:
				!isHorseLocatedAtTheSameResidentialAddress &&
				thoroughbredCountry.length === 0
		};
		errorItems['thoroughbredCountry'] = thoroughbredCountryField;

		let sex = {
			...errorItems['sex'],
			value: selectedGender === null || selectedGender?.length === 0
		};
		errorItems['sex'] = sex;

		let ageField = {
			...errorItems['age'],
			value: age.length === 0
		};
		errorItems['age'] = ageField;

		let colourField = {
			...errorItems['colour'],
			value: colour === null || colour?.length === 0
		};
		errorItems['colour'] = colourField;

		let countryOfBirthField = {
			...errorItems['countryOfBirth'],
			value: countryOfBirth === null || countryOfBirth?.length === 0
		};
		errorItems['countryOfBirth'] = countryOfBirthField;

		let foalYearField = {
			...errorItems['foalYear'],
			value: foalYear === null
		};
		errorItems['foalYear'] = foalYearField;

		let brandLeft = {
			...errorItems['brandLeft'],
			value: brandLeftImages.length === 0
		};
		errorItems['brandLeft'] = brandLeft;

		let brandRight = {
			...errorItems['brandRight'],
			value: brandRightImages.length === 0
		};
		errorItems['brandRight'] = brandRight;

		let markingsLeg = {
			...errorItems['markingsLeg'],
			value: markingsLegImages.length === 0
		};
		errorItems['markingsLeg'] = markingsLeg;

		let markingsHead = {
			...errorItems['markingsHead'],
			value: markingHeadImages.length === 0
		};
		errorItems['markingsHead'] = markingsHead;

		let hasHorseRaced = {
			...errorItems['hasHorseRaced'],
			value: hasHorseEverRaced === 0
		};
		errorItems['hasHorseRaced'] = hasHorseRaced;

		let howDidHorseComeInYourCare = {
			...errorItems['howDidHorseComeInYourCare'],
			value: howDidHorseComeYourCase.length === 0
		};
		errorItems['howDidHorseComeInYourCare'] = howDidHorseComeInYourCare;

		let horseNameBreedingField = {
			...errorItems['horseNameBreeding'],
			value: horseNameBreeding.length === 0
		};
		errorItems['horseNameBreeding'] = horseNameBreedingField;

		setFieldErrors(errorItems);
		for (let key in errorItems) {
			if (errorItems[key].value) {
				return errorItems[key].value;
			}
		}
	};

	const filterImages = (arrayImages) => {
		return arrayImages
			.filter((item) => !item.thoroughbredUnregisteredId)
			.map((item) => ({
				key: item.key,
				filename: item.filename,
				type: item.type,
				...(item?.created && { created: item.created })
			}));
	};

	const onSaveThoroughbredUnregisteredForm = (formStatus) => {
		try {
			const brandLeftImagesFiltered = filterImages(brandLeftImages);
			const brandRightImagesFiltered = filterImages(brandRightImages);
			const markingsLegImagesFiltered = filterImages(markingsLegImages);
			const markingHeadImagesFiltered = filterImages(markingHeadImages);
			const importExportDocumentationImagesFiltered = filterImages(
				importExportDocumentationImages
			);

			let photoArrays = [
				...brandLeftImagesFiltered,
				...brandRightImagesFiltered,
				...markingsLegImagesFiltered,
				...markingHeadImagesFiltered,
				...importExportDocumentationImagesFiltered
			];

			let formId = id;

			if (!formId) {
				formId = dataStateInfo.id;
			}

			const input = {
				...(formId && { id: formId }),
				isThisHorseLocatedTheSameAddress: isHorseLocatedAtTheSameResidentialAddress,
				...(!isHorseLocatedAtTheSameResidentialAddress && {
					horseLocationResidentialAddress: thoroughbredAddress,
					horseLocationResidentialSuburb: thoroughbredSuburb,
					horseLocationResidentialPostcode: parseInt(thoroughbredPostcode),
					horseLocationResidentialState: thoroughbredState,
					horseLocationResidentialCountry: thoroughbredCountry
				}),
				horseNameBreeding: horseNameBreeding,
				gender: selectedGender,
				foalDate: foalDate === '' ? null : foalDate,
				foalYear: !foalYear ? null : parseInt(foalYear.substr(0, 4)),
				age: parseInt(age),
				sireName: sireName,
				damName: damName,
				colour: colour,
				countryOfBirth: countryOfBirth,
				countryOfRegistration: countryOfRegistration,
				registrationNo: registrationNo,
				lifeNo: lifeNo,
				microchipNumber: microchip === '' ? null : microchip,
				overseasName: overseasName,
				importationDate: importationDate === '' ? null : importationDate,
				hasThisHorseEverRaced: hasHorseEverRaced,
				horseToBeInYourCase: howDidHorseComeYourCase,
				anyOtherDetailsHorseIdentity: areThereAnyDetailsIdentity,
				status: formStatus,
				files: photoArrays
			};

			AppSyncService.execute(saveThoroughbredUnregisteredForm, {
				input
			}).then((data) => {
				if (data.data != null) {
					if (!profileInfo?.residentialAddress?.address && address.length > 0) {
						updateAddress();
					}
					if (
						!profileInfo?.postalAddress?.address &&
						postalAddress.length > 0
					) {
						updatePostalAddress();
					}
					if (!profileInfo?.phone && contactNumber.length > 0) {
						updateProfile();
					}
					addToast({
						Message:
							formStatus === REVIEW
								? 'Thoroughbred Form Submitted'
								: 'Draft Thoroughbred Form Saved',
						IsSuccess: true
					});
					navigateToDashboard();
				}
			});
		} catch (error) {
			if (error) {
				console.log(error);
			} else {
				console.log(error);
			}
		}
	};

	const onSaveDraft = () => {
		onSaveThoroughbredUnregisteredForm(DRAFT);
	};

	const onSubmitForm = () => {
		setDisableSubmitBtn(false);
		const hasError = evaluateFieldErrors();
		if (hasError) {
			setDisableSubmitBtn(true);
		} else {
			onSaveThoroughbredUnregisteredForm(REVIEW);
		}
	};

	useEffect(() => {
		if (
			isDifferentPostalAddress &&
			postalAddress.length > 0 &&
			postalSuburb.length > 0 &&
			postalState.length > 0 &&
			postalCountry.length > 0 &&
			postalPostcode.length > 0
		) {
			setDisableSubmitBtn(false);
		} else if (!isDifferentPostalAddress) {
			setDisableSubmitBtn(false);
		} else {
			setDisableSubmitBtn(true);
		}
		evalAge(age, foalDate);
	}, [
		postalAddress,
		postalSuburb,
		postalPostcode,
		postalState,
		postalCountry,
		isDifferentPostalAddress,
		age,
		foalDate
	]);

	useEffect(() => {
		if (
			!isHorseLocatedAtTheSameResidentialAddress &&
			thoroughbredAddress.length > 0 &&
			thoroughbredSuburb.length > 0 &&
			thoroughbredPostcode.length > 0 &&
			thoroughbredState.length > 0 &&
			thoroughbredCountry.length > 0
		) {
			setDisableSubmitBtn(false);
		} else if (isHorseLocatedAtTheSameResidentialAddress) {
			setDisableSubmitBtn(false);
		} else {
			setDisableSubmitBtn(true);
		}
		evalAge(age, foalDate);
	}, [
		thoroughbredAddress,
		thoroughbredSuburb,
		thoroughbredPostcode,
		thoroughbredState,
		thoroughbredCountry,
		isHorseLocatedAtTheSameResidentialAddress,
		age,
		foalDate
	]);

	useEffect(() => {
		if (
			address.length > 0 &&
			suburb.length > 0 &&
			postcode.length > 0 &&
			(state === true || state.length > 0) &&
			country.length > 0 &&
			horseNameBreeding.length > 0 &&
			selectedGender != 0 &&
			foalYear &&
			colour &&
			countryOfBirth !== null &&
			brandLeftImages.length > 0 &&
			brandRightImages.length > 0 &&
			markingHeadImages.length > 0 &&
			markingsLegImages.length > 0 &&
			hasHorseEverRaced != 0 &&
			howDidHorseComeYourCase.length > 0
		) {
			setDisableSubmitBtn(false);
		} else {
			setDisableSubmitBtn(true);
		}
		evalAge(age, foalDate);
	}, [
		address,
		suburb,
		postcode,
		state,
		country,
		horseNameBreeding,
		selectedGender,
		foalYear,
		colour,
		countryOfBirth,
		brandLeftImages,
		brandRightImages,
		markingHeadImages,
		markingsLegImages,
		hasHorseEverRaced,
		howDidHorseComeYourCase,
		age,
		foalDate
	]);

	useEffect(() => {
		evalAge(age, foalDate);
	}, [age, foalDate]);

	const evalAge = (age, foalDate) => {
		let errorItems = { ...fieldErrors };
		if (age.length > 0) {
			const ageVal = +age;
			let maxAge = {
				...errorItems['maxAge'],
				value: ageVal < 0 || ageVal >= 40
			};
			errorItems['maxAge'] = maxAge;
			setFieldErrors(errorItems);
			if (maxAge['value']) {
				setDisableSubmitBtn(true);
			} else {
				setDisableSubmitBtn(false);
			}
		}
		if (foalDate !== null && age.length > 0) {
			const currentAge = getHorseAge(foalDate);
			let unmatchAge = {
				...errorItems['unmatchAge'],
				value: currentAge != age
			};
			errorItems['unmatchAge'] = unmatchAge;
			setFieldErrors(errorItems);
			if (unmatchAge['value']) {
				setDisableSubmitBtn(true);
			} else {
				setDisableSubmitBtn(false);
			}
		}
	};

	return (
		<Container className={classes.pageContainer}>
			<SEO title="Thoroughbred Unregistered Form" />
			<HeaderBanner
				title="Application to identify Studbook Registration"
				top
				type="primary"
				styles={{ marginBottom: '0px', borderRadius: '5px 5px 0px 0px' }}
			/>
			<MessageBar>
				Please note this process can take time as our team will need to work
				through multiple sources to identify your thoroughbred registration
				details. Include as much information as you can and our team will be in
				touch with you.
			</MessageBar>
			<HeaderBanner
				title="Contact Details"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '30px' }}
			/>
			<div className={classes.formContainer}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>First Name</div>
								<FormTextField value={profileInfo.firstName} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Last Name</div>
								<FormTextField value={profileInfo.lastName} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Email</div>
								<FormTextField value={profileInfo.email} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Contact Number*</div>
								{!profileInfo?.phone && (
									<FormTextField
										value={contactNumber}
										onChange={(e) => {
											setContactNumber(e.target.value);
											setFieldErrors({
												...fieldErrors,
												contactNumber: {
													...fieldErrors.contactNumber,
													value: false
												}
											});
										}}
									/>
								)}
								{profileInfo?.phone && (
									<span className={classes.profileInfo}>{contactNumber}</span>
								)}
								<span className={classes.errorText}>
									{fieldErrors.contactNumber.value
										? fieldErrors.contactNumber.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Address*</div>
								<AddressAutoSuggestion
									value={address}
									onChange={(value) => {
										setAddress(value);
										setFieldErrors({
											...fieldErrors,
											residentialAddress: {
												...fieldErrors.residentialAddress,
												value: false
											}
										});
									}}
									onAddressSelect={populateResidentialAddressFields}
									disableAutoSuggestion={
										isManuallyAddMyResidentialPostalAddress
									}
									canEdit={!profileInfo?.residentialAddress?.address}
								/>
								<span className={classes.errorText}>
									{fieldErrors.residentialAddress.value
										? fieldErrors.residentialAddress.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Suburb*</div>
								{!profileInfo?.residentialAddress?.suburb && (
									<FormTextField
										value={suburb}
										onChange={(e) => {
											setSuburb(e.target.value);
											setFieldErrors({
												...fieldErrors,
												residentialSuburb: {
													...fieldErrors.residentialSuburb,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyResidentialPostalAddress}
									/>
								)}
								{profileInfo?.residentialAddress?.suburb && (
									<span className={classes.profileInfo}>{suburb}</span>
								)}
								<span className={classes.errorText}>
									{fieldErrors.residentialSuburb.value
										? fieldErrors.residentialSuburb.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Postcode*</div>
								{(!profileInfo?.residentialAddress?.postcode ||
									profileInfo?.residentialAddress?.postcode === 0) && (
									<FormTextField
										value={postcode}
										onChange={(e) => {
											updatePostCode(e.target.value);
											setFieldErrors({
												...fieldErrors,
												residentialPostcode: {
													...fieldErrors.residentialPostcode,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyResidentialPostalAddress}
									/>
								)}
								{profileInfo?.residentialAddress?.postcode &&
									profileInfo?.residentialAddress?.postcode > 0 && (
										<span className={classes.profileInfo}>{postcode}</span>
									)}
								<span className={classes.errorText}>
									{fieldErrors.residentialPostcode.value
										? fieldErrors.residentialPostcode.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential State*</div>
								{!profileInfo?.residentialAddress?.state ? (
									<StateSelector
										country={DEFAULT_COUNTRY}
										value={state}
										changeHandler={(newStateValue) => {
											setState(newStateValue);
											setFieldErrors({
												...fieldErrors,
												residentialState: {
													...fieldErrors.residentialState,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyResidentialPostalAddress}
									/>
								) : null}

								{profileInfo?.residentialAddress?.state && (
									<FormTextField name="state" value={state} canEdit={false} />
								)}
								<span className={classes.errorText}>
									{fieldErrors.residentialState.value
										? fieldErrors.residentialState.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Country*</div>
								{!profileInfo?.residentialAddress?.country ? (
									<CountrySelector
										value={country}
										changeHandler={(newCountryValue) => {
											setCountry(newCountryValue);
											setFieldErrors({
												...fieldErrors,
												residentialCountry: {
													...fieldErrors.residentialCountry,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyResidentialPostalAddress}
									/>
								) : null}

								{profileInfo?.residentialAddress?.country && (
									<FormTextField
										name="state"
										value={profileInfo?.residentialAddress?.country}
										canEdit={false}
									/>
								)}
								<span className={classes.errorText}>
									{fieldErrors.residentialCountry.value
										? fieldErrors.residentialCountry.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					{!(
						profileInfo?.residentialAddress?.address &&
						profileInfo?.postalAddress?.address
					) ? (
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.checkboxFieldContainer}>
									<BasicCheckbox
										value={isManuallyAddMyResidentialPostalAddress}
										handleChange={() =>
											setIsManuallyAddMyResidentialPostalAddress(
												!isManuallyAddMyResidentialPostalAddress
											)
										}
										label={'Manually add my address'}
									/>
								</div>
							</Col>
						</Row>
					) : null}

					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.checkboxFieldContainer}>
								<BasicCheckbox
									value={isDifferentPostalAddress}
									handleChange={() =>
										setIsDifferentPostalAddress(!isDifferentPostalAddress)
									}
									label={'I have a different postal address'}
									disabled={
										profileInfo?.postalAddress?.address &&
										profileInfo?.postalAddress?.suburb &&
										profileInfo?.postalAddress?.postcode &&
										profileInfo?.postalAddress?.state &&
										profileInfo?.postalAddress?.country
									}
								/>
							</div>
						</Col>
					</Row>
					{isDifferentPostalAddress ? renderDifferentPostalAddress() : null}
				</div>
				<HeaderBanner
					title="Horse Location"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is this horse located at the same residential address?
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setIsHorseLocatedAtTheSameResidentialAddress(value);
										setIsManuallyAddMyThoroughbredAddress(false);
										if (value) {
											setFieldErrors({
												...fieldErrors,
												thoroughbredAddress: {
													...fieldErrors.thoroughbredAddress,
													value: false
												},
												thoroughbredSuburb: {
													...fieldErrors.thoroughbredSuburb,
													value: false
												},
												thoroughbredPostcode: {
													...fieldErrors.thoroughbredPostcode,
													value: false
												},
												thoroughbredState: {
													...fieldErrors.thoroughbredState,
													value: false
												},
												thoroughbredCountry: {
													...fieldErrors.thoroughbredCountry,
													value: false
												}
											});
										}
									}}
									value={isHorseLocatedAtTheSameResidentialAddress}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Address*</div>
								<AddressAutoSuggestion
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? address
											: thoroughbredAddress
									}
									onChange={(val) => {
										setThoroughbredAddress(val);
										setFieldErrors({
											...fieldErrors,
											thoroughbredAddress: {
												...fieldErrors.thoroughbredAddress,
												value: false
											}
										});
									}}
									disableAutoSuggestion={isManuallyAddMyThoroughbredAddress}
									onAddressSelect={populateThoroughbredAddressFields}
									canEdit={!isHorseLocatedAtTheSameResidentialAddress}
								/>
								<span className={classes.errorText}>
									{fieldErrors.thoroughbredAddress.value
										? fieldErrors.thoroughbredAddress.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Suburb*</div>
								<FormTextField
									name="resSuburb"
									onChange={(e) => {
										setThoroughbredSuburb(e.target.value);
										setFieldErrors({
											...fieldErrors,
											thoroughbredSuburb: {
												...fieldErrors.thoroughbredSuburb,
												value: false
											}
										});
									}}
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? suburb
											: thoroughbredSuburb
									}
									canEdit={!isHorseLocatedAtTheSameResidentialAddress}
									isDisabled={!isManuallyAddMyThoroughbredAddress}
								/>
								<span className={classes.errorText}>
									{fieldErrors.thoroughbredSuburb.value
										? fieldErrors.thoroughbredSuburb.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Postcode*</div>
								<FormTextField
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? postcode
											: thoroughbredPostcode
									}
									canEdit={!isHorseLocatedAtTheSameResidentialAddress}
									isDisabled={!isManuallyAddMyThoroughbredAddress}
									onChange={(e) => {
										setThoroughbredPostcode(e.target.value);
										setFieldErrors({
											...fieldErrors,
											thoroughbredPostcode: {
												...fieldErrors.thoroughbredPostcode,
												value: false
											}
										});
									}}
								/>
								<span className={classes.errorText}>
									{fieldErrors.thoroughbredPostcode.value
										? fieldErrors.thoroughbredPostcode.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred State*</div>
								{!isHorseLocatedAtTheSameResidentialAddress ? (
									<StateSelector
										country={DEFAULT_COUNTRY}
										value={thoroughbredState}
										changeHandler={(newStateValue) => {
											setThoroughbredState(newStateValue);
											setFieldErrors({
												...fieldErrors,
												thoroughbredState: {
													...fieldErrors.thoroughbredState,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyThoroughbredAddress}
									/>
								) : null}

								{isHorseLocatedAtTheSameResidentialAddress && (
									<FormTextField name="state" value={state} canEdit={false} />
								)}
								<span className={classes.errorText}>
									{fieldErrors.thoroughbredState.value
										? fieldErrors.thoroughbredState.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Country*</div>
								{!isHorseLocatedAtTheSameResidentialAddress ? (
									<CountrySelector
										value={thoroughbredCountry}
										changeHandler={(newStateValue) => {
											setThoroughbredCountry(newStateValue);
											setFieldErrors({
												...fieldErrors,
												thoroughbredCountry: {
													...fieldErrors.thoroughbredCountry,
													value: false
												}
											});
										}}
										isDisabled={!isManuallyAddMyThoroughbredAddress}
									/>
								) : null}

								{isHorseLocatedAtTheSameResidentialAddress && (
									<FormTextField name="state" value={country} canEdit={false} />
								)}
								<span className={classes.errorText}>
									{fieldErrors.thoroughbredCountry.value
										? fieldErrors.thoroughbredCountry.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					{!isHorseLocatedAtTheSameResidentialAddress ? (
						<Row>
							<Col xs={12} lg={4}>
								<div className={classes.checkboxFieldContainer}>
									<BasicCheckbox
										value={isManuallyAddMyThoroughbredAddress}
										handleChange={() =>
											setIsManuallyAddMyThoroughbredAddress(
												!isManuallyAddMyThoroughbredAddress
											)
										}
										label={`Manually add horse's address`}
									/>
								</div>
							</Col>
						</Row>
					) : null}
				</div>

				<HeaderBanner
					title="Horse Details"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Horse Name / Breeding*</div>
								<FormTextField
									value={horseNameBreeding}
									onChange={(e) => {
										setHorseNameBreeding(e.target.value);
										setFieldErrors({
											...fieldErrors,
											horseNameBreeding: {
												...fieldErrors.horseNameBreeding,
												value: false
											}
										});
									}}
									canEdit={true}
								/>
								<span className={classes.errorText}>
									{fieldErrors.horseNameBreeding.value
										? fieldErrors.horseNameBreeding.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sex*</div>
								<FormDropDown
									name="sex"
									selectedId={selectedGender}
									items={genderOpts.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setSelectedGender(e.target.value);
										setFieldErrors({
											...fieldErrors,
											sex: { ...fieldErrors.sex, value: false }
										});
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{fieldErrors.sex.value ? fieldErrors.sex.message : null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Foal Date (DOB)</div>
								<FormDatePicker
									DateValue={foalDate}
									updateSelection={(value) =>
										updateDateYear(value, setFoalDate)
									}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Age*</div>
								<FormTextField
									value={age}
									onChange={(e) => {
										updateAge(e.target.value);
										setFieldErrors({
											...fieldErrors,
											age: { ...fieldErrors.age, value: false }
										});
									}}
									canEdit={true}
								/>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span className={classes.errorText}>
										{fieldErrors.age.value ? fieldErrors.age.message : null}
									</span>
									<span className={classes.errorText}>
										{fieldErrors.maxAge.value
											? fieldErrors.maxAge.message
											: null}
									</span>
									{foalDate !== null ? (
										<span className={classes.errorText}>
											{fieldErrors.unmatchAge.value
												? fieldErrors.unmatchAge.message
												: null}
										</span>
									) : null}
								</div>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Foal Year*</div>
								<FormDatePicker
									DateValue={foalYear}
									format="yyyy"
									updateSelection={(value) => {
										updateDateYear(value, setFoalYear);
										setFieldErrors({
											...fieldErrors,
											foalYear: { ...fieldErrors.foalYear, value: false }
										});
									}}
									minDate={getMinDate(41)}
								/>
								<span className={classes.errorText}>
									{fieldErrors.foalYear.value
										? fieldErrors.foalYear.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sire Name</div>
								<FormTextField
									value={sireName}
									onChange={(e) => setSireName(e.target.value)}
									canEdit={true}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Dam Name</div>
								<FormTextField
									value={damName}
									onChange={(e) => setDamName(e.target.value)}
									canEdit={true}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Colour*</div>
								<FormDropDown
									name="colour"
									selectedId={colour}
									items={referenceListColours.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setColour(e.target.value);
										setFieldErrors({
											...fieldErrors,
											colour: { ...fieldErrors.colour, value: false }
										});
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{fieldErrors.colour.value ? fieldErrors.colour.message : null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Country of Birth*</div>
								<FormDropDown
									name="country"
									selectedId={countryOfBirth}
									items={referenceListCountries.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setCountryOfBirth(e.target.value);
										setFieldErrors({
											...fieldErrors,
											countryOfBirth: {
												...fieldErrors.countryOfBirth,
												value: false
											}
										});
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{fieldErrors.countryOfBirth.value
										? fieldErrors.countryOfBirth.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Country of Registration
								</div>
								<FormDropDown
									name="country"
									selectedId={countryOfRegistration}
									items={referenceListCountries.map((item) => {
										return {
											id: item,
											value: item
										};
									})}
									onChange={(e) => {
										setCountryOfRegistration(e.target.value);
									}}
									showBlank
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Registration No.</div>
								<FormTextField
									value={registrationNo}
									onChange={(e) => setRegistrationNo(e.target.value)}
									canEdit={true}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Life No.</div>
								<FormTextField
									value={lifeNo}
									onChange={(e) => setLifeNo(e.target.value)}
									canEdit={true}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<HeaderBanner
					title="Horse Identification"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Microchip Number</div>
								<Controller
									control={control}
									name="microchip"
									render={({ onChange }) => (
										<FormTextField
											value={microchip}
											onChange={(e) => {
												onChange(e.target.value);
												updateMicrochipNumber(e.target.value);
											}}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.microchip?.message}
								</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Overseas Name</div>
								<FormTextField
									value={overseasName}
									onChange={(e) => setOverseasName(e.target.value)}
									canEdit={true}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand - Left (jpg,png, pdf)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg', 'pdf']}
									labelName="Upload"
									error={uploadError}
									data={brandLeftImages}
									sizeLimitMB={5}
									numberOfImages={5}
									success={(e) => {
										photosUpload(e, setBrandLeftImages, 'brandLeft');
										setFieldErrors({
											...fieldErrors,
											brandLeft: { ...fieldErrors.brandLeft, value: false }
										});
									}}
									getImage={filePreviewHandler}
									removeImage={(e) =>
										removePhoto(e, brandLeftImages, setBrandLeftImages)
									}
								/>
								<span className={classes.errorText}>
									{fieldErrors.brandLeft.value
										? fieldErrors.brandLeft.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand - Right (jpg,png, pdf)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg', 'pdf']}
									labelName="Upload"
									error={uploadError}
									data={brandRightImages}
									sizeLimitMB={5}
									numberOfImages={5}
									success={(e) => {
										photosUpload(e, setBrandRightImages, 'brandRight');
										setFieldErrors({
											...fieldErrors,
											brandRight: { ...fieldErrors.brandRight, value: false }
										});
									}}
									getImage={filePreviewHandler}
									removeImage={(e) =>
										removePhoto(e, brandRightImages, setBrandRightImages)
									}
								/>
								<span className={classes.errorText}>
									{fieldErrors.brandRight.value
										? fieldErrors.brandRight.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Markings - Leg (jpg, png, pdf)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg', 'pdf']}
									labelName="Upload"
									error={uploadError}
									data={markingsLegImages}
									sizeLimitMB={5}
									numberOfImages={5}
									success={(e) => {
										photosUpload(e, setMarkingsLegImages, 'markingsLeg');
										setFieldErrors({
											...fieldErrors,
											markingsLeg: { ...fieldErrors.markingsLeg, value: false }
										});
									}}
									getImage={filePreviewHandler}
									removeImage={(e) =>
										removePhoto(e, markingsLegImages, setMarkingsLegImages)
									}
								/>
								<span className={classes.errorText}>
									{fieldErrors.markingsLeg.value
										? fieldErrors.markingsLeg.message
										: null}
								</span>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Markings - Head (jpg, png, pdf)*
								</div>
								<UploadPhoto
									formats={['png', 'jpeg', 'pdf']}
									labelName="Upload"
									error={uploadError}
									data={markingHeadImages}
									sizeLimitMB={5}
									numberOfImages={5}
									success={(e) => {
										photosUpload(e, setMarkingHeadImages, 'markingsHead');
										setFieldErrors({
											...fieldErrors,
											markingsHead: {
												...fieldErrors.markingsHead,
												value: false
											}
										});
									}}
									getImage={filePreviewHandler}
									removeImage={(e) =>
										removePhoto(e, markingHeadImages, setMarkingHeadImages)
									}
								/>
								<span className={classes.errorText}>
									{fieldErrors.markingsHead.value
										? fieldErrors.markingsHead.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
				</div>

				<HeaderBanner
					title="Horse History"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>

				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Importation date (Date arrived into Australia, if known)
								</div>
								<FormDatePicker
									DateValue={importationDate}
									updateSelection={(value) =>
										updateDateYear(value, setImportationDate)
									}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has this horse ever raced?*
								</div>
								<FormDropDown
									name="country"
									selectedId={hasHorseEverRaced}
									items={hasHorseRacedOpts}
									onChange={(e) => {
										setHasHorseEverRaced(e.target.value);
										setFieldErrors({
											...fieldErrors,
											hasHorseRaced: {
												...fieldErrors.hasHorseRaced,
												value: false
											}
										});
									}}
									showBlank
								/>
								<span className={classes.errorText}>
									{fieldErrors.hasHorseRaced.value
										? fieldErrors.hasHorseRaced.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle} style={{ float: 'left' }}>
									How did the horse come to be in your care?*
								</div>
								<ResizingTextArea
									value={howDidHorseComeYourCase}
									onChange={(e) => {
										setHowDidHorseComeYourCase(e.target.value);
										setFieldErrors({
											...fieldErrors,
											howDidHorseComeInYourCare: {
												...fieldErrors.howDidHorseComeInYourCare,
												value: false
											}
										});
									}}
									maxChars={500}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
								/>
								<span className={classes.errorText}>
									{fieldErrors.howDidHorseComeInYourCare.value
										? fieldErrors.howDidHorseComeInYourCare.message
										: null}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div
									className={classes.fieldTitle}
									style={{ float: 'left' }}
								>{`Are there any other details relevant to this horse's history or identity?`}</div>
								<ResizingTextArea
									value={areThereAnyDetailsIdentity}
									onChange={(e) =>
										setAreThereAnyDetailsIdentity(e.target.value)
									}
									maxChars={500}
									styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Upload Import/Export paperwork or sale history documentation
								</div>
								<UploadPhoto
									formats={['png', 'jpeg', 'pdf', 'docx', WORD_DOC_MIME_TYPE]}
									labelName="Upload"
									error={uploadError}
									data={importExportDocumentationImages}
									sizeLimitMB={5}
									numberOfImages={5}
									success={(e) =>
										photosUpload(
											e,
											setImportExportDocumentationImages,
											'uploadImportExport'
										)
									}
									getImage={filePreviewHandler}
									removeImage={(e) =>
										removePhoto(
											e,
											importExportDocumentationImages,
											setImportExportDocumentationImages
										)
									}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<hr className={classes.dividerLine}></hr>
				<div className={classes.buttonContainer}>
					<div
						className={classes.cancelRequest}
						onClick={() => setConfirmationModal(true)}
					>
						Cancel
					</div>
					<div className={classes.saveRequest}>
						<span className={classes.saveButton} onClick={() => onSaveDraft()}>
							Save Draft
						</span>
						<span>
							<input
								type="button"
								disabled={disableSubmitBtn}
								value={'Submit'}
								className={
									disableSubmitBtn
										? classes.disabledCompleteFormButton
										: classes.completeFormButton
								}
								onClick={() => onSubmitForm()}
							/>
						</span>
					</div>
				</div>
			</div>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			<ConfirmationModal
				showModal={confirmationModal}
				closeModal={() => setConfirmationModal(false)}
				styles={modalStylesConfirmation}
				heading="Cancel Application to identify Studbook Registration"
				positiveLabel="Confirm"
				negativeLabel="Cancel"
				onClickPositive={() => cancelRequest()}
			>
				Are you sure you wish to delete this form?
			</ConfirmationModal>
		</Container>
	);
};

export default ThoroughbredUnregisteredForm;

export const claimList = `
query approvedClaimedThroughbredList($memberExternalId: String!) {
  getHorseClaims(memberExternalId: $memberExternalId, status: [Approved], orderBy: "last_updated", order: DESC) {
    horse {
      name
      colour
      sex
      age
      horseCode
      location {
        address
        postcode
        suburb
        state
        country
        stabledDate
      }
      ottInformation {
        paddockName
        profilePhoto {
          key
        }
      }
    }
  }
}`;

export const getOhepFormData = `  
    query GetOhepFormData($memberExternalId: String!,$horseCode: Int!) {
        listOhepNominationRelationships {
            id
            value
        }
        listOhepNominationReasons { 
            id
            value
            description
            label
        }
        getMember(externalId: $memberExternalId) {
            firstName
            lastName
            email
            phone
            residentialAddress {
                address
                suburb
                state
                postcode
                country
            }
        }
        getHorseProfile(horseCode: $horseCode) {
            horse {
                name
                sex
                colour
            }
            status {
                id
                status {
                  id
                  value
                }
                effectiveDate
              }
        }
        getOhepNominationForm(horseCode: $horseCode) {
            acknowledgedConsentToParticipate
            acknowledgedOwnerConfirmation
            acknowledgedOwnerIndemnity
            acknowledgedOwnershipBinding
            acknowledgedOwnershipWarranty
            acknowledgedPrivacy
            advertisedToRvDate 
            currentAddress 
            currentAddressDurationMonths
            currentAddressDurationYears
            currentAddressValidated
            currentCountry 
            currentPostcode 
            currentState 
            currentSuburb 
            thoroughbredOverTwentyDetails 
            evidenceOfDangerousBehaviourFiles {
                created
                filename
                key
                type
            }
            id
            leftSidePhotos {
                created
                filename
                key
                type
            }
            otherFiles {
                created
                filename
                key
                type
            }
            previousAddress 
            previousAddressValidated
            previousCountry 
            previousPostcode 
            previousState 
            previousSuburb 
            reasonsForNomination {
                id
                value
                description
                label
            }
            rehomingAttemptDetails 
            rehomingAttemptDetailsDate 
            relationshipToHorse {
                id
                value
            }
            relationshipToHorseOther
            rightSidePhotos {
                created
                filename
                key
                type
            }
            rspcaNoticeToComplyFiles {
                created
                filename
                key
                type
            }
            statuaryDeclarationFiles {
                created
                filename
                key
                type
            }
            veterinaryCertificateFiles {
                created
                filename
                key
                type
            }
            status
        }
    }
`;

import { createUseStyles } from 'react-jss';

export default createUseStyles({
	bodyContent: {
		'& > div:first-child': {
			borderTopRightRadius: 5,
			borderTopLeftRadius: 5,
			overflow: 'hidden'
		}
	}
});

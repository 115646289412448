export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value.length === 0 ||
				values[f].value === false) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'Field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

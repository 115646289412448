import React from 'react';
import Map from '../../../Map';
import MapMarker from '../../../MapMarker';
import { useScreenClass } from 'react-grid-system';
import { checkIsSmallScreen } from '../../../../utils/helpers';

const RETRAINER_MAP_STYLES = {
	width: '100%',
	borderRadius: 5,
	overflow: 'hidden'
};

const RetrainerMap = ({
	searchResults,
	businessProfileIdFilter,
	setBusinessProfileIdFilter
}) => {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	let centerLatLng = null;
	if (businessProfileIdFilter) {
		const selectedBusinessProfile = searchResults.find(
			({ id }) => businessProfileIdFilter === id
		);
		centerLatLng = {
			lat: selectedBusinessProfile.latitude,
			lng: selectedBusinessProfile.longitude
		};
	}
	return (
		<Map
			style={{ ...RETRAINER_MAP_STYLES, height: isSmallScreen ? 400 : 600 }}
			hoverDistance={22.5}
			centerLatLng={centerLatLng}
		>
			{searchResults
				.filter(({ latitude, longitude }) => latitude && longitude)
				.map(
					({
						id,
						latitude,
						longitude,
						businessName,
						suburb,
						state,
						postcode
					}) => (
						<MapMarker
							key={id}
							lat={latitude}
							lng={longitude}
							businessName={businessName}
							location={`${suburb}, ${state} ${postcode}`}
							isSelected={businessProfileIdFilter === id}
							onClick={() => {
								setBusinessProfileIdFilter((prevState) =>
									prevState ? null : id
								);
							}}
						/>
					)
				)}
		</Map>
	);
};

export default RetrainerMap;

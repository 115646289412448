import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	uploadButton: {
		backgroundColor: '#006da6',
		border: 'none',
		borderRadius: 4,
		color: 'white',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		width: '100%',
		fontSize: 15,
		fontWeight: 400,
		'&:focus': {
			outline: 'none'
		},
		cursor: 'pointer'
	},
	uploadButtonDisabled: {
		backgroundColor: '#d8d8d8',
		border: 'none',
		borderRadius: 4,
		color: '#b2b2b2',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		height: 38,
		width: '100%',
		fontSize: 15,
		fontWeight: 400,
		'&:focus': {
			outline: 'none'
		}
	},
	approved: {
		fontStyle: 'italic',
		textDecoration: 'underline',
		fontSize: 13,
		color: '#0085ca',
		cursor: 'pointer',
		wordBreak: 'break-all'
	},
	uploadedFile: {
		extend: 'approved',
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	clearUpload: {
		fontSize: 13,
		marginLeft: 4,
		color: '#afafaf',
		cursor: 'pointer'
	}
}));

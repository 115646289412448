export const getOhepNTPFormData = `  
    query GetOhepFormData($horseCode: Int!) {
        listHorseColours
		listHorseGenders
        listOhepNTPServiceTypes {
            id
            value
        }
        getHorseProfile(horseCode: $horseCode) {
            horse {
                name
                sex
                colour
                age
                damName
                microChipNumber
                sireName
                location {
                    address
                    suburb
                    state
                    postcode
                    country
                }
            }
        }
        getOhepNTPForm(horseCode: $horseCode) {
            created
            currentAddress
            currentAddressValidated
            currentCountry
            currentPostcode
            currentState
            currentSuburb
            spAddress
            spAddressValidated
            spCountry
            spPostcode
            spState
            spSuburb
            horseAge
            horseCode
            horseColour
            horseDam
            horseMicrochip
            horseName
            horseOwnerEmail
            horseOwnerFirstName
            horseOwnerLastName
            horseOwnerMobile
            horseOwnerName
            horseOwnerSyndicateName
            horseOwnerTitle
            horseSire
            horseGender
            id
            lastUpdated
            letterSentDate
            letterSentPostDate
            memberId
            photos
             {
                        created
                        filename
                        key
                        type
                    }
            serviceProviderEmail
            serviceProviderFullname
            serviceProviderShortName
            status
            typeOfService{
                        id
                        value
                    }
        }
    }
`;

export const getOhepNominationApprovedFormData = /* GraphQL */ `
	query getOhepNominationApprovedFormData($horseCode: Int!) {
		getOhepNominationApprovedForm(horseCode: $horseCode) {
			adminData {
				primaryConcern {
					id
					value
				}
				primaryConcernOther
			}
			currentAddress
			currentAddressDurationMonths
			currentAddressDurationYears
			currentAddressValidated
			currentCountry
			currentPostcode
			currentState
			currentSuburb
			id
			status
			relationshipToHorseOther
			relationshipToHorse {
				id
				value
			}
			member {
				firstName
				lastName
				email
				phone
				id
			}
		}
	}
`;

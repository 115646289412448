import React, { Fragment } from 'react';
import HeaderBanner from '../HeaderBanner';
import SEO from '../seo';
import { StyledPageContainer, StyledTable, StyledTableHeading } from './style';
import { graphql, useStaticQuery } from 'gatsby';
import { parseTableData } from './helpers';

const PAGE_TITLE = 'OHEP Service Providers';

const OHEPProviders = () => {
	const ohepProviders = useStaticQuery(
		graphql`
			query ohepProviders {
				allContentfulTableContent {
					nodes {
						title {
							title
						}
						body {
							raw
						}
						tags
					}
				}
			}
		`
	);

	const parsedEuthanasiaOnly = parseTableData(
		ohepProviders.allContentfulTableContent.nodes.find((item) =>
			item.tags?.includes('euthanasia')
		)?.body?.raw
	);
	const parsedEuthanasiaVet = parseTableData(
		ohepProviders.allContentfulTableContent.nodes.find((item) =>
			item.tags?.includes('euthanasia vet')
		)?.body?.raw
	);

	const renderRows = (parsedArr) => {
		return parsedArr.map((row) => (
			<tr key={row[0]}>
				{row.map((column) => {
					return <td key={column.value}>{column.value}</td>;
				})}
			</tr>
		));
	};

	return (
		<Fragment>
			<SEO title={PAGE_TITLE} />
			<StyledPageContainer>
				<HeaderBanner className="!mb-4" title={PAGE_TITLE} />
				<StyledTableHeading className="mt-0">
					OHEP Service Providers which are pre-qualified
				</StyledTableHeading>
				<StyledTable className="mb-4">
					<thead>
						<tr>
							<th>Euthanasia only (Veterinary) providers</th>
							<th>Contact Person</th>
							<th>Phone</th>
							<th>Location</th>
						</tr>
					</thead>
					<tbody>{renderRows(parsedEuthanasiaOnly)}</tbody>
				</StyledTable>
				<StyledTable>
					<thead>
						<tr>
							<th>Euthanasia and/or removal providers</th>
							<th>Contact Person</th>
							<th>Phone</th>
							<th>Location</th>
						</tr>
					</thead>
					<tbody>{renderRows(parsedEuthanasiaVet)}</tbody>
				</StyledTable>
			</StyledPageContainer>
		</Fragment>
	);
};

export default OHEPProviders;

import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AppSyncService from '../../graphql/AppSyncService';
import HorseUpdatesContainer from '../HorseUpdatesContainer';
import InfiniteScrollLoadRow from '../InfiniteScrollLoadRow';
import {
	getRemoveReasons,
	getTotalUserHorseUpdatesCount,
	getUserHorseUpdates
} from './graphQL/queries';
import { Col, Container } from 'react-grid-system';
import HorseUpdate from '../HorseUpdate';
import LoaderSpinner from '../LoaderSpinner';
import EmptyHorseUpdates from './components/EmptyHorseUpdates';
import RemoveHorseUpdateModal from '../RemoveHorseUpdateModal';
import { removeHorseUpdate } from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';

const AdminHorseUpdatesTab = () => {
	const { currentUser } = useAuth();
	const { addToast } = useToast();
	const [totalUserHorseUpdates, setTotalUserHorseUpdates] = useState(0);
	const [userHorseUpdates, setUserHorseUpdates] = useState([]);
	const [paginationIndex, setPaginationIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [removeReasons, setRemoveReasons] = useState([]);
	const [isLoadOrRefresh, setIsLoadOrRefresh] = useState(true);
	const [postBeingRemoved, setPostBeingRemoved] = useState(null);

	const fetchMoreHorseUpdates = () => {
		AppSyncService.execute(getUserHorseUpdates, {
			paginationIndex,
			paginationSize: 15
		}).then((result) => {
			if (result?.data?.getUserHorseUpdates) {
				setUserHorseUpdates((prevState) => [
					...prevState,
					...result.data.getUserHorseUpdates
				]);
				setPaginationIndex((prevState) => ++prevState);
			}
		});
	};

	useEffect(() => {
		AppSyncService.execute(getRemoveReasons).then((result) => {
			if (result?.data?.listHorseUpdateRemoveReasons) {
				setRemoveReasons(result?.data?.listHorseUpdateRemoveReasons);
			}
		});
	}, []);

	useEffect(() => {
		if (isLoadOrRefresh) {
			AppSyncService.execute(getTotalUserHorseUpdatesCount).then((result) => {
				if (result?.data?.countUserHorseUpdates) {
					setTotalUserHorseUpdates(result?.data?.countUserHorseUpdates);
				}
			});
			AppSyncService.execute(getUserHorseUpdates, {
				paginationIndex: 0,
				paginationSize: 15
			}).then((result) => {
				if (result?.data?.getUserHorseUpdates) {
					setUserHorseUpdates(result.data.getUserHorseUpdates);
					setPaginationIndex(1);
					setIsLoadOrRefresh(false);
				}
				setIsLoading(false);
			});
		}
	}, [isLoadOrRefresh]);

	const onRemoveHorseUpdate = (reasonId, otherReason) => {
		AppSyncService.execute(removeHorseUpdate, {
			memberExternalId: currentUser?.UserId,
			postId: postBeingRemoved,
			reasonId,
			otherReason
		}).then((result) => {
			if (result?.data?.removeHorseUpdate?.success) {
				addToast({ Message: 'Removed horse update', IsSuccess: true });
				setIsLoadOrRefresh(true);
				setIsLoading(true);
				setPostBeingRemoved(null);
			} else {
				addToast({
					Message: 'Failed to remove horse update',
					IsSuccess: false
				});
			}
		});
	};

	return (
		<Container style={{ maxWidth: 750 }}>
			<Col>
				{isLoading ? (
					<LoaderSpinner status={true} />
				) : (
					<HorseUpdatesContainer>
						{userHorseUpdates.length > 0 ? (
							<InfiniteScroll
								dataLength={userHorseUpdates.length}
								next={fetchMoreHorseUpdates}
								hasMore={totalUserHorseUpdates > userHorseUpdates.length}
								loader={<InfiniteScrollLoadRow />}
							>
								{userHorseUpdates.map(
									({
										post_id,
										horse_code: horseCode,
										message,
										created_at,
										horse,
										files,
										source
									}) => (
										<HorseUpdate
											key={post_id}
											postId={post_id}
											horseCode={horseCode}
											horseAvatarVisible={true}
											profileImageSrc={horse.ottInformation.profilePhoto.key}
											horseName={horse.name}
											message={message}
											createdAt={created_at}
											files={files}
											imageStyles={{ marginLeft: 45 }}
											containerStyles={{ padding: 15 }}
											showMoreOptions={true}
											onRemoveUpdate={() => setPostBeingRemoved(post_id)}
											source={source}
										/>
									)
								)}
							</InfiniteScroll>
						) : (
							<EmptyHorseUpdates />
						)}
					</HorseUpdatesContainer>
				)}
			</Col>
			{postBeingRemoved && (
				<RemoveHorseUpdateModal
					showModal={!!postBeingRemoved}
					onConfirm={onRemoveHorseUpdate}
					onCancel={() => setPostBeingRemoved(null)}
					removeReasons={removeReasons}
				/>
			)}
		</Container>
	);
};

export default AdminHorseUpdatesTab;

import { createUseStyles } from 'react-jss';
export default createUseStyles(() => ({
	'@global': {
		'rbc-events-container': {
			width: '100%'
		},
		'rbc-row-segment': {
			padding: 5
		},
		'.rbc-date-cell': {
			textAlign: 'left',
			paddingLeft: 5,
			paddingRight: 0
		},
		'.rbc-show-more': {
			color: '#0085ca',
			textDecoration: 'none',
			fontWeight: 500
		},
		'.rbc-overlay > * + *': {
			marginBottom: 5
		}
	},
	labelDate: {
		color: 'rgb(71,71,71)',
		display: 'flex',
		minWidth: 165,
		justifyContent: 'space-around'
	},
	labelDateWeek: {
		extend: 'labelDate',
		padding: 8,
		fontWeight: 500
	},
	toolbarContainer: {
		padding: '10px 0px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline'
	},
	btnBack: {
		color: 'rgb(0 109 166)',
		cursor: 'pointer'
	},
	btnNext: {
		color: 'rgb(0 109 166)',
		cursor: 'pointer'
	},
	backNextButtons: {
		fontSize: 20,
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		marginLeft: 10
	},
	backNextWeekButtons: {
		extend: 'backNextButtons',
		background: 'rgb(220 235 243)',
		marginLeft: 0
	},
	container: {
		marginBottom: 10,
		paddingLeft: '0 !important',
		paddingRight: '0 !important'
	},
	dateHeader: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	eventCount: {
		color: '#0085ca',
		fontSize: 12,
		marginTop: 5,
		marginRight: 5,
		fontWeight: 500
	},
	multiSelect: {
		zIndex: 40
	},
	eventPopover: {
		backgroundColor: 'rgb(237, 237, 237)',
		padding: 10,
		borderRadius: '0px 0px 5px 5px'
	},
	upNext: {
		extend: 'eventPopover',
		minHeight: 145
	},
	topBannerText: {
		marginBottom: 10,
		color: '#464749',
		fontSize: 20,
		fontWeight: 800
	},
	mobileUpNext: {
		extend: 'eventPopover',
		marginBottom: 15
	},
	dateLabel: {
		minWidth: '25%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		fontWeight: 500,
		color: 'rgb(70 71 73)'
	},
	dayLabel: {
		fontSize: 35
	},
	dowLabel: {
		fontSize: 12,
		fontWeight: 500,
		marginTop: -10
	},
	noEvents: {
		margin: '20px 0px',
		color: '#777777',
		fontSize: 12,
		fontWeight: 500
	},
	mobileDateContainer: {
		display: 'flex',
		marginBottom: 10,
		padding: 5,
		paddingLeft: 0
	},
	arrowLeft: {
		marginTop: -2,
		height: 16,
		marginRight: 7
	},
	arrowRight: {
		marginTop: -2,
		height: 16,
		marginLeft: 7,
		transform: 'rotate(180deg)'
	},
	filterContainer: {
		marginTop: 15,
		maxHeight: '100%',
		padding: '0px !important'
	},
	filterContainerHeader: {
		fontSize: 15,
		color: '#464749',
		fontWeight: 800
	},
	searchButton: {
		backgroundColor: '#006da6',
		border: 'none',
		'-webkitAppearance': 'none',
		borderRadius: '0px 4px 4px 0px',
		color: 'white',
		float: 'left',
		textAlign: 'center',
		textDecoration: 'none',
		display: 'inline-block',
		minWidth: '100%',
		height: 38,
		fontSize: 16,
		margin: 0,
		cursor: 'pointer',
		'&:focus': {
			outline: 'none'
		}
	},
	filterContainerHeaderMobile: {
		fontSize: 12,
		color: '#464749',
		fontWeight: 800,
		padding: '10px 10px 6px 0px'
	}
}));

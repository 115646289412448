import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, ScreenClassRender, Row, Col } from 'react-grid-system';
import useStyles from './styles';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import Programs from '../Programs';
import ThreeCards from '../ThreeCards';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import FullArticleEmbeddedAsset from '../FullArticleEmbeddedAsset';

const RetrainerOfTheYear = () => {
	const classes = useStyles();
	const [cards, setCards] = useState([]);
	const [richText, setRichText] = useState(null);
	const retrainerContent = useStaticQuery(
		graphql`
			query retrainerOfTheYearLandingPageContent {
				allContentfulLandingPageContent(
					filter: { tags: { in: "Retrainer Of The Year Landing Page" } }
				) {
					nodes {
						title {
							title
						}
						body {
							raw
							references {
								__typename
								... on ContentfulAsset {
									contentful_id
									file {
										url
										contentType
										fileName
									}
									title
								}
							}
						}
					}
				}
				allContentfulInfoCards(
					filter: { tags: { in: "Retrainer Of The Year Info Cards" } }
				) {
					nodes {
						text {
							text
						}
						title
						order
						image {
							file {
								url
								fileName
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		const retrainerOfTheYearInfo =
			retrainerContent.allContentfulInfoCards.nodes;
		let retrainerOfTheYearInfoCards = [];
		if (retrainerOfTheYearInfo.length > 0) {
			retrainerOfTheYearInfo.map((d) => {
				retrainerOfTheYearInfoCards.push({
					title: d?.title,
					summary: d?.text?.text,
					image: d?.image?.file?.url,
					order: d?.order
				});
			});
			retrainerOfTheYearInfoCards.sort((a, b) =>
				a.order > b.order ? 1 : b.order > a.order ? -1 : 0
			);
			setCards(retrainerOfTheYearInfoCards);
			setRichText(
				retrainerContent?.allContentfulLandingPageContent.nodes[0].body
			);
		}
	}, []);

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => (
				<div className={classes.articleText}>{children}</div>
			),
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				return (
					<FullArticleEmbeddedAsset
						contentType={node.data.target.file.contentType}
						contentSrc={node.data.target.file.url}
					/>
				);
			}
		}
	};

	const landingPageContent = (isMobile) => {
		return (
			<>
				<HeaderBanner title="Retrainer Of The Year" />
				<Row>
					<Col xs={12}>
						{!isMobile && cards.length > 0 && <ThreeCards data={cards} />}
						{isMobile && cards.length > 0 && (
							<Programs data={cards} mobile noReadMore />
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={9}>
						{richText && renderRichText(richText, options)}
					</Col>
				</Row>
			</>
		);
	};

	const component = (isMobile) => {
		return (
			<>
				<Container
					className={isMobile ? classes.mobileTopContainer : classes.container}
				>
					<Row>
						<Col xs={12}>
							<SEO title="Retrainer Of The Year" />
							<div className={classes.topContainer}>
								{landingPageContent(isMobile)}
							</div>
						</Col>
					</Row>
				</Container>
			</>
		);
	};

	const page = (screenClass) => {
		return component(['xs', 'sm', 'md'].includes(screenClass));
	};

	return <ScreenClassRender render={page} />;
};

export default RetrainerOfTheYear;

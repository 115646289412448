import { createUseStyles } from 'react-jss';

export default createUseStyles({
	footerContainer: {
		border: '1px solid #b3bcc1',
		borderTop: 'none',
		borderBottomLeftRadius: 5,
		borderBottomRightRadius: 5
	},
	footerContentContainer: {
		width: '100%',
		boxSizing: 'border-box',
		padding: 20,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		'@media (min-width:992px)': {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			alignItems: 'center'
		}
	},
	errorMessage: {
		color: 'red',
		marginRight: 10,
		marginBottom: 10,
		fontSize: 13,
		'@media (min-width:992px)': {
			fontSize: 15,
			marginBottom: 0
		}
	},
	footerActions: {
		display: 'flex'
	}
});

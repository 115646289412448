export const DEPENDENT_DROPDOWN = 'DEPENDENT_DROPDOWN';
export const DEPENDENT_DROPDOWN_WITH_TOGGLE = 'DEPENDENT_DROPDOWN_WITH_TOGGLE';
export const UNAUTHENTICATED_REQUIRED = 'UNAUTHENTICATED_REQUIRED';

export const validation = (values, initial, validationFields) => {
	let errors = {};

	validationFields.map((f) => {
		if (initial && values[f].conditions.length > 0) {
			values[f].conditions.map((c) => {
				if (c.condition === DEPENDENT_DROPDOWN) {
					let dependentValueId = c.dependantArray.filter(
						(rec) =>
							rec.value === c.value &&
							rec.id === parseInt(values[c.dependantField].value)
					);
					if (
						dependentValueId.length > 0 &&
						(values[f].value === null ||
							values[f].value.length === 0 ||
							values[f].value === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === DEPENDENT_DROPDOWN_WITH_TOGGLE) {
					let dependentValueId = c.dependantArray.filter(
						(rec) =>
							rec.value === c.value &&
							rec.id === parseInt(values[c.dependantField].value)
					);
					if (
						dependentValueId.length > 0 &&
						values[c.toggleField].value === c.toggleValue &&
						(values[f].value === null || values[f].value.length === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
				if (c.condition === UNAUTHENTICATED_REQUIRED) {
					let user = c.user;
					if (
						!user &&
						(values[f].value === null ||
							values[f].value === '' ||
							values[f].value === 0)
					) {
						errors[f] = c.errorMessage;
					}
				}
			});
		}
		if (
			initial &&
			(values[f].value === null ||
				values[f].value === '' ||
				values[f].value === 0 ||
				values[f].value.length === 0 ||
				values[f].value === false) &&
			values[f].isRequired
		) {
			let error = '';
			if (values[f].errorMessage) {
				error = values[f].errorMessage;
			} else {
				error = 'Field is required';
			}
			errors[f] = error;
		}
	});
	return errors;
};

import React, { useEffect, useState } from 'react';
import FormDatePicker from '../FormDatePicker';
import useStyles from './styles';
import { Row, Col, useScreenClass } from 'react-grid-system';
import {
	checkDropDownValue,
	checkIsSmallScreen,
	convertToSortedMultiListData,
	extractLookUpOptions,
	formatDate,
	isOptionOther
} from '../../utils/helpers';
import SaveCancelButton from '../SaveCancelButton';
import { BLANK_OPTION } from '../../utils/constants';
import { getRetrainerConcludedRetrainingOptions } from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import FormDropDown from '../FormDropDown';
import FormMultiSelectList from '../FormMultiSelectList';
import ResizingTextArea from '../ResizingTextArea';
import { HorseManagementKeys, CommonKeys } from './../../utils/constants/index';

const RetrainerConcludedEuthanisedForm = ({ cancel, submitForm }) => {
	const classes = useStyles();
	const [date, setDate] = useState(null);
	const [dateError, setDateError] = useState('');
	const [error, setError] = useState('');
	const [euthanasiaReasons, setEuthanasiaReasons] = useState([BLANK_OPTION]);
	const [euthanasiaReason, setEuthanasiaReason] = useState(0);
	const [euthanasiaReasonError, setEuthanasiaReasonError] = useState('');
	const [euthanasiaInjuryTypes, setEuthanasiaInjuryTypes] = useState([]);
	const [euthanasiaIllnessTypes, setEuthanasiaIllnessTypes] = useState([]);
	const [unsuitableReasonOptions, setUnsuitableReasonOptions] = useState([]);
	const [euthanasiaOtherTypes, setEuthanasiaOtherTypes] = useState([]);
	const [injuryTypeError, setInjuryTypeError] = useState('');
	const [illnessTypeError, setIllnessTypeError] = useState('');
	const [unsuitableReasonError, setUnsuitableReasonError] = useState('');
	const [otherReasonError, setOtherReasonError] = useState('');
	const [injuryType, setInjuryType] = useState([]);
	const [illnessType, setIllnessType] = useState([]);
	const [unsuitableReason, setUnsuitableReason] = useState([]);
	const [otherReason, setOtherReason] = useState([]);
	const [otherInfo, setOtherInfo] = useState('');
	const screenSize = useScreenClass();
	const isMobile = checkIsSmallScreen(screenSize);
	const [isOtherSelected, setIsOtherSelected] = useState(false);

	useEffect(() => {
		AppSyncService.execute(getRetrainerConcludedRetrainingOptions).then(
			(result) => {
				if (result.data) {
					setEuthanasiaReasons([
						BLANK_OPTION,
						...extractLookUpOptions(result, 'listEuthanisedReasons')
					]);
					setEuthanasiaInjuryTypes(
						convertToSortedMultiListData(
							extractLookUpOptions(result, 'listEuthanisedInjuries')
						)
					);
					setEuthanasiaIllnessTypes(
						convertToSortedMultiListData(
							extractLookUpOptions(result, 'listEuthanisedIllness')
						)
					);
					setUnsuitableReasonOptions(
						convertToSortedMultiListData(
							extractLookUpOptions(result, 'listEuthanisedUnsuitableReasons')
						)
					);
					setEuthanasiaOtherTypes(
						convertToSortedMultiListData(
							extractLookUpOptions(result, 'listEuthanisedOtherReasons')
						)
					);
				}
			}
		);
	}, []);

	const isOtherSelectedInMultiDropdown = () => {
		return (
			injuryType.find(({ label }) => label == 'Other') !== undefined ||
			illnessType.find(({ label }) => label == 'Other') !== undefined ||
			unsuitableReason.find(({ label }) => label == 'Other') !== undefined
		);
	};

	const clearError = (errorUpdateFunction) => {
		errorUpdateFunction('');
	};

	const clearEuthanasiaInuryInputFields = () => {
		setInjuryType([]);
		setOtherInfo('');
	};

	const clearEuthanasiaIllnessInputFields = () => {
		setIllnessType([]);
		setOtherInfo('');
	};

	const clearEuthanasiaUnsuitableForRehomingInputFields = () => {
		setUnsuitableReason([]);
		setOtherInfo('');
	};

	const clearEuthanasiaOtherInputFields = () => {
		setOtherReason([]);
		setOtherInfo('');
	};

	const updateEuthanasiaReason = (event) => {
		const newReason = event.target.value;
		setEuthanasiaReason(newReason);
		setEuthanasiaReasonError('');
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Injury')) {
			clearEuthanasiaInuryInputFields();
		}
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Illness')) {
			clearEuthanasiaIllnessInputFields();
		}
		if (
			!checkDropDownValue(
				euthanasiaReasons,
				newReason,
				'Unsuitable for rehoming'
			)
		) {
			clearEuthanasiaUnsuitableForRehomingInputFields();
		}
		if (!checkDropDownValue(euthanasiaReasons, newReason, 'Other')) {
			clearEuthanasiaOtherInputFields();
		}
	};

	const updateDate = (date) => {
		if (date == '') {
			setDate(null);
		} else {
			let formattedDate = formatDate(date);
			if (formattedDate != '1970-01-01') {
				setDateError('');
				setDate(formattedDate);
			} else {
				setDate(null);
			}
		}
	};

	useEffect(() => {
		setIsOtherSelected(isOtherSelectedInMultiDropdown());
	}, [injuryType, illnessType, unsuitableReason]);

	const updateInjuryType = (value) => {
		setInjuryType(value);
		clearError(setInjuryTypeError);
	};

	const updateIllnessType = (value) => {
		setIllnessType(value);
		clearError(setIllnessTypeError);
	};

	const updateUnsuitableReason = (value) => {
		setUnsuitableReason(value);
		clearError(setUnsuitableReasonError);
	};

	const updateOtherReason = (value) => {
		setOtherReason(value);
		clearError(setOtherReasonError);
	};

	const FormDropDownInputField = ({
		id,
		fieldLabel,
		listOptions,
		selectedIds,
		onSelectChange,
		errorMessage
	}) => {
		return (
			<FormFieldWrapper
				fieldLabel={fieldLabel}
				errorMessage={errorMessage}
				isRequired
			>
				<FormMultiSelectList
					id={id}
					listOptions={listOptions}
					selectedIds={selectedIds}
					onSelectChange={onSelectChange}
					border={errorMessage ? 'red' : null}
				/>
			</FormFieldWrapper>
		);
	};

	const OtherFieldInput = () => {
		return (
			<ResizingTextArea
				title="Other"
				charLengthStyle={isMobile ? { float: 'right', marginBottom: 10 } : {}}
				name="OtherInfo"
				id={HorseManagementKeys.EUTHANISED_OTHER_INJURY_OR_ILLNESS}
				value={otherInfo}
				onChange={(e) => {
					setOtherInfo(e.target.value);
				}}
				maxChars={500}
				canEdit={true}
			/>
		);
	};

	const HorseDeathCauseEuthanasiaInput = () => {
		const isEuthanasiaReasonInjury = checkDropDownValue(
			euthanasiaReasons,
			euthanasiaReason,
			'Injury'
		);
		const isEuthanasiaReasonIllness = checkDropDownValue(
			euthanasiaReasons,
			euthanasiaReason,
			'Illness'
		);
		const isEuthanasiaReasonUnsuitableForRehoming = checkDropDownValue(
			euthanasiaReasons,
			euthanasiaReason,
			'Unsuitable for rehoming'
		);
		const isEuthanasiaReasonOther = isOptionOther(
			euthanasiaReasons,
			euthanasiaReason
		);

		return (
			<>
				{isEuthanasiaReasonInjury && (
					<>
						<FormDropDownInputField
							id={HorseManagementKeys.EUTHANISED_INJURY_TYPE}
							fieldLabel="Injury Type"
							listOptions={euthanasiaInjuryTypes}
							selectedIds={injuryType}
							onSelectChange={updateInjuryType}
							errorMessage={injuryTypeError}
						/>
					</>
				)}
				{isEuthanasiaReasonIllness && (
					<>
						<FormDropDownInputField
							id={HorseManagementKeys.EUTHANISED_ILLNESS_TYPE}
							fieldLabel="Illness Type"
							listOptions={euthanasiaIllnessTypes}
							selectedIds={illnessType}
							onSelectChange={updateIllnessType}
							errorMessage={illnessTypeError}
						/>
					</>
				)}
				{isEuthanasiaReasonUnsuitableForRehoming && (
					<>
						<FormDropDownInputField
							id={HorseManagementKeys.EUTHANISED_UNSUITABLE_TYPE}
							fieldLabel="Unsuitable for rehoming reason"
							listOptions={unsuitableReasonOptions}
							selectedIds={unsuitableReason}
							onSelectChange={updateUnsuitableReason}
							errorMessage={unsuitableReasonError}
						/>
					</>
				)}
				{isEuthanasiaReasonOther && (
					<>
						<FormDropDownInputField
							id={HorseManagementKeys.EUTHANISED_OTHER_TYPE}
							fieldLabel="Other reason"
							listOptions={euthanasiaOtherTypes}
							selectedIds={otherReason}
							onSelectChange={updateOtherReason}
							errorMessage={otherReasonError}
						/>
					</>
				)}
			</>
		);
	};

	const saveData = () => {
		const reason = euthanasiaReasons.find(({ id }) => id == euthanasiaReason)
			?.value;
		let doSubmit = true;

		switch (reason) {
			case 'Injury':
				if (injuryType.length === 0) {
					setInjuryTypeError('You must select one or more injury types');
					doSubmit = false;
				}
				break;
			case 'Illness':
				if (illnessType.length === 0) {
					setIllnessTypeError('You must select one or more illness types');
					doSubmit = false;
				}
				break;
			case 'Unsuitable for rehoming':
				if (unsuitableReason.length === 0) {
					setUnsuitableReasonError(
						'You must select one or more reasons the horse was unsuitable for rehoming'
					);
					doSubmit = false;
				}
				break;
			case 'Other':
				if (otherReason.length === 0) {
					setOtherReasonError('You must select one or more reasons for other');
					doSubmit = false;
				}
				break;
			default:
				setEuthanasiaReasonError('You must select a reason for euthanasia');
				doSubmit = false;
				break;
		}
		if (date === null) {
			setDateError('Please select the date of death');
			doSubmit = false;
		}
		if (doSubmit) {
			let injuryTypeIds = injuryType.map(({ value }) => value);
			let illnessTypeIds = illnessType.map(({ value }) => value);
			let unsuitableReasonIds = unsuitableReason.map(({ value }) => value);
			let otherReasonIds = otherReason.map(({ value }) => value);
			let specificIds = injuryTypeIds
				.concat(illnessTypeIds)
				.concat(unsuitableReasonIds)
				.concat(otherReasonIds);
			let data = {
				date: date,
				specificCauses: specificIds,
				reasonId: euthanasiaReason,
				specificCauseOther: otherInfo || null
			};
			submitForm(data);
		} else {
			setError('Required fields cannot be empty');
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.formContainer}>
				<Row>
					<FormFieldWrapper
						fieldLabel="Date of death"
						errorMessage={dateError}
						isRequired
					>
						<FormDatePicker
							id={HorseManagementKeys.DATE_OF_DEATH}
							DateValue={date}
							updateSelection={(value) => {
								clearError(setDate);
								updateDate(value, setDate);
							}}
							isError={dateError !== ''}
						/>
					</FormFieldWrapper>
					<FormFieldWrapper
						fieldLabel="Reason"
						errorMessage={euthanasiaReasonError}
						isRequired
					>
						<FormDropDown
							id={HorseManagementKeys.EUTHANISED_REASON}
							items={euthanasiaReasons}
							selectedId={euthanasiaReason}
							onChange={updateEuthanasiaReason}
							showBlank
							isError={euthanasiaReasonError !== ''}
						/>
					</FormFieldWrapper>
					{euthanasiaReason !== 0 && HorseDeathCauseEuthanasiaInput()}
				</Row>
				<Row>
					<Col xs={12}>{isOtherSelected && OtherFieldInput()}</Col>
				</Row>
				{error !== '' && (
					<Row>
						<Col xs={12}>
							<div style={{ color: '#db0000', paddingTop: 10 }}>{error}</div>
						</Col>
					</Row>
				)}
				<hr className={classes.divider}></hr>
				<div className={classes.saveButtonContainer}>
					<SaveCancelButton
						idPositive={CommonKeys.SAVE}
						idNegative={CommonKeys.CANCEL}
						positiveLabel="Save"
						onClickPositive={saveData}
						cancel={cancel}
						negativeLabel="Cancel"
					/>
				</div>
			</div>
		</div>
	);
};
export default RetrainerConcludedEuthanisedForm;

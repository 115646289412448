import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import { navigate } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveFullCircleStatusMutation,
	saveThoroughbredRegistrationStatusMutation
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useToast } from '../../hooks/useToast';
import FormTextArea from '../FormTextArea';
import HeaderBanner from '../HeaderBanner';
import { CommonKeys } from '../../utils/constants';

const AdminApprovalOnly = ({
	reviewNotesData,
	id,
	reviewStatus,
	reviewType,
	isLoading
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [reviewNotes, setReviewNotes] = useState('');
	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [isSubmitDisabled, seIsSubmitDisabled] = useState(false);
	const REVIEWED = 'Reviewed';

	useEffect(() => {
		setReviewNotes(reviewNotesData);
		if (reviewStatus == 'Draft' || reviewStatus == REVIEWED) {
			seIsSubmitDisabled(true);
		}
	}, [reviewNotesData]);

	const updateReviewNotes = (value) => {
		if (reviewStatus == 'Draft') {
			seIsSubmitDisabled(true);
		} else {
			seIsSubmitDisabled(false);
		}
		setReviewNotes(value);
	};

	const storeData = async (reviewStatus) => {
		setBackendErrorMessage(null);
		if (reviewType == 'ThoroughbredRegistration') {
			try {
				AppSyncService.execute(saveThoroughbredRegistrationStatusMutation, {
					guid: id,
					reviewNotes: reviewNotes,
					status: reviewStatus
				}).then((data) => {
					if (data.data != null) {
						seIsSubmitDisabled(true);
						addToast({ Message: reviewStatus, IsSuccess: true });
						navigate(`/review-list`);
					}
				});
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving Thoroughbred Registration review data. Please contact support'
					);
				}
			}
		} else {
			try {
				AppSyncService.execute(saveFullCircleStatusMutation, {
					id: parseInt(id),
					reviewNotes: reviewNotes,
					status: reviewStatus
				}).then((data) => {
					if (data.data != null) {
						seIsSubmitDisabled(true);
						addToast({ Message: reviewStatus, IsSuccess: true });
						navigate(`/review-list`);
					}
				});
			} catch (error) {
				if (error) {
					setBackendErrorMessage(error);
				} else {
					setBackendErrorMessage(
						'Unknown error while saving Full Circle review data. Please contact support'
					);
				}
			}
		}
	};

	return (
		<>
			<HeaderBanner
				title="Additional Notes (Admin Only)"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.container}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div>
					<div>
						<Row>
							<Col
								className={classes.notesContainer}
								style={{ marginLeft: 20 }}
							>
								<div>
									<span className={classes.fieldTitle}>Notes</span>
								</div>
								<FormTextArea
									id={CommonKeys.NOTES}
									value={reviewNotes}
									onChange={(e) => {
										updateReviewNotes(e.target.value);
									}}
								/>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>
					<div className={classes.buttonContainer}>
						<div>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span>
								<input
									id={CommonKeys.COMPLETE}
									type="button"
									value="Complete"
									className={
										isSubmitDisabled
											? classes.disabledFormButton
											: classes.primaryFormButton
									}
									onClick={() => storeData(REVIEWED)}
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default AdminApprovalOnly;

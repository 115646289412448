import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		background: '#f5f5f5',
		paddingBottom: 20,
		borderBottom: '1px solid #e5f0f6'
	},
	formContainer: {
		paddingRight: 20,
		paddingLeft: 20,
		borderLeft: '1px solid #b3bcc1',
		borderRight: '1px solid #b3bcc1'
	},
	formHeading: {
		fontSize: 18,
		fontWeight: 600,
		color: '#464749',
		padding: '20px 0 20px 0'
	},
	divider: {
		background: '#e2e2e2',
		height: 2,
		border: 'none',
		marginTop: 35
	},
	topMessage: {
		padding: '10px 0px 10px 5px'
	},
	dateFieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 5,
		marginTop: 20
	},
	fieldTitle: {
		fontSize: 14,
		marginTop: 5,
		marginBottom: 5,
		fontWeight: 600,
		color: '#464749'
	},
	saveButtonContainer: {
		marginTop: 20
	},
	cancelRequestLink: {
		color: '#0085ca',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	submitContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderTop: '1px solid #e5f0f6',
		borderBottom: '1px solid #b3bcc1',
		marginLeft: -20,
		marginRight: -20,
		padding: 15,
		marginTop: 10
	},
	errorText: {
		color: 'red'
	},
	messageBarWithToggle: {
		display: 'flex',
		width: '100%'
	},
	pageContainer: {
		maxWidth: '1000px!important',
		marginBottom: 20
	},
	readMoreLink: {
		color: '#006da6',
		fontWeight: 600,
		textDecoration: 'none'
	},
	fieldContainer: {
		marginTop: 15
	},
	fullWidthDivider: {
		background: '#e5f0f6',
		height: 1,
		border: 'none',
		marginTop: 35,
		marginLeft: -20,
		marginRight: -20
	},
	fullCircleBar: {
		marginBottom: 10
	}
}));

import React, { useEffect, useState } from 'react';
import { MessageBarTypeEnum } from '../../utils/enums';
import InfoBar from '../InfoBar';
import useStyles from './styles';
import {
	formatDate,
	extractLookUpOptions,
	formatDateReadableVariant,
	checkIsSmallScreen
} from '../../utils/helpers';
import { useToast } from '../../hooks/useToast';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteImageFromS3,
	deleteImagesFromS3,
	getUserImage,
	uploadImageToS3,
	saveResetReportingImage,
	getListOfHabitsAndVices,
	saveFosterInitialAssessmentForm,
	getFosterInitialAssessment,
	submitFosterInitialAssessmentForReview
} from '../../graphql/custom.queries';
import axios from 'axios';
import UploadPhoto from '../UploadPhoto';
import Lightbox from 'react-image-lightbox';
import ResizingTextArea from '../ResizingTextArea';
import PrimaryButton from '../PrimaryButton';
import Toggle from '../Toggle';
import { useAuth } from '../../hooks/useAuth';
import FormDatePicker from '../FormDatePicker';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import FormDropDown from '../FormDropDown';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import { physicalHealthItems } from './constants';
import { REVIEW, APPROVED, CommonKeys } from '../../utils/constants/index';
import LoaderSpinner from '../LoaderSpinner';
import Dialog from '../Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import ValidationErrorText from '../FormComponents/ValidationErrorText';
import FormLabelField from '../FormLabelField';

const schema = yup.object().shape({});

const infoText =
	'As this is your first report, please complete the following Initial Assessment form within 14 days of the horse arriving.';

const AcknowledgedFosterInitialAssessmentForm = ({
	horseCode,
	horseData,
	index,
	reload
}) => {
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, control } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});
	const { currentUser } = useAuth();
	const [dateOfAssessment, setDateOfAssessment] = useState(null);
	const [leftSidePhotos, setLeftSidePhotos] = useState([]);
	const [rightSidePhotos, setRightSidePhotos] = useState([]);
	const [additionalPhotos, setAdditionalPhotos] = useState([]);
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [overallCondition, setOverallCondition] = useState('');
	const [worming, setWorming] = useState(false);
	const [farrier, setFarrier] = useState(false);
	const [dentist, setDentist] = useState(false);
	const [wormingDate, setWormingDate] = useState(null);
	const [farrierDate, setFarrierDate] = useState(null);
	const [dentistDate, setDentistDate] = useState(null);
	const [otherHusbandryInfo, setOtherHusbandryInfo] = useState('');
	const [feedingPlan, setFeedingPlan] = useState('');
	const [temperament, setTemperament] = useState('');
	const [horseHandle, setHorseHandle] = useState('');
	const [horseHasVices, setHorseHasVices] = useState(false);
	const [viceOptions, setViceOptions] = useState([]);
	const [selectedVices, setSelectedVices] = useState([]);
	const [otherVices, setOtherVices] = useState('');
	const [physicalHealth, setPhysicalHealth] = useState(false);
	const [physicalHealthVal, setPhysicalHealthVal] = useState(0);
	const [physicalHealthConcern, setPhysicalHealthConcern] = useState('');
	const [behaviourConcern, setBehaviourConcern] = useState(false);
	const [behaviourConcernVal, setBehaviourConcernVal] = useState('');
	const [finalComments, setFinalComments] = useState('');
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingForm, setIsLoadingForm] = useState(false);
	const [openClearConfirmationDiag, setOpenClearConfirmationDiag] = useState(
		false
	);
	const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
	const [feedingError, setFeedingError] = useState('');
	const [temperamentError, setTemperamentError] = useState('');
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	//const [formSubmitted, setformSubmitted] = useState(false);
	const [habitsOtherReadOnly, setHabitsOtherReadOnly] = useState('');
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isRetirementDetailsLoaded, setIsRetirementDetailsLoaded] = useState(
		false
	);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);
	let isValidationErrors = false;
	const validateUncontrolledErrors = () => {
		validateLeftSidePhotos();
		validateRightSidePhotos();
		validateWormingDate();
		validateFarrierDate();
		validateDentistDate();
		validateAssessmentDate();
		validatePhysicalHealth();
		validatePhysicalHealthOther();
		validateBehaviourOther();
		if (feedingPlan.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setFeedingError('You must enter the initial feeding plan assessment.');
		} else {
			setFeedingError('');
		}
		if (temperament.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setTemperamentError('You must enter the initial temperament assessment.');
		} else {
			setTemperamentError('');
		}
	};

	const validateLeftSidePhotos = () => {
		if (leftSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: {
					message: 'You must upload at least one left side photo.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				leftSidePhotos: { message: null }
			}));
		}
	};

	const validateRightSidePhotos = () => {
		if (rightSidePhotos.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: {
					message: 'You must upload at least one right side photo.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rightSidePhotos: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.leftSidePhotos?.message) {
			validateLeftSidePhotos();
		}
	}, [leftSidePhotos]);

	useEffect(() => {
		if (uncontrolledErrors?.rightSidePhotos?.message) {
			validateRightSidePhotos();
		}
	}, [rightSidePhotos]);

	const validateWormingDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				WormingDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (worming && !wormingDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: {
						message: 'You must enter a Date of Completion for Worming.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					WormingDate: { message: null }
				}));
			}
		}
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	useEffect(() => {
		if (uncontrolledErrors?.WormingDate?.message) {
			validateWormingDate();
		}
	}, [worming, wormingDate]);

	const validateAssessmentDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				AssessmentDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!dateOfAssessment) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: {
						message: 'You must enter Date of Assessment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					AssessmentDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.AssessmentDate?.message) {
			validateAssessmentDate();
		}
	}, [dateOfAssessment]);

	const validateFarrierDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				FarrierDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (farrier && !farrierDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: {
						message:
							'You must enter a Date of Completion/Booking for the Farrier.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					FarrierDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.FarrierDate?.message) {
			validateFarrierDate();
		}
	}, [farrier, farrierDate]);

	const validateDentistDate = (isInvalidDate) => {
		if (isInvalidDate) {
			isValidationErrors = true;
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				DentistDate: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (dentist && !dentistDate) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: {
						message:
							'You must enter a Date of Completion/Booking for Dentistry.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					DentistDate: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.DentistDate?.message) {
			validateDentistDate();
		}
	}, [dentist, dentistDate]);

	const validatePhysicalHealth = () => {
		if ((!physicalHealthVal || physicalHealthVal == 0) && physicalHealth) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealth: {
					message: 'You must select physical health concern.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealth: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.PhysicalHealth?.message) {
			validatePhysicalHealth();
		}
	}, [physicalHealth, physicalHealthVal]);

	const validateBehaviourOther = () => {
		if (behaviourConcern && behaviourConcernVal.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				BehaviourOther: {
					message: "You must provide more information with horse's behaviour."
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				BehaviourOther: {
					message: null
				}
			}));
		}
	};

	const validatePhysicalHealthOther = () => {
		if (physicalHealthVal == 4 && physicalHealthConcern.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealthOther: {
					message: 'You must enter the other concern.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				PhysicalHealthOther: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.PhysicalHealthOther?.message) {
			validatePhysicalHealthOther();
		}
	}, [physicalHealthConcern, physicalHealthVal]);

	useEffect(() => {
		AppSyncService.execute(getListOfHabitsAndVices).then((result) => {
			setViceOptions(extractLookUpOptions(result, 'listHabitsAndVices'));
		});
	}, []);

	useEffect(() => {
		const payload = {
			horseCode: horseCode,
			memberExternalId: currentUser?.UserId
		};
		setIsLoadingForm(true);
		AppSyncService.execute(getFosterInitialAssessment, payload).then((data) => {
			if (data.data != null) {
				let initialData = data.data.getFosterInitialAssessmentForm;
				if (initialData == null) setIsShowErrorMessages(false);
				else setIsShowErrorMessages(true);

				updateDate(
					initialData.dateOfAssessment,
					setDateOfAssessment,
					validateAssessmentDate
				);
				setLeftSidePhotos(initialData.leftSidePhotos);
				setRightSidePhotos(initialData.rightSidePhotos);
				setAdditionalPhotos(initialData.additionalPhotos ?? []);
				setHorseHasVices(initialData.hasHorseDisplayedVices);
				setHorseHandle(initialData.horseHandle ?? '');
				setOverallCondition(initialData.overallCondition ?? '');
				setWorming(!!initialData.worming);
				setFarrier(!!initialData.farrier);
				setDentist(!!initialData.dentist);
				updateDate(
					initialData.worming ? initialData.worming : null,
					setWormingDate,
					validateWormingDate
				);
				updateDate(
					initialData.farrier ? initialData.farrier : null,
					setFarrierDate,
					validateFarrierDate
				);
				updateDate(
					initialData.dentist ? initialData.dentist : null,
					setDentistDate,
					validateDentistDate
				);
				setOtherHusbandryInfo(initialData.otherHusbandryInfo ?? '');
				setFeedingPlan(initialData.feedingRequirementsPlan ?? '');
				setFeedingError(
					initialData.feedingRequirementsPlan ||
						initialData.feedingRequirementsPlan.length !== 0
						? ''
						: 'You must enter the initial feeding plan assessment.'
				);
				setTemperament(initialData.temperament ?? '');
				setTemperamentError(
					initialData.temperament || initialData.temperament.length !== 0
						? ''
						: 'You must enter the initial temperament assessment.'
				);
				if (initialData.habits.length > 0) {
					const mapHabits = initialData.habits.map((habit) => ({
						value: habit.id,
						label: habit.value
					}));
					setSelectedVices(mapHabits);
				}
				if (initialData.habitsOther.length > 0) {
					setHabitsOtherReadOnly(initialData.habitsOther);
					let splitOtherHabits = initialData.habitsOther.split(',');
					const mapHabitsOther = splitOtherHabits.map((otherHabit) => ({
						label: otherHabit,
						value: otherHabit
					}));
					setOtherVices(mapHabitsOther);
				}
				setPhysicalHealth(!!initialData.horsePhysicalHealth);
				setPhysicalHealthVal(initialData.horsePhysicalHealth ?? 0);
				setPhysicalHealthConcern(
					initialData.horsePhysicalHealthOtherConcern ?? ''
				);
				setBehaviourConcern(initialData.horseBehaviour.length !== 0);
				setBehaviourConcernVal(initialData.horseBehaviour);
				setFinalComments(initialData.finalComments);
				setIsReadOnly(
					initialData.status === REVIEW || initialData.status === APPROVED
				);
			}
			setIsRetirementDetailsLoaded(true);
			setIsLoadingForm(false);
		});
	}, []);

	useEffect(() => {
		if (isRetirementDetailsLoaded) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
	}, [isRetirementDetailsLoaded]);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const updateDate = (value, stateAssignmentFunction, validationFunction) => {
		var now = new Date();
		var dateDifference = now - value;
		if (dateDifference > 504910816000 && dateDifference < 3155839771896) {
			validationFunction(false);
		} else {
			validationFunction(true);
		}
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};

	const onSubmit = (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredSelectedVices = selectedVices.map(
			(selectedVice) => selectedVice.value
		);
		const input = {
			horseCode: horseCode,
			dateOfAssessment: dateOfAssessment,
			hasHorseDisplayedVices: horseHasVices,
			horseHandle: horseHandle,
			overallCondition: overallCondition,
			worming: wormingDate,
			farrier: farrierDate,
			dentist: dentistDate,
			otherHusbandryInfo: otherHusbandryInfo,
			feedingRequirementsPlan: feedingPlan,
			temperament: temperament,
			habits: filteredSelectedVices,
			habitsOther:
				otherVices.length === 0
					? ''
					: otherVices.map((item) => item.value).toString(),
			horsePhysicalHealthOtherConcern: physicalHealthConcern,
			horsePhysicalHealth: physicalHealthVal === 0 ? null : physicalHealthVal,
			horseBehaviour: behaviourConcernVal,
			finalComments: finalComments
		};
		setIsLoading(true);
		AppSyncService.execute(saveFosterInitialAssessmentForm, {
			input
		}).then((data) => {
			if (data.data !== null) {
				let photoArrays = [
					{ name: 'leftSide', arr: leftSidePhotos },
					{ name: 'rightSide', arr: rightSidePhotos },
					{ name: 'additional', arr: additionalPhotos }
				];
				photoArrays.forEach((photoArray, index) => {
					photoArray.arr.map((photo) => {
						AppSyncService.execute(saveResetReportingImage, {
							filename: photo.filename,
							key: photo.key,
							reportType: 'FosterInitial',
							reportId: data.data.saveFosterInitialAssessmentForm.id,
							type: photoArrays[index].name
						});
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					reload();
					addToast({ Message: 'Draft Saved', IsSuccess: true });
				} else {
					AppSyncService.execute(submitFosterInitialAssessmentForReview, {
						horseCode: horseCode
					}).then((data) => {
						if (data?.data?.submitAckFosterInitialAssessmentForReview) {
							addToast({
								Message: 'Initial Assessment Form Submitted',
								IsSuccess: true
							});
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
						}
					});
				}
			}
			if (!isSubmitReport) {
				if (!isValidationErrors) {
					setDisableSubmitbutton(false);
				}
			}
			setIsLoading(false);
		});
	};

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			onSubmit(true);
		}
	};

	const onClearConfirmationDialog = () => {
		setOpenClearConfirmationDiag(true);
	};

	const onCloseClearConfirmationDialog = (e, reason) => {
		e.preventDefault();
		if (reason && reason == 'backdropClick') return;
		setOpenClearConfirmationDiag(false);
	};

	const onRenderClearDialogContent = () => {
		setConfirmationDialogTitle('Clear Form');
		return (
			<>
				<>
					<DialogContentText className={classes.dialogTextColor}>
						Are you sure you want to clear the form?
					</DialogContentText>
				</>
			</>
		);
	};

	const onRenderActionButtons = () => {
		return (
			<>
				<PrimaryButton
					id={CommonKeys.YES}
					onClick={() => clearForm()}
					isNonSubmit
				>
					Yes
				</PrimaryButton>

				<PrimaryButton
					id={CommonKeys.CANCEL}
					style={{ backgroundColor: '#c62828' }}
					onClick={(e) => onCloseClearConfirmationDialog(e)}
					isNonSubmit
				>
					Cancel
				</PrimaryButton>
			</>
		);
	};

	const clearForm = () => {
		setDateOfAssessment(null);
		deleteAllPhotos();
		setHorseHasVices(false);
		setHorseHandle('');
		setOverallCondition('');
		setWorming(false);
		setFarrier(false);
		setDentist(false);
		setWormingDate(null);
		setFarrierDate(null);
		setDentistDate(null);
		setOtherHusbandryInfo('');
		setFeedingPlan('');
		setTemperament('');
		setSelectedVices([]);
		setOtherVices('');
		setPhysicalHealth(false);
		setPhysicalHealthVal(0);
		setPhysicalHealthConcern('');
		setBehaviourConcern(false);
		setBehaviourConcernVal('');
		setFinalComments('');
		setOpenClearConfirmationDiag(false);
	};

	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key !== img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
				setShouldValidate(true);
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[leftSidePhotos, rightSidePhotos, additionalPhotos].forEach(
			(photoArray) => {
				photoArray.map((d) => {
					listOfKeys.push(d.key);
				});
			}
		);
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setLeftSidePhotos([]);
					setRightSidePhotos([]);
					setAdditionalPhotos([]);
				}
			});
		}
	};

	return (
		<div className={classes.container}>
			{isLoadingForm ? (
				<LoaderSpinner
					status={isLoadingForm}
					styles={{ display: 'flex', justifyContent: 'center', padding: 20 }}
				/>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit()}>
						<div className={classes.formHeading}>Initial Assessment Form</div>
						<InfoBar
							type={MessageBarTypeEnum.info}
							infoText={infoText}
							noButton
						/>
						<Container>
							<Row>
								{isReadOnly ? (
									<Col>
										<FormLabelField
											title="Date of Assessmentss*"
											value={formatDateReadableVariant(dateOfAssessment)}
										/>
									</Col>
								) : (
									<Col xs={12} lg={4}>
										<>
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date of Assessment*
												</div>
												<FormDatePicker
													DateValue={dateOfAssessment}
													updateSelection={(value) => {
														updateDate(
															value,
															setDateOfAssessment,
															validateAssessmentDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorStyle}>
													{uncontrolledErrors?.AssessmentDate?.message}
												</span>
											)}
										</>
									</Col>
								)}
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Upload Left Side (jpg, png)*
										</div>
										<UploadPhoto
											id={`RESETInitial-leftSide-${index}`}
											formats={['png', 'jpeg']}
											labelName="Upload Left Side"
											error={uploadError}
											data={leftSidePhotos}
											sizeLimitMB={5}
											numberOfImages={5}
											success={(e) => photosUpload(e, setLeftSidePhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, leftSidePhotos, setLeftSidePhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorStyle}>
											{uncontrolledErrors?.leftSidePhotos?.message}
										</span>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											Upload Right Side (jpg, png)*
										</div>
										<UploadPhoto
											id={`RESETInitial-rightSide-${index}`}
											formats={['png', 'jpeg']}
											labelName="Upload Right Side"
											error={uploadError}
											data={rightSidePhotos}
											sizeLimitMB={5}
											numberOfImages={5}
											success={(e) => photosUpload(e, setRightSidePhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, rightSidePhotos, setRightSidePhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
									{isShowErrorMessages && (
										<span className={classes.errorStyle}>
											{uncontrolledErrors?.rightSidePhotos?.message}
										</span>
									)}
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Additional Photos</div>
										<UploadPhoto
											id={`RESETInitial-additionalPhotos-${index}`}
											formats={['png', 'jpeg']}
											labelName="Additional Photos"
											error={uploadError}
											data={additionalPhotos}
											sizeLimitMB={5}
											numberOfImages={5}
											success={(e) => photosUpload(e, setAdditionalPhotos)}
											getImage={getImage}
											removeImage={(e) =>
												removePhoto(e, additionalPhotos, setAdditionalPhotos)
											}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							{/* <Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Has the horse's status changed?`}
										</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setHorseStatusChanged(value);
												if (!value) {
													setSelectedNewStatus(0);
												}
												setShouldValidate(true);
											}}
											value={horseStatusChanged}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{!horseStatusChanged ? (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Current Status</div>

											<div className={classes.statusTitle}>
												{horseData?.currentStatus?.value}
											</div>
										</div>
									</Col>
								) : (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>New Status*</div>
											<FormDropDown
												items={newStatus}
												selectedId={selectedNewStatus}
												onChange={(e) => {
													setSelectedNewStatus(e.target.value);
													setShouldValidate(true);
												}}
												showBlank
												id={`formDropdown-${index}`}
												disabled={isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.HorseNewStatus?.message}
											</span>
										)}{' '}
									</Col>
								)}
							</Row> */}
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Describe the horse’s overall condition{' '}
													<span className={classes.fontWeightNormal}>
														(body condition, feet condition, note any
														superficial wounds/abrasions)
													</span>
												</div>
											}
											id={`ResizingTextArea-overallCondition-${index}`}
											onChange={(e) => {
												setOverallCondition(e.target.value);
											}}
											maxChars={700}
											value={overallCondition}
											titleStyles={{
												fontSize: 14
											}}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Worming</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setWorming(value);
												setShouldValidate(true);
											}}
											value={worming}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{worming && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion*"
														value={formatDateReadableVariant(wormingDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion*
												</div>
												<FormDatePicker
													DateValue={wormingDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setWormingDate,
															validateWormingDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.WormingDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Farrier</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setFarrier(value);
												setShouldValidate(true);
											}}
											value={farrier}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{farrier && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion/Booking*"
														value={formatDateReadableVariant(farrierDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion/Booking*
												</div>
												<FormDatePicker
													noMaxDate={true}
													DateValue={farrierDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setFarrierDate,
															validateFarrierDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.FarrierDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Dentist</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setDentist(value);
												setShouldValidate(true);
											}}
											value={dentist}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{dentist && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<Row>
												<Col>
													<FormLabelField
														title="Date of Completion/Booking*"
														value={formatDateReadableVariant(dentistDate)}
													/>
												</Col>
											</Row>
										) : (
											<div className={classes.fieldContainer}>
												<div className={classes.fieldTitle}>
													Date Of Completion/Booking*
												</div>
												<FormDatePicker
													noMaxDate={true}
													DateValue={dentistDate}
													updateSelection={(value) => {
														updateDate(
															value,
															setDentistDate,
															validateDentistDate
														);
														setShouldValidate(true);
													}}
												/>
											</div>
										)}

										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.DentistDate?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Other Husbandry Information{' '}
													<span className={classes.fontWeightNormal}>
														(include possible requirements in future)
													</span>
												</div>
											}
											id={`ResizingTextArea-otherHusbandryInfo-${index}`}
											onChange={(e) => setOtherHusbandryInfo(e.target.value)}
											maxChars={500}
											value={otherHusbandryInfo}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Assessment of feeding requirements/plan{' '}
													<span className={classes.fontWeightNormal}>
														(including whether the horse is currently eating
														well)
													</span>
													*
												</div>
											}
											id={`ResizingTextArea-feedingPlan-${index}`}
											onChange={(e) => {
												setFeedingPlan(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={feedingPlan}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{feedingError && isShowErrorMessages && (
											<ValidationErrorText errorText={feedingError} />
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title={
												<div>
													Assessment of temperament/behaviour{' '}
													<span className={classes.fontWeightNormal}>
														(nervous/relaxed/aggressive/sensitive)
													</span>
													*
												</div>
											}
											id={`ResizingTextArea-temperament-${index}`}
											onChange={(e) => {
												setTemperament(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={temperament}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
										{temperamentError && isShowErrorMessages && (
											<ValidationErrorText errorText={temperamentError} />
										)}
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<ResizingTextArea
											title="How has the horse been to handle?"
											id={`ResizingTextArea-horseHandle-${index}`}
											onChange={(e) => {
												setHorseHandle(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											value={horseHandle}
											titleStyles={{ fontSize: 14 }}
											canEdit={!isReadOnly}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Has the horse displayed any vices?`}
										</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setHorseHasVices(value);
												if (!value) {
													setSelectedVices([]);
												}
												setShouldValidate(true);
											}}
											value={horseHasVices}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{horseHasVices && (
									<Col xs={12}>
										{isReadOnly ? (
											<div style={{ padding: '10px 0px 10px 0px' }}>
												<FormLabelField
													title={`Select vices or bad habits that ${horseData?.horseName} has`}
													value={selectedVices
														.map((selectedHabit) => selectedHabit.label)
														.join()}
													style={{ padding: 0 }}
												/>
											</div>
										) : (
											<div className={classes.fieldContainer}>
												<MultiSelectInputField
													idPrefix={`vices`}
													fieldLabel={`Select vices or bad habits that ${horseData?.horseName} has`}
													selectedIds={selectedVices}
													options={viceOptions}
													onSelectChange={(newVices) => {
														setSelectedVices(newVices);
														if (
															!newVices.find(({ label }) => label === 'Other')
														) {
															setOtherVices('');
														}
													}}
													otherValue={otherVices}
													onOtherChange={(newOtherValue) => {
														setOtherVices(newOtherValue);
													}}
												/>
											</div>
										)}
									</Col>
								)}
								{isReadOnly && (
									<Col xs={12}>
										<FormLabelField
											title="Other"
											value={habitsOtherReadOnly}
											style={{ padding: 0 }}
										/>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											{`Are there any concerns with the horse's physical health?`}
										</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setPhysicalHealth(value);
												if (!value) {
													setPhysicalHealthVal('');
												}
												setShouldValidate(true);
											}}
											value={physicalHealth}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{physicalHealth && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												{`Please identify the concern `}
												<span className={classes.fontWeightNormal}>
													(select other if there are multiple)
												</span>
												*
											</div>

											<FormDropDown
												items={physicalHealthItems}
												selectedId={physicalHealthVal}
												onChange={(e) => {
													setPhysicalHealthVal(e.target.value);
													setShouldValidate(true);
												}}
												showBlank
												id={`formDropdown-${index}`}
												disabled={isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorStyle}>
												{uncontrolledErrors?.PhysicalHealth?.message}
											</span>
										)}
									</Col>
								)}
							</Row>
							{physicalHealthVal == 4 && (
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<Controller
												control={control}
												name="PhysicalHealthOtherConcern"
												render={({ onChange }) => (
													<ResizingTextArea
														title="Other Concern*"
														titleStyles={{ fontSize: '14px' }}
														id={`ResizingTextArea-physicalHealthOtherConcern-${index}`}
														onChange={(e) => {
															onChange(e.target.value);
															setPhysicalHealthConcern(e.target.value);
															setShouldValidate(true);
														}}
														maxChars={500}
														value={physicalHealthConcern}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorStyle}>
													{uncontrolledErrors?.PhysicalHealthOther?.message}
												</span>
											)}
										</div>
									</Col>
								</Row>
							)}
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div
											className={classes.fieldTitle}
										>{`Are there any concerns with the horse's behaviour?`}</div>
										<Toggle
											labelNegative="No"
											labelPositive="Yes"
											onClick={(value) => {
												setBehaviourConcern(value);
												if (!value) {
													setBehaviourConcernVal('');
												}
												setShouldValidate(true);
											}}
											value={behaviourConcern}
											disabled={isReadOnly}
										/>
									</div>
								</Col>
								{behaviourConcern && (
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<Controller
												control={control}
												name="BehaviourConcern"
												render={({ onChange }) => (
													<ResizingTextArea
														title="Please provide more information*"
														id={`ResizingTextArea-behaviourConcern-${index}`}
														onChange={(e) => {
															onChange(e.target.value);
															setBehaviourConcernVal(e.target.value);
															setShouldValidate(true);
														}}
														maxChars={700}
														value={behaviourConcernVal}
														titleStyles={{ fontSize: '14px' }}
														canEdit={!isReadOnly}
													/>
												)}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorStyle}>
													{uncontrolledErrors?.BehaviourOther?.message}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<Controller
											control={control}
											name="FinalComments"
											render={({ onChange }) => (
												<ResizingTextArea
													title="Final Comments"
													id={`ResizingTextArea-finalComments-${index}`}
													onChange={(e) => {
														onChange(e.target.value);
														setFinalComments(e.target.value);
													}}
													maxChars={700}
													value={finalComments}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											)}
										/>
									</div>
								</Col>
							</Row>

							<hr className={classes.divider} />
							{isLoading ? (
								<LoaderSpinner status={isLoading} styles={{ marginTop: 20 }} />
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: `${
											isReadOnly ? 'flex-end' : 'space-between'
										}`,
										alignItems: 'center'
									}}
								>
									{!isReadOnly && (
										<div
											onClick={() => onClearConfirmationDialog()}
											className={classes.clearFormLink}
										>
											Clear Form
										</div>
									)}

									<div
										className={
											isSmallScreen
												? classes.saveRequestMobile
												: classes.saveRequest
										}
									>
										<span className={isSmallScreen ? null : classes.saveButton}>
											<PrimaryButton
												id={`Save-Primary-Button-${index}`}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												onClick={() => onSubmit()}
												disabled={isReadOnly}
											>
												Save
											</PrimaryButton>
										</span>
										<span>
											<PrimaryButton
												id={`Submit-Primary-Button-${index}`}
												isNonSubmit={false}
												style={{ minWidth: '170px' }}
												disabled={disableSubmitButton || isReadOnly}
												onClick={() => handleOnSubmit()}
											>
												Submit Report
											</PrimaryButton>
										</span>
									</div>
								</div>
							)}
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
						{showImageUploadErrorModal && (
							<UploadPhotoErrorModal
								showErrorModal={showImageUploadErrorModal}
								closeModal={() => setShowImageUploadErrorModal(false)}
								isMinimumUploadSize={isMinimumUploadSize}
							/>
						)}
					</form>
					<Dialog
						openDialog={openClearConfirmationDiag}
						handleClose={onCloseClearConfirmationDialog}
						title={confirmationDialogTitle}
						contentChildren={onRenderClearDialogContent}
						actionButtons={onRenderActionButtons}
					/>
				</div>
			)}
		</div>
	);
};

export default AcknowledgedFosterInitialAssessmentForm;

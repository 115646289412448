import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import useStyles from './styles';
import FormDropDown from '../FormDropDown';
import { BLANK_OPTION } from '../../utils/constants';
import AppSyncService from '../../graphql/AppSyncService';
import { listPrimaryMemeberships } from '../../graphql/queries';
import { getRoleList, searchUsers } from '../../graphql/custom.queries';
import SearchField from '../SearchField';
import ResultColumn from './ResultColumn';
import ResultColumnHeader from './ResultColumnHeader';
import LoaderSpinner from '../LoaderSpinner';
import { navigate } from '@reach/router';

const ID_PREFIX = 'admin-user-search';

const ResultRow = ({
	memberId,
	name,
	email,
	suburb,
	primaryRole,
	horsesClaimed
}) => {
	const classes = useStyles();
	return (
		<div
			className={classes.searchResultRow}
			onClick={() => navigate(`/member/${memberId}`)}
		>
			<ResultColumn>
				<strong>{name}</strong>
			</ResultColumn>
			<ResultColumn>{email}</ResultColumn>
			<ResultColumn>{suburb}</ResultColumn>
			<ResultColumn>{primaryRole}</ResultColumn>
			<ResultColumn>{horsesClaimed}</ResultColumn>
		</div>
	);
};

const UserSearchHeader = () => {
	const classes = useStyles();
	return (
		<Row>
			<div className={classes.userSearchHeader}>
				<h2 className={classes.userSearchTitle}>Users</h2>
			</div>
		</Row>
	);
};

const NonResultContentContainer = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.nonResultContentContainer}>{children}</div>;
};

const SearchResultsTable = ({
	searchResults,
	searchMessage,
	isLoadingData
}) => {
	const classes = useStyles();
	return (
		<>
			<Row>
				<div className={classes.searchResultsHeaderContainer}>
					<ResultColumnHeader>Full Name</ResultColumnHeader>
					<ResultColumnHeader>Email</ResultColumnHeader>
					<ResultColumnHeader>Suburb</ResultColumnHeader>
					<ResultColumnHeader>Primary Role</ResultColumnHeader>
					<ResultColumnHeader>Claimed Horses</ResultColumnHeader>
				</div>
			</Row>
			<Row>
				<div className={classes.searchResultsRowContainer}>
					{searchResults.length > 0 ? (
						<div className={classes.scrollableContainer}>
							{searchResults.map(
								({
									id,
									firstName,
									lastName,
									email,
									residentialAddress: { suburb },
									primaryMembershipType: { name: primaryRole },
									claims
								}) => (
									<ResultRow
										key={id}
										memberId={id}
										name={`${firstName} ${lastName}`}
										email={email}
										suburb={suburb || ''}
										primaryRole={primaryRole}
										horsesClaimed={claims.length}
									/>
								)
							)}
						</div>
					) : (
						<NonResultContentContainer>
							{(isLoadingData && <LoaderSpinner status={true} />) ||
								searchMessage ||
								'No results matching search criteria'}
						</NonResultContentContainer>
					)}
				</div>
			</Row>
		</>
	);
};

const UserSearchDropdownField = ({ title, options, value, onChange, id }) => {
	return (
		<Row style={{ padding: '0px 15px 15px 15px' }} align="center">
			<Col lg={2} sm={12}>
				<label>{title}</label>
			</Col>
			<Col lg={10} sm={12}>
				<FormDropDown
					id={id}
					items={[BLANK_OPTION, ...options]}
					selectedId={value}
					onChange={onChange}
					showBlank
				/>
			</Col>
		</Row>
	);
};

const UserSearch = () => {
	const [nameInputValue, setNameInputValue] = useState('');
	const [
		primaryUserTypeFilterOptions,
		setPrimaryUserTypeFilterOptions
	] = useState([]);
	const [primaryUserTypeFilterValue, setPrimaryUserTypeFilterValue] = useState(
		BLANK_OPTION.id
	);
	const [
		adminAssignedRoleFilterOptions,
		setAdminAssignedRoleFilterOptions
	] = useState([]);
	const [
		adminAssignedRoleFilterValue,
		setAdminAssignedRoleFilterValue
	] = useState(BLANK_OPTION.id);
	const [searchResults, setSearchResults] = useState([]);
	const [searchMessage, setSearchMessage] = useState(
		'Please enter search criteria'
	);
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();

	const fetchPrimaryUserTypes = async () => {
		const result = await AppSyncService.execute(listPrimaryMemeberships);
		if (result?.data?.listPrimaryMemeberships?.length) {
			const sortedPrimaryMembershipOptions = result.data.listPrimaryMemeberships
				.map(({ id, name }) => ({
					id,
					value: name
				}))
				.sort((a, b) =>
					a.value.toUpperCase().localeCompare(b.value.toUpperCase())
				);
			setPrimaryUserTypeFilterOptions(sortedPrimaryMembershipOptions);
		}
	};

	const fetchAdminAssignedRoles = async () => {
		const result = await AppSyncService.execute(getRoleList);
		if (result?.data?.listRoles?.length) {
			const sortedAdminAssigedRoleOptions = result.data.listRoles
				.map(({ id, name }) => ({
					id,
					value: name
				}))
				.sort((a, b) =>
					a.value.toUpperCase().localeCompare(b.value.toUpperCase())
				);
			setAdminAssignedRoleFilterOptions(sortedAdminAssigedRoleOptions);
		}
	};

	useEffect(() => {
		fetchPrimaryUserTypes();
		fetchAdminAssignedRoles();
	}, []);

	const validateSearchInput = () => {
		let isValidSearch = true;
		if (
			!nameInputValue.trim() &&
			primaryUserTypeFilterValue == BLANK_OPTION.id &&
			adminAssignedRoleFilterValue == BLANK_OPTION.id
		) {
			isValidSearch = false;
		}
		return isValidSearch;
	};

	const onSearchClick = () => {
		setIsLoading(true);
		setSearchResults([]);
		const validSearchInput = validateSearchInput();
		if (validSearchInput) {
			const input = {
				...(nameInputValue.trim() && { name: nameInputValue.trim() }),
				...(+primaryUserTypeFilterValue !== BLANK_OPTION.id && {
					primaryRole: primaryUserTypeFilterValue
				}),
				...(+adminAssignedRoleFilterValue !== BLANK_OPTION.id && {
					adminAssignedRole: adminAssignedRoleFilterValue
				})
			};
			AppSyncService.execute(searchUsers, {
				input
			}).then((result) => {
				if (result?.data?.findUsers) {
					setSearchResults(result.data.findUsers);
					setSearchMessage('');
				}
				setIsLoading(false);
			});
		} else {
			setSearchResults([]);
			setSearchMessage('Please ensure that search criteria is valid');
			setIsLoading(false);
		}
	};

	const handleNameChange = (newNameValue) => setNameInputValue(newNameValue);

	const handlePrimaryUserTypeChange = (e) =>
		setPrimaryUserTypeFilterValue(e.target.value);

	const handleAdminAssignedRoleChange = (e) =>
		setAdminAssignedRoleFilterValue(e.target.value);
	return (
		<>
			<UserSearchHeader />
			<Row>
				<div className={classes.searchInputContainer}>
					<Row style={{ padding: 15 }} align="center">
						<Col lg={2} sm={12}>
							<label>Search by Name</label>
						</Col>
						<Col lg={10} sm={12}>
							<SearchField
								onInputChange={handleNameChange}
								inputValue={nameInputValue}
								keyCheck={(key) => {
									if (key === 13) {
										// Enter key pressed
										onSearchClick();
									}
								}}
								onSearchClick={onSearchClick}
							/>
						</Col>
					</Row>
					<UserSearchDropdownField
						id={`${ID_PREFIX}-role-dropdown`}
						title="Primary User Type"
						options={primaryUserTypeFilterOptions}
						value={primaryUserTypeFilterValue}
						onChange={handlePrimaryUserTypeChange}
					/>
					<UserSearchDropdownField
						id={`${ID_PREFIX}-admin-assigned-role-dropdown`}
						title="Admin Assigned Role"
						options={adminAssignedRoleFilterOptions}
						value={adminAssignedRoleFilterValue}
						onChange={handleAdminAssignedRoleChange}
					/>
				</div>
			</Row>
			{searchResults.length > 0 ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						fontWeight: 600,
						fontSize: 15
					}}
				>
					{searchResults.length}{' '}
					{`result${searchResults.length == 1 ? '' : 's'}`} found
				</div>
			) : null}
			<SearchResultsTable
				searchResults={searchResults}
				searchMessage={searchMessage}
				isLoadingData={isLoading}
			/>
		</>
	);
};

export default UserSearch;

export { ResultRow, UserSearchHeader, NonResultContentContainer };

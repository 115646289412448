import { createUseStyles } from "react-jss";
import Tick from "../../assets/icons/Light_Check_Validated_icon.svg";
import Cross from "../../assets/icons/Light_Cross_Not_Validated_icon.svg";

export default createUseStyles(() => ({
  fieldEntry: {
    float: "left",
    height: "39px",
    borderRadius: "4px",
    border: "1px solid #c7ced1",
    width: "100%",
    paddingLeft: 16,
    fontSize: 15,
    boxSizing: "border-box",
    "&:focus": {
      outline: "none",
    },
  },
  fieldIcon: {
    "&:after": {
      pointerEvents: "none",
      marginTop: 7,
      verticalAlign: 'middle'
    },
  },
  fieldValid: {
    "&:after": {
      content: `url(${Tick})`,
    },
  },
  fieldNotValid: {
    "&:after": {
      content: `url(${Cross})`,
    },
  },
  fieldEditable: {
    "&:after": {
      position: "absolute",
      right: 25,
    },
  },
  fieldNonEditable: {
    "&:after": {
      paddingLeft: 10,
    },
  },
}));

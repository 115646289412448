import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	photoHelpMessageContainer: {
		backgroundColor: '#dcebf3',
		padding: 20,
		borderRadius: 5,
		fontSize: 15,
		color: '#464749',
		width: '100%',
		boxSizing: 'border-box',
		marginBottom: 15
	},
	photoHelpLink: {
		textDecoration: 'none',
		color: '#006da6',
		fontWeight: 600
	}
}));

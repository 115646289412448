import React from 'react';
import { Row, Col } from 'react-grid-system';
import useStyles from './styles';

const AdminReviewBody = ({ children }) => {
	const classes = useStyles();
	return (
		<Row>
			<Col className={classes.bodyContent}>{children}</Col>
		</Row>
	);
};

export default AdminReviewBody;

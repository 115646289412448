import { convertBlankOptionToNull } from '../../utils/helpers';

export const sortLookupArrayAlphabeticallyWithOtherAtEnd = (arr) => {
	const result = arr.sort((a, b) => {
		const valA = a.value.toLowerCase();
		const valB = b.value.toLowerCase();
		if (valA < valB) return -1;
		if (valA > valB) return 1;
		return 0;
	});
	return [
		...result.filter(({ value }) => value !== 'Other'),
		result.find(({ value }) => value === 'Other')
	];
};

export const generatePayload = (values, formId) => {
	const result = { ohepNominationId: +formId };
	result.adminExternalId = values.adminExternalId;
	result.adminStatus = convertBlankOptionToNull(values.adminStatus);
	result.formComplete = values.formComplete;
	result.ewdRecommendation = values.ewdRecommendation;
	result.ewdRecommendationDate = values.ewdRecommendationDate;
	result.ewdComments = values.ewdComments;
	result.eligibilityReason = convertBlankOptionToNull(values.eligibilityReason);
	result.primaryConcern = convertBlankOptionToNull(values.primaryConcern);
	result.primaryConcernOther =
		result.primaryConcern !== 4 ? null : values.primaryConcernOther;
	result.hasInjuryIdentified =
		result.primaryConcern !== 3 ? null : values.hasInjuryIdentified;
	result.hasInjuryPreventedCareer =
		result.primaryConcern !== 3 ? null : values.hasInjuryPreventedCareer;
	result.dateInjuryReported =
		result.primaryConcern !== 3 && !values.hasInjuryIdentified
			? null
			: values.dateInjuryReported;
	result.nominationCategory = convertBlankOptionToNull(
		values.nominationCategory
	);
	result.ewdFinalComments = values.ewdFinalComments;
	result.ewgmApprovalToProceed = values.ewgmApprovalToProceed;
	result.ewgmComments = values.ewgmComments;
	result.adminNotes = values.adminNotes;
	if (result.eligibilityReason === 4) {
		result.illness = convertBlankOptionToNull(values.illness);
		result.illnessOther = result.illness !== 7 ? null : values.illnessOther;
		result.injury = convertBlankOptionToNull(values.injury);
		result.injuryOther = result.injury !== 12 ? null : values.injuryOther;
	} else {
		result.illness = null;
		result.illnessOther = null;
		result.injury = null;
		result.injuryOther = null;
	}
	if (result.eligibilityReason === 2) {
		result.rehomingAttemptsSatisfactory = values.rehomingAttemptsSatisfactory;
		result.rehomingAttemptsComments = values.rehomingAttemptsComments;
	} else {
		result.rehomingAttemptsSatisfactory = null;
		result.rehomingAttemptsComments = null;
	}
	result.bodyParts = values.bodyParts.map((item) => +item.id);
	if (result.bodyParts.includes(9)) {
		result.bodyPartOther = values.bodyPartOther;
	}
	result.addEwgmSignatureOnAcceptanceNotice = result.ewgmApprovalToProceed
		? values.addEwgmSignatureOnAcceptanceNotice
		: false;
	result.automaticallySendAcceptanceNoticeToOwner =
		result.ewgmApprovalToProceed && result.addEwgmSignatureOnAcceptanceNotice
			? values.automaticallySendAcceptanceNoticeToOwner
			: false;
	result.ewgmApprovalToProceedDate = result.ewgmApprovalToProceed
		? values.ewgmApprovalToProceedDate
		: null;
	return result;
};

export const setFormValuesFromApiResult = (resultValues, setValue) => {
	setValue(
		'addEwgmSignatureOnAcceptanceNotice',
		resultValues?.addEwgmSignatureOnAcceptanceNotice
	);
	setValue('adminExternalId', resultValues?.adminAction?.admin?.externalId);
	setValue('adminStatus', resultValues?.adminAction?.adminStatus?.id);
	setValue('formComplete', resultValues?.adminAction?.formComplete);
	setValue('adminNotes', resultValues?.adminNotes);
	setValue(
		'automaticallySendAcceptanceNoticeToOwner',
		resultValues?.automaticallySendAcceptanceNoticeToOwner
	);
	setValue('eligibilityReason', resultValues?.eligibilityReason?.id);
	setValue('ewdComments', resultValues?.ewdComments);
	setValue('ewdFinalComments', resultValues?.ewdFinalComments);
	setValue('ewdRecommendation', resultValues?.ewdRecommendation);
	setValue('ewdRecommendationDate', resultValues?.ewdRecommendationDate);
	setValue('ewgmApprovalToProceed', resultValues?.ewgmApprovalToProceed);
	setValue(
		'ewgmApprovalToProceedDate',
		resultValues?.ewgmApprovalToProceedDate
	);
	setValue('ewgmComments', resultValues?.ewgmComments);
	setValue('illness', resultValues?.illness?.id);
	setValue('illnessOther', resultValues?.illnessOther);
	setValue('injury', resultValues?.injury?.id);
	setValue('injuryOther', resultValues?.injuryOther);
	setValue('nominationCategory', resultValues?.nominationCategory?.id);
	setValue(
		'bodyParts',
		resultValues?.bodyParts.map((item) => ({
			...item,
			label: item.value
		}))
	);

	setValue('bodyPartOther', resultValues?.bodyPartOther);
	setValue('primaryConcern', resultValues?.primaryConcern?.id);
	setValue('primaryConcernOther', resultValues?.primaryConcernOther);
	setValue('dateInjuryReported', resultValues?.dateInjuryReported);
	setValue('hasInjuryIdentified', resultValues?.hasInjuryIdentified ?? true);
	setValue('hasInjuryPreventedCareer', resultValues?.hasInjuryPreventedCareer);
	setValue('rehomingAttemptsComments', resultValues?.rehomingAttemptsComments);
	setValue(
		'rehomingAttemptsSatisfactory',
		resultValues?.rehomingAttemptsSatisfactory
	);
	setValue('vetCertOrOtherFiles', resultValues?.vetCertOrOtherFiles);
	setValue('xRayFiles', resultValues?.xRayFiles);
	setValue('brandImages', resultValues?.brandImages);
};

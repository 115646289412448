import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { OTTNewsLink } from '../Mobile';
import { formatDateReadable } from '../../utils/helpers';

const INITIAL_ARTICLES = 5;
const currentDate = new Date();
let articles = [];

const OTTNewsHomePage = ({ mobile }) => {
	const classes = useStyles();
	const [newsArticles, setNewsArticles] = useState([]);
	const [counter] = useState(INITIAL_ARTICLES);
	const contentfulArticles = useStaticQuery(
		graphql`
			query ottHomePage {
				allContentfulArticle(
					filter: { tags: { in: "News Feed" } }
					sort: { fields: publishTime, order: DESC }
				) {
					edges {
						node {
							id
							title
							summary
							publishTime
							previewImage {
								fluid(quality: 100) {
									src
								}
							}
						}
					}
				}
			}
		`
	);

	useEffect(() => {
		articles = contentfulArticles.allContentfulArticle.edges.filter((a) => {
			var publishTime = new Date(a.node.publishTime);
			return publishTime.getTime() <= currentDate.getTime();
		});

		setNewsArticles(articles.slice(0, counter));
	}, []);

	const goToArticle = (id) => {
		navigate(`/ott-news/${id}`);
	};

	const RenderArticles = () => {
		return (
			<div>
				<p
					style={{
						fontSize: 20,
						color: '#464749',
						fontWeight: 'bold',
						marginLeft: mobile ? 15 : 0
					}}
				>
					OTT NEWS
				</p>
				{newsArticles.length > 0 && (
					<SectionArticle article={newsArticles[0]} isLeader />
				)}
				{newsArticles.length > 2 && (
					<div
						className={
							mobile ? classes.mobileContainer : classes.desktopContainer
						}
					>
						<div
							className={
								mobile ? classes.mobileSegment : classes.desktopSegment
							}
						>
							<SectionArticle article={newsArticles[1]} alignLeft={!mobile} />
						</div>
						<div
							className={
								mobile ? classes.mobileSegment : classes.desktopSegment
							}
						>
							<SectionArticle article={newsArticles[2]} alignRight={!mobile} />
						</div>
					</div>
				)}
				<div style={{ marginTop: '20px' }}>
					<NewsStubs news={newsArticles[3]} />
					<NewsStubs news={newsArticles[4]} />
					<NewsStubs news={newsArticles[5]} />
				</div>
			</div>
		);
	};

	const NewsStubs = ({ news }) => {
		return (
			<div>
				{news !== undefined && (
					<OTTNewsLink
						isMobile
						onClick={() => goToArticle(news.node.id)}
						key={news.node.id}
						heading={news.node.title}
						date={formatDateReadable(news.node.publishTime)}
						imageLink={news.node.previewImage?.fluid?.src}
					/>
				)}
			</div>
		);
	};

	const Title = ({ isLeader, children }) => {
		return (
			<div
				className={classes.title}
				style={{ fontSize: !mobile && isLeader ? 20 : 12 }}
			>
				{children}
			</div>
		);
	};
	const Content = ({ isLeader, children }) => {
		return (
			<div
				className={classes.content}
				style={{ fontSize: !mobile && isLeader ? 15 : 12 }}
			>
				{children}
			</div>
		);
	};

	const SectionArticle = ({ article, isLeader, alignLeft, alignRight }) => {
		return (
			<div onClick={() => goToArticle(article.node.id)}>
				<div
					className={mobile ? classes.heroImageMobile : classes.heroImage}
					style={{
						backgroundImage:
							article !== null
								? `url(${article.node.previewImage.fluid.src})`
								: '',
						minHeight: mobile || isLeader ? '400px' : '225px',
						width: isLeader && !mobile ? '100%' : '98%',
						marginLeft: alignLeft ? 0 : 'auto',
						marginRight: alignRight ? 0 : 'auto'
					}}
				>
					<div
						className={classes.heroText}
						style={{
							transform:
								isLeader || mobile
									? 'translate(0px, 255px)'
									: 'translate(0px, 125px)'
						}}
					>
						<div className={classes.mull}>
							{' '}
							{article && (
								<div>
									<div>
										<Title isLeader={isLeader}>{article.node.title}</Title>
										<Content isLeader={isLeader}>
											{article.node.summary}
										</Content>
										<PublishDate>{article.node.publishTime}</PublishDate>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	const PublishDate = ({ children }) => {
		let date = new Date(children);
		let monthNames = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		];

		let day = date.getDate();

		let monthIndex = date.getMonth();
		let monthName = monthNames[monthIndex];

		let year = date.getFullYear();

		let nicelyFormattedDate = `${day} ${monthName} ${year}`;

		return <div className={classes.datetime}>{nicelyFormattedDate}</div>;
	};

	return (
		<div className={classes.ottNewsMobileTopContainer}>
			<RenderArticles />
		</div>
	);
};

export default OTTNewsHomePage;

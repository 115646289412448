import React from 'react';
import useStyles from './styles';
import warningIcon from '../../assets/icons/Alert_icon.svg';

const FormLabelField = ({
	id,
	title,
	value,
	mobile,
	showWarning,
	styles,
	containsLink
}) => {
	const classes = useStyles();
	const renderField = () => {
		if (mobile) {
			return (
				<div className={classes.fieldContainer} style={styles}>
					<div className={classes.fieldTitleMobile}>{title}</div>
					<span className={classes.fieldValueMobile}>{value}</span>
				</div>
			);
		} else if (containsLink) {
			return (
				<div className={classes.fieldContainer} style={styles}>
					<div className={classes.fieldTitle} style={styles}>
						<div dangerouslySetInnerHTML={{ __html: title }}></div>
					</div>
					<span id={id} className={classes.fieldValue}>
						{value}
					</span>
				</div>
			);
		} else {
			return (
				<div className={classes.fieldContainer} style={styles}>
					<div className={classes.fieldTitle} style={styles}>
						{title}
						{showWarning && (
							<img src={warningIcon} className={classes.warningIcon} />
						)}
					</div>
					<span id={id} className={classes.fieldValue}>
						{value}
					</span>
				</div>
			);
		}
	};
	return renderField();
};

export default FormLabelField;

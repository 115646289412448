import React, { useState, useEffect } from 'react';
import useStyles from './style';
import AppSyncService from '../../graphql/AppSyncService';
import { Container, Row, Col, useScreenClass } from 'react-grid-system';
import FormDropDown from '../FormDropDown';
import Lightbox from 'react-image-lightbox';
import Toggle from '../Toggle';
import PrimaryButton from '../PrimaryButton';
import FormDatePicker from '../FormDatePicker';
import { useForm } from 'react-hook-form';
import LoaderSpinner from '../LoaderSpinner';
import ResizingTextArea from '../ResizingTextArea';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import ConfirmationModal from '../ConfirmationModal';
import MultiSelectInputField from '../FormComponents/MultiSelectInputField';
import {
	formatDate,
	extractLookUpOptionsOther,
	formatDateReadableVariant,
	RESETWeeksAvailable,
	sortArray,
	checkIsSmallScreen
} from '../../utils/helpers';
import {
	saveFosterWeeklyAssessmentForm,
	getFosterWeeklyAssessmentForm,
	saveResetReportingImage,
	getUserImage,
	uploadImageToS3,
	deleteImageFromS3,
	deleteImagesFromS3,
	getMemberData,
	submitAckFosterWeeklyAssessmentForReview,
	getAcknowledgedFosterWeeklyAssessmentReferences,
	saveDeceasedThoroughbredForm,
	unclaimHorse
} from '../../graphql/custom.queries';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import { logToServer } from '../../utils/logger';
import FormToolTip from '../FormToolTip';
import axios from 'axios';
import { REVIEW, APPROVED, CommonKeys } from '../../utils/constants/index';
import FormLabelField from '../FormLabelField';
import { HorseManagementKeys } from './../../utils/constants/index';
import MessageBar from '../MessageBar';
import FormTextField from '../FormTextField';
import useDebounce from '../../hooks/useDebounce';

const reasonForRehab = {
	INJURY: 1,
	ILLNESS: 2,
	OTHER: 3
};

const reasonForDeath = {
	INJURY: 1,
	ILLNESS: 2,
	UNSUITABLE: 3,
	OTHER: 4
};

const AcknowledgedFosterWeeklyAssessmentForm = ({
	horseData,
	horseCode,
	reload
}) => {
	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};
	const screenSize = useScreenClass();
	const isSmallScreen = checkIsSmallScreen(screenSize);
	const { currentUser } = useAuth();
	const classes = useStyles();
	const { handleSubmit } = useForm({
		mode: 'onSubmit'
	});
	const [confirmationModal, setConfirmationModal] = useState(false);

	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);
	const [weekId, setWeekId] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? 51
			: horseData?.lastSubmittedForm?.weekId
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [weekIdText, setWeekIdText] = useState(
		horseData?.lastSubmittedForm?.weekId > 50
			? +horseData.lastSubmittedForm.weekId + 1
			: 0
	);
	const [referenceRehabReasonList, setReferenceRehabReasonList] = useState([]);
	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [referencePriceBracketList, setReferencePriceBracketList] = useState(
		[]
	);

	const [
		referenceConcludedReasonList,
		setReferenceConcludedReasonList
	] = useState([]);
	const [referenceReasonOfDeathList, setReferenceReasonOfDeathList] = useState(
		[]
	);
	const [
		referenceUnsuitableReasonsList,
		setReferenceUnsuitableReasonsList
	] = useState([]);
	const [
		referenceDeceasedReasonOfDeathOtherList,
		setReferenceDeceasedReasonOfDeathOtherList
	] = useState([]);
	const [referencePurposeList, setReferencePurposeList] = useState([]);
	const [
		referenceRetainingPurposeList,
		setReferenceRetainingPurposeList
	] = useState([]);
	const [referenceInjuryList, setReferenceInjuryList] = useState([]);
	const [
		referenceAssistanceTypeList,
		setReferenceAssistanceTypeList
	] = useState([]);
	const [referenceIllnessList, setReferenceIllnessList] = useState([]);
	const [referenceNewStatusList, setReferenceNewStatusList] = useState([]);
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [currentUserMemberId, setCurrentUserMemberId] = useState(null);
	const [uncontrolledErrors, setUncontrolledErrors] = useState({});
	const [progressPhotos, setProgressPhotos] = useState([]);

	const [dateOfAssessment, setDateOfAssessment] = useState(null);
	const [hasHorseStatusChanged, setHasHorseStatusChanged] = useState(false);
	const [isShowErrorMessages, setIsShowErrorMessages] = useState(false);
	const [newStatus, setNewStatus] = useState(0);
	const [rehabReason, setRehabReason] = useState(0);
	const [concludedReason, setConcludedReason] = useState(0);
	const [injuryType, setInjuryType] = useState([]);
	const [assistanceType, setAssistanceType] = useState([]);
	const [illnessType, setIllnessType] = useState([]);
	const [priceBracket, setPriceBracket] = useState(0);
	const [spellingExpectedTimeframe, setSpellingExpectedTimeframe] = useState(
		null
	);
	const [postcode, setPostcode] = useState(null);
	const [email, setEmail] = useState(null);
	const [fullName, setFullName] = useState(null);
	const [selectedDisciplines, setSelectedDisciplines] = useState([]);
	const [purpose, setPurpose] = useState(0);
	const [purposeOther, setPurposeOther] = useState(null);
	const [unsuitableReason, setUnsuitableReason] = useState([]);
	const [unsuitableReasonOther, setUnsuitableReasonOther] = useState(null);
	const [rehabReasonOther, setRehabReasonOther] = useState(null);
	const [rehabDateCommenced, setRehabDateCommenced] = useState(null);
	const [horseProgressUpdate, setHorseProgressUpdate] = useState(null);
	const [horseIssuesEncountered, setHorseIssuesEncountered] = useState(null);
	const [feedingPlan, setFeedingPlan] = useState(null);
	const [physicalConcernDetails, setPhysicalConcernDetails] = useState(null);
	const [physicalConcerns, setPhysicalConcerns] = useState(null);
	const [isRetrainHorse, setIsRetrainHorse] = useState(null);
	const [isFullCircle, setIsFullCircle] = useState(null);
	const [vetTreatmentDetails, setVetTreatmentDetails] = useState(null);
	const [vetTreatment, setVetTreatment] = useState(null);
	const [otherConcerns, setOtherConcerns] = useState(null);
	const [deceasedDateOfDeath, setDeceasedDateOfDeath] = useState(null);
	const [dateSold, setDateSold] = useState(null);
	const [dateCommenced, setDateCommenced] = useState(null);
	const [deceasedReasonOfDeath, setDeceasedReasonOfDeath] = useState(0);
	const [deceasedInjuryDetailsOther, setDeceasedInjuryDetailsOther] = useState(
		''
	);
	const [selectedDisciplinesOther, setSelectedDisciplinesOther] = useState('');
	const [
		deceasedIllnessDetailsOther,
		setDeceasedIllnessDetailsOther
	] = useState('');
	const [
		deceasedReasonForDeathOther,
		setDeceasedReasonForDeathOther
	] = useState(0);
	const [disableSubmitButton, setDisableSubmitbutton] = useState(true);
	const [isReadOnly, setIsReadOnly] = useState(false);
	const [isFosterDetailsLoaded, setIsFosterDetailsLoaded] = useState(false);
	const [shouldValidate, setShouldValidate] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);

	const [hasHorseBeenInWorkThisWeek, setHasHorseBeenInWorkThisWeek] = useState(
		true
	);
	const [
		detailsOfWorkSessionThisWeek,
		setDetailsOfWorkSessionThisWeek
	] = useState(null);
	const [
		keyProgressAchievementsToDate,
		setKeyProgressAchievementsToDate
	] = useState(null);
	const [areaNeedExtraWorkAttention, setAreaNeedExtraWorkAttention] = useState(
		null
	);
	const [workPlanForTheComingWeek, setWorkPlanForTheComingWeek] = useState(
		null
	);
	const [
		hasHorseBeenInWorkThisWeekExplaination,
		setHasHorseBeenInWorkThisWeekExplaination
	] = useState(null);
	const [
		anyLamenessOrPhysicalHealthConcern,
		setAnyLamenessOrPhysicalHealthConcern
	] = useState(false);
	const [horseRequiredVetAttention, setHorseRequiredVetAttention] = useState(
		false
	);
	const [
		requiredVetAttentionExplaination,
		setRequiredVetAttentionExplaination
	] = useState(null);
	const [physicalHealthConcern, setPhysicalHealthConcern] = useState(null);
	const [dateFoundationTraining, setDateFoundationTraining] = useState(null);

	const debouncedWeekNumber = useDebounce(weekIdText, 700);
	let isValidationErrors = false;
	/* ------------------------------- validation ------------------------------- */
	const validatePostcode = () => {
		if (newStatus == 2 && concludedReason == 2 && !postcode) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				postcode: {
					message: 'You must enter a postcode.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				postcode: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.postcode?.message) {
			validatePostcode();
		}
	}, [postcode]);

	const validateAssistanceType = () => {
		if (
			newStatus == 2 &&
			concludedReason == 3 &&
			isFullCircle &&
			assistanceType.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				assistanceType: {
					message: 'You must select an assistance type.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				assistanceType: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.assistanceType?.message) {
			validateAssistanceType();
		}
	}, [assistanceType]);

	const validatePurposeOther = () => {
		if (
			newStatus == 2 &&
			concludedReason == 3 &&
			purpose == 6 &&
			!purposeOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				purposeOther: {
					message: 'You must enter other purpose.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				purposeOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.purposeOther?.message) {
			validatePurposeOther();
		}
	}, [purposeOther]);

	const validateDisciplinesOther = () => {
		if (
			newStatus == 2 &&
			concludedReason == 2 &&
			selectedDisciplines?.find((e) => e.value == 13) &&
			!selectedDisciplinesOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedDisciplinesOther: {
					message: 'You must enter other discipline.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedDisciplinesOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.selectedDisciplinesOther?.message) {
			validateDisciplinesOther();
		}
	}, [selectedDisciplinesOther]);

	const validateDisciplines = () => {
		if (
			newStatus == 2 &&
			concludedReason == 2 &&
			selectedDisciplines.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedDisciplines: {
					message: 'You must select a discipline.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				selectedDisciplines: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.selectedDisciplines?.message) {
			validateDisciplines();
		}
	}, [selectedDisciplines]);

	const validatePurpose = () => {
		if (
			newStatus == 2 &&
			(concludedReason == 2 || concludedReason == 3) &&
			(!purpose || purpose == 0)
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				purpose: {
					message: 'You must select a purpose.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				purpose: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.purpose?.message) {
			validatePurpose();
		}
	}, [purpose]);

	const validatePriceBracket = () => {
		if (
			newStatus == 2 &&
			concludedReason == 2 &&
			(!priceBracket || priceBracket == 0)
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				priceBracket: {
					message: 'You must select a price bracket.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				priceBracket: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.priceBracket?.message) {
			validatePriceBracket();
		}
	}, [priceBracket]);

	const validateWeekId = () => {
		if (!weekId || weekId == 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekId: {
					message: 'You must select a week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekId: { message: null }
			}));
		}

		if (weekId == 51 && (!weekIdText || isNaN(weekIdText))) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekIdText: {
					message: 'You must enter a week number (numeric only).'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				weekIdText: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (
			uncontrolledErrors?.weekId?.message ||
			uncontrolledErrors?.weekIdText?.message
		) {
			validateWeekId();
		}
	}, [weekId, debouncedWeekNumber]);

	const validateSpellingExpectedTimeframe = () => {
		if (newStatus == 3 && !spellingExpectedTimeframe) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				spellingExpectedTimeframe: {
					message:
						'You must enter expected timeframe for the Spelling and the reason.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				spellingExpectedTimeframe: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.spellingExpectedTimeframe?.message) {
			validateSpellingExpectedTimeframe();
		}
	}, [spellingExpectedTimeframe]);

	const validateFeedingPlan = () => {
		if ((newStatus == 1 || newStatus == 3) && !feedingPlan) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				feedingPlan: {
					message: 'You must enter feeding plan/requirements.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				feedingPlan: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.feedingPlan?.message) {
			validateFeedingPlan();
		}
	}, [feedingPlan]);

	const validatePhysicalConcernDetails = () => {
		if (
			(newStatus == 1 || newStatus == 3) &&
			physicalConcerns &&
			!physicalConcernDetails
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				physicalConcernDetails: {
					message: 'You must provide details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				physicalConcernDetails: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.physicalConcernDetails?.message) {
			validatePhysicalConcernDetails();
		}
	}, [physicalConcernDetails]);

	const validateVetTreatmentDetails = () => {
		if (
			(newStatus == 1 || newStatus == 3) &&
			vetTreatment &&
			!vetTreatmentDetails
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				vetTreatmentDetails: {
					message: 'You must provide details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				vetTreatmentDetails: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.vetTreatmentDetails?.message) {
			validateVetTreatmentDetails();
		}
	}, [vetTreatmentDetails]);

	const validateDateOfAssessment = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dateOfAssessment: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (!dateOfAssessment) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateOfAssessment: {
						message: 'You must select date of assessment.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateOfAssessment: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dateOfAssessment?.message) {
			validateDateOfAssessment();
		}
	}, [dateOfAssessment]);

	const validateNewStatus = () => {
		if (hasHorseStatusChanged && (!newStatus || newStatus == 0)) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				newStatus: {
					message: 'You must select the new status.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				newStatus: {
					message: null
				}
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.newStatus?.message) {
			validateNewStatus();
		}
	}, [hasHorseStatusChanged, newStatus]);

	const validateRehabReason = () => {
		if (newStatus == 1 && (!rehabReason || rehabReason == 0)) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReason: {
					message: 'You must select a reason for rehabilitation.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReason: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabReason?.message) {
			validateRehabReason();
		}
	}, [rehabReason]);

	const validateHorseProgressUpdate = () => {
		if (newStatus == 1 && !horseProgressUpdate) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horseProgressUpdate: {
					message: "You must enter an update on horse's progress."
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horseProgressUpdate: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.horseProgressUpdate?.message) {
			validateHorseProgressUpdate();
		}
	}, [horseProgressUpdate]);

	const validateHorseIssuesEncountered = () => {
		if (newStatus == 1 && !horseIssuesEncountered) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horseIssuesEncountered: {
					message: "You must enter any issues you've encountered this week."
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				horseIssuesEncountered: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.horseIssuesEncountered?.message) {
			validateHorseIssuesEncountered();
		}
	}, [horseIssuesEncountered]);

	const validateInjuryType = () => {
		if (
			hasHorseStatusChanged &&
			(newStatus == 1 || newStatus == 2) &&
			(rehabReason == 1 || deceasedReasonOfDeath == 1) &&
			injuryType.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				injuryType: {
					message: 'You must select injury type.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				injuryType: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.injuryType?.message) {
			validateInjuryType();
		}
	}, [injuryType]);

	const validateIllnessType = () => {
		if (
			hasHorseStatusChanged &&
			(newStatus == 1 || newStatus == 2) &&
			(rehabReason == 2 || deceasedReasonOfDeath == 2) &&
			illnessType.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				illnessType: {
					message: 'You must select illness type.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				illnessType: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.illnessType?.message) {
			validateIllnessType();
		}
	}, [illnessType]);

	const validateUnsuitableReason = () => {
		if (
			hasHorseStatusChanged &&
			newStatus == 2 &&
			concludedReason == 1 &&
			deceasedReasonOfDeath == 3 &&
			unsuitableReason.length === 0
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				unsuitableReason: {
					message: 'You must select Unsuitable for rehoming reason.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				unsuitableReason: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.unsuitableReason?.message) {
			validateUnsuitableReason();
		}
	}, [unsuitableReason]);

	const validateUnsuitableReasonOther = () => {
		if (
			hasHorseStatusChanged &&
			newStatus == 2 &&
			concludedReason == 1 &&
			deceasedReasonOfDeath == 3 &&
			unsuitableReason?.find((e) => e.value == 28) &&
			!unsuitableReasonOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				unsuitableReasonOther: {
					message: 'You must provide other unsuitable reason.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				unsuitableReasonOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.unsuitableReasonOther?.message) {
			validateUnsuitableReasonOther();
		}
	}, [unsuitableReason]);

	const validateRehabReasonOther = () => {
		if (hasHorseStatusChanged && rehabReason == 3 && !rehabReasonOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReasonOther: {
					message: 'You must provide other reason for rehabilitation.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabReasonOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabReasonOther?.message) {
			validateRehabReasonOther();
		}
	}, [rehabReasonOther]);

	const validateRehabDateCommenced = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				rehabDateCommenced: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (hasHorseStatusChanged && newStatus == 1 && !rehabDateCommenced) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					rehabDateCommenced: {
						message: 'You must enter rehabilitation date commenced.'
					}
				}));
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					rehabDateCommenced: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.rehabDateCommenced?.message) {
			validateRehabDateCommenced();
		}
	}, [rehabDateCommenced]);

	const validateConcludedReason = () => {
		if (newStatus == 2 && (!concludedReason || concludedReason == 0)) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				concludedReason: {
					message: 'You must select a reason for concluded.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				concludedReason: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.concludedReason?.message) {
			validateConcludedReason();
		}
	}, [concludedReason]);

	const validateDateCommenced = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dateCommenced: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (
				((newStatus == 2 && concludedReason == 3) || newStatus == 3) &&
				!dateCommenced
			) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateCommenced: {
						message: 'You must select the date commenced.'
					}
				}));
				3;
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateCommenced: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dateCommenced?.message) {
			validateDateCommenced();
		}
	}, [dateCommenced]);

	const validateDateFoundationTraining = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dateFoundationTraining: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if ((newStatus == 5 || newStatus == 4) && !dateFoundationTraining) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateFoundationTraining: {
						message: 'You must select the date foundation training commenced.'
					}
				}));
				3;
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateFoundationTraining: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dateFoundationTraining?.message) {
			validateDateFoundationTraining();
		}
	}, [dateFoundationTraining]);

	const validateDateSold = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				dateSold: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (newStatus == 2 && concludedReason == 2 && !dateSold) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateSold: {
						message: 'You must select the date sold.'
					}
				}));
				3;
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					dateSold: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.dateSold?.message) {
			validateDateSold();
		}
	}, [dateSold]);

	const validateDeceasedDateOfDeath = (isInvalidDate) => {
		if (isInvalidDate) {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedDateOfDeath: {
					message: 'Invalid Date Entered.'
				}
			}));
		} else {
			if (newStatus == 2 && concludedReason == 1 && !deceasedDateOfDeath) {
				isValidationErrors = true;
				setDisableSubmitbutton(true);
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					deceasedDateOfDeath: {
						message: 'You must select the deceased date.'
					}
				}));
				3;
			} else {
				setUncontrolledErrors((uncontrolledErrors) => ({
					...uncontrolledErrors,
					deceasedDateOfDeath: { message: null }
				}));
			}
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedDateOfDeath?.message) {
			validateDeceasedDateOfDeath();
		}
	}, [deceasedDateOfDeath]);

	useEffect(() => {
		if (isFosterDetailsLoaded && weekId != 0) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
		}
		setIsFosterDetailsLoaded(false);
	}, [isFosterDetailsLoaded]);

	useEffect(() => {
		if (shouldValidate) {
			validateUncontrolledErrors();
			if (isValidationErrors) setDisableSubmitbutton(true);
			else setDisableSubmitbutton(false);
			setShouldValidate(false);
		}
	}, [shouldValidate]);

	const validateDeceasedReasonOfDeath = () => {
		if (
			newStatus == 2 &&
			concludedReason == 1 &&
			(!deceasedReasonOfDeath || deceasedReasonOfDeath == 0)
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonOfDeath: {
					message: 'You must select the reason of death.'
				}
			}));
			3;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonOfDeath: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedReasonOfDeath?.message) {
			validateDeceasedReasonOfDeath();
		}
	}, [deceasedReasonOfDeath]);

	const validateDeceasedInjuryDetailsOther = () => {
		if (injuryType?.find((e) => e.value == 12) && !deceasedInjuryDetailsOther) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedInjuryDetailsOther: {
					message: 'You must provide other injury details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedInjuryDetailsOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedInjuryDetailsOther?.message) {
			validateDeceasedInjuryDetailsOther();
		}
	}, [deceasedInjuryDetailsOther]);

	const validateDeceasedIllnessDetailsOther = () => {
		if (
			illnessType?.find((e) => e.value == 4) &&
			!deceasedIllnessDetailsOther
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedIllnessDetailsOther: {
					message: 'You must provide other illness details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedIllnessDetailsOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedIllnessDetailsOther?.message) {
			validateDeceasedIllnessDetailsOther();
		}
	}, [deceasedIllnessDetailsOther]);

	const validateDeceasedReasonForDeathOther = () => {
		if (
			newStatus == 2 &&
			deceasedReasonOfDeath == 4 &&
			(!deceasedReasonForDeathOther || deceasedReasonForDeathOther == 0)
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonForDeathOther: {
					message: 'You must provide other reason of death.'
				}
			}));
			3;
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				deceasedReasonForDeathOther: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.deceasedReasonForDeathOther?.message) {
			validateDeceasedReasonForDeathOther();
		}
	}, [deceasedReasonForDeathOther]);

	const validateProgressPhotos = () => {
		if (progressPhotos?.length === 0) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: 'You must have at least one progress photo' }
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				progressPhotos: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.progressPhotos?.message) {
			validateProgressPhotos();
		}
	}, [progressPhotos]);

	const validateDetailsOfWorkSessionThisWeek = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			hasHorseBeenInWorkThisWeek &&
			!detailsOfWorkSessionThisWeek
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				detailsOfWorkSessionThisWeek: {
					message: 'You must enter details of work sessions this week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				detailsOfWorkSessionThisWeek: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.detailsOfWorkSessionThisWeek?.message) {
			validateDetailsOfWorkSessionThisWeek();
		}
	}, [detailsOfWorkSessionThisWeek]);

	const validateKeyProgressToDate = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			hasHorseBeenInWorkThisWeek &&
			!keyProgressAchievementsToDate
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				keyProgressAchievementsToDate: {
					message: 'You must enter key progress/achievements to date.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				keyProgressAchievementsToDate: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.keyProgressAchievementsToDate?.message) {
			validateKeyProgressToDate();
		}
	}, [keyProgressAchievementsToDate]);

	const validateAreaNeedExtraAttention = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			hasHorseBeenInWorkThisWeek &&
			!areaNeedExtraWorkAttention
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				areaNeedExtraWorkAttention: {
					message: 'You must enter what area(s) need extra work / attention.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				areaNeedExtraWorkAttention: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.areaNeedExtraWorkAttention?.message) {
			validateAreaNeedExtraAttention();
		}
	}, [areaNeedExtraWorkAttention]);

	const validateWorkPlanForComingWeek = () => {
		if ((newStatus == 5 || newStatus == 4) && !workPlanForTheComingWeek) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				workPlanForTheComingWeek: {
					message: 'You must enter work plan for the coming week.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				workPlanForTheComingWeek: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.workPlanForTheComingWeek?.message) {
			validateWorkPlanForComingWeek();
		}
	}, [workPlanForTheComingWeek]);

	const validateHasHorseBeenInWorkExplaination = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			!hasHorseBeenInWorkThisWeek &&
			!hasHorseBeenInWorkThisWeekExplaination
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				hasHorseBeenInWorkThisWeekExplaination: {
					message: 'You must enter please explain why.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				hasHorseBeenInWorkThisWeekExplaination: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.hasHorseBeenInWorkThisWeekExplaination?.message) {
			validateHasHorseBeenInWorkExplaination();
		}
	}, [hasHorseBeenInWorkThisWeekExplaination]);

	const validatePhyiscalHealthConcern = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			anyLamenessOrPhysicalHealthConcern &&
			!physicalHealthConcern
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				physicalHealthConcern: {
					message: 'You must enter please give details.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				physicalHealthConcern: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.physicalHealthConcern?.message) {
			validatePhyiscalHealthConcern();
		}
	}, [physicalHealthConcern]);

	const validateRequiredVetAttentionExplaination = () => {
		if (
			(newStatus == 5 || newStatus == 4) &&
			horseRequiredVetAttention &&
			!requiredVetAttentionExplaination
		) {
			isValidationErrors = true;
			setDisableSubmitbutton(true);
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				requiredVetAttentionExplaination: {
					message: 'You must enter please explain why.'
				}
			}));
		} else {
			setUncontrolledErrors((uncontrolledErrors) => ({
				...uncontrolledErrors,
				requiredVetAttentionExplaination: { message: null }
			}));
		}
	};

	useEffect(() => {
		if (uncontrolledErrors?.requiredVetAttentionExplaination?.message) {
			validateRequiredVetAttentionExplaination();
		}
	}, [requiredVetAttentionExplaination]);

	const validateUncontrolledErrors = () => {
		validateWeekId();
		validateDateOfAssessment();
		validateFeedingPlan();
		validateDisciplines();
		validateDisciplinesOther();
		validatePurpose();
		validatePurposeOther();
		validateAssistanceType();
		validatePostcode();
		validateConcludedReason();
		validateVetTreatmentDetails();
		validatePhysicalConcernDetails();
		validateHorseIssuesEncountered();
		validateRehabReason();
		validateHorseProgressUpdate();
		validateInjuryType();
		validateIllnessType();
		validateUnsuitableReason();
		validateRehabReasonOther();
		validateRehabDateCommenced();
		validatePriceBracket();
		validateSpellingExpectedTimeframe();
		validateDeceasedDateOfDeath();
		validateDateSold();
		validateDateCommenced();
		validateDeceasedReasonOfDeath();
		validateDeceasedInjuryDetailsOther();
		validateDeceasedIllnessDetailsOther();
		validateDeceasedReasonForDeathOther();
		validateProgressPhotos();
		validateNewStatus();
		validateUnsuitableReasonOther();
		validateDateFoundationTraining();
		validateDetailsOfWorkSessionThisWeek();
		validateKeyProgressToDate();
		validateAreaNeedExtraAttention();
		validateWorkPlanForComingWeek();
		validateHasHorseBeenInWorkExplaination();
		validatePhyiscalHealthConcern();
		validateRequiredVetAttentionExplaination();
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const handleOnSubmit = () => {
		validateUncontrolledErrors();
		if (isValidationErrors) {
			setDisableSubmitbutton(true);
		} else {
			handleSave(true);
		}
	};

	const onSubmit = async () => {
		await validateUncontrolledErrors();
		if (
			Object.values(uncontrolledErrors).every((item) => item.message === null)
		) {
			// setIsQuarterFormValidated(true);
		}
	};

	const generateUnclaimPayload = () => {
		let unclaimHorseInputPayload = {};
		const UNCLAIM_HORSE_BASE_INPUT = {
			member_id: currentUser?.UserId,
			horse_code: horseCode,
			unclaim_reason: null,
			effective_date: null,
			other_details: null,
			full_circle_interested: null,
			date_of_death: null,
			euthanised_unsuitable_id: null,
			euthanised_unsuitable_other: null,
			euthanised_illness_id: null,
			euthanised_illness_other: null,
			cause_of_death_id: null,
			euthanised_injury_id: null,
			euthanised_injury_other: null,
			deceased_other_info: null,
			knackery_location: null,
			date_rehomed: null,
			price: null,
			purpose: null,
			purpose_other: null,
			rehomed_disciplines: [],
			disciplines_other: null
		};
		if (concludedReason == 1) {
			unclaimHorseInputPayload = {
				...UNCLAIM_HORSE_BASE_INPUT,
				full_circle_interested: false,
				cause_of_death_id: 2,
				date_of_death: deceasedDateOfDeath,
				unclaim_reason: 1
			};
		} else if (concludedReason == 2) {
			unclaimHorseInputPayload = {
				...UNCLAIM_HORSE_BASE_INPUT,
				date_rehomed: dateSold,
				rehomed_disciplines: selectedDisciplines.map(({ value }) => value),
				disciplines_other: selectedDisciplinesOther || '',
				purpose: +purpose,
				full_circle_interested: false,
				price: priceBracket == 0 ? null : priceBracket
			};
		}
		return unclaimHorseInputPayload;
	};

	const handleSave = async (isSubmitReport = false) => {
		validateUncontrolledErrors();
		const filteredInjuries = injuryType.map((inj) => inj.value);
		const filteredIllnesses = illnessType.map((ill) => ill.value);
		const filteredAssistanceType = assistanceType.map(
			(assistance) => assistance.value
		);
		const filteredUnsuitableReason = unsuitableReason.map(
			(reason) => reason.value
		);
		const filteredSelectedDisciplines = selectedDisciplines.map(
			(discipline) => discipline.value
		);

		let acknowledgedFormData = {
			memberId: currentUserMemberId,
			horseCode,
			weekId: weekId == 0 ? null : weekId >= 51 ? weekIdText : weekId,
			concludedReason: concludedReason == 0 ? null : concludedReason,
			hasHorseStatusChanged: hasHorseStatusChanged
				? hasHorseStatusChanged
				: false,
			newStatus: newStatus == 0 ? null : newStatus,
			dateOfAssessment,
			otherConcerns,
			rehabReason: rehabReason == 0 ? null : rehabReason,
			priceBracket: priceBracket == 0 ? null : priceBracket,
			spellingExpectedTimeframe,
			fullName,
			email,
			postcode,
			selectedDisciplinesOther,
			purpose: purpose == 0 ? null : purpose,
			purposeOther,
			unsuitableReason: filteredUnsuitableReason,
			unsuitableReasonOther,
			selectedDisciplines: filteredSelectedDisciplines,
			injuryType: filteredInjuries,
			assistanceType: filteredAssistanceType,
			illnessType: filteredIllnesses,
			rehabReasonOther,
			rehabDateCommenced,
			horseProgressUpdate,
			horseIssuesEncountered,
			feedingPlan,
			physicalConcernDetails,
			physicalConcerns,
			isRetrainHorse,
			isFullCircle,
			vetTreatment,
			vetTreatmentDetails,
			dateSold,
			dateCommenced,
			deceasedDateOfDeath,
			deceasedReasonOfDeath:
				deceasedReasonOfDeath == 0 ? null : deceasedReasonOfDeath,
			deceasedInjuryDetailsOther,
			deceasedIllnessDetailsOther,
			deceasedReasonForDeathOther:
				deceasedReasonForDeathOther == 0 ? null : deceasedReasonForDeathOther,
			hasHorseBeenInWorkThisWeek,
			detailsOfWorkSessionThisWeek,
			keyProgressAchievementsToDate,
			areaNeedExtraWorkAttention,
			workPlanForTheComingWeek,
			hasHorseBeenInWorkThisWeekExplaination,
			anyLamenessOrPhysicalHealthConcern,
			horseRequiredVetAttention,
			requiredVetAttentionExplaination,
			physicalHealthConcern,
			dateFoundationTraining
		};

		setIsLoading(true);
		AppSyncService.execute(saveFosterWeeklyAssessmentForm, {
			input: acknowledgedFormData
		}).then((data) => {
			if (data?.data?.saveFosterWeeklyAssessmentForm !== null) {
				progressPhotos.forEach((photo) => {
					AppSyncService.execute(saveResetReportingImage, {
						filename: photo.filename,
						key: photo.key,
						reportType: 'FosterWeekly',
						reportId: data.data.saveFosterWeeklyAssessmentForm.id,
						type: 'progress'
					});
				});
				setIsShowErrorMessages(true);
				if (!isSubmitReport) {
					reload();
					addToast({
						Message: 'Draft Saved',
						IsSuccess: true
					});
				} else {
					AppSyncService.execute(submitAckFosterWeeklyAssessmentForReview, {
						memberId: currentUserMemberId,
						horseCode: horseCode,
						weekId: weekId >= 51 ? weekIdText : weekId
					}).then((data) => {
						if (data?.data?.submitAckFosterWeeklyAssessmentForReview) {
							if (newStatus == 2) {
								let unclaimHorseInputPayload = generateUnclaimPayload();
								if (concludedReason == 1 || concludedReason == 2) {
									AppSyncService.execute(unclaimHorse, {
										input: unclaimHorseInputPayload
									}).then((data) => {
										if (data?.data?.unclaimHorse?.success) {
											addToast({
												Message: 'Thoroughbred Unclaimed',
												IsSuccess: true
											});
										} else {
											addToast({
												Message: 'An error occured unclaiming.',
												IsSuccess: false
											});
										}
									});
								}
								if (concludedReason == 1) {
									const deceasedCauseOfDeathId = 2;
									const euthanisedReasonId =
										deceasedCauseOfDeathId == 2 && deceasedReasonOfDeath != 0
											? Number(deceasedReasonOfDeath)
											: null;
									const deceasedInput = {
										horseCode: Number(horseCode),
										euthanisedReasonId,
										deceasedThoroughbredSourceId: 6, //@NOTE - 1 is equal to STAR Weekly Assessment
										deceasedDateOfDeath: deceasedDateOfDeath,
										deceasedCauseOfDeathId: deceasedCauseOfDeathId,
										memberId: null,
										unsuitableReasons:
											deceasedCauseOfDeathId == 2 &&
											euthanisedReasonId == 3 &&
											unsuitableReason?.length > 0
												? unsuitableReason.map((item) => item.value)
												: [],
										euthanisedInjuryOther:
											deceasedCauseOfDeathId == 2 && euthanisedReasonId == 1
												? deceasedInjuryDetailsOther
												: null,
										euthanisedIllnessOther:
											deceasedCauseOfDeathId == 2 && euthanisedReasonId == 2
												? deceasedIllnessDetailsOther
												: null,
										euthanisedUnsuitableId: null,
										euthanisedUnsuitableOther:
											deceasedCauseOfDeathId == 2 && euthanisedReasonId == 3
												? unsuitableReasonOther
												: null,
										deceasedReasonForDeathOther:
											deceasedReasonForDeathOther &&
											deceasedCauseOfDeathId == 2 &&
											euthanisedReasonId == 4 &&
											!isNaN(Number(deceasedReasonForDeathOther))
												? [Number(deceasedReasonForDeathOther)]
												: [],
										deceasedKnackeryPostcode: null,
										deceasedKnackerySuburb: null,
										deceasedOtherPostcode: null,
										deceasedOtherSuburb: null,
										informationAboutDeath: null,
										hasOwnerBeenNotified: false,
										status: 'Review',
										illnesses:
											deceasedCauseOfDeathId == 2 &&
											euthanisedReasonId == 2 &&
											illnessType?.length > 0
												? illnessType.map((item) => item.value)
												: [],
										injuries:
											deceasedCauseOfDeathId == 2 &&
											euthanisedReasonId == 1 &&
											injuryType?.length > 0
												? injuryType.map((item) => item.value)
												: []
									};
									AppSyncService.execute(saveDeceasedThoroughbredForm, {
										input: deceasedInput
									});
								}
							}

							addToast({
								Message: 'Weekly Assessment Form Submitted',
								IsSuccess: true
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Weekly STAR Report form Submission Submitted and Passed'
							);
						} else {
							addToast({
								Message:
									'An error submitting. Racing Victoria are investigating.',
								IsSuccess: false
							});
							logToServer(
								currentUser?.UserId,
								data,
								'Weekly STAR Report form Submission Submitted and Failed'
							);
						}
					});
				}
			}
		});

		if (!isSubmitReport) {
			if (!isValidationErrors) {
				setDisableSubmitbutton(false);
			} else {
				setDisableSubmitbutton(false);
			}
		}
		setIsLoading(false);
	};

	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		fetchMemberData();
	}, []);

	const fetchMemberData = async () => {
		const result = await AppSyncService.execute(getMemberData, {
			externalId: currentUser.UserId
		});

		if (result?.data?.getMember) {
			setCurrentUserMemberId(result.data.getMember.id);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		if (currentUser !== null) {
			AppSyncService.execute(
				getAcknowledgedFosterWeeklyAssessmentReferences
			).then((result) => {
				if (result.data) {
					setReferenceRehabReasonList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementRehabReasonTypes'
						)
					);
					setReferenceRehabReasonList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceConcludedReasonList(
						extractLookUpOptionsOther(result, 'listAckFosterConcludedReasons')
					);
					setReferenceConcludedReasonList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceDeceasedReasonOfDeathOtherList(
						extractLookUpOptionsOther(
							result,
							'listAckRetirementDeceasedReasonOfDeathOtherTypes'
						)
					);
					setReferenceDeceasedReasonOfDeathOtherList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceReasonOfDeathList(
						extractLookUpOptionsOther(result, 'listShareInfoEuthanisedReasons')
					);
					setReferenceReasonOfDeathList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceUnsuitableReasonsList(
						extractLookUpOptionsOther(
							result,
							'listShareInfoEuthanisedUnsuitableReasons'
						)
					);
					setReferenceUnsuitableReasonsList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferencePriceBracketList(
						extractLookUpOptionsOther(result, 'listSoldRehomePriceBrackets')
					);
					setReferencePriceBracketList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferencePurposeList(
						extractLookUpOptionsOther(result, 'listSoldRehomePurposes')
					);
					setReferencePurposeList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceRetainingPurposeList(
						extractLookUpOptionsOther(result, 'listHorsePurposes')
					);
					setReferenceRetainingPurposeList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					let SortedDicipline = sortArray(
						result.data.listSpecialtyDisciplines,
						true
					);

					setDisciplineOptions(SortedDicipline);

					setReferenceAssistanceTypeList(
						extractLookUpOptionsOther(result, 'listFullCircleTypesOfAssistance')
					);
					setReferenceAssistanceTypeList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceInjuryList(
						extractLookUpOptionsOther(result, 'listAckRetirementInjuryTypes')
					);
					setReferenceInjuryList((prev) => [{ id: 0, value: '' }].concat(prev));
					setReferenceIllnessList(
						extractLookUpOptionsOther(result, 'listAckRetirementIllnessTypes')
					);
					setReferenceIllnessList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);

					setReferenceNewStatusList(
						extractLookUpOptionsOther(result, 'listAckFosterNewStatusTypes')
					);
					setReferenceNewStatusList((prev) =>
						[{ id: 0, value: '' }].concat(prev)
					);
				}
			});
		}
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (
			(weekId && weekId != 0 && weekId != 51) ||
			(weekId == 51 && weekIdText && weekIdText != 0 && !isNaN(weekIdText))
		) {
			setDisableSubmitbutton(true);
			loadWeeklyFosterForm();
		}
	}, [weekId, debouncedWeekNumber]);

	const loadWeeklyFosterForm = () => {
		const input = {
			memberExternalId: currentUser?.UserId,
			horseCode,
			weekId: weekId >= 51 ? +weekIdText : weekId
		};
		try {
			AppSyncService.execute(getFosterWeeklyAssessmentForm, input).then(
				(result) => {
					if (result?.data?.getFosterWeeklyAssessmentForm) {
						const initialData = result?.data?.getFosterWeeklyAssessmentForm;

						if (initialData?.status == null) {
							setIsShowErrorMessages(false);
						} else {
							setIsShowErrorMessages(true);
						}

						updateDate(
							initialData.dateOfAssessment ?? null,
							setDateOfAssessment,
							validateDateOfAssessment
						);
						setHasHorseStatusChanged(initialData.hasHorseStatusChanged);
						setNewStatus(initialData.newStatus.id ?? 0);
						setOtherConcerns(initialData.otherConcerns ?? '');
						setRehabReason(initialData.rehabReason.id ?? 0);
						setPriceBracket(initialData.priceBracket.id ?? 0);
						setSpellingExpectedTimeframe(
							initialData.spellingExpectedTimeframe ?? ''
						);
						setFullName(initialData.fullName ?? '');
						setDeceasedIllnessDetailsOther(
							initialData.deceasedIllnessDetailsOther ?? ''
						);
						setPostcode(initialData.postcode ?? '');
						setEmail(initialData.email ?? '');
						setConcludedReason(initialData.concludedReason.id ?? 0);
						setSelectedDisciplinesOther(
							initialData.selectedDisciplinesOther ?? ''
						);
						setPurpose(initialData.purpose.id ?? 0);
						setPurposeOther(initialData.purposeOther ?? '');
						if (initialData.unsuitableReason.length > 0) {
							const reasons = initialData.unsuitableReason.map((reason) => ({
								value: reason.id,
								label: reason.value
							}));
							setUnsuitableReason(reasons);
						}
						if (initialData.selectedDisciplines.length > 0) {
							const disciplines = initialData.selectedDisciplines.map(
								(reason) => ({
									value: reason.id,
									label: reason.value
								})
							);
							setSelectedDisciplines(disciplines);
						}
						setUnsuitableReasonOther(initialData.unsuitableReasonOther ?? '');
						if (initialData.injuryType.length > 0) {
							const mapInjuries = initialData.injuryType.map((injury) => ({
								value: injury.id,
								label: injury.value
							}));
							setInjuryType(mapInjuries);
						}
						if (initialData.assistanceType.length > 0) {
							const mapInjuries = initialData.assistanceType.map(
								(assistance) => ({
									value: assistance.id,
									label: assistance.value
								})
							);
							setAssistanceType(mapInjuries);
						}
						if (initialData.illnessType.length > 0) {
							const mapIllnesses = initialData.illnessType.map((illness) => ({
								value: illness.id,
								label: illness.value
							}));
							setIllnessType(mapIllnesses);
						}
						setRehabReasonOther(initialData.rehabReasonOther ?? '');
						updateDate(
							initialData.rehabDateCommenced
								? initialData.rehabDateCommenced
								: null,
							setRehabDateCommenced,
							validateRehabDateCommenced
						);
						setHorseProgressUpdate(initialData.horseProgressUpdate ?? '');
						setHorseIssuesEncountered(initialData.horseIssuesEncountered ?? '');
						setFeedingPlan(initialData.feedingPlan ?? '');
						setPhysicalConcernDetails(initialData.physicalConcernDetails ?? '');
						setPhysicalConcerns(initialData.physicalConcerns ?? false);
						setIsRetrainHorse(initialData.isRetrainHorse ?? false);
						setIsFullCircle(initialData.isFullCircle ?? false);
						setVetTreatmentDetails(initialData.vetTreatmentDetails ?? '');
						setVetTreatment(initialData.vetTreatment ?? false);
						updateDate(
							initialData.deceasedDateOfDeath,
							setDeceasedDateOfDeath,
							validateDeceasedDateOfDeath
						);
						updateDate(initialData.dateSold, setDateSold, validateDateSold);
						updateDate(
							initialData.dateCommenced,
							setDateCommenced,
							validateDateCommenced
						);
						setDeceasedReasonOfDeath(initialData.deceasedReasonOfDeath.id ?? 0);
						setDeceasedInjuryDetailsOther(
							initialData.deceasedInjuryDetailsOther ?? ''
						);
						setDeceasedReasonForDeathOther(
							initialData.deceasedReasonForDeathOther.id ?? 0
						);
						setProgressPhotos(initialData.progressPhotos ?? []);
						setIsReadOnly(
							initialData.status === REVIEW || initialData.status === APPROVED
						);
						updateDate(
							initialData.dateFoundationTraining
								? initialData.dateFoundationTraining
								: null,
							setDateFoundationTraining,
							validateDateFoundationTraining
						);
						setHasHorseBeenInWorkThisWeek(
							initialData.hasHorseBeenInWorkThisWeek ?? true
						);
						setDetailsOfWorkSessionThisWeek(
							initialData.detailsOfWorkSessionThisWeek ?? ''
						);
						setKeyProgressAchievementsToDate(
							initialData.keyProgressAchievementsToDate ?? ''
						);
						setAreaNeedExtraWorkAttention(
							initialData.areaNeedExtraWorkAttention ?? ''
						);
						setWorkPlanForTheComingWeek(
							initialData.workPlanForTheComingWeek ?? ''
						);
						setHasHorseBeenInWorkThisWeekExplaination(
							initialData.hasHorseBeenInWorkThisWeekExplaination ?? ''
						);
						setAnyLamenessOrPhysicalHealthConcern(
							initialData.anyLamenessOrPhysicalHealthConcern ?? false
						);
						setHorseRequiredVetAttention(
							initialData.horseRequiredVetAttention ?? false
						);
						setRequiredVetAttentionExplaination(
							initialData.requiredVetAttentionExplaination ?? ''
						);
						setPhysicalHealthConcern(initialData.physicalHealthConcern ?? '');
						setDisableSubmitbutton(true);
						setUncontrolledErrors({});
					}
					setIsFosterDetailsLoaded(true);
				}
			);
		} catch (error) {
			logToServer(currentUser?.UserId, error, 'Form fetch failed');
			addToast({ Message: 'Unable to fetch form', IsSuccess: false });
		}
	};

	const handleCancel = () => {
		deleteAllPhotos();
		setWeekId(0);
		setWeekIdText(0);
		setDateOfAssessment(null);
		setHasHorseStatusChanged(false);
		setNewStatus(0);
		setOtherConcerns(null);
		setRehabReason(0);
		setPriceBracket(0);
		setFullName(null);
		setEmail(null);
		setPostcode(null);
		setSelectedDisciplinesOther(null);
		setSpellingExpectedTimeframe(null);
		setPurpose(0);
		setPurposeOther(null);
		setUnsuitableReason([]);
		setSelectedDisciplines([]);
		setUnsuitableReasonOther(null);
		setAssistanceType([]);
		setInjuryType([]);
		setIllnessType([]);
		setRehabReasonOther(null);
		setRehabDateCommenced(null);
		setHorseProgressUpdate(null);
		setHorseIssuesEncountered(null);
		setFeedingPlan(null);
		setPhysicalConcernDetails(null);
		setPhysicalConcerns(null);
		setIsRetrainHorse(null);
		setIsFullCircle(null);
		setVetTreatment(null);
		setVetTreatmentDetails(null);
		setDateSold(null);
		setDateCommenced(null);
		setDeceasedDateOfDeath(null);
		setDeceasedReasonOfDeath(0);
		setDeceasedInjuryDetailsOther('');
		setDeceasedReasonForDeathOther(0);

		setConfirmationModal(false);
	};

	const updateDate = (value, stateAssignmentFunction) => {
		if (!value) stateAssignmentFunction(null);
		let formattedDate = formatDate(value);
		if (formattedDate != '1970-01-01') {
			stateAssignmentFunction(formattedDate);
		} else {
			stateAssignmentFunction(null);
		}
	};
	const photosUpload = (image, photoArraySetter) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						addToast({ Message: 'Photo uploaded', IsSuccess: true });
						photoArraySetter((photoArray) => [
							...photoArray,
							{ filename: image.name, key: imageKey }
						]);
						setShouldValidate(true);
					});
			}
		});
	};

	const getImage = (img) => {
		const imageKey = { imageKey: img.key };
		AppSyncService.execute(getUserImage, imageKey).then((data) => {
			setImgSrcUrl(data.data.getUserImage.signedUrl);
		});
	};

	const removePhoto = (img, photoArray, photoArraySetter) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					photoArraySetter(photoArray.filter((item) => item.key != img.key));
				} else {
					return addToast({
						Message: 'Image Removal Failed',
						IsSuccess: false
					});
				}
			}
		);
	};

	const deleteAllPhotos = () => {
		let listOfKeys = [];
		[progressPhotos].forEach((photoArray) => {
			photoArray.map((d) => {
				listOfKeys.push(d.key);
			});
		});
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					setProgressPhotos([]);
				}
			});
		}
	};

	const DateCommenced = () => {
		return (
			<Col xs={12} lg={4}>
				{isReadOnly ? (
					<Row>
						<Col>
							<FormLabelField
								title="Date Commenced*"
								value={formatDateReadableVariant(dateCommenced)}
							/>
						</Col>
					</Row>
				) : (
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Date Commenced*</div>
						<FormDatePicker
							id={HorseManagementKeys.DATE_COMMENCED}
							DateValue={dateCommenced}
							updateSelection={(value) => {
								updateDate(value, setDateCommenced, validateDateCommenced);
								setShouldValidate(true);
							}}
							canEdit={!isReadOnly}
						/>
						{isShowErrorMessages && (
							<span className={classes.errorText}>
								{uncontrolledErrors.dateCommenced?.message}
							</span>
						)}
					</div>
				)}
			</Col>
		);
	};

	const DateFoundationTraining = () => {
		return (
			<Col xs={12} lg={4}>
				{isReadOnly ? (
					<Row>
						<Col>
							<FormLabelField
								title="Date Foundation Training Commenced*"
								value={formatDateReadableVariant(dateFoundationTraining)}
							/>
						</Col>
					</Row>
				) : (
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>
							Date Foundation Training Commenced*
						</div>
						<FormDatePicker
							DateValue={dateFoundationTraining}
							updateSelection={(value) => {
								updateDate(
									value,
									setDateFoundationTraining,
									validateDateFoundationTraining
								);
								setShouldValidate(true);
							}}
							canEdit={!isReadOnly}
						/>
						{isShowErrorMessages && (
							<span className={classes.errorText}>
								{uncontrolledErrors.dateFoundationTraining?.message}
							</span>
						)}
					</div>
				)}
			</Col>
		);
	};

	const renderFeedingPlanRequirements = () => {
		return (
			<Row>
				<Col xs={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>
							<ResizingTextArea
								name="feedingPlan"
								title={
									<div>
										Feeding requirements/plan{' '}
										<span className={classes.fontWeightNormal}>
											(including whether the horse is currently eating well)
										</span>
										*
									</div>
								}
								id="acknowledgedFoster-feedingPlan"
								value={feedingPlan}
								onChange={(e) => {
									setFeedingPlan(e.target.value);
									setShouldValidate(true);
								}}
								maxChars={500}
								charLengthStyle={{
									fontWeight: 'normal'
								}}
								styles={{
									marginLeft: 0,
									marginRight: 0,
									padding: 0
								}}
								titleStyles={{ fontSize: '14px' }}
								canEdit={!isReadOnly}
							/>
						</div>
						{isShowErrorMessages && (
							<span className={classes.errorText}>
								{uncontrolledErrors.feedingPlan?.message}
							</span>
						)}
					</div>
				</Col>
			</Row>
		);
	};

	const renderVetTreatmentQuestion = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Has the horse required vet attention or treatment in the past week?"
										value={vetTreatment ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									Has the horse required vet attention or treatment in the past
									week?
								</div>
								<Toggle
									id={HorseManagementKeys.VET_ATTENTION}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setVetTreatment(value);
										validateVetTreatmentDetails();
									}}
									value={vetTreatment}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
				</Row>

				{vetTreatment && (
					<Row>
						<Col xs={12}>
							{isReadOnly ? (
								<Row>
									<Col>
										<FormLabelField
											title="Please provide details*"
											value={vetTreatmentDetails}
										/>
									</Col>
								</Row>
							) : (
								<div className={classes.fieldContainerAdjustedPaddingTop}>
									<div className={classes.fieldTitle}>
										<ResizingTextArea
											title={'Please provide details*'}
											id={HorseManagementKeys.VET_ATTENTION_DETAILS}
											onChange={(e) => {
												setVetTreatmentDetails(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											value={vetTreatmentDetails}
											canEdit={!isReadOnly}
										/>
									</div>

									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.vetTreatmentDetails?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					</Row>
				)}
			</>
		);
	};

	const renderPhysicalConcernQuestion = () => {
		return (
			<>
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Are there any lameness or physical health concerns (including recurring)?"
										value={physicalConcerns ? 'Yes' : 'No'}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									Are there any lameness or physical health concerns (including
									recurring)?
								</div>
								<Toggle
									id={HorseManagementKeys.LAMENESS}
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => {
										setPhysicalConcerns(value);
										validatePhysicalConcernDetails();
									}}
									value={physicalConcerns}
									disabled={isReadOnly}
								/>
							</div>
						)}
					</Col>
				</Row>

				{physicalConcerns && (
					<Row>
						<Col xs={12}>
							{isReadOnly ? (
								<Row>
									<Col>
										<FormLabelField
											title="Please provide details*"
											value={physicalConcernDetails}
										/>
									</Col>
								</Row>
							) : (
								<div className={classes.fieldContainerAdjustedPaddingTop}>
									<div className={classes.fieldTitle}>
										<ResizingTextArea
											title={'Please provide details*'}
											id={HorseManagementKeys.LAMENESS_DETAILS}
											onChange={(e) => {
												setPhysicalConcernDetails(e.target.value);
												setShouldValidate(true);
											}}
											maxChars={500}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											value={physicalConcernDetails}
											canEdit={!isReadOnly}
										/>
									</div>

									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.physicalConcernDetails?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					</Row>
				)}
			</>
		);
	};

	const renderAssessmentFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Week Number*
								<FormToolTip>
									The week number indicates how long the horse has been in your
									care
								</FormToolTip>
							</div>
							<FormDropDown
								items={RESETWeeksAvailable()}
								selectedId={weekId}
								onChange={(e) => {
									setWeekId(e.target.value);
									setShouldValidate(true);
								}}
								id={HorseManagementKeys.WEEK_NUMBER_DROPDOWN}
								showBlank
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.weekId?.message}
								</span>
							)}
						</div>
					</Col>
					{+weekId === 51 && (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Week Number*</div>
								<FormTextField
									id={HorseManagementKeys.WEEK_NUMBER_TEXT}
									value={weekIdText}
									onChange={(e) => {
										setWeekIdText(e.target.value);
										setShouldValidate(true);
									}}
									styles={{ height: 38 }}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.weekIdText?.message}
									</span>
								)}
							</div>
						</Col>
					)}
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Date of Assessment*"
										value={formatDateReadableVariant(dateOfAssessment)}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Date of Assessment*</div>
								<FormDatePicker
									DateValue={dateOfAssessment}
									id={HorseManagementKeys.DATE_OF_ASSESSMENT}
									updateSelection={(value) => {
										updateDate(
											value,
											setDateOfAssessment,
											validateDateOfAssessment
										);
										setShouldValidate(true);
									}}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.dateOfAssessment?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Has the horse&apos;s status changed?*
							</div>
							<Toggle
								id={HorseManagementKeys.HORSE_STATUS_CHANGE}
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									if (!value) {
										setNewStatus(0);
									}
									setHasHorseStatusChanged(value);
									setShouldValidate(true);
								}}
								value={hasHorseStatusChanged}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
					{hasHorseStatusChanged ? (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>New Status*</div>
								<FormDropDown
									showBlank
									id={HorseManagementKeys.NEW_STATUS}
									items={referenceNewStatusList}
									selectedId={newStatus}
									onChange={(e) => {
										setNewStatus(e.target.value);
										setShouldValidate(true);
										setIllnessType([]);
										setInjuryType([]);
										setUnsuitableReason([]);
										setAssistanceType([]);
										setSelectedDisciplines([]);
									}}
									disabled={isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.newStatus?.message}
									</span>
								)}
							</div>
						</Col>
					) : (
						<Col xs={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Current Status</div>
								<div className={classes.statusTitle}>
									{horseData?.currentStatus?.value}
								</div>
							</div>
						</Col>
					)}

					<Col xs={12} lg={4}>
						<div className={classes.fieldContainerNoPaddingTop}>
							<div className={classes.fieldTitle}>
								Progress Photo(s)* (1 required - jpeg, png)
							</div>
							<UploadPhoto
								id={HorseManagementKeys.QUARTERLY_PROGRESS_PHOTOS}
								formats={['png', 'jpeg']}
								labelName="Upload Progress Photo(s)"
								error={uploadError}
								data={progressPhotos}
								sizeLimitMB={5}
								numberOfImages={10}
								success={(e) => photosUpload(e, setProgressPhotos)}
								getImage={getImage}
								removeImage={(e) =>
									removePhoto(e, progressPhotos, setProgressPhotos)
								}
								canEdit={!isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.progressPhotos?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</div>
		);
	};

	const renderSpellingFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<DateCommenced />
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									name="spellingExpectedTimeframe"
									title="What is the expected timeframe for Spelling and the reason?*"
									id="acknowledgedFoster-spellingExpectedTimeframe"
									value={spellingExpectedTimeframe}
									onChange={(e) => {
										setSpellingExpectedTimeframe(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
							</div>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.spellingExpectedTimeframe?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<>{renderFeedingPlanRequirements()}</>
				<>{renderPhysicalConcernQuestion()}</>
				<>{renderVetTreatmentQuestion()}</>
			</div>
		);
	};

	const renderConcludedFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Reason for Concluded*</div>
							<FormDropDown
								showBlank
								name="concludedReason"
								id={HorseManagementKeys.CONCLUDED_REASON}
								items={referenceConcludedReasonList}
								selectedId={concludedReason}
								onChange={(e) => {
									setConcludedReason(e.target.value);
									setShouldValidate(true);
									setUnsuitableReason([]);
									setUnsuitableReasonOther(null);
									setIllnessType([]);
									setInjuryType([]);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.concludedReason?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				{
					//@NOTE: If concludedReason is Euthanised
					concludedReason == 1 && (
						<>
							<Row>
								<Col xs={12} lg={4}>
									{isReadOnly ? (
										<Row>
											<Col>
												<FormLabelField
													title="Date of death*"
													value={formatDateReadableVariant(deceasedDateOfDeath)}
												/>
											</Col>
										</Row>
									) : (
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Date of death*</div>
											<FormDatePicker
												id={HorseManagementKeys.DATE_OF_DEATH}
												DateValue={deceasedDateOfDeath}
												updateSelection={(value) => {
													updateDate(
														value,
														setDeceasedDateOfDeath,
														validateDeceasedDateOfDeath
													);
													setShouldValidate(true);
												}}
												canEdit={!isReadOnly}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.deceasedDateOfDeath?.message}
												</span>
											)}
										</div>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Reason*</div>
										<FormDropDown
											showBlank
											name="deceasedReasonOfDeath"
											id="acknowledgedFoster-deceasedReasonOfDeath"
											items={referenceReasonOfDeathList}
											selectedId={deceasedReasonOfDeath}
											onChange={(e) => {
												setDeceasedReasonOfDeath(e.target.value);
												setShouldValidate(true);
												setIllnessType([]);
												setInjuryType([]);
												setUnsuitableReason([]);
												setUnsuitableReasonOther(null);
												setDeceasedIllnessDetailsOther(null);
												setDeceasedInjuryDetailsOther(null);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.deceasedReasonOfDeath?.message}
											</span>
										)}
									</div>
								</Col>
								{deceasedReasonOfDeath == reasonForDeath.INJURY && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Injury Type*`}
												value={injuryType
													.map((seledctedInjury) => seledctedInjury.label)
													.join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div
												className={
													classes.fieldContainerPaddingTopDeceasedMultiSelect
												}
											>
												<MultiSelectInputField
													idPrefix={`injuries`}
													fieldLabel={`Injury Type*`}
													options={referenceInjuryList}
													selectedIds={injuryType}
													onSelectChange={(vals) => {
														setInjuryType(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.injuryType?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{deceasedReasonOfDeath == reasonForDeath.ILLNESS && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Illness Type*`}
												value={illnessType
													.map((seledctedInjury) => seledctedInjury.label)
													.join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div
												className={
													classes.fieldContainerPaddingTopDeceasedMultiSelect
												}
											>
												<MultiSelectInputField
													idPrefix={`illnesses`}
													fieldLabel={`Illness Type*`}
													options={referenceIllnessList}
													selectedIds={illnessType}
													onSelectChange={(vals) => {
														setIllnessType(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.illnessType?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{deceasedReasonOfDeath == reasonForDeath.UNSUITABLE && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Unsuitable for rehoming reason*`}
												value={unsuitableReason
													.map((seledctedInjury) => seledctedInjury.label)
													.join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div
												className={
													classes.fieldContainerPaddingTopDeceasedMultiSelect
												}
											>
												<MultiSelectInputField
													idPrefix={`unsuitable`}
													fieldLabel={`Unsuitable for rehoming reason*`}
													options={referenceUnsuitableReasonsList}
													selectedIds={unsuitableReason}
													onSelectChange={(vals) => {
														setUnsuitableReason(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.unsuitableReason?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
								{deceasedReasonOfDeath == reasonForDeath.OTHER && (
									<Col xs={12} lg={4}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Other reason for death*
											</div>
											<FormDropDown
												showBlank
												name="deceasedReasonForDeathOther"
												id="acknowledgedFoster-deceasedReasonForDeathOther"
												items={referenceDeceasedReasonOfDeathOtherList}
												selectedId={deceasedReasonForDeathOther}
												onChange={(e) => {
													setDeceasedReasonForDeathOther(e.target.value);
													setShouldValidate(true);
												}}
												disabled={isReadOnly}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{
														uncontrolledErrors.deceasedReasonForDeathOther
															?.message
													}
												</span>
											)}
										</div>
									</Col>
								)}
							</Row>
							{deceasedReasonOfDeath == reasonForDeath.INJURY &&
								injuryType.map((i) => {
									if (i.label === 'Other') {
										return (
											<Row>
												<Col xs={12}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															<ResizingTextArea
																name="deceasedInjuryDetailsOther"
																title="Other injury details*"
																id="acknowledgedFoster-deceasedInjuryDetailsOther"
																value={deceasedInjuryDetailsOther}
																onChange={(e) => {
																	setDeceasedInjuryDetailsOther(e.target.value);
																	setShouldValidate(true);
																}}
																maxChars={500}
																charLengthStyle={{
																	fontWeight: 'normal'
																}}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																titleStyles={{ fontSize: '14px' }}
																canEdit={!isReadOnly}
															/>
														</div>
														{isShowErrorMessages && (
															<span className={classes.errorText}>
																{
																	uncontrolledErrors.deceasedInjuryDetailsOther
																		?.message
																}
															</span>
														)}
													</div>
												</Col>
											</Row>
										);
									}
								})}
							{deceasedReasonOfDeath == reasonForDeath.ILLNESS &&
								illnessType.map((i) => {
									if (i.label === 'Other') {
										return (
											<Row>
												<Col xs={12}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															<ResizingTextArea
																title="Other illness details*"
																name="deceasedIllnessDetailsOther"
																id="acknowledgedFoster-deceasedIllnessDetailsOther"
																value={deceasedIllnessDetailsOther}
																onChange={(e) => {
																	setDeceasedIllnessDetailsOther(
																		e.target.value
																	);
																	setShouldValidate(true);
																}}
																maxChars={500}
																charLengthStyle={{
																	fontWeight: 'normal'
																}}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																titleStyles={{ fontSize: '14px' }}
																canEdit={!isReadOnly}
															/>
														</div>
														{isShowErrorMessages && (
															<span className={classes.errorText}>
																{
																	uncontrolledErrors.deceasedIllnessDetailsOther
																		?.message
																}
															</span>
														)}
													</div>
												</Col>
											</Row>
										);
									}
								})}
							{deceasedReasonOfDeath == reasonForDeath.UNSUITABLE &&
								unsuitableReason.map((i) => {
									if (i.label === 'Other') {
										return (
											<Row>
												<Col xs={12}>
													<div className={classes.fieldContainer}>
														<div className={classes.fieldTitle}>
															<ResizingTextArea
																name="unsuitableReasonOther"
																title={'Other Unsuitable for rehoming reason*'}
																id={
																	HorseManagementKeys.EUTHANISED_UNSUITABLE_TYPE
																}
																value={unsuitableReasonOther}
																onChange={(e) => {
																	setUnsuitableReasonOther(e.target.value);
																	setShouldValidate(true);
																}}
																maxChars={500}
																charLengthStyle={{
																	fontWeight: 'normal'
																}}
																styles={{
																	marginLeft: 0,
																	marginRight: 0,
																	padding: 0
																}}
																titleStyles={{ fontSize: '14px' }}
																canEdit={!isReadOnly}
															/>
														</div>
														{isShowErrorMessages && (
															<span className={classes.errorText}>
																{
																	uncontrolledErrors.unsuitableReasonOther
																		?.message
																}
															</span>
														)}
													</div>
												</Col>
											</Row>
										);
									}
								})}
						</>
					)
				}

				{
					//@NOTE: If concludedReason is Sold/Rehomed
					concludedReason == 2 && (
						<>
							<Row>
								<Col xs={12} lg={4}>
									{isReadOnly ? (
										<Row>
											<Col>
												<FormLabelField
													title="Date Sold*"
													value={formatDateReadableVariant(dateSold)}
												/>
											</Col>
										</Row>
									) : (
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>Date Sold*</div>
											<FormDatePicker
												id={HorseManagementKeys.DATE_SOLD}
												DateValue={dateSold}
												updateSelection={(value) => {
													updateDate(value, setDateSold, validateDateSold);
													setShouldValidate(true);
												}}
												canEdit={!isReadOnly}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.dateSold?.message}
												</span>
											)}
										</div>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Price Bracket*</div>
										<FormDropDown
											showBlank
											name="priceBracket"
											id="acknowledgedFoster-priceBracket"
											items={referencePriceBracketList}
											selectedId={priceBracket}
											onChange={(e) => {
												setPriceBracket(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.priceBracket?.message}
											</span>
										)}
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Purpose*</div>
										<FormDropDown
											showBlank
											name="purpose"
											id="acknowledgedFoster-purpose"
											items={referencePurposeList}
											selectedId={purpose}
											onChange={(e) => {
												setPurpose(e.target.value);
												setShouldValidate(true);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.purpose?.message}
											</span>
										)}
									</div>
								</Col>
								<Col xs={12}>
									{isReadOnly ? (
										<FormLabelField
											title={`Disciplines*`}
											value={selectedDisciplines
												.map((seledctedDiscipline) => seledctedDiscipline.label)
												.join()}
											style={{ padding: 0 }}
										/>
									) : (
										<div
											className={
												classes.fieldContainerPaddingTopDeceasedMultiSelect
											}
										>
											<MultiSelectInputField
												idPrefix={`unsuitable`}
												fieldLabel={`Disciplines*`}
												options={disciplineOptions}
												selectedIds={selectedDisciplines}
												onSelectChange={(vals) => {
													setSelectedDisciplines(vals);
													setShouldValidate(true);
												}}
												otherDisabled={true}
											/>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.selectedDisciplines?.message}
												</span>
											)}
										</div>
									)}
								</Col>
							</Row>
							{selectedDisciplines.map((i) => {
								if (i.label === 'Other') {
									return (
										<Row>
											<Col xs={12}>
												<div className={classes.fieldContainer}>
													<div className={classes.fieldTitle}>
														<ResizingTextArea
															name="selectedDisciplinesOther"
															title={'Other Disciplines*'}
															id={
																HorseManagementKeys.CONCLUDED_DISCIPLINE_OTHER
															}
															value={selectedDisciplinesOther}
															onChange={(e) => {
																setSelectedDisciplinesOther(e.target.value);
																setShouldValidate(true);
															}}
															maxChars={500}
															charLengthStyle={{
																fontWeight: 'normal'
															}}
															styles={{
																marginLeft: 0,
																marginRight: 0,
																padding: 0
															}}
															titleStyles={{ fontSize: '14px' }}
															canEdit={!isReadOnly}
														/>
													</div>
													{isShowErrorMessages && (
														<span className={classes.errorText}>
															{
																uncontrolledErrors.selectedDisciplinesOther
																	?.message
															}
														</span>
													)}
												</div>
											</Col>
										</Row>
									);
								}
							})}
							<hr className={classes.divider}></hr>
							<div className={classes.formHeading} style={{ paddingBottom: 0 }}>
								New Owner Details
							</div>
							<Row>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Full Name</div>
										<FormTextField
											id={HorseManagementKeys.FULL_NAME}
											value={fullName}
											onChange={(e) => setFullName(e.target.value)}
											styles={{ height: 38 }}
										/>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Email</div>
										<FormTextField
											id={HorseManagementKeys.EMAIL}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											styles={{ height: 38 }}
										/>
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Postcode*</div>
										<FormTextField
											id={HorseManagementKeys.POSTCODE}
											value={postcode}
											onChange={(e) => {
												setPostcode(e.target.value);
												setShouldValidate(true);
											}}
											styles={{ height: 38 }}
										/>
									</div>
								</Col>
							</Row>
						</>
					)
				}

				{
					//@NOTE: If concludedReason is Retaining for Own Use
					concludedReason == 3 && (
						<>
							<Row>
								<DateCommenced />
								<Col xs={12} lg={4}>
									{isReadOnly ? (
										<Row>
											<Col>
												<FormLabelField
													title="Are you going to be retraining this horse?"
													value={isRetrainHorse ? 'Yes' : 'No'}
												/>
											</Col>
										</Row>
									) : (
										<div className={classes.fieldContainerAdjustedPaddingTop}>
											<div className={classes.fieldTitle}>
												Are you going to be retraining this horse?
											</div>
											<Toggle
												id={HorseManagementKeys.RETRAIN}
												labelNegative="No"
												labelPositive="Yes"
												onClick={(value) => {
													setIsRetrainHorse(value);
												}}
												value={isRetrainHorse}
												disabled={isReadOnly}
											/>
										</div>
									)}
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>Purpose*</div>
										<FormDropDown
											showBlank
											name="purpose"
											id="acknowledgedFoster-purpose"
											items={referenceRetainingPurposeList}
											selectedId={purpose}
											onChange={(e) => {
												setPurpose(e.target.value);
												setShouldValidate(true);
												setPurposeOther(null);
											}}
											disabled={isReadOnly}
										/>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.purpose?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
							{purpose == 6 && (
								<Row>
									<Col xs={12}>
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												<ResizingTextArea
													name="purposeOther"
													title={'Other Purpose*'}
													id={HorseManagementKeys.CONCLUDED_PURPOSE_OTHER}
													value={purposeOther}
													onChange={(e) => {
														setPurposeOther(e.target.value);
														setShouldValidate(true);
													}}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													styles={{
														marginLeft: 0,
														marginRight: 0,
														padding: 0
													}}
													titleStyles={{ fontSize: '14px' }}
													canEdit={!isReadOnly}
												/>
											</div>
											{isShowErrorMessages && (
												<span className={classes.errorText}>
													{uncontrolledErrors.purposeOther?.message}
												</span>
											)}
										</div>
									</Col>
								</Row>
							)}
							<Row>
								<Col xs={12} lg={4}>
									{isReadOnly ? (
										<Row>
											<Col>
												<FormLabelField
													title="Do you want to Full Circle this horse?"
													value={isFullCircle ? 'Yes' : 'No'}
												/>
											</Col>
										</Row>
									) : (
										<div className={classes.fieldContainer}>
											<div className={classes.fieldTitle}>
												Do you want to Full Circle this horse?
											</div>
											<Toggle
												id={HorseManagementKeys.FULL_CIRCLE_HORSE}
												labelNegative="No"
												labelPositive="Yes"
												onClick={(value) => {
													setIsFullCircle(value);
													setAssistanceType([]);
													setShouldValidate(true);
												}}
												value={isFullCircle}
												disabled={isReadOnly}
											/>
										</div>
									)}
								</Col>
								{isFullCircle && (
									<Col xs={12} lg={4}>
										{isReadOnly ? (
											<FormLabelField
												title={`Type of Assistance*`}
												value={assistanceType
													.map(
														(seledctedAssistance) => seledctedAssistance.label
													)
													.join()}
												style={{ padding: 0 }}
											/>
										) : (
											<div className={classes.fieldContainerNoPaddingTop}>
												<MultiSelectInputField
													idPrefix={HorseManagementKeys.TYPE_OF_ASSISTANCE}
													fieldLabel={`Type of Assistance*`}
													options={referenceAssistanceTypeList}
													selectedIds={assistanceType}
													onSelectChange={(vals) => {
														setAssistanceType(vals);
														setShouldValidate(true);
													}}
													otherDisabled={true}
												/>
												{isShowErrorMessages && (
													<span className={classes.errorText}>
														{uncontrolledErrors.assistanceType?.message}
													</span>
												)}
											</div>
										)}
									</Col>
								)}
							</Row>
						</>
					)
				}
			</div>
		);
	};

	const renderRehabFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Please indicate reason for rehabilitation*
							</div>
							<FormDropDown
								showBlank
								name="rehabReason"
								id={HorseManagementKeys.REHABILITATION_REASON}
								items={referenceRehabReasonList}
								selectedId={rehabReason}
								onChange={(e) => {
									setRehabReason(e.target.value);
									setShouldValidate(true);
									setIllnessType([]);
									setInjuryType([]);
									setUnsuitableReason([]);
									setSelectedDisciplines([]);
									setUnsuitableReasonOther(null);
									setDeceasedIllnessDetailsOther(null);
									setDeceasedInjuryDetailsOther(null);
								}}
								disabled={isReadOnly}
							/>
							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.rehabReason?.message}
								</span>
							)}
						</div>
					</Col>
					{rehabReason == reasonForRehab.INJURY && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Injury Type*`}
									value={injuryType
										.map((seledctedInjury) => seledctedInjury.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={classes.fieldContainerPaddingTopRehabMultiSelect}
								>
									<MultiSelectInputField
										idPrefix={HorseManagementKeys.INJURIES}
										fieldLabel={`Injury Type*`}
										options={referenceInjuryList}
										selectedIds={injuryType}
										onSelectChange={(vals) => {
											setInjuryType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.injuryType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
					{rehabReason == reasonForRehab.ILLNESS && (
						<Col xs={12} lg={4}>
							{isReadOnly ? (
								<FormLabelField
									title={`Illness Type*`}
									value={illnessType
										.map((selectedIllness) => selectedIllness.label)
										.join()}
									style={{ padding: 0 }}
								/>
							) : (
								<div
									className={classes.fieldContainerPaddingTopRehabMultiSelect}
								>
									<MultiSelectInputField
										idPrefix={HorseManagementKeys.ILLNESS}
										fieldLabel={`Illness Type*`}
										options={referenceIllnessList}
										selectedIds={illnessType}
										onSelectChange={(vals) => {
											setIllnessType(vals);
											setShouldValidate(true);
										}}
										otherDisabled={true}
									/>
									{isShowErrorMessages && (
										<span className={classes.errorText}>
											{uncontrolledErrors.illnessType?.message}
										</span>
									)}
								</div>
							)}
						</Col>
					)}
				</Row>
				{rehabReason == reasonForRehab.OTHER && (
					<Row>
						<Col xs={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										name="rehabReasonOther"
										title={'Other Retirement Rehabilitation*'}
										id={HorseManagementKeys.OTHER_REHABILITATION_REASON}
										value={rehabReasonOther}
										onChange={(e) => {
											setRehabReasonOther(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
								</div>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.rehabReasonOther?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}
				{injuryType.map((i) => {
					if (i.label === 'Other') {
						return (
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												name="deceasedInjuryDetailsOther"
												title={'Other injury details*'}
												id="acknowledgedFoster-deceasedInjuryDetailsOther"
												value={deceasedInjuryDetailsOther}
												onChange={(e) => {
													setDeceasedInjuryDetailsOther(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{uncontrolledErrors.deceasedInjuryDetailsOther?.message}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}
				{illnessType.map((i) => {
					if (i.label === 'Other') {
						return (
							<Row>
								<Col xs={12}>
									<div className={classes.fieldContainer}>
										<div className={classes.fieldTitle}>
											<ResizingTextArea
												title="Other illness details*"
												name="deceasedIllnessDetailsOther"
												id="acknowledgedFoster-deceasedIllnessDetailsOther"
												value={deceasedIllnessDetailsOther}
												onChange={(e) => {
													setDeceasedIllnessDetailsOther(e.target.value);
													setShouldValidate(true);
												}}
												maxChars={500}
												charLengthStyle={{
													fontWeight: 'normal'
												}}
												styles={{
													marginLeft: 0,
													marginRight: 0,
													padding: 0
												}}
												titleStyles={{ fontSize: '14px' }}
												canEdit={!isReadOnly}
											/>
										</div>
										{isShowErrorMessages && (
											<span className={classes.errorText}>
												{
													uncontrolledErrors.deceasedIllnessDetailsOther
														?.message
												}
											</span>
										)}
									</div>
								</Col>
							</Row>
						);
					}
				})}
				<Row>
					<Col xs={12} lg={4}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Date rehabilitation commenced*"
										value={formatDateReadableVariant(rehabDateCommenced)}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									Date rehabilitation commenced*
								</div>
								<FormDatePicker
									id={HorseManagementKeys.REHABILITATION_COMMENCED}
									DateValue={rehabDateCommenced}
									updateSelection={(value) => {
										updateDate(
											value,
											setRehabDateCommenced,
											validateRehabDateCommenced
										);
										setShouldValidate(true);
									}}
									canEdit={!isReadOnly}
								/>
								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.rehabDateCommenced?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>

				<Row>
					<Col xs={12}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Please provide an update on the horse’s progress and care plan for the week ahead.*"
										value={horseProgressUpdate}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										title="Please provide an update on the horse’s progress and care plan for the week ahead.*"
										id={HorseManagementKeys.HORSE_PROGRESS_UPDATE}
										onChange={(e) => {
											setHorseProgressUpdate(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										value={horseProgressUpdate}
										canEdit={!isReadOnly}
									/>
								</div>

								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.horseProgressUpdate?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>

				<Row>
					<Col xs={12}>
						{isReadOnly ? (
							<Row>
								<Col>
									<FormLabelField
										title="Have you encountered any issues with the horse this week?*"
										value={horseIssuesEncountered}
									/>
								</Col>
							</Row>
						) : (
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										title="Have you encountered any issues with the horse this week?*"
										id={HorseManagementKeys.HORSE_ISSUES_ENCOUNTERED}
										onChange={(e) => {
											setHorseIssuesEncountered(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										value={horseIssuesEncountered}
										canEdit={!isReadOnly}
									/>
								</div>

								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.horseIssuesEncountered?.message}
									</span>
								)}
							</div>
						)}
					</Col>
				</Row>
				<>{renderFeedingPlanRequirements()}</>
				<>{renderPhysicalConcernQuestion()}</>
				<>{renderVetTreatmentQuestion()}</>
			</div>
		);
	};

	const renderHasHorseBeenInWorkThisWeekYes = () => {
		return (
			<>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainerAdjustedPaddingTop}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									title="Details of work sessions this week*"
									onChange={(e) => {
										setDetailsOfWorkSessionThisWeek(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									value={detailsOfWorkSessionThisWeek}
									canEdit={!isReadOnly}
								/>
							</div>

							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.detailsOfWorkSessionThisWeek?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainerAdjustedPaddingTop}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									title="Key progress/achievements to date*"
									onChange={(e) => {
										setKeyProgressAchievementsToDate(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									value={keyProgressAchievementsToDate}
									canEdit={!isReadOnly}
								/>
							</div>

							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.keyProgressAchievementsToDate?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainerAdjustedPaddingTop}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									title="What area(s) need extra work / attention?*"
									onChange={(e) => {
										setAreaNeedExtraWorkAttention(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									value={areaNeedExtraWorkAttention}
									canEdit={!isReadOnly}
								/>
							</div>

							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.areaNeedExtraWorkAttention?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
			</>
		);
	};

	const renderFoundationFields = () => {
		return (
			<div className={classes.formFieldsContainer}>
				<Row>
					<DateFoundationTraining />
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Has the horse been in work this week?
							</div>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setShouldValidate(true);
									setHasHorseBeenInWorkThisWeek(value);
								}}
								value={hasHorseBeenInWorkThisWeek}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
				{hasHorseBeenInWorkThisWeek ? (
					renderHasHorseBeenInWorkThisWeekYes()
				) : (
					<Row>
						<Col xs={12}>
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										title="Please explain why*"
										onChange={(e) => {
											setHasHorseBeenInWorkThisWeekExplaination(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										value={hasHorseBeenInWorkThisWeekExplaination}
										canEdit={!isReadOnly}
									/>
								</div>

								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.hasHorseBeenInWorkThisWeekExplaination
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col xs={12}>
						<div className={classes.fieldContainerAdjustedPaddingTop}>
							<div className={classes.fieldTitle}>
								<ResizingTextArea
									title="Work plan for the coming week?*"
									onChange={(e) => {
										setWorkPlanForTheComingWeek(e.target.value);
										setShouldValidate(true);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									value={workPlanForTheComingWeek}
									canEdit={!isReadOnly}
								/>
							</div>

							{isShowErrorMessages && (
								<span className={classes.errorText}>
									{uncontrolledErrors.workPlanForTheComingWeek?.message}
								</span>
							)}
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Are there any lameness or physical health concern?
							</div>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setAnyLamenessOrPhysicalHealthConcern(value);
									setShouldValidate(true);
								}}
								value={anyLamenessOrPhysicalHealthConcern}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
				{anyLamenessOrPhysicalHealthConcern ? (
					<Row>
						<Col xs={12}>
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										title="Please give details*"
										onChange={(e) => {
											setPhysicalHealthConcern(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										value={physicalHealthConcern}
										canEdit={!isReadOnly}
									/>
								</div>

								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{uncontrolledErrors.physicalHealthConcern?.message}
									</span>
								)}
							</div>
						</Col>
					</Row>
				) : null}

				<Row>
					<Col xs={12} lg={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>
								Has the horse required vet attention or treatment in the past
								week?
							</div>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => {
									setHorseRequiredVetAttention(value);
									setShouldValidate(true);
								}}
								value={horseRequiredVetAttention}
								disabled={isReadOnly}
							/>
						</div>
					</Col>
				</Row>
				{horseRequiredVetAttention ? (
					<Row>
						<Col xs={12}>
							<div className={classes.fieldContainerAdjustedPaddingTop}>
								<div className={classes.fieldTitle}>
									<ResizingTextArea
										title="Please explain why*"
										onChange={(e) => {
											setRequiredVetAttentionExplaination(e.target.value);
											setShouldValidate(true);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										value={requiredVetAttentionExplaination}
										canEdit={!isReadOnly}
									/>
								</div>

								{isShowErrorMessages && (
									<span className={classes.errorText}>
										{
											uncontrolledErrors.requiredVetAttentionExplaination
												?.message
										}
									</span>
								)}
							</div>
						</Col>
					</Row>
				) : null}
			</div>
		);
	};
	return (
		<div className={classes.container}>
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner status={true} />
				</div>
			) : (
				<div className={classes.formContainer}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={classes.formHeading}>
							STAR Weekly Assessment form
						</div>
						<Container>
							<MessageBar>
								<div className={classes.topMessage}>
									We need more information for STAR Weekly progress. Please fill
									in and save all the fields below.
								</div>
							</MessageBar>
							<>{renderAssessmentFields()}</>

							{hasHorseStatusChanged && newStatus == 1 && (
								<>{renderRehabFields()}</>
							)}
							{hasHorseStatusChanged && newStatus == 2 && (
								<>{renderConcludedFields()}</>
							)}
							{hasHorseStatusChanged && newStatus == 3 && (
								<>{renderSpellingFields()}</>
							)}
							{hasHorseStatusChanged && (newStatus == 5 || newStatus == 4) && (
								<>{renderFoundationFields()}</>
							)}
							{newStatus != 2 && (
								<div className={classes.formFieldsContainer}>
									<Row>
										<Col xs={12}>
											<div className={classes.fieldContainerNoPaddingTop}>
												<ResizingTextArea
													title="Do you have any other concerns to report? Changes to your Initial Assessment?"
													id={HorseManagementKeys.OTHER_CONCERNS}
													onChange={(e) => setOtherConcerns(e.target.value)}
													maxChars={500}
													charLengthStyle={{
														fontWeight: 'normal'
													}}
													value={otherConcerns}
													canEdit={!isReadOnly}
												/>
											</div>
										</Col>
									</Row>
								</div>
							)}
							{(!weekId || weekId == 0) && (
								<div className={classes.formFieldsContainer}>
									<Row>
										<Col xs={12}>
											<div className={classes.fieldReminder}>
												Click on the week number when you return to complete
												your Weekly Form
											</div>
										</Col>
									</Row>
								</div>
							)}
							{imgSrcUrl && (
								<Lightbox
									mainSrc={imgSrcUrl}
									onCloseRequest={() => setImgSrcUrl(false)}
								/>
							)}
							<hr className={classes.IdentificationDivider}></hr>

							<div
								style={{
									display: 'flex',
									justifyContent: `${
										isReadOnly ? 'flex-end' : 'space-between'
									}`,
									alignItems: 'center'
								}}
							>
								{!isReadOnly && (
									<div
										id={CommonKeys.CLEAR_FORM}
										className={classes.clearFormLink}
										onClick={() => {
											setConfirmationModal(true);
										}}
									>
										Clear Form
									</div>
								)}
								<div
									className={
										isSmallScreen
											? classes.saveRequestMobile
											: classes.saveRequest
									}
								>
									<span className={isSmallScreen ? null : classes.saveButton}>
										<PrimaryButton
											id={CommonKeys.SAVE}
											isNonSubmit={false}
											style={{ minWidth: '170px' }}
											onClick={() => handleSave()}
											disabled={isReadOnly}
										>
											Save
										</PrimaryButton>
									</span>
									<span>
										<PrimaryButton
											id={HorseManagementKeys.SUBMIT_REPORT}
											isNonSubmit={false}
											disabled={disableSubmitButton || isReadOnly}
											style={{ minWidth: '170px' }}
											onClick={() => handleOnSubmit()}
										>
											Submit Report
										</PrimaryButton>
									</span>
								</div>
							</div>
						</Container>
						{imgSrcUrl && (
							<Lightbox
								mainSrc={imgSrcUrl}
								onCloseRequest={() => setImgSrcUrl(false)}
							/>
						)}
					</form>
					<ConfirmationModal
						showModal={confirmationModal}
						closeModal={() => setConfirmationModal(false)}
						styles={modalStylesConfirmation}
						heading="Are you sure you want to clear the form?"
						positiveLabel="Confirm"
						negativeLabel="Cancel"
						onClickPositive={handleCancel}
					>
						Cancel quarterly progress form?
					</ConfirmationModal>
					<UploadPhotoErrorModal
						showErrorModal={showImageUploadErrorModal}
						closeModal={() => setShowImageUploadErrorModal(false)}
						isMinimumUploadSize={isMinimumUploadSize}
					/>
				</div>
			)}
		</div>
	);
};

export default AcknowledgedFosterWeeklyAssessmentForm;

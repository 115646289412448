import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  image: {
    width: 590,
    objectFit: "cover",
    borderRadius: "5px 0px 0px 5px",
  },
  imageSmall: {
    width: 350,
    objectFit: "cover",
    borderRadius: "5px 0px 0px 5px",
  },
  imageMobile: {
    width: "100%",
    objectFit: "cover",
    height: 200,
    borderRadius: "5px 5px 0px 0px",
  },
  video: {
    width: 590,
    objectFit: "cover",
    borderRadius: "5px 0px 0px 5px",
    "&:focus": {
      outline: "none",
    },
  },
  videoSmall: {
    width: 350,
    borderRadius: "5px 0px 0px 5px",
    "&:focus": {
      outline: "none",
    },
  },
  videoMobile: {
    width: "100%",
    background: "black",
    height: 200,
    borderRadius: "5px 5px 0px 0px",
    "&:focus": {
      outline: "none",
    },
  },
}));

import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles({
	followingTabContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column-reverse',
		'@media (min-width:992px)': {
			flexDirection: 'row'
		}
	},
	tabHeading: {
		color: '#464749',
		fontSize: 15,
		fontWeight: 600,
		marginBottom: 15,
		marginTop: 0,
		textAlign: 'center',
		'@media (min-width: 992px)': {
			fontSize: 20
		}
	},
	quickLinksColumn: {
		paddingTop: 0
		// '@media (min-width: 992px)': {
		// 	paddingTop: 32
		// }
	},
	suggestionsContainer: {
		width: '100%',
		display: 'flex',
		marginTop: 20,
		flexDirection: 'column'
		// flexDirection: 'column-reverse',
		// '@media (min-width:992px)': {
		// 	flexDirection: 'row',
		// 	marginTop: 20
		// }
	},
	bannerContainer: {
		background: `url(${BannerImage})`,
		fontSize: 15,
		'@media (min-width: 992px)': {
			marginBottom: 15
		}
	},
	bannerHeading: {
		padding: 15,
		fontSize: 15,
		textAlign: 'center',
		'@media (min-width: 992px)': {
			padding: 20,
			fontSize: 20,
			textAlign: 'center'
		}
	},
	statusChange: {
		color: '#c43294'
	},
	contactRetrainer: {
		color: 'mediumblue !important',
		cursor: 'pointer !important'
	}
});

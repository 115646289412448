import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	dateContainer: {
		width: '100%'
	},
	dateField: {
		border: '1px solid #c7ced1',
		fontSize: 15,
		width: '100%',
		boxSizing: 'border-box',
		height: 39,
		borderRadius: 4,
		'&:focus': {
			outline: 'none'
		}
	}
}));

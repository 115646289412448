import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Lightbox from 'react-image-lightbox';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveOTTStatusAndProgram,
	saveResetExpressRehabilitationFormStatus
} from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import { formatDateReadableVariant } from '../../utils/helpers';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import FormLabelField from '../FormLabelField';
import HeaderBanner from '../HeaderBanner';
import { MAXREASONLENGTH } from './constants';
import UploadPhoto from '../UploadPhoto';
import { getImage } from './utils';
import useStyles from './styles';
import { useAuth } from '../../hooks/useAuth';

const ResetExpressRehabilitationReviewInfo = ({ data, mobile }) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const { currentUser } = useAuth();

	useEffect(() => {
		setRejectReason(data.reviewReasons === null ? '' : data.reviewReasons);
		setNotes(data.reviewNotes === null ? '' : data.reviewNotes);
	}, []);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};
	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(saveResetExpressRehabilitationFormStatus, {
			id: parseInt(data.id),
			reviewNotes: notes,
			reviewReasons: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveResetExpressRehabilitationFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressRehabilitationFormStatus?.error
						?.errorMessage
				);
			}
		});
	};

	const approve = () => {
		setIsProcessingApprove(true);
		const horseCode = data?.horse?.horseCode;
		AppSyncService.execute(saveResetExpressRehabilitationFormStatus, {
			id: parseInt(data.id),
			status: 'Approved',
			reviewNotes: notes,
			reviewReasons: rejectReason
		}).then(async (response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveResetExpressRehabilitationFormStatus?.success) {
				const payload = {
					asOfDate: new Date(),
					horseCode,
					programId: 9,
					statusId: 41,
					memberExternalId: currentUser.UserId
				};
				await AppSyncService.execute(saveOTTStatusAndProgram, payload);
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveResetExpressRehabilitationFormStatus?.error
						?.errorMessage
				);
			}
		});
	};
	return (
		<>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<HeaderBanner
					title="RESET 2 Rehabilitation Conclusion Report"
					mobile={mobile}
					styles={{
						marginBottom: 0,
						marginTop: 0,
						borderRadius: '5px 5px 0px 0px'
					}}
				/>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="Date submitted"
							value={
								data.lastUpdated
									? formatDateReadableVariant(data.lastUpdated)
									: ''
							}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField title="Week Number" value={data.weekId} />
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="How would you describe the horse's temperament?"
							value={data.horseTemperamentDesc}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={12}>
						<FormLabelField
							title="How would you describe the horse's movement?"
							value={data.horseMovementDesc}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Is the horse good for the farrier?"
							value={data.isHorseGoodForFarrier ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseGoodForFarrierDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Is the horse Shod?"
							value={data.isHorseShod ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseShodDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Has the horse been treated by a dentist?"
							value={data.hasHorseBeenTreatedDentist ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseBeenTreatedDentistDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Is the horse currently receiving medication?"
							value={data.isHorseReceivedMedication ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseReceivedMedicationDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Has the horse received any form of exercise as part of the rehabilitation program?"
							value={data.hasHorseReceivedAnyFormOfExercise ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Selected exercise(s)"
							value={data.rehabilitationReportFormExercise
								.map(
									(rehabilitationReportFormExercise) =>
										rehabilitationReportFormExercise.value
								)
								.join(', ')}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Has the horse received any form of groundwork as part of the rehabilitation program?"
							value={data.hasHorseReceivedAnyGroundWork ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Selected ground work(s)"
							value={data.groundWork
								.map((groundWork) => groundWork.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Other details"
							value={data.groundWorkOtherDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Is the horse currently sound?"
							value={data.isHorseSound ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseSoundDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Is the horse ready to commence ridden work for the purpose of retraining and rehoming?"
							value={data.isHorseReadyToCommenceRiddenWork ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isHorseReadyToCommenceRiddenWorkDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Has the horse been cleared by your Veterinarian to return to ridden work?"
							value={data.hasHorseBeenClearedByVet ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.hasHorseBeenClearedByVetDetails}
						/>
					</Col>
					<Col xs={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Veterinary report document
							</div>
							<UploadPhoto
								data={data.vetClearedDocuments}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Once retrained, what disciplines do you anticipate this horse would be suited to?"
							value={data.disciplines
								.map((disciplines) => disciplines.value)
								.join(', ')}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Other details"
							value={data.horseDisciplineDetails}
						/>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="Are there issues that will require ongoing attention (eg joint supplements for arthritis/ medications)?"
							value={data.isRequireOngoingAttention ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={4}>
						<FormLabelField
							title="Additional details"
							value={data.isRequireOngoingAttentionDetails}
						/>
					</Col>
					<Col xs={4}>
						<div className={classes.fieldContainer}>
							<div className={classes.uploadFieldTitle}>
								Veterinary report document
							</div>
							<UploadPhoto
								data={data.requireOngoingAttentionDocuments}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
				<Row className={classes.d}>
					<Col xs={4}>
						<FormLabelField
							title="How many weeks do you intend to retrain this horse for prior to rehoming?"
							value={data.weeksToRetrainForPriorRehoming?.value}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Thoroughbred Details"
				mobile={mobile}
				styles={{ marginBottom: 0, marginTop: 0, borderRadius: '0px' }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row className={classes.d}>
					<Col xs={12} lg={4}>
						<FormLabelField title="Racing Name" value={data.name} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Age" value={data.horse?.age} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Colour" value={data.horse?.colour} />
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				mobile={mobile}
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${data.member?.firstName} ${data.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Email" value={data.member?.email} />
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField title="Contact Number" value={data.member.phone} />
					</Col>
				</Row>
			</div>
			<AdminReviewFormApproval
				notes={notes}
				updateNotes={(value) => setNotes(value)}
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={data.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetExpressRehabilitationReportReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ResetExpressRehabilitationReviewInfo;

import React from 'react';
import ConfirmationModal from '../ConfirmationModal';

const MODAL_STYLES = {
	content: {
		minWidth: 300,
		maxWidth: 400,
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	}
};

const PostUpdateConfirmModal = ({ showModal, onConfirm, onCancel }) => {
	return (
		<ConfirmationModal
			showModal={showModal}
			closeModal={onCancel}
			styles={MODAL_STYLES}
			heading="Post Update"
			positiveLabel="Confirm"
			negativeLabel="Cancel"
			onClickPositive={onConfirm}
		>
			It is the user’s responsibility to ensure any uploaded images meet the{' '}
			<a
				href="/terms"
				target="_blank"
				rel="noreferrer"
				style={{ color: '#006da6' }}
			>
				Terms & Conditions
			</a>{' '}
			of the platform. Racing Victoria may remove the image without notice if
			Terms & Conditions are not met, or the image is of poor quality.
		</ConfirmationModal>
	);
};

export default PostUpdateConfirmModal;

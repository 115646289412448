export const getOHEPData = `
query getOHEPData($horseCode: Int!) {
    retrieveConcludeOHEPConfirmedProviders {
      id
      value
    }
    retrieveConcludeOHEPPrices {
      id
      value
    }
    listAreaOfEuthanasia {
        id
        value
      }
      listMethodOfEuthanasia {
        id
        value
      }
      getAdminMembers {
		id
		externalId
		firstName
		lastName
      }
      retrieveConcludeOHEP(horseCode: $horseCode) {
        acceptanceNotice
        areaOfEuthanasia
        byMemberId
        collectionDate
        confirmedProvider
        created
        euthanasiaDate
        euthanasiaInvoiceReceived
        horseCode
        horseRemoved
        id
        invoiceToProcurement
        lastReviewed
        lastUpdated
        methodOfEuthanasia
        noticeToProceed
        providerOfEuthanasia
        providerWhoCollected
        removalInvoiceReceived
        status
        totalCost
      }
  }
`;

export const concludeOHEP = `
mutation concludeOHEP($input: ConcludeOHEPFormInput) {
    concludeOHEP(input: $input){
        error {
            errorCode
            errorDetails
            errorMessage
        }
        id
        message
        success
    }
}`;

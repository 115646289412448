import { createUseStyles } from 'react-jss';

export default createUseStyles({
	carouselItemContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	horseName: {
		color: '#006da6',
		fontWeight: 'bold',
		fontSize: 15,
		margin: '0px 0px 1px 0px',
		cursor: 'pointer'
	},
	updateCreatedTime: {
		marginBottom: 0,
		marginTop: 3,
		color: 'grey',
		fontSize: 13
	},
	horseMessage: {
		fontSize: 15,
		color: '#464749'
	},
	suggestedHorseImage: {
		borderRadius: 5,
		width: '100%',
		height: '100%'
	},
	objectFitContainVal: {
		objectFit: 'contain !important'
	},
	cardContainer: {
		width: '100%',
		height: 'auto',
		marginRight: 'auto',
		marginLeft: 'auto',
		maxWidth: '55vh'
	},
	startFollowingBtn: {
		minWidth: 150,
		maxWidth: 160,
		marginTop: 0,
		height: 38,
		minHeight: 38,
		padding: 0
	}
});

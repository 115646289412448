import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from '../ResetFormReviewInfo/styles';
import HeaderBanner from '../HeaderBanner';
import UploadPhoto from '../UploadPhoto';
import AppSyncService from '../../graphql/AppSyncService';
import Lightbox from 'react-image-lightbox';
import FormLabelField from '../FormLabelField';
import AdminReviewFormApproval from '../AdminReviewFormApproval';
import {
	reviewShareInformationForm,
	saveAdminAction,
	saveHorseLocation
} from '../../graphql/custom.queries';
import { navigate } from '@reach/router';
import { useToast } from '../../hooks/useToast';
import { MAXREASONLENGTH } from '../ResetFormReviewInfo/constants';
import { getImage } from '../ResetFormReviewInfo/utils';
import AdminActions from '../AdminActions';
import SNSAdmin from '../SNSAdmin';
import { formatDateReadableVariant, sortArray } from '../../utils/helpers';
import FormTagsListField from '../FormTagsListField';
import { useAuth } from '../../hooks/useAuth';

const ShareInformationDetailsView = ({
	shareInformationFormData,
	adminActionList,
	mobile
}) => {
	const classes = useStyles();
	const { addToast } = useToast();
	const [imgSrcUrl, setImgSrcUrl] = useState(null);
	const [isProcessingReject, setIsProcessingReject] = useState(false);
	const [isProcessingApprove, setIsProcessingApprove] = useState(false);
	const [notes, setNotes] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [rejectReason, setRejectReason] = useState('');
	const [maxCharacters, setmaxCharacters] = useState(300);
	const [outcomeId, setOutcomeId] = useState(0);
	const [actionMemberId, setActionMemberId] = useState(1);
	const [adminStatusId, setAdminStatusId] = useState(0);
	const [outcomeList, setOutcomeList] = useState([]);
	const [actionMemberList, setActionMemberList] = useState([]);
	const [adminStatusList, setAdminStatusList] = useState([]);
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [adminActions, setAdminActions] = useState(null);
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [error, setError] = useState('');
	const { currentUser } = useAuth();

	useEffect(() => {
		let modifiedOutcomeList = sortArray(
			adminActionList.listAdminActionOutcomes,
			true
		);
		modifiedOutcomeList.unshift({ id: 0, value: '' });
		setOutcomeList(modifiedOutcomeList);
		let modifiedActionList = adminActionList.listAdminActionStauses;
		modifiedActionList.unshift({ id: 0, value: '' });
		setAdminStatusList(modifiedActionList);
		let memberList = [];
		adminActionList.getAdminMembers.map((v) => {
			memberList.push({
				id: v.externalId,
				value: `${v.firstName} ${v.lastName}`
			});
		});
		setActionMemberList(memberList);

		setAdminActions(shareInformationFormData.adminAction);
		let memberId = shareInformationFormData.adminAction?.admin?.externalId
			? shareInformationFormData.adminAction?.admin?.externalId
			: currentUser?.UserId;
		let outComeId = shareInformationFormData.adminAction?.adminOutcome?.id
			? shareInformationFormData.adminAction?.adminOutcome?.id
			: 0;
		let adminStatus = shareInformationFormData.adminAction?.adminStatus?.id
			? shareInformationFormData.adminAction?.adminStatus?.id
			: 0;
		setOutcomeId(outComeId);
		setNotes(shareInformationFormData.reviewNotes);
		setRejectReason(shareInformationFormData.reviewMessages);
		setActionMemberId(memberId);
		setAdminStatusId(adminStatus);
	}, [shareInformationFormData, adminActionList]);

	const updateRejectReason = (val) => {
		setRejectReason(val);
		let valueLength = val.length;
		setmaxCharacters(MAXREASONLENGTH - valueLength);
	};

	const updateFields = (name, value) => {
		if (name === 'outcome') {
			setOutcomeId(value);
		}
		if (name === 'adminStatus') {
			setAdminStatusId(value);
		}

		if (name === 'memberId') {
			setActionMemberId(value);
		}
		setIsAdminActionUpdated(true);
	};

	const reject = () => {
		setIsProcessingReject(true);
		AppSyncService.execute(reviewShareInformationForm, {
			id: parseInt(shareInformationFormData.id),
			reviewNotes: notes,
			reviewMessages: rejectReason,
			status: 'Rejected'
		}).then((response) => {
			setIsProcessingReject(false);
			if (response.data?.saveShareInformationFormStatus?.success) {
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				updateAdminActions(true);
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveShareInformationFormStatus?.error?.errorMessage
				);
			}
		});
	};

	const approve = (reviewStatus = false) => {
		if (!reviewStatus) {
			setIsProcessingApprove(true);
		}
		AppSyncService.execute(reviewShareInformationForm, {
			id: parseInt(shareInformationFormData.id),
			reviewNotes: notes,
			status: reviewStatus ? reviewStatus : 'Approved'
		}).then((response) => {
			setIsProcessingApprove(false);
			if (response.data?.saveShareInformationFormStatus?.success) {
				if (shareInformationFormData.updateLocationAndStatus) {
					AppSyncService.execute(saveHorseLocation, {
						input: {
							address: shareInformationFormData.address,
							country: shareInformationFormData.country,
							horseCode: +shareInformationFormData.horse_code,
							postcode: shareInformationFormData.postcode,
							state: shareInformationFormData.state,
							suburb: shareInformationFormData.suburb,
							sourceId: 3 //@NOTE: 3 is equal to Share Information form
						}
					}).then((result) => {
						if (result?.data?.saveHorseLocation) {
							console.log('horse location success');
						} else {
							addToast({
								Message: 'Failed to save horse location',
								IsSuccess: false
							});
						}
					});
				}
				addToast({
					Message: 'Form updated',
					IsSuccess: true
				});
				updateAdminActions(true);
				navigate(`/review-list`);
			} else {
				addToast({ Message: 'Error processing form', IsSuccess: false });
				setErrorMessage(
					response.data?.saveShareInformationFormStatus?.error?.errorMessage
				);
			}
		});
	};

	const updateReviewNotes = (val) => {
		setNotes(val);
		setIsAdminActionUpdated(true);
	};

	const updateAdminActions = (onlyUpdateAdminAction) => {
		setIsLoadingAdminAction(true);
		AppSyncService.execute(saveAdminAction, {
			formId: parseInt(shareInformationFormData.id),
			formType: 'ShareInformation',
			memberExternalId: actionMemberId,
			adminOutcomeId: parseInt(outcomeId) === 0 ? null : parseInt(outcomeId),
			adminStatusId:
				parseInt(adminStatusId) === 0 ? null : parseInt(adminStatusId),
			id:
				adminActions.admin.id === actionMemberId ? adminActions.admin.id : null
		}).then((data) => {
			if (
				data.data != null &&
				!data.data?.saveAdminAction?.error?.errorMessage
			) {
				if (!onlyUpdateAdminAction) {
					approve(shareInformationFormData.status);
				} else {
					addToast({ Message: 'Updated Admin Action', IsSuccess: true });
					navigate(`/review-list`);
				}
			} else {
				addToast({ Message: 'Failed to Update', IsSuccess: false });
				setError(data.data?.saveAdminAction?.error?.errorMessage);
			}
		});
	};

	const displayEuthanisedFields = () => {
		return (
			<Fragment>
				{shareInformationFormData.newStatus?.value === 'Deceased' && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Date Of Death"
							value={
								shareInformationFormData.naturalDateOfDeath ||
								shareInformationFormData.ohepDate ||
								shareInformationFormData.knackeryDate ||
								shareInformationFormData.deceasedUnknownDate ||
								shareInformationFormData.deceasedOtherDate ||
								shareInformationFormData.euthanisedDate
							}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedReason?.value && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Reason"
							value={shareInformationFormData.euthanisedReason?.value}
						/>
					</Col>
				)}
				{shareInformationFormData.illnesses?.length > 0 && (
					<Col xs={12} lg={4}>
						<FormTagsListField
							title="Euthanised Illness Types"
							value={shareInformationFormData.illnesses.map(
								({ value }) => value
							)}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedIllnessOther && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Illness Type Other"
							value={shareInformationFormData.euthanisedIllnessOther}
						/>
					</Col>
				)}
				{shareInformationFormData.injuries?.length > 0 && (
					<Col xs={12} lg={4}>
						<FormTagsListField
							title="Euthanised Injury Types"
							value={shareInformationFormData.injuries.map(
								({ value }) => value
							)}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedInjuryOther && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Injury Type Other"
							value={shareInformationFormData.euthanisedInjuryOther}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedUnsuitable?.value && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Unsuitable Reason Type"
							value={shareInformationFormData.euthanisedUnsuitable?.value}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedUnsuitableOther && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Unsuitable Reason Type Other"
							value={shareInformationFormData.euthanisedUnsuitableOther}
						/>
					</Col>
				)}
				{shareInformationFormData.euthanisedOther?.value && (
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Euthanised Other Type"
							value={shareInformationFormData.euthanisedOther?.value}
						/>
					</Col>
				)}
			</Fragment>
		);
	};
	return (
		<>
			<HeaderBanner
				title="Thoroughbred Relationship"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.sectionContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Relationship to this horse"
							value={shareInformationFormData?.relationship?.value}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Other Relationship to this horse"
							value={shareInformationFormData?.relationshipOther}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Last seen date"
							value={formatDateReadableVariant(
								shareInformationFormData?.lastSeenDate
							)}
						/>
					</Col>
					<Col></Col>
				</Row>
			</div>
			{shareInformationFormData?.horse && (
				<SNSAdmin horseProfileData={shareInformationFormData.horse} />
			)}
			<HeaderBanner
				title="Thoroughbred Status"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.sectionContainer}>
				<Row>
					<Col xs={12}>
						<FormLabelField
							title="Horse Status"
							value={shareInformationFormData.horse?.status}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Is the listed status of the horse correct?"
							value={shareInformationFormData.isStatusCorrect ? 'Yes' : 'No'}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Please tell us which information needs to be updated about this horse"
							value={shareInformationFormData.informationToUpdate}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Racing Name"
							value={shareInformationFormData.horse?.name}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Age"
							value={shareInformationFormData.horse?.age}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Colour"
							value={shareInformationFormData.horse?.colour}
						/>
					</Col>
				</Row>
				{shareInformationFormData.newStatus?.value && (
					<Row>
						<Col xs={12} lg={4}>
							<FormLabelField
								title="New Status"
								value={shareInformationFormData.newStatus?.value}
							/>
						</Col>
						{shareInformationFormData.newStatus?.value == 'Other' && (
							<Col xs={12}>
								<FormLabelField
									title="Please advise the correct status*"
									value={shareInformationFormData.otherStatusValue}
								/>
							</Col>
						)}
						{shareInformationFormData.causeOfDeath?.value &&
							shareInformationFormData.newStatus?.value === 'Deceased' && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Cause Of Death"
										value={shareInformationFormData.causeOfDeath?.value}
									/>
								</Col>
							)}

						{shareInformationFormData.newStatus?.value === 'Deceased' &&
							displayEuthanisedFields()}
						{shareInformationFormData.knackeryPostcode && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Knackery Postcode"
									value={shareInformationFormData.knackeryPostcode}
								/>
							</Col>
						)}
						{shareInformationFormData.knackerySuburb && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Knackery Suburb"
									value={shareInformationFormData.knackerySuburb}
								/>
							</Col>
						)}
						{shareInformationFormData.deceasedOtherInfo && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Information about death"
									value={shareInformationFormData.deceasedOtherInfo}
								/>
							</Col>
						)}
						{shareInformationFormData.retiredStatusDate &&
							shareInformationFormData.newStatus?.value === 'Retired' && (
								<Col xs={12} lg={4}>
									<FormLabelField
										title="Retired Status Date"
										value={shareInformationFormData.retiredStatusDate}
									/>
								</Col>
							)}
						{shareInformationFormData.retiredSuburb && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Retired Suburb"
									value={shareInformationFormData.retiredSuburb}
								/>
							</Col>
						)}
						{shareInformationFormData.retiredPostcode && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Retired Postcode"
									value={shareInformationFormData.retiredPostcode}
								/>
							</Col>
						)}
						{shareInformationFormData.otherStatusDate && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other Status Date"
									value={shareInformationFormData.otherStatusDate}
								/>
							</Col>
						)}
						{shareInformationFormData.otherStatusSuburb && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other Status Suburb"
									value={shareInformationFormData.otherStatusSuburb}
								/>
							</Col>
						)}
						{shareInformationFormData.otherStatusPostcode && (
							<Col xs={12} lg={4}>
								<FormLabelField
									title="Other Status Postcode"
									value={shareInformationFormData.otherStatusPostcode}
								/>
							</Col>
						)}
					</Row>
				)}
			</div>
			<HeaderBanner
				title="Thoroughbred Photos"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.sectionContainer}>
				<Row>
					<Col>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Photos</div>
							<UploadPhoto
								formats={['png', 'jpeg']}
								data={shareInformationFormData.uploads}
								getImage={(img) => getImage(img, setImgSrcUrl)}
								canEdit={false}
							/>
						</div>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Contact Details"
				inline
				styles={{ marginTop: 0, marginBottom: 0 }}
			/>
			<div
				style={
					mobile
						? { border: 'none' }
						: {
								borderTop: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								borderBottom: 'none'
						  }
				}
				className={
					mobile ? classes.sectionContainerMobile : classes.sectionContainer
				}
			>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Name"
							value={`${shareInformationFormData.member?.firstName} ${shareInformationFormData.member?.lastName}`}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Email"
							value={shareInformationFormData.member?.email}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Contact Number"
							value={
								shareInformationFormData.member.phone ||
								shareInformationFormData.member.businessPhone
							}
						/>
					</Col>
				</Row>
			</div>
			<HeaderBanner
				title="Address and Supporting Information"
				inline
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '0px' }}
			/>
			<div className={classes.sectionContainer}>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Horse Address"
							value={shareInformationFormData.address}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Horse Suburb"
							value={shareInformationFormData.suburb}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Horse State"
							value={shareInformationFormData.state}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Horse Postcode"
							value={shareInformationFormData.postcode}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<FormLabelField
							title="Horse Country"
							value={shareInformationFormData.country}
						/>
					</Col>
				</Row>
				<hr className={classes.divider} />
				<Row>
					<Col xs={12}>
						<FormLabelField
							title="Is there any additional information that needs to be changed or updated for this horse?"
							value={shareInformationFormData.info}
						/>
					</Col>
					<Col xs={12}>
						<FormLabelField
							title="Confirmed accurate information"
							value={
								shareInformationFormData.confirmAccurateInfo ? 'Yes' : 'No'
							}
						/>
					</Col>
				</Row>
			</div>
			<AdminActions
				actionMemberId={actionMemberId}
				adminStatusId={adminStatusId}
				outComeId={outcomeId}
				memberList={actionMemberList}
				statusList={adminStatusList}
				outComeList={outcomeList}
				formName="shareInformation"
				reviewNotes={notes}
				updateFields={updateFields}
				reviewStatus={shareInformationFormData.status}
				updateReviewNotes={updateReviewNotes}
				isAdminActionUpdated={isAdminActionUpdated}
				isLoading={isLoadingAdminAction}
				updateAdminAction={() => updateAdminActions(false)}
				error={error}
				noBorderBottom
			/>
			<AdminReviewFormApproval
				noHeader
				rejectReason={rejectReason}
				updateRejectReason={(value) => updateRejectReason(value)}
				mobile={mobile}
				isProcessingApprove={isProcessingApprove}
				isProcessingReject={isProcessingReject}
				processReject={reject}
				processApprove={approve}
				status={shareInformationFormData.status}
				errorMessage={errorMessage}
				maxReasonCharacters={maxCharacters}
				totalLength={300}
				formName="resetWeeklyReviewForm"
			/>
			{imgSrcUrl && (
				<Lightbox
					mainSrc={imgSrcUrl}
					onCloseRequest={() => setImgSrcUrl(false)}
				/>
			)}
		</>
	);
};
export default ShareInformationDetailsView;

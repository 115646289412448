import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	pageContainer: {
		maxWidth: '1000px !important',
		marginBottom: 150,
		paddingRight: '0 !important',
		paddingLeft: '0 !important',
		color: '#464749'
	},
	resultColumn: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: 20,
		boxSizing: 'border-box'
	},
	header: {
		fontSize: 20,
		fontWeight: 600,
		marginTop: 20,
		marginBottom: 20
	},
	filterContainer: {
		background: '#dcebf3',
		height: 65
	},
	filterTitle: {
		float: 'left',
		margin: 20,
		fontSize: 15
	},
	filterComponentContainer: {
		width: '98%',
		paddingTop: 12
	},
	resultContainer: {
		maxHeight: 304,
		border: '1px solid #d9dde0',
		borderTop: 'none',
		overflowY: 'auto'
	},
	singleResultContainer: {
		height: 60,
		width: '100%',
		borderBottom: '1px solid #d9dde0',
		display: 'flex',
		fontSize: 15
	},
	name: {
		marginLeft: 20,
		paddingTop: 20,
		fontWeight: 600
	},
	longText: {
		width: 250,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		'@media (max-width:992px)': {
			width: 80
		}
	},
	longTextWithHeading: {
		width: 140,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		'@media (max-width:992px)': {
			width: 80
		}
	},
	nameMobile: {
		width: 80,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	status: {
		paddingTop: 20
	},
	button: {
		textAlign: 'right',
		marginRight: 30,
		width: '100%',
		height: '100%'
	},
	actionButton: {
		display: 'block',
		textAlign: 'center',
		paddingLeft: 26,
		paddingRight: 26,
		borderRadius: 4,
		background: '#006da6',
		padding: 8,
		color: 'white',
		fontWeight: 600,
		float: 'right',
		marginTop: 13,
		cursor: 'pointer'
	},
	actionButtonMobile: {
		display: 'block',
		textAlign: 'center',
		borderRadius: 4,
		background: '#006da6',
		padding: 8,
		color: 'white',
		fontWeight: 600,
		float: 'right',
		marginTop: 13
	},
	toggleHeader: {
		display: 'inline',
		padding: 10,
		cursor: 'pointer',
		borderRadius: 50,
		color: '#006da6',
		fontSize: 15
	},
	scrollableTabsContainer: {
		marginBottom: 20
	},
	email: {
		color: '#464749',
		fontSize: 13,
		fontWeight: 600,
		marginBottom: 5
	},
	buttonContainer: {
		marginTop: 20,
		width: '18%',
		float: 'right'
	},
	eventOrganizer: {
		marginTop: 15,
		'& div': {
			fontSize: 13,
			fontWeight: 600
		},
		'& select': {
			fontSize: 13,
			fontWeight: 400
		}
	},
	sortIcon: {
		float: 'right',
		marginTop: '-1px'
	},
	roles: {
		color: '#464749',
		fontSize: 15,
		fontWeight: 600,
		marginTop: 20
	}
}));

export const getCurrentCompetition = `
query getCompetition {
    getCompetition {
        id
        name
        endDate
        notificationText
    }
  }
`;

export const checkUserCanEnterCompetition = `
query checkUserCanEnterCompetition($memberExternalId: String, $competitionId: Int) {
    getCompetitionEntries(memberExternalId: $memberExternalId, competitionId: $competitionId) {
        id
    }
  }
`;

export const addCompetitionEntry = `
mutation addCompetitionEntry($input: CompetitionEntryInput) {
    addCompetitionEntry(input: $input)
}
`;

export const dismissCompetitionNotification = `
mutation dismissCompetitionNotification {
    dismissCompetitionNotification
}
`;

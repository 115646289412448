import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formContainer: {
		marginTop: 14
	},
	divider: {
		background: '#dfedf4',
		height: 1,
		border: 'none',
		marginTop: 20,
		marginBottom: 20
	},
	cancelListingContainer: {
		marginTop: 10
	},
	cancelListingField: {
		marginBottom: 10,
		marginTop: 10,
		fontWeight: 600
	},
	cancelListingFieldMoreInfo: {
		marginBottom: 10,
		marginTop: 15,
		fontWeight: 600
	},
	remainingCharacters: {
		color: '#98999d',
		fontWeight: 100,
		float: 'right'
	},
	cancelListingCancelButton: {
		float: 'left',
		marginTop: 10,
		color: '#1b7caf',
		textDecoration: 'underline',
		cursor: 'pointer'
	},
	cancelListingSubmit: {
		width: 135,
		float: 'right'
	},
	clear: {
		clear: 'both'
	},
	cancelRequest: {
		textDecoration: 'underline',
		color: '#0085ca',
		fontSize: 16,
		marginTop: 10,
		cursor: 'pointer'
	}
}));

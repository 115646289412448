import { navigate } from 'gatsby-link';
import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import PrimaryButton from '../PrimaryButton';
import useStyles from './styles';
import {
	ROLE_RV_EQUINE_WELFARE,
	ROLE_COMPETITION_ADMIN
} from '../../utils/constants';
import { checkUserAdmin } from '../../utils/helpers';
import { EVENT_REPORTING_ROUTE } from '../../utils/constants/routes';

const EventsBanner = () => {
	const classes = useStyles();
	const { currentUser } = useAuth();
	const showAddEvent = currentUser?.Roles.some(
		(role) => role === ROLE_RV_EQUINE_WELFARE || role === ROLE_COMPETITION_ADMIN
	);
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	return (
		<>
			<div className={`${classes.topBanner} ${classes.topBannerInline}`}>
				<div className={classes.topBannerText}>
					<div>Events Calendar</div>

					{showAddEvent && (
						<div className={classes.eventBanner}>
							<PrimaryButton
								onClick={() => navigate(EVENT_REPORTING_ROUTE)}
								id="event-banner-reporting-button"
								className={classes.secondaryButtons}
							>
								Sponsorship & Event Reporting
							</PrimaryButton>
							{isAdminUser && (
								<PrimaryButton
									onClick={() => navigate('/event-drafts')}
									id="event-banner-draft-button"
									className={classes.secondaryButtons}
								>
									View Drafts
								</PrimaryButton>
							)}
							<PrimaryButton
								onClick={() => navigate('/add-event')}
								id="event-banner-add-button"
								style={{ marginLeft: 10, width: 155 }}
							>
								Add New Event
							</PrimaryButton>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default EventsBanner;

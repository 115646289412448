import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	fieldContainer: {
		paddingTop: 15,
		paddingBottom: 10
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	add: {
		background: '#dcebf3',
		padding: 10,
		border: 'none',
		marginLeft: 20,
		borderRadius: 5
	},
	remove: {
		padding: 10,
		borderRadius: 5,
		border: 'none',
		background: '#f4caca'
	},
	options: {
		marginTop: 37
	},
	optionsMobile: {
		marginTop: 5
	}
}));

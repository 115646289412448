import React from 'react';
import { Col } from 'react-grid-system';
import useStyles from './styles';

const AdminReviewDatum = ({
	label,
	value,
	largeScreenWidth = 4,
	isRequired = false
}) => {
	const classes = useStyles();
	return (
		<Col sm={12} lg={largeScreenWidth} style={{ padding: 0 }}>
			<div className={classes.contentContainer}>
				<div className={classes.label}>
					{label}
					{isRequired && '*'}
				</div>
				<div className={classes.dataValue}>{value}</div>
			</div>
		</Col>
	);
};

export default AdminReviewDatum;

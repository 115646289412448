import { createUseStyles } from 'react-jss';
import BannerImage from '../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	topBanner: {
		background: `url(${BannerImage})`,
		backgroundSize: 'contain',
		maxWidth: '1000px',
		minWidth: '100%',
		minHeight: 60,
		marginBottom: 15,
		marginTop: 10,
		borderRadius: 5
	},
	topBannerInline: {
		borderRadius: 5
	},
	topBannerDefault: {
		borderRadius: '5px 5px 0px 0px'
	},
	topBannerText: {
		padding: '20px 0px 20px 20px',
		display: 'inline-flex',
		justifyContent: 'space-between',
		width: '95%',
		color: '#464749',
		fontSize: 20,
		fontWeight: 800,
		alignItems: 'center'
	},
	secondaryButtons: {
		marginLeft: 10,
		width: 155,
		backgroundColor: 'rgb(220 235 243)',
		color: 'rgb(0 109 166)'
	},
	eventBanner: {
		display: 'flex',
		flexDirection: 'column',
		paddingRight: 56
	},
	'@media only screen and (min-width: 64.063em)': {
		eventBanner: {
			display: 'flex',
			flexDirection: 'row',
			paddingRight: 0
		}
	}
}));

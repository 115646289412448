import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/styles/withStyles';

const StyledSelect = withStyles({
	root: {
		background: 'none',
		border: 'none',
		paddingTop: 0,
		paddingBottom: 0,
		color: '#006da6',
		fontWeight: 'bold',
		textDecoration: 'underline',
		'&:focus': { background: 'none' }
	},
	icon: { color: '#006da6' }
})(Select);

const ClaimedHorseSelect = ({
	idPrefix,
	selectOptions,
	selectedValue,
	onChange
}) => {
	return (
		<StyledSelect
			id={`${idPrefix}-claimed-horse-select`}
			value={selectedValue}
			onChange={onChange}
			inputProps={{ 'aria-label': 'Without label' }}
			disableUnderline
		>
			{selectOptions.map((option) => (
				<MenuItem key={`${option.id}-${option.value}`} value={option.id}>
					{option.value}
				</MenuItem>
			))}
		</StyledSelect>
	);
};

export default ClaimedHorseSelect;

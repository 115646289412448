import { DEFAULT_COUNTRY } from '../../utils/constants';

export const getDataModel = (
	stateVal,
	data,
	relationshipList,
	retirementList,
	formId
) => {
	let dataModel = { ...stateVal };
	dataModel = {
		registeredOwner: {
			...dataModel.registeredOwner,
			value: data.iAmRegisteredOwner ? true : false
		},
		registeredManagingOwner: {
			...dataModel.registeredManagingOwner,
			value: data.iAmRegisteredManagingOwner ? true : false
		},
		proofOwner: {
			...dataModel.proofOwner,
			value:
				data.attachedProofOfPurchase || data.proofOfPurchaseDocuments.length > 0
					? true
					: false
		},
		proofOfPurchaseDocuments: {
			...dataModel.proofOfPurchaseDocuments,
			value: data.proofOfPurchaseDocuments
		},
		racingName: {
			...dataModel.racingName,
			value: data.horse?.name
		},
		age: {
			...dataModel.age,
			value: data.horse?.age
		},
		colour: {
			...dataModel.colour,
			value: data.horse?.colour
		},
		sireName: {
			...dataModel.sireName,
			value: data.sireName
		},
		damName: {
			...dataModel.damName,
			value: data.damName
		},
		approxHorseHeight: {
			...dataModel.approxHorseHeight,
			value: data.horseHeight
		},
		reasonForRetirement: {
			...dataModel.reasonForRetirement,
			value: data.retirementReason.id
		},
		reasonForRetirementOther: {
			...dataModel.reasonForRetirementOther,
			value: data.retirementReasonOther
		},
		addressValidated: {
			...dataModel.addressValidated,
			value: data.addressValidated ?? true
		},
		address: {
			...dataModel.address,
			value: data.address
		},
		postcode: {
			...dataModel.postcode,
			value: data.postcode
		},
		suburb: {
			...dataModel.suburb,
			value: data.suburb
		},
		state: {
			...dataModel.state,
			value: data.state
		},
		country: {
			...dataModel.country,
			value: data.country || DEFAULT_COUNTRY
		},
		residentialAddressValidated: {
			...dataModel.residentialAddressValidated,
			value: data.residentialAddressValidated ?? true
		},
		residentialAddress: {
			...dataModel.residentialAddress,
			value: data.residentialAddress
		},
		residentialPostcode: {
			...dataModel.residentialPostcode,
			value: data.residentialPostcode
		},
		residentialSuburb: {
			...dataModel.residentialSuburb,
			value: data.residentialSuburb
		},
		residentialState: {
			...dataModel.residentialState,
			value: data.residentialState
		},
		residentialCountry: {
			...dataModel.residentialCountry,
			value: data.residentialCountry || DEFAULT_COUNTRY
		},
		contactName: {
			...dataModel.contactName,
			value: `${data.member?.firstName} ${data.member?.lastName}`
		},
		contactEmail: {
			...dataModel.contactEmail,
			value: data.member?.email
		},
		contactNumber: {
			...dataModel.contactName,
			value: data.member?.phone
		},
		relationship: {
			...dataModel.relationship,
			value: data.relationshipToHorse.id
		},
		otherRelationship: {
			...dataModel.otherRelationship,
			value: data.relationshipToHorseOther
		},
		relationshipLength: {
			...dataModel.relationshipLength,
			value: data.lengthOfRelationship.id
		},
		currentOwners: {
			...dataModel.currentOwners,
			value: data.currentOwners
		},
		currentTrainers: {
			...dataModel.currentTrainers,
			value: data.currentRetrainers
		},
		windSuck: {
			...dataModel.windSuck,
			value: data.isKnownToWindsuck.id
		},
		injured: {
			...dataModel.injured,
			value:
				data?.injuries?.length > 0 ||
				data.vetDocuments.length > 0 ||
				formId === null
					? true
					: false
		},
		vetReports: {
			...dataModel.vetReports,
			value: data.vetDocuments
		},
		injuryTypes: {
			...dataModel.injuryTypes,
			value: getInjuryTypes(data.injuries)
		},
		injuryNature: {
			...dataModel.injuryNature,
			value: data.injuriesDetails
		},
		heritableDisorder: {
			...dataModel.heritableDisorder,
			value: data.heritableDisorder ? true : false
		},
		heritableDisorderDetails: {
			...dataModel.heritableDisorderDetails,
			value: data.heritableDisorder
		},
		disease: { ...dataModel.disease, value: data.disease ? true : false },
		diseaseDetails: {
			...dataModel.diseaseDetails,
			value: data.disease
		},
		accidents: {
			...dataModel.accidents,
			value: data.accidents ? true : false
		},
		accidentsDetails: {
			...dataModel.accidentsDetails,
			value: data.accidents
		},
		eyeDisease: {
			...dataModel.eyeDisease,
			value: data.eyeIssues ? true : false
		},
		eyeDiseaseDetails: {
			...dataModel.eyeDiseaseDetails,
			value: data.eyeIssues
		},
		lameness: {
			...dataModel.lameness,
			value: data.lameness ? true : false
		},
		lamenessDetails: {
			...dataModel.lamenessDetails,
			value: data.lameness
		},
		headShaking: {
			...dataModel.headShaking,
			value: data.headShaking ? true : false
		},
		headShakingDetails: {
			...dataModel.headShakingDetails,
			value: data.headShaking
		},
		vices: { ...dataModel.vices, value: data.vices ? true : false },
		vicesDetails: {
			...dataModel.vicesDetails,
			value: data.vices
		},
		abnormality: {
			...dataModel.abnormality,
			value: data.breathingAbnormalities ? true : false
		},
		abnormalityDetails: {
			...dataModel.abnormalityDetails,
			value: data.breathingAbnormalities
		},
		surgical: {
			...dataModel.surgical,
			value: data.surgicalProcedures ? true : false
		},
		surgicalDetails: {
			...dataModel.surgicalDetails,
			value: data.surgicalProcedures
		},
		vaccination: {
			...dataModel.vaccination,
			value: data.vaccinationsAdministered ? true : false
		},
		vaccinationDetails: {
			...dataModel.vaccinationDetails,
			value: data.vaccinationsAdministered
		},
		breeding: {
			...dataModel.breeding,
			value: data.breedingHistory ? true : false
		},
		breedingOther: {
			...dataModel.breedingOther,
			value: data.breedingHistory
		},
		temperament: {
			...dataModel.temperament,
			value: data.temperamentDetails
		},
		disciplines: {
			...dataModel.disciplines,
			value: getDisciplines(data.disciplines)
		},
		disciplinesOther: {
			...dataModel.disciplinesOther,
			value: data.disciplinesOther
		},
		riddenHistory: {
			...dataModel.riddenHistory,
			value: data.riddenHistory
		},
		vicesBehaviour: {
			...dataModel.vicesBehaviour,
			value: data.otherReleventDetails
		},
		faceOn: { ...dataModel.faceOn, value: data.faceOnPhotos },
		leftSide: { ...dataModel.leftSide, value: data.leftSidePhotos },
		rightSide: {
			...dataModel.rightSide,
			value: data.rightSidePhotos
		},
		behind: { ...dataModel.behind, value: data.behindFullPhotos },
		additional: {
			...dataModel.additional,
			value: data.additionalPhotos
		},
		rehomingAttempt: {
			...dataModel.rehomingAttempt,
			value: data.rehomingDeclarationMinOfTwo
		},
		noBehaviouralissues: {
			...dataModel.noBehaviouralissues,
			value: data.rehomingDeclarationNoKnownBehaviours
		},
		noHealthIssues: {
			...dataModel.noHealthIssues,
			value: data.rehomingDeclarationNoKnownVeterinary
		},
		responsibleOwner: {
			...dataModel.responsibleOwner,
			value: data.programDeclerationResponsibleOwner
		},
		cantBeReHomed: {
			...dataModel.cantBeReHomed,
			value: data.programDeclerationContinueAsOwner
		},
		rehomingProgram: {
			...dataModel.rehomingProgram,
			value: data.programDeclerationRehomeIntention
		},
		agreeCompletionOfForm: {
			...dataModel.agreeCompletionOfForm,
			value: data.programDeclerationNotGuaranteeAcknowledgement
		},
		disabledAssociation: {
			...dataModel.disabledAssociation,
			value: data.programDeclerationRDAVAcknowledgement
		},
		salePrice: {
			...dataModel.salePrice,
			value: data.programDeclerationRacingVicDeterminesSalePrice
		},
		RVagreement: {
			...dataModel.RVagreement,
			value: data.programDeclerationEntryIntoReHomed
		},
		privacyStatement: {
			...dataModel.privacyStatement,
			value: data.programDeclerationReadPrivacyStatement
		},
		concent: {
			...dataModel.concent,
			value: data.consentToVeterinaryExamination
		},
		transport: {
			...dataModel.transport,
			value: data.isHelpingTransportCosts
		},
		status: {
			value: data.status
		}
	};

	dataModel.otherRelationship.conditions[0].dependantArray = relationshipList;
	dataModel.reasonForRetirementOther.conditions[0].dependantArray = retirementList;

	return dataModel;
};

export const setDataModel = (values, horseCode) => {
	let dataModel = {};
	(dataModel.horseCode = horseCode),
		(dataModel.iAmRegisteredManagingOwner =
			values.registeredManagingOwner.value);
	dataModel.iAmRegisteredOwner = values.registeredOwner.value;
	dataModel.attachedProofOfPurchase = values.proofOwner.value;
	dataModel.sireName = values.sireName.value;
	dataModel.damName = values.damName.value;
	dataModel.horseHeight = values.approxHorseHeight.value;
	dataModel.retirementReasonId = Array.isArray(values.reasonForRetirement.value)
		? null
		: values.reasonForRetirement.value;
	dataModel.retirementReasonOther =
		values.reasonForRetirementOther.conditions[0].dependantArray.filter(
			(v) => v.value === 'Other' && v.id === values.reasonForRetirement.value
		).length > 0
			? values.reasonForRetirementOther.value
			: '';
	dataModel.residentialAddressValidated =
		values.residentialAddressValidated.value;
	dataModel.residentialAddress = values.residentialAddress.value;
	dataModel.residentialSuburb = values.residentialSuburb.value;
	dataModel.residentialPostcode = values.residentialPostcode.value;
	dataModel.residentialState = values.residentialState.value;
	dataModel.residentialCountry = values.residentialCountry.value;
	dataModel.addressValidated = values.addressValidated.value;
	dataModel.address = values.address.value;
	dataModel.suburb = values.suburb.value;
	dataModel.postcode = values.postcode.value;
	dataModel.state = values.state.value;
	dataModel.country = values.country.value;
	dataModel.relationshipToHorseId = values.relationship.value || null;
	dataModel.relationshipToHorseOther =
		values.otherRelationship.conditions[0].dependantArray.filter(
			(v) => v.value === 'Other' && v.id === values.relationship.value
		).length > 0
			? values.otherRelationship.value
			: '';
	dataModel.lengthOfRelationshipId = values.relationshipLength.value || null;
	dataModel.currentOwners = values.currentOwners.value;
	dataModel.currentRetrainers = values.currentTrainers.value;
	dataModel.isKnownToWindsuckId = values.windSuck.value || null;
	dataModel.injuries = values.injured.value;
	dataModel.injuriesDetails = values.injured.value
		? values.injuryNature.value
		: '';
	(dataModel.injuries = values.injured.value
		? setInjuryTypes(values.injuryTypes.value)
		: []),
		(dataModel.heritableDisorder = values.heritableDisorder.value
			? values.heritableDisorderDetails.value
			: '');
	dataModel.disease = values.disease.value ? values.diseaseDetails.value : '';
	dataModel.accidents = values.accidents.value
		? values.accidentsDetails.value
		: '';
	dataModel.eyeIssues = values.eyeDisease.value
		? values.eyeDiseaseDetails.value
		: '';
	dataModel.lameness = values.lameness.value
		? values.lamenessDetails.value
		: '';
	dataModel.headShaking = values.headShaking.value
		? values.headShakingDetails.value
		: '';
	dataModel.vices = values.vices.value ? values.vicesDetails.value : '';
	dataModel.breathingAbnormalities = values.abnormality.value
		? values.abnormalityDetails.value
		: '';
	dataModel.surgicalProcedures = values.surgical.value
		? values.surgicalDetails.value
		: '';
	dataModel.vaccinationsAdministered = values.vaccination.value
		? values.vaccinationDetails.value
		: '';
	dataModel.breedingHistory = values.breeding.value
		? values.breedingOther.value
		: '';
	dataModel.temperamentDetails = values.temperament.value;
	dataModel.otherReleventDetails = values.vicesBehaviour.value;
	dataModel.disciplines = setDisciplines(values.disciplines.value);
	dataModel.disciplinesOther = values.disciplinesOther.value;
	dataModel.riddenHistory = values.riddenHistory.value;
	dataModel.rehomingDeclarationMinOfTwo = values.rehomingAttempt.value;
	dataModel.rehomingDeclarationNoKnownBehaviours =
		values.noBehaviouralissues.value;
	dataModel.rehomingDeclarationNoKnownVeterinary = values.noHealthIssues.value;
	dataModel.programDeclerationResponsibleOwner = values.responsibleOwner.value;
	dataModel.programDeclerationContinueAsOwner = values.cantBeReHomed.value;
	dataModel.programDeclerationRehomeIntention = values.rehomingProgram.value;
	dataModel.programDeclerationNotGuaranteeAcknowledgement =
		values.agreeCompletionOfForm.value;
	dataModel.programDeclerationRDAVAcknowledgement =
		values.disabledAssociation.value;
	dataModel.programDeclerationRacingVicDeterminesSalePrice =
		values.salePrice.value;
	dataModel.programDeclerationEntryIntoReHomed = values.RVagreement.value;
	dataModel.programDeclerationReadPrivacyStatement =
		values.privacyStatement.value;
	dataModel.consentToVeterinaryExamination = values.concent.value;
	dataModel.isHelpingTransportCosts = values.transport.value;

	return dataModel;
};

const setInjuryTypes = (injuries) => {
	let types = [];
	injuries.map((val) => {
		types.push(val.value);
	});
	return types;
};

const setDisciplines = (disciplines) => {
	let types = [];
	disciplines.map((val) => {
		types.push(val.value);
	});
	return types;
};

export const getInjuryTypes = (injuries) => {
	let types = [];
	injuries.map((val) => {
		types.push({ value: val.id, label: val.value });
	});
	return types;
};

export const getDisciplines = (disciplines) => {
	let types = [];
	disciplines.map((val) => {
		types.push({ value: val.id, label: val.value });
	});
	return types;
};

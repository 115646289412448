import React from 'react';
import useStyles from './styles';
import classnames from 'classnames';

const CheckboxInputField = ({
	idPrefix,
	isChecked,
	onChange,
	checkBoxLabel
}) => {
	const classes = useStyles();
	const checkBoxLabelId = `${idPrefix}_checkbox_label`;
	const checkBoxId = `${idPrefix}_checkbox`;
	return (
		<div className={classes.checkBoxContainer}>
			<div
				className={classnames({
					[classes.checkBox]: true,
					[classes.selected]: isChecked
				})}
				role="checkbox"
				aria-checked={isChecked}
				onClick={onChange}
				onKeyPress={onChange}
				tabIndex="0"
				{...(checkBoxLabel && { 'aria-labelledby': checkBoxLabelId })}
				id={checkBoxId}
			/>
			{checkBoxLabel && (
				<label id={checkBoxLabelId} className={classes.checkBoxLabel}>
					{checkBoxLabel}
				</label>
			)}
		</div>
	);
};

export default CheckboxInputField;

import React, { useState, useEffect } from 'react';
import { Container, ScreenClassRender } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import { getThoroughbredFormQuery } from '../../graphql/custom.queries';
import { useParams } from '@reach/router';
import SEO from '../seo';
import HeaderBanner from '../HeaderBanner';
import ThoroughbredDetailsView from '../ThoroughbredDetailsView';
import MemberProfileView from '../MemberProfileView';
import AdminActions from '../AdminActions';
import { navigate } from '@reach/router';
import {
	saveThoroughbredRegistrationStatusMutation,
	getAdminActionOptionsList,
	saveAdminAction
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import { useToast } from '../../hooks/useToast';
import { sortArray } from '../../utils/helpers';
import { useAuth } from '../../hooks/useAuth';

const ThoroughbredRegistrationReview = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	let { id } = useParams();
	const [memberProfileData, setMemberProfileData] = useState(null);
	const [reviewNotesData, setReviewNotesData] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [reviewStatus, setReviewStatus] = useState('');
	const [thoroughbredFormData, setThoroughbredFormData] = useState(null);
	const [outcomeId, setOutcomeId] = useState(0);
	const [actionMemberId, setActionMemberId] = useState(1);
	const [adminStatusId, setAdminStatusId] = useState(0);
	const [outcomeList, setOutcomeList] = useState([]);
	const [actionMemberList, setActionMemberList] = useState([]);
	const [adminStatusList, setAdminStatusList] = useState([]);
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [adminActions, setAdminActions] = useState(null);
	const [error, setError] = useState('');
	const [formError, setFormError] = useState('');
	const [isSubmitted, setIsSubmitted] = useState('');
	const { currentUser } = useAuth();

	const completeForm = (reviewStatus) => {
		setFormError(null);
		AppSyncService.execute(saveThoroughbredRegistrationStatusMutation, {
			guid: id,
			reviewNotes: reviewNotesData,
			status: reviewStatus
		}).then((data) => {
			if (data.data != null) {
				setIsSubmitted(true);
				updateAdminActions(true);
				addToast({ Message: reviewStatus, IsSuccess: true });
				navigate(`/review-list`);
			} else {
				setFormError(
					'Unknown error while saving Thoroughbred Registration review data. Please contact support'
				);
			}
		});
	};

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getAdminActionOptionsList).then((list) => {
			let modifiedOutcomeList = sortArray(
				list.data.listAdminActionOutcomes,
				true
			);
			modifiedOutcomeList.unshift({ id: 0, value: '' });
			setOutcomeList(modifiedOutcomeList);
			let modifiedActionList = list.data.listAdminActionStauses;
			modifiedActionList.unshift({ id: 0, value: '' });
			setAdminStatusList(modifiedActionList);
			let memberList = [];
			list.data.getAdminMembers.map((v) => {
				memberList.push({
					id: v.externalId,
					value: `${v.firstName} ${v.lastName}`
				});
			});
			setActionMemberList(memberList);

			AppSyncService.execute(getThoroughbredFormQuery, { guid: id }).then(
				(result) => {
					if (result?.data?.getThoroughbredForm != null) {
						setThoroughbredFormData(result?.data?.getThoroughbredForm);
						setMemberProfileData(result?.data?.getThoroughbredForm?.member);
						setReviewNotesData(result?.data?.getThoroughbredForm?.reviewNotes);
						setReviewStatus(result?.data?.getThoroughbredForm?.status);
						setAdminActions(result?.data?.getThoroughbredForm?.adminAction);
						let memberId = result?.data?.getThoroughbredForm?.adminAction?.admin
							?.externalId
							? result?.data?.getThoroughbredForm?.adminAction?.admin
									?.externalId
							: currentUser?.UserId;
						let outComeId = result?.data?.getThoroughbredForm?.adminAction
							?.adminOutcome?.id
							? result?.data?.getThoroughbredForm?.adminAction?.adminOutcome?.id
							: 0;
						let adminStatus = result?.data?.getThoroughbredForm?.adminAction
							?.adminStatus?.id
							? result?.data?.getThoroughbredForm?.adminAction?.adminStatus?.id
							: 0;
						setOutcomeId(outComeId);
						setActionMemberId(memberId);
						setAdminStatusId(adminStatus);
						setIsLoading(false);
					}
				}
			);
		});
	}, []);

	const updateAdminActions = (onlyUpdateAdminAction) => {
		setIsLoadingAdminAction(true);
		AppSyncService.execute(saveAdminAction, {
			formId: id,
			formType: 'FindYourThoroughbred',
			memberExternalId: actionMemberId,
			adminOutcomeId: parseInt(outcomeId) === 0 ? null : parseInt(outcomeId),
			adminStatusId:
				parseInt(adminStatusId) === 0 ? null : parseInt(adminStatusId),
			id: adminActions.admin.id === actionMemberId ? adminActions.id : null
		}).then((data) => {
			if (!data.data?.saveAdminAction?.error?.errorMessage) {
				if (!onlyUpdateAdminAction) {
					completeForm(reviewStatus);
				} else {
					addToast({ Message: 'Updated Admin Action', IsSuccess: true });
					navigate(`/review-list`);
				}
			} else {
				setError(data.data?.saveAdminAction?.error?.errorMessage);
			}
		});
	};

	const updateFields = (name, value) => {
		if (name === 'outcome') {
			setOutcomeId(value);
		}
		if (name === 'adminStatus') {
			setAdminStatusId(value);
		}

		if (name === 'memberId') {
			setActionMemberId(value);
		}
		setIsAdminActionUpdated(true);
	};

	const updateReview = (val) => {
		setReviewNotesData(val);
		setIsAdminActionUpdated(true);
	};

	const desktopComponent = (
		<Container className={classes.pageContainer}>
			<SEO title="Find Your Thoroughbred Review" />
			<HeaderBanner
				title={`Find Your Thoroughbred - ${thoroughbredFormData?.horseName}`}
				top
				type="primary"
				rightTitle={`Status: ${reviewStatus}`}
				styles={{ borderRadius: '5px 5px 5px 5px' }}
			/>
			<div>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				{memberProfileData != null && (
					<MemberProfileView
						memberProfileData={memberProfileData}
						isThoroughbredRegistration={true}
					/>
				)}
				{thoroughbredFormData != null && (
					<ThoroughbredDetailsView
						thoroughbredFormData={thoroughbredFormData}
					/>
				)}

				<AdminActions
					actionMemberId={actionMemberId}
					adminStatusId={adminStatusId}
					outComeId={outcomeId}
					memberList={actionMemberList}
					statusList={adminStatusList}
					outComeList={outcomeList}
					formName="ThoroughbredRegistration"
					reviewNotes={reviewNotesData}
					updateFields={updateFields}
					reviewStatus={reviewStatus}
					updateReviewNotes={updateReview}
					isAdminActionUpdated={isAdminActionUpdated}
					isLoading={isLoadingAdminAction}
					updateAdminAction={() => updateAdminActions(false)}
					completeForm={completeForm}
					isSubmitted={isSubmitted}
					error={error}
					formError={formError}
				/>
			</div>
		</Container>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};
export default ThoroughbredRegistrationReview;

import React, { useEffect, useState } from 'react';
import {
	REHABILITATION,
	SOLD,
	OWN_USE,
	OWN_USE_FUNDED,
	RETURN_TO_USER,
	EUTHANISED,
	OWN_USE_LEASED
} from '../../utils/constants';
import useStyles from './styles';
import { ScreenClassRender } from 'react-grid-system';
import { HeaderBanner } from '../Mobile';
import FormMultiSelectList from '../FormMultiSelectList';
import {
	convertToMultiListData,
	formatDate,
	formatDateReadableVariant,
	parseUTCToLocal
} from '../../utils/helpers';
import RetrainerMonthlyDataTable from '../RetrainerMonthlyDataTable';
import ConcludedRetrainingDataTable from '../ConcludedRetrainingDataTable';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getRetrainingThoroughbreds,
	listRetrainingStatuses,
	saveRetrainerHorseStatus,
	horseRetainerPageOptionsList,
	saveRetrainerRehab,
	saveEuthanisedReason,
	saveConcludedSoldRehomed,
	saveConcludedSoldRehomedDisciplines,
	saveConcludedReturnToOwner,
	saveRetrainerOwnPurpose,
	saveConcludedRetainingClientFunded,
	submitRetrainerForm,
	retrieveConcludedRetraining,
	saveFullCircle,
	getMemberProfile,
	unclaimHorse,
	saveForSaleHorseImage,
	saveDeceasedThoroughbredForm
} from '../../graphql/custom.queries';
import { useToast } from '../../hooks/useToast';
import { useAuth } from '../../hooks/useAuth';
import { navigate } from 'gatsby-link';
import { sortArray } from '../../utils/helpers';
import InfoBar from '../InfoBar';
import { MessageBarTypeEnum } from '../../utils/enums';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import FormDatePicker from '../FormDatePicker';
import { Container, Row, Col } from 'react-grid-system';
import BannerImage from '../../assets/images/header_banner.png';
import Accordion from '../Accordion';
import { HorseManagementKeys } from './../../utils/constants/index';
import DataTable from '../DataTable';
import HorseManagementDataRow from '../HorseManagementDataRow';
import { RETRAINER } from '../../utils/constants';
import DynamicAutoSuggestion from '../DynamicAutoSuggestion';

const tableHeadings = [
	'',
	'Name',
	'Funded by',
	'Retraining Status',
	'As of Date',
	'Change Status'
];

const mobileHeadings = null;

const RetrainMonthly = () => {
	const { addToast } = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [isConcludedLoading, setIsConcludedLoading] = useState(false);
	const { currentUser } = useAuth();
	const classes = useStyles();
	const [statusOptions, setStatusOptions] = useState([]);
	const [injuryOptions, setInjuryOptions] = useState([]);
	const [priceOptions, setPriceOptions] = useState([]);
	const [purposeOptions, setPurposeOption] = useState([]);
	const [conclusionPurposeOptions, setConclusionPurposeOptions] = useState([]);
	const [concludedReturnedReasons, setConcludedReturnedReasons] = useState([]);
	const [conclusionReasons, setConclusionReasons] = useState([]);
	const [disciplineOptions, setDisciplineOptions] = useState([]);
	const [selectedStatus, setselectedStatus] = useState([]);
	const [retrainerHorseList, setRetrainerHorseList] = useState([]);
	const [originalHorseList, setOriginalHorseList] = useState([]);
	const [retrainerStatuses, setRetrainerStatuses] = useState([]);
	const [currentSortDirection, setCurrentSortDirection] = useState(null);
	const [lastSubmittedDate, setLastSubmittedDate] = useState(null);
	const [
		concludedRetrainingHorseList,
		setConcludedRetrainingHorseList
	] = useState([]);
	const [concludedDateFrom, setConcludedDateFrom] = useState(null);
	const [concludedDateTo, setConcludedDateTo] = useState(null);
	const [count, setCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [fundingTypes, setFundingTypes] = useState([]);
	const [memberData, setMemberData] = useState(null);
	const [illnessOptions, setIllnessOptions] = useState([]);
	const [rows, setRows] = useState([]);
	const [isMobile, setIsMobile] = useState(false);
	const [selectedSearch, setSelectedSearch] = useState(null);
	const [selectedSearchInput, setSelectedSearchInput] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [filteredSearchList, setFilteredSearchList] = useState([]);

	useEffect(() => {
		let memberExternalId = currentUser?.UserId;
		setIsLoading(true);
		AppSyncService.execute(listRetrainingStatuses).then((data) => {
			try {
				setStatusOptions(
					convertToMultiListData(data.data.listRetrainingStatuses, 'value')
				);
				setRetrainerStatuses(data.data.listRetrainingStatuses);
			} catch {
				addToast({ Message: 'Status List Error', IsSuccess: false });
			}
		});
		getHorseList(memberExternalId);
		getConcludedRetrainingList(memberExternalId);
		AppSyncService.execute(horseRetainerPageOptionsList).then((data) => {
			try {
				let sortedInjuryList = sortArray(data.data.listInjuryDetails);
				setInjuryOptions(sortedInjuryList);
				let sortedIllnessList = sortArray(
					data.data.listAckRetirementIllnessTypes
				);
				setIllnessOptions(sortedIllnessList);
				let priceBracket = data.data.listSoldRehomePriceBrackets;
				priceBracket.unshift({ id: 0, value: '' });
				setPriceOptions(priceBracket);
				let conclusionPurpose = data.data.listHorsePurposes;
				conclusionPurpose.unshift({ id: 0, value: '' });
				setConclusionPurposeOptions(conclusionPurpose);
				let purpose = data.data.listSoldRehomePurposes;
				purpose.unshift({ id: 0, value: '' });
				setPurposeOption(data.data.listSoldRehomePurposes);
				let SortedDicipline = sortArray(
					data.data.listSpecialtyDisciplines,
					true
				);

				setDisciplineOptions(SortedDicipline);
				setConcludedReturnedReasons([
					{ id: 0, value: '' },
					...data.data.listReturnToOwnerReasons
				]);
				setConclusionReasons(
					data.data.listConclusionReasons.map((i) => ({
						id: i.id,
						label: i.value,
						value: i.id,
						isSelected: false
					}))
				);
			} catch {
				addToast({ Message: 'Options list error', IsSuccess: false });
			}
		});
		AppSyncService.execute(getMemberProfile, {
			externalId: memberExternalId
		}).then((data) => {
			setMemberData(data.data?.getMember);
		});
	}, []);

	useEffect(() => {
		if (concludedDateFrom || concludedDateTo) {
			let input = {
				memberExternalId: currentUser?.UserId,
				pageNumber: 0
			};
			if (concludedDateFrom) {
				input['startDate'] = formatDate(concludedDateFrom);
			}
			if (concludedDateTo) {
				input['endDate'] = formatDate(concludedDateTo);
			}
			retrieveConcludingRows(input);
		}
	}, [concludedDateFrom, concludedDateTo]);

	useEffect(() => {
		buildRows();
	}, [retrainerHorseList, fundingTypes]);

	const buildRows = () => {
		const numRows = retrainerHorseList.length;
		const constructedRows = retrainerHorseList.map(
			(acknowledgedRetrainerHorse, index) => {
				acknowledgedRetrainerHorse = {
					...acknowledgedRetrainerHorse,
					fundingType:
						fundingTypes?.find(
							(item) => item.id === acknowledgedRetrainerHorse.funding
						)?.value ||
						fundingTypes?.find(
							(item) => item.id === 1 //Self
						)?.value
				};
				return (
					<HorseManagementDataRow
						horseData={acknowledgedRetrainerHorse}
						key={index}
						index={index}
						isLastRow={numRows === index + 1}
						isMobile={isMobile}
						program={RETRAINER}
						updateCheckBox={updateCheckBox}
						onChangeDropDown={onChangeDropDown}
						statusList={retrainerStatuses}
						onChangeToggle={onChangeToggle}
					/>
				);
			}
		);
		setRows(constructedRows);
	};

	const changePage = (page) => {
		const input = {
			memberExternalId: currentUser?.UserId,
			pageNumber: page
		};
		if (concludedDateFrom) {
			input['startDate'] = formatDate(concludedDateFrom);
		}
		if (concludedDateTo) {
			input['endDate'] = formatDate(concludedDateTo);
		}
		setConcludedRetrainingHorseList([]);
		setIsConcludedLoading(true);
		AppSyncService.execute(retrieveConcludedRetraining(false), {
			input: input
		}).then((data) => {
			if (data?.data?.retrieveConcludedRetraining) {
				setConcludedRetrainingHorseList(data.data.retrieveConcludedRetraining);
				const filteredRetrainingList = data.data.retrieveConcludedRetraining.filter(
					(item) =>
						item.horseName.toLowerCase().includes(searchValue.toLowerCase())
				);
				setFilteredSearchList(filteredRetrainingList);
			}
			setIsConcludedLoading(false);
			setCurrentPage(page);
		});
	};

	const onPageChange = (event, page) => {
		changePage(page);
	};

	const getConcludedRetrainingList = (memberExternalId) => {
		const input = {
			memberExternalId: memberExternalId,
			pageNumber: 0
		};
		retrieveConcludingRows(input);
	};

	const getHorseList = (memberExternalId) => {
		AppSyncService.execute(getRetrainingThoroughbreds, {
			memberExternalId
		}).then((data) => {
			try {
				let horseDataArray = [];
				let horseData =
					data.data.getMember.retrainingData.retrainingThoroughbreds;
				if (data.data.getMember.retrainingData.lastSubmitted?.dateTime) {
					setLastSubmittedDate(
						formatDateReadableVariant(
							parseUTCToLocal(
								data.data.getMember.retrainingData.lastSubmitted.dateTime
							)
						)
					);
				}
				horseData.map((d) => {
					let horse = {
						id: d.horse.horseCode,
						horseName: d.horse.name,
						currentAddress: d.location?.address,
						retrainStatus: d.status.status.value,
						asOfDate: d.status.effectiveDate,
						isSelected: false,
						retrainerStatus: d.status.status.id,
						openForm: false,
						originalStatus: d.status.status.id,
						isSaved: d.status.submitted.id === null ? true : false,
						ottInformation: d.horse.ottInformation,
						funding: d.status.funding || 1
					};
					horseDataArray.push(horse);
				});
				setRetrainerHorseList(horseDataArray);
				let allHorses = JSON.parse(JSON.stringify(horseDataArray));
				setOriginalHorseList(allHorses);
				setFundingTypes(data.data.listFundingTypes);
			} catch (error) {
				addToast({ Message: 'Horse List Error', IsSuccess: false });
			}
			setIsLoading(false);
		});
	};

	const updateHorseList = (value) => {
		setselectedStatus(value);
		let tempList = Object.assign([], originalHorseList);
		let array = [];
		if (value.length > 0) {
			value.map((flist) => {
				let filter = flist.value;
				tempList.map((d) => {
					if (d.retrainerStatus === filter) {
						array.push(d);
					}
				});
			});
			if (currentSortDirection === 1) {
				sortRowsDown(array);
			} else if (currentSortDirection === 2) {
				sortRowsUp(array);
			} else {
				setRetrainerHorseList(array);
			}
		} else {
			if (currentSortDirection === 1) {
				sortRowsDown(tempList);
			} else if (currentSortDirection === 2) {
				sortRowsUp(tempList);
			} else {
				setRetrainerHorseList(tempList);
			}
		}
	};

	const unClaimHorse = (payload) => {
		AppSyncService.execute(unclaimHorse, { input: payload }).then((data) => {
			if (data?.data?.unclaimHorse?.success) {
				addToast({ Message: 'Thoroughbred Unclaimed', IsSuccess: true });
			} else {
				addToast({
					Message: 'An error occured unclaiming.',
					IsSuccess: false
				});
			}
		});
	};

	const onConcludedRetraining = (input, isUnclaim = false) => {
		const { horse_code } = input;
		let retrieveConludingRowsInput = {
			memberExternalId: currentUser?.UserId,
			pageNumber: 0
		};
		let updatedHorseList = retrainerHorseList.filter(
			(horse) => horse?.id !== horse_code
		);

		setRetrainerHorseList(updatedHorseList);
		retrieveConcludingRows(retrieveConludingRowsInput);
		if (isUnclaim) unClaimHorse(input);
	};

	const submitForm = async (formData, type, horseCode, status, funding) => {
		console.log(formData, 'formDataformDataformDataformData');
		let input = {
			asOfDate: formData.date,
			conclusionId:
				type === EUTHANISED
					? 1
					: type === SOLD
					? 2
					: type === OWN_USE
					? 3
					: type === OWN_USE_FUNDED
					? 4
					: type === RETURN_TO_USER
					? 5
					: type === OWN_USE_LEASED
					? 6
					: null,
			horseCode: horseCode,
			statusId: status,
			funding: funding,
			addImageForSaleNotice: formData?.addImageForSaleNotice || null,
			otherDiscipline: formData?.otherDiscipline || null
		};

		AppSyncService.execute(saveRetrainerHorseStatus, { input }).then(
			async (data) => {
				try {
					let saveId = data.data.saveStatus;
					if (
						type !== EUTHANISED &&
						type !== SOLD &&
						type !== REHABILITATION &&
						type !== OWN_USE &&
						type !== OWN_USE_FUNDED &&
						type !== RETURN_TO_USER &&
						type !== OWN_USE_LEASED
					) {
						updateHorseListAfterUpdate(horseCode, formData, status);
						if (formData?.addImageForSaleNotice) {
							const horseUpdateInput = {
								horseCode: horseCode,
								fileName: formData?.forSaleImage[0].filename,
								fileKey: formData?.forSaleImage[0].key
							};
							const result = await AppSyncService.execute(
								saveForSaleHorseImage,
								horseUpdateInput
							);
							console.log(result);
						}
						addToast({
							Message: 'Status Changed Successfully',
							IsSuccess: true
						});
					}

					if (type === REHABILITATION) {
						const id = saveId;
						const injuryDetailsId =
							formData.injury === 0 ? null : formData.injury;
						const otherInjuryDetails = formData.otherInjury;
						const rehabOtherReason = formData.other;
						const rehabReasonId = formData.rehabReason;
						const illnessDetailsId =
							formData.illness === 0 ? null : formData.illness;
						const otherIllnessDetails = formData.otherIllness;
						AppSyncService.execute(saveRetrainerRehab, {
							id,
							injuryDetailsId,
							otherInjuryDetails,
							rehabOtherReason,
							rehabReasonId,
							illnessDetailsId,
							otherIllnessDetails
						}).then((data) => {
							if (data.data != null) {
								updateHorseListAfterUpdate(horseCode, formData, status);
								addToast({
									Message: 'Status Changed Successfully',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (type === EUTHANISED) {
						const id = saveId;
						const { reasonId, specificCauses, specificCauseOther } = formData;

						AppSyncService.execute(saveEuthanisedReason, {
							id,
							reasonId,
							specificCauses,
							specificCauseOther
						}).then((data) => {
							if (data.data != null) {
								updateHorseListAfterUpdate(horseCode, formData, status);
								const deceasedCauseOfDeathId = 2;
								const euthanisedReasonId =
									deceasedCauseOfDeathId == 2 && reasonId != 0
										? Number(reasonId)
										: null;
								const deceasedInput = {
									horseCode: Number(horseCode),
									deceasedThoroughbredSourceId: 2, //@NOTE - 2 is equal to Concluded Retraining
									deceasedDateOfDeath: input.asOfDate,
									deceasedCauseOfDeathId,
									memberId: null,
									euthanisedReasonId: euthanisedReasonId,
									euthanisedInjuryOther:
										deceasedCauseOfDeathId == 2 && euthanisedReasonId == 1
											? specificCauseOther
											: null,
									euthanisedIllnessOther:
										deceasedCauseOfDeathId == 2 && euthanisedReasonId == 2
											? specificCauseOther
											: null,
									unsuitableReasons:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 3 &&
										specificCauses?.length > 0
											? specificCauses
											: [],
									euthanisedUnsuitableOther:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 3 &&
										specificCauses.includes(28)
											? specificCauseOther
											: null,
									deceasedReasonForDeathOther:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 4 &&
										specificCauses?.length > 0
											? specificCauses
											: [],
									deceasedKnackeryPostcode: null,
									deceasedKnackerySuburb: null,
									deceasedOtherPostcode: null,
									deceasedOtherSuburb: null,
									informationAboutDeath: null,
									hasOwnerBeenNotified: false,
									status: 'Review',
									injuries:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 1 &&
										specificCauses?.length > 0
											? specificCauses
											: [],
									illnesses:
										deceasedCauseOfDeathId == 2 &&
										euthanisedReasonId == 2 &&
										specificCauses?.length > 0
											? specificCauses
											: []
								};
								AppSyncService.execute(saveDeceasedThoroughbredForm, {
									input: deceasedInput
								}).then(() => {
									const unclaimInput = {
										member_id: currentUser?.UserId,
										horse_code: input.horseCode,
										full_circle_interested: false,
										cause_of_death_id: 2,
										date_of_death: input.asOfDate,
										unclaim_reason: input.conclusionId
									};
									onConcludedRetraining(unclaimInput, true);

									addToast({
										Message: 'Status Changed Successfully',
										IsSuccess: true
									});
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (type === SOLD) {
						const id = saveId;
						const {
							disciplineIds,
							priceBracketId,
							purposeId,
							newOwnerFullName,
							newOwnerEmail,
							newOwnerPostcode
						} = formData;

						const rehoming = {
							id,
							purposeId: purposeId,
							priceBracketId: priceBracketId,
							newOwnerFullName: newOwnerFullName,
							newOwnerEmail: newOwnerEmail,
							newOwnerPostcode: newOwnerPostcode
						};
						AppSyncService.execute(saveConcludedSoldRehomed, { rehoming }).then(
							() => {
								AppSyncService.execute(saveConcludedSoldRehomedDisciplines, {
									id,
									disciplineIds
								}).then((data) => {
									if (data.data != null) {
										updateHorseListAfterUpdate(horseCode, formData, status);

										const unclaimInput = {
											date_rehomed: input.asOfDate,
											horse_code: input.horseCode,
											member_id: currentUser?.UserId,
											price: priceBracketId,
											purpose: purposeId,
											rehomed_disciplines: disciplineIds
										};
										onConcludedRetraining(unclaimInput, true);
										addToast({
											Message: 'Status Changed Successfully',
											IsSuccess: true
										});
									} else {
										addToast({
											Message: 'Failed to submit form',
											IsSuccess: false
										});
									}
								});
							}
						);
					}
					if (type === OWN_USE) {
						const id = saveId;
						const { focusIds: focusId, other: otherFocus } = formData;
						AppSyncService.execute(saveRetrainerOwnPurpose, {
							id,
							focusId: +focusId,
							otherFocus: +focusId === 6 ? otherFocus : null
						}).then((data) => {
							if (data.data != null) {
								updateHorseListAfterUpdate(horseCode, formData, status);
								addToast({
									Message: 'Status Changed Successfully',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (type === OWN_USE_FUNDED) {
						const id = saveId;
						const {
							focusIds: focusId,
							other: otherFocus,
							disciplineIds
						} = formData;
						AppSyncService.execute(saveConcludedRetainingClientFunded, {
							id,
							focusId: +focusId,
							otherFocus: +focusId === 6 ? otherFocus : null,
							disciplineIds
						}).then((data) => {
							if (data.data != null) {
								updateHorseListAfterUpdate(horseCode, formData, status);
								addToast({
									Message: 'Status Changed Successfully',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (type === OWN_USE_LEASED) {
						const id = saveId;
						const {
							focusIds: focusId,
							other: otherFocus,
							disciplineIds,
							reasonId,
							other: reasonOther,
							isHorseStaying
						} = formData;
						AppSyncService.execute(saveConcludedRetainingClientFunded, {
							id,
							focusId: +focusId,
							otherFocus: +focusId === 6 ? otherFocus : null,
							disciplineIds
						}).then((data) => {
							if (data.data != null) {
								if (!isHorseStaying) {
									const unclaimInput = {
										member_id: currentUser?.UserId,
										horse_code: input.horseCode,
										date_rehomed: input.asOfDate,
										purpose: reasonId,
										purpose_other: reasonOther
									};
									onConcludedRetraining(unclaimInput, true);
								} else {
									onConcludedRetraining({ horse_code: input.horseCode }, false);
								}
								addToast({
									Message: 'Status Changed Successfully',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (type === RETURN_TO_USER) {
						const id = saveId;
						const { reasonId, other: reasonOther, wantFullCircle } = formData;
						AppSyncService.execute(saveConcludedReturnToOwner, {
							id,
							reasonId,
							reasonOther
						}).then((data) => {
							if (data.data != null) {
								updateHorseListAfterUpdate(horseCode, formData, status);
								const unclaimInput = {
									member_id: currentUser?.UserId,
									horse_code: input.horseCode,
									date_rehomed: input.asOfDate,
									purpose: reasonId,
									purpose_other: `Returned to Owner - Reason: ${reasonId} - Other Reason: ${reasonOther}`,
									full_circle_interested: wantFullCircle
								};
								onConcludedRetraining(unclaimInput, true);
								addToast({
									Message: 'Status Changed Successfully',
									IsSuccess: true
								});
							} else {
								addToast({
									Message: 'Failed to submit form',
									IsSuccess: false
								});
							}
						});
					}
					if (
						formData.wantFullCircle &&
						(type === SOLD ||
							type === OWN_USE ||
							type === OWN_USE_FUNDED ||
							type === OWN_USE_LEASED ||
							type === RETURN_TO_USER)
					) {
						const { data: saveFullCircleData } = await AppSyncService.execute(
							saveFullCircle,
							{
								input: {
									submitForm: true,
									horseCode: horseCode,
									memberExternalId: currentUser?.UserId,
									relationship: 4,
									phone: memberData?.phone,
									organisation: null,
									residentialAddress: memberData?.residentialAddress?.address,
									residentialSuburb: memberData?.residentialAddress?.suburb,
									residentialState: memberData?.residentialAddress?.state,
									residentialPostcode: memberData?.residentialAddress?.postcode,
									residentialCountry: memberData?.residentialAddress?.country,
									businessPhone: memberData?.businessPhone
								},
								horseCode,
								memberExternalId: currentUser?.UserId,
								typeOfAssistance: formData.assistanceType.map((d) => d.key)
							}
						);
						if (
							saveFullCircleData.saveFullCircle &&
							saveFullCircleData.saveFullCircleTypesOfAssociation
						) {
							addToast({ Message: 'Registered Full Circle', IsSuccess: true });
						} else {
							addToast({
								Message: 'Error Registering Full Circle',
								IsSuccess: false
							});
						}
					}
				} catch {
					addToast({ Message: 'Error Saving', IsSuccess: false });
				}
			}
		);
	};

	const updateHorseListAfterUpdate = (horseCode, formData, status) => {
		retrainerHorseList
			.filter((d) => d.id === horseCode)
			.map((d) => {
				d.isSaved = true;
				d.openForm = false;
				d.isSelected = false;
				d.asOfDate = formData.date;
				d.isSelected = false;
			});
		originalHorseList
			.filter((m) => m.id === horseCode)
			.map((m) => {
				m.isSaved = true;
				m.openForm = false;
				m.isSelected = false;
				m.asOfDate = formData.date;
				m.isSelected = false;
				m.retrainStatus = retrainerStatuses.filter(
					(m) => m.id === parseInt(status)
				)[0].value;
				m.retrainerStatus = status;
			});
		let newOriginalList = Object.assign([], originalHorseList);
		let newRetrainerHorseList = Object.assign([], retrainerHorseList);

		setRetrainerHorseList(newRetrainerHorseList);
		setOriginalHorseList(newOriginalList);
	};

	const updateCheckBox = (id, status) => {
		let originalStatus = originalHorseList.filter((m) => m.id === id)[0]
			.retrainStatus;
		let originalStatusId = originalHorseList.filter((m) => m.id === id)[0]
			.retrainerStatus;
		retrainerHorseList
			.filter((m) => m.id === id)
			.map((m) => {
				m.isSelected = !status;
				if (m.isSelected === false) {
					m.openForm = false;
					m.retrainStatus = originalStatus;
					m.retrainerStatus = originalStatusId;
				}
			});
		let newList = Object.assign([], retrainerHorseList);
		setRetrainerHorseList(newList);
	};

	const submitHorseData = () => {
		AppSyncService.execute(submitRetrainerForm, {})
			.then((data) => {
				if (data.data?.submitRetrainingThoroughbredsReport) {
					addToast({ Message: 'Report Submitted', IsSuccess: true });
					navigate(DASHBOARD_ROUTE);
				} else {
					addToast({ Message: 'Failed to submit report', IsSuccess: false });
				}
			})
			.catch(() => {
				addToast({ Message: 'Failed to submit report', IsSuccess: false });
			});
	};

	const onChangeDropDown = (value, id) => {
		let rValue = retrainerStatuses.filter((m) => m.id === parseInt(value))[0]
			.value;
		retrainerHorseList
			.filter((m) => m.id === id)
			.map((m) => {
				m.retrainStatus = rValue;
				m.retrainerStatus = parseInt(value);
				m.openForm = true;
				let originalStatusId = originalHorseList.filter((m) => m.id === id)[0]
					.retrainerStatus;
				if (originalStatusId === parseInt(value)) {
					m.openForm = false;
				}
			});
		let newList = Object.assign([], retrainerHorseList);
		setRetrainerHorseList(newList);
	};

	const onChangeToggle = (value, id) => {
		let rValue = value
			? fundingTypes.find((item) => item.value === 'Client').id
			: fundingTypes.find((item) => item.value === 'Self').id;
		retrainerHorseList
			.filter((m) => m.id === id)
			.map((m) => {
				m.funding = rValue;
				m.openForm = true;
				let originalFundingId = originalHorseList.filter((m) => m.id === id)[0]
					.funding;
				if (originalFundingId === parseInt(value)) {
					m.openForm = false;
				}
			});
		let newList = Object.assign([], retrainerHorseList);
		setRetrainerHorseList(newList);
	};

	const sortConcludedRowsUp = () => {
		let input = {
			memberExternalId: currentUser?.UserId,
			pageNumber: 0,
			sortDirection: 'ASC'
		};
		if (concludedDateFrom) {
			input['startDate'] = formatDate(concludedDateFrom);
		}
		if (concludedDateTo) {
			input['endDate'] = formatDate(concludedDateTo);
		}
		retrieveConcludingRows(input);
	};

	const sortConcludedRowsDown = () => {
		let input = {
			memberExternalId: currentUser?.UserId,
			pageNumber: 0,
			sortDirection: 'DESC'
		};
		if (concludedDateFrom) {
			input['startDate'] = formatDate(concludedDateFrom);
		}
		if (concludedDateTo) {
			input['endDate'] = formatDate(concludedDateTo);
		}
		retrieveConcludingRows(input);
	};

	const retrieveConcludingRows = (input) => {
		setIsConcludedLoading(true);
		setCurrentPage(0);
		setConcludedRetrainingHorseList([]);
		AppSyncService.execute(retrieveConcludedRetraining(true), {
			input: input
		}).then((data) => {
			if (data?.data?.retrieveConcludedRetraining) {
				setConcludedRetrainingHorseList(data.data.retrieveConcludedRetraining);
				const filteredRetrainingList = data.data.retrieveConcludedRetraining.filter(
					(item) =>
						item.horseName.toLowerCase().includes(searchValue.toLowerCase())
				);
				setFilteredSearchList(filteredRetrainingList);
				if (data.data.retrieveConcludedRetraining.length > 0) {
					setCount(data.data.retrieveConcludedRetraining[0].totalCount);
				}
			}
			setIsConcludedLoading(false);
		});
	};

	const sortRowsDown = (horseList) => {
		setCurrentSortDirection(1);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = retrainerHorseList;
		}
		setRetrainerHorseList(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse1?.ottInformation?.paddockName ?? horse1?.horseName
				).localeCompare(
					horse2?.ottInformation?.paddockName ?? horse2?.horseName
				)
			)
		);
		buildRows();
	};

	const sortRowsUp = (horseList) => {
		setCurrentSortDirection(2);
		let thoroughbredRows;
		if (horseList) {
			thoroughbredRows = horseList;
		} else {
			thoroughbredRows = retrainerHorseList;
		}
		setRetrainerHorseList(
			thoroughbredRows.sort((horse1, horse2) =>
				(
					horse2?.ottInformation?.paddockName ?? horse2?.horseName
				).localeCompare(horse1?.ottInformation?.paddockName ?? horse1.horseName)
			)
		);
		buildRows();
	};

	const onChangeSearch = (e, newVal) => {
		setSelectedSearch(newVal);
	};

	const onChangeSearchInput = (e, newInputVal) => {
		setSelectedSearchInput(newInputVal);
	};

	const onSearch = (e, searchValue) => {
		console.log(selectedSearch);
		setSearchValue(searchValue);
		const concludedRetrainingHorseListCopy = JSON.parse(
			JSON.stringify(concludedRetrainingHorseList)
		);
		const filteredConcludedRetrainingHorse = concludedRetrainingHorseListCopy.filter(
			(item) => item.horseName.toLowerCase().includes(searchValue.toLowerCase())
		);
		setFilteredSearchList([...filteredConcludedRetrainingHorse]);
	};
	const component = (isMobile) => (
		<>
			{!isLoading && (
				<InfoBar
					id={HorseManagementKeys.SUBMIT_REPORT}
					infoText={
						(retrainerHorseList.filter((d) => d.isSaved).length > 0
							? 'This form currently has unsubmitted changes, if you have finished making edits, please Submit the report. Reports are due on a minimum of monthly basis, and you can make more frequent changes if preferred.'
							: 'No Retrainer Status has changed.\nIf this is correct, please submit your Status Report to the Equine Welfare team.') +
						(lastSubmittedDate
							? `\nRetrainer report was last submitted on: ${lastSubmittedDate}`
							: `\nRetrainer report has not yet been submitted.`)
					}
					buttonText="Submit Report"
					buttonOnClick={submitHorseData}
					type={
						retrainerHorseList.filter((d) => d.isSaved).length > 0
							? MessageBarTypeEnum.info
							: MessageBarTypeEnum.message
					}
				/>
			)}
			<HeaderBanner
				title={`Retraining Thoroughbreds (${retrainerHorseList.length})`}
				styles={{
					backgroundSize: 'cover',
					borderRadius: '5px 5px 0px 0px',
					borderBottom: '1px solid #b3bcc1',
					marginBottom: 0,
					fontSize: 20,
					fontWeight: 600,
					color: '#505153'
				}}
			/>
			<div className={classes.filterContainer}>
				<div className={classes.filterTitle}>Filter by</div>
				<div className={classes.filterComponentContainer}>
					<FormMultiSelectList
						name="filterStatus"
						listOptions={statusOptions}
						selectedIds={selectedStatus}
						onSelectChange={updateHorseList}
					/>
				</div>
			</div>
			<DataTable
				headings={isMobile ? mobileHeadings : tableHeadings}
				rows={rows}
				sortHeading="Name"
				sortUpFunction={sortRowsUp}
				sortDownFunction={sortRowsDown}
				hasHorseInOriginalList={retrainerHorseList.length > 0}
				program={RETRAINER}
				emptyMessage={'No thoroughbred are currently in Retraining'}
			/>
			<RetrainerMonthlyDataTable
				retrainerHorseList={retrainerHorseList}
				updateCheckBox={updateCheckBox}
				submitForm={submitForm}
				isLoading={isLoading}
				injuryOptions={injuryOptions}
				priceBracket={priceOptions}
				rehomingPurposeList={purposeOptions}
				disciplineOptions={disciplineOptions}
				retrainPurpose={conclusionPurposeOptions}
				concludedReturnedReasons={concludedReturnedReasons}
				conclusionReasons={conclusionReasons}
				illnessOptions={illnessOptions}
				isMobile={isMobile}
				fundingTypes={fundingTypes}
				statusList={retrainerStatuses}
				onChangeToggle={onChangeToggle}
				onChangeDropDown={onChangeDropDown}
			/>

			<Accordion
				id={HorseManagementKeys.CONCLUDED_RETRAINING}
				title={`Concluded Retraining (${
					!searchValue ? count : filteredSearchList.length
				})`}
				dropDownStyle={isMobile ? { marginTop: 17 } : {}}
				titleStyle={{ color: '#505153', fontSize: 20 }}
				styles={
					isMobile
						? {
								marginTop: 15,
								background: `url(${BannerImage})`,
								backgroundSize: 'contain',
								borderRadius: '5px 5px 0px 0px',
								minHeight: 67
						  }
						: {
								marginTop: 15,
								background: `url(${BannerImage})`,
								borderBottom: '1px solid #b3bcc1',
								borderRadius: '5px 5px 0px 0px',
								minHeight: 67
						  }
				}
			>
				<div className={classes.filterContainerDatePicker}>
					{!isMobile && (
						<Container>
							<Row>
								<Col xs={12} lg={2}>
									<div className={classes.filterTitle}>
										Concluded Date From:
									</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.filterComponentContainerDatePicker}>
										<FormDatePicker
											noMaxDate
											id="retrainmonthly-concludeddatefrom"
											DateValue={concludedDateFrom}
											updateSelection={(value) => setConcludedDateFrom(value)}
										/>
									</div>
								</Col>
								<Col xs={12} lg={2}>
									<div className={classes.filterTitle}>Concluded Date To:</div>
								</Col>
								<Col xs={12} lg={4}>
									<div className={classes.filterComponentContainerDatePicker}>
										<FormDatePicker
											noMaxDate
											id="retrainmonthly-concludeddateto"
											DateValue={concludedDateTo}
											updateSelection={(value) => setConcludedDateTo(value)}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col xs={12} lg={2}>
									<div className={classes.filterTitle}>
										Search horse by Name
									</div>
								</Col>
								<Col xs={12} lg={10}>
									<div style={{ marginTop: 10 }}>
										<DynamicAutoSuggestion
											options={concludedRetrainingHorseList}
											onChangeFunc={onChangeSearch}
											onInputChangeFunc={onChangeSearchInput}
											inputValue={selectedSearchInput}
											isFilter
											onSearch={onSearch}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					)}
					{isMobile && (
						<>
							<div className={classes.filterTitle}>Concluded Date From:</div>
							<div className={classes.filterComponentContainerDatePickerMobile}>
								<FormDatePicker
									noMaxDate
									id="retrainmonthly-concludeddatefrom"
									DateValue={concludedDateFrom}
									updateSelection={(value) => setConcludedDateFrom(value)}
								/>
							</div>
							<div className={classes.filterTitle}>Concluded Date To:</div>
							<div
								className={
									classes.filterComponentContainerDatePickerBottomMobile
								}
							>
								<FormDatePicker
									noMaxDate
									id="retrainmonthly-concludeddateto"
									DateValue={concludedDateTo}
									updateSelection={(value) => setConcludedDateTo(value)}
								/>
							</div>
						</>
					)}
				</div>

				<ConcludedRetrainingDataTable
					concludedRetrainingList={
						!searchValue ? concludedRetrainingHorseList : filteredSearchList
					}
					retrainerStatusList={retrainerStatuses}
					isLoading={isConcludedLoading}
					sortUpFunction={sortConcludedRowsUp}
					sortDownFunction={sortConcludedRowsDown}
					isMobile={isMobile}
					count={!searchValue ? count : filteredSearchList.length}
					currentPage={currentPage}
					onPageChange={onPageChange}
				/>
			</Accordion>
		</>
	);

	//const mobile = <></>;

	const page = (screenClass) => {
		setIsMobile(['xs', 'sm', 'md'].includes(screenClass));
		return ['xs', 'sm', 'md'].includes(screenClass)
			? component(true)
			: component(false);
	};

	return <ScreenClassRender render={page} />;
};
export default RetrainMonthly;

import { yupResolver } from '@hookform/resolvers/yup';
import { navigate, useParams } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, ScreenClassRender } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import AppSyncService from '../../graphql/AppSyncService';
import {
	deleteHorsePlacementFormMutation,
	deleteHorsePlacementImageMutation,
	deleteImageFromS3,
	deleteImagesFromS3,
	getHorsePlacementFormQuery,
	getPlacementReferenceData,
	saveHorsePlacementFormData,
	saveHorsePlacementFormStatusMutation,
	updateMemberProfile,
	uploadHorsePlacementImage,
	uploadImageToS3
} from '../../graphql/custom.queries';
import { useAuth } from '../../hooks/useAuth';
import useFilePreview from '../../hooks/useFilePreview';
import { useToast } from '../../hooks/useToast';
import {
	APPROVED,
	DEFAULT_COUNTRY,
	REVIEW,
	WORD_DOC_MIME_TYPE
} from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import {
	addNameToUnnamedHorse,
	convertToSortedMultiListData,
	sortArray,
	checkUserAdmin
} from '../../utils/helpers';
import ConfirmationModal from '../ConfirmationModal';
import FormAddressEntryGeneric from '../FormAddressEntryGeneric';
import FileUploadInputField from '../FormComponents/FileUploadInputField';
import FormFieldWrapper from '../FormComponents/FormFieldWrapper';
import FormDropDown from '../FormDropDown';
import FormLabelField from '../FormLabelField';
import FormMultiSelectList from '../FormMultiSelectList';
import FormTextArea from '../FormTextArea';
import FormTextField from '../FormTextField';
import HeaderBanner from '../HeaderBanner';
import LoaderSpinner from '../LoaderSpinner';
import MainPhotoUploadToolTip from '../MainPhotoUploadToolTip';
import MessageBar from '../MessageBar';
import PrimaryButton from '../PrimaryButton';
import SimpleCheckBox from '../SimpleCheckBox';
import Toggle from '../Toggle';
import UploadPhoto from '../UploadPhoto';
import UploadPhotoErrorModal from '../UploadPhotoErrorModal';
import SEO from '../seo';
import useStyles from './styles';
import SubmitOnBehalf from '../SubmitOnBehalf';
import ResizingTextArea from '../ResizingTextArea';
import MultiSelectInput from '../MultiSelectInput';

// form validation rules
const schema = yup.object().shape({
	retirementReason: yup
		.string()
		.required('Reason for Retirement is required')
		.typeError('Reason for Retirement is required'),
	relationshipToHorse: yup
		.string()
		.required('Relationship to this horse is required')
		.typeError('Relationship to this horse is required'),
	lengthOfRelationship: yup
		.string()
		.required('Length of relationship is required')
		.typeError('Length of relationship is required'),
	sireName: yup
		.string()
		.required('Sire name is required')
		.typeError('Sire name is required'),
	damName: yup
		.string()
		.required('Dam name is required')
		.typeError('Dam name is required'),
	height: yup
		.string()
		.required('Height is required')
		.typeError('Height is required'),
	currentOwners: yup
		.string()
		.required('Current Owners is required')
		.max(200, 'Current Owners must have maximum of 200 characters')
		.typeError('Current Owners is required'),
	currentRetrainers: yup
		.string()
		.required('Current Retrainers is required')
		.max(100, 'Current Retrainers must have maximum of 100 characters')
		.typeError('Current Retrainers is required'),
	temperament: yup
		.string()
		.required('Temperament is required')
		.max(500, 'Temperament must have maximum of 500 characters')
		.typeError('Temperament is required'),
	riddenHistory: yup
		.string()
		.required('Ridden History is required')
		.max(700, 'Ridden History must have maximum of 700 characters')
		.typeError('Ridden History is required'),
	otherDetails: yup
		.string()
		.required('Other Details is required')
		.max(500, 'Other Details must have maximum of 500 characters')
		.typeError('Other Details is required'),
	faceOnPhotoImages: yup
		.string()
		.required('Face on photo is required.')
		.oneOf(['true'], 'Face on photo is required.'),
	leftSidePhotoImages: yup
		.string()
		.required('Left side photo is required.')
		.oneOf(['true'], 'Left side photo is required.'),
	rightSidePhotoImages: yup
		.string()
		.required('Right side photo is required.')
		.oneOf(['true'], 'Right side photo is required.'),
	behindFullPhotoImages: yup
		.string()
		.required('Behind photo is required.')
		.oneOf(['true'], 'Behind photo is required.')
});

const HorsePlacement = () => {
	const classes = useStyles();
	const { addToast } = useToast();
	const { handleSubmit, errors, control, setValue } = useForm({
		mode: 'onSubmit',
		resolver: yupResolver(schema)
	});

	const { currentUser } = useAuth();
	const isAdminUser = checkUserAdmin(currentUser?.Roles);
	let { id } = useParams();
	const filePreviewHandler = useFilePreview();
	const [horsePlacementFormData, setHorsePlacementFormData] = useState(null);
	const [memberData, setMemberData] = useState(null);
	const [horsePlacementFormID, setHorsePlacementFormID] = useState(null);
	const [formStatus, setFormStatus] = useState(null);
	const updatesFormData = (id, status) => {
		setHorsePlacementFormID(id);
		if (status) {
			setFormStatus(status);
		}
	};

	const REVIEW_STATUS = 'Review';
	const [
		referenceSpecialtyDisciplines,
		setReferenceSpecialtyDisciplines
	] = useState([]);
	const [
		referenceLengthOfRelationship,
		setReferenceLengthOfRelationship
	] = useState([]);
	const [
		referenceRelationshipToHorse,
		setReferenceRelationshipToHorse
	] = useState([]);
	const [referenceRetirementReason, setReferenceRetirementReason] = useState(
		[]
	);
	const [referenceIsKnownToWindsuck, setReferenceIsKnownToWindsuck] = useState(
		[]
	);

	const [referenceInjuries, setReferenceInjuries] = useState([]);
	const [referenceIllnesses, setReferenceIllnesses] = useState([]);
	const [referenceHabits, setReferenceHabits] = useState([]);
	const [injuriesList, setInjuriesList] = useState([]);
	const [illnessesList, setIllnessesList] = useState([]);
	const [habitsList, setHabitsList] = useState([]);
	const [injuriesValues, setInjuriesValues] = useState([]);
	const [injuriesPastValues, setInjuriesPastValues] = useState([]);
	const [illnessesValues, setIllnessesValues] = useState([]);
	const [vicesCurrentValues, setVicesCurrentValues] = useState([]);
	const [vicesPastValues, setVicesPastValues] = useState([]);

	const [illnessAndInjuriesDetails, setIllnessAndInjuriesDetails] = useState(
		''
	);
	const [vetReportUploads, setVetReportUploads] = useState([]);
	const [disciplinesList, setDisciplinesList] = useState([]);
	const [disciplinesValues, setDisciplinesValues] = useState([]);
	const [disciplinesOther, setDisciplinesOther] = useState(null);
	const [retirementOtherReason, setRetirementOtherReason] = useState(null);
	const [relationshipOther, setRelationshipOther] = useState(null);
	const [horseName, setHorseName] = useState('');
	const [horseCode, setHorseCode] = useState(null);
	const [damName, setDamName] = useState('');
	const [sireName, setSireName] = useState('');
	const [height, setHeight] = useState();
	const currentOwnersSize = 200;
	const [currentOwners, setCurrentOwners] = useState(null);
	const [currentOwnersRemaining, setCurrentOwnersRemaining] = useState(
		currentOwnersSize
	);
	const currentRetrainersSize = 100;
	const [currentRetrainers, setCurrentRetrainers] = useState(null);
	const [currentRetrainersRemaining, setCurrentRetrainersRemaining] = useState(
		currentRetrainersSize
	);
	const injuriesDetailsSize = 500;
	const [
		illnessAndInjuriesDetailsRemaining,
		setIllnessAndInjuriesDetailsRemaining
	] = useState(injuriesDetailsSize);

	const temperamentSize = 500;
	const [temperament, setTemperament] = useState(null);
	const [temperamentRemaining, setTemperamentRemaining] = useState(
		temperamentSize
	);

	const riddenHistorySize = 700;
	const [riddenHistory, setRiddenHistory] = useState(null);
	const [riddenHistoryRemaining, setRiddenHistoryRemaining] = useState(
		riddenHistorySize
	);

	const otherDetailsSize = 500;
	const [otherDetails, setOtherDetails] = useState(null);
	const [otherDetailsRemaining, setOtherDetailsRemaining] = useState(
		otherDetailsSize
	);

	const [address, setAddress] = useState('');
	const [suburb, setSuburb] = useState('');
	const [postCode, setPostCode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [wasInjured, setWasInjured] = useState(false);
	const [isImpactSoundness, setIsImpactSoundness] = useState(false);
	const [isPastInjuryImpactedHorse, setIsPastInjuryImpactedHorse] = useState(
		false
	);
	const [hasIllness, setHasIllness] = useState(false);
	const [injuryOther, setInjuryOther] = useState('');
	const [illnessOther, setIllnessOther] = useState('');
	const [injuryPastOther, setInjuryPastOther] = useState('');
	const [habitsCurrentOther, setHabitsCurrentOther] = useState([]);
	const [habitsPastOther, setHabitsPastOther] = useState([]);
	const [doesHorseHasCurrentVices, setDoesHorseHasCurrentVices] = useState(
		false
	);
	const [doesHorseHasPastVices, setDoesHorseHasPastVices] = useState(false);
	const [habitsCurrentOtherReadOnly, setHabitsCurrentOtherReadOnly] = useState(
		''
	);
	const [habitsPastOtherReadOnly, setHabitsPastOtherReadOnly] = useState('');
	const [isHelpingTransportCosts, setIsHelpingTransportCosts] = useState(true);
	const [
		isContributeRetrainingCosts,
		setIsContributeRetrainingCosts
	] = useState(true);
	const [isTermsDeclaration, setIsTermsDeclaration] = useState(false);
	const [isRegisterDeclaration, setIsRegisterDeclaration] = useState(false);
	const [isEligibleForRESET, setIsEligibleForRESET] = useState(false);
	const [isSubmitClicked, setIsSubmitClicked] = useState(false);
	const [
		selectedLengthOfRelationship,
		setSelectedLengthOfRelationship
	] = useState(null);
	const [
		selectedRelationshipToHorse,
		setSelectedRelationshipToHorse
	] = useState(null);
	const [selectedRetirementReason, setSelectedRetirementReason] = useState(
		null
	);
	const [selectedIsKnownToWindsuck, setSelectedIsKnownToWindsuck] = useState();

	const [showImageUploadErrorModal, setShowImageUploadErrorModal] = useState(
		false
	);

	const [additionalPhotos, setAdditionalPhotos] = useState([]);
	const [behindFullPhotos, setBehindFullPhotos] = useState([]);
	const [faceOnPhotos, setFaceOnPhotos] = useState([]);
	const [leftSidePhotos, setLeftSidePhotos] = useState([]);
	const [rightSidePhotos, setRightSidePhotos] = useState([]);

	const [contactNumber, setContactNumber] = useState(null);

	const [backendErrorMessage, setBackendErrorMessage] = useState(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isCancelled, setIsCancelled] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isMinimumUploadSize, setIsMinimumUploadSize] = useState(false);
	const [isReadOnly, setIsReadOnly] = useState(false);

	const [isOtherDisciplineDisplayed, setIsOtherDisciplineDisplayed] = useState(
		false
	);
	const [
		isOtherRetirementTextDisplayed,
		setIsOtherRetirementTextDisplayed
	] = useState(false);
	const [
		isOtherRelationTextDisplayed,
		setIsOtherRelationTextDisplayed
	] = useState(false);

	const [isAddressManual, setIsAddressManual] = useState(false);
	const manualAddressCheckBoxData = {
		id: 'manualCheckBox',
		label: 'Manually add my address',
		value: 'Manually add my address',
		isSelected: isAddressManual
	};

	const [isSubmitOnBehalf, setIsSubmitOnBehalf] = useState(false);
	const [selectedUserVerifiedInfo, setSelectedUserVerifiedInfo] = useState(
		null
	);
	const [isSelectUserRequired, setIsSelectUserRequired] = useState(false);
	const [selectUserRequiredError, setSelectUserRequiredError] = useState('');

	const toggleManualAddress = () => {
		setIsAddressManual(!isAddressManual);
	};

	const modalStylesConfirmation = {
		content: {
			minWidth: 300,
			top: '40%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		}
	};

	useEffect(() => {
		setValue(
			'faceOnPhotoImages',
			faceOnPhotos != null && faceOnPhotos.length > 0 ? 'true' : 'false'
		);
		setValue(
			'leftSidePhotoImages',
			leftSidePhotos != null && leftSidePhotos.length > 0 ? 'true' : 'false'
		);
		setValue(
			'rightSidePhotoImages',
			rightSidePhotos != null && rightSidePhotos.length > 0 ? 'true' : 'false'
		);
		setValue(
			'behindFullPhotoImages',
			behindFullPhotos != null && behindFullPhotos.length > 0 ? 'true' : 'false'
		);
		setIsSubmitted(false);
	}, [faceOnPhotos, leftSidePhotos, rightSidePhotos, behindFullPhotos]);

	useEffect(() => {
		setIsSubmitted(false);
		setIsSubmitDisabled(false);
	}, [
		additionalPhotos,
		address,
		suburb,
		state,
		postCode,
		country,
		contactNumber,
		currentOwners,
		currentRetrainers,
		damName,
		disciplinesValues,
		disciplinesOther,
		height,
		injuriesValues,
		injuriesPastValues,
		vicesCurrentValues,
		vicesPastValues,
		illnessesValues,
		illnessAndInjuriesDetails,
		isPastInjuryImpactedHorse,
		hasIllness,
		injuryOther,
		illnessOther,
		injuryPastOther,
		habitsCurrentOther,
		habitsPastOther,
		doesHorseHasCurrentVices,
		doesHorseHasPastVices,
		isImpactSoundness,
		isEligibleForRESET,
		isHelpingTransportCosts,
		isContributeRetrainingCosts,
		isTermsDeclaration,
		isRegisterDeclaration,
		selectedRelationshipToHorse,
		selectedRetirementReason,
		selectedLengthOfRelationship,
		selectedIsKnownToWindsuck,
		retirementOtherReason,
		relationshipOther,
		otherDetails,
		riddenHistory,
		sireName,
		temperament
	]);

	useEffect(() => {
		AppSyncService.execute(getPlacementReferenceData).then((result) => {
			if (result.data) {
				setReferenceSpecialtyDisciplines(result.data.listSpecialtyDisciplines);
				setReferenceInjuries(sortArray(result.data.listInjuryDetails, true));
				setReferenceIllnesses(sortArray(result.data.listIllnessDetails, true));
				setReferenceHabits(sortArray(result.data.listHabitsAndVices, true));
				let modifiedLengthOfRelationship =
					result.data.listRelationshipToHorseLength;
				modifiedLengthOfRelationship.unshift({ id: '', value: '' });
				setReferenceLengthOfRelationship(modifiedLengthOfRelationship);

				let modifiedRelationshipToHorse = result.data.listRelationshipToHorse;
				modifiedRelationshipToHorse.unshift({ id: '', value: '' });
				setReferenceRelationshipToHorse(modifiedRelationshipToHorse);

				let modifiedRetirementReason = result.data.listRetirementLookup;
				modifiedRetirementReason.unshift({ id: '', value: '' });
				setReferenceRetirementReason(sortArray(modifiedRetirementReason, true));

				let modifiedIsKnownToWindsuck = result.data.listYesNo;
				modifiedIsKnownToWindsuck.unshift({ id: '', value: '' });
				setReferenceIsKnownToWindsuck(modifiedIsKnownToWindsuck);
			}
		});
	}, []);

	useEffect(() => {
		setIsLoading(true);
		if (currentUser != null) {
			const memberExternalId = currentUser?.UserId;
			AppSyncService.execute(getHorsePlacementFormQuery, {
				memberExternalId: memberExternalId,
				horseCode: id
			}).then((data) => {
				if (data.data != null) {
					setIsReadOnly(
						data.data?.getHorsePlacementForm?.status === APPROVED ||
							data.data?.getHorsePlacementForm?.status === REVIEW
					);
					setHorseCode(id);
					setMemberData(data.data.getHorsePlacementForm?.member);
					setHorsePlacementFormData(data.data.getHorsePlacementForm);
					setHorsePlacementFormID(data.data.getHorsePlacementForm?.id);
					setHorseName(data.data.getHorsePlacementForm?.horse?.name);
					setDamName(data.data.getHorsePlacementForm?.damName);
					setValue('damName', data.data.getHorsePlacementForm?.damName);
					setSireName(data.data.getHorsePlacementForm?.sireName);
					setValue('sireName', data.data.getHorsePlacementForm?.sireName);
					setHeight(data.data.getHorsePlacementForm?.height);
					setValue('height', data.data.getHorsePlacementForm?.height);
					setContactNumber(
						data.data.getHorsePlacementForm?.contactNumber ||
							data.data.getHorsePlacementForm?.member?.phone
					);
					setValue(
						'contactNumber',
						data.data.getHorsePlacementForm?.contactNumber ||
							data.data.getHorsePlacementForm?.member?.phone
					);
					let injuriesList = data.data.getHorsePlacementForm?.injuries;
					if (injuriesList && injuriesList.length > 0) {
						injuriesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.injuries
							),
							setInjuriesValues
						);
					}
					let injuriesPastList = data.data.getHorsePlacementForm?.injuriesPast;
					if (injuriesPastList && injuriesPastList.length > 0) {
						injuriesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.injuriesPast
							),
							setInjuriesPastValues
						);
					}
					let illnessesList = data.data.getHorsePlacementForm?.illnesses;
					if (illnessesList && illnessesList.length > 0) {
						injuriesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.illnesses
							),
							setIllnessesValues
						);
					}
					let vicesCurrentList = data.data.getHorsePlacementForm?.vicesCurrent;
					if (vicesCurrentList && vicesCurrentList.length > 0) {
						injuriesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.vicesCurrent
							),
							setVicesCurrentValues
						);
					}
					let vicesPastList = data.data.getHorsePlacementForm?.vicesPast;
					if (vicesPastList && vicesPastList.length > 0) {
						injuriesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.vicesPast
							),
							setVicesPastValues
						);
					}
					setIsPastInjuryImpactedHorse(
						data.data.getHorsePlacementForm?.isPastInjuryImpactedHorse
					);
					setIllnessAndInjuriesDetails(
						data.data.getHorsePlacementForm?.illnessAndInjuriesDetails
					);
					setHasIllness(
						data.data.getHorsePlacementForm?.isIllnessImpactedHorse
					);
					setInjuryOther(data.data.getHorsePlacementForm?.injuryOther);
					setIllnessOther(data.data.getHorsePlacementForm?.illnessOther);
					setInjuryPastOther(data.data.getHorsePlacementForm?.injuryPastOther);
					if (data.data.getHorsePlacementForm?.habitsCurrentOther?.length > 0) {
						setHabitsCurrentOtherReadOnly(
							data.data.getHorsePlacementForm?.habitsCurrentOther
						);
						let splitOtherHabits = data.data.getHorsePlacementForm?.habitsCurrentOther.split(
							','
						);
						const mapHabitsOther = splitOtherHabits.map((otherHabit) => ({
							label: otherHabit,
							value: otherHabit
						}));
						setHabitsCurrentOther(mapHabitsOther);
					}
					if (data.data.getHorsePlacementForm?.habitsPastOther?.length > 0) {
						setHabitsPastOtherReadOnly(
							data.data.getHorsePlacementForm?.habitsPastOther
						);
						let splitOtherHabits = data.data.getHorsePlacementForm?.habitsPastOther.split(
							','
						);
						const mapHabitsOther = splitOtherHabits.map((otherHabit) => ({
							label: otherHabit,
							value: otherHabit
						}));
						setHabitsPastOther(mapHabitsOther);
					}
					setDoesHorseHasCurrentVices(
						data.data.getHorsePlacementForm?.doesHorseHasCurrentVices
					);
					setDoesHorseHasPastVices(
						data.data.getHorsePlacementForm?.doesHorseHasPastVices
					);
					setIsImpactSoundness(
						data.data.getHorsePlacementForm?.isImpactSoundness
					);
					setWasInjured(data.data.getHorsePlacementForm?.isInjuryImpactedHorse);
					let disciplinesList = data.data.getHorsePlacementForm?.disciplines;
					if (disciplinesList && disciplinesList.length > 0) {
						disciplinesValuesChanged(
							convertToSortedMultiListData(
								data.data.getHorsePlacementForm?.disciplines
							)
						);
					}
					setDisciplinesOther(
						data.data.getHorsePlacementForm?.disciplinesOther
					);
					setValue(
						'disciplinesOther',
						data.data.getHorsePlacementForm?.disciplinesOther
					);
					setRelationshipOther(
						data.data.getHorsePlacementForm?.relationshipToHorseOther
					);
					setValue(
						'relationshipOther',
						data.data.getHorsePlacementForm?.relationshipToHorseOther
					);

					setSelectedLengthOfRelationship(
						data.data.getHorsePlacementForm?.lengthOfRelationship?.id
					);
					setValue(
						'lengthOfRelationship',
						data.data.getHorsePlacementForm?.lengthOfRelationship?.id
					);

					updateRelationshipToHorse(
						data.data.getHorsePlacementForm?.relationshipToHorse?.id
					);
					setValue(
						'relationshipToHorse',
						data.data.getHorsePlacementForm?.relationshipToHorse?.id
					);

					updateRetirementReason(
						data.data.getHorsePlacementForm?.retirementReason?.id
					);
					setValue(
						'retirementReason',
						data.data.getHorsePlacementForm?.retirementReason?.id
					);

					setRetirementOtherReason(
						data.data.getHorsePlacementForm?.retirementOtherReason
					);
					setValue(
						'retirementOtherReason',
						data.data.getHorsePlacementForm?.retirementOtherReason
					);

					if (data.data.getHorsePlacementForm?.isKnownToWindsuck?.id != null) {
						updateIsKnownToWindsuck(
							data.data.getHorsePlacementForm?.isKnownToWindsuck?.id
						);
					} else {
						setSelectedIsKnownToWindsuck(2);
					}
					setAddress(data.data.getHorsePlacementForm?.address);
					setValue('address', data.data.getHorsePlacementForm?.address);
					setSuburb(data.data.getHorsePlacementForm?.suburb);
					setValue('suburb', data.data.getHorsePlacementForm?.suburb);
					setPostCode(data.data.getHorsePlacementForm?.postcode);
					setValue('postCode', data.data.getHorsePlacementForm?.postcode);
					const countryValue =
						data.data.getHorsePlacementForm?.country || DEFAULT_COUNTRY;
					setCountry(countryValue);
					setValue('country', countryValue);
					const stateValue = data.data.getHorsePlacementForm?.state || '';
					setState(stateValue);
					setValue('state', stateValue);
					setIsAddressManual(
						data.data.getHorsePlacementForm?.isManualAddress ?? false
					);

					setCurrentOwnersData(data.data.getHorsePlacementForm?.currentOwners);
					setValue(
						'currentOwners',
						data.data.getHorsePlacementForm?.currentOwners
					);
					setCurrentRetrainersData(
						data.data.getHorsePlacementForm?.currentRetrainers
					);
					setValue(
						'currentRetrainers',
						data.data.getHorsePlacementForm?.currentRetrainers
					);
					setTemperamentData(data.data.getHorsePlacementForm?.temperament);
					setValue('temperament', data.data.getHorsePlacementForm?.temperament);
					setRiddenHistoryData(data.data.getHorsePlacementForm?.riddenHistory);
					setValue(
						'riddenHistory',
						data.data.getHorsePlacementForm?.riddenHistory
					);
					setOtherDetailsData(data.data.getHorsePlacementForm?.otherDetails);
					setValue(
						'otherDetails',
						data.data.getHorsePlacementForm?.otherDetails
					);
					setAdditionalPhotos(
						data.data.getHorsePlacementForm?.additionalPhotos
					);
					setVetReportUploads(data.data.getHorsePlacementForm.vetReports);
					setBehindFullPhotos(
						data.data.getHorsePlacementForm?.behindFullPhotos
					);
					setFaceOnPhotos(data.data.getHorsePlacementForm?.faceOnPhotos);
					setLeftSidePhotos(data.data.getHorsePlacementForm?.leftSidePhotos);
					setRightSidePhotos(data.data.getHorsePlacementForm?.rightSidePhotos);

					setIsEligibleForRESET(
						data.data.getHorsePlacementForm?.isEligibleForRESET
					);
					setIsHelpingTransportCosts(
						data.data.getHorsePlacementForm?.isHelpingTransportCosts ?? true
					);
					setIsContributeRetrainingCosts(
						data.data.getHorsePlacementForm?.contributeRetrainingCosts ?? true
					);
					setIsTermsDeclaration(
						data.data.getHorsePlacementForm?.termsDeclaration
					);
					setIsRegisterDeclaration(
						data.data.getHorsePlacementForm?.registerDeclaration
					);
					setFormStatus(data.data.getHorsePlacementForm?.status);
					if (
						data.data.getHorsePlacementForm &&
						data.data.getHorsePlacementForm.status &&
						data.data.getHorsePlacementForm.status !== 'Draft'
					) {
						setIsSubmitted(true);
						setIsSubmitDisabled(true);
					}
				}
			});
		}
		if (referenceInjuries && referenceInjuries.length > 1) {
			setInjuriesList(convertToSortedMultiListData(referenceInjuries));
		}
		if (referenceIllnesses && referenceIllnesses.length > 1) {
			setIllnessesList(convertToSortedMultiListData(referenceIllnesses));
		}
		if (referenceHabits && referenceHabits.length > 1) {
			setHabitsList(convertToSortedMultiListData(referenceHabits));
		}
		if (
			referenceSpecialtyDisciplines &&
			referenceSpecialtyDisciplines.length > 1
		) {
			setDisciplinesList(
				convertToSortedMultiListData(referenceSpecialtyDisciplines)
			);
		}
		setIsLoading(false);
	}, [
		referenceInjuries,
		referenceIllnesses,
		referenceHabits,
		referenceSpecialtyDisciplines
	]);

	useEffect(() => {
		if (isSelectUserRequired) {
			setSelectUserRequiredError('User is required');
		} else {
			setSelectUserRequiredError('');
		}
	}, [isSelectUserRequired]);

	const navigateToDashboard = () => {
		navigate(DASHBOARD_ROUTE);
	};

	const cancelRequest = () => {
		if (formStatus && formStatus === 'Approved') {
			onCancelSuccess();
			return;
		}
		let listOfKeys = [];
		if (additionalPhotos.length > 0) {
			additionalPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (behindFullPhotos.length > 0) {
			behindFullPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (faceOnPhotos.length > 0) {
			faceOnPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (leftSidePhotos.length > 0) {
			leftSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (rightSidePhotos.length > 0) {
			rightSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		listOfKeys = listOfKeys.concat(
			vetReportUploads.reduce(
				(unapprovedReports, report) => [
					...unapprovedReports,
					...(!report.isApproved ? [report.key] : [])
				],
				[]
			)
		);
		if (listOfKeys.length > 0) {
			AppSyncService.execute(deleteImagesFromS3, {
				imageKeys: listOfKeys
			}).then((data) => {
				if (data?.data?.deleteUserImages?.success) {
					removeHorsePlacement();
				}
			});
		} else {
			removeHorsePlacement();
		}
	};

	const onCancelSuccess = () => {
		setIsCancelled(true);
		addToast({ Message: 'Request cancelled', IsSuccess: true });
		setConfirmationModal(false);
		navigateToDashboard();
	};

	const removeHorsePlacement = () => {
		AppSyncService.execute(deleteHorsePlacementFormMutation, {
			id: horsePlacementFormID
		}).then((data) => {
			if (data.data != null) {
				onCancelSuccess();
			} else {
				setBackendErrorMessage(
					'Unknown error while cancelling Request for Retraining Form data. Please contact support'
				);
			}
		});
	};

	const validate = () => {
		if (
			address != null &&
			address.trim() != '' &&
			isTermsDeclaration &&
			isRegisterDeclaration &&
			contactNumber != null &&
			contactNumber.trim() != '' &&
			postCode != null &&
			postCode.trim() != '' &&
			suburb != null &&
			suburb.trim() != '' &&
			state != null &&
			state.trim() != '' &&
			country != null &&
			country.trim() != '' &&
			currentOwners != null &&
			currentOwners.trim() != '' &&
			currentOwners.trim().length <= 200 &&
			currentRetrainers != null &&
			currentRetrainers.trim() != '' &&
			currentRetrainers.trim().length <= 100 &&
			otherDetails != null &&
			otherDetails.trim() != '' &&
			otherDetails.trim().length <= 500 &&
			damName != null &&
			damName.trim() != '' &&
			height != null &&
			height.trim() != '' &&
			selectedLengthOfRelationship != null &&
			selectedLengthOfRelationship != '' &&
			selectedRelationshipToHorse != null &&
			selectedRelationshipToHorse != '' &&
			selectedRetirementReason != null &&
			selectedRetirementReason != '' &&
			selectedIsKnownToWindsuck != null &&
			selectedIsKnownToWindsuck != '' &&
			riddenHistory != null &&
			riddenHistory.trim() != '' &&
			riddenHistory.trim().length <= 700 &&
			temperament != null &&
			temperament.trim() != '' &&
			temperament.trim().length <= 500 &&
			sireName != null &&
			sireName.trim() != '' &&
			faceOnPhotos.length > 0 &&
			leftSidePhotos.length > 0 &&
			rightSidePhotos.length > 0 &&
			behindFullPhotos.length > 0 &&
			validateVetReportUploads() &&
			validateOtherRetirement() &&
			validateOtherRelationships() &&
			validateInjuriesIllnessVices() &&
			validateIllnessAndInjuryDetails()
		) {
			return true;
		}
	};

	const validateVetReportUploads = () => {
		if (wasInjured && (!vetReportUploads || vetReportUploads.length == 0))
			return false;
		return true;
	};

	const validateIllnessAndInjuryDetails = () => {
		if (
			(hasIllness || wasInjured || isPastInjuryImpactedHorse) &&
			(!illnessAndInjuriesDetails || illnessAndInjuriesDetails.trim() == '')
		) {
			return false;
		}
		return true;
	};

	const validateInjuriesIllnessVices = () => {
		if (
			(wasInjured && isShowOtherInputField(injuriesValues) && !injuryOther) ||
			(isPastInjuryImpactedHorse &&
				(!injuriesPastValues || injuriesPastValues?.length == 0)) ||
			(isPastInjuryImpactedHorse &&
				isShowOtherInputField(injuriesPastValues) &&
				!injuryPastOther) ||
			(hasIllness && (!illnessesValues || illnessesValues?.length == 0)) ||
			(hasIllness && isShowOtherInputField(illnessesValues) && !illnessOther) ||
			(doesHorseHasCurrentVices &&
				(!vicesCurrentValues || vicesCurrentValues?.length == 0)) ||
			(doesHorseHasCurrentVices &&
				isShowOtherInputField(vicesCurrentValues) &&
				habitsCurrentOther?.length == 0) ||
			(doesHorseHasPastVices &&
				(!vicesPastValues || vicesPastValues?.length == 0)) ||
			(doesHorseHasPastVices &&
				isShowOtherInputField(vicesPastValues) &&
				habitsPastOther?.length == 0)
		)
			return false;
		else return true;
	};

	const validateOtherRetirement = () => {
		if (selectedRetirementReason == 4) {
			if (retirementOtherReason == null || retirementOtherReason.trim() == '') {
				return false;
			}
			return true;
		}
		return true;
	};

	const validateOtherRelationships = () => {
		if (selectedRelationshipToHorse == 5) {
			if (relationshipOther == null || relationshipOther.trim() == '') {
				return false;
			}
			return true;
		}
		return true;
	};

	const validateOtherDisciplines = (disciplineIDs) => {
		if (disciplineIDs.includes(13)) {
			if (disciplinesOther == null || disciplinesOther.trim() == '') {
				return false;
			}
			return true;
		}
		return true;
	};

	const updateProfile = async () => {
		try {
			if (!memberData?.phone && !isSubmitOnBehalf) {
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData: { phone: contactNumber }
				});
				addToast({ Message: 'Updated member profile', IsSuccess: true });
			} else if (isSubmitOnBehalf && selectedUserVerifiedInfo) {
				let updateMemberProfileData = {
					...(selectedUserVerifiedInfo?.phone && {
						phone: selectedUserVerifiedInfo?.phone
					}),
					...(isSubmitOnBehalf &&
						selectedUserVerifiedInfo && {
							externalId: selectedUserVerifiedInfo.memberExternalId
						}),
					...(selectedUserVerifiedInfo?.firstName && {
						firstName: selectedUserVerifiedInfo?.firstName
					})
				};
				await AppSyncService.execute(updateMemberProfile, {
					updateMemberProfileData
				});
				addToast({ Message: 'Updated member profile', IsSuccess: true });
			}
		} catch (error) {
			addToast({
				Message: 'Could not update member profile',
				IsSuccess: false
			});
		}
	};

	const sendRequest = async (formStatus) => {
		let disciplineIDs = [];
		disciplinesValues &&
			disciplinesValues.map((item) => {
				disciplineIDs.push(item.value);
			});
		let injuryIDs = [];
		injuriesValues &&
			injuriesValues.map((item) => {
				injuryIDs.push(item.value);
			});

		let injuryPastIDs = [];
		injuriesPastValues &&
			injuriesPastValues.map((item) => {
				injuryPastIDs.push(item.value);
			});
		let illnessIDs = [];
		illnessesValues &&
			illnessesValues.map((item) => {
				illnessIDs.push(item.value);
			});
		let habitPastIDs = [];
		vicesPastValues &&
			vicesPastValues.map((item) => {
				habitPastIDs.push(item.value);
			});
		let habitCurrentIDs = [];
		vicesCurrentValues &&
			vicesCurrentValues.map((item) => {
				habitCurrentIDs.push(item.value);
			});
		try {
			const horsePlacementFormInputData = {
				address: address,
				confirmed: true,
				contactNumber: isSubmitOnBehalf
					? selectedUserVerifiedInfo?.phone
					: contactNumber,
				country: country,
				currentOwners: currentOwners,
				currentRetrainers: currentRetrainers,
				damName: damName,
				disciplines: disciplineIDs,
				disciplinesOther: disciplinesOther,
				height: height,
				horseCode: horseCode,
				injuries: injuryIDs,
				isEligibleForRESET: isEligibleForRESET,
				isHelpingTransportCosts: isHelpingTransportCosts,
				contributeRetrainingCosts: isContributeRetrainingCosts,
				termsDeclaration: isTermsDeclaration,
				registerDeclaration: isRegisterDeclaration,
				isKnownToWindsuckId:
					selectedIsKnownToWindsuck != '' ? selectedIsKnownToWindsuck : null,
				isManualAddress: isAddressManual,
				lengthOfRelationshipId:
					selectedLengthOfRelationship != ''
						? selectedLengthOfRelationship
						: null,
				otherDetails: otherDetails,
				postcode: postCode,
				relationshipToHorseId:
					selectedRelationshipToHorse != ''
						? selectedRelationshipToHorse
						: null,
				relationshipToHorseOther: relationshipOther,
				retirementOtherReason: retirementOtherReason,
				retirementReasonId:
					selectedRetirementReason != '' ? selectedRetirementReason : null,
				riddenHistory: riddenHistory,
				sireName: sireName,
				state: state,
				suburb: suburb,
				temperament: temperament,
				isInjuryImpactedHorse: wasInjured,
				isIllnessImpactedHorse: hasIllness,
				illnesses: illnessIDs,
				doesHorseHasCurrentVices,
				doesHorseHasPastVices,
				isImpactSoundness,
				vicesCurrent: habitCurrentIDs,
				vicesPast: habitPastIDs,
				illnessOther,
				injuryOther,
				habitsCurrentOther:
					habitsCurrentOther.length === 0
						? ''
						: habitsCurrentOther.map((item) => item.value).toString(),
				habitsPastOther:
					habitsPastOther.length === 0
						? ''
						: habitsPastOther.map((item) => item.value).toString(),
				isPastInjuryImpactedHorse,
				injuriesPast: injuryPastIDs,
				illnessAndInjuriesDetails,
				injuryPastOther,
				...(isSubmitOnBehalf && {
					isSubmitOnBehalf: true,
					memberId: selectedUserVerifiedInfo?.id
				})
			};
			if (formStatus === 'Review') {
				await updateProfile();
			}
			AppSyncService.execute(saveHorsePlacementFormData, {
				horsePlacementFormInputData
			}).then((data) => {
				if (data?.data != null) {
					var formId = data.data.saveHorsePlacementForm?.id;
					setHorsePlacementFormID(formId);
					if (formStatus === 'Review') {
						updateFormStatus(formId);
					} else {
						addToast({ Message: 'Form saved', IsSuccess: true });
						navigateToDashboard();
					}
				}
			});
		} catch (error) {
			if (error) {
				setBackendErrorMessage(error);
			} else {
				setBackendErrorMessage(
					'Unknown error while sending Request for Retraining Form data. Please contact support'
				);
			}
		}
	};

	const updateFormStatus = (formId) => {
		const horsePlacementFormStatusInput = {
			id: formId,
			status: REVIEW_STATUS
		};
		AppSyncService.execute(saveHorsePlacementFormStatusMutation, {
			horsePlacementFormStatusInput
		}).then((data) => {
			if (data.data != null) {
				addToast({ Message: 'Form Submitted', IsSuccess: true });
				navigateToDashboard();
			}
		});
	};

	const onSubmit = async () => {
		setIsSubmitClicked(true);
		setBackendErrorMessage(null);
		let injuriesValid = true;

		let disciplineIDs = [];
		disciplinesValues &&
			disciplinesValues.map((item) => {
				disciplineIDs.push(item.value);
			});
		let injuryIDs = [];
		injuriesValues &&
			injuriesValues.map((item) => {
				injuryIDs.push(item.value);
			});

		if (
			injuriesValid &&
			disciplineIDs.length != 0 &&
			validateOtherDisciplines(disciplineIDs) &&
			validate()
		) {
			sendRequest('Review');
			setIsSubmitted(true);
			setIsSubmitDisabled(true);
		} else {
			setBackendErrorMessage('Please complete all required fields');
		}
	};

	const checkIfSubmitOnBehalf = () => {
		if (isSubmitOnBehalf && !selectedUserVerifiedInfo) {
			setIsSelectUserRequired(true);
			return;
		} else {
			setIsSelectUserRequired(false);
			return;
		}
	};

	const onPostCodeChange = (postCode) => {
		const regex = RegExp(/^\d*$/);
		if (regex.test(postCode) && postCode.toString().length <= 4) {
			setPostCode(postCode);
			setValue('postCode', postCode);
		}
	};

	const updatePhoneNumber = (number) => {
		const regex = RegExp(/^\+\d*$|^\d*$/);
		if (regex.test(number)) {
			setContactNumber(number);
			setValue('contactNumber', postCode);
		}
	};

	const onAddressChange = (newAddress) => {
		setAddress(newAddress);
		setValue('address', newAddress);
	};

	const onSuburbChange = (newSuburb) => {
		setSuburb(newSuburb);
		setValue('suburb', newSuburb);
	};

	const onCountryChange = (newCountry) => {
		setCountry(newCountry);
		setValue('country', newCountry);
	};

	const onAddressStateChange = (newCountryState) => {
		setState(newCountryState);
		setValue('state', newCountryState);
	};

	const updateRelationshipToHorse = (value) => {
		if (value == 5) {
			setIsOtherRelationTextDisplayed(true);
		} else {
			setIsOtherRelationTextDisplayed(false);
			setRelationshipOther('');
		}
		setSelectedRelationshipToHorse(value);
	};

	const updateLengthOfRelationship = (value) => {
		setSelectedLengthOfRelationship(value);
	};

	const updateRetirementReason = (value) => {
		if (value == 4) {
			setIsOtherRetirementTextDisplayed(true);
		} else {
			setIsOtherRetirementTextDisplayed(false);
			setRetirementOtherReason('');
		}
		setSelectedRetirementReason(value);
	};

	const updateIsKnownToWindsuck = (value) => {
		setSelectedIsKnownToWindsuck(value);
		setValue('isKnownToWindsuck', value);
	};

	const setCurrentOwnersData = (value) => {
		setCurrentOwners(value);
		if (value || value === '') {
			setCurrentOwnersRemaining(currentOwnersSize - value.length);
		}
	};

	const setCurrentRetrainersData = (value) => {
		setCurrentRetrainers(value);
		if (value || value === '') {
			setCurrentRetrainersRemaining(currentRetrainersSize - value.length);
		}
	};

	const setIllnessAndInjuriesDetailsData = (value) => {
		setIllnessAndInjuriesDetails(value);
		if (value || value === '') {
			setIllnessAndInjuriesDetailsRemaining(injuriesDetailsSize - value.length);
		}
	};

	const setTemperamentData = (value) => {
		setTemperament(value);
		if (value || value === '') {
			setTemperamentRemaining(temperamentSize - value.length);
		}
	};

	const setRiddenHistoryData = (value) => {
		setRiddenHistory(value);
		if (value || value === '') {
			setRiddenHistoryRemaining(riddenHistorySize - value.length);
		}
	};

	const setOtherDetailsData = (value) => {
		setOtherDetails(value);
		if (value || value === '') {
			setOtherDetailsRemaining(otherDetailsSize - value.length);
		}
	};

	const updateWasInjured = (value) => {
		if (value === false) {
			setInjuriesValues(null);
			setInjuryOther(null);
		}
		setWasInjured(value);
	};

	const updateisPastInjuryImpactedHorse = (value) => {
		if (value === false) {
			setInjuriesPastValues([]);
			setInjuryPastOther(null);
		}
		setIsPastInjuryImpactedHorse(value);
	};

	const updateHasIllness = (value) => {
		if (value === false) {
			setIllnessesValues([]);
			setIllnessOther(null);
		}
		setHasIllness(value);
	};

	const updateIsHelpingTransportCosts = (value) => {
		setIsHelpingTransportCosts(value);
	};

	const updateIsRegisterDeclaration = (value) => {
		setIsRegisterDeclaration(value);
	};

	const updateIsTermsDeclaration = (value) => {
		setIsTermsDeclaration(value);
	};

	const updateIsContributeRetrainingCosts = (value) => {
		setIsContributeRetrainingCosts(value);
	};

	const updateIsEligibleForRESET = (value) => {
		setIsEligibleForRESET(value);
	};

	const updateDoesHorseHasCurrentVices = (value) => {
		if (value === false) {
			setVicesCurrentValues([]);
			setHabitsCurrentOther([]);
		}
		setDoesHorseHasCurrentVices(value);
	};

	const updateDoesHorseHasPastVices = (value) => {
		if (value === false) {
			setVicesPastValues([]);
			setHabitsPastOther([]);
		}
		setDoesHorseHasPastVices(value);
	};

	const injuriesValuesChanged = (value, setMethod) => {
		setMethod(value);
	};

	const disciplinesValuesChanged = (value) => {
		let foundOther = false;
		value.map((item) => {
			if (item.label === 'Other') {
				foundOther = true;
			}
		});
		if (foundOther) {
			setIsOtherDisciplineDisplayed(true);
		} else {
			setIsOtherDisciplineDisplayed(false);
			setDisciplinesOther('');
		}
		setDisciplinesValues(value);
	};

	const uploadError = (isMinimumUploadSize) => {
		setIsMinimumUploadSize(isMinimumUploadSize);
		setShowImageUploadErrorModal(true);
	};

	const faceOnPhotosUpload = (image, onChange) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(uploadHorsePlacementImage, {
							filename: image.name,
							horseCode: horseCode,
							key: imageKey,
							type: 'faceOn',
							...(isSubmitOnBehalf && {
								memberId: selectedUserVerifiedInfo.id,
								isSubmitOnBehalf: isSubmitOnBehalf
							})
						}).then((uploadResponse) => {
							try {
								// TODO: Handle error ie. saveHorsePlacementImage.success and also make this a reusable function.
								updatesFormData(
									uploadResponse.data.saveHorsePlacementImage?.id,
									'Draft'
								);
								let dataList = Object.assign([], faceOnPhotos);
								dataList.push({ filename: image.name, key: imageKey });
								setFaceOnPhotos(dataList);
								onChange('true');
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
								setIsSubmitted(false);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const leftSidePhotosUpload = (image, onChange) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(uploadHorsePlacementImage, {
							filename: image.name,
							horseCode: horseCode,
							key: imageKey,
							type: 'leftSide',
							...(isSubmitOnBehalf && {
								memberId: selectedUserVerifiedInfo.id,
								isSubmitOnBehalf: isSubmitOnBehalf
							})
						}).then((uploadResponse) => {
							try {
								// TODO: Handle error ie. saveHorsePlacementImage.success and also make this a reusable function.
								updatesFormData(
									uploadResponse.data.saveHorsePlacementImage?.id,
									'Draft'
								);
								let dataList = Object.assign([], leftSidePhotos);
								dataList.push({ filename: image.name, key: imageKey });
								setLeftSidePhotos(dataList);
								onChange('true');
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
								setIsSubmitted(false);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const rightSidePhotosUpload = (image, onChange) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(uploadHorsePlacementImage, {
							filename: image.name,
							horseCode: horseCode,
							key: imageKey,
							type: 'rightSide',
							...(isSubmitOnBehalf && {
								memberId: selectedUserVerifiedInfo.id,
								isSubmitOnBehalf: isSubmitOnBehalf
							})
						}).then((uploadResponse) => {
							try {
								// TODO: Handle error ie. saveHorsePlacementImage.success and also make this a reusable function.
								updatesFormData(
									uploadResponse.data.saveHorsePlacementImage?.id,
									'Draft'
								);
								let dataList = Object.assign([], rightSidePhotos);
								dataList.push({ filename: image.name, key: imageKey });
								setRightSidePhotos(dataList);
								onChange('true');
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
								setIsSubmitted(false);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const behindFullPhotosUpload = (image, onChange) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(uploadHorsePlacementImage, {
							filename: image.name,
							horseCode: horseCode,
							key: imageKey,
							type: 'behindFull',
							...(isSubmitOnBehalf && {
								memberId: selectedUserVerifiedInfo.id,
								isSubmitOnBehalf: isSubmitOnBehalf
							})
						}).then((uploadResponse) => {
							try {
								// TODO: Handle error ie. saveHorsePlacementImage.success and also make this a reusable function.
								updatesFormData(
									uploadResponse.data.saveHorsePlacementImage?.id,
									'Draft'
								);
								let dataList = Object.assign([], behindFullPhotos);
								dataList.push({ filename: image.name, key: imageKey });
								setBehindFullPhotos(dataList);
								onChange('true');
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
								setIsSubmitted(false);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const additionalPhotosUpload = (image) => {
		const headers = {
			'Content-Type': image.type
		};
		const imageKey = { imageKey: image.name };
		AppSyncService.execute(uploadImageToS3, imageKey).then((data) => {
			if (data.data != null) {
				let imageKey = data.data.putUserImage.imageKey;
				axios
					.put(data.data.putUserImage.signedUrl, image, { headers: headers })
					.then(() => {
						AppSyncService.execute(uploadHorsePlacementImage, {
							filename: image.name,
							horseCode: horseCode,
							key: imageKey,
							type: 'additional',
							...(isSubmitOnBehalf && {
								memberId: selectedUserVerifiedInfo.id,
								isSubmitOnBehalf: isSubmitOnBehalf
							})
						}).then((uploadResponse) => {
							try {
								// TODO: Handle error ie. saveHorsePlacementImage.success and also make this a reusable function.
								updatesFormData(
									uploadResponse.data.saveHorsePlacementImage?.id,
									'Draft'
								);
								let dataList = Object.assign([], additionalPhotos);
								dataList.push({ filename: image.name, key: imageKey });
								setAdditionalPhotos(dataList);
								addToast({ Message: 'Photo uploaded', IsSuccess: true });
								setIsSubmitted(false);
							} catch {
								addToast({
									Message: 'Error Uploading to S3...',
									IsSuccess: false
								});
							}
						});
					});
			}
		});
	};

	const saveVetReportUpload = (image, imageKey) => {
		AppSyncService.execute(uploadHorsePlacementImage, {
			filename: image.name,
			horseCode: horseCode,
			key: imageKey,
			type: 'vetReport',
			...(isSubmitOnBehalf && {
				memberId: selectedUserVerifiedInfo.id,
				isSubmitOnBehalf: isSubmitOnBehalf
			})
		}).then((uploadResponse) => {
			if (uploadResponse?.data?.saveHorsePlacementImage?.success) {
				updatesFormData(
					uploadResponse.data.saveHorsePlacementImage?.id,
					'Draft'
				);
				setVetReportUploads((prevState) => [
					...prevState,
					{ filename: image.name, key: imageKey }
				]);
				addToast({ Message: 'Vet report uploaded', IsSuccess: true });
				setIsSubmitted(false);
			} else {
				addToast({
					Message: 'Failed to save vet report',
					IsSuccess: false
				});
			}
		});
	};

	const saveVetReportRemove = (img) => {
		AppSyncService.execute(deleteHorsePlacementImageMutation, {
			key: img.key
		}).then((result) => {
			if (result?.data?.deleteHorsePlacementImage) {
				setVetReportUploads((prevState) =>
					prevState.filter((report) => report.key != img.key)
				);
				addToast({ Message: 'Vet report removed', IsSuccess: true });
			} else {
				addToast({
					Message: 'Failed to remove vet report',
					IsSuccess: false
				});
			}
		});
	};

	const removeFaceOnPhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorsePlacementImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteHorsePlacementImage) {
							let dataList = Object.assign([], faceOnPhotos);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setFaceOnPhotos(newDataList);
						}
					});
				}
			}
		);
	};

	const removeLeftSidePhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorsePlacementImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteHorsePlacementImage) {
							let dataList = Object.assign([], leftSidePhotos);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setLeftSidePhotos(newDataList);
						}
					});
				}
			}
		);
	};

	const removeRightSidePhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorsePlacementImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteHorsePlacementImage) {
							let dataList = Object.assign([], rightSidePhotos);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setRightSidePhotos(newDataList);
						}
					});
				}
			}
		);
	};

	const removeBehindFullPhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorsePlacementImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteHorsePlacementImage) {
							let dataList = Object.assign([], behindFullPhotos);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setBehindFullPhotos(newDataList);
						}
					});
				}
			}
		);
	};

	const removeAdditionalPhotos = (img) => {
		AppSyncService.execute(deleteImageFromS3, { imageKey: img.key }).then(
			(data) => {
				if (data?.data?.deleteUserImage?.success) {
					AppSyncService.execute(deleteHorsePlacementImageMutation, {
						key: img.key
					}).then((data) => {
						if (data.data.deleteHorsePlacementImage) {
							let dataList = Object.assign([], additionalPhotos);
							let newDataList = dataList.filter((d) => d.key != img.key);
							setAdditionalPhotos(newDataList);
						}
					});
				}
			}
		);
	};

	const onSelectUserVerified = (selectedUserVerified) => {
		if (selectedUserVerified?.id !== selectedUserVerifiedInfo?.id) {
			onClearFields();
		}
		setIsSelectUserRequired(false);
		setSelectedUserVerifiedInfo(selectedUserVerified);
		setHorsePlacementFormID(null);
	};

	const onToggleSubmitBehalf = (submitOnBehalf) => {
		setIsSubmitOnBehalf(submitOnBehalf);
		if (!submitOnBehalf) {
			onClearFields();
		}
	};

	const getImagesList = () => {
		let listOfKeys = [];
		if (rightSidePhotos.length > 0) {
			rightSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (leftSidePhotos.length > 0) {
			leftSidePhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (faceOnPhotos.length > 0) {
			faceOnPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (behindFullPhotos.length > 0) {
			behindFullPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (additionalPhotos.length > 0) {
			additionalPhotos.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}
		if (vetReportUploads.length > 0) {
			vetReportUploads.map((d) => {
				if (!d.isApproved) {
					listOfKeys.push(d.key);
				}
			});
		}

		return listOfKeys;
	};

	const onClearFields = () => {
		let listOfKeys = getImagesList();
		if (listOfKeys.length > 0) {
			removeImagesFromSelectedUser(listOfKeys);
		}
		clearSelectedFields();
	};

	const clearSelectedFields = () => {
		setDamName('');
		setValue('damName', '');
		setSireName('');
		setValue('sireName', '');
		setHeight('');
		setValue('height', '');
		setIsOtherDisciplineDisplayed(false);
		setDisciplinesValues([]);
		setDisciplinesOther('');
		setValue('disciplinesOther', '');
		setSelectedLengthOfRelationship('');
		setValue('lengthOfRelationship', '');
		setIsOtherRelationTextDisplayed(false);
		setRelationshipOther('');
		setSelectedRelationshipToHorse('');
		setSelectedRetirementReason('');
		setIsOtherRetirementTextDisplayed(false);
		setValue('retirementReason', '');
		setRetirementOtherReason('');
		setValue('retirementOtherReason', '');
		setSelectedIsKnownToWindsuck(0);
		setAddress('');
		setValue('address', '');
		setSuburb('');
		setValue('suburb', '');
		setPostCode('');
		setValue('postCode', '');
		const countryValue = DEFAULT_COUNTRY;
		setCountry(countryValue);
		setValue('country', countryValue);
		const stateValue = '';
		setState(stateValue);
		setValue('state', stateValue);
		setIsAddressManual(false);

		setCurrentOwnersData('');
		setValue('currentOwners', '');
		setCurrentRetrainersData('');
		setValue('currentRetrainers', '');
		setInjuriesValues([]);
		setTemperamentData('');
		setValue('temperament', '');
		setRiddenHistoryData('');
		setValue('riddenHistory', '');
		setOtherDetailsData('');
		setValue('otherDetails', '');
		setIsEligibleForRESET(false);
		setIsHelpingTransportCosts(true);
		setIsContributeRetrainingCosts(true);
		setIsTermsDeclaration(false);
		setIsRegisterDeclaration(false);
	};

	const removeImagesFromSelectedUser = async (listOfKeys) => {
		const result = await AppSyncService.execute(deleteImagesFromS3, {
			imageKeys: listOfKeys
		});
		if (result?.data?.deleteUserImages?.success) {
			const deletedFileResult = await AppSyncService.execute(
				deleteHorsePlacementFormMutation,
				{
					id: horsePlacementFormID,
					memberExternalId: selectedUserVerifiedInfo?.memberExternalId
				}
			);
			if (deletedFileResult?.data?.deleteHorsePlacementForm) {
				setAdditionalPhotos([]);
				setBehindFullPhotos([]);
				setFaceOnPhotos([]);
				setLeftSidePhotos([]);
				setRightSidePhotos([]);
				setVetReportUploads([]);
			}
		}
	};
	const onRenderContactMemberInfo = () => {
		return (
			<Row>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Contact Name*</div>
						<Controller
							control={control}
							name="contactName"
							render={() => (
								<FormTextField
									id="horsePlacement-contactName"
									value={memberData?.firstName}
									canEdit={false}
								/>
							)}
						/>
						<span className={classes.errorText}>
							{errors.contactName?.message}
						</span>
					</div>
				</Col>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Contact Email*</div>
						<Controller
							control={control}
							name="contactEmail"
							render={() => (
								<FormTextField
									id="horsePlacement-contactEmail"
									value={memberData?.email}
									canEdit={false}
								/>
							)}
						/>
						<span className={classes.errorText}>
							{errors.contactEmail?.message}
						</span>
					</div>
				</Col>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Contact Number*</div>
						<Controller
							control={control}
							name="contactNumber"
							render={({ onChange }) => (
								<FormTextField
									id="horsePlacement-contactNumber"
									value={contactNumber}
									canEdit={!memberData?.phone}
									onChange={(e) => {
										onChange(e.target.value);
										updatePhoneNumber(e.target.value);
									}}
								/>
							)}
						/>
						{isSubmitClicked &&
							(contactNumber == null || contactNumber.trim().length === 0) && (
								<span className={classes.errorText}>
									Contact Number is required
								</span>
							)}
					</div>
				</Col>
			</Row>
		);
	};

	const isShowOtherInputField = (selectedItems) => {
		return (
			selectedItems && selectedItems.find(({ label }) => label === 'Other')
		);
	};

	const onRenderContactMemberInfoByAdmin = () => {
		return (
			<Row>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Contact Name*</div>
						<Controller
							control={control}
							name="contactName"
							render={() => (
								<FormTextField
									id="horsePlacement-contactName"
									value={
										selectedUserVerifiedInfo
											? selectedUserVerifiedInfo.firstName
											: ''
									}
									onChange={(e) => {
										setSelectedUserVerifiedInfo({
											...selectedUserVerifiedInfo,
											firstName: e.target.value
										});
										setValue('contactName', e.target.value);
									}}
									canEdit={true}
								/>
							)}
						/>

						{isSubmitClicked &&
							(selectedUserVerifiedInfo?.firstName == null ||
								selectedUserVerifiedInfo?.firstName.trim().length === 0) && (
								<span className={classes.errorText}>
									Contact Name is required
								</span>
							)}
					</div>
				</Col>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldContainer}>
							<div className={classes.fieldTitle}>Contact Email*</div>
							<Controller
								control={control}
								name="contactEmail"
								render={() => (
									<FormTextField
										id="horsePlacement-contactEmail"
										value={
											selectedUserVerifiedInfo
												? selectedUserVerifiedInfo.email
												: ''
										}
										canEdit={false}
									/>
								)}
							/>
							<span className={classes.errorText}>
								{errors.contactEmail?.message}
							</span>
						</div>
						<span className={classes.errorText}>
							{errors.contactEmail?.message}
						</span>
					</div>
				</Col>
				<Col md={12} lg={4}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Contact Number*</div>
						<Controller
							control={control}
							name="contactNumber"
							render={() => (
								<FormTextField
									name="contactNumber"
									value={
										selectedUserVerifiedInfo
											? selectedUserVerifiedInfo.phone
											: ''
									}
									onChange={(e) => {
										setSelectedUserVerifiedInfo({
											...selectedUserVerifiedInfo,
											phone: e.target.value
										});
										setContactNumber(e.target.value);
										setValue('contactNumber', e.target.value);
									}}
									canEdit={true}
								/>
							)}
						/>
						{isSubmitClicked &&
							(selectedUserVerifiedInfo?.phone == null ||
								selectedUserVerifiedInfo?.phone.trim().length === 0) && (
								<span className={classes.errorText}>
									Contact Number is required
								</span>
							)}
					</div>
				</Col>
			</Row>
		);
	};

	const desktopComponent = (
		<Container className={classes.pageContainer}>
			<SEO title="Request for Retraining Form" />
			{isAdminUser ? (
				<SubmitOnBehalf
					horseCode={id}
					onToggleSubmitBehalf={onToggleSubmitBehalf}
					onSelectUserVerified={onSelectUserVerified}
					isGetAllUsersVerified
					selectUserRequiredError={selectUserRequiredError}
					isSelectUserRequired={isSelectUserRequired}
				/>
			) : null}
			<HeaderBanner
				title="Request for Retraining Form"
				top
				type="primary"
				styles={{ marginBottom: '0px', borderRadius: '5px 5px 0px 0px' }}
			/>
			<MessageBar>
				The RV Acknowledged Retrainers specialise in re-educating and rehoming
				retired racehorses for equestrian purposes. If you would like to
				transfer ownership of your horse to an Acknowledged Retrainer (for no
				fee), you can make a request by filling in this form. This will assist
				the RV Acknowledged Retrainers to understand the suitability of this
				horse to their Retraining experience, disciplines and approach. The more
				detail you can supply, the better.
				<br />
				<br />
				Please note that, if your horse is accepted by an RV Acknowledged
				Retrainer, you will be required to relinquish ownership of that horse to
				the RV Acknowledged Retrainer for no fee i.e. there will not be a
				purchase price attached to any horses submitted via this form. If you
				wish to sell this horse for a price, you can advertise it on websites
				such as Horse Deals or Horsezone.
				<br />
				<br />
				This form can only be used by a Registered Owner, Registered Trainer,
				Registered Managing Owner (in the case of a syndicate) or Registered
				Managing Lessee (in the case of a lease) of this horse (as per Racing
				Victoria’s records).
				<br />
				<br />
				Please ensure you have adequate knowledge of this horse’s background
				including any training undertaken and past injuries, as well as having 4
				full length images of this horse from each side, front on and from
				behind.
			</MessageBar>
			<HeaderBanner
				title="Thoroughbred Details"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '30px' }}
			/>
			<form className={classes.formContainer} onSubmit={handleSubmit(onSubmit)}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}

				<div className={classes.sectionContainer}>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Racing Name*</div>
								<Controller
									control={control}
									name="horseName"
									render={() => (
										<FormTextField
											id="horsePlacement-horseName"
											value={addNameToUnnamedHorse(horseName)}
											canEdit={false}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.horseName?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Age*</div>
								<Controller
									control={control}
									name="horseAge"
									render={() => (
										<FormTextField
											id="horsePlacement-horseAge"
											value={horsePlacementFormData?.horse?.age}
											canEdit={false}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.horseAge?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Colour*</div>
								<FormTextField
									id="horsePlacement-colour"
									name="colour"
									value={horsePlacementFormData?.horse?.colour}
									canEdit={false}
								/>
								<span className={classes.errorText}>
									{errors.colour?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sire Name*</div>
								<Controller
									control={control}
									name="sireName"
									render={({ onChange }) => (
										<FormTextField
											id="horsePlacement-sireName"
											value={sireName}
											onChange={(e) => {
												onChange(e.target.value);
												setSireName(e.target.value);
											}}
											canEdit={!isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.sireName?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Dam Name*</div>
								<Controller
									control={control}
									name="damName"
									render={({ onChange }) => (
										<FormTextField
											id="horsePlacement-damName"
											value={damName}
											onChange={(e) => {
												onChange(e.target.value);
												setDamName(e.target.value);
											}}
											canEdit={!isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.damName?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Horse Sex</div>
								<FormTextField
									id="horsePlacement-horseSex"
									value={horsePlacementFormData?.horse?.sex}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Reason for Retirement*</div>
								<Controller
									control={control}
									name="retirementReason"
									render={({ onChange }) => (
										<FormDropDown
											id="horsePlacement-retirementReason"
											items={referenceRetirementReason}
											selectedId={selectedRetirementReason}
											onChange={(e) => {
												onChange(e.target.value);
												updateRetirementReason(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
											disabled={isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.retirementReason?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Approx. Horse Height*</div>
								<Controller
									control={control}
									name="height"
									render={({ onChange }) => (
										<FormTextField
											id="horsePlacement-height"
											value={height}
											onChange={(e) => {
												onChange(e.target.value);
												setHeight(e.target.value);
											}}
											canEdit={!isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.height?.message}
								</span>
							</div>
						</Col>
					</Row>
					{isOtherRetirementTextDisplayed && (
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Other reasons for Retirement (separated by commas)*
									</div>
									<Controller
										control={control}
										name="retirementOtherReason"
										render={({ onChange }) => (
											<FormTextField
												id="horsePlacement-retirementOtherReason"
												value={retirementOtherReason}
												onChange={(e) => {
													onChange(e.target.value);
													setRetirementOtherReason(e.target.value);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.retirementOtherReason?.message}
									</span>
								</div>
							</Col>
						</Row>
					)}
					<hr className={classes.divider} />
					<FormAddressEntryGeneric
						address={address}
						onAddressChange={onAddressChange}
						isPostalAddressManual={false}
						suburb={suburb}
						onSuburbChange={onSuburbChange}
						postcode={postCode}
						onPostCodeChange={onPostCodeChange}
						country={country}
						onCountryChange={onCountryChange}
						manualAddressCheckBoxData={manualAddressCheckBoxData}
						toggleManualAddressCheckBox={toggleManualAddress}
						state={state}
						onAddressStateChange={onAddressStateChange}
						isSubmitClicked={isSubmitClicked}
						disabled={isReadOnly}
					/>
					<span className={classes.errorText}></span>
					<MessageBar>
						To increase the chances of your horse being accepted by a retrainer,
						please consider financially supporting the retraining and/ or
						transport costs.
						<br />
						<br />
						For example:
						<br />
						<br />
						$500 would contribute towards the horse’s initial body work, dentist
						and farrier services.
						<br />
						<br />
						$600 would cover a month’s worth of spelling costs.
						<br />
						<br />
						$1000 will contribute towards the retraining and rehoming costs.
						<br />
						<br />
						$3,500 will cover most of the retraining expenses.
						<br />
						<br />
						Any contribution you choose will significantly improve your horses
						chances of being accepted by a Retrainer.
						<br />
						<br />
						<span style={{ fontWeight: 'bold' }}>
							As a minimum we recommend you arrange the transport of the horse
							to the Retrainer’s property.{' '}
						</span>
					</MessageBar>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Are you willing to contribute towards the retraining costs?*
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateIsContributeRetrainingCosts(value)}
									value={isContributeRetrainingCosts}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Are you willing to help with transport costs?*
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateIsHelpingTransportCosts(value)}
									value={isHelpingTransportCosts}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Contact"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					{isSubmitOnBehalf && selectedUserVerifiedInfo
						? onRenderContactMemberInfoByAdmin()
						: onRenderContactMemberInfo()}
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Your relationship to this horse*
								</div>
								<Controller
									control={control}
									name="relationshipToHorse"
									render={({ onChange }) => (
										<FormDropDown
											id="horsePlacement-relationshipToHorse"
											items={referenceRelationshipToHorse}
											selectedId={selectedRelationshipToHorse}
											onChange={(e) => {
												onChange(e.target.value);
												updateRelationshipToHorse(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
											disabled={isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.relationshipToHorse?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Length of relationship to this horse*
								</div>
								<Controller
									control={control}
									name="lengthOfRelationship"
									render={({ onChange }) => (
										<FormDropDown
											id="horsePlacement-lengthOfRelationship"
											items={referenceLengthOfRelationship}
											selectedId={selectedLengthOfRelationship}
											onChange={(e) => {
												onChange(e.target.value);
												updateLengthOfRelationship(e.target.value);
											}}
											styles={{ width: '100%' }}
											showBlank
											disabled={isReadOnly}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.lengthOfRelationship?.message}
								</span>
							</div>
						</Col>
					</Row>
					{isOtherRelationTextDisplayed && (
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Other relationship to this horse (separated by commas)*
									</div>
									<Controller
										control={control}
										name="relationshipOther"
										render={({ onChange }) => (
											<FormTextField
												id="horsePlacement-relationshipOther"
												value={relationshipOther}
												onChange={(e) => {
													onChange(e.target.value);
													setRelationshipOther(e.target.value);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.relationshipOther?.message}
									</span>
								</div>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Who are the current owner(s)? (comma separated)*
									</span>
									<span className={classes.charLengthInfo}>
										{currentOwnersRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="currentOwners"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-currentOwners"
											value={currentOwners}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setCurrentOwnersData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(currentOwners == null ||
										currentOwners.trim().length === 0 ||
										currentOwners.trim().length > 200) && (
										<span className={classes.errorText}>
											Current owner(s) is required and should have maximum of
											200 characters
										</span>
									)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Who are the current/most recent trainer(s)? (comma
										separated)*
									</span>
									<span className={classes.charLengthInfo}>
										{currentRetrainersRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="currentRetrainers"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-currentRetrainers"
											value={currentRetrainers}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setCurrentRetrainersData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(currentRetrainers == null ||
										currentRetrainers.trim().length === 0 ||
										currentRetrainers.trim().length > 100) && (
										<span className={classes.errorText}>
											Current/most recent trainer(s) is required and should have
											maximum of 100 characters
										</span>
									)}
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Injury and Health"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row style={{ paddingTop: 15, paddingBottom: 10 }}>
						<FormFieldWrapper
							addTopSpace
							fieldLabel="Has this horse been known to Windsuck"
							isRequired
							errorMessage={errors.isKnownToWindsuck?.message}
						>
							<Controller
								control={control}
								name="isKnownToWindsuck"
								render={({ onChange }) => (
									<FormDropDown
										id="horsePlacement-isKnownToWindsuck"
										items={referenceIsKnownToWindsuck}
										selectedId={selectedIsKnownToWindsuck}
										onChange={(e) => {
											onChange(e.target.value);
											updateIsKnownToWindsuck(e.target.value);
										}}
										styles={{ width: '100%' }}
										showBlank
										disabled={isReadOnly}
									/>
								)}
							/>
						</FormFieldWrapper>
						<FormFieldWrapper
							fieldLabel="Is this horse impacted by a current or long term injury?"
							isRequired
						>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => updateWasInjured(value)}
								value={wasInjured}
								disabled={isReadOnly}
							/>
						</FormFieldWrapper>
						{wasInjured && (
							<Col sm={12} lg={4} className={classes.formFieldWrapper}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`If yes, please select injury type(s)*`}
											value={injuriesValues
												.map((injuries) => injuries.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div
										className={classes.fieldContainer}
										style={{ marginTop: 5 }}
									>
										<div className={classes.fieldTitle}>
											If yes, please select injury type(s)*
										</div>
										<FormMultiSelectList
											id="horsePlacement-injuries"
											name="injuries"
											listOptions={injuriesList}
											selectedIds={injuriesValues}
											onSelectChange={(value) =>
												injuriesValuesChanged(value, setInjuriesValues)
											}
										/>
										{isSubmitClicked &&
											(injuriesValues == null ||
												injuriesValues.length === 0) && (
												<span className={classes.errorText}>
													Injury types is required
												</span>
											)}
									</div>
								)}
							</Col>
						)}
					</Row>
					{wasInjured && (
						<>
							{isShowOtherInputField(injuriesValues) && (
								<Row>
									<FormFieldWrapper
										fullWidth
										fieldLabel="Injury types - Other"
										isRequired
									>
										<ResizingTextArea
											name="injuryOther"
											id="injuryOther"
											value={injuryOther}
											onChange={(e) => {
												setInjuryOther(e.target.value);
											}}
											maxChars={500}
											charLengthStyle={{
												fontWeight: 'normal'
											}}
											styles={{
												marginLeft: 0,
												marginRight: 0,
												padding: 0
											}}
											titleStyles={{ fontSize: '14px' }}
											canEdit={!isReadOnly}
										/>
										{isSubmitClicked &&
											(injuryOther == null ||
												injuryOther.trim().length === 0 ||
												injuryOther.trim().length > 500) && (
												<span className={classes.errorText}>
													Injury types - Other is required and should have
													maximum of 500 characters
												</span>
											)}
									</FormFieldWrapper>
								</Row>
							)}
							<Row>
								<FileUploadInputField
									idPrefix="horse-placement-form-vet-reports"
									fieldLabel="Please upload any veterinarian reports"
									buttonText="Upload report"
									isRequired
									fileData={vetReportUploads}
									onSuccessfulS3Upload={saveVetReportUpload}
									onSuccessfulS3Delete={saveVetReportRemove}
									onSuccessfulS3ImageDownload={filePreviewHandler}
									formats={['png', 'jpeg', 'pdf', WORD_DOC_MIME_TYPE]}
									numberOfFiles={3}
									inputErrorHandler={() => setShowImageUploadErrorModal(true)}
									isEditable={!isReadOnly}
									validationError={
										isSubmitClicked &&
										wasInjured &&
										(!vetReportUploads || vetReportUploads.length == 0)
											? 'Veterinarian report is required'
											: ''
									}
									isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
								/>
								<FormFieldWrapper fieldLabel="Does this injury currently impact the horses soundness?">
									<Toggle
										labelNegative="No"
										labelPositive="Yes"
										onClick={(value) => setIsImpactSoundness(value)}
										value={isImpactSoundness}
										disabled={isReadOnly}
									/>
								</FormFieldWrapper>
							</Row>
						</>
					)}
					<Row style={{ marginTop: 5 }}>
						<FormFieldWrapper
							fieldLabel="Is this horse impacted by a current or long term illness?"
							isRequired
						>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => updateHasIllness(value)}
								value={hasIllness}
								disabled={isReadOnly}
							/>
						</FormFieldWrapper>
						{hasIllness && (
							<Col sm={12} lg={4} className={classes.formFieldWrapper}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`If yes, please select illness type(s)*`}
											value={illnessesValues
												.map((injuries) => injuries.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div
										className={classes.fieldContainer}
										style={{ marginTop: 5 }}
									>
										<div className={classes.fieldTitle}>
											If yes, please select illness type(s)*
										</div>
										<FormMultiSelectList
											id="horsePlacement-illnesses"
											name="illnesses"
											listOptions={illnessesList}
											selectedIds={illnessesValues}
											onSelectChange={(value) =>
												injuriesValuesChanged(value, setIllnessesValues)
											}
										/>
										{isSubmitClicked &&
											(illnessesValues == null ||
												illnessesValues.length === 0) && (
												<span className={classes.errorText}>
													Illness types is required
												</span>
											)}
									</div>
								)}
							</Col>
						)}
					</Row>
					{hasIllness && isShowOtherInputField(illnessesValues) && (
						<Row>
							<FormFieldWrapper
								fullWidth
								fieldLabel="Illness types - Other"
								isRequired
							>
								<ResizingTextArea
									name="illnessOther"
									id="illnessOther"
									value={illnessOther}
									onChange={(e) => {
										setIllnessOther(e.target.value);
									}}
									maxChars={500}
									charLengthStyle={{
										fontWeight: 'normal'
									}}
									styles={{
										marginLeft: 0,
										marginRight: 0,
										padding: 0
									}}
									titleStyles={{ fontSize: '14px' }}
									canEdit={!isReadOnly}
								/>
								{isSubmitClicked &&
									(illnessOther == null ||
										illnessOther.trim().length === 0 ||
										illnessOther.trim().length > 500) && (
										<span className={classes.errorText}>
											Illness types - Other is required and should have maximum
											of 500 characters
										</span>
									)}
							</FormFieldWrapper>
						</Row>
					)}
					<Row style={{ marginTop: 5 }}>
						<FormFieldWrapper
							fieldLabel="Has this horse ever been injured? Please include injuries which are now fully recovered?"
							isRequired
						>
							<Toggle
								labelNegative="No"
								labelPositive="Yes"
								onClick={(value) => updateisPastInjuryImpactedHorse(value)}
								value={isPastInjuryImpactedHorse}
								disabled={isReadOnly}
							/>
						</FormFieldWrapper>
						{isPastInjuryImpactedHorse && (
							<Col sm={12} lg={4} className={classes.formFieldWrapper}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`If yes, please select injury type(s)*`}
											value={injuriesPastValues
												.map((injuries) => injuries.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div
										className={classes.fieldContainer}
										style={{ marginTop: 5 }}
									>
										<div className={classes.fieldTitle}>
											If yes, please select injury type(s)*
										</div>
										<FormMultiSelectList
											id="horsePlacement-injuries"
											name="injuries"
											listOptions={injuriesList}
											selectedIds={injuriesPastValues}
											onSelectChange={(value) =>
												injuriesValuesChanged(value, setInjuriesPastValues)
											}
										/>
										{isSubmitClicked &&
											(injuriesPastValues == null ||
												injuriesPastValues.length === 0) && (
												<span className={classes.errorText}>
													Injury types is required
												</span>
											)}
									</div>
								)}
							</Col>
						)}
					</Row>
					{isPastInjuryImpactedHorse &&
						isShowOtherInputField(injuriesPastValues) && (
							<Row>
								<FormFieldWrapper
									fullWidth
									fieldLabel="Injury types - Other"
									isRequired
								>
									<ResizingTextArea
										name="injuryPastOther"
										id="injuryPastOther"
										value={injuryPastOther}
										onChange={(e) => {
											setInjuryPastOther(e.target.value);
										}}
										maxChars={500}
										charLengthStyle={{
											fontWeight: 'normal'
										}}
										styles={{
											marginLeft: 0,
											marginRight: 0,
											padding: 0
										}}
										titleStyles={{ fontSize: '14px' }}
										canEdit={!isReadOnly}
									/>
									{isSubmitClicked &&
										(injuryPastOther == null ||
											injuryPastOther.trim().length === 0 ||
											injuryPastOther.trim().length > 500) && (
											<span className={classes.errorText}>
												Injury types - Other is required and should have maximum
												of 500 characters
											</span>
										)}
								</FormFieldWrapper>
							</Row>
						)}

					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Please explain in detail the nature of the injury/ illness
										and the veterinary care and any rehabilitation that has
										occurred both during and prior to the horse being in your
										care.{' '}
										<span style={{ fontWeight: 'normal' }}>
											This information is very important for the review of the
											horses long term options and support that may be available
											for retraining and rehoming successfully.
										</span>
										{(hasIllness || wasInjured || isPastInjuryImpactedHorse) &&
											'*'}
									</span>
									<span className={classes.charLengthInfo}>
										{illnessAndInjuriesDetailsRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="injuriesAndIllnessDetails"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-injuriesAndIllnessDetails"
											value={illnessAndInjuriesDetails}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setIllnessAndInjuriesDetailsData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(hasIllness || wasInjured || isPastInjuryImpactedHorse) &&
									(illnessAndInjuriesDetails == null ||
										illnessAndInjuriesDetails.trim().length === 0 ||
										illnessAndInjuriesDetails.trim().length > 500) && (
										<span className={classes.errorText}>
											Nature of injury/illness is required and should have
											maximum of 500 characters
										</span>
									)}
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Information"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										To the best of your knowledge, how would you describe this
										horse&apos;s temperament?*
									</span>
									<span className={classes.charLengthInfo}>
										{temperamentRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="temperament"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-temperament"
											value={temperament}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setTemperamentData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(temperament == null ||
										temperament.trim().length === 0 ||
										temperament.trim().length > 500) && (
										<span className={classes.errorText}>
											Temperament is required and should have maximum of 500
											characters
										</span>
									)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Does this horse currently have any vices?
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateDoesHorseHasCurrentVices(value)}
									value={doesHorseHasCurrentVices}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{doesHorseHasCurrentVices && (
							<Col sm={12} lg={4} className={classes.formFieldWrapper}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`If yes, please select vice type(s)*`}
											value={vicesCurrentValues
												.map((injuries) => injuries.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div
										className={classes.fieldContainer}
										style={{ marginTop: 5 }}
									>
										<div className={classes.fieldTitle}>
											If yes, please select vice type(s)*
										</div>
										<FormMultiSelectList
											id="horsePlacement-vices"
											name="vices"
											listOptions={habitsList}
											selectedIds={vicesCurrentValues}
											onSelectChange={(value) =>
												injuriesValuesChanged(value, setVicesCurrentValues)
											}
										/>
										{isSubmitClicked &&
											(vicesCurrentValues == null ||
												vicesCurrentValues.length === 0) && (
												<span className={classes.errorText}>
													Vice types is required
												</span>
											)}
									</div>
								)}
							</Col>
						)}
					</Row>
					{doesHorseHasCurrentVices &&
						isShowOtherInputField(vicesCurrentValues) && (
							<Row>
								{!isReadOnly ? (
									<FormFieldWrapper
										fieldLabel={
											<div>
												Other{' '}
												<span style={{ fontWeight: 'normal' }}>
													(Please separate individual values by using a comma)
												</span>
												*
											</div>
										}
										fullWidth
									>
										<MultiSelectInput
											id="vices-current-other"
											value={habitsCurrentOther}
											updateValue={(newOtherValue) => {
												setHabitsCurrentOther(newOtherValue);
											}}
											hasError={!habitsCurrentOther}
										/>
										{isSubmitClicked && habitsCurrentOther?.length == 0 && (
											<span className={classes.errorText}>
												Vice types - Other is required
											</span>
										)}
									</FormFieldWrapper>
								) : (
									<FormLabelField
										title="Other (Please separate individual values by using a comma)*"
										value={habitsCurrentOtherReadOnly}
										style={{ padding: 0 }}
									/>
								)}
							</Row>
						)}
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has this horse ever had any vices reported/ identified?
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateDoesHorseHasPastVices(value)}
									value={doesHorseHasPastVices}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{doesHorseHasPastVices && (
							<Col sm={12} lg={4} className={classes.formFieldWrapper}>
								{isReadOnly ? (
									<div style={{ padding: '10px 0px 10px 0px' }}>
										<FormLabelField
											title={`If yes, please select vice type(s)*`}
											value={vicesPastValues
												.map((injuries) => injuries.label)
												.join(', ')}
											styles={{ padding: 0 }}
										/>
									</div>
								) : (
									<div
										className={classes.fieldContainer}
										style={{ marginTop: 5 }}
									>
										<div className={classes.fieldTitle}>
											If yes, please select vice type(s)*
										</div>
										<FormMultiSelectList
											id="horsePlacement-vices"
											name="vices"
											listOptions={habitsList}
											selectedIds={vicesPastValues}
											onSelectChange={(value) =>
												injuriesValuesChanged(value, setVicesPastValues)
											}
										/>
										{isSubmitClicked &&
											(vicesPastValues == null ||
												vicesPastValues.length === 0) && (
												<span className={classes.errorText}>
													Vice types is required
												</span>
											)}
									</div>
								)}
							</Col>
						)}
					</Row>
					{doesHorseHasPastVices && isShowOtherInputField(vicesPastValues) && (
						<Row>
							{!isReadOnly ? (
								<FormFieldWrapper
									fieldLabel={
										<div>
											Other{' '}
											<span style={{ fontWeight: 'normal' }}>
												(Please separate individual values by using a comma)
											</span>
											*
										</div>
									}
									fullWidth
								>
									<MultiSelectInput
										id="vices-past-other"
										value={habitsPastOther}
										updateValue={(newOtherValue) => {
											setHabitsPastOther(newOtherValue);
										}}
										hasError={!habitsPastOther}
									/>
									{isSubmitClicked && habitsPastOther?.length == 0 && (
										<span className={classes.errorText}>
											Vice types - Other is required
										</span>
									)}
								</FormFieldWrapper>
							) : (
								<FormLabelField
									title="Other (Please separate individual values by using a comma)*"
									value={habitsPastOtherReadOnly}
									style={{ padding: 0 }}
								/>
							)}
						</Row>
					)}
					<Row>
						<Col>
							{isReadOnly ? (
								<div style={{ padding: '10px 0px 10px 0px' }}>
									<FormLabelField
										title={`Based on your experience, what discipline(s) do you believe
										this horse will be suited to?*`}
										value={disciplinesValues
											.map((discipline) => discipline.label)
											.join(', ')}
										styles={{ padding: 0 }}
									/>
								</div>
							) : (
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Based on your experience, what discipline(s) do you believe
										this horse will be suited to?*
									</div>
									<FormMultiSelectList
										id="horsePlacement-disciplines"
										name="disciplines"
										listOptions={disciplinesList}
										selectedIds={disciplinesValues}
										onSelectChange={disciplinesValuesChanged}
									/>
									{isSubmitClicked &&
										(disciplinesValues == null ||
											disciplinesValues.length === 0) && (
											<span className={classes.errorText}>
												Disciplines is required
											</span>
										)}
								</div>
							)}
						</Col>
					</Row>
					{isOtherDisciplineDisplayed && (
						<Row>
							<Col>
								<div className={classes.fieldContainer}>
									<div className={classes.fieldTitle}>
										Other (separated by commas)
									</div>
									<Controller
										control={control}
										name="disciplinesOther"
										render={({ onChange }) => (
											<FormTextField
												id="horsePlacement-disciplinesOther"
												value={disciplinesOther}
												onChange={(e) => {
													onChange(e.target.value);
													setDisciplinesOther(e.target.value);
												}}
											/>
										)}
									/>
									<span className={classes.errorText}>
										{errors.disciplinesOther?.message}
									</span>
								</div>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Please provide a detailed history of this horse’s ridden
										education. If applicable, please specifically include any
										training undertaken away from the racetrack (e.g. dressage
										training).*
									</span>
									<span className={classes.charLengthInfo}>
										{riddenHistoryRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="riddenHistory"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-riddenHistory"
											value={riddenHistory}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setRiddenHistoryData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(riddenHistory == null ||
										riddenHistory.trim().length === 0 ||
										riddenHistory.trim().length > 700) && (
										<span className={classes.errorText}>
											Detailed history is required and should have maximum of
											700 characters
										</span>
									)}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div>
									<span className={classes.fieldTitle}>
										Please provide information on any other relevant matters or
										details regarding the horse’s temperament, physical
										condition, mental condition or education, including any
										specific requirements they may have (inclusive of feed, care
										or medication) to enable the new owner/ retrainer to
										successfully manage the horse.*
									</span>
									<span className={classes.charLengthInfo}>
										{otherDetailsRemaining} characters remaining
									</span>
								</div>
								<Controller
									control={control}
									name="otherDetails"
									render={({ onChange }) => (
										<FormTextArea
											id="horsePlacement-otherDetails"
											value={otherDetails}
											styles={{ hight: '60px' }}
											onChange={(e) => {
												onChange(e.target.value);
												setOtherDetailsData(e.target.value);
											}}
											disabled={isReadOnly}
										/>
									)}
								/>
								{isSubmitClicked &&
									(otherDetails == null ||
										otherDetails.trim().length === 0 ||
										otherDetails.trim().length > 500) && (
										<span className={classes.errorText}>
											Any other relevant matters or details is required and
											should have maximum of 500 characters
										</span>
									)}
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Thoroughbred Photos"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<Row>
					<Col>
						<MessageBar
							type="primary"
							style={{ marginTop: 10, marginLeft: 20, marginRight: 20 }}
						>
							To meet the requirements, please provide <b>at least 4 photos.</b>{' '}
							Please use landscape photos where possible.
							<br />
							Videos are encouraged, please email to{' '}
							<a
								className={classes.mailLink}
								href="mailto:offthetrack@racingvictoria.net.au"
							>
								offthetrack@racingvictoria.net.au
							</a>
							<br />
							<br />
							<a
								href={'/photo-upload-guide'}
								target="_blank"
								rel="noreferrer"
								className={classes.mailLink}
							>
								<b>
									Need help with photo requirements? Click here to view examples
									of suitable photos of your thoroughbred.
								</b>
							</a>
						</MessageBar>
					</Col>
				</Row>
				<div className={classes.sectionContainer}>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Left Side - Full Length & Brand (jpg,png)*
									<MainPhotoUploadToolTip
										mobilePositionStyles={{
											top: -70,
											left: -190
										}}
									>
										Retrainers viewing the horse profile will see this image
										first.
									</MainPhotoUploadToolTip>
								</div>
								<Controller
									control={control}
									name="leftSidePhotoImages"
									render={({ onChange }) => (
										<UploadPhoto
											id="horsePlacement-leftSidePhotos"
											formats={['png', 'jpeg']}
											labelName="Upload Left Side Photo"
											error={uploadError}
											data={leftSidePhotos}
											sizeLimitMB={5}
											numberOfImages={1}
											success={(image) => leftSidePhotosUpload(image, onChange)}
											getImage={filePreviewHandler}
											removeImage={removeLeftSidePhotos}
											canEdit={!isReadOnly}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.leftSidePhotoImages?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Right Side - Full Length & Brand (jpg,png)*
								</div>
								<Controller
									control={control}
									name="rightSidePhotoImages"
									render={({ onChange }) => (
										<UploadPhoto
											id="horsePlacement-rightSidePhotos"
											formats={['png', 'jpeg']}
											labelName="Upload Right Side Photo"
											error={uploadError}
											data={rightSidePhotos}
											sizeLimitMB={5}
											numberOfImages={1}
											success={(image) =>
												rightSidePhotosUpload(image, onChange)
											}
											getImage={filePreviewHandler}
											removeImage={removeRightSidePhotos}
											canEdit={!isReadOnly}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.rightSidePhotoImages?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Face On - Full Length & Legs (jpg,png)*
								</div>
								<Controller
									control={control}
									name="faceOnPhotoImages"
									render={({ onChange }) => (
										<UploadPhoto
											id="horsePlacement-faceOnPhotos"
											formats={['png', 'jpeg']}
											labelName="Upload Face On Photo"
											error={uploadError}
											data={faceOnPhotos}
											sizeLimitMB={5}
											numberOfImages={1}
											success={(image) => faceOnPhotosUpload(image, onChange)}
											getImage={filePreviewHandler}
											removeImage={removeFaceOnPhotos}
											canEdit={!isReadOnly}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.faceOnPhotoImages?.message}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Behind - Full Length & Legs (jpg,png)*
								</div>
								<Controller
									control={control}
									name="behindFullPhotoImages"
									render={({ onChange }) => (
										<UploadPhoto
											id="horsePlacement-behindFullPhotos"
											formats={['png', 'jpeg']}
											labelName="Upload Behind Photo"
											error={uploadError}
											data={behindFullPhotos}
											sizeLimitMB={5}
											numberOfImages={1}
											success={(image) =>
												behindFullPhotosUpload(image, onChange)
											}
											getImage={filePreviewHandler}
											removeImage={removeBehindFullPhotos}
											canEdit={!isReadOnly}
											isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
										/>
									)}
								/>
								<span className={classes.errorText}>
									{errors.behindFullPhotoImages?.message}
								</span>
							</div>
						</Col>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Additional Photos (jpg,png)
								</div>
								<UploadPhoto
									id="horsePlacement-additionalPhotos"
									formats={['png', 'jpeg']}
									labelName="Upload Additional Photo(s)"
									error={uploadError}
									data={additionalPhotos}
									sizeLimitMB={5}
									numberOfImages={10}
									success={additionalPhotosUpload}
									getImage={filePreviewHandler}
									removeImage={removeAdditionalPhotos}
									canEdit={!isReadOnly}
									isDisabled={isSubmitOnBehalf && !selectedUserVerifiedInfo}
								/>
							</div>
						</Col>
					</Row>
				</div>
				<HeaderBanner
					title="Eligibility for the RESET Program"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									If this horse is not successful in this process, would you be
									interested in this horse being nominated for the RESET
									Program?*
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateIsEligibleForRESET(value)}
									value={isEligibleForRESET}
									disabled={isReadOnly}
								/>
							</div>
						</Col>
						{/* <Col md={12} lg={4}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									If the horse is considered eligible for the RESET Program and
									the horse does not find a home within the first 10 business
									days of being advertised, a veterinarian assessment will be
									arranged (to confirm the physical and mental health condition
									of the horse). Are you willing to arrange this?*
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									onClick={(value) => updateIsWillingToArrange(value)}
									value={isWillingToArrange}
									disabled={isReadOnly}
								/>
							</div>
						</Col> */}
					</Row>
					<MessageBar>
						Please note this will require additional information to be completed
						as part of the RESET Program nomination process, we will contact you
						if this horse becomes eligible for this program.
						<b>
							{' '}
							<a
								id="horseplacementform-resetprogramsection-resetreadmore"
								href="/programs/reset-landing-page"
								target="_blank"
							>
								Read More
							</a>
						</b>
					</MessageBar>
				</div>
				<HeaderBanner
					title="Declarations"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col xs={12}>
							<div className={classes.checkboxFieldContainer}>
								<SimpleCheckBox
									name="isRegisterDeclaration"
									data={{
										id: 'horse-placement-register-declaration',
										isSelected: isRegisterDeclaration
									}}
									changeCheckboxState={() =>
										isReadOnly
											? () => null
											: updateIsRegisterDeclaration(!isRegisterDeclaration)
									}
									custom
									customStyle={
										isSubmitClicked && !isRegisterDeclaration
											? { float: 'left', border: '1px solid red' }
											: { float: 'left' }
									}
								/>
								<div className={classes.checkboxField}>
									I declare that, to the best of knowledge and belief, the
									information provided in this form is true and correct, in
									particular the information provided regarding the horse’s
									health, veterinary related issues and temperament, and
									acknowledge that I may be subject to Racing Victoria
									enquiries, including pursuant to the{' '}
									<a
										href="https://www.racingvictoria.com.au/the-sport/racing/rules-of-racing"
										target="_blank"
										rel="noopener noreferrer"
									>
										Racing Victoria Rules of Racing
									</a>
									, for providing any false or misleading information, or
									deliberately omitting relevant information.*
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<div className={classes.checkboxFieldContainer}>
								<SimpleCheckBox
									name="isTermsDeclaration"
									data={{
										id: 'horse-placement-terms-declaration',
										isSelected: isTermsDeclaration
									}}
									changeCheckboxState={() =>
										isReadOnly
											? () => null
											: updateIsTermsDeclaration(!isTermsDeclaration)
									}
									custom
									customStyle={
										isSubmitClicked && !isTermsDeclaration
											? { float: 'left', border: '1px solid red' }
											: { float: 'left' }
									}
								/>
								<div className={classes.checkboxField}>
									I declare that I am the Registered Owner, Registered Managing
									Owner (in the case of a syndicate), Registered Managing Lessee
									(in the case of a lease) or Registered Trainer of this horse
									(as per Racing Victoria’s records).*
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<hr className={classes.dividerLine}></hr>
				<div className={classes.buttonContainer}>
					<div
						className={classes.cancelRequest}
						onClick={() => setConfirmationModal(true)}
						style={isCancelled ? { display: 'none' } : {}}
					>
						Cancel Request
					</div>
					<div className={classes.saveRequest}>
						{backendErrorMessage && (
							<span className={classes.errorMessage}>
								{backendErrorMessage}
							</span>
						)}
						{!backendErrorMessage && Object.keys(errors).length > 0 && (
							<span className={classes.errorMessage}>
								Please complete all required fields
							</span>
						)}
						<span
							className={classes.saveButton}
							onClick={() => sendRequest('Draft')}
							style={isSubmitted || isCancelled ? { display: 'none' } : {}}
						>
							Save Draft
						</span>
						<span>
							<PrimaryButton
								onClick={() => {
									setIsSubmitClicked(true);
									checkIfSubmitOnBehalf();
								}}
								id="horsePlacement-PrimaryButton"
								style={{ width: '165px' }}
								disabled={isSubmitDisabled || isSubmitted ? true : false}
							>
								{isSubmitted ? 'Submitted' : 'Submit Request'}
							</PrimaryButton>
						</span>
					</div>
				</div>
			</form>
			<UploadPhotoErrorModal
				showErrorModal={showImageUploadErrorModal}
				closeModal={() => setShowImageUploadErrorModal(false)}
				isMinimumUploadSize={isMinimumUploadSize}
			/>
			<ConfirmationModal
				showModal={confirmationModal}
				closeModal={() => setConfirmationModal(false)}
				styles={modalStylesConfirmation}
				heading="Cancel Request for Retraining Form"
				positiveLabel="Confirm"
				negativeLabel="Cancel"
				onClickPositive={() => cancelRequest()}
			>
				Are you sure you wish to delete this form?
			</ConfirmationModal>
		</Container>
	);

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? desktopComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

export default HorsePlacement;

import React from 'react';
import CheckboxInputField from '../FormComponents/CheckboxInputField';

const ManualAddressCheckBox = ({
	isSelected,
	onChangeHandler,
	checkBoxTitle = null,
	idPrefix
}) => {
	return (
		<CheckboxInputField
			idPrefix={idPrefix}
			isChecked={isSelected}
			onChange={onChangeHandler}
			checkBoxLabel={checkBoxTitle || 'Manually add my address'}
		/>
	);
};

export default ManualAddressCheckBox;

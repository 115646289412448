import React, {
	useState,
	useEffect,
	useImperativeHandle,
	forwardRef
} from 'react';
import { Col, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	saveBusinessInformation,
	getBusinessInfoReferenceData
} from '../../graphql/custom.queries';
import LoaderSpinner from '../LoaderSpinner';
import PrimaryButton from '../PrimaryButton';
import { useToast } from '../../hooks/useToast';
import FormMultiSelectList from '../FormMultiSelectList';
import MultiSelectInput from '../MultiSelectInput';
import { convertToSortedMultiListData } from '../../utils/helpers';
import { validation, MULTI_DEPENDENT, CHARACTER_LIMIT } from './validation';
import ResizingTextArea from '../ResizingTextArea';
import {
	ROLE_RV_ACKNOWLEDGED_RETRAINER,
	ROLE_ACKNOWLEDGED_FOSTER_CARER
} from '../../utils/constants';

const validationFields = [
	'businessSummary',
	'priceRange',
	'experiences',
	'facilitiesOnsiteValues',
	'facilitiesOffsiteValues',
	'specialtyDisciplinesValues',
	'servicesOfferedValues',
	'facilitiesOnSiteOtherMulti',
	'facilitiesOffSiteOtherMulti',
	'specialtyDisciplinesOtherMulti',
	'servicesOfferedOtherMulti'
];

const BusinessInfoDetails = forwardRef(
	(
		{
			currentUser,
			businessProfileData,
			isLoading,
			updateIsLoading,
			setIsSubmitted,
			businessProfileType,
			onSaveBusinessProfileData,
			validateFields
		},
		ref
	) => {
		const classes = useStyles();
		const { addToast } = useToast();

		const [isFormDisabled, setIsFormDisabled] = useState(true);
		const [backendErrorMessage, setBackendErrorMessage] = useState(null);
		const [errors, setErrors] = useState({});
		const [specialtyDisciplinesError, setSpecialtyDisciplinesError] = useState(
			''
		);

		const ackRetrainerRoleExist = currentUser?.Roles.some(
			(role) => role === ROLE_RV_ACKNOWLEDGED_RETRAINER
		);

		const ackFosterRoleExist = currentUser?.Roles.some(
			(role) => role === ROLE_ACKNOWLEDGED_FOSTER_CARER
		);

		if (!ackRetrainerRoleExist && !ackFosterRoleExist) {
			businessProfileType = 2;
		}

		const isRetirementFarmSelected = businessProfileType == 2;

		const [values, setValues] = useState({
			businessSummary: {
				value: '',
				isRequired: true,
				isTouched: false,
				conditions: [
					{
						condition: CHARACTER_LIMIT,
						limit: 700,
						errorMessage: 'Business Summary must have maximum of 700 characters'
					}
				],
				errorMessage: 'Business Summary is required'
			},
			priceRange: {
				value: '',
				isTouched: false,
				conditions: [
					{
						condition: CHARACTER_LIMIT,
						limit: 700,
						errorMessage: 'Price Range must have maximum of 700 characters'
					}
				],
				isRequired: false
			},
			experiences: {
				value: '',
				isTouched: false,
				conditions: [
					{
						condition: CHARACTER_LIMIT,
						limit: 700,
						errorMessage:
							'Experience / Qualifications must have maximum of 700 characters'
					}
				],
				isRequired: false
			},
			facilitiesOffsiteValues: {
				value: [],
				isRequired: false,
				isTouched: false
			},
			facilitiesOffSiteOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'facilitiesOffsiteValues',
						value: 'Other',
						errorMessage: `Other Facilities - Off Property are required`
					}
				]
			},
			facilitiesOnsiteValues: {
				value: [],
				isRequired: true,
				isTouched: false,
				errorMessage: `Facilities - On Property are required`
			},
			facilitiesOnSiteOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'facilitiesOnsiteValues',
						value: 'Other',
						errorMessage: `Other Facilities - On Property are required`
					}
				]
			},
			specialtyDisciplinesValues: {
				value: [],
				isTouched: false
			},
			specialtyDisciplinesOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'specialtyDisciplinesValues',
						value: 'Other',
						errorMessage: 'Other Specialty Disciplines are required'
					}
				]
			},
			servicesOfferedValues: {
				value: [],
				isRequired: true,
				isTouched: false,
				errorMessage: 'Services Offered are required'
			},
			servicesOfferedOtherMulti: {
				value: [],
				isRequired: false,
				conditions: [
					{
						condition: MULTI_DEPENDENT,
						field: 'servicesOfferedValues',
						value: 'Other',
						errorMessage: 'Other Services Offered are required'
					}
				]
			}
		});

		const [referenceFacilitiesOnsite, setReferenceFacilitiesOnsite] = useState(
			[]
		);
		const [facilitiesOnsiteList, setFacilitiesOnsiteList] = useState([]);
		const [
			referenceFacilitiesOffsite,
			setReferenceFacilitiesOffsite
		] = useState([]);
		const [facilitiesOffsiteList, setFacilitiesOffsiteList] = useState([]);
		const [referenceSpecialty, setReferenceSpecialty] = useState([]);
		const [specialtyDisciplinesList, setSpecialtyDisciplinesList] = useState(
			[]
		);

		const [referenceServices, setReferenceServices] = useState([]);
		const [servicesOfferedList, setServicesOfferedList] = useState([]);

		const handleChange = (e) => {
			setIsFormDisabled(false);
			let items = { ...values };
			let item = {
				...items[e.target.name],
				value: e.target.value,
				isTouched: true
			};
			items[e.target.name] = item;
			setValues(items);
		};

		useEffect(() => {
			AppSyncService.execute(getBusinessInfoReferenceData).then((result) => {
				if (result.data) {
					setReferenceFacilitiesOnsite(result.data.listFacilitiesOnProperty);
					setReferenceFacilitiesOffsite(result.data.listFacilitiesOffSite);
					setReferenceSpecialty(result.data.listSpecialtyDisciplines);
					setReferenceServices(result.data.listServicesOffered);
				}
			});
		}, []);

		useEffect(() => {
			let items = { ...values };
			let businessSummary = {
				...items['businessSummary'],
				value: businessProfileData ? businessProfileData.businessSummary : ''
			};
			items['businessSummary'] = businessSummary;
			let priceRange = {
				...items['priceRange'],
				value: businessProfileData ? businessProfileData.priceRange : ''
			};
			items['priceRange'] = priceRange;
			let experiences = {
				...items['experiences'],
				value: businessProfileData ? businessProfileData.experiences : ''
			};
			items['experiences'] = experiences;
			let facilitiesOnsiteValues = {
				...items['facilitiesOnsiteValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.facilitiesOnProperty : []
				)
			};
			items['facilitiesOnsiteValues'] = facilitiesOnsiteValues;
			let facilitiesOnSiteOtherMulti = {
				...items['facilitiesOnSiteOtherMulti'],
				value: businessProfileData
					? businessProfileData?.facilitiesOnPropertyOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['facilitiesOnSiteOtherMulti'] = facilitiesOnSiteOtherMulti;

			let facilitiesOffsiteValues = {
				...items['facilitiesOffsiteValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.facilitiesOffSite : []
				)
			};
			items['facilitiesOffsiteValues'] = facilitiesOffsiteValues;
			let facilitiesOffSiteOtherMulti = {
				...items['facilitiesOffSiteOtherMulti'],
				value: businessProfileData
					? businessProfileData?.facilitiesOffSiteOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['facilitiesOffSiteOtherMulti'] = facilitiesOffSiteOtherMulti;

			let specialtyDisciplinesValues = {
				...items['specialtyDisciplinesValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.specialtyDisciplines : []
				)
			};
			if (isRetirementFarmSelected) {
				setSpecialtyDisciplinesError('');
			}
			items['specialtyDisciplinesValues'] = specialtyDisciplinesValues;
			let specialtyDisciplinesOtherMulti = {
				...items['specialtyDisciplinesOtherMulti'],
				value: businessProfileData
					? businessProfileData?.specialtyDisciplinesOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['specialtyDisciplinesOtherMulti'] = specialtyDisciplinesOtherMulti;

			let servicesOfferedValues = {
				...items['servicesOfferedValues'],
				value: convertToSortedMultiListData(
					businessProfileData ? businessProfileData.servicesOffered : []
				)
			};
			items['servicesOfferedValues'] = servicesOfferedValues;
			let servicesOfferedOtherMulti = {
				...items['servicesOfferedOtherMulti'],
				value: businessProfileData
					? businessProfileData?.servicesOfferedOther
							?.split(',')
							.map((label) => ({ label: label, value: label })) || []
					: []
			};
			items['servicesOfferedOtherMulti'] = servicesOfferedOtherMulti;

			setValues(items);
			updateIsLoading(false);
			if (referenceFacilitiesOnsite && referenceFacilitiesOnsite.length > 1) {
				setFacilitiesOnsiteList(
					convertToSortedMultiListData(referenceFacilitiesOnsite)
				);
			}
			if (referenceFacilitiesOffsite && referenceFacilitiesOffsite.length > 1) {
				setFacilitiesOffsiteList(
					convertToSortedMultiListData(referenceFacilitiesOffsite)
				);
			}
			if (referenceSpecialty && referenceSpecialty.length > 1) {
				setSpecialtyDisciplinesList(
					convertToSortedMultiListData(referenceSpecialty)
				);
			}
			if (referenceServices && referenceServices.length > 1) {
				setServicesOfferedList(convertToSortedMultiListData(referenceServices));
			}
			setIsFormDisabled(true);
		}, [
			businessProfileData,
			referenceFacilitiesOnsite,
			referenceFacilitiesOffsite,
			referenceSpecialty,
			referenceServices
		]);

		const handleSubmit = async (e) => {
			e.preventDefault();
			validateFields();
			setErrors(validation(values, true, validationFields));
			if (
				Object.keys(validation(values, true, validationFields)).length === 0 &&
				(isRetirementFarmSelected ||
					(values.specialtyDisciplinesValues.value.length !== 0 &&
						!isRetirementFarmSelected))
			) {
				setBackendErrorMessage(null);
				setSpecialtyDisciplinesError('');
				let businessInfoFacilitiesOnPropertyIDs = [];
				values.facilitiesOnsiteValues.value &&
					values.facilitiesOnsiteValues.value.map((item) => {
						businessInfoFacilitiesOnPropertyIDs.push(item.value);
					});
				let businessInfoFacilitiesOffsitePropertyIDs = [];
				values.facilitiesOffsiteValues.value &&
					values.facilitiesOffsiteValues.value.map((item) => {
						businessInfoFacilitiesOffsitePropertyIDs.push(item.value);
					});
				let otherfacilitiesOffSite = null;
				if (
					values.facilitiesOffsiteValues.value &&
					values.facilitiesOffsiteValues.value.filter(
						(r) => r.label === 'Other'
					).length > 0
				) {
					otherfacilitiesOffSite = values.facilitiesOffSiteOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}
				let otherfacilitiesOnProperty = null;
				if (
					values.facilitiesOnsiteValues.value &&
					values.facilitiesOnsiteValues.value.filter((r) => r.label === 'Other')
						.length > 0
				) {
					otherfacilitiesOnProperty = values.facilitiesOnSiteOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}
				let businessInfoSpecialtyIDs = [];
				values.specialtyDisciplinesValues.value &&
					values.specialtyDisciplinesValues.value.map((item) => {
						businessInfoSpecialtyIDs.push(item.value);
					});
				let otherSpecialtyDisciplines = null;
				if (
					values.specialtyDisciplinesValues.value &&
					values.specialtyDisciplinesValues.value.filter(
						(r) => r.label === 'Other'
					).length > 0
				) {
					otherSpecialtyDisciplines = values.specialtyDisciplinesOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}
				let businessInfoServicesIDs = [];
				values.servicesOfferedValues.value &&
					values.servicesOfferedValues.value.map((item) => {
						businessInfoServicesIDs.push(item.value);
					});
				let otherServicesOffered = null;
				if (
					values.servicesOfferedValues.value &&
					values.servicesOfferedValues.value.filter((r) => r.label === 'Other')
						.length > 0
				) {
					otherServicesOffered = values.servicesOfferedOtherMulti.value
						?.map(({ value }) => value)
						.toString();
				}
				let items = { ...values };
				const state = {
					businessSummary: values.businessSummary.value,
					experiences: values.experiences.value,
					facilitiesOffSite: items?.facilitiesOffsiteValues?.value.map(
						(offsite) => {
							return { id: offsite.value, value: offsite.label };
						}
					),
					facilitiesOffSiteOther: otherfacilitiesOffSite,
					facilitiesOnProperty: items?.facilitiesOnsiteValues?.value.map(
						(onsite) => {
							return { id: onsite.value, value: onsite.label };
						}
					),
					facilitiesOnPropertyOther: otherfacilitiesOnProperty,
					priceRange: values.priceRange.value,
					servicesOffered: items?.servicesOfferedValues?.value.map(
						(service) => {
							return { id: service.value, value: service.label };
						}
					),
					servicesOfferedOther: otherServicesOffered,
					specialtyDisciplinesOther: otherSpecialtyDisciplines,
					specialtyDisciplines: items?.specialtyDisciplinesValues?.value.map(
						(discipline) => {
							return { id: discipline.value, value: discipline.label };
						}
					),
					status: 'Draft'
				};
				try {
					AppSyncService.execute(saveBusinessInformation, {
						businessSummary: values.businessSummary.value,
						experiences: values.experiences.value,
						facilitiesOffSite: businessInfoFacilitiesOffsitePropertyIDs,
						facilitiesOffSiteOther: otherfacilitiesOffSite,
						facilitiesOnProperty: businessInfoFacilitiesOnPropertyIDs,
						facilitiesOnPropertyOther: otherfacilitiesOnProperty,
						memberExternalId: currentUser?.UserId,
						priceRange: values.priceRange.value,
						servicesOffered: businessInfoServicesIDs,
						servicesOfferedOther: otherServicesOffered,
						specialtyDisciplinesOther: otherSpecialtyDisciplines,
						specialtyDisciplines: businessInfoSpecialtyIDs,
						businessProfileTypeId: businessProfileType
					}).then((data) => {
						if (data.data != null) {
							addToast({ Message: 'Saved', IsSuccess: true });
							setIsSubmitted(false);
							onSaveBusinessProfileData(state);
						}
					});
					setIsFormDisabled(true);
				} catch (error) {
					if (error) {
						setBackendErrorMessage(error);
					} else {
						setBackendErrorMessage(
							'Unknown error while saving user Business Information data. Please contact support'
						);
					}
				}
			} else {
				if (
					values.specialtyDisciplinesValues.value.length === 0 &&
					!isRetirementFarmSelected
				) {
					setSpecialtyDisciplinesError('Specialty Disciplines are required');
				}
				setBackendErrorMessage(
					'Please complete all the required fields before saving.'
				);
			}
		};

		const handleMultiFieldChange = (value, name, fieldsToClear) => {
			let items = { ...values };
			let item = {
				...items[name],
				value: value
			};
			items[name] = item;
			if (fieldsToClear && fieldsToClear.length > 0) {
				fieldsToClear.forEach((field) => {
					let newItem = {
						...items[field.item],
						value: field.value
					};
					items[field.item] = newItem;
				});
			}
			setIsFormDisabled(false);
			setValues(items);
		};

		useEffect(() => {
			setBackendErrorMessage(null);
		}, [values]);

		useImperativeHandle(ref, () => ({
			triggerValidation() {
				setErrors(validation(values, true, validationFields));
			}
		}));

		return (
			<>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<form className={classes.formContainer}>
					<div className={classes.personalProfile}>
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Business Summary*
									</div>
									<ResizingTextArea
										borderColor={errors.businessSummary ? 'red' : '#c7ced1'}
										name="businessSummary"
										id="businessProfile-businessSummary"
										value={values.businessSummary.value}
										onChange={handleChange}
										maxChars={700}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.businessSummary}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Facilities - On{' '}
										{businessProfileType === 2 ? 'Property' : 'Site'}*
									</div>
									<FormMultiSelectList
										name="facilitiesOnsiteValues"
										listOptions={facilitiesOnsiteList}
										selectedIds={values.facilitiesOnsiteValues.value}
										onSelectChange={(e) =>
											handleMultiFieldChange(e, 'facilitiesOnsiteValues', [
												{ item: 'facilitiesOnSiteOtherMulti', value: '' }
											])
										}
										border={errors.facilitiesOnsiteValues ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.facilitiesOnsiteValues
											? `Facilities - On ${
													isRetirementFarmSelected ? 'Property' : 'Site'
											  } are required`
											: ''}
									</span>
								</div>
							</Col>
						</Row>
						{values.facilitiesOnsiteValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Facilities - On{' '}
												{businessProfileType === 2 ? 'Property' : 'Site'}{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual facilities by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											value={values.facilitiesOnSiteOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'facilitiesOnSiteOtherMulti'
												);
											}}
											hasError={errors.facilitiesOnSiteOtherMulti}
										/>
									</div>
									<span className={classes.errorText}>
										{errors.facilitiesOnSiteOtherMulti
											? `Other Facilities - On ${
													isRetirementFarmSelected ? 'Property' : 'Site'
											  } are required`
											: ''}
									</span>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Facilities - Off{' '}
										{businessProfileType === 2 ? 'Property' : 'Site'}{' '}
										<span className={classes.fieldSubtitle}>(Optional)</span>
									</div>
									<FormMultiSelectList
										name="facilitiesOffsite"
										listOptions={facilitiesOffsiteList}
										selectedIds={values.facilitiesOffsiteValues.value}
										onSelectChange={(e) =>
											handleMultiFieldChange(e, 'facilitiesOffsiteValues', [
												{ item: 'facilitiesOffSiteOtherMulti', value: '' }
											])
										}
										border={errors.facilitiesOffsite ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.facilitiesOffsite
											? `Facilities - Off ${
													isRetirementFarmSelected ? 'Property' : 'Site'
											  } are required`
											: ''}
									</span>
								</div>
							</Col>
						</Row>
						{values.facilitiesOffsiteValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Facilities - Off{' '}
												{businessProfileType === 2 ? 'Property' : 'Site'}{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual facilities by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											value={values.facilitiesOffSiteOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'facilitiesOffSiteOtherMulti'
												);
											}}
											hasError={errors.facilitiesOffSiteOtherMulti}
										/>
										<span className={classes.errorText}>
											{errors.facilitiesOffSiteOtherMulti
												? `Other Facilities - Off ${
														isRetirementFarmSelected ? 'Property' : 'Site'
												  } are required`
												: ''}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>
										Specialty Disciplines{isRetirementFarmSelected ? `` : `*`}
									</div>
									<FormMultiSelectList
										name="specialtyDisciplines"
										listOptions={specialtyDisciplinesList}
										selectedIds={values.specialtyDisciplinesValues.value}
										onSelectChange={(e) => {
											handleMultiFieldChange(e, 'specialtyDisciplinesValues', [
												{ item: 'specialtyDisciplinesOtherMulti', value: '' }
											]);
										}}
										border={specialtyDisciplinesError ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{specialtyDisciplinesError}
									</span>
								</div>
							</Col>
						</Row>
						{values.specialtyDisciplinesValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Specialty Disciplines{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual specialties by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											value={values.specialtyDisciplinesOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'specialtyDisciplinesOtherMulti'
												);
											}}
											hasError={errors.specialtyDisciplinesOtherMulti}
										/>
									</div>
									<span className={classes.errorText}>
										{errors.specialtyDisciplinesOtherMulti}
									</span>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle}>Services Offered*</div>
									<FormMultiSelectList
										name="servicesOfferedValues"
										listOptions={servicesOfferedList}
										selectedIds={values.servicesOfferedValues.value}
										onSelectChange={(e) =>
											handleMultiFieldChange(e, 'servicesOfferedValues', [
												{ item: 'servicesOfferedOtherMulti', value: '' }
											])
										}
										border={errors.servicesOfferedValues ? 'red' : null}
									/>
									<span className={classes.errorText}>
										{errors.servicesOfferedValues}
									</span>
								</div>
							</Col>
						</Row>
						{values.servicesOfferedValues.value.filter(
							(r) => r.label === 'Other'
						).length > 0 && (
							<Row>
								<Col>
									<div className={classes.ProfilefieldContainer}>
										<div style={{ marginBottom: 7 }}>
											<div className={classes.fieldTitle}>
												Other Services Offered{' '}
												<span className={classes.fieldSubtitle}>
													(Please separate individual services by comma)
												</span>
												*
											</div>
										</div>
										<MultiSelectInput
											value={values.servicesOfferedOtherMulti.value}
											updateValue={(value) => {
												handleMultiFieldChange(
													value,
													'servicesOfferedOtherMulti'
												);
											}}
											hasError={errors.servicesOfferedOtherMulti}
										/>
										<span className={classes.errorText}>
											{errors.servicesOfferedOtherMulti}
										</span>
									</div>
								</Col>
							</Row>
						)}
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Price Range{' '}
										<span className={classes.fieldSubtitle}>(Optional)</span>
									</div>
									<ResizingTextArea
										borderColor={errors.priceRange ? 'red' : '#c7ced1'}
										name="priceRange"
										id="businessProfile-priceRange"
										value={values.priceRange.value}
										onChange={handleChange}
										maxChars={700}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>{errors.priceRange}</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className={classes.ProfilefieldContainer}>
									<div className={classes.fieldTitle} style={{ float: 'left' }}>
										Experience / Qualifications{' '}
										<span className={classes.fieldSubtitle}>(Optional)</span>
									</div>
									<ResizingTextArea
										borderColor={errors.experiences ? 'red' : '#c7ced1'}
										name="experiences"
										id="businessProfile-experiences"
										value={values.experiences.value}
										onChange={handleChange}
										maxChars={700}
										styles={{ marginLeft: 0, marginRight: 0, padding: 0 }}
									/>
									<span className={classes.errorText}>
										{errors.experiences}
									</span>
								</div>
							</Col>
						</Row>
					</div>
					<hr className={classes.divider}></hr>

					<div className={classes.buttonContainer}>
						<div className={classes.errorContainer}>
							{backendErrorMessage && (
								<span className={classes.errorMessage}>
									{backendErrorMessage}
								</span>
							)}
						</div>
						<div className={classes.saveRequest}>
							<span className={classes.lastSaved}></span>
							<span>
								<PrimaryButton
									style={{ width: '165px' }}
									disabled={isFormDisabled}
									onClick={handleSubmit}
								>
									{isFormDisabled ? 'Saved' : 'Save Changes'}
								</PrimaryButton>
							</span>
						</div>
					</div>
					<div className={classes.clear}></div>
				</form>
			</>
		);
	}
);
export default BusinessInfoDetails;

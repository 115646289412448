import { createUseStyles } from 'react-jss';

export default createUseStyles({
	emptyHorseUpdates: {
		backgroundColor: '#fcf7da',
		padding: 10,
		margin: 15,
		fontSize: 13,
		borderRadius: 4,
		color: '#464749'
	}
});

import React, { useEffect, useState, Fragment } from 'react';
import { useParams } from '@reach/router';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getHorseProgram,
	getPreviousHorseClaimByIdQuery,
	listHorseClaimSources
} from '../../graphql/custom.queries';
import SEO from '../seo';
import ClaimThoroughbredReview from '../ClaimThoroughbredReview';
import { APPROVED } from '../../utils/constants';

const PreviousClaimThoroughbredReview = () => {
	const { id } = useParams();
	const [data, setData] = useState({
		address: null,
		claimPurpose: '',
		claimsSource: null,
		claimsSourceOther: null,
		horse: null,
		horseCode: null,
		horseName: '',
		horseProgram: null,
		isHorseAtAddress: false,
		member: null,
		memberId: null,
		organisation: '',
		ownershipType: '',
		reviewStatus: APPROVED,
		stabledAddress: null,
		stabledDate: null
	});
	const [allClaimData, setAllClaimData] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const fetchData = async () => {
		const sourceData = await AppSyncService.execute(listHorseClaimSources);
		if (!sourceData?.data?.listHorseClaimSources) {
			return;
		}

		const result = await AppSyncService.execute(
			getPreviousHorseClaimByIdQuery,
			{
				id: parseInt(id)
			}
		);
		if (!result?.data?.getHorsePreviousClaimById) {
			return;
		}
		const { getHorsePreviousClaimById } = result.data;

		let stabledAddress, horseName, claimsSource;
		let isHorseAtAddress = true;

		setAllClaimData(getHorsePreviousClaimById);

		if (getHorsePreviousClaimById?.claimsSource) {
			claimsSource =
				sourceData.data.listHorseClaimSources.find(
					(item) => item.id === getHorsePreviousClaimById.claimsSource
				)?.value || '';
		}

		if (getHorsePreviousClaimById.horse) {
			horseName = getHorsePreviousClaimById.horse.name;
		}

		if (
			getHorsePreviousClaimById?.stabledAddressLine &&
			getHorsePreviousClaimById?.stabledCountry &&
			getHorsePreviousClaimById?.stabledPostcode &&
			getHorsePreviousClaimById?.stabledSuburb &&
			getHorsePreviousClaimById?.stabledState
		) {
			stabledAddress = {
				stabledAddressLine: getHorsePreviousClaimById.stabledAddressLine,
				stabledCountry: getHorsePreviousClaimById.stabledCountry,
				stabledPostcode: getHorsePreviousClaimById.stabledPostcode,
				stabledSuburb: getHorsePreviousClaimById.stabledSuburb,
				stabledState: getHorsePreviousClaimById.stabledState
			};
			isHorseAtAddress = false;
		}

		setData((state) => ({
			...state,
			...getHorsePreviousClaimById,
			address: getHorsePreviousClaimById.member.residentialAddress,
			claimsSource,
			horseCode: getHorsePreviousClaimById.horse.horseCode,
			horseName,
			isHorseAtAddress,
			memberId: getHorsePreviousClaimById.member.externalId,
			reviewStatus: APPROVED,
			stabledAddress
		}));
		setIsLoading(false);

		const getHorseProgramResult = await AppSyncService.execute(
			getHorseProgram,
			{
				horseCode: parseInt(getHorsePreviousClaimById.horse.horseCode)
			}
		);
		if (
			getHorseProgramResult.data != null &&
			getHorseProgramResult.data.getHorseProfile != null
		) {
			setData((state) => ({
				...state,
				horseProgram: getHorseProgramResult.data.getHorseProfile.program.value
			}));
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Fragment>
			<SEO title="Previous Claimed Thoroughbred Review" />
			<ClaimThoroughbredReview
				allClaimData={allClaimData}
				data={data}
				isCurrent={false}
				isLoading={isLoading}
				title={`Previous Claimed Thoroughbred - ${data.horseName}`}
			/>
		</Fragment>
	);
};

export default PreviousClaimThoroughbredReview;

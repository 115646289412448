import React from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from './style';
import TagsList from '../HorseProfile/components/TagsList';

const FormTagsListField = ({ title, value, children, ...props }) => {
	return (
		<StyledContainer {...props}>
			<label>{title}</label>
			<TagsList tagsList={value}>{children}</TagsList>
		</StyledContainer>
	);
};

FormTagsListField.propTypes = {
	title: PropTypes.string,
	value: PropTypes.array
};

export default FormTagsListField;

import React from 'react';
import useStyles from './styles';

const SaveCancelButton = ({
	idNegative,
	idPositive,
	negativeLabel,
	positiveLabel,
	cancel,
	onClickPositive
}) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div
				id={idNegative}
				className={classes.buttonStyleNegative}
				onClick={cancel}
			>
				{negativeLabel}
			</div>
			<div
				id={idPositive}
				className={classes.buttonStyle}
				onClick={onClickPositive}
			>
				{positiveLabel}
			</div>
		</div>
	);
};

export default SaveCancelButton;

import React from 'react';
import PrimaryButton from '../../PrimaryButton';
import { Row } from 'react-grid-system';
import useStyles from './styles';

const BASE_BUTTON_STYLES = {
	width: 150,
	marginTop: 0
};

const REJECT_BUTTON_STYLES = {
	...BASE_BUTTON_STYLES,
	marginRight: 15,
	backgroundColor: '#edf5f9',
	color: '#006da6'
};

const AdminReviewFooter = ({ status, formState, onReject, onApprove }) => {
	const validationErrorsApproved = Object.keys(formState).filter(
		(key) => !key.includes('rejection')
	);
	const validationErrorsRejected = Object.keys(formState).filter((key) =>
		key.includes('rejection')
	);
	const validationErrors = Object.values(formState).find(
		({ validationError }) => validationError
	);
	const classes = useStyles();
	return (
		<Row className={classes.footerContainer}>
			<div className={classes.footerContentContainer}>
				{validationErrors && (
					<span className={classes.errorMessage}>
						Please complete all required fields
					</span>
				)}
				<div className={classes.footerActions}>
					<PrimaryButton
						style={REJECT_BUTTON_STYLES}
						isNonSubmit
						onClick={onReject}
						disabled={
							status === 'Rejected' ||
							status === 'Approved' ||
							status === 'Draft' ||
							Object.values(validationErrorsRejected).find(
								({ validationError }) => validationError
							)
						}
					>
						Reject
					</PrimaryButton>
					<PrimaryButton
						style={BASE_BUTTON_STYLES}
						onClick={onApprove}
						disabled={
							status === 'Approved' ||
							status === 'Rejected' ||
							status === 'Draft' ||
							Object.values(validationErrorsApproved).find(
								({ validationError }) => validationError
							)
						}
					>
						Approve
					</PrimaryButton>
				</div>
			</div>
		</Row>
	);
};

export default AdminReviewFooter;

import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import useStyles from './styles';
import AppSyncService from '../../graphql/AppSyncService';
import {
	getThoroughbredUnregisteredFormByIdReview,
	getMemberProfileForAdminView,
	getAdminActionHorseRegistrationOptionsList,
	saveThoroughbredUnregisteredFormStatus,
	saveAdminAction
} from '../../graphql/custom.queries';
import FormTextField from '../FormTextField';
import LoaderSpinner from '../LoaderSpinner';
import HeaderBanner from '../HeaderBanner';
import UploadPhoto from '../UploadPhoto';
import MessageBar from '../MessageBar';
import SEO from '../seo';
// import { DASHBOARD_ROUTE } from '../../utils/constants/routes';
import BasicCheckbox from '../BasicCheckbox';
import Toggle from '../Toggle';
import { DEFAULT_COUNTRY, REVIEW, REVIEWED } from '../../utils/constants';
import { formatDateReadableVariant, sortArray } from '../../utils/helpers';
import { navigate, useParams } from '@reach/router';
import AdminActions from '../AdminActions';
import { useAuth } from '../../hooks/useAuth';
import { useToast } from '../../hooks/useToast';
import useFilePreview from '../../hooks/useFilePreview';

const hasHorseRacedOpts = [
	{ id: 0, value: '' },
	{ id: 1, value: 'Yes' },
	{ id: 2, value: 'No' },
	{ id: 3, value: 'Unknown' }
];

const ThoroughbredUnregisteredFormReview = () => {
	const classes = useStyles();
	let { id } = useParams();
	const { addToast } = useToast();
	const filePreviewHandler = useFilePreview();
	const { currentUser } = useAuth();
	const [colour, setColour] = useState(null);
	const [microchip, setMicrochip] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [profileInfo, setProfileInfo] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [isDifferentPostalAddress, setIsDifferentPostalAddress] = useState(
		false
	);
	const [
		isHorseLocatedAtTheSameResidentialAddress,
		setIsHorseLocatedAtTheSameResidentialAddress
	] = useState(true);
	const [address, setAddress] = useState('');
	const [suburb, setSuburb] = useState('');
	const [postcode, setPostcode] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState(DEFAULT_COUNTRY);
	const [thoroughbredAddress, setThoroughbredAddress] = useState('');
	const [thoroughbredSuburb, setThoroughbredSuburb] = useState('');
	const [thoroughbredPostcode, setThoroughbredPostcode] = useState('');
	const [thoroughbredState, setThoroughbredState] = useState('');
	const [thoroughbredCountry, setThoroughbredCountry] = useState(
		DEFAULT_COUNTRY
	);
	const [postalAddress, setPostalAddress] = useState('');
	const [postalSuburb, setPostalSuburb] = useState('');
	const [postalPostcode, setPostalPostcode] = useState('');
	const [postalState, setPostalState] = useState('');
	const [postalCountry, setPostalCountry] = useState(DEFAULT_COUNTRY);
	const [horseNameBreeding, setHorseNameBreeding] = useState('');
	const [selectedGender, setSelectedGender] = useState(null);
	const [age, setAge] = useState('');
	const [sireName, setSireName] = useState('');
	const [damName, setDamName] = useState('');
	const [countryOfBirth, setCountryOfBirth] = useState(null);
	const [countryOfRegistration, setCountryOfRegistration] = useState(null);
	const [registrationNo, setRegistrationNo] = useState('');
	const [lifeNo, setLifeNo] = useState('');
	const [overseasName, setOverseasName] = useState('');
	const [brandLeftImages, setBrandLeftImages] = useState([]);
	const [brandRightImages, setBrandRightImages] = useState([]);
	const [markingsLegImages, setMarkingsLegImages] = useState([]);
	const [markingHeadImages, setMarkingHeadImages] = useState([]);
	const [
		importExportDocumentationImages,
		setImportExportDocumentationImages
	] = useState([]);
	const [hasHorseEverRaced, setHasHorseEverRaced] = useState(0);
	const [howDidHorseComeYourCase, setHowDidHorseComeYourCase] = useState('');
	const [areThereAnyDetailsIdentity, setAreThereAnyDetailsIdentity] = useState(
		''
	);
	const [foalYear, setFoalYear] = useState(null);
	const [foalDate, setFoalDate] = useState(null);
	const [importationDate, setImportationDate] = useState(null);

	const [outcomeId, setOutcomeId] = useState(0);
	const [actionMemberId, setActionMemberId] = useState(1);
	const [adminStatusId, setAdminStatusId] = useState(0);
	const [outcomeList, setOutcomeList] = useState([]);
	const [actionMemberList, setActionMemberList] = useState([]);
	const [adminStatusList, setAdminStatusList] = useState([]);
	const [isAdminActionUpdated, setIsAdminActionUpdated] = useState(false);
	const [adminActions, setAdminActions] = useState(null);
	const [adminActionList, setAdminActionList] = useState(null);
	const [error, setError] = useState('');
	const [isLoadingAdminAction, setIsLoadingAdminAction] = useState(false);
	const [
		thoroughbredUnregisteredForm,
		setThoroughbredUnregisteredForm
	] = useState(null);
	const [notes, setNotes] = useState('');

	useEffect(() => {
		setIsLoading(true);
		AppSyncService.execute(getAdminActionHorseRegistrationOptionsList).then(
			(list) => {
				setAdminActionList(list.data);
				setIsLoading(false);
			}
		);
	}, []);

	useEffect(() => {
		if (adminActionList && thoroughbredUnregisteredForm) {
			let modifiedOutcomeList = sortArray(
				adminActionList.listAdminActionOutcomesHorseRegistration,
				true
			);
			modifiedOutcomeList.unshift({ id: 0, value: '' });
			setOutcomeList(modifiedOutcomeList);
			let modifiedActionList = adminActionList.listAdminActionStauses;
			modifiedActionList.unshift({ id: 0, value: '' });
			setAdminStatusList(modifiedActionList);
			let memberList = [];
			adminActionList.getAdminMembers.map((v) => {
				memberList.push({
					id: v.externalId,
					value: `${v.firstName} ${v.lastName}`
				});
			});
			setActionMemberList(memberList);

			setAdminActions(thoroughbredUnregisteredForm.adminAction);
			let memberId = thoroughbredUnregisteredForm.adminAction?.admin?.externalId
				? thoroughbredUnregisteredForm.adminAction?.admin?.externalId
				: currentUser?.UserId;
			let outComeId = thoroughbredUnregisteredForm.adminAction?.adminOutcome?.id
				? thoroughbredUnregisteredForm.adminAction?.adminOutcome?.id
				: 0;
			let adminStatus = thoroughbredUnregisteredForm?.adminAction?.adminStatus
				?.id
				? thoroughbredUnregisteredForm.adminAction?.adminStatus?.id
				: 0;
			setOutcomeId(outComeId);
			setActionMemberId(memberId);
			setAdminStatusId(adminStatus);
		}
	}, [thoroughbredUnregisteredForm, adminActionList]);

	useEffect(() => {
		setIsLoading(true);

		const payload = {
			...(id && { id: id })
		};
		AppSyncService.execute(
			getThoroughbredUnregisteredFormByIdReview,
			payload
		).then((result) => {
			if (result.data !== null) {
				let dataInfo = result?.data?.getThoroughbredUnregisteredFormById;

				const userId = dataInfo.memberId;
				AppSyncService.execute(getMemberProfileForAdminView, {
					id: userId
				}).then((response) => {
					const { getMember } = response.data;

					setProfileInfo(getMember);
					setContactNumber(getMember.phone ?? '');
					if (getMember?.residentialAddress !== null) {
						setAddress(getMember?.residentialAddress?.address ?? '');
						setSuburb(getMember?.residentialAddress?.suburb ?? '');
						setPostcode(getMember?.residentialAddress?.postcode ?? '');
						setState(getMember?.residentialAddress?.state || '');
						setCountry(
							getMember?.residentialAddress?.country || DEFAULT_COUNTRY
						);
					}
					if (getMember?.postalAddress !== null) {
						setPostalAddress(getMember?.postalAddress?.address ?? '');
						setPostalSuburb(getMember?.postalAddress?.suburb ?? '');
						setPostalPostcode(getMember?.postalAddress?.postcode ?? '');
						setPostalState(getMember?.postalAddress?.state || '');
						setPostalCountry(
							getMember?.postalAddress?.country || DEFAULT_COUNTRY
						);
					}
					setIsDifferentPostalAddress(getMember?.postalAddress?.address);
				});

				if (dataInfo?.id !== null) {
					setThoroughbredUnregisteredForm(dataInfo);
					setIsAdminActionUpdated(dataInfo?.status === REVIEWED);
					setNotes(!dataInfo.reviewNotes ? '' : dataInfo.reviewNotes);
					setIsHorseLocatedAtTheSameResidentialAddress(
						dataInfo.isThisHorseLocatedTheSameAddress
					);
					setThoroughbredAddress(
						!dataInfo.horseLocationResidentialAddress
							? ''
							: dataInfo.horseLocationResidentialAddress
					);
					setThoroughbredSuburb(
						!dataInfo.horseLocationResidentialSuburb
							? ''
							: dataInfo.horseLocationResidentialSuburb
					);
					setThoroughbredPostcode(
						!dataInfo.horseLocationResidentialPostcode
							? ''
							: dataInfo.horseLocationResidentialPostcode
					);
					setThoroughbredState(
						!dataInfo.horseLocationResidentialState
							? ''
							: dataInfo.horseLocationResidentialState
					);
					setThoroughbredCountry(
						!dataInfo.horseLocationResidentialCountry
							? DEFAULT_COUNTRY
							: dataInfo.horseLocationResidentialCountry
					);
					setHorseNameBreeding(
						!dataInfo.horseNameBreeding ? '' : dataInfo.horseNameBreeding
					);
					setSelectedGender(dataInfo.gender);
					setFoalDate(!dataInfo.foalDate ? null : dataInfo.foalDate);
					setAge(!dataInfo.age ? '' : dataInfo?.age?.toString());
					setFoalYear(!dataInfo.foalYear ? null : dataInfo.foalYear.toString());
					setSireName(!dataInfo.sireName ? '' : dataInfo.sireName);
					setDamName(!dataInfo.damName ? '' : dataInfo.damName);
					setColour(!dataInfo.colour ? null : dataInfo.colour);
					setCountryOfBirth(
						!dataInfo.countryOfBirth ? null : dataInfo.countryOfBirth
					);
					setCountryOfRegistration(
						!dataInfo.countryOfRegistration ? null : dataInfo.countryOfBirth
					);
					setRegistrationNo(
						!dataInfo.registrationNo ? '' : dataInfo.registrationNo
					);
					setLifeNo(!dataInfo.lifeNo ? '' : dataInfo.lifeNo);
					setMicrochip(
						!dataInfo.microchipNumber ? '' : dataInfo.microchipNumber
					);
					setOverseasName(!dataInfo.overseasName ? '' : dataInfo.overseasName);
					setBrandLeftImages(dataInfo.brandLeftImages);
					setBrandRightImages(dataInfo.brandRightImages);
					setMarkingsLegImages(dataInfo.markingsLegImages);
					setMarkingHeadImages(dataInfo.markingsHeadImages);
					setImportationDate(
						!dataInfo.importationDate ? null : dataInfo.importationDate
					);
					setHasHorseEverRaced(
						!dataInfo.hasThisHorseEverRaced ? 0 : dataInfo.hasThisHorseEverRaced
					);
					setHowDidHorseComeYourCase(
						!dataInfo.horseToBeInYourCase ? '' : dataInfo.horseToBeInYourCase
					);
					setAreThereAnyDetailsIdentity(
						!dataInfo.anyOtherDetailsHorseIdentity
							? ''
							: dataInfo.anyOtherDetailsHorseIdentity
					);
					setImportExportDocumentationImages(dataInfo.uploadImportExportImages);
				}
			}
		});

		setIsLoading(false);
	}, []);

	const updateReviewNotes = (val) => {
		setNotes(val);
		setIsAdminActionUpdated(true);
	};

	const updateFields = (name, value) => {
		if (name === 'outcome') {
			setOutcomeId(value);
		}
		if (name === 'adminStatus') {
			setAdminStatusId(value);
		}

		if (name === 'memberId') {
			setActionMemberId(value);
		}
		setIsAdminActionUpdated(true);
	};

	const completeForm = (status) => {
		updateAdminActions(status);
	};

	const updateAdminActions = (status = null) => {
		setIsLoadingAdminAction(true);
		AppSyncService.execute(saveAdminAction, {
			formId: parseInt(thoroughbredUnregisteredForm?.id),
			formType: 'ThoroughbredUnregistered',
			memberExternalId: actionMemberId,
			adminOutcomeId: parseInt(outcomeId) === 0 ? null : parseInt(outcomeId),
			adminStatusId:
				parseInt(adminStatusId) === 0 ? null : parseInt(adminStatusId),
			id:
				adminActions?.admin?.id === actionMemberId
					? adminActions?.admin?.id
					: null
		}).then((data) => {
			if (
				data.data != null &&
				!data.data?.saveAdminAction?.error?.errorMessage
			) {
				AppSyncService.execute(saveThoroughbredUnregisteredFormStatus, {
					id: thoroughbredUnregisteredForm.id,
					reviewNotes: notes,
					status
				}).then((result) => {
					setIsLoadingAdminAction(false);
					if (
						result.data != null &&
						!result.data?.saveThoroughbredUnregisteredFormStatus?.error
							?.errorMessage
					) {
						addToast({ Message: 'Updated Admin Action', IsSuccess: true });
						if (status === REVIEWED) navigate(`/review-list`);
					} else {
						addToast({ Message: 'Failed to Update', IsSuccess: false });
						setError(
							result.data?.saveThoroughbredUnregisteredFormStatus?.error
								?.errorMessage
						);
					}
				});
			} else {
				setIsLoadingAdminAction(false);
				addToast({ Message: 'Failed to Update', IsSuccess: false });
				setError(data.data?.saveAdminAction?.error?.errorMessage);
			}
		});
	};

	// const navigateToDashboard = () => {
	// 	navigate(DASHBOARD_ROUTE);
	// };

	const renderDifferentPostalAddress = () => {
		return (
			<Row>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Address*</div>
						<span className={classes.profileInfo}>{postalAddress}</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Suburb*</div>
						<span className={classes.profileInfo}>{postalSuburb}</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Postcode*</div>
						<span className={classes.profileInfo}>{postalPostcode}</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal State*</div>
						<span className={classes.profileInfo}>{postalState}</span>
					</div>
				</Col>
				<Col lg={4} sm={12}>
					<div className={classes.fieldContainer}>
						<div className={classes.fieldTitle}>Postal Country*</div>
						<span className={classes.profileInfo}>{postalCountry}</span>
					</div>
				</Col>
			</Row>
		);
	};

	return (
		<Container className={classes.pageContainer}>
			<SEO title="Thoroughbred Unregistered Form" />
			<HeaderBanner
				title="Application to identify Studbook Registration"
				top
				type="primary"
				styles={{ marginBottom: '0px', borderRadius: '5px 5px 0px 0px' }}
			/>
			<MessageBar>
				Please note this process can take time as our team will need to work
				through multiple sources to identify your thoroughbred registration
				details. Include as much information as you can and our team will be in
				touch with you.
			</MessageBar>
			<HeaderBanner
				title="Contact Details"
				top
				type="primary"
				styles={{ marginBottom: '0px', marginTop: '30px' }}
			/>
			<div className={classes.formContainer}>
				{isLoading && (
					<div className={classes.loadingDiv}>
						<LoaderSpinner status={true} />
					</div>
				)}
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>First Name</div>
								<FormTextField value={profileInfo.firstName} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Last Name</div>
								<FormTextField value={profileInfo.lastName} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Email</div>
								<FormTextField value={profileInfo.email} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Contact Number*</div>
								<span className={classes.profileInfo}>{contactNumber}</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Address*</div>
								<span className={classes.profileInfo}>{address}</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Suburb*</div>
								<span className={classes.profileInfo}>{suburb}</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Postcode*</div>
								<span className={classes.profileInfo}>{postcode}</span>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential State*</div>
								<FormTextField name="state" value={state} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Residential Country*</div>
								<FormTextField name="state" value={country} canEdit={false} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs={12} lg={4}>
							<div className={classes.checkboxFieldContainer}>
								<BasicCheckbox
									value={isDifferentPostalAddress}
									handleChange={() =>
										setIsDifferentPostalAddress(!isDifferentPostalAddress)
									}
									label={'I have a different postal address'}
									disabled={true}
								/>
							</div>
						</Col>
					</Row>
					{isDifferentPostalAddress ? renderDifferentPostalAddress() : null}
				</div>
				<HeaderBanner
					title="Horse Location"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Is this horse located at the same residential address?
								</div>
								<Toggle
									labelNegative="No"
									labelPositive="Yes"
									disabled={true}
									value={isHorseLocatedAtTheSameResidentialAddress}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Address*</div>
								<FormTextField
									name="resSuburb"
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? address
											: thoroughbredAddress
									}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Suburb*</div>
								<FormTextField
									name="resSuburb"
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? suburb
											: thoroughbredSuburb
									}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Postcode*</div>
								<FormTextField
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? postcode
											: thoroughbredPostcode
									}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred State*</div>
								<FormTextField
									name="state"
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? state
											: thoroughbredState
									}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Thoroughbred Country*</div>
								<FormTextField
									name="country"
									value={
										isHorseLocatedAtTheSameResidentialAddress
											? country
											: thoroughbredCountry
									}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<HeaderBanner
					title="Horse Details"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Horse Name / Breeding*</div>
								<FormTextField value={horseNameBreeding} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sex*</div>
								<FormTextField value={selectedGender} canEdit={false} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Foal Date (DOB)</div>
								{foalDate ? formatDateReadableVariant(foalDate) : ''}
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Age*</div>
								<FormTextField value={age} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Foal Year*</div>
								{foalYear ? formatDateReadableVariant(foalYear) : ''}
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Sire Name</div>
								<FormTextField value={sireName} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Dam Name</div>
								<FormTextField value={damName} canEdit={false} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Colour*</div>
								<FormTextField value={colour} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Country of Birth*</div>
								<FormTextField value={countryOfBirth} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Country of Registration
								</div>
								<FormTextField value={countryOfRegistration} canEdit={false} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Registration No.</div>
								<FormTextField value={registrationNo} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Life No.</div>
								<FormTextField value={lifeNo} canEdit={false} />
							</div>
						</Col>
					</Row>
				</div>

				<HeaderBanner
					title="Horse Identification"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>
				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Microchip Number</div>
								<FormTextField value={microchip} canEdit={false} />
							</div>
						</Col>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>Overseas Name</div>
								<FormTextField value={overseasName} canEdit={false} />
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand - Left (jpg,png, pdf)*
								</div>
								<UploadPhoto
									data={brandLeftImages}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Brand - Right (jpg,png, pdf)*
								</div>
								<UploadPhoto
									data={brandRightImages}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Markings - Leg (jpg, png, pdf)*
								</div>
								<UploadPhoto
									data={markingsLegImages}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Markings - Head (jpg, png, pdf)*
								</div>
								<UploadPhoto
									data={markingHeadImages}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<HeaderBanner
					title="Horse History"
					inline
					styles={{ marginBottom: '10px', marginTop: '15px' }}
				/>

				<div className={classes.sectionContainer}>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Importation date (Date arrived into Australia, if known)
								</div>
								{importationDate
									? formatDateReadableVariant(importationDate)
									: ''}
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Has this horse ever raced?*
								</div>
								{hasHorseEverRaced
									? hasHorseRacedOpts.find(
											(selectedOpt) => selectedOpt.id === hasHorseEverRaced
									  )?.value
									: ''}
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									How did the horse come to be in your care?*
								</div>
								<span className={classes.profileInfo}>
									{howDidHorseComeYourCase}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className={classes.fieldContainer}>
								<div
									className={classes.fieldTitle}
								>{`Are there any other details relevant to this horse's history or identity?`}</div>
								<span className={classes.profileInfo}>
									{areThereAnyDetailsIdentity}
								</span>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={4} md={4} sm={12}>
							<div className={classes.fieldContainer}>
								<div className={classes.fieldTitle}>
									Upload Import/Export paperwork or sale history documentation
								</div>
								<UploadPhoto
									data={importExportDocumentationImages}
									getImage={filePreviewHandler}
									canEdit={false}
								/>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<AdminActions
				actionMemberId={actionMemberId}
				adminStatusId={adminStatusId}
				outComeId={outcomeId}
				memberList={actionMemberList}
				statusList={adminStatusList}
				outComeList={outcomeList}
				formName="ThoroughbredUnRegistered"
				reviewNotes={notes}
				updateReviewNotes={updateReviewNotes}
				isAdminActionUpdated={isAdminActionUpdated}
				updateFields={updateFields}
				reviewStatus={thoroughbredUnregisteredForm?.status}
				isLoading={isLoadingAdminAction}
				updateAdminAction={() => updateAdminActions(REVIEW)}
				completeForm={completeForm}
				error={error}
			/>
		</Container>
	);
};

export default ThoroughbredUnregisteredFormReview;

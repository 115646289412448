import {
	DEFAULT_LOCATION_SOURCE,
	DEFAULT_PROGRAM,
	DEFAULT_OTT_STATUS,
	WELFARE_CHECK,
	PRIVATE_VISIBLITY,
	FOLLOWER_VISIBLITY,
	AUDIT,
	FOSTER_CHECK,
	EMERGENCY_AID_PROGRAM,
	SOURCE_LOCATION_STAR,
	PRINCIPAL_RACING_AUTHORITY,
	SOURCE_LOCATION_PRINCIPAL_AUTHORITY
} from './constants';
import { BLANK_OPTION, DEFAULT_COUNTRY } from '../../utils/constants';
import {
	isOptionOther,
	checkDropDownValue,
	isMultiSelectOptionOther,
	convertToMultiListData
} from '../../utils/helpers';

export const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const checkStableLocationChanged = (
	horseLocationFormState,
	savedLocationData
) => {
	const inputStableLocationAddress = {
		address: horseLocationFormState.address.value,
		suburb: horseLocationFormState.suburb.value,
		state: horseLocationFormState.state.value,
		country: horseLocationFormState.country.value,
		postcode: +horseLocationFormState.postcode.value
	};
	const savedAddress = {
		address: savedLocationData?.address,
		suburb: savedLocationData?.suburb,
		state: savedLocationData?.state,
		country: savedLocationData?.country,
		postcode: +savedLocationData?.postcode
	};
	return (
		JSON.stringify(inputStableLocationAddress) !== JSON.stringify(savedAddress)
	);
};

export const validateHorseLocation = (stableLocationFormState) => {
	const {
		address,
		suburb,
		postcode,
		state,
		country,
		stabledDate
	} = stableLocationFormState;
	let validationErrors = { ...stableLocationFormState };
	if (!address.value) {
		validationErrors = {
			...validationErrors,
			address: {
				...validationErrors.address,
				validationError: 'Stable address is required'
			}
		};
	}
	if (!suburb.value) {
		validationErrors = {
			...validationErrors,
			suburb: {
				...validationErrors.suburb,
				validationError: 'Suburb is required'
			}
		};
	}
	if (!postcode.value || isNaN(postcode.value)) {
		validationErrors = {
			...validationErrors,
			postcode: {
				...validationErrors.postcode,
				validationError: 'Valid postcode is required'
			}
		};
	}
	if (!state.value) {
		validationErrors = {
			...validationErrors,
			state: {
				...validationErrors.state,
				validationError: 'State is required'
			}
		};
	}
	if (!country.value) {
		validationErrors = {
			...validationErrors,
			country: {
				...validationErrors.country,
				validationError: 'Country is required'
			}
		};
	}
	if (!stabledDate.value) {
		validationErrors = {
			...validationErrors,
			stabledDate: {
				...validationErrors.stabledDate,
				validationError: 'Stabled date is required'
			}
		};
	}
	return validationErrors;
};

export const validateOTTStatusSection = (
	ottStatusFormState,
	lookUpOptions,
	stableLocationFormState,
	horseData
) => {
	const {
		locationSourceOptions,
		welfareSourceOptions,
		welfareRelationshipOptions,
		auditNameOptions,
		fosterReasonForEnteringOptions,
		principalRacingAuthorityOptions
	} = lookUpOptions;
	const {
		locationSource,
		otherLocationSource,
		asOfDate,
		welfareSource,
		otherWelfareSource,
		personsOfInterest,
		welfareRelationship,
		otherWelfareRelationship,
		program,
		auditName,
		auditOther,
		otherRelationshipAudit,
		relationshipAudit,
		fosterReasonForEnteringProgram,
		fosterAssistanceType,
		fosterReasonForEnteringProgramOther,
		fosterAssistanceTypeOther,
		principalRacingAuthorityOther,
		principalRacingAuthority
	} = ottStatusFormState;
	let validationErrors = { ...ottStatusFormState };
	if (
		locationSource.value == BLANK_OPTION.id ||
		(checkStableLocationChanged(stableLocationFormState, horseData.location) &&
			!locationSourceOptions.find(({ id }) => id == locationSource.value))
	) {
		validationErrors = {
			...validationErrors,
			locationSource: {
				...validationErrors.locationSource,
				validationError: 'Source of location is required'
			}
		};
	}
	if (
		isOptionOther(locationSourceOptions, locationSource.value) &&
		!otherLocationSource.value
	) {
		validationErrors = {
			...validationErrors,
			otherLocationSource: {
				...validationErrors.otherLocationSource,
				validationError: 'Otther source of location is required'
			}
		};
	}
	if (program.value != DEFAULT_PROGRAM && !asOfDate.value) {
		validationErrors = {
			...validationErrors,
			asOfDate: {
				...validationErrors.asOfDate,
				validationError: 'As of date is required'
			}
		};
	}
	const isLocationSourceWelfareCheck = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		WELFARE_CHECK
	);
	const isLocationSourcFosterCheck = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		FOSTER_CHECK
	);
	const isLocationSourceAudit = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		AUDIT
	);
	const isPrincipalRacingAuthority = checkDropDownValue(
		locationSourceOptions,
		locationSource.value,
		PRINCIPAL_RACING_AUTHORITY
	);
	const isEmergencyProgram = program.value == EMERGENCY_AID_PROGRAM;

	if (isLocationSourceWelfareCheck) {
		if (welfareSource.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				welfareSource: {
					...validationErrors.welfareSource,
					validationError: 'Identified during is required'
				}
			};
		}
		if (
			isOptionOther(welfareSourceOptions, welfareSource.value) &&
			!otherWelfareSource.value
		) {
			validationErrors = {
				...validationErrors,
				otherWelfareSource: {
					...validationErrors.otherWelfareSource,
					validationError: 'Other Identified during is required'
				}
			};
		}
		if (!personsOfInterest.value) {
			validationErrors = {
				...validationErrors,
				personsOfInterest: {
					...validationErrors.personsOfInterest,
					validationError: 'Person of interest is required'
				}
			};
		}
		if (welfareRelationship.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				welfareRelationship: {
					...validationErrors.welfareRelationship,
					validationError: 'POI relationship is required'
				}
			};
		}
		if (
			isOptionOther(welfareRelationshipOptions, welfareRelationship.value) &&
			!otherWelfareRelationship.value
		) {
			validationErrors = {
				...validationErrors,
				otherWelfareRelationship: {
					...validationErrors.otherWelfareRelationship,
					validationError: 'Other POI Relationship is required'
				}
			};
		}
	}
	if (isLocationSourceAudit) {
		if (auditName.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				auditName: {
					...validationErrors.auditName,
					validationError: 'Audit name is required'
				}
			};
		}
		if (isOptionOther(auditNameOptions, auditName.value) && !auditOther.value) {
			validationErrors = {
				...validationErrors,
				auditOther: {
					...validationErrors.auditOther,
					validationError: 'Other Audit Name is required'
				}
			};
		}
		if (
			isOptionOther(welfareRelationshipOptions, relationshipAudit.value) &&
			!otherRelationshipAudit.value
		) {
			validationErrors = {
				...validationErrors,
				otherRelationshipAudit: {
					...validationErrors.otherRelationshipAudit,
					validationError: 'Other POI Relationship is required'
				}
			};
		}
	}

	if (isPrincipalRacingAuthority) {
		if (principalRacingAuthority.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				principalRacingAuthority: {
					...validationErrors.principalRacingAuthority,
					validationError: 'Principal Racing Authority is required'
				}
			};
		}
		if (
			isOptionOther(
				principalRacingAuthorityOptions,
				principalRacingAuthority.value
			) &&
			!principalRacingAuthorityOther.value
		) {
			validationErrors = {
				...validationErrors,
				principalRacingAuthorityOther: {
					...validationErrors.principalRacingAuthorityOther,
					validationError: 'Other PRA is required'
				}
			};
		}
	}
	if (isLocationSourcFosterCheck) {
		if (fosterReasonForEnteringProgram.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				fosterReasonForEnteringProgram: {
					...validationErrors.fosterReasonForEnteringProgram,
					validationError: 'Reason for entering program is required'
				}
			};
		}
		if (
			isOptionOther(
				fosterReasonForEnteringOptions,
				fosterReasonForEnteringProgram.value
			) &&
			!fosterReasonForEnteringProgramOther.value
		) {
			validationErrors = {
				...validationErrors,
				fosterReasonForEnteringProgramOther: {
					...validationErrors.fosterReasonForEnteringProgramOther,
					validationError: 'Reason for entering program - Other is required'
				}
			};
		}
		if (
			fosterAssistanceType.value &&
			fosterAssistanceType.value.length > 0 &&
			isMultiSelectOptionOther(fosterAssistanceType.value) &&
			!fosterAssistanceTypeOther.value
		) {
			validationErrors = {
				...validationErrors,
				fosterAssistanceTypeOther: {
					...validationErrors.fosterAssistanceTypeOther,
					validationError: 'Assistance Type - Other is required'
				}
			};
		}
	}
	if (isEmergencyProgram) {
		if (fosterReasonForEnteringProgram.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				fosterReasonForEnteringProgram: {
					...validationErrors.fosterReasonForEnteringProgram,
					validationError: 'Reason for entering program is required'
				}
			};
		}
		if (
			isOptionOther(
				fosterReasonForEnteringOptions,
				fosterReasonForEnteringProgram.value
			) &&
			!fosterReasonForEnteringProgramOther.value
		) {
			validationErrors = {
				...validationErrors,
				fosterReasonForEnteringProgramOther: {
					...validationErrors.fosterReasonForEnteringProgramOther,
					validationError: 'Reason for entering program - Other is required'
				}
			};
		}
		if (fosterAssistanceType.value == BLANK_OPTION.id) {
			validationErrors = {
				...validationErrors,
				fosterAssistanceType: {
					...validationErrors.fosterAssistanceType,
					validationError: 'Assistance Type is required'
				}
			};
		}
		if (
			fosterAssistanceType.value &&
			fosterAssistanceType.value.length > 0 &&
			isMultiSelectOptionOther(fosterAssistanceType.value) &&
			!fosterAssistanceTypeOther.value
		) {
			validationErrors = {
				...validationErrors,
				fosterAssistanceTypeOther: {
					...validationErrors.fosterAssistanceTypeOther,
					validationError: 'Assistance Type - Other is required'
				}
			};
		}
	}

	return validationErrors;
};

export const generateSaveHorseLocationPayload = (
	stableLocationFormState,
	ottStatusFormState,
	inputBase,
	isAdminUser
) => {
	const {
		address,
		suburb,
		postcode,
		state,
		country,
		addressEnteredManually,
		stabledDate
	} = stableLocationFormState;
	const {
		locationSource,
		otherLocationSource,
		welfareSource,
		otherWelfareSource,
		personsOfInterest,
		welfareRelationship,
		otherWelfareRelationship,
		welfareComments,
		auditName,
		personOfInterestAudit,
		relationshipAudit,
		otherRelationshipAudit,
		auditComments,
		auditOther,
		fosterReasonForEnteringProgram,
		fosterAssistanceType,
		fosterReasonForEnteringProgramOther,
		fosterAssistanceTypeOther,
		principalRacingAuthority,
		principalRacingAuthorityOther
	} = ottStatusFormState;
	const welfareCheckDetailsAvailable = personsOfInterest.value;
	const fosterCheckDetailsAvailable =
		locationSource.value == SOURCE_LOCATION_STAR &&
		(fosterReasonForEnteringProgram.value || fosterAssistanceType.value);
	const principalRacingAuthorityAvailable =
		locationSource.value == SOURCE_LOCATION_PRINCIPAL_AUTHORITY &&
		principalRacingAuthority.value;
	const emergencyAidCheckDetailsAvailable =
		ottStatusFormState?.program?.value == EMERGENCY_AID_PROGRAM &&
		(fosterReasonForEnteringProgram.value || fosterAssistanceType.value);
	const auditCheckDetailsAvailable =
		personOfInterestAudit.value ||
		relationshipAudit.value ||
		otherRelationshipAudit.value ||
		auditComments.value;
	return {
		...inputBase,
		address: address.value,
		addressVisibility: address.visibility,
		suburb: suburb.value,
		suburbVisibility: suburb.visibility,
		postcode: postcode.value,
		postcodeVisibility: postcode.visibility,
		state: state.value,
		stateVisibility: state.visibility,
		country: country.value,
		countryVisibility: country.visibility,
		isValidated: !addressEnteredManually.value,
		stabledDate: stabledDate.value,
		stabledDateVisibility: stabledDate.visibility,
		sourceId: isAdminUser ? +locationSource.value : DEFAULT_LOCATION_SOURCE,
		...(isAdminUser &&
			otherLocationSource.value && { sourceOther: otherLocationSource.value }),
		...(welfareCheckDetailsAvailable && {
			welfareCheckDetails: {
				comments: welfareComments.value || null,
				names: personsOfInterest.value,
				relationshipId: +welfareRelationship.value,
				relationshipOther: otherWelfareRelationship.value || null,
				sourceId: +welfareSource.value,
				sourceOther: otherWelfareSource.value || null
			}
		}),
		...(fosterCheckDetailsAvailable && {
			fosterCheckDetails: {
				reasonForEnteringProgramId: +fosterReasonForEnteringProgram.value,
				reasonForEnteringProgramOther:
					fosterReasonForEnteringProgramOther.value || null,
				assistanceTypeIds:
					fosterAssistanceType.value && fosterAssistanceType.value.length > 0
						? fosterAssistanceType.value.map((item) => +item.value)
						: [],
				assistanceTypeOther: fosterAssistanceTypeOther.value || null
			}
		}),
		...(principalRacingAuthorityAvailable && {
			principalRacingAuthorityDetails: {
				principalRacingAuthorityId: +principalRacingAuthority.value,
				principalRacingAuthorityOther:
					principalRacingAuthorityOther.value || null
			}
		}),
		...(emergencyAidCheckDetailsAvailable && {
			emergencyAidCheckDetails: {
				reasonForEnteringProgramId: +fosterReasonForEnteringProgram.value,
				reasonForEnteringProgramOther:
					fosterReasonForEnteringProgramOther.value || null,
				assistanceTypeIds:
					fosterAssistanceType.value && fosterAssistanceType.value.length > 0
						? fosterAssistanceType.value.map((item) => +item.value)
						: [],
				assistanceTypeOther: fosterAssistanceTypeOther.value || null
			}
		}),
		auditName: +auditName.value || null,
		...(auditCheckDetailsAvailable && {
			auditCheckDetails: {
				comments: auditComments.value || null,
				names: personOfInterestAudit.value,
				relationshipId: +relationshipAudit.value || null,
				relationshipOther: otherRelationshipAudit.value || null,
				auditOther: auditOther.value || null
			}
		})
	};
};

export const initialiseHorseLocationData = (
	horseLocationData,
	updateFormState
) => {
	updateFormState((prevState) => ({
		...prevState,
		address: {
			value: horseLocationData?.address || '',
			visibility: horseLocationData?.addressVisibility?.id || PRIVATE_VISIBLITY
		},
		suburb: {
			value: horseLocationData?.suburb || '',
			visibility: horseLocationData?.suburbVisibility?.id || PRIVATE_VISIBLITY
		},
		postcode: {
			value: horseLocationData?.postcode || '',
			visibility:
				horseLocationData?.postcodeVisibility?.id || FOLLOWER_VISIBLITY
		},
		state: {
			value: horseLocationData?.state || '',
			visibility: horseLocationData?.stateVisibility?.id || FOLLOWER_VISIBLITY
		},
		country: {
			value: horseLocationData?.country || DEFAULT_COUNTRY,
			visibility: horseLocationData?.countryVisibility?.id || FOLLOWER_VISIBLITY
		},
		stabledDate: {
			value: horseLocationData?.stabledDate || null,
			visibility:
				horseLocationData?.stabledDateVisibility?.id || FOLLOWER_VISIBLITY
		},
		addressEnteredManually: { value: !horseLocationData.isValidated }
	}));
};

export const generateSaveOttInfoPayload = (ottInfoFormState, inputBase) => {
	const {
		profileImage,
		knownAsName,
		purpose,
		otherPurpose,
		disciplines,
		otherDisciplines,
		personalityInfo,
		happinessInfo,
		vices,
		otherVices,
		injuriesPresent,
		injuryTypes,
		otherInjuryTypes,
		illnessesPresent,
		illnessTypes,
		otherIllnessTypes
	} = ottInfoFormState;
	return {
		...inputBase,
		profilePhotoVisibility: profileImage.visibility,
		paddockName: knownAsName.value || null,
		paddockNameVisibility: knownAsName.visibility,
		purposeId: +purpose.value !== BLANK_OPTION.id ? +purpose.value : null,
		purposeVisibility: purpose.visibility,
		purposeOther: otherPurpose.value || null,
		disciplines: disciplines.value.map(({ value }) => value),
		disciplineVisibility: disciplines.visibility,
		disciplinesOther:
			otherDisciplines.value.length > 0
				? otherDisciplines.value.map(({ value }) => value).toString()
				: null,
		personalityDetails: personalityInfo.value || null,
		personalityDetailsVisibility: personalityInfo.visibility,
		happyDetails: happinessInfo.value || null,
		happinessDetailsVisibility: happinessInfo.visibility,
		habits: vices.value.map(({ value }) => value),
		habitsVisibility: vices.visibility,
		habitsOther:
			otherVices.value.length > 0
				? otherVices.value.map(({ value }) => value).toString()
				: null,
		hasInjuries: injuriesPresent.value,
		injuries: injuryTypes.value.map(({ value }) => value),
		injuriesVisibility: injuriesPresent.visibility,
		injuriesOther:
			otherInjuryTypes.value.length > 0
				? otherInjuryTypes.value.map(({ value }) => value).toString()
				: null,
		hasIllnesses: illnessesPresent.value,
		illnessesVisibility: illnessesPresent.visibility,
		illnesses: illnessTypes.value.map(({ value }) => value),
		illnessesOther:
			otherIllnessTypes.value.length > 0
				? otherIllnessTypes.value.map(({ value }) => value).toString()
				: null
	};
};

export const initialiseOttInfoData = (horseOttInformation, updateFormState) => {
	updateFormState((prevState) => ({
		...prevState,
		profileImage: {
			value:
				horseOttInformation.uploadedFiles?.filter(
					(file) => file.type === 'ProfileImage' && !file.removed
				)?.length > 0
					? horseOttInformation.uploadedFiles?.filter(
							(file) => file.type === 'ProfileImage' && !file.removed
					  )
					: [],
			visibility:
				horseOttInformation.profilePhotoVisibility?.id || FOLLOWER_VISIBLITY
		},
		knownAsName: {
			value: horseOttInformation.paddockName || '',
			visibility:
				horseOttInformation.paddockNameVisibility?.id || FOLLOWER_VISIBLITY
		},
		purpose: {
			value: horseOttInformation.purpose?.id || BLANK_OPTION.id,
			visibility:
				horseOttInformation.purposeVisibility?.id || FOLLOWER_VISIBLITY
		},
		otherPurpose: { value: horseOttInformation.purposeOther || '' },
		disciplines: {
			value:
				horseOttInformation.disciplines.map(({ id, value }) => ({
					label: value,
					value: id
				})) || [],
			visibility:
				horseOttInformation.disciplinesVisibility?.id || FOLLOWER_VISIBLITY
		},
		otherDisciplines: {
			value:
				(horseOttInformation.disciplinesOther &&
					horseOttInformation.disciplinesOther
						.split(',')
						.map((value) => ({ label: value, value: value }))) ||
				''
		},
		personalityInfo: {
			value: horseOttInformation.personalityDetails || '',
			visibility:
				horseOttInformation.personalityDetailsVisibility?.id ||
				FOLLOWER_VISIBLITY
		},
		happinessInfo: {
			value: horseOttInformation.happyDetails || '',
			visibility:
				horseOttInformation.happyDetailsVisibility?.id || FOLLOWER_VISIBLITY
		},
		vices: {
			value:
				horseOttInformation.habits.map(({ id, value }) => ({
					label: value,
					value: id
				})) || [],
			visibility: horseOttInformation.habitsVisibility?.id || PRIVATE_VISIBLITY
		},
		otherVices: {
			value:
				(horseOttInformation.habitsOther &&
					horseOttInformation.habitsOther
						.split(',')
						.map((value) => ({ label: value, value: value }))) ||
				''
		},
		injuriesPresent: {
			value: horseOttInformation.hasInjuries,
			visibility:
				horseOttInformation.injuriesVisibility?.id || PRIVATE_VISIBLITY
		},
		injuryTypes: {
			value:
				horseOttInformation.injuries.map(({ id, value }) => ({
					label: value,
					value: id
				})) || [],
			visibility: horseOttInformation.injuriesVisibility.id || PRIVATE_VISIBLITY
		},
		otherInjuryTypes: {
			value:
				(horseOttInformation.injuriesOther &&
					horseOttInformation.injuriesOther
						.split(',')
						.map((value) => ({ label: value, value: value }))) ||
				''
		},
		illnessesPresent: {
			value: horseOttInformation.hasIllnesses,
			visibility:
				horseOttInformation.illnessesVisibility?.id || PRIVATE_VISIBLITY
		},
		illnessTypes: {
			value:
				horseOttInformation.illnesses.map(({ id, value }) => ({
					label: value,
					value: id
				})) || [],
			visibility:
				horseOttInformation.illnessesVisibility.id || PRIVATE_VISIBLITY
		},
		otherIllnessTypes: {
			value:
				(horseOttInformation.illnessesOther &&
					horseOttInformation.illnessesOther
						.split(',')
						.map((value) => ({ label: value, value: value }))) ||
				''
		}
	}));
};

export const generateSaveOttStatusPayload = (ottStatusFormState, inputBase) => {
	const { program, ottStatus, asOfDate } = ottStatusFormState;
	return {
		...inputBase,
		programId: program.value,
		statusId: ottStatus.value,
		asOfDate: asOfDate.value
	};
};

export const initialiseOttStatusData = (
	horseProgram,
	horseOttStatus,
	horseLocationData,
	updateFormState
) => {
	updateFormState((prevState) => ({
		...prevState,
		program: { value: horseProgram.id || DEFAULT_PROGRAM },
		ottStatus: { value: horseOttStatus.status.id || DEFAULT_OTT_STATUS },
		asOfDate: { value: horseOttStatus.effectiveDate || null },
		locationSource: { value: horseLocationData?.source?.id || BLANK_OPTION.id },
		otherLocationSource: { value: horseLocationData?.sourceOther || '' },
		welfareSource: {
			value:
				horseLocationData?.welfareCheckDetails?.source?.id || BLANK_OPTION.id,
			validationError: ''
		},
		otherWelfareSource: {
			value: horseLocationData?.welfareCheckDetails?.sourceOther || '',
			validationError: ''
		},
		personsOfInterest: {
			value: horseLocationData?.welfareCheckDetails?.names || '',
			validationError: ''
		},
		welfareRelationship: {
			value:
				horseLocationData?.welfareCheckDetails?.relationship?.id ||
				BLANK_OPTION.id,
			validationError: ''
		},
		otherWelfareRelationship: {
			value: horseLocationData?.welfareCheckDetails?.relationshipOther || '',
			validationError: ''
		},
		welfareComments: {
			value: horseLocationData?.welfareCheckDetails?.comments || '',
			validationError: ''
		},
		auditName: {
			value: horseLocationData?.auditName?.id || BLANK_OPTION.id,
			validationError: ''
		},
		personOfInterestAudit: {
			value: horseLocationData?.auditCheckDetails?.names || '',
			validationError: ''
		},
		relationshipAudit: {
			value:
				horseLocationData?.auditCheckDetails?.relationship?.id ||
				BLANK_OPTION.id,
			validationError: ''
		},
		otherRelationshipAudit: {
			value: horseLocationData?.auditCheckDetails?.relationshipOther || '',
			validationError: ''
		},
		auditComments: {
			value: horseLocationData?.auditCheckDetails?.comments || '',
			validationError: ''
		},
		auditOther: {
			value: horseLocationData?.auditCheckDetails?.auditOther || '',
			validationError: ''
		},
		fosterReasonForEnteringProgramOther: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_STAR
					? horseLocationData?.fosterCheckDetails
							?.reasonForEnteringProgramOther || ''
					: horseProgram.id == EMERGENCY_AID_PROGRAM
					? horseLocationData?.emergencyAidCheckDetails
							?.reasonForEnteringProgramOther || ''
					: '',
			validationError: ''
		},
		fosterAssistanceTypeOther: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_STAR
					? horseLocationData?.fosterCheckDetails?.assistanceTypeOther || ''
					: horseProgram.id == EMERGENCY_AID_PROGRAM
					? horseLocationData?.emergencyAidCheckDetails?.assistanceTypeOther ||
					  ''
					: '',
			validationError: ''
		},
		fosterReasonForEnteringProgram: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_STAR
					? horseLocationData?.fosterCheckDetails?.reasonForEnteringProgram ||
					  ''
					: horseProgram.id == EMERGENCY_AID_PROGRAM
					? horseLocationData?.emergencyAidCheckDetails
							?.reasonForEnteringProgram || ''
					: BLANK_OPTION.id,
			validationError: ''
		},
		principalRacingAuthority: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_PRINCIPAL_AUTHORITY
					? horseLocationData?.principalRacingAuthorityDetails
							?.principalRacingAuthority || ''
					: BLANK_OPTION.id,
			validationError: ''
		},
		principalRacingAuthorityOther: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_PRINCIPAL_AUTHORITY
					? horseLocationData?.principalRacingAuthorityDetails
							?.principalRacingAuthorityOther || ''
					: '',
			validationError: ''
		},
		fosterAssistanceType: {
			value:
				horseLocationData?.source?.id == SOURCE_LOCATION_STAR &&
				horseLocationData?.fosterCheckDetails?.assistanceTypes
					? convertToMultiListData(
							JSON.parse(
								horseLocationData?.fosterCheckDetails?.assistanceTypes
							),
							'value'
					  )
					: horseProgram.id == EMERGENCY_AID_PROGRAM &&
					  horseLocationData?.emergencyAidCheckDetails?.assistanceTypes
					? convertToMultiListData(
							JSON.parse(
								horseLocationData?.emergencyAidCheckDetails?.assistanceTypes
							),
							'value'
					  )
					: [],
			validationError: ''
		}
	}));
};

export const generateHorseProfileRetirementPayload = (adminData, inputBase) => {
	const {
		selectedCategory,
		selectedVulnerabilityType,
		otherVicesRetirement,
		conformationalDefectsRetirement,
		otherEducationDetails,
		otherInjuryDetails,
		otherIllnessDetails,
		rehomingHistoryDetails,
		otherDetailsRetirement,
		selectedVicesRetirement,
		selectedInjuriesRetirement,
		selectedIllnessRetirement,
		selectedEducation,
		selectedReason,
		informationSource,
		sourceDetail,
		sourceDetailOther,
		detailsInformationObtained,
		adminId,
		dateInformationProvided,
		otherRelevantDetails
	} = adminData;

	const filteredViceRetirement = selectedVicesRetirement.map(
		(selectedViceRetirement) => selectedViceRetirement.value
	);
	const filteredEducation = selectedEducation.map(
		(selectedEduc) => selectedEduc.value
	);
	const filteredInjuryRetirement = selectedInjuriesRetirement.map(
		(selectedInjuryRetirement) => selectedInjuryRetirement.value
	);
	const filteredIllnessRetirement = selectedIllnessRetirement.map(
		(selectedIllnessRetirement) => selectedIllnessRetirement.value
	);
	const filteredReasonRetirement = selectedReason.map(
		(selectedReason) => selectedReason.value
	);
	return {
		...inputBase,
		informationSource,
		sourceDetail,
		sourceDetailOther:
			sourceDetail == 3 ||
			sourceDetail == 10 ||
			sourceDetail == 19 ||
			sourceDetail == 20
				? sourceDetailOther
				: null,
		detailsInformationObtained:
			informationSource == 2 ? detailsInformationObtained : null,
		adminId: informationSource == 2 ? adminId : null,
		dateInformationProvided,
		otherRelevantDetails,
		retirementCategory: selectedCategory,
		vulnerabilityType: selectedVulnerabilityType,
		behaviourOthersRetirement:
			otherVicesRetirement.length === 0
				? ''
				: otherVicesRetirement.map((item) => item.value).toString(),
		conformationalDetailsRetirement: conformationalDefectsRetirement,
		otherEducationDetails: otherEducationDetails,
		otherInjuryDetailsRetirement: otherInjuryDetails,
		otherIllnessDetailsRetirement: otherIllnessDetails,
		rehomingHistoryDetails: rehomingHistoryDetails,
		otherDetailsRetirement: otherDetailsRetirement,
		habitsRetirement: filteredViceRetirement,
		injuriesRetirement: filteredInjuryRetirement,
		illnessRetirement: filteredIllnessRetirement,
		education: filteredEducation,
		reasonRetirement: filteredReasonRetirement
	};
};

import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	formSectionContent: {
		marginTop: 0,
		'@media (min-width:992px)': {
			'& > div:not(:first-child)': {
				marginTop: 15
			}
		}
	},
	fieldTitle: {
		fontWeight: 600,
		fontSize: 14,
		marginBottom: 7,
		color: '#464749'
	},
	errorText: {
		color: 'red',
		fontSize: 13
	}
}));

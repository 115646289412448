import React, { useState } from 'react';
import { isOptionOther } from '../../utils/helpers';
import ConfirmationModal from '../ConfirmationModal';
import SelectInputField from '../FormComponents/SelectInputField';
import TextInputField from '../FormComponents/TextInputField';
import useStyles, { MODAL_STYLES } from './styles';

const RemoveHorseUpdateModal = ({
	showModal,
	onConfirm,
	onCancel,
	removeReasons
}) => {
	const classes = useStyles();
	const [removeReason, setRemoveReason] = useState(1);
	const [otherReason, setOtherReason] = useState('');
	const isConfirmDisabled =
		(isOptionOther(removeReasons, removeReason) && !otherReason) ||
		!removeReason;

	return (
		<ConfirmationModal
			showModal={showModal}
			closeModal={onCancel}
			styles={MODAL_STYLES}
			heading="Remove horse update"
			positiveLabel="Confirm"
			negativeLabel="Cancel"
			onClickPositive={() => onConfirm(removeReason, otherReason)}
			isConfirmDisabled={isConfirmDisabled}
		>
			<div className={classes.modalContentContainer}>
				<SelectInputField
					id="remove-horse-update-reason-select-input"
					label="Reason for removing post"
					value={removeReason}
					options={removeReasons}
					onChange={(event) => {
						setRemoveReason(event.target.value);
						setOtherReason('');
					}}
					isRequired
					fullWidth
				/>
				{isOptionOther(removeReasons, removeReason) && (
					<div className={classes.otherReasonContainer}>
						<TextInputField
							id="remove-horse-update-other-reason-textfield"
							label="Other"
							value={otherReason}
							onChange={(event) => setOtherReason(event.target.value)}
							isRequired
							fullWidth
						/>
					</div>
				)}
			</div>
		</ConfirmationModal>
	);
};

export default RemoveHorseUpdateModal;

import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatDateReadableVariant } from '../../../../utils/helpers';

const CurrentLicenseAndRegistrationSection = ({ formData }) => {
	return (
		<AdminReviewSection title="Current Racing Victoria Licences/Registrations">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label="Licence/Registration Type"
						value={formData?.licenceType?.value}
					/>
					<AdminReviewDatum
						label="Licence/Registration Number"
						value={formData?.licence}
					/>
					<AdminReviewDatum
						label="Licence/Registration Start Date"
						value={formatDateReadableVariant(formData?.licenceStart)}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default CurrentLicenseAndRegistrationSection;
